import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import queryString from "query-string";
import StarRatings from "react-star-ratings";
import { useAxios } from "../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import Spinner from "../../helpers/Spinner";
import { useAppInsights } from "../../context/TelemetryContext";
import { Icon } from "@fluentui/react/lib/Icon";
import Breadcrumbs from "../Breadcrumbs";
import TutorialUploadModal from "./TutorialsUpload";
import SortDropdown from "./SortDropdown";
import TutorialsFilter from "./TutorialsFilter";
import HashtagAutoSuggestions from "../TopicsLanding/HashtagSuggestions";
import { TabTitle } from "../../utils/GeneralFunctions";
import $ from "jquery";
import "./TopicsTutorials.css";
import TutorialEdit from "./TutorialEdit";
import TutorialDeleteModal from "./TutorialDeleteModal";
import RatingConfirmationModal from "../TopicsTutorialsDetails/RatingConfirmationModal";

const Tutorials = ({
  tutorialDynamicFilterTag,
  setTutorialDynamicFilterTag,
  tutorialDynamicFilterTagMapping,
  setTutorialDynamicFilterTagMapping,
  searchInput,
  setSearchInput,
  tutorialHashtag,
  setTutorialHashtag,
  showTutorials,
}) => {
  const location = window.location.search;
  const { track } = useAppInsights();
  const { tutorialId } = useParams();
  const { axiosGetService } = useAxios();
  const {
    breadcrumbs,
    setBreadcrumbs,
    setSkipNavigationId,
    isAdmin,
    endUserView,
  } = useLeftMenuCollapse();
  const [showLoader, setShowLoader] = useState(false);
  const [consultingTopicsTutorials, setConsultingTopicsTutorials] = useState(
    []
  );
  const [updatedConsultingTopics, setUpdatedConsultingTopics] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showTutorialUploadModal, setShowTutorialUploadModal] = useState(false);
  const [showApiResponseModal, setShowApiResponseModal] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSearchInput, setHashtagSearchInput] = useState("");
  const [showTutorialEdit, setShowTutorialEdit] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [showTutorialDelete, setShowTutorialDelete] = useState(false);
  const sortData = [
    { id: 1, name: "Default", type: "0" },
    { id: 2, name: "Name", type: "Name" },
    { id: 3, name: "Publish Date", type: "PublishDate" },
    { id: 4, name: "Rating", type: "Rating" },
    { id: 5, name: "Views", type: "Views" },
  ];
  const [selectedSortType, setSelectedSortType] = useState({
    id: 1,
    name: "Default",
  });
  const [enableRating, setEnableRating] = useState(false);
  // const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [showRatingConfirmationPopUp, setShowRatingConfirmationPopUp] =
    useState(false);
  // const selectedProcedures =
  //   window.location.search &&
  //     queryString.parse(window.location.search).topicType === "job" &&
  //     (queryString.parse(window.location.search).selectedJobAides === "2" ||
  //       queryString.parse(window.location.search).selectedJobAides === "3")
  //     ? Number(queryString.parse(window.location.search).selectedJobAides)
  //     : 0;

  const [enableFilterTagContainer, setEnableFilterTagContainer] =
    useState(false);

  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSearchInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const getConsultingOrSupportTopics = async () => {
    const selectedFiltersIds = [];
    const filterIds = Object.keys(tutorialDynamicFilterTag);
    const encodedHashtags = tutorialHashtag.map((item) => {
      return encodeURIComponent(item);
    });
    for (const id of filterIds) {
      if (tutorialDynamicFilterTag[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          tutorialDynamicFilterTag[Number(id)].selectedTagId.toString()
        );
      }
    }
    await axiosGetService(
      `api/sdmtutorials/videoTutorials/get?searchTitle=${searchInput ? encodeURIComponent(searchInput) : 0
      }&hashTagsText=${tutorialHashtag.length > 0 ? encodedHashtags.toString() : 0
      }&sortBy=${selectedSortType.type}&filterIds=${selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : 0
      }&pageTypeId=11&endUserView=${endUserView}`
    ).then((response) => {
      setConsultingTopicsTutorials(response.data);
      if (searchInput) {
        track(2, 1109, `tutorials_landingPage`, searchInput);
      }
      if (tutorialHashtag.length > 0 || searchInput.trim().length > 0) {
        track(1, 1208, "Procedures Landing Page", "Procedures", {
          hashtag:
            tutorialHashtag.length > 0 ? tutorialHashtag.toString() : null,
          title: searchInput.trim().length > 0 ? searchInput : null,
        });
      }
    });
    window.scroll(0, 0);
    setShowLoader(false);
  };

  // load breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=0&itemTypeId=81&isEdit=false
			&isAutoComplete=false&isSearchResultsHome=false&isSearchResults=false
			&isMyContributions=false&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const pageBreadcrumb = {
        title: breadcrumbTitle[1],
        parent: "Home",
        path: window.location.pathname,
      };
      const newBreadcrumbs = breadcrumbs.slice(0, 1);
      newBreadcrumbs.push(pageBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[1]);
    });
  };

  const getUserDefaultTopicId = async () => {
    const response = await axiosGetService("/api/sdmuser/getbyemail");
    if (response !== null && response.data !== null) {
      track(2, 1103, `tutorials_landingPage`, "Tutorials");
    }
  };

  const collapseFilter = (e) => {
    const filterArea = document.getElementById("filtersRightPanel");
    if (
      filterArea &&
      !filterArea.contains(e.target) &&
      e.target.className !== "filtersButton" &&
      !(
        typeof e.target.className !== "object" &&
        e.target.className &&
        e.target.className.includes("searchIcon")
      ) &&
      e.target.className !== "root-50" &&
      e.target.className !== "root-56" &&
      e.target.className !== "showMoreFilter"
    ) {
      setShowFilters(false);
    }
  };

  const checkForFilterTag = () => {
    const artifactTypeIds = Object.keys(tutorialDynamicFilterTag);
    for (const id in artifactTypeIds) {
      if (
        tutorialDynamicFilterTag[Number(artifactTypeIds[id])].selectedTagId
          .length > 0
      ) {
        setEnableFilterTagContainer(true);
        break;
      }
    }
    if (tutorialHashtag.length > 0 && !enableFilterTagContainer) {
      setEnableFilterTagContainer(true);
    }
  };

  const handleDynamicFilterTag = (artifactTypeId, tagId) => {
    const updatedProcessGroupFilterTag = tutorialDynamicFilterTag[
      artifactTypeId
    ].selectedTagId.filter((id) => id !== tagId);
    const updatedValue = {
      artifactTypeId: artifactTypeId,
      artifactTypeName:
        tutorialDynamicFilterTag[artifactTypeId].artifactTypeName,
      selectedTagId: [...updatedProcessGroupFilterTag],
    };
    setTutorialDynamicFilterTag({
      ...tutorialDynamicFilterTag,
      [artifactTypeId]: updatedValue,
    });
  };

  const clearFilters = () => {
    const newDynamicFilterTag = { ...tutorialDynamicFilterTag };
    const filterIds = Object.keys(newDynamicFilterTag);
    for (const id of filterIds) {
      newDynamicFilterTag[Number(id)].selectedTagId = [];
    }
    setTutorialDynamicFilterTag(newDynamicFilterTag);
    setHashtagSearchInput("");
    setTutorialHashtag([]);
  };

  const handleTutorialHashTags = (item) => {
    const updateHashtags = tutorialHashtag.filter(
      (hashtag) => hashtag !== item
    );
    setTutorialHashtag(updateHashtags);
  };

  const renderTopicsList = (topics, topicClickTelemetryId) => {
    let rowIndex = 1;
    return topics.map((item) => (
      <div key={item.categoryId} className="tutorialGrid">

        <div role="grid">
          <div role="rowgroup">
            <div class="tableHeading" role="row">
              <div role="cell" aria-rowindex="1">
                <h6>{item.categoryName}</h6>
              </div>
            </div>
          </div>
        </div>



        <div
          className="Rtable"
          role="table"

          aria-label="Delivery management table"
        >
          <div
            className="Rtable-row Rtable-row--head"
            role="row"
          >
            <div
              role="columnheader"
              className="Rtable-cell project-cell column-heading"
              aria-colindex={1}
              tabIndex={0}
            >
              Name
            </div>
            <div
              role="columnheader"
              className="Rtable-cell duration-cell column-heading"
              aria-colindex={2}
              tabIndex={0}
            >
              Duration
            </div>
            <div
              role="columnheader"
              className="Rtable-cell rating-cell column-heading"
              aria-colindex={3}
              tabIndex={0}
            >
              Rating
            </div>
            <div
              role="columnheader"
              className="Rtable-cell date-created-cell column-heading"
              aria-colindex={4}
              tabIndex={0}
            >
              Date Created
            </div>
            {isAdmin && (<div
              role="columnheader"
              className="Rtable-cell edit-cell column-heading"
              aria-colindex={5}
            ></div>
            )}
          </div>
          {item.sdmTutorials.map((sdmTutorials, tutorialIndex) => (
            <React.Fragment key={sdmTutorials.id}>
              <>
                <div
                  className="Rtable-row"
                  role="row"
                  aria-rowindex={rowIndex + tutorialIndex}
                >

                  <div className="Rtable-cell project-cell" role="cell" aria-colindex={1}>
                    <Link
                      className='nameOfTableData'
                      to={`/tutorials/${sdmTutorials.id}`}
                      title={sdmTutorials.name}
                      onClick={() => {
                        track(
                          1,
                          1110,
                          sdmTutorials.id,
                          sdmTutorials.name
                        );
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          track(
                            1,
                            1110,
                            sdmTutorials.id,
                            sdmTutorials.name
                          );
                        }
                      }}
                    >
                      <div
                        // role="cell"
                        className="Rtable-cell--content video-link-cell"
                      >  {sdmTutorials.name}
                      </div>
                    </Link>

                  </div>
                  <div className="Rtable-cell duration-cell">
                    <div
                      aria-colindex={2}
                      role="cell"
                      className="Rtable-cell--content"
                      tabIndex={0}
                    >
                      {sdmTutorials.duration}
                    </div>
                  </div>
                  <div className="Rtable-cell rating-cell" role="cell" aria-colindex={3}>
                    <span className="sampleTemplateRating noCursor" tabIndex={0}>
                      <StarRatings
                        rating={sdmTutorials.rating}
                        starDimension="14px"
                        starRatedColor={"#ffd700"}
                        starEmptyColor={"#fff"}
                        starSpacing="3px"
                        svgIconViewBox="0 0 31.951 32"
                        svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
                      />
                      <span>
                        ({sdmTutorials.ratingCount})
                      </span>
                    </span>
                  </div>
                  <div className="Rtable-cell date-created-cell">
                    <div
                      aria-colindex={4}
                      role="cell"
                      className="Rtable-cell--content"
                      tabIndex={0}
                    >
                      {sdmTutorials.publishedDate}
                    </div>
                  </div>
                  {isAdmin && (
                    <div className="Rtable-cell edit-icon-cell" role="cell" aria-colindex={5}>
                      <div>
                        <Icon
                          iconName="EditSolid12"
                          onClick={() => {
                            setSelectedTutorial(sdmTutorials);
                            setShowTutorialEdit(true);
                          }}
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setSelectedTutorial(
                                sdmTutorials
                              );
                              setShowTutorialEdit(
                                true
                              );
                            }
                          }}
                          role="button"
                          aria-label={`Edit ${sdmTutorials.name} Topic Tutorial`}
                        />
                        <span
                          className="topicTutorialDeleteIcon"
                          onClick={() => {
                            setSelectedTutorial(sdmTutorials);
                            setShowTutorialDelete(
                              true
                            );
                          }}
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setSelectedTutorial(
                                sdmTutorials
                              );
                              setShowTutorialDelete(
                                true
                              );
                            }
                          }}
                          role="button"
                          aria-label={`Delete ${sdmTutorials.name} Topic Tutorial`}
                        ></span>
                      </div>
                    </div>
                  )}
                </div>

              </>
            </React.Fragment>
          ))}
        </div>
      </div>
    ));
  };


  const getUpdatedSupportOrConsultingTopics = (
    consultingOrSupportData,
  ) => {
    const topicsLength = consultingOrSupportData.length;
    const updatedConsultingOrSupportData = [];
    if (topicsLength >= 2) {
      let initialIndex = 0;
      let increment_size = 0;
      let divider = 2;
      if (topicsLength % divider === 0) {
        increment_size = Math.floor(topicsLength / divider);
        while (initialIndex < topicsLength) {
          updatedConsultingOrSupportData.push(
            consultingOrSupportData.slice(
              initialIndex,
              (initialIndex += increment_size)
            )
          );
        }
      } else {
        while (initialIndex < topicsLength) {
          increment_size = Math.ceil((topicsLength - initialIndex) / divider--);
          updatedConsultingOrSupportData.push(
            consultingOrSupportData.slice(
              initialIndex,
              (initialIndex += increment_size)
            )
          );
        }
      }
    } else {
      for (const data in consultingOrSupportData) {
        updatedConsultingOrSupportData.push(
          consultingOrSupportData.slice(data, data + 1)
        );
      }
    }
    setUpdatedConsultingTopics(updatedConsultingOrSupportData);
  };

  useEffect(() => {
    if (consultingTopicsTutorials) {
      getUpdatedSupportOrConsultingTopics(consultingTopicsTutorials);
    }
  }, [consultingTopicsTutorials]);

  useEffect(() => {
    setSkipNavigationId("selectedTopicMenu");
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getUserDefaultTopicId();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [location]);

  useEffect(() => {
    showTutorials && loadBreadcrumb();
  }, []);

  useEffect(() => {
    let timeOutId;
    setShowLoader(true);
    getConsultingOrSupportTopics();
    checkForFilterTag();
    return () => {
      clearTimeout(timeOutId);
    };
  }, [
    searchInput,
    selectedSortType,
    tutorialDynamicFilterTag,
    tutorialHashtag,
    endUserView,
  ]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSearchInput.trim() && hashtagSearchInput[0] === "#") {
        hashtagsAutoComplete();
      } else {
        setHashtagSuggestions([]);
        setSearchInput("");
      }
    }, 1000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [hashtagSearchInput]);

  useEffect(() => {
    window.addEventListener("click", collapseFilter);
    return () => {
      window.removeEventListener("click", collapseFilter);
    };
  }, []);

  const _projectcellClickHandler = (e, i) => {
    e.stopPropagation();
    $('#projectcell-' + i).toggleClass('hiddenRow');
  }

  return (
    <div className="topicLandingMainContainer topicParentWrapper" role="main">
      <div className="topicsLandingPage">
        <Breadcrumbs />
        <div
          className="topicsContainerVideoTutorial expanded"
          id="topicsLanding"
        >
          <div className="topicsMenuWrapper">
            <div className="globalHashtagsSearch">
              <Icon iconName="Search" />
              <HashtagAutoSuggestions
                className="topicLandingHashtagAutoSuggestion"
                enableDropdown={true}
                data={hashtagSuggestions}
                searchValue={hashtagSearchInput}
                setSearchValue={setHashtagSearchInput}
                existingHashtags={tutorialHashtag}
                setExistingHashtags={setTutorialHashtag}
                setHashtagSuggestions={setHashtagSuggestions}
                setDataSearchInput={setSearchInput}
              />
              <SortDropdown
                className="sortDropdown"
                data={sortData}
                setValue={setSelectedSortType}
                value={selectedSortType}
                enableDropdown={true}
              />
            </div>
            <div
              className="addNewTutorials"
              tabIndex={0}
              onClick={() => {
                setShowTutorialUploadModal(true);
              }}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setShowTutorialUploadModal(true);
                }
              }}
              role="button"
              aria-label="Click to add new video tutorials"
            >
              <Icon iconName="PublishContent" />
              <div className="tabsTitleToolTip">
                <span className="tabsToolTipArrow"></span>
                <span>Contribute Video Tutorial</span>
              </div>
            </div>
            <div className="raciFiltersPart">
              <span>Clear all filters</span>
              <span
                className="ClearFiltersButton"
                onClick={clearFilters}
                tabIndex={0}
                role="button"
                aria-label="Clear all filters"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    clearFilters();
                  }
                }}
              ></span>
            </div>
            <button
              className="filtersButton"
              onClick={() => setShowFilters(!showFilters)}
            >
              Filters
              <Icon
                iconName="FilterSolid"
                title="Filters"
                className="searchIcon"
              />
            </button>
            <TutorialsFilter
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              dynamicFilterTag={tutorialDynamicFilterTag}
              setDynamicFilterTag={setTutorialDynamicFilterTag}
              setDynamicFilterTagMapping={setTutorialDynamicFilterTagMapping}
            />
          </div>
          <div className="topicsListContainer">
            {enableFilterTagContainer ? (
              <div className="topicFilterTagsDiv">
                <div className="filterTagsContainer">
                  <div className="tagsContainer">
                    {tutorialHashtag.map((item) => (
                      <span key={item}>
                        {item}
                        <Icon
                          onClick={() => handleTutorialHashTags(item)}
                          iconName="cancel"
                        />
                      </span>
                    ))}
                    {Object.keys(tutorialDynamicFilterTag).map((item) => (
                      <>
                        {tutorialDynamicFilterTag[Number(item)].selectedTagId
                          .length > 0 ? (
                          <>
                            {tutorialDynamicFilterTag[
                              Number(item)
                            ].selectedTagId.map((tagId) => (
                              <>
                                {tutorialDynamicFilterTagMapping[
                                  tutorialDynamicFilterTag[Number(item)]
                                    .artifactTypeId
                                ][tagId] ? (
                                  <span key={tagId}>
                                    {`${tutorialDynamicFilterTagMapping[
                                      tutorialDynamicFilterTag[Number(item)]
                                        .artifactTypeId
                                    ][tagId]
                                      }`}
                                    <Icon
                                      onClick={() =>
                                        handleDynamicFilterTag(
                                          Number(item),
                                          tagId
                                        )
                                      }
                                      iconName="cancel"
                                    />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {showApiResponseModal ? (
              <Modal
                show={showApiResponseModal}
                onHide={() => setShowApiResponseModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="templateFeedbackPopUp"
              >
                <Modal.Body>
                  <p>You have uploaded the video tutorial sucessfully.</p>
                  <button
                    className="feedbackSubmittedOk"
                    onClick={() => setShowApiResponseModal(false)}
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowApiResponseModal(false);
                      }
                    }}
                  >
                    OK
                  </button>
                </Modal.Body>
              </Modal>
            ) : (
              <></>
            )}

            {showLoader ? (
              <div className="spinner">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                {showTutorialUploadModal ? (
                  <TutorialUploadModal
                    showTutorialUploadModal={showTutorialUploadModal}
                    setShowTutorialUploadModal={setShowTutorialUploadModal}
                    setShowApiResponseModal={setShowApiResponseModal}
                    getConsultingOrSupportTopics={
                      getConsultingOrSupportTopics
                    }
                  />
                ) : (
                  <></>
                )}

                {showTutorialEdit && (
                  <TutorialEdit
                    setShowTutorialEdit={setShowTutorialEdit}
                    selectedTutorial={selectedTutorial}
                    getConsultingOrSupportTopics={
                      getConsultingOrSupportTopics
                    }
                  />
                )}

                {showTutorialDelete && (
                  <TutorialDeleteModal
                    setEnableTutorialDeleteModal={setShowTutorialDelete}
                    selectedTutorial={selectedTutorial}
                    setSelectedTutorial={setSelectedTutorial}
                    getConsultingOrSupportTopics={
                      getConsultingOrSupportTopics
                    }
                  />
                )}

                {(consultingTopicsTutorials.length === 0) ? (
                  <p className="noWorkInstructions">No Video Tutorials found!</p>
                )
                  :
                  <div className="">
                    <div className="topictuTorialContainer">

                      <div className="topicTutorialsMainDiv">

                        {consultingTopicsTutorials &&
                          updatedConsultingTopics.length >= 1 ? (
                          <>
                            {renderTopicsList(
                              updatedConsultingTopics[0],
                              10
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="topicTutorialsMainDiv">
                        {consultingTopicsTutorials &&
                          updatedConsultingTopics.length > 1 ? (
                          <>
                            {renderTopicsList(
                              updatedConsultingTopics[1],
                              10
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="topicTutorialsMainDiv">
                        {consultingTopicsTutorials &&
                          updatedConsultingTopics.length > 2 ? (
                          <>
                            {renderTopicsList(
                              updatedConsultingTopics[2],
                              10
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
          {showRatingConfirmationPopUp ? (
            <RatingConfirmationModal
              setEnableRating={setEnableRating}
              showRatingConfirmationPopUp={showRatingConfirmationPopUp}
              setShowRatingConfirmationPopUp={setShowRatingConfirmationPopUp}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div >
    // </div>
  );
};

export default Tutorials;
