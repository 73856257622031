import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import Dropdown from './Dropdown';
import Spinner from '../../helpers/Spinner';

const ManageProceduresArtifacts = () => {
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		}
	];
	const { pathname } = useLocation();
	const history = useHistory();
	const { axiosGetService, axiosPostService } = useAxios();
	const [selectedProjectType, setSelectedProjectType] = useState(
		projectTypeData[0]
	);
	const [procedureCategories, setProcedureCategories] = useState([]);
	const [selectedProcedureCategory, setSelectedProcedureCategory] =
		useState(null);
	const [selectedProcedure, setSelectedProcedure] = useState(null);
	const [procedures, setProcedures] = useState([]);
	const [procedureSerachValue, setProcedureSerachValue] = useState('');
	const [entitySerachValue, setEntitySerachValue] = useState('');
	const [artifactTypes, setArtifactTypes] = useState([]);
	const [selectedArificatType, setSelectedArificatType] = useState(null);
	const [selectedArtifactData, setSelectedArtifactData] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showArtifactSpinner, setShowArtifactSpinner] = useState(false);
	const [expandFilter, setExpandFilter] = useState(true);
	const getProcedureCategories = async () => {
		const response = await axiosGetService(
			`api/sdmtopiccategories/getprocedurecategoriesbyprojecttype/${selectedProjectType.id}`
		);
		setProcedureCategories(response.data);
		if (selectedProcedureCategory) {
			setSelectedProcedureCategory(
				response.data.find(({ id }) => selectedProcedureCategory.id === id)
			);
		}
	};
	const getProcedures = async () => {
		if (selectedProcedureCategory) {
			const response = await axiosGetService(
				`api/sdmtopics/getproceduresbycategory?topicCategoryIds=${
					selectedProcedureCategory?.id ?? 0
				}&searchValue=${
					procedureSerachValue.length > 0 ? procedureSerachValue : 0
				}`
			);
			setProcedures(
				response.data.map((procedure) => {
					return {
						...procedure,
						projectTypeId: selectedProjectType.id,
						projectTypeName: selectedProjectType.name,
					};
				})
			);
		} else {
			setProcedures([]);
		}
	};
	const getArtifactTypes = async () => {
		const response = await axiosGetService(
			`api/sdmproceduremaster/getprocedurefilterdata?projectTypeId=${selectedProjectType.id}`
		);
		setArtifactTypes(
			response.data.map(({ id, artifactTypeName, artifactTypeId }) => {
				return { id, name: artifactTypeName, artifactTypeId };
			})
		);
	};
	const selectProcedure = async (procedure) => {
		if (selectedProcedure?.id !== procedure.id) {
			setSelectedProcedure(procedure);
			setSelectedArificatType(null);
			setSelectedArtifactData([]);
			setEntitySerachValue('');
			getArtifactTypes();
		}
	};

	const getArtifacts = async () => {
		if (selectedArificatType) {
			setShowArtifactSpinner(true);
			const response = await axiosGetService(
				`api/sdmproceduremaster/getartifactgrid?artifactId=${
					selectedArificatType.id
				}&topicId=${selectedProcedure.id}&procedureTypeId=${
					selectedProcedure.projectTypeId === 1 ? 2 : 3
				}`
			);
			setSelectedArtifactData(response.data[0].artifactData);
			setShowArtifactSpinner(false);
		}
	};

	const toggleArtifact = (artifactIndex) => {
		const newArtifactData = [...selectedArtifactData];
		newArtifactData[artifactIndex].isSelected =
			!newArtifactData[artifactIndex].isSelected;
		setSelectedArtifactData(newArtifactData);
	};

	const saveMappingData = async () => {
		const postBody = {
			topicId: selectedProcedure.id,
			procedureType: selectedProcedure.projectTypeId === 1 ? 2 : 3,
			artifactTypeId: selectedArificatType.artifactTypeId,
			artifactIds: selectedArtifactData
				.reduce((selectedArificatIds, { isSelected, id }) => {
					if (isSelected) {
						return [...selectedArificatIds, id];
					} else {
						return selectedArificatIds;
					}
				}, [])
				.toString(),
		};
		setShowSpinner(true);
		const response = await axiosPostService(
			`api/sdmproceduremaster/updateproceduremappings`,
			postBody
		);
		setShowSpinner(false);
		if (response.data.status) {
			toast.info('Succesfully Updated Procedure Mappings', {
				position: 'top-right',
			});
		} else {
			toast.error(response.data.errMsg, { position: 'top-right' });
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(getProcedureCategories, 500);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);
	useEffect(() => {
		const timeOutId = setTimeout(getProcedures, 500);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProcedureCategory, procedureSerachValue]);
	useEffect(() => {
		const timeOutId = setTimeout(getArtifacts, 500);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedArificatType]);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageProceduresArtifacts"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Job Aids Artifacts
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Edit Job Aids Link</span>
									</li>
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div className="methodOwnerMiddleContainer">
									<div className="methodPhaseDetailsTab">
										{showSpinner ? (
											<Spinner />
										) : (
											<div className="manageProceduresContainer">
												<div className="manageProceduresFiltersContainer">
													<div className="cloneActivityButtonAction">
														<h6>Select Job Aids</h6>
														<div className="cloneActivityFieldsWrapper showGridFiltersToggle">
															<Icon iconName="Search" aria-hidden="true" />
															<Icon
																iconName="FilterSolid"
																aria-hidden="true"
																className="root-47"
															/>
															<Icon
																iconName={
																	expandFilter ? 'ChevronUp' : 'ChevronDown'
																}
																aria-hidden="true"
																onClick={() => setExpandFilter(!expandFilter)}
																className="cloneDropDownArrow root-41"
															/>
														</div>
														{expandFilter && (
															<div className="addActivityFields">
																<div className="cloneActivityContainer"></div>
																<div className="cloneActivityFiltersWrapper">
																	<div>
																		<div className="cloneActivitySolTypeFilters">
																			<div className='hidingConsultingSupport'>
																				<Dropdown
																					id="projectTypes"
																					className="activityReferProjectSelectionDropdown"
																					data={projectTypeData}
																					value={selectedProjectType}
																					setValue={setSelectedProjectType}
																					label="Select Project Type"
																					enableDropdown={true}
																					disableNone={true}
																				/>
																			</div>
																			<div className="multiSelectdropDown CloseMultiSelectDp">
																				<Dropdown
																					id="procedureCategories"
																					className="activityReferProjectSelectionDropdown"
																					data={procedureCategories}
																					value={selectedProcedureCategory}
																					setValue={
																						setSelectedProcedureCategory
																					}
																					label="Select Job Aids Categories"
																					enableDropdown={true}
																					disableNone={true}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="cloneActivityFieldsWrapper raciGridSearchBox">
																		<input
																			placeholder="Search Job Aids"
																			value={procedureSerachValue}
																			onChange={(e) => {
																				setProcedureSerachValue(e.target.value);
																			}}
																		/>
																		<div className="resultsCountOfGrid"></div>
																	</div>
																	<div className="cloneActivityResultsWrapper selectProcedure">
																		<div className="selectedProcedure">
																			<h6>Selected Job Aids</h6>
																			<span>
																				{selectedProcedure
																					? selectedProcedure.name
																					: 'None'}
																			</span>
																		</div>
																		<h6>{procedures.length} Result(s) Found</h6>
																	</div>
																</div>
															</div>
														)}
													</div>
													{selectedProcedureCategory && (
														<div className="cloneActivityResultsWrapper resultsProcedures">
															<ul>
																{procedures.map(
																	(procedure, index) =>
																		(index < 10 || showMore) && (
																			<li
																				key={procedure.id}
																				onClick={() => {
																					selectProcedure(procedure);
																				}}
																				className={
																					selectedProcedure?.id === procedure.id
																						? 'selectedProcedure'
																						: ''
																				}
																			>
																				<span className="cloneActivityTitle">
																					{procedure.name}
																				</span>
																				<div className="cloneInfiActivity">
																					<Icon
																						iconName="Info"
																						aria-hidden="true"
																						className="root-41"
																					/>
																					<div className="tabsTitleToolTip">
																						<span className="tabsToolTipArrow"></span>
																						<h5>Job Aids Details</h5>
																						<h6>{procedure.projectTypeName}</h6>
																						<span className="detailsOfCloneActivity">
																							<span className="detailsCloneActivitySol">
																								<span>Job Aids Category</span>
																								:&nbsp;
																								<span>
																									{
																										selectedProcedureCategory.name
																									}
																								</span>
																							</span>
																						</span>
																					</div>
																				</div>
																			</li>
																		)
																)}
															</ul>
															{procedures.length > 10 && (
																<span
																	className="closeShowMoreLess"
																	onClick={() => setShowMore(!showMore)}
																	aria-label={
																		showMore
																			? 'Click to Show less Procedures'
																			: 'Click to Show less Procedures'
																	}
																	role="Link"
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setShowMore(!showMore);
																		}
																	}}
																	tabIndex={0}
																>
																	{showMore ? ' Show less ' : 'Show More'}
																</span>
															)}
														</div>
													)}
												</div>
												<div className="manageProceduresEntityLinkContainer">
													<div className="toggleFiltersListContainer">
														<h6>Select entity to be linked</h6>
														<div className="manageProceduresEntityLinkedContainer">
															<Dropdown
																id="arifacts"
																className="activityReferProjectSelectionDropdown"
																data={artifactTypes}
																value={selectedArificatType}
																setValue={setSelectedArificatType}
																label="Select For Entity"
																enableDropdown={selectedProcedure}
																disableNone={true}
															/>
															<div>
																<input
																	value={entitySerachValue}
																	onChange={(e) =>
																		setEntitySerachValue(e.target.value)
																	}
																	placeholder="Search by name"
																	disabled={
																		selectedArtifactData?.length === 0 ||
																		showArtifactSpinner
																	}
																/>
															</div>
														</div>
														{showArtifactSpinner ? (
															<Spinner />
														) : (
															selectedProcedure && (
																<ul className="phaseSwimlaneHistory">
																	{selectedArtifactData?.map(
																		(artifact, index) =>
																			artifact.name
																				.toLocaleLowerCase()
																				.includes(
																					entitySerachValue.toLocaleLowerCase()
																				) && (
																				<li
																					key={artifact.id}
																					className={
																						artifact.id ===
																						selectedProcedure?.id
																							? 'selectedProcedure'
																							: ''
																					}
																				>
																					<label className="NotificationChkboxContainer">
																						<input
																							name="Support"
																							type="checkbox"
																							checked={artifact.isSelected}
																						/>
																						<span
																							className="CustomCheckmark"
																							onClick={() =>
																								toggleArtifact(index)
																							}
																						></span>
																						<span className="complexityLabel">
																							{artifact.name}
																						</span>
																					</label>
																				</li>
																			)
																	)}
																</ul>
															)
														)}
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="saveContinueDetailsContainer">
								<button
									disabled={!(selectedProcedure || selectedArificatType)}
									className="forwardButton"
									onClick={saveMappingData}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveMappingData();
										}
									}}
								>
									<span className="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageProceduresArtifacts;
