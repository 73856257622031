import React, { useState, useContext, createContext, useEffect } from 'react';
import urlContains from '../config/index';

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(() => {
    const domain = window.location.hostname;
    const config = urlContains(domain);
    return config;
  });

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

const useConfig = () => useContext(ConfigContext);

export { ConfigProvider, useConfig };
