import React, { useState } from 'react';
import { Icon } from '@fluentui/react';

import UserSelectionDropdown from '../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown';
import OwnerSelectionDropdown from '../TopicsBuilder/OwnerSelectionDropdown';
import Dropdown from '../Dropdown';

// import TopicCategorySequence from './Sequence';

import TopicCategorySequence from './Grid/Sequence';

const Form = ({
	categoryName,
	setCategoryName,
	categoryType,
	setCategoryType,
	procedureType,
	setProcedureType,
	owner,
	setOwner,
	coOwners,
	setCoOwners,
	reviewers,
	setReviewers,
	approvers,
	setApprovers,
	ownerEmail,
	setOwnerEmail,
	coOwnerEmail,
	setCoOwnerEmail,
	reviewerEmail,
	setReviewerEmail,
	approverEmail,
	setApproverEmail,
	emailFound,
	setEmailFound,
	usersList,
	setUsersList,
	error,
	setError,
	removeCoOwner,
	consultingTopics,
	isVisible,
	setIsVisible,
	sequence,
	setSequence,
	categoryData,
}) => {
	const handleApproverState = (owner) => {
		// Check if user already exists in approvers list
		if (!approvers.some((item) => item.mail === owner.mail)) {
		  setApprovers((prevApprovers) => [...prevApprovers, owner]);
		}
	  };
	return (
		<div class="methodOwnerMiddleContainer">
			<div className="topicCategorySequenceContainer">
				<div className="topicCategoryContainer">
					<div className="addRoleNameContainer topicCategoryName">
						<span className="AddRoleFieldLabel">Category Name *</span>
						<div>
							<input
								placeholder="Enter Category Name"
								value={categoryName}
								onChange={(e) => {
									setCategoryName(e.target.value);
								}}
								disabled={categoryName === 'Portfolio Management'}
							/>
							<label className="NotificationChkboxContainer">
								<input
									type="checkbox"
									checked={isVisible}
									onChange={() => {
										setIsVisible(!isVisible);
									}}
									aria-label="check this checkbox to visible in topic category"
								/>
								<span className="CustomCheckmark"></span>
								<span className="complexityLabel">Is Visible</span>
							</label>
						</div>
					</div>

					<div className='hidingConsultingSupport'>
						<span className="AddRoleFieldLabel">Category Type *</span>
						<Dropdown
							className="topicCategoryType"
							data={categoryData.categoryTypes}
							setValue={setCategoryType}
							value={categoryType}
							enableDropdown={true}
						/>
					</div>
					<div className="addRoleNameContainer">
						<span className="AddRoleFieldLabel">Procedure Type</span>
						<Dropdown
							className="topicCategoryProcedureType"
							data={categoryData.procedureTypes.map((item) => {
								return {
									id: item.id,
									name: item.title,
								};
							})}
							setValue={setProcedureType}
							value={procedureType}
							enableDropdown={true}
						/>
					</div>
					<div className="addRoleNameContainer">
						<span className="AddRoleFieldLabel">Add Owner  <span className="red-asterisk">*</span></span>
						<OwnerSelectionDropdown
							className="topicCategoryOwner"
							enableDropdown={true}
							data={usersList}
							value={owner}
							setValue={setOwner}
							updateApprovers={handleApproverState}
							type="categoryOwner"
							searchValue={ownerEmail}
							setSearchValue={setOwnerEmail}
							error={error}
							emailFound={emailFound['categoryOwner']}
						/>
						{owner.mail && (
							<ul className={'afterSaveListTopic collaboratorsApprovers'}>
								<li key={owner.mail}>
									<span className="collabaratorPersonaImage">
										<img src={owner.photo} alt="img" />
									</span>
									<span className="collabaratorPersonaTitle">
										{owner.firstName + ' ' + owner.surname}
									</span>
									<span>
										<Icon
											iconName="Cancel"
											onClick={() => {
												setOwner({});
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setOwner({});
												}
											}}
											role="button"
											tabIndex={0}
											aria-label={`Remove ${owner.firstName} ${owner.surname}`}
											title="Remove Owner"
										/>
									</span>
								</li>
							</ul>
						)}
					</div>
					<div className="addRoleNameContainer">
						<span className="AddRoleFieldLabel">Add Co-owner  <span className="red-asterisk">*</span></span>
						<UserSelectionDropdown
							className="coOwner"
							enableDropdown={true}
							data={usersList}
							value={coOwners}
							setValue={setCoOwners}
							type="coOwner"
							searchValue={coOwnerEmail}
							setSearchValue={setCoOwnerEmail}
							error={error}
							emailFound={emailFound['coOwner']}
						/>
						{coOwners.length > 0 && (
							<div className="collabaratorsChildContainers">
								<ul>
									{coOwners.map((user) => (
										<li key={user.mail}>
											<span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
											<span className="collabaratorPersonaTitle">
												{user.firstName + ' ' + user.surname}
											</span>
											<span>
												<Icon
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, coOwners, setCoOwners);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, coOwners, setCoOwners);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove CoOwner"
												/>
											</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<div className="addRoleNameContainer">
						<span className="AddRoleFieldLabel">Add Approvers <span className="red-asterisk">*</span></span>
						<UserSelectionDropdown
							className="approver"
							enableDropdown={true}
							data={usersList}
							value={approvers}
							setValue={setApprovers}
							type="approver"
							searchValue={approverEmail}
							setSearchValue={setApproverEmail}
							error={error}
							emailFound={emailFound['approver']}
						/>
						{approvers.length > 0 && (
							<div className="collabaratorsChildContainers">
								<ul>
									{approvers.map((user) => (
										<li key={user.mail}>
											<span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
											<span className="collabaratorPersonaTitle">
												{user.firstName + ' ' + user.surname}
											</span>
											<span>
												<Icon
												 hidden={(owner.mail === user.mail)}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, approvers, setApprovers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, approvers, setApprovers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove approver"
												/>
											</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<div className="addRoleNameContainer">
						<span className="AddRoleFieldLabel">Add Reviewers <span className="red-asterisk">*</span></span>
						<UserSelectionDropdown
							className="reviewer"
							enableDropdown={true}
							data={usersList}
							value={reviewers}
							setValue={setReviewers}
							type="reviewer"
							searchValue={reviewerEmail}
							setSearchValue={setReviewerEmail}
							error={error}
							emailFound={emailFound['reviewer']}
						/>
						{reviewers.length > 0 && (
							<div className="collabaratorsChildContainers">
								<ul>
									{reviewers.map((user) => (
										<li key={user.mail}>
											<span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
											<span className="collabaratorPersonaTitle">
												{user.firstName + ' ' + user.surname}
											</span>
											<span>
												<Icon
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, reviewers, setReviewers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, reviewers, setReviewers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove reviewer"
												/>
											</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
				<TopicCategorySequence sequence={sequence} setSequence={setSequence} />
			</div>
		</div>
	);
};

export default Form;
