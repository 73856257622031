import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';

import '../Dropdown/Dropdown.css';

function Dropdown({
	id,
	className,
	data,
	setValue,
	value,
	enableDropdown,
	label,
	disableNone,
	required,
}) {
	const { track } = useAppInsights();
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(id);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const getSelectedItemName = () => {
		return value?.name ?? label;
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const selectedValue = item;
		if (value?.id !== selectedValue.id) {
			setValue(selectedValue);
		}
		setVisible(!visible);

		track(1, 9, id, selectedValue);
	};

	return (
		<div title={getSelectedItemName()} id={id}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">{`${getSelectedItemName()} ${
						required ? '*' : ''
					}`}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{disableNone || (
								<li
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleDropdown(null);
										}
									}}
									onClick={() => handleDropdown(null)}
									title="None"
									key="None"
								>
									<span>None</span>
								</li>
							)}
							{data.map((item) => (
								<li
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleDropdown(item);
										}
									}}
									onClick={() => handleDropdown(item)}
									title={item.name}
									key={item.id}
								>
									<span>{item.name}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
