// Convert hex string to ArrayBuffer
export const hexToArrayBuffer = (hex) => {
    const bytes = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    return bytes.buffer;
  };
  
  // Convert base64 string to ArrayBuffer
  export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };
  
  // Convert ArrayBuffer to string
  export const arrayBufferToString = (buffer) => {
    const decoder = new TextDecoder();
    return decoder.decode(buffer);
  };
  
  // Convert string to ArrayBuffer
  export const stringToArrayBuffer = (str) => {
    const encoder = new TextEncoder();
    return encoder.encode(str).buffer;
  };
  
  // Convert ArrayBuffer to base64
  export const arrayBufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  