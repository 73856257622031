import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { MessageBar, MessageBarType } from '@fluentui/react';
import $ from 'jquery';
import dompurify from 'dompurify';
import Dropdown from '../../../components/Dropdown';
import FileDropModal from '../../../components/TemplateCard/FileDropModal';
import PreviewModal from '../../../components/TemplateCard/PreviewModal';
import SampleTemplateModal from '../../../components/TemplateCard/SampleTemplateModal';
import { useAxios } from '../../../context/AxiosContext';
import { useAuth } from '../../../context/UserAuthContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import Spinner from '../../../helpers/Spinner';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../context/TelemetryContext';
import ActivityTemplateHisotry from '../../ActivityTemplateHisotry';
import ActivityRaciMatrix from '../../ActivityRaciMatrix';
import EntryCriteriaMyContributions from '../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/EntryCriteriaMyContributions';
import ExitCriteriaMyContributions from "../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/ExitCriteriaMyContributions";
import TaskMyContributions from "../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/TaskMyContributions";
import './ActivityHistory.css';
import TemporaryCkAlert from '../../CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../CkEditor/DecryptionComponent';
import { useConfig } from '../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/MyContributions/DescriptionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const SupportAndActivitiesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/MyContributions/SupportingActivitiesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const TipsAndTechniquesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/MyContributions/TipsAndTechniquesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const DeliverablesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/MyContributions/DeliverablesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

const ReviewPreview = ({
	contributorsData,
	reviewerInfo,
	setReviewerInfo,
	enableTemplatePreview,
	setEnableTemplatePreview,
}) => {
	const { ckEdKEY } = useConfig();
	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();
	const htmlSanitizer = dompurify.sanitize;
	const { setSkipNavigationId } = useLeftMenuCollapse();
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const { user } = useAuth();
	const currentUser = user.profile.mail;
	const { track } = useAppInsights();
	const { artifactType, id, artifactId, stateId } = useParams();
	const { axiosGetService } = useAxios();
	const complexityId = Number(
		queryString.parse(window.location.search).complexity
	);
	const [licenseKey, setLicenseKey] = useState(null);
	const [activityContent, setActivityContent] = useState(null);
	const [ckEditorUserMap, setCkEditorUserMap] = useState({});
	const [
		selectedToolsAndGuidanceSuggestions,
		setSelectedToolsAndGuidanceSuggestions,
	] = useState([]);
	const [
		contributiorToolsAndGuidanceSuggestion,
		setContributiorToolsAndGuidanceSuggestion,
	] = useState([]);
	const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
		useState([]);
	const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);

	const [contributiorToolsId, setContributiorToolsId] = useState([]);
	const [toolsReviewedIds, setToolsReviewedIds] = useState({});
	const [toolsUrl, setToolsUrl] = useState({});
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');

	// template Preview Data
	const [templateData, setTemplateData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [procedureData, setProcedureData] = useState([]);
	const [complexities, setComplexities] = useState([]);
	const [complexityName, setComplexityName] = useState({
		name: '',
		type: 'option',
		id: '',
	});
	const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');
	const [entryCriteriaData, setEntryCriteriaData] = useState([]);
	const [exitCriteriaData, setExitCriteriaData] = useState([]);
	const [taskData, setTaskData] = useState([]);
	const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry-hist');
	const [currentExitTab, setCurrentExitTab] = useState('tab-exit-hist');

	const getEntryCriteriaData = async (data) => {
		let contributedEntryCriteria = [];
		if (data.resultCalloutJson && data.resultCalloutJson.entryCriteriaSuggestions) {
			contributedEntryCriteria = [...data.resultCalloutJson.entryCriteriaSuggestions];
		}
		const filteredEntryCriteria = data.entryCriteriaList.filter(
			({ id }) =>
				!contributedEntryCriteria.some((item) => JSON.parse(item).id === id)
		);
		setEntryCriteriaData(filteredEntryCriteria);

	};
	const getExitCriteriaData = async (data) => {
		let contributedExitCriteria = [];
		if (data.resultCalloutJson && data.resultCalloutJson.exitCriteriaSuggestions) {
			contributedExitCriteria = [...data.resultCalloutJson.exitCriteriaSuggestions];
		}
		const filteredExitCriteria = data.exitCriteriaList.filter(
			({ id }) =>
				!contributedExitCriteria.some((item) => JSON.parse(item).id === id)
		);
		setExitCriteriaData(filteredExitCriteria);
	};

	const getTaskData = async (data) => {
		let contributedTask = [];
		if (data.resultCalloutJson && data.resultCalloutJson.taskSuggestions) {
			contributedTask = [...data.resultCalloutJson.taskSuggestions];
		}
		const filteredTask = data.taskList.filter(
			({ id }) =>
				!contributedTask.some((item) => JSON.parse(item).id === id)
		);
		setTaskData(filteredTask);

	};
	const getTemplatesInfo = (data) => {
		let contributedTemplates = [];
		if (data.resultCalloutJson && data.resultCalloutJson.templateSuggestions) {
			contributedTemplates = [...data.resultCalloutJson.templateSuggestions];
		}
		const filteredTemplates = data.activityTemplateDetails.filter(
			({ id }) =>
				!contributedTemplates.some((item) => JSON.parse(item).id === id)
		);
		setTemplateData(filteredTemplates);
	};

	const getActivityContents = () => {
		if (contributorsData.activityContent) {
			setActivityContent(JSON.parse(contributorsData.activityContent));
		}
		const mappedComplexities = [
			{
				name: contributorsData.complexityName,
				id: contributorsData.complexityId,
				option: 'type',
			},
		];
		setComplexityName({
			name: contributorsData.complexityName,
			id: contributorsData.complexityId,
			type: 'option',
		});
		getTemplatesInfo(contributorsData);
		setTopicData(contributorsData.activityTopics);
		setProcedureData(contributorsData.activityProcedureType);
		setComplexities(mappedComplexities);
		const contributorToolsData = JSON.parse(contributorsData.activityContent)
			.ToolsAndGuidance
			? JSON.parse(contributorsData.activityContent).ToolsAndGuidance.split(',')
			: [];
		setContributiorToolsAndGuidanceSuggestion(contributorToolsData);
		const contributionsToolsId = [];
		if (
			contributorsData.resultCalloutJson &&
			contributorsData.resultCalloutJson.toolsAndGuidance
		) {
			setSelectedToolsAndGuidanceSuggestions(
				contributorsData.resultCalloutJson.toolsAndGuidance
			);
			const toolsSuggestions =
				contributorsData.resultCalloutJson.toolsAndGuidance;
			const reviewedData = {};
			for (const id in toolsSuggestions) {
				contributionsToolsId.push(JSON.parse(toolsSuggestions[id]).toolsId);
				reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
					toolsSuggestions[id]
				).state;
			}
			setToolsReviewedIds(reviewedData);
		}
		if (
			contributorsData.resultReviewedJson &&
			contributorsData.resultReviewedJson.approvedToolsSuggestions
		) {
			const toolsSuggestions =
				contributorsData.resultReviewedJson.approvedToolsSuggestions;
			const reviewedData = {};
			for (const id in toolsSuggestions) {
				reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
					toolsSuggestions[id]
				).state;
			}
			setToolsReviewedIds(reviewedData);
		}

		setContributiorToolsId(contributionsToolsId);
		const url = {};
		for (const id in contributorsData.activityToolsList) {
			if (
				contributionsToolsId.includes(contributorsData.activityToolsList[id].id)
			) {
				contributorsData.activityToolsList[id].isSelected = true;
			}
			url[contributorsData.activityToolsList[id].id] =
				contributorsData.activityToolsList[id].url;
		}
		setToolsUrl(url);
		setToolsAndGuidanceSuggestions(contributorsData.activityToolsList);
		getEntryCriteriaData(contributorsData);
		getExitCriteriaData(contributorsData);
		getTaskData(contributorsData);
	};

	const getContributor = (suggestions) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		if (contributorsData.resultCalloutJson) {
			getContributor(contributorsData.resultCalloutJson.description);
			getContributor(contributorsData.resultCalloutJson.supportingActivities);
			getContributor(contributorsData.resultCalloutJson.toolsAndGuidance);
			getContributor(contributorsData.resultCalloutJson.tipsAndTechniques);
			getContributor(contributorsData.resultCalloutJson.templateSuggestions);
			getContributor(contributorsData.resultCalloutJson.entryCriteriaSuggestions);
			getContributor(contributorsData.resultCalloutJson.exitCriteriaSuggestions);
			getContributor(contributorsData.resultCalloutJson.taskSuggestions);
			getContributor(contributorsData.resultCalloutJson.deliverables);
		}

		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		const userProfileMapping = {};
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
			userProfileMapping[userData.mail] = [
				userData.firstName + ' ' + userData.surname,
				userData.photo,
			];
		}

		setCkEditorUserMap(userProfileMapping);
		setCkEditorUsers(contributorInfo);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	const getToolsConatinerHeight = () => {
		const height = $('.ToolsContentContainer').height();
		setToolsSidebarHeight(height + 63);
	};

	const getTemplateSidebarHeight = () => {
		const height = $('.templateContentContainer').height();
		setTemplateSidebarHeight(height + 63);
	};

	useEffect(() => {
		getToolsConatinerHeight();
	});

	useEffect(() => {
		getTemplateSidebarHeight();
	});

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		if (contributorsData) {
			getUsersInfo();
		}
	}, []);

	useEffect(() => {
		if (contributorsData) {
			getActivityContents();
		}
	}, []);

	if (!contributorsData) {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				There were no contribution from reviewer.
			</MessageBar>
		);
	}

	if (
		!activityContent ||
		ckEditorUsers.length < 1 ||
		!contributorsData ||
		!licenseKey
	) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}
	return (
		<div className="historicalPreview">
			<div className="activityLandinPage">
				<div className="activityMainContainer">
					<div className="activityDescriptionDetails">
						<div className="descOfActivity historyActivity">
							<h4
								title={contributorsData.activityName}
								className="activityLandingPageTitle"
							>
								{contributorsData.activityName}
							</h4>
							<ActivityRaciMatrix activityData={contributorsData} />
							<div className="innerContent" id="innerContent">
								{activityContent.Description ? (
									<>
										<h6 className="sub-heading" id="desc">
											Description
										</h6>
										<TemporaryCkAlert width={'77'} />

										<DescriptionCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.Description}
											initalSuggestions={
												contributorsData.resultCalloutJson.description
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedDescSuggestions
													: null
											}
											licenseKey={licenseKey}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments.description
													? contributorsData.resultApproverComments.description
													: []
											}
											stateId={1}
											previewType="contribution"
										/>
									</>
								) : null}

								{activityContent.SupportingActivities ? (
									<>
										<h6 className="sub-heading" id="supportAct">
											Supporting Activities
										</h6>
										<TemporaryCkAlert width={'77'}/>

										<SupportAndActivitiesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.SupportingActivities}
											initalSuggestions={
												contributorsData.resultCalloutJson.supportingActivities
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedSupportSuggestions
													: null
											}
											licenseKey={licenseKey}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments
														.supportingActivities
													? contributorsData.resultApproverComments
														.supportingActivities
													: []
											}
											stateId={1}
											previewType="contribution"
										/>
									</>
								) : null}

								{entryCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.entryCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Entry Criteria/Input</span>
										</h2>
										<EntryCriteriaMyContributions
											criteriaData={entryCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.entryCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.entryCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedEntryCriteriaSuggestions
													: []
											}
											criteria="entry"
											currentTab={currentEntryTab}
											setCurrentTab={setCurrentEntryTab}
										/>
									</div>
								) : null}

								{taskData.length > 0 || (
									contributorsData.resultCalloutJson.taskSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Task</span>
										</h2>
										<TaskMyContributions
											taskData={taskData}
											toolsSidebarHeight={templateSidebarHeight}
											taskSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.taskSuggestions
													? contributorsData.resultCalloutJson
														.taskSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedTaskSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedTaskSuggestions
													: []
											}
										/>
									</div>
								) : null}

								{exitCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.exitCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Exit Criteria/Output</span>
										</h2>
										<ExitCriteriaMyContributions
											criteriaData={exitCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.exitCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.exitCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedExitCriteriaSuggestions
													: []
											}
											criteria="exit"
											currentTab={currentExitTab}
											setCurrentTab={setCurrentExitTab}
										/>
									</div>
								) : null}


								{procedureData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Procedures
											<div className="nonEditableSection">
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{procedureData.length > 0 ? (
											<div id="activityProcedure">
												<ul>
													{procedureData.map((procedure) => (
														<li>
															{
																<a href={procedure.url} target="_blank">
																	{procedure.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}
								{topicData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Related Topics
											<div className="nonEditableSection">
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{topicData.length > 0 ? (
											<div id="topicList">
												<ul>
													{topicData.map((topic) => (
														<li>
															<a href={topic.url} target="_blank">
																{topic.name}
															</a>
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}


								<div className="addingTemplatesEditAcivity">
								<TemporaryCkAlert width={'77'}/>

									<h2 className="sub-heading" id="deliver">
										<span>Deliverables</span>
									</h2>
									<ActivityTemplateHisotry
										templateData={templateData}
										toolsSidebarHeight={templateSidebarHeight}
										templateSuggestions={
											contributorsData.resultCalloutJson &&
												contributorsData.resultCalloutJson.templateSuggestions
												? contributorsData.resultCalloutJson.templateSuggestions
												: []
										}
										ckEditorUserMap={ckEditorUserMap}
										reviewedTemplateSuggestions={
											contributorsData.resultReviewedJson
												? contributorsData.resultReviewedJson
													.approvedTemplateSuggestions
												: []
										}
										enableTemplatePreview={enableTemplatePreview}
										setEnableTemplatePreview={setEnableTemplatePreview}
									/>
									{/* {contributorsData.activityDeliverables ? (
										<div
											id="activityDeliverables"
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(
													contributorsData.activityDeliverables
												),
											}}
										/>
									) : null} */}
									{activityContent.Deliverables ? (
									<>
									<div className='deliverables-container-hp'>
										 <h2 className="sub-heading" id="desc" aria-label='Description'>
											Description
										</h2> 
										<TemporaryCkAlert width={'77'} />

										<DeliverablesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.Deliverables}
											initalSuggestions={
												contributorsData.resultCalloutJson.deliverables
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedDeliverablesSuggestions
													: null
											}
											licenseKey={licenseKey}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments.deliverables
													? contributorsData.resultApproverComments.deliverables
													: []
											}
											stateId={1}
											previewType="contribution"
										/>
										</div>
									</>
								) : null}
								</div>

								<h2 className="sub-heading" id="tool">
									Tools
								</h2>
								<div className="customToolsEditor">
									<div className="toolsEditorMainContainer">
										<div className="ToolsContentContainer">
											<ul>
												{contributorsData.activityToolsList.map((tools) => (
													<>
														{(tools.isSelected &&
															!contributiorToolsId.includes(tools.id) &&
															contributiorToolsAndGuidanceSuggestion.includes(
																tools.id.toString()
															)) ||
															(!contributiorToolsId.includes(tools.id) &&
																contributiorToolsAndGuidanceSuggestion.includes(
																	tools.id.toString()
																)) ? (
															<li>
																<a href={tools.url} target="_blank">
																	{tools.name}
																</a>
															</li>
														) : (
															<></>
														)}
													</>
												))}
												{selectedToolsAndGuidanceSuggestions.map((item) => (
													<>
														{!adminToolsAndGuidance.includes(
															JSON.parse(item).toolsId
														) ? (
															<li
																className={
																	JSON.parse(item).suggestionType === 'deletion'
																		? 'userDeletedToolsAndGuidance'
																		: 'userSelectedToolsAndGuidance'
																}
															>
																<div className={'userAddedItem'}>
																	<a href={toolsUrl[JSON.parse(item).toolsId]}>
																		{JSON.parse(item).name}
																	</a>
																</div>
															</li>
														) : (
															<li
																className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType === 'deletion'
																	? 'userDeletedToolsAndGuidance'
																	: 'none'
																	}`}
															>
																<a
																	href={toolsUrl[JSON.parse(item).toolsId]}
																	target="_blank"
																>
																	{JSON.parse(item).name}
																</a>

																{JSON.parse(item).suggestionType ===
																	'deletion' ? (
																	<div className="sideBarCard">
																		<div className="sideBarCardHeader">
																			<img
																				className="userImg"
																				src={
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][1]
																				}
																				alt=""
																			/>
																			<span>
																				{
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][0]
																				}
																			</span>
																		</div>
																		<div className="itemStatus">
																			<span>Remove</span>&nbsp;
																			{JSON.parse(item).name}
																		</div>
																	</div>
																) : (
																	<></>
																)}
															</li>
														)}
													</>
												))}
											</ul>
										</div>
									</div>
									<div
										className="toolsSidebar"
										style={{ height: toolsSidebarHeight }}
									>
										{selectedToolsAndGuidanceSuggestions.map((item) => (
											<>
												{toolsReviewedIds[JSON.parse(item).id] ===
													'accepted' ? (
													<div
														className={`sideBarCard acceptedSideBarCard ${JSON.parse(item).suggestionType === 'insertion'
															? 'userAddedItem'
															: 'userDeletedToolsAndGuidance'
															}`}
													>
														<div className="sideBarCardHeader">
															<img
																className="userImg"
																src={
																	ckEditorUserMap[JSON.parse(item).authorId][1]
																}
																alt=""
															/>
															<span>
																{ckEditorUserMap[JSON.parse(item).authorId][0]}
															</span>
															<span className="suggestionStatus">Approved</span>
														</div>
														<div className="itemStatus">
															<span>
																{JSON.parse(item).suggestionType === 'insertion'
																	? 'Add'
																	: 'Remove'}
															</span>
															<span>&nbsp;"{JSON.parse(item).name}"</span>
														</div>
													</div>
												) : (
													<div
														className={`sideBarCard ${toolsReviewedIds[JSON.parse(item).id] ===
															'rejected'
															? 'rejectedSideBarCard'
															: ''
															} ${JSON.parse(item).suggestionType === 'insertion'
																? 'userAddedItem'
																: 'userDeletedToolsAndGuidance'
															}`}
													>
														<div className="sideBarCardHeader">
															<img
																className="userImg"
																src={
																	ckEditorUserMap[JSON.parse(item).authorId][1]
																}
																alt=""
															/>
															<span>
																{ckEditorUserMap[JSON.parse(item).authorId][0]}
															</span>
															<span className="suggestionStatus myContributionSuggestionStatus">{`${toolsReviewedIds[JSON.parse(item).id] ===
																'rejected'
																? 'Rejected'
																: ''
																}`}</span>

															<div className="itemStatus">
																<span>
																	{JSON.parse(item).suggestionType ===
																		'insertion'
																		? 'Add'
																		: 'Remove'}
																</span>
																<span>&nbsp;"{JSON.parse(item).name}"</span>
															</div>
														</div>
													</div>
												)}
											</>
										))}
									</div>
								</div>

								{activityContent.TipsAndTechniques ? (
									<>
										<h6 className="sub-heading" id="tipstech">
											Guidance, Tips and Techniques
										</h6>
										<TemporaryCkAlert width={'77'} />

										<TipsAndTechniquesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.TipsAndTechniques}
											initalSuggestions={
												contributorsData.resultCalloutJson.tipsAndTechniques
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedTipsSuggestions
													: null
											}
											licenseKey={licenseKey}
											stateId={1}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments
														.tipsAndTechniques
													? contributorsData.resultApproverComments
														.tipsAndTechniques
													: []
											}
											previewType="contribution"
										/>
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewPreview;
