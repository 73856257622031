import { PublicClientApplication } from '@azure/msal-browser';
import { useConfig } from './ConfigContext';
import { MsalProvider } from '@azure/msal-react';

const MsalInstanceProvider = ({ children }) => {
	const { clientId, authority, graphRedirectUri } = useConfig();
	const config = {
		auth: {
			clientId,
			authority,
			redirectUri: graphRedirectUri,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: true,
		},
	};
	const instance = new PublicClientApplication(config);
	return <MsalProvider instance={instance}>{children}</MsalProvider>;
};

export default MsalInstanceProvider;
