import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';
import MultiSelectDropdown from './MultiSelectDropdown';
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import Spinner from '../../../helpers/Spinner';
import { toast } from 'react-toastify';

const SolutionMethodRefer = ({
	setShowSolutionMethodReferForm,
	activityGroups,
	setActivityGroups,
	projectTypeId,
	solutionMethodId,
	phaseId,
	setDisableSaveAndContinue,
	phaseType,
	parentId,
	complexityId,
	type,
}) => {
	const { parentPhaseId } = useParams();
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];

	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const { complexity } = useLeftMenuCollapse();
	const [showSpinner, setShowSpinner] = useState(true);

	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([
		{
			id: Number(projectTypeId),
			name: Number(projectTypeId) == 1 ? 'Consulting' : 'Support',
		},
	]);

	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);
	const [apiCalled, setApiCalled] = useState(false);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data
				.map((item) => {
					return {
						id: item.solutionMethodId,
						name: item.solutionMethodName,
					};
				})
				.filter(({ id }) => Number(id) !== Number(solutionMethodId));
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
			closeSpinner();
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};

	const cloneGroup = (data) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		setActivityGroups(activityGroupClone);
		setShowSolutionMethodReferForm(false);
	};

	const referSolutionMethods = async () => {
		if (selectedSolutionMethods.length > 0) {
			setApiCalled(true);
			const postData = selectedSolutionMethods.map((item) => {
				return {
					solutionMethodId:
						type === 'parentPhase'
							? Number(parentId)
							: Number(solutionMethodId),
					phaseId: type === 'parentPhase' ? parentPhaseId : phaseId,
					childSolutionMethodId: item.id,
					complexityId: complexity.id,
					isRightSolutionMethod: type === 'parentPhase' ? true : false,
				};
			});
			await axiosPostService(
				`api/sdmsolutionmethod/addreferedsolutionmethod`,
				postData
			).then((response) => {
				if (response.data.status) {
					for (const solutionMethod of selectedSolutionMethods) {
						track(1, 1700, solutionMethod.id, solutionMethod.name);
					}
					cloneGroup(response.data.activityGroupsData);
					toast.info('You have successfully Linked the solution method(s)', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setDisableSaveAndContinue(false);
				setApiCalled(false);
			});
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			setShowSpinner(true);
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);
	return (
		<div className="addActivityFields">
			<div className="cloneActivityContainer">
				<div className="cloneActivityFieldsWrapper">
					<Icon iconName="CaretUpSolid8" className="cloneDropDownArrow" />
				</div>
			</div>
			<div className="cloneActivityFiltersWrapper">
				<div className="cloneActivitySolTypeFilters">
						<div className='hidingConsultingSupport'>
						<span>Consulting / Support</span>
						<MultiSelectDropdown
							className="activityReferProjectSelectionDropdown"
							data={projectTypeData}
							setValue={setSelectedProjectType}
							value={selectedProjectType}
							enableDropdown={true}
							label="Consulting/Support"
						/>
					</div>

					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Solution Methods</span>
						<MultiSelectDropdown
							className="activityReferSolutionMethodDropdown"
							data={solutionMethods}
							setValue={setSelectedSolutionMethods}
							value={selectedSolutionMethods}
							enableDropdown={true}
							label="Solution Methods"
						/>
					</div>
				</div>
			</div>
			<div className="cloneActivityResultsWrapper">
				{showSpinner ? (
					<Spinner />
				) : (
					<>
						<div className="AddActivityCloneButtonSection">
							<button
								className="AddActivityCloneButton"
								onClick={() => {
									referSolutionMethods();
								}}
								disabled={apiCalled}
							>
								Link Solution Method(s)
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SolutionMethodRefer;
