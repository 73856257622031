import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Spinner.css';

const Loading = () => (
	<div className="spinner">
		<Spinner animation="border" role="status" aria-label="Loading..." />
	</div>
);

export default Loading;
