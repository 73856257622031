import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function ProcessGroup({
	className,
	data,
	setValue,
	value,
	enableDropdown,
	processGroupMap,
	selectedComplexity,
	pageType = undefined,
}) {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (dropdown && !dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		setValue((prevState) => ({
			...prevState,
			processGroupId: item.id,
		}));
		setVisible(false);
	};
	return (
		<div
			title={
				enableDropdown ? value && processGroupMap[value.processGroupId] : ''
			}
			id={className}
		>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					role="listbox"
					aria-label="process group"
					aria-roledescription="dropdown"
					title={processGroupMap[value.processGroupId]}
					aria-expanded={visible ? true : false}
				>
					{value && processGroupMap[value.processGroupId] ? (
						<span
							className="wrapHeader"
							title={processGroupMap[value.processGroupId]}
						>
							{processGroupMap[value.processGroupId]}
						</span>
					) : (
						<span className="wrapHeader" title="Select process group">
							Select process group
						</span>
					)}
					<Icon iconName="ChevronDown"  style={{ fontWeight: pageType==='Activity' ? 'Bold':'' ,fontSize: pageType==='Activity' ? '12px':''}} />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className={`${pageType === 'Activity' ? 'dropDownList editprocessdropdown' : 'dropDownList'}`}
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.id}
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProcessGroup;
