import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';
import queryString from 'query-string';
import dompurify from 'dompurify';
import { Button } from 'react-bootstrap';
import { Prompt } from 'react-router';
import loadable from '@loadable/component';
import { MessageBar, MessageBarType } from '@fluentui/react';
import $ from 'jquery';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import Breadcrumbs from '../Breadcrumbs';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import Dropdown from '../Dropdown';
import MultiSelectDropdown from './MultiSelectDropdown';
import MultiSelectDropdownFluentReviewer from './MultiSelectDropdownFluentReviewer';
import DiscardPopUp from '../ActivityContributorCrowdSourcing/DiscardPopUp';
import PreviewAndSubmitPopUp from './PreviewAndSubmitPopUp';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../context/UserAuthContext';
import SuccessMessagePopUp from '../ActivityContributorCrowdSourcing/SuccessPopUp';
import Spinner from '../../helpers/Spinner';
import ReviewedPopUp from './ReviewedPopUp';
import FileDropModal from '../TemplateCard/FileDropModal';
import PreviewModal from '../TemplateCard/PreviewModal';
import SampleTemplateModal from '../TemplateCard/SampleTemplateModal';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import HistoryPreview from './HistoricalPreviewPopUp';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import UserNotVerified from './UserNotVerifiedPopUp';
import { useAppInsights } from '../../context/TelemetryContext';
import { Icon } from '@fluentui/react/lib/Icon';
import TemplateCrowdSourcing from '../ActivityContributorCrowdSourcing/TemplateContribution';
import TemplateBuilder from '../ActivityTemplateBuilder';
import { artifactMapData } from '../../static/ArtifactMapData';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import { TabTitle } from '../../utils/GeneralFunctions';
import EntryCriteriaContribution from '../ActivityContributorCrowdSourcing/EntryCriteriaContribution';
import ExitCriteriaContribution from '../ActivityContributorCrowdSourcing/ExitCriteriaContribution';
import TaskContribution from '../ActivityContributorCrowdSourcing/TaskContribution';
import '../Activity/Activity.css';
import '../ActivityContributorCrowdSourcing/ContributorActivityCrowdSourcing.css';
import './ReviewerActivityCrowdSourcing.css';
import TemporaryCkAlert from '../CkEditor/TemporaryCkAlert';
import ActivityTemplateBuilder from '../ActivityTemplateBuilder';
import decryptAES256 from '../CkEditor/DecryptionComponent';
import { useConfig } from '../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import('../CkEditor/ActivityReviewerTrackChanges/DescriptionTrackChanges'),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const SupportAndActivitiesCkEditor = loadable(
	() =>
		import(
			'../CkEditor/ActivityReviewerTrackChanges/SupportingActivitieTrackChanges'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const TipsAndTechniquesCkEditor = loadable(
	() =>
		import(
			'../CkEditor/ActivityReviewerTrackChanges/TipsAndTechniquesTrackChanges'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const DeliverableCkEditor = loadable(
	() =>
		import('../CkEditor/ActivityReviewerTrackChanges/DeliverableTrackChanges'),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
function ReviewerActivityCrowdSourcing({isEnableComplexity}) {
	const { ckEdKEY } = useConfig(); 
	const graphUserData = [];
	const userProfileMapping = {};
	const userDataMap = new Map();
	const history = useHistory();
	const htmlSanitizer = dompurify.sanitize;
	const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
		useLeftMenuCollapse();
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const { user } = useAuth();
	const CkEditorCurrentUser = user.profile.mail;
	const { track } = useAppInsights();
	const [messagesType, setMessageType] = useState({});
	const [reviewerVerified, setReviewerVerified] = useState(true);
	const [checkNotificationClick, setCheckNotificationClick] = useState(false);
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { showHistory, userLeaveWithoutSave } = crowdSourcingAppState;
	const [leavingWithoutSave, setLeavingWithoutSave] = useState(true);
	const {
		projectTypeId,
		solutionMethodId,
		phaseId,
		parentPhaseId,
		activityId,
	} = useParams();
	const { axiosGetService } = useAxios();
	const searchComplexityParam = Number(
		queryString.parse(window.location.search).complexity
	);
	const [licenseKey, setLicenseKey] = useState(null);
	const activityIdentifier = queryString.parse(
		window.location.search
	).identifier;
	const [activityContent, setactivityContent] = useState(null);
	const [toolsUrl, setToolsUrl] = useState({});
	const [templateData, setTemplateData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [procedureData, setProcedureData] = useState([]);
	const [complexities, setComplexities] = useState([]);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const complexityId = searchComplexityParam;
	const [complexityName, setComplexityName] = useState({
		name: '',
		type: 'option',
		id: '',
	});
	const [sampleTemplates, setSampleTemplates] = useState({
		enable: false,
		data: null,
		parentData: null,
	});
	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);
	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};
	const [showDiscardPopUp, setShowDiscardPopUp] = useState(false);
	const [showPreviewAndSubmitPopUp, setShowPreviewAndSubmitPopUp] =
		useState(false);
	const [submitMessage, setSubmitMessage] = useState(null);
	const [description, setDescription] = useState(null);
	const [supportingActivities, setSupportingActivities] = useState(null);
	const [toolsAndGuidance, setToolsAndGuidance] = useState(null);
	const [tipsAndTechniques, setTipsAndTechniques] = useState(null);
	const [contributorsData, setContributorsData] = useState(null);
	const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
	const [reviewedDescriptionSuggestions, setReviewedDescriptionSuggestions] =
		useState([]);
	const [reviewedSupportSuggestions, setReviewedSupportSuggestions] = useState(
		[]
	);
	const [
		reviewedToolsAndGuidanceSuggestions,
		setReviewedToolsAndGuidanceSuggestions,
	] = useState([]);
	const [
		reviewedEntryCriteriaSuggestions,
		setReviewedEntryCriteriaSuggestions,
	] = useState([]);
	const [reviewedExitCriteriaSuggestions, setReviewedExitCriteriaSuggestions] =
		useState([]);
	const [reviewedTaskSuggestions, setReviewedTaskSuggestions] =
		useState([]);
	const [reviewedReorderedTaskSuggestions,setReviewedReorderedTaskSuggestions] = useState([])
	const [reviewedReorderedEntrySuggestions,setReviewedReorderedEntrySuggestions] = useState([])
	const [reviewedReorderedExitSuggestions,setReviewedReorderedExitSuggestions] = useState([])
	const [reviewedReorderedInputSuggestions,setReviewedReorderedInputSuggestions] = useState([])
	const [reviewedReorderedOutputSuggestions,setReviewedReorderedOutputSuggestions] = useState([])
	const [initialEntryIdBeforeReorder,setInitialEntryIdBeforeReorder]= useState([])
	const [initialExitIdBeforeReorder,setInitialExitIdBeforeReorder]= useState([])
	const [disableDrag, setDisableDrag] = useState(false)
	const [disableEntryDrag, setDisableEntryDrag] = useState(false)
	const [disableInputDrag, setDisableInputDrag] = useState(false)
	const [disableExitDrag, setDisableExitDrag] = useState(false)
	const [disableOutputDrag, setDisableOutputDrag] = useState(false)
	const [reorderedTaskRejectionCardInfo, setReorderedTaskRejectionCardInfo] = useState([])
    const [reorderedEntryRejectionCardInfo, setReorderedEntryRejectionCardInfo] = useState([])
    const [reorderedInputRejectionCardInfo, setReorderedInputRejectionCardInfo] = useState([])
    const [reorderedExitRejectionCardInfo, setReorderedExitRejectionCardInfo] = useState([])
    const [reorderedOutputRejectionCardInfo, setReorderedOutputRejectionCardInfo] = useState([])



	const [
		reviewedTipsAndTechniquesSuggestions,
		setReviewedTipsAndTechniquesSuggestions,
	] = useState([]);
	const [reviewedTemplateSuggestions, setReviewedTemplateSuggestions] =
		useState([]);
	const [reviewerTemplateSuggestions, setReviewerTemplateSuggestions] =
		useState([]);
	const [ckEditorUserMap, setCkEditorUserMap] = useState({});
	const [supportingActivitiesSuggestions, setSupportingActivitiesSuggestions] =
		useState([]);
	const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
		useState([]);
	const [
		selectedToolsAndGuidanceSuggestions,
		setSelectedToolsAndGuidanceSuggestions,
	] = useState([]);
	const [
		contributiorToolsAndGuidanceSuggestion,
		setContributiorToolsAndGuidanceSuggestion,
	] = useState([]);
	const [templateSuggestions, setTemplateSuggestions] = useState([]);
	const [reviewerInfo, setReviewerInfo] = useState(null);
	const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);
	const [reviewedTools, setReviewedTools] = useState([]);
	const [tipsAndTechniquesSuggestions, setTipsAndTechniquesSuggestions] =
		useState([]);
	const [checkForAdminToolsUpdate, setCheckForAdminToolsUpdate] = useState([]);
	const [contributiorToolsId, setContributiorToolsId] = useState([]);
	const [historyToolsId, setHistoryToolsId] = useState([]);
	const [historicalPreviewTools, setHistoricalPreviewTools] = useState([]);
	const [historyToolsSuggestion, setHistoryToolsSuggestion] = useState([]);
	const [toolsReviewedIds, setToolsReviewedIds] = useState({});
	const [sidebarCard, setSidebarCard] = useState([]);
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
	const [isActivityLock, setIsActivityLock] = useState(false);
	const [activityLockedBy, setActivityLockedBy] = useState('');
	const [lockedByUsername, setLockedByUsername] = useState('');
	const [contributorSuggestionsInfo, setContributorSuggestionsInfo] = useState(
		{}
	);
	const [ratingPoints, setRatingPoints] = useState(null);
	const [enableGamification, setEnableGamification] = useState(false);
	const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);
	const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');

	const [entryCriteriaButtonClicked, setEntryCriteriaButtonClicked] =
		useState(false);
	const [entryCriteriaSuggestions, setEntryCriteriaSuggestions] = useState([]);
	const [entryCriteriaWithSuggestions, setEntryCriteriaWithSuggestions] =
		useState([]);
	const [exitCriteriaButtonClicked, setExitCriteriaButtonClicked] =
		useState(false);
	const [outputButtonClicked, setOutputButtonClicked] = useState(false);
	const [exitCriteriaSuggestions, setExitCriteriaSuggestions] = useState([]);
	const [outputSuggestions, setOutputSuggestions] = useState([]);
	const [exitCriteriaWithSuggestions, setExitCriteriaWithSuggestions] =
		useState([]);
	const [entryCriteriaData, setEntryCriteriaData] = useState([]);
	const [exitCriteriaData, setExitCriteriaData] = useState([]);
	const [
		contributorEntryCriteriaSuggestion,
		setContributorEntryCriteriaSuggestion,
	] = useState({});
	const [
		contributorExitCriteriaSuggestion,
		setContributorExitCriteriaSuggestion,
	] = useState({});
	const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry');
	const [currentExitTab, setCurrentExitTab] = useState('tab-exit');

	const [taskButtonClicked, setTaskButtonClicked] =
		useState(false);
	const [taskSuggestions, setTaskSuggestions] = useState([]);
	const [taskWithSuggestions, setTaskWithSuggestions] =
		useState([]);
	const [taskData, setTaskData] = useState([]);
	const [
		contributorTaskSuggestion,
		setContributorTaskSuggestion,
	] = useState({});
	const [reviewerEntryCriteriaSuggestions, setReviewerEntryCriteriaSuggestions] = useState([]);
	const [reviewerExitCriteriaSuggestions, setReviewerExitCriteriaSuggestions] = useState([]);
	const [reviewerTaskSuggestions, setReviewerTaskSuggestions] = useState([]);
	const [deliverables, setDeliverables] = useState(null);
	const [deliverablesSuggestions, setDeliverablesSuggestions] = useState([]);
	const [reviewedDeliverablesSuggestions, setReviewedDeliverablesSuggestions] = useState([]);
	const [taskReorder,setTaskReorder] = useState(false);
	const [entryContributionReorder,setEntryContributionReorder] = useState(false);
	const [inputContributionReorder,setInputContributionReorder] = useState(false);
	const [exitContributionReorder,setExitContributionReorder] = useState(false);
	const [outputContributionReorder,setOutputContributionReorder] = useState(false);
	const [taskReorderArray, setTaskReorderArray] = useState([]);
	const [entryCriteriaReorderArray, setEntryCriteriaReorderArray] = useState([]);
	const [inputCriteriaReorderArray, setInputCriteriaReorderArray] = useState([]);
	const [exitCriteriaReorderArray, setExitCriteriaReorderArray]= useState([]);
	const [outputCriteriaReorderArray, setOutputCriteriaReorderArray]= useState([]);
	const [initialTaskIdBeforeReorder, setInitialTaskIdBeforeReorder] = useState([])
	const [ckEditorCurrentReorderUsername, setCkEditorCurrentReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
    const [ckEditorCurrentReorderUserImage,setCkEditorCurrentReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentEntryReorderUsername, setCkEditorCurrentEntryReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
    const [ckEditorCurrentEntryReorderUserImage,setCkEditorCurrentEntryReorderUserImage]=useState(user.profile.photo)
    const [ckEditorCurrentExitReorderUserImage,setCkEditorCurrentExitReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentExitReorderUsername, setCkEditorCurrentExitReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
    const [ckEditorCurrentInputReorderUserImage,setCkEditorCurrentInputReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentInputReorderUsername, setCkEditorCurrentInputReorderUsername] = useState(user.profile.firstName+''+user.profile.surname)
	const [ckEditorCurrentOutputReorderUserImage,setCkEditorCurrentOutputReorderUserImage]=useState(user.profile.photo)
	const [ckEditorCurrentOutputReorderUsername,setCkEditorCurrentOutputReorderUsername]=useState(user.profile.firstName+''+user.profile.surname)
	const [isReviewerTaskReordered, setIsReviewerTaskReordered] = useState(false)
	const [isReviewerEntryReordered, setIsReviewerEntryReordered] = useState(false)
	const [isReviewerInputReordered, setIsReviewerInputReordered] = useState(false)
	const [isReviewerExitReordered, setIsReviewerExitReordered] = useState(false)
	const [isReviewerOutputReordered, setIsReviewerOutputReordered] = useState(false)
	const [reorderContributionCount, setReorderContributionCount] = useState(0)
	const [addedTaskSequence, setAddedTaskSequence] = useState([])
    const [addedEntrySequence, setAddedEntrySequence] = useState([])
    const [addedExitSequence, setAddedExitSequence] = useState([])
	const [isOrderChanged, setIsOrderChanged] = useState({
		task:false,
		entry:false,
		exit:false,
		input:false,
		output:false
	  })


	

	const getActivityLockInfo = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifactsForContribution?artifactTypeConstId=${artifactMapData['activities']
		}&artifactId=${activityId}&artifactDraftId=${0}`
		)
			.then(async (response) => {
				if (response.data) {
					setIsActivityLock(true);
					setActivityLockedBy(response.data.email);
					const accessToken = await axiosTokenService();
					const userData = await getOtherUserProfile(
						accessToken,
						response.data.email
					);
					if (userData)
						setLockedByUsername(userData.firstName + ' ' + userData.surname);
					else setLockedByUsername(response.data.email);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		};
	

	const getExistingTemplates = () => {
		const existingTemplates = templateSuggestions.map((template) =>
			JSON.parse(template)
		);
		return [...templateData, ...existingTemplates];
	};

	const addTemplateToSuggestion = (suggestions) => {
		const updatedSuggestions = suggestions
			.filter(
				(suggestedTemplate) =>
					!templateData.some(({ id }) => id === suggestedTemplate.id)
			)
			.map((template) =>
			JSON.stringify({
				...template,
				suggestionType: 'insertion',
					authorId: CkEditorCurrentUser,
					title: template.name,
					templateId: template.id.toString(),
				})
			);
		setTemplateSuggestions((prevState) => [
			...prevState,
			...updatedSuggestions,
		]);
	};
	
	const updateSuggestionInfo = (suggestionInfo, suggestionsData) => {
		const id =
		suggestionInfo.split(':').length > 3
		? suggestionInfo.split(':')[2]
				: suggestionInfo.split(':')[1];
				const type =
				suggestionInfo.split(':').length > 3
				? suggestionInfo.split(':').slice(0, 2).join(':')
				: suggestionInfo.split(':')[0];
		const authorId =
			suggestionInfo.split(':').length > 3
				? suggestionInfo.split(':')[3]
				: suggestionInfo.split(':')[2];
		suggestionsData[id] = {
			id: id,
			type: type,
			authorId: authorId,
			data: null,
		};
	};

	const getSuggestionsInfo = (emCollection, suggestionsData) => {
		for (let i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-after')) {
				const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-end-after'
					);
					updateSuggestionInfo(suggestionInfo, suggestionsData);
				} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
					const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-start-before'
				);
				updateSuggestionInfo(suggestionInfo, suggestionsData);
			}
		}
	};
	
	const checkGamificationStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
			(response) => {
				if (response.data[0].value === 'true') {
					setEnableGamification(true);
				}
			}
		);
	};       //setContributorsData(response.data);
	//checkSuggestion(response.data.description, 'description');

	const checkSuggestion = (htmlData, type) => {
		const domParser = new DOMParser();
		const docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		let emCollection = docElement.getElementsByTagName('suggestion');

		const suggestionsData = {};
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionId = emCollection[i].attributes.id.value;
			suggestionsData[suggestionId.split(':')[0]] = {
				id: suggestionId.split(':')[0],
				type: emCollection[i].attributes['suggestion-type'].value,
				authorId: suggestionId.split(':')[1],
				data: null,
			};
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionInfo = emCollection[i].attributes.name.value;
			const id =
			suggestionInfo.split(':').length > 3
			? suggestionInfo.split(':')[2]
			: suggestionInfo.split(':')[1];
			const type =
			suggestionInfo.split(':').length > 3
			? suggestionInfo.split(':').slice(0, 2).join(':')
			: suggestionInfo.split(':')[0];
			const authorId =
			suggestionInfo.split(':').length > 3
			? suggestionInfo.split(':')[3]
			: suggestionInfo.split(':')[2];
			suggestionsData[id] = {
				id: id,
				type: type,
				authorId: authorId,
				data: null,
			};
		}
		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(emCollection, suggestionsData);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(emCollection, suggestionsData);
		setContributorSuggestionsInfo((prevState) => ({
			...prevState,
			[type]: suggestionsData,
		}));
	};

	const getActivityContents = async () => {
		await axiosGetService(
			`api/activities/activity?activityIdentifier=${activityIdentifier}&complexityId=${complexityId}&url=activity&urlPathName=activity`
			)
			.then(async (response) => {
				const mappedComplexities = response.data.mappedCompelxityList.map(
					(item) => ({
						name: item.name,
						id: item.id,
						option: 'type',
					})
				);
				setComplexityName({
					name: response.data.complexityName,
					id: response.data.complexityId,
					type: 'option',
				});
				const hashtags = response.data.hashTagsList.map(({ name }) => {
					return name;
				});
				setExistingHashtags(hashtags);
				setTemplateData((prevState) => [
					...response.data.activityTemplateDetails,
					...prevState,
				]);
				setTopicData(response.data.activityTopics);
				setProcedureData(response.data.activityProcedureType);
				setactivityContent(response.data);
				setComplexities(mappedComplexities);
				setReviewerVerified(response.data.isReviewer);
				setIsActivityLock(response.data.isDrafted);
				setActivityLockedBy(response.data.lastDraftChangesMadeBy);
				if (response.data.isDrafted) {
					const accessToken = await axiosTokenService();
					const userData = await getOtherUserProfile(
						accessToken,
						response.data.lastDraftChangesMadeBy
						);
					if (userData)
						setLockedByUsername(userData.firstName + ' ' + userData.surname);
					else setLockedByUsername(response.data.lastDraftChangesMadeBy);
				}
				if (!response.data.isDrafted) {
					getActivityLockInfo();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	
	const getRatingPoints = async () => {
		await axiosGetService(`api/sdmuser/gamification/getRatingPoints`).then(
			(response) => {
				setRatingPoints(response.data);
			}
			);
	};

	const getContributorData = async () => {
		await axiosGetService(
			`api/sdmactivity/getActivity2/${activityId}/${complexityId}`
			)
			.then((response) => {
				setContributorsData(response.data);	
				if(response.data.resultCalloutJson !== null){
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						['description']: response.data.resultCalloutJson.description,
					}));
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						['supportingActivities']: response.data.resultCalloutJson.supportingActivities,
					}));
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						['tipsAndTechniques']: response.data.resultCalloutJson.tipsAndTechniques,
					}));
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						['deliverables']: response.data.resultCalloutJson.deliverables,
					}));
				}
				
				// checkSuggestion(response.data.description, 'description');
				// checkSuggestion(
				// 	response.data.supportingActivities,
				// 	'supportingActivities'
				// );
				// checkSuggestion(response.data.tipsAndTechniques, 'tipsAndTechniques');
				// checkSuggestion(response.data.deliverables, 'deliverables');
				const adminTools = [];
				const contributionsToolsId = [];
				const updateIsSelected = {};
				const contributorToolsSuggestions = [];
				if (
					response.data.resultCalloutJson &&
					response.data.resultCalloutJson.toolsAndGuidance
				) {
					const toolsSuggestions =
						response.data.resultCalloutJson.toolsAndGuidance;
					for (const id in toolsSuggestions) {
						const tools = {
							toolsId: JSON.parse(toolsSuggestions[id]).toolsId,
							id: JSON.parse(toolsSuggestions[id]).id,
							authorId: JSON.parse(toolsSuggestions[id]).authorId,
							suggestionType: JSON.parse(toolsSuggestions[id]).suggestionType,
							name: JSON.parse(toolsSuggestions[id]).name,
							createdAt: JSON.parse(toolsSuggestions[id]).createdAt,
							type: 'option',
							isSelected: JSON.parse(toolsSuggestions[id]).isSelected,
						};
						contributionsToolsId.push(JSON.parse(toolsSuggestions[id]).toolsId);
						if (
							JSON.parse(toolsSuggestions[id]).suggestionType === 'insertion'
						) {
							updateIsSelected[JSON.parse(toolsSuggestions[id]).toolsId] = true;
						} else {
							updateIsSelected[
								JSON.parse(toolsSuggestions[id]).toolsId
							] = false;
						}
						contributorToolsSuggestions.push(JSON.stringify(tools));
					}
					const suggestion = {};
					for (const tool of toolsSuggestions) {
						suggestion[JSON.parse(tool).id] = JSON.parse(tool);
					}
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						toolsAndGuidance: suggestion,
					}));
				} else {
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						toolsAndGuidance: {},
					}));
				}
				if (
					response.data.resultCalloutJson &&     //if(response.data.resultReorderJson){ }
					response.data.resultCalloutJson.templateSuggestions
				) {
					const templateSuggestions =
												response.data.resultCalloutJson.templateSuggestions;
					const suggestions = {};
					for (const template of templateSuggestions) {
						suggestions[JSON.parse(template).id] = {
							...JSON.parse(template),
							type: JSON.parse(template).suggestionType,
						};
					}
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						templates: suggestions,
					}));
				} else {
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						templates: {},
					}));
				}
				
				setContributiorToolsId(contributionsToolsId);
				const updateToolsAndGuideSuggestion = [];
				const toolData = [...response.data.activityToolsList];
				const url = {};
				for (const id in toolData) {
					const tools = {
						activities: toolData[id].activities,
						createdBy: toolData[id].createdBy,
						createdOn: toolData[id].createdOn,
						email: toolData[id].email,
						toolsId: toolData[id].id,
						isSelected: toolData[id].isSelected,
						modifiedBy: toolData[id].modifiedBy,
						modifiedOn: toolData[id].modifiedOn,
						modifiedUserEmail: toolData[id].modifiedUserEmail,
						name: toolData[id].name,
						url: toolData[id].url,
					};
					if (toolData[id].id in updateIsSelected) {
						tools.isSelected = updateIsSelected[toolData[id].id];
						updateToolsAndGuideSuggestion.push(tools);
					} else {
						updateToolsAndGuideSuggestion.push(tools);
					}
					url[toolData[id].id] = toolData[id].url;
				}

				setToolsUrl(url);
				setToolsAndGuidanceSuggestions(updateToolsAndGuideSuggestion);

				const nonContributedTools = [];
				for (const id in response.data.activityToolsList) {
					if (
						response.data.activityToolsList[id].isSelected &&
						!contributionsToolsId.includes(
							response.data.activityToolsList[id].id
						)
					) {
						const tools = {
							toolsId: response.data.activityToolsList[id].id,
							authorId: null,
							suggestionType: 'none',
							createdAt: new Date(),
							name: response.data.activityToolsList[id].name,
							type: 'option',
							isSelected: true,
						};
						nonContributedTools.push(JSON.stringify(tools));
						adminTools.push(response.data.activityToolsList[id].id);
					}
				}
				setAdminToolsAndGuidance([...adminTools]);
				for (const id in contributorToolsSuggestions) {
					nonContributedTools.push(contributorToolsSuggestions[id]);
				}
				setSelectedToolsAndGuidanceSuggestions(nonContributedTools);

				if (
					response.data.resultCalloutJson &&
					response.data.resultCalloutJson.templateSuggestions
				) {
					setTemplateSuggestions(
						response.data.resultCalloutJson.templateSuggestions.map(
							(template) =>
								JSON.stringify({
									...JSON.parse(template),
									suggestionType: 'insertion',
								})
						)
					);
				}

				//Historical preview
				const historyContributionsToolsId = [];
				if (
					response.data.resultCalloutJson &&
					response.data.resultCalloutJson.toolsAndGuidance
				) {
					setHistoricalPreviewTools(
						response.data.resultCalloutJson.toolsAndGuidance
					);
					const toolsSuggestions =
						response.data.resultCalloutJson.toolsAndGuidance;
					for (const id in toolsSuggestions) {
						historyContributionsToolsId.push(
							JSON.parse(toolsSuggestions[id]).toolsId
						);
					}
					setHistoryToolsId(historyContributionsToolsId);
				}
				const updatedTools = [];
				for (const id in response.data.activityToolsList) {
					const tools = {
						activities: response.data.activityToolsList.activities,
						createdBy: response.data.activityToolsList.createdBy,
						createdOn: response.data.activityToolsList.createdOn,
						email: response.data.activityToolsList.email,
						toolsId: response.data.activityToolsList.toolsId,
						isSelected: historyContributionsToolsId.includes(
							response.data.activityToolsList[id].toolsId
						)
							? true
							: false,
						modifiedBy: response.data.activityToolsList.modifiedBy,
						modifiedOn: response.data.activityToolsList.modifiedOn,
						modifiedUserEmail:
							response.data.activityToolsList.modifiedUserEmail,
						name: response.data.activityToolsList.name,
						url: response.data.activityToolsList.url,
					};
					updatedTools.push(tools);
				}
				setHistoryToolsSuggestion([...updatedTools]);
				const reviewedIds = {};
				if (response.data.reviewedSuggestionsJson) {
					const toolsAndGuidancesReviewedSuggestions = JSON.parse(
						response.data.reviewedSuggestionsJson
					).toolsAndGuidancesReviewedSuggestions;
					for (const id in toolsAndGuidancesReviewedSuggestions) {
						reviewedIds[
							JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).id
						] = JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).state;
					}
					setToolsReviewedIds(reviewedIds);
				}
				setEntryCriteriaData(response.data.entryCriteriaList);
				getEntryCriteriaSuggestions(response);
				setExitCriteriaData(response.data.exitCriteriaList);
				getExitCriteriaSuggestions(response);
				setTaskData(response.data.taskList);
				getTaskSuggestions(response);
				getTaskSuggestionsReorder(response);
				getEntrySuggestionsReorder(response);
				getExitSuggestionsReorder(response);
				getInputSuggestionsReorder(response);
				getOutputSuggestionsReorder(response);

			})
			.catch((err) => {
				console.log(err);
			});
	};
	
	const removeToolsAndGuidance = (cardType, item) => {
		let data = [];
		let count = 0;
		for (const key in selectedToolsAndGuidanceSuggestions) {
			if (
				JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId !==
				item.toolsId
			) {
				data.push(selectedToolsAndGuidanceSuggestions[key]);
				count = count + 1;
			} else if (
				adminToolsAndGuidance.includes(item.toolsId) &&
				cardType === 'admin'
			) {
				count = count + 1;
				const suggestionCardData = {
					toolsId: item.toolsId,
					id: uniqueIdGenerator(),
					authorId: CkEditorCurrentUser,
					suggestionType: 'deletion',
					name: item.name,
					type: 'option',
					createdAt: new Date(),
					isSelected: false,
					createdBy: 'reviewer',
				};
				data.push(JSON.stringify(suggestionCardData));
				if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
					setCheckForAdminToolsUpdate([
						...checkForAdminToolsUpdate,
						item.toolsId,
					]);
				}
			} else if (
				adminToolsAndGuidance.includes(item.toolsId) &&
				cardType === 'adminToolsDeletion'
			) {
				count = count + 1;
				const suggestionCardData = {
					toolsId: item.toolsId,
					id: uniqueIdGenerator(),
					authorId: CkEditorCurrentUser,
					suggestionType: 'none',
					name: item.name,
					type: 'option',
					createdAt: new Date(),
					isSelected: true,
					createdBy: 'reviewer',
				};
				const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
					(id) => Number(id) !== Number(item.toolsId)
				);
				setCheckForAdminToolsUpdate(removeCheckedSuggestion);
				data.push(JSON.stringify(suggestionCardData));
			}
		}
		setSelectedToolsAndGuidanceSuggestions(count > 0 ? data : []);
		data = [];
		for (const key in toolsAndGuidanceSuggestions) {
			if (toolsAndGuidanceSuggestions[key].toolsId === item.toolsId) {
				toolsAndGuidanceSuggestions[key].isSelected =
					cardType === 'adminToolsDeletion' ? true : false;
				data.push(toolsAndGuidanceSuggestions[key]);
			} else {
				data.push(toolsAndGuidanceSuggestions[key]);
			}
		}
		setToolsAndGuidanceSuggestions(data);
	};

	const updateContributorSuggestion = (reviewType, item, suggestionType) => {
		if (reviewType === 'accept') {
			setReviewedToolsAndGuidanceSuggestions([
				...reviewedToolsAndGuidanceSuggestions,
				JSON.stringify({ id: item.id, state: 'accepted' }),
			]);
			setReviewedTools([...reviewedTools, item.toolsId]);
			setAdminToolsAndGuidance([...adminToolsAndGuidance, item.toolsId]);
		} else {
			setReviewedTools([...reviewedTools, item.toolsId]);
			setReviewedToolsAndGuidanceSuggestions([
				...reviewedToolsAndGuidanceSuggestions,
				JSON.stringify({ id: item.id, state: 'rejected' }),
			]);
			if (suggestionType === 'deletion') {
				setAdminToolsAndGuidance([...adminToolsAndGuidance, item.toolsId]);
			}
		}

		let data = [];
		for (const key in selectedToolsAndGuidanceSuggestions) {
			if (
				JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId !==
				item.toolsId
			) {
				data.push(selectedToolsAndGuidanceSuggestions[key]);
			} else if (
				(reviewType === 'accept' && suggestionType === 'insertion') ||
				(reviewType === 'reject' && suggestionType === 'deletion')
			) {
				const suggestionCardData = {
					toolsId: JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId,
					id: null,
					authorId: null,
					suggestionType: 'none',
					name: JSON.parse(selectedToolsAndGuidanceSuggestions[key]).name,
					type: 'option',
					createdAt: new Date(),
					isSelected: JSON.parse(selectedToolsAndGuidanceSuggestions[key])
						.isSelected,
				};
				data.push(JSON.stringify(suggestionCardData));
			}
		}

		setSelectedToolsAndGuidanceSuggestions(data);
		data = [];
		for (const key in toolsAndGuidanceSuggestions) {
			if (toolsAndGuidanceSuggestions[key].toolsId === item.toolsId) {
				toolsAndGuidanceSuggestions[key].isSelected =
					(reviewType === 'accept' && suggestionType === 'insertion') ||
						(reviewType === 'reject' && suggestionType === 'deletion')
						? true
						: false;
				data.push(toolsAndGuidanceSuggestions[key]);
			} else {
				data.push(toolsAndGuidanceSuggestions[key]);
			}
		}
		setToolsAndGuidanceSuggestions(data);
	};
	 
	const getEntryCriteriaSuggestions = async (response) => {
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if (
			response.data.entryCriteriaList &&
			!(
				response.data.resultCalloutJson &&
				response.data.resultCalloutJson.entryCriteriaSuggestions) &&
				!(reorderResponse &&
					reorderResponse?.isEntryReorder ||
					reorderResponse?.isInputReorder )
				// &&
				// JSON.parse(response.data.reorderJson).isInputReorder
			)
		 {  
			const updatedList = response.data.entryCriteriaList
				//.filter((item) => item.criteriaTypeName === 'Entry Criteria')
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);

			setEntryCriteriaWithSuggestions((prevState) => [
				...prevState,
				...updatedList,
			]);
			setInitialEntryIdBeforeReorder((prevState) => [
				...prevState,
				...updatedList,
			  ]);
			  return;
		}

		if ((
			response.data.resultCalloutJson &&
			response.data.resultCalloutJson.entryCriteriaSuggestions
		   
		) && !(reorderResponse &&
			reorderResponse?.isEntryReorder ||
			reorderResponse?.isInputReorder )){ 
			const criteriaSuggestions =
				response.data.resultCalloutJson.entryCriteriaSuggestions;
			const suggestions = {};
			for (const criteria of criteriaSuggestions) {
				suggestions[JSON.parse(criteria).id] = {
					...JSON.parse(criteria),
					type: JSON.parse(criteria).suggestionType,
				};
			}
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				entryCriteria: suggestions,
			}));

			setEntryCriteriaSuggestions([
				...response.data.resultCalloutJson.entryCriteriaSuggestions,
			]);
			setContributorEntryCriteriaSuggestion([
				...response.data.resultCalloutJson.entryCriteriaSuggestions,
			]);
			// if(response.data.resultCalloutJson.entryCriteriaSuggestions.some((item)=>JSON.parse(item).id===))
			const adminEntryCriteria = response.data.entryCriteriaList
				.filter(
					(item) =>
						!response.data.resultCalloutJson.entryCriteriaSuggestions.some(
							(value) => item.id === JSON.parse(value).id
						)
				)
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);
				const allEntry = [
					...adminEntryCriteria,
					...response.data.resultCalloutJson.entryCriteriaSuggestions,
				]
				const parsedAllEntry = allEntry.map(e=>JSON.parse(e))
				function customSort(a, b) {
					if (a.sequence !== b.sequence) {
					  return a.sequence - b.sequence;
					}
				  
				   
					return a.criteriaType === 'Exit' ? -1 : 1;
				  }
				  const sortedArray = parsedAllEntry.sort(customSort);
				  const sortedArrayString = sortedArray.map(e=>JSON.stringify(e))

			setEntryCriteriaWithSuggestions([
				...sortedArrayString
			]);
			setInitialEntryIdBeforeReorder([
				...sortedArrayString
			  ]);
			  return;
		} 
		if(reorderResponse && reorderResponse?.isEntryReorder
		    || JSON.parse(response.data.reorderJson).isInputReorder){
				
			const parsedReorderEntryArray = reorderResponse.isEntryReorder?.map(e=> JSON.parse(e))
			const parsedReorderInputArray = reorderResponse.isInputReorder?.map(e=> JSON.parse(e))
			if(reorderResponse.isEntryReorder){
				setDisableEntryDrag(true)
			} if (reorderResponse.isInputReorder){
				setDisableInputDrag(true)
			}
			if(response.data.resultCalloutJson && response.data.resultCalloutJson.entryCriteriaSuggestions){
				const criteriaSuggestions =
				response.data.resultCalloutJson.entryCriteriaSuggestions;
			const suggestions = {};
			for (const criteria of criteriaSuggestions) {
				suggestions[JSON.parse(criteria).id] = {
					...JSON.parse(criteria),
					type: JSON.parse(criteria).suggestionType,
				};
			}
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				entryCriteria: suggestions,
			}));

			setEntryCriteriaSuggestions([
				...response.data.resultCalloutJson.entryCriteriaSuggestions,
			]);
			setContributorEntryCriteriaSuggestion([
				...response.data.resultCalloutJson.entryCriteriaSuggestions,
			]);
			const adminEntryCriteria = response.data.entryCriteriaList
				.filter(
					(item) =>
						!response.data.resultCalloutJson.entryCriteriaSuggestions.some(
							(value) => item.id === JSON.parse(value).id
						)
				)
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);
			const allEntryWithoutReorder = [...adminEntryCriteria, ...response.data.resultCalloutJson.entryCriteriaSuggestions]
			const parsedallEntryWithoutReorder = allEntryWithoutReorder.map(e=>JSON.parse(e))
			const entryWithoutInsert = parsedallEntryWithoutReorder.filter(e=>e.id.length < 20)
			const entryWithInsert = parsedallEntryWithoutReorder.filter(e=>e.id.length > 20)
			const sortedArray = entryWithoutInsert?.map((item)=>{
				return JSON.stringify({
					...item,
					 sequence: response.data.entryCriteriaList.filter((v) => v.id === item.id)[0]?.sequence,
					 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				  })

			})
			const parsedOriginalAddedEntry = entryWithInsert.length ? response.data.resultCalloutJson.originalAddedEntry
			 .map(e=>JSON.parse(e)) : []
			const sortedAddedEntry = entryWithInsert.length ? entryWithInsert?.map((item)=>{
				return JSON.stringify({
					...item,
					 sequence: parsedOriginalAddedEntry.filter((v) => v.id === item.id)[0]?.sequence,
					 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				  })
				}) : []
			const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
			const parsedSortedAddedEntry = sortedAddedEntry.map(e=>JSON.parse(e))
			parsedSortedArray.sort((a,b)=>{
				return a.sequence - b.sequence
			})
			parsedSortedAddedEntry.sort((a,b)=>{
				return a.sequence - b.sequence
			})
			const combinedData = [...parsedSortedArray, ...parsedSortedAddedEntry]
			let i = 1;
			let j = 1;
			const initialSequence = [...combinedData]
			
			initialSequence.forEach(e=>{
				if(e.criteriaTypeID === 1){
					e.sequence = i;
					i++
				 }
				 else if(e.criteriaTypeID === 3){
					 e.sequence = j;
					 j++
				 }
			 })
			const parsedInitialSequence = initialSequence.map(e=>JSON.stringify(e))
			const parsedFilteredEntry = initialSequence.filter(e=>e.criteriaTypeName === 'Entry Criteria')
			const parsedFilteredInput = initialSequence.filter(e=>e.criteriaTypeName === 'Input')
			const idToObjectMap = new Map(parsedallEntryWithoutReorder.map(obj => [obj.id, obj]));
			if(reorderResponse.isEntryReorder && !reorderResponse.isInputReorder){
			const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
			const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
			const allEntryWithReorder = [...entryWithReorder,...parsedFilteredInput]
			const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			setInitialEntryIdBeforeReorder(parsedInitialSequence)

			} else if(!reorderResponse.isEntryReorder && reorderResponse.isInputReorder){
			const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
			const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
			const allInputWithReorder = [...parsedFilteredEntry,...inputWithReorder]
			const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			setInitialEntryIdBeforeReorder(parsedInitialSequence)

			}
			else if(reorderResponse.isEntryReorder && reorderResponse.isInputReorder){
				const combinedParsedReorderArray = [...parsedReorderEntryArray,...parsedReorderInputArray]
				const allEntryWithReorder = combinedParsedReorderArray.map(item => idToObjectMap.get(item.id))
			   const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
			   setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			  
			   setInitialEntryIdBeforeReorder(parsedInitialSequence)
			} 
               return;
			}else{ 
				const adminEntryCriteria = response.data.entryCriteriaList.map((item) =>
				JSON.stringify({
					...item,
					suggestionType: 'none',
					authorId: CkEditorCurrentUser,
					suggestionId: null,
					createdAt: new Date(),
				})   
			); 
			const parsedadminEntryCriteria = adminEntryCriteria.map(e=>JSON.parse(e))
			const parsedFilteredEntry = parsedadminEntryCriteria.filter(e=>e.criteriaTypeName === 'Entry Criteria')
			const parsedFilteredInput = parsedadminEntryCriteria.filter(e=>e.criteriaTypeName === 'Input')
			if(JSON.parse(response.data.reorderJson).isEntryReorder && !JSON.parse(response.data.reorderJson).isInputReorder){
			const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
			const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
			const allEntryWithReorder = [...entryWithReorder,...parsedFilteredInput]
			const allEntryWithReorderString = allEntryWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			} else if(JSON.parse(response.data.reorderJson).isInputReorder && !JSON.parse(response.data.reorderJson).isEntryReorder){
			 const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
			const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
			const allInputWithReorder = [...parsedFilteredEntry,...inputWithReorder]
			const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
			setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			} else if(JSON.parse(response.data.reorderJson).isEntryReorder && JSON.parse(response.data.reorderJson).isInputReorder){
				const idToObjectMap = new Map(parsedFilteredEntry.map(obj => [obj.id, obj]));
				const idToObjectMapInput = new Map(parsedFilteredInput.map(obj => [obj.id, obj]));
			    const entryWithReorder = parsedReorderEntryArray.map(item => idToObjectMap.get(item.id))
			    const inputWithReorder = parsedReorderInputArray.map(item => idToObjectMapInput.get(item.id))
				const allInputWithReorder = [...entryWithReorder,...inputWithReorder]
				const allEntryWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
				setEntryCriteriaWithSuggestions(allEntryWithReorderString)
			}
			setInitialEntryIdBeforeReorder(adminEntryCriteria)
			}

		}
		
		else {
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				entryCriteria: {},
			}));
		}
	};

	const getExitCriteriaSuggestions = async (response) => {
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }

		
		if (
			response.data.exitCriteriaList && 
			!(
				response.data.resultCalloutJson &&
				response.data.resultCalloutJson.exitCriteriaSuggestions 
			) && !(reorderResponse &&
				reorderResponse?.isExitReorder ||
				reorderResponse?.isOutputReorder )
		) { 
			const updatedList = response.data.exitCriteriaList
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);

			setExitCriteriaWithSuggestions((prevState) => [
				...prevState,
				...updatedList,
			]);
			setInitialExitIdBeforeReorder((prevState) => [
				...prevState,
				...updatedList,
			]);
			return;
		}

		if ((
			response.data.resultCalloutJson &&
			response.data.resultCalloutJson.exitCriteriaSuggestions
		) && !(reorderResponse &&
			reorderResponse?.isExitReorder ||
			reorderResponse?.isOutputReorder )){   
			const criteriaSuggestions =
				response.data.resultCalloutJson.exitCriteriaSuggestions;
			const suggestions = {};
			for (const criteria of criteriaSuggestions) {
				suggestions[JSON.parse(criteria).id] = {
					...JSON.parse(criteria),
					type: JSON.parse(criteria).suggestionType,
				};
			}
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				exitCriteria: suggestions,
			}));
			setExitCriteriaSuggestions([
				...response.data.resultCalloutJson.exitCriteriaSuggestions,
			]);
			setContributorExitCriteriaSuggestion([
				...response.data.resultCalloutJson.exitCriteriaSuggestions,
			]);
			const adminExitCriteria = response.data.exitCriteriaList
				.filter(
					(item) =>
						!response.data.resultCalloutJson.exitCriteriaSuggestions.some(
							(value) => item.id === JSON.parse(value).id
						)
				)
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);
				const allEntry = [
					...adminExitCriteria,
				...response.data.resultCalloutJson.exitCriteriaSuggestions
				]
				const parsedAllEntry = allEntry.map(e=>JSON.parse(e))
				function customSort(a, b) {
					if (a.sequence !== b.sequence) {
					  return a.sequence - b.sequence;
					}
				  
				   
					return a.criteriaType === 'Exit' ? -1 : 1;
				  }
				  const sortedArray = parsedAllEntry.sort(customSort);
				  const sortedArrayString = sortedArray.map(e=>JSON.stringify(e))

			setExitCriteriaWithSuggestions([
				...sortedArrayString
			]);
			setInitialExitIdBeforeReorder([
				...sortedArrayString
			]);
			return;
		} 
		if(reorderResponse && reorderResponse?.isExitReorder
		|| reorderResponse?.isOutputReorder){

			const parsedReorderExitArray = reorderResponse.isExitReorder?.map(e=> JSON.parse(e))
			const parsedReorderOutputArray = reorderResponse.isOutputReorder?.map(e=> JSON.parse(e))
			if(reorderResponse.isExitReorder){
				setDisableExitDrag(true)
			} if(reorderResponse.isOutputReorder){
				setDisableOutputDrag(true)
			}

		if (
			response.data.resultCalloutJson &&
			response.data.resultCalloutJson.exitCriteriaSuggestions
		) {
			const criteriaSuggestions =
				response.data.resultCalloutJson.exitCriteriaSuggestions;
			const suggestions = {};
			for (const criteria of criteriaSuggestions) {
				suggestions[JSON.parse(criteria).id] = {
					...JSON.parse(criteria),
					type: JSON.parse(criteria).suggestionType,
				};
			}
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				exitCriteria: suggestions,
			}));
			setExitCriteriaSuggestions([
				...response.data.resultCalloutJson.exitCriteriaSuggestions,
			]);
			setContributorExitCriteriaSuggestion([
				...response.data.resultCalloutJson.exitCriteriaSuggestions,
			]);
			const adminExitCriteria = response.data.exitCriteriaList
				.filter(
					(item) =>
						!response.data.resultCalloutJson.exitCriteriaSuggestions.some(
							(value) => item.id === JSON.parse(value).id
						)
				)
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);

				const allExitWithoutReorder = [...adminExitCriteria, ...response.data.resultCalloutJson.exitCriteriaSuggestions]
				const parsedallExitWithoutReorder = allExitWithoutReorder.map(e=>JSON.parse(e))
				const exitWithoutInsert = parsedallExitWithoutReorder.filter(e=>e.id.length < 20)
				const exitWithInsert = parsedallExitWithoutReorder.filter(e=>e.id.length > 20)
				const sortedArray = exitWithoutInsert.map((item)=>{
					return JSON.stringify({
						...item,
						 sequence: response.data.exitCriteriaList.filter((v) => v.id === item.id)[0]?.sequence,
						 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
						createdAt: new Date(),
					  })

				})
				const parsedOriginalAddedExit = exitWithInsert.length ?response.data.resultCalloutJson.originalAddedExit
				.map(e=>JSON.parse(e)):[]
				const sortedAddedArray =  exitWithInsert.length ? exitWithInsert.map((item)=>{
					return JSON.stringify({
						...item,
						 sequence: parsedOriginalAddedExit.filter((v) => v.id === item.id)[0]?.sequence,
						 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
						createdAt: new Date(),
					  }) 
				}): []
				const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
				const parsedsortedAddedArray = sortedAddedArray.map(e=>JSON.parse(e))
				parsedSortedArray.sort((a,b)=>{
                    return a.sequence - b.sequence
				})
				parsedsortedAddedArray.sort((a,b)=>{
                    return a.sequence - b.sequence
				})
				const combinedData = [...parsedSortedArray, ...parsedsortedAddedArray]
				let i = 1;
			        let j = 1;
			        const initialSequence = [...combinedData]
			   
			        initialSequence.forEach(e=>{
				    if(e.criteriaTypeID === 2){
					   e.sequence = i;
					   i++
					}
					else if(e.criteriaTypeID === 4){
						e.sequence = j;
						j++
					}
				})
			    const parsedInitialSequence = initialSequence.map(e=>JSON.stringify(e))
			    const parsedFilteredExit = initialSequence.filter(e=>e.criteriaTypeName === 'Exit Criteria')
			    const parsedFilteredOutput = initialSequence.filter(e=>e.criteriaTypeName === 'Output')
				if(reorderResponse.isExitReorder && !reorderResponse.isOutputReorder){
					const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
			        const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
			        const allExitWithReorder = [...exitWithReorder,...parsedFilteredOutput]
			        const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
					setExitCriteriaWithSuggestions(allExitWithReorderString)

				} else if(!reorderResponse.isExitReorder && reorderResponse.isOutputReorder){
					const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
			        const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
			        const allOutputWithReorder = [...parsedFilteredExit,...outputWithReorder]
			        const allExitWithReorderString = allOutputWithReorder.map(e=>JSON.stringify(e))
			        setExitCriteriaWithSuggestions(allExitWithReorderString)

				
				} else if(reorderResponse.isExitReorder && reorderResponse.isOutputReorder){
					const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
				    const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
			      	const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
			     	const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
			    	const allInputWithReorder = [...exitWithReorder,...outputWithReorder]
			    	const allExitWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
			    	setExitCriteriaWithSuggestions(allExitWithReorderString)
				}
					
				setInitialExitIdBeforeReorder(parsedInitialSequence)

				}else{
					const adminExitCriteria = response.data.exitCriteriaList.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})   
				); 

			    const parsedadminExitCriteria = adminExitCriteria.map(e=>JSON.parse(e))
			    const parsedFilteredExit = parsedadminExitCriteria.filter(e=>e.criteriaTypeName === 'Exit Criteria')
			    const parsedFilteredOutput = parsedadminExitCriteria.filter(e=>e.criteriaTypeName === 'Output')
			if(JSON.parse(response.data.reorderJson).isExitReorder && !JSON.parse(response.data.reorderJson).isOutputReorder){
			    const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
			    const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
			    const allExitWithReorder = [...exitWithReorder,...parsedFilteredOutput]
			    const allExitWithReorderString = allExitWithReorder.map(e=>JSON.stringify(e))
			setExitCriteriaWithSuggestions(allExitWithReorderString)
			} else if(JSON.parse(response.data.reorderJson).isOutputReorder && !JSON.parse(response.data.reorderJson).isExitReorder){
			     const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
			    const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
			    const allOutputWithReorder = [...parsedFilteredExit,...outputWithReorder]
			    const allExitWithReorderString = allOutputWithReorder.map(e=>JSON.stringify(e))
			setExitCriteriaWithSuggestions(allExitWithReorderString)
			} else if(JSON.parse(response.data.reorderJson).isExitReorder && JSON.parse(response.data.reorderJson).isOutputReorder){
				const idToObjectMap = new Map(parsedFilteredExit.map(obj => [obj.id, obj]));
				const idToObjectMapInput = new Map(parsedFilteredOutput.map(obj => [obj.id, obj]));
				const exitWithReorder = parsedReorderExitArray.map(item => idToObjectMap.get(item.id))
				const outputWithReorder = parsedReorderOutputArray.map(item => idToObjectMapInput.get(item.id))
				const allInputWithReorder = [...exitWithReorder,...outputWithReorder]
				const allExitWithReorderString = allInputWithReorder.map(e=>JSON.stringify(e))
				setExitCriteriaWithSuggestions(allExitWithReorderString)
			}
			setInitialExitIdBeforeReorder(adminExitCriteria)
			}
            return;
			}
			else {
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				exitCriteria: {},
			}));
		}
	};
	const getTaskSuggestions = async (response) => {
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if (
			response.data.taskList && 
			!(
				response.data.resultCalloutJson &&
				response.data.resultCalloutJson.taskSuggestions 
				) && !(
					reorderResponse && reorderResponse?.isTaskReorder
				)
				
		) {
			const updatedList = response.data.taskList
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})
				);

			setTaskWithSuggestions((prevState) => [
				...prevState,
				...updatedList,
			]);
			setInitialTaskIdBeforeReorder((prevState)=>[...prevState,...updatedList])
           return;

		}
		if ((
			response.data.resultCalloutJson &&
			response.data.resultCalloutJson.taskSuggestions 
		  ) && !(
			reorderResponse && reorderResponse?.isTaskReorder
		)) { 
			const taskSuggestions =
				response.data.resultCalloutJson.taskSuggestions;
			const suggestions = {};
			for (const task of taskSuggestions) {
				suggestions[JSON.parse(task).id] = {
					...JSON.parse(task),
				};
			}
			setContributorSuggestionsInfo((prevState) => ({
				...prevState,
				task: suggestions,
			}));

			setTaskSuggestions([
				...response.data.resultCalloutJson.taskSuggestions,
			]);
			setContributorTaskSuggestion([
				...response.data.resultCalloutJson.taskSuggestions,
			]);
			const adminTask = response.data.taskList
				.filter(
					(item) =>
						!response.data.resultCalloutJson.taskSuggestions.some(
							(value) => item.id === JSON.parse(value).id
						) 
				)
				.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})   
				);

				const allTask = [
					...adminTask,
					...response.data.resultCalloutJson.taskSuggestions,
				]
				const parsedAllTask = allTask.map(e=>JSON.parse(e))
				parsedAllTask.sort((a,b)=>{
					return a.sequence - b.sequence
				})
			 const stringParsedAllTask = parsedAllTask.map(e=>JSON.stringify(e))
		
			setTaskWithSuggestions(
				stringParsedAllTask
			);
			setInitialTaskIdBeforeReorder(
				stringParsedAllTask
			  );
           return;
		} 
		if(reorderResponse && reorderResponse?.isTaskReorder){
			const parsedReorderTaskArray =JSON.parse(response.data.reorderJson).isTaskReorder.map(e=> JSON.parse(e))
			    setDisableDrag(true)
				if(response.data.resultCalloutJson && response.data.resultCalloutJson.taskSuggestions){
					const taskSuggestions =
					response.data.resultCalloutJson.taskSuggestions;
					const suggestions = {};
					for (const task of taskSuggestions) {
						suggestions[JSON.parse(task).id] = {
							...JSON.parse(task),
						};
					}
					setContributorSuggestionsInfo((prevState) => ({
						...prevState,
						task: suggestions,
					}));
					
					setTaskSuggestions([
						...response.data.resultCalloutJson.taskSuggestions,
					]);
					setContributorTaskSuggestion([
						...response.data.resultCalloutJson.taskSuggestions,
					]);
					const adminTask = response.data.taskList
					.filter(
						(item) =>
						!response.data.resultCalloutJson.taskSuggestions.some(
							(value) => item.id === JSON.parse(value).id
							) 
							)
							.map((item) =>
							JSON.stringify({
								...item,
								suggestionType: 'none',
								authorId: CkEditorCurrentUser,
								suggestionId: null,
								createdAt: new Date(),
							})   
							); 
							const allTask = [
								...adminTask,
								...response.data.resultCalloutJson.taskSuggestions,
							]
							const parsedAllTask = allTask.map(e=>JSON.parse(e))
							const instertedTask = parsedAllTask.filter(e=>e.id.length > 20)
							const notInsertedTask = parsedAllTask.filter(e=>e.id.length < 20)
							const sortedArray = notInsertedTask?.map((item)=>{
								return JSON.stringify({
									...item,
									 sequence: response.data.taskList.filter((v) => v.id === item.id)[0]?.sequence,
									 authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
									createdAt: new Date(),
								  })
				
							})
							const parsedOriginalAddedTask = instertedTask.length ?response.data.resultCalloutJson.originalAddedTask
							.map(e=>JSON.parse(e)):[]
							const sortedAddedArray = instertedTask.length?instertedTask.map((item)=>{
								return JSON.stringify({
									...item,
									sequence: parsedOriginalAddedTask.filter((v) => v.id === item.id)[0]?.sequence,
									authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
								   createdAt: new Date(),
							 })
							 }):[];
							const parsedSortedArray = sortedArray.map(e=>JSON.parse(e))
							const parsedSortedAddedArray = sortedAddedArray.map(e=>JSON.parse(e))
							
							parsedSortedArray.sort((a,b)=>{
								return a.sequence - b.sequence
							})
							parsedSortedAddedArray.sort((a,b)=>{
								return a.sequence - b.sequence
							})
							const combinedData = [...parsedSortedArray, ...parsedSortedAddedArray]
						    const stringParsedAllTask = combinedData.map(e=>JSON.stringify(e))
							const allTaskWithoutReorder = [...stringParsedAllTask]
							const parsedallTaskWithoutReorder = allTaskWithoutReorder.map(e=>JSON.parse(e))
							const idToObjectMap = new Map(parsedallTaskWithoutReorder.map(obj => [obj.id, obj]));
							const allTasksWithReorder = parsedReorderTaskArray.map(item => idToObjectMap.get(item.id))
							const allTasksWithReorderString = allTasksWithReorder.map(e=>JSON.stringify(e))
							setTaskWithSuggestions(allTasksWithReorderString)
							const initialSequence = [...combinedData]
							initialSequence.forEach((item, index) => {
								item.sequence = index + 1;
							  });
		                    const allTasksUpdatedSequence = initialSequence.map(e=>JSON.stringify(e))
							setInitialTaskIdBeforeReorder([...allTasksUpdatedSequence])
						 return;
						}else{
							const adminTask = response.data.taskList.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'none',
						authorId: CkEditorCurrentUser,
						suggestionId: null,
						createdAt: new Date(),
					})   
				); 
                const parsedAdminTask = adminTask.map(e=>JSON.parse(e))
				const idToObjectMap = new Map(parsedAdminTask.map(obj => [obj.id, obj]));
                const allTasksWithReorder = parsedReorderTaskArray.map(item => idToObjectMap.get(item.id))
				const allTasksWithReorderString = allTasksWithReorder.map(e=>JSON.stringify(e))
                setTaskWithSuggestions(allTasksWithReorderString)
				setInitialTaskIdBeforeReorder(adminTask)
				}

			} else {
				setContributorSuggestionsInfo((prevState) => ({
					...prevState,
					task: {},
				}));
			}
	};
	const getTaskSuggestionsReorder = async (response)=>{
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isTaskReorder){
			setCkEditorCurrentReorderUserImage(JSON.parse(reorderResponse.isTaskReorder[0]).authorId[1])
			setCkEditorCurrentReorderUsername(JSON.parse(reorderResponse.isTaskReorder[0]).authorId[0])
			setReorderedTaskRejectionCardInfo([JSON.parse(reorderResponse.isTaskReorder[0]).authorId[1],JSON.parse(reorderResponse.isTaskReorder[0]).authorId[0]])
			setTaskReorderArray(reorderResponse.isTaskReorder)
			setTaskReorder(true)
			setReorderContributionCount((prev)=>prev + 1)
		}
	
	}

	const getEntrySuggestionsReorder = async (response)=>{
		
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isEntryReorder){
			setCkEditorCurrentEntryReorderUserImage(JSON.parse(reorderResponse.isEntryReorder[0]).authorId[1])
			setCkEditorCurrentEntryReorderUsername(JSON.parse(reorderResponse.isEntryReorder[0]).authorId[0])
			setReorderedEntryRejectionCardInfo([JSON.parse(reorderResponse.isEntryReorder[0]).authorId[1],JSON.parse(reorderResponse.isEntryReorder[0]).authorId[0]])
			setEntryCriteriaReorderArray(reorderResponse.isEntryReorder)
			setEntryContributionReorder(true)
			setReorderContributionCount((prev)=>prev + 1)
		}	
	}
	const getExitSuggestionsReorder = async (response)=>{
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(reorderResponse && reorderResponse.isExitReorder){
			setCkEditorCurrentExitReorderUserImage(JSON.parse(reorderResponse.isExitReorder[0]).authorId[1])
			setCkEditorCurrentExitReorderUsername(JSON.parse(reorderResponse.isExitReorder[0]).authorId[0])
			setReorderedExitRejectionCardInfo([JSON.parse(reorderResponse.isExitReorder[0]).authorId[1],JSON.parse(reorderResponse.isExitReorder[0]).authorId[0]])
			setExitCriteriaReorderArray(reorderResponse.isExitReorder)
			setExitContributionReorder(true)
			setReorderContributionCount((prev)=>prev + 1)
		}
	
	}
	const getInputSuggestionsReorder = async (response)=>{
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(response.data.reorderJson && reorderResponse.isInputReorder){
			setCkEditorCurrentInputReorderUserImage(JSON.parse(reorderResponse.isInputReorder[0]).authorId[1])
			setCkEditorCurrentInputReorderUsername(JSON.parse(reorderResponse.isInputReorder[0]).authorId[0])
			setReorderedInputRejectionCardInfo([JSON.parse(reorderResponse.isInputReorder[0]).authorId[1],JSON.parse(reorderResponse.isInputReorder[0]).authorId[0]])
			setInputCriteriaReorderArray(reorderResponse.isInputReorder)
			setInputContributionReorder(true)
			setReorderContributionCount((prev)=>prev + 1)
		}
	
	}

	const getOutputSuggestionsReorder = async (response)=>{
		let reorderResponse;
		if (response.data.reorderJson) {
			reorderResponse = JSON.parse(response.data.reorderJson);
		  } else {
			reorderResponse = null; 
		  }
		if(response.data.reorderJson && reorderResponse.isOutputReorder){
			setCkEditorCurrentOutputReorderUserImage(JSON.parse(reorderResponse.isOutputReorder[0]).authorId[1])
			setCkEditorCurrentOutputReorderUsername(JSON.parse(reorderResponse.isOutputReorder[0]).authorId[0])
			setReorderedOutputRejectionCardInfo([JSON.parse(reorderResponse.isOutputReorder[0]).authorId[1],JSON.parse(reorderResponse.isOutputReorder[0]).authorId[0]])
			setOutputCriteriaReorderArray(reorderResponse.isOutputReorder)
			setOutputContributionReorder(true)
			setReorderContributionCount((prev)=>prev + 1)
		}
	}
	
		const getUsersEmail = (suggestions) => {
		for (const suggestion of suggestions) {
			const userEmail = JSON.parse(suggestion).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		const contributorInfo = [];
		if (contributorsData.resultCalloutJson) {
			if (contributorsData.resultCalloutJson.description) {
				getUsersEmail(contributorsData.resultCalloutJson.description);
			}
			if (contributorsData.resultCalloutJson.supportingActivities) {
				getUsersEmail(contributorsData.resultCalloutJson.supportingActivities);
			}
			if (contributorsData.resultCalloutJson.toolsAndGuidance) {
				getUsersEmail(contributorsData.resultCalloutJson.toolsAndGuidance);
			}
			if (contributorsData.resultCalloutJson.tipsAndTechniques) {
				getUsersEmail(contributorsData.resultCalloutJson.tipsAndTechniques);
			}
			if (contributorsData.resultCalloutJson.templateSuggestions) {
				getUsersEmail(contributorsData.resultCalloutJson.templateSuggestions);
			}
			if (contributorsData.resultCalloutJson.entryCriteriaSuggestions) {
				getUsersEmail(
					contributorsData.resultCalloutJson.entryCriteriaSuggestions
				);
			}
			if (contributorsData.resultCalloutJson.exitCriteriaSuggestions) {
				getUsersEmail(
					contributorsData.resultCalloutJson.exitCriteriaSuggestions
				);
			}
			if (contributorsData.resultCalloutJson.taskSuggestions) {
				getUsersEmail(
					contributorsData.resultCalloutJson.taskSuggestions
				);
			}
			if (contributorsData.resultCalloutJson.deliverables) {
				getUsersEmail(contributorsData.resultCalloutJson.deliverables);
			}
		}
		if (!userDataMap.has(CkEditorCurrentUser)) {
			graphUserData.push(CkEditorCurrentUser);
			userDataMap.set(CkEditorCurrentUser, 1);
		}
		const accessToken = await axiosTokenService();
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});

			userProfileMapping[userData.mail] = [
				userData.firstName + ' ' + userData.surname,
				userData.photo,
			];
		}
		setCkEditorUserMap(userProfileMapping);
		setCkEditorUsers(contributorInfo);
		if (contributorsData.reviewerEmail) {
			await getOtherUserProfile(
				accessToken,
				contributorsData.reviewerEmail
			).then((response) => {
				setReviewerInfo(response.firstName + ' ' + response.surname);
			});
		}
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	const updateToolsSuggestions = () => {
		const updatedCard = [];
		for (const id in selectedToolsAndGuidanceSuggestions) {
			if (
				!adminToolsAndGuidance.includes(
					JSON.parse(selectedToolsAndGuidanceSuggestions[id]).toolsId &&
					((JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId !==
						CkEditorCurrentUser &&
						!reviewedTools.includes(
							JSON.parse(selectedToolsAndGuidanceSuggestions[id]).toolsId
						)) ||
						JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId ===
						CkEditorCurrentUser)
				) ||
				(JSON.parse(selectedToolsAndGuidanceSuggestions[id]).suggestionType ===
					'deletion' &&
					JSON.parse(selectedToolsAndGuidanceSuggestions[id]).authorId ===
					CkEditorCurrentUser)
			) {
				updatedCard.push(selectedToolsAndGuidanceSuggestions[id]);
			}
		}
		setSidebarCard([...updatedCard]);
	};

	const getMessage = async (title) => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewPrivilegeMessage&title=0`
		).then((response) => {
			message['reviewPrivilegeMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=noContributionMessage&title=0`
		).then((response) => {
			message['noContributionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewError&title=0`
		).then((response) => {
			message['reviewError'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingApprovalForReviewer&title=0`
		).then((response) => {
			message['pendingApprovalForReviewer'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewSubmissionMessage&title=0`
		).then((response) => {
			message['reviewSubmissionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=noContributionMessage&title=${encodeURIComponent(
				title
			)}`
		).then((response) => {
			message['noContributionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=contentUpdate&title=0`
		).then((response) => {
			message['contentUpdate'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewError&title=0`
		).then((response) => {
			message['reviewError'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewSubmissionMessage&title=${encodeURIComponent(
				title
			)}`
		).then((response) => {
			message['reviewSubmissionMessage'] = response.data;
		});
		setMessageType(message);
	};

	const updateSuggestions = (id, text, type) => {
		if(contributorSuggestionsInfo !== null && contributorSuggestionsInfo[type] !== undefined){
			const activitySuggestions = contributorSuggestionsInfo[type];
			if (id in activitySuggestions && !activitySuggestions[id].data) {
				setContributorSuggestionsInfo((prevState) => ({
					...prevState,
					[type]: {
						...contributorSuggestionsInfo[type],
						[id]: { ...contributorSuggestionsInfo[type][id], data: text },
					},
				}));
			}
		}
	};

	useEffect(() => {
		updateToolsSuggestions();
	}, [selectedToolsAndGuidanceSuggestions]);

	useEffect(() => {
		getLicense();
		setSkipNavigationId('innerContent');
	}, []);

	useEffect(() => {
		if (contributorsData) {
			getUsersInfo();
		}
	}, [contributorsData]);

	useEffect(() => {
		if (checkNotificationClick) {
			window.location.reload();
		}
		setCheckNotificationClick(true);
	}, [activityId, complexityId]);

	useEffect(() => {
		const suggestionItems = document.getElementsByClassName(
			'ck-suggestion-marker'
		);
		for (let i = 0; i < suggestionItems.length; i++) {
			if (suggestionItems[i].attributes['data-suggestion']) {
				const suggestionId =
					suggestionItems[i].attributes['data-suggestion'].value;
				const suggestionText = suggestionItems[i].innerHTML;
				updateSuggestions(suggestionId, suggestionText, 'description');
				updateSuggestions(suggestionId, suggestionText, 'supportingActivities');
				updateSuggestions(suggestionId, suggestionText, 'tipsAndTechniques');
				updateSuggestions(suggestionId, suggestionText, 'deliverables');
			}
		}
	});

	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${activityId}&itemTypeId=2
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId !== 'null'
				? phaseId
				: parentPhaseId !== 'null'
					? parentPhaseId
					: 0
			}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());
			const projectBreadcrumb = {
				title: breadcrumbTitle[1],
				type: 'projectType',
				path: `/project/${projectTypeId}`,
				parent: ['home', 'myContributions'],
			};
			const solutionMethodBreadcrumb = {
				title: breadcrumbTitle[2],
				type: 'solutionMethod',
				path: `/project/${projectTypeId}/method/${solutionMethodId}`,
				parent: 'projectType',
			};
			const phaseBreadcrumb = {
				title: breadcrumbTitle[3],
				type: 'Phase',
				path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`,
				parent: 'solutionMethod',
			};
			const titleBreadcrumb = {
				title: breadcrumbTitle[4],
				type: 'pageTitle',
				path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier}?complexity=${complexityId}`,
				parent: ['phase'],
			};
			const pageBreadcrumb = {
				title: breadcrumbTitle[5],
				type: 'activity',
				path: window.location.pathname + `?complexity=${complexityId}`,
				parent: ['pageTitle'],
			};
			const newBreadcrumbs = breadcrumbs.slice(
				0,
				breadcrumbs.length > 1 && breadcrumbs[1].type === 'myContributions'
					? 2
					: 1
			);
			if (
				phaseId === 'null' &&
				parentPhaseId === 'null' &&
				solutionMethodId === '0'
			) {
				newBreadcrumbs.push(titleBreadcrumb);
				newBreadcrumbs.push(pageBreadcrumb);
			} else {
				newBreadcrumbs.push(projectBreadcrumb);
				newBreadcrumbs.push(solutionMethodBreadcrumb);
				newBreadcrumbs.push(phaseBreadcrumb);
				newBreadcrumbs.push(titleBreadcrumb);
				newBreadcrumbs.push(pageBreadcrumb);
			}
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[5]);
		});
	};

	const getToolsConatinerHeight = () => {
		const height = $('.ToolsContentContainer').height();
		setToolsSidebarHeight(height + 63);
	};

	const getTemplateSidebarHeight = () => {
		const height = $('.templateContentContainer').height();
		setTemplateSidebarHeight(height + 63);
	};

	useEffect(() => {
		getTemplateSidebarHeight();
	});

	useEffect(() => {
		getActivityContents();
	}, [complexityId]);

	useEffect(() => {
		getContributorData();
	}, [complexityId]);

	useEffect(() => {
		if (activityContent) {
			track(2, 131, activityContent.activityId, activityContent.activityName);
			getMessage(activityContent.activityName);
		}
	}, [activityContent]);

	useEffect(() => {
		getRatingPoints();
		loadBreadcrumb();
	}, []);

	useEffect(() => {
		getToolsConatinerHeight();
	});

	useEffect(() => {
		if (contributorsData) {
			setDescription(contributorsData.description);
			setSupportingActivities(contributorsData.supportingActivities);
			setToolsAndGuidance(contributorsData.toolsAndGuidance);
			setTipsAndTechniques(contributorsData.tipsAndTechniques);
			setDeliverables(contributorsData.deliverables);
		}
		if (contributorsData && contributorsData.resultCalloutJson) {
			setDescriptionSuggestions(contributorsData.resultCalloutJson.description);
			setSupportingActivitiesSuggestions(
				contributorsData.resultCalloutJson.supportingActivities
			);
			setContributiorToolsAndGuidanceSuggestion(
				contributorsData.resultCalloutJson.toolsAndGuidance
			);
			setTipsAndTechniquesSuggestions(
				contributorsData.resultCalloutJson.tipsAndTechniques
			);
			setDeliverablesSuggestions(contributorsData.resultCalloutJson.deliverables);
		}
	}, [contributorsData]);
	useEffect(() => {
		checkGamificationStatus();
	}, []);

	if (
		!activityContent ||
		ckEditorUsers.length < 1 ||
		!contributorsData ||
		!licenseKey
	) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}

	if (!reviewerVerified) {
		return <UserNotVerified message={messagesType['reviewPrivilegeMessage']} />;
	}

	if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			userLeaveWithoutSave: true,
		});
	}

	return (
		<div>
			<div className="activityLandinPage activityCrowdSourcing">
				<Breadcrumbs />
				<SuccessMessagePopUp message={submitMessage} />
				<div className="activityMainContainer">
					<div className="activityDescriptionDetails">
						<div className="activityDescTitlePart">
							<div className="activityTitleRightPart">
								<span className="activityVersion">
									{`Document Version: ${activityContent.activityVersion},`}
									&nbsp;&nbsp;{`${activityContent.date} `}
								</span>
								{isEnableComplexity &&<div className="activityComplexity">
									<span>Complexity:</span>
									<Dropdown
										className="complexityDisabled"
										data={complexities}
										setValue={setComplexityName}
										value={complexityName}
										enableDropdown={false}
									/>
								</div>}
								{showDiscardPopUp ? (
									<DiscardPopUp
										setShowDiscardPopUp={setShowDiscardPopUp}
										complexityId={complexityId}
									/>
								) : null}
								{showPreviewAndSubmitPopUp ? (
									<PreviewAndSubmitPopUp
										setShowPreviewAndSubmitPopUp={setShowPreviewAndSubmitPopUp}
										activityContent={activityContent}
										activityDescription={description}
										activitySupportingActivities={supportingActivities}
										templateData={templateData}
										topicData={topicData}
										procedureData={procedureData}
										activityToolsAndGuidance={toolsAndGuidanceSuggestions}
										activityTipsAndTechniques={tipsAndTechniques}
										complexities={complexities}
										setComplexityName={setComplexityName}
										complexityName={complexityName}
										complexityId={complexityId}
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										descriptionSuggestions={descriptionSuggestions}
										supportingActivitiesSuggestions={
											supportingActivitiesSuggestions
										}
										toolsAndGuidanceSuggestions={toolsAndGuidanceSuggestions}
										tipsAndTechniquesSuggestions={tipsAndTechniquesSuggestions}
										reviewedDescriptionSuggestions={
											reviewedDescriptionSuggestions
										}
										reviewedSupportSuggestions={reviewedSupportSuggestions}
										reviewedToolsAndGuidanceSuggestions={
											reviewedToolsAndGuidanceSuggestions
										}
										selectedToolsAndGuidanceSuggestions={
											selectedToolsAndGuidanceSuggestions
										}
										reviewedTipsAndTechniquesSuggestions={
											reviewedTipsAndTechniquesSuggestions
										}
										reviewedTemplateSuggestions={reviewedTemplateSuggestions}
										setLeavingWithoutSave={setLeavingWithoutSave}
										contributorDescSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.description
												: null
										}
										contributorSupportSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.supportingActivities
												: null
										}
										contributorTipsSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.tipsAndTechniques
												: null
										}
										contributorToolsSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.toolsAndGuidance
												: null
										}
										contributorTemplateSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.templateSuggestions
												: null
										}
										contributorEntryCriteriaSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.entryCriteriaSuggestions
												: null
										}
										contributorExitCriteriaSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.exitCriteriaSuggestions
												: null
										}
										contributorTaskSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.taskSuggestions
												: null
										}
										reviewedEntryCriteriaSuggestions={
											reviewedEntryCriteriaSuggestions
										}
										setReviewedEntryCriteriaSuggestions={
											setReviewedEntryCriteriaSuggestions
										}
										reviewedExitCriteriaSuggestions={
											reviewedExitCriteriaSuggestions
										}
										setReviewedExitCriteriaSuggestions={
											setReviewedExitCriteriaSuggestions
										}
										reviewedTaskSuggestions={
											reviewedTaskSuggestions
										}
										setTaskSuggestions={
											setTaskSuggestions
										}
										
										entryCriteriaData={entryCriteriaData}
										exitCriteriaData={exitCriteriaData}
										taskData={taskData}
										entryCriteriaSuggestions={entryCriteriaSuggestions}
										entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
										taskWithSuggestions={taskWithSuggestions}
										taskSuggestions={taskSuggestions}
										exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
										setSubmitMessage={setSubmitMessage}
										contributorsData={contributorsData}
										licenseKey={licenseKey}
										toolsUrl={toolsUrl}
										noContributionMessage={
											messagesType['noContributionMessage']
										}
										reviewError={messagesType['reviewError']}
										messagesType={messagesType}
										contributorSuggestionsInfo={contributorSuggestionsInfo}
										existingHashtags={existingHashtags}
										ratingPoints={ratingPoints}
										enableGamification={enableGamification}
										templateSuggestions={templateSuggestions}
										activityDeliverables={deliverables}
										deliverablesSuggestions={deliverablesSuggestions}
										reviewedDeliverablesSuggestions={
											reviewedDeliverablesSuggestions
										}
										contributorDeliverablesSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.deliverables
												: null
										}
										taskReorderArray={isOrderChanged.task  ? taskReorderArray:null}
										exitCriteriaReorderArray={isOrderChanged.exit ? exitCriteriaReorderArray: null}
										entryCriteriaReorderArray={isOrderChanged.entry ? entryCriteriaReorderArray : null}
										inputCriteriaReorderArray={isOrderChanged.input ? inputCriteriaReorderArray: null}
										outputCriteriaReorderArray={isOrderChanged.output ? outputCriteriaReorderArray:null }
										reviewedReorderedTaskSuggestions={reviewedReorderedTaskSuggestions.length ? reviewedReorderedTaskSuggestions: null}
										reviewedReorderedEntrySuggestions={reviewedReorderedEntrySuggestions.length ? reviewedReorderedEntrySuggestions:null}
										reviewedReorderedExitSuggestions={reviewedReorderedExitSuggestions.length ? reviewedReorderedExitSuggestions:null}
										reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions.length ?reviewedReorderedInputSuggestions:null}
										reviewedReorderedOutputSuggestions={reviewedReorderedOutputSuggestions.length ?reviewedReorderedOutputSuggestions:null}
										isReviewerTaskReordered={isReviewerTaskReordered}
										isReviewerEntryReordered={isReviewerEntryReordered}
										isReviewerInputReordered={isReviewerInputReordered}
										isReviewerExitReordered={isReviewerExitReordered} 
	                                    isReviewerOutputReordered={isReviewerOutputReordered} 
										reorderedTaskRejectionCardInfo={reorderedTaskRejectionCardInfo}
                                        reorderedEntryRejectionCardInfo={reorderedEntryRejectionCardInfo}
                                        reorderedInputRejectionCardInfo={reorderedInputRejectionCardInfo}
                                        reorderedExitRejectionCardInfo={reorderedExitRejectionCardInfo}
                                        reorderedOutputRejectionCardInfo={reorderedOutputRejectionCardInfo}
										user={user}
										reorderContributionCount ={reorderContributionCount}
										addedTaskSequence={addedTaskSequence.length? addedTaskSequence:null}
                                        addedEntrySequence={addedEntrySequence.length? addedEntrySequence:null}
                                        addedExitSequence={addedExitSequence.length?addedExitSequence:null}
									/>
								) : null}
								<div className="discardAndPreviewButton">
									<Button
										title="Discard"
										className="activityDiscardButton"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowDiscardPopUp(true);
											}
										}}
										onClick={() => {
											setShowDiscardPopUp(true);
										}}
									>
										Discard
									</Button>
									<Button
										title="Preview &amp; Submit"
										className="activityPreviewButton"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowPreviewAndSubmitPopUp(true);
											}
										}}
										onClick={() => {
											setShowPreviewAndSubmitPopUp(true);
										}}
									>
										Preview &amp; Submit
									</Button>
								</div>
							</div>
						</div>
						<div className="descOfActivity">
							<h4
								title={activityContent.activityName}
								className="activityLandingPageTitle"
							>
								{activityContent.activityName}
							</h4>
							<div class="existingHashTagsOfActivity">
								{existingHashtags.map((name) => (
									<span
										key={name}
										onClick={() => {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												history.push(
													`/search?searchParam=${encodeURIComponent(name)}`
												);
											}
										}}
									>
										{name}
									</span>
								))}
							</div>
							{Number(contributorsData.stateId) === 0 ? (
								<div className="WarningMessageActivityPage">
									<MessageBar
										messageBarType={MessageBarType.warning}
										isMultiline={false}
									>
										You are a reviewer for this activity, any contributions that
										you suggest will go directly to the approver for approvals.
									</MessageBar>
								</div>
							) : null}
							<ActivityRaciMatrix activityData={activityContent} />
							<div className="innerContent" id="innerContent">
								<h2
									className="sub-heading"
									id="desc"
									aria-label="Description"
									tabIndex={0}
								>
									Description
								</h2>
								<TemporaryCkAlert width={'77'}/>
								{contributorsData.description ? (
									<DescriptionCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData={contributorsData.description}
										setContent={setDescription}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.description
												: null
										}
										setSuggestions={setDescriptionSuggestions}
										setReviewedSuggestion={setReviewedDescriptionSuggestions}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								) : (
									<DescriptionCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData=" "
										setContent={setDescription}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.description
												: null
										}
										setSuggestions={setDescriptionSuggestions}
										setReviewedSuggestion={setReviewedDescriptionSuggestions}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								)}
								<h2 className="sub-heading" id="supportAct">
									Supporting Activities
								</h2>
								<TemporaryCkAlert width={'77'}/>
								{contributorsData.supportingActivities ? (
									<SupportAndActivitiesCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData={contributorsData.supportingActivities}
										setContent={setSupportingActivities}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.supportingActivities
												: null
										}
										setSuggestions={setSupportingActivitiesSuggestions}
										setReviewedSuggestion={setReviewedSupportSuggestions}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								) : (
									<SupportAndActivitiesCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData=" "
										setContent={setSupportingActivities}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson
													.supportingActivities
												: null
										}
										setSuggestions={setSupportingActivitiesSuggestions}
										setReviewedSuggestion={setReviewedSupportSuggestions}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								)}

								<div className="innerContent" id="innerContent">
									<h2 className="sub-heading entryCriteria-heading">
										<span aria-label="entry criteria" tabIndex={0}>
											Entry Criteria/Input
										</span>

										<div className="contributorAddTemplates">
											<span
												className={
													entryCriteriaButtonClicked
														? 'contributorAddTemplatesButton hiddenEntryCriteria'
														: 'contributorAddTemplatesButton '
												}
												tabIndex={0}
												title="Add entry criteria"
												role="button"
												aria-label="Click to Add a new Criteria"
												onKeyPress={(e) => {
													if (e.which === 13) {
														setEntryCriteriaButtonClicked(true);
													}
												}}
												onClick={() => {
													setEntryCriteriaButtonClicked(true);
												}}
											>
												Add{' '}
												{currentEntryTab === 'tab-entry'
													? 'Entry Criteria'
													: 'Input'}
												<span className="phaseAddIcon"></span>
											</span>
										</div>
									</h2>
									<EntryCriteriaContribution
										contributorsData={contributorsData}
										entryCriteriaButtonClicked={entryCriteriaButtonClicked}
										setEntryCriteriaButtonClicked={
											setEntryCriteriaButtonClicked
										}
										ckEditorUserMap={ckEditorUserMap}
										CkEditorCurrentUser={CkEditorCurrentUser}
										entryCriteriaSuggestions={entryCriteriaSuggestions}
										setEntryCriteriaSuggestions={setEntryCriteriaSuggestions}
										entryCriteriaWithSuggestions={entryCriteriaWithSuggestions}
										setEntryCriteriaWithSuggestions={
											setEntryCriteriaWithSuggestions
										}
										reviewedEntryCriteriaSuggestions={
											reviewedEntryCriteriaSuggestions
										}
										setReviewedEntryCriteriaSuggestions={
											setReviewedEntryCriteriaSuggestions
										}
										source="review"
										reviewerEntryCriteriaSuggestions={reviewerEntryCriteriaSuggestions}
										entryCriteriaData={entryCriteriaData}
										setEntryCriteriaData={setEntryCriteriaData}
										currentTab={currentEntryTab}
										setCurrentTab={setCurrentEntryTab}
										entryCriteriaReorderArray={entryCriteriaReorderArray}
										inputCriteriaReorderArray={inputCriteriaReorderArray}
										setInputCriteriaReorderArray={setInputCriteriaReorderArray}
										setEntryCriteriaReorderArray={setEntryCriteriaReorderArray}
										reviewedReorderedEntrySuggestions = {reviewedReorderedEntrySuggestions}
										setReviewedReorderedEntrySuggestions = {setReviewedReorderedEntrySuggestions}
										reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions}
										setReviewedReorderedInputSuggestions={setReviewedReorderedInputSuggestions}
										entryContributionReorder={entryContributionReorder}
										setEntryContributionReorder={setEntryContributionReorder}
										setInputContributionReorder={setInputContributionReorder}
										inputContributionReorder={inputContributionReorder}		
										initialEntryIdBeforeReorder ={initialEntryIdBeforeReorder}
										setInitialEntryIdBeforeReorder = {setInitialEntryIdBeforeReorder}
										disableEntryDrag ={disableEntryDrag}
										setDisableEntryDrag = {setDisableEntryDrag}
										disableInputDrag ={disableInputDrag}
										setDisableInputDrag={setDisableInputDrag}
										ckEditorCurrentEntryReorderUsername={ckEditorCurrentEntryReorderUsername}
                                        setCkEditorCurrentEntryReorderUsername={setCkEditorCurrentEntryReorderUsername}
                                        ckEditorCurrentEntryReorderUserImage={ckEditorCurrentEntryReorderUserImage}
                                        setCkEditorCurrentEntryReorderUserImage={setCkEditorCurrentEntryReorderUserImage}
									    ckEditorCurrentInputReorderUserImage={ckEditorCurrentInputReorderUserImage}
										setCkEditorCurrentInputReorderUserImage={setCkEditorCurrentInputReorderUserImage}
									    ckEditorCurrentInputReorderUsername={ckEditorCurrentInputReorderUsername}
										setCkEditorCurrentInputReorderUsername={setCkEditorCurrentInputReorderUsername}
										user={user}
										isReviewerEntryReordered={isReviewerEntryReordered}
										setIsReviewerEntryReordered={setIsReviewerEntryReordered}
										isReviewerInputReordered={isReviewerInputReordered}
										setIsReviewerInputReordered={setIsReviewerInputReordered}
										setReorderContributionCount = {setReorderContributionCount}
										setAddedEntrySequence={setAddedEntrySequence}
										setIsOrderChanged={setIsOrderChanged}
									/>
								</div>

								<div className="innerContent" id="innerContent">
									<h2 className="sub-heading entryCriteria-heading">
										<span aria-label="entry criteria" tabIndex={0}>
											Task
										</span>

										<div className="contributorAddTemplates">
											<span
												className={
													taskButtonClicked
														? 'contributorAddTemplatesButton contributorAddTemplatesButtonTask hiddenEntryCriteria'
														: 'contributorAddTemplatesButton contributorAddTemplatesButtonTask'
												}
												tabIndex={0}
												title="Add task"
												role="button"
												aria-label="Click to Add a task"
												onKeyPress={(e) => {
													if (e.which === 13) {
														setTaskButtonClicked(true);
													}
												}}
												onClick={() => {
													setTaskButtonClicked(true);
												}}
											>
												Add Task
												<span className="phaseAddIcon"></span>
											</span>
										</div>
									</h2>
									<TaskContribution
										contributorsData={contributorsData}
										taskButtonClicked={taskButtonClicked}
										setTaskButtonClicked={
											setTaskButtonClicked
										}
										ckEditorUserMap={ckEditorUserMap}
										CkEditorCurrentUser={CkEditorCurrentUser}
										taskSuggestions={taskSuggestions}
										setTaskSuggestions={setTaskSuggestions}
										taskWithSuggestions={taskWithSuggestions}
										setTaskWithSuggestions={
											setTaskWithSuggestions
										}
										reviewedTaskSuggestions={
											reviewedTaskSuggestions
										}
										setReviewedTaskSuggestions={
											setReviewedTaskSuggestions
										}
										reviewedReorderedTaskSuggestions={
                                            reviewedReorderedTaskSuggestions
                                        }
                                        setReviewedReorderedTaskSuggestions={
                                            setReviewedReorderedTaskSuggestions
                                        }
										source="review"
										taskData={taskData}
										setTaskData={setTaskData}
										reviewerTaskSuggestions={reviewerTaskSuggestions}
										taskReorder={taskReorder}
										setTaskReorder={setTaskReorder}
										taskReorderArray={taskReorderArray}
										setTaskReorderArray={setTaskReorderArray}
										initialTaskIdBeforeReorder={initialTaskIdBeforeReorder}
										setInitialTaskIdBeforeReorder={setInitialTaskIdBeforeReorder}
										disableDrag={disableDrag} 
										setDisableDrag={setDisableDrag}
										ckEditorCurrentReorderUsername={ckEditorCurrentReorderUsername}
                                        setCkEditorCurrentReorderUsername={setCkEditorCurrentReorderUsername}
										ckEditorCurrentReorderUserImage={ckEditorCurrentReorderUserImage}
                                        setCkEditorCurrentReorderUserImage={setCkEditorCurrentReorderUserImage}
										user={user}
										isReviewerTaskReordered={isReviewerTaskReordered}
										setIsReviewerTaskReordered={setIsReviewerTaskReordered}
										setReorderContributionCount = {setReorderContributionCount}
										setAddedTaskSequence={setAddedTaskSequence}
										setIsOrderChanged={setIsOrderChanged}
									/>
								</div>

								<div className="innerContent" id="innerContent">
									<h2 className="sub-heading exitCriteria-heading">
										<span aria-label="exit criteria" tabIndex={0}>
											Exit Criteria/Output
										</span>
										<div className="contributorAddTemplates">
											<span
												className={
													exitCriteriaButtonClicked
														? 'contributorAddTemplatesButton hiddenExitCriteria'
														: 'contributorAddTemplatesButton '
												}
												tabIndex={0}
												title="Add exit criteria"
												role="button"
												aria-label="Click to Add a new Criteria"
												onKeyPress={(e) => {
													if (e.which === 13) {
														setExitCriteriaButtonClicked(true);
													}
												}}
												onClick={() => {
													setExitCriteriaButtonClicked(true);
												}}
											>
												Add{' '}
												{currentExitTab === 'tab-exit'
													? 'Exit Criteria'
													: 'Output'}
												<span className="phaseAddIcon"></span>
											</span>
										</div>
									</h2>
									<ExitCriteriaContribution
										contributorsData={contributorsData}
										exitCriteriaButtonClicked={exitCriteriaButtonClicked}
										setExitCriteriaButtonClicked={setExitCriteriaButtonClicked}
										ckEditorUserMap={ckEditorUserMap}
										CkEditorCurrentUser={CkEditorCurrentUser}
										exitCriteriaSuggestions={exitCriteriaSuggestions}
										setExitCriteriaSuggestions={setExitCriteriaSuggestions}
										exitCriteriaWithSuggestions={exitCriteriaWithSuggestions}
										setExitCriteriaWithSuggestions={
											setExitCriteriaWithSuggestions
										}
										outputButtonClicked={outputButtonClicked}
										setOutputButtonClicked={setOutputButtonClicked}
										outputSuggestions={outputSuggestions}
										setOutputSuggestions={setOutputSuggestions}
										reviewedExitCriteriaSuggestions={
											reviewedExitCriteriaSuggestions
										}
										setReviewedExitCriteriaSuggestions={
											setReviewedExitCriteriaSuggestions
										}
										source="review"
										reviewerExitCriteriaSuggestions={reviewerExitCriteriaSuggestions}
										exitCriteriaData={exitCriteriaData}
										setExitCriteriaData={setExitCriteriaData}
										currentTab={currentExitTab}
										setCurrentTab={setCurrentExitTab}
										exitCriteriaReorderArray={exitCriteriaReorderArray}
										outputCriteriaReorderArray={outputCriteriaReorderArray}
										setOutputCriteriaReorderArray={setOutputCriteriaReorderArray}
										setExitCriteriaReorderArray={setExitCriteriaReorderArray}
										reviewedReorderedExitSuggestions = {reviewedReorderedExitSuggestions}
										setReviewedReorderedExitSuggestions ={setReviewedReorderedExitSuggestions}
										reviewedReorderedOutputSuggestions ={reviewedReorderedOutputSuggestions}
										setReviewedReorderedOutputSuggestions ={setReviewedReorderedOutputSuggestions}
										initialExitIdBeforeReorder={initialExitIdBeforeReorder}
										setInitialExitIdBeforeReorder={setInitialExitIdBeforeReorder}
										exitContributionReorder={exitContributionReorder}
										setExitContributionReorder={setExitContributionReorder}
										outputContributionReorder={outputContributionReorder}
										setOutputContributionReorder={setOutputContributionReorder}
										disableExitDrag={disableExitDrag} 
										setDisableExitDrag={setDisableExitDrag}
	                                    disableOutputDrag ={disableOutputDrag}
										setDisableOutputDrag={setDisableOutputDrag}  
										ckEditorCurrentExitReorderUserImage={ckEditorCurrentExitReorderUserImage}
										setCkEditorCurrentExitReorderUserImage={setCkEditorCurrentExitReorderUserImage}
										ckEditorCurrentExitReorderUsername={ckEditorCurrentExitReorderUsername} 
										setCkEditorCurrentExitReorderUsername={setCkEditorCurrentExitReorderUsername} 
										ckEditorCurrentOutputReorderUserImage={ckEditorCurrentOutputReorderUserImage}
										setCkEditorCurrentOutputReorderUserImage={setCkEditorCurrentOutputReorderUserImage}
										ckEditorCurrentOutputReorderUsername={ckEditorCurrentOutputReorderUsername}
										setCkEditorCurrentOutputReorderUsername={setCkEditorCurrentOutputReorderUsername} 
										user={user}
										isReviewerExitReordered={isReviewerExitReordered} 
										setIsReviewerExitReordered={setIsReviewerExitReordered}
	                                    isReviewerOutputReordered={isReviewerOutputReordered} 
										setIsReviewerOutputReordered={setIsReviewerOutputReordered}
										setReorderContributionCount = {setReorderContributionCount}
										setAddedExitSequence={setAddedExitSequence}
										setIsOrderChanged={setIsOrderChanged}
									/>
								</div>

								{procedureData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Procedures
											<div className="nonEditableSection" tabIndex={0}>
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{procedureData.length > 0 ? (
											<div id="activityProcedure">
												<ul>
													{procedureData.map((procedure) => (
														<li>
															{
																<a href={procedure.url} target="_blank">
																	{procedure.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}
								{topicData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Related Topics
											<div className="nonEditableSection" tabIndex={0}>
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{topicData.length > 0 ? (
											<div id="topicList">
												<ul>
													{topicData.map((topic) => (
														<li>
															<a href={topic.url} target="_blank">
																{topic.name}
															</a>
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}

								<div className="addingTemplatesEditAcivity">
									<h2 className="sub-heading" id="deliver">
										<span aria-label="Deliverables" tabIndex={0}>
											Deliverables
										</span>

										<div class="contributorAddTemplates">
											<div>
												<span className="delTemplateAccessabilityIcon"></span>
												<span>
													Can't find the template you are looking for?
												</span>
											</div>
											<span
												class="contributorAddTemplatesButton"
												onClick={() => {
													setShowTemplateBuilder(true);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setShowTemplateBuilder(true);
													}
												}}
												tabIndex={0}
												role="button"
												title="Add a new Template"
												aria-label="Click to Add a new Template"
											>
												<span>Add a new Template</span>
												<span class="phaseAddIcon"></span>
											</span>
										</div>
									</h2>
									<TemplateCrowdSourcing
										templateData={templateData}
										toolsSidebarHeight={templateSidebarHeight}
										CkEditorCurrentUser={CkEditorCurrentUser}
										templateSuggestions={templateSuggestions}
										setTemplateSuggestions={setTemplateSuggestions}
										setTemplateData={setTemplateData}
										ckEditorUserMap={ckEditorUserMap}
										source="review"
										reviewedTemplateSuggestions={reviewedTemplateSuggestions}
										setReviewedTemplateSuggestions={
											setReviewedTemplateSuggestions
										}
										reviewerTemplateSuggestions={reviewerTemplateSuggestions}

									/>
									{/* {activityContent.activityDeliverables ? (
										<div
											id="activityDeliverables"
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(
													activityContent.activityDeliverables
												),
											}}
										/>
									) : null} */}

									<div className='deliverables-container'>
									<TemporaryCkAlert width={'77'}/>
										<h2
											className="sub-heading"
											id="desc"
											aria-label="Description"
										>
											Description
										</h2>
										{contributorsData.deliverables ? (
											<DeliverableCkEditor
												users={ckEditorUsers}
												currentUser={CkEditorCurrentUser}
												initialData={contributorsData.deliverables}
												setContent={setDeliverables}
												initalSuggestions={
													contributorsData.resultCalloutJson
														? contributorsData.resultCalloutJson.deliverables
														: null
												}
												setSuggestions={setDeliverablesSuggestions}
												setReviewedSuggestion={setReviewedDeliverablesSuggestions}
												licenseKey={licenseKey}
												stateId={contributorsData.stateId}
											/>
										) : (
											<DeliverableCkEditor
												users={ckEditorUsers}
												currentUser={CkEditorCurrentUser}
												initialData=" "
												setContent={setDeliverables}
												initalSuggestions={
													contributorsData.resultCalloutJson
														? contributorsData.resultCalloutJson.deliverables
														: null
												}
												setSuggestions={setDeliverablesSuggestions}
												setReviewedSuggestion={setReviewedDeliverablesSuggestions}
												licenseKey={licenseKey}
												stateId={contributorsData.stateId}
											/>
										)}
									</div>
								</div>
								<h2
									className="sub-heading"
									id="tool"
									aria-label="Tools"
									tabIndex={0}
								>
									Tools
								</h2>
								<div className="customToolsEditor">
									<div className="toolsEditorMainContainer">
										<div className="toolsHeaderEditor selectTools">
											<h6>Tools</h6>
											<MultiSelectDropdownFluentReviewer
												className="toolsAndGuidanceMultiSelectFilter"
												value={selectedToolsAndGuidanceSuggestions}
												setValue={setSelectedToolsAndGuidanceSuggestions}
												setToolsAndGuidanceSuggestions={
													setToolsAndGuidanceSuggestions
												}
												enableDropdown={true}
												toolsAndGuidanceSuggestions={
													toolsAndGuidanceSuggestions
												}
												adminToolsAndGuidance={adminToolsAndGuidance}
												currentUser={CkEditorCurrentUser}
												checkForAdminToolsUpdate={checkForAdminToolsUpdate}
												setCheckForAdminToolsUpdate={
													setCheckForAdminToolsUpdate
												}
												setContributiorToolsAndGuidanceSuggestion={
													setContributiorToolsAndGuidanceSuggestion
												}
												contributiorToolsAndGuidanceSuggestion={
													contributiorToolsAndGuidanceSuggestion
												}
												pageType="review"
												contributiorToolsId={contributiorToolsId}
												reviewedTools={reviewedTools}
												setReviewedTools={setReviewedTools}
												updateContributorSuggestion={
													updateContributorSuggestion
												}
											/>											
										</div>
										<div className="ToolsContentContainer">
											<ul>
												{selectedToolsAndGuidanceSuggestions.map((item) => (
													<>
														{!adminToolsAndGuidance.includes(
															JSON.parse(item).toolsId
														) ? (
															<li
																className={
																	JSON.parse(item).suggestionType ===
																		'deletion' &&
																		!reviewedTools.includes(
																			JSON.parse(item).toolsId
																		)
																		? 'userDeletedToolsAndGuidance'
																		: 'userSelectedToolsAndGuidance'
																}
															>
																<div className={'userAddedItem'}>
																	<a href={toolsUrl[JSON.parse(item).toolsId]}>
																		{JSON.parse(item).name}
																	</a>
																</div>
															</li>
														) : (
															<li
																className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType ===
																	'deletion' &&
																	JSON.parse(item).authorId ===
																	CkEditorCurrentUser
																	? 'userDeletedToolsAndGuidance'
																	: 'none'
																	}`}
															>
																<a
																	href={toolsUrl[JSON.parse(item).toolsId]}
																	target="_blank"
																>
																	{JSON.parse(item).name}
																</a>
																<Icon
																	ariaLabel={`${JSON.parse(item).name} Remove`}
																	iconName="Cancel"
																	onClick={() =>
																		removeToolsAndGuidance(
																			'admin',
																			JSON.parse(item)
																		)
																	}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			removeToolsAndGuidance(
																				'admin',
																				JSON.parse(item)
																			);
																		}
																	}}
																	role="button"
																	tabIndex={0}
																/>
															</li>
														)}
													</>
												))}
											</ul>
										</div>
									</div>
									<div
										className="toolsSidebar"
										style={{ height: toolsSidebarHeight }}
									>
										{sidebarCard.map((card) => (
											<>
												{JSON.parse(card).authorId !== CkEditorCurrentUser &&
													!reviewedTools.includes(JSON.parse(card).toolsId) &&
													!adminToolsAndGuidance.includes(
														JSON.parse(card).toolsId
													) ? (
													<>
														<div
															className={`sideBarCard ${JSON.parse(card).suggestionType === 'insertion'
																? 'userAddedItem'
																: 'userDeletedToolsAndGuidance'
																}`}
														>
															<div className="sideBarCardHeader">
																<img
																	className="userImg"
																	src={
																		ckEditorUserMap[
																		JSON.parse(card).authorId
																		][1]
																	}
																	alt=""
																/>
																<span>
																	{
																		ckEditorUserMap[
																		JSON.parse(card).authorId
																		][0]
																	}
																</span>

																<Icon
																	title="Reject Suggestion"
																	ariaLabel={`click to reject ${JSON.parse(card).name
																		} as suggestion`}
																	role="button"
																	iconName="Cancel"
																	onClick={() =>
																		updateContributorSuggestion(
																			'reject',
																			JSON.parse(card),
																			JSON.parse(card).suggestionType
																		)
																	}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			updateContributorSuggestion(
																				'reject',
																				JSON.parse(card),
																				JSON.parse(card).suggestionType
																			)
																		}
																	  }}

																	tabIndex={0}
																/>
																<Icon
																	title="Accept Suggestion"
																	iconName="Accept"
																	onClick={() =>
																		updateContributorSuggestion(
																			'accept',
																			JSON.parse(card),
																			JSON.parse(card).suggestionType
																		)
																	}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			updateContributorSuggestion(
																				'accept',
																				JSON.parse(card),
																				JSON.parse(card).suggestionType
																			);
																		}
																	}}
																	ariaLabel={`click to Accept ${JSON.parse(card).name
																		} as suggestion`}
																	role="button"
																	tabIndex={0}
																/>
																<div className="itemStatus">
																	<span>
																		{JSON.parse(card).suggestionType ===
																			'insertion'
																			? 'Add'
																			: 'Remove'}
																	</span>
																	&nbsp;"{JSON.parse(card).name}"
																</div>
															</div>
														</div>
													</>
												) : (
													<>
														{JSON.parse(card).authorId ===
															CkEditorCurrentUser &&
															JSON.parse(card).suggestionType === 'insertion' ? (
															<>
																<div
																	className={`sideBarCard ${JSON.parse(card).suggestionType ===
																		'insertion'
																		? 'userAddedItem'
																		: 'userDeletedToolsAndGuidance'
																		}`}
																>
																	<div className="sideBarCardHeader">
																		<img
																			className="userImg"
																			src={
																				ckEditorUserMap[
																				JSON.parse(card).authorId
																				][1]
																			}
																			alt=""
																		/>
																		<span>
																			{
																				ckEditorUserMap[
																				JSON.parse(card).authorId
																				][0]
																			}
																		</span>
																		<Icon
																			title="Reject Suggestion"
																			iconName="Cancel"
																			onClick={() =>
																				removeToolsAndGuidance(
																					'insertion',
																					JSON.parse(card)
																				)
																			}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					removeToolsAndGuidance(
																						'insertion',
																						JSON.parse(card)
																					);
																				}
																			}}
																			ariaLabel={`click to Add ${JSON.parse(card).name
																				} as suggestion`}
																			role="button"
																			tabIndex={0}
																		/>
																		<div className="itemStatus">
																			<span>Add</span>&nbsp;"
																			{JSON.parse(card).name}"
																		</div>
																	</div>
																</div>
															</>
														) : (
															<>
																{JSON.parse(card).suggestionType ===
																	'deletion' &&
																	JSON.parse(card).authorId ===
																	CkEditorCurrentUser ? (
																	<div
																		className={`sideBarCard ${JSON.parse(card).suggestionType ===
																			'insertion'
																			? 'userAddedItem'
																			: 'userDeletedToolsAndGuidance'
																			}`}
																	>
																		<div className="sideBarCardHeader">
																			<img
																				className="userImg"
																				src={
																					ckEditorUserMap[
																					JSON.parse(card).authorId
																					][1]
																				}
																				alt=""
																			/>
																			<span>
																				{
																					ckEditorUserMap[
																					JSON.parse(card).authorId
																					][0]
																				}
																			</span>
																			<Icon
																				title="Reject Suggestion"
																				ariaLabel={`click to reject ${JSON.parse(card).name
																					} as suggestion`}
																				role="button"
																				iconName="Cancel"
																				onClick={() =>
																					removeToolsAndGuidance(
																						'adminToolsDeletion',
																						JSON.parse(card)
																					)
																				}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						removeToolsAndGuidance(
																							'adminToolsDeletion',
																							JSON.parse(card)
																						)
																					}
																				  }}
																				tabIndex={0}
																			/>
																		</div>
																		<div className="itemStatus">
																			<span>Remove</span>&nbsp;"
																			{JSON.parse(card).name}"
																		</div>
																	</div>
																) : (
																	<></>
																)}
															</>
														)}
													</>
												)}
											</>
										))}
									</div>
								</div>
								<h2 className="sub-heading" id="tipstech">
									Guidance, Tips and Techniques
								</h2>
								<TemporaryCkAlert width={'77'}/>
								{contributorsData.tipsAndTechniques ? (
									<TipsAndTechniquesCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData={contributorsData.tipsAndTechniques}
										setContent={setTipsAndTechniques}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.tipsAndTechniques
												: null
										}
										setSuggestions={setTipsAndTechniquesSuggestions}
										setReviewedSuggestion={
											setReviewedTipsAndTechniquesSuggestions
										}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								) : (
									<TipsAndTechniquesCkEditor
										users={ckEditorUsers}
										currentUser={CkEditorCurrentUser}
										initialData=" "
										setContent={setTipsAndTechniques}
										initalSuggestions={
											contributorsData.resultCalloutJson
												? contributorsData.resultCalloutJson.tipsAndTechniques
												: null
										}
										setSuggestions={setTipsAndTechniquesSuggestions}
										setReviewedSuggestion={
											setReviewedTipsAndTechniquesSuggestions
										}
										licenseKey={licenseKey}
										stateId={contributorsData.stateId}
									/>
								)}
							</div>
							<Prompt
								when={
									leavingWithoutSave &&
									!showDiscardPopUp &&
									contributorsData.stateId !== 2 &&
									!isActivityLock
								}
								message="You have unsaved changes, are you sure you want to leave?"
							/>
							{(contributorsData.stateId === 2 && !showHistory) ||
								isActivityLock ? (
								<ReviewedPopUp
									contributorContent={
										contributorsData.lastContributorActivityContent
									}
									isActivityLock={isActivityLock}
									lockedByUsername={lockedByUsername}
									activityLockedBy={activityLockedBy}
								/>
							) : null}
							{contributorsData.stateId === 2 && showHistory ? (
								<HistoryPreview
									contriutorLastSuggestions={contributorsData.resultCalloutJson}
									reviewedSuggestions={contributorsData.reviewedSuggestionsJson}
									contributorContent={
										contributorsData.lastContributorActivityContent
									}
									activityContent={activityContent}
									templateData={templateData}
									topicData={topicData}
									procedureData={procedureData}
									entryCriteriaData={entryCriteriaData}
									setEntryCriteriaData={setEntryCriteriaData}
									exitCriteriaData={exitCriteriaData}
									setExitCriteriaData={setExitCriteriaData}
									taskData={taskData}
									setTaskData={setTaskData}
									complexities={complexities}
									setComplexityName={setComplexityName}
									complexityName={complexityName}
									complexityId={complexityId}
									users={ckEditorUsers}
									currentUser={CkEditorCurrentUser}
									historyToolsId={historyToolsId}
									historicalPreviewTools={historicalPreviewTools}
									historyToolsSuggestion={historyToolsSuggestion}
									toolsReviewedIds={toolsReviewedIds}
									ckEditorUserMap={ckEditorUserMap}
									reviewerInfo={reviewerInfo}
									activityToolsList={contributorsData.activityToolsList}
									toolsUrl={toolsUrl}
									existingHashtags={existingHashtags}
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
			{showTemplateBuilder ? (
				<TemplateBuilder
					showActivityTemplateBuilder={showTemplateBuilder}
					setShowActivityTemplateBuilder={setShowTemplateBuilder}
					activityId={activityContent.activityId}
					complexityId={complexityId}
					pageType="crowdSource"
					addTemplateSuggestion={addTemplateToSuggestion}
					existingTemplates={getExistingTemplates()}
					stateUpdate={false}
				/>
			) : (
				<></>
			)}
			{enableTemplatePreview ? (
				<PreviewModal
					enableTemplatePreview={enableTemplatePreview}
					closeModal={() => setEnableTemplatePreview(false)}
					data={templatePreviewData}
				/>
			) : (
				<></>
			)}
			{enableFileDropModal ? (
				<FileDropModal
					enableFileDropModal={enableFileDropModal}
					closeModal={() => setEnableFileDropModal(false)}
					data={sampleTemplates.parentTemplate}
				/>
			) : (
				<></>
			)}
			<Modal
				show={sampleTemplates.enable}
				onHide={() => {
					setSampleTemplates({
						id: null,
						enable: false,
						parentTemplate: null,
					});
				}}
				centered
				dialogClassName="templateModal"
			>
				{sampleTemplates.enable ? (
					<SampleTemplateModal
						id={sampleTemplates.id}
						handleTemplatePreview={handleTemplatePreview}
						setEnableFileDropModal={setEnableFileDropModal}
						closeModal={() => {
							setSampleTemplates({
								id: null,
								enable: false,
								parentTemplate: null,
							});
						}}
						enableFileDropModal={enableFileDropModal}
						complexityId={complexityName.id}
					/>
				) : (
					<></>
				)}
			</Modal>
		</div>
	);
}

export default ReviewerActivityCrowdSourcing;
