import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import HtmlDiff from 'htmldiff-js';

const TopicsAdminHistory = ({
	showAdminHistory,
	setShowAdminHistory,
	historyData,
	versionDetails,
}) => {
	const tabs = [historyData.roleName];
	const [selectedTab, setSelectedTab] = useState(historyData.roleName);

	const getVersionDifference = (oldData, newData) => {
		return HtmlDiff.execute(oldData, newData);
	};

	return (
		<Modal
			dialogClassName={'topicsCrowdsourceHistoryModal'}
			show={showAdminHistory}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				setShowAdminHistory(false);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<div>
						<h5>Historical Preview</h5>
						<div>
							<span>
								Document Version:&nbsp;{versionDetails.versionNumber},&nbsp;
								{versionDetails.modifiedDate}
							</span>
						</div>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="topicsCrowdsourcingHistoryModalContainer historyContainer">
					<div className="topicsMenuContainer">
						<ul className="topicsMenu">
							{tabs.map((item) => (
								<li
									name={item}
									tabIndex="0"
									className={
										selectedTab === item
											? 'selectedTopicMenu'
											: 'notSelectedTopicMenu'
									}
									onClick={() => {
										setSelectedTab(item);
									}}
								>
									<span className="consultingMenu">{item}</span>
								</li>
							))}
						</ul>
					</div>
					<div className="topicsCrowdsourcingHistoryContentContainer">
						<div className="PreviewAndSubmitPopUp historicalPreview">
							<div className="topicsCrowdSourcingContainer">
								<div className="topicsDescriptionDetails">
									<div className="topicDescriptionDiv">
										<h4 className="topicTitle">
											<div
												dangerouslySetInnerHTML={{
													__html: getVersionDifference(
														historyData.previousVersionTopicName,
														historyData.topicName
													),
												}}
											/>
										</h4>
										<div class="existingHashTagsOfActivity methodOwner">
											{historyData.previousVersionHashTagsList.map(
												({ name }) => {
													if (
														historyData.hashTagsList.some(
															(hashTag) => hashTag.name === name
														)
													) {
														return <span key={name}>{name}</span>;
													} else {
														return (
															<span key={name}>
																<del class="diffmod">{name}</del>
															</span>
														);
													}
												}
											)}
											{historyData.hashTagsList.map(({ name }) => {
												if (
													!historyData.previousVersionHashTagsList.some(
														(hashTag) => hashTag.name === name
													)
												) {
													return (
														<span key={name}>
															<ins class="diffmod">{name}</ins>
														</span>
													);
												}
											})}
										</div>
										<div
											dangerouslySetInnerHTML={{
												__html: getVersionDifference(
													historyData.previousVersionDescription,
													historyData.description
												),
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopicsAdminHistory;
