import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { artifactMapData } from '../../static/ArtifactMapData';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';

const DeleteActivity = ({
	deleteTopicInfo,
	setDeleteTopicInfo,
	setShowDeleteTopicModal,
	userAction,
	setUserAction,
}) => {
	const { track } = useAppInsights();
	const { axiosGetService, axiosDeleteService } = useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(false);
	const [showSpinner, setShowSpinner] = useState(true);
	const [isTopicLock, setIsTopicLock] = useState(false);

	const deleteTopic = async () => {
		await axiosDeleteService(
			`api/sdmtopics/deletetopic/${deleteTopicInfo.id}`
		).then((response) => {
			if (response.data.status) {
				track(1, 1804, deleteTopicInfo.id, deleteTopicInfo.title);
				setSuccessMessage(true);
				setUserAction(!userAction);
			} else {
				setErrorMessage(response.data.errorMessage);
				setTimeout(() => {
					setErrorMessage(null);
				}, 5000);
			}
		});
	};

	const getLockStatus = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifacts?artifactId=${
				deleteTopicInfo.id ? deleteTopicInfo.id : 0
			}&artifactTypeConstId=${artifactMapData['topics']}&artifactDraftId=${0}`
		).then((response) => {
			setIsTopicLock(response.data.status);
			setShowSpinner(false);
		});
	};

	useEffect(() => {
		getLockStatus();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="deletePopup topicDeletePopup"
					show={true}
					onHide={() => {
						setShowDeleteTopicModal(false);
					}}
					size="md"
					centered
				>
					<Modal.Header>
						Delete {deleteTopicInfo.title}
						<Icon
							onClick={() => {
								setShowDeleteTopicModal(false);
								setDeleteTopicInfo({});
							}}
							iconName="Cancel"
							aria-label="close"
							role="button"
							tabIndex={0}
						/>
					</Modal.Header>

					<Modal.Body>
						<div className="deletePopupContainer">
							{successMessage ? (
								<>
									<Alert variant="success">
										You have successfully deleted the topic "
										{deleteTopicInfo.title}".
									</Alert>
									<div className="deletePopupOkButton">
										<button
											onClick={() => {
												setShowDeleteTopicModal(false);
											}}
											aria-label="Okay"
										>
											Ok
										</button>
									</div>
								</>
							) : (
								<>
									{isTopicLock ? (
										<p>
											You can not delete "{deleteTopicInfo.title}" as admin is
											updating the content
										</p>
									) : (
										<>
											<div className="deletePopupdelinkContainer">
												<p>
													Are you sure that you want to delete the topic "
													{deleteTopicInfo.title}" ?
												</p>
											</div>

											<div className="deletePopupButton">
												<Button
													className="templateRejectButton"
													onClick={() => {
														setShowDeleteTopicModal(false);
													}}
													aria-label="cancel"
												>
													Cancel
												</Button>

												<Button
													onClick={() => {
														deleteTopic();
													}}
													aria-label="delete"
												>
													Delete
												</Button>
											</div>
											{errorMessage ? (
												<span className="errorMsg">{errorMessage}</span>
											) : (
												<></>
											)}
										</>
									)}
								</>
							)}
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default DeleteActivity;
