import React, { useState, useEffect } from 'react';
import { Link, useHistory   } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { useAppInsights } from '../../context/TelemetryContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { useAxios } from '../../context/AxiosContext';

const DeleteModalPolygon = ({
    primaryWarningMsg,
    setPrimaryWarningMsg,
    secondaryWarningMsg,
    setSecondaryWarningMsg,
    deleteTopicInfo,
    setDeleteTopicInfo,
    setShowDeleteTopicModal,
    setDeletedTopic,
    deletedTopic,
    parentTopicName,
    postData,
    setPostData,
    count,
    setCount,
}) => {
    const { axiosGetService, axiosDeleteService } = useAxios();
    const { track } = useAppInsights();
    const [successMessage, setSuccessMessage] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const history  = useHistory();
    const deleteTopic = async () => {
        if(deleteTopicInfo.id === 0 ){
            setPostData(prevState => (prevState.filter(e => e.topicName !== deleteTopicInfo.title)
            ));
            setSuccessMessage(true);
            setCount((prevState) => prevState - 1)
        }else{
            await axiosDeleteService(
                `api/sdmtopics/deletetopic/${deleteTopicInfo.id}`
            ).then((response) => {
                if (response.data.status) {
                    track(1, 1804, deleteTopicInfo.id, deleteTopicInfo.title);
                    setSuccessMessage(true);
                    setDeletedTopic(!deletedTopic);
                } else {
                    setErrorMessage(response.data.errorMessage);
                    setTimeout(() => {
                        setErrorMessage(null);
                    }, 5000);
                }
            });
        }
	};

    return(
        <>
            {showSpinner ? (
                <Spinner />
            ) : (
                <Modal
                    show={true}
                    onHide={() => {
                        setShowDeleteTopicModal(false);
                    }}
                    dialogClassName="deletePopupPolygon"
                >
                    <Modal.Body>
                        <Icon
                            onClick={() => {
                                setShowDeleteTopicModal(false);
                                setDeleteTopicInfo({});
                            }}
                            iconName="Cancel"
                            aria-label="close"
                            role="button"
                            tabIndex={0}
                            className='cancelPolygondelete'
                        />
                        <div className="deletePopupContainer">
                            {successMessage ? (
                                <>
                                    <Alert variant="success">
                                        You have successfully deleted the topic "
                                        {deleteTopicInfo.sides === 4 ? 
                                        parentTopicName :
                                        deleteTopicInfo.title}".
                                    </Alert>
                                    <div className="deletePopupOkButton">
                                        <button
                                            onClick={() => {
                                                setShowDeleteTopicModal(false);
                                                if(deleteTopicInfo.sides === 4){
                                                    history.push('/topics');
                                                }
                                            }}
                                            aria-label="Okay"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="deletePopupPolygonContainer">
                                        <span className='alertIconPolygon'></span>
                                        <div className='deleteWarningMsg'>
                                            <span>
                                                {primaryWarningMsg}
                                            </span>
                                            <p>
                                                {secondaryWarningMsg}
                                            </p>
                                        </div>

                                        <div className="deletePolygonPopupButtons">
                                            <Button
                                                className="deleteRejectButton"
                                                onClick={() => {
                                                    setShowDeleteTopicModal(false);
                                                    setDeleteTopicInfo({});
                                                }}
                                                aria-label="cancel"
                                            >
                                                No
                                            </Button>

                                            <Button
                                                className='deleteAcceptButton'
                                                onClick={() => {
                                                    deleteTopic();
                                                }}
                                                aria-label="delete"
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </div>

                                    
                                    {errorMessage ? (
                                        <span className="errorMsg">{errorMessage}</span>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )} 
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default DeleteModalPolygon;