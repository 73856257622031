import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { v4 as uniqueIdGenerator } from 'uuid';
import { CompactPicker } from 'react-color';
import {
	getUsersList,
	getOtherUserProfile,
} from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import ActivityDrag from './ActivityDrag';
import ActivitySelectionDropdown from './ActivitySelectionDropdown';
import { titleValidator } from '../../../helpers/Validation';
import UserSelectionDropdown from '../PhaseAddition/UserSelectionDropdown';
import SubgroupsActivitySelection from './SubgroupsActivitySelection';
import { useAxios } from '../../../context/AxiosContext';
import GroupRefer from './ReferGroups';

const GroupAndSequence = ({
	activityGroups,
	setActivityGroups,
	expandedActivityGroups,
	setExpandedActivityGroups,
	createOrUpdateGroups,
	coOwners,
	userAlias,
	existingGroupNames,
	disableSaveAndContinue,
	approvers,
	setApprovers,
	reviewers,
	setReviewers,
	showSubgroupForm,
	setShowSubgroupForm,
	selectedGroupDetails,
	setSelectedGroupDetails,
	showSubgroupEditForm,
	setShowSubgroupEditForm,
	selectedSubgroupDetails,
	setSelectedSubgroupDetails,
	projectTypeId,
	solutionMethodId,
	phaseId,
	phaseType,
	parentId,
	setDisableSaveAndContinue,
	searchInput,
	solutionMethodsData,
	setSolutionMethodsData,
	phasesData,
	setPhasesData,
}) => {
	const { axiosGetService } = useAxios();
	const [showGroupForm, setShowGroupForm] = useState(showSubgroupForm);
	const [groupName, setGroupName] = useState('');
	const [inputError, setInputError] = useState({
		groupNameEroor: false,
		activitySelectionError: false,
	});
	const [groupInformation, setGroupInformation] = useState([]);
	const [dropdownData, setDropdownData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [selectedGroupId, setSelectedGroupId] = useState(0);
	const [selectedGroupName, setSelectedGroupName] = useState('');
	const [selectedActivities, setSelectedActivities] = useState([]);
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
	});
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});

	const [subgroupsDetails, setSubgroupsDetails] = useState({});
	const [selectedSubgroupsActivities, setSelectedSubgroupsActivites] = useState(
		[]
	);
	const [groupColor, setGroupColor] = useState('#f58220');
	const [colorPickerEnable, setColorPickerEnable] = useState(false);
	const [availableColors, setAvailableColors] = useState(['#f58220']);
	const [showGroupReferForm, setShowGroupReferForm] = useState(false);

	const handleDropdownData = () => {
		const data = activityGroups.filter(
			(group) => group.groupId === 0 && group.activities.length > 0
		);
		let filteredActivities = [];
		for (const group of data) {
			filteredActivities = [
				...group.activities.filter(
					({ isMethodOwnerOrCoOwner }) => isMethodOwnerOrCoOwner
				),
				...filteredActivities,
			];
		}
		setDropdownData(filteredActivities);
	};

	const subgroupsDropdownData = (item) => {
		if (selectedGroupDetails) {
			const selectedSubgroupsActivity = [];
			for (const key of Object.keys(subgroupsDetails)) {
				const activities = subgroupsDetails[key].activities.map(
					({ dragNDropId }) => {
						return dragNDropId;
					}
				);
				selectedSubgroupsActivity.push(...activities);
			}
			const activities = subgroupsDetails[item].activities.map(
				({ dragNDropId }) => {
					return dragNDropId;
				}
			);
			const updatedActivities = [];
			for (const activity of selectedGroupDetails.activities) {
				if (
					(!selectedSubgroupsActivity.includes(activity.dragNDropId) ||
						activities.includes(activity.dragNDropId)) &&
					!activity.isSubgroup
				) {
					updatedActivities.push(activity);
				}
			}
			return updatedActivities;
		} else {
			return [];
		}
	};

	const selectedSubgroupDropdownData = () => {
		const updatedActivities = [];
		if (selectedGroupDetails) {
			for (const activity of selectedGroupDetails.activities) {
				if (!activity.isSubgroup) {
					updatedActivities.push(activity);
				}
			}
			return updatedActivities;
		}
		return updatedActivities;
	};

	const handleGroupExpand = (groupId) => {
		if (!expandedActivityGroups.includes(groupId)) {
			setExpandedActivityGroups((prevState) => [...prevState, groupId]);
		} else {
			const filteredIds = expandedActivityGroups.filter(
				(item) => item !== groupId
			);
			setExpandedActivityGroups(filteredIds);
		}
	};

	const foundSelectedActivites = (group) => {
		const selectedActivityId = group.activities[0].dragNDropId;
		const filteredActivity = selectedActivities.filter(
			({ dragNDropId }) => dragNDropId === selectedActivityId
		);
		if (filteredActivity.length > 0) {
			return true;
		}
		return false;
	};

	const changeToDeafultState = () => {
		setGroupName('');
		setSelectedActivities([]);
		setEditMode(false);
		setSelectedGroupId(0);
		setReviewerEmail('');
		setApproverEmail('');
		setApprovers([]);
		setReviewers([]);
		setSelectedGroupDetails('');
		setSelectedSubgroupsActivites([]);
		setSubgroupsDetails({});
		setShowGroupForm(false);
		setShowSubgroupEditForm(false);
		setShowGroupReferForm(false);
		setShowSubgroupForm(false);
	};

	const saveGroupDetails = async () => {
		if (groupName.trim().length === 0) {
			setInputError((prevState) => ({ ...prevState, groupNameEroor: true }));
		} else {
			setInputError((prevState) => ({
				...prevState,
				activitySelectionError: false,
				groupNameEroor: false,
			}));
			const groupDetails = [
				{
					groupId: 0,
					groupName: groupName,
					isParentActivity: false,
					isDrafted: true,
					activities: selectedActivities,
					dragNDropId: uniqueIdGenerator(),
					colourCode: groupColor,
					parentGroupid: 0,
					isParentGroup: true,
					isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
				},
			];
			for (const subgroup of Object.keys(subgroupsDetails)) {
				if (subgroupsDetails[subgroup].subGroupName) {
					groupDetails.push(subgroupsDetails[subgroup]);
				}
			}
			createOrUpdateGroups('create', groupDetails, changeToDeafultState);
		}
	};

	const updateGroupDetails = () => {
		if (groupName.trim().length === 0) {
			setInputError((prevState) => ({ ...prevState, groupNameEroor: true }));
		} else {
			setInputError((prevState) => ({
				...prevState,
				activitySelectionError: false,
				groupNameEroor: false,
			}));
			const groupDetails = {
				groupName: groupName,
				groupId: selectedGroupId,
				activities: [...selectedActivities],
				parentGroupid: selectedGroupId,
				isParentGroup: true,
				colourCode: groupColor,
				isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
			};
			for (const group of activityGroups) {
				if (group.groupId === selectedGroupId) {
					group.groupName = groupName;
					if (selectedActivities.length > 0) {
						group.activities = [...selectedActivities, ...group.activities];
					}
				}
			}
			const updateGroupDetails = [groupDetails];
			for (const subgroup of Object.keys(subgroupsDetails)) {
				if (subgroupsDetails[subgroup].subGroupName) {
					updateGroupDetails.push(subgroupsDetails[subgroup]);
				}
			}
			createOrUpdateGroups('update', updateGroupDetails, changeToDeafultState);
		}
	};

	const updateGroupInformation = () => {
		const data = activityGroups
			.map((item) => {
				return {
					id: item.groupId,
					name: item.groupName,
					isMethodOwnerOrCoOwner: item.isMethodOwnerOrCoOwner,
					isGroupRefered: item.isGroupRefered,
					isGroupReferredWithActivity: item.isGroupReferredWithActivity,
				};
			})
			.filter(
				(item) =>
					item.id !== 0 &&
					item.isMethodOwnerOrCoOwner &&
					!item.isGroupRefered &&
					!item.isGroupReferredWithActivity
			);
		setGroupInformation(data);
	};

	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeUser = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	const getGroupUser = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
	};

	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getGroupUser(userEmail, setValue);
		}
	};

	const createNewSubgroup = () => {
		const groupDetails = {
			key: uniqueIdGenerator(),
			subGroupName: '',
			activities: [],
			parentGroupid: selectedGroupId,
			colourCode: '',
			isParentGroup: false,
			isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
		};
		setSubgroupsDetails({
			...subgroupsDetails,
			[groupDetails.key]: groupDetails,
		});
	};

	const updateSubgroupsDetails = (key, name) => {
		setSubgroupsDetails((prevState) => ({
			...prevState,
			[key]: {
				...subgroupsDetails[key],
				subGroupName: name,
			},
		}));
	};

	const getSubgroupsDetails = () => {
		if (selectedGroupDetails) {
			const groupDetails = { ...selectedGroupDetails };
			const subgroups = groupDetails.activities.filter(
				(item) => item.isSubgroup
			);
			const adjustSubgroups = {};
			for (const subgroup of subgroups) {
				const updatedSubgroup = { ...subgroup };
				updatedSubgroup.activities = [];
				adjustSubgroups[updatedSubgroup.dragNDropId] = updatedSubgroup;
			}
			getCollaboratorsInfo(
				selectedGroupDetails.approverMembersList,
				setApprovers
			);
			getCollaboratorsInfo(
				selectedGroupDetails.reviewerMembersList,
				setReviewers
			);
			setSubgroupsDetails(adjustSubgroups);
			setGroupName(selectedGroupDetails.groupName);
			setSelectedGroupName(selectedGroupDetails.groupName);
			setSelectedGroupId(selectedGroupDetails.groupId);
			setEditMode(true);
			if (selectedGroupDetails.colourCode) {
				setGroupColor(selectedGroupDetails.colourCode);
			}
		}
	};

	const updateSubgroupDetails = () => {
		const postData = [
			{
				...selectedSubgroupDetails,
				activities: [...selectedSubgroupsActivities],
				parentGroupid: selectedGroupId,
				isParentGroup: false,
				isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
			},
		];
		createOrUpdateGroups('subgroupUpdate', postData, changeToDeafultState);
	};

	const getColourCodes = async () => {
		await axiosGetService(`api/sdmactivity/getallcolourcodes`).then(
			(response) => {
				const data = response.data.map(({ colourCode }) => {
					return colourCode;
				});
				setAvailableColors(data);
			}
		);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		updateGroupInformation();
	}, [activityGroups]);

	useEffect(() => {
		handleDropdownData();
	}, [activityGroups]);

	useEffect(() => {
		if (approvers.length === 0) {
			setApproverEmail('');
		}
		if (reviewers.length === 0) {
			setReviewerEmail('');
		}
	}, [approvers, reviewers]);

	useEffect(() => {
		return () => {
			setApprovers([]);
			setReviewers([]);
		};
	}, []);

	useEffect(() => {
		getSubgroupsDetails();
	}, [selectedGroupDetails]);

	useEffect(() => {
		return () => {
			changeToDeafultState();
			setShowGroupForm(false);
			setSelectedSubgroupDetails('');
			setShowSubgroupForm(false);
		};
	}, []);

	useEffect(() => {
		getColourCodes();
	}, []);

	return (
		<div class="activityGroupAdditionDiv refergroupContainer">
			<h6>Add, Edit and rearrange groups and activity sequence</h6>
			<div class="activityAdditionCont referActivityContainer">
				<div class="cloneActivityButtonAction">
					<div
						class="selectAddActivityButton"
						onClick={() => {
							setShowGroupReferForm(!showGroupReferForm);
						}}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowGroupReferForm(!showGroupReferForm);
							}
						}}
						role="button"
						tabIndex={0}
						aria-label="Link existing Group(s)"
					>
						+&nbsp;Link existing Group(s)
					</div>
					{showGroupReferForm ? (
						<GroupRefer
							showGroupReferForm={showGroupReferForm}
							setShowGroupReferForm={setShowGroupReferForm}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							projectTypeId={projectTypeId}
							solutionMethodId={solutionMethodId}
							phaseId={phaseId}
							setDisableSaveAndContinue={setDisableSaveAndContinue}
							phaseType={phaseType}
							parentId={parentId}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
			<div class="activityAdditionCont">
				<div class="addActivityButtonAction">
					{!showGroupForm ? (
						<div
							class="selectAddActivityButton"
							onClick={() => setShowGroupForm(!showGroupForm)}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowGroupForm(!showGroupForm);
								}
							}}
							role="button"
							tabIndex={0}
							aria-label="Click here to Add a New Group"
							aria-expanded={showGroupForm ? 'true' : 'false'}
						>
							+&nbsp;Add a New Group
						</div>
					) : (
						<></>
					)}
					{showGroupForm ? (
						<div class="addActivityFields">
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Group Name *</span>
								<div className="compactPickerDiv">
									<input
										title={groupName}
										placeholder="Group name"
										value={groupName}
										onChange={(e) => setGroupName(e.target.value)}
									/>
									{inputError['groupNameEroor'] ? (
										<p>Group name required</p>
									) : (
										<></>
									)}
									{editMode ? (
										<>
											{selectedGroupName !== groupName &&
											existingGroupNames.includes(
												groupName.trim().toLowerCase()
											) ? (
												<p className="errorMsg"> Duplicate group name</p>
											) : (
												<></>
											)}
										</>
									) : (
										<>
											{existingGroupNames.includes(
												groupName.trim().toLowerCase()
											) ? (
												<p className="errorMsg"> Duplicate group name</p>
											) : (
												<></>
											)}
										</>
									)}
									{groupName && !titleValidator.test(groupName) ? (
										<p className="errorMsg">Invalid title</p>
									) : (
										<></>
									)}
									<div title="colour">
										<span class="AddActivityFieldLabel">Colour*</span>
										<div>
											<span
												className="selectedColorForGroup"
												style={{ background: groupColor }}
											></span>
											<Icon
												iconName="ChevronDown"
												title="colour"
												onClick={() => {
													setColorPickerEnable(!colorPickerEnable);
												}}
												tabIndex={0}
												aria-label="Click here to pick the Colour for group"
												role="button"
												onKeyPress={(e) => {
													if (e.which === 13) {
														setColorPickerEnable(!colorPickerEnable);
													}
												}}
												aria-expanded={colorPickerEnable ? 'true' : 'false'}
											/>
										</div>
										{colorPickerEnable ? (
											<CompactPicker
												className="compactPicker"
												color={groupColor}
												onChangeComplete={(color) => {
													setGroupColor(color.hex);
												}}
												colors={availableColors}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Activities</span>
								<ActivitySelectionDropdown
									className="activitySelectionDropdown"
									enableDropdown={
										groupName.trim().length > 0 && dropdownData.length > 0
									}
									data={dropdownData}
									value={selectedActivities}
									setValue={setSelectedActivities}
								/>
								{inputError['activitySelectionError'] ? (
									<p>At least one activity should be selected</p>
								) : (
									<></>
								)}
							</div>

							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Approvers</span>
								<UserSelectionDropdown
									className="approver"
									enableDropdown={true}
									data={usersList}
									value={approvers}
									setValue={setApprovers}
									type="approver"
									searchValue={approverEmail}
									setSearchValue={setApproverEmail}
									error={error}
									emailFound={emailFound['approver']}
								/>
								{approvers.length > 0 && (
									<div className="collabaratorsChildContainers">
										<ul>
											{approvers.map((user) => (
												<li key={user.mail}>
													<span className="collabaratorPersonaImage">
														<img src={user.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{user.firstName + ' ' + user.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															onClick={() => {
																removeUser(user.mail, approvers, setApprovers);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeUser(
																		user.mail,
																		approvers,
																		setApprovers
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${user.firstName} ${user.surname}`}
															title="Remove approver"
														/>
													</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Reviewers</span>
								<UserSelectionDropdown
									className="reviewer"
									enableDropdown={true}
									data={usersList}
									value={reviewers}
									setValue={setReviewers}
									type="reviewer"
									searchValue={reviewerEmail}
									setSearchValue={setReviewerEmail}
									error={error}
									emailFound={emailFound['reviewer']}
								/>
								{reviewers.length > 0 && (
									<div className="collabaratorsChildContainers">
										<ul>
											{reviewers.map((user) => (
												<li key={user.mail}>
													<span className="collabaratorPersonaImage">
														<img src={user.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{user.firstName + ' ' + user.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															onClick={() => {
																removeUser(user.mail, reviewers, setReviewers);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeUser(
																		user.mail,
																		reviewers,
																		setReviewers
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${user.firstName} ${user.surname}`}
															title="Remove reviewer"
														/>
													</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>

							<div className="subgroupFields">
								<div className="addNewSubGroupButton">
									<span>+ Add Subgroup</span>
									<Icon iconName="ChevronDown" />
								</div>
								{Object.keys(subgroupsDetails).map((item) => (
									<React.Fragment key={item}>
										<div class="entryCriteriaName ">
											<span class="AddActivityFieldLabel">Subgroup Name *</span>
											<input
												title={subgroupsDetails[item].subGroupName}
												placeholder="Subgroup name"
												value={subgroupsDetails[item].subGroupName}
												onChange={(e) => {
													updateSubgroupsDetails(item, e.target.value);
												}}
											/>
										</div>
										<div class="entryCriteriaName subgroupActivitiesDp">
											<span class="AddActivityFieldLabel">Add Activities</span>
											<SubgroupsActivitySelection
												className={`activitySelectionDropdown${item}`}
												enableDropdown={
													subgroupsDetails[item].subGroupName.trim().length >
														0 && subgroupsDropdownData(item).length > 0
												}
												data={subgroupsDropdownData(item)}
												value={subgroupsDetails}
												setValue={setSubgroupsDetails}
												subgroupKey={item}
											/>
										</div>
									</React.Fragment>
								))}
							</div>
							<div className="addNewSubGroupButton">
								<button
									onClick={() => {
										createNewSubgroup();
									}}
								>
									Add Subgroup
								</button>
							</div>
							<div class="criteriaButtons">
								<button
									onClick={() => {
										setShowGroupForm(false);
										changeToDeafultState();
									}}
								>
									Cancel
								</button>
								<button
									onClick={() => {
										if (editMode) {
											updateGroupDetails();
										} else {
											saveGroupDetails();
										}
									}}
									disabled={
										(existingGroupNames.includes(
											groupName.trim().toLowerCase()
										) &&
											selectedGroupName !== groupName) ||
										!titleValidator.test(groupName) ||
										groupName.trim().length === 0 ||
										disableSaveAndContinue
									}
								>
									Save
								</button>
							</div>
						</div>
					) : (
						<></>
					)}
					{showSubgroupEditForm ? (
						<div class="addActivityFields">
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Subgroup Name *</span>
								<input
									title={selectedSubgroupDetails.subGroupName}
									placeholder="Group name"
									value={selectedSubgroupDetails.subGroupName}
									onChange={(e) =>
										setSelectedSubgroupDetails({
											...selectedSubgroupDetails,
											subGroupName: e.target.value,
										})
									}
								/>
								{!selectedSubgroupDetails.subGroupName ? (
									<p>Subgroup name required</p>
								) : (
									<></>
								)}

								{selectedSubgroupDetails.subGroupName &&
								!titleValidator.test(selectedSubgroupDetails.subGroupName) ? (
									<p className="errorMsg">Invalid title</p>
								) : (
									<></>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Activities</span>
								<ActivitySelectionDropdown
									className="activitySelectionDropdown"
									enableDropdown={
										selectedSubgroupDetails.subGroupName &&
										selectedSubgroupDetails.subGroupName.trim().length > 0 &&
										selectedSubgroupDropdownData().length > 0
									}
									data={selectedSubgroupDropdownData()}
									value={selectedSubgroupsActivities}
									setValue={setSelectedSubgroupsActivites}
								/>
							</div>
							<div class="criteriaButtons">
								<button
									onClick={() => {
										setShowGroupForm(false);
										changeToDeafultState();
									}}
								>
									Cancel
								</button>
								<button
									onClick={() => {
										updateSubgroupDetails();
									}}
									disabled={
										!titleValidator.test(
											selectedSubgroupDetails.subGroupName
										) ||
										(selectedSubgroupDetails.subGroupName &&
											selectedSubgroupDetails.subGroupName.trim().length ===
												0) ||
										disableSaveAndContinue
									}
								>
									Update
								</button>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>

			<ActivityDrag
				expandedActivityGroups={expandedActivityGroups}
				handleGroupExpand={handleGroupExpand}
				activityGroups={activityGroups}
				setActivityGroups={setActivityGroups}
				updateGroupDetails={updateGroupDetails}
				setEditMode={setEditMode}
				setSelectedGroupId={setSelectedGroupId}
				setGroupName={setGroupName}
				setShowGroupForm={setShowGroupForm}
				groupInformation={groupInformation}
				coOwners={coOwners}
				userAlias={userAlias}
				setSelectedGroupName={setSelectedGroupName}
				setApprovers={setApprovers}
				setReviewers={setReviewers}
				getCollaboratorsInfo={getCollaboratorsInfo}
				setSelectedGroupDetails={setSelectedGroupDetails}
				setSelectedSubgroupDetails={setSelectedSubgroupDetails}
				setShowSubgroupEditForm={setShowSubgroupEditForm}
				setExpandedActivityGroups={setExpandedActivityGroups}
				searchInput={searchInput}
				phasesData={phasesData}
				setPhasesData={setPhasesData}
				solutionMethodsData={solutionMethodsData}
				setSolutionMethodsData={setSolutionMethodsData}
			/>
		</div>
	);
};

export default GroupAndSequence;
