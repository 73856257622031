import React from 'react';
import dompurify from 'dompurify';
import { Icon } from '@fluentui/react/lib/Icon';
import './PhaseShowMore.css';

function PhaseShowMore({ phaseDescription, setShowMoreVisible, parentName }) {
	const htmlSanitizer = dompurify.sanitize;
	return (
		<div className="phaseShowMore">
			<Icon
				iconName="ChromeClose"
				tabIndex={0}
				onKeyPress={(e) => {
					if (e.which === 13) {
						setShowMoreVisible(false);
					}
				}}
				onClick={() => setShowMoreVisible(false)}
			/>
			<h3 className="parentSolutionMethod">
				{phaseDescription.type === 'phase'
					? `What is ${parentName}`
					: `${parentName}'s`}
			</h3>
			<h3>{`${phaseDescription.title} phase`.toUpperCase()}</h3>
			<div
				className='ck-content'
				dangerouslySetInnerHTML={{
					__html: htmlSanitizer(phaseDescription.description),
				}}
			/>
		</div>
	);
}

export default PhaseShowMore;
