import React from 'react';

import NoContributionsSVG from '../../assets/NoContributions.svg';

const NoContributions = ({ message }) => {
	return (
		<div className="noContributions">
			<img src={NoContributionsSVG} alt="No Contributions" />
			<span>{message}</span>
		</div>
	);
};

export default NoContributions;
