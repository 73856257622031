import React, { useEffect } from 'react';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import dompurify from 'dompurify';
import './DeliveryManagementDesc.css';

function DeliveryManagementDesc({ parentSolutionMethod, setVisible }) {
	const { setParentPhase } = useLeftMenuCollapse();
	const htmlSanitizer = dompurify.sanitize;
	useEffect(() => {
		setParentPhase({
			id: null,
			title: null,
		});

		setVisible({
			leftPanel: true,
			rightPanel: true,
		});
	}, []);
	return (
		<>
			<h4>{parentSolutionMethod.solutionMethodName}</h4>
			<div dangerouslySetInnerHTML={{ __html: htmlSanitizer(parentSolutionMethod.description) }} />
		</>
	);
}

export default DeliveryManagementDesc;
