import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import UserSelectionDropdown from '../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown';
import { getUsersList } from '../../helpers/GraphService';
import { useParams } from "react-router-dom";
import { useAxios } from '../../context/AxiosContext';
import { useAuth } from '../../context/UserAuthContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import OwnerSelectionDropdown from  '../TopicsBuilder/OwnerSelectionDropdown'



function ActivityCollaborators({
    setShowActivityOwnerCollaborators,
    activityName,
    artifactName,
	artifactOwner,
	existingCoOwners,
	existingReviewers,
    existingApprovers,
	showCollaboratorSpinner,
	previousPostData,
	isAdminOrOwner,
	setIsEdited,
	isEdited,
	setExistingApprovers,
	setExistingReviewers,
	setExistingCoOwners,
	setArtifactOwner,
	solutionMethodContentOwner,
    solutionMethodContentOwnerEmail,
	prevArtifactOwner,
	setPrevArtifactOwner
}) {
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		owner: [],
	});
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		owner: false,
	});
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		owner: false,
	});
	const [apiCalled, setApiCalled] = useState(false);
	const { axiosGetService, axiosPostService,userAlias } = useAxios();

	const {activityId,phaseId,solutionMethodId} = useParams();
	const [editingMode,setEditingMode] = useState(false)
	const { user } = useAuth();
	const {
		endUserView,isAdmin
	} = useLeftMenuCollapse();
	const [isAdminOrOwnerCollaborator, setIsAdminOrOwnerCollaborator]=useState(endUserView)
	const isOwnerOrCowner = artifactOwner 
    ? [artifactOwner.mail, ...existingCoOwners.map(e => e.mail)]
    : [prevArtifactOwner.mail, ...existingCoOwners.map(e => e.mail)];

	const [isSolutionMethodCoOwner,setIsSolutionMethodCoOwner] = useState('');
	const fetchCoOwnerMembers = async () => {
		try {
		  const response = await axiosGetService(
			`api/sdmactivity/getactivityorphaseormethodcollaborators/0/0/${solutionMethodId}`
		  );
		  setIsSolutionMethodCoOwner(response.data.coOwnerMembers.includes(userAlias));
		} catch (error) {
		  console.error('Error fetching coOwnerMembers:', error);
		}
	  };
	  useEffect(() => {
		if (solutionMethodId) {
		  fetchCoOwnerMembers();
		}
	  }, [solutionMethodId]);
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
    const [activityOwnerEmail, setActivityOwnerEmail] = useState("")
	const editorEmail = user.profile.mail
	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
		  if (activityOwnerEmail) {
			getUser(activityOwnerEmail, "activityOwner");
		  } else {
			setUsersList((prevState) => ({ ...prevState, activityOwner: [] }));
			setError({ ...error, ["activityOwner"]: false });
		  }
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [activityOwnerEmail])

	useEffect(() => {
		const timeOutId = setTimeout(() => {
		  if (coOwnerEmail) {
			getUser(coOwnerEmail, "coOwner");
		  } else {
			setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			setError({ ...error, ["coOwner"]: false });
		  }
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail])
	
	
	const resetStates = () => {
		setShowActivityOwnerCollaborators(false);
	};
   
	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};
	const saveDetails = async () => {
const reviewersEmails = reviewers
.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
.map(({ mail }) => mail);

const approversEmails = approvers
.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
.map(({ mail }) => mail);

const coOwnerEmails = existingCoOwners
.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
.map(({ mail }) => mail);

const artifactOwnerEmail = artifactOwner ? ((!artifactOwner.isInhertedValues || artifactOwner.isInhertedValues === undefined) ? artifactOwner.mail:null)

:((!prevArtifactOwner.isInhertedValues || prevArtifactOwner.isInhertedValues === undefined) ? prevArtifactOwner.mail:null)

		const reviewerEmailString = reviewersEmails.join(',')
		const approverEmailString = approversEmails.join(',')
		const coOwnerEmailString = coOwnerEmails.join(',')
 
		const postData = {
			approverMembers: approverEmailString,
			reviewerMembers: reviewerEmailString,
			coOwnerMembers: coOwnerEmailString,
			owner:artifactOwnerEmail,
		};
		if (JSON.stringify(previousPostData) !== JSON.stringify(postData)) {
			postData.activityIdentifier = activityId;
			postData.parentPhaseId = Number(phaseId);
			postData.solutionMethodId = Number(solutionMethodId);
			postData.modifiedBy = editorEmail;
			setApiCalled(true);
			
			await axiosPostService(
				`api/sdmactivity/updateactivitycollobarators`,
				postData
			).then((response) => {
				if (response.status === 200) {
					toast.info('You have successfully updated the collaborators', {
						position: 'top-right',
					});
					setExistingApprovers([])
					setExistingReviewers([])
					setExistingCoOwners([])
					setIsEdited(!isEdited)
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setApiCalled(false);
				// setTopicOwner(topicOwnerInfo);
			});
			resetStates();
		}
	};

	useEffect(() => {
		setReviewers(existingReviewers);
	  }, [existingReviewers]);
	
	  useEffect(() => {
		setApprovers(existingApprovers);
	  }, [existingApprovers]);
  return (
    <Modal
    className="phaseCreationRightPopup"
    show={true}
    onHide={() => {
        // Don't do anything.
    }}
>
    <Modal.Header>
        <Modal.Title> Collaborators	</Modal.Title>
        <Icon
            iconName="ChromeClose"
            title="Close"
            tabIndex={0}
            role="button"
            // aria-label={`Click to close the ${topicName} topic collaborators modal`}
            onClick={() => {
                 setShowActivityOwnerCollaborators(false);
            }}
            onKeyPress={(e) => {
                if (e.which === 13) {
                    setShowActivityOwnerCollaborators(false);
                }
            }}
        />
    </Modal.Header>
    <Modal.Body>
        <div className="methodOwnerMainContainer">
            <div className="methodOwnerPage">
                <div className="methodOwnerContainer">
                    <div className="methodLeftContainerEndUserView">
                        <ul className="methodPhaseLeftMenu">
                            <li className="activePhaseTab">
                                {false && <span>Collaborators </span>}
                            </li>
                        </ul>
                    </div>
                    <div className="middleDetailsContainer">
                        <div className="methodOwnerMiddleContainer">
						    <div className="methodPhaseCollabaratorsTab">
				            {showCollaboratorSpinner ? (
				             <div className="spinner">
				             	<Spinner animation="border" />
				             </div>
							) : (
                                <div className="phaseCollabaratorsFields">
						<div className="collabaratorsChildContainersEndUserViewOwner">
                            <div className='activityTitleContainer'>
							<h6 className='collaboratorsTitle'>{artifactName} / <span>{activityName}</span>
							</h6>
							{!isAdminOrOwnerCollaborator && artifactName==='Activity' && (isAdminOrOwner || isOwnerOrCowner.includes(userAlias) || isSolutionMethodCoOwner || solutionMethodContentOwnerEmail === userAlias)? (<div onClick={()=>{setEditingMode(true);setIsAdminOrOwnerCollaborator(!isAdminOrOwnerCollaborator)}} className='editIconActivity'>
							</div>
							):(
								<></>
							)}
				    	   </div>
						   {artifactName==='Activity' &&
						   <div className="inheritedCollabaratorsLegend">
							<p>
								<span className='inheritedCollaboratorsIconRounded'></span>The collaborators automatically receive their permissions from the corresponding method or phase, unless specified otherwise for a particular activity.</p>
							</div>	
							}
							{artifactName==='Phase' &&
						   <div className="inheritedCollabaratorsLegend">
							<p>
								<span className='inheritedCollaboratorsIconRounded'></span>The collaborators automatically receive their permissions from the corresponding method unless specified otherwise for a particular phase.</p>
							</div>	
							}

							<div className="collabaratorsParentContainers">
							<h6>Owner</h6>
								{editingMode && (isAdmin || userAlias === solutionMethodContentOwnerEmail || isSolutionMethodCoOwner) && <OwnerSelectionDropdown
									 className="owner"
									 collaboratorDropDownList='collaboratorDropDownList'
									 ownerRadioButton='CustomCheckmarkActivityOwnerCreation'
									 enableDropdown={true}
									 data={usersList}
									 value={artifactOwner? artifactOwner: prevArtifactOwner}
									 setValue={setArtifactOwner ? setArtifactOwner : setPrevArtifactOwner}
									 type="activityOwner"
									 searchValue={activityOwnerEmail}
									 setSearchValue={setActivityOwnerEmail}
									 error={error}
									 emailFound={emailFound["owner"]}
								/>}
								{(artifactOwner?.mail ? (artifactOwner?.mail) : (prevArtifactOwner?.mail)) ? (
									<ul className={`afterSaveListTopic collaboratorsApprovers ${editingMode ? 'methodOrActivityOwnerContainerEditingMode':'methodOrActivityOwnerContainer'}`}>
										<li key={artifactOwner ? artifactOwner.mail : prevArtifactOwner.mail}>
										{artifactName==='Activity' && editingMode ? <span className="collabaratorPersonaImage ">
											<img src={artifactOwner ? artifactOwner.photo : prevArtifactOwner.photo} alt="img" />
											</span> : <></>}
											<span className={artifactName === 'Activity' && editingMode ? 'collabaratorsChildContainers' : 'collabaratorPersonalTitleEndUserView'}>
    {!editingMode ? (
        <span className='activityHyperLinkName'>
            {(artifactOwner?.isInhertedValues || prevArtifactOwner?.isInhertedValues) && 
                <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
            <a href={`mailto:${artifactOwner?.mail || prevArtifactOwner?.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
                {`${artifactOwner?.firstName || prevArtifactOwner?.firstName} ${artifactOwner?.surname || prevArtifactOwner?.surname}`}
            </a>
        </span>
    ) : (
        <span>
            {`${artifactOwner?.firstName || prevArtifactOwner?.firstName} ${artifactOwner?.surname || prevArtifactOwner?.surname}`}
        </span>
    )}
</span>

											{ editingMode && (isAdmin || userAlias === solutionMethodContentOwnerEmail || isSolutionMethodCoOwner)  && <span>
												<Icon
    hidden={artifactOwner?.mail ? artifactOwner.isInhertedValues : prevArtifactOwner.isInhertedValues}
    iconName="Cancel"
    onClick={() => {
        if (prevArtifactOwner?.mail) {
            setPrevArtifactOwner({});
        }
    }}
    tabIndex={0}
    onKeyPress={(e) => {
        if (e.which === 13) {
            if (prevArtifactOwner?.mail) {
                setPrevArtifactOwner({});
            }
        }
    }}
    role="button"
    aria-label={`Remove ${artifactOwner ? artifactOwner?.firstName : prevArtifactOwner.firstName} ${artifactOwner ? artifactOwner?.surname : prevArtifactOwner.surname}`}
    title="Remove Owner"
/>

											</span>}
										</li>
									</ul>
								) : (
									<></>
								)}
							</div>
							
						</div>
				             	
				    <div className="collabaratorsMainContainers">
					{existingCoOwners.length > 0 || (editingMode && (isAdminOrOwner || userAlias === solutionMethodContentOwnerEmail
					|| ( (artifactOwner ? artifactOwner.mail : prevArtifactOwner.mail )=== userAlias ))) ? (
						<>
						<div className="collabaratorsParentContainers">
							<h6>Co-owners</h6>
							{editingMode && (isAdminOrOwner || userAlias === solutionMethodContentOwnerEmail || isSolutionMethodCoOwner
							  || ((artifactOwner ? artifactOwner.mail : prevArtifactOwner.mail) === userAlias)) &&
							<UserSelectionDropdown
								className="coOwner"
								enableDropdown={true}
								data={usersList}
								value={existingCoOwners}
								setValue={setExistingCoOwners}
								type="coOwner"
								searchValue={coOwnerEmail}
								setSearchValue={setCoOwnerEmail}
								error={error}
								emailFound={emailFound['coOwner']}
							/>}
						</div>
						<div className= "collabaratorsChildContainersEndUserViewCoOwner newDisplay">
								<ul className={editingMode ? 'methodOrActivityOwnerContainerEditingMode':'methodOrActivityOwnerContainer'}>
									{existingCoOwners.map((user) => (
									<li key={user}>
										 {artifactName==='Activity' && editingMode ? <span className="collabaratorPersonaImage">
											<img src={user.photo} alt="img" />
											</span> : <></>}
											<span className= {artifactName==='Activity'  && editingMode ? 'collabaratorsChildContainers'
						                     : "collabaratorPersonalTitleEndUserView"}>
										{!editingMode ? (<span  className='activityHyperLinkName'>
											{user?.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
											<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{user.firstName +
													' ' +
													user.surname}
											    
											</a>
											</span>):(
												<span>
												{user.firstName +
													' ' +
													user.surname}
												</span>
												
											)}
										</span>
										{editingMode  && (isAdminOrOwner || userAlias === solutionMethodContentOwnerEmail || isSolutionMethodCoOwner
					                     || ((artifactOwner ? artifactOwner.mail : prevArtifactOwner.mail )=== userAlias )) ?( 
										      <span>
												<Icon
												hidden={user.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, existingCoOwners, setExistingCoOwners);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, existingCoOwners, setExistingCoOwners);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove CoOwner"
												/>
										</span>
					                     ):<></>
									    }
									</li>
								))}
							</ul>
						</div>
						</>
						): <></>}
				             	</div>
				             	{artifactName ==='Activity' || existingApprovers.length > 0 ? (
				                	<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
							<h6>{artifactName==='Activity' && editingMode ? 'Add Approvers' : 'Approvers'}</h6>
							{editingMode && artifactName==='Activity' ? (<UserSelectionDropdown
								className="approver"
								enableDropdown={true}
								data={usersList}
								value={approvers}
								setValue={setApprovers}
								type="approver"
								searchValue={approverEmail}
								setSearchValue={setApproverEmail}
								error={error}
								emailFound={emailFound['approver']}
							/>
							):(
								<></>
							)}
						</div>

						{approvers.length > 0 && (
							<div className= {artifactName==='Activity' && editingMode ? 'collabaratorsChildContainers newDisplay'
						        : "collabaratorsChildContainersEndUserView newDisplay"}>
								<ul>
									{approvers.map((user) => (
										<li key={user}>
											{artifactName==='Activity' && editingMode ? <span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span> : <></>}
										    <span className= {artifactName==='Activity'  && editingMode ? 'collabaratorsChildContainers'
						                     : "collabaratorPersonalTitleEndUserView"}>
											{!editingMode ? (<span  className='activityHyperLinkName'>
												{user.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
												<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{user.firstName +
													' ' +
													user.surname}
											    
											</a>
											</span>):(
												<span>
												{user.firstName +
													' ' +
													user.surname}
												</span>
											)}
											</span>
											{artifactName==='Activity'  && editingMode ? (<span>
												<Icon
												hidden={user.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, approvers, setApprovers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, approvers, setApprovers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove Collabarator"
												/>
											</span>
											): (
											<></>
											)}
										</li>
									))}
								</ul>
							</div>
						)}
				                	</div>
				             	):(
				             	<></>
				             	)}
								{artifactName ==='Activity' || existingReviewers.length > 0 ? (
                                   <div className="collabaratorsMainContainers">
								   <div className="collabaratorsParentContainers">
									   <h6>{artifactName==='Activity' && editingMode ? 'Add Reviewers' : 'Reviewers'}</h6>
									   {editingMode && artifactName==='Activity' && editingMode ? (<UserSelectionDropdown
										   className="reviewer"
										   enableDropdown={true}
										   data={usersList}
										   value={reviewers}
										   setValue={setReviewers}
										   type="reviewer"
										   searchValue={reviewerEmail}
										   setSearchValue={setReviewerEmail}
										   error={error}
										   emailFound={emailFound['reviewer']}
									   />
									   ):(
										<></>
									   )}
								   </div>
		   
								   {reviewers.length > 0 && (
									   <div className= {artifactName==='Activity' && editingMode ? 'collabaratorsChildContainers newDisplay'
									   : "collabaratorsChildContainersEndUserView newDisplay"}>
										   <ul>
											   {reviewers.map((user) => (
												   <li key={user.mail}>
													   {artifactName==='Activity' && editingMode ?  <span className="collabaratorPersonaImage">
														   <img src={user.photo} alt="img" />
													   </span> : <></>
													   }
													   <span className= {artifactName==='Activity' && editingMode ? 'collabaratorsChildContainers'
														: "collabaratorPersonalTitleEndUserView"}>
														{!editingMode ? (
														<span className='activityHyperLinkName'>
															{user.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
															<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												            {user.firstName +
												         	' ' +
												          	user.surname}
										               	   </a>
											            </span>):(
											            	<span>
											            	{user.firstName +
											            		' ' +
											            	user.surname}
											            	</span>
											            )}
													   </span>
													   {artifactName==='Activity' && editingMode ? (<span>
														   <Icon
														   hidden={user.isInhertedValues}
															   iconName="Cancel"
															   onClick={() => {
															   	removeCoOwner(user.mail, reviewers, setReviewers);
															   }}
															   onKeyPress={(e) => {
															   	if (e.which === 13) {
															   		removeCoOwner(user.mail, reviewers, setReviewers);
															   	}
															   }}
															   role="button"
															   tabIndex={0}
															   aria-label={`Remove ${user.firstName} ${user.surname}`}
															   title="Remove Collabarator"
														   />
													   </span>
													   ):(
														<></>
													   )
													   }
												   </li>
											   ))}
										   </ul>
									   </div>
								   )}
											</div>
								):
								<></>
								}
				                </div>
							)}	
						    </div>
                        </div>
                    </div>
                    {artifactName==='Activity' && editingMode ? (
                    <div className="saveContinueDetailsContainer newTopicCollaboratorsButtons">
						<button onClick={()=>{resetStates(); setIsAdminOrOwnerCollaborator(!isAdminOrOwnerCollaborator)}}>
									Cancel
						</button>
                        <button
                            onClick={() => {
                                saveDetails();
                            }}
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.which === 13) {
                                    saveDetails();
                                }
                            }}
                            disabled={apiCalled}
                            className="forwardButton"
                        >
                            <span className="saveContinueButton newSaveContinueSpan">
                                Save
                            </span>
                        </button>
                    </div>
                    ):(
						<></>
					)} 
                </div>
            </div>
        </div>
    </Modal.Body>
</Modal>
  )
}

export default ActivityCollaborators
