import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useAppInsights } from '../../../context/TelemetryContext';
import Spinner from '../../../helpers/Spinner';

const EditCategory = ({ setCurrentTab }) => {
	const [categories, setCategories] = useState([]);
	const [expandedCategories, setExpandedCategories] = useState([]);
	const [newCategory, setNewCategory] = useState(null);
	const { axiosGetService, axiosPostService } = useAxios();
	const [expandedNewCategory, setExpandedNewCategory] = useState(false);
	const [showSpinner, setShowSpinner] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState({});

	const { track } = useAppInsights();

	const saveCategory = async (updatedCategory) => {
		if (getCategoryMessage(updatedCategory) !== '') {
			toast.error(getCategoryMessage(updatedCategory), {
				position: 'top-right',
			});
			return;
		}
		if (
			categories.some(
				(category) => category.name === updatedCategory.name && updatedCategory.id !== category.id
			)
		) {
			toast.error('Category already exists', {
				position: 'top-right',
			});
			return;
		}
		const postBody = {
			updatedCategories: [
				{
					...updatedCategory,
				},
			],
		};
		const response = await axiosPostService(
			'api/sdmtutorials/updateCategoriesForAdmin',
			postBody
		);
		if (response.data.status) {
			toast.info('Category updated successfully', {
				position: 'top-right',
			});
			if (!updateCategory.id) {
				setNewCategory({ name: ''});
			}
			getCategories();
		} else {
			toast.error(response.data.errorMsg, {
				position: 'top-right',
			});
		}
	};
	const deleteCategory = async (category) => {
		const postBody = {
			deletedCategoryIds: category.id,
		};
		const response = await axiosPostService(
			'api/sdmtutorials/updateCategoriesForAdmin',
			postBody
		);
		if (response.data.status) {
			toast.info('Category deleted successfully', {
				position: 'top-right',
			});
			getCategories();
		} else {
			toast.error(response.data.errorMsg, {
				position: 'top-right',
			});
		}
	};
	const getCategoryMessage = (category) => {
		if (category.name.length === 0) return 'Category name cannot be empty';
		if (category.name.length > 50)
			return 'Category name cannot be more than 50 characters';
		return '';
	};
	const toggleCategoryEditor = (categoryId) => {
		if (expandedCategories.includes(categoryId)) {
			setExpandedCategories(expandedCategories.filter((id) => id !== categoryId));
		} else {
			setExpandedCategories([...expandedCategories, categoryId]);
		}
	};
	const updateCategory = (categoryIndex, propetyName, newValue) => {
		const newCategories = [...categories];
		newCategories[categoryIndex][propetyName] = newValue;
		setCategories(newCategories);
	};

	const getCategories = async () => {
		const response = await axiosGetService(`api/sdmtutorials/getCategoriesForAdmin`);
		setShowSpinner(true);
		setCategories(
			response.data.map((category) => {
				return {
					...category,
				};
			})
		);
		setShowSpinner(false);
	};
	useEffect(() => {
		getCategories();
		setNewCategory({ name: '' });
	}, []);
	return (
		<>
			<div class="middleDetailsContainer">
				<div class="methodOwnerMiddleContainer">
					{showSpinner ? (
						<Spinner />
					) : (
						<div class="methodPhaseDetailsTab">
							<div class="roleAdditionContainer">
								<div class="addRoleButtonAction">
									<div
										class="selectAddRoleButton"
										onClick={() => setExpandedNewCategory(!expandedNewCategory)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setExpandedNewCategory(!expandedNewCategory);
											}
										}}
									>
										+&nbsp;Add a New Category
									</div>
									{expandedNewCategory && (
										<div class="addRoleFields">
											<div class="addRoleNameContainer">
												<span class="AddRoleFieldLabel">Category Title *</span>
												<input
													title=""
													placeholder="Enter Category Title"
													value={newCategory.name}
													onChange={(e) =>
														setNewCategory({
															...newCategory,
															name: e.target.value,
														})
													}
												/>
											</div>
											<div>
												<span
													className="addRoleIcon"
													title="Add Category"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															saveCategory(newCategory);
														}
													}}
													onClick={() => saveCategory(newCategory)}
												></span>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="addRolesListContainer">
								<ul>
									{categories.map((category, index) => (
										<li key={index}>
											<div class="addRoleButtonAction">
												<div class="addRoleTitleEditPart">
													<h6>{category.name}</h6>
													<div className="editRoleDetailsDiv">
														<span
															className="editRoleIcon"
															onClick={() => {
																toggleCategoryEditor(category.id);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	toggleCategoryEditor(category.id);
																}
															}}
															title="Edit Category"
														></span>
														{selectedCategory?.id === category.id && (
															<div className="deleteSamplesPopUp">
																<p>
																	{selectedCategory.canBeDeleted
																		? `Are you sure that you want to delete this Category ?`
																		: 'Please De-link category from Video Tutorial before deleting this category'}
																</p>
																<div>
																	<button
																		onClick={() => {
																			setSelectedCategory({});
																		}}
																		tabIndex={0}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setSelectedCategory({});
																			}
																		}}
																	>
																		Cancel
																	</button>
																	{selectedCategory.canBeDeleted && (
																		<button
																			onClick={() => {
																				deleteCategory(category);
																			}}
																			tabIndex={0}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					deleteCategory(category);
																				}
																			}}
																		>
																			Delete
																		</button>
																	)}
																</div>
															</div>
														)}
														<span
															className="deleteRoleIcon"
															onClick={() => setSelectedCategory(category)}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedCategory(category);
																}
															}}
															title="Delete Category"
														></span>
													</div>
												</div>
												{expandedCategories.includes(category.id) && (
													<div class="addRoleFields">
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">
																Category Title *
															</span>
															<input
																title=""
																placeholder="Category Title"
																value={category.name}
																onChange={(e) =>
																	updateCategory(index, 'name', e.target.value)
																}
															/>
														</div>
														<div class="criteriaSaveUpdate">
															<button
																tabIndex={0}
																onClick={() => saveCategory(category)}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		saveCategory(category);
																	}
																}}
															>
																Update
															</button>
														</div>
													</div>
												)}
												{expandedCategories.includes(category.id) && (
													<p className="extractMethodologyMessage">
														{getCategoryMessage(category)}
													</p>
												)}
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default EditCategory;
