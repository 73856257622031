import React, { useState, useContext, createContext } from 'react';

const CrowdSourcingContext = createContext();

const CrowdSourcingProvider = ({ children }) => {
	const [crowdSourcingAppState, setCrowdSourcingAppState] = useState({
		successMessagePopUp: false,
		successMessageComplexityId: 0,
		successMessageTitle: null,
		showHistory: false,
		showPreviewPopUp: false,
		userLeaveWithoutSave: false,
		userLeaveWithSave: false,
		reviewerError: false,
		skipNavigationId: null,
	});

	return (
		<CrowdSourcingContext.Provider
			value={{
				crowdSourcingAppState,
				setCrowdSourcingAppState,
			}}
		>
			{children}
		</CrowdSourcingContext.Provider>
	);
};

const useCrowdSourcing = () => useContext(CrowdSourcingContext);

export { CrowdSourcingProvider, useCrowdSourcing };
