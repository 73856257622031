import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';

import ComplexityDropdown from './ComplexityCloneDropdown';
import MultiSelectDropdown from './MultiSelectDropdown';
import CloneModal from './CloneModal';
import { useAxios } from '../../../context/AxiosContext';
import Spinner from '../../../helpers/Spinner';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';

const ActivityClone = ({
	setShowCloneActivityForm,
	activityGroups,
	setActivityGroups,
	showCloneFilter,
	setShowCloneFilter,
	projectTypeId,
	solutionMethodId,
	phaseId,
	setDisableSaveAndContinue,
	type,
	parentId,
}) => {
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];
	const { axiosGetService } = useAxios();
	const {appState} = useLeftMenuCollapse();
	const {defaultComplexity} = appState;
	const [showSpinner, setShowSpinner] = useState(true);
	const [activitySearchValue, setActivitySearchValue] = useState('');
	const [foundActivities, setFoundActivities] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	

	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([
		{
			id: Number(projectTypeId),
			name: Number(projectTypeId) == 1 ? 'Consulting' : 'Support',
		},
	]);

	//solution method state
	const [solutionMethodSearchValue, setSolutionMethodSearchValue] =
		useState('');
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);

	// phase state
	const [phasesSearchValue, setPhasesSearchValue] = useState('');
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [phases, setPhases] = useState([]);

	//
	const [complexities, setComplexities] = useState([]);
	const [selectedComplexity, setSelectedComplexity] =
		useState(defaultComplexity);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const getComplexities = async () => {
		await axiosGetService(`api/activities/projectcomplexity`).then(
			(response) => {
				setComplexities(response.data);
			}
		);
	};
	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};

	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=${
				phasesSearchValue.trim().length > 0
					? encodeURIComponent(phasesSearchValue)
					: 0
			}&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			const data = response.data.phases.map((item) => {
				return {
					name: item.phaseName,
					id: item.phaseId,
				};
			});
			const filterTag = selectedPhases.filter(({ id }) =>
				findSelectedItem(data, id)
			);
			if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
				setSelectedPhases(filterTag);
			}
			setPhases(data);
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	const getActivity = async () => {
		const phaseIds = selectedPhases.map(({ id }) => {
			return id;
		});
		const methods = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmactivity/getactivitiesbymethodandphase?searchValue=${
				activitySearchValue ? activitySearchValue : 0
			}&methodIds=${methods.length ? methods.toString() : 0}&phaseIds=${
				phaseIds.length ? phaseIds.toString() : 0
			}&complexityId=${selectedComplexity.id}`
		).then((response) => {
			setFoundActivities(response.data);
			closeSpinner();
		});
	};

	useEffect(() => {
		getComplexities();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phasesSearchValue, selectedSolutionMethods]);

	useEffect(() => {
		setShowSpinner(true);
		const timeOutId = setTimeout(() => {
			getActivity();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [
		activitySearchValue,
		selectedSolutionMethods,
		selectedPhases,
		selectedComplexity,
	]);

	return (
		<div className="addActivityFields">
			{selectedActivity ? (
				<CloneModal
					selectedActivity={selectedActivity}
					setSelectedActivity={setSelectedActivity}
					setShowCloneActivityForm={setShowCloneActivityForm}
					selectedComplexity={selectedComplexity}
					activityGroups={activityGroups}
					setActivityGroups={setActivityGroups}
					setDisableSaveAndContinue={setDisableSaveAndContinue}
					solutionMethodId={solutionMethodId}
					type={type}
					parentId={parentId}
				/>
			) : (
				<>
					<div className="cloneActivityContainer">
						<div className="cloneActivityFieldsWrapper">
							<input
								title={activitySearchValue}
								placeholder="Activity name"
								value={activitySearchValue}
								onChange={(e) => setActivitySearchValue(e.target.value)}
							/>
							<Icon
								iconName="FilterSolid"
								onClick={() => setShowCloneFilter(!showCloneFilter)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowCloneFilter(!showCloneFilter);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Show/Hide Activites Filter"
							/>
							<Icon
								iconName={showCloneFilter ? 'CaretUpSolid8' : 'CaretDownSolid8'}
								className="cloneDropDownArrow"
							/>
						</div>
					</div>
					<div
						className={
							!showCloneFilter
								? 'hideCloneFilter'
								: 'cloneActivityFiltersWrapper'
						}
					>
						<div className="cloneActivitySolTypeFilters">
							<div className="multiSelectdropDown CloseMultiSelectDp">
								<span>Consulting / Support</span>
								<MultiSelectDropdown
									className="projectSelectionDropdown"
									data={projectTypeData}
									setValue={setSelectedProjectType}
									value={selectedProjectType}
									enableDropdown={true}
									label="Consulting / Support"
								/>
							</div>

							<div className="multiSelectdropDown CloseMultiSelectDp">
								<span>Solution Methods</span>
								<MultiSelectDropdown
									className="solutionMethodDropdown"
									data={solutionMethods}
									setValue={setSelectedSolutionMethods}
									value={selectedSolutionMethods}
									enableDropdown={true}
									label="Solution Methods"
								/>
							</div>
						</div>
						<div className="cloneActivitySolTypeFilters">
							<div className="multiSelectdropDown CloseMultiSelectDp">
								<span>Phases</span>
								<MultiSelectDropdown
									className="solutionMethodphasesDropdown"
									data={phases}
									setValue={setSelectedPhases}
									value={selectedPhases}
									enableDropdown={true}
								/>
							</div>
							<div className="multiSelectdropDown CloseMultiSelectDp">
								<span>Complexities</span>
								<ComplexityDropdown
									className="complexityDrodown"
									data={complexities}
									setValue={setSelectedComplexity}
									value={selectedComplexity}
									enableDropdown={true}
								/>
							</div>
						</div>
					</div>
					<div className="cloneActivityResultsWrapper">
						{showSpinner ? (
							<Spinner />
						) : (
							<>
								<h6>{foundActivities.length} Result(s) Found</h6>
								{foundActivities.length ? (
									<ul>
										{showMore ? (
											<>
												{foundActivities.map((item) => (
													<li key={item.id + item.name + item.activityId}>
														<span
															class="cloneActivityTitle"
															onClick={() => setSelectedActivity(item)}
															title={item.name}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedActivity(item);
																}
															}}
															tabIndex={0}
															role="button"
															aria-label={item.name}
														>
															{item.name}&nbsp;
														</span>
														<div class="cloneInfiActivity">
															<Icon iconName="Info" tabIndex={0} />
															<div className="tabsTitleToolTip">
																<span className="tabsToolTipArrow"></span>
																<h5>Activity Details</h5>
																<h6>{item.projectTypeName}</h6>
																<span className="detailsOfCloneActivity">
																	<span className="detailsCloneActivitySol">
																		<span>Delivery Method</span>:&nbsp;
																		<span>{item.solutionMethodName}</span>
																	</span>
																	<span className="detailsCloneActivitySol">
																		<span>Phase</span>:&nbsp;
																		<span>{item.phaseTitle}</span>
																	</span>
																</span>
															</div>
														</div>
													</li>
												))}
											</>
										) : (
											<>
												{foundActivities
													.slice(
														0,
														foundActivities.length > 10
															? 10
															: foundActivities.length
													)
													.map((item) => (
														<li key={item.id + item.name + item.activityId}>
															<span
																class="cloneActivityTitle"
																onClick={() => setSelectedActivity(item)}
																title={item.name}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setSelectedActivity(item);
																	}
																}}
																tabIndex={0}
																role="button"
																aria-label={item.name}
															>
																{item.name}&nbsp;
															</span>
															<div class="cloneInfiActivity">
																<Icon iconName="Info" tabIndex={0} />
																<div className="tabsTitleToolTip">
																	<span className="tabsToolTipArrow"></span>
																	<h5>Activity Details</h5>
																	<h6>{item.projectTypeName}</h6>
																	<span className="detailsOfCloneActivity">
																		<span className="detailsCloneActivitySol">
																			<span>Delivery Method</span>:&nbsp;
																			<span>{item.solutionMethodName}</span>
																		</span>
																		<span className="detailsCloneActivitySol">
																			<span>Phase</span>:&nbsp;
																			<span>{item.phaseTitle}</span>
																		</span>
																	</span>
																</div>
															</div>
														</li>
													))}
											</>
										)}
									</ul>
								) : (
									<></>
								)}
								{foundActivities.length > 10 && !showMore ? (
									<span
										className="closeShowMoreLess"
										onClick={() => setShowMore(true)}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowMore(true);
											}
										}}
										tabIndex={0}
										role="button"
										aria-label="Click to Show more Activities"
									>
										Show more
									</span>
								) : (
									<></>
								)}
								{showMore ? (
									<span
										className="closeShowMoreLess"
										onClick={() => setShowMore(false)}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowMore(false);
											}
										}}
										tabIndex={0}
										role="button"
										aria-label="Click to Show less Activities"
									>
										Show less
									</span>
								) : (
									<></>
								)}
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ActivityClone;
