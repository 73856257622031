const word = '/assets/word.svg';
const excel = '/assets/excel.svg';
const ppt = '/assets/ppt.svg';
const outlook = '/assets/outlook.svg';
const pdf = '/assets/pdf.svg';
const zip = '/assets/zip.svg';
const html = '/assets/html.svg';
const inProgress = '/assets/inProgress.svg';
const procedure = '/assets/procedure.png';
const topic = '/assets/topic.svg';
const videotutorials = '/assets/videotutorials.svg';
const method = '/assets/method.png';
const activity = '/assets/Activity.png';
const phase = '/assets/phase.png';
const vsd = '/assets/vsd.png';
const defaultTemplate = '/assets/defaultTemplate.svg';

const fileIcons = {
	pptx: ppt,
	docx: word,
	pdf,
	dotx: word,
	potx: ppt,
	xlsx: excel,
	xlsm: excel,
	msg: outlook,
	zip,
	aspx: html,
	inProgress,
	procedure,
	videotutorials,
	topic,
	method,
	activity,
	phase,
	vsd,
	defaultTemplate,
};

export const iconMapper = {
	2: activity,
	15: method,
	10: topic,
};

export const templateFileTypes = [
	'xlsx',
	'pptx',
	'potx',
	'ppt',
	'pdf',
	'doc',
	'docx',
	'dotx',
	//'zip',
	'xlsm',
	//'aspx',
	'msg',
	'mpp',
	'mpt',
];

export const noPreviewFileTypes = ['zip', 'aspx', 'msg', 'mpp', 'mpt', null];

export default fileIcons;
