import React, { useState, useEffect } from 'react';
import { Link, useHistory   } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function DiscardModalPolygon({ 
	showDiscardModal, 
	setShowDiscardModal, 
	url,
	topicId,
	type,
    setDiscardTopic,
    discardTopic,
    topicsData
}) {
    const [selectedTopic, setSelectedTopic] = useState(topicsData.find((item)=> item.topicId === topicId));
	const [messagesType, setMessagesType] = useState({});
    const { track } = useAppInsights();

	const { axiosGetService, axiosDeleteService } = useAxios();
    const history  = useHistory();
	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=discardPopUpMessage&title=0`
		).then((response) => {
			message['discardPopUpMessage'] = response.data;
		});

		setMessagesType(message);
	};

	const closeModal = () => {
		setShowDiscardModal(false);
	};

	const removeDraftedBusinessRule = async ()=>{
		if(type === 'updateTopics'){
		let api  = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${topicId}/10/0/${true}`
		await axiosGetService(api)
		}
	}

    const deleteTopic = async () => {
		await axiosDeleteService(
			`api/sdmtopics/deletetopic/${selectedTopic.polygonParent}`
		).then((response) => {
			if (response.data.status) {
				track(1, 1804, selectedTopic.topicId, selectedTopic.topicName.replace(/_.*/, ''));
                history.push('/topics');
			}
		});
	};

	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="activityDiscardPopUp"
			show={showDiscardModal}
			onHide={() => {
				closeModal();
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{messagesType['discardPopUpMessage']}</p>
				<div className="discardPopUpActivityButtons">
					<Button
						title="No"
						className="activityDiscardPopUpCancel"
						variant="primary"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => closeModal()}
					>
						No
					</Button>
                    {discardTopic ?
                        <Button title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info"
                            onClick={()=>{
                                removeDraftedBusinessRule();
                                deleteTopic();
                            }}>
                            Yes
                        </Button>
                  :
					<Link aria-label="Discard PopUp Yes" to={url}>
						<Button title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info"
						  onClick={removeDraftedBusinessRule}>
							Yes
						</Button>
					</Link>
                    }
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardModalPolygon;
