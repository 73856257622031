import React, { useMemo, useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px 20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};
const AddVisio = ({
	file,
	setFile,
	getRootProps,
	getInputProps,
	open,
	acceptedFiles,
	isDragActive,
	isDragAccept,
	isDragReject,
	error,
	setError,
	fileError,
	sizeError,
	swimlanePreviewUrl,
	swimlaneSharePointUrl,
	enableSendForApproval,
	setEnableSendForApproval,
	phaseId,
	setSwimlaneSharePointUrl,
	downloadUrl,
	fileSize,
}) => {
	const { url } = useRouteMatch();
	const { axiosPostService, axiosGetService, axiosPostServiceSwimlane } = useAxios();
	const [uploadError, setUploadError] = useState(null);
	const [approvalStatus, setApprovalStatus] = useState([]);
	const [showHistory, setShowHistory] = useState(false);
	const [swimlaneTemplateUrl, setSwimlaneTemplateUrl] = useState(null);
	const { isAdmin } = useLeftMenuCollapse();
	const [usersDetail, setUsersDetail] = useState({});
	const getSwimlaneUrl = async () => {
		await axiosGetService(`api/sdmSettings?sdmKey=VisioTemplateUrl`).then(
			(response) => {
				if (response.data && response.data.length > 0) {
					setSwimlaneTemplateUrl(response.data[0].value);
				}
			}
		);
	};

	const sendForApproval = async () => {
		setEnableSendForApproval(false);
		const formData = new FormData();
		formData.append('fileLocation', file);
		await axiosPostServiceSwimlane(
			`api/sdmphase/swimlanesendforapproval/${phaseId}`,
			formData
		).then((response) => {
			if (response.data.erroMessage) {
				setEnableSendForApproval(true);
				uploadError(response.data.erroMessage);
			} else {
				isAdmin &&
					toast.info('Swimlane Uploaded Successfully', {
						position: 'top-right',
					});
				setSwimlaneSharePointUrl(response.data.url);
				setUploadError(null);
			}
		});
	};

	const getSwimlaneUploadDetails = async (userEmail) => {
		if (!usersDetail[userEmail]) {
			const accessToken = await axiosTokenService();
			await getOtherUserProfile(accessToken, userEmail).then((response) => {
				setUsersDetail((prevState) => ({
					...prevState,
					[userEmail]: response,
				}));
			});
		}
	};

	const checkApprovalStatus = async () => {
		await axiosGetService(`api/sdmPhaseSwimlane/getbyphase/${phaseId}`).then(
			(response) => {
				setApprovalStatus(response.data);
				for (const user of response.data) {
					getSwimlaneUploadDetails(user.uploadedEmail);
				}
			}
		);
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);
	useEffect(() => {
		if (error && file !== null) {
			setError(false);
		}
	}, [file]);

	useEffect(() => {
		getSwimlaneUrl();
		checkApprovalStatus();
	}, []);

	return (
		<div className="container">
			<h4 className="uploadSwimlaneTitle">Add your swimlane diagram here * </h4>
			<div className="upLoadManageSwimlaneDiv">
				{isAdmin && (
					<Link to={`${url}?managePhaseSwimlanes=true`}> Manage Swimlanes</Link>
				)}
				<div>
					<span className="uploadSwimlaneInfoIcon">
						<span
							className="uploadSwimlaneInfoIconHover"
							tabIndex={0}
							aria-label="steps for how to upload swimlane"
							role="button"
							title="upload swimlane info"
						>
							<Icon
								iconName="Info"
							/>
							<div class="tabsTitleToolTip">
								<span class="tabsToolTipArrow"></span>
								<span>
									<ul>
										<li> {`Provide name of the phase within <Sample Phase>`}</li>
										<li>
											{' '}
											{`Ensure the Tab name is same as the given phase name `}
										</li>
										<li> {`Add role names under <Role Name>`}</li>
										<li>
											{' '}
											{`If there are more roles, insert swim lanes in between`}
										</li>
										<li>
											{' '}
											{`Update the activity names within the white boxes <Activity Name>`}
										</li>
										<li> {`Update Group name within Blue Box <Group Name>`}</li>
										<li>
											{' '}
											{`Under Outputs, update associated template names, if any <Template Name>`}
										</li>
										<li>
											{' '}
											{`Adjust the placement of white boxes based on the number activities which are being added.`}
										</li>
										<li>
											{' '}
											{`Position the arrows based on how you want to connect activities`}
										</li>
										<li>
											{' '}
											{`After completing the changes save the file “Phase Name_Version Number” in .vsdx format
							Example: First Sprint_V1.vsdx `}
										</li>
										<li>
											{' '}
											{`From the File Menu, use export option; choose "Change File Type -> Web Page (*.htm) -> Publish`}
										</li>
										<li>
											{' '}
											{`The file will be published in a zip folder with the same name.`}
										</li>
										<li>
											{' '}
											{`Example: First Sprint V1.zip-> First Sprint_V1.svg and First Sprint_V1.htm `}
										</li>
										<li>
											{' '}
											{` Upload the published .svg file using SDMPlus 2.0 -> Swimlane diagram`}
										</li>
									</ul>
								</span>
							</div>
						</span>
					</span>
					<a
						target="_blank"
						title="Download template"
						href={swimlaneTemplateUrl}
						download
					>
						<button className="SwimDowndTemplateButton" tabIndex="-1">
							Download Template
							<span className="swimlaneDownloadOrange"></span>
						</button>
					</a>
				</div>
			</div>

			<div
				className={`filedropContainer ${file ? 'swimlaneDropCont' : null}`}
				{...getRootProps({ style })}
			>
				<input {...getInputProps()} />
				{file !== null || swimlaneSharePointUrl ? (
					<>
						{swimlanePreviewUrl.length > 0 ? (
							<img src={swimlanePreviewUrl[0].preview} />
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
				{file === null && !swimlaneSharePointUrl ? (
					<>
						<span className="swimlaneUploadIcon"></span>
						<span>Drag and drop a file here</span>
						<span>or</span>
					</>
				) : (
					<></>
				)}
				<div className="visiChangeApproveButtonDiv">
					<button
						title="Choose File"
						className="dragDropButton"
						type="button"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								open();
							}
						}}
						onClick={open}
					>
						{file === null && !swimlaneSharePointUrl ? 'Choose File' : 'Change'}
					</button>
					{file !== null ? (
						<>
							<button
								className="visioSentForApproveButton"
								disabled={!enableSendForApproval || phaseId === 0}
								onClick={() => sendForApproval()}
							>
								{isAdmin ? 'Upload' : 'Send for approval'}
							</button>
						</>
					) : (
						<></>
					)}
				</div>
				{file !== null && !enableSendForApproval && !isAdmin ? (
					<p className="visioApproveStatus">
						Approval status : <span>In Progress</span>
					</p>
				) : (
					<></>
				)}
				{approvalStatus.length > 0 ? (
					<button onClick={() => setShowHistory(!showHistory)}>
						Visio History
					</button>
				) : (
					<></>
				)}
				{(file !== null || swimlaneSharePointUrl) && showHistory ? (
					<div className="swimApprovalHistoryDiv">
						<ul>
							{approvalStatus.length > 0 ? (
								<>
									{approvalStatus.map((item) => (
										<li key={item.fileName}>
											<div className="swimlaneHistoryFields">
												<div className="swimlaneHistoryFieldsDiv">
													<span className="swimlaneHistoryDtAndTitle">
														<span>{item.uploadedDate}</span>
														<span>{item.swimlaneFileName}</span>
													</span>
													<span>
														Owner :{' '}
														{usersDetail[item.uploadedEmail] &&
															usersDetail[item.uploadedEmail].firstName +
															' ' +
															usersDetail[item.uploadedEmail].surname}
													</span>
												</div>
												<div className="swimlaneHistStatus">
													<span>
														Approved <Icon iconName="ChevronDown" />
													</span>
												</div>
											</div>
											<div className="swimlaneComments">
												<span>Comment: {item.reviewerComments}</span>
											</div>
										</li>
									))}
								</>
							) : (
								<li>
									<p>No history available</p>
								</li>
							)}
						</ul>
					</div>
				) : (
					<></>
				)}
				{sizeError ? (
					<span className="errorMsg">
						Choose a file size less than {fileSize / 1048576} MB.
					</span>
				) : (
					<></>
				)}
				{uploadError ? uploadError : <></>}
				{fileError ? (
					<span className="errorMsg">
						Invalid file Format. Only Svg are allowed.
					</span>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
export default AddVisio;
