import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import { useConfig } from '../../context/ConfigContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import NewUserPreferencePopUp from '../../components/NewUserPreferencePopUp';

import './UserPreference.css';

const UserPreference = () => {
	const { pathname } = useLocation();
	const [modalShow, setModalShow] = useState(true);
	const [sitePreference, setSitePreference] = useState(2);
	const [projectType, setProjecType] = useState(-1);
	const { axiosPostService, userAlias, axiosGetService } = useAxios();
	const { sdm1URL, sdm2URL } = useConfig();
	const [userChangedPreference, setUserChangedPreference] = useState(true);
	const { appState, setAppState } = useLeftMenuCollapse();

	const redirectToSDM1_0 = () => {
		setModalShow(false);
		if (window.location.href === sdm2URL + '/') {
			window.location.assign(sdm1URL);
		}
		setUserChangedPreference(false);
		changeUserPreference(1);
	};

	const getUserPrefernce = async () => {
		await axiosGetService('/api/sdmuser/getbyemail').then((response) => {
			if (response.data === null) {
				changeUserPreference(2);
			} else if (response.data.userSitePreference === 1) {
				setSitePreference(1);
			} else if (
				(response.data &&
					response.data.userSitePreference === 2 &&
					response.data.projectTypeId === 3) ||
				(response.data && !userChangedPreference && response.data.projectTypeId === 3)
			) {
				if (sdm2URL === window.location.href) {
					setProjecType(0);
				} else {
					if (pathname.includes('topics/support') || pathname.includes('project/2')) {
						changeUserSettings(2);
					} else {
						changeUserSettings(1);
					}
				}
			}
		});
		await axiosGetService('api/sdmuser/get').then(() => {
			// call to set up user settings
		});
	};

	const changeUserSettings = async (selectedProjectTypeId) => {
		const id = Number(selectedProjectTypeId);
		const body = {
			email: userAlias,
			projectTypeId: Number(selectedProjectTypeId),
			userSitePreference: 0,
		};
		await axiosPostService('/api/sdmuser/insert', body).then((response) => {
			setAppState({ ...appState, userDefaultProjectTypeId: id });
		});
	};

	const changeUserPreference = async (userSitePreference) => {
		const body = {
			userSitePreference: userSitePreference,
			email: userAlias,
			projecTypeId: 0,
		};
		await axiosPostService('/api/sdmuser/insert', body).then((response) => {
			setSitePreference(2);
			setModalShow(false);
		});
	};
	useEffect(() => {
		getUserPrefernce();
	}, [sitePreference]);

	return (
		<>
			{Number(projectType) === 0 && sdm2URL + '/' === window.location.href ? (
				<NewUserPreferencePopUp setProjecType={setProjecType} />
			) : null}
			{sitePreference === 1 ? (
				<Modal
					className="userPreference"
					show={modalShow}
					onHide={() => {
						// We should allow use to set preference without closing modal
					}}
					size="md"
					centered
				>
					<Modal.Body>
						<p>
							Your current site preference is set to SDMPlus 1.0. Would you like to change it to
							SDMPlus 2.0?
						</p>
						<div className="userPreferenceButtons">
							<Button
								title="No"
								className="userPreferencePopUpCancel"
								variant="primary"
								onClick={() => redirectToSDM1_0()}
							>
								No
							</Button>

							<Button
								onClick={() => changeUserPreference(2)}
								title="Yes"
								className="userPreferenceAccept"
								variant="info"
							>
								Yes
							</Button>
						</div>
					</Modal.Body>
				</Modal>
			) : null}
		</>
	);
};

export default UserPreference;
