import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import Dropdown from '../Dropdown';
import { toast } from 'react-toastify';
import { useAppInsights } from '../../../context/TelemetryContext';

const SelectToggleFilters = ({ pages }) => {
	const filteredPages = pages.filter(page => !page.name.includes("Consulting"));
	const [selectedPage, setSelectedPage] = useState(null);
	const [artifacts, setArtifacts] = useState([]);
	const { axiosGetService, axiosPostService } = useAxios();
	const { track } = useAppInsights();
	const getArtifactsList = async () => {
		const response = await axiosGetService(
			`api/sdmartifactmaster/getArtifactFilterByPageType?pageTypeId=${selectedPage.id}`
		);
		setArtifacts(response.data);
	};
	const updatePageArtifacts = async () => {
		if (selectedPage) {
			const postBody = {
				pageTypeId: selectedPage.id,
				artifactIds: artifacts.reduce((filteredaritfacts, artifact) => {
					if (artifact.isSelected) {
						filteredaritfacts.push(artifact.id);
					}
					return filteredaritfacts;
				}, []),
			};
			const response = await axiosPostService(
				`api/sdmToggleFilters/updateToggleFilters`,
				postBody
			);
			track(1, 2003, selectedPage.id, selectedPage.name);
			if (response.data.status) {
				toast.info('Filters Updated Successfully', { position: 'top-right' });
			} else {
				toast.error(response.data.errorMsg, { position: 'top-right' });
			}
		} else {
			toast.error('Page Not Selected', { position: 'top-right' });
		}
	};
	const handleArtifactClick = (artifact, index) => {
		const updatedArtifacts = [...artifacts];
		updatedArtifacts[index].isSelected = !artifact.isSelected;
		setArtifacts(updatedArtifacts);
	};
	useEffect(() => {
		if (selectedPage) {
			getArtifactsList();
		}
	}, [selectedPage]);
	return (
		<>
			<div class="middleDetailsContainer">
				<div class="methodOwnerMiddleContainer">
					<div class="methodPhaseDetailsTab">
						<div className="addRolesListContainer addDeliveryPlaybookContainer">
							<div className="phaseSwimlaneFilters">
								<h6>Select Page To Manage Toggle Filters</h6>
								<div class="multiSelectdropDown CloseMultiSelectDp">
									<Dropdown
										id="pages"
										className="activityReferProjectSelectionDropdown"
										data={filteredPages}
										value={selectedPage}
										setValue={setSelectedPage}
										label="Select Page"
										enableDropdown={true}
										disableNone={true}
									/>
								</div>
							</div>
							{selectedPage ? (
								<div className="toggleFiltersListContainer">
									<h6>Select filters you would like to have in the Page</h6>
									<ul className="phaseSwimlaneHistory">
										{artifacts?.map((artifact, index) => (
											<li key={artifact.id}>
												<label class="NotificationChkboxContainer">
													<input
														name="Support"
														type="checkbox"
														checked={artifact.isSelected}
														onChange={() =>
															handleArtifactClick(artifact, index)
														}
													/>
													<span class="CustomCheckmark"></span>
													<span class="complexityLabel">{artifact.name}</span>
												</label>
											</li>
										))}
									</ul>
								</div>
							) : (
								<h6>Select Page to View Filters</h6>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="saveContinueDetailsContainer">
				<button
					className="forwardButton"
					onClick={updatePageArtifacts}
					onKeyPress={(e) => {
						if (e.which === 13) {
							updatePageArtifacts();
						}
					}}
					aria-label="Update Page Filters"
					tabIndex={0}
				>
					<span className="saveContinueButton">Save</span>
				</button>
			</div>
		</>
	);
};

export default SelectToggleFilters;
