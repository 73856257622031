import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import './TemplateCrowdSourcing.css';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getOtherUserProfile } from '../../helpers/GraphService';
import fileIcons from '../../static/TemplateIcons';
import ComplexityModal from './ApproverComplexityModal';
import RejectionModal from './ApproverRejectedModal';
import { useAppInsights } from '../../context/TelemetryContext';
import {
	closeFullScreen,
	openFullScreen,
} from '../../helpers/FullscreenService';

const TemplateApproveCard = ({
	data,
	complexities,
	setAccepedtRejectedTemplate,
	accepedtRejectedTemplate,
	singleView,
	complexitiesModal,
	setSuccessMsg,
}) => {
	const { track } = useAppInsights();
	const [showRelatedTemplates, setShowRelatedTemplates] = useState(false);
	const [constributorDetails, setConstributorDetails] = useState(null);
	const [reviewerDetails, setReviewerDetails] = useState(null);
	const [showComplexityModal, setShowComplexityModal] = useState(false);
	const [showRejectionModal, setShowRejectionModal] = useState(false);
	const [preview, setPreview] = useState({
		show: false,
		data: null,
	});

	const handlePreview = (suggestion) => {

		const elem = document.getElementById(
			`templateCrowdSourcingPreview_${data.templateId}_${data.complexityId}`
		);
		if (preview.show) {
			setPreview({ show: false, data: null });
			closeFullScreen();
		} else {
			setPreview({
				show: true,
				data: suggestion,
			});
			openFullScreen(elem);
		}
	};

	const handleFullScreen = () => {
		if (
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement
		) {

		} else {
			setPreview({ show: false, data: null });
		}
	};

	const getContributorAndReviewerInfo = async (userId, contributorType) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userId).then((response) => {
			if (contributorType === 1) {
				setConstributorDetails(response.firstName + ' ' + response.surname);
			} else {
				setReviewerDetails(response.firstName + ' ' + response.surname);
			}
		});
	};

	useEffect(() => {
		getContributorAndReviewerInfo(data.contributorEmail, 1);
		getContributorAndReviewerInfo(data.reviewerEmail, 2);
	}, []);

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreen, false);
		document.addEventListener('mozfullscreenchange', handleFullScreen, false);
		document.addEventListener(
			'webkitfullscreenchange',
			handleFullScreen,
			false
		);
		document.addEventListener('msfullscreenchange', handleFullScreen, false);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreen, false);
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'msfullscreenchange',
				handleFullScreen,
				false
			);
		};
	}, []);

	return (
		<div className="templateReviewerParent">
			<div className="newTableParentRow">
				<div className="newTableRow">
					<div className="newWrapper newAttributes">
						<div className="newWrapper newTitleCommentModuleReporter ">
							<div className="newWrapper newTitleComment ">
								<div className="newColumn newTitle newActTd ">
									<div>
										<img
											src={fileIcons[data.fileType]}
											alt="fileType"
											className="imageOfTemplateResult"
										/>
										<div className="templateCrowdTitleDiv">
											<span className="newTitle">{data.title}</span>
											{data.isLocalized ? (
												<span className="language">
													<strong>Language</strong>: {data.languageName}
												</span>
											) : (
												<></>
											)}
											<div className="templateCrowdPreviewDownload">
												<span
													onClick={() => {
														handlePreview(data);
														track(1, 159, data.id, data.title);
													}}
													role="button"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															handlePreview(data);
															track(1, 159, data.id, data.title);
														}
													}}
												>
													Preview
												</span>{' '}
												|{' '}
												<a
													href={data.filePath}
													target="_blank"
													onClick={() => track(1, 112, data.id, data.title)}
												>
													Download
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="newWrapper ">
						<div className="newColumn templateComplexity newActTd ">
							{!data.isLocalized ? complexities[data.complexityId] : 'NA'}
						</div>
					</div>
					<div className="newWrapper ">
						<div className="newColumn uploadDetails newActTd ">
							<p>{constributorDetails}</p>
							<span>{data.notificationDate}</span>
						</div>
					</div>
					<div className="newWrapper ">
						<div className="newColumn uploadDetails newActTd ">
							<p>{reviewerDetails}</p>
							<span>{data.notificationDate}</span>
						</div>
					</div>
					<div className="newWrapper ">
						<div className="newColumn details newActTd ">
							{data.contributorComments}
						</div>
					</div>
					<div className="newWrapper ">
						<div className="newColumn approveAndReject newActTd ">
							<button
								onClick={() => {
									setShowComplexityModal(true);
									track(1, 158, data.id, data.title);
								}}
							>
								Publish
							</button>
							<button
								onClick={() => {
									setShowRejectionModal(true);
									track(1, 160, data.id, data.title);
								}}
							>
								Reject
							</button>
						</div>
					</div>
					{showComplexityModal ? (
						<ComplexityModal
							templateData={data}
							submittedComplexity={complexities[data.complexityId]}
							complexitiesData={complexitiesModal}
							setShowComplexityModal={setShowComplexityModal}
							setAccepedtRejectedTemplate={setAccepedtRejectedTemplate}
							accepedtRejectedTemplate={accepedtRejectedTemplate}
							setSuccessMsg={setSuccessMsg}
							singleView={singleView}
						/>
					) : null}
					{showRejectionModal ? (
						<RejectionModal
							templateData={data}
							setShowRejectionModal={setShowRejectionModal}
							setAccepedtRejectedTemplate={setAccepedtRejectedTemplate}
							accepedtRejectedTemplate={accepedtRejectedTemplate}
							singleView={singleView}
							setSuccessMsg={setSuccessMsg}
						/>
					) : null}
				</div>
				<div
					className="templateCrowdSourcingPreview"
					id={`templateCrowdSourcingPreview_${data.templateId}_${data.complexityId}`}
				>
					{preview.show ? (
						<>
							<div className="templateSuggestedPreview">
								<div className="templateCrowdSourcingPanelHeader">
									<span>Suggested Template</span>
									<span className="tempPreviewInfoIcon">
										<div className="tabsShowMoreMenu">
											<span className="tabsShowMoreArrow"></span>
											<div className="menuItemUL">
												{preview.data.contributorComments}
											</div>
										</div>
									</span>
									<div>
										<a href={preview.data.filePath} target="_blank">
											Download File
										</a>
										<button
											className="templatePreviewReject"
											title={
												preview.data.isApprover
													? 'Reject'
													: 'You are not a approver'
											}
											onClick={() => {
												if (Number(preview.data.reviewStatus) !== 1) {
													setShowRejectionModal(true);
													handlePreview();
												}
											}}
											disabled={
												Number(preview.data.reviewStatus) === 1 ||
												!preview.data.isApprover
											}
										>
											Reject
										</button>
										<button
											className="templatePreviewApprove"
											title={
												preview.data.isApprover
													? 'Publish'
													: 'You are not a approver'
											}
											onClick={() => {
												if (Number(preview.data.reviewStatus) !== 1) {
													setShowComplexityModal(true);
													handlePreview();
												}
											}}
											disabled={
												Number(preview.data.reviewStatus) === 1 ||
												!preview.data.isApprover
											}
										>
											Publish
										</button>
									</div>
								</div>
								<iframe
									src={preview.data.previewUrl}
									className="templateCrowdSourcingIframe"
									scrolling="yes"
									arial-label="Template Preview"
								/>
							</div>
							<div className="templateCurrentPreview">
								<div className="templateCrowdSourcingPanelHeader">
									<span>Current Template</span>
									<div>
										{preview.data.previewUrlOld ? (
											<a href={preview.data.filePathOld} target="_blank">
												Download File
											</a>
										) : (
											<></>
										)}
										<Icon
											iconName="ChromeClose"
											onClick={handlePreview}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													handlePreview();
												}
											}}
										/>
									</div>
								</div>
								{preview.data.previewUrlOld ? (
									<iframe
										src={preview.data.previewUrlOld}
										className="templateCrowdSourcingIframe"
										scrolling="yes"
										arial-label="Template Preview"
									/>
								) : (
									<h5>There is no template available.</h5>
								)}
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default TemplateApproveCard;
