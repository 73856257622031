import React, { useState, useMemo, useEffect } from 'react';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';

import ActivityEditor from '../PhaseLeftMenu/ActivityEditor';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

import { Icon } from '@fluentui/react';

import TemplateDropdown from './MultiSelectionDropdown';
import TemplateSelectionDropdwon from './MultiSelectWithSearch';
import TemplateTypeDropdown from '../Dropdown';
import ComplexityDropdown from './ComplexityDropdown';
import TemplatePreview from './TemplatePreview';
import { useAxios } from '../../context/AxiosContext';
import { templateTypeData, templateFocusData } from '../../static/ActivityEdit';
import { templateFileTypes } from '../../static/TemplateIcons';
import { titleValidator, hashtagValidator } from '../../helpers/Validation';
import TemplateIcon from '../../static/TemplateIcons';
import TemplateEdit from './TemplatesEdit';
import HashtagSuggestions from '../HashtagSuggestions';
import AccessabilityCheck from '../TemplateLanding/AccessabilityCheckModal';
import { useParams } from 'react-router-dom';


const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	padding: '18px 10px',
	marginBottom: '15px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

const Deliverables = ({
	showFields,
	setshowFields,
	handelFormView,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	setComplexityLevelUpdatedDetails,
	data,
	value,
	setValue,
	enableDropdown,
	className,
	searchValue,
	setSearchValue,
	updatedValue,
	setUpdatedValue,
	templatesMapper,
	complexityList,
	phases,
	phaseSearchValue,
	setPhaseSearchvalue,
	setTemplatesMapper,
}) => {
	const defaultTemplateType = {
		id: 0,
		name: 'Select',
	};
	const { axiosGetService, axiosPostService, axiosDeleteService, axiosPostServiceSwimlane } = useAxios();
	const [fileSize, setFileSize] = useState(0);
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
	});
	const [showTemplateForm, setShowTemplateForm] = useState(false);
	const [templateName, setTemplateName] = useState('');
	const [templateType, setTemplateType] = useState(defaultTemplateType);
	const [templateFocus, setTemplateFocus] = useState(defaultTemplateType);
	const [selectedComplexities, setSelectedComplexities] = useState([
		{ id: selectedComplexity },
	]);
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [templateFile, setTemplateFile] = useState(null);
	const [fileUploadStatus, setFileUploadStatus] = useState(false);
	const [fileTypeError, setFileTypeError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [deletedTemplates, setDeletedTemplates] = useState([]);
	const [uploadedTemplateIds, setUploadedTemplateIds] = useState([]);
	const [isInProgress, setIsInProgress] = useState(false);
	const [templateDescription, setTemplateDescription] = useState('');
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtags, setHashtags] = useState([]);
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [updateTemplateId, setUpdateTemplateId] = useState(0);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [showAccessabillity, setShowAccessabillity] = useState(false);
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const {phaseId,activityId} = useParams();
	const selectedPhase = phases.filter(e=>e.id=== Number(phaseId))

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const uploadTemplate = async () => {
		if (
			templateName.trim().length === 0 ||
			selectedComplexities.length === 0 ||
			templateType.id === 0 ||
			templateFocus.id === 0 ||
			(!templateFile && !isInProgress)
		) {
			setErrorMessage(true);
		} else if (!sizeError && !fileTypeError) {
			setErrorMessage(false);
			const formData = new FormData();
			formData.append('fileLocation', templateFile);
			setFileUploadStatus(true);
			let templateUrlResponse;
			if (templateFile && !isInProgress) {
				templateUrlResponse = await axiosPostServiceSwimlane(
					`api/sdmtemplate/uploadDraftTemplate`,
					formData
				);
			}
			const complexitiesIds = selectedComplexities.map((item) => {
				return item.id;
			});
	        const phasesIds = [Number(phaseId)]

			const postData = {
				title: templateName,
				focus: templateFocus.name,
				templateType: templateType.name,
				fileLocation: templateFile ? templateUrlResponse.data.templateURL : '',
				templatePhaseIds: phasesIds,
				copyToComplexityIds: complexitiesIds,
				fileType: templateFile ? templateUrlResponse.data.templateType : '',
				inProgress: isInProgress,
				description: templateDescription,
				hashTags: hashtags.toString(),
				activityIdentifier: activityId,
			};
			await axiosPostService(`api/sdmtemplate/addDraft`, postData).then(
				(response) => {
					if (!response.data.status) {
						toast.error(response.data.errorMsg, {
							position: toast.POSITION.TOP_RIGHT,
						});
						setFileUploadStatus(false);
					} else {
						setUpdatedValue((prevState) => ({
							...prevState,
							[selectedComplexity]: {
								...updatedValue[selectedComplexity],
								templatesList: [
									...updatedValue[selectedComplexity].templatesList,
									{
										name: templateName,
										fileLocation: templateFile
											? templateUrlResponse.data.templateURL
											: null,
										id: response.data.templateId,
										previewUrl: response.data.previewUrl,
									},
								],
							},
						}));
						setTemplatesMapper((prevState) => ({
							...prevState,
							[response.data.templateId]: {
								name: templateName,
								fileLocation: response.data.fileLocation,
								id: response.data.templateId,
								previewUrl: response.data.previewUrl,
								fileType: templateFile
									? templateUrlResponse.data.templateType
									: null,
								inProgress: isInProgress,
							},
						}));
						setUploadedTemplateIds([
							...uploadedTemplateIds,
							response.data.templateId,
						]);
						deafultState();
						setFileUploadStatus(false);
						setUserAddedHashtags([]);
						setShowTemplateForm(false);
						toast.info('Template added successfull', {
							position: 'top-right',
						});
					}
				}
			);
		}
	};

	const removeHashtag = (tag) => {
		const updatedHashtag = hashtags.filter((item) => tag !== item);
		setHashtags(updatedHashtag);
	};

	const removeDuplicateHashtag = (data) => {
		return data.filter((value, index) => data.indexOf(value) === index);
	};

	const updateHashtags = () => {
		setHashtags([...userAddedHashtags, ...hashtags]);
		setHashtagSuggestionInput('');
		setUserAddedHashtags([]);
	};

	const filterFileType = (file) => {
		const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
		if (templateFileTypes.includes(selectedFileType)) {
			setTemplateFile(file);
			setFileTypeError(false);
			setSizeError(false);
			setErrorMessage(null);
		} else {
			setTemplateFile(null);
			setFileTypeError(true);
			setSizeError(false);
			setErrorMessage(null);
		}
	};

	const deafultState = () => {
		setTemplateName('');
		setTemplateFile(null);
		setTemplateFocus(defaultTemplateType);
		setSelectedComplexities([{ id: selectedComplexity }]);
		setSelectedPhases([]);
		setIsInProgress(false);
		setTemplateDescription('');
		setHashtagsInput('');
		setHashtags([]);
		setTemplateType(defaultTemplateType);
	};

	const closeModal = () => {
		setEnableTemplatePreview(false);
		setTemplatePreviewData(null);
	};

	const deleteTemplates = async (templatesList, type) => {
		if (type === 'save') {
			const availableTemplateIds = [...templatesList];
			for (const complexity of complexityList) {
				availableTemplateIds.push(...value[complexity.id].templatesList);
			}
			const filteredTemplateIds = availableTemplateIds.map(({ id }) => {
				return id;
			});
			const deletedTemplatesIds = deletedTemplates.filter(
				(id) => !filteredTemplateIds.includes(id)
			);
			if (deletedTemplatesIds.length > 0) {
				await axiosDeleteService(
					`api/sdmtemplate/deleteDraftTemplate?templateIds=${deletedTemplatesIds.toString()}`
				);
			}
			setDeletedTemplates([]);
		} else {
			if (uploadedTemplateIds.length > 0) {
				await axiosDeleteService(
					`api/sdmtemplate/deleteDraftTemplate?templateIds=${uploadedTemplateIds.toString()}`
				);
				setUploadedTemplateIds([]);
			}
		}
	};

	const removeTemplate = (templateId) => {
		const filteredTemplates = updatedValue[
			selectedComplexity
		].templatesList.filter(({ id }) => id !== templateId);
		setUpdatedValue((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...updatedValue[selectedComplexity],
				templatesList: filteredTemplates,
			},
		}));
		setDeletedTemplates([...deletedTemplates, templateId]);
	};

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setFileTypeError(false);
			setSizeError(true);
			setErrorMessage(null);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setTemplateFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		getFileSize();
	}, []);
// Get the current and original descriptions based on the selected complexity level
const currentDeliverables = complexityLevelUpdatedDetails?.[selectedComplexity]?.deliverables || [];
const originalDeliverables = complexityLevelDetails?.[selectedComplexity]?.deliverables || [];

const currentTemplatesList = complexityLevelUpdatedDetails?.[selectedComplexity]?.templatesList || [];
const originalTemplatesList = complexityLevelDetails?.[selectedComplexity]?.templatesList || [];

useEffect(() => {
	// Update complexityLevelDetails when deliverables or templatesList differ from their originals
	if (
		currentDeliverables !== originalDeliverables ||
		currentTemplatesList !== originalTemplatesList
	) {
		setComplexityLevelDetails(prevState => ({
			...prevState,
			[selectedComplexity]: {
				...prevState?.[selectedComplexity],
				deliverables: currentDeliverables,
				templatesList: [...currentTemplatesList],
			},
		}));
	}
}, [selectedComplexity, currentDeliverables, currentTemplatesList]);

useEffect(() => {
	// Update `originalDeliverables` and `originalTemplatesList` whenever `selectedComplexity` changes
	const newDeliverables = complexityLevelDetails?.[selectedComplexity]?.deliverables || [];
	const newTemplatesList = complexityLevelDetails?.[selectedComplexity]?.templatesList || [];
	
	setComplexityLevelUpdatedDetails(prevState => ({
		...prevState,
		[selectedComplexity]: {
			...prevState?.[selectedComplexity],
			deliverables: newDeliverables,
			templatesList: [...newTemplatesList],
		},
	}));
}, [selectedComplexity]);

useEffect(() => {
	// Call deleteTemplates when the templatesList is updated
	if (currentTemplatesList !== originalTemplatesList) {
		deleteTemplates([...currentTemplatesList], 'save');
	}
}, [currentTemplatesList, originalTemplatesList]);


	return (
		<div
			className={
				 'Detailswrapper pt-0 pl-0 pr-0 pb-0'
			}
		>
			<div className="DetailswrapperTitle">
				{true ? (
					<></>
				) : (
					<>
						{(complexityLevelUpdatedDetails[selectedComplexity] &&
							complexityLevelUpdatedDetails[selectedComplexity].deliverables) ||
						(value[selectedComplexity] &&
							value[selectedComplexity].templatesList.length > 0) ? (
							<>
								{value[selectedComplexity] &&
								value[selectedComplexity].templatesList.length > 0 ? (
									<>
										<ul className="newActivityTemplateListWithLink ">
											{value[selectedComplexity].templatesList.map((item) => (
												<li key={item.id}>
													{templatesMapper[item.id] ? (
														<img
															src={
																templatesMapper[item.id].inProgress
																	? TemplateIcon['inProgress']
																	: templatesMapper[item.id].fileType
																	? TemplateIcon[
																			templatesMapper[
																				item.id
																			].fileType.toLowerCase()
																	  ] || TemplateIcon['defaultTemplate']
																	: TemplateIcon['defaultTemplate']
															}
															className="imageOfSearchResult"
															alt="File Type"
														/>
													) : (
														<></>
													)}
													<a
														href={
															templatesMapper[item.id] &&
															templatesMapper[item.id].inProgress
																? null
																: templatesMapper[item.id] &&
																  templatesMapper[item.id].fileLocation
														}
													>
														{(templatesMapper[item.id] &&
															templatesMapper[item.id].name) ||
															item.name}
													</a>
												</li>
											))}
										</ul>
									</>
								) : (
									<></>
								)}
								<div className="deleditTemplateAccessabilityDiv">
									<div>
										<span className="delTemplateAccessabilityIcon"></span>
										<span>
											Please check the accessability of the templates.
										</span>
									</div>
									<div
										class="accessabilityCheckDiv"
										onClick={() => setShowAccessabillity(true)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowAccessabillity(true);
											}
										}}
										role="button"
										title="Accessability Instructions"
										aria-label="Please check the accessability of the templates."
									>
										<div>
											<span class="accessabilityIcon"></span>
											<span class="accessabilityHoverIcon"></span>
											<span class="accessabilityCheckText">
												Accessibility instructions
											</span>
										</div>
									</div>
								</div>
								{complexityLevelDetails[selectedComplexity] &&
								complexityLevelDetails[selectedComplexity].deliverables ? (
									<div className='forGuidanceImg'
										dangerouslySetInnerHTML={{
											__html: HtmlSanitizer(
												complexityLevelDetails[selectedComplexity].deliverables
											),
										}}
									/>
								) : (
									<></>
								)}
							</>
						) : (
							<span
								className="addingInputButton"
								tabIndex={0}
								onClick={() => handelFormView('deliverables')}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handelFormView('deliverables');
									}
								}}
								aria-label={`click to Add deliverables`}
								title="Add"
								role="Button"
							>
								+ Add
							</span>
						)}
					</>
				)}
				{enableTemplatePreview ? (
					<TemplatePreview
						enableTemplatePreview={enableTemplatePreview}
						closeModal={closeModal}
						data={templatePreviewData}
					/>
				) : (
					<></>
				)}
				{true ? (
					<>
						<div className="newActivityfieldsWrapper">
							<TemplateSelectionDropdwon
								className={'templateseditList mr-0'}
								enableDropdown={enableDropdown}
								data={data}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								complexityId={selectedComplexity}
								updatedValue={updatedValue}
								setUpdatedValue={setUpdatedValue}
								type={'templatesList'}
								setSelectedRaci={setSelectedTemplate}
								pageType={'Activity'}
							/>
							{!showTemplateForm ? (
								<span
									className="addMoreTools addNewTemplate mt-2 mb-2"
									onClick={() => {
										setUpdateTemplateId(0);
										setShowTemplateForm(true);
									}}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setUpdateTemplateId(0);
											setShowTemplateForm(true);
										}
									}}
								>
									+ Add New Templates
									<br />
								</span>
							) : (
								<></>
							)}
							{updatedValue[selectedComplexity] &&
							updatedValue[selectedComplexity].templatesList.length > 0 ? (
								<>
									<ul className="newActivityeditTemplateList pl-0 mb-0">
										{updatedValue[selectedComplexity].templatesList.map(
											(item) => (
												<li>
													<div className="newActivityTemplateListItemEditacForm">
														<div className="newActivityTemplateListItem">
															{templatesMapper[item.id] ? (
																<img
																	src={
																		templatesMapper[item.id].inProgress
																			? TemplateIcon['inProgress']
																			: templatesMapper[item.id].fileType
																			? TemplateIcon[
																					templatesMapper[
																						item.id
																					].fileType.toLowerCase()
																			  ] || TemplateIcon['defaultTemplate']
																			: TemplateIcon['defaultTemplate']
																	}
																	className="imageOfSearchResult"
																	alt="File Type"
																/>
															) : (
																<></>
															)}
															{templatesMapper[item.id] &&
																templatesMapper[item.id].name}
															
														</div>
														<span className="neweditActivityTemplateListItemIcons mr-2 mt-0">
															{/* <button
																tabIndex={0}
																aria-label="Preview"
																onKeyPress={(e) => {
																	setTemplatePreviewData(item);
																	setEnableTemplatePreview(true);
																}}
																onClick={() => {
																	setTemplatePreviewData(item);
																	setEnableTemplatePreview(true);
																}}
																title="Preview"
															> */}
															<Icon
																className="editActivityTemplateIcon mr-1"
																iconName="EditSolid12"
																onClick={() => {
																	setShowTemplateForm(false);
																	setUpdateTemplateId(item.id);
																}}
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setShowTemplateForm(false);
																		setUpdateTemplateId(item.id);
																	}
																}}
															/>
																<Icon
																	className="viewiconacitivity"
																	iconName="View"
																	tabIndex={0}
																aria-label="Preview"
																onKeyPress={(e) => {
																	setTemplatePreviewData(item);
																	setEnableTemplatePreview(true);
																}}
																onClick={() => {
																	setTemplatePreviewData(item);
																	setEnableTemplatePreview(true);
																}}
																title="Preview"
																/>
																{/* <span className="previewIconBlack" iconName="previewIconBlack"></span> */}
															{/* </button> */}
															<a
																href={
																	templatesMapper[item.id] &&
																	templatesMapper[item.id].inProgress
																		? null
																		: templatesMapper[item.id] &&
																		  templatesMapper[item.id].fileLocation
																}
																title="Download"
															>
																<Icon
																	className="NewdownloadTemplate"
																	iconName="ReleaseGate"
																/>
															</a>
															<Icon
																iconName="Cancel"
																onClick={() => removeTemplate(item.id)}
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		removeTemplate(item.id);
																	}
																}}
															/>
														</span>
													</div>
													{updateTemplateId === item.id ? (
														<TemplateEdit
															template={item}
															value={value}
															setValue={setValue}
															complexityList={complexityList}
															templatesMapper={templatesMapper}
															setTemplatesMapper={setTemplatesMapper}
															selectedComplexity={selectedComplexity}
															phases={phases}
															phaseSearchValue={phaseSearchValue}
															setPhaseSearchvalue={setPhaseSearchvalue}
															defaultTemplateType={defaultTemplateType}
															setUpdateTemplateId={setUpdateTemplateId}
															phaseId={phaseId}
															activityId={activityId}
															selectedPhase={selectedPhase}
														/>
													) : (
														<></>
													)}
												</li>
											)
										)}
									</ul>
								</>
							) : (
								<></>
							)}
							<div className="deleditTemplateAccessabilityDiv">
								<div>
									<span className="delTemplateAccessabilityIcon"></span>
									<span>Please check the accessability of the templates.</span>
								</div>
								<div
									class="accessabilityCheckDiv"
									onClick={() => setShowAccessabillity(true)}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowAccessabillity(true);
										}
									}}
									role="button"
									title="Accessability Instructions"
									aria-label="Please check the accessability of the templates."
								>
									<div>
										<span class="accessabilityIcon"></span>
										<span class="accessabilityHoverIcon"></span>
										<span class="accessabilityCheckText">
											Accessibility instructions
										</span>
									</div>
								</div>
							</div>
							{showTemplateForm ? (

								<>
								<Modal
												size="lg"
												centered
												show={true}
												onHide={() => {
													/* Not calling any function to close modal*/
												}}
												dialogClassName="modal-90w"
											>
												<Modal.Header>
													<Modal.Title>
														<div className="polygonTopicModalRelatedContenTitle">
															<span className='newToolDialogHeading'>Add Template</span>
														</div>
													</Modal.Title>
													<Icon
														className="relatedContentClose"
														iconName="ChromeClose"
														title="Close"
														onClick={() => {
															setShowTemplateForm(false);
														}}
													/>
												</Modal.Header>
												<Modal.Body className="modal-body-acitivity-scrollable">
													<div className="addPolygonTopicContenUrlContainer">
													<div className="addNewTemplatesToActivitynew pt-0">
									<div className="addNewTemplatesFieldsName">
										<div className="addNewTemplatesNameTitle">
											<div className="addNewTemplatesWithOutOutputdata">
												<h6>Template Name</h6>
												<label className="NotificationChkboxContainer">
													<input
													className='templateInnerContent'
														name="Critical"
														type="checkbox"
														checked={isInProgress}
														onChange={() => {
															setIsInProgress(!isInProgress);
														}}
														role="checkbox"
														aria-label="click to check Output without template Checkbox "
													/>
													<span className="CustomCheckmark"></span>
													<span className="complexityLabel">
														Output without template
													</span>
												</label>
											</div>

											<input
												placeholder="Template name"
												value={templateName}
												onChange={(e) => {
													setTemplateName(e.target.value);
												}}
											/>

											{templateName && !titleValidator.test(templateName) ? (
												<p className="errorMsg">Invalid name</p>
											) : (
												<></>
											)}
										</div>
									</div>

									<div className="addNewTemplatesFields">
										<div>
											<h6>Template Type</h6>
											<TemplateTypeDropdown
												className="templateTypeDropdown"
												data={templateTypeData}
												enableDropdown={true}
												value={templateType}
												setValue={setTemplateType}
											/>
										</div>
										<div>
											<h6>Template Focus</h6>
											<TemplateTypeDropdown
												className="templateFocusDropdown"
												data={templateFocusData}
												enableDropdown={true}
												value={templateFocus}
												setValue={setTemplateFocus}
											/>
										</div>
									</div>
									<div className="addNewTemplatesFields">
										<div>
											<h6>Complexity</h6>
											<ComplexityDropdown
												className="complexityDropdown"
												enableDropdown={true}
												data={complexityList}
												value={selectedComplexities}
												setValue={setSelectedComplexities}
											/>
										</div>
										<div>
											<h6>Phases</h6>
											<TemplateDropdown
												className="phasesDropdown"
												data={phases}
												hidePhaseDropDown={'hidePhaseDropDown'}
												enableDropdown={true}
												searchValue={phaseSearchValue}
												setSearchValue={setPhaseSearchvalue}
												updatedValue={selectedPhases}
												setUpdatedValue={setSelectedPhases}
												label="Phases"
											/>
											<div className='deliverablesPhaseContainer'>
                                            <input type='text' value={selectedPhase[0].name} disabled/>
					                        </div>
										</div>
									</div>
									<div className="addNewTemplatesFields">
										<div className="addNewTemplatesDescription">
											<h6>Template Description</h6>
											<textarea
												value={templateDescription}
												onChange={(e) => setTemplateDescription(e.target.value)}
											/>
										</div>
									</div>
									<div className="addNewTemplatesFields addNewTemplatesFieldsHashTags mt-0">
										<div className="addNewTemplatesDescription">
											<h6>Add Hashtags</h6>
											<HashtagSuggestions
												className="topicsHashtagsInput"
												enableDropdown={true}
												data={hashtagSuggestions}
												setUpdateApiCall={setUpdateApiCall}
												setHashtagSuggestions={setHashtagSuggestions}
												searchValue={hashtagsInput}
												setSearchValue={setHashtagsInput}
												existingHashtags={hashtags}
												userAddedHashtags={userAddedHashtags}
												setUserAddedHashtags={setUserAddedHashtags}
												hashtagSuggestionInput={hashtagSuggestionInput}
												setHashtagSuggestionInput={setHashtagSuggestionInput}
												closeHashtagForm={() => {
													setHashtagsInput('');
													setUserAddedHashtags([]);
												}}
												submitHashtags={updateHashtags}
												autoFocus={false}
												showSubmitButton={true}
											/>
										</div>
										{hashtags.length > 0 ? (
											<div className="hashTagsContainer">
												{hashtags.map((item) => (
													<span key={item} tabIndex={0}>
														{item}{' '}
														<Icon
															iconName="Cancel"
															onClick={() => removeHashtag(item)}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeHashtag(item);
																}
															}}
															aria-label={`click to Remove ${item} Hashtag`}
															title="Cancel"
															role="Button"
														/>
													</span>
												))}
											</div>
										) : (
											<></>
										)}
									</div>
									{!isInProgress ? (
										<div {...getRootProps({ style })}>
											<h6>Choose File*</h6>
											<input {...getInputProps()} />
											{templateFile === null ? (
												<div>
													<span>Drag and drop a file here or</span>
													<button
														title="Choose File"
														className="dragDropButton"
														type="button"
														onClick={open}
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																open();
															}
														}}
													>
														Choose File
													</button>
												</div>
											) : (
												<>
													<div className="templateContributionFileNameContainer">
														<div
															key={templateFile.path}
															className="templateContributionFileName"
														>
															{templateFile.path}
															<button
																onClick={() => setTemplateFile(null)}
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setTemplateFile(null);
																	}
																}}
																title="Remove Uploaded File"
																role="Button"
																aria-label="Remove"
															>
																X
															</button>
														</div>
													</div>
												</>
											)}
											{errorMessage ? (
												<span className="errorMsg">All input are required</span>
											) : (
												<></>
											)}
											{sizeError ? (
												<span className="errorMsg">
													File should be less than {fileSize / 1048576} MB
												</span>
											) : (
												<></>
											)}
											{fileTypeError ? (
												<span className="errorMsg">
													Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf,
													doc, docx, dotx, zip, xlsm, aspx, msg, mpp, mpt" are
													allowed.
												</span>
											) : (
												<></>
											)}
										</div>
									) : (
										<></>
									)}
									{errorMessage && isInProgress ? (
										<span className="errorMsg">All input are required</span>
									) : (
										<></>
									)}
									{templateFile === null && !isInProgress ? (
										<></>
									) : (
										<>
											<div className="deliverablesTemplatesButtons">
												{templateFile ? (
													<button
														disabled={fileUploadStatus}
														title="Choose File"
														className="dragDropButton"
														type="button"
														onClick={open}
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																open();
															}
														}}
													>
														Change
													</button>
												) : (
													<></>
												)}
												
											</div>
										</>
									)}
								</div>
													</div>
												</Modal.Body>
												<Modal.Footer>
													<div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
														<button
															className="cancelBusinessContent  mr-2"
															onClick={() => {
																setShowTemplateForm(false);
															}}
														>
															Cancel
														</button>
														
														<button
													title="Save"
													className="addBtnBusinessContent"
													type="button"
													disabled={
														fileUploadStatus ||
														!titleValidator.test(templateName) ||
														templateName.trim().length === 0
													}
													onClick={() => uploadTemplate()}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															uploadTemplate();
														}
													}}
												>
													Save
												</button>
													</div>
												</Modal.Footer>
											</Modal>
								
								
								</>
							) : (
								<></>
							)}
						</div>

						<ActivityEditor
							value={complexityLevelUpdatedDetails}
							setValue={setComplexityLevelUpdatedDetails}
							type="deliverables"
							selectedComplexity={selectedComplexity}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		
		</div>
	);
};

export default Deliverables;
