import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAxios } from '../../../context/AxiosContext';
import { useHistory, useLocation } from 'react-router';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import LinkDetails from './LinkDetails';
import GroupAndSequence from './GroupAndSequence';
import PreviewLeftPanel from './PreviewLeftPanel';
import './Activities.css';


function ManageLeftPanel() {
  	const { pathname } = useLocation();
	const history = useHistory();
	const [leftMenuBuilderTabId, setLeftMenuBuilderTabId] = useState(1);
	const [selectedTab, setSelectedTab] = useState(leftMenuBuilderTabId);
	const { axiosGetService, axiosPostService } = useAxios();
	const [quickLinkTypeId, setQuickLinkTypeId] = useState(3);
	const [complexityId, setComplexityId] = useState(0);
	const [phaseId, setPhaseId] = useState(0);
	const [leftMenuGroupData, setLeftMenuGroupData] = useState([]);
	const [groupCreated, setGroupCreated] = useState(false);
	const [groupUpdated, setGroupUpdated] = useState(false);
	const [linkCreated, setLinkCreated] = useState(false);
	const [linkUpdated, setLinkUpdated] = useState(false);
	const [linkDeleted, setLinkDeleted]= useState(false);
	const [previewStage, setPreviewStage] = useState(false);
	const [previewData, setPreviewData] = useState();
	const [disableBtn, setDisableBtn] = useState(true);
	const [groupAndLinksChanged, setGroupAndLinksChanged] = useState(0)
	
	const {
		phase,
		parentPhase,
		complexity,
		setExpandedActivityGroups,
		expandedActivityGroups,
		complexityValidActivities,
		phaseHashtags,
		role,
		phaseDynamicFilterTag,
		endUserView,
	} = useLeftMenuCollapse();
	const leftMenuBuilderTab = [
		{
			id: 1,
			name: 'Link Details',
		},
		{
			id: 2,
			name: 'Sequence',
		},
	];

	const getLeftMenuData = async () => {
		const encodedHashtags = phaseHashtags.map((item) => {
			return encodeURIComponent(item);
		});
		await axiosGetService(
			`api/sdmquicklink/getquicklinkgroupactivities/${quickLinkTypeId}/${complexityId}/${phaseId}`
		).then((response) => {
			setLeftMenuGroupData([...response.data].sort((a, b) => a.sequence - b.sequence));
		});
	};

	const handleClickNext = () => {
		setSelectedTab(2);
		if(groupAndLinksChanged === 0){
			setDisableBtn(true);
		}
	}

	const handleClickPreview = async () => {
		const groupSequenceData = [];
		const linkSequenceData = [];
		await leftMenuGroupData.map(async (item) => {
			const groupSequence = {
				"Id": item.quickLinkGroupId,
				"Sequence": item.sequenceNumber
			};
			groupSequenceData.push(groupSequence);
			await item.quickLinkActivities.map((data) => {
				const linkSequence = {
					"Id": data.quickLinkActivityId,
					"SequenceNumber": data.sequenceNumber
				};
				linkSequenceData.push(linkSequence);
			});
		});

		const postData = {
            QuickLinkTypeId: quickLinkTypeId,
            PhaseId: phaseId,
            ComplexityId: complexityId,
			SdmQuickLinkGroups: groupSequenceData,
			SdmQuickLinkActivities: linkSequenceData
        };

        await axiosPostService(`api/sdmquicklink/quicklinkspreview`, postData).then(
            (response) => {
				setPreviewStage(true);
				
				setPreviewData([...response.data].sort((a, b) => a.sequence - b.sequence));
            }
        );
	}

	const handleClickPublish =async () =>{
		const postData = {
            QuickLinkTypeId: quickLinkTypeId,
            PhaseId: phaseId
        };

		await axiosPostService(`api/sdmquicklink/quicklinkpublish`, postData).then(
            (response) => {
				if (response.data.publishStatus) {
					setPreviewStage(false);
					toast.info(`You have successfully publish the Links.`, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
				else{
					toast.error(response.data.errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
				}
				history.push(pathname);
            }
        );
	}
	const handleClickCancel = () => {
		history.push(pathname);
	}

	const handleClickBack = () => {
		setPreviewStage(false);
		setSelectedTab(2);
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getLeftMenuData();
			setDisableBtn(false);
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [groupCreated,groupUpdated,linkCreated,linkUpdated, linkDeleted]);

	useEffect(() => {
        const interval = setInterval(() => {
            setGroupCreated(false);
			setGroupUpdated(false);
			setLinkCreated(false);
			setLinkUpdated(false);
			setLinkDeleted(false);
        }, 1000);
        return () => {
          clearInterval(interval);
        };
    }, []);

	const activityMenu = {
		1: (
			<LinkDetails 
				quickLinkTypeId = {quickLinkTypeId}
				leftMenuGroupData={leftMenuGroupData}
				setLeftMenuGroupData={setLeftMenuGroupData}
				setGroupCreated={setGroupCreated}
				setGroupUpdated={setGroupUpdated}
				phaseId={phaseId}
				complexityId={complexityId}
				setLinkCreated={setLinkCreated}
				setLinkUpdated={setLinkUpdated}
				setLinkDeleted={setLinkDeleted}
				setGroupAndLinksChanged={setGroupAndLinksChanged}
			/>
		),
		2: (
			<>
				{previewStage ? (
					<PreviewLeftPanel 
						previewData={previewData}
						setPreviewData={setPreviewData}
					/>
				) : (
					<GroupAndSequence 
						quickLinkTypeId = {quickLinkTypeId}
						leftMenuGroupData={leftMenuGroupData}
						setLeftMenuGroupData={setLeftMenuGroupData}
						setDisableBtn={setDisableBtn}
					/>
				)}
			</>
		),
	};

  return (
    <Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements manageLeftPanel"
			size="lg"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter" >
					Left Panel Builder
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu">
									{leftMenuBuilderTab.map((tab) => (
										<li
											onClick={() => setSelectedTab(tab.id)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedTab(tab.id);
												}
											}}
											tabIndex={0}
											key={tab.id}
											aria-label={
												selectedTab === tab.id
													? `${tab.name}`
													: `${tab.name} not selected`
											}
											aria-selected={selectedTab === tab.id ? 'true' : 'false'}
											role="tab"
											className={
												selectedTab === tab.id ? 'activePhaseTab' : null
											}
										>
											<span
												aria-selected={
													selectedTab === tab.id ? 'true' : 'false'
												}
											>
												{tab.name}
											</span>
										</li>
									))}
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div id="adjustWidthId" className="methodOwnerMiddleContainer">									
									{activityMenu[selectedTab]}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="left-btn-cancel"
					onClick={selectedTab === 1 ? handleClickCancel: (previewStage ? handleClickBack : handleClickCancel)}
				>
					{selectedTab === 1 ? 'Cancel' : (previewStage ? 'Back' : 'Cancel')}
				</button>
				<button className="left-btn-next"
					onClick={selectedTab === 1 ? handleClickNext: (previewStage ? handleClickPublish : handleClickPreview)}
					disabled={disableBtn}
				>
					{selectedTab === 1 ? 'Next' : (previewStage ? 'Publish' : 'Preview')}
				</button>
			</Modal.Footer>
		</Modal>
  )
}

export default ManageLeftPanel;
