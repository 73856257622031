import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { v4 as uniqueIdGenerator } from 'uuid';

import ReviewersOrApproversList from '../ReviewersOrApproversList';
import MyContributionInfo from '../MyContributionInfo';

import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';

const MyContributions = ({
	currentPage,
	pageLimit,
	setDataCount,
	setCurrentPage,
	handleStatusClassName,
	enableGamification,
}) => {
	const { track } = useAppInsights();
	const { getNotifications } = useLeftMenuCollapse();
	const [myContributions, setMyContributions] = useState([]);
	const [searchText, setSearchText] = useState(0);
	const { axiosGetService, axiosPostService } = useAxios();
	const [contributorsInfo, setContributorsInfo] = useState({});
	const [documentStatusId, setDocumentStatusId] = useState(-1);
	const [showApproversOrReviewersList, setShowApproversOrReviewersList] =
		useState(false);
	const [selectedArtifactId, setSelectedArtifactId] = useState(-1);

	const getMyContributionsData = async () => {
		setDataCount(0);
		setMyContributions([]);
		await axiosGetService(
			`api/sdmtopics/gettopiccontributions?topicName=${searchText}&pageSize=${pageLimit}&pageNumber=${currentPage}`
		).then((response) => {
			if (Number(response.data.count) > 0) {
				const contributionData = [];
				for (const topic of response.data.topics) {
					contributionData.push({ ...topic, uniqueId: uniqueIdGenerator() });
				}
				setMyContributions(contributionData);
				setDataCount(Number(response.data.count));
			} else {
				setMyContributions([]);
				setDataCount(null);
			}
		});
	};
	const getUserDetails = async (
		contributedEmail,
		reviewerEmail,
		approverEmail
	) => {
		const accessToken = await axiosTokenService();
		const userDetails = {};
		if (contributedEmail && !(contributedEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, contributedEmail);
			userDetails[contributedEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (reviewerEmail && !(reviewerEmail in userDetails)) {
			const userData = await getOtherUserProfile(accessToken, reviewerEmail);
			userDetails[reviewerEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (approverEmail && !(approverEmail in userDetails)) {
			const userData = await getOtherUserProfile(accessToken, approverEmail);
			userDetails[approverEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		setContributorsInfo((prevState) => ({ ...prevState, ...userDetails }));
	};

	const getSearchText = (e) => {
		if (!e.target.value.trim()) {
			setSearchText(0);
		} else {
			setSearchText(e.target.value);
		}
	};

	const postNotificationRead = async (contribution) => {
		const postBody = {
			actionTypeId: contribution.actionTypeId,
			itemId: contribution.topicId,
			itemTypeName: 'Topic',
			isNotifiedUI: true,
			createdOn: new Date().toUTCString(),
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then((response) => {
				getNotifications();
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (currentPage === 1) {
			getMyContributionsData();
		} else {
			setCurrentPage(1);
		}
	}, [searchText]);

	useEffect(() => {
		getMyContributionsData();
	}, [currentPage]);

	useEffect(() => {
		track(2, 164, 'Topic', 'My Contributions');
	}, []);

	return (
		<div className="myContributionListDiv">
			<div className="myContributionParentDiv">
				<div className="myConributionHeaderDiv">
					<div className="newTableChildRow header">
						<div className="newTableRow">
							<div className="newWrapper newAttributes">
								<div className="newWrapper newTitleCommentModuleReporter ">
									<div className="newWrapper newTitleComment">
										<div className="newColumn newTitle newActTd ">
											Topic Name{' '}
											<div className="myContrTitleSearch">
												<Icon iconName="Search" />
												<input
													type="text"
													title="Search for a Topic"
													value={searchText === 0 ? '' : searchText}
													placeholder="Search by name"
													onChange={(e) => getSearchText(e)}
													pattern="[^\s]+"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="newWrapper ">
								<div className="newColumn date newActTd ">
									Contribution Date
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status  newActTd ">
									Contribution Status
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status newActTd ">
									Document Status
								</div>
							</div>
							{enableGamification ? (
								<div className="newWrapper ">
									<div className="newColumn points  newActTd ">Points</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{myContributions.map((contribution, id) => (
					<div className="mycontributionChildWrapper" key={id}>
						<div className="newTableChildRow">
							<div className="newTableRow">
								<div className="newWrapper newAttributes">
									<div className="newWrapper newTitleCommentModuleReporter ">
										<div className="newWrapper newTitleComment">
											<div className="newColumn newTitle newActTd ">
												<Link
													title={contribution.topicName}
													aria-label={contribution.topicName}
													onClick={() => {
														postNotificationRead(contribution);
														track(
															1,
															164,
															contribution.topicId,
															contribution.topicName,
															{
																artifactType: 'Topic',
																crowdSourcingType: 'My Contributions',
																complexity: contribution.complexityName,
															}
														);
													}}
													to={
														contribution.documentStatus === 'Pending Review'
															? `/topics/edit/${
																	contribution.topicId
															  }`
															: `/mycontributions/historicalpreview/topics/${contribution.id}/${contribution.topicId}/${contribution.stateId}`
													}
												>
													{contribution.topicName}
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="newWrapper ">
									<div className="newColumn date newActTd ">
										{contribution.contributionDate || '-'}
									</div>
								</div>
								<div className="newWrapper ">
									<div
										className={`newColumn status newActTd ${handleStatusClassName(
											contribution.contributionStatus
										)}`}
									>
										{contribution.contributionStatus
											? contribution.contributionStatus
											: '-'}
									</div>
								</div>
								<div className="newWrapper ">
									<div
										className={`newColumn status newActTd contributionsInfo ${handleStatusClassName(
											contribution.documentStatus
										)}`}
										tabIndex={0}
										role="button"
										aria-label={contribution.documentStatus}
										onMouseEnter={() => {
											setDocumentStatusId(id);
											getUserDetails(
												contribution.contributedEmail,
												contribution.reviewerEmail,
												contribution.approverEmail
											);
										}}
										onMouseLeave={() => {
											setDocumentStatusId(-1);
										}}
										onBlur={(e) => {
											setDocumentStatusId(-1);
										}}
										onFocus={(e) => {
											setDocumentStatusId(id);
											getUserDetails(
												contribution.contributedEmail,
												contribution.reviewerEmail,
												contribution.approverEmail
											);
										}}
										onClick={() => {
											if (
												contribution.documentStatus === 'Pending Review' ||
												contribution.documentStatus === 'Pending Approval'
											) {
												setShowApproversOrReviewersList(true);
												setSelectedArtifactId(contribution.uniqueId);
											}
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (
													contribution.documentStatus === 'Pending Review' ||
													contribution.documentStatus === 'Pending Approval'
												) {
													setShowApproversOrReviewersList(true);
													setSelectedArtifactId(contribution.uniqueId);
												}
											}
										}}
									>
										{contribution.documentStatus || '-'}
										{documentStatusId === id ? (
											<MyContributionInfo
												contributorEmail={contribution.contributedEmail}
												reviewerEmail={contribution.reviewerEmail}
												approverEmail={contribution.approverEmail}
												contributionData={contribution}
												contributorsInfo={contributorsInfo}
												type="myContribution"
												documentStatus={contribution.documentStatus}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
								{enableGamification ? (
									<div className="newWrapper ">
										<div className="newColumn points newActTd ">
											{contribution.points}
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
						{showApproversOrReviewersList &&
						contribution.uniqueId === selectedArtifactId ? (
							<ReviewersOrApproversList
								showList={setShowApproversOrReviewersList}
								setShowList={setShowApproversOrReviewersList}
								actionTypeId={
									contribution.documentStatus === 'Pending Review' ? 1 : 2
								}
								artifactId={contribution.topicId}
								artifactType="topics"
								setSelectedArtifactId={setSelectedArtifactId}
							/>
						) : (
							<></>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default MyContributions;
