import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
// import CriteriaActivityEditor from './CriteriaActivityEditor';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Dropdown from '../Dropdown';

const ExitCriteria = ({
	showFields,
	setshowFields,
	handelFormView,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	handelFormViewEditPerventClose,
	setComplexityLevelUpdatedDetails,
	criteriaTypes,
}) => {
	const defaultCriteriType = {
		id: 1,
		name: 'Text',
		//type: 'option',
	};
	const defaultCriteriaTypeValue = {
		2: 'Numeric',
		1: 'Text',
	};
	const tabs = criteriaTypes.filter(
		(item) => item.title === 'Exit Criteria' || item.title === 'Output'
	);
	const dropdownValue = [
		{ id: 2, name: 'Text', type: 'option' },
		{ id: 1, name: 'Numeric', type: 'option' },
	];
	const [criteriaName, setCriteriaName] = useState('');
	const [criteriaDescription, setCriteriaDescription] = useState('');
	const [criteriaType, setCriteriaType] = useState(defaultCriteriType);
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updatedId, setUpdateId] = useState(0);
	const [selectedExitTab, setSelectedExitTab] = useState(
		criteriaTypes.filter((item) => item.title === 'Exit Criteria')[0].id
	);


	const [exitCriteriaList, setExitCriteriaList] = useState(
		[...(complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName === 'Exit Criteria'
		) || [
				{
					id: uniqueIdGenerator(),
					name: "",
					criteriaType: 'Exit',
					valueType: defaultCriteriType.id,
					status: 'new',
					criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Exit Criteria')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Exit Criteria')[0]?.id))[0]?.title,
					sequence: 1
				}
			])]
	);

	const [outputList, setOutputList] = useState(
		[...(complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName !== 'Exit Criteria'
		) || [
				{
					id: uniqueIdGenerator(),
					name: "",
					status: 'new',
					criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Output')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Output')[0]?.id))[0]?.title,
					criteriaType: 'Exit',
					sequence: 1
				}
			])]
	);





	const [outputDescription, setOutputDecription] = useState('');
	// useEffect(() => {
	// 	setshowFields(['exitCriteria']);
	// }, []);
	useEffect(() => {
		const exitCriteria = complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName === 'Exit Criteria'
		);

		const outCriteria = complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName !== 'Exit Criteria'
		);

		setExitCriteriaList(exitCriteria.length > 0 ? exitCriteria : [
			{
				id: uniqueIdGenerator(),
				name: "",
				criteriaType: 'Exit',
				valueType: defaultCriteriType.id,
				status: 'new',
				criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Exit Criteria')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Exit Criteria')[0]?.id))[0]?.title,
				sequence: 1
			}
		]);

		setOutputList(outCriteria.length > 0 ? outCriteria : [
			{
				id: uniqueIdGenerator(),
				name: "",
				status: 'new',
				criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Output')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Output')[0]?.id))[0]?.title,
				criteriaType: 'Exit',
				sequence: 1
			}
		]);
	}, [selectedComplexity]);

	useEffect(() => {
		if (editMode && updatedId !== 0 ) {
			handelFormViewEditPerventClose('exitCriteria');
		}
	}, [criteriaName,outputDescription]);

	const updateExitCriteria = () => {
		if (criteriaType.id !== 0) {
			if (editMode) {
				const updatedDtails = exitCriteriaList.map((item) => {
					if (item.id === updatedId) {
						return {
							...item,
							name: criteriaName,
							criteriaType: 'Exit',
							valueType: criteriaType.name === 'Text' ? 1 : 2,
						};
					} else {
						return item;
					}
				});
				setExitCriteriaList([...updatedDtails]);
				setError(false);
			} else {
				setExitCriteriaList([
					...exitCriteriaList,
					{
						id: uniqueIdGenerator(),
						name: criteriaName, 
						criteriaType: 'Exit',
						valueType: criteriaType.name === 'Text' ? 1 : 2,
						status: 'new',
						criteriaTypeID: tabs.filter(({ id }) => id === selectedExitTab)[0]
							.id,
						criteriaTypeName: tabs.filter(({ id }) => id === selectedExitTab)[0]
							.title,
						sequence: exitCriteriaList.length+1
					},
				]);
				setCriteriaName('');
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setError(false);
			}
		} else {
			setError(true);
		}
	};

	const updateOutputList = () => {
		if (editMode && outputDescription) {
			const updatedDtails = outputList.map((item) => {
				if (item.id === updatedId) {
					return {
						...item,
						name: outputDescription,
						criteriaType: 'Exit',
					};
				} else {
					return item;
				}
			});
			setOutputList([...updatedDtails]);
			setError(false);
		} else if (outputDescription) {
			setOutputList([
				...outputList,
				{
					id: uniqueIdGenerator(),
					name: outputDescription,
					status: 'new',
					criteriaTypeID: tabs.filter(({ id }) => id === selectedExitTab)[0].id,
					criteriaTypeName: tabs.filter(({ id }) => id === selectedExitTab)[0]
						.title,
					criteriaType: 'Exit',
					sequence: outputList.length+1
				},
			]);
			setOutputDecription('');
			setError(false);
		}
	};

	const deleteOtherDetails = (deleteId, value, setValue) => {
		const filteredValue = value.filter(({ id }) => id !== deleteId);
		filteredValue.forEach((item,index)=>{
            item.sequence = index+1
        })
		setValue(filteredValue);
	};

	const tabComponent = {
		[criteriaTypes.filter((item) => item.title === 'Exit Criteria')[0].id]: (
			<ExitCriteriaComponent
				error={error}
				dropdownValue={dropdownValue}
				criteriaType={criteriaType}
				setCriteriaType={setCriteriaType}
				setCriteriaName={setCriteriaName}
				criteriaDescription={criteriaDescription}
				setCriteriaDescription={setCriteriaDescription}
				criteriaName={criteriaName}
				exitCriteriaList={exitCriteriaList}
				defaultCriteriaTypeValue={defaultCriteriaTypeValue}
				setEditMode={setEditMode}
				setUpdateId={setUpdateId}
				showFields={showFields}
				handelFormView={handelFormView}
				deleteOtherDetails={deleteOtherDetails}
				updateExitCriteria={updateExitCriteria}
				setError={setError}
				setExitCriteriaList={setExitCriteriaList}
				defaultCriteriType={defaultCriteriType}
				setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
				complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
				selectedComplexity={selectedComplexity}
				complexityLevelDetails={complexityLevelDetails}
				setComplexityLevelDetails={setComplexityLevelDetails}
				tabs={tabs}
				selectedExitTab={selectedExitTab}
				outputList={outputList}
			/>
		),
		[criteriaTypes.filter((item) => item.title === 'Output')[0].id]: (
			<OutputComponent
				error={error}
				showFields={showFields}
				outputDescription={outputDescription}
				setOutputDecription={setOutputDecription}
				updateOutputList={updateOutputList}
				outputList={outputList}
				setOutputList={setOutputList}
				setEditMode={setEditMode}
				setUpdateId={setUpdateId}
				setError={setError}
				deleteOtherDetails={deleteOtherDetails}
				handelFormView={handelFormView}
				setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
				complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
				selectedComplexity={selectedComplexity}
				complexityLevelDetails={complexityLevelDetails}
				setComplexityLevelDetails={setComplexityLevelDetails}
				exitCriteriaList={exitCriteriaList}
				tabs={tabs}
				selectedExitTab={selectedExitTab}
			/>
		),
	};

	const saveExitCriteria = () => {
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				exitCriteria: [...exitCriteriaList, ...outputList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				exitCriteria: [...exitCriteriaList, ...outputList],
			},
		}));
		handelFormView('exitCriteria');
	};

	return (
		<div
			className={
				' Detailswrapper pt-0 pl-0 pb-0'
					
			}
		>
			
			<div className="DetailswrapperTitle entryExit">
				<div className="otherDetailsContainer">
					<div className="entryExitCriteriadiv">
					
						{true && (
							<div className="entryAndInputTabs">
								<ul>
									{tabs.map((tab) => (
										<li
											className={
												tab.id === selectedExitTab ? 'selectedExitTab pt-0' : 'pt-0'
											}
											onClick={() => {
												setSelectedExitTab(tab.id);
												setEditMode(false);
											}}
											key={tab.id}
										>
											{tab.title}
										</li>
									))}
								</ul>
							</div>
						)}

						{true ? (
							tabComponent[selectedExitTab]
						) : (
							<>
								{tabs.map((item) => (
									<React.Fragment key={item.id}>
										{tabComponent[item.id]}
									</React.Fragment>
								))}
							</>
						)}
					</div>
				</div>
			</div>
	
		</div>
	);
};

const ExitCriteriaComponent = ({
	error,
	dropdownValue,
	criteriaType,
	setCriteriaType,
	setCriteriaName,
	criteriaDescription,
	setCriteriaDescription,
	criteriaName,
	exitCriteriaList,
	defaultCriteriaTypeValue,
	setEditMode,
	setUpdateId,
	showFields,
	handelFormView,
	deleteOtherDetails,
	updateExitCriteria,
	setError,
	setExitCriteriaList,
	defaultCriteriType,
	setComplexityLevelUpdatedDetails,
	complexityLevelUpdatedDetails,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	outputList,
	tabs,selectedExitTab
}) => {
	const [entryUpdatedArray, setEntryUpdatedArray] = useState([]);
	const handleToChangeEntryContent = (e, item, index) => {

		if (item.id !== undefined) {
			setEntryUpdatedArray([...entryUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...exitCriteriaList];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setExitCriteriaList(updatedBusinessRuleContent);
		saveExitCriteria(updatedBusinessRuleContent);
	}


	const saveExitCriteria = (exitCriteriaList) => {
		const validOutputList = Array.isArray(outputList) ? outputList : [];
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				exitCriteria: [...exitCriteriaList, ...validOutputList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				exitCriteria: [...exitCriteriaList, ...validOutputList],
			},
		}));
		handelFormView('exitCriteria');
	};
	
	const removeEntryContent = (index) => {
		// Only allow removal if there is more than one item in the list
		if (exitCriteriaList.length > 1) {
			const updatedContent = [...exitCriteriaList];
			updatedContent.splice(index, 1);
			setExitCriteriaList(updatedContent);
			saveExitCriteria(updatedContent);
		}
	};
	const handleAddBusinessContentFields = () => {
		const lastItem = exitCriteriaList[exitCriteriaList.length - 1];

		// Check if the last item has all required fields filled
		if (lastItem.valueType !== "" && lastItem.name !== "") {

			setExitCriteriaList([
				...exitCriteriaList,
				{
				

					name: "",
					criteriaType: 'Exit',
					valueType: defaultCriteriType.id,
					status: 'new',
				
					criteriaTypeID: tabs.filter(({ id }) => id === selectedExitTab)[0]
						.id,
					criteriaTypeName: tabs.filter(({ id }) => id === selectedExitTab)[0]
						.title,
					sequence: exitCriteriaList.length + 1,


				}
			]);
		} else {
			// Optionally, show an alert or error message if the fields are not filled
			alert("Please fill in the current row before adding a new one.");
		}
	};

	const handleDropdownChange = (newId, index) => {
		
		// Extract the id from newId object
		const newValueType = parseInt(newId.id);
	
		const updatedList = exitCriteriaList.map((item, i) => {
			if (i === index) {
				
				return { ...item, valueType: newValueType };
			}
			return item;
		});
		
		console.log('Updated list:', updatedList);
		setExitCriteriaList(updatedList);
		saveExitCriteria(updatedList);
	};
	
	return (
		<>
	
				<div className="addPolygonTopicRelatedInputContainer">
				<div className="polygonInputContainer">

					{/* entryCriteriaList */}
					{exitCriteriaList.map((item, index) => (

						<div className="row " key={item.id || index}>
							<div className="col-xl-4">
								<Dropdown
									className="entryExitCriteriadropdown"
									data={dropdownValue}
									
									value={dropdownValue.find(option => option.id === item.valueType) || dropdownValue[0]} // Use item.valueType directly
									setValue={(newVal) => handleDropdownChange(newVal, index)}
									enableDropdown={true}
									pageType={'Activity'}
								/>
							</div>
							<div className="col-xl-7 pr-1">
								<input
									className='exitEditCriteriaInput'
									title={`Exit Criteria Description`}
								placeholder={`Enter Description`}
									value={item.name}
									name="name"
									onChange={(e) => {
										handleToChangeEntryContent(e, item, index);
									}}
								></input>
							</div>
							<div className="addPolygonTopicFieldSave mt-2">
								<button className= "relatededitContentDelete"
									onClick={() => {
										removeEntryContent(index);
									}}>
									<Icon
										className="relatedContentDeleteIcon"
										iconName="ChromeClose"
										title="Close"
									/>
								</button>
							</div>
							{index === exitCriteriaList.length - 1 && (
								<div className="addPolygonTopicFieldSave mt-2">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddBusinessContentFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddBusinessContentFields();
											}
										}}
									>
										<Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/>
									</button>
								</div>)}
						</div>
					))}



				</div></div>
		

			
		</>
	);
};

const OutputComponent = ({
	error,
	showFields,
	outputDescription,
	setOutputDecription,
	updateOutputList,
	outputList,
	setOutputList,
	setEditMode,
	setUpdateId,
	setError,
	deleteOtherDetails,
	handelFormView,
	setComplexityLevelUpdatedDetails,
	complexityLevelUpdatedDetails,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	tabs,selectedExitTab,exitCriteriaList
}) => {
	const [entryUpdatedArray, setEntryUpdatedArray] = useState([]);
	const handleToChangeInputContent = (e, item, index) => {
		if (item.id !== undefined) {
			setEntryUpdatedArray([...entryUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...outputList];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setOutputList(updatedBusinessRuleContent);
		saveExitCriteria(updatedBusinessRuleContent);
	}

	// const saveExitCriteria = (updatedInputList) => {
	// 	debugger;
	// 	const validexitCriteriaList = Array.isArray(exitCriteriaList) ? exitCriteriaList : [];
	// 	setComplexityLevelUpdatedDetails((prevState) => ({
	// 		...prevState,
	// 		[selectedComplexity]: {
	// 			...complexityLevelUpdatedDetails[selectedComplexity],
	// 			exitCriteria: [...updatedInputList, ...validexitCriteriaList],
	// 		},
	// 	}));
	// 	setComplexityLevelDetails((prevState) => ({
	// 		...prevState,
	// 		[selectedComplexity]: {
	// 			...complexityLevelDetails[selectedComplexity],
	// 			exitCriteria: [ ...updatedInputList,...validexitCriteriaList],
	// 		},
	// 	}));
	// 	handelFormView('exitCriteria');
	// };

	const saveExitCriteria = (updatedInputList) => {
		const validentryCriteriaList = Array.isArray(exitCriteriaList) ? exitCriteriaList : [];
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				exitCriteria: [...validentryCriteriaList,...updatedInputList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				exitCriteria: [...validentryCriteriaList,...updatedInputList],
			},
		}));
		handelFormView('entryCriteria');
	};


	const removeEntryContent = (index) => {
		// Only allow removal if there is more than one item in the list
		if (outputList.length > 1) {
			const updatedContent = [...outputList];
			updatedContent.splice(index, 1);
			setOutputList(updatedContent);
			saveExitCriteria(updatedContent);
		}
	};
	const handleAddInputFields = () => {
		const lastItem = outputList[outputList.length - 1];

		// Check if the last item has all required fields filled
		if (lastItem.valueType !== "" && lastItem.name !== "") {

			setOutputList([
				...outputList,
				{

					name: "",
					status: "new",
					// criteriaTypeID: lastItem.criteriaTypeID,
					criteriaType: 'Exit',

					criteriaTypeID: tabs.filter(({ id }) => id === selectedExitTab)[0]
						.id,
					criteriaTypeName: tabs.filter(({ id }) => id === selectedExitTab)[0]
						.title,
					// criteriaTypeName: lastItem.criteriaTypeName,
					// criteriaType: lastItem.criteriaType,
					sequence: outputList.length + 1,



					

				}
			]);
		} else {
			// Optionally, show an alert or error message if the fields are not filled
			alert("Please fill in the current row before adding a new one.");
		}
	};
	return (
		<>
			
		
				<div className="addPolygonTopicRelatedInputContainer">
				<div className="polygonInputContainer">

					{outputList.map((item, index) => (
						<div className="row " key={item.id || index}>

							<div className="col-xl-11 pr-1">


								<input
									className='entryEditCriteriaInputdesc'
									title={`Output Description`}
								placeholder={`Enter Description`}
									value={item.name}
									onChange={(e) => handleToChangeInputContent(e, item, index)}
									name='name'
								></input>
							</div>
							<div className="addPolygonTopicFieldSave mt-1">
								<button className="relatededitContentDelete"
									onClick={() => {
										removeEntryContent(index);
									}}>
									<Icon
										className="relatedContentDeleteIcon"
										iconName="ChromeClose"
										title="Close"
									/>
								</button>
							</div>
							{index === outputList.length - 1 && (
								<div className="addPolygonTopicFieldSave mt-1">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddInputFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddInputFields();
											}
										}}
									>
										<Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/>
									</button>
								</div>
							)}
						</div>
					))}
				</div>
			</div>

		</>
	);
};

export default ExitCriteria;
