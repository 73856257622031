import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory, useLocation } from "react-router";
import Select, { components } from "react-select";
import "./ManageQuickLinks.css";
import { handleTernaryCondition } from "../../helpers/Validation";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { NavLink, useRouteMatch, Link } from "react-router-dom";
import { Icon } from "@fluentui/react/lib/Icon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAxios } from "../../context/AxiosContext";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { urlValidator } from '../../helpers/Validation';


function ManageQuickLinks() {
  const { pathname } = useLocation();
  const history = useHistory();
  const { setExpandedActivityGroups, expandedActivityGroups } =
    useLeftMenuCollapse();
  const { url } = useRouteMatch();
  const { axiosPostService, axiosGetService, axiosDeleteService } = useAxios();

  // const [selectedStage, setSelectedStage] = useState(null);
  // const [stageOptions, setStageOptions] = useState([{ value: "", label: "" }]);
  const [phaseOptions, setPhaseOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [complexityOptions,setComplexityOptions] = useState([]);
  const [complexityLevelData, setComlexityLevelData] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [quickLinkTypeId, setQuickLinkTypeId] = useState(null);
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [linkText, setLinkText] = useState("");
  const [linkURL, setLinkURL] = useState("");
  const [quickLinks, setQuickLinks] = useState([]);
  const [addedActivities, setAddedActivities] = useState([]);
  const [activeTab, setActiveTab] = useState("internalActivities"); // New state for tabs
  // Add a new state to control the display of data
  const [showData, setShowData] = useState(false);
  const [quickLinkData, setQuickLinkData] = useState([]); // Assuming initialData is your JSON data
  const [editingLink, setEditingLink] = useState(null);
  const [editingGroupName, setEditingGroupName] = useState(null);
  const [groupNameInput, setGroupNameInput] = useState("");
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] = useState(false);
  const [deletingGroupName, setDeletingGroupName] = useState(null);
  const [deleteTarget, setDeleteTarget] = useState(null); // Holds either the group ID or the quick link ID.
  const [displayGroups, setDisplayGroups] = useState(false);
  const [quickLinksGroupDeleteId, setQuickLinksGroupDeleteId] = useState(null);
  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showDuplicateGroup, setShowDuplicateGroup] = useState(false);
  const [groupName, setGroupName] = useState();
  const [previewStage, setPreviewStage] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [buttonStage, setButtonStage] = useState('fetch');
  const [showModal, setShowModal] = useState(true);
  const [showDuplicateLink, setShowDuplicateLink] = useState(false);
  const [isStageSelected, setIsStageSelected] = useState(false);
  const [ showDuplicateLinkUrl,setShowDuplicateLinkUrl] = useState(false)
  const [addNewGroup, setAddNewGroup] = useState([])
  const [internalGroupActivities, setInternalGroupActivities] = useState([])
  const [linkTextMessage,setlinkTextMessage] = useState('')
  const [validateTitle, setValidateTitle] = useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [showGroupRequired, setShowGroupRequired] = useState(false);
  const [validateUrl, setValidateUrl] = useState(false);
  const [linkUrlMessage, setlinkUrlMessage] = useState();
  const [allSelected, setAllselected] = useState(false)
  const [isRealizeValue, setIsRealizeValue] = useState(false)
  const [showDeleteActivityConfirmation, setShowDeleteActivityConfirmation] = useState(false)
  const [isProjectDelivery, setIsProjectDelivery] = useState();

  const getProjectDeliveryId = async () => {
    await axiosGetService(
      `api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`
    ).then((response) => {
      setIsProjectDelivery(response.data[0].value.split(",").at(1));
    });
  };

  useEffect(() => {
    getProjectDeliveryId();
  }, []);


  const [activities, setActivities] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
        setShowDuplicateGroup(false);
        setShowDuplicateLink(false);
        setShowDuplicateLinkUrl(false);
    }, 4000);
    return () => {
      clearInterval(interval);
    };
}, []);

  useEffect(() => {
    if (selectedPhase && selectedComplexity) {
      setButtonStage('fetch');
    }
  }, [selectedPhase, selectedComplexity]);

  // Custom Option component for phases with radio button
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => null}
          className="custom-radio"
        />
        <label className="custom-option-label">{props.label}</label>
      </components.Option>
    );
  };
  const CheckboxOption = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => handleActivitySelection(props.option)} // the checkbox change is handled by react-select internally
          onClick={(e) => e.stopPropagation()} // prevent the dropdown from closing on click
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    )
  }
  const CustomValueContainer = ({ children, getValue, ...props }) => {
    const numValues = getValue().length;
    const label =
      numValues === 1 ? "1 item selected" : `${numValues} items selected`;

    return (
      <components.ValueContainer {...props}>
        {numValues > 0 ? label : children}
      </components.ValueContainer>
    );
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: allSelected ?  '1px solid #A2A2A2': "1px solid #0078D4" , 
      boxShadow: "none",
      borderRadius: "4px",
      padding: "4px",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "none",
      marginTop:'0px',
      border: '1px solid #0078D4'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#E1F0FB' : '',
      display: "flex",
      alignItems: "center",
      padding: '5px 10px',
      cursor: "pointer",
    }),
    // Add more custom styles if needed
  };
  const customActivitiesStyles= {
    control: (provided) => ({
      ...provided,
      border: "1px solid #0078D4", 
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop:'0px',
      border: "1px solid #0078D4",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#E1F0FB' : '',
      color:'black',
      padding:'0px 12px',
      cursor: "pointer",
    }),
    // Add more custom styles if needed
  };

  
  const customTabStyles = {
    tabsContainer: {
      display: "flex",
      // borderBottom: "1px solid #ccc",
    },
    tab: {
      marginRight: "15px",
      paddingBottom: "5px",
      cursor: "pointer",
      borderBottom: "3px solid transparent", // Default non-active state
    },
    activeTab: {
      borderBottom: "3px solid #007bff", // Active state
      fontWeight: "bold",
    },
    dropdownContainer: {
      marginBottom: "15px",

    },
    activitiesList: {
      listStyle: "none",
      paddingLeft: "0",
    },
    activityItem: {
      background: "#f0f0f0",
      padding: "5px 10px",
      marginBottom: "5px",
      borderRadius: "4px",
    },
  };

  // const getStageData = async () => {
  //   await axiosGetService("api/sdmquicklink/getquicklinksolutionmethods")
  //     .then(async (response) => {
  //       console.log("response",response);
  //       let updatedData = response.data;
  //       let parsedUpdatedData = JSON.parse(updatedData);
  //       let updatedStage = parsedUpdatedData.map((entry) => ({
  //         value: entry.ID,
  //         label: entry.Name,
  //       }));
  //       // setStageOptions(updatedStage);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getPhaseData = async () => {
    if (isProjectDelivery) {
      await axiosGetService(
        `api/sdmquicklink/getquicklinkphases/${isProjectDelivery}`
      )
        .then(async (response) => {
          const updatedData = response.data;
          const parsedUpdatedData = JSON.parse(updatedData);
          const updatedStage = parsedUpdatedData.map((entry) => ({
            value: entry.ID,
            label: entry.PhaseName,
          }));
          setPhaseOptions(updatedStage);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getComplexity = async () => {
    if (isProjectDelivery) {
      setAllselected(true)
      await axiosGetService(
        `api/sdmquicklink/getquicklinkgroupsandactivitys/${isProjectDelivery}/${selectedPhase.value}/${selectedComplexity.value}`
      )
        .then(async (response) => {
          const updatedData = response.data;

          const groupMap = updatedData.reduce((acc, entry) => {
            if (acc[entry.groupId]) {
              acc[entry.groupId].sdmActivities = [...acc[entry.groupId].sdmActivities, ...entry.sdmActivities];
            } else {
              acc[entry.groupId] = { ...entry };
            }
            return acc;
          }, {});
          const allGroupMap = updatedData.reduce((acc, entry) => {
            if (acc[entry.groupId]) {
              acc[entry.groupId].sdmActivities = [...acc[entry.groupId].sdmActivities, ...entry.sdmActivities];
            } else {
              acc[entry.groupId] = { ...entry };
            }
            return acc;
          }, {});
          const updatedComplexity = Object.values(groupMap).map(entry => {
            return { value: entry.groupId, label: entry.groupName};
          });
          setComlexityLevelData(Object.values(groupMap));
          setInternalGroupActivities([groupMap])
          // setGroupOptions([...updatedComplexity,...addNewGroup]);

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getAllActivities = async () => {
    if (isProjectDelivery && selectedPhase && selectedComplexity) {
      await axiosGetService(
        `api/sdmquicklink/getquicklinkgroupactivities/${quickLinkTypeId}/${selectedComplexity.value}/${selectedPhase.value}`
      )
        .then(async (response) => {
          setDisplayGroups(true);
          setQuickLinkData(response.data)
          const groupArray=[]
          response.data.forEach(e=>{
            return groupArray.push({value:e.quickLinkGroupId, label:e.quickLinkGroupName,isInternal:false})
          })
          const updatedComplexity = complexityLevelData.map(entry => {
            return { value: entry.groupId, label: entry.groupName,isInternal:true};
          }); 
          const filteredUpdatedComplexity = updatedComplexity.filter(e=>e.value !== 0)
          const activitesWithoutGroup = complexityLevelData.filter(e=>e.groupId === 0).map
          (item => item.sdmActivities)
          const activitesWithoutGroupOptions = [];
          if(activitesWithoutGroup.length !== 0){
            activitesWithoutGroup[0].forEach(e=>{
              activitesWithoutGroupOptions.push({value:e.activityIdentity, label:e.activityTitle})
            })
          }
          
          setActivities(activitesWithoutGroupOptions)
          const combinedData = [...filteredUpdatedComplexity, ...groupArray]
          let uniqueGroup = [];
          if(combinedData.length !==0){
             uniqueGroup = combinedData.filter((obj, index, array) => {
              return array.findIndex(item => item.value === obj.value) === index;
            });
          }
          
          setGroupOptions(uniqueGroup)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // useEffect(() => {
  //   getStageData();
  // }, []);

  useEffect(() => {
    getPhaseData();
  }, [isProjectDelivery]);

  useEffect(() => {
    getComplexity();
  }, [selectedComplexity]);

  // useEffect(()=>{
  //   if(selectedComplexity && selectedPhase){
  //     getAllActivities()
  //   }

  // },[selectedComplexity, selectedPhase])
  const handleAddNewClick = () => {
    setShowAdditionalOptions(true); // Set state to show additional options
    setShowData(false); // Hide data when adding new quick links
    if(selectedPhase.label.toLowerCase() === 'realize value'){
      setIsRealizeValue(true)
      setActiveTab("quickLink")
    }
  };

  const handleStageChange = async(selectedOption) => {
    // setSelectedStage(selectedOption); // Update the selected phase state
    // setIsStageSelected(true);
    await axiosGetService(`api/activities/projectdynamiccomplexity?solutionMethodId=${isProjectDelivery}`).then(
			(response) => {
				const updatedData = response.data;
          const updatedStage = updatedData.map((entry) => ({
            value: entry.id,
            label: entry.name,
          }));
          setComplexityOptions(updatedStage);
			}
		);
  };

  // Handlers for dropdown changes
  const handleGroupChange = (selectedOption) => {
    const groupData = internalGroupActivities[0][selectedOption.value]
    let activityOptions = groupData?.sdmActivities?.map((e) => ({
      value: e?.activityIdentity,
      label: e?.activityTitle,
    }));
    
    if(activityOptions === undefined){
      activityOptions = [null]
    }
    setSelectedGroup(selectedOption);
    setSelectedActivities([])
    if(selectedOption.isInternal){
      setActivities(activityOptions);
    }
  };
  // Handlers for Quick Link inputs
  const handleLinkTextChange = (e) => {
    setLinkText(e.target.value);
  };

  const handleLinkURLChange = (e) => {
    setLinkURL(e.target.value);
  };

  const handleAddLink = async () => {
    // Add the new link to the quickLinks array and reset the input fields
    const postData = {
      LinkName: linkText,
      LinkUrl: linkURL,
      ActivityIdentifier: null,
      Sequence: null,
      SolutionMethodId: isProjectDelivery,
      PhaseId: selectedPhase.value,
      GroupId: selectedGroup.value,
      ComplexityId: selectedComplexity.value,
      IsInternalQuickLink: 0,
      QuickLinkTypeId: quickLinkTypeId,
    };

    await axiosPostService(
      `api/sdmquicklink/addquicklinkactivity`,
      postData
    ).then((response) => {
      if (response.data.status) {
        // setLinkCreated(true);
        toast.info(`You have successfully added the quick link`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // setShowExternalLinkForm(false);
    });

    setQuickLinks([...quickLinks, { text: linkText, url: linkURL }]);
    setLinkText("");
    setLinkURL("");
  };

  const handleCancel = () => {
    setLinkText("");
    setLinkURL("");
    setShowAdditionalOptions(false);
    setShowData(true)
    setActiveTab("internalActivities");
    setSelectedGroup(null)
    getAllActivities();
  };
  const handleActivitySelection = (selectedOptions) => {
    setSelectedActivities(selectedOptions);
  };

  const handleCancelActivityClick = () => {
    setSelectedActivities([]);
    setShowAdditionalOptions(false);
    setActiveTab("internalActivities");
    setShowData(true)
    setSelectedGroup(null)
    getAllActivities();
  };
  // Handler for the "Add" button click in the "Internal Activities" section
  const handleAddActivityClick = async () => {
    if (!selectedGroup || !selectedGroup.value) {
      toast.error("Please select the group", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Exit the function early if no group is selected
    }
    let filteredGroupSequence = quickLinkData?.filter(e=> e.quickLinkGroupId === selectedGroup.value)
    let maxSequence;
    if(filteredGroupSequence.length ===0){
      filteredGroupSequence= complexityLevelData.filter(e=>e.groupId === selectedGroup.value)
      maxSequence = quickLinkData.length !== 0 ? Math.max(...filteredGroupSequence[0]?.sdmActivities.map(o => o.groupSequence)) + 1 : 1

    } else{
    if(filteredGroupSequence[0].quickLinkActivities[0] !== null){
      maxSequence = quickLinkData.length !== 0 ? Math.max(...filteredGroupSequence[0]?.quickLinkActivities.map(o => o.sequenceNumber)) + 1 : 1
    }else{
      maxSequence = 1;
    }
  }
    const internalQuickLinkActivityArray = []
    
    selectedActivities.forEach(activity => {
     internalQuickLinkActivityArray.push({
      Id: activity.value,
      LinkName: activity.label,
      SequenceNumber: maxSequence
     })
     maxSequence++
    });
    const postData = {
      LinkName: null,
      LinkUrl: null,
      ActivityIdentifier: null,
      SequenceNumber:0,
      SolutionMethodId: isProjectDelivery,
      PhaseId: selectedPhase.value,
      GroupId: selectedGroup.value,
      ComplexityId: selectedComplexity.value,
      IsInternalQuickLink: 1,
      QuickLinkTypeId: quickLinkTypeId,
      InternalQuickLinkActivityArray: internalQuickLinkActivityArray,
      IsInternalGroup: selectedGroup.isInternal
    };


    if(selectedActivities.length !== 0){
    await axiosPostService(
      `api/sdmquicklink/addquicklinkactivity`,
      postData
    ).then((response) => {
      if (response.data.status) {
        // setLinkCreated(true);
        toast.info(`You have successfully added the quick link`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // setShowExternalLinkForm(false);
      setButtonStage('preview')
      setSelectedActivities([]);
    });
  } else {
    toast.error(`Please select activities`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

    const newAddedActivities = selectedActivities.filter(
      (selected) =>
        !addedActivities.some((added) => added.value === selected.value)
    );

    setAddedActivities([...addedActivities, ...newAddedActivities]);
    // Do not clear the selectedActivities to keep checkboxes checked
  };
  const handleGroupExpand = (groupId, type) => {
    setExpandedActivityGroups((prevState) => {
      const isExpanded = prevState.includes(groupId);
      if (type !== "filterUpdate" && isExpanded) {
        // Collapse the group
        return prevState.filter((id) => id !== groupId);
      } else if (!isExpanded) {
        // Expand the group
        return [...prevState, groupId];
      }
      return prevState;
    });
  };
  const handlePhaseChange = async (selectedOption) => {
    setSelectedPhase(selectedOption); // Update the selected phase state
    if (selectedOption.value !== 180) {
      setQuickLinkTypeId(2);
    } else {
      setQuickLinkTypeId(1);
    }

    await axiosGetService(`api/activities/projectdynamiccomplexity?solutionMethodId=${isProjectDelivery}`).then(
			(response) => {
				let updatedData = response.data;
          let updatedStage = updatedData.map((entry) => ({
            value: entry.id,
            label: entry.name,
          }));
          setComplexityOptions(updatedStage);
			}
		);
  };

  const handleComplexityChange = (selectedOption) => {
    if(selectedPhase !== null){
      setSelectedComplexity(selectedOption); // Update the selected phase state
      setShowData(true); // Show data when a phase is selected
    } else{
      toast.error(`Please select phase`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const handleEditClick = (groupId, linkId) => {
    const group = quickLinkData.find(
      (group) => group.quickLinkGroupId === groupId
    );
    const link = group.quickLinkActivities.find(
      (link) => link?.quickLinkActivityId === linkId
    );
    setEditingLink({
      groupId,
      linkId,
      linkName: link?.linkName,
      linkUrl: link?.linkUrl,
    });
  };
  const handleLinkNameChange = (e) => {
    setEditingLink({ ...editingLink, linkName: e.target.value });
  };

  const handleLinkUrlChange = (e) => {
    setEditingLink({ ...editingLink, linkUrl: e.target.value });
  };

  const handleSaveEdit = async () => {
    const postData = {
      Id: editingLink?.linkId,
      LinkName: editingLink?.linkName,
      LinkUrl: editingLink?.linkUrl,
      ActivityIdentifier: 0,
      SequenceNumber: editingLink.sequenceNumber ? editingLink.sequenceNumber : 0,
      SolutionMethodId: isProjectDelivery,
      PhaseId: selectedPhase.value,
      GroupId: editingLink?.groupId,
      ComplexityId: selectedComplexity.value,
      IsInternalQuickLink: 0,
      QuickLinkTypeId: quickLinkTypeId
    };
    await axiosPostService(`api/sdmquicklink/updatequicklinkactivity`, postData).then(
      (response) => {
        if (response.data.status) {
          setEditingLink(null);
          toast.info(`You have successfully updated the quick link`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setQuickLinkData(
            quickLinkData.map((group) => {
              if (group.quickLinkGroupId === editingLink.groupId) {
                return {
                  ...group,
                  quickLinkActivities: group.quickLinkActivities.map((link) => {
                    if (link?.quickLinkActivityId === editingLink.linkId) {
                      return {
                        ...link,
                        linkName: editingLink?.linkName,
                        linkUrl: editingLink?.linkUrl,
                      };
                    }
                    return link;
                  }),
                };
              }
              return group;
            })
          );
          setButtonStage('preview')
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setEditingLink(null);
      }
    );
    setEditingLink(null);
  };

  const handleCancelEdit = () => {
    setEditingLink(null);
  };
  
  const handleGroupNameChange = (event) => {
    
    setGroupNameInput(event.target.value);
    setGroupName(event.target.value)
  };

  const startEditGroupName = (group) => {
    setEditingGroupName(group.quickLinkGroupId);
    setGroupNameInput(group.quickLinkGroupName);
  };

  const updateGroupName = async (data) => {
    const postData = {
      Id: data.quickLinkGroupId,
      QuickLinkGroupName: groupNameInput,
      Sequence: data.sequence,
      QuickLinkTypeId: quickLinkTypeId,
      PhaseId: selectedPhase.value,
      ComplexityId: !selectedComplexity.value ?  0 : selectedComplexity.value
    };

    await axiosPostService(`api/sdmquicklink/updatequicklinkgroup`, postData).then(
      (response) => {
        if (response.data.status) {
          toast.info(`You have successfully updated the group`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAllActivities();
          setButtonStage('preview')
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setEditingGroupName(null);
      }
    );
  }
  const cancelEditGroupName = () => {
    setEditingGroupName(null);
    setGroupNameInput("");
  };
  const handleDeleteGroup = (groupId) => {
    const group = quickLinkData.find((g) => g.quickLinkGroupId === groupId);
    if (group.quickLinkActivities[0] !== null) {
      // Show warning message
      setShowDeleteWarning(true);
      setDeletingGroupName(groupId);
    } else {
      // Show confirmation message
      setDeleteTarget(groupId);
      setShowDeleteGroupConfirmation(true);
    }
  };
  const handleDeleteQuickLink = (quickLinkGroupId, quickLinkId) => {
    // Show confirmation message
    setQuickLinksGroupDeleteId(quickLinkGroupId);
    setDeleteTarget(quickLinkId);
    setShowDeleteActivityConfirmation(true);
  };

  const confirmDeleteGroup = async (id) => {
    await axiosDeleteService(`api/sdmquicklink/deletequicklinkgroup/${id}/${quickLinkTypeId}/${selectedPhase.value}/${selectedComplexity.value}`).then(
      (response) => {
        if (response.data.status) {
          //setQuickLinkData(response.data.sdmQuickLinkGroups);
          getAllActivities();
          toast.info(`You have successfully deleted the group`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShowDeleteGroupConfirmation(false);
          setShowDeleteWarning(false);
          setDeleteTarget(null);
          setButtonStage('preview')
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShowDeleteGroupConfirmation(false);
          setShowDeleteWarning(false);
          setDeleteTarget(null);
        }
        setShowDeleteGroup(false);
      }
    );
  }

  const confirmDelete = async (id) => {
    await axiosDeleteService(`api/sdmquicklink/deletequicklinkactivity/${id}/${quickLinksGroupDeleteId}/${selectedPhase.value}/${quickLinkTypeId}`).then(
      (response) => {
        if (response.data.status) {
          setShowDeleteActivityConfirmation(false);
          setShowDeleteWarning(false);
          setDeleteTarget(null);
          getAllActivities();
          toast.info(`You have successfully deleted the link`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setButtonStage('preview')
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShowDeleteActivityConfirmation(false);
          setShowDeleteWarning(false);
          setDeleteTarget(null);
        }
        // setShowDeleteLink(null);
      }
    );

  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type === "GROUPS") {
      // Reorder groups
      const newGroups = reorder(
        quickLinkData,
        result.source.index,
        result.destination.index
      );
      newGroups.forEach((item, index) => {
        item.sequenceNumber = index + 1;
      })
      setQuickLinkData(newGroups);
      setButtonStage('preview')
    } else {
      const { source, destination } = result;

      // Deep copy of the state to avoid direct state mutation
      const newData = JSON.parse(JSON.stringify(quickLinkData));

      // Find source and destination groups
      const sourceGroup = newData.find(group => group.quickLinkGroupId.toString() === source.droppableId);
      const destinationGroup = newData.find(group => group.quickLinkGroupId.toString() === destination.droppableId);

      // Dragged item
      const draggedItem = sourceGroup.quickLinkActivities[source.index];

      // Check if the item is internal and if it is being moved to a different group
      if (draggedItem.isInternalQuickLink && source.droppableId !== destination.droppableId) {
        // If it's internal and moving to a different group, cancel the drag
        return;
      }

      // Remove from the source group
      sourceGroup.quickLinkActivities.splice(source.index, 1);

      // If moving within the same group or to a different group (for external items), update the destination
      destinationGroup.quickLinkActivities.splice(destination.index, 0, draggedItem);

      // Update sequence numbers for the affected groups
      sourceGroup.quickLinkActivities.forEach((activity, idx) => {
        activity.sequenceNumber = idx + 1;
      });
      if (source.droppableId !== destination.droppableId) {
        destinationGroup.quickLinkActivities.forEach((activity, idx) => {
          activity.sequenceNumber = idx + 1;
        });
      }

      // Update the state
      setQuickLinkData(newData);
      setButtonStage('preview')
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleButtonClick = () => {
    // if (!selectedStage || !selectedStage.value) {
    //   toast.error("Please select a stage.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return; // Prevent further action
    // }

    if (!selectedPhase || !selectedPhase.value) {
      toast.error("Please select a phase.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Prevent further action
    }

    if (!selectedComplexity || !selectedComplexity.value) {
      toast.error("Please select a complexity level.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Prevent further action
    }
    if (buttonStage === 'fetch') {
      getAllActivities();
    } else if (buttonStage === 'preview') {
      handleClickPreview();
      setButtonStage('submit'); // Change the stage to 'submit' after preview
    } else if (buttonStage === 'submit') {
      handleClickPublish();
    }
  };
  const saveGroupDetails = async () => {
    const postData = {                     
      QuickLinkGroupName: groupName,
      Sequence: quickLinkData.length !== 0 ? Math.max(...quickLinkData.map(o => o.sequence)) + 1 : 1,
      QuickLinkTypeId: quickLinkTypeId,
      PhaseId: selectedPhase.value,
      ComplexityId: !selectedComplexity.value ?  0 : selectedComplexity.value
    };
    await axiosPostService(`api/sdmquicklink/addquicklinkgroup`, postData).then(
      (response) => {
        if (response.data.status) {
          //setGroupCreated(true);
          toast.info(`You have successfully added the group`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAllActivities();
          getComplexity();
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setShowGroupForm(false);
        const updatedGroup = response.data.sdmQuickLinkGroups.map(e=>{
          return {value:e.id, label: e.quickLinkGroupName}
        })
        setAddNewGroup(updatedGroup)
        setGroupName('')

      }
    );
  }

  const validateGroup = async(isUpdate,data) => {
    if(groupName === '' || groupName === undefined){
        toast.error("Please enter the valid group name", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return;
    }else{
      let postData
      if(!isUpdate){
         postData = {
          SearchText: groupName,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: 0,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: true,
          IsUrl: false,
          IsUpdate: isUpdate
        };
      } else{
        postData = {
          SearchText: groupName,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: editingGroupName,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: true,
          IsUrl: false,
          IsUpdate: isUpdate,
        };

      }
     
        await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
            (response) => {
                if(response.data.status === 1){
                    setShowDuplicateGroup(true);
                    toast.error("Duplicate Group name not allowed", {
                      position: toast.POSITION.TOP_RIGHT,
                  });
                }else{
                    setShowDuplicateGroup(false);
                    if(!isUpdate){
                      saveGroupDetails();
                    }else{
                      updateGroupName(data)
                    }
                }
            }
        );
    }
}

const validateLinkText = async(isUpdate,data) => {

  if (!selectedGroup || !selectedGroup.value) {
      setShowGroupRequired(true);
      setValidateTitle(false);
      return;
  }else if( linkText === '' || linkText === undefined){
      setShowGroupRequired(false);
      setShowDuplicateLink(true);
      setlinkTextMessage("Please enter the link text");
      setValidateTitle(false);
      return;
  }else{
      setShowGroupRequired(false);
      let postData
      if(!isUpdate){
        postData = {
          SearchText: linkText,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: selectedGroup.value,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: false,
          IsUrl: false,
          IsUpdate: isUpdate
        };
      } else {
        postData = {
          SearchText: linkText,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: selectedGroup.value,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: false,
          IsUrl: false,
          IsUpdate: isUpdate
        }

      }
      
      await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
          (response) => {
              if(response.data.status === 1){
                  setShowDuplicateLink(true);
                  setlinkTextMessage("Duplicate Link Text not allowed");
                  setValidateTitle(false);
                  return;
              } else {
                  setShowDuplicateLink(false);
                  setValidateTitle(true);
                  return;
              }
          }
      );
  }
}

const validateLinkUrl = async(event) => {
  await validateLinkText();
  if (!selectedGroup || !selectedGroup.value) {
      setShowGroupRequired(true);
      setValidateUrl(false);
      return;
  }else if(linkURL === '' || linkURL === undefined){
      setShowGroupRequired(false);
      setShowDuplicateLinkUrl(true);
      setlinkUrlMessage("Please enter the link url");
      setValidateUrl(false);
      return;
  }else if(!urlValidator.test(linkURL)){
      setShowDuplicateLinkUrl(true);
      setlinkUrlMessage("Please enter the valid link url");
      setValidateUrl(false);
      return;
  }else{
      setShowGroupRequired(false);
     const postData = {
        SearchText: linkURL,
        QuickLinkTypeId: quickLinkTypeId,
        QuickLinkId: 0,
        GroupId: selectedGroup.value,
        PhaseId: selectedPhase.value,
        ComplexityId: selectedComplexity.value,
        IsGroup: false,
        IsUrl: true,
        IsUpdate: false
      };

      await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
          (response) => {
              if(response.data.status === 1){
                  setShowDuplicateLinkUrl(true);
                  setlinkUrlMessage("Duplicate Link Url not allowed");
                  setValidateUrl(false);
                  return;
              } else {
                  setShowDuplicateLinkUrl(false);
                  setValidateUrl(true);
                  setLinkValidation(!linkValidation);
                  return;
              }
          }
      );
  }
}

useEffect(() => {
  if(validateUrl && validateTitle) {
      // const postData = {
      //     LinkName: linkTitle,
      //     LinkUrl: linkUrl,
      //     ActivityIdentifier: activityIdentifier,
      //     SequenceNumber: linkSequenceNumber,
      //     SolutionMethodId: solutionMethodId,
      //     PhaseId: phaseId,
      //     GroupId: groupSelectedOptions,
      //     ComplexityId: complexityId,
      //     IsInternalQuickLink: isInternalQuickLink,
      //     QuickLinkTypeId: quickLinkTypeId
      // };

      // axiosPostService(`api/sdmquicklink/addquicklinkactivity`, postData).then(
      //     (response) => {
      //         if (response.data.status) {
      //             setLinkTitle('');
      //             setLinkUrl('');
      //             setGroupSelectedName('');
      //             setGroupSelectedOptions('');
      //             setLinkCreated(true);
      //             setValidateTitle(false);
      //             setValidateUrl(false);
      //             toast.info(`You have successfully added the quick link`, {
      //                 position: toast.POSITION.TOP_RIGHT,
      //             });
      //         } else {
      //             toast.error(response.data.errorMessage, {
      //                 position: toast.POSITION.TOP_RIGHT,
      //             });
      //         }
      //         setShowExternalLinkForm(false);
      //     }
      // );
      saveLinkDetails();
  }
},[linkValidation])



  const validateText = async (isGroup, isUrl, isUpdate) => {
    var postData;
    if (isGroup) {
      postData = {
        SearchText: groupName,
        QuickLinkTypeId: quickLinkTypeId,
        QuickLinkId: 0,
        GroupId: 0,
        PhaseId: selectedPhase.value,
        ComplexityId: selectedComplexity.value,
        IsGroup: isGroup,
        IsUrl: isUrl,
        IsUpdate: isUpdate
      };
    } else {
      if (!isUrl) {
        if (!selectedGroup || !selectedGroup.value) {
          toast.error("Please select the group", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return; // Exit the function early if no group is selected
        }
        postData = {
          SearchText: linkText,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: selectedGroup.value,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: isGroup,
          IsUrl: isUrl,
          IsUpdate: isUpdate
        };
      } else {
        postData = {
          SearchText: linkURL,
          QuickLinkTypeId: quickLinkTypeId,
          QuickLinkId: 0,
          GroupId: selectedGroup.value,
          PhaseId: selectedPhase.value,
          ComplexityId: selectedComplexity.value,
          IsGroup: isGroup,
          IsUrl: isUrl,
          IsUpdate: isUpdate
        };
      }
    }
    await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`, postData).then(
      (response) => {
        const check = postData
        if (response.data.status === 1 && isGroup) {
          setShowDuplicateGroup(true);
        } else if (response.data.status === 1 && !isGroup && !isUrl) {
          setShowDuplicateLink(true);
        } else if (response.data.status === 1 && !isGroup && isUrl) {
          setShowDuplicateLinkUrl(true);
        }
      }
    );
  }

  const saveLinkDetails = async () => {
    if (!selectedGroup || !selectedGroup.value) {
      toast.error("Please select the group", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Exit the function early if no group is selected
    }
    let sequence;
    const filteredGroupSequence = quickLinkData?.filter(e=> e.quickLinkGroupId === selectedGroup.value)
    if(filteredGroupSequence.length){
     sequence= filteredGroupSequence[0]?.quickLinkActivities[0] !== null? Math.max(...filteredGroupSequence[0]?.quickLinkActivities.map(o => o.sequenceNumber)) + 1 : 1
    } else {
      sequence = 1
    }
    const postData = {
      LinkName: linkText,
      LinkUrl: linkURL,
      ActivityIdentifier: null,
      SequenceNumber:sequence,
      SolutionMethodId: isProjectDelivery,
      PhaseId: selectedPhase.value,
      GroupId: selectedGroup.value,
      ComplexityId: selectedComplexity.value,
      IsInternalQuickLink: 0,
      QuickLinkTypeId: quickLinkTypeId,
      IsInternalGroup:selectedGroup.isInternal
    };
    await axiosPostService(`api/sdmquicklink/addquicklinkactivity`, postData).then(
      (response) => {
        if (response.data.status) {
          getAllActivities()
          toast.info(`You have successfully added the quick link`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setEditingLink(null);
        setLinkURL("")
        setLinkText("")
        setSelectedGroup(null)
        setButtonStage('preview')
      }
    );
  }
  const handleClickPreview = async () => {
    const groupSequenceData = [];
    const linkSequenceData = [];
    await quickLinkData.map(async (item) => {
      const groupSequence = {
        "Id": item.quickLinkGroupId,
        "Sequence": item.sequence,
        "IsInternalGroup": item.quickLinkActivities[0] === null ? false : item.quickLinkActivities[0].isInternalGroup
      };
      groupSequenceData.push(groupSequence);
      await item.quickLinkActivities.map((data) => {
        const linkSequence = {
          "Id": data?data.quickLinkActivityId : 0,
          "SequenceNumber": data? data.sequenceNumber : 0
        };
        if(data !== null){
          linkSequenceData.push(linkSequence);
        }
      });
    });
    groupSequenceData.forEach((e,i)=>{
      return e["Sequence"] = i+1;
    })
    const postData = {
      QuickLinkTypeId: quickLinkTypeId,
      PhaseId: selectedPhase.value,
      ComplexityId: selectedComplexity.value,
      SdmQuickLinkGroups: groupSequenceData,
      SdmQuickLinkActivities: linkSequenceData
    };
    await axiosPostService(`api/sdmquicklink/quicklinkspreview`, postData).then(
      (response) => {
        setPreviewStage(true);
        setPreviewData(response.data);
      }
    );
  }
  const linkOrder = (array) => {
		return array.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}
 
  const handleClickPublish = async () => {
    const postData = {
      QuickLinkTypeId: quickLinkTypeId,
      PhaseId: selectedPhase.value
    };

    await axiosPostService(`api/sdmquicklink/quicklinkpublish`, postData).then(
      (response) => {
        if (response.data.publishStatus) {
          setPreviewStage(false);
          toast.info(`You have successfully publish the Links.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(response.data.errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        history.push(pathname);
      }
    );
  }
  const handleCloseModal = () => {
    if(buttonStage === 'submit'){
       setPreviewStage(false)
       setButtonStage('preview')
    } else{
    setShowModal(false);
    history.push(pathname);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Modal
        dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements manageQuickLinkPanel"
        size="lg"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Quick Links Builder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr />
          {!previewStage ? (
            <div className="modal-body-container">
              {/* Stage Dropdown */}
              {/* <div className="dropdown-container" style={{marginTop:'20px'}}>
                <label htmlFor="stage-select" className="dropdown-label" >
                  Select Stage
                </label>
                <Select
                  id="stage-select"
                  options={stageOptions}
                  value={selectedStage}
                  onChange={handleStageChange}
                  placeholder="Select Stage"
                  styles={customStyles}
                  isSearchable={false}
                  isDisabled={isStageSelected}
                />
              </div> */}

              {/* Phase Dropdown */}
              <div className="dropdown-container">
                <label htmlFor="phase-select" className= "dropdown-label">
                  Select Phase
                </label>
                <Select
                  id="phase-select"
                  options={phaseOptions}
                  value={selectedPhase}
                  onChange={handlePhaseChange}
                  placeholder="Select Phase"
                  styles={customStyles}
                  components={{ Option }}
                  isSearchable={false}
                  isDisabled={allSelected}
                />
              </div>
              <div className="dropdown-container">
                <label htmlFor="phase-complexity" className="dropdown-label">
                  Select Complexity
                </label>
                <Select
                  id="phase-complexity"
                  options={complexityOptions}
                  value={selectedComplexity}
                  onChange={handleComplexityChange}
                  placeholder="Select Complexity"
                  styles={customStyles}
                  components={{ Option }}
                  isSearchable={false}
                  isDisabled={allSelected}
                />
              </div>
              {/* Add New Quick Link/Group Button */}
              {displayGroups && (
                <>
                  {!showAdditionalOptions && (
                    <div className="addGroupButtonAction">
                      <div
                        onClick={handleAddNewClick}
                        className="selectAddGroupButton"
                      >
                        + Add New Quick Link/Group
                      </div>
                    </div>
                  )}

                  {/* Conditional rendering based on showAdditionalOptions */}
                  {showAdditionalOptions && (
                    <>
                      {/* Group Dropdown */}
                      <div className={(showDuplicateLink || showDuplicateLinkUrl) ? "addDuplicateLinkFields" : "addLinkFields"}>
                        <div className="dropdown-container">
                          <label htmlFor="group-select" className="dropdown-label">
                            Select Group *
                          </label>
                          <Select
                            id="group-select"
                            options={groupOptions}
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            placeholder={groupOptions.length > 0 ? "Select Group" : "No options"}
                            styles={customStyles}
                            components={{ Option }}
                            isSearchable={false}
                            isDisabled={groupOptions.length === 0}
                          />
                          <div className='addGroupLeftPanelAction'>
                            <div
                              class="addGroupButton addButtonHeight"
                              onClick={() => setShowGroupForm(!showGroupForm)}
                              onKeyPress={(e) => {
                                if (e.which === 13) {
                                  setShowGroupForm(!showGroupForm);
                                }
                              }}
                              title="Add a New Group"
                              tabIndex={0}
                              aria-label="Click to Add a New Group"
                              role="button"
                            >
                              +
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='quickLinkSelectGroup'>
                                    {showGroupRequired? (
                                        <span>Please Select the group</span> 
                                    ) : (
                                        <></>
                                    )}
                                </div>
                      <div>
                        {showGroupForm ? (
                          <div className='addGroupForm'>
                            <div className={showDuplicateGroup ? "addDuplicateGroup" : "addGroupFields"}>
                              <div class="entryGroupName addedClass">
                                <input
                                  id="group-name"
                                  title="{groupName}"
                                  placeholder="Enter Group Name"
                                  value={groupName}
                                  onChange={(e) => setGroupName(e.target.value)}
                                  autoFocus={true}
                                />
                              </div>
                              <div class="groupButtons">
                                <button
                                  onClick={() => {
                                    validateGroup(false, {})
                                  }}
                                  className='leftBtnFooter leftGroupSaveButton'
                                >
                                  Add
                                </button>
                                <button
                                  onClick={() => {
                                    setShowGroupForm(false);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                            {showDuplicateGroup ? (
                              <span>Duplicate Group Name not allowed</span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* Activities and Quick Link Section */}
                      {/* ... (your additional components for activities and quick link) */}
                      {/* Tabs for Internal Activities and Quick Link */}
                         <div style={customTabStyles.tabsContainer}>
                        {!isRealizeValue ? (
                           <div
                             style={{
                               ...customTabStyles.tab,
                               ...(activeTab === "internalActivities"
                                 ? customTabStyles.activeTab
                                 : {}),
                             }}
                             onClick={() => setActiveTab("internalActivities")}
                           >
                             Internal Activities
                           </div>
                       ) : <></>}
                        <div
                          style={{
                            ...customTabStyles.tab,
                            ...(activeTab === "quickLink"
                              ? customTabStyles.activeTab
                              : {}),
                          }}
                          onClick={() => setActiveTab("quickLink")}
                        >
                          Quick Link
                        </div>
                      </div>

                      {/* Content for Internal Activities Tab */}
                      {activeTab === "internalActivities" && !isRealizeValue && (
                        <div className="internal-activities-container">
                          <div className="activity-info">
                            {activities[0]?activities.length:0} Activities are available
                          </div>
                          <Select
                            isMulti
                            name="activities"
                            styles={customActivitiesStyles}
                            options={activities ? activities : {value:'', label:''}}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleActivitySelection}
                            value={selectedActivities}
                            closeMenuOnSelect={false} // Keep the dropdown open after selection
                            hideSelectedOptions={false} // Do not hide options after they are selected
                            components={{
                              Option: CheckboxOption,
                              ValueContainer: CustomValueContainer,
                            }} // Your custom Option component with checkboxes
                            isSearchable={false}
                          />
                          <div className="activity-actions">
                            <button
                              onClick={handleCancelActivityClick}
                              className="activity-cancel-button"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleAddActivityClick}
                              className="activity-add-button"
                            >
                              Add Link
                            </button>
                          </div>
                          {addedActivities.length > 0 && (
                            <div className="added-activities-display">
                              <strong>
                                {addedActivities.length} Activities added:
                              </strong>
                              <div className="added-activities-list">
                                {addedActivities.map((activity, index) => (
                                  <div
                                    key={index}
                                    className="added-activity-item"
                                  >
                                    {activity.label}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Content for Quick Link Tab */}
                      {activeTab === "quickLink" && (
                        <div className="quicklink-container">
                          <div className="entryCriteriaName linkText">
                            <label htmlFor="link-text">Link Text</label>
                            <input
                              id="link-text"
                              type="text"
                              value={linkText}
                              onChange={handleLinkTextChange}
                              onBlur={()=>validateLinkText(false)}
                              placeholder="Enter link text"
                              className="quicklink-input"
                            />
                            {showDuplicateLink ? (
                              <span className="duplicateQuickUrl">{linkTextMessage}</span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="entryCriteriaName linkUrl">
                            <label htmlFor="link-url">Link URL</label>
                            <input
                              id="link-url"
                              type="text"
                              value={linkURL}
                              onChange={handleLinkURLChange}
                              onBlur={()=>validateText(false, true, false)}
                              placeholder="Enter link URL"
                              className="quicklink-input"
                            />
                                 {showDuplicateLinkUrl? (
                                    <span style={{color:'red'}}>{linkUrlMessage}</span> 
                                ) : (
                                    <></>
                                )}
                          </div>

                          <div className="criteriaButtons managequicklinkaddbtn">
                            <button
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => {
                                validateLinkUrl()
                              }}
                            >
                              Add Link
                            </button>
                          </div>
                          {quickLinks.length > 0 && (
                            <div className="quicklink-added-container">
                              <span>
                                {quickLinks.length} Quick Link
                                {quickLinks.length > 1 ? "s" : ""} added
                              </span>
                              <div className="added-quicklinks-display">
                                {quickLinks[0].text}
                                {/* Display only the last added quick link */}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  <>
                    {showData && (
                      <div className="activitiesList">
                        <Droppable droppableId="all-groups" type="GROUPS">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {quickLinkData.length !== 0 &&
                                quickLinkData.map((data, index) => {
                                  const groupId = `${data.quickLinkGroupName}_${data.quickLinkGroupId}`;
                                  return (
                                    <div
                                      className="orangeDelivery"
                                      key={data.quickLinkGroupId}
                                    >
                                      <>
                                        <Draggable
                                          key={String(data.quickLinkGroupName)}
                                          draggableId={data.quickLinkGroupName}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}
                                            >
                                              <div className="activityListMainContainer activityGroupParent">
                                                {showDeleteWarning &&
                                                  deletingGroupName ===
                                                  data.quickLinkGroupId && (
                                                    <div className="deleteGroupWarning">
                                                      <Icon iconName="WarningSolid" />
                                                      <span>
                                                        Please remove the Quick
                                                        Links first to remove the
                                                        Group.
                                                      </span>
                                                      <Icon
                                                        iconName="Cancel"
                                                        tabIndex={0}
                                                        aria-label={`Close warning `}
                                                        onClick={() =>
                                                          setShowDeleteWarning(
                                                            false
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  )}

                                                <div
                                                  className="activityContainer activityGroup"
                                                  tabIndex={0}
                                                  key={groupId}
                                                  onKeyPress={(e) => {
                                                    if (e.which === 13) {
                                                      handleGroupExpand(
                                                        groupId,
                                                        "userUpdate"
                                                      );
                                                    }
                                                  }}
                                                  onClick={() =>
                                                    handleGroupExpand(
                                                      groupId,
                                                      "userUpdate"
                                                    )
                                                  }
                                                  aria-expanded={handleTernaryCondition(
                                                    expandedActivityGroups.includes(
                                                      `${data.quickLinkGroupName}_${data.quickLinkGroupId}`
                                                    )
                                                      ? "true"
                                                      : "false"
                                                  )}
                                                  role="tree"
                                                >
                                                  <span className="activitytypeColor" />
                                                  <div
                                                    className="activityTitle"
                                                    title={
                                                      data.quickLinkGroupName
                                                    }
                                                  >

                                                    <Icon
                                                      iconName={handleTernaryCondition(
                                                        expandedActivityGroups.includes(
                                                          `${data.quickLinkGroupName}_${data.quickLinkGroupId}`
                                                        ),
                                                        "CaretDownSolid8",
                                                        "CaretRightSolid8"
                                                      )}
                                                    />
                                                    {editingGroupName ===
                                                      data.quickLinkGroupId ? (
                                                      <div className="editGroupName">
                                                        <input
                                                          type="text"
                                                          value={groupNameInput}
                                                          onChange={
                                                            handleGroupNameChange
                                                          }
                                                          id="group-name"
                                                          placeholder="Group Name"
                                                          autoFocus={true}
                                                        />
                                                        <Icon
                                                          // className='editGroupActions'
                                                          iconName="CheckMark"
                                                          tabIndex={0}
                                                          title="Save"
                                                          ariaLabel="Save"
                                                          onClick={() =>
                                                            validateGroup(true, data)
                                                            // saveGroupName(
                                                            //   data.quickLinkGroupId
                                                            // )
                                                          }
                                                        />
                                                        <Icon
                                                          // className='editGroupActions'
                                                          iconName="Cancel"
                                                          tabIndex={0}
                                                          title="Cancel"
                                                          ariaLabel="Cancel"
                                                          onClick={
                                                            cancelEditGroupName
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      <>
                                                        <span
                                                          aria-label={
                                                            data.quickLinkGroupName
                                                          }
                                                          className="titleOfActivity"
                                                          role="tree"
                                                          title={
                                                            data.quickLinkGroupName
                                                          }
                                                          aria-selected={handleTernaryCondition(
                                                            expandedActivityGroups.includes(
                                                              `${data.quickLinkGroupName}_${data.quickLinkGroupId}`
                                                            ),
                                                            "true",
                                                            "false"
                                                          )}
                                                        >
                                                          {
                                                            data.quickLinkGroupName
                                                          }
                                                        </span>
                                                        {(showDeleteGroupConfirmation &&
                                                          deleteTarget ===
                                                          data.quickLinkGroupId ? (
                                                          <div className="deleteGroupPopUp">
                                                            <p>
                                                              Are you sure you want to delete it?
                                                            </p>
                                                            <div>
                                                              <button
                                                                onClick={() =>
                                                                  confirmDeleteGroup(
                                                                    data.quickLinkGroupId
                                                                  )
                                                                }
                                                                onKeyPress={() => confirmDeleteGroup(
                                                                  data.quickLinkGroupId
                                                                )}
                                                                className="confirmDelete"
                                                              >
                                                                Yes 
                                                              </button>
                                                              <button
                                                                onClick={() => { setShowDeleteGroupConfirmation(false); }}
                                                                onKeyPress={() => { setShowDeleteGroupConfirmation(false); }}
                                                              >
                                                                No
                                                              </button>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <></>
                                                        ))}


                                                        <div className="groupIcons">
                                                          { data.quickLinkActivities[0] === null ||
                                                          data.quickLinkActivities[0].isInternalGroup === false ?
                                                          <Icon
                                                          iconName="Edit"
                                                          title="Edit Group"
                                                          onClick={() =>
                                                            startEditGroupName(
                                                              data
                                                            )
                                                          }
                                                        /> : <></>
                                                        }
                                                         
                                                          <Icon
                                                            iconName="Delete"
                                                            title="Delete Group"
                                                            className="delete-icon"
                                                            onClick={() =>
                                                              handleDeleteGroup(
                                                                data.quickLinkGroupId
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                                <Droppable
                                                  droppableId={
                                                    String(data.quickLinkGroupId)
                                                  }
                                                  type={data.quickLinkGroupId}
                                                >
                                                  {(provided) => (
                                                    <div
                                                      {...provided.droppableProps}
                                                      ref={provided.innerRef}
                                                    >
                                                      {expandedActivityGroups.includes(
                                                        `${data.quickLinkGroupName}_${data.quickLinkGroupId}`
                                                      ) &&
                                                        (data.quickLinkActivities
                                                          .length !== 0 && data.quickLinkActivities[0] !== null ? (
                                                          linkOrder(data.quickLinkActivities).map(
                                                            (item, index) => (
                                                              <Draggable
                                                                key={
                                                                  item?.quickLinkActivityId
                                                                }
                                                                draggableId={
                                                                  String(item?.quickLinkActivityId)
                                                                }
                                                                index={index}
                                                              >
                                                                {(provided) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}

                                                                  >
                                                                    <div
                                                                      key={
                                                                        item?.quickLinkActivityId
                                                                      }
                                                                    >
                                                                      {editingLink?.linkId ===
                                                                        item?.quickLinkActivityId ? (
                                                                        <div className="edit-quicklink-form">
                                                                          <div className="input-link-edit">
                                                                            <label
                                                                              htmlFor="link-name-input"
                                                                              className="input-label"
                                                                            >
                                                                              Link
                                                                              Text
                                                                            </label>
                                                                            <input
                                                                              type="text"
                                                                              className="edit-quicklink-input"
                                                                              value={
                                                                                editingLink?.linkName
                                                                              }
                                                                              onChange={
                                                                                handleLinkNameChange
                                                                              }
                                                                              placeholder="Link text"
                                                                            />
                                                                          </div>
                                                                          <div className="input-link-url-edit">
                                                                            <label
                                                                              htmlFor="link-url-input"
                                                                              className="input-label"
                                                                            >
                                                                              Link
                                                                              URL
                                                                            </label>
                                                                            <input
                                                                              type="url"
                                                                              className="edit-quicklink-input"
                                                                              value={
                                                                                editingLink?.linkUrl
                                                                              }
                                                                              onChange={
                                                                                handleLinkUrlChange
                                                                              }
                                                                              placeholder="Link URL"
                                                                            />
                                                                          </div>
                                                                          <div className="btn-group">
                                                                            <button
                                                                              className="btn-footer save-button"
                                                                              onClick={handleSaveEdit}
                                                                            >
                                                                              Add Link
                                                                            </button>
                                                                            <button
                                                                              className="btn-footer cancel-button"
                                                                              onClick={
                                                                                handleCancelEdit
                                                                              }
                                                                            >
                                                                              Cancel
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <>
                                                                          <div className="orangeDelivery">
                                                                            <div className="activityChildContainer">
                                                                              <div>
                                                                                <div
                                                                                  className="activityChildTitle"
                                                                                  title={
                                                                                    item?.linkName
                                                                                  }
                                                                                >
                                                                                  <span className="titleOfActivity">
                                                                                    <>
                                                                                      {item?.linkName}
                                                                                      {/* {showDeleteConfirmation &&
                                                                                        deleteTarget ===
                                                                                          item.quickLinkActivityId && (
                                                                                          <div className="delete-confirmation">
                                                                                            <p>
                                                                                              Are
                                                                                              you
                                                                                              sure
                                                                                              you
                                                                                              want
                                                                                              to
                                                                                              remove
                                                                                              it?
                                                                                            </p>
                                                                                            <button
                                                                                              className="confirm-delete-btn"
                                                                                              onClick={() =>
                                                                                                confirmDelete(
                                                                                                  item.quickLinkActivityId
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              Yes
                                                                                            </button>
                                                                                            <button
                                                                                              className="cancel-delete-btn"
                                                                                              onClick={() =>
                                                                                                setShowDeleteConfirmation(
                                                                                                  false
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              No
                                                                                            </button>
                                                                                          </div>
                                                                                        )} */}
                                                                                    </>
                                                                                  </span>
                                                                                  {(showDeleteActivityConfirmation &&
                                                                                    deleteTarget ===
                                                                                    item?.quickLinkActivityId ? (
                                                                                    <div className="deleteGroupPopUp">
                                                                                      <p>
                                                                                        Are you sure you want to delete it?
                                                                                      </p>
                                                                                      <div>
                                                                                        <button
                                                                                          onClick={() =>
                                                                                            confirmDelete(
                                                                                              item?.quickLinkActivityId
                                                                                            )
                                                                                          }
                                                                                          onKeyPress={() => confirmDelete(
                                                                                            item?.quickLinkActivityId
                                                                                          )}
                                                                                        >
                                                                                          Yes
                                                                                        </button>
                                                                                        <button
                                                                                          onClick={() => { setShowDeleteActivityConfirmation(false); }}
                                                                                          onKeyPress={() => { setShowDeleteActivityConfirmation(false); }}
                                                                                        >
                                                                                          No
                                                                                        </button>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : (
                                                                                    <></>
                                                                                  ))}

                                                                                  <div className="linkIcons">
                                                                                    {!item.isInternalQuickLink && <Icon
                                                                                      iconName="Edit"
                                                                                      title="Edit Activity"
                                                                                      className="edit-icon"
                                                                                      onClick={() =>
                                                                                        handleEditClick(
                                                                                          data.quickLinkGroupId,
                                                                                          item?.quickLinkActivityId
                                                                                        )
                                                                                      }
                                                                                    />}
                                                                                    <Icon
                                                                                      iconName="Delete"
                                                                                      title= 'Delete Activity'
                                                                                      className="delete-icon"
                                                                                      onClick={() =>
                                                                                        handleDeleteQuickLink(
                                                                                          data.quickLinkGroupId,
                                                                                          item?.quickLinkActivityId
                                                                                        )
                                                                                      }
                                                                                    />
                                                                                  </div>
                                                                                  {/* {!item.activityIdentifier && (
                                                  <>{item.linkName}</>
                                                )} */}

                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )
                                                        ) : (
                                                          <div className="activityChildContainer">
                                                            <div className="activityChildTitle noActivities">
                                                              <span className="titleOfActivity">
                                                                No Activities
                                                                Found
                                                              </span>
                                                            </div>
                                                          </div>
                                                        ))}
                                                      {provided.placeholder}
                                                    </div>
                                                  )}
                                                </Droppable>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      </>
                                    </div>
                                  );
                                })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </>
                </>
              )}
              {/* ... (rest of the modal content) */}
            </div>
          ) : (
            <PreivewQuickLink
              previewData={previewData}
              phaseName={selectedPhase.label} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
           {buttonStage === 'submit' ? 'Back' : 'Cancel'}
          </button>
          <button className="btn-next" onClick={handleButtonClick}>
            {buttonStage === 'fetch' ? 'Next' : buttonStage === 'preview' ? 'Preview' : 'Submit'}
          </button>
        </Modal.Footer>
      </Modal>
    </DragDropContext>
  );
}
const PreivewQuickLink = ({
  phaseName,
  previewData
}) => {
  const linkOrder = (array) => {
		return array.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}
  const order = (array) => {
		return array.sort((a, b) => a.sequence - b.sequence);
	}
  return (
     <>
     <div className='previewLeftPanel'>
    
         <div className='preivewPhase quickLinkPhase'>
         <Icon
            iconName="Blur"
            tabIndex={0}
            role="button"
          />
             <span className="quickLinkPhaseSpan">{phaseName}</span>
         </div>
         <div className='previewGroupsPanel'>
        
             {order(previewData)?.map((item) => (
                 <div className='previewGroupsQuick ' key={item.quickLinkGroupId}>
             
                     <span style={{color:'#05132C'}}>
                         {item?.quickLinkGroupName}
                     </span>
                     {linkOrder(item.quickLinkActivities)?.map((data) => (
                         <div className='previewQuickLink '>
                             <a
                                 href={data?.linkUrl}
                                 key={data?.quickLinkActivityId}
                                 tabIndex={0}
                                 aria-label={data?.linkName}
                                 target="_blank"
                                 rel="noopener noreferrer" // This is important for security reasons
                             >
                                 {data?.linkName}
                             </a>
                         </div>
                     ))}
                 </div>
             ))}
             
         </div>
     </div>
 </>
  );
}


export default ManageQuickLinks;
