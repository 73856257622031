import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class EnableStyleAttribute extends Plugin {
	init() {
		const editor = this.editor;
		const modelElements = ['heading1', 'heading2', 'heading3', 'paragraph'];
		const allowedAttributes = [
			'style',
			'class',
			'id',
			'name',
			'title',
			'data-attr',
			'data-attr-id',
			'tabindex',
		];

		const preserveAttribute = (attribute) => {
			modelElements.forEach((element) => {
				editor.model.schema.extend(element, {
					allowAttributes: attribute,
				});
				editor.conversion.for('upcast').attributeToAttribute({
					model: {
						name: element,
						key: attribute,
					},
					view: attribute,
				});
				editor.conversion.for('downcast').add(
					(dispatcher) => {
						dispatcher.on(`attribute:${attribute}:${element}`, (evt, data, conversionApi) => {
							const viewElement = conversionApi.mapper.toViewElement(data.item);
							conversionApi.writer.setAttribute(attribute, data.attributeNewValue, viewElement);
						});
					},
					{ priority: 'highest' }
				);
			});
		};
		for (const attribute of allowedAttributes) {
			preserveAttribute(attribute);
		}
	}
}
