import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const SampleReRatingModal = ({
	setShowRatingStar,
	setShowRatingConfirmationPopUp,
	showRatingConfirmationPopUp,
	showRatingStar,
	setRateAgain,
}) => {
	return (
		<Modal
			className="templateRejectModalPopUp"
			show={showRatingConfirmationPopUp}
			onHide={() => {
				setShowRatingStar(-1);
				setShowRatingConfirmationPopUp(false);
			}}
			size="md"
			centered
		>
			<Modal.Body>
				<p>
					You have already rated this sample template. Do you want to rate it
					again?
				</p>
				<Button
					className="noRating"
					onClick={() => {
						setShowRatingStar(-1);
						setShowRatingConfirmationPopUp(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowRatingStar(-1);
							setShowRatingConfirmationPopUp(false);
						}
					}}
					aria-label="No"
				>
					No
				</Button>

				<Button
					className="reRating"
					onClick={() => {
						setShowRatingStar(showRatingStar);
						setShowRatingConfirmationPopUp(false);
						setRateAgain(true);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowRatingStar(showRatingStar);
							setShowRatingConfirmationPopUp(false);
							setRateAgain(true);
						}
					}}
					aria-label="Yes"
				>
					Yes
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default SampleReRatingModal;
