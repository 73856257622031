import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';

function AccessabilityCheck({
	showAccessabillity,
	setShowAccessabillity,
	closeModal,
}) {
	return (
		<Modal
			show={showAccessabillity}
			onHide={() => {
				setShowAccessabillity(false);
			}}
			centered
			dialogClassName="AccessabilityModal"
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					How to do accessability test for templates
				</Modal.Title>
				<Icon
					iconName="Cancel"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							closeModal();
						}
					}}
					onClick={() => {
						setShowAccessabillity(false);
					}}
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="checkingAccessabilityStepsMainont">
					<div className="checkingAccessabilitySteps">
						<div>
							<p>Here are steps for testing acessability for templates</p>
							<ol>
								<li>1. Open any template(e.g., Word, PPT or Excell</li>
								<li>
									2. Click on <b>Review tab</b> {`>>`} click on{' '}
									<b>Check Accessability</b>
								</li>
							</ol>
						</div>
						<span className="AccessabilityCheckImage"></span>
					</div>
					<div>
						<h6>Common issues</h6>
						<ol>
							<li>
								<p>1. Missing alternative text</p>
								<div>
									<p>
										<span>
											<Icon iconName="CircleShapeSolid" />
										</span>
										Right click on the image and select "<b>Edit Alt Text</b>"
										Add the text of image in the section
									</p>
									<span className="AccessabilityAltText"></span>
								</div>
								<p>
									<p>
										<span>
											<Icon iconName="CircleShapeSolid" />
										</span>
										Enter the image Name
									</p>
									<span className="accessabilityAddAltText"></span>
								</p>
							</li>
							<li>
								<p>2. Image or object not inline</p>
								<div>
									<p>
										<span>
											<Icon iconName="CircleShapeSolid" />
										</span>
										Right click on the image and select "<b>Wrap Text</b>"{' '}
										{`>>`} "<b>Inline with Text</b>"
									</p>
									<span className="accessabilityWrapText"></span>
								</div>
							</li>
							<li>
								<p>3. No Header Row</p>
								<div>
									<p>
										<span>
											<Icon iconName="CircleShapeSolid" />
										</span>
										Click on the table {`>>`} "<b>Table Design</b>"{`>>`} check
										"<b>Header Row</b>"
									</p>
									<span className="accessabilityTableDesign"></span>
								</div>
							</li>
							<li>
								<p>4. Check Tab Order(Reading Order) in tables</p>
							</li>
							<li>
								<p>5. Hard-to-read Text Contrast</p>
								<div>
									<p>
										<span>
											<Icon iconName="CircleShapeSolid" />
										</span>
										Change the color of text from Pink to Brown
									</p>
								</div>
							</li>
						</ol>
					</div>
					<div className="checkingAccessabilityStepsButton">
						<button
							onClick={() => {
								setShowAccessabillity(false);
							}}
						>
							Yes, I got it
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default AccessabilityCheck;
