import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { Icon } from '@fluentui/react';
import Activities from './Activities';
import Form from 'react-bootstrap/Form';
import Select, { components } from 'react-select';
import { urlValidator } from '../../../helpers/Validation';

const LinkDetails = ({
    quickLinkTypeId,
    leftMenuGroupData,
    setLeftMenuGroupData,
    setGroupCreated,
	setGroupUpdated,
    phaseId,
	complexityId,
    setLinkCreated,
	setLinkUpdated,
    setLinkDeleted,
    setGroupAndLinksChanged
}) => {
    const { pathname } = useLocation();
	const history = useHistory();
    const { axiosGetService, axiosPostService } = useAxios();
    const [showExternalLinkForm, setShowExternalLinkForm] = useState(false);
    const [showGroupForm, setShowGroupForm] = useState(false);
    const [groupName, setGroupName] = useState();
    const [sequenceNumber, setSequenceNumber] = useState(0);
    const [groupSelectedOptions, setGroupSelectedOptions] = useState();
    const [linkSequenceNumber, setlinkSequenceNumber] =useState();
    const [linkTitle, setLinkTitle] = useState();
    const [linkUrl, setLinkUrl] =useState();
    const [activityIdentifier, setActivityIdentifier] = useState(0);
    const [solutionMethodId, setSolutionMethodId] = useState(0);
    const [isInternalQuickLink, setIsInternalQuickLink] =useState(0);
    const [groupSelectedName, setGroupSelectedName] = useState();

    const [showDuplicateGroup, setShowDuplicateGroup] = useState(false);
    const [showDuplicateLink, setShowDuplicateLink] = useState(false);
    const [showDuplicateLinkUrl, setShowDuplicateLinkUrl] = useState(false);
    const [showGroupRequired, setShowGroupRequired] = useState(false);

    const [quickLinkId, setQuickLinkId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [validateUrl, setValidateUrl] = useState(false);
    const [validateTitle, setValidateTitle] = useState(false);
    const [linkTextMessage, setlinkTextMessage] = useState();
    const [linkUrlMessage, setlinkUrlMessage] = useState();
    const [linkValidation, setLinkValidation] = useState(false);

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <input
                    type="radio"
                    checked={groupSelectedOptions === props.data.quickLinkGroupId ? true : false}
                    onChange={() => null}
                    className="custom-radio"
                    value={props.data.quickLinkGroupId}
                />
                <label className="custom-option-label">{props.data.quickLinkGroupName}</label>
            </components.Option>
        );
    };

    // Custom styles for react-select
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #0078D4',
            boxShadow: 'none',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            cursor: 'pointer',
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: 'none',
            marginTop:'0px',
            border: '1px solid #0078D4',
            maxHeight: '150px',
            overflow: 'scroll'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#E1F0FB' : '',
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            cursor: 'pointer',
        }),
    };

    const saveGroupDetails = async () => {
        const postData = {
            QuickLinkGroupName: groupName,
            Sequence: sequenceNumber,
            QuickLinkTypeId: quickLinkTypeId
        };

        await axiosPostService(`api/sdmquicklink/addquicklinkgroup`, postData).then(
            (response) => {
                if (response.data.status) {
                    setGroupName('');
                    setGroupCreated(true);
                    setGroupAndLinksChanged(prev=> prev + 1)
                    toast.info(`You have successfully added the group`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                setShowGroupForm(false);
            }
        );
    }

    const validateGroup = async() => {
        if(groupName === '' || groupName === undefined){
            toast.error("Please enter the valid group name", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }else{
            var postData = {
                SearchText: groupName,
                QuickLinkTypeId: quickLinkTypeId,
                QuickLinkId: quickLinkId,
                GroupId: groupId,
                PhaseId: phaseId,
                ComplexityId: complexityId,
                IsGroup: true,
                IsUrl: false,
                IsUpdate: false
            };

            await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
                (response) => {
                    if(response.data.status === 1){
                        setShowDuplicateGroup(true);
                    }else{
                        setShowDuplicateGroup(false);
                        saveGroupDetails();
                    }
                }
            );
        }
    }

    const validateLinkText = async() => {
        if (groupSelectedOptions === '' || groupSelectedOptions === undefined) {
            setShowGroupRequired(true);
            setValidateTitle(false);
            return;
        }else if( linkTitle === '' || linkTitle === undefined){
            setShowGroupRequired(false);
            setShowDuplicateLink(true);
            setlinkTextMessage("Please enter the link text");
            setValidateTitle(false);
            return;
        }else{
            setShowGroupRequired(false);
            var postData = {
                SearchText: linkTitle,
                QuickLinkTypeId: quickLinkTypeId,
                QuickLinkId: quickLinkId,
                GroupId: groupSelectedOptions,
                PhaseId: phaseId,
                ComplexityId: complexityId,
                IsGroup: false,
                IsUrl: false,
                IsUpdate: false
            };

            await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
                (response) => {
                    if(response.data.status === 1){
                        setShowDuplicateLink(true);
                        setlinkTextMessage("Duplicate Link Text not allowed");
                        setValidateTitle(false);
                        return;
                    } else {
                        setShowDuplicateLink(false);
                        setValidateTitle(true);
                        return;
                    }
                }
            );
        }
    }

    const validateLinkUrl = async(event) => {
        await validateLinkText();
        if (groupSelectedOptions === '' || groupSelectedOptions === undefined) {
            setShowGroupRequired(true);
            setValidateUrl(false);
            return;
        }else if(linkUrl === '' || linkUrl === undefined){
            setShowGroupRequired(false);
            setShowDuplicateLinkUrl(true);
            setlinkUrlMessage("Please enter the link url");
            setValidateUrl(false);
            return;
        }else if(!urlValidator.test(linkUrl)){
            setShowDuplicateLinkUrl(true);
            setlinkUrlMessage("Please enter the valid link url");
            setValidateUrl(false);
            return;
        }else{
            setShowGroupRequired(false);
            var postData = {
                SearchText: linkUrl,
                QuickLinkTypeId: quickLinkTypeId,
                QuickLinkId: quickLinkId,
                GroupId: groupSelectedOptions,
                PhaseId: phaseId,
                ComplexityId: complexityId,
                IsGroup: false,
                IsUrl: true,
                IsUpdate: false
            };

            await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
                (response) => {
                    if(response.data.status === 1){
                        setShowDuplicateLinkUrl(true);
                        setlinkUrlMessage("Duplicate Link Url not allowed");
                        setValidateUrl(false);
                        return;
                    } else {
                        setShowDuplicateLinkUrl(false);
                        setValidateUrl(true);
                        setLinkValidation(!linkValidation);
                        return;
                    }
                }
            );
        }
    }                                    
    useEffect(() => {
        if(validateUrl && validateTitle) {
            const postData = {
                LinkName: linkTitle,
                LinkUrl: linkUrl,
                ActivityIdentifier: activityIdentifier,
                SequenceNumber: linkSequenceNumber,
                SolutionMethodId: solutionMethodId,
                PhaseId: phaseId,
                GroupId: groupSelectedOptions,
                ComplexityId: complexityId,
                IsInternalQuickLink: isInternalQuickLink,
                QuickLinkTypeId: quickLinkTypeId
            };
    
            axiosPostService(`api/sdmquicklink/addquicklinkactivity`, postData).then(
                (response) => {
                    if (response.data.status) {
                        setLinkTitle('');
                        setLinkUrl('');
                        setGroupSelectedName('');
                        setGroupSelectedOptions('');
                        setLinkCreated(true);
                        setValidateTitle(false);
                        setValidateUrl(false);
                        setGroupAndLinksChanged(prev=> prev + 1)
                        toast.info(`You have successfully added the quick link`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(response.data.errorMessage, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setShowExternalLinkForm(false);
                }
            );
        }
    },[linkValidation])

    const saveLinkDetails = async () => {
        await validateLinkUrl();
    }

    const order = (array) => {
		return array.sort((a, b) => a.sequence - b.sequence);
	}

    return(
        <>
            <div className="activitiesList">
                {order(leftMenuGroupData).length !== 0 && order(leftMenuGroupData).map((item) => (
                    <div
                        className='leftOrangeDelivery'
                        key={item.quickLinkGroupId}
                    >
                        <Activities
                            data={item}
                            key={item.quickLinkGroupId}
                            quickLinkTypeId={quickLinkTypeId}
                            setGroupCreated={setGroupCreated}
                            setGroupUpdated={setGroupUpdated}
                            setLeftMenuGroupData={setLeftMenuGroupData}
                            phaseId={phaseId}
                            isInternalQuickLink={isInternalQuickLink}
                            complexityId={complexityId}
                            solutionMethodId={solutionMethodId}
                            activityIdentifier={activityIdentifier}
                            setLinkCreated ={setLinkCreated}
	                        setLinkUpdated={setLinkUpdated}
                            setLinkDeleted={setLinkDeleted}
                            setGroupAndLinksChanged={setGroupAndLinksChanged}
                        />
                    </div>
                ))}
            </div>

            <div className='leftAddGroupButtonAction'>
                <div
                    class="selectAddGroupButton"
                    onClick={() => setShowExternalLinkForm(!showExternalLinkForm)}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            setShowExternalLinkForm(!showExternalLinkForm);
                        }
                    }}
                    title="Add a New Link/Group"
                    tabIndex={0}
                    aria-label="Click to Add a New Link/Group"
                    role="button"
                >
                    +&nbsp;Add a New Link/Group
                </div>
            </div>

            <div >
                {showExternalLinkForm ? (
                    <div className='addExternalLinkForm'>
                        <div className= {showDuplicateLink || showDuplicateLinkUrl ? "addDuplicateLinkFields" : "addLinkFields"} >
                            <div className="left-dropdown-container">
                                <label htmlFor="left-group-select" className="dropdown-label">Select Group</label>
                                <Select
                                    id="left-group-select"
                                    options={leftMenuGroupData}
                                    value={groupSelectedOptions}
                                    onChange={(e) => {
                                        var maxAge;
                                        if (e !== null) {
                                            if (e.quickLinkActivities.length !== 0 && e.quickLinkActivities[0] !== null) {
                                                maxAge = Math.max(...e.quickLinkActivities?.map(obj => obj.sequenceNumber));
                                            } else {
                                                maxAge = 0;
                                            }
                                        }
                                        setGroupSelectedOptions(e.quickLinkGroupId);
                                        setlinkSequenceNumber(parseInt(maxAge+1,10));
                                        setGroupSelectedName(e.quickLinkGroupName);
                                    }}
                                    placeholder={groupSelectedName}
                                    styles={customStyles}
                                    components={{ Option }}
                                    isSearchable={false}
                                />
                                <div className='addGroupLeftPanelAction'>
                                    <button
                                        class="addGroupButton"
                                        onClick={() => {
                                            var maxAge;
                                            if(leftMenuGroupData.length !== 0){
                                                maxAge = Math.max(...leftMenuGroupData.map(obj => obj.sequence));
                                            }else{
                                                maxAge = 0;
                                            }
                                            setSequenceNumber(parseInt(maxAge,10)+1);
                                            setShowGroupForm(!showGroupForm);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.which === 13) {
                                                setShowGroupForm(!showGroupForm);
                                            }
                                        }}
                                        title="Add a New Group"
                                        tabIndex={0}
                                        aria-label="Click to Add a New Group"
                                        role="button"
                                    >
                                        +
                                    </button>
                                </div>
                                <div>
                                    {showGroupForm ? (
                                        <div className='addGroupForm'>
                                            <div className={showDuplicateGroup ? "addDuplicateGroup" : "addGroupFields" }>
                                                <div class="entryGroupName">
                                                    <input
                                                        id="group-name"
                                                        title="Group Name"
                                                        placeholder="Group Name"
                                                        value={groupName}
                                                        onChange={(e) => setGroupName(e.target.value)}
                                                        autoFocus={true}
                                                    />
                                                </div>

                                                <div class="leftGroupButtons">
                                                    <button
                                                        className="leftBtnFooter leftGroupSaveButton"
                                                        onClick={() => { validateGroup() }}
                                                    >
                                                        Add
                                                    </button>
                                                    <button
                                                        className="leftBtnFooter leftGrouupCancelButton"
                                                        onClick={() => { setShowGroupForm(false); }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                            {showDuplicateGroup? (
                                                <span>Duplicate Group Name not allowed</span> 
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className='leftSelectGroup'>
                                    {showGroupRequired? (
                                        <span>Please Select the group</span> 
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div class="entryCriteriaName">
                                <label htmlFor="group-link-text" className="dropdown-label">Link Text</label>
                                <input
                                    id="group-link-text"
                                    title="Link Text"
                                    placeholder="Link Text"
                                    value={linkTitle}
                                    onChange={(e) => { setLinkTitle(e.target.value) }}
                                    onBlur={() => { validateLinkText(); }}
                                />
                                {showDuplicateLink? (
                                    <span>{linkTextMessage}</span> 
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div class="entryCriteriaName">
                                <label htmlFor="group-link-url" className="dropdown-label">Link Url</label>
                                <input
                                    id="group-link-url"
                                    title="Link URL"
                                    placeholder="Link Url"
                                    value={linkUrl}
                                    onChange={(e) => {
                                        setLinkUrl(e.target.value);
                                    }}
                                />
                                {showDuplicateLinkUrl? (
                                    <span>{linkUrlMessage}</span> 
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div class="criteriaButtons manageLeftPanelLinkBtn">
                                <button
                                    onClick={() => {
                                        setLinkTitle('');
                                        setLinkUrl('');
                                        setGroupSelectedName('');
                                        setGroupSelectedOptions('');
                                        setShowDuplicateLink(false);
                                        setShowDuplicateLinkUrl(false);
                                        setShowExternalLinkForm(false);
                                    }}
                                    title='Cancel'
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        saveLinkDetails();
                                    }}
                                    title='Add Link'
                                >
                                    Add Link
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
					<></>
				)}
            </div>
        </>
    );
}

export default LinkDetails;