import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { useAxios } from '../../context/AxiosContext';

import './DiscardPopUp.css';

function DiscardPopUp(props) {
	const [modalShow, setModalShow] = useState(true);
	const { topicType, id } = useParams();
	const [messagesType, setMessagesType] = useState({});
	const { axiosGetService } = useAxios();
	const closeModal = () => {
		setModalShow(false);
	};

	const getMessage = async () => {
		const message = {};
		await axiosGetService(`/api/sdmuser/getmessagevalue?messageName=pendingApproval&title=0`).then(
			(response) => {
				message['pendingApproval'] = response.data;
			}
		);

		setMessagesType(message);
	};
	useEffect(() => {
		getMessage();
	}, []);
	return (
		<Modal
			className="activityDiscardPopUp contributorPopUp"
			show={modalShow}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{messagesType['pendingApproval']}</p>
				<div className="discardPopUpActivityButtons">
					<Link to={`/topics/${id}`}>
						<Button
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							className="activityDiscardPopUpAccept"
							variant="info"
						>
							ok
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
