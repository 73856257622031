import React from 'react';
import ReactPaginate from 'react-paginate';

import { useAppInsights } from '../../context/TelemetryContext';

import '../Pagination/Pagination.css';

function SearchPagination({ pageCount, setCurrentPage, currentPage, searchParam, selectedTab }) {
	const { track } = useAppInsights();
	return (
		<ReactPaginate
			initialPage={currentPage - 1}
			previousLabel={'<'}
			nextLabel={'>'}
			onPageChange={(data) => {
				window.scrollTo(0, 0);
				setCurrentPage(data.selected + 1);
				track(1, 406, 'Search Result Page', searchParam, {
					pageNumber: data.selected + 1,
					selectedTab: selectedTab,
				});
			}}
			disableInitialCallback={true}
			pageCount={pageCount}
			breakClassName={'breakPagination'}
			marginPagesDisplayed={2}
			pageRangeDisplayed={2}
			containerClassName={'react-hooks-paginator'}
			pageClassName={'page-item'}
			pageLinkClassName={'page-link'}
			previousClassName={'page-item'}
			previousLinkClassName={'page-link'}
			nextClassName={'page-item'}
			nextLinkClassName={'page-link'}
			activeClassName={'active'}
		/>
	);
}

export default SearchPagination;
