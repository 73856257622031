import React from 'react';
import { Icon } from '@fluentui/react';

const RemoveRaci = ({
	complexityLevelDetails,
	setComplexityLevelDetails,
	selectedComplexity,
	type,
	allowEdit,
}) => {
	const removeRole = (roleId) => {
		const updatedData = [...complexityLevelDetails[selectedComplexity][type]];
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				[type]: updatedData.filter(({ id }) => id !== roleId),
			},
		}));
	};

	return (
		<>
			{complexityLevelDetails[selectedComplexity] &&
				complexityLevelDetails[selectedComplexity][type].length > 0 && (
					<div className="raci-role-list">
						{complexityLevelDetails[selectedComplexity][type].map((role) => (
							<div
								className="raci-role-item"
								key={`role_${role.id}`}
							>
								<div className="raci-role-content">
									<span className="raci-role-name" title={role.name}>
										{role.name}
									</span>
									{allowEdit && (
										<Icon
											iconName="cancel"
											className="raci-role-remove-icon"
											role="button"
											tabIndex={0}
											onClick={() => {
												removeRole(role.id);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													removeRole(role.id);
												}
											}}
										/>
									)}
								</div>
							</div>
						))}
					</div>
				)}
		</>
	);
};

export default RemoveRaci;
