import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Icon } from '@fluentui/react';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import PhaseDetails from './PhaseDetails';
import Collaborators from './Collaborators';
import PhaseSequence from './PhaseSequence';
import AddVisio from './UpdateSwimlane';
import OtherDetails from './OtherDetails';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import { useAxios } from '../../../context/AxiosContext';
import { HtmlSanitizer } from '../../../helpers/HtmlSanitizer';
import { artifactMapData } from '../../../static/ArtifactMapData';

import './CreatePhase.css';

const CreatePhase = ({
	showPhaseUpdateModal,
	setShowPhaseUpdateModal,
	projectTypeId,
	solutionMethodId,
	setPhaseCreated,
	updatePhaseId,
	setShowPhaseLockModal,
	setLockEmailId,
	type,
	parentId,
	getParentSolutionMethodData,
	methodOwners,
	setParentPhaseCreated,
	parentPhaseCreated,
	getSolutionMethodData,
	phaseCreated,
	selectedTab,
	setSelectedTab,
	phaseBuilderType,
	isPhaseEdited,
	setIsPhaseEdited
}) => {
	const { track } = useAppInsights();
	const { phase, parentPhase } = useLeftMenuCollapse();
	const [pageLockUser, setPageLockUser] = useState(null);
	const [enableSendForApproval, setEnableSendForApproval] = useState(false);
	const [usersDetail, setUsersDetail] = useState({});
	const [approvalStatus, setApprovalStatus] = useState([]);
	const [disableSaveAndContinue, setDisableSaveAndContinue] = useState(false);
	const [fileSize, setFileSize] = useState(0);
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
		accept: 'image/svg+xml',
		onDrop: (acceptedFile) => {
			setSwimlanePreviewUrl(
				acceptedFile.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			setSwimlaneSharePointUrl(null);
			setEnableSendForApproval(true);
		},
	});
	const { axiosGetService, axiosPostService, axiosDeleteService, userAlias } =
		useAxios();
	const history = useHistory();
	const phaseId = updatePhaseId;
	const [showSpinner, setShowSpinner] = useState(false);
	const [swimlanePreviewUrl, setSwimlanePreviewUrl] = useState([]);
	const [swimlaneSharePointUrl, setSwimlaneSharePointUrl] = useState(null);
	const [phaseTitle, setPhaseTitle] = useState('');
	const [phaseDescription, setPhaseDescription] = useState('');
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [methodOwner, setMethodOwner] = useState('');
	const [phases, setPhases] = useState([]);
	const [swimlaneFile, setSwimlaneFile] = useState(null);
	const [enableComplexity, setEnableComplexity] = useState(true);
	const [uploadSwimlane, setUploadSwimlane] = useState(false);
	const [phaseEntryCriteria, setPhaseEntryCriteria] = useState([]);
	const [phaseExitCriteria, setPhaseExitCriteria] = useState([]);
	const [error, setError] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [isDrafted, setIsDrafted] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState('');
	const [phaseTitleError, setPhaseTitleError] = useState(false);
	const [existingPhaseTitle, setExistingPhaseTitle] = useState([]);
	const [existingPhase, setExistingPhase] = useState({});
	const [showCollaboratorSpinner, setShowCollaboratorSpinner] = useState(false);
	const [prevPostData, setPrevPostData] = useState({});

	const phaseTab = [
		{ id: 1, name: 'Phase Details' },
		{ id: 2, name: 'Collaborators' },
		{ id: 3, name: 'Sequence Phase' },
		{ id: 4, name: 'Add Swimlane' },
		{ id: 5, name: 'Other Details' },
	];

	const filterFileType = (uploadedFile) => {
		const fileTypes = ['svg'];
		const selectedFileType = uploadedFile.name
			.split('.')
			.slice(-1)
			.pop()
			.toLowerCase();
		if (fileTypes.includes(selectedFileType)) {
			setSwimlaneFile(uploadedFile);
			setUploadSwimlane(true);
			setFileError(false);
			setError(false);
			setSizeError(false);
		} else {
			setSwimlaneFile(null);
			setFileError(true);
			setError(false);
			setSizeError(false);
		}
	};
	const getUser = async (userEmail, setValue) => {
		setShowCollaboratorSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setShowCollaboratorSpinner(false);
		}, 1000);
	};
	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	};

	const sortEmail = (value) => {
		const updatedValue = value
			.map((item) => {
				return Number(item);
			})
			.sort(function (id1, id2) {
				return id1 - id2;
			});
		return updatedValue;
	};
	const getPhases = async () => {
		await axiosGetService(
			`api/sdmphase/getbysolutionmethod/${
				type === 'parentPhase' ? parentId : solutionMethodId
			}`
		).then((response) => {
			const data = response.data.map((item) => {
				return {
					id: item.phaseId.toString(),
					name: item.phaseName,
					type: item.phaseId === updatePhaseId ? 'new' : 'old',
				};
			});
			setPhases(data);
			const phaseSequenceIds = data.map(({ id }) => id);
			setPrevPostData((prevState) => ({
				...prevState,
				phaseSequenceIds: phaseSequenceIds.toString(),
			}));
			const phaseTitleData = response.data.map((item) => {
				return item.phaseName.toLowerCase();
			});
			setExistingPhaseTitle(phaseTitleData);
		});
	};

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const getSecurityGroupIds = async () => {
		await axiosGetService(
			`api/sdmphase/getbyid/${updatePhaseId}/${
				type === 'parentPhase' ? parentId : solutionMethodId
			}`
		).then((response) => {
			setExistingPhase(response.data);
			getCollaboratorsInfo(
				response.data.coOwnerMembersList,
				setExistingCoOwners
			);
			getCollaboratorsInfo(
				response.data.reviewerMembersList,
				setExistingReviewers
			);
			getCollaboratorsInfo(
				response.data.approverMembersList,
				setExistingApprovers
			);
			setMethodOwner(response.data.methodOwner);
			setPhaseTitle(response.data.phaseName);
			setPhaseDescription(response.data.phaseDescription);
			setIsDrafted(response.data.isDrafted);
			setEnableComplexity(response.data.enableComplexity);
			const entryPhaseCriteria = response.data.entryPhaseCriteria.map(
				(item) => {
					return {
						id: item.id,
						name: item.name,
						description: item.description,
						criteriaType: item.criteriaType,
						valueType: item.valueType,
						status: 'old',
					};
				}
			);
			const existingEntryPhaseCriteria = response.data.entryPhaseCriteria.map(
				(item) => {
					return {
						id: item.id,
						name: item.name,
						description: item.description,
						criteriaType: item.criteriaType,
						valueType: item.valueType,
						status: 'old',
					};
				}
			);
			const exitPhaseCriteria = response.data.exitPhaseCriteria.map((item) => {
				return {
					id: item.id,
					name: item.name,
					description: item.description,
					criteriaType: item.criteriaType,
					valueType: item.valueType,
					status: 'old',
				};
			});
			const existingExitPhaseCriteria = response.data.exitPhaseCriteria.map(
				(item) => {
					return {
						id: item.id,
						name: item.name,
						description: item.description,
						criteriaType: item.criteriaType,
						valueType: item.valueType,
						status: 'old',
					};
				}
			);
			setPrevPostData((prevState) => ({
				...prevState,
				phaseName: response.data.phaseName,
				phaseDescription: response.data.phaseDescription,
				enableComplexity: response.data.enableComplexity,
				solutionMethodId: type === 'parentPhase' ? parentId : solutionMethodId,
				phaseId: response.data.phaseId,
				entryPhaseCriteria:
					existingEntryPhaseCriteria.length > 0
						? existingEntryPhaseCriteria
						: null,
				exitPhaseCriteria:
					existingExitPhaseCriteria.length > 0
						? existingExitPhaseCriteria
						: null,
				coOwnerMembersList: response.data.coOwnerMembersList.sort(),
				approverMembersList: response.data.approverMembersList.sort(),
				reviewerMembersList: response.data.reviewerMembersList.sort(),
				imagePaths: response.data.imagePaths,
			}));
			setPhaseEntryCriteria(entryPhaseCriteria);
			setPhaseExitCriteria(exitPhaseCriteria);
			setSwimlanePreviewUrl([{ preview: response.data.svgFileName }]);
			setSwimlaneSharePointUrl(response.data.svgFileName);
			setDownloadUrl(response.data.svgFileName);
		});
		setShowSpinner(false);
	};

	const updateCriteriaDetails = (value) => {
		const criteriaDetails = value.map((item) => {
			if (item.status === 'old') {
				return {
					id: item.id,
					name: item.name,
					description: item.description,
					criteriaType: item.criteriaType,
					valueType: item.valueType,
				};
			}
			return {
				name: item.name,
				description: item.description,
				criteriaType: item.criteriaType,
				valueType: item.valueType,
			};
		});
		return criteriaDetails;
	};
	const submitPhaseDetails = async () => {
		const phaseSequenceIds = phases.map(({ id }) => id);
		const entryPhaseCriteria = updateCriteriaDetails(phaseEntryCriteria);
		const exitPhaseCriteria = updateCriteriaDetails(phaseExitCriteria);
		const coOwnersEmails = coOwners.map(({ mail }) => {
			return mail;
		});
		const reviewersEmails = reviewers.map(({ mail }) => {
			return mail;
		});
		const approversEmails = approvers.map(({ mail }) => {
			return mail;
		});
		const imagesUrl = sessionStorage.getItem('phaseUploadedImages')
			? JSON.parse(sessionStorage.getItem('phaseUploadedImages'))
			: [];
		const postData = {
			phaseSequenceIds: phaseSequenceIds.toString(),
			phaseName: HtmlSanitizer(phaseTitle),
			phaseDescription: HtmlSanitizer(phaseDescription),
			enableComplexity: enableComplexity,
			solutionMethodId: type === 'parentPhase' ? parentId : solutionMethodId,
			phaseId: phaseId,
			entryPhaseCriteria:
				entryPhaseCriteria.length > 0 ? entryPhaseCriteria : null,
			exitPhaseCriteria:
				exitPhaseCriteria.length > 0 ? exitPhaseCriteria : null,
			coOwnerMembersList: coOwnersEmails.sort(),
			approverMembersList: approversEmails.sort(),
			reviewerMembersList: reviewersEmails.sort(),
			imagePaths: [...prevPostData.imagePaths, ...imagesUrl],
		};

		if (JSON.stringify(prevPostData) !== JSON.stringify(postData)) {
			setDisableSaveAndContinue(true);
			postData.isDrafted = true;
			postData.createdBy = userAlias;
			postData.methodOwner = methodOwner;
			postData.modifiedBy = userAlias;
			postData.isColllaborators = selectedTab === 2 ? true : false;
			await axiosPostService(`api/sdmphase/updateordraft`, postData).then(
				(response) => {
					const selectedTabName = phaseTab.filter(
						({ id }) => id === selectedTab
					);
					if (response.data.status) {
						track(
							1,
							type === 'parentPhase' ? 803 : 801,
							phaseId,
							response.data.phaseName,
							{
								SaveAndContinueTabName: selectedTabName[0].name,
							}
						);
						setExistingCoOwners([...coOwners]);
						setExistingReviewers([...reviewers]);
						setExistingApprovers([...approvers]);
						setIsPhaseEdited(!isPhaseEdited)
						if (selectedTab === 5) {
							if (type === 'parentPhase') {
								getParentSolutionMethodData(parentId, methodOwners);
								setParentPhaseCreated(!parentPhaseCreated);
							} else {
								getSolutionMethodData(methodOwners, 'update');
								setPhaseCreated(!phaseCreated);
							}
							setSelectedTab(1);
							history.push(
								`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
									type !== 'parentPhase' ? phaseId : phase.id
								}/parentPhase/${
									type === 'parentPhase' ? phaseId : parentPhase.id
								}`
							);
							setShowPhaseUpdateModal(false);
						}
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
					setDisableSaveAndContinue(false);
				}
			);
		}
	};
	const getSwimlaneUploadDetails = async (userEmail) => {
		if (!usersDetail[userEmail]) {
			const accessToken = await axiosTokenService();
			await getOtherUserProfile(accessToken, userEmail).then((response) => {
				setUsersDetail((prevState) => ({
					...prevState,
					[userEmail]: response,
				}));
			});
		}
	};

	const checkApprovalStatus = async () => {
		await axiosGetService(`api/sdmPhaseSwimlane/getbyphase/${phaseId}`).then(
			(response) => {
				setApprovalStatus(response.data);
				for (const user of response.data) {
					getSwimlaneUploadDetails(user.uploadedEmail);
				}
			}
		);
	};

	const enterLockMode = async () => {
		const postData = {
			artifactId: updatePhaseId,
			artifactTypeConstId: artifactMapData['phases'],
			artifactDraftId: 0,
		};
		await axiosPostService(`api/sdmartifactmaster/lockArtifact`, postData).then(
			() => {
				setPageLockUser(userAlias);
			}
		);
	};
	const checkLockMode = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifacts?artifactId=${updatePhaseId}&artifactTypeConstId=${
				artifactMapData['phases']
			}&artifactDraftId=${0}`
		).then((response) => {
			setLockEmailId(response.data.email);
			if (response.data.status && userAlias !== response.data.email) {
				setShowPhaseLockModal(true);
				setShowPhaseUpdateModal(false);
			} else if (!response.data.status) {
				enterLockMode();
			} else {
				enterLockMode();
				setPageLockUser(response.data.email);
			}
		});
	};

	const exitLockMode = async () => {
		if (pageLockUser === userAlias) {
			await axiosDeleteService(
				`api/sdmartifactmaster/deleteLockedArtifact?artifactId=${updatePhaseId}&artifactTypeConstId=${
					artifactMapData['phases']
				}&artifactDraftId=${0}`
			);
		}
		sessionStorage.setItem('phaseUploadedImages', JSON.stringify([]));
	};

	const leftMenu = {
		1: (
			<PhaseDetails
				phaseTitle={phaseTitle}
				setPhaseTitle={setPhaseTitle}
				phaseDescription={phaseDescription}
				setPhaseDescription={setPhaseDescription}
				existingPhaseTitle={existingPhaseTitle}
				phaseTitleError={phaseTitleError}
				setPhaseTitleError={setPhaseTitleError}
				currentPhaseTitle={existingPhase.phaseName}
				phaseBuilderType={phaseBuilderType}
			/>
		),
		2: (
			<Collaborators
				coOwners={coOwners}
				setCoOwners={setCoOwners}
				reviewers={reviewers}
				setReviewers={setReviewers}
				approvers={approvers}
				setApprovers={setApprovers}
				showSpinner={showCollaboratorSpinner}
			/>
		),
		3: <PhaseSequence phases={phases} setPhases={setPhases} />,
		4: (
			<AddVisio
				file={swimlaneFile}
				setFile={setSwimlaneFile}
				getRootProps={getRootProps}
				getInputProps={getInputProps}
				open={open}
				acceptedFiles={acceptedFiles}
				isDragActive={isDragActive}
				isDragAccept={isDragAccept}
				isDragReject={isDragReject}
				rejectedFiles={rejectedFiles}
				setUploadSwimlane={setUploadSwimlane}
				sizeError={sizeError}
				setSizeError={setSizeError}
				fileError={fileError}
				setFileError={setFileError}
				error={error}
				setError={setError}
				swimlanePreviewUrl={swimlanePreviewUrl}
				swimlaneSharePointUrl={swimlaneSharePointUrl}
				setEnableSendForApproval={setEnableSendForApproval}
				enableSendForApproval={enableSendForApproval}
				phaseId={updatePhaseId}
				setSwimlaneSharePointUrl={setSwimlaneSharePointUrl}
				downloadUrl={downloadUrl}
				usersDetail={usersDetail}
				setUsersDetail={setUsersDetail}
				approvalStatus={approvalStatus}
				setApprovalStatus={setApprovalStatus}
				submitPhaseDetails={submitPhaseDetails}
				fileSize={fileSize}
				getSwimlaneUploadDetails={getSwimlaneUploadDetails}
			/>
		),
		5: (
			<OtherDetails
				enableComplexity={enableComplexity}
				setEnableComplexity={setEnableComplexity}
				phaseExitCriteria={phaseExitCriteria}
				setPhaseExitCriteria={setPhaseExitCriteria}
				phaseEntryCriteria={phaseEntryCriteria}
				setPhaseEntryCriteria={setPhaseEntryCriteria}
			/>
		),
	};

	const closeModal = () => {
		if (pageLockUser === userAlias) {
			exitLockMode();
		}
		setTimeout(() => {
			if (type === 'parentPhase') {
				getParentSolutionMethodData(parentId, methodOwners);
				setParentPhaseCreated(!parentPhaseCreated);
			} else {
				getSolutionMethodData(methodOwners, 'update');
				setPhaseCreated(!phaseCreated);
			}
			setSelectedTab(1);
			history.push(
				`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
					type !== 'parentPhase' ? phaseId : phase.id
				}/parentPhase/${type === 'parentPhase' ? phaseId : parentPhase.id}`
			);
			setShowPhaseUpdateModal(false);
		}, 500);
	};

	useEffect(() => {
		checkLockMode();
	}, []);

	useEffect(() => {
		return () => {
			if (pageLockUser === userAlias) {
				exitLockMode();
			}
		};
	}, [pageLockUser]);

	useEffect(() => {
		checkApprovalStatus();
	}, []);

	useEffect(() => {
		const updatedPhase = phases.map((item) => {
			if (item.id === phaseId.toString()) {
				return { id: phaseId.toString(), name: phaseTitle, type: item.type };
			}
			return { id: item.id, name: item.name, type: item.type };
		});
		setPhases(updatedPhase);
	}, [phaseTitle]);

	useEffect(() => {
		setShowSpinner(true);
		getPhases();
		getSecurityGroupIds();
	}, []);

	useEffect(() => {
		setCoOwners(existingCoOwners);
	}, [existingCoOwners]);

	useEffect(() => {
		setReviewers(existingReviewers);
	}, [existingReviewers]);

	useEffect(() => {
		setApprovers(existingApprovers);
	}, [existingApprovers]);

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			swimlanePreviewUrl.forEach((previewUrl) =>
				URL.revokeObjectURL(previewUrl.preview)
			);
		},
		[swimlaneFile]
	);

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setError(false);
			setFileError(false);
			setSizeError(true);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length === 0) {
			setSwimlaneFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		let timeOutId = 0;
		if (pageLockUser === userAlias) {
			timeOutId = setInterval(() => {
				enterLockMode();
			}, 300000);
		}
		return () => {
			clearInterval(timeOutId);
		};
	}, [pageLockUser]);

	useEffect(() => {
		getFileSize();
	}, []);

	return (
		<Modal
			className="phaseCreationRightPopup"
			show={showPhaseUpdateModal}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<Modal.Title>Phase Builder </Modal.Title>
				<Icon
					iconName="ChromeClose"
					title="Close"
					onClick={closeModal}
					onKeyPress={(e) => {
						if (e.which === 13) {
							closeModal();
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Close Phase Builder"
				/>
			</Modal.Header>
			<Modal.Body>
				{showSpinner ? (
					<div className="spinner">
						<Spinner animation="border" />
					</div>
				) : (
					<div className="methodOwnerMainContainer">
						<div className="methodOwnerPage">
							<div className="methodOwnerContainer">
								<div className="methodLeftContainer">
									<ul className="methodPhaseLeftMenu">
										{phaseTab.map((tab) => (
											<li
												onClick={() => setSelectedTab(tab.id)}
												key={tab.id}
												className={
													selectedTab === tab.id ? 'activePhaseTab' : null
												}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setSelectedTab(tab.id);
													}
												}}
												tabIndex={0}
												aria-label={tab.name}
											>
												<span>{tab.name}</span>
											</li>
										))}
									</ul>
								</div>
								<div className="middleDetailsContainer">
									<div className="methodOwnerMiddleContainer">
										{leftMenu[selectedTab]}
									</div>
								</div>
								<div className="saveContinueDetailsContainer">
									<button
										disabled={selectedTab === 1}
										onClick={() => setSelectedTab(selectedTab - 1)}
										className="backwardButton"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setSelectedTab(selectedTab - 1);
											}
										}}
									>
										<span className="backDetailsButton">
											<Icon iconName="ChromeBack" />
											Back
										</span>
									</button>
									<button
										onClick={() => {
											if (selectedTab < 5) {
												setSelectedTab(selectedTab + 1);
											} else {
												closeModal();
											}
											if (phaseTitle) {
												submitPhaseDetails();
											}
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (selectedTab < 5) {
													setSelectedTab(selectedTab + 1);
												} else {
													closeModal();
												}
												if (phaseTitle) {
													submitPhaseDetails();
												}
											}
										}}
										disabled={
											(!phaseTitle || phaseTitleError ? true : false) ||
											disableSaveAndContinue
										}
										className="forwardButton"
									>
										<span className="saveContinueButton">
											Save & Continue <Icon iconName="ChromeBackMirrored" />
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default CreatePhase;
