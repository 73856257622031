import React, { useState ,useEffect} from 'react';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';

import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import $ from "jquery";

import './AnnouncementFlyOut.css';

function AnnouncementFlyOut() {
	const { axiosGetService } = useAxios();
	const [showSharePointBanner, setShowSharePointBanner] = useState(false);
	const getBannerStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=ShowHomePageBanner`).then(
			(response) => {				
				if(response.data[0].value === 'true'){
					setShowSharePointBanner(true);
					}
			}
		);
	};

	useEffect(() => {
		getBannerStatus();
		if(showSharePointBanner){
		$('.announcementFlyoutContainer').css('margin-top', '2%');
		}
	}, [showSharePointBanner]);
	const { track } = useAppInsights();
	const history = useHistory();
	const {
		appState,
		setAppState,
		pendingAnnouncements,
		setPendingAnnouncements,
		getNotifications,
		getNewAnnouncements,
	} = useLeftMenuCollapse();
	const { rightPanelContent } = appState;
	const { axiosPostService } = useAxios();
	const [showAnnouncementChild, setShowAnnouncementChild] = useState({});

	const handleRightPanel = (id) => {
		const newRightPanelContent = rightPanelContent === id ? null : id;
		setAppState({ ...appState, rightPanelContent: newRightPanelContent });
	};

	const routeToArtifact = (item) => {
		let reviewerPath = '',
			approverPath = '',
			contributorPreviewPath = '',
			reviewerPreviewPath = '';

		if (item.itemTypeId === 2) {
			reviewerPath = `/project/${item.projectTypeId}/method/${item.solutionMethodId}/phase/${item.phaseId}/parentPhase/null/activity/${item.activityId}/reviewer/edit?complexity=${item.complexityId}&identifier=${item.activityIdentifier}`;
			approverPath = `/project/${item.projectTypeId}/method/${item.solutionMethodId}/phase/${item.phaseId}/parentPhase/null/activity/${item.activityId}/approver/edit?complexity=${item.complexityId}&identifier=${item.activityIdentifier}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/activity/${item.id}/${item.activityId}/1?complexity=${item.complexityId}`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/activity/${item.id}/${item.activityId}/2?complexity=${item.complexityId}`;
		} else if (item.itemTypeId === 10) {
			reviewerPath = `/topics/reviewer/edit/${
				item.topicId
			}`;
			approverPath = `/topics/approver/edit/${
				item.topicId
			}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/topics/${item.id}/${item.topicId}/1`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/topics/${item.id}/${item.topicId}/2`;
		} else if (item.itemTypeId === 44 || item.itemTypeId === 1) {
			reviewerPath = `/template/crowdsourcing/review/${item.templateId}/${item.itemTypeId}/${item.complexityId}/${item.languageId}`;
			approverPath = `/template/crowdsourcing/approve/${item.templateId}/${item.itemTypeId}/${item.complexityId}/${item.languageId}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/template/${item.id}/-1/1`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/template/${item.id}/-1/2`;
		}

		if (item.actionTypeId === 1) {
			history.push(reviewerPath);
		} else if (item.actionTypeId === 2) {
			history.push(approverPath);
		} else if (
			item.actionTypeId === 4 ||
			item.actionTypeId === 6 ||
			item.actionTypeId === 7
		) {
			history.push(contributorPreviewPath);
		} else if (item.actionTypeId === 5 || item.actionTypeId === 8) {
			history.push(reviewerPreviewPath);
		}
	};

	const resetFlyout = (count) => {
		setPendingAnnouncements({
			data: {
				features: null,
				announcements: null,
				pendingApprovals: {},
				pendingReviews: {},
				approvedActivities: [],
				rejectedActivities: [],
				approvedTopics: [],
				rejectedTopics: [],
				approvedTemplates: [],
				rejectedTemplates: [],
			},
			count: count,
		});
	};

	const updateAnnouncements = async (
		id,
		itemTypeId,
		itemId,
		notificationType,
		allRead,
		individualRead
	) => {
		await axiosPostService(
			`api/sdmuser/updateflyout?id=${id}&itemTypeId=${itemTypeId}&itemId=${itemId}&notificationType=${notificationType}&allRead=${allRead}`,
			{}
		).then((response) => {
			if (response.data.status && !individualRead) {
				resetFlyout(itemTypeId ? pendingAnnouncements.count : 0);
				getNotifications();
			} else if (individualRead) {
				getNewAnnouncements();
			} else {
				toast.error('Oops! We are having trouble in updating your flyout', {
					position: 'top-right',
				});
			}
		});
	};

	const getSum = (activities, topics, templates) => {
		return activities + topics + templates;
	};

	const showFlyout = () => {
		return (
			(pendingAnnouncements.data && pendingAnnouncements.data.features) ||
			pendingAnnouncements.data.announcements ||
			(pendingAnnouncements.data.pendingApprovals &&
				pendingAnnouncements.data.pendingApprovals.count > 0) ||
			(pendingAnnouncements.data.pendingReviews &&
				pendingAnnouncements.data.pendingReviews.count > 0) ||
			(pendingAnnouncements.data.approvedActivities &&
				pendingAnnouncements.data.approvedActivities.length > 0) ||
			(pendingAnnouncements.data.approvedTemplates &&
				pendingAnnouncements.data.approvedTemplates.length > 0) ||
			(pendingAnnouncements.data.approvedTopics &&
				pendingAnnouncements.data.approvedTopics.length > 0)
		);
	};

	return (
		<div className="announcementFlyoutContainer">
			{showFlyout() ? (
				<>
					<div className="announcementFlyoutTitleClose">
						<span tabIndex={0} title="What's new">
							WHAT'S NEW
						</span>
						<span
							className="markAsAllReadDiv"
							tabIndex={0}
							onClick={() => {
								updateAnnouncements(0, 0, 0, 0, true, false);
								track(1, 15006, 'flyout', 'Mark All As Read');
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									updateAnnouncements(0, 0, 0, 0, true, false);
									track(1, 15006, 'flyout', 'Mark All As Read');
								}
							}}
							aria-label="MARK AS ALL READ"
							role="button"
						>
							<span>MARK AS ALL READ</span>
							<span className="markAsAllReadIcon"></span>
						</span>
						<span
							className="dismissAllDiv"
							onClick={() => {
								resetFlyout();
								track(1, 15004, 'flyout', 'Flyout Close');
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									resetFlyout();
									track(1, 15004, 'flyout', 'Flyout Close');
								}
							}}
							tabIndex="0"
							aria-label="HIDE ALL"
							role="button"
						>
							<span>HIDE ALL</span>
							<Icon iconName="Cancel" />
						</span>
					</div>

					<ul>
						{pendingAnnouncements.data.features && (
							<li>
								<div
									onClick={() => {
										handleRightPanel('whatsNew');
										updateAnnouncements(0, 0, 0, 0, false, false);
										track(1, 15003, 'flyout', 'Feature Flyout');
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleRightPanel('whatsNew');
											updateAnnouncements(0, 0, 0, 0, false, false);
											track(1, 15003, 'flyout', 'Feature Flyout');
										}
									}}
									tabIndex={0}
									role="button"
									aria-label="expand to see Pending Announcements"
								>
									<div>
										<span className="announcementIconDiv"></span>
										<span>{pendingAnnouncements.data.features.title}</span>
									</div>
									<span className="announcementUpdate">Feature</span>
								</div>
							</li>
						)}

						{pendingAnnouncements.data.announcements && (
							<li>
								<div
									onClick={() => {
										handleRightPanel('whatsNew');
										updateAnnouncements(0, 0, 0, 0, false, false);
										track(1, 15002, 'flyout', 'Announcement Flyout');
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleRightPanel('whatsNew');
											updateAnnouncements(0, 0, 0, 0, false, false);
											track(1, 15002, 'flyout', 'Announcement Flyout');
										}
									}}
									tabIndex={0}
									role="button"
									aria-label="expand to see Pending Announcements"
								>
									<div>
										<span className="announcementIconDiv"></span>
										<span>{pendingAnnouncements.data.announcements.title}</span>
									</div>
									<span className="announcementUpdate">Announcement</span>
								</div>
							</li>
						)}

						{(pendingAnnouncements.data.approvedActivities.length > 0 ||
							pendingAnnouncements.data.approvedTopics.length > 0 ||
							pendingAnnouncements.data.approvedTemplates.length > 0) && (
							<li
								onClick={() =>
									setShowAnnouncementChild({
										...showAnnouncementChild,
										approvedContent: showAnnouncementChild['approvedContent']
											? false
											: true,
									})
								}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowAnnouncementChild({
											...showAnnouncementChild,
											approvedContent: showAnnouncementChild['approvedContent']
												? false
												: true,
										});
									}
								}}
								tabIndex={0}
								aria-label="Click to see Pending Announcements"
							>
								<div>
									<div>
										<span className="approvedAnnouncementIconDiv"></span>
										<span>{`Congrats! Your contributions have been approved`}</span>
									</div>
									<span>
										<span className="announcementUpdate">Notification</span>
										<span className="announcementCount">
											<span>
												{getSum(
													pendingAnnouncements.data.approvedActivities.length,
													pendingAnnouncements.data.approvedTopics.length,
													pendingAnnouncements.data.approvedTemplates.length
												)}
											</span>
										</span>
										<Icon iconName="ChevronDown" />
									</span>
								</div>
								{showAnnouncementChild['approvedContent'] && (
									<>
										<div className="announcementDivChild approvedContributions">
											<ul>
												{pendingAnnouncements.data.approvedTopics.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			tabIndex="0"
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Topics -`}
																			<div className="notificationEntityName">
																				<span title={item.topicName}>
																					&nbsp;{item.topicName}
																				</span>
																			</div>
																		</span>
																		<span
																			tabIndex="0"
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}

												{pendingAnnouncements.data.approvedActivities.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex="0"
																		>
																			{`Activity -`}
																			<div className="notificationEntityName">
																				<span title={item.activityName}>
																					&nbsp;{item.activityName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex="0"
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}

												{pendingAnnouncements.data.approvedTemplates.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex="0"
																		>
																			{`Template -`}
																			<div className="notificationEntityName">
																				<span title={item.title}>
																					&nbsp;{item.title}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex="0"
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
											</ul>
										</div>
									</>
								)}
							</li>
						)}

						{(pendingAnnouncements.data.rejectedActivities.length > 0 ||
							pendingAnnouncements.data.rejectedTopics.length > 0 ||
							pendingAnnouncements.data.rejectedTemplates.length > 0) && (
							<li
								onClick={() =>
									setShowAnnouncementChild({
										...showAnnouncementChild,
										rejectedContent: showAnnouncementChild['rejectedContent']
											? false
											: true,
									})
								}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowAnnouncementChild({
											...showAnnouncementChild,
											rejectedContent: showAnnouncementChild['rejectedContent']
												? false
												: true,
										});
									}
								}}
								tabIndex={0}
								aria-label="Click to see Pending Announcements"
							>
								<div>
									<div>
										<span className="rejectedAnnouncementIconDiv"></span>
										<span>{`Sorry! Your contributions have been rejected`}</span>
									</div>
									<span>
										<span className="announcementUpdate">Notification</span>
										<span className="announcementCount">
											<span>
												{getSum(
													pendingAnnouncements.data.rejectedActivities.length,
													pendingAnnouncements.data.rejectedTopics.length,
													pendingAnnouncements.data.rejectedTemplates.length
												)}
											</span>
										</span>
										<Icon iconName="ChevronDown" />
									</span>
								</div>
								{showAnnouncementChild['rejectedContent'] && (
									<>
										<div className="announcementDivChild rejectedContributions">
											<ul>
												{pendingAnnouncements.data.rejectedTopics.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																		>
																			{`Topic -`}
																			<div className="notificationEntityName">
																				<span title={item.topicName}>
																					&nbsp;{item.topicName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
												{pendingAnnouncements.data.rejectedActivities.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																		>
																			{`Activity -`}
																			<div className="notificationEntityName">
																				<span title={item.activityName}>
																					&nbsp;{item.activityName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
												{pendingAnnouncements.data.rejectedTemplates.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																		>
																			{`Template -`}
																			<div className="notificationEntityName">
																				<span title={item.title}>
																					&nbsp;{item.title}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			tabIndex={0}
																			aria-label="Click to see Pending Announcements"
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
											</ul>
										</div>
									</>
								)}
							</li>
						)}

						{pendingAnnouncements.data.pendingReviews.count > 0 && (
							<li
								onClick={() =>
									setShowAnnouncementChild({
										...showAnnouncementChild,
										pendingReviews: showAnnouncementChild['pendingReviews']
											? false
											: true,
									})
								}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowAnnouncementChild({
											...showAnnouncementChild,
											pendingReviews: showAnnouncementChild['pendingReviews']
												? false
												: true,
										});
									}
								}}
								tabIndex={0}
								aria-label="Click to see Pending Announcements"
							>
								<div>
									<div>
										<span className="pendingAnnouncementIconDiv"></span>
										<span>{`You have contributions waiting for you to be reviewed`}</span>
									</div>
									<span>
										<span className="announcementUpdate">Notification</span>
										<span className="announcementCount">
											<span>
												{pendingAnnouncements.data.pendingReviews.count}
											</span>
										</span>
										<Icon iconName="ChevronDown" />
									</span>
								</div>
								{showAnnouncementChild['pendingReviews'] && (
									<>
										<div className="announcementDivChild pendingContributions">
											<ul>
												{pendingAnnouncements.data.pendingReviews.topics.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															{' '}
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Topic -`}
																			<div className="notificationEntityName">
																				<span title={item.topicName}>
																					&nbsp;{item.topicName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}

												{pendingAnnouncements.data.pendingReviews.activities.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Activity -`}
																			<div className="notificationEntityName">
																				<span title={item.activityName}>
																					&nbsp;{item.activityName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}

												{pendingAnnouncements.data.pendingReviews.templates.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Template -`}
																			<div className="notificationEntityName">
																				<span title={item.title}>
																					&nbsp;{item.title}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
											</ul>
										</div>
									</>
								)}
							</li>
						)}

						{pendingAnnouncements.data.pendingApprovals.count > 0 && (
							<li
								onClick={() =>
									setShowAnnouncementChild({
										...showAnnouncementChild,
										pendingApprovals: showAnnouncementChild['pendingApprovals']
											? false
											: true,
									})
								}
							>
								<div>
									<div>
										<span className="pendingAnnouncementIconDiv"></span>
										<span>{`You have contributions waiting for you to be approved`}</span>
									</div>
									<span>
										<span className="announcementUpdate">Notification</span>
										<span className="announcementCount">
											<span>
												{pendingAnnouncements.data.pendingApprovals.count}
											</span>
										</span>
										<Icon iconName="ChevronDown" />
									</span>
								</div>
								{showAnnouncementChild['pendingApprovals'] && (
									<>
										<div className="announcementDivChild pendingContributions">
											<ul>
												{pendingAnnouncements.data.pendingApprovals.topics.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Topic -`}
																			<div className="notificationEntityName">
																				<span title={item.topicName}>
																					&nbsp;{item.topicName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.topicId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.topicId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
												{pendingAnnouncements.data.pendingApprovals.activities.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Activity -`}
																			<div className="notificationEntityName">
																				<span title={item.activityName}>
																					&nbsp;{item.activityName}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.activityId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.activityId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
												{pendingAnnouncements.data.pendingApprovals.templates.map(
													(item) => (
														<li key={uniqueIdGenerator()}>
															<div>
																<div>
																	<span>
																		<span
																			onClick={() => {
																				routeToArtifact(item);
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					false
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					routeToArtifact(item);
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						false
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																		>
																			{`Template -`}
																			<div className="notificationEntityName">
																				<span title={item.title}>
																					&nbsp;{item.title}
																				</span>
																			</div>
																		</span>
																		<span
																			onClick={() => {
																				updateAnnouncements(
																					item.id,
																					item.itemTypeId,
																					item.templateId,
																					item.notificationType,
																					false,
																					true
																				);
																				track(
																					1,
																					15002,
																					'flyout',
																					'Announcement Flyout'
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateAnnouncements(
																						item.id,
																						item.itemTypeId,
																						item.templateId,
																						item.notificationType,
																						false,
																						true
																					);
																					track(
																						1,
																						15002,
																						'flyout',
																						'Announcement Flyout'
																					);
																				}
																			}}
																			className="notificationEntityAsRead"
																		></span>
																	</span>
																</div>
															</div>
														</li>
													)
												)}
											</ul>
										</div>
									</>
								)}
							</li>
						)}
					</ul>
				</>
			) : (
				<></>
			)}
		</div>
	);
}

export default AnnouncementFlyOut;
