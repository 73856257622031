const AddBreadCrumb = (currentCrumbs, newCrumb) => {
  const newCrumbArray = Array.isArray(newCrumb) ? newCrumb : [newCrumb];
  let parentFound = false;

  for (let i = currentCrumbs.length - 1; i >= 0; i -= 1) {
    const crumb = currentCrumbs[i];
    const newCrumbData = Array.isArray(newCrumb) ? newCrumb[0] : newCrumb;

    if (crumb.type === newCrumbData.parent || newCrumbData.parent.includes(crumb.type)) {
      currentCrumbs = currentCrumbs.slice(0, i + 1);
      currentCrumbs = [...currentCrumbs, ...newCrumbArray];
      parentFound = true;
      break;
    }
  }

  if (!parentFound) {
    currentCrumbs = currentCrumbs.slice(0, 1);
    currentCrumbs = [...currentCrumbs, ...newCrumbArray];
  }

  return currentCrumbs;
};

export default AddBreadCrumb;
