import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import dompurify from 'dompurify';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import $ from 'jquery';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/DescriptionTrackChanges';
import DeliverablesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/DeliverablesTrackChanges';
import SupportAndActivitiesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/SupportingActivitieTrackChanges';
import TipsAndTechniquesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/TipsAndTechniquesTrackChanges';
import Dropdown from '../Dropdown';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import FileDropModal from '../TemplateCard/FileDropModal';
import PreviewModal from '../TemplateCard/PreviewModal';
import SampleTemplateModal from '../TemplateCard/SampleTemplateModal';
import RejectionReasonModal from '../ActivityReviewerCrowdSourcing/RejectionReasonModal';
import ActivityApproval from '../CkEditor/ActivityPreviewModeTrackChanges/ActivityApproval';
import CopyToComplexity from './CopyToComplexity';

import '../Activity/Activity.css';
import '../ActivityContributorCrowdSourcing/PreviewAndSubmitPopUp.css';
import { toast } from 'react-toastify';
import ActivityRaciMatrix from '../ActivityRaciMatrix';

function PreviewAndSubmitPopUp({
	activityContent,
	contributorContent,
	activityDescription,
	activitySupportingActivities,
	templateData,
	topicData,
	procedureData,
	activityToolsAndGuidance,
	activityTipsAndTechniques,
	descriptionSuggestions,
	supportingActivitiesSuggestions,
	toolsAndGuidanceSuggestions,
	tipsAndTechniquesSuggestions,
	setShowPreviewAndSubmitPopUp,
	complexities,
	complexityId,
	complexityName,
	setComplexityName,
	users,
	currentUser,
	setLeavingWithoutSave,
	setSubmitMessage,
	approverDescSuggestions,
	approvedDescSuggestions,
	approverSupportSuggestions,
	approvedSupportSuggestions,
	approverToolsSuggestions,
	approvedToolsSuggestions,
	approverTipssSuggestions,
	approvedTipsSuggestions,
	licenseKey,
	selectedToolsAndGuidanceSuggestions,
	toolsUrl,
	messagesType,
	reviewerSuggestionsInfo,
	existingHashtags,
	ratingPoints,
	enableGamification,
	templateSuggestions,
	approvedTemplateSuggestions,
	activityComplexityData,
	selectedComplexityTab,
	setSelectedComplexityTa,
	complexitySectionModal,
	setComplexitySectionModal,
	publishComplexity,
	setPublishComplexity,
	publishComplexityEntity,
	setPublishComplexityEntity,
	setSelectedComplexityTab,
	approverEntryCriteriaSuggestions,
	approvedEntryCriteriaSuggestions,
	approverExitCriteriaSuggestions,
	approvedExitCriteriaSuggestions,
	entryCriteriaWithSuggestions,
	exitCriteriaWithSuggestions,
	entryCriteriaData,
	exitCriteriaData,
	entryCriteriaSuggestions,
	exitCriteriaSuggestions,
	approverTaskSuggestions,
	approvedTaskSuggestions,
	taskWithSuggestions,
	taskData,
	taskSuggestions,
	activityDeliverables,
	deliverablesSuggestions,
	approverDeliverablesSuggestions,
	approvedDeliverablesSuggestions,
	isApproverTaskReordered, 
    isApproverEntryReordered,
    isApproverInputReordered,
    isApproverExitReordered,
    isApproverOutputReordered,
	taskReorderArray,
	exitCriteriaReorderArray,
	entryCriteriaReorderArray,
	inputCriteriaReorderArray,
	outputCriteriaReorderArray,
	reviewedReorderedTaskSuggestions,
	reviewedReorderedEntrySuggestions,
	reviewedReorderedExitSuggestions,
	reviewedReorderedInputSuggestions,
	reviewedReorderedOutputSuggestions,
    reorderedTaskRejectionCardInfo,
    reorderedEntryRejectionCardInfo,
    reorderedInputRejectionCardInfo,
    reorderedExitRejectionCardInfo,
    reorderedOutputRejectionCardInfo,
}) {
	const copyToComplexityItems = [
		{
			id: 1,
			name: 'All',
		},
		{ id: 2, name: 'Description' },
		{ id: 3, name: 'Supporting Activities' },
		{ id: 4, name: 'Guidance, Tips and Techniques' },
		{ id: 5, name: 'Deliverables' },
		{ id: 6, name: 'Tasks' },
		{ id: 7, name: 'Entry Criteria' },
		{ id: 8, name: 'Exit Criteria' }
	];
	const history = useHistory();
	const htmlSanitizer = (data) => {
		const config = {
		  ADD_ATTR: [ 'target'],
		  };
		return dompurify.sanitize(data, config);
	  };
	const { axiosPostService, axiosGetService } = useAxios();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { activityId } = useParams();
	const { track } = useAppInsights();
	const [modalShow, setModalShow] = useState(true);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState(activityDescription);
	const [previewActivityDeliverables, setPreviewActivityDeliverables] =
		useState(activityDeliverables);
	const [
		previewActivitySupportingActivities,
		setPreviewActivitySupportingActivities,
	] = useState(activitySupportingActivities);
	const [
		previewActivityTipsAndTechniques,
		setpreviewActivityTipsAndTechniques,
	] = useState(activityTipsAndTechniques);
	const [sampleTemplates, setSampleTemplates] = useState({
		enable: false,
		data: null,
		parentData: null,
	});
	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);
	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};
	const [showLoader, setShowLoader] = useState(false);
	const [showRejectionReasonModal, setShowRejectionReasonModal] =
		useState(false);
	const [rejectionComments, setRejectionComments] = useState({});
	const [usersRating, setUsersRating] = useState({});
	const [reorderRejectionComments, setReorderRejectionComments] = useState({task:{},entryCriteria:{},exitCrteria:{},inputCriteria:{},outputCriteria:{}})


	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
		setShowRejectionReasonModal(false);
	};
	const setUpdateEntity = (complexity, item) => {
		const updateItem = {};
		if (item.id === 1) {
			for (const complexityItem of copyToComplexityItems) {
				updateItem[complexityItem.name] = publishComplexityEntity[
					complexity.name
				]?.['All']
					? false
					: true;
			}
		} else {
			for (const complexityItem of copyToComplexityItems) {
				if (complexityItem.id === item.id) {
					updateItem[complexityItem.name] =
						!publishComplexityEntity[complexity.name]?.[item.name];
				} else {
					updateItem[complexityItem.name] =
						publishComplexityEntity[complexity.name]?.[complexityItem.name];
				}
			}
			let countCheckedEntity = 0;
			const complexityEntityValues = Object.values(updateItem);
			const complexityEntityLength =
				Object.keys(copyToComplexityItems).length - 1;
			for (const value of complexityEntityValues) {
				if (value) {
					countCheckedEntity++;
				}
			}
			updateItem['All'] =
				complexityEntityLength === countCheckedEntity &&
					!publishComplexityEntity[complexity.name]?.['All']
					? true
					: false;
		}
		setPublishComplexityEntity({
			...publishComplexityEntity,
			[complexity.name]: { ...updateItem },
		});
	};
	const updateSuggestionsArray = (allSuggestions, rejectedSuggestions) => {
		if (allSuggestions.length === 0) {
			return null;
		}
		if (rejectedSuggestions.length === 0) {
			return allSuggestions;
		} else {
			const updatedSuggestions = [];
			for (const data in allSuggestions) {
				let suggestionsRejected = false;
				for (const rejectId in rejectedSuggestions) {
					if (
						JSON.parse(allSuggestions[data]).id ===
						JSON.parse(rejectedSuggestions[rejectId]).id
					) {
						if (
							JSON.parse(rejectedSuggestions[rejectId]).state === 'rejected'
						) {
							suggestionsRejected = true;
							break;
						}
					}
				}
				if (!suggestionsRejected) {
					updatedSuggestions.push(allSuggestions[data]);
				}
			}
			if (updatedSuggestions.length > 0) {
				return updatedSuggestions;
			} else {
				return null;
			}
		}
	};

	const getToolsCount = () => {
		let toolsCount = 0;
		for (const tool of selectedToolsAndGuidanceSuggestions) {
			if (JSON.parse(tool).suggestionType !== 'deletion') {
				toolsCount += 1;
			}
		}
		return toolsCount;
	};

	const getApprovedSuggestions = (reviewerSuggestions, approvedSuggestions) => {
		if (reviewerSuggestions) {
			const suggestionsArray = [];
			for (const revId in reviewerSuggestions) {
				let foundApprovedId = false;
				for (const apprvId in approvedSuggestions) {
					if (
						JSON.parse(approvedSuggestions[apprvId]).id ===
						JSON.parse(reviewerSuggestions[revId]).id
					) {
						if (JSON.parse(approvedSuggestions[apprvId]).state !== 'open') {
							foundApprovedId = true;
							suggestionsArray.push(approvedSuggestions[apprvId]);
							break;
						}
					}
				}
				if (!foundApprovedId) {
					suggestionsArray.push(
						JSON.stringify({
							id: JSON.parse(reviewerSuggestions[revId]).id,
							state: 'accepted',
						})
					);
				}
			}
			if (suggestionsArray.length > 0) {
				return suggestionsArray;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const getToolsApprovedSuugestions = (
		reviewerSuggestions,
		approvedSuggestions
	) => {
		if (reviewerSuggestions) {
			const suggestionsArray = [];
			for (const revId in reviewerSuggestions) {
				let foundApprovedId = false;
				for (const apprvId in approvedSuggestions) {
					if (
						JSON.parse(approvedSuggestions[apprvId]).id ===
						JSON.parse(reviewerSuggestions[revId]).id
					) {
						foundApprovedId = true;
						suggestionsArray.push(approvedSuggestions[apprvId]);
						break;
					}
				}
				if (!foundApprovedId) {
					suggestionsArray.push(
						JSON.stringify({
							id: JSON.parse(reviewerSuggestions[revId]).id,
							state: 'accepted',
						})
					);
				}
			}
			if (suggestionsArray.length > 0) {
				return suggestionsArray;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const getToolsAndGuidanceSuggestion = () => {
		const updatedSuggestion = selectedToolsAndGuidanceSuggestions.filter(
			(suggestion) =>
				JSON.parse(suggestion).suggestionType !== 'none' &&
				JSON.parse(suggestion).authorId === currentUser &&
				JSON.parse(suggestion).createdBy === 'approver'
		);
		return updatedSuggestion;
	};

	const getToolsAndGuidanceId = () => {
		const id = [];
		for (const key in selectedToolsAndGuidanceSuggestions) {
			if (
				JSON.parse(selectedToolsAndGuidanceSuggestions[key]).suggestionType !==
				'deletion'
			) {
				id.push(
					JSON.stringify(
						JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId
					)
				);
			}
		}
		return id.toString();
	};

	const getToolsAndGuidanceForComplexityModal = () => {
		const tools = selectedToolsAndGuidanceSuggestions.filter(
			(tool) => JSON.parse(tool).suggestionType !== 'deletion'
		);

		return tools;
	};

	const getTemplateIds = () => {
		let templates = [];
		if (templateData) {
			templates = [
				...templateData.map(({ id }) => {
					return id;
				}),
			];
		}
		if (templateSuggestions) {
			templates = [
				...templates,
				...templateSuggestions.map((item) => {
					return JSON.parse(item).id;
				}),
			];
		}
		return templates.toString();
	};
	const getTemplatesIdsForComplexity = (complexityTemplateData) => {
		let templates = [];
		if (complexityTemplateData) {
			templates = [
				...templateData.map(({ id }) => {
					return id;
				}),
			];
		}
		return templates.toString();
	};

	const getTemplatesForComplexityModal = () => {
		let templates = [];
		if (templateData) {
			templates = [...templateData];
		}
		if (templateSuggestions) {
			templates = [
				...templates,
				...templateSuggestions.map((item) => {
					return { ...JSON.parse(item), title: JSON.parse(item).name };
				}),
			];
		}
		return templates;
	};

	const getApproverTemplateSuggestions = () => {
		let updatedSuggestions = [];
		if (templateSuggestions) {
			updatedSuggestions = templateSuggestions.filter(
				(item) => JSON.parse(item).addedBy === 'approver'
			);
		}

		return updatedSuggestions.length > 0 ? updatedSuggestions : null;
	};

	const getEntryCriteriaIds = () => {
		let entryCriteria = [];
		if (entryCriteriaData) {
			entryCriteria = [
				...entryCriteriaData.filter((item) => !entryCriteriaSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map(({ id }) => {
					return id;
				}),
			];
		}
		if (entryCriteriaSuggestions) {
			entryCriteria = [
				...entryCriteria,
				...entryCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return JSON.parse(item).id;
					}),
			];
		}
		return entryCriteria.toString();
	};
	const getEntryCriteriaData = () => {
		let entryCriteria = [];
		if (entryCriteriaData) {
			entryCriteria = [
				...entryCriteriaData.filter((item) => !entryCriteriaSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map((item) => {
					return JSON.stringify(item);
				}),
			];
		}
		if (entryCriteriaSuggestions) {
			entryCriteria = [
				...entryCriteria,
				...entryCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((value) => {

						return JSON.stringify({
							id: JSON.parse(value).id,
							name: JSON.parse(value).name,
							criteriaType: JSON.parse(value).criteriaType,
							valueType: JSON.parse(value).valueType,
							createdDate: JSON.parse(value).createdDate,
							createdBy: JSON.parse(value).authorId,
							criteriaTypeID: JSON.parse(value).criteriaTypeID,
							criteriaTypeName: JSON.parse(value).criteriaTypeName,
							complexityId: JSON.parse(value).complexityId
						});
					}
					)
			];
		}
		return entryCriteria.toString();
	};
	const getApproverEntryCriteriaSuggestions = () => {
		let updatedSuggestions = [];
		if (entryCriteriaSuggestions) {
			updatedSuggestions = entryCriteriaSuggestions.filter(
				(item) => JSON.parse(item).authorId === currentUser && JSON.parse(item).suggestionType !== 'none'
			);
		}

		return updatedSuggestions.length > 0 ? updatedSuggestions : null;
	};


	const getExitCriteriaIds = () => {
		let exitCriteria = [];
		if (exitCriteriaData) {
			exitCriteria = [
				...exitCriteriaData.filter((item) => !exitCriteriaSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map(({ id }) => {
					return id;
				}),
			];
		}
		if (exitCriteriaSuggestions) {
			exitCriteria = [
				...exitCriteria,
				...exitCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return JSON.parse(item).id;
					}),
			];
		}
		return exitCriteria.toString();
	};
	const getExitCriteriaData = () => {
		let exitCriteria = [];
		if (exitCriteriaData) {
			exitCriteria = [
				...exitCriteriaData.filter((item) => !exitCriteriaSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map((item) => {
					return JSON.stringify(item);
				}),
			];
		}
		if (exitCriteriaSuggestions) {
			exitCriteria = [
				...exitCriteria,
				...exitCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((value) => {

						return JSON.stringify({
							id: JSON.parse(value).id,
							name: JSON.parse(value).name,
							criteriaType: JSON.parse(value).criteriaType,
							valueType: JSON.parse(value).valueType,
							createdDate: JSON.parse(value).createdDate,
							createdBy: JSON.parse(value).authorId,
							criteriaTypeID: JSON.parse(value).criteriaTypeID,
							criteriaTypeName: JSON.parse(value).criteriaTypeName,
							complexityId: JSON.parse(value).complexityId
						});
					}
					)
			];
		}
		return exitCriteria.toString();
	};
	const getApproverExitCriteriaSuggestions = () => {
		let updatedSuggestions = [];
		if (exitCriteriaSuggestions) {
			updatedSuggestions = exitCriteriaSuggestions.filter(
				(item) => JSON.parse(item).authorId === currentUser && JSON.parse(item).suggestionType !== 'none'
			);
		}
		return updatedSuggestions.length > 0 ? updatedSuggestions : null;
	};


	const getTaskIds = () => {
		let task = [];
		if (taskData) {
			task = [
				...taskData.filter((item) => !taskSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map(({ id }) => {
					return id;
				}),
			];
		}
		if (taskSuggestions) {
			task = [
				...task,
				...taskSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return JSON.parse(item).id;
					}),
			];
		}
		return task.toString();
	};
	const getTaskData = () => {
		let task = [];
		if (taskData) {
			task = [
				...taskData.filter((item) => !taskSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				)).map((item) => {
					return JSON.stringify(item);
				}),
			];
		}
		if (taskSuggestions) {
			task = [
				...task,
				...taskSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((value) => {

						return JSON.stringify({
							id: JSON.parse(value).id,
							name: JSON.parse(value).name,
							valueType: JSON.parse(value).valueType,
							createdDate: JSON.parse(value).createdDate,
							createdBy: JSON.parse(value).authorId,
							criteriaTypeID: JSON.parse(value).criteriaTypeID,
							complexityId: JSON.parse(value).complexityId,
							sequence: taskData.length+1,
						});
					}
					)
			];
			task.map((e,i)=>{
				return JSON.parse(e).sequence = i + 2;
				
			  })
			  
		}
		return task.toString();
	};
	const getTaskDataForComplexityModal = () => {

		let task = [];
		if (taskData) {
			task = [
				...taskData.filter((item) => !taskSuggestions.some(
					(value) => item.id === JSON.parse(value).id
				))
			];
		}
		if (taskSuggestions) {
			task = [
				...task,
				...taskSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return { ...JSON.parse(item) };
					}),

			];
		}
		return task;

	};
	const getEntryCriteriaDataForComplexityModal = () => {

		let criteria = [];
		if (entryCriteriaData) {
			criteria = [...entryCriteriaData.filter((item) => !entryCriteriaSuggestions.some(
				(value) => item.id === JSON.parse(value).id
			))
			];
		}
		if (entryCriteriaSuggestions) {
			criteria = [
				...criteria,
				// ...entryCriteriaSuggestions.map((item) => {
				// 	return { ...JSON.parse(item), title: JSON.parse(item).name };
				// }),
				...entryCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return { ...JSON.parse(item) };
					}),

			];

		}
		return criteria;

	};
	const getExitCriteriaDataForComplexityModal = () => {
		let criteria = [];
		if (exitCriteriaData) {
			criteria = [...exitCriteriaData.filter((item) => !exitCriteriaSuggestions.some(
				(value) => item.id === JSON.parse(value).id
			))
			];
		}
		if (exitCriteriaSuggestions) {
			criteria = [
				...criteria,
				// ...exitCriteriaSuggestions.map((item) => {
				// 	return { ...JSON.parse(item), title: JSON.parse(item).name };
				// }),
				...exitCriteriaSuggestions.filter((item) =>
					JSON.parse(item).suggestionType === 'updation' || JSON.parse(item).suggestionType === 'insertion').map((item) => {
						return { ...JSON.parse(item) };
					}),

			];

		}
		return criteria;

	};

	const getApproverTaskSuggestions = () => {
		let updatedSuggestions = [];
		if (taskSuggestions) {
			updatedSuggestions = taskSuggestions.filter(
				(item) => JSON.parse(item).authorId === currentUser && JSON.parse(item).suggestionType !== 'none'
			);
		}
		return updatedSuggestions.length > 0 ? updatedSuggestions : null;
	};



	const verifyContribution = (suggestionIds, type) => {
		if(suggestionIds[type]){
		const rejectedSuggestionsIds = suggestionIds
			.filter(
				(suggestion) =>
					JSON.parse(suggestion).state === 'rejected' ||
					JSON.parse(suggestion).state === 'accepted'
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const contributedSuggestionsIds = Object.keys(
			reviewerSuggestionsInfo[type]
		).map((suggestion) => {
			return reviewerSuggestionsInfo[type][suggestion].id;
		});
		const contributorsRejectedIds = contributedSuggestionsIds.filter((id) =>
			rejectedSuggestionsIds.includes(id)
		);
		return contributorsRejectedIds.length > 0 ? true : false;
	}return false;
	};

	    const checkContribution = (requestType) => {
		const reviewStatus =
			verifyContribution(approvedDescSuggestions, 'description') ||
			verifyContribution(approvedSupportSuggestions, 'supportingActivities') ||
			verifyContribution(approvedToolsSuggestions, 'toolsAndGuidance') ||
			verifyContribution(approvedTipsSuggestions, 'tipsAndTechniques') ||
			verifyContribution(approvedTemplateSuggestions, 'templates') ||
			verifyContribution(approvedEntryCriteriaSuggestions, 'entryCriteria') ||
			verifyContribution(approvedExitCriteriaSuggestions, 'exitCriteria') ||
			verifyContribution(approvedTaskSuggestions, 'task') ||
			verifyContribution(approvedDeliverablesSuggestions, 'deliverables');
		const reviewersSuggestions =
			contributorContent.resultReviewedJson.description ||
			contributorContent.resultReviewedJson.supportingActivities ||
			contributorContent.resultReviewedJson.tipsAndTechniques ||
			contributorContent.resultReviewedJson.toolsAndGuidance ||
			contributorContent.resultReviewedJson.templateSuggestions ||
			contributorContent.resultReviewedJson.entryCriteriaSuggestions ||
			contributorContent.resultReviewedJson.exitCriteriaSuggestions ||
			contributorContent.resultReviewedJson.taskSuggestions ||
			contributorContent.resultReviewedJson.deliverables 
	    const reviewReorderStatus =
		    JSON.parse(contributorContent.reorderJson)?.isTaskReorder ||
			JSON.parse(contributorContent.reorderJson)?.isEntryReorder ||
			JSON.parse(contributorContent.reorderJson)?.isInputReorder ||
			JSON.parse(contributorContent.reorderJson)?.isExitReorder  ||
			JSON.parse(contributorContent.reorderJson)?.isOutputReorder
			if (requestType === 'submit') {
			if (reviewStatus || (reviewersSuggestions && users.length > 1) || reviewReorderStatus) {
				setShowRejectionReasonModal(true);
			} else {
				 submitData();
			}
		} else {
			if (reviewStatus) {
				return true;
			}
			return false;
		}
	};

	const checkValidEntitySelection = () => {
		const complexitiesName = complexities
			.filter(({ id }) => publishComplexity.includes(id))
			.map(({ name }) => {
				return name;
			});
		let errorMessage = false;
		for (const complexity of complexitiesName) {
			const isValid =
				publishComplexityEntity[complexity]?.['Description'] ||
				publishComplexityEntity[complexity]?.['Supporting Activities'] ||
				publishComplexityEntity[complexity]?.[
				'Guidance, Tips and Techniques'
				] ||
				publishComplexityEntity[complexity]?.['Deliverables']
				|| publishComplexityEntity[complexity]?.['Tasks']
				|| publishComplexityEntity[complexity]?.['Entry Criteria']
				|| publishComplexityEntity[complexity]?.['Exit Criteria'];
			if (!isValid) {
				toast.error(
					`You should select at least one entity to copy for ${complexity} complexity`,
					{
						position: 'top-right',
					}
				);
				errorMessage = true;
			}
		}
		return errorMessage;
	};
	const destinationComplexityPostData = () => {
		const complexitiesName = complexities
			.filter(({ id }) => publishComplexity.includes(id))
			.map(({ name }) => {
				return name;
			});
		const complexityPostData = [];
		for (const complexity of complexitiesName) {
			const data = {
				activityId: activityId,
				complexityId: complexities
					.filter(({ name }) => complexity === name)
					.map(({ id }) => {
						return id;
					})[0],
				description: publishComplexityEntity[complexity]?.['Description']
					? activityDescription
						? previewActivityDescription
						: null
					: activityComplexityData[complexity].activityDescription,
				supportingActivities: publishComplexityEntity[complexity]?.[
					'Supporting Activities'
				]
					? activitySupportingActivities
						? previewActivitySupportingActivities
						: null
					: activityComplexityData[complexity].activitySupportingActivities,
				tipsAndTechniques: publishComplexityEntity[complexity]?.[
					'Guidance, Tips and Techniques'
				]
					? activityTipsAndTechniques
						? previewActivityTipsAndTechniques
						: null
					: activityComplexityData[complexity].activityTipsAndTechniques,
				templateIds: publishComplexityEntity[complexity]?.['Deliverables']
					? getTemplateIds()
						? getTemplateIds()
						: null
					: getTemplatesIdsForComplexity(
						activityComplexityData[complexity].activityTemplateDetails
					),
				copyToTask: publishComplexityEntity[complexity]?.['Tasks'] ? true : false,
				copyToEntryCriteria: publishComplexityEntity[complexity]?.['Entry Criteria'] ? true : false,
				copyToExitCriteria: publishComplexityEntity[complexity]?.['Exit Criteria'] ? true : false,
				deliverables: publishComplexityEntity[complexity]?.['Deliverables']
					? activityDeliverables
						? previewActivityDeliverables
						: null
					: activityComplexityData[complexity].activityDeliverables,
				sequence: activityComplexityData[complexity].sequence,
				isCritical: activityComplexityData[complexity].isCritical,
				tileDescription: activityComplexityData[complexity].tileDescription,
				activityVersion: activityComplexityData[complexity].activityVersion,
				//deliverables: activityComplexityData[complexity].activityDeliverables,
				isLite: activityComplexityData[complexity].isLite,
				isFull: activityComplexityData[complexity].isFull,
				url: activityComplexityData[complexity].url,
				isProcessStep: activityComplexityData[complexity].isProcessStep,
			};
			complexityPostData.push(data);
		}
		return complexityPostData;
	};

	const postData = {
		activityId: activityId,
		complexityId: complexityId,
		description: activityDescription,
		supportingActivities: activitySupportingActivities,
		toolsAndGuidance: getToolsAndGuidanceId(),
		tipsAndTechniques: activityTipsAndTechniques,
		tileDescription: contributorContent.tileDescription,
		isCritical: contributorContent.isCritical,
		isProcessStep: contributorContent.isProcessStep,
		isLite: contributorContent.isLite,
		isFull: contributorContent.isFull,
		sequence: contributorContent.sequence,
		//deliverables: contributorContent.deliverables,
		url: contributorContent.url,
		activityVersion: contributorContent.activityVersion,
		modifiedOn: contributorContent.modifiedOn,
		reviewerEmail: contributorContent.reviewerEmail,
		contributorCalloutJson: contributorContent.calloutJson,
		contributorReviewedJson: contributorContent.reviewedSuggestionsJson,
		reviewerCalloutJson: contributorContent.reviewerCalloutJson,
		reviewerReviewedJson: contributorContent.reviewerReviewedJson,
		activityName: activityContent.activityName,
		deliverables: activityDeliverables,
		publishActivityContent: {
			description: activityDescription ? previewActivityDescription : null,
			supportingActivities: activitySupportingActivities
				? previewActivitySupportingActivities
				: null,
			toolsAndGuidance: getToolsAndGuidanceId(),
			tipsAndTechniques: activityTipsAndTechniques
				? previewActivityTipsAndTechniques
				: null,
			deliverables: activityDeliverables ? previewActivityDeliverables : null,
		},
		resultReviewedJson: {
			approvedDescSuggestions: null,
			approvedSupportSuggestions: null,
			approvedToolsSuggestions: null,
			approvedTipsSuggestions: null,
			approvedTemplateSuggestions: null,
			approvedEntryCriteriaSuggestions: null,
			approvedExitCriteriaSuggestions: null,
			approvedTaskSuggestions: null,
			approvedDeliverablesSuggestions: null,
		},
		resultCalloutJson: {
			approverDescSuggestions: updateSuggestionsArray(
				approverDescSuggestions,
				approvedDescSuggestions
			),
			approverSupportSuggestions: updateSuggestionsArray(
				approverSupportSuggestions,
				approvedSupportSuggestions
			),
			approverTipsSuggestions: updateSuggestionsArray(
				approverTipssSuggestions,
				approvedTipsSuggestions
			),
			approverToolsSuggestions: getToolsAndGuidanceSuggestion(),
			approverTemplateSuggestions: getApproverTemplateSuggestions(),
			approverEntryCriteriaSuggestions: getApproverEntryCriteriaSuggestions(),
			approverExitCriteriaSuggestions: getApproverExitCriteriaSuggestions(),
			approverTaskSuggestions: getApproverTaskSuggestions(),
			approverDeliverablesSuggestions: updateSuggestionsArray(
				approverDeliverablesSuggestions,
				approvedDeliverablesSuggestions
			)
		},

		lastContributorActivityContent:
			contributorContent.lastContributorActivityContent,
		templateIds: getTemplateIds(),
		entryCriteriaIds: getEntryCriteriaIds(),
		exitCriteriaIds: getExitCriteriaIds(),
		entryCriteriaData: getEntryCriteriaData(),
		exitCriteriaData: getExitCriteriaData(),
		taskds: getTaskIds(),
		taskData: getTaskData(),
		destinationActivities: destinationComplexityPostData(),
		resultReorderJson:{
			isTaskReorder: taskReorderArray,
			isEntryReorder:entryCriteriaReorderArray,
			isExitReorder: exitCriteriaReorderArray,
			isInputReorder:inputCriteriaReorderArray,
            isOutputReorder:outputCriteriaReorderArray
		}
	};
	$(document).ready(function () {
		$('figure').each(function (index, element) {
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				$(imgElement).attr({ style: $(element).attr('style') });
			}
		});
	});


	const addReviewerComment = (type) => {
		var reviewedSuggestions= [];
		if(reviewerSuggestionsInfo[type] !== undefined && reviewerSuggestionsInfo[type] !== null){
			reviewedSuggestions = Object.keys(
				reviewerSuggestionsInfo[type]
			).map((suggestion) => {
				if(type === 'templates' || type === 'entryCriteria' || type === 'exitCriteria'
			       || type === 'task' || type === 'toolsAndGuidance'){
					return reviewerSuggestionsInfo[type][suggestion];
				}else{
					return JSON.parse(reviewerSuggestionsInfo[type][suggestion]);
				}
				// return JSON.parse(reviewerSuggestionsInfo[type][suggestion]);
			});
		}
		if (rejectionComments[type]) {
			const commentsInfo = Object.keys(rejectionComments[type]).map((id) => {
				const reviewed = reviewedSuggestions.filter((reviewed) => { return reviewed.id == id });
				return JSON.stringify({
					id: id,
					authorId: reviewed[0].authorId,
					data:
						type === 'toolsAndGuidance' || type === 'templates' || type === 'entryCriteria' || type === 'exitCriteria' || type === 'task'
							? reviewed[0].name
							: reviewed[0].data,
					type:
						type === 'toolsAndGuidance' || type === 'entryCriteria' || type === 'exitCriteria' || type === 'task'
							? reviewed[0].suggestionType
							: reviewed[0].type,
					comment: rejectionComments[type][id],
				});
			});
			return commentsInfo;
		}
	};

	const submitData = async () => {

		if (!checkValidEntitySelection()) {
			setShowLoader(true);
			const editingtateId = await axiosGetService(
				`api/sdmactivity/getActivity2/${activityId}/${complexityId}`
			);
			if (editingtateId.data.stateId === 0) {
				setLeavingWithoutSave(false);
				setSubmitMessage(messagesType['publishedDocumentMessage']);
				setCrowdSourcingAppState({
					...crowdSourcingAppState,
					successMessagePopUp: true,
					successMessageComplexityId: complexityId,
					successMessageTitle: activityContent.activityName,
					userLeaveWithSave: true,
				});
				setShowPreviewAndSubmitPopUp(false);
			} else {
				track(1, 202, activityContent.activityId, activityContent.activityName);
				if (editingtateId.data.resultReviewedJson) {
					postData.resultReviewedJson.approvedDescSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.description,
							approvedDescSuggestions
						);
					postData.resultReviewedJson.approvedSupportSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.supportingActivities,
							approvedSupportSuggestions
						);
					postData.resultReviewedJson.approvedTipsSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.tipsAndTechniques,
							approvedTipsSuggestions
						);
					postData.resultReviewedJson.approvedToolsSuggestions =
						getToolsApprovedSuugestions(
							editingtateId.data.resultReviewedJson.toolsAndGuidance,
							approvedToolsSuggestions
						);
					postData.resultReviewedJson.approvedTemplateSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.templateSuggestions,
							approvedTemplateSuggestions
						);
					postData.resultReviewedJson.approvedEntryCriteriaSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.entryCriteriaSuggestions,
							approvedEntryCriteriaSuggestions
						);
					postData.resultReviewedJson.approvedExitCriteriaSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.exitCriteriaSuggestions,
							approvedExitCriteriaSuggestions
						);
					postData.resultReviewedJson.approvedTaskSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.taskSuggestions,
							approvedTaskSuggestions
						);
					postData.resultReviewedJson.approvedDeliverablesSuggestions =
						getApprovedSuggestions(
							editingtateId.data.resultReviewedJson.deliverables,
							approvedDeliverablesSuggestions
						);
				}
				postData.resultApproverComments = {
					description: addReviewerComment('description'),
					supportingActivities: addReviewerComment('supportingActivities'),
					tipsAndTechniques: addReviewerComment('tipsAndTechniques'),
					toolsAndGuidance: addReviewerComment('toolsAndGuidance'),
					templates: addReviewerComment('templates'),
					entryCriteria: addReviewerComment('entryCriteria'),
					exitCriteria: addReviewerComment('exitCriteria'),
					task: addReviewerComment('task'),
					deliverables: addReviewerComment('deliverables'),
				};
				const imagesUrl = sessionStorage.getItem('crowdSourcing')
					? JSON.parse(sessionStorage.getItem('crowdSourcing'))
					: [];
				postData.imagePaths = [...contributorContent.imagePaths, ...imagesUrl];
				postData.ratingPoints = usersRating;
				await axiosPostService(`api/sdmactivity/saveApprover`, postData)
					.then(() => {
						setLeavingWithoutSave(false);
						setSubmitMessage(messagesType['approvalSubmissionMessage']);
						setCrowdSourcingAppState({
							...crowdSourcingAppState,
							successMessagePopUp: true,
							successMessageComplexityId: complexityId,
							successMessageTitle: activityContent.activityName,
							userLeaveWithSave: true,
						});
						sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
						setShowPreviewAndSubmitPopUp(false);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};
   
	const getReviewerInfo = () => {
		return users.filter(({ id }) => id === contributorContent.reviewerEmail);
	};

	const handleComplexityModal = (e) => {
		const targetDocument = document.getElementById('complexityModalSelection');
		if (targetDocument && !targetDocument.contains(e.target)) {
			setComplexitySectionModal('');
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleComplexityModal);
		return () => {
			document.removeEventListener('click', handleComplexityModal);
		};
	}, []);
	
	function removePTags(html) {
    // Replace all <p> tags with an empty string
    return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }

  useEffect(() => {
    $('#previewId').focus();
  }, []);
   
	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					{showRejectionReasonModal ? (
						<RejectionReasonModal
							users={getReviewerInfo()}
							closeModal={closeModal}
							reviewedDescriptionSuggestions={approvedDescSuggestions}
							reviewedSupportSuggestions={approvedSupportSuggestions}
							reviewedToolsAndGuidanceSuggestions={approvedToolsSuggestions}
							reviewedTipsAndTechniquesSuggestions={approvedTipsSuggestions}
							reviewedTemplateSuggestions={approvedTemplateSuggestions}
							reviewedEntryCriteriaSuggestions={approvedEntryCriteriaSuggestions}
							reviewedExitCriteriaSuggestions={approvedExitCriteriaSuggestions}
							reviewedTaskSuggestions={approvedTaskSuggestions}
							contributorSuggestionsInfo={reviewerSuggestionsInfo}
							submit={submitData}
							rejectionComments={rejectionComments}
							setRejectionComments={setRejectionComments}
							type="approve"
							currentUser={currentUser}
							postData={postData}
							ratingPoints={ratingPoints}
							checkRejection={checkContribution}
							usersRating={usersRating}
							setUsersRating={setUsersRating}
							enableGamification={enableGamification}
							reviewedDeliverablesSuggestions={approvedDeliverablesSuggestions}
							reviewedReorderedTaskSuggestions={reviewedReorderedTaskSuggestions}
							reviewedReorderedEntrySuggestions={reviewedReorderedEntrySuggestions}
	                        reviewedReorderedExitSuggestions={reviewedReorderedExitSuggestions}
	                        reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions}
	                        reviewedReorderedOutputSuggestions={reviewedReorderedOutputSuggestions}
							reorderRejectionComments={reorderRejectionComments}
							setReorderRejectionComments={setReorderRejectionComments}
							reorderedTaskRejectionCardInfo={reorderedTaskRejectionCardInfo}
							reorderedEntryRejectionCardInfo={reorderedEntryRejectionCardInfo}
							reorderedInputRejectionCardInfo={reorderedInputRejectionCardInfo}
							reorderedExitRejectionCardInfo={reorderedExitRejectionCardInfo}
							reorderedOutputRejectionCardInfo={reorderedOutputRejectionCardInfo}
						/>
					) : (
						<Modal
							className="PreviewAndSubmitPopUp activityPreviewAndSubmitModal copyComplexityWrapperModal"
							show={modalShow}
							onHide={() => {
								/* Not calling any function to close modal*/
							}}
							dialogClassName="modal-90w"
						>
							<Modal.Header>
								<Modal.Title>
								<span tabIndex={0} role="dialog" id="previewId" aria-label="Preview dialog">Preview</span>
									<div className="discardAndPreviewButton">
										<Button
											title="Cancel"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													closeModal();
												}
											}}
											onClick={closeModal}
											className="activityDiscardButton"
										>
											Cancel
										</Button>
										<Button
											title="Submit"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													checkContribution('submit');
												}
											}}
											onClick={() => {
												checkContribution('submit');
											}}
											className="activityPreviewButton"
										>
											Publish
										</Button>
									</div>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div>
									<div className="activityLandinPage">
										<div className="activityMainContainer">
											<div className="activityDescriptionDetails">
												<div className="activityDescTitlePart">
													<div className="activityTitleRightPart">
														<h4
															title={activityContent.activityName}
															className="activityLandingPageTitle"
														>
															{activityContent.activityName}
														</h4>
														<span className="activityVersion">
															{`Document Version: ${activityContent.activityVersion},`}
															&nbsp;&nbsp;{`${activityContent.date} `}
														</span>
													</div>
												</div>

												<div
													className={`previewDescOfActivity copyToComplexityDiv ${publishComplexity.length > 0
														? 'showFullActivityPreview'
														: 'doNotShowFullActivityPreview'
														}`}
												>
													<div className="slectingSourceDestinationBar">
														<div class="Detailswrapper complexitySelection">
															<div>Source Complexity</div>
															<h6>{complexityName.name}</h6>
														</div>
														<div class="Detailswrapper complexitySelection">
															<div class="NewActivitycomplexityWRapper">
																<span>
																	Select the complexities for which you want to
																	publish this activity
																</span>
																<div>
																	<div>Destination Complexity</div>
																	<ul id="complexityModalSelection">
																		{complexities
																			.filter(
																				(item) => item.id !== complexityName.id
																			)
																			.map((complexity) => (
																				<>
																					<li
																						key={complexity.name}
																						className={
																							selectedComplexityTab ===
																								complexity.name
																								? 'selectedComplexities'
																								: activityComplexityData[
																									complexity.name
																								]?.isCrowdSourced
																									? 'DisabledSelectComplexity'
																									: ''
																						}
																						title={
																							activityComplexityData[
																								complexity.name
																							]?.isCrowdSourced
																								? 'There are changes pending to be reviewed, so you are unable to copy changes to this complexity'
																								: complexity.name
																						}
																					>
																						<label class="NotificationChkboxContainer">
																							<input
																								name={complexity.name}
																								aria-label={complexity.name}
																								type="checkbox"
																								checked={publishComplexity.includes(
																									complexity.id
																								)}
																								onChange={() => {
																									if (
																										!activityComplexityData[
																											complexity.name
																										]?.isDrafted &&
																										!activityComplexityData[
																											complexity.name
																										]?.isCrowdSourced
																									) {
																										if (
																											publishComplexity.includes(
																												complexity.id
																											)
																										) {
																											setPublishComplexity(
																												publishComplexity.filter(
																													(id) =>
																														id !== complexity.id
																												)
																											);
																											const checkAvailableComplexity =
																												complexities.filter(
																													(item) =>
																														item.id !==
																														complexityName.id &&
																														item.id !==
																														complexity.id &&
																														publishComplexity.includes(
																															item.id
																														)
																												);
																											if (
																												checkAvailableComplexity.length >
																												0
																											) {
																												setSelectedComplexityTab(
																													checkAvailableComplexity[0]
																														.name
																												);
																											} else {
																												setSelectedComplexityTab(
																													''
																												);
																											}
																											setComplexitySectionModal(
																												''
																											);
																										} else {
																											setSelectedComplexityTab(
																												complexity.name
																											);
																											setComplexitySectionModal(
																												complexity.name
																											);
																											setPublishComplexity([
																												...publishComplexity,
																												complexity.id,
																											]);
																										}
																									}
																								}}
																							/>
																							<span class="CustomCheckmark"></span>
																						</label>
																						<span
																							class="filterLabel"
																							onClick={() => {
																								if (
																									!activityComplexityData[
																										complexity.name
																									]?.isDrafted &&
																									!activityComplexityData[
																										complexity.name
																									]?.isCrowdSourced &&
																									publishComplexity.includes(
																										complexity.id
																									)
																								) {
																									setSelectedComplexityTab(
																										complexity.name
																									);
																									setComplexitySectionModal(
																										complexity.name
																									);
																								}
																							}}
																						>
																							{complexity.name}
																						</span>
																						<div className="complexityDataSelection">
																							<Icon
																								iconName="MoreVertical"
																								onClick={() => {
																									if (
																										!activityComplexityData[
																											complexity.name
																										]?.isDrafted &&
																										!activityComplexityData[
																											complexity.name
																										]?.isCrowdSourced
																									) {
																										if (
																											complexitySectionModal ===
																											complexity.name
																										) {
																											setComplexitySectionModal(
																												''
																											);
																										} else if (
																											publishComplexity.includes(
																												complexity.id
																											)
																										) {
																											setComplexitySectionModal(
																												complexity.name
																											);
																										}
																									}
																								}}
																							/>
																							{complexity.name ===
																								complexitySectionModal && (
																									<div class="showMoreMenu complexityPopUp">
																										<span class="showMoreArrow"></span>

																										<div className="selectingComplexitySectionsDiv">
																											<h6>
																												Select the section with
																												changes you want to copy
																												to {complexity.name}{' '}
																												complexity
																											</h6>
																											<div>
																												<div className="selectingComplexityMessageBar">
																													<p>
																														<span class="aprroverStatusIconForCopy"></span>
																														Data for this
																														complexity would be
																														overwritten which is
																														irreversible.
																													</p>
																												</div>
																												<div className="selectingComplexitySections">
																													<ul>
																														{copyToComplexityItems.map(
																															(item) => (
																																<li
																																	key={item.name}
																																>
																																	<label class="NotificationChkboxContainer">
																																		<input
																																			name={
																																				item.name
																																			}
																																			type="checkbox"
																																			checked={
																																				publishComplexityEntity[
																																				complexity
																																					.name
																																				]?.[
																																				item
																																					.name
																																				]
																																			}
																																			onChange={() => {
																																				setUpdateEntity(
																																					complexity,
																																					item
																																				);
																																			}}
																																		/>
																																		<span class="CustomCheckmark"></span>
																																		<span class="filterLabel">
																																			{item.name}
																																		</span>
																																	</label>
																																</li>
																															)
																														)}
																													</ul>
																												</div>
																											</div>
																										</div>
																									</div>
																								)}
																						</div>
																					</li>
																				</>
																			))}
																	</ul>
																	
																</div>
															</div>
														</div>
													</div>
													<div className="resultedActivityComplexityContainer">
														<div>
															<div className="sourceComplexityContainer">
																<div class="existingHashTagsOfActivity">
																	{existingHashtags.map((name) => (
																		<span
																			key={name}
																			onClick={() => {
																				history.push(
																					`/search?searchParam=${encodeURIComponent(
																						name
																					)}`
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					history.push(
																						`/search?searchParam=${encodeURIComponent(
																							name
																						)}`
																					);
																				}
																			}}
																		>
																			{name}
																		</span>
																	))}
																</div>
																<ActivityRaciMatrix
																	activityData={activityContent}
																/>
																<div
																	className="innerContent"
																	id="previewInnerContent"
																	aria-label="Description"
																>
																	{activityDescription ? (
																		<>
																			<h2
																				className="sub-heading"
																				id="previewDesc"
																			>
																				Description
																			</h2>
																			<DescriptionCkEditor
																				users={users}
																				currentUser={currentUser}
																				initialData={activityDescription}
																				setContent={
																					setPreviewActivityDescription
																				}
																				initalSuggestions={
																					descriptionSuggestions
																				}
																				licenseKey={licenseKey}
																			/>
																			<div className="hideApprovalCKEditor">
																				<ActivityApproval
																					users={users}
																					currentUser={currentUser}
																					initialData={activityDescription}
																					setContent={
																						setPreviewActivityDescription
																					}
																					initalSuggestions={
																						descriptionSuggestions
																					}
																					licenseKey={licenseKey}
																				/>
																			</div>
																		</>
																	) : null}

																	{activitySupportingActivities ? (
																		<>
																			<h2
																				className="sub-heading"
																				id="previewSupportAct"
																			>
																				Supporting Activities
																			</h2>
																			<SupportAndActivitiesCkEditor
																				users={users}
																				currentUser={currentUser}
																				initialData={
																					activitySupportingActivities
																				}
																				setContent={
																					setPreviewActivitySupportingActivities
																				}
																				initalSuggestions={
																					supportingActivitiesSuggestions
																				}
																				licenseKey={licenseKey}
																			/>
																			<div className="hideApprovalCKEditor">
																				<ActivityApproval
																					users={users}
																					currentUser={currentUser}
																					initialData={
																						activitySupportingActivities
																					}
																					setContent={
																						setPreviewActivitySupportingActivities
																					}
																					initalSuggestions={
																						supportingActivitiesSuggestions
																					}
																					licenseKey={licenseKey}
																				/>
																			</div>
																		</>
																	) : null}

																	{activityContent.activityEntryCriteria || entryCriteriaWithSuggestions.length > 0 ? (
																		<>
																			<h2 className="sub-heading" id="previewEntryc">
																				Entry Criteria/Input {isApproverEntryReordered || isApproverInputReordered ? <span className='ReorderMessage'>You have reordered the data</span> :<></>}
																			</h2>
																			{entryCriteriaWithSuggestions.length > 0 ? (
																				<div id="previewTempList">
																					<ul>
																						{entryCriteriaWithSuggestions.some(
																							(value) =>
																								JSON.parse(value).criteriaTypeName ===
																								'Entry Criteria'
																						) && (
																								<>
																									<>Entry Criteria</>
																									{entryCriteriaWithSuggestions.map(
																										(item) => (
																											<>
																												{JSON.parse(item)
																													.criteriaTypeName ===
																													'Entry Criteria' && (
																														<li
																															className={`${JSON.parse(item)
																																.suggestionType === 'none'
																																? ''
																																: JSON.parse(item)
																																	.suggestionType ===
																																	'deletion'
																																	? 'userDeletedToolsAndGuidance'
																																	: 'userAddedItem'
																																}`}
																															key={JSON.parse(item).id}
																														>
																															<span className="">
																																{/* {JSON.parse(item).name} */}
																																<span
																																	className="descEn"
																																	//id="entryCriteriaSanitizer"
																																	id={`${JSON.parse(item)
																																		.suggestionType === "none"
																																		? "criteriaDesc"
																																		: "entryCriteriaSanitizer"

																																		}`}
																																	dangerouslySetInnerHTML={{
																																		__html: htmlSanitizer(JSON.parse(item).name),
																																	}}
																																/>
																															</span>
																														</li>
																													)}
																											</>
																										)
																									)}
																								</>
																							)}
																						{entryCriteriaWithSuggestions.some(
																							(value) =>
																								JSON.parse(value).criteriaTypeName ===
																								'Input'
																						) && (
																								<>
																									<>Input</>
																									{entryCriteriaWithSuggestions.map(
																										(item) => (
																											<>
																												{JSON.parse(item)
																													.criteriaTypeName ===
																													'Input' && (
																														<li
																															className={`${JSON.parse(item)
																																.suggestionType === 'none'
																																? ''
																																: JSON.parse(item)
																																	.suggestionType ===
																																	'deletion'
																																	? 'userDeletedToolsAndGuidance'
																																	: 'userAddedItem'
																																}`}
																															key={JSON.parse(item).id}
																														>
																															<span className="">
																																{/* {JSON.parse(item).name} */}
																																<span
																																	className="descEn"
																																	//id="entryCriteriaSanitizer"
																																	id={`${JSON.parse(item)
																																		.suggestionType === "none"
																																		? "criteriaDesc"
																																		: "entryCriteriaSanitizer"

																																		}`}
																																	dangerouslySetInnerHTML={{
																																		__html: htmlSanitizer(JSON.parse(item).name),
																																	}}
																																/>
																															</span>
																														</li>
																													)}
																											</>
																										)
																									)}
																								</>
																							)}
																					</ul>
																				</div>
																			) : null}
																		</>
																	) : null}

																	{activityContent.activityTasks || taskWithSuggestions.length > 0 ? (
																		<>
																			<h2
																				className="sub-heading"
																				id="previewTasks"
																			>
																				Tasks {isApproverTaskReordered && <span className='ReorderMessage'>You have reordered the data</span>}
																			</h2>

																			{taskWithSuggestions.length > 0 ? (
																				<div id="previewTempList">
																					<ul>
																						<>
																							{taskWithSuggestions.map(
																								(item) => (
																									<>
																										<li
																											className={`${JSON.parse(item)
																												.suggestionType === 'none'
																												? ''
																												: JSON.parse(item)
																													.suggestionType ===
																													'deletion'
																													? 'userDeletedToolsAndGuidance'
																													: 'userAddedItem'
																												}`}
																											key={JSON.parse(item).id}
																										>
																											
																											<span
                                                                                                            className="templateEntityName"
                                                                                                            dangerouslySetInnerHTML={{ __html: htmlSanitizer(removePTags(JSON.parse(item).name)) }}
                                                                                                            />
																										</li>
																									</>
																								)
																							)}
																						</>
																					</ul>
																				</div>
																			) : null}
																		</>
																	) : null}

																	{activityContent.activityExitCriteria || exitCriteriaWithSuggestions.length > 0 ? (
																		<>
																			<h2 className="sub-heading" id="previewExitc">
																				Exit Criteria/Output {isApproverExitReordered || isApproverOutputReordered ? <span className='ReorderMessage'>{`You have reordered the data`}</span> : <></>}
																			</h2>
																			{exitCriteriaWithSuggestions.length > 0 ? (
																				<div id="previewTempList">
																					<ul>
																						{exitCriteriaWithSuggestions.some(
																							(value) =>
																								JSON.parse(value).criteriaTypeName ===
																								'Exit Criteria'
																						) && (
																								<>
																									<>Exit Criteria</>
																									{exitCriteriaWithSuggestions.map(
																										(item) => (
																											<>
																												{JSON.parse(item)
																													.criteriaTypeName ===
																													'Exit Criteria' && (
																														<li
																															className={`${JSON.parse(item)
																																.suggestionType === 'none'
																																? ''
																																: JSON.parse(item)
																																	.suggestionType ===
																																	'deletion'
																																	? 'userDeletedToolsAndGuidance'
																																	: 'userAddedItem'
																																}`}
																															key={JSON.parse(item).id}
																														>
																															<span className="">
																																{/* {JSON.parse(item).name} */}
																																<span
																																	className="descEn"
																																	//id="entryCriteriaSanitizer"
																																	id={`${JSON.parse(item)
																																		.suggestionType === "none"
																																		? "criteriaDesc"
																																		: "entryCriteriaSanitizer"

																																		}`}
																																	dangerouslySetInnerHTML={{
																																		__html: htmlSanitizer(JSON.parse(item).name),
																																	}}
																																/>
																															</span>
																														</li>
																													)}
																											</>
																										)
																									)}
																								</>
																							)}
																						{exitCriteriaWithSuggestions.some(
																							(value) =>
																								JSON.parse(value).criteriaTypeName ===
																								'Output'
																						) && (
																								<>
																									<>Output</>
																									{exitCriteriaWithSuggestions.map(
																										(item) => (
																											<>
																												{JSON.parse(item)
																													.criteriaTypeName ===
																													'Output' && (
																														<li
																															className={`${JSON.parse(item)
																																.suggestionType === 'none'
																																? ''
																																: JSON.parse(item)
																																	.suggestionType ===
																																	'deletion'
																																	? 'userDeletedToolsAndGuidance'
																																	: 'userAddedItem'
																																}`}
																															key={JSON.parse(item).id}
																														>
																															<span className="">
																																{/* {JSON.parse(item).name} */}
																																<span
																																	className="descEn"
																																	//id="entryCriteriaSanitizer"
																																	id={`${JSON.parse(item)
																																		.suggestionType === "none"
																																		? "criteriaDesc"
																																		: "entryCriteriaSanitizer"

																																		}`}
																																	dangerouslySetInnerHTML={{
																																		__html: htmlSanitizer(JSON.parse(item).name),
																																	}}
																																/>
																															</span>
																														</li>
																													)}
																											</>
																										)
																									)}
																								</>
																							)}
																					</ul>
																				</div>
																			) : null}
																		</>
																	) : null}


																	{procedureData.length > 0 ? (
																		<h2
																			className="sub-heading"
																			id="previewTopics"
																		>
																			Procedures
																		</h2>
																	) : (
																		<></>
																	)}

																	{procedureData.length > 0 ? (
																		<div id="previewActivityProcedures">
																			<ul>
																				{procedureData.map((procedure) => (
																					<li>
																						<a
																							href={procedure.url}
																							target="_blank"
																						>
																							{procedure.name}
																						</a>
																					</li>
																				))}
																			</ul>
																		</div>
																	) : null}
																	{topicData.length > 0 ? (
																		<h2
																			className="sub-heading"
																			id="previewTopics"
																		>
																			Related Topics
																		</h2>
																	) : (
																		<></>
																	)}

																	{topicData.length > 0 ? (
																		<div id="previewTopicList">
																			<ul>
																				{topicData.map((topic) => (
																					<li>
																						{
																							<a
																								href={topic.url}
																								target="_blank"
																							>
																								{topic.name}
																							</a>
																						}
																					</li>
																				))}
																			</ul>
																		</div>
																	) : null}

																	{activityContent.activityDeliverables ||
																		(templateData && templateData.length > 0) ||
																		(templateSuggestions &&
																			templateSuggestions.length > 0) ? (
																		<h2
																			className="sub-heading"
																			id="previewDeliver"
																		>
																			Deliverables
																		</h2>
																	) : (
																		<></>
																	)}

																	{(templateData && templateData.length > 0) ||
																		(templateSuggestions &&
																			templateSuggestions.length > 0) ? (
																		<div id="previewTempList">
																			<ul>
																				{templateData &&
																					templateData.map((template) => (
																						<li>
																							{template.inProgress === true ? (
																								<a
																									className="noClick"
																									href={template.fileLocation}
																									target="_blank"
																								>
																									{template.title}
																								</a>
																							) : (
																								<a
																									href={template.fileLocation}
																									target="_blank"
																								>
																									{template.title}
																								</a>
																							)}
																							{template.sampleTemplates &&
																								template.sampleTemplates
																									.length !== 0 &&
																								!template.inProgress ? (
																								<button
																									className="activitySampleButton"
																									tabIndex={0}
																									onKeyPress={(e) => {
																										if (e.which === 13) {
																											setSampleTemplates({
																												enable: true,
																												id: template.id,
																												parentTemplate:
																													template,
																											});
																										}
																									}}
																									onClick={() => {
																										setSampleTemplates({
																											enable: true,
																											id: template.id,
																											parentTemplate: template,
																										});
																									}}
																								>
																									(Sample)
																								</button>
																							) : (
																								<></>
																							)}
																						</li>
																					))}
																				{templateSuggestions &&
																					templateSuggestions.map((item) => (
																						<li
																							className={`${JSON.parse(item)
																								.suggestionType ===
																								'insertion' &&
																								JSON.parse(item).authorId ===
																								currentUser
																								? 'userAddedItem'
																								: 'none'
																								}`}
																							key={item.id}
																						>
																							<span className="templateEntityName">
																								<a
																									href={
																										JSON.parse(item)
																											.fileLocation
																									}
																								>
																									{JSON.parse(item).title}
																								</a>
																							</span>
																						</li>
																					))}
																			</ul>
																		</div>
																	) : null}
																	{/* {activityContent.activityDeliverables ? (
																		<div
																			id="previewActivityDeliverables"
																			dangerouslySetInnerHTML={{
																				__html: htmlSanitizer(
																					activityContent.activityDeliverables
																				),
																			}}
																		/>
																	) : null} */}

																	{activityDeliverables ? (
																		<>
																			{/* <h2
																				className="sub-heading"
																				id="previewDesc"
																			>
																				Deliverables
																			</h2> */}
																			<DeliverablesCkEditor
																				users={users}
																				currentUser={currentUser}
																				initialData={activityDeliverables}
																				setContent={
																					setPreviewActivityDeliverables
																				}
																				initalSuggestions={
																					deliverablesSuggestions
																				}
																				licenseKey={licenseKey}
																			/>
																			<div className="hideApprovalCKEditor">
																				<ActivityApproval
																					users={users}
																					currentUser={currentUser}
																					initialData={activityDeliverables}
																					setContent={
																						setPreviewActivityDeliverables
																					}
																					initalSuggestions={
																						deliverablesSuggestions
																					}
																					licenseKey={licenseKey}
																				/>
																			</div>
																		</>
																	) : null}

																	{getToolsCount() ||
																		postData.resultCalloutJson
																			.approverToolsSuggestions ? (
																		<>
																			<h2
																				className="sub-heading"
																				id="previewTool"
																			>
																				Tools
																			</h2>
																			<ul>
																				{postData.resultCalloutJson
																					.approverToolsSuggestions &&
																					postData.resultCalloutJson.approverToolsSuggestions.map(
																						(tools) => (
																							<React.Fragment
																								key={JSON.parse(tools).toolsId}
																							>
																								<li
																									className={
																										JSON.parse(tools)
																											.suggestionType ===
																											'deletion'
																											? 'userDeletedToolsAndGuidance'
																											: 'userAddedItemPreview'
																									}
																								>
																									<a
																										href={
																											toolsUrl[
																											JSON.parse(tools)
																												.toolsId
																											]
																										}
																									>
																										{JSON.parse(tools).name}
																									</a>
																								</li>
																							</React.Fragment>
																						)
																					)}
																				{selectedToolsAndGuidanceSuggestions.map(
																					(tools) => (
																						<React.Fragment
																							key={JSON.parse(tools).toolsId}
																						>
																							{JSON.parse(tools)
																								.suggestionType !==
																								'deletion' &&
																								JSON.parse(tools)
																									.suggestionType !==
																								'insertion' ? (
																								<li>
																									<a
																										href={
																											toolsUrl[
																											JSON.parse(tools)
																												.toolsId
																											]
																										}
																									>
																										{JSON.parse(tools).name}
																									</a>
																								</li>
																							) : (
																								<></>
																							)}
																						</React.Fragment>
																					)
																				)}
																			</ul>
																		</>
																	) : (
																		<></>
																	)}

																	{activityTipsAndTechniques ? (
																		<>
																			<h2
																				className="sub-heading"
																				id="previewTipstech"
																			>
																				Guidance, Tips and Techniques
																			</h2>
																			<TipsAndTechniquesCkEditor
																				users={users}
																				currentUser={currentUser}
																				initialData={activityTipsAndTechniques}
																				setContent={
																					setpreviewActivityTipsAndTechniques
																				}
																				initalSuggestions={
																					tipsAndTechniquesSuggestions
																				}
																				licenseKey={licenseKey}
																			/>
																			<div className="hideApprovalCKEditor">
																				<ActivityApproval
																					users={users}
																					currentUser={currentUser}
																					initialData={
																						activityTipsAndTechniques
																					}
																					setContent={
																						setpreviewActivityTipsAndTechniques
																					}
																					initalSuggestions={
																						tipsAndTechniquesSuggestions
																					}
																					licenseKey={licenseKey}
																				/>
																			</div>
																		</>
																	) : null}
																</div>
															</div>
														</div>
														<CopyToComplexity
															complexities={complexities}
															complexityName={complexityName}
															publishComplexity={publishComplexity}
															setPublishComplexity={setPublishComplexity}
															selectedComplexityTab={selectedComplexityTab}
															setSelectedComplexityTab={
																setSelectedComplexityTab
															}
															complexitySectionModal={complexitySectionModal}
															publishComplexityEntity={publishComplexityEntity}
															setPublishComplexityEntity={
																setPublishComplexityEntity
															}
															setComplexitySectionModal={
																setComplexitySectionModal
															}
															activityComplexityData={activityComplexityData}
															description={
																activityDescription
																	? previewActivityDescription
																	: null
															}
															supportingActivities={
																activitySupportingActivities
																	? previewActivitySupportingActivities
																	: null
															}
															toolsAndGuidance={getToolsAndGuidanceForComplexityModal()}
															tipsAndTechniques={
																activityTipsAndTechniques
																	? previewActivityTipsAndTechniques
																	: null
															}
															taskData={getTaskDataForComplexityModal()}
															entryCriteriaData={getEntryCriteriaDataForComplexityModal()}
															exitCriteriaData={getExitCriteriaDataForComplexityModal()}
															deliverables={
																activityDeliverables
																	? previewActivityDeliverables
																	: null
															}
															templateData={getTemplatesForComplexityModal()}
															setSampleTemplates={setSampleTemplates}
															enableFileDropModal={enableFileDropModal}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									{enableTemplatePreview ? (
										<PreviewModal
											enableTemplatePreview={enableTemplatePreview}
											closeModal={() => setEnableTemplatePreview(false)}
											data={templatePreviewData}
										/>
									) : (
										<></>
									)}
									{enableFileDropModal ? (
										<FileDropModal
											enableFileDropModal={enableFileDropModal}
											closeModal={() => setEnableFileDropModal(false)}
											data={sampleTemplates.parentTemplate}
										/>
									) : (
										<></>
									)}
									<Modal
										show={sampleTemplates.enable}
										onHide={() => {
											setSampleTemplates({
												id: null,
												enable: false,
												parentTemplate: null,
											});
										}}
										centered
										dialogClassName="templateModal"
									>
										{sampleTemplates.enable ? (
											<SampleTemplateModal
												id={sampleTemplates.id}
												handleTemplatePreview={handleTemplatePreview}
												setEnableFileDropModal={setEnableFileDropModal}
												closeModal={() => {
													setSampleTemplates({
														id: null,
														enable: false,
														parentTemplate: null,
													});
												}}
												enableFileDropModal={enableFileDropModal}
												complexityId={complexityName.id}
											/>
										) : (
											<></>
										)}
									</Modal>
								</div>
							</Modal.Body>
						</Modal>
					)}
				</>
			)
			}
		</>
	);
}

export default PreviewAndSubmitPopUp;
