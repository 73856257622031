import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import fileIcons from '../../static/TemplateIcons';
import HashTagsModalForTemplate from './HashtagsModalForTemplate';
import AdminLocalizedTemplateEdit from './AdminLocalizedTemplateEdit';
import SaveSpinner from '../../helpers/CrowdSourcingSpinner';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
function LocalizedTemplateModal({
	id,
	complexityId,
	handleTemplatePreview,
	closeModal,
	enableLocalizedTemplate,
	setEnableLocalizedTemplate,
	complexityTag,
}) {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosGetService, axiosDeleteService } = useAxios();
	const [data, setData] = useState([]);
	const [enableHashtagsForTemplate, setEnableHashtagsForTemplate] =
		useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const [selectedSample, setSelectedSample] = useState(null);
	const [selectedTemplateInfo, setSelectedTemplateInfo] = useState({});
	const [enableLocalizedTemplateEdit, setEnableLocalizedTemplateEdit] =
		useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { isAdmin } = useLeftMenuCollapse();
	const getTemplates = async () => {
		await axiosGetService(
			`api/sdmtemplate/getLocTemplate/${id}/${complexityId}`
		).then((response) => {
			setData(response.data.length === 0 ? null : response.data);
			setExistingHashtags([]);
		});
	};

	const deleteLocalizedTemplate = async () => {
		setShowSpinner(true);
		await axiosDeleteService(
			`/api/sdmtemplate/deletelocalisedtemplate?localisedTemplateId=${selectedTemplateInfo.id}`
		).then((response) => {
			setShowSpinner(false);
			if (response.data.status) {
				toast.info(
					`You have successfully deleted ${selectedTemplateInfo.title}`,
					{
						position: 'top-right',
					}
				);
				setSelectedTemplateInfo({});
				getTemplates();
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
		});
	};

	useEffect(() => {
		if (!enableHashtagsForTemplate && !enableLocalizedTemplate) {
			getTemplates();
		}
	}, [enableHashtagsForTemplate, enableLocalizedTemplate]);

	return (
		<>
			{enableHashtagsForTemplate ? (
				<HashTagsModalForTemplate
					enableHashtagsForTemplate={enableHashtagsForTemplate}
					closeModal={() => setEnableHashtagsForTemplate(false)}
					template={selectedSample}
					complexityTag={complexityTag}
					existingHashtags={existingHashtags}
					setExistingHashtags={setExistingHashtags}
					type="localisedTemplates"
				/>
			) : (
				<></>
			)}
			{showSpinner ? (
				<SaveSpinner />
			) : (
				<div
					className={
						enableLocalizedTemplate ||
						enableHashtagsForTemplate ||
						enableLocalizedTemplateEdit
							? 'hideLocalizedTemplateModal'
							: null
					}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Localized Templates
						</Modal.Title>
						<button
							title="Submit Localized Template"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setEnableLocalizedTemplate(true);
								}
							}}
							onClick={() => {
								setEnableLocalizedTemplate(true);
							}}
							className="submitSampleButton"
						>
							Submit Localized Template
						</button>
						<Icon
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							aria-label="cancel"
							role="button"
						/>
					</Modal.Header>
					<Modal.Body>
						<Table striped>
							<thead>
								<tr>
									<th>Name</th>
									<th>Country</th>
									<th>Language</th>

									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data === null ? (
									<tr>
										<td colspan="5" style={{ textAlign: 'center' }}>
											No Localized Templates available
										</td>
									</tr>
								) : (
									data.map((template) => {
										return (
											<tr>
												<td>
													<div>
														<img
															src={
																template.inProgress
																	? fileIcons['inProgress']
																	: template.fileType !== null
																	? fileIcons[template.fileType.toLowerCase()]
																	: fileIcons['defaultTemplate']
															}
															className="imageOfSearchResult"
															alt="File Type"
														/>
														{!template.inProgress ? (
															<a
																href={template.fileLocationNew}
																target="_blank"
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		track(1, 123, template.id, template.title, {
																			phaseName: template.phaseName,
																			complexityId: template.complexityId,
																		});
																	}
																}}
																onClick={() =>
																	track(1, 123, template.id, template.title, {
																		phaseName: template.phaseName,
																		complexityId: template.complexityId,
																	})
																}
															>
																{template.title}
															</a>
														) : (
															<span>{template.title}</span>
														)}
													</div>
													<div className="existingHashTags">
														{template.hashTagsList
															.slice(0, 3)
															.map(({ name }) => (
																<span
																	key={name}
																	onClick={() => {
																		history.push(
																			`/search?searchParam=${encodeURIComponent(
																				name
																			)}`
																		);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			history.push(
																				`/search?searchParam=${encodeURIComponent(
																					name
																				)}`
																			);
																		}
																	}}
																>
																	{name}
																</span>
															))}
														{template.hashTagsList.length ? (
															<div className="addHashtagsIconContainer">
																<span
																	className="addHashtagsIcon"
																	onClick={() => {
																		setSelectedSample(template);
																		setExistingHashtags(
																			template.hashTagsList.map(({ name }) => {
																				return name;
																			})
																		);
																		setEnableHashtagsForTemplate(true);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setSelectedSample(template);
																			setExistingHashtags(
																				template.hashTagsList.map(
																					({ name }) => {
																						return name;
																					}
																				)
																			);
																			setEnableHashtagsForTemplate(true);
																		}
																	}}
																	tabIndex="0"
																	role="button"
																	aria-label="Add Hashtags"
																	aria-expanded={
																		enableHashtagsForTemplate ? true : false
																	}
																></span>
																<div class="tabsTitleToolTip">
																	<span class="tabsToolTipArrow"></span>
																	<span>Add Hashtags</span>
																</div>
															</div>
														) : (
															<p className="noHashtagsDescription">
																No hashtags yet!{' '}
																<Link
																	to="/"
																	onClick={(e) => {
																		e.preventDefault();
																		setSelectedSample(template);
																		setExistingHashtags(
																			template.hashTagsList.map(({ name }) => {
																				return name;
																			})
																		);
																		setEnableHashtagsForTemplate(true);
																	}}
																>
																	Would you like to add?
																</Link>
															</p>
														)}
													</div>
												</td>
												<td>{template.country}</td>
												<td>{template.language}</td>

												<td>
													{selectedTemplateInfo.id === template.id && (
														<div className="deleteSamplesPopUp">
															<p>
																Are you sure that you want to delete this
																template?
															</p>
															<div>
																<button
																	onClick={() => {
																		setSelectedTemplateInfo({});
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setSelectedTemplateInfo({});
																		}
																	}}
																	role="button"
																	aria-label="Cancel"
																	tabIndex={0}
																>
																	Cancel
																</button>
																<button
																	onClick={() => {
																		deleteLocalizedTemplate();
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			deleteLocalizedTemplate();
																		}
																	}}
																	role="button"
																	aria-label="Delete"
																	tabIndex={0}
																>
																	Delete
																</button>
															</div>
														</div>
													)}
													{isAdmin && (
														<Icon
															iconName="EditSolid12"
															onClick={() => {
																setSelectedSample(template);
																setEnableLocalizedTemplateEdit(true);
															}}
															title="Edit Localized template"
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedSample(template);
																	setEnableLocalizedTemplateEdit(true);
																}
															}}
															role="button"
														/>
													)}
													<span
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																handleTemplatePreview(template);
															}
														}}
														onClick={() => {
															handleTemplatePreview(template);
														}}
														disabled={
															['zip', 'aspx', 'html', null].includes(
																template.fileType
															) ||
															template.inProgress ||
															template.previewUrl === null
														}
														title="View Document"
														className="previewIconBlack"
														role="button"
													></span>
													{isAdmin && (
														<span
															title="Delete Localized template"
															onClick={() => {
																setSelectedTemplateInfo(template);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedTemplateInfo(template);
																}
															}}
															role="button"
															tabIndex={0}
															className="ChildTemplateDeleteIcon"
														></span>
													)}
												</td>
											</tr>
										);
									})
								)}
							</tbody>
						</Table>
					</Modal.Body>
					{enableLocalizedTemplateEdit && (
						<AdminLocalizedTemplateEdit
							closeModal={() => {
								setEnableLocalizedTemplateEdit(false);
								setSelectedSample(null);
							}}
							data={selectedSample}
							enableLocalizedTemplateEdit={enableLocalizedTemplateEdit}
							complexityId={complexityId}
							getTemplates={getTemplates}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default LocalizedTemplateModal;
