import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';

const UpdatePhasePopUp = ({
	setShowPhaseUpdateModal,
	setShowPhaseUpdatePopUp,
	setDeletePhase,
	phaseId,
	phaseName,
	solutionMethodId,
	projectTypeId,
	parentPhaseId,
	setShowDeletePhaseModal,
	setUpdatePhaseId,
	className,
	setShowParentDeletePhaseModal,
	type,
	selectedPhase,
}) => {
	const { phase, parentPhase } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const history = useHistory();

	const handleOutsideClick = (e) => {
		const region = document.getElementById(className);
		if (!region.contains(e.target)) {
			setShowPhaseUpdatePopUp(false);
			setUpdatePhaseId(0);
		}
	};

	useEffect(() => {
		setTimeout(() => window.addEventListener('click', handleOutsideClick), 0);
		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<div
			className={
				selectedPhase.isMethodOwnerOrCoOwner && !selectedPhase.isRefered
					? 'showMoreMenu'
					: 'showMoreMenu collapsed'
			}
			id={className}
		>
			<span class={'showMoreArrow'}></span>
			<ul className="menuItemUL" role="listbox">
				{selectedPhase.isMethodOwnerOrCoOwner && !selectedPhase.isRefered ? (
					<li
						className="moreItemLi"
						tabIndex="0"
						onClick={() => {
							setTimeout(() => {
								setShowPhaseUpdateModal(true);
								setShowPhaseUpdatePopUp(false);
							}, 500);
							track(1, 801, phaseId, phaseName);
						}}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setTimeout(() => {
									setShowPhaseUpdateModal(true);
									setShowPhaseUpdatePopUp(false);
								}, 500);
								track(1, 801, phaseId, phaseName);
							}
						}}
						role="option"
						aria-label="edit phase"
					>
						<div className="ellipseMenuItem">
							<span>Edit Phase</span>
						</div>
					</li>
				) : (
					<></>
				)}

				<li
					className="moreItemLi"
					tabIndex="0"
					onClick={() => {
						if (type === 'parentPhase') {
							setShowParentDeletePhaseModal(true);
						} else {
							setDeletePhase(true);
						}
						setShowDeletePhaseModal(true);
						setTimeout(() => {
							setShowPhaseUpdatePopUp(false);
						}, 500);
						history.push(
							`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
								type === 'parentPhase' ? phase.id : phaseId
							}/parentPhase/${
								type === 'parentPhase' ? phaseId : parentPhase.id
							}`
						);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							if (type === 'parentPhase') {
								setShowParentDeletePhaseModal(true);
							} else {
								setDeletePhase(true);
							}
							setShowDeletePhaseModal(true);
							setTimeout(() => {
								setShowPhaseUpdatePopUp(false);
							}, 500);
							history.push(
								`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
									type === 'parentPhase' ? phase.id : phaseId
								}/parentPhase/${
									type === 'parentPhase' ? phaseId : parentPhase.id
								}`
							);
						}
					}}
					role="option"
					aria-label={
						selectedPhase.isMethodOwnerOrCoOwner && !selectedPhase.isRefered
							? 'Delete Phase'
							: 'De-link phase'
					}
				>
					<div className="ellipseMenuItem">
						<span>
							{selectedPhase.isMethodOwnerOrCoOwner && !selectedPhase.isRefered
								? 'Delete Phase'
								: 'De-link phase'}
						</span>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default UpdatePhasePopUp;
