import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const RatingConfirmationModal = ({
	setEnableRating,
	showRatingConfirmationPopUp,
	setShowRatingConfirmationPopUp,
}) => {
	return (
		<Modal
			className="templateRejectModalPopUp"
			show={showRatingConfirmationPopUp}
			onHide={() => {
				setShowRatingConfirmationPopUp(false);
			}}
			size="md"
			centered
		>
			<Modal.Body>
				<p>
					You have already rated this tutorial. Do you want to rate it again?
				</p>
				<Button
					tabIndex={1}
					title="No"
					role='presentation'
					aria-label="dialog button No"
					className="noRating"
					onClick={() => {
						setShowRatingConfirmationPopUp(false);
					}}
				>
					No
				</Button>

				<Button
					aria-hidden='true'
					className="reRating"
					onClick={() => {
						setEnableRating(true);
						setShowRatingConfirmationPopUp(false);
					}}
					onKeyDown={(e)=>{
						if(e.key === 'Enter' || e.key === ' '){
							setEnableRating(false);
							setShowRatingConfirmationPopUp(false);
						}
				
					}}				
				>
					Yes
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default RatingConfirmationModal;
