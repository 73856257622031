import React from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function AdminPopUp({}) {
 return (
  <Modal
   className="activityDiscardPopUp"
   show={true}
   onHide={() => {
    // don't do anything
   }}
   size="md"
   aria-labelledby="contained-modal-title-vcenter"
   centered
  >
   <Modal.Body>
    <p>You don't have permission to edit this page. Please contact Admin.</p>
    <div className="discardPopUpActivityButtons">
     <Link aria-label="Discard PopUp Yes" to={`/topics`}>
      <Button
       title="Yes"
       tabIndex={-1}
       className="activityDiscardPopUpAccept"
       variant="info"
      >
       OK
      </Button>
     </Link>
    </div>
   </Modal.Body>
  </Modal>
 );
}

export default AdminPopUp;
