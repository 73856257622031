import React, { useState, useEffect, useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useAxios } from '../../context/AxiosContext';

import MyAchievements from './MyAchievements';
import MyAchievementsLeftMenu from './MyAchievementsLeftMenu';
import Dashboard from './Dashboard/Dashboard';
import Community from './Community';
import Badges from './Badges';

import './MyAchievements.css';

const Gamification = () => {
 const { url } = useRouteMatch();
 const { axiosGetService } = useAxios();
 const [expandMyAchievementsLeftMenu, setExpandMyAchievementsLeftMenu] =
  useState(true);
 const [enableGamification, setEnableGamification] = useState(false);
 const [showSpinner, setShowSpinner] = useState(true);
 const [MyAchievementsHoc, setMyAchievementsHoc] = useState(null);
 const [myPerformanceTrendsData, setMyPerformanceTrendsData] = useState([]);

 const checkGamificationStatus = async () => {
  await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
   (response) => {
    if (response.data[0].value === 'true') {
     setEnableGamification(true);
    } else {
     setShowSpinner(false);
    }
   }
  );
 };

 useEffect(() => {
  checkGamificationStatus();
 }, []);

 useMemo(() => {
  setMyAchievementsHoc(
   <MyAchievements
    myPerformanceTrendsData={myPerformanceTrendsData}
    setMyPerformanceTrendsData={setMyPerformanceTrendsData}
   />
  );
 }, [myPerformanceTrendsData]);

 if (!enableGamification && !showSpinner) {
  return (
   <div className="comingSoon">
    <h3>Coming soon!</h3>
   </div>
  );
 }

 return (
  <div className="myAchievementsMainContainer topicParentWrapper">
   <div className="myAchievementsLandingPage">
    <Breadcrumbs />
    <div className="myAchievementsLeftAndMainContainer">
     <MyAchievementsLeftMenu
      expandMyAchievementsLeftMenu={expandMyAchievementsLeftMenu}
      setExpandMyAchievementsLeftMenu={setExpandMyAchievementsLeftMenu}
     />
     <div
      className={
       !expandMyAchievementsLeftMenu
        ? 'myAchievementsContainer'
        : 'myAchievementsContainer expanded'
      }
     >
      <Switch>
       <Route exact path={url}>
        {MyAchievementsHoc}
       </Route>
       <Route exact path={url + '/dashboard'}>
        <Dashboard
         myPerformanceTrendsData={myPerformanceTrendsData}
         setMyPerformanceTrendsData={setMyPerformanceTrendsData}
        />
       </Route>
       <Route exact path={url + '/community'}>
        <Community />
       </Route>
       <Route exact path={url + '/badges'}>
        <Badges />
       </Route>
       <Route path="*">
        <ComingSoon />
       </Route>
      </Switch>
     </div>
    </div>
   </div>
  </div>
 );
};

const ComingSoon = () => (
 <h2 className="pageNotFound">
  <b>404 Page not found!</b>
 </h2>
);

export default Gamification;
