import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';
import { v4 as uniqueIdGenerator } from 'uuid';

import { useAxios } from '../../context/AxiosContext';
import { urlValidator } from '../../helpers/Validation';
import { useHistory, useLocation } from 'react-router';

const AddDeliveryPlayBook = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { axiosGetService, axiosPostService } = useAxios();

	const [deliveryPlaybooks, setDeliveryPlaybooks] = useState([]);
	const [deliveryPlaybookTitle, setDeliveryPlaybookTitle] = useState('');
	const [deliveryPlaybookUrl, setDeliveryPlaybookUrl] = useState('');
	const [addDeliveryPlaybookButton, setAddDeliveryPlaybookButton] =
		useState(false);

	const getDevliveryPlaybooks = async () => {
		await axiosGetService(`api/admin/deplaybook`).then((response) => {
			const data = response.data.map((item) => {
				return {
					...item,
					id: uniqueIdGenerator(),
				};
			});
			setDeliveryPlaybooks(data);
		});
	};

	const checkUrl = (fileLocation) => {
		return !urlValidator.test(fileLocation) && fileLocation;
	};

	const submitDeliveryPlaybook = async () => {
		const isTitleOrUrlEmpty = deliveryPlaybooks.find(
			(item) => !item.title || !item.fileLocation
		);
		const isDuplicateTitle =
			new Set(
				deliveryPlaybooks.map(({ title }) => {
					return title;
				})
			).size !== deliveryPlaybooks.length;
		const isDuplicateUrl =
			new Set(
				deliveryPlaybooks.map(({ fileLocation }) => {
					return fileLocation;
				})
			).size !== deliveryPlaybooks.length;

		const updatedJson = deliveryPlaybooks.map((item) => {
			delete item.id;
			return item;
		});
		if (isTitleOrUrlEmpty) {
			toast.error('Please fill all the input', {
				position: 'top-right',
			});
		} else if (isDuplicateTitle || isDuplicateUrl) {
			toast.error(
				`Please remove duplicate ${isDuplicateTitle ? 'title' : 'url'}`,
				{
					position: 'top-right',
				}
			);
		} else {
			await axiosPostService(`api/admin/deplaybook`, updatedJson).then(
				(response) => {
					if (response.data.status) {
						toast.info('You have successfully updated the delivery playbook', {
							position: 'top-right',
						});
						history.push(pathname);
					} else {
						toast.error(response.data.errorMsg, {
							position: 'top-right',
						});
					}
				}
			);
		}
	};

	const addDeliveryPlaybooks = (item) => {
		if (!deliveryPlaybookTitle || !deliveryPlaybookUrl) {
			toast.error('please fill all the input', {
				position: 'top-right',
			});
		} else if (
			!checkUrl(deliveryPlaybookUrl) &&
			deliveryPlaybookTitle &&
			deliveryPlaybookUrl
		) {
			const isItemExist = deliveryPlaybooks.filter(
				(item) =>
					item.fileLocation === deliveryPlaybookUrl ||
					item.title === deliveryPlaybookTitle
			);
			if (isItemExist.length > 0) {
				toast.error('Item already exist', {
					position: 'top-right',
				});
				return;
			}
			setDeliveryPlaybooks([
				{ ...item, id: uniqueIdGenerator() },
				...deliveryPlaybooks,
			]);
		}
	};

	const updateDeliveryPlaybooks = (id, title, fileLocation) => {
		const updatedDeliveryPlaybook = deliveryPlaybooks.map((dplay) => {
			if (dplay.id === id) {
				return {
					...dplay,
					title: title,
					fileLocation: fileLocation,
				};
			}
			return dplay;
		});
		setDeliveryPlaybooks([...updatedDeliveryPlaybook]);
	};

	const removeDeliveryPlaybook = (deleteId) => {
		setDeliveryPlaybooks(deliveryPlaybooks.filter(({ id }) => deleteId !== id));
	};

	useEffect(() => {
		getDevliveryPlaybooks();
	}, []);

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageDeliveryPlayBookModal"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Delivery Playbooks
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li class="activePhaseTab">
										<span>Edit Delivery Playbooks</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div class="roleAdditionContainer ">
											<div class="addRoleButtonAction">
												<div class="selectAddRoleButton">
													<span
														onClick={() => {
															setAddDeliveryPlaybookButton(true);
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setAddDeliveryPlaybookButton(true);
															}
														}}
														tabIndex={0}
														role="button"
														aria-label="Add a New Delivery Playbook"
														aria-expanded={
															addDeliveryPlaybookButton ? 'true' : 'false'
														}
													>
														+&nbsp;Add a New Delivery Playbook
													</span>
													{addDeliveryPlaybookButton ? (
														<Icon
															iconName="ChevronUp"
															onClick={() => {
																setAddDeliveryPlaybookButton(
																	!addDeliveryPlaybookButton
																);
															}}
															tabIndex={0}
															role="button"
															aria-label="close New Delivery Playbook"
															onKeyPress={() => {
																setAddDeliveryPlaybookButton(
																	!addDeliveryPlaybookButton
																);
															}}
														/>
													) : (
														<></>
													)}
												</div>
												{addDeliveryPlaybookButton ? (
													<>
														<div class="addRoleFields">
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">
																	Playbook Name
																</span>
																<input
																	placeholder="Enter Playbook Name"
																	value={deliveryPlaybookTitle}
																	onChange={(e) =>
																		setDeliveryPlaybookTitle(e.target.value)
																	}
																/>
															</div>
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">
																	Playbook Link
																</span>
																<input
																	placeholder="Enter Playbook Link"
																	value={deliveryPlaybookUrl}
																	onChange={(e) =>
																		setDeliveryPlaybookUrl(e.target.value)
																	}
																/>
																{checkUrl(deliveryPlaybookUrl) ? (
																	<span className="toolErrorMsg">
																		Invalid url
																	</span>
																) : (
																	<></>
																)}
															</div>
															<div>
																<span
																	className="addRoleIcon"
																	title="Add Delivery Playbook"
																	tabIndex="0"
																	onClick={() => {
																		addDeliveryPlaybooks({
																			title: deliveryPlaybookTitle,
																			fileLocation: deliveryPlaybookUrl,
																		});
																	}}
																	onKeyPress={() => {
																		addDeliveryPlaybooks({
																			title: deliveryPlaybookTitle,
																			fileLocation: deliveryPlaybookUrl,
																		});
																	}}
																	role="button"
																></span>
															</div>
														</div>
													</>
												) : (
													<></>
												)}
											</div>
										</div>
										<div className="addRolesListContainer addDeliveryPlaybookContainer">
											<ul>
												<li>
													<div class="entryCriteriaList">
														<div class="criteriaHeader">
															<div class="criteriaIcon">Playbook Name</div>
															<div class="criteriaName">Playbook Link</div>

															<div class="criteriaEditDeleteIcons">Delete</div>
														</div>
														<ul>
															{deliveryPlaybooks.map((item) => (
																<li key={item.id}>
																	<div class="criteriaTable">
																		<div class="criteriaIcon">
																			<input
																				placeholder="Enter Playbook Title"
																				value={item.title}
																				onChange={(e) =>
																					updateDeliveryPlaybooks(
																						item.id,
																						e.target.value,
																						item.fileLocation
																					)
																				}
																			/>
																		</div>
																		<div class="criteriaName">
																			<div>
																				<input
																					placeholder="Enter Playbook Link"
																					value={item.fileLocation}
																					onChange={(e) =>
																						updateDeliveryPlaybooks(
																							item.id,
																							item.title,
																							e.target.value
																						)
																					}
																				/>
																				{checkUrl(item.fileLocation) ? (
																					<span className="toolErrorMsg">
																						Invalid url
																					</span>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>

																		<div class="criteriaEditDeleteIcons">
																			<span
																				className="deliverPlaybookDeleteIcon"
																				title="Delete"
																				tabIndex="0"
																				onClick={() => {
																					removeDeliveryPlaybook(item.id);
																				}}
																				onKeyPress={() => {
																					removeDeliveryPlaybook(item.id);
																				}}
																				role="button"
																			></span>
																		</div>
																	</div>
																</li>
															))}
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="saveContinueDetailsContainer">
								<button
									class="forwardButton"
									onClick={() => submitDeliveryPlaybook()}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											submitDeliveryPlaybook();
										}
									}}
									aria-label="Save Delivery Playbook"
								>
									<span class="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AddDeliveryPlayBook;
