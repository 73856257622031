import React, { useState,useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';
import { titleValidator, urlValidator } from '../../helpers/Validation';
// import { Modal } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import ToolsDropdown from './MultiSelectionDropdown';
import { useAxios } from '../../context/AxiosContext';
import Alert from "react-bootstrap/Alert";



const AddTools = ({
	showFields,
	handelFormView,
	data,
	value,
	setValue,
	enableDropdown,
	className,
	searchValue,
	setSearchValue,
	updatedValue,
	setUpdatedValue,
	toolsMapper,
	setToolsMapper,
	getTools,
}) => {
	const { axiosPostService, axiosDeleteService } = useAxios();
	const [showEditTools, setShowEditTools] = useState(false);
	const [deletedToolIds, setDeletedToolIds] = useState([]);
	const [showNewToolsForm, setShowNewToolsForm] = useState(false);
	const [toolsName, setToolsName] = useState('');
	const [toolsUrl, setToolsUrl] = useState('');
	const [error, setError] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)
	const [emailAddress,setEmailAddress] = useState('')
	const checkName = data.filter(
		({ name }) => name.trim().toLowerCase() === toolsName.trim().toLowerCase()
	);
	const { axiosGetService } = useAxios();


	const getIsAdmin = async () => {
        await axiosGetService('api/user/profile').then((res) => {
 
            setIsAdmin(res.data.isAdmin);
        });
    }

	const getContactEmail = async () => {
		await axiosGetService(
		  `api/sdmsettings?sdmKey=SDMToolHelpEmail`
		).then((response) => {
		  setEmailAddress(response.data[0].value);
		});
	  };

	useEffect(() => {
        getIsAdmin();
		getContactEmail();
    }, []);

	useEffect(() => {
		deleteTool([...updatedValue]);
		setValue([...updatedValue]);
		changeToDefault();
	 }, [updatedValue]);
	 
	
	const addTools = async () => {
			if (toolsName && toolsUrl) {
				setError(false);
				const postData = {
					name: toolsName,
					url: toolsUrl,
					isDrafted: true,
				};
				await axiosPostService(`api/sdmtools/addDraft`, postData).then(
					(response) => {
						if (response.data.status) {
							setUpdatedValue([
								...updatedValue,
								{ id: response.data.toolId, name: toolsName, url: toolsUrl }
							]);
							
							setToolsMapper((prevState) => ({
								...prevState,
								[response.data.toolId]: { name: toolsName, url: toolsUrl },
							}));
							changeToDefault();
							setShowEditTools(false);
							getTools();
							toast.info('Tool added successfull', {
								position: 'top-right',
							});
						} else {
							toast.error(response.data.errorMsg, {
								position: toast.POSITION.TOP_RIGHT,
							});
						}
					}
				);
			} else {
				setError(true);
			}
	};

	const changeToDefault = () => {
		setToolsName('');
		setToolsUrl('');
		setError(false);
	};
	const deleteTool = async (toolIds) => {
		const filteredToolIds = toolIds.map(({ id }) => {
			return id;
		});
		const deletedTools = deletedToolIds.filter(
			(id) => !filteredToolIds.includes(id)
		);
		if (deletedTools.length > 0) {
			await axiosDeleteService(
				`api/sdmtools/deleteDraftTool?toolIds=${deletedTools.toString()}`
			);
			setDeletedToolIds([]);
			getTools();
		}
	};
	const checkError = () => {
		if (
			!urlValidator.test(toolsUrl) ||
			toolsUrl.length > 300 ||
			!titleValidator.test(toolsName) ||
			toolsName.trim().length === 0 ||
			checkName.length > 0
		) {
			return true;
		}
		return false;
	};
	const removeTool = (toolId) => {
		const filteredTools = updatedValue.filter(({ id }) => id !== toolId);
		setDeletedToolIds([toolId, ...deletedToolIds]);
		setUpdatedValue(filteredTools);
	};

    const mailtoLink = `mailto:${emailAddress}`;
	return (
		<div
			// className={
			// 	 'Detailswrapper'
			// }
		>
			{/* <div className="DetailswrapperHeading">Add Tools</div> */}
			<div>
				{/* {true ? (
					<></>
				) : (
					<>
						{value.length === 0 ? (
							<span
								className="addingInputButton"
								tabIndex={0}
								onClick={() => handelFormView('tools')}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handelFormView('tools');
									}
								}}
								aria-label={`click to Add tools`}
								title="Add"
								role="Button"
							>
								+ Add
							</span>
						) : (
							<></>
						)}
					</>
				)} */}
				{true ? (
					<>
						<div className="row">
							<div className="col-md-8 pr-0 mb-2">

							<ToolsDropdown
								className={className}
								data={data}
								enableDropdown={enableDropdown}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								updatedValue={updatedValue}
								setUpdatedValue={setUpdatedValue}
								label="Tools"
								pageType={'Activity'}
							/>

							</div>
							
							
							
							<div className="col-md-4">
								{showNewToolsForm ? (
									<>
									{isAdmin ? ( <>
										<input
											onFocus={() => setSearchValue('')}
											value={toolsName}
											onChange={(e) => setToolsName(e.target.value)}
											placeholder="Enter name of the tool"
										/>
										<input
											onFocus={() => setSearchValue('')}
											value={toolsUrl}
											onChange={(e) => setToolsUrl(e.target.value)}
											placeholder="Enter url"
										/>

										<button
											onClick={() => {
												addTools();
											}}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													addTools();
												}
											}}
											disabled={checkError()}
										>
											Add
										</button>
										</>
								 ): ( <Alert variant="warning"  style={{marginTop:'10px'}}>
								 Tools cannot be added as of now, please reach out to &nbsp;
								 <a href={mailtoLink} target="_blank" rel="noopener noreferrer">{emailAddress}</a>
								  </Alert>
								) }
									</>
								) : (
									<>
									<span
										className="addeditMoreTools"
										onClick={() => {
											setShowEditTools(true);
										}}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowEditTools(true);
											}
										}}
										aria-label={`Can’t find tool you are looking for? Add a New One`}
										role="button"
									>
										Can’t find your tool? Add a New One
									</span>
									  {showEditTools ?
										<Modal
											size="md"
											centered
											show={true}
											onHide={() => {
												/* Not calling any function to close modal*/
											}}
											dialogClassName="modal-90w"
										>
											<Modal.Header>
												<Modal.Title>
													<div>
														<span className='newToolDialogHeading'>Add New Tool</span>
													</div>
												</Modal.Title>
												<Icon
													className="relatedContentClose"
													iconName="ChromeClose"
													title="Close"
													onClick={() => setShowEditTools(false)}
												/>
											</Modal.Header>
											<Modal.Body>
												<div className='row'>
													<div className='col-md-12'>
													<input
													className='EditInputFields'
											onFocus={() => setSearchValue('')}
											value={toolsName}
											onChange={(e) => setToolsName(e.target.value)}
											placeholder="Enter name of the tool"
										/>
										
										{toolsName && !titleValidator.test(toolsName) ? (
											<span className="errorMsg">Invalid name</span>
										) : (
											<></>
										)}
										{checkName.length > 0 ? (
											<span className="errorMsg">Duplicate tool name</span>
										) : (
											<></>
										)}
													</div>
												</div>
												<div className='row mt-1'>
													<div className='col-md-12'>
													<input
													className='EditInputFields'
											onFocus={() => setSearchValue('')}
											value={toolsUrl}
											onChange={(e) => setToolsUrl(e.target.value)}
											placeholder="Enter url"
										/>{!urlValidator.test(toolsUrl) && toolsUrl ? (
											<span className="toolErrorMsgedit">Invalid url</span>
										) : (
											<></>
										)}
										
										{error ? (
											<span className="errorMsg">
												Make sure your inputs are correct
											</span>
										) : (
											<></>
										)}
													</div>
												</div>
											
											</Modal.Body>
											<Modal.Footer>
												<div className="mr-0">
													<button
														className="cancelBusinessContent"
														onClick={() => {
															setShowEditTools(false);
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowEditTools(false);
															}
														}}
													>
														Cancel
													</button>
													<button
														className="addBtnBusinessContent"
														onClick={() => {
															addTools();
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
															 addTools();
															}
														}}
													>
														Add Tool
													</button>
												</div>
											</Modal.Footer>
										</Modal>
										:
										<></>
									}</>
								)}
							</div>
							
						</div>
						{updatedValue.length > 0 ? (
							<ul
								className={
									' afterSaveListEdit beforEditSavedListEdit pl-0'
			
								}
							>
								{updatedValue.map((item) => (
									<li>
										<a
											href={toolsMapper[item.id] && toolsMapper[item.id].url}
											target="_blank"
										>
											{toolsMapper[item.id] && toolsMapper[item.id].name}
										</a>
										{true ? (
											<Icon
												iconName="Cancel"
												onClick={() => removeTool(item.id)}
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														removeTool(item.id);
													}
												}}
												aria-label={`click here to remove ${
													toolsMapper[item.id] && toolsMapper[item.id].name
												} as tool`}
												role="button"
											/>
										) : (
											<></>
										)}
									</li>
								))}
							</ul>
						) : (
							<></>
						)}
					</>
				) : (
					<>
						{value.length > 0 ? (
							<ul
								className={
								 ' afterSaveListEdit beforEditSavedListEdit pl-0'
										
								}
							>
								{value.map((item) => (
									<li>
										<a
											href={toolsMapper[item.id] && toolsMapper[item.id].url}
											target="_blank"
										>
											{toolsMapper[item.id] && toolsMapper[item.id].name}
										</a>
										{true ? (
											<Icon
												iconName="Cancel"
												onClick={() => removeTool(item.id)}
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														removeTool(item.id);
													}
												}}
											/>
										) : (
											<></>
										)}
									</li>
								))}
							</ul>
						) : (
							<></>
						)}
					</>
				)}
			</div>

			{/* {showFields.includes('tools') ? (
				<div className="DetailswrapperRightSec">
					<button
						onClick={() => {
							handelFormView('tools');
							setUpdatedValue([...value]);
							changeToDefault();
						}}
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handelFormView('tools');
								setUpdatedValue([...value]);
								changeToDefault();
							}
						}}
					>
						Close
					</button>
					<button
						onClick={() => {
							deleteTool([...updatedValue]);
							handelFormView('tools');
							setValue([...updatedValue]);
							changeToDefault();
						}}
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								deleteTool([...updatedValue]);
								handelFormView('tools');
								setValue([...updatedValue]);
								changeToDefault();
							}
						}}
					>
						Save
					</button>
				</div>
			) : (
				<>
					{value.length > 0 ? (
						<span
							className="addingInputButton"
							onClick={() => handelFormView('tools')}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handelFormView('tools');
								}
							}}
							aria-label={`click to Edit tools`}
							title="Edit"
							role="Button"
						>
							Edit
						</span>
					) : (
						<></>
					)}
				</>
			)} */}
		</div>
	);
};

export default AddTools;
