import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../../context/LeftMenuCollapseContext';
import Spinner from '../../../../helpers/Spinner';
import { v4 as uniqueIdGenerator } from 'uuid';

import ServiceCommandUnit from './ServiceCommandUnit';
import AddToGroupDropdown from './AddToGroupDropdown';

// function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: 'white',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: 'white',
});

const getDynamicFilterData = (phaseDynamicFilterTag, role) => {
	const selectedFiltersIds = [];
	const filterIds = Object.keys(phaseDynamicFilterTag);
	for (const id of filterIds) {
		if (phaseDynamicFilterTag[Number(id)].selectedTagId.length > 0) {
			selectedFiltersIds.push(
				`${Number(id)}` +
					'-' +
					phaseDynamicFilterTag[Number(id)].selectedTagId.toString()
			);
		}
	}
	if (role.id !== 0) {
		selectedFiltersIds.push(`16-${role.id}`);
	}
	return selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0;
};

const Sequence = ({
	expandedActivityGroups,
	handleGroupExpand,
	activityGroups,
	setActivityGroups,
	setShowGroupForm,
	groupInformation,
	coOwners,
	userAlias,
	setSelectedGroupDetails,
	setSelectedSubgroupDetails,
	setShowSubgroupEditForm,
	setExpandedActivityGroups,
	searchInput,
	solutionMethodsData,
	setSolutionMethodsData,
	phasesData,
	setPhasesData,
}) => {
	const { axiosGetService } = useAxios();
	const { complexity, phaseHashtags, role, phaseDynamicFilterTag } =
		useLeftMenuCollapse();
	const [showAddToGroupDropdwon, setShowAddToGroupDropdown] = useState(false);
	const [selectedActivityId, setSelectedActivityId] = useState(0);

	const findSelectedActivity = (dragNDropId) => {
		for (const group of activityGroups) {
			if (group.activities && group.activities.length > 0) {
				for (const activity of group.activities) {
					if (activity.dragNDropId === dragNDropId) {
						return activity;
					}
				}
			}
		}

		return null;
	};

	const detachActivityFromGroup = (activities, sourceActivityId) => {
		const updatedActivities = activities.filter(
			({ dragNDropId }) => sourceActivityId !== dragNDropId
		);
		return updatedActivities;
	};

	const detachActivityFromSubgroup = (
		groupDetails,
		subgroups,
		sourceActivity
	) => {
		const updatedGroups = [];
		const updatedSubgroupActivities = subgroups.activities.filter(
			({ dragNDropId }) => dragNDropId !== sourceActivity.dragNDropId
		);
		for (const group of activityGroups) {
			const groupData = { ...group };
			if (groupData.groupId === groupDetails.groupId) {
				groupData.activities = [];
				for (const activity of group.activities) {
					const activityClone = { ...activity };
					if (
						activity.isSubgroup &&
						activity.subGroupId === subgroups.subGroupId
					) {
						activityClone.activities = updatedSubgroupActivities;
					}
					groupData.activities.push(activityClone);
				}
				groupData.activities.push(sourceActivity);
			}
			updatedGroups.push(groupData);
		}
		setActivityGroups(updatedGroups);
	};

	const makeActivityOrphan = (selectedGroupId, dragNDropId) => {
		const updatedGroups = [];
		const selectedActivity = findSelectedActivity(dragNDropId);
		const groupDetails = {
			groupId: 0,
			groupName: null,
			isParentActivity: selectedActivity.isParentActivity,
			isDrafted: true,
			activities: [selectedActivity],
			isMethodOwnerOrCoOwner: true,
		};
		for (const group of activityGroups) {
			if (group.groupId === selectedGroupId) {
				group.activities = detachActivityFromGroup(
					group.activities,
					dragNDropId
				);
				updatedGroups.push(group);
				updatedGroups.push(groupDetails);
			} else {
				updatedGroups.push(group);
			}
		}
		setActivityGroups(updatedGroups);
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const sourceIndex = result.source.index;
		const destIndex = result.destination.index;
		if (result.type === 'droppableItem') {
			const updatedItems = reorder(activityGroups, sourceIndex, destIndex);
			setActivityGroups(updatedItems);
		} else if (result.type === 'droppableSubItem') {
			const itemSubItemMap = activityGroups.reduce((acc, item) => {
				acc[item.dragNDropId] = item.activities;
				return acc;
			}, {});

			const sourceParentId = result.source.droppableId;
			const destParentId = result.destination.droppableId;

			const sourceSubItems = itemSubItemMap[sourceParentId];
			const destSubItems = itemSubItemMap[destParentId];
			let newItems = [...activityGroups];
			/** In this case subItems are reOrdered inside same Parent */
			if (sourceParentId === destParentId) {
				const reorderedSubItems = reorder(
					sourceSubItems,
					sourceIndex,
					destIndex
				);
				newItems = newItems.map((item) => {
					if (
						item.dragNDropId === sourceParentId &&
						item.isMethodOwnerOrCoOwner &&
						!item.isGroupRefered &&
						!item.isGroupReferredWithActivity
					) {
						item.activities = reorderedSubItems;
					}
					return item;
				});
				setActivityGroups(newItems);
			} else {
				const checkUserAccess = activityGroups.filter(
					({ dragNDropId }) =>
						dragNDropId === sourceParentId || dragNDropId === destParentId
				);
				if (
					checkUserAccess.length > 1 &&
					checkUserAccess[0].isMethodOwnerOrCoOwner &&
					checkUserAccess[1].isMethodOwnerOrCoOwner &&
					!checkUserAccess[1].isGroupRefered &&
					!checkUserAccess[0].isGroupRefered &&
					!checkUserAccess[1].isGroupReferredWithActivity &&
					!checkUserAccess[0].isGroupReferredWithActivity
				) {
					const newSourceSubItems = [...sourceSubItems];
					const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);
					const newDestSubItems = [...destSubItems];
					newDestSubItems.splice(destIndex, 0, draggedItem);
					newItems = newItems.map((item) => {
						if (item.dragNDropId === sourceParentId) {
							item.activities = newSourceSubItems;
						} else if (item.dragNDropId === destParentId) {
							item.activities = newDestSubItems;
						}
						return item;
					});
					setActivityGroups(newItems);
				}
			}
		} else if (result.type === 'droppableSubgroupActivity') {
			const sourceParentId = result.source.droppableId;
			const destParentId = result.destination.droppableId;
			const subGroupsItems = {};
			for (const group of activityGroups) {
				for (const activity of group.activities) {
					if (activity.isSubgroup) {
						subGroupsItems[activity.dragNDropId] = [...activity.activities];
					}
				}
			}
			const sourceSubItems = subGroupsItems[sourceParentId];
			const destSubItems = subGroupsItems[destParentId];
			const newItems = [...activityGroups];
			if (sourceParentId === destParentId) {
				const reorderedSubItems = reorder(
					sourceSubItems,
					sourceIndex,
					destIndex
				);
				const updatedActivityGroups = [];
				for (const group of newItems) {
					for (const activity of group.activities) {
						if (
							activity.isSubgroup &&
							activity.dragNDropId === sourceParentId &&
							!group.isGroupRefered &&
							!group.isGroupReferredWithActivity
						) {
							activity.activities = reorderedSubItems;
						}
					}
					updatedActivityGroups.push(group);
				}
				setActivityGroups(updatedActivityGroups);
			}
		}
	};

	const handleGroupCollapse = (type, phaseName, groupId) => {
		const id = `${phaseName}_${groupId}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
	};

	const handleSubgroupExpand = (type, subgroupId, subGroupName, phaseName) => {
		const id = `${phaseName}_${subgroupId}_${subGroupName}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
	};

	const handleSolutionMethodsExpand = (type, solutionMethod) => {
		const id = `${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
		if (!solutionMethodsData[solutionMethod.solutionMethodId]) {
			getSolutionMethodData(solutionMethod);
		}
	};

	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};
	const cloneGroup = (data) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: false,
					isGroupRefered: data[group].isGroupRefered,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: false,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		return activityGroupClone;
	};

	const getSolutionMethodData = async (item) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${item.solutionMethodId}/${
				item.isMethodOwnerOrCoOwner
			}/${0}/${0}/${0}/${0}/false/0`
		).then((response) => {
			const data = [];
			for (const phase of response.data.phasesList) {
				data.push({
					...phase,
					groups: cloneGroup(phase.activityGroupsData),
				});
			}
			setSolutionMethodsData({
				...solutionMethodsData,
				[item.solutionMethodId]: data,
			});
		});
	};

	const getPhaseData = async (phaseId, solutionMethodId) => {
		const encodedHashtags = phaseHashtags.map((item) => {
			return encodeURIComponent(item);
		});
		await axiosGetService(
			`api/sdmphase/getphasemergedactivities/${solutionMethodId}/${phaseId}/${
				complexity.id
			}/${coOwners.includes(userAlias)}/${getDynamicFilterData(
				phaseDynamicFilterTag,
				role
			)}/${
				searchInput.trim().length > 0
					? encodeURIComponent(searchInput.trim())
					: 0
			}/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}/false/0`
		).then((response) => {
			setPhasesData((prevState) => ({
				...prevState,
				[phaseId]: cloneGroup(response.data.activityGroupsData),
			}));
		});
	};

	const handleSolutionMethodPhaseExpand = (phaseId, solutionMethodId) => {
		const id = `${phaseId}_${solutionMethodId}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
		if (!phasesData[phaseId]) {
			getPhaseData(phaseId, solutionMethodId);
		}
	};

	const renderOrphanActivity = (item, group) => {
		return (
			<>
				{group.activities.length !== 0 ? (
					group.activities.map((activity) => (
						<div
							className="activityListMainContainer"
							key={activity.dragNDropId}
						>
							<div className="activityContainer">
								<span className="activitytypeColor" />
								{activity.isMethodOwnerOrCoOwner && activity.isDrafted ? (
									<span
										className={
											activity.isDrafted ? 'draftActivity' : 'nonDraftActivity'
										}
									>
										Draft
									</span>
								) : (
									<></>
								)}
								<div className="activityTitle" title={activity.activityTitle}>
									<span className="titleOfActivity">
										{activity.activityTitle}
									</span>
								</div>
							</div>
						</div>
					))
				) : (
					<></>
				)}
			</>
		);
	};

	const renderGroup = (item, group, isDelivery) => {
		return (
			<div className="activityListMainContainer activityGroupParent">
				<div
					className="activityContainer activityGroup"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							handleGroupCollapse('userUpdate', item.phaseName, group.groupId);
						}
					}}
					onClick={() =>
						handleGroupCollapse('userUpdate', item.phaseName, group.groupId)
					}
				>
					<span className="activitytypeColor" />
					<div className="activityTitle" title={group.groupName}>
						{expandedActivityGroups.includes(
							`${item.phaseName}_${group.groupId}`
						) ? (
							<Icon iconName="CaretDownSolid8" />
						) : (
							<Icon iconName="CaretRightSolid8" />
						)}
						<span aria-label={group.groupName} className="titleOfActivity">
							{group.groupName}
						</span>

						<span
							className="groupColor"
							style={{ background: group.colourCode }}
						></span>
					</div>
				</div>
				{expandedActivityGroups.includes(
					`${item.phaseName}_${group.groupId}`
				) ? (
					group.activities.length !== 0 ? (
						group.activities.map((activity) => (
							<div key={item.dragNDropId}>
								{activity.isSubgroup ? (
									<div
										className={
											activity.isParentActivity || isDelivery
												? 'delivery subGroupContainer'
												: !activity.isParentActivity
												? 'orangeDelivery subGroupContainer'
												: 'subGroupContainer'
										}
									>
										<div className="activityChildContainer">
											<div class="activityListMainContainer activityGroupParent">
												<div
													class="activityContainer activityGroup"
													tabIndex="0"
													onKeyPress={(e) => {
														if (e.which === 13) {
															handleSubgroupExpand(
																'userUpdate',
																activity.subGroupId,
																activity.subGroupName,
																item.phaseName
															);
														}
													}}
													onClick={() => {
														handleSubgroupExpand(
															'userUpdate',
															activity.subGroupId,
															activity.subGroupName,
															item.phaseName
														);
													}}
												>
													<span class="activitytypeColor"></span>
													<div
														class="activityTitle"
														title={activity.subGroupName}
													>
														{expandedActivityGroups.includes(
															`${item.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
														) ? (
															<Icon iconName="CaretDownSolid8" />
														) : (
															<Icon iconName="CaretRightSolid8" />
														)}
														<span
															aria-label={activity.subGroupName}
															class="titleOfActivity"
														>
															{activity.subGroupName}
														</span>
													</div>
												</div>
												{expandedActivityGroups.includes(
													`${item.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
												) &&
													activity.activities.map((subgroupActivity) => (
														<div
															key={subgroupActivity.dragNDropId}
															className={
																subgroupActivity.isParentActivity || isDelivery
																	? 'delivery'
																	: !subgroupActivity.isParentActivity
																	? 'orangeDelivery'
																	: ''
															}
														>
															<div className="activityChildContainer">
																<div>
																	<div
																		className="activityChildTitle"
																		title={subgroupActivity.activityTitle}
																	>
																		{subgroupActivity.isMethodOwnerOrCoOwner &&
																		subgroupActivity.isDrafted ? (
																			<span
																				className={
																					subgroupActivity.isDrafted
																						? 'draftActivity'
																						: 'nonDraftActivity'
																				}
																			>
																				Draft
																			</span>
																		) : (
																			<></>
																		)}
																		<span className="titleOfActivity">
																			{subgroupActivity.activityTitle}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													))}
											</div>
										</div>
									</div>
								) : (
									<>
										<div
											className={
												activity.isParentActivity || isDelivery
													? 'delivery'
													: !activity.isParentActivity
													? 'orangeDelivery'
													: ''
											}
										>
											<div className="activityChildContainer">
												<div>
													<div
														className="activityChildTitle"
														title={activity.activityTitle}
													>
														{activity.isMethodOwnerOrCoOwner &&
														activity.isDrafted ? (
															<span
																className={
																	activity.isDrafted
																		? 'draftActivity'
																		: 'nonDraftActivity'
																}
															>
																Draft
															</span>
														) : (
															<></>
														)}
														<span className="titleOfActivity">
															{activity.activityTitle}
														</span>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						))
					) : (
						<div className="activityChildContainer">
							<div className="activityChildTitle noActivities">
								<span className="titleOfActivity">No Activities Found</span>
							</div>
						</div>
					)
				) : (
					<></>
				)}
			</div>
		);
	};

	const renderSolutionMethod = (solutionMethod, isDelivery) => {
		return (
			<div className="activityListMainContainer activityGroupParent">
				<div
					className="activityContainer activityGroup"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							handleSolutionMethodsExpand('userUpdate', solutionMethod);
						}
					}}
					onClick={() =>
						handleSolutionMethodsExpand('userUpdate', solutionMethod)
					}
				>
					<span className="activitytypeColor" />
					<div
						className="activityTitle"
						title={solutionMethod.solutionMethodName}
					>
						{expandedActivityGroups.includes(
							`${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`
						) ? (
							<Icon iconName="CaretDownSolid8" />
						) : (
							<Icon iconName="CaretRightSolid8" />
						)}
						<span
							aria-label={solutionMethod.solutionMethodName}
							className="titleOfActivity"
						>
							{solutionMethod.solutionMethodName}
						</span>
					</div>
				</div>
				{expandedActivityGroups.includes(
					`${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}`
				) ? (
					solutionMethodsData[solutionMethod.solutionMethodId] ? (
						solutionMethodsData[solutionMethod.solutionMethodId].map(
							(phase) => (
								<div key={phase.phaseId}>
									<div
										className={
											phase.isParentActivity || isDelivery
												? 'delivery subGroupContainer'
												: !phase.isParentActivity
												? 'orangeDelivery subGroupContainer'
												: 'subGroupContainer'
										}
									>
										<div className="activityChildContainer">
											<div class="activityListMainContainer activityGroupParent">
												<div
													class="activityContainer activityGroup"
													tabIndex="0"
													onKeyPress={(e) => {
														if (e.which === 13) {
															handleSolutionMethodPhaseExpand(
																phase.phaseId,
																solutionMethod.solutionMethodId
															);
														}
													}}
													onClick={() => {
														handleSolutionMethodPhaseExpand(
															phase.phaseId,
															solutionMethod.solutionMethodId
														);
													}}
												>
													<span class="activitytypeColor"></span>
													<div class="activityTitle" title={phase.phaseName}>
														{expandedActivityGroups.includes(
															`${phase.phaseId}_${solutionMethod.solutionMethodId}`
														) ? (
															<Icon iconName="CaretDownSolid8" />
														) : (
															<Icon iconName="CaretRightSolid8" />
														)}
														<span
															aria-label={phase.phaseName}
															class="titleOfActivity"
														>
															{phase.phaseName}
														</span>
													</div>
												</div>
												{expandedActivityGroups.includes(
													`${phase.phaseId}_${solutionMethod.solutionMethodId}`
												) ? (
													<>
														{phasesData[phase.phaseId] ? (
															phasesData[phase.phaseId].map((group) => (
																<div
																	className={
																		group.isParentActivity ? 'delivery' : ''
																	}
																	key={group.dragNDropId}
																>
																	<div className="activityChildContainer">
																		{group.isSolutionMethod ? (
																			<>{renderSolutionMethod(group, true)}</>
																		) : group.groupId ? (
																			<>
																				{renderGroup(
																					phasesData[phase.phaseId],
																					group,
																					true
																				)}
																			</>
																		) : (
																			<>
																				{renderOrphanActivity(
																					phasesData[phase.phaseId],
																					group
																				)}
																			</>
																		)}
																	</div>
																</div>
															))
														) : (
															<div className="activityChildContainer">
																<Spinner />
															</div>
														)}
													</>
												) : (
													<></>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						)
					) : (
						<div className="activityChildContainer">
							<Spinner />
						</div>
					)
				) : (
					<></>
				)}
			</div>
		);
	};

	return (
		<DragDropContext onDragEnd={(result) => onDragEnd(result)}>
			<Droppable droppableId="droppable" type="droppableItem">
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{activityGroups.map((item, index) => (
							<>
								{item.isSolutionMethod ? (
									<Draggable
										key={item.solutionMethodName}
										draggableId={item.dragNDropId}
										index={index}
									>
										{(provided, snapshot) => (
											<div>
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<div
														className={
															item.isParentActivity || item.isSolutionMethod
																? 'delivery'
																: ''
														}
														key={item.dragNDropId}
													>
														<div className="referredSolutionMethod">
															{renderSolutionMethod(item, true)}
														</div>
													</div>
												</div>
												{provided.placeholder}
											</div>
										)}
									</Draggable>
								) : (
									<Draggable
										key={
											item.groupName
												? item.dragNDropId
												: item.activities[0].dragNDropId
										}
										draggableId={
											item.groupName
												? item.dragNDropId
												: item.activities[0].dragNDropId
										}
										index={index}
									>
										{(provided, snapshot) => (
											<div>
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													{item.groupName ? (
														<>
															<div
																key={item.dragNDropId}
																className={
																	item.isParentActivity ? 'delivery' : ''
																}
															>
																<div className="activityListMainContainer activityGroupParent">
																	<div
																		className="activityContainer activityGroup"
																	
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				handleGroupExpand(
																					item.groupId.toString()
																				);
																			}
																		}}
																		onClick={() =>
																			handleGroupExpand(item.groupId.toString())
																		}
																	>
																		<span className="activitytypeColor" />
																		<div
																			className="activityTitle"
																			title={item.groupName}
																		>
																			{expandedActivityGroups.includes(
																				item.groupId.toString()
																			) ? (
																				<Icon iconName="CaretDownSolid8" />
																			) : (
																				<Icon iconName="CaretRightSolid8" />
																			)}
																			<span
																				aria-label={item.groupName}
																				className="titleOfActivity"
																			>
																				{item.groupName}
																			</span>
																		</div>
																		{item.isMethodOwnerOrCoOwner &&
																		!item.isGroupRefered &&
																		!item.isGroupReferredWithActivity ? (
																			<>
																				<span
																					className="phaseAddIcon"
																					onClick={() => {
																						setSelectedGroupDetails(item);
																						setShowGroupForm(true);
																					}}
																					tabIndex="0"
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setSelectedGroupDetails(item);
																							setShowGroupForm(true);
																						}
																					}}
																					title="Add subgroup"
																				></span>
																				<span
																					class="ActivityEditIcon"
																					title="Edit Group"
																					onClick={() => {
																						setSelectedGroupDetails(item);
																						setShowGroupForm(true);
																					}}
																					tabIndex="0"
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setSelectedGroupDetails(item);
																							setShowGroupForm(true);
																						}
																					}}
																				></span>
																				{/* <Icon
																					className="phaseLandEditMode"
																					title="Edit"
																					onClick={() => {
																						setSelectedGroupDetails(item);
																						setShowGroupForm(true);
																					}}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setSelectedGroupDetails(item);
																							setShowGroupForm(true);
																						}
																					}}
																					iconName="EditSolid12"
																				/> */}
																			</>
																		) : (
																			<></>
																		)}
																		<span
																			className="groupColor"
																			style={{ background: item.colourCode }}
																		></span>
																	</div>
																</div>
															</div>
															<ServiceCommandUnit
																subItems={item.activities}
																type={item.groupId.toString()}
																expandedActivityGroups={expandedActivityGroups}
																group={item}
																dragNDropId={item.dragNDropId}
																makeActivityOrphan={makeActivityOrphan}
																coOwners={coOwners}
																userAlias={userAlias}
																setSelectedSubgroupDetails={
																	setSelectedSubgroupDetails
																}
																setShowSubgroupEditForm={
																	setShowSubgroupEditForm
																}
																setSelectedGroupDetails={
																	setSelectedGroupDetails
																}
																setShowGroupForm={setShowGroupForm}
																detachActivityFromSubgroup={
																	detachActivityFromSubgroup
																}
																setExpandedActivityGroups={
																	setExpandedActivityGroups
																}
																activityGroups={activityGroups}
																setActivityGroups={setActivityGroups}
															/>
														</>
													) : (
														<div
															className={
																item.isParentActivity ? 'delivery' : ''
															}
															key={item.activities[0].dragNDropId}
														>
															<div
																className="activityListMainContainer"
																key={item.activities[0].dragNDropId}
															>
																<div className="activityContainer">
																	<span className="activitytypeColor" />
																	{coOwners.includes(userAlias) &&
																	item.activities[0].isDrafted ? (
																		<span
																			className={
																				item.activities[0].isDrafted
																					? 'draftActivity'
																					: 'nonDraftActivity'
																			}
																		>
																			Draft
																		</span>
																	) : (
																		<></>
																	)}
																	<div
																		className="activityTitle"
																		title={item.activities[0].activityTitle}
																	>
																		<span className="titleOfActivity">
																			{item.activities[0].activityTitle}
																		</span>
																	</div>
																	{showAddToGroupDropdwon &&
																	selectedActivityId ===
																		item.activities[0].dragNDropId ? (
																		<AddToGroupDropdown
																			className={`addToGroupDropdown`}
																			data={groupInformation}
																			setValue={setActivityGroups}
																			value={activityGroups}
																			enableDropdown={true}
																			setShowAddToGroupDropdown={
																				setShowAddToGroupDropdown
																			}
																			selectedActivityId={selectedActivityId}
																		/>
																	) : item.activities[0]
																			.isMethodOwnerOrCoOwner ? (
																		<span className="activityAddtoGroup">
																			Add to group
																			<span
																				onClick={() => {
																					setSelectedActivityId(
																						item.activities[0].dragNDropId
																					);
																					setShowAddToGroupDropdown(true);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						setSelectedActivityId(
																							item.activities[0].dragNDropId
																						);
																						setShowAddToGroupDropdown(true);
																					}
																				}}
																				className="phaseAddIcon"
																				tabIndex="0"
																				title="Add to a group"
																			></span>
																		</span>
																	) : (
																		<></>
																	)}
																</div>
															</div>
														</div>
													)}
												</div>
												{provided.placeholder}
											</div>
										)}
									</Draggable>
								)}
							</>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default Sequence;
