import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';

import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';

function ComplexityModal({
	templateData,
	setShowComplexityModal,
	templateTitle,
	accepedtRejectedTemplate,
	setAccepedtRejectedTemplate,
	setSuccessMsg,
	singleView,
	submittedComplexity,
}) {
	const [modalShow, setModalShow] = React.useState(true);
	const [checkedItems, setCheckedItems] = useState({});
	const [complexitiesData, setComplexitiesData] = useState([]);
	const [errorMessage, setErrorMessage] = useState(false);
	const [messagesType, setMessagesType] = useState({});
	const [retainLocTemplate, setRetainLocTemplate] = useState(true);
	const { axiosPostService, axiosGetService } = useAxios();
	const [spinner, setSpinner] = useState(templateData.isLocalized);
	const [localizedTemplateCount, setLocalizedTemplateCount] = useState(0);
	const [sampleTemplateCount, setSampleTemplateCount] = useState(0);
	const history = useHistory();

	const getComplexities = () => {
		const complexityObject = {};
		const complexitiesModal = [];
		if (templateData.isCritical || templateData.isHigh) {
			complexityObject['Critical/High'] = true;
			complexitiesModal.push('Critical/High');
		}
		// if (templateData.isHigh) {
		// 	complexityObject['High'] = true;
		// 	complexitiesModal.push('High');
		// }
		if (templateData.isMedium || templateData.isLow) {
			complexityObject['Medium/Low'] = true;
			complexitiesModal.push('Medium/Low');
		}

		// if (templateData.isLow) {
		// 	complexityObject['Low'] = true;
		// 	complexitiesModal.push('Low');
		// }
		setCheckedItems(complexityObject);
		setComplexitiesData(complexitiesModal);
	};

	const updateAllCheckbox = (allValue) => {
		const complexities = {};
		for (const id in complexitiesData) {
			complexities[complexitiesData[id]] = allValue;
		}
		if (complexitiesData.length > 1) {
			complexities['All'] = allValue;
		}
		setCheckedItems(complexities);
	};

	const updateComplexitiesArray = (event) => {
		if (event.target.name === 'All' && event.target.checked) {
			updateAllCheckbox(true);
		} else if (event.target.name === 'All' && !event.target.checked) {
			updateAllCheckbox(false);
		} else {
			setCheckedItems({
				...checkedItems,
				[event.target.name]: event.target.checked,
				All: false,
			});
		}
	};

	const renderComplexitiesForm = () => {
		return complexitiesData.map((data) => {
			return (
				<label className="NotificationChkboxContainer">
					<input
						name={data}
						type="checkbox"
						checked={checkedItems[data]}
						onChange={updateComplexitiesArray}
					/>
					<span className="CustomCheckmark"></span>
					<span className="complexityLabel">{data}</span>
				</label>
			);
		});
	};

	const getSampleTemplateCount = async () => {
		await axiosGetService(
			`api/sdmtemplate/getLocTemplate/${templateData.templateId}/${templateData.complexityId}`
		).then((response) => {
			setLocalizedTemplateCount(response.data.length);
		});
		await axiosGetService(
			`api/sdmtemplate/getSampleTemplate/${templateData.templateId}/${templateData.complexityId}`
		).then((response) => {
			setSampleTemplateCount(response.data.length);
		});
	};

	const publishTemplate = async () => {
		setSpinner(true);
		const postData = {
			id: templateData.id,
			stateId: 3,
			templateId: templateData.templateId,
			complexityId: templateData.complexityId,
			complexityList: templateData.complexityList,
			isCritical: checkedItems['Critical/High'] ? true : false,
			isMedium: checkedItems['Medium/Low'] ? true : false,
			isHigh: false,
			isLow: false,
			// isHigh: checkedItems['High'] ? true : false,
			// isLow: checkedItems['Low'] ? true : false,
			filePath: templateData.filePath,
			uniqueid: templateData.uniqueId,
			uniqueIdOld: templateData.uniqueIdOld,
			filePathOld: templateData.filePathOld,
			title: templateData.title,
			contributorEmail: templateData.contributorEmail,
			reviewerEmail: templateData.reviewerEmail,
			isLocalized: templateData.isLocalized,
			regionName: templateData.regionName,
			languageName: templateData.languageName,
			countryName: templateData.countryName,
			languageId: templateData.languageId,
			countryId: templateData.countryId,
			retainLocTemplates:
				(templateData.fileType !== templateData.fileTypeOld &&
					(sampleTemplateCount > 0 || localizedTemplateCount > 0)) ||
				(templateData.fileType === templateData.fileTypeOld &&
					complexitiesData.length > 1 &&
					!checkedItems['All'] &&
					(sampleTemplateCount > 0 || localizedTemplateCount > 0))
					? retainLocTemplate
					: false,
		};

		await axiosPostService(
			'api/sdmtemplate/inserttemplateapprover',
			postData
		).then((response) => {
			setAccepedtRejectedTemplate([
				...accepedtRejectedTemplate,
				templateData.id,
			]);
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=approvalSubmissionMessage&title=${encodeURIComponent(
				templateData.title
			)}`
		).then((response) => {
			setSuccessMsg(response.data);
			setSpinner(false);
			setModalShow(false);
			setShowComplexityModal(false);
			if (singleView) {
				setTimeout(() => {
					history.push('/template/crowdsourcing/approve/all');
				}, 2000);
			}
		});
	};

	const checkForAllCheckBox = () => {
		let allChecked = true;
		let UncheckedValueCount = 0;
		let allValue = false;
		for (const checkboxItem in checkedItems) {
			if (checkboxItem !== 'All' && !checkedItems[checkboxItem]) {
				allChecked = false;
			}
			if (checkboxItem !== 'All' && !checkedItems[checkboxItem]) {
				UncheckedValueCount = UncheckedValueCount + 1;
			}
			if (checkboxItem === 'All') {
				allValue = checkedItems[checkboxItem];
			}
		}
		if (UncheckedValueCount === complexitiesData.length) {
			setErrorMessage(true);
		} else {
			setErrorMessage(false);
		}
		if (!allValue && allChecked && complexitiesData.length > 1) {
			updateAllCheckbox(true);
		}
	};

	useEffect(() => {
		getComplexities();
	}, []);

	useEffect(() => {
		if (complexitiesData.length > 0) {
			updateAllCheckbox(true);
		}
	}, [complexitiesData]);

	useEffect(() => {
		checkForAllCheckBox();
	}, [checkedItems]);

	useEffect(() => {
		if (templateData.isLocalized) {
			// setSpinner(true);
			publishTemplate();
		}
	}, []);

	useEffect(() => {
		getSampleTemplateCount();
	}, []);

	if (spinner) {
		return <CrowdSourcingSpinner />;
	}

	return (
		<Modal
			className="approverComplexityModal"
			show={modalShow && !templateData.isLocalized}
			onHide={() => {
				setModalShow(false);
				setShowComplexityModal(false);
			}}
			size="lg"
			centered
		>
			<>
				<Modal.Header>
					Approve new template for "{templateData.title}"
					<Icon
						iconName="Cancel"
						title="Cancel"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setModalShow(false);
								setShowComplexityModal(false);
							}
						}}
						onClick={() => {
							setModalShow(false);
							setShowComplexityModal(false);
						}}
					/>
				</Modal.Header>
				<Modal.Body>
					<div className="templateApproverContainer">
						<div className="approverPublishMessage">
							{complexitiesData.length > 1 ? (
								<p>
									The template submitted by the contributor is for complexity "
									{submittedComplexity}
									". However, the same template is applicable to complexity{' '}
									{complexitiesData
										.filter((complexity) => complexity !== submittedComplexity)
										.join(', ')}
									. Please choose the complexities you want to replace the
									template for {templateData.title}.
								</p>
							) : (
								<p>
									The template submitted by the contributor is for complexity "
									{submittedComplexity}
									".{' '}
								</p>
							)}
						</div>

						<form>
							{complexitiesData.length > 1 ? (
								<label className="NotificationChkboxContainer">
									<input
										name="All"
										type="checkbox"
										checked={checkedItems['All']}
										onChange={updateComplexitiesArray}
									/>
									<span className="CustomCheckmark"></span>
									<span className="complexityLabel">All</span>
								</label>
							) : null}
							{renderComplexitiesForm()}
						</form>
					</div>

					<ol>
						{(templateData.fileType !== templateData.fileTypeOld &&
							(sampleTemplateCount > 0 || localizedTemplateCount > 0)) ||
						(templateData.fileType === templateData.fileTypeOld &&
							complexitiesData.length > 1 &&
							!checkedItems['All'] &&
							(sampleTemplateCount > 0 || localizedTemplateCount > 0)) ? (
							<>
								<p className="noteMessage">**Please Note:</p>
								<li className="retainTemplate">
									<p className="approverMessage">
										The existing samples or localized templates for{' '}
										{templateData.title} will become old since you are changing
										the base Template.
									</p>
									<p className="retainMessage">
										Do do you want to retain them?&nbsp;
									</p>
									<label className="checkBoxContainer filterChkboxContainer">
										<input
											name="Yes"
											type="radio"
											checked={retainLocTemplate}
											onChange={() => setRetainLocTemplate(true)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setRetainLocTemplate(true);
												}
											}}
										/>
										<span className="checkmark"></span>
										<span className="filterLabel">Yes</span>
									</label>
									&nbsp;
									<label className="checkBoxContainer filterChkboxContainer">
										<input
											name="No"
											type="radio"
											checked={!retainLocTemplate}
											onChange={() => setRetainLocTemplate(false)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setRetainLocTemplate(false);
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setRetainLocTemplate(false);
												}
											}}
										/>
										<span className="checkmark"></span>
										<span className="filterLabel">No</span>
									</label>
								</li>
							</>
						) : (
							<></>
						)}
					</ol>

					{errorMessage && complexitiesData.length > 0 ? (
						<p>
							You need to select at least one complexity to publish this
							template
						</p>
					) : null}
					<div className="publishCancelButtons">
						<Button
							onClick={() => {
								setModalShow(false);
								setShowComplexityModal(false);
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								publishTemplate();
							}}
							disabled={errorMessage ? true : false}
						>
							Publish
						</Button>
					</div>
				</Modal.Body>
			</>
		</Modal>
	);
}

export default ComplexityModal;
