import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import './TemplateCrowdSourcing.css';
import fileIcons from '../../static/TemplateIcons';
import { useAxios } from '../../context/AxiosContext';
import ReviewerApproveRejectPopup from './ReviewerApproveRejectPopUp';
import {
	closeFullScreen,
	openFullScreen,
} from '../../helpers/FullscreenService';
import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';
import { useAppInsights } from '../../context/TelemetryContext';
import RatingModal from './RatingModal';

const TemplateReviewCard = ({
	data,
	complexities,
	singleView,
	getContributions,
	contributorInfo,
	setContributorInfo,
	getAllContributors,
	ratingPoints,
	userRatingPoints,
	setUserRatingPoints,
	ratingStars,
	setRatingStars,
}) => {
	const { track } = useAppInsights();
	const history = useHistory();
	const [showRelatedTemplates, setShowRelatedTemplates] = useState(false);
	const [preview, setPreview] = useState({
		show: false,
		data: {},
	});
	const [relatedTemplates, setRelatedTemplates] = useState([]);
	const [showApproveRejectPopup, setShowApproveRejectPopup] = useState(false);
	const [approveRejectData, setApproveRejectData] = useState({
		data: null,
		type: '',
	});
	const [loading, setLoading] = useState(false);
	const [reviewComment, setReviewComment] = useState('');
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const [showRatingModal, setShowRatingModal] = useState(false);
	const [enableGamification, setEnableGamification] = useState(false);

	const handlePreview = (suggestion) => {
		const elem = document.getElementById(
			`templateCrowdSourcingPreview_${data.templateId}_${data.itemId}_${data.complexityId}`
		);
		if (preview.show) {
			setPreview({ show: false, data: null });
			closeFullScreen();
		} else {
			setPreview({
				show: true,
				data: suggestion,
			});
			openFullScreen(elem);
		}
	};

	const handleFullScreen = () => {
		if (
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement
		) {

		} else {
			setPreview({ show: false, data: null });
		}
	};

	const handleApproveAndReject = (item, actionType) => {
		setApproveRejectData({ data: item, type: actionType });
		if (actionType === 'approve' && enableGamification) {
			setShowRatingModal(true);
		} else {
			setShowApproveRejectPopup(true);
		}
	};

	const checkGamificationStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
			(response) => {
				if (response.data[0].value === 'true') {
					setEnableGamification(true);
				}
			}
		);
	};

	const clearRating = () => {
		setUserRatingPoints(null);
		setRatingStars(null);
	};

	const submitApproveOrReject = async (item, actionType) => {
		if (enableGamification && actionType === 'approve') {
			setShowRatingModal(false);
		} else {
			setShowApproveRejectPopup(false);
		}
		setLoading(true);
		const postBody = {
			id: item.id,
			templateId: item.templateId,
			complexityId: item.complexityId,
			stateId: actionType === 'approve' ? 2 : 4,
			contributorEmail: item.contributorEmail,
			title: item.title,
			isLocalized: item.isLocalized,
			languageId: item.languageId,
			languageName: item.languageName,
			regionName: item.regionName,
			countryName: item.countryName,
			rejectedComments: reviewComment,
			ratingPoints: userRatingPoints ? userRatingPoints : 0,
			ratingStars: ratingStars ? ratingStars : 0,
		};
		await axiosPostService(
			'api/sdmtemplate/inserttemplatereviewer',
			postBody
		).then((response) => {
			setLoading(false);

			if (singleView) {
				history.push('/template/crowdsourcing/review/all');
			} else {
				getContributions();
			}
			clearRating();
		});
	};

	const getRelatedTemplates = async () => {
		await axiosGetService(
			`api/sdmtemplate/getreviewertemplategroup/${data.templateId}/${data.complexityId}/${data.languageId}/${data.isLocalized}`
		).then((response) => {
			setRelatedTemplates(response.data);
			getAllContributors(response.data);
		});
	};

	useEffect(() => {
		if (showRelatedTemplates && !singleView) {
			getRelatedTemplates();
		} else if (showRelatedTemplates && singleView) {
			setRelatedTemplates(data.sdmTemplate2s);
			getAllContributors(data.sdmTemplate2s);
		} else {
			setRelatedTemplates([]);
		}
	}, [showRelatedTemplates]);

	useEffect(() => {
		checkGamificationStatus();
	}, []);

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreen, false);
		document.addEventListener('mozfullscreenchange', handleFullScreen, false);
		document.addEventListener(
			'webkitfullscreenchange',
			handleFullScreen,
			false
		);
		document.addEventListener('msfullscreenchange', handleFullScreen, false);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreen, false);
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'msfullscreenchange',
				handleFullScreen,
				false
			);
		};
	}, []);

	return (
		<div className="templateReviewerParent">
			{loading ? <CrowdSourcingSpinner /> : <></>}
			{showRelatedTemplates ? (
				<>
					<div className="templateChildWrapper">
						{relatedTemplates &&
							relatedTemplates.map((item) => (
								<SuggestionDetails
									data={item}
									complexities={complexities}
									contributorInfo={contributorInfo}
									handlePreview={handlePreview}
									handleApproveAndReject={handleApproveAndReject}
									key={JSON.stringify(item)}
								/>
							))}
					</div>
				</>
			) : (
				<></>
			)}
			<SuggestionDetails
				data={data}
				complexities={complexities}
				contributorInfo={contributorInfo}
				handlePreview={handlePreview}
				handleApproveAndReject={handleApproveAndReject}
			/>
			{data.count > 0 ? (
				<span
					className="relatedTemplates"
					onClick={() => setShowRelatedTemplates(!showRelatedTemplates)}
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowRelatedTemplates(!showRelatedTemplates);
						}
					}}
				>
					{!showRelatedTemplates ? (
						<span>{data.count} more suggestions are available +</span>
					) : (
						<span>- Show Less</span>
					)}
				</span>
			) : (
				<></>
			)}
			{showApproveRejectPopup ? (
				<ReviewerApproveRejectPopup
					closeModal={() => {
						setShowApproveRejectPopup(false);
					}}
					templateData={data}
					showApproveRejectPopup={showApproveRejectPopup}
					approveRejectData={approveRejectData}
					submitApproveOrReject={submitApproveOrReject}
					handleApproveAndReject={handleApproveAndReject}
					singleView={singleView}
					getContributions={getContributions}
					reviewComment={reviewComment}
					setReviewComment={setReviewComment}
				/>
			) : (
				<></>
			)}
			{showRatingModal ? (
				<RatingModal
					closeModal={() => {
						setShowRatingModal(false);
					}}
					templateData={data}
					showRatingModal={showRatingModal}
					approveRejectData={approveRejectData}
					submitApproveOrReject={submitApproveOrReject}
					handleApproveAndReject={handleApproveAndReject}
					userRatingPoints={userRatingPoints}
					setUserRatingPoints={setUserRatingPoints}
					ratingStars={ratingStars}
					setRatingStars={setRatingStars}
					ratingPoints={ratingPoints}
					contributorInfo={contributorInfo}
					clearRating={clearRating}
					enableGamification={enableGamification}
					setShowRatingModal={setShowRatingModal}
				/>
			) : (
				<></>
			)}
			<div
				className="templateCrowdSourcingPreview"
				id={`templateCrowdSourcingPreview_${data.templateId}_${data.itemId}_${data.complexityId}`}
			>
				{preview.show ? (
					<>
						<div className="templateSuggestedPreview">
							<div className="templateCrowdSourcingPanelHeader">
								<span>Suggested Template</span>
								<span className="tempPreviewInfoIcon">
									<div className="tabsShowMoreMenu">
										<span className="tabsShowMoreArrow"></span>
										<div className="menuItemUL">
											{preview.data.contributorComments}
										</div>
									</div>
								</span>
								<div>
									<a href={preview.data.filePath} target="_blank">
										Download File
									</a>
									<button
										className="templatePreviewReject"
										title={
											preview.data.isReviewer
												? 'Reject'
												: 'You are not a reviewer'
										}
										onClick={() => {
											if (Number(preview.data.reviewStatus) !== 1) {
												handleApproveAndReject(preview.data, 'reject');
												handlePreview();
												track(1, 156, preview.data.id, preview.data.title);
											}
										}}
										disabled={
											Number(preview.data.reviewStatus) === 1 ||
											!preview.data.isReviewer
										}
									>
										Reject
									</button>
									<button
										title={
											preview.data.isReviewer
												? 'Approve'
												: 'You are not a reviewer'
										}
										className="templatePreviewApprove"
										onClick={() => {
											if (Number(preview.data.reviewStatus) !== 1) {
												handleApproveAndReject(preview.data, 'approve');
												handlePreview();
												track(1, 154, preview.data.id, preview.data.title);
											}
										}}
										disabled={
											Number(preview.data.reviewStatus) === 1 ||
											!preview.data.isReviewer
										}
									>
										Approve
									</button>
								</div>
							</div>
							<iframe
								src={preview.data.previewUrl}
								className="templateCrowdSourcingIframe"
								scrolling="yes"
								arial-label="Template Preview"
							/>
						</div>
						<div className="templateCurrentPreview">
							<div className="templateCrowdSourcingPanelHeader">
								<span>Current Template</span>
								<div>
									{preview.data.previewUrlOld ? (
										<a href={preview.data.file} target="_blank">
											Download File
										</a>
									) : (
										<></>
									)}
									<Icon
										iconName="ChromeClose"
										onClick={handlePreview}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handlePreview();
											}
										}}
									/>
								</div>
							</div>
							{preview.data.previewUrlOld ? (
								<iframe
									src={preview.data.previewUrlOld}
									className="templateCrowdSourcingIframe"
									scrolling="yes"
									arial-label="Template Preview"
								/>
							) : (
								<h5>There is no template available.</h5>
							)}
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

const SuggestionDetails = ({
	data,
	handlePreview,
	contributorInfo,
	handleApproveAndReject,
	complexities,
}) => {
	const { track } = useAppInsights();
	return (
		<div className="newTableParentRow">
			<div className="newTableRow">
				<div className="newWrapper newAttributes">
					<div className="newWrapper newTitleCommentModuleReporter ">
						<div className="newWrapper newTitleComment ">
							<div className="newColumn newTitle newActTd ">
								<div>
									<img
										src={fileIcons[data.fileType]}
										className="imageOfTemplateResult"
										alt="File Type"
									/>
									<div className="templateCrowdTitleDiv">
										<span className="newTitle">{data.title}</span>
										{data.isLocalized ? (
											<span className="newTitle">
												<strong>Language</strong>: {data.languageName}
											</span>
										) : (
											<></>
										)}
										<div className="templateCrowdPreviewDownload">
											<span
												onClick={() => {
													handlePreview(data);
													track(1, 155, data.id, data.title);
												}}
												role="button"
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														handlePreview(data);
														track(1, 155, data.id, data.title);
													}
												}}
											>
												Preview
											</span>{' '}
											|{' '}
											<a
												href={data.filePath}
												target="_blank"
												onClick={() => {
													track(1, 112, data.id, data.title);
												}}
											>
												Download
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="newWrapper ">
					<div className="newColumn templateComplexity newActTd ">
						{!data.isLocalized ? complexities[data.complexityId] : 'NA'}
					</div>
				</div>
				<div className="newWrapper ">
					<div className="newColumn uploadDetails newActTd ">
						{Object.keys(contributorInfo).includes(data.contributorEmail) ? (
							<p>{`${contributorInfo[data.contributorEmail].firstName} ${contributorInfo[data.contributorEmail].surname
								}`}</p>
						) : (
							<></>
						)}
						<span>{data.notificationDate}</span>
					</div>
				</div>
				<div className="newWrapper ">
					<div className="newColumn details newActTd ">
						{data.contributorComments}
					</div>
				</div>
				<div className="newWrapper ">
					<div className="newColumn approveAndReject newActTd">
						<button
							title={data.isReviewer ? 'Approve' : 'You are not a reviewer'}
							onClick={() => {
								if (Number(data.reviewStatus) !== 1) {
									handleApproveAndReject(data, 'approve');
									track(1, 154, data.id, data.title);
								}
							}}
							disabled={Number(data.reviewStatus) === 1 || !data.isReviewer}
						>
							Approve
						</button>
						<button
							title={data.isReviewer ? 'Reject' : 'You are not a reviewer'}
							onClick={() => {
								if (Number(data.reviewStatus) !== 1) {
									handleApproveAndReject(data, 'reject');
									track(1, 156, data.id, data.title);
								}
							}}
							disabled={Number(data.reviewStatus) === 1 || !data.isReviewer}
						>
							Reject
						</button>
						{data.stateId === 2 ? (
							<p className="statusText">
								<span>Sent for approval</span>
							</p>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateReviewCard;
