import React from 'react';
import StarRatings from 'react-star-ratings';

import './HistoryPreviewPopUp.css';

const RatingModal = ({
 users,
 currentUser,
 usersRating,
 setUsersRating,
 ratingPoints,
 updatedUsers,
}) => {
 return (
  <div className="reviwerRejectedRatingContainer">
   {users
    .filter(({ id }) => id !== currentUser && updatedUsers.includes(id))
    .map((user) => (
     <div className="reviwerRejectedRatingTab" key={user.id}>
      <div className="reviwerRejectedUserDetails">
       <img src={user.avatar} />
       <h6>{user.name}</h6>
      </div>
      <div className="reviwerRejectedRatingDetails">
       <div className="reviwerRejectedRating">
        <h6>Rating</h6>
        <span>
         <StarRatings
          rating={usersRating[user.id] ? usersRating[user.id].ratingStars : 0}
          starDimension="19px"
          starRatedColor={'#ffd700'}
          starHoverColor={'#ffd700'}
          starEmptyColor={'#e9e9f0'}
          starSpacing="5px"
          svgIconViewBox="0 0 31.951 32"
          svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
          changeRating={(value) => {
           setUsersRating((prevState) => ({
            ...prevState,
            [user.id]: {
             ratingPoints: ratingPoints[`star${value}`],
             ratingStars: value,
            },
           }));
          }}
         />
        </span>
       </div>
       <div className="reviwerRejectedEarnedPoints">
        <h6>Points earned by contributor</h6>
        <span>
         {usersRating[user.id] ? usersRating[user.id].ratingPoints : 0}
        </span>
       </div>
      </div>
     </div>
    ))}
  </div>
 );
};

export default RatingModal;
