import React,{useEffect} from 'react';
import { Icon } from '@fluentui/react';

import TopicsDropdown from './TopicsAndProceduresDropdown';

const AddTopics = ({
	showFields,
	handelFormView,
	data,
	value,
	setValue,
	enableDropdown,
	className,
	searchValue,
	setSearchValue,
	updatedValue,
	setUpdatedValue,
	topicsMapper,
	topicCategoryId,
	setTopicCategoryId,
	proceduresData,
	proceduresMapper,
	selectedProcedures,
	setSelectedProcedures,
	proceduresCategoryIds,
	setProceduresCategoryIds,
	updatedProcedures,
	setUpdatedProcedures,
	topicsCategoryData,
	procedureSearchValue,
	setProcedureSearchValue,
	proceduresCategoryData,
	topicsCategorySearchValue,
	setTopicsCategorySearchValue,
	proceduresCategorySearchValue,
	setProceduresCategorySearchValue,
}) => {

	const autoSave = () => {
		// Here you can handle the logic to auto-save the data
		// This could be an API call to save the data, or just updating the parent component's state.
		setValue([...updatedValue]);
		setSelectedProcedures([...updatedProcedures]);
	};

	// Effect to auto-save when `updatedValue` or `updatedProcedures` changes
	useEffect(() => {
		autoSave();
	}, [updatedValue, updatedProcedures]);
	const removeTopic = (topicId) => {
		const filteredTools = updatedValue.filter(({ id }) => id !== topicId);
		setUpdatedValue(filteredTools);
	};

	const removeProcedure = (procedureId) => {
		const filteredTools = updatedProcedures.filter(
			({ id }) => id !== procedureId
		);
		setUpdatedProcedures(filteredTools);
	};
	return (
		<div
			className={
				 ' Detailswrapper activityEditTopic p-0'
					
			}
		>
			<div className="DetailswrapperTitle">
				{/* {true ? (
					<></>
				) : (
					<>
						{value.length === 0 && updatedProcedures.length === 0 ? (
							<span
								className="addingInputButton"
								tabIndex={0}
								onClick={() => handelFormView('topics')}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handelFormView('topics');
									}
								}}
								aria-label={`click to Add topics`}
								title="Add"
								role="Button"
							>
								+ Add
							</span>
						) : (
							<></>
						)}
					</>
				)} */}

				{true ? (
					<div className="neweditActivityfieldsWrapper topicsSelectionDropdown mb-2">
						<TopicsDropdown
							className="editCategorySelectionDropdown"
							enableDropdown={true}
							data={topicsCategoryData}
							searchValue={topicsCategorySearchValue}
							setSearchValue={setTopicsCategorySearchValue}
							updatedValue={topicCategoryId}
							setUpdatedValue={setTopicCategoryId}
							type="Topics Category"
							pagetype="Activity"
						/>
						<TopicsDropdown
							className="edittopicsDropdown"
							enableDropdown={true}
							data={data}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
							updatedValue={updatedValue}
							setUpdatedValue={setUpdatedValue}
							type="Topics"
							pagetype="Activity"
						/>
					</div>
				) : (
					<></>
				)}
				{updatedValue.length > 0 ? (
					<>
						{!true ? (
							<p className="topicsName">Related Topics</p>
						) : (
							<></>
						)}
						<ul
							className={
								'afterSaveEditList beforEditActivitySavedList pl-0 mb-0'
									
							}
						>
							{updatedValue.map((item) => (
								<li key={item.id}>
									<a
										href={
											topicsMapper[item.id] &&
											`/topics/${item.id}`
										}
									>
										{(topicsMapper[item.id] && topicsMapper[item.id].name) ||
											item.name}
									</a>
									{true ? (
										<Icon
											iconName="Cancel"
											onClick={() => removeTopic(item.id)}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													removeTopic(item.id);
												}
											}}
											aria-label={`Click to cancel added ${
												(topicsMapper[item.id] && topicsMapper[item.id].name) ||
												item.name
											} topic `}
											role="button"
										/>
									) : (
										<></>
									)}
								</li>
							))}
						</ul>
					</>
				) : (
					<></>
				)}
				{true ? (
					<div className="neweditActivityfieldsWrapper topicsSelectionDropdown">
						<TopicsDropdown
							className="editeditproceduresCategorySelectionDropdown"
							enableDropdown={true}
							data={proceduresCategoryData}
							searchValue={proceduresCategorySearchValue}
							setSearchValue={setProceduresCategorySearchValue}
							updatedValue={proceduresCategoryIds}
							setUpdatedValue={setProceduresCategoryIds}
							type="Job Aids Category"
							pagetype="Activity"
						/>
						<TopicsDropdown
							className="editproceduresDropdown"
							enableDropdown={true}
							data={proceduresData}
							searchValue={procedureSearchValue}
							setSearchValue={setProcedureSearchValue}
							updatedValue={updatedProcedures}
							setUpdatedValue={setUpdatedProcedures}
							type="Job Aids"
							pagetype="Activity"
						/>
					</div>
				) : (
					<></>
				)}
				{updatedProcedures.length > 0 ? (
					<>
						{!true ? (
							<p className="topicsName">Job Aids</p>
						) : (
							<></>
						)}
						<ul
							className={
							' afterSaveEditList beforEditActivitySavedList pl-0 mt-2 mb-0'
									
							}
						>
							{updatedProcedures.map((item) => (
								<li key={item.id}>
									<a
										href={
											proceduresMapper[item.id] &&
											`/topics/${item.id}`
										}
									>
										{(proceduresMapper[item.id] &&
											proceduresMapper[item.id].name) ||
											item.name}
									</a>
									{true ? (
										<Icon
											iconName="Cancel"
											onClick={() => removeProcedure(item.id)}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													removeProcedure(item.id);
												}
											}}
											aria-label={`Click to remove ${
												(proceduresMapper[item.id] &&
													proceduresMapper[item.id].name) ||
												item.name
											} Procedure`}
											role="button"
										/>
									) : (
										<></>
									)}
								</li>
							))}
						</ul>
					</>
				) : (
					<></>
				)}
			</div>
			{/* {showFields.includes('topics') ? (
				<>
					<div className="DetailswrapperRightSec">
						<button
							onClick={() => {
								handelFormView('topics');
								setUpdatedValue([...value]);
								setUpdatedProcedures([...selectedProcedures]);
							}}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handelFormView('topics');
									setUpdatedValue([...value]);
									setUpdatedProcedures([...selectedProcedures]);
								}
							}}
						>
							Close
						</button>
						<button
							onClick={() => {
								handelFormView('topics');
								setValue([...updatedValue]);
								setSelectedProcedures([...updatedProcedures]);
							}}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handelFormView('topics');
									setValue([...updatedValue]);
									setSelectedProcedures([...updatedProcedures]);
								}
							}}
						>
							Save
						</button>
					</div>
				</>
			) : (
				<>
					{value.length > 0 || updatedProcedures.length > 0 ? (
						<span
							className="addingInputButton"
							onClick={() => handelFormView('topics')}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handelFormView('topics');
								}
							}}
							aria-label={`click to Edit topics`}
							title="Edit"
							role="Button"
						>
							Edit
						</span>
					) : (
						<></>
					)}
				</>
			)} */}
		</div>
	);
};

export default AddTopics;
