import React, { useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import AddToSubgroupDropdown from './AddToSubgroupDropdown';
import SubgroupActivityDrag from './SubgroupActivitiesDrag';

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: 'white',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: 'white',
	margin: '0.1px 0',
});

const ServiceCommandUnit = ({
	subItems,
	type,
	expandedActivityGroups,
	group,
	dragNDropId,
	makeActivityOrphan,
	coOwners,
	userAlias,
	setSelectedSubgroupDetails,
	setShowSubgroupEditForm,
	setShowGroupForm,
	setSelectedGroupDetails,
	detachActivityFromSubgroup,
	setExpandedActivityGroups,
	activityGroups,
	setActivityGroups,
}) => {
	const [showSubgroupDropdown, setShowSubgroupDropdown] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);

	const getSubgroups = () => {
		const subgroups = [];
		const activities = group.activities;
		for (const activity of activities) {
			if (activity.isSubgroup) {
				subgroups.push({
					id: activity.subGroupId,
					name: activity.subGroupName,
				});
			}
		}
		return subgroups;
	};

	const handleSubgroupExpand = (subgroupId) => {
		const id = 'subgroup' + subgroupId;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else {
			const filteredIds = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(filteredIds);
		}
	};

	return (
		<Droppable droppableId={dragNDropId} type={`droppableSubItem`}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					style={getListStyle(snapshot.isDraggingOver)}
				>
					{expandedActivityGroups.includes(type) &&
						subItems.map((item, index) => (
							<React.Fragment>
								{item.isSubgroup ? (
									<Draggable
										key={item.dragNDropId}
										draggableId={item.dragNDropId}
										index={index}
									>
										{(provided, snapshot) => (
											<div style={{ display: 'flex' }}>
												<div
													className="draggbleActivityItem"
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<div key={item.dragNDropId}>
														<div
															className={
																item.isParentActivity
																	? 'delivery subGroupContainer'
																	: !item.isParentActivity
																	? 'orangeDelivery subGroupContainer'
																	: 'subGroupContainer'
															}
															tabIndex={0}
														>
															<div className="activityChildContainer">
																<div class="activityListMainContainer activityGroupParent">
																	<div
																		class="activityContainer activityGroup"
																		tabIndex="0"
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				handleSubgroupExpand(
																					item.subGroupId.toString()
																				);
																			}
																		}}
																		onClick={() =>
																			handleSubgroupExpand(
																				item.subGroupId.toString()
																			)
																		}
																	>
																		<span class="activitytypeColor"></span>
																		<div
																			class="activityTitle"
																			title={item.subGroupName}
																		>
																			{expandedActivityGroups.includes(
																				'subgroup' + item.subGroupId.toString()
																			) ? (
																				<Icon iconName="CaretDownSolid8" />
																			) : (
																				<Icon iconName="CaretRightSolid8" />
																			)}
																			<span
																				aria-label={item.subGroupName}
																				class="titleOfActivity"
																			>
																				{item.subGroupName}
																			</span>
																			{item.isMethodOwnerOrCoOwner &&
																			!group.isGroupRefered &&
																			!group.isGroupReferredWithActivity ? (
																				<span
																					class="ActivityEditIcon"
																					onClick={() => {
																						setSelectedSubgroupDetails(item);
																						setShowSubgroupEditForm(true);
																						setSelectedGroupDetails(group);
																					}}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setSelectedSubgroupDetails(item);
																							setShowSubgroupEditForm(true);
																							setSelectedGroupDetails(group);
																						}
																					}}
																					tabIndex="0"
																					title="Edit Subgroup"
																				></span>
																			) : (
																				<></>
																			)}
																		</div>
																	</div>
																	{expandedActivityGroups.includes(
																		'subgroup' + item.subGroupId.toString()
																	) && (
																		<SubgroupActivityDrag
																			activityGroups={activityGroups}
																			setActivityGroups={setActivityGroups}
																			subgroup={item}
																			group={group}
																			detachActivityFromSubgroup={
																				detachActivityFromSubgroup
																			}
																			dragNDropId={item.dragNDropId}
																		/>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
												{provided.placeholder}
											</div>
										)}
									</Draggable>
								) : (
									<Draggable
										key={item.dragNDropId}
										draggableId={item.dragNDropId}
										index={index}
									>
										{(provided, snapshot) => (
											<div style={{ display: 'flex' }}>
												<div
													className="draggbleActivityItem"
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<div key={item.dragNDropId}>
														<div
															className={
																(!group.isParentActivity &&
																	item.isParentActivity) ||
																(group.isParentActivity &&
																	item.isParentActivity)
																	? 'delivery'
																	: group.isParentActivity &&
																	  !item.isParentActivity
																	? 'orangeDelivery'
																	: ''
															}
														>
															<div className="activityChildContainer">
																{coOwners.includes(userAlias) &&
																item.isDrafted ? (
																	<span
																		className={
																			item.isDrafted
																				? 'draftActivity'
																				: 'nonDraftActivity'
																		}
																	>
																		Draft
																	</span>
																) : (
																	<></>
																)}
																<div
																	className="activityChildTitle"
																	title={item.activityTitle}
																>
																	<span className="titleOfActivity">
																		{item.activityTitle}
																	</span>
																</div>

																<span className="activityAddtoGroup">
																	{item.isMethodOwnerOrCoOwner &&
																	!group.isGroupRefered ? (
																		<span
																			onClick={() => {
																				makeActivityOrphan(
																					Number(type),
																					item.dragNDropId
																				);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					makeActivityOrphan(
																						Number(type),
																						item.dragNDropId
																					);
																				}
																			}}
																			tabIndex="0"
																			className="removeFromSubgroupIcon"
																			title="Make orphan"
																		></span>
																	) : (
																		<></>
																	)}
																	{showSubgroupDropdown &&
																	selectedActivity.dragNDropId ===
																		item.dragNDropId ? (
																		<AddToSubgroupDropdown
																			className={`addToGroupDropdown`}
																			data={getSubgroups()}
																			setValue={setActivityGroups}
																			value={activityGroups}
																			enableDropdown={
																				getSubgroups().length > 0 ? true : false
																			}
																			setShowAddToGroupDropdown={
																				setShowSubgroupDropdown
																			}
																			selectedActivity={selectedActivity}
																			parentGroup={group}
																		/>
																	) : (
																		<></>
																	)}

																	{!group.isGroupRefered &&
																	!group.isGroupReferredWithActivity ? (
																		<span
																			onClick={() => {
																				if (getSubgroups().length > 0) {
																					setShowSubgroupDropdown(true);
																					setSelectedActivity(item);
																				}
																			}}
																			className={`${
																				getSubgroups().length === 0
																					? 'phaseAddIcon disabled'
																					: 'phaseAddIcon'
																			}`}
																			title="Move to subgroup"
																		></span>
																	) : (
																		<></>
																	)}
																</span>
															</div>
														</div>
													</div>
												</div>
												{provided.placeholder}
											</div>
										)}
									</Draggable>
								)}
							</React.Fragment>
						))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

export default ServiceCommandUnit;
