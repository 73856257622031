import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Timepicker({ className, data, setValue, value }) {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		setValue(item);
		setVisible(!visible);
	};

	return (
		<div title={'Select time'} id={className} aria-label="Time">
			<div className={`dropDowncontainer ${className}`}>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					aria-expanded={visible}
					role={'listbox'}
					aria-roledescription="dropdown"
					aria-label={'time picker'}
				>
					<span className="wrapHeader">{value || 'Select time'}</span>
					<Icon
						iconName="ChevronDown"
						onKeyPress={(e) => {
							if (e.which === 13) {
								setVisible(!visible);
							}
						}}
						onClick={() => setVisible(!visible)}
						tabIndex={0}
						role={'button'}
						aria-label={'time picker'}
						aria-expanded={visible ? true : false}
					/>
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => (
								<li
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleDropdown(item);
										}
									}}
									onClick={() => handleDropdown(item)}
									title={item.value}
									key={item.value}
									aria-label="Time"
								>
									<span>{item.value}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Timepicker;
