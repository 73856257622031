import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert, Button } from 'react-bootstrap';
import { Icon, IconButton } from '@fluentui/react';
import { activityProperties } from './SourceActivityProperty';
import { destinationActivityProperty } from './DestinationProperty';
import { useParams } from 'react-router-dom';
import SourceComplexityDropdown from './SourceCloneDropdown';
import DestinationComplexityDropdown from './DestinationCloneDropdown';
import { useAxios } from '../../context/AxiosContext';

const CloneModal = ({
	showCloneModal,
	setShowCloneModal,
	activityName,
	publishComplexityList,
	complexities,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	setComplexityLevelUpdatedDetails,
	availableComplexity,
	setAvailableComplexity,
	setIsApiCalled,
	isApiCalled,
	setSelectedComplexity,
	prePublishComplexityfunction
}) => {
	const defaultSourceComplexity = {
		id: 0,
		name: 'Select source Complexity',
	};
	const {
		activityId,
		draftId,
	} = useParams();
	const { axiosGetService, axiosPostService, axiosDeleteService, userAlias } =
		useAxios();
		const [fiterByComplexity, setFiterByComplexity] = useState([])
	const [actProperty, setActProperty] = useState(activityProperties)
	const [clonedActivityProperty, setClonedActivityProperty] = useState(destinationActivityProperty)
	const [detailSelection, setDetailSelection] = useState(destinationActivityProperty);
	const [sourceComplexity, setSourceComplexity] = useState(
		defaultSourceComplexity
	);
	const [destinationComplexity, setDestinationComplexity] = useState([]);
	const [successMessage, setSuccessMessage] = useState(false);
	const handleCheckBoxChange = (value) => {
		if (value.id === 1) {
			const isCheckerValue = value.isChecked
			const items = actProperty.map((item) => {
				return { ...item, isChecked: !isCheckerValue }
			})
			setActProperty(items)
		} else {
			const items = actProperty.map((item) => item.id === value.id ? { ...item, isChecked: !item.isChecked } : item)
			const isCheckedSomeFalse = items.slice(1,14).some((i) => i.isChecked === false)
			const isCheckedAllTrue = items.slice(1,14).every((i) => i.isChecked === true)
			if (isCheckedSomeFalse) {
				const finalItems = items.map((i) => {
					if (i.id === 1) {
						i.isChecked = false
					}
					return i;
				})
				setActProperty(finalItems)
			} else if (isCheckedAllTrue) {
				const finalItems = items.map((i) => {
					if (i.id === 1) {
						i.isChecked = true
					}
					return i;
				})
				setActProperty(finalItems)
			}
			else {
				setActProperty(items)
			}
		}
	}
	const getBusinessRelatedContent = async()=>{
		let id = activityId !== 'null' ? activityId : draftId
		let api  = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${id}/2/${sourceComplexity.id}/${false}`
		await axiosGetService(api).then(
			(response) => {
			 setFiterByComplexity(response.data.filter(e=> e.complexityId === sourceComplexity.id))
			console.log(fiterByComplexity,"linefileter")
		})
	}
	useEffect(()=>{
		getBusinessRelatedContent()
	},[sourceComplexity.id, ])

const addBusinessRelatedContent = async (businessRules, destinationComplexityId) => {
	let id = activityId !== 'null' ? activityId : draftId;
	for (let rule of businessRules) {
		let content = {
			name: rule.name,
			url: rule.url,
			isDrafted: true,
			ArtifactId: id,
			ArtifactTypeId: 2,
			ComplexityId: destinationComplexityId
		};
		const postData = JSON.stringify(content);
		await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData);
	}
};
	const handleCloneButtonClick = () => {
		// const selectedItems = actProperty.map((item) => item)
		setClonedActivityProperty(actProperty);
		setDetailSelection(actProperty)
	}

	const getSourceComplexityValue = (type) => {
		return complexityLevelDetails[sourceComplexity.id][type];
	};

	const getDestinationComplexityValue = (destinationComplexityId, type) => {
		return complexityLevelDetails[destinationComplexityId][type];
	};

	const cloneToComplexity = (destinationComplexityId, value, setValue) => {
		const DS = detailSelection.filter((item)=> item.isChecked === true)
		const extractName = DS.map(({ name }) => {
			return name;
		});
		setValue((prevState) => ({
			...prevState,
			[destinationComplexityId]: {
				...value[destinationComplexityId],
				id: getDestinationComplexityValue(destinationComplexityId, 'id'),
				description: extractName.includes('Activity Description')
					? getSourceComplexityValue('description')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'description'
					),
				supportingActivities: extractName.includes('Supporting Activity')
					? getSourceComplexityValue('supportingActivities')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'supportingActivities'
					),
				deliverables: extractName.includes('Deliverables')
					? getSourceComplexityValue('deliverables')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'deliverables'
					),
				tileDescription: extractName.includes('Tile Description')
					? getSourceComplexityValue('tileDescription')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'tileDescription'
					),
				tipsAndTechniques: extractName.includes('Guidance, Tips and Techniques')
					? getSourceComplexityValue('tipsAndTechniques')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'tipsAndTechniques'
					),
				entryCriteria: extractName.includes('Entry Criteria')
					? getSourceComplexityValue('entryCriteria')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'entryCriteria'
					),
				exitCriteria: extractName.includes('Exit Criteria')
					? getSourceComplexityValue('exitCriteria')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'exitCriteria'
					),
				taskList: extractName.includes('Tasks')
					? getSourceComplexityValue('taskList')
					: getDestinationComplexityValue(destinationComplexityId, 'taskList'),
				templatesList: extractName.includes('Templates')
					? getSourceComplexityValue('templatesList')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'templatesList'
					),
				isCritical: extractName.includes('Is Critical')
					? getSourceComplexityValue('isCritical')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'isCritical'
					),

				isDeliverables: extractName.includes('SMMI Is Deliverable')
                    ? getSourceComplexityValue('isDeliverables')
                    : getDestinationComplexityValue(
                        destinationComplexityId,
                        'isDeliverables'
                    ), 
                isProcessStep: extractName.includes('SMMI Is Mandatory Process Step')
                    ? getSourceComplexityValue('isProcessStep')
                    : getDestinationComplexityValue(
                        destinationComplexityId,
                        'isProcessStep'
                    ),
				secureByDefault: extractName.includes('SMMI Secure by Default')
                    ? getSourceComplexityValue('secureByDefault')
                    : getDestinationComplexityValue(
                        destinationComplexityId,
                        'secureByDefault'
                    ),
                isBusinessRules: extractName.includes('Business Rules')
                    ? getSourceComplexityValue('isBusinessRules')
                    : getDestinationComplexityValue(
                        destinationComplexityId,
                        'isBusinessRules'
                    ),
				processGroupId: extractName.includes('Process Group')
					? getSourceComplexityValue('processGroupId')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'processGroupId'
					),
				responsibleList: extractName.includes('RACI')
					? getSourceComplexityValue('responsibleList')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'responsibleList'
					),
				accountableList: extractName.includes('RACI')
					? getSourceComplexityValue('accountableList')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'accountableList'
					),
				consultedList: extractName.includes('RACI')
					? getSourceComplexityValue('consultedList')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'consultedList'
					),
				informedList: extractName.includes('RACI')
					? getSourceComplexityValue('informedList')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'informedList'
					),
				isRaciValid: extractName.includes('RACI')
					? getSourceComplexityValue('isRaciValid')
					: getDestinationComplexityValue(
						destinationComplexityId,
						'isRaciValid'
					),
			},
		}));
		setSuccessMessage(true);
		setClonedActivityProperty(destinationActivityProperty.map(destItem => ({
			...destItem,
			isChecked: actProperty.find(actItem => actItem.id === destItem.id)?.isChecked || false
		})));		
		setTimeout(() => {
			setSuccessMessage(false);
		}, 10000);
	};


	const cloneActivity = () => {
		prePublishComplexityfunction(complexities);

		for (const complexity of destinationComplexity) {
			cloneToComplexity(
				complexity.id,
				complexityLevelDetails,
				setComplexityLevelDetails
			);
			cloneToComplexity(
				complexity.id,
				complexityLevelDetails,
				setComplexityLevelUpdatedDetails
			);
		}
		const businessRulesItem = clonedActivityProperty.find(item => item.name === "Business Rules");
		if (businessRulesItem && businessRulesItem.isChecked) {
			addBusinessRelatedContent(fiterByComplexity, destinationComplexity[0].id);
		}
	};

	// const filterSourceComplexity = () => {
	// 	const filterValue = complexities.filter(({ id }) =>
	// 		publishComplexityList.includes(id)
	// 	);
	// 	return filterValue;
	// };

	// const filterDestinationComplexity = () => {
	// 	const filterValue = complexities.filter(
	// 		({ id }) =>
	// 			sourceComplexity.id !== id && publishComplexityList.includes(id)
	// 	);
	// 	return filterValue;
	// };
	const filterSourceComplexity = () => {
		// Simply return the entire list without filtering
		return complexities;
	};
	
	const filterDestinationComplexity = () => {
		// Return all items, excluding the source complexity
		const filterValue = complexities.filter(({ id }) => sourceComplexity.id !== id);
		return filterValue;
	};
	
	useEffect(() => {
		setDestinationComplexity([]);
	}, [sourceComplexity]);

	let isDestinationChecked = detailSelection.every(item => !item.isChecked)
	

	return (
		<Modal
			className="cloneActivityDetailsPopup"
			show={showCloneModal}
			onHide={() => setShowCloneModal(false)}
			size="lg"
			centered

		>
			<Modal.Body>
				<div className="cloneActivityDatasWrapper">
					{successMessage ? (
						<Alert variant="success">
							You have successfully cloned the details.
						</Alert>
					) : (
						<></>
					)}
					<div className="cloneModalTitle">
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<p style={{ width: "660px", marginTop: '15px' }}>
								Clone Complexity
							</p>

							<Icon
								onClick={() => setShowCloneModal(false)}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowCloneModal(false);
									}
								}}
								iconName="Cancel"
							/>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>

							This will clone the following details of "{activityName}" and save
							it as a draft. And then you can make further changes to suit your
							requirements
						</div>

					</div>
					<div className="resltedActCloseListwrapper" style={{ alignItems: 'center' }}>
						<div className="actCloneComplexityDpDown">


						</div>
						<div>
							<div className="resltedActCloseList" style={{ height: '400px', border: '1px dashed #707070', borderRadius: '5px' }}>
								<div style={{ paddingLeft: '15px', paddingTop: '5px' }}>
									<SourceComplexityDropdown
										enableDropdown={true}
										className="sourceComplexityDropdown"
										data={filterSourceComplexity()}
										value={sourceComplexity}
										setValue={setSourceComplexity}
									/>
								</div>
								<div>
									<ul style={{ height: '360px', overflow: 'scroll', padding: '15px' }}>
										{actProperty.map((item) => (
											<li key={item.id}>
												<label className="NotificationChkboxContainer">
													<input
														name="Task Assignment Planned Hours"
														type="checkbox"
														checked={item.isChecked}
														onChange={() => handleCheckBoxChange(item)}
													/>
													<span class="CustomCheckmark"></span>
													<span className="cloneCheckBoxLabel">{item.name}</span>
												</label>
											</li>
										))}
									</ul></div>
							</div>
							<div className="deletePopupButton" style={{marginTop:'200px',marginRight:'-10px'}}>
								<IconButton
									iconProps={{ iconName: 'DoubleChevronRight' }}
									title="Clone Complexity"
									ariaLabel="Clone Complexity"
									styles={{
										root: {
											backgroundColor: 'white',
											border: '1px solid #ebe8e8',
											color: 'black',
										},
										rootHovered: {
											backgroundColor: '#e5e5e5',
											color:'black'
										},
										icon: {
											fontSize: 16,
										},
									}}
									disabled={destinationComplexity.length > 0 ? false : true || (detailSelection.length === 0 ? true : false)}
									onClick={() => {
										handleCloneButtonClick();
									}}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleCloneButtonClick();
										}
									}}
								/>
							</div>
							<div className="resltedActCloseList" style={{ height: '400px', border: '1px dashed #707070', borderRadius: '5px' }}>
								<div style={{ paddingLeft: '15px', paddingTop: '5px' }}>
									<DestinationComplexityDropdown
										enableDropdown={sourceComplexity.id !== 0 ? true : false}
										className="destinationComplexityDropdown"
										data={filterDestinationComplexity()}
										value={destinationComplexity}
										setValue={setDestinationComplexity}
									/>
								</div>
								<div>
									<ul style={{ height: '360px', overflow: 'scroll', padding: '15px' }}>
										{clonedActivityProperty.map((item) => (
											<li key={item.id}>
												<label className="NotificationChkboxContainer">
													<input
														name="Task Assignment Planned Hours"
														type="checkbox"
														checked={item.isChecked}
														disabled={true}
													/>
													<span class="CustomCheckmark"></span>
													<span className="cloneCheckBoxLabel">{item.name}</span>
												</label>
											</li>
										))}
									</ul>
								</div>

							</div>
						</div>
					</div>
					{detailSelection.length === 0 ? (
						<p className="errorMsg">
							Please select at least one property to clone
						</p>
					) : (
						<></>
					)}
					<div className="deletePopupButton" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
						<Button
							disabled={
								(destinationComplexity.length > 0 ? false : true) ||
								(detailSelection.length === 0 ? true : false) ||
								isDestinationChecked
							}
							variant="warning"
							onClick={() => {
								cloneActivity();
								setSelectedComplexity(3);
							}}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									cloneActivity();
								}
							}}
						>
							Clone
						</Button>
						<Button onClick={() => setShowCloneModal(false)}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowCloneModal(false);
								}
							}}
							variant="secondary">Cancel</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CloneModal;
