import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/UserAuthContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useAxios } from "../../context/AxiosContext";

import MyFavorites from "../../views/MyFavorites";
import MySettings from "../../views/MySettings";

import "./UserProfile.css";
import Toggle from "./Toggle";

function UserProfile() {
  const { axiosGetService } = useAxios();
  const { track } = useAppInsights();
  const { user, logout } = useAuth();
  const { appState, setAppState } = useLeftMenuCollapse();

  const { rightPanelContent } = appState;

  const [enableGamification, setEnableGamification] = useState(false);
  const [userIsOwner, setUserIsOwner] = useState(false);

  const handleRightPanel = (id) => {
    setAppState({
      ...appState,
      rightPanelContent: id,
      parentRightPanelComponent: "myProfile",
    });
  };

  const checkGamificationStatus = async () => {
    await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
      (response) => {
        if (response.data[0].value === "true") {
          setEnableGamification(true);
        }
      }
    );
  };

  const getUserIsOwner = async () => {
    await axiosGetService(`api/sdmuser/isownerorcoowneroradminofartifact`).then(
      (response) => {
        setUserIsOwner(response.data);
      }
    );
  };

  useEffect(() => {
    getUserIsOwner();
  });

  useEffect(() => {
    checkGamificationStatus();
  });

  return (
    <>
      {rightPanelContent === "myFavorites" ? <MyFavorites /> : null}
      {rightPanelContent === "mySettings" ? <MySettings /> : null}
      {rightPanelContent === "myProfile" ? (
        <MyProfileContent
          user={user}
          handleRightPanel={handleRightPanel}
          track={track}
          enableGamification={enableGamification}
          userIsOwner={userIsOwner}
          rightPanelContent={rightPanelContent}
        />
      ) : null}
      <div
        tabIndex={0}
        className="logout logoutButton"
        title="Logout"
        aria-label="LogOut"
        role="Link"
        onKeyPress={(e) => {
          if (e.which === 13) {
            logout();
            track(1, 106, "myProfile_logout", "Logout");
          }
        }}
        onClick={() => {
          logout();
          track(1, 106, "myProfile_logout", "Logout");
        }}
      >
        <span className="logoutIcon"></span>
        <span>Logout</span>
      </div>
    </>
  );
}

const MyProfileContent = ({
  user,
  handleRightPanel,
  track,
  enableGamification,
  userIsOwner,
  rightPanelContent,
}) => {
  //   useEffect(() => {
  //     getdefaultPreference();
  //   });
  const { axiosGetService } = useAxios();

  const { appState, setAppState, isAdmin, userIsAdmin, endUserView } =
    useLeftMenuCollapse();

  //const { endUserView, setEndUserView } = (false);
  //   const getdefaultPreference = async () => {
  //     await axiosGetService("/api/sdmuser/getbyemail").then((response) => {
  //       if (response.data !== null) {
  //         //response.data.userDefaultView==1;
  // 		console.log(response.data.userDefaultView);
  //         setEndUserView(response.data.userDefaultView);
  //       }
  //     });
  //   };

  return (
    <>
      <div className="userDetails">
        <div className="userImgRound">
          <img
            className="userImage"
            alt="profileImage"
            src={user.profile.photo}
          />
        </div>
        <h4>
          {user.profile.firstName}
          &nbsp;
          {user.profile.surname}
        </h4>
        <h5>{user.profile.jobTitle}</h5>
      </div>
      <div>
        {(isAdmin || userIsOwner) && (
          <div className="switchUserContainer">
            <div className={`${endUserView ? "" : "active"}`}>
              {userIsAdmin ? "Admin View" : "Owner View"}
            </div>
            <Toggle />
            <div className={`${endUserView ? "active" : ""}`}>
              End User View
            </div>
          </div>
        )}
      </div>
      <ul className="userActions">
        <li>
          {isAdmin && (
            <Link
              to="/admin"
              onClick={() => {
                setAppState({
                  ...appState,
                  rightPanelContent: null,
                });
              }}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setAppState({
                    ...appState,
                    rightPanelContent: null,
                  });
                }
              }}
              role={"link"}
              aria-label={"click to open Administration"}
            >
              <span className="adminImage" />
              <span>Administration</span>
              <span className="chevronRight"></span>
            </Link>
          )}
        </li>
        {enableGamification && (
          <li>
            <Link
              to="/myachievements"
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setAppState({
                    ...appState,
                    rightPanelContent: null,
                  });
                  track(1, 109, "myProfile_myachievements", "My Achievements");
                }
              }}
              onClick={() => {
                setAppState({
                  ...appState,
                  rightPanelContent: null,
                });
                track(1, 109, "myProfile_myachievements", "My Achievements");
              }}
              role={"link"}
              aria-label={"click to open My Achievements"}
            >
              <span className="myAchievementsImage" />
              <span>My Achievements</span>
              <span className="chevronRight"></span>
            </Link>
          </li>
        )}

        <li
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.which === 13) {
              handleRightPanel("mySettings");
              track(1, 106, "myProfile_mySettings", "My Settings");
            }
          }}
          onClick={() => {
            handleRightPanel("mySettings");
            track(1, 106, "myProfile_mySettings", "My Settings");
          }}
          role="button"
          aria-label="Click to open My Settings"
          aria-pressed={rightPanelContent === "mySettings" ? true : false}
        >
          <span className="settingImage" />
          <span>My Settings</span>
          <span className="chevronRight"></span>
        </li>
        <li
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.which === 13) {
              handleRightPanel("myFavorites");
              track(1, 107, "myProfile_myFavorites", "My Favorites");
            }
          }}
          onClick={() => {
            handleRightPanel("myFavorites");
            track(1, 107, "myProfile_myFavorites", "My Favorites");
          }}
          role="button"
          aria-label="click to open My Favorites"
          aria-pressed={rightPanelContent === "myFavorites" ? true : false}
        >
          <span className="myFavoritesImage" />
          <span>My Favorites</span>
          <span className="chevronRight"></span>
        </li>
        <li>
          <Link
            to="/mycontributions"
            onClick={() => {
              setAppState({
                ...appState,
                rightPanelContent: null,
              });
            }}
            onKeyPress={(e) => {
              if (e.which === 13) {
                setAppState({
                  ...appState,
                  rightPanelContent: null,
                });
              }
            }}
            role={"link"}
            aria-label={"click to open My Contributions"}
          >
            <span className="myContributionImage" />
            <span>My Contributions</span>
            <span className="chevronRight"></span>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default UserProfile;
