import React, { useState } from "react";
import "./PolygonTopic.css";

const Hexagon = (props) => {
    return (
        <div className="container">
            <ul className="hexagonContent">
                {props.showLinking ?
                    <>
                        <a href={window.location.origin + `/topics/${props.topicsData[1]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon hexagonTopicItem1" : "hexagonTopicItem1"}>
                                <span className="hexagonTopicItemTitle">
                                    {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[2]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon hexagonTopicItem2" : "hexagonTopicItem2"}>
                                <span className="hexagonTopicItemTitle">
                                    {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[3]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon hexagonTopicItem3" : "hexagonTopicItem3"}>
                                <span className="hexagonTopicItemTitle" >
                                    {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[0]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon hexagonTopicItem4" : "hexagonTopicItem4"}>
                                <span className="hexagonTopicItemTitle" >
                                    {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[4]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon hexagonTopicItem5" : "hexagonTopicItem5"}>
                                <span className="hexagonTopicItemTitle">
                                    {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[5]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(5) ? "selectedPolygon hexagonTopicItem6" : "hexagonTopicItem6"}>
                                <span className="hexagonTopicItemTitle" >
                                    {props.topicsData ? props.topicsData[5]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[6]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(6) ? "selectedPolygon hexagonTopicItem7" : "hexagonTopicItem7"}>
                                <span className="hexagonTopicItemTitle">
                                    {props.topicsData ? props.topicsData[6]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                    </>
                    :
                    <>
                        <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon hexagonTopicItem1" : "hexagonTopicItem1"}
                            onClick={() => props.saveNextTopic(1)}>
                            <span className="hexagonTopicItemTitle">
                                {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon hexagonTopicItem2" : "hexagonTopicItem2"}
                            onClick={() => props.saveNextTopic(2)}>
                            <span className="hexagonTopicItemTitle">
                                {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon hexagonTopicItem3" : "hexagonTopicItem3"}
                            onClick={() => props.saveNextTopic(3)}>
                            <span className="hexagonTopicItemTitle" >
                                {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon hexagonTopicItem4" : "hexagonTopicItem4"}
                            onClick={() => props.saveNextTopic(0)}>
                            <span className="hexagonTopicItemTitle" >
                                {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>

                        <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon hexagonTopicItem5" : "hexagonTopicItem5"}
                            onClick={() => props.saveNextTopic(4)}>
                            <span className="hexagonTopicItemTitle">
                                {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>

                        <li className={props.selectedPath.toString().includes(5) ? "selectedPolygon hexagonTopicItem6" : "hexagonTopicItem6"}
                            onClick={() => props.saveNextTopic(5)}>
                            <span className="hexagonTopicItemTitle" >
                                {props.topicsData ? props.topicsData[5]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(6) ? "selectedPolygon hexagonTopicItem7" : "hexagonTopicItem7"}
                            onClick={() => props.saveNextTopic(6)}>
                            <span className="hexagonTopicItemTitle">
                                {props.topicsData ? props.topicsData[6]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </>
                }
            </ul>
        </div>
    );
}

export default Hexagon;