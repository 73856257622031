import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

//context
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import ReviewersOrApproversList from '../ReviewersOrApproversList';
import MyReviewsInfo from '../MyReviewsInfo';

//utils
import { noPreviewFileTypes } from '../../../static/TemplateIcons';

const TemplateReview = ({
	currentPage,
	pageLimit,
	setDataCount,
	setCurrentPage,
	handleStatusClassName,
	enableGamification,
}) => {
	const { url } = useRouteMatch();
	const { track } = useAppInsights();
	const { getNotifications } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService } = useAxios();
	const [searchText, setSearchText] = useState('');
	const [contributions, setContributions] = useState([]);
	const [contributorsInfo, setContributorsInfo] = useState({});
	const [documentStatusId, setDocumentStatusId] = useState(-1);
	const [showApproversOrReviewersList, setShowApproversOrReviewersList] =
		useState(false);
	const [selectedArtifactId, setSelectedArtifactId] = useState(-1);

	const getContributions = async () => {
		const searchParam = searchText === '' ? -1 : searchText;
		setDataCount(0);
		setContributions([]);
		await axiosGetService(
			`api/sdmtemplate/getTemplateContributions?searchValue=${searchParam}&pageSize=${pageLimit}&pageNumber=${currentPage}&userSelection=2`
		).then((res) => {
			if (res.data && Number(res.data.templatesCount) > 0) {
				setContributions(res.data.sdmTemplate2);
				setDataCount(Number(res.data.templatesCount));
			} else {
				setContributions(null);
				setDataCount(null);
			}
		});
	};

	const getUserDetails = async (
		contributedEmail,
		reviewerEmail,
		approverEmail
	) => {
		const accessToken = await axiosTokenService();
		const userDetails = {};
		if (contributedEmail && !(contributedEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, contributedEmail);
			userDetails[contributedEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (reviewerEmail && !(reviewerEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, reviewerEmail);
			userDetails[reviewerEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (approverEmail && !(approverEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, approverEmail);
			userDetails[approverEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		setContributorsInfo((prevState) => ({ ...prevState, ...userDetails }));
	};

	const postNotificationRead = async (contribution) => {
		const postBody = {
			actionTypeId: contribution.actionTypeId,
			itemId: contribution.templateId,
			complexityId: contribution.complexityId,
			itemTypeName: 'Template',
			isNotifiedUI: true,
			createdOn: new Date().toUTCString(),
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then((response) => {
				getNotifications();
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (
			(searchText.length >= 3 && searchText.trim().length !== 0) ||
			searchText.length === 0
		) {
			if (currentPage === 1) {
				getContributions();
			} else {
				setCurrentPage(1);
			}
		}
	}, [searchText]);

	useEffect(() => {
		getContributions();
	}, [currentPage]);

	useEffect(() => {
		track(2, 164, 'Templates', 'My Reviews');
	}, []);

	return (
		<div className="myContributionListDiv">
			<div className="myContributionParentDiv">
				<div className="myConributionHeaderDiv">
					<div className="newTableChildRow header">
						<div className="newTableRow">
							<div className="newWrapper newAttributes">
								<div className="newWrapper newTitleCommentModuleReporter ">
									<div className="newWrapper newTitleComment">
										<div className="newColumn newTitle newActTd ">
											Template Name{' '}
											<div className="myContrTitleSearch">
												<Icon iconName="Search" />
												<input
													type="text"
													title="Search for a Template"
													value={searchText}
													placeholder="Search by name"
													onChange={(e) => setSearchText(e.target.value)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Complexity</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Language</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Acted On</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status  newActTd ">Review Status</div>
							</div>
							{enableGamification ? (
								<div className="newWrapper ">
									<div className="newColumn points  newActTd ">Points</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{contributions &&
					contributions.map((contribution, id) => (
						<div className="mycontributionChildWrapper" key={id}>
							<div className="newTableChildRow">
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment">
												<div className="newColumn newTitle newActTd ">
													<Link
														title={contribution.title}
														aria-label={contribution.title}
														onClick={() => {
															postNotificationRead(contribution);
															track(
																1,
																164,
																contribution.templateId,
																contribution.title,
																{
																	artifactType: 'Templates',
																	crowdSourcingType: 'My Reviews',
																	complexity: contribution.complexityName,
																}
															);
														}}
														to={
															contribution.documentStatus === 'Pending Review'
																? `/template/crowdsourcing/review/${
																		contribution.templateId
																  }/${contribution.isLocalized ? 44 : 1}/${
																		contribution.complexityId
																  }/${contribution.languageId}`
																: `${url}/historicalpreview/template/${contribution.id}/-1/2`
														}
													>
														{contribution.title}
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.isLocalized
												? `NA`
												: contribution.complexityName}
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.languageName}
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.notificationDate || '-'}
										</div>
									</div>
									<div className="newWrapper ">
										<div
											className={`newColumn status newActTd contributionsInfo ${handleStatusClassName(
												contribution.documentStatus
											)}`}
											tabIndex="0"
											role="button"
											aria-label={contribution.documentStatus}
											onMouseEnter={() => {
												setDocumentStatusId(contribution.id);
												getUserDetails(
													contribution.contributorEmail,
													contribution.reviewerEmail
														? contribution.reviewerEmail
														: contribution.reviewerRejectedEmail,
													contribution.approverEmail
														? contribution.approverEmail
														: contribution.approverRejectedEmail
												);
											}}
											onMouseLeave={() => {
												setDocumentStatusId(-1);
											}}
											onBlur={(e) => {
												setDocumentStatusId(-1);
											}}
											onFocus={(e) => {
												setDocumentStatusId(contribution.id);
												getUserDetails(
													contribution.contributorEmail,
													contribution.reviewerEmail
														? contribution.reviewerEmail
														: contribution.reviewerRejectedEmail,
													contribution.approverEmail
														? contribution.approverEmail
														: contribution.approverRejectedEmail
												);
											}}
											onClick={() => {
												if (
													contribution.documentStatus === 'Pending Review' ||
													contribution.documentStatus === 'Pending Approval'
												) {
													setShowApproversOrReviewersList(true);
													setSelectedArtifactId(contribution.id);
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													if (
														contribution.documentStatus === 'Pending Review' ||
														contribution.documentStatus === 'Pending Approval'
													) {
														setShowApproversOrReviewersList(true);
														setSelectedArtifactId(contribution.id);
													}
												}
											}}
										>
											{contribution.documentStatus || '-'}
											{documentStatusId === contribution.id ? (
												<MyReviewsInfo
													reviewerEmail={
														contribution.reviewerEmail
															? contribution.reviewerEmail
															: contribution.reviewerRejectedEmail
													}
													approverEmail={
														contribution.approverEmail
															? contribution.approverEmail
															: contribution.approverRejectedEmail
													}
													contributionData={contribution}
													contributorsInfo={contributorsInfo}
													type="myReview"
													documentStatus={contribution.documentStatus}
												/>
											) : (
												<></>
											)}
										</div>
									</div>
									{enableGamification ? (
										<div className="newWrapper ">
											<div className="newColumn points newActTd">
												{contribution.points}
											</div>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
							{showApproversOrReviewersList &&
							contribution.id === selectedArtifactId ? (
								<ReviewersOrApproversList
									showList={setShowApproversOrReviewersList}
									setShowList={setShowApproversOrReviewersList}
									actionTypeId={
										contribution.documentStatus === 'Pending Review' ? 1 : 2
									}
									artifactId={contribution.templateId}
									artifactType="templates"
									setSelectedArtifactId={setSelectedArtifactId}
								/>
							) : (
								<></>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default TemplateReview;
