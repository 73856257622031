import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import Dropdown from '../Dropdown';
import { artifactMapData } from '../../static/ArtifactMapData';
import HashtagSuggestions from '../HashtagSuggestions';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../context/AxiosContext';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '140px 20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

function AdminSampleEdit({
	enableAdminSampledEdit,
	closeModal,
	data,
	getTemplates,
	complexityId,
}) {
	const { axiosGetService, axiosPostService, axiosPostServiceSwimlane } = useAxios();
	const [file, setFile] = useState(null);
	const [fileSize, setFileSize] = useState(0);
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior 7340032
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
	});
	const [errorMessage, setErrorMessage] = useState(null);
	const defaultLanguage = { name: 'English', id: 15 };
	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

	const defaultCountry = { name: 'Worldwide', id: 217 };
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

	const [error, setError] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [showSpinner, setShowSpinner] = useState(true);
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const [sampleInfo, setSampleInfo] = useState({});

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<button
				tabIndex={0}
				onKeyPress={(e) => {
					if (e.which === 13) {
						setFile(null);
					}
				}}
				onClick={() => setFile(null)}
			>
				X
			</button>
		</li>
	));

	const updateSample = async (title, fileLocation, fileType) => {
		const postData = {
			title: title,
			fileLocation: fileLocation,
			fileType: fileType,
			country: selectedCountry.name,
			language: selectedLanguage.name,
			parentTemplateId: sampleInfo.parentTemplateId,
			complexityId: complexityId,
			hashtags: [...existingHashtags, ...userAddedHashtags].toString(),
			id: data.id,
			languageId: selectedLanguage.id,
			countryId: selectedCountry.id,
			subscriptionId: sampleInfo.subscriptionId,
		};
		await axiosPostService(`api/sdmtemplate/addsampletemplate`, postData)
			.then((response) => {
				if (response.data.status === 'false') {
					setFileUploadStatus(true);
				} else {
					setFileUploadStatus(false);
					setFileUploaded(true);
					setUserAddedHashtags([]);
					setExistingHashtags([]);
					getTemplates();
				}
			})
			.catch((err) => console.log(err));
	};

	const handleSubmit = async () => {
		if (file !== null) {
			const fileName = file.name.split('.');
			fileName.pop();
			fileName.join('.');

			const formData = new FormData();
			formData.append('fileLocation', file);
			setFile('uploading');

			const templateUrlResponse = await axiosPostServiceSwimlane(
				`api/sdmtemplate/uploadSampleTemplate`,
				formData
			);

			if (templateUrlResponse.data) {
				if (templateUrlResponse.data.status === 'false') {
					setFileUploadStatus(true);
					setErrorMessage(templateUrlResponse.data.errorMessage);
				} else {
					updateSample(
						templateUrlResponse.data.fileName,
						templateUrlResponse.data.templateURL,
						templateUrlResponse.data.templateType
					);
				}
			} else {
				setFileUploadStatus(true);
			}
		} else {
			updateSample(
				sampleInfo.title,
				sampleInfo.fileLocation,
				sampleInfo.fileType
			);
		}
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	//  const getLanguages = async () => {
	//   await axiosGetService(`api/sdmtemplate/languages/${selectedCountry.id}`)
	//    .then((response) => {
	//     const data = response.data.map((item) => ({
	//      name: item.name,
	//      id: item.id,
	//     }));
	//     setLanguages(data);
	//     setSelectedLanguage({
	//      id: data[0].id,
	//      name: data[0].name,
	//      option: 'type',
	//     });
	//    })
	//    .catch((error) => console.log(error));
	//  };

	//  const getCountries = async () => {
	//   await axiosGetService('api/sdmtemplate/countries')
	//    .then((response) => {
	//     const data = response.data.map((item) => ({
	//      name: item.name,
	//      id: item.id,
	//     }));
	//     setCountries(data);
	//    })
	//    .catch((error) => console.log(error));
	//  };

	const getSampleDetails = async () => {
		await axiosGetService(
			`api/sdmtemplate/getsampletemplate?sampleTemplateId=${data.id}`
		)
			.then((response) => {
				const sampleData = response.data;
				const countriesData = sampleData.countries.map((item) => {
					return {
						name: item.name,
						id: item.id,
					};
				});
				const languageData = sampleData.languages.map((item) => {
					return {
						name: item.name,
						id: item.id,
						countryIds: item.countryIds,
					};
				});
				setSelectedCountry({
					id: sampleData.countryId,
					name: sampleData.country,
					option: 'type',
				});
				setSelectedLanguage({
					id: sampleData.languageId,
					name: sampleData.language,
					option: 'type',
				});
				const hashtags = sampleData.hashTagsList.map(({ name }) => {
					return name;
				});
				setLanguages(languageData);
				setCountries(countriesData);
				setExistingHashtags(hashtags);
				setSampleInfo(sampleData);
				setShowSpinner(false);
			})
			.catch((error) => console.log(error));
	};

	const filterFileType = (file) => {
		const fileTypes = [
			'xlsx',
			'pptx',
			'potx',
			'ppt',
			'pdf',
			'doc',
			'docx',
			'dotx',
			'zip',
			'xlsm',
			'aspx',
			'msg',
			'mpp',
			'mpt',
		];
		const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
		if (fileTypes.includes(selectedFileType)) {
			setFile(file);
			setFileError(false);
			setError(false);
			setSizeError(false);
		} else {
			setFileError(true);
			setError(false);
			setSizeError(false);
		}
	};

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setError(false);
			setFileError(false);
			setSizeError(true);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		if (error && file !== null) {
			setError(false);
		}
	}, [file]);

	//  useEffect(() => {
	//   getCountries();
	//  }, []);

	//  useEffect(() => {
	//   if (selectedCountry.id !== 0) {
	//    getLanguages();
	//   }
	//  }, [selectedCountry]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		getFileSize();
	}, []);

	useEffect(() => {
		getSampleDetails();
	}, []);

	useEffect(() => {
		const isLanguageAvailable = languages.find(
			(item) =>
				item.countryIds.includes(selectedCountry.id) &&
				item.id === selectedLanguage.id
		);
		if (!isLanguageAvailable) {
			const updatedLanguages = languages.filter((item) =>
				item.countryIds.includes(selectedCountry.id)
			);
			if (updatedLanguages.length > 0) {
				setSelectedLanguage({
					id: updatedLanguages[0].id,
					name: updatedLanguages[0].name,
				});
			}
		}
	}, [selectedCountry, selectedLanguage, countries, languages]);

	return (
		<Modal
			dialogClassName={
				!fileUploaded ? 'templateModal' : 'templateFeedbackPopUp'
			}
			show={true}
			onHide={closeModal}
			centered
			size="sm"
		>
			{showSpinner ? (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			) : (
				<>
					{enableAdminSampledEdit ? (
						fileUploaded || fileUploadStatus ? (
							<>
								<Modal.Body>
									{fileUploadStatus ? (
										<p>{errorMessage}</p>
									) : (
										<p>Sample uploaded successfully</p>
									)}

									<button
										title="OK"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												closeModal();
											}
										}}
										onClick={closeModal}
										className="feedbackSubmittedOk"
									>
										OK
									</button>
								</Modal.Body>
							</>
						) : (
							<>
								<Modal.Header className="sampleSubmitTemplatePopUp">
									Edit Sample
									<Icon
										iconName="Cancel"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												closeModal();
											}
										}}
										onClick={closeModal}
									/>
								</Modal.Header>
								<Modal.Body>
									<div
										className={
											file === 'uploading'
												? 'sampleSubmitTemplatePopUp noClickEvents'
												: 'sampleSubmitTemplatePopUp'
										}
									>
										<div className="detailsOfSampleTemplate">
											<p>
												<span className="bold">Project Type:&nbsp; </span>
												<span>{sampleInfo.projectType}</span>
											</p>
											<p>
												<span className="bold">Phase:&nbsp; </span>
												<span>{sampleInfo.phaseName}</span>
											</p>
										</div>
										<div className="submitSampleFilterDiv">
											<div className="CountryDiv">
												<span>Country:</span>
												<Dropdown
													className="countryFilter"
													data={countries}
													setValue={setSelectedCountry}
													value={selectedCountry}
													enableDropdown={true}
												/>
											</div>
											<div className="languageDiv">
												<span>Language:</span>
												<Dropdown
													className="languageFilter"
													data={languages.filter((item) =>
														item.countryIds.includes(selectedCountry.id)
													)}
													setValue={setSelectedLanguage}
													value={selectedLanguage}
													enableDropdown={
														selectedCountry.id !== 0 ? true : false
													}
												/>
											</div>
										</div>
										<div className="detOfContributionTemplateforHashtags">
											<h6>Add Hashtags</h6>
											<HashtagSuggestions
												className="templateHashtagsInput"
												enableDropdown={true}
												data={hashtagSuggestions}
												setUpdateApiCall={setUpdateApiCall}
												setHashtagSuggestions={setHashtagSuggestions}
												searchValue={hashtagsInput}
												setSearchValue={setHashtagsInput}
												existingHashtags={existingHashtags}
												userAddedHashtags={userAddedHashtags}
												setUserAddedHashtags={setUserAddedHashtags}
												hashtagSuggestionInput={hashtagSuggestionInput}
												setHashtagSuggestionInput={setHashtagSuggestionInput}
												closeHashtagForm={() => {
													setHashtagsInput('');
													setUserAddedHashtags([]);
												}}
												submitHashtags={() => {
													//do nothing
												}}
												autoFocus={false}
												showSubmitButton={false}
											/>
											<div className="hashTagsContainer">
												{existingHashtags.map((item) => (
													<span>{item}</span>
												))}
											</div>
										</div>

										<div className="container">
											<div
												className="filedropContainer"
												{...getRootProps({ style })}
											>
												<input {...getInputProps()} />
												{file === null || file === 'uploaded' ? (
													<>
														<span>Drag and drop a file here</span>
														<span>or</span>
														<button
															title="Choose File"
															className="dragDropButton"
															type="button"
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	open();
																}
															}}
															onClick={open}
														>
															Choose File
														</button>
													</>
												) : (
													<ul>{files}</ul>
												)}
												{file === 'uploading' ? (
													<div>Uploading File...</div>
												) : (
													<></>
												)}
												{sizeError ? (
													<span className="errorMsg">
														Choose a file size less than {fileSize / 1048576}{' '}
														MB.
													</span>
												) : (
													<></>
												)}
												{error ? (
													<span className="errorMsg">
														Choose a file size less than {fileSize / 1048576} MB
														and select Country & Language to Upload
													</span>
												) : (
													<></>
												)}
												{fileError ? (
													<span className="errorMsg">
														Invalid file Format. Only "xlsx, pptx, potx, ppt,
														pdf, doc, docx, dotx, zip, xlsm, aspx, msg, mpp,
														mpt" are allowed.
													</span>
												) : (
													<></>
												)}
											</div>
										</div>
										<button
											title="Submit"
											className="sampleSubmitButton"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													handleSubmit();
												}
											}}
											onClick={handleSubmit}
										>
											Submit
										</button>
									</div>
								</Modal.Body>
							</>
						)
					) : (
						<></>
					)}
				</>
			)}
		</Modal>
	);
}
export default AdminSampleEdit;
