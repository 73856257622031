import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/TopicPreviewModeTrachChanges/DescriptionTrackChanges';
import Spinner from '../../helpers/CrowdSourcingSpinner';

import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './PreviewAndSubmitPopUp.css';

function PreviewAndSubmitPopUp({
	setShowPreviewAndSubmitPopUp,
	users,
	currentUser,
	description,
	descriptionSuggestions,
	setLeavingWithoutSave,
	setSubmitMessage,
	rejectedDescSuggestions,
	contributorDescSuggestions,
	topicDescriptionData,
	licenseKey,
	messagesType,
}) {
	const history = useHistory();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { id } = useParams();
	const [modalShow, setModalShow] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState(1);

	const { axiosPostService, axiosGetService } = useAxios();

	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
	};

	const getSuggestionsInfo = (
		emCollection,
		suggestionsData,
		suggestionsMap
	) => {
		for (var i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-aftPer')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-end-after').split(':')
						.length > 3
						? $(emCollection[i]).attr('data-suggestion-end-after').split(':')[2]
						: $(emCollection[i])
								.attr('data-suggestion-end-after')
								.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-start-before').split(':')
						.length > 3
						? $(emCollection[i])
								.attr('data-suggestion-start-before')
								.split(':')[2]
						: $(emCollection[i])
								.attr('data-suggestion-start-before')
								.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			}
		}
	};

	const findSuggestionsInHtmData = (htmlData) => {
		var domParser = new DOMParser();
		var docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		var emCollection = docElement.getElementsByTagName('suggestion');
		const suggestionsData = [];
		const suggestionsMap = new Map();
		for (var i = 0; i < emCollection.length; i++) {
			const suggestionId = $(emCollection[i]).attr('id').split(':')[0];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (var i = 0; i < emCollection.length; i++) {
			const suggestionId =
				$(emCollection[i]).attr('name').split(':').length > 3
					? $(emCollection[i]).attr('name').split(':')[2]
					: $(emCollection[i]).attr('name').split(':')[1];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		return suggestionsData;
	};

	const updateSuggestionsArray = (allSuggestions, htmlData) => {
		// if (!htmlData || htmlData.length === 0) {
		// 	return null;
		// }
		// const suggestionFromHtmlData = findSuggestionsInHtmData(htmlData);
		// const verifiedSuggestions = [];
		// for (const id in suggestionFromHtmlData) {
		// 	for (const allSuggestionId in allSuggestions) {
		// 		if (
		// 			suggestionFromHtmlData[id] ===
		// 			JSON.parse(allSuggestions[allSuggestionId]).id
		// 		) {
		// 			verifiedSuggestions.push(allSuggestions[allSuggestionId]);
		// 			break;
		// 		}
		// 	}
		// }
		// if (verifiedSuggestions.length > 0) {
		// 	return verifiedSuggestions;
		// } else {
		// 	return null;
		// }
	};

	const verifySuggestions = (previousSuggestions, latestSuggestions) => {
		if (!previousSuggestions && !latestSuggestions) {
			return true;
		}
		if (previousSuggestions && !latestSuggestions) {
			return false;
		}
		for (const data in latestSuggestions) {
			let currentIdFound = false;
			for (const revData in previousSuggestions) {
				if (
					JSON.parse(latestSuggestions[data]).id ===
					JSON.parse(previousSuggestions[revData]).id
				) {
					currentIdFound = true;
					break;
				}
			}
			if (!currentIdFound) {
				return false;
			}
		}
		return true;
	};

	const postData = {
		topicId: id,
		description: description,
		resultCalloutJson: descriptionSuggestions,
		// updateSuggestionsArray(rejectedDescSuggestions, descriptionSuggestions),
		// updateSuggestionsArray(
		// 	descriptionSuggestions,
		// 	description
		// ),
		topicVersion: topicDescriptionData.topicVersion,
		isEditable: true,
	};

	useEffect(() => {
		track(2, 145, topicDescriptionData.topicId, topicDescriptionData.title);
	}, []);

	const submitData = async () => {
		const editingtateId = await axiosGetService(
			`api/sdmtopics/getTopic2/${id}`
		);

		if (editingtateId.data.stateId === 2) {
			setShowLoader(true);
			setLeavingWithoutSave(false);
			setSubmitMessage(messagesType['pendingApproval']);
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				successMessagePopUp: true,
				userLeaveWithSave: true,
			});
			setShowPreviewAndSubmitPopUp(false);
		} else {
			const response = editingtateId.data;
			let descVerified = true;
			if (response.resultCalloutJson) {
				descVerified = verifySuggestions(
					contributorDescSuggestions,
					response.resultCalloutJson
				);
			}
			if (!descVerified) {
				setShowLoader(true);
				setLeavingWithoutSave(false);
				setSubmitMessage(messagesType['contentUpdate']);
				setCrowdSourcingAppState({
					...crowdSourcingAppState,
					reviewerError: true,
					successMessagePopUp: true,
					userLeaveWithSave: true,
				});
				setShowPreviewAndSubmitPopUp(false);
			} else if (
				postData.resultCalloutJson &&
				postData.resultCalloutJson.length > 0
			) {
				setShowLoader(true);
				const imagesUrl = sessionStorage.getItem('crowdSourcing')
					? JSON.parse(sessionStorage.getItem('crowdSourcing'))
					: [];
				postData.imagePaths = [
					...topicDescriptionData.imagePaths,
					...imagesUrl,
				];
				await axiosPostService(`api/sdmtopics/addTopic2Contributor`, postData)
					.then(() => {
						setLeavingWithoutSave(false);
						setSubmitMessage(messagesType['contributionSubmissionMessage']);
						setCrowdSourcingAppState({
							...crowdSourcingAppState,
							successMessagePopUp: true,
							userLeaveWithSave: true,
						});
						sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
						setShowPreviewAndSubmitPopUp(false);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				toast.error(messagesType['noContributionMessage'], {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	};

	const GetImageBase64 = () => {
		var imagesFromDescription = $(previewActivityDescription).find('img');
		$.each(imagesFromDescription, function (i, img) {
			var imageSource = img.src;
			if (imageSource.indexOf('base64') <= 0) {
				imageSource = decodeURI(imageSource);
				axiosGetService(
					`api/sdmuser/getimagebase64fromblob?sourceUrl=` + imageSource
				).then((response) => {
					setPreviewActivityDescription(
						previewActivityDescription.replace(imageSource, response.data)
					);
				});
			}
		});
	};

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				if (type && type.split(' ')[0] === 'image') {
					if (typeof $(element).attr('style') !== 'undefined') {
						$(imgElement).attr({ style: $(element).attr('style') });
					} else {
						$(imgElement).attr({ style: ' width: 100%' });
					}
				} else if (type === 'media') {
					const iframeUrl = $(imgElement).attr('url');
					if (imgElement.children.length < 1) {
						imgElement.innerHTML = HtmlSanitizer(
							`<iframe src=${iframeUrl} style="border:none;" width="640" height="360" arial-label="Preview" allowfullscreen="true"></iframe> `
						);
					}
				}
			}
		});
	});

	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					<Modal
						className="topicPreviewAndSubmitPopUp"
						show={modalShow}
						onHide={() => {
							/* Not calling any function to close modal*/
						}}
						dialogClassName="modal-90w"
					>
						<Modal.Header>
							<Modal.Title>
								Preview
								<div className="discardAndPreviewButton">
									<Button
										title="Cancel"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												closeModal();
												track(
													1,
													148,
													topicDescriptionData.topicId,
													topicDescriptionData.title
												);
											}
										}}
										onClick={() => {
											closeModal();
											track(
												1,
												148,
												topicDescriptionData.topicId,
												topicDescriptionData.title
											);
										}}
										className="activityDiscardButton"
									>
										Cancel
									</Button>
									<Button
										title="Submit"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												// submitData();
												track(
													1,
													203,
													topicDescriptionData.topicId,
													topicDescriptionData.title
												);
											}
										}}
										onClick={() => {
											submitData();
											track(
												1,
												203,
												topicDescriptionData.topicId,
												topicDescriptionData.title
											);
										}}
										className="activityPreviewButton"
									>
										Submit
									</Button>
								</div>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="topicsCrowdSourcingContainer">
								<div class="topicsDescriptionDetails">
									<div className="topicDescriptionDiv">
										<div className="topicDescTitlePart">
											<span className="topicVersion">
												Document Version: {topicDescriptionData.topicVersion}
												,&nbsp;&nbsp;
												{`${topicDescriptionData.modifiedDate} `}
											</span>
										</div>
										<h4 className="topicTitle">{topicDescriptionData.title}</h4>
										<div class="existingHashTagsOfTopic">
											{topicDescriptionData.hashTagsList.map(({ name }) => (
												<span
													key={name}
													onClick={() => {
														history.push(
															`/search?searchParam=${encodeURIComponent(name)}`
														);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															history.push(
																`/search?searchParam=${encodeURIComponent(
																	name
																)}`
															);
														}
													}}
												>
													{name}
												</span>
											))}
										</div>

										{description ? (
											<DescriptionCkEditor
												users={users}
												currentUser={currentUser}
												initialData={description}
												setContent={setPreviewActivityDescription}
												initalSuggestions={descriptionSuggestions}
												licenseKey={licenseKey}
											/>
										) : null}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
}

export default PreviewAndSubmitPopUp;
