import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	value,
	setValue,
	label,
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = value.filter(({ id }) => id === item.id);
		if (filteredData.length > 0) {
			const updatedData = value.filter(({ id }) => id !== item.id);
			setValue(updatedData);
		} else {
			setValue([...value, item]);
		}
	};

	const findSelectedItem = (item) => {
		const filteredData = value.filter(({ id }) => id === item.id);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	return (
		<div title={enableDropdown ? className : ''} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					aria-expanded={visible ? true : false}
					role="listbox"
					aria-roledescription="Dropdown"
					aria-label={label}
				>
					<span
						className="wrapHeader"
						aria-label={
							value.length === 0
								? `Select`
								: value.length === 1
								? `${value[0].name}`
								: `Selected - ${value.length}`
						}
					>
						{value.length === 0
							? `Select`
							: value.length === 1
							? `${value[0].name}`
							: `Selected - ${value.length}`}
					</span>
					<Icon
						iconName="ChevronDown"
						onKeyPress={(e) => {
							if (e.which === 13 && enableDropdown) {
								setVisible(!visible);
							}
						}}
						onClick={() => setVisible(!visible)}
						tabIndex={0}
						role="button"
						aria-label={`Select ${label} dropdown`}
						aria-expanded={visible ? true : false}
					/>
				</div>
				<div>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}

								return (
									<li
										title={item.name}
										key={item.id}
										role="option"
										aria-label={item.name}
										tabIndex={0}
									>
										<label className="NotificationChkboxContainer">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedItem(item)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
