import React, { useEffect } from "react";
import { useAppInsights } from "../../context/TelemetryContext";
import "./Toggle.css";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAxios } from "../../context/AxiosContext";

const Toggle = () => {
  const { track } = useAppInsights();
  const { axiosGetService, axiosPostService, userAlias } = useAxios();
  const { isAdmin, setIsAdmin, endUserView, setEndUserView, userIsAdmin } =
    useLeftMenuCollapse();

  const handleRightPanel = async (view) => {
    const body = {
      userDefaultView: view,
      email: userAlias,
      projecTypeId: 0,
    };
    await axiosPostService("/api/sdmuser/update", body).then((response) => {});
  };

  return (
    <div>
      <div
        className="custom-control custom-switch"
        tabIndex={0}
        title="Toggle View"
        onKeyPress={(e) => {
          if (e.which === 13) {
            e.preventDefault();
            isAdmin ? setIsAdmin(false) : setIsAdmin(userIsAdmin);
            setEndUserView(!endUserView);
            track(1, 2201, "toggle_EndUserView", "EndUserView");
          }
        }}
        onClick={(e) => {
          e.preventDefault();
          isAdmin ? setIsAdmin(false) : setIsAdmin(userIsAdmin);
          setEndUserView(!endUserView);
          track(1, 2201, "toggle_EndUserView", "EndUserView");
          handleRightPanel(!endUserView);
        }}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id="customSwitches"
          checked={endUserView}
          tabIndex={-1}
          readOnly
          aria-label="Switch View"
          role="button"
          aria-pressed="true"
        />
        <label className="custom-control-label" htmlFor="customSwitches" />
      </div>
    </div>
  );
};
export default Toggle;
