import React from 'react';

const Community = () => {
	return (
		<div className="comingSoon">
			<h3>Coming soon!</h3>
		</div>
	);
};

export default Community;
