import React from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import ActivityMyContributions from './Activity';
import TopicsMyContributions from './Topics';
import TemplatePreview from '../TemplateMyContributionPreview';

const ArtifactType = ({ enableGamification }) => {
	const { artifactType } = useParams();

	return (
		<div className="historicalPreviewContainer">
			<div className="fullWidthBreadcrumb">
				<Breadcrumbs />
			</div>
			<div className="myContributionHistoricalPreview">
				{artifactType === 'topics' ? (
					<TopicsMyContributions />
				) : artifactType === 'activity' ? (
					<ActivityMyContributions />
				) : (
					<TemplatePreview enableGamification={enableGamification} />
				)}
			</div>
		</div>
	);
};

export default ArtifactType;
