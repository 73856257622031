import React, { useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import TemplatePreview from './TemplatePreview';

const TemplateContribution = ({
	templateData,
	setTemplateData,
	toolsSidebarHeight,
	CkEditorCurrentUser,
	templateSuggestions,
	setTemplateSuggestions,
	ckEditorUserMap,
	source,
	reviewedTemplateSuggestions,
	setReviewedTemplateSuggestions,
	reviewerTemplateSuggestions
}) => {
	const pageType = ['review', 'approve'];
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	const removeTemplate = (item) => {
		const updateSuggestion = templateSuggestions.filter(
			(template) => JSON.parse(template).id !== item.id
		);
		setTemplateSuggestions([...updateSuggestion]);
		if (pageType.includes(source) && item.authorId !== CkEditorCurrentUser) {
			setReviewedTemplateSuggestions([
				...reviewedTemplateSuggestions,
				JSON.stringify({ id: item.id, state: 'rejected' }),
			]);
		}
		if (source==='approve' && item.authorId === CkEditorCurrentUser && reviewerTemplateSuggestions?.some((value) => item.id === JSON.parse(value).id)) {
			setReviewedTemplateSuggestions([
				...reviewedTemplateSuggestions,
				JSON.stringify({ id: item.id, state: 'rejected' }),
			]);
		}
	};

	const renderTemplateSuggestions = (templates) => {
		if (templates) {
			return templates.map((item) => (
				<li
					className={`${
						JSON.parse(item).suggestionType === 'insertion'
							? 'userAddedItem'
							: 'none'
					}`}
					key={item.id}
				>
					<div>
						<span className="templateEntityName">
							<a href={item.fileLocation} target="_blank">
								{JSON.parse(item).title}
							</a>
						</span>
						<span className="newActivityTemplateListItemIcons">
							<button
								tabIndex="0"
								title="Preview"
								onClick={() => {
									setTemplatePreviewData(JSON.parse(item));
									setEnableTemplatePreview(true);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setTemplatePreviewData(JSON.parse(item));
										setEnableTemplatePreview(true);
									}
								}}
								aria-label={`${JSON.parse(item).title} Preview`}
								role="button"
							>
								<span className="previewIconBlack"></span>
							</button>
							<a
								href={JSON.parse(item).fileLocation}
								title="Download"
								target="_blank"
								aria-label={`${JSON.parse(item).title} Download`}
								role="Link"
							>
								<span className="templateEditDownloadIcon"></span>
								{/* <Icon
        iconName="ReleaseGate"
        aria-hidden="true"
        className="NewdownloadTemplate root-55"
       /> */}
							</a>
						</span>
					</div>
				</li>
			));
		}
	};

	const acceptTemplate = (item) => {
		removeTemplate(item);
		setTemplateData([...templateData, item]);
		setReviewedTemplateSuggestions([
			...reviewedTemplateSuggestions,
			JSON.stringify({ id: item.id, state: 'accepted' }),
		]);
	};

	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer">
					<div className="toolsHeaderEditor">
					<span className='alternativeOfh6' >Templates</span>
					</div>
					<div className="templateContentContainer">
						<ul>
							{templateData.map((item) => (
								<li className={'none'} key={item.id}>
									<div>
										<span className="templateEntityName">
											<a href={item.fileLocation} target="_blank">
												{item.title}
											</a>
										</span>
										<span className="newActivityTemplateListItemIcons">
											<button
												tabIndex="0"
												title="Preview"
												onClick={() => {
													setTemplatePreviewData(item);
													setEnableTemplatePreview(true);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setTemplatePreviewData(item);
														setEnableTemplatePreview(true);
													}
												}}
												aria-label={`${item.title} Preview`}
												role="button"
											>
												<span className="previewIconBlack"></span>
											</button>
											<a
												href={item.fileLocation}
												title="Download"
												target="_blank"
												aria-label={`${item.title} Download`}
												role="button"
											>
												<span className="templateEditDownloadIcon"></span>
												{/* <Icon
												iconName="ReleaseGate"
												aria-hidden="true"
												className="NewdownloadTemplate root-55"
											/> */}
											</a>
										</span>
									</div>
								</li>
							))}

							{renderTemplateSuggestions(templateSuggestions)}
						</ul>
					</div>
				</div>
				<div className="toolsSidebar" style={{ height: toolsSidebarHeight }}>
					{templateSuggestions &&
						templateSuggestions.map((card) => (
							<>
								<div
									className={`sideBarCard ${
										JSON.parse(card).suggestionType === 'insertion'
											? 'userAddedItem'
											: 'userDeletedToolsAndGuidance'
									}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={ckEditorUserMap[JSON.parse(card).authorId][1]}
											alt=""
										/>
										<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>

										{JSON.parse(card).authorId === CkEditorCurrentUser ||
										(pageType.includes(source) &&
											JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
											<Icon
												title="Reject Suggestion"
												ariaLabel={`Click to reject ${
													JSON.parse(card).title
												} as suggestion`}
												role="button"
												iconName="Cancel"
												onClick={() => removeTemplate(JSON.parse(card))}
												onKeyPress={(e) => {
													if (e.which === 13) {
														removeTemplate(JSON.parse(card));
													}
												}}
												tabIndex={0}
											/>
										) : (
											<></>
										)}
										{pageType.includes(source) &&
										JSON.parse(card).authorId !== CkEditorCurrentUser ? (
											<Icon
												title="Accept Suggestion"
												iconName="Accept"
												onClick={() => acceptTemplate(JSON.parse(card))}
												onKeyPress={(e) => {
													if (e.which === 13) {
														acceptTemplate(JSON.parse(card));
													}
												}}
												ariaLabel={`Click to Accept ${
													JSON.parse(card).title
												} as suggestion`}
												role="button"
												tabIndex={0}
											/>
										) : (
											<></>
										)}
									</div>
									<div className="itemStatus">
										<span>
											{JSON.parse(card).suggestionType === 'insertion'
												? 'Add'
												: 'Remove'}
										</span>
										&nbsp;"{JSON.parse(card).title}"
									</div>
								</div>
							</>
						))}
				</div>
			</div>

			{enableTemplatePreview ? (
				<TemplatePreview
					enableTemplatePreview={enableTemplatePreview}
					closeModal={() => {
						setEnableTemplatePreview(false);
						setTemplatePreviewData(null);
					}}
					data={templatePreviewData}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default TemplateContribution;
