import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button, Alert } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import dompurify from 'dompurify';

import { useAppInsights } from '../../context/TelemetryContext';

import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const DereferSolutionMethod = ({
	showDereferSolutionMethodModal,
	setShowDereferSolutionMethodModal,
	setShowParentDereferSolutionMethodModal,
	phaseId,
	type,
	selectedSolutionMethod,
	setSelectedSolutionMethod,
	getPhaseData,
}) => {
	const htmlSanitizer = dompurify.sanitize;
	const { parentPhaseId } = useParams();
	const { track } = useAppInsights();
	const { axiosDeleteService } = useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [disableReferButton, setDisableReferButton] = useState(false);

	const DereferSolutionMethod = async () => {
		setDisableReferButton(true);
		await axiosDeleteService(
			`api/sdmsolutionmethod/derefersolutionmethod?phaseId=${
				type === 'parentPhase' ? parentPhaseId : phaseId
			}&childSolutionMethodId=${selectedSolutionMethod.solutionMethodId}`
		).then((response) => {
			if (response.data.status) {
				track(
					1,
					1703,
					selectedSolutionMethod.solutionMethodId,
					selectedSolutionMethod.solutionMethodName
				);
				setShowSuccessMessage(response.data.status);
			} else {
				setErrorMessage(response.data.errorMessage);
			}
			setDisableReferButton(false);
		});
	};
	return (
		<>
			{disableReferButton ? (
				<Spinner />
			) : (
				<Modal
					className="phasePublishModal"
					show={true}
					onHide={() => {
						if (type === 'parentPhase' && showSuccessMessage) {
							setShowParentDereferSolutionMethodModal(false);
							getPhaseData();
						} else if (showSuccessMessage) {
							setShowDereferSolutionMethodModal(false);
							getPhaseData();
						} else if (type === 'parentPhase') {
							setShowParentDereferSolutionMethodModal(false);
						} else {
							setShowDereferSolutionMethodModal(false);
						}
						setSelectedSolutionMethod('');
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							De-link{' '}
							{selectedSolutionMethod &&
								selectedSolutionMethod.solutionMethodName}
						</Modal.Title>
						<Icon
							onClick={() => {
								if (type === 'parentPhase' && showSuccessMessage) {
									setShowParentDereferSolutionMethodModal(false);
									getPhaseData();
								} else if (showSuccessMessage) {
									setShowDereferSolutionMethodModal(false);
									getPhaseData();
								} else if (type === 'parentPhase') {
									setShowParentDereferSolutionMethodModal(false);
								} else {
									setShowDereferSolutionMethodModal(false);
								}
								setSelectedSolutionMethod('');
							}}
							iconName="ChromeClose"
						></Icon>
					</Modal.Header>
					<Modal.Body>
						{showSuccessMessage || errorMessage ? (
							<>
								{showSuccessMessage ? (
									<Alert variant="success">
										You have successfully de-linked the solution method.
									</Alert>
								) : (
									<Alert variant="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(errorMessage),
											}}
										/>
									</Alert>
								)}
							</>
						) : (
							<>
								<p>
									Are you sure that you want to de-link{' '}
									{selectedSolutionMethod &&
										selectedSolutionMethod.solutionMethodName}
									?
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="Cancel"
										className="activityDiscardPopUpCancel"
										variant="primary"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (type === 'parentPhase') {
													setShowParentDereferSolutionMethodModal(false);
												} else {
												}
												setShowDereferSolutionMethodModal(false);
											}
										}}
										onClick={() => {
											if (type === 'parentPhase') {
												setShowParentDereferSolutionMethodModal(false);
											} else {
											}
											setShowDereferSolutionMethodModal(false);
										}}
									>
										Cancel
									</Button>

									<Button
										title="De-link"
										tabIndex={-1}
										onClick={() => {
											DereferSolutionMethod();
										}}
										disabled={disableReferButton}
										className="activityDiscardPopUpAccept"
										variant="info"
									>
										De-link
									</Button>
								</div>

								{errorMessage ? (
									<span className="errorMsg">{errorMessage}</span>
								) : (
									<></>
								)}
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default DereferSolutionMethod;
