import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';

import TemplateDetailCard from '../TemplateCard/TemplateDetailCard';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import RecentlyDownloadedTemplates from './RecentlyDownloadedTemplates';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import { useAxios } from '../../context/AxiosContext';
import noResults from '../../assets/noResults.svg';
import TemplateCard from '../TemplateCard';
import Breadcrumbs from '../Breadcrumbs';
import Dropdown from '../Dropdown';
import Pagination from '../Pagination';
import TemplateLandingFilter from './TemplateLandingFilter';
import HashtagAutoSuggestions from './HashtagAutoSuggestions';
import AccessabilityCheck from './AccessabilityCheckModal';
import TemplateActivityLink from '../TemplateActivityLink';
import { TabTitle } from '../../utils/GeneralFunctions';

import './TemplateLanding.css';

function Templates() {
	const { setSkipNavigationId, breadcrumbs, setBreadcrumbs } =
		useLeftMenuCollapse();
	const { url } = useRouteMatch();
	const location = window.location.pathname + window.location.search;
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const pageLimit = 10;
	const [templateData, setTemplateData] = useState([]);
	const [templateCount, setTemplateCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchText, setSearchText] = useState('');
	const [showPagination, setShowPagination] = useState(true);
	const [changeLastFiveDownload, setChangeLastFiveDownload] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [complexityTag, setComplexityTag] = useState();
	const [dynamicFilterTagMapping, setDynamicFilterTagMapping] = useState({});
	const [templateHashtags, setTemplateHashtags] = useState([]);
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSearchInput, setHashtagSearchInput] = useState('');
	const [showAccessabillity, setShowAccessabillity] = useState(false);
	const [showNewTemplateBuilder, setShowNewTemplateBuilder] = useState(false);
	const [dynamicFilterTag, setDynamicFilterTag] = useState({});

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSearchInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const loadBreadcrumb = async () => {
		const pageBreadcrumb = {
			title: 'Templates',
			type: 'templates',
			parent: 'home',
			path: window.location.pathname,
		};
		track(2, 163, 'Template Landing', 'Template Landing');
		const newBreadcrumbs = AddBreadCrumb(breadcrumbs, pageBreadcrumb);
		setBreadcrumbs(newBreadcrumbs);
		TabTitle('Templates');
	};

	const getTemplates = async () => {
		const encodedHashtags = templateHashtags.map((item) => {
			return encodeURIComponent(item);
		});
		const selectedFilterIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		let selectedFilter = false;
		for (const id in filterIds) {
			if (dynamicFilterTag[Number(filterIds[id])].selectedTagId.length > 0) {
				selectedFilter = true;
				selectedFilterIds.push(
					`${Number(filterIds[id])}` +
						'-' +
						dynamicFilterTag[Number(filterIds[id])].selectedTagId.toString()
				);
			}
		}
		if(complexityTag !== undefined && complexityTag !== null){
			await axiosGetService(
				`api/sdmtemplate/getwithfilters?pageSize=${pageLimit}&pageNumber=${currentPage}&filterIds=${
					selectedFilter ? selectedFilterIds.join('|') : 0
				}&complexityId=${complexityTag.id}&searchTitle=${
					searchText.trim().length ? encodeURIComponent(searchText.trim()) : 0
				}&hashTagsText=${
					templateHashtags.length > 0 ? encodedHashtags.toString() : 0
				}`
			).then((res) => {
				if (res.data === null || res.data.templatesCount === 0) {
					setTemplateData(null);
					setTemplateCount(0);
				} else if (
					res.data.templatesCount > 0 &&
					res.data.sdmTemplates.length === 0
				) {
					setCurrentPage(1);
					setShowPagination(false);
					setShowPagination(true);
				} else {
					setTemplateData(res.data.sdmTemplates);
					setTemplateCount(res.data.templatesCount);
				}
			});
		}
	};

	const getComplexityTag = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=DefaultComplexityTag`
		).then((response) => {
			const obj = {
				id: response.data[0].value.split(",").at(0),
				name: response.data[0].value.split(",").at(1).toString()
			}
			setComplexityTag(obj);
		});
	};
	
	useEffect(() => {
		getComplexityTag();
	}, []);

	const handleFilterTag = (filterTagId, filterTag, setFilterTag) => {
		const updatedProcessGroupFilterTag = filterTag.filter(
			(id) => id !== filterTagId
		);
		setFilterTag([...updatedProcessGroupFilterTag]);
	};

	const handleTemplateHashtag = (item) => {
		const updateHashtags = templateHashtags.filter(
			(hashtag) => hashtag !== item
		);
		setTemplateHashtags(updateHashtags);
	};

	const collapseFilter = (e) => {
		const filterArea = document.getElementById('filtersRightPanel');
		if (
			filterArea &&
			!filterArea.contains(e.target) &&
			e.target.className !== 'filtersButton' &&
			e.target.className !== 'filterCheckbox' &&
			!(
				typeof e.target.className !== 'object' &&
				e.target.className &&
				e.target.className.includes('searchIcon')
			) &&
			e.target.className !== 'root-50' &&
			e.target.className !== 'root-56' &&
			e.target.className !== 'showMoreFilter'
		) {
			setShowFilters(false);
		}
	};
	const handleDynamicFilterTag = (artifactTypeId, tagId) => {
		const updatedProcessGroupFilterTag = dynamicFilterTag[
			artifactTypeId
		].selectedTagId.filter((id) => id !== tagId);
		const updatedValue = {
			artifactTypeId: artifactTypeId,
			artifactTypeName: dynamicFilterTag[artifactTypeId].artifactTypeName,
			selectedTagId: [...updatedProcessGroupFilterTag],
		};
		setDynamicFilterTag({
			...dynamicFilterTag,
			[artifactTypeId]: updatedValue,
		});
	};
	const clearFilters = () => {
		const newDynamicFilterTag = { ...dynamicFilterTag };
		const filterIds = Object.keys(newDynamicFilterTag);
		for (const id of filterIds) {
			newDynamicFilterTag[Number(id)].selectedTagId = [];
		}
		// getComplexityTag();
		setDynamicFilterTag(newDynamicFilterTag);
		setHashtagSearchInput('');
		setTemplateHashtags([]);
	};
	useEffect(() => {
		if (location === url) {
			loadBreadcrumb();
		}
	}, [location]);

	useEffect(() => {
		let timeOutId;
		setTemplateData([]);
		setTemplateCount(0);
		if (location === url) {
			timeOutId = setTimeout(() => {
				getTemplates();
			}, 1000);
		}
		return () => {
			clearTimeout(timeOutId);
		};
	}, [
		complexityTag,
		currentPage,
		searchText,
		location,
		templateHashtags,
		dynamicFilterTag,
	]);

	useEffect(() => {
		setSkipNavigationId('templatesFilterPart');
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSearchInput.trim() && hashtagSearchInput[0] === '#') {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSearchInput]);

	useEffect(() => {
		window.addEventListener('click', collapseFilter);
		return () => {
			window.removeEventListener('click', collapseFilter);
		};
	}, []);
	return (
		<div className="templatesLandingPage">
			<Switch>
				<Route path={`${url}/:templateId`}>
					<>
						<div className="templatesFiltersAndBreadcrumbCont">
							<div className="templatesBreadcrumb">
								<Breadcrumbs />
							</div>
						</div>
						<div className="templatesDetailPageContainer">
							<div className="templatesList">
								<div className="templatesListCont">
									<TemplateDetailCard />
								</div>
							</div>
						</div>
					</>
				</Route>
				<Route path={url}>
					<>
						<div className="templatesFiltersAndBreadcrumbCont">
							<div className="templatesBreadcrumb">
								<Breadcrumbs />
							</div>
							<div className="templatesDetailsContainer">
								<div className="templatesTitlePart">
									<h4>Templates</h4>
								</div>
								<div className="templatesFilterPart">
									<div>
										<div className="templatesSearch">
											<Icon iconName="Search" />
											<HashtagAutoSuggestions
												className="templateLandingHashtagAutoSuggestion"
												enableDropdown={true}
												data={hashtagSuggestions}
												searchValue={hashtagSearchInput}
												setSearchValue={setHashtagSearchInput}
												existingHashtags={templateHashtags}
												setExistingHashtags={setTemplateHashtags}
												setHashtagSuggestions={setHashtagSuggestions}
												setTemplateSearchValue={setSearchText}
											/>
										</div>
									</div>
									<div class="contributorAddTemplates">
										<div>
											<span>Can't find the template you are looking for?</span>
										</div>
										<span
											class="contributorAddTemplatesButton"
											onClick={() => {
												setShowNewTemplateBuilder(true);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setShowNewTemplateBuilder(true);
												}
											}}
											tabIndex={0}
											aria-label="click to Add a new Template"
											role="button"
										>
											<span>Add a new Template</span>
											<span class="phaseAddIcon"></span>
										</span>
									</div>
									{showNewTemplateBuilder ? (
										<TemplateActivityLink
											showNewTemplateBuilder={showNewTemplateBuilder}
											setShowNewTemplateBuilder={setShowNewTemplateBuilder}
											complexityId={complexityTag?.id}
										/>
									) : (
										<></>
									)}
									<div
										className="accessabilityCheckDiv"
										onClick={() => setShowAccessabillity(true)}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowAccessabillity(true);
											}
										}}
										title="Accessability Instructions"
										tabIndex={0}
										role="button"
										aria-label="Check accessability"
									>
										<div>
											<span className="accessabilityIcon"></span>
											<span className="accessabilityHoverIcon"></span>
											<span className="accessabilityCheckText">
												Check accessability
											</span>
										</div>
									</div>
									{showAccessabillity ? (
										<AccessabilityCheck
											showAccessabillity={showAccessabillity}
											setShowAccessabillity={setShowAccessabillity}
										/>
									) : (
										<></>
									)}
									<div className="raciFiltersPart">
										<span>Clear all filters</span>
										<span
											className="ClearFiltersButton"
											onClick={clearFilters}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													clearFilters();
												}
											}}
											role="button"
											aria-label="Clear all filters"
										></span>
									</div>
									<button
										className="filtersButton"
										onClick={() => setShowFilters(!showFilters)}
									>
										Filters
										<Icon
											className="searchIcon"
											iconName="FilterSolid"
											title="Filters"
										/>
									</button>
								</div>
							</div>
						</div>
						<div className="templatesResultPageContainer" role="main">
							<div className="templateFilterContainer">
								{complexityTag && 
								<TemplateLandingFilter
									showFilters={showFilters}
									setShowFilters={setShowFilters}
									complexityTag={complexityTag}
									setComplexityTag={setComplexityTag}
									dynamicFilterTag={dynamicFilterTag}
									setDynamicFilterTag={setDynamicFilterTag}
									setDynamicFilterTagMapping={setDynamicFilterTagMapping}
								/>
								}
								<div className="filterTagsContainer">
									<div className="tagsContainer">
										<span>Complexity:&nbsp;{complexityTag?.name}</span>
										{templateHashtags.map((item) => (
											<span key={item}>
												{item}
												<Icon
													onClick={() => handleTemplateHashtag(item)}
													iconName="cancel"
												/>
											</span>
										))}
										{Object.keys(dynamicFilterTag).map((item) => (
											<>
												{dynamicFilterTag[Number(item)].selectedTagId.length >
													0 && (
													<>
														{dynamicFilterTag[Number(item)].selectedTagId.map(
															(tagId) => (
																<>
																	{dynamicFilterTagMapping[
																		dynamicFilterTag[Number(item)]
																			.artifactTypeId
																	][tagId] && (
																		<span key={tagId}>
																			{`${
																				dynamicFilterTagMapping[
																					dynamicFilterTag[Number(item)]
																						.artifactTypeId
																				][tagId]
																			}`}
																			<Icon
																				onClick={() =>
																					handleDynamicFilterTag(
																						Number(item),
																						tagId
																					)
																				}
																				tabIndex={0}
																				role="button"
																				aria-label={`Clear ${
																					dynamicFilterTagMapping[
																						dynamicFilterTag[Number(item)]
																							.artifactTypeId
																					][tagId]
																				} Filter`}
																				iconName="cancel"
																			/>
																		</span>
																	)}
																</>
															)
														)}
													</>
												)}
											</>
										))}
									</div>
								</div>
							</div>
							<div className="templateListMainContainer">
								<div className="templatesList">
									<div className="templatesListCont">
										{templateData === null ? (
											<div className="noResultsFound">
												<img
													src={noResults}
													className="noRecentlyDownloaded"
													alt="No Results Found"
												/>
												<p>No results found</p>
											</div>
										) : templateData.length === 0 ? (
											<div className="spinner">
												<Spinner animation="border" />
											</div>
										) : (
											templateData.map((template, index) => (
												<>
													{complexityTag && 
														<TemplateCard
															data={template}
															index={index}
															key={template.title + index}
															changeLastFiveDownload={changeLastFiveDownload}
															setChangeLastFiveDownload={setChangeLastFiveDownload}
															currentPage={currentPage}
															complexityTag={complexityTag}
															getTemplates={getTemplates}
														/>
													}
												</>
											))
										)}
									</div>
									<div
										className={`templatePagination ${
											templateCount <= pageLimit ? 'hidePagination' : ''
										}`}
									>
										{showPagination ? (
											<Pagination
												pageCount={Math.ceil(templateCount / pageLimit)}
												setCurrentPage={setCurrentPage}
												currentPage={currentPage}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
								<RecentlyDownloadedTemplates
									changeLastFiveDownload={changeLastFiveDownload}
								/>
							</div>
						</div>
					</>
				</Route>
				<Route path="*">
					<ComingSoon />
				</Route>
			</Switch>
		</div>
	);
}

const ComingSoon = () => (
	<h2 className="pageNotFound">
		<b>404 Page not found!</b>
	</h2>
);

export default Templates;
