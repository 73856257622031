import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Dropdown({ className, data, setValue, value, enableDropdown }) {
	const [visible, setVisible] = useState(false);
	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const handleDropdown = (item) => {
		const selectedValue = item;
		const filteredData = value.filter(
			({ dragNDropId }) => dragNDropId !== item.dragNDropId
		);
		if (filteredData.length !== value.length) {
			setValue(filteredData);
		} else {
			setValue([...value, selectedValue]);
		}
	};

	const findSelectedItem = (item) => {
		const filteredData = value.filter(
			({ dragNDropId }) => dragNDropId === item.dragNDropId
		);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					aria-expanded={visible ? 'true' : 'false'}
					role="listbox"
					title={
						!enableDropdown
							? 'Add group name to select activities from dropdown'
							: 'Please select activities from dropdown to add into group'
					}
				>
					<span className="wrapHeader">
						{value.length > 0 ? (
							`${value.length} ${
								value.length > 0 ? 'Activities' : 'Activity'
							} Selected`
						) : (
							<>Select Activities</>
						)}
					</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul role="listbox">
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.activityTitle + ' Header'}
											key={item.activityTitle}
											role="option"
										>
											<span>{item.activityTitle}</span>
										</li>
									);
								}
								return (
									<li
										className={
											findSelectedItem(item) ? 'selectedGroupActivity' : 'none'
										}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.activityTitle}
										key={item.activityTitle}
										role="option"
										aria-label={item.activitytitle}
									>
										<span>{item.activityTitle}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
