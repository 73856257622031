import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import TutorialForm from './TutorialForm';
import TutorialSequence from './TutorialSequence';

const TutorialEdit = ({
	setShowTutorialEdit,
	selectedTutorial,
	getConsultingOrSupportTopics,
}) => {
	const [currentTab, setCurrentTab] = useState(1);
	const tabs = [
		{
			id: 1,
			title: 'Edit Video Tutorial',
		}
		// ,
		// { id: 2, title: 'Sequence' },
	];
	const components = {
		1: (
			<TutorialForm
				selectedTutorial={selectedTutorial}
				setCurrentTab={setCurrentTab}
			/>
		),
		// 2: (
		// 	<TutorialSequence
		// 		selectedTab={selectedTab}
		// 		setShowTutorialEdit={setShowTutorialEdit}
		// 	/>
		// ),
	};

	useEffect(() => {
		getConsultingOrSupportTopics();
		return () =>
			getConsultingOrSupportTopics();
	}, [currentTab]);

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles editTutorials"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				setShowTutorialEdit(false);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Edit Video Tutorial
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu" role="tablist">
									{tabs.map((item) => (
										<li
											key={item.id}
											className={currentTab === item.id ? 'activePhaseTab' : ''}
											onClick={() => {
												setCurrentTab(item.id);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setCurrentTab(item.id);
												}
											}}
											role="tab"
											tabIndex={0}
											aria-label={
												currentTab === item.id
													? `${item.title}`
													: `${item.title} not selected`
											}
											aria-selected={currentTab === item.id ? 'true' : 'false'}
										>
											<span>{item.title}</span>
										</li>
									))}
								</ul>
							</div>
							{components[currentTab]}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TutorialEdit;
