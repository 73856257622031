import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useRouteMatch } from 'react-router';
import './MultiLevelDropdown.css';
import { Button } from 'react-bootstrap';

function DropdownMenu({ data, setActiveClassId, activeClassId }) {
	const { track } = useAppInsights();

	if (data.hasSubMenu) {
		return (
			<li>
				<MultiLevelDropdown
					data={data}
					drop="right"
					setActiveClassId={setActiveClassId}
					activeClassId={activeClassId}
				/>
			</li>
		);
	}

	if (data.hasOwnProperty('fileLocation')) {
		return (
			<li>
				<a
					active="false"
					href={data.fileLocation}
					aria-label={data.title}
					title={data.title}
					className="deliveryPlayBook"
					target="_blank"
					onKeyPress={(e) => {
						if (e.which === 13 || e.which === 25 || e.which === 26) {
							track(1, 29, `deliveryPlaybook_${data.title}`, data.title);
						}
						setActiveClassId([]);
					}}
					onClick={() => {
						track(1, 29, `deliveryPlaybook_${data.title}`, data.title);
						setActiveClassId([]);
					}}
				>
					{data.title}
				</a>
			</li>
		);
	}

	return (
		<li>
			<Link
				to={`/project/${0}/method/${data.solutionMethodId}`}
				className="dropdownLink"
				title={data.solutionMethodName}
				aria-label={data.solutionMethodName}
				onKeyPress={(e) => {
					if (e.which === 13 || e.which === 25 || e.which === 26) {
						if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
							track(1, 15, data.solutionMethodId, data.solutionMethodName);
						}
						setActiveClassId([]);
					}
				}}
				onClick={(e) => {
					if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
						track(1, 15, data.solutionMethodId, data.solutionMethodName);
					}
					setActiveClassId([]);
				}}
			>
				<span>{data.solutionMethodName}</span>
			</Link>
		</li>
	);
}

function MultiLevelDropdown({ data, drop, setActiveClassId, activeClassId }) {
	const { track } = useAppInsights();
	const { axiosGetService, userAlias } = useAxios();
	const [subMenu, setSubMenu] = useState([]);
	const { isAdmin, endUserView } = useLeftMenuCollapse();
	const { url } = useRouteMatch();
	const getData = async () => {
		await axiosGetService(data.APIPath).then((res) => {
			setSubMenu(res.data);
		});
	};

	useEffect(() => {
		if (typeof userAlias === 'string') {
			if (data.hasAPIMenu) {
				getData();
			} else {
				setSubMenu(data.subMenu);
			}
		}
	}, [userAlias]);

	const handleDropdownCollapse = (e) => {
		const navDropdown = document.getElementById(`navbar-dropdown-${data.id}`);

if (navDropdown) {
    const activeItem = navDropdown.getElementsByClassName("topMenuItemActive")[0];
    
    if (activeItem && !activeItem.contains(e.target)) {
        setActiveClassId([]);
    }
}

	};

	useEffect(() => {

		if (drop !== 'right') {
			window.addEventListener('click', handleDropdownCollapse);
		}
	}, []);

	return (
		<div className="dropdown" id={`navbar-dropdown-${data.id}`}>

			<a
				// href="#"
				className={`nav-link ${activeClassId.includes(data.id) ? 'topMenuItemActive' : null
					}`}
				type="button"
				data-toggle="dropdown"
				// title={}
				tabIndex="0"
				aria-label={data.title || data.solutionMethodName}
				onClick={() => {
					if (activeClassId.includes(data.id)) {
						const filteredId = activeClassId.filter((id) => id !== data.id);
						setActiveClassId([...filteredId]);
					} else if (activeClassId.includes(4)) {
						setActiveClassId([data.id, 4]);
					} else {
						setActiveClassId([data.id]);
					}
				}}
				onKeyPress={(e) => {
					if (e.which === 13 || e.which === 25 || e.which === 26) {
						if (activeClassId.includes(data.id)) {
							const filteredId = activeClassId.filter((id) => id !== data.id);
							setActiveClassId([...filteredId]);
						} else if (activeClassId.includes(4)) {
							setActiveClassId([data.id, 4]);
						} else {
							setActiveClassId([data.id]);
						}
					}
				}}
				role="button"
			>
				{data.title || data.solutionMethodName} <span className="caret"></span>
			</a>
			{activeClassId.includes(data.id) ? (

				<ul

					className={'dropdownmenu ' + drop}
					id={data.title || data.solutionMethodName}
					onKeyPress={(e) => {
						if (e.which === 13 || e.which === 25 || e.which === 26) {
							track(data.title || data.solutionMethodName, 0, 0, 0);
						}
					}}
					onClick={() => {
						track(data.title || data.solutionMethodName, 0, 0, 0);
					}}
				>
					{subMenu.map((item) => (
						<DropdownMenu
							data={item}
							key={item.title || item.solutionMethodName}
							setActiveClassId={setActiveClassId}
							activeClassId={activeClassId}
						/>
					))}
					{isAdmin && subMenu[0] && subMenu[0].hasOwnProperty('fileLocation') && (
						<div className="addDeliveryPlaybookButton">
							<Link
								to={(location) => {
									return {
										...location,
										search: `?manageDeliveryPlayBook=true`,
									};
								}}
							>
								<span>Manage Delivery Playbooks</span>
								<span className="editDelevieryPlayBookIcon"></span>
							</Link>
						</div>
					)}
				</ul>
			) : null}
		</div>
	);
}

export default MultiLevelDropdown;
