import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';


const SubGroupDeleteModal = ({
    showDeleteSubGroupModal,
    setShowDeleteSubGroupModal,
    selectedDeleteSubGroupDetails
}) => {
    return (
        <>
            <Modal
                className="deletePopup"
                show={showDeleteSubGroupModal}
                onHide={() => {
                    setShowDeleteSubGroupModal(false);
                }}
                size="md"
                centered
            >
                <Modal.Header>
                    Delete {selectedDeleteSubGroupDetails.subGroupName} SubGroup
                    <Icon
                        onClick={() => {
                            setShowDeleteSubGroupModal(false);
                        }}
                        iconName="Cancel"
                    />
                </Modal.Header>

                <Modal.Body>
                    <div>
                    Delete/delink activities first.
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default SubGroupDeleteModal;
