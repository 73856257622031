import React from 'react';
import Iframe from 'react-iframe';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';

function PreviewModal({ enableTemplatePreview, closeModal, data }) {
	const { axiosPostService } = useAxios();
	const { track } = useAppInsights();
	const postSampleDownloadNotification = async () => {
		const postBody = {
			actionTypeId: 3,
			itemId: data.id,
			complexity: 0,
			itemTypeName: 'SampleTemplate',
			eventId: 1,
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then((response) => console.log(response))
			.catch((error) => console.log(error));
	};

	return (
		<Modal
			show={enableTemplatePreview}
			onHide={closeModal}
			centered
			dialogClassName="templateModal"
		>
			{enableTemplatePreview ? (
				<>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">{`Preview - ${data.title}`}</Modal.Title>
						<Icon
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							aria-label={`Click to close ${data.title} Preview modal`}
							role="button"
						/>
					</Modal.Header>
					<Modal.Body>
						<Iframe
							url={data.previewUrl}
							id="templatePreviewIframe"
							role="presentation"
							aria-label={`${data.title} template preview`}
							title={`${data.title} template preview`}
						/>
						<button
							tabIndex={0}
							role="none"
							
							
							aria-label="Download template"
							className="previewDownload"
							onClick={() =>
								track(1, 112, data.id, data.title, {
									phaseName: data.phaseName,
									complexityId: data.complexityId,
								})
							}
						>
							<a
								href={data.fileLocationNew}
								target="_blank"
								onClick={() => {
									if (data.templateMessage === 'Sample') {
										postSampleDownloadNotification();
									}
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										if (data.templateMessage === 'Sample') {
											postSampleDownloadNotification();
										}
									}
								}}
								role="button"
								aria-hidden="true"
								tabIndex={-1}
								
							>
								Download
							</a>
						</button>
					</Modal.Body>
				</>
			) : (
				<></>
			)}
		</Modal>
	);
}

export default PreviewModal;
