import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { useParams } from 'react-router-dom';
import ComplexityDropdown from './ComplexityCloneDropdown';
import MultiSelectDropdown from './MultiSelectDropdown';
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import Spinner from '../../../helpers/Spinner';
import { toast } from 'react-toastify';

const ActivityRefer = ({
	setShowReferActivityForm,
	activityGroups,
	setActivityGroups,
	showCloneFilter,
	setShowCloneFilter,
	projectTypeId,
	solutionMethodId,
	phaseId,
	setDisableSaveAndContinue,
	type,
	parentId,
}) => {
	const { parentPhaseId } = useParams();
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const { complexity } = useLeftMenuCollapse();
	const [showSpinner, setShowSpinner] = useState(true);
	const [activitySearchValue, setActivitySearchValue] = useState('');
	const [foundActivities, setFoundActivities] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState([]);

	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([
		{
			id: Number(projectTypeId),
			name: Number(projectTypeId) == 1 ? 'Consulting' : 'Support',
		},
	]);

	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);

	// phase state
	const [phasesSearchValue, setPhasesSearchValue] = useState('');
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [phases, setPhases] = useState([]);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const checkSelectedActivity = (value, item) => {
		const filteredData = value.filter(({ activityId }) => activityId === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const removeSelectedItem = (value, setValue, item) => {
		const filteredData = value.filter(({ activityId }) => activityId !== item);
		setValue(filteredData);
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};

	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		const filterPhaseId = type === 'parentPhase' ? parentPhaseId : phaseId;
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=${
				phasesSearchValue.trim().length > 0
					? encodeURIComponent(phasesSearchValue)
					: 0
			}&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			const data = response.data.phases
				.map((item) => {
					return {
						name: item.phaseName,
						id: item.phaseId,
					};
				})
				.filter(({ id }) => Number(id) !== Number(filterPhaseId));
			const filterTag = selectedPhases.filter(({ id }) =>
				findSelectedItem(data, id)
			);
			if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
				setSelectedPhases(filterTag);
			}
			setPhases(data);
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	const getActivity = async () => {
		const phaseIds = selectedPhases.map(({ id }) => {
			return id;
		});
		const methods = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmactivity/getactivitiesbymethodandphase?searchValue=${
				activitySearchValue ? activitySearchValue : 0
			}&methodIds=${methods.length ? methods.toString() : 0}&phaseIds=${
				phaseIds.length ? phaseIds.toString() : 0
			}&complexityId=${0}`
		).then((response) => {
			setFoundActivities(response.data);
			closeSpinner();
		});
	};

	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};

	const cloneGroup = (data) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		setActivityGroups(activityGroupClone);
	};

	const referActivity = async () => {
		if (selectedActivity.length > 0) {
			await axiosPostService(
				`api/sdmactivity/addreferedactivity`,
				selectedActivity
			).then((response) => {
				if (response.data.status) {
					for (const activity of selectedActivity) {
						track(1, 910, activity.activityId, activity.title);
					}
					cloneGroup(response.data.activityGroupsData);
					toast.info('You have successfully Linked the activity(ies)', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setDisableSaveAndContinue(false);
			});
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phasesSearchValue, selectedSolutionMethods]);

	useEffect(() => {
		setShowSpinner(true);
		const timeOutId = setTimeout(() => {
			getActivity();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [activitySearchValue, selectedSolutionMethods, selectedPhases]);

	return (
		<div className="addActivityFields">
			<div className="cloneActivityContainer">
				<div className="cloneActivityFieldsWrapper">
					<input
						title={activitySearchValue}
						placeholder="Activity name"
						value={activitySearchValue}
						onChange={(e) => setActivitySearchValue(e.target.value)}
					/>
					<Icon iconName="FilterSolid" />
					<Icon iconName="CaretUpSolid8" className="cloneDropDownArrow" />
				</div>
			</div>
			<div className="cloneActivityFiltersWrapper">
				<div>
					<div className="cloneActivitySolTypeFilters">
						 <div className='hidingConsultingSupport' >
							<span>Consulting / Support</span>
							<MultiSelectDropdown
								className="activityReferProjectSelectionDropdown"
								data={projectTypeData}
								setValue={setSelectedProjectType}
								value={selectedProjectType}
								enableDropdown={true}
								label="Consulting / Support"
							/>
						</div>

						<div className="multiSelectdropDown CloseMultiSelectDp">
							<span>Solution Methods</span>
							<MultiSelectDropdown
								className="activityReferSolutionMethodDropdown"
								data={solutionMethods}
								setValue={setSelectedSolutionMethods}
								value={selectedSolutionMethods}
								enableDropdown={true}
								label="Solution Methods"
							/>
						</div>
					</div>
					<div className="cloneActivitySolTypeFilters">
						<div className="multiSelectdropDown CloseMultiSelectDp">
							<span>Phases</span>
							<MultiSelectDropdown
								className="activityReferSolutionMethodphasesDropdown"
								data={phases}
								setValue={setSelectedPhases}
								value={selectedPhases}
								enableDropdown={true}
								label="Phases"
							/>
						</div>
					</div>
				</div>
				<div className="AddActivityCloneButtonSection actvityAddResultsButton">
					<h6>{foundActivities.length} Result(s) Found</h6>
					<button
						className="AddActivityCloneButton"
						onClick={() => {
							referActivity();
						}}
					>
						Link Activity(ies)
					</button>
				</div>
			</div>
			<div className="cloneActivityResultsWrapper">
				{showSpinner ? (
					<Spinner />
				) : (
					<>
						{foundActivities.length ? (
							<>
								<ul>
									{showMore ? (
										<>
											{foundActivities.map((item) => (
												<li
													key={item.id + item.name + item.activityId}
													title={item.name}
												>
													<label class="NotificationChkboxContainer">
														<input
															name={item.name}
															type="checkbox"
															onChange={() => {
																if (
																	checkSelectedActivity(
																		selectedActivity,
																		item.id
																	)
																) {
																	removeSelectedItem(
																		selectedActivity,
																		setSelectedActivity,
																		item.id
																	);
																} else {
																	setSelectedActivity([
																		...selectedActivity,
																		{
																			parentPhaseId:
																				type === 'parentPhase'
																					? parentPhaseId
																					: phaseId,
																			activityId: item.id,
																			groupId: item.groupId,
																			solutionMethodId:
																				type === 'parentPhase'
																					? Number(parentId)
																					: Number(solutionMethodId),
																			complexityId: complexity.id,
																			title: item.name,
																			isRightSolutionMethod:
																				type === 'parentPhase' ? true : false,
																		},
																	]);
																}
															}}
															checked={checkSelectedActivity(
																selectedActivity,
																item.id
															)}
														/>
														<span class="CustomCheckmark"></span>
														<span class="complexityLabel cloneActivityTitle">
															{item.name}
														</span>
													</label>
													<div class="cloneInfiActivity">
														<Icon iconName="Info" />
														<div className="tabsTitleToolTip">
															<span className="tabsToolTipArrow"></span>
															<h5>Activity Details</h5>
															<h6>{item.projectTypeName}</h6>
															<span className="detailsOfCloneActivity">
																<span className="detailsCloneActivitySol">
																	<span>Delivery Method</span>:&nbsp;
																	<span>{item.solutionMethodName}</span>
																</span>
																<span className="detailsCloneActivitySol">
																	<span>Phase</span>:&nbsp;
																	<span>{item.phaseTitle}</span>
																</span>
															</span>
														</div>
													</div>
												</li>
											))}
										</>
									) : (
										<>
											{foundActivities
												.slice(
													0,
													foundActivities.length > 10
														? 10
														: foundActivities.length
												)
												.map((item) => (
													<li
														key={item.id + item.name + item.activityId}
														title={item.name}
													>
														<label class="NotificationChkboxContainer">
															<input
																name={item.name}
																type="checkbox"
																onChange={() => {
																	if (
																		checkSelectedActivity(
																			selectedActivity,
																			item.id
																		)
																	) {
																		removeSelectedItem(
																			selectedActivity,
																			setSelectedActivity,
																			item.id
																		);
																	} else {
																		setSelectedActivity([
																			...selectedActivity,
																			{
																				parentPhaseId:
																					type === 'parentPhase'
																						? parentPhaseId
																						: phaseId,
																				activityId: item.id,
																				groupId: item.groupId,
																				solutionMethodId:
																					type === 'parentPhase'
																						? Number(parentId)
																						: Number(solutionMethodId),
																				complexityId: complexity.id,
																				title: item.name,
																				isRightSolutionMethod:
																					type === 'parentPhase' ? true : false,
																			},
																		]);
																	}
																}}
																checked={checkSelectedActivity(
																	selectedActivity,
																	item.id
																)}
															/>
															<span class="CustomCheckmark"></span>
															<span class="complexityLabel cloneActivityTitle">
																{item.name}
															</span>
														</label>

														<div class="cloneInfiActivity">
															<Icon iconName="Info" tabIndex={0} />
															<div className="tabsTitleToolTip">
																<span className="tabsToolTipArrow"></span>
																<h5>Activity Details</h5>
																<h6>{item.projectTypeName}</h6>
																<span className="detailsOfCloneActivity">
																	<span className="detailsCloneActivitySol">
																		<span>Delivery Method</span>:&nbsp;
																		<span>{item.solutionMethodName}</span>
																	</span>
																	<span className="detailsCloneActivitySol">
																		<span>Phase</span>:&nbsp;
																		<span>{item.phaseTitle}</span>
																	</span>
																</span>
															</div>
														</div>
													</li>
												))}
										</>
									)}
								</ul>
							</>
						) : (
							<></>
						)}
						{foundActivities.length > 10 && !showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(true)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(true);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show more Activities"
							>
								Show more
							</span>
						) : (
							<></>
						)}
						{showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(false)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(false);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show less Activities"
							>
								Show less
							</span>
						) : (
							<></>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ActivityRefer;
