import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Dropdown({ className, data, setValue, value, enableDropdown }) {
	const [visible, setVisible] = useState(false);
	const [selectedOption,setSelectedOption] = useState(null)

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		setValue(item);
		setVisible(!visible);
		setSelectedOption(item.id);
	};

	return (
		<div title={value.name} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
				style={{width:'280px'}}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					role="combobox"
					aria-label={`${value.name} selected`}
					aria-expanded={enableDropdown ? 'true' : 'false'}
					title="Select Complexity Dropdown"
				>
					<span className="wrapHeader"  style={{width:"200px"}}>{value.name}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.id}
									>
										<input
											 type="radio"
											 value={item.id}
											 checked={selectedOption === item.id}
											 onChange={() =>  handleDropdown(item)}/> 
											 <span style={{paddingLeft:'5px'}}>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
