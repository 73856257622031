import React from 'react';
import { Spinner } from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';

import './Spinner.css';

function CrowdSourcingSpinner() {
	return (
		<Modal
			className="CrowdSourcingLoader"
			show={true}
			onHide={() => {
				// We should not close spinner when user click
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{/* <Modal.Body> */}
			<div className="spinner">
				<Spinner animation="border" />
			</div>
			{/* </Modal.Body> */}
		</Modal>
	);
}

export default CrowdSourcingSpinner;
