import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import noNotification from '../../assets/noNotification.png'
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import RatingModal from './RatingModal';

import './Notifications.css';

function Notifications({ handleCloseButton }) {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosPostService } = useAxios();
	const { notifications, notificationExpand, getNotifications } =
		useLeftMenuCollapse();
	const [enableRatingModal, setEnableRatingModal] = useState(false);
	const [templateData, setTemplateData] = useState({}); // for rating Modal

	const routeToArtifact = (item) => {
		let reviewerPath = '',
			approverPath = '',
			contributorPreviewPath = '',
			reviewerPreviewPath = '';

		if (item.itemTypeName === 'Activity') {
			reviewerPath = `/project/${item.projectTypeId}/method/${item.methodId}/phase/${item.phaseId != 0 ? item.phaseId : item.parentPhaseId}/parentPhase/null/activity/${item.itemId}/reviewer/edit?complexity=${item.complexityId}&identifier=${item.itemIdentifier}`;
			approverPath = `/project/${item.projectTypeId}/method/${item.methodId}/phase/${item.phaseId != 0 ? item.phaseId : item.parentPhaseId}/parentPhase/null/activity/${item.itemId}/approver/edit?complexity=${item.complexityId}&identifier=${item.itemIdentifier}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/activity/${item.historyId}/${item.itemId}/1?complexity=${item.complexityId}`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/activity/${item.historyId}/${item.itemId}/2?complexity=${item.complexityId}`;
		} else if (item.itemTypeId === 10) {
			reviewerPath = `/topics/reviewer/edit/${item.itemId}`;
			approverPath = `/topics/approver/edit/${item.itemId}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/topics/${item.historyId}/${item.itemId}/1`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/topics/${item.historyId}/${item.itemId}/2`;
		} else if (
			item.itemTypeName === 'Template' ||
			item.itemTypeName === 'Localized Template'
		) {
			reviewerPath = `/template/crowdsourcing/review/${item.itemId}/${item.itemTypeId}/${item.complexityId}/${item.languageId}`;
			approverPath = `/template/crowdsourcing/approve/${item.itemId}/${item.itemTypeId}/${item.complexityId}/${item.languageId}`;
			contributorPreviewPath = `/mycontributions/historicalpreview/template/${item.historyId}/-1/1`;
			reviewerPreviewPath = `/mycontributions/historicalpreview/myreviews/template/${item.historyId}/-1/2`;
		}

		if (item.actionTypeId === 1) {
			history.push(reviewerPath);
		} else if (item.actionTypeId === 2) {
			history.push(approverPath);
		} else if (
			item.actionTypeId === 4 ||
			item.actionTypeId === 6 ||
			item.actionTypeId === 7
		) {
			history.push(contributorPreviewPath);
		} else if (item.actionTypeId === 5 || item.actionTypeId === 8) {
			history.push(reviewerPreviewPath);
		}
	};

	const postNotificationRead = async (item) => {
		track(1, 113, item.actionTypeId + '+' + item.itemId, item.title);
		if (!item.isNotifiedUI) {
			const postBody = {
				...item,
				isNotifiedUI: !item.isNotifiedUI,
			};
			await axiosPostService('api/sdmnotification/insert', [postBody])
				.then((response) => {
					getNotifications();
				})
				.catch((error) => console.log(error));
		}
	};

	useEffect(() => {
		getNotifications();
	}, []);

	return (
		<div className="notificationsRightPanel" key={notificationExpand}>
			<div className="notificationParentDiv">
				<ul className="listOfNotifications">
					{notifications.data &&
						notifications.data.map((notification) => {
							if (notification.itemTypeName === 'SampleTemplate') {
								return (
									<li
										tabIndex={0}
										key={`notification_${notification.itemTypeName}_${notification.itemId}`}
										className={
											notification.isNotifiedUI ? 'readNotification' : ''
										}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setTemplateData(notification);
												setEnableRatingModal(true);
												postNotificationRead(notification);
											}
										}}
										onClick={() => {
											setTemplateData(notification);
											setEnableRatingModal(true);
											postNotificationRead(notification);
										}}
										title="Notifications"
										role="link"
									>
										<div className="ratingSampleInNotification">
											<span className="favoritesImg"></span>
											<div>
												<p className="favoriteDesc">
													You downloaded the Sample '{notification.title}'.
													Would you like to rate it?
												</p>
												<span>
													This helps in suggesting better & relevant templates
													to users
												</span>
											</div>
										</div>
									</li>
								);
							} else {
								return (
									<>
										{notificationExpand ? (
											<li
												tabIndex={0}
												key={`notification_${notification.itemTypeName}_${notification.itemId}`}
												className={
													notification.isNotifiedUI ? 'readNotification' : ''
												}
												onKeyPress={(e) => {
													if (e.which === 13) {
														postNotificationRead(notification);
														handleCloseButton();
														routeToArtifact(notification);
													}
												}}
												onClick={(e) => {
													postNotificationRead(notification);
													handleCloseButton();
													routeToArtifact(notification);
												}}
												title="Notification"
												role="link"
											>
												<div className="newTableChildRow">
													<div className="newTableRow">
														<div className="newWrapper newAttributes">
															<div className="newWrapper newTitleCommentModuleReporter ">
																<div className="newWrapper newTitleComment ">
																	<div className="newColumn newTitle newActTd ">
																		{notification.projectTypeName ? (
																			<span className="notifyTitle">{`${notification.title}`}</span>
																		) : (
																			<span className="notifyTitle">{`${
																				notification.title
																			} ${
																				Number(notification.itemTypeId) === 44
																					? `(${notification.languageName})`
																					: `- ${notification.complexityName}`
																			}`}</span>
																		)}
																	</div>
																</div>
															</div>
														</div>
														<div className="newWrapper ">
															<div className="newColumn notifyRequest newActTd ">
																{notification.actionTypeId === 2
																	? 'For Approval'
																	: notification.actionTypeId === 1
																	? 'For Review'
																	: notification.actionTypeId === 6
																	? 'Sent for Approval'
																	: notification.actionTypeId === 7 ||
																	  notification.actionTypeId === 8
																	? 'Rejected'
																	: 'Published'}
															</div>
														</div>
														<div className="newWrapper ">
															<div className="newColumn notifyType  newActTd ">
																{notification.itemTypeName.toUpperCase()}
															</div>
														</div>
														<div className="newWrapper ">
															<div className="newColumn notifyTypeDate newActTd ">
																{notification.notificationDate}
															</div>
														</div>
													</div>
												</div>
											</li>
										) : (
											<li
												tabIndex={0}
												key={`notification_${notification.itemTypeName}_${notification.itemId}`}
												className={
													notification.isNotifiedUI ? 'readNotification' : ''
												}
												onKeyPress={(e) => {
													if (e.which === 13) {
														postNotificationRead(notification);
														handleCloseButton();
														routeToArtifact(notification);
													}
												}}
												onClick={() => {
													postNotificationRead(notification);
													handleCloseButton();
													routeToArtifact(notification);
												}}
												title="Notifications"
												role="link"
											>
												<div className="notifyTitleAndAuthorDiv">
													<div className="notifyTitle">
														{!notification.projectTypeName
															? `${notification.title} ${
																	Number(notification.itemTypeId) === 44
																		? `(${notification.languageName})`
																		: `- ${notification.complexityName}`
															  }`
															: `${notification.title}`}
													</div>
													<div className="notifyAuthor">
														<span className="notifiedDate">
															{notification.notificationDate}
														</span>
													</div>
												</div>
												<div className="notifyTypeAndRequest">
													<div className="notifyType">
														{notification.itemTypeName.toUpperCase()}
													</div>
													<div className="notifyRequest">
														{notification.actionTypeId === 2
															? 'For Approval'
															: notification.actionTypeId === 1
															? 'For Review'
															: notification.actionTypeId === 6
															? 'Sent for Approval'
															: notification.actionTypeId === 7 ||
															  notification.actionTypeId === 8
															? 'Rejected'
															: 'Published'}
													</div>
												</div>
											</li>
										)}
									</>
								);
							}
						})}
						{notifications.data == 0 ? (
						<div className='noNotification'>
							<div className='noNotificationImg' role="img" aria-label="No Notification">
							 	<img src={noNotification} className="noRecentlyDownloaded" alt="No notification"/>
							 </div>
							<div className='noNotificationMessage'>
								<h5>No Notifications or suggestions</h5>
								<p>Check back later to see what&#39;s new and stay up to date.</p>
							</div>
						</div>
				) : (
					<></>
				)}
				</ul>
				{enableRatingModal ? (
					<RatingModal
						enableRatingModal={enableRatingModal}
						closeModal={() => setEnableRatingModal(false)}
						templateData={templateData}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default Notifications;
