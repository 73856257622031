import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { useAxios } from '../../context/AxiosContext';

const SubGroupActualDeleteModal = ({
    showActualDeleteSGrpModal,
    setShowActualDeleteSGrpModal,
    setPhaseCreated,
    phaseCreated,
    selectedDeleteSubGroupDetails
}) => {
    const { axiosDeleteService } = useAxios();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const errMessage = 'Not able to delete the SubGroup. Please contact administrator.'

    const deleteSubgroup = async () => {
        setShowSpinner(true)
        await axiosDeleteService(
            `api/sdmactivity/deletesubgroup/${selectedDeleteSubGroupDetails.subGroupId}` //
        ).then((response) => {
            if (response.data.status) {
                setShowSpinner(false)
                setSuccessMessage(true)
            }
            else {
                setShowSpinner(false)
                setErrorMessage(true);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 5000);
            }
        }).catch((err) => {
            setShowSpinner(false)
            console.log(err)
        })

    };

    return (
        <>
            {showSpinner ? <Spinner></Spinner> :
                (
                    <Modal
                        className="deletePopup"
                        show={showActualDeleteSGrpModal}
                        onHide={() => {
                            setShowActualDeleteSGrpModal(false);
                            setPhaseCreated(!phaseCreated);
                        }}
                        size="md"
                        centered
                    >
                        <Modal.Header>
                            Delete {selectedDeleteSubGroupDetails.subGroupName} SubGroup
                            <Icon
                                onClick={() => {
                                    setShowActualDeleteSGrpModal(false);
                                    setPhaseCreated(!phaseCreated);
                                }}
                                iconName="Cancel"
                            />
                        </Modal.Header>

                        <Modal.Body>
                            <div className="deletePopupContainer">
                                {successMessage ? (
                                    <Alert variant="success">
                                        You have successfully deleted the {selectedDeleteSubGroupDetails.subGroupName} subgroup.
                                    </Alert>
                                ) : (
                                    <><p>
                                        Are you sure you want to delete the {selectedDeleteSubGroupDetails.subGroupName} subgroup?
                                    </p>
                                        <div className="deletePopupButton">
                                            <Button
                                                className="templateRejectButton"
                                                onClick={() => {
                                                    setShowActualDeleteSGrpModal(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    deleteSubgroup();
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                        {errorMessage ? (
                                            <span className="errorMsg">{errMessage}</span>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
        </>
    );
};

export default SubGroupActualDeleteModal;
