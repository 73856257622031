import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';

import { getUsersList } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { Spinner } from 'react-bootstrap';
import UserSelectionDropdown from '../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown';
import OwnerSelectionDropdown from '../TopicsBuilder/OwnerSelectionDropdown';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

const TopicsOwnerCollaborator = ({
	coOwners,
	setCoOwners,
	reviewers,
	setReviewers,
	approvers,
	setApprovers,
	showSpinner,
	topicOwnerInfo,
	setTopicOwnerInfo,
	showOwnerForm,
	artifactName,
	isAdminOrOwner,
	editingMode,
	setEditingMode,
	leftMenuSelectedId,
	astrikFlag,
	categoryOwner
}) => {
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		topicOwner: [],
	});
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [topicOwnerEmail, setTopicOwnerEmail] = useState('');
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		topicOwner: false,
	});
	const [showUsersList, setShowUsersList] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		topicOwner: false,
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		topicOwner: false,
	});
	const {
		endUserView,
	} = useLeftMenuCollapse();
	const [isAdminOrOwnerCollaborator, setIsAdminOrOwnerCollaborator]=useState(endUserView)
	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getUser(coOwnerEmail, 'coOwner');
			} else {
				setError({ ...error, ['coOwner']: false });
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (topicOwnerEmail) {
				getUser(topicOwnerEmail, 'topicOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, topicOwner: [] }));
				setError({ ...error, ['topicOwner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [topicOwnerEmail]);

	return (
		<div className="methodPhaseCollabaratorsTab">
			{showSpinner ? (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			) : (
				<div className="phaseCollabaratorsFields">
					{showOwnerForm && (
						<div className={editingMode ? "collabaratorsMainContainers":"collabaratorsChildContainersEndUserView"}>
						<div className={!isAdminOrOwnerCollaborator && isAdminOrOwner ? 'activityTitleContainer' : ''}>
							<div className='collaboratorsTitleDiv'><h6 className='collaboratorsTitle'>Topics and Job Aids / <span>{artifactName}</span>
							</h6>
							</div>
							{!isAdminOrOwnerCollaborator && isAdminOrOwner? <div onClick={()=>{setEditingMode(true);setIsAdminOrOwnerCollaborator(false)}} className='editIconActivity'></div> : <></>}
						</div>
						{console.log('C_leftMenuSelectedId',leftMenuSelectedId)}
						{leftMenuSelectedId===1 &&
							<div className="inheritedCollabaratorsLegend">
								<p><span className='inheritedCollaboratorsIconRounded'></span>The collaborators automatically receive their permissions from the corresponding Topic category unless specified otherwise for a particular topic.</p>
							</div>
						}
						{leftMenuSelectedId===2 &&
							<div className="inheritedCollabaratorsLegend">
								<p><span className='inheritedCollaboratorsIconRounded'></span>The collaborators automatically receive their permissions from the corresponding Job aid category unless specified otherwise for a particular job aid.</p>
							</div>
						}
							<div className="collabaratorsParentContainers">
								<h6>{editingMode ? 'Add Owner' : 'Owner'}</h6>
								{editingMode && <OwnerSelectionDropdown
									className="topicOwner"
									enableDropdown={true}
									data={usersList}
									value={topicOwnerInfo}
									setValue={setTopicOwnerInfo}
									type="topicOwner"
									searchValue={topicOwnerEmail}
									setSearchValue={setTopicOwnerEmail}
									error={error}
									emailFound={emailFound['topicOwner']}
								/>}
								{topicOwnerInfo?.mail ? (
									<ul className={'afterSaveListTopic collaboratorsApprovers'}>
										<li key={topicOwnerInfo.mail}>
											{editingMode && <span className="collabaratorPersonaImage">
												<img src={topicOwnerInfo.photo} alt="img" />
											</span>}
											<span className={editingMode ? "collabaratorPersonaTitle":"collabaratorPersonalTitleEndUserView"}>
												
											{!editingMode ? (<span className='activityHyperLinkName'>
												{topicOwnerInfo.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
												<a href={`mailto:${topicOwnerInfo.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{topicOwnerInfo.firstName +
													' ' +
													topicOwnerInfo.surname}
											    
											</a>
											</span>):(
												<span>
												{topicOwnerInfo.firstName +
													' ' +
												topicOwnerInfo.surname}
												</span>
												
											)}
											</span>
											{ editingMode  && <span>
												<Icon
												hidden={topicOwnerInfo.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														setTopicOwnerInfo({});
													}}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setTopicOwnerInfo({});
														}
													}}
													role="button"
													tabindex="0"
													aria-label={`Remove ${topicOwnerInfo.firstName} ${topicOwnerInfo.surname}`}
													title="Remove Owner"
												/>
											</span>}
										</li>
									</ul>
								) : (
									<></>
								)}
							</div>
						</div>
					)}
					<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
							{coOwners.length === 0 && !editingMode ?
							<></> :
							<h6>{editingMode ? 'Add Co-owners' : 'Co-owners'}{astrikFlag?<span className="red-asterisk"> *</span>: <></>}</h6>
						     }
							{editingMode && <UserSelectionDropdown
								className="coOwner"
								enableDropdown={true}
								data={usersList}
								value={coOwners}
								setValue={setCoOwners}
								type="coOwner"
								searchValue={coOwnerEmail}
								setSearchValue={setCoOwnerEmail}
								error={error}
								emailFound={emailFound['coOwner']}
							/>}
						</div>

						{coOwners.length > 0 && (
							<div className={editingMode ? "collabaratorsChildContainers newDisplay" : "collabaratorsChildContainersEndUserView newDisplay"}>
								<ul>
									{coOwners.map((user) => (
										<li key={user.mail}>
											{editingMode && <span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>}
											<span className={editingMode ? "collabaratorPersonaTitle":"collabaratorPersonalTitleEndUserView"}>
												{!editingMode ? (<span className='activityHyperLinkName'>
												{user.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
												<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{user.firstName +
													' ' +
													user.surname}
											    
											</a></span>):(
												<span>
												{user.firstName +
													' ' +
													user.surname}
												</span>
												
											)}
											</span>
											{editingMode && <span>
												<Icon
												hidden={user.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, coOwners, setCoOwners);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, coOwners, setCoOwners);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove CoOwner"
												/>
											</span>
											}
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
							{approvers.length === 0 && !editingMode ? <></>
							: <h6>{editingMode ? 'Add Approvers':'Approvers'}{astrikFlag?<span className="red-asterisk"> *</span>: <></>}</h6>
						    }
							{editingMode && <UserSelectionDropdown
								className="approver"
								enableDropdown={true}
								data={usersList}
								value={approvers}
								setValue={setApprovers}
								type="approver"
								searchValue={approverEmail}
								setSearchValue={setApproverEmail}
								error={error}
								emailFound={emailFound['approver']}
							/>
                            }
						</div>

						{approvers.length > 0 && (
							<div className={editingMode ? "collabaratorsChildContainers newDisplay" : "collabaratorsChildContainersEndUserView newDisplay"}>
								<ul>
									{approvers.map((user) => (
										<li key={user.mail}>
											{editingMode && <span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>}
											<span className={editingMode ? "collabaratorPersonaTitle":"collabaratorPersonalTitleEndUserView"}>
											{!editingMode ? (<span className='activityHyperLinkName'>
												{user.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
												<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{user.firstName +
													' ' +
													user.surname}
											</a>
											</span>):(
												<span>
												{user.firstName +
													' ' +
													user.surname}
												</span>
												
											)}
											</span>
											{editingMode && <span>
												<Icon
												hidden={astrikFlag ? (categoryOwner.mail === user.mail) : user.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, approvers, setApprovers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, approvers, setApprovers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove Collabarator"
												/>
											</span>
                                            }
										</li>
									))}
								</ul>
							</div>
						)}
					</div>

					<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
						{reviewers.length === 0 && !editingMode ? <></>:
							<h6>{editingMode ? 'Add Reviewers':'Reviewers'}{astrikFlag?<span className="red-asterisk"> *</span>: <></>}</h6>
							}
							{editingMode && <UserSelectionDropdown
								className="reviewer"
								enableDropdown={true}
								data={usersList}
								value={reviewers}
								setValue={setReviewers}
								type="reviewer"
								searchValue={reviewerEmail}
								setSearchValue={setReviewerEmail}
								error={error}
								emailFound={emailFound['reviewer']}
							/>
                            }
						</div>

						{reviewers.length > 0 && (
							<div className={editingMode ? "collabaratorsChildContainers newDisplay" : "collabaratorsChildContainersEndUserView newDisplay"}>
								<ul>
									{reviewers.map((user) => (
										<li key={user.mail}>
											{editingMode && <span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
                                            }
											<span className={editingMode ? "collabaratorPersonaTitle":"collabaratorPersonalTitleEndUserView"}>
											{!editingMode ? (<span className='activityHyperLinkName'>
												{user?.isInhertedValues && <span className='inheritedCollaboratorsIcon iIconforCollaborators'></span>}
												<a href={`mailto:${user.mail}`} className='collabaratorPersonalTitleEndUserViewHyperLink'>
												{user.firstName +
													' ' +
													user.surname}
											</a>
											</span>):(
												<span>
												{user.firstName +
													' ' +
													user.surname}
												</span>
												
											)}
											</span>
											{editingMode && <span>
												<Icon
												hidden={user.isInhertedValues}
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, reviewers, setReviewers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, reviewers, setReviewers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove Collabarator"
												/>
											</span>
                                            }
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default TopicsOwnerCollaborator;
