import React, { useState } from "react";
import "./PolygonTopic.css";

const Triangle = (props) => {
    return (
        <div className="container">
            <ul className="triangleContent">
                {props.showLinking ?
                    <>
                        <a href={`${window.location.origin}/topics/${props.topicsData[1]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon triangleUp2" : "triangleUp2"}>
                                <span className="triangleUpTopicTitle2">
                                    {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={`${window.location.origin}/topics/${props.topicsData[2]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon triangleUp3" : "triangleUp3"}>
                                <span className="triangleUpTopicTitle3">
                                    {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={`${window.location.origin}/topics/${props.topicsData[3]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon triangleUp1" : "triangleUp1"}>
                                <span className="triangleUpTopicTitle1">
                                    {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={`${window.location.origin}/topics/${props.topicsData[0]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon triangleDown" : "triangleDown"}>
                                <span className="triangleDownTopicTitle">
                                    {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                    </>
                    :
                    <>
                        <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon triangleUp2" : "triangleUp2"}
                            onClick={() => props.saveNextTopic(1)} >
                            <span className="triangleUpTopicTitle2">
                                {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon triangleUp3" : "triangleUp3"}
                            onClick={() => props.saveNextTopic(2)} >
                            <span className="triangleUpTopicTitle3">
                                {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon triangleUp1" : "triangleUp1"}
                            onClick={() => props.saveNextTopic(3)} >
                            <span className="triangleUpTopicTitle1">
                                {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon triangleDown" : "triangleDown"}
                            onClick={() => props.saveNextTopic(0)} >
                            <span className="triangleDownTopicTitle">
                                {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </>
                }
            </ul>
        </div>
    );
}

export default Triangle;