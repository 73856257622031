import {
  FeedbackAgeGroup,
  FeedbackAuthenticationType,
  FeedbackHostPlatformType,
  FeedbackPolicyValue,
  FeedbackScreenshotSourceType,
  FeedbackUiType,
  IFeedbackInitOptions,
  InAppFeedbackDataForHost
} from './FeedbackTypes'

function onDismissPrint(isFeedbackSent?: boolean) {
  console.log('isFeedbackSent : ', isFeedbackSent)
}

function onErrorPrint(errorMessage?: string) {
  console.log('error in sending feedback : ', errorMessage)
}

function onSuccessPrint(clientFeedbackId?: string) {
  console.log('clientFeedbackId : ', clientFeedbackId)
}

function getContextData(): Promise<File[]> {
  const file1 = new File(['sample file 1'], 'sample file 1.txt', {
    type: 'text/plain'
  })
  const file2 = new File(['sample file 2'], 'sample file 2.txt', {
    type: 'text/plain'
  })
  return new Promise((resolve: any) => {
    resolve([file1, file2])
  })
}

function attachDiagnosticsLogs(diagnosticsUploadId: string, diagnosticsEndpoint: string) {
  console.log(`attachDiagnosticsLogs : ${diagnosticsEndpoint} ${diagnosticsUploadId} `)
}

function extractFeedbackDataForHost(feedbackData?: InAppFeedbackDataForHost) {
  console.log('extractFeedbackDataForHost : ', feedbackData)
}

export const defaultFeedbackOptions: IFeedbackInitOptions = {
  ageGroup: FeedbackAgeGroup.Adult,
  authenticationType: FeedbackAuthenticationType.AAD,
  callbackFunctions: {
    onDismiss: onDismissPrint,
    onError: onErrorPrint,
    onSuccess: onSuccessPrint
  },
  clientName: 'OcvFeedbackDemoHostingApp',
  feedbackConfig: {
    //appData: '{"AppData1":"AppData1 Value", "AppData2":"AppData2 Value"}',
    complianceChecks: {
      connectedExperiences: FeedbackPolicyValue.Enabled,
      policyAllowContact: FeedbackPolicyValue.Enabled,
      policyAllowContent: FeedbackPolicyValue.Disabled,
      policyAllowFeedback: FeedbackPolicyValue.Enabled,
      policyAllowScreenshot: FeedbackPolicyValue.Enabled,
      policyAllowSurvey: FeedbackPolicyValue.Enabled,
      policyEmailCollectionDefault: FeedbackPolicyValue.Enabled,
      policyScreenshotDefault : FeedbackPolicyValue.Enabled,
      policyContentSamplesDefault :  FeedbackPolicyValue.Disabled
    },
    diagnosticsConfig: {
      //attachDiagnostics: attachDiagnosticsLogs,
      diagnosticsEndPoint: 'ODS',
      diagnosticsExplanationUrl: 'https://go.microsoft.com/fwlink/?linkid=2003203',
      isDiagnosticsIncluded: true,
      waitForDiagnosticsUpload: false,
      //getContextData: getContextData,
      isShareContextDataEnabled: true,
      isRetrieveFormDataEnabled: true,
      //extractFeedbackDataForHost: extractFeedbackDataForHost
    },
    //featureAreas: featuresKeysList,
    feedbackUiType: FeedbackUiType.Modal,
    hostPlatform: FeedbackHostPlatformType.Web,
    isDisplayed: false,
    isEmailCollectionEnabled: true,
    isFeedbackForumEnabled: true,
    isFileUploadEnabled: true,
    isMyFeedbackEnabled: true,
    isScreenshotEnabled: true,
    isScreenRecordingEnabled: true,
    isThankYouPageDisabled: false,
    screenshotInfo: {
      providedScreenshotType: FeedbackScreenshotSourceType.AutoCapture
    },
  },
  telemetry: {
    // Telemetry is optional and the values are for representation only
    // The host app needs to set their own telemetry data
    // audience: 'Microsoft',
    // audienceGroup: 'TestAudienceGroup',
    // channel: 'CC',
    // clientCountryCode: 'GB',
    // deviceId: 'deviceId',
    // deviceType: 'deviceType',
    // errorClassification: 'errorClassification',
    // errorCode: 'errorCode',
    // errorName: 'errorName',
    // flights: 'flights',
    // flightSource: 'flightSource',
    // fundamentalArea: 'fundamentalArea',
    // installationType: 'installationType',
    // isUserSubscriber: true,
    // officeArchitecture: 'x64',
    // officeEditingLang: 1,
    // officeUILang: 2,
    // osBitness: 64,
    // osBuild: '10.1.23.5',
    // osUserLang: 3,
    // platform: 'string',
    //processSessionId: '00000000-0000-0000-0000-000000000000',
    // ringId: 4,
    // sku: 'server',
    // sourceContext: 'Soruce',
    // systemManufacturer: 'Micro',
    // systemProductName: 'Test Productname'
  },
  themeOptions: { baseTheme: 'WindowsLight' },
}

