import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import fileIcons from '../../static/TemplateIcons';
import SampleReRatingModal from './SampleReRatingModal';
import { useAxios } from '../../context/AxiosContext';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import HashTagsModalForTemplate from './HashtagsModalForTemplate';
import AdminSampleEdit from './AdminSampleEdit';
import SaveSpinner from '../../helpers/CrowdSourcingSpinner';

function SampleTemplateModal({
	id,
	handleTemplatePreview,
	setEnableFileDropModal,
	closeModal,
	templateData,
	enableFileDropModal,
	complexityId,
	complexityTag,
}) {
	const history = useHistory();
	const { axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
	const [data, setData] = useState([]);
	const { track } = useAppInsights();
	const { getNotifications, isAdmin } = useLeftMenuCollapse();
	const [showRatingStar, setShowRatingStar] = useState(-1);
	const [rating, setRating] = useState(0);
	const [ratingSubmitted, setRatingSubmitted] = useState(false);
	const [showRatingConfirmationPopUp, setShowRatingConfirmationPopUp] =
		useState(false);
	const [rateAgain, setRateAgain] = useState(false);
	const [enableHashtagsForTemplate, setEnableHashtagsForTemplate] =
		useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const [selectedSample, setSelectedSample] = useState(null);
	const [selectedSampleInfo, setSelectedSampleInfo] = useState({});
	const [enableAdminSampledEdit, setEnableAdminSampledEdit] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);

	const getTemplates = async () => {
		await axiosGetService(
			`api/sdmtemplate/getSampleTemplate/${id}/${complexityId}`
		).then((response) => {
			setData(response.data.length === 0 ? null : response.data);
			setShowRatingStar(-1);
			setRateAgain(false);
			setRating(0);
			setExistingHashtags([]);
		});
	};

	const postSampleDownloadNotification = async (item) => {
		const postBody = {
			actionTypeId: 3,
			itemId: item.id,
			complexity: 0,
			itemTypeName: 'SampleTemplate',
			eventId: 1,
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then(() => {
				getNotifications();
			})
			.catch((error) => console.log(error));
	};

	const handleSampleRating = async (rating, templateId) => {
		if (rating > 0) {
			const postBody = {
				sampleId: templateId,
				rating,
			};
			setRatingSubmitted(true);
			await axiosPostService(`api/sdmtemplate/Rating`, postBody)
				.then((response) => {
					if (!isNaN(id)) {
						getTemplates();
					} else {
						setData(templateData);
					}
				})
				.catch((error) => console.log(error));
		}
	};

	const deleteSample = async () => {
		setShowSpinner(true);
		await axiosDeleteService(
			`/api/sdmtemplate/deletesampletemplate?sampleTemplateId=${selectedSampleInfo.id}`
		).then((response) => {
			setShowSpinner(false);
			if (response.data.status) {
				toast.info(
					`You have successfully deleted ${selectedSampleInfo.title}`,
					{
						position: 'top-right',
					}
				);
				setSelectedSampleInfo({});
				getTemplates();
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
		});
	};

	const handleOutsideClick = (e) => {
		if (
			e.target.className === 'reRating btn btn-primary' ||
			e.target.className === 'star-container'
		) {
			return;
		} else if (
			!e.target.className ||
			(typeof e.target.className !== 'object' &&
				e.target.className !== 'sampleTemplateRating')
		) {
			setShowRatingStar(-1);
			setRateAgain(false);
		}
	};

	useEffect(() => {
		if (!enableFileDropModal && !enableHashtagsForTemplate) {
			getTemplates();
		}
	}, [enableFileDropModal, enableHashtagsForTemplate]);

	useEffect(() => {
		if (!isNaN(id)) {
			getTemplates();
		} else {
			setData(templateData);
		}
	}, []);

	useEffect(() => {
		setRatingSubmitted(false);
	}, [data]);

	useEffect(() => {
		window.addEventListener('click', handleOutsideClick);
		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<>
			{showRatingConfirmationPopUp ? (
				<SampleReRatingModal
					setShowRatingConfirmationPopUp={setShowRatingConfirmationPopUp}
					setShowRatingStar={setShowRatingStar}
					showRatingConfirmationPopUp={showRatingConfirmationPopUp}
					showRatingStar={showRatingStar}
					setRateAgain={setRateAgain}
				/>
			) : (
				<></>
			)}
			{enableHashtagsForTemplate ? (
				<HashTagsModalForTemplate
					enableHashtagsForTemplate={enableHashtagsForTemplate}
					closeModal={() => setEnableHashtagsForTemplate(false)}
					template={selectedSample}
					complexityTag={complexityTag}
					existingHashtags={existingHashtags}
					setExistingHashtags={setExistingHashtags}
					type="sampleTemplates"
				/>
			) : (
				<></>
			)}
			{showSpinner ? (
				<SaveSpinner />
			) : (
				<div
					className={
						enableFileDropModal ||
						showRatingConfirmationPopUp ||
						enableHashtagsForTemplate ||
						enableAdminSampledEdit
							? 'hideSampleModal'
							: ''
					}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							<span>Samples</span>
							<div>
								<button
									title="Submit Sample"
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setEnableFileDropModal(true);
										}
									}}
									onClick={() => setEnableFileDropModal(true)}
									className="submitSampleButton"
								>
									Submit Sample
								</button>
								<Icon
									iconName="Cancel"
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											closeModal();
										}
									}}
									onClick={closeModal}
									role="button"
									aria-label="Click to Close Samples Modal"
								/>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Table striped>
							<thead>
								<tr>
									<th>Name</th>
									<th>Country</th>
									<th>Language</th>
									<th>Ratings</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data === null ? (
									<tr>
										<td colspan="6" style={{ textAlign: 'center' }}>
											No Samples available
										</td>
									</tr>
								) : (
									data.map((template) => {
										return (
											<tr>
												<td>
													<div>
														<img
															src={
																template.inProgress
																	? fileIcons['inProgress']
																	: template.fileType !== null
																	? fileIcons[template.fileType.toLowerCase()]
																	: fileIcons['defaultTemplate']
															}
															className="imageOfSearchResult"
															alt="File Type"
														/>
														{!template.inProgress ? (
															<a
																href={template.fileLocationNew}
																target="_blank"
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		postSampleDownloadNotification(template);
																		track(1, 122, template.id, template.title, {
																			phaseName: template.phaseName,
																			complexityId: template.complexityId,
																		});
																	}
																}}
																onClick={() => {
																	postSampleDownloadNotification(template);
																	track(1, 122, template.id, template.title, {
																		phaseName: template.phaseName,
																		complexityId: template.complexityId,
																	});
																}}
															>
																{template.title}
															</a>
														) : (
															<span>{template.title}</span>
														)}
													</div>
													<div className="existingHashTags">
														{template.hashTagsList
															.slice(0, 3)
															.map(({ name }) => (
																<span
																	key={name}
																	onClick={() => {
																		history.push(
																			`/search?searchParam=${encodeURIComponent(
																				name
																			)}`
																		);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			history.push(
																				`/search?searchParam=${encodeURIComponent(
																					name
																				)}`
																			);
																		}
																	}}
																	tabIndex={0}
																	aria-label={`click to search for ${name} as hashtag`}
																	role="button"
																>
																	{name}
																</span>
															))}
														{template.hashTagsList.length ? (
															<div className="addHashtagsIconContainer">
																<span
																	className="addHashtagsIcon"
																	onClick={() => {
																		setSelectedSample(template);
																		setExistingHashtags(
																			template.hashTagsList.map(({ name }) => {
																				return name;
																			})
																		);
																		setEnableHashtagsForTemplate(true);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setSelectedSample(template);
																			setExistingHashtags(
																				template.hashTagsList.map(
																					({ name }) => {
																						return name;
																					}
																				)
																			);
																			setEnableHashtagsForTemplate(true);
																		}
																	}}
																	tabIndex={0}
																	aria-label={`Click to Add Hashtags for Sample ${template.title} template`}
																	role="button"
																></span>
																<div class="tabsTitleToolTip">
																	<span class="tabsToolTipArrow"></span>
																	<span>Add Hashtags</span>
																</div>
															</div>
														) : (
															<p className="noHashtagsDescription">
																No hashtags yet!{' '}
																<Link
																	to="/"
																	onClick={(e) => {
																		e.preventDefault();
																		setSelectedSample(template);
																		setExistingHashtags(
																			template.hashTagsList.map(({ name }) => {
																				return name;
																			})
																		);
																		setEnableHashtagsForTemplate(true);
																	}}
																	aria-label={`click here to add hashtags for ${template.title}`}
																	role="button"
																>
																	Would you like to add?
																</Link>
															</p>
														)}
													</div>
												</td>
												<td>{template.country}</td>
												<td>{template.language}</td>
												<td
													className="sampleTemplateRating ratingStyle"
													onClick={() => {
														if (!ratingSubmitted) {
															if (template.isRated && !rateAgain) {
																setShowRatingConfirmationPopUp(true);
																setShowRatingStar(template.id);
															} else {
																if (
																	rateAgain &&
																	template.isRated &&
																	template.id !== showRatingStar
																) {
																	setShowRatingConfirmationPopUp(true);
																	setShowRatingStar(template.id);
																} else {
																	setShowRatingStar(template.id);
																}
															}
														}
													}}
												>
													{ratingSubmitted && showRatingStar === template.id ? (
														'Updating your rating...'
													) : showRatingStar === template.id ? (
														<StarRatings
															rating={rating}
															starDimension="19px"
															starRatedColor={'#ffd700'}
															starHoverColor={'#ffd700'}
															starEmptyColor={'#e9e9f0'}
															starSpacing="5px"
															svgIconViewBox="0 0 31.951 32"
															svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
															changeRating={(value) =>
																handleSampleRating(value, template.id)
															}
														/>
													) : (
														<>
															<StarRatings
																rating={template.rating}
																starDimension="14px"
																starRatedColor={'#ffd700'}
																starEmptyColor={'#fff'}
																starSpacing="3px"
																svgIconViewBox="0 0 31.951 32"
																svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
															/>
															<span>{`(${template.ratingCount})`}</span>
														</>
													)}
												</td>

												<td className="actionStyle">
													{selectedSampleInfo.id === template.id && (
														<div className="deleteSamplesPopUp">
															<p>
																Are you sure that you want to delete this
																sample?
															</p>
															<div>
																<button
																	onClick={() => {
																		setSelectedSampleInfo({});
																	}}
																>
																	Cancel
																</button>
																<button
																	onClick={() => {
																		deleteSample();
																	}}
																>
																	Delete
																</button>
															</div>
														</div>
													)}
													{isAdmin && (
														<Icon
															title="Edit Sample template"
															iconName="EditSolid12"
															onClick={() => {
																setSelectedSample(template);
																setEnableAdminSampledEdit(true);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedSample(template);
																	setEnableAdminSampledEdit(true);
																}
															}}
															aria-label={`click here to Edit  ${template.title} Sample template`}
															role="button"
														/>
													)}
													<span
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																handleTemplatePreview(template);
															}
														}}
														onClick={() => {
															handleTemplatePreview(template);
														}}
														disabled={
															['zip', 'aspx', 'html', null].includes(
																template.fileType
															) ||
															template.inProgress ||
															template.previewUrl === null
														}
														title="View Document"
														aria-label={`click here to View  ${template.title} Sample template`}
														className="previewIconBlack"
													></span>
													{isAdmin && (
														<span
															title="Delete Sample template"
															onClick={() => {
																setSelectedSampleInfo(template);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedSampleInfo(template);
																}
															}}
															aria-label={`click here to Delete  ${template.title} Sample template`}
															role="button"
															className="ChildTemplateDeleteIcon"
														></span>
													)}
												</td>
											</tr>
										);
									})
								)}
							</tbody>
						</Table>
					</Modal.Body>
					{enableAdminSampledEdit && (
						<AdminSampleEdit
							enableAdminSampledEdit={enableAdminSampledEdit}
							closeModal={() => {
								setEnableAdminSampledEdit(false);
								setSelectedSample(null);
							}}
							data={selectedSample}
							getTemplates={getTemplates}
							complexityId={complexityId}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default SampleTemplateModal;
