const UatConfig = {
    clientId: 'b4f64e73-998b-494b-8b47-0a77a8122f31',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://UAT2sdmplus.azurewebsites.net/',
    apiScopes: [
    'https://microsoft.onmicrosoft.com/eaaa4032-05fc-468b-9fc3-f5f3828a5578/.default',
    ],
    copilotApiScope: ['api://dd95f4e6-1439-46f7-a5af-29d9d54801cd'+'/.default'],
    copilotApiURL: 'https://api.stage.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://uatapisdmplus.azurewebsites.net/',
    instrumentationKey: '1a8a8824-7772-4eaa-b524-1ca0d585aa53',
    solutionMethodIconsUrl: 'https://sdmplusstoragedev.z19.web.core.windows.net',
    sdm1URL: 'https://uatsdmplus.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://uat2sdmplus.azurewebsites.net',
    appInsightsAPIKey: 'o2q725cp704i5yp7ml2bktn868kfhowdzo7ctxpb',
    appInsightsAppId: 'f719bccc-552a-4866-804d-1f60b6f4c282',
    yammerGroupId: '144161169408',
    yammerInstance: 'UAT',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - UAT',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'false',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusTutorials_Test/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey'
};

export default UatConfig;
