import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { Icon } from '@fluentui/react';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const TopicPublishCancelModal = ({
	topicId,
	showPublishCancelModal,
	setShowPublishCancelModal,
	formatPublishDate,
	publishScheduledDateTime,
	name,
	setPublishScheduledDateTime,
}) => {
	const { track } = useAppInsights();

	const { axiosPostService, setApiDown } = useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const [showSpinner, setShowSpinner] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const cancelSchedulePublish = async () => {
		setShowSpinner(true);
		const postData = {
			topicId: topicId,
		};
		await axiosPostService(
			`api/sdmtopics/publishscheduletopiccancel`,
			postData
		).then((response) => {
			if (response.data.status) {
				setSuccessMessage('You have successfully cancelled the schedule');
				setPublishScheduledDateTime('');
			} else {
				setApiDown(true);
				setErrorMessage(response.data.errorMessage);
			}
			setShowSpinner(false);
		});
	};

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="phasePublishModal"
					show={showPublishCancelModal}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					onHide={() => {
						setShowPublishCancelModal(false);
					}}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Cancel the scheduled publication
						</Modal.Title>
						<Icon
							onClick={() => {
								setShowPublishCancelModal(false);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowPublishCancelModal(false);
								}
							}}
							tabIndex={0}
							iconName="ChromeClose"
							role="button"
							aria-label="Cancel the scheduled publication"
							title="Cancel button"
						></Icon>
					</Modal.Header>
					<Modal.Body>
						{successMessage || errorMessage ? (
							<>
								{successMessage ? (
									<Alert variant="success">{successMessage}</Alert>
								) : (
									<Alert variant="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: HtmlSanitizer(errorMessage),
											}}
										/>
									</Alert>
								)}
							</>
						) : (
							<>
								<div className="phasePublishModalDiv">
									<p>
										Topic "{name}" is scheduled to be published at{' '}
										{formatPublishDate(publishScheduledDateTime)} (Local).
									</p>
								</div>
								<div className="cancelSchedulePublishDiv">
									<p>Are you sure you want to cancel this ?</p>
									<div>
										<button
											onClick={() => {
												cancelSchedulePublish();
											}}
										>
											Yes, Cancel
										</button>
										<button
											onClick={() => {
												setShowPublishCancelModal(false);
											}}
										>
											No, Keep the schedule
										</button>
									</div>
								</div>
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default TopicPublishCancelModal;
