import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DeletedEntity = ({ errorMsg }) => {
	return (
		<Modal
			className="activityDiscardPopUp"
			show={true}
			onHide={() => {
				// do not do anything
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{errorMsg}</p>
				<div className="discardPopUpActivityButtons">
					<Link aria-label="Discard PopUp Yes" to="/">
						<Button
							title="OK"
							tabIndex={-1}
							className="activityDiscardPopUpAccept"
							variant="info"
						>
							OK
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DeletedEntity;
