import React, { useState, useEffect } from 'react';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import dompurify from 'dompurify';

import './KnownIssue.css';
const KnownIssue = () => {
	const htmlSanitizer = dompurify.sanitize;
	const { axiosGetService } = useAxios();
	const [knownIssues, setKnownIssues] = useState(null);
	const { setSkipNavigationId } = useLeftMenuCollapse();
	const getKnownIssue = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=known-issue`).then((response) => {

			setKnownIssues(response.data[0].value);
		});
	};
	useEffect(() => {
		getKnownIssue();
		setSkipNavigationId('issue');
	}, []);

	return (
		<div className="knownIssue" id="issue">
			<div
				dangerouslySetInnerHTML={{
					__html: htmlSanitizer(knownIssues),
				}}
			/>
		</div>
	);
};

export default KnownIssue;
