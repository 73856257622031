import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';

import './TemplateCrowdSourcing.css';
import Breadcrumbs from '../Breadcrumbs';
import Pagination from '../Pagination';
import TemplateApproveCard from './TemplateApproveCard';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function TemplateApproveAll() {
	const pageLimit = 10;
	const { track } = useAppInsights();
	const [currentPage, setCurrentPage] = useState(1);
	const [templateCount, setTemplateCount] = useState(0);
	const [showPagination, setShowPagination] = useState(true);
	const [templateContributions, setTemplateContributions] = useState([]);
	const [accepedtRejectedTemplate, setAccepedtRejectedTemplate] = useState([]);
	const [complexities, setComplexities] = useState({});
	const [successMsg, setSuccessMsg] = useState(null);
	const [noAccess, setNoAccess] = useState(null);
	const [messagesType, setMessagesType] = useState({});
	const { axiosGetService } = useAxios();

	const getContributions = async () => {
		setTemplateContributions([]);
		setTemplateCount(0);
		await axiosGetService(
			`api/sdmtemplate/getapprover/${pageLimit}/${currentPage}`
		).then((response) => {
			if (response.data && response.data.templatesCount !== 0) {
				setTemplateContributions(response.data.sdmTemplate2);
				setTemplateCount(response.data.templatesCount);
			} else {
				setTemplateContributions(null);
			}
			getComplexities();
		});
	};

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity')
			.then((res) => {
				const complexityObject = {};
				res.data.map((item) => {
					complexityObject[item.id] = item.name;
				});
				return complexityObject;
			})
			.then((complexityObject) => setComplexities(complexityObject));
	};

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=approvePrivilegeMessage&title=0`
		).then((response) => {
			message['approvePrivilegeMessage'] = response.data;
		});

		setMessagesType(message);
	};

	useEffect(() => {
		getContributions();
	}, [currentPage, accepedtRejectedTemplate]);

	useEffect(() => {
		if (successMsg) {
			setTimeout(() => {
				setSuccessMsg(null);
			}, 60000);
		}
	}, [successMsg]);

	useEffect(() => {
		getMessage();
		track(2, 157, 'All', 'Approve All Templates');
	}, []);

	return (
		<div className="templateCrowdSourcingMainContainer">
			<div className="templateCrowdSourcingBreadcrumb">
				<Breadcrumbs />
			</div>
			<div className="templateCrowdSourcingContainer">
				<div className="templateCrSourcingListWrapper">
					<div className="templateFixedHeaderPart">
						<div className="templateCrSourcingTitleWrapper">
							<h4>
								Suggested Templates <span>(Pending for approval)</span>
							</h4>
							{successMsg ? (
								<div className="approverSuccessMsg">
									<span className="successMsgIcon"></span>
									<span>{successMsg}</span>
								</div>
							) : null}
						</div>

						{templateContributions === null ||
						(templateContributions.length > 0 &&
							templateContributions.length ===
								accepedtRejectedTemplate.length) ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								There are no templates for you to approve
							</MessageBar>
						) : (
							<div
								className="newTableParentRow header"
								id="templateCrowdSourcing"
							>
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment ">
												<div className="newColumn newTitle newActTd ">
													<span className="templateNewTitle">
														Template Name
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn templateComplexity newActTd ">
											Complexity
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Upload Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Review Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn details newActTd ">Details</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn approveAndReject newActTd ">
											Publish/Reject
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="listOfTemplateCrSourcing">
						{templateContributions &&
							templateContributions.map((contribution) => (
								<>
									{!accepedtRejectedTemplate.includes(contribution.id) ? (
										<TemplateApproveCard
											data={contribution}
											complexities={complexities}
											key={contribution.title}
											setAccepedtRejectedTemplate={setAccepedtRejectedTemplate}
											singleView={false}
											accepedtRejectedTemplate={accepedtRejectedTemplate}
											setSuccessMsg={setSuccessMsg}
											getContributions={getContributions}
										/>
									) : null}
								</>
							))}
					</div>
				</div>
			</div>
			<div
				className={`templatePagination ${
					templateCount <= pageLimit ? 'hidePagination' : ''
				}`}
			>
				{showPagination ? (
					<Pagination
						pageCount={Math.ceil(templateCount / pageLimit)}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default TemplateApproveAll;
