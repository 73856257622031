import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../../helpers/Spinner';

import { useAxios } from '../../context/AxiosContext';
import TopicsDynamicFilter from './TopicsDynamicFilter';
import { Icon } from '@fluentui/react/lib/Icon';
const pagetypeIds = {
	1: { Consulting: 2, Support: 3 },
	2: { Consulting: 9, Support: 10 },
	3: { Consulting: 11, Support: 11 },
	4: { Consulting: 18, Support: 19 },
};
function TopicLandingFilters({
	showFilters,
	setShowFilters,
	selectedTab,
	dynamicFilterTag,
	setDynamicFilterTag,
	setDynamicFilterTagMapping,
	leftMenuSelectedId,
}) {
	const searchInputRef = useRef(null);
	const [dynamicFilterData, setDynamicFilterData] = useState([]);
	const [dynamicSearchText, setDynamicSearchText] = useState({});
	const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
		{}
	);
	const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
		{}
	);
	const { axiosGetService } = useAxios();

	const getFilterData = async () => {
		const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}` +
						'-' +
						dynamicFilterTag[Number(id)].selectedTagId.toString()
				);
			}
		}
		await axiosGetService(
			`api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${
				pagetypeIds[leftMenuSelectedId][selectedTab]
			}&isFilter=true&artifactId=0&filterIds=${
				selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0
			}&complexityIds=0&searchValue=0`
		).then((response) => {
			const nameMapping = {};
			const filterTag = {};
			const filterTagMapping = {};
			const serachFilterData = {};
			let searchText = {};
			const data = response.data.map((item) => {
				return {
					artifactTypeId: item.artifactTypeId,
					artifactTypeName: item.artifactTypeName,
					artifactData: item.artifactData,
				};
			});
			for (const id in data) {
				nameMapping[data[id].artifactTypeId] =
					dynamicFilterSearchEnable[data[id].artifactTypeId];
				filterTag[data[id].artifactTypeId] = {
					artifactTypeId: data[id].artifactTypeId,
					artifactTypeName: data[id].artifactTypeName,
					selectedTagId: [],
				};
				const tag = data[id].artifactData;
				let artifactTag = {};
				for (const tagId in tag) {
					artifactTag = {
						...artifactTag,
						[tag[tagId].id]: tag[tagId].name,
					};
				}
				filterTagMapping[data[id].artifactTypeId] = artifactTag;
				searchText = {
					...searchText,
					[data[id].artifactTypeId]:
						dynamicSearchText[data[id].artifactTypeId] ?? '',
				};
				serachFilterData[data[id].artifactTypeId] = dynamicSearchText[
					data[id].artifactTypeId
				]
					? tag.filter((item) =>
							item.name
								.toLowerCase()
								.includes(
									dynamicSearchText[data[id].artifactTypeId].toLowerCase()
								)
					  )
					: [];
			}
			setDynamicFilterSearchResult({ ...serachFilterData });
			setDynamicSearchText({ ...searchText });
			setDynamicFilterTagMapping({ ...filterTagMapping });
			setDynamicFilterSearchEnable({ ...nameMapping });
			setDynamicFilterData([...data]);
			const newDynamicFilter = { ...dynamicFilterTag };
			for (const artifactTypeId in newDynamicFilter) {
				const { artifactData } =
					data.find(({ artifactTypeId: id }) => id == artifactTypeId) ?? [];
				newDynamicFilter[artifactTypeId].selectedTagId = newDynamicFilter[
					artifactTypeId
				].selectedTagId.filter((tagId) =>
					artifactData.map(({ id: itemId }) => itemId).includes(tagId)
				);
			}
			if (
				JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilter)
			) {
				setDynamicFilterTag(newDynamicFilter);
			}
		});
	};

	const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
		if (searchText.trim().length > 0) {
			const filteredRoles = artifactData.filter((item) =>
				item.name.toLowerCase().includes(searchText.toLowerCase())
			);
			setDynamicFilterSearchResult((prevState) => ({
				...prevState,
				[artifactTypeId]: filteredRoles,
			}));
		}
		setDynamicSearchText((prevState) => ({
			...prevState,
			[artifactTypeId]: searchText.trim().length > 0 ? searchText : '',
		}));
	};

	useEffect(() => {
		if (selectedTab && leftMenuSelectedId) {
			getFilterData();
		}
	}, [selectedTab, leftMenuSelectedId, dynamicFilterTag]);

	useEffect(() => {
		const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
		if (dynamicRefValue.includes(true) && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [dynamicFilterSearchEnable]);

	return (
		<div
			id="filtersRightPanel"
			className={!showFilters ? 'topicLandingFilters' : ''}
		>
			<div className="filterPanelHeader">
				<h5>Filters</h5>
				<Icon
					iconName="cancel"
					onClick={() => {
						setShowFilters(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowFilters(false);
						}
					}}
					title="Close"
					tabIndex={0}
					aria-label="Click here to close filters panel"
					role="button"
				/>
			</div>
			<div className="filtersPanelBody">
				<>
					{dynamicFilterData.map((item) => (
						<div className="filtersCategoryPanel" key={item.artifactTypeId}>
							{item.artifactData.length > 5 ? (
								<>
									{dynamicFilterSearchEnable[item.artifactTypeId] ? (
										<div className="rolesSearch">
											<input
												type="text"
												placeholder={`Search for  ${item.artifactTypeName}`}
												value={dynamicSearchText[item.artifactTypeId]}
												onChange={(e) => {
													e.persist();
													searchForArtifact(
														e.target.value,
														item.artifactTypeId,
														item.artifactData
													);
												}}
												role="input"
												ref={searchInputRef}
												aria-expanded={
													dynamicFilterSearchEnable[item.artifactTypeId]
														? 'true'
														: 'false'
												}
											/>
											<Icon
												className="searchIcon"
												iconName="cancel"
												onClick={() => {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}}
												onKeyPress={(e) => {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}}
												tabIndex={0}
												aria-label="cancel search"
												role="button"
											/>
										</div>
									) : (
										<div className="filtersearch">
											<h6>{item.artifactTypeName}</h6>
											<span
												className="searchIcon"
												onClick={() =>
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: true,
													})
												}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setDynamicFilterSearchEnable({
															...dynamicFilterSearchEnable,
															[item.artifactTypeId]: true,
														});
													}
												}}
												tabIndex={0}
												aria-expanded={
													dynamicFilterSearchEnable[item.artifactTypeId]
														? 'true'
														: 'false'
												}
												aria-label={`click to search ${item.artifactTypeName}`}
												role="button"
											>
												<Icon iconName="search" className="searchIcon" />
											</span>
										</div>
									)}
								</>
							) : (
								<h6>{item.artifactTypeName}</h6>
							)}
							<TopicsDynamicFilter
								data={
									dynamicSearchText[item.artifactTypeId]
										? dynamicFilterSearchResult[item.artifactTypeId]
										: item.artifactData
								}
								value={dynamicFilterTag}
								artifactTypeName={item.artifactTypeName}
								setValue={setDynamicFilterTag}
								artifactTypeId={item.artifactTypeId}
							/>
						</div>
					))}
				</>
			</div>
		</div>
	);
}

export default TopicLandingFilters;
