import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import $ from 'jquery';
import { MessageBar, MessageBarType } from '@fluentui/react';
import dompurify from 'dompurify';

import { useAxios } from '../../../context/AxiosContext';
import { useAuth } from '../../../context/UserAuthContext';

import Spinner from '../../../helpers/Spinner';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../context/TelemetryContext';
import ActivityTemplateHisotry from '../../ActivityTemplateHisotry';
import { handleTernaryCondition } from '../../../helpers/Validation';
import ActivityRaciMatrix from '../../ActivityRaciMatrix';
import EntryCriteriaMyContributions from '../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/EntryCriteriaMyContributions';
import ExitCriteriaMyContributions from "../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/ExitCriteriaMyContributions";
import TaskMyContributions from "../../../views/MyContributions/HistoricalPreview/MyContributions/Activity/TaskMyContributions";
import TemporaryCkAlert from '../../CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../CkEditor/DecryptionComponent';
import { useConfig } from '../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/ApproverContributions/DescriptionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const SupportAndActivitiesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/ApproverContributions/SupportingActivitiesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const TipsAndTechniquesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/ApproverContributions/TipsAndTechniquesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const DeliverablesCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionActivity/ApproverContributions/DeliverablesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

const ApprovePreview = ({
	contributorsData,
	reviewerInfo,
	setReviewerInfo,
	enableTemplatePreview,
	setEnableTemplatePreview,
}) => {
	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();

	const htmlSanitizer = dompurify.sanitize;
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const { user } = useAuth();
	const currentUser = handleTernaryCondition(
		contributorsData,
		contributorsData && contributorsData.approverEmail,
		null
	);
	const { ckEdKEY } = useConfig();
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const [licenseKey, setLicenseKey] = useState(null);
	const [activityContent, setActivityContent] = useState(null);
	const [descSuggestionsMapperData, setDescSuggestionsMapperData] = useState(
		{}
	);
	const [deliverablesSuggestionsMapperData, setDeliverablesSuggestionsMapperData] = useState(
		{}
	);
	const [supportSuggestionsMapperData, setSupportSuggestionsMapperData] =
		useState({});
	const [tipsSuggestionsMapperData, setTipsSuggestionsMapperData] = useState(
		{}
	);
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
	const [toolsUrl, setToolsUrl] = useState({});
	// template Preview Data

	const [templateData, setTemplateData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [procedureData, setProcedureData] = useState([]);
	const [complexities, setComplexities] = useState([]);
	const [complexityName, setComplexityName] = useState({
		name: '',
		type: 'option',
		id: '',
	});

	const [ckEditorUserMap, setCkEditorUserMap] = useState({});
	const [
		selectedToolsAndGuidanceSuggestions,
		setSelectedToolsAndGuidanceSuggestions,
	] = useState([]);
	const [
		contributiorToolsAndGuidanceSuggestion,
		setContributiorToolsAndGuidanceSuggestion,
	] = useState([]);
	const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
		useState([]);
	const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);

	const [contributiorToolsId, setContributiorToolsId] = useState([]);
	const [toolsReviewedIds, setToolsReviewedIds] = useState({});
	const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');

	const [entryCriteriaData, setEntryCriteriaData] = useState([]);
	const [exitCriteriaData, setExitCriteriaData] = useState([]);
	const [taskData, setTaskData] = useState([]);
	const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry-hist');
	const [currentExitTab, setCurrentExitTab] = useState('tab-exit-hist');


	const getEntryCriteriaData = async (data) => {
		let contributedEntryCriteria = [];
		if (data.resultCalloutJson && data.resultCalloutJson.entryCriteriaSuggestions) {
			contributedEntryCriteria = [...data.resultCalloutJson.entryCriteriaSuggestions];
		}
		const filteredEntryCriteria = data.entryCriteriaList.filter(
			({ id }) =>
				!contributedEntryCriteria.some((item) => JSON.parse(item).id === id)
		);
		setEntryCriteriaData(filteredEntryCriteria);

	};

	const getExitCriteriaData = async (data) => {
		let contributedExitCriteria = [];
		if (data.resultCalloutJson && data.resultCalloutJson.exitCriteriaSuggestions) {
			contributedExitCriteria = [...data.resultCalloutJson.exitCriteriaSuggestions];
		}
		const filteredExitCriteria = data.exitCriteriaList.filter(
			({ id }) =>
				!contributedExitCriteria.some((item) => JSON.parse(item).id === id)
		);
		setExitCriteriaData(filteredExitCriteria);
	};

	const getTaskData = async (data) => {
		let contributedTask = [];
		if (data.resultCalloutJson && data.resultCalloutJson.taskSuggestions) {
			contributedTask = [...data.resultCalloutJson.taskSuggestions];
		}
		const filteredTask = data.taskList.filter(
			({ id }) =>
				!contributedTask.some((item) => JSON.parse(item).id === id)
		);
		setTaskData(filteredTask);

	};

	const getTemplatesInfo = (data) => {
		let contributedTemplates = [];
		if (data.resultCalloutJson && data.resultCalloutJson.templateSuggestions) {
			contributedTemplates = [...data.resultCalloutJson.templateSuggestions];
		}
		const filteredTemplates = data.activityTemplateDetails.filter(
			({ id }) =>
				!contributedTemplates.some((item) => JSON.parse(item).id === id)
		);
		setTemplateData(filteredTemplates);
	};

	const getApprovedSuggestions = (suggestions) => {
		const approvedSuggestions = [];
		for (const id in suggestions) {
			const approvedData = {};
			approvedData['id'] = JSON.parse(suggestions[id]).id;
			approvedData['state'] = 'accepted';
			approvedSuggestions.push(JSON.stringify(approvedData));
		}
		return approvedSuggestions;
	};

	const getActivityContents = () => {
		if (contributorsData.activityContent) {
			setActivityContent(JSON.parse(contributorsData.activityContent));
		}
		const mappedComplexities = [
			{
				name: contributorsData.complexityName,
				id: contributorsData.complexityId,
				option: 'type',
			},
		];
		setComplexityName({
			name: contributorsData.complexityName,
			id: contributorsData.complexityId,
			type: 'option',
		});
		getTemplatesInfo(contributorsData);
		setTopicData(contributorsData.activityTopics);
		setProcedureData(contributorsData.activityProcedureType);
		setComplexities(mappedComplexities);
		const contributionsToolsId = [];
		if (
			contributorsData.resultCalloutJson &&
			contributorsData.resultCalloutJson.approverToolsSuggestions
		) {
			setSelectedToolsAndGuidanceSuggestions(
				contributorsData.resultCalloutJson.approverToolsSuggestions
			);
			const toolsSuggestions =
				contributorsData.resultCalloutJson.approverToolsSuggestions;
			const reviewedData = {};
			for (const id in toolsSuggestions) {
				contributionsToolsId.push(JSON.parse(toolsSuggestions[id]).toolsId);
				reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
					toolsSuggestions[id]
				).state;
			}
			setToolsReviewedIds(reviewedData);
		}
		if (
			contributorsData.resultReviewedJson &&
			contributorsData.resultReviewedJson.approvedToolsSuggestions
		) {
			const toolsSuggestions =
				contributorsData.resultReviewedJson.approvedToolsSuggestions;
			const reviewedData = {};
			for (const id in toolsSuggestions) {
				reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
					toolsSuggestions[id]
				).state;
			}
			setToolsReviewedIds(reviewedData);
		}
		setContributiorToolsId(contributionsToolsId);

		const contributorToolsData = handleTernaryCondition(
			JSON.parse(contributorsData.activityContent).ToolsAndGuidance,
			JSON.parse(contributorsData.activityContent).ToolsAndGuidance &&
			JSON.parse(contributorsData.activityContent).ToolsAndGuidance.split(
				','
			),
			[]
		);
		setContributiorToolsAndGuidanceSuggestion(contributorToolsData);

		const url = {};
		for (const id in contributorsData.activityToolsList) {
			if (
				contributionsToolsId.includes(contributorsData.activityToolsList[id].id)
			) {
				contributorsData.activityToolsList[id].isSelected = true;
			}
			url[contributorsData.activityToolsList[id].id] =
				contributorsData.activityToolsList[id].url;
		}
		setToolsUrl(url);
		setToolsAndGuidanceSuggestions(contributorsData.activityToolsList);
		getEntryCriteriaData(contributorsData);
		getExitCriteriaData(contributorsData);
		getTaskData(contributorsData);
	};
	const updateSuggestionInfo = (
		suggestionInfo,
		reviewerSuggestions,
		suggestionsMapperData,
		suggestionsMap
	) => {
		const suggestionId = handleTernaryCondition(
			suggestionInfo.split(':').length > 3,
			suggestionInfo.split(':')[2],
			suggestionInfo.split(':')[1]
		);
		const type = handleTernaryCondition(
			suggestionInfo.split(':').length > 3,
			suggestionInfo.split(':').slice(0, 2).join(':'),
			suggestionInfo.split(':')[0]
		);
		const userEmail = handleTernaryCondition(
			suggestionInfo.split(':').length > 3,
			suggestionInfo.split(':')[3],
			suggestionInfo.split(':')[2]
		);
		if (userEmail && !userDataMap.has(userEmail)) {
			graphUserData.push(userEmail);
			userDataMap.set(userEmail, 1);
		}
		if (!suggestionsMap.has(suggestionId)) {
			suggestionsMapperData[suggestionId] = userEmail;
			suggestionsMap.set(suggestionId, 1);
		}
		const suggestionsData = {
			id: suggestionId,
			type: type,
			hasComments: false,
			data: null,
			originalSuggestionId: null,
			authorId: userEmail,
			createdAt: Date.now(),
		};
		reviewerSuggestions.push(JSON.stringify(suggestionsData));
	};

	const getSuggestionsInfo = (
		emCollection,
		reviewerSuggestions,
		suggestionsMapperData,
		suggestionsMap
	) => {
		for (let i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-after')) {
				const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-end-after'
				);
				updateSuggestionInfo(
					suggestionInfo,
					reviewerSuggestions,
					suggestionsMapperData,
					suggestionsMap
				);
			} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
				const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-start-before'
				);
				updateSuggestionInfo(
					suggestionInfo,
					reviewerSuggestions,
					suggestionsMapperData,
					suggestionsMap
				);
			}
		}
	};

	const getContributor = (suggestions, htmlData, subActivityType) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
		const suggestionsMapperData = {};
		const domParser = new DOMParser();
		const docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		let emCollection = docElement.getElementsByTagName('suggestion');
		const suggestionsMap = new Map();
		const reviewerSuggestions = [];
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionId = $(emCollection[i]).attr('id').split(':')[0];
			const userEmail = $(emCollection[i]).attr('id').split(':')[1];
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsMapperData[suggestionId] = userEmail;
				suggestionsMap.set(suggestionId, 1);
			}
			const suggestionsData = {
				id: suggestionId,
				type: $(emCollection[i]).attr('suggestion-type'),
				hasComments: false,
				data: null,
				originalSuggestionId: null,
				authorId: userEmail,
				createdAt: Date.now(),
			};
			reviewerSuggestions.push(JSON.stringify(suggestionsData));
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionInfo = emCollection[i].attributes.name.value;
			const suggestionId = handleTernaryCondition(
				suggestionInfo.split(':').length > 3,
				suggestionInfo.split(':')[2],
				suggestionInfo.split(':')[1]
			);
			const type = handleTernaryCondition(
				suggestionInfo.split(':').length > 3,
				suggestionInfo.split(':').slice(0, 2).join(':'),
				suggestionInfo.split(':')[0]
			);
			const userEmail = handleTernaryCondition(
				suggestionInfo.split(':').length > 3,
				suggestionInfo.split(':')[3],
				suggestionInfo.split(':')[2]
			);
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsMapperData[suggestionId] = userEmail;
				suggestionsMap.set(suggestionId, 1);
			}
			const suggestionsData = {
				id: suggestionId,
				type: type,
				hasComments: false,
				data: null,
				originalSuggestionId: null,
				authorId: userEmail,
				createdAt: Date.now(),
			};
			reviewerSuggestions.push(JSON.stringify(suggestionsData));
		}

		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);

		if (subActivityType === 'desc') {
			contributorsData.resultCalloutJson.approverDescSuggestions =
				reviewerSuggestions;
		} else if (subActivityType === 'support') {
			contributorsData.resultCalloutJson.approverSupportSuggestions =
				reviewerSuggestions;
		} else if (subActivityType === 'deliverables') {
			contributorsData.resultCalloutJson.approverDeliverablesSuggestions =
				reviewerSuggestions;
		} else if (subActivityType === 'tools') {
			contributorsData.resultCalloutJson.approverToolsSuggestions =
				reviewerSuggestions;
		} else {
			contributorsData.resultCalloutJson.approverTipsSuggestions =
				reviewerSuggestions;
		}
		return suggestionsMapperData;
	};

	const getUsersInfo = async () => {
		if (contributorsData.resultCalloutJson) {
			setDescSuggestionsMapperData(
				getContributor(
					contributorsData.resultCalloutJson.approverDescSuggestions,
					activityContent.Description,
					'desc'
				)
			);
			setSupportSuggestionsMapperData(
				getContributor(
					contributorsData.resultCalloutJson.approverSupportSuggestions,
					activityContent.SupportingActivities,
					'support'
				)
			);
			setDeliverablesSuggestionsMapperData(
				getContributor(
					contributorsData.resultCalloutJson.approverDeliverablesSuggestions,
					activityContent.Deliverables,
					'deliverables'
				)
			);
			setTipsSuggestionsMapperData(
				getContributor(
					contributorsData.resultCalloutJson.approverTipsSuggestions,
					activityContent.TipsAndTechniques,
					'tips'
				)
			);
		}
		if (contributorsData.resultCalloutJson) {
			contributorsData.resultReviewedJson = {};
			contributorsData.resultReviewedJson['approvedDescSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverDescSuggestions
				);
			contributorsData.resultReviewedJson['approvedSupportSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverSupportSuggestions
				);
			contributorsData.resultReviewedJson['approvedDeliverablesSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverDeliverablesSuggestions
				);
			contributorsData.resultReviewedJson['approvedTipsSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverTipsSuggestions
				);
			contributorsData.resultReviewedJson['approvedToolsSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverToolsSuggestions
				);
			contributorsData.resultReviewedJson['approvedTemplateSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverTemplateSuggestions
				);
			contributorsData.resultReviewedJson['approvedEntryCriteriaSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverEntryCriteriaSuggestions
				);
			contributorsData.resultReviewedJson['approvedExitCriteriaSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverExitCriteriaSuggestions
				);
			contributorsData.resultReviewedJson['approvedTaskSuggestions'] =
				getApprovedSuggestions(
					contributorsData.resultCalloutJson.approverTaskSuggestions
				);
		}
		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		const userProfileMapping = {};
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
			userProfileMapping[userData.mail] = [
				userData.firstName + ' ' + userData.surname,
				userData.photo,
			];
		}
		if (
			contributorsData.approverEmail &&
			!(contributorsData.approverEmail in userProfileMapping)
		) {
			const userEmail = contributorsData.approverEmail;
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
			userProfileMapping[userData.mail] = [
				userData.firstName + ' ' + userData.surname,
				userData.photo,
			];
		}

		setCkEditorUserMap(userProfileMapping);
		setCkEditorUsers(contributorInfo);
	};
	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	const getToolsConatinerHeight = () => {
		const height = $('.ToolsContentContainer').height();
		setToolsSidebarHeight(height + 63);
	};
	const getTemplateSidebarHeight = () => {
		const height = $('.templateContentContainer').height();
		setTemplateSidebarHeight(height + 63);
	};

	useEffect(() => {
		getToolsConatinerHeight();
	});

	useEffect(() => {
		getTemplateSidebarHeight();
	});

	useEffect(() => {
		getLicense();
	}, []);	

	useEffect(() => {
		if (contributorsData && activityContent) {
			if (!contributorsData.resultCalloutJson) {
				contributorsData.resultCalloutJson = {
					approverDescSuggestions: null,
					approverSupportSuggestions: null,
					approverToolsSuggestions: null,
					approverTipsSuggestions: null,
					approverDeliverablesSuggestions: null,
				};
			}
			getUsersInfo();
		}
	}, [contributorsData, activityContent]);

	useEffect(() => {
		if (contributorsData) {
			getActivityContents();
		}
	}, []);

	if (!contributorsData) {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				There were no contribution from approver.
			</MessageBar>
		);
	}

	if (
		!activityContent ||
		ckEditorUsers.length < 1 ||
		!contributorsData ||
		!licenseKey
	) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}
	return (
		<div className="historicalPreview">
			<div className="activityLandinPage">
				<div className="activityMainContainer">
					<div className="activityDescriptionDetails">
						<div className="descOfActivity historyActivity">
							<h4
								title={contributorsData.activityName}
								className="activityLandingPageTitle"
							>
								{contributorsData.activityName}
							</h4>
							<ActivityRaciMatrix activityData={contributorsData} />

							<div className="innerContent" id="innerContent">
								{activityContent.Description && (
									<>
										<h6 className="sub-heading" id="desc"
											aria-label='Description'>
											Description
										</h6>
										<TemporaryCkAlert width={'77'}/>
										<DescriptionCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.Description}
											initalSuggestions={
												contributorsData.resultCalloutJson
													.approverDescSuggestions
											}
											reviewedSuggestions={handleTernaryCondition(
												contributorsData.resultReviewedJson,
												contributorsData.resultReviewedJson
													.approvedDescSuggestions,
												null
											)}
											licenseKey={licenseKey}
											previewType="approverContribution"
											suggestionsMapper={descSuggestionsMapperData}
											comment={[]}
										/>
									</>
								)}

								{activityContent.SupportingActivities && (
									<>
										<h6 className="sub-heading" id="supportAct">
											Supporting Activities
										</h6>
										<TemporaryCkAlert width={'77'}/>

										<SupportAndActivitiesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.SupportingActivities}
											initalSuggestions={
												contributorsData.resultCalloutJson
													.approverSupportSuggestions
											}
											reviewedSuggestions={handleTernaryCondition(
												contributorsData.resultReviewedJson,
												contributorsData.resultReviewedJson
													.approvedSupportSuggestions,
												null
											)}
											licenseKey={licenseKey}
											previewType="approverContribution"
											suggestionsMapper={supportSuggestionsMapperData}
											comment={[]}
										/>
									</>
								)}



								{entryCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.approverEntryCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Entry Criteria/Input</span>
										</h2>
										<EntryCriteriaMyContributions
											criteriaData={entryCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.approverEntryCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.approverEntryCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedEntryCriteriaSuggestions
													: []
											}
											criteria="entry"
											currentTab={currentEntryTab}
											setCurrentTab={setCurrentEntryTab}
										/>
									</div>
								) : null}


								{taskData.length > 0 || (
									contributorsData.resultCalloutJson.approverTaskSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Task</span>
										</h2>
										<TaskMyContributions
											taskData={taskData}
											toolsSidebarHeight={templateSidebarHeight}
											taskSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.approverTaskSuggestions
													? contributorsData.resultCalloutJson
														.approverTaskSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedTaskSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedTaskSuggestions
													: []
											}
										/>
									</div>
								) : null}



								{exitCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.approverExitCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Exit Criteria/Output</span>
										</h2>
										<ExitCriteriaMyContributions
											criteriaData={exitCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.approverExitCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.approverExitCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedExitCriteriaSuggestions
													: []
											}
											criteria="exit"
											currentTab={currentExitTab}
											setCurrentTab={setCurrentExitTab}
										/>
									</div>
								) : null}

								{procedureData.length > 0 && (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Procedures
											<div className="nonEditableSection">
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{procedureData.length > 0 && (
											<div id="activityProcedure">
												<ul>
													{procedureData.map((procedure) => (
														<li>
															{
																<a href={procedure.url} target="_blank">
																	{procedure.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								)}
								{topicData.length > 0 && (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Related Topics
											<div className="nonEditableSection">
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{topicData.length > 0 && (
											<div id="topicList">
												<ul>
													{topicData.map((topic) => (
														<li>
															<a href={topic.url} target="_blank">
																{topic.name}
															</a>
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								)}

								<div className="addingTemplatesEditAcivity">
									<h2 className="sub-heading" id="deliver">
										<span>Deliverables</span>
									</h2>
									<ActivityTemplateHisotry
										templateData={templateData}
										toolsSidebarHeight={templateSidebarHeight}
										templateSuggestions={handleTernaryCondition(
											contributorsData.resultCalloutJson &&
											contributorsData.resultCalloutJson
												.approverTemplateSuggestions,
											contributorsData.resultCalloutJson
												.approverTemplateSuggestions,
											[]
										)}
										ckEditorUserMap={ckEditorUserMap}
										reviewedTemplateSuggestions={handleTernaryCondition(
											contributorsData.resultReviewedJson,
											contributorsData.resultReviewedJson
												.approvedTemplateSuggestions,
											[]
										)}
										enableTemplatePreview={enableTemplatePreview}
										setEnableTemplatePreview={setEnableTemplatePreview}
									/>
									{/* {contributorsData.activityDeliverables && (
										<div
											id="activityDeliverables"
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(
													contributorsData.activityDeliverables
												),
											}}
										/>
									)} */}
									{activityContent.Deliverables && (
										<>
										<div className='deliverables-container-hp'>
										<TemporaryCkAlert width={'77'}/>

											<h2 className="sub-heading" id="desc"
												aria-label='Description'>
												Description
											</h2>
											<DeliverablesCkEditor
												users={ckEditorUsers}
												currentUser={currentUser}
												initialData={activityContent.Deliverables}
												initalSuggestions={
													contributorsData.resultCalloutJson
														.approverDeliverablesSuggestions
												}
												reviewedSuggestions={handleTernaryCondition(
													contributorsData.resultReviewedJson,
													contributorsData.resultReviewedJson
														.approvedDeliverablesSuggestions,
													null
												)}
												licenseKey={licenseKey}
												previewType="approverContribution"
												suggestionsMapper={deliverablesSuggestionsMapperData}
												comment={[]}
											/>
											</div>
										</>
									)}
								</div>

								<h2 className="sub-heading" id="tool">
									Tools
								</h2>
								<div className="customToolsEditor">
									<div className="toolsEditorMainContainer">
										<div className="ToolsContentContainer">
											<ul>
												{contributorsData.activityToolsList.map((tools) => (
													<>
														{(tools.isSelected &&
															!contributiorToolsId.includes(tools.id) &&
															contributiorToolsAndGuidanceSuggestion.includes(
																tools.id.toString()
															)) ||
															(!contributiorToolsId.includes(tools.id) &&
																contributiorToolsAndGuidanceSuggestion.includes(
																	tools.id.toString()
																) && (
																	<li>
																		<a href={tools.url} target="_blank">
																			{tools.name}
																		</a>
																	</li>
																))}
													</>
												))}
												{selectedToolsAndGuidanceSuggestions.map((item) => (
													<>
														{!adminToolsAndGuidance.includes(
															JSON.parse(item).toolsId
														) && (
																<li
																	className={handleTernaryCondition(
																		JSON.parse(item).suggestionType ===
																		'deletion',
																		'userDeletedToolsAndGuidance',
																		'userSelectedToolsAndGuidance'
																	)}
																>
																	<div className={'userAddedItem'}>
																		<a href={toolsUrl[JSON.parse(item).toolsId]}>
																			{JSON.parse(item).name}
																		</a>
																	</div>
																</li>
															)}
														{adminToolsAndGuidance.includes(
															JSON.parse(item).toolsId
														) && (
																<li
																	className={`adminToolsAndGuidance ${handleTernaryCondition(
																		JSON.parse(item).suggestionType ===
																		'deletion',
																		'userDeletedToolsAndGuidance',
																		'none'
																	)}`}
																>
																	<a
																		href={toolsUrl[JSON.parse(item).toolsId]}
																		target="_blank"
																	>
																		{JSON.parse(item).name}
																	</a>

																	{JSON.parse(item).suggestionType ===
																		'deletion' && (
																			<div className="sideBarCard">
																				<div className="sideBarCardHeader">
																					<img
																						className="userImg"
																						src={
																							ckEditorUserMap[
																							JSON.parse(item).authorId
																							][1]
																						}
																						alt=""
																					/>
																					<span>
																						{
																							ckEditorUserMap[
																							JSON.parse(item).authorId
																							][0]
																						}
																					</span>
																				</div>
																				<div className="itemStatus">
																					<span>Remove</span>&nbsp;"
																					{JSON.parse(item).name}"
																				</div>
																			</div>
																		)}
																</li>
															)}
													</>
												))}
											</ul>
										</div>
									</div>
									<div
										className="toolsSidebar"
										style={{ height: toolsSidebarHeight }}
									>
										{selectedToolsAndGuidanceSuggestions.map((item) => (
											<>
												<div
													className={`sideBarCard acceptedSideBarCard ${handleTernaryCondition(
														JSON.parse(item).suggestionType === 'insertion',
														'userAddedItem',
														'userDeletedToolsAndGuidance'
													)}`}
												>
													<div className="sideBarCardHeader">
														<img
															className="userImg"
															src={
																ckEditorUserMap[JSON.parse(item).authorId][1]
															}
															alt=""
														/>
														<span>
															{ckEditorUserMap[JSON.parse(item).authorId][0]}
														</span>
														<span className="suggestionStatus">Approved</span>
													</div>
													<div className="itemStatus">
														<span>
															{handleTernaryCondition(
																JSON.parse(item).suggestionType === 'insertion',
																'Add',
																'Remove'
															)}
														</span>
														<span>&nbsp;"{JSON.parse(item).name}"</span>
													</div>
												</div>
											</>
										))}
									</div>
								</div>

								{activityContent.TipsAndTechniques && (
									<>
										<h6 className="sub-heading" id="tipstech">
											Guidance, Tips and Techniques
										</h6>
										<TemporaryCkAlert width={'77'}/>

										<TipsAndTechniquesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.TipsAndTechniques}
											initalSuggestions={
												contributorsData.resultCalloutJson
													.approverTipsSuggestions
											}
											reviewedSuggestions={handleTernaryCondition(
												contributorsData.resultReviewedJson,
												contributorsData.resultReviewedJson
													.approvedTipsSuggestions,
												null
											)}
											licenseKey={licenseKey}
											previewType="approverContribution"
											suggestionsMapper={tipsSuggestionsMapperData}
											comment={[]}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApprovePreview;
