import React, { useState } from 'react';

import MyContributionsPreview from './TopicsMyContributionsPreview';
import ReviewComments from './ReviewComments';

const TopicsMyReviews = () => {
	const myReviewsTab = ['Your Contributions', 'Comments'];
	const [activeTab, setActiveTab] = useState('Your Contributions');
	const artifact = {
		Comments: <ReviewComments />,
		'Your Contributions': <MyContributionsPreview />,
	};
	return (
		<div className="myContributionPreviewContainer">
			<div className="myContributionsRightContent">
				<div className="myContributionsContentMenuContainer">
					<ul
						className="myContributionsContentMenuTabs"
						id="myContributionsContentMenuTabsId"
					>
						{myReviewsTab.map((tab) => (
							<li
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setActiveTab(tab);
									}
								}}
								onClick={() => setActiveTab(tab)}
								className={
									activeTab === tab
										? 'activeMyContributionCrowdSourcingTab'
										: null
								}
							>
								{tab}
							</li>
						))}
					</ul>
				</div>
			</div>
			{artifact[activeTab]}
		</div>
	);
};

export default TopicsMyReviews;
