const TestConfig = {
    clientId: '65e5ba55-9d46-48fc-86a1-2a3c4608088a',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://test2sdmplus.azurewebsites.net/',
    apiScopes: [
        'https://microsoft.onmicrosoft.com/0931acf5-49d0-43f1-ada4-42e50d520508/.default',
    ],
    copilotApiScope: ['api://dd95f4e6-1439-46f7-a5af-29d9d54801cd' + '/.default'],
    copilotApiURL: 'https://api.buddy.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://testapisdmplus.azurewebsites.net/',
    instrumentationKey: '393376d0-7227-4c7f-ae01-c9bf29fc85e8',
    solutionMethodIconsUrl: 'https://sdmplusstoragedev.z19.web.core.windows.net',
    sdm1URL: 'https://testsdmplus.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://test2sdmplus.azurewebsites.net',
    appInsightsAPIKey: 'zlrdp7iie2eb7r87ovggy4urrcbt8vdwnnri5lb5',
    appInsightsAppId: '00b1945e-27e0-48cb-95bb-4563f2f75ac7',
    yammerGroupId: '142500216832',
    yammerInstance: 'Test',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - Test',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'false',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusTutorials_Test/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey'
};

export default TestConfig;
