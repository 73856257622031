import axios from 'axios';
import urlContains from '../../../config/index';
import { PublicClientApplication } from '@azure/msal-browser';

const graph = require('@microsoft/microsoft-graph-client');
const { clientId, authority, graphRedirectUri, apiEndPoint, apiScopes, copilotApiScope } =
 urlContains(window.location.hostname);
const msalScopes = ["user.read"];
const msalConfig = {
 auth: {
  clientId,
  authority,
  redirectUri: graphRedirectUri,
 },
 cache: {
  storeAuthStateInCookie: true,
 },
};
let msal = () => {
 // msal instance
};
msal = new PublicClientApplication(msalConfig);

export const axiosGetService = async (path) => {
 const account = msal.getAllAccounts()[0];
 const tokenResponse = await msal.acquireTokenSilent({
  scopes: apiScopes,
  authority: msalConfig.auth.authority,
  account: account,
 });
 const axiosInstance = axios.create({
  baseURL: apiEndPoint,
  headers: {
   Authorization: `Bearer ${tokenResponse.accessToken}`,
   UserAlias: tokenResponse.account.userName,
   'Access-Control-Allow-Origin': '*',
   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
   'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
   Accept: 'application/json, text/plain, */*',
   'Content-Type': 'application/json',
  },
 });
 let response = {};
 await axiosInstance
  .get(path)
  .then((res) => {
   response = res;
  })
  .catch(async (err) => {
   response = await axiosInstance.get(path);
  });
 return response;
};

export const axiosPostService = async (path, body) => {
 const account = msal.getAllAccounts()[0];
 const tokenResponse = await msal.acquireTokenSilent({
  scopes: apiScopes,
  authority: msalConfig.auth.authority,
  account: account,
 });
 const axiosInstance = axios.create({
  baseURL: apiEndPoint,
  headers: {
   Authorization: `Bearer ${tokenResponse.accessToken}`,
   UserAlias: tokenResponse.account.userName,
   'Access-Control-Allow-Origin': '*',
   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
   'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
   Accept: 'application/json, text/plain, */*',
   'Content-Type': 'application/json',
  },
 });
 let response = {};
 await axiosInstance
  .post(path, body)
  .then((res) => {
   response = res;
  })
  .catch(async (err) => {
   response = await axiosInstance.post(path, body);
  });
 return response;
};

export const axiosGraphService = async (path) => {
 const account = msal.getAllAccounts()[0];
 const tokenResponse = await msal.acquireTokenSilent({
  scopes: msalScopes,
  authority: msalConfig.auth.authority,
  account: account,
 });
 const client = graph.Client.init({
  // Use the provided access token to authenticate
  // requests
  authProvider: (done) => {
   done(null, tokenResponse.accessToken);
  },
 });
 let response = {};
 await client
  .api(`${path}`)
  .get()
  .then((res) => {
   response = res;
  })
  .catch((err) => {
   console.log(err);
  });
 return response;
};

export const axiosTokenService = async () => {
 const account = msal.getAllAccounts()[0];
 const tokenResponse = await msal.acquireTokenSilent({
  scopes: msalScopes,
  authority: msalConfig.auth.authority,
  account: account,
 });
 return tokenResponse;
};

export const copilotTokenService = async () => {
    const account = msal.getAllAccounts()[0];
    const tokenResponse = await msal.acquireTokenSilent({
     scopes: copilotApiScope,
     authority: msalConfig.auth.authority,
     account: account,
    });
    return tokenResponse.accessToken;
   };
