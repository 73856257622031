import React, { useState, useEffect } from "react";
import { Icon } from "@fluentui/react";
import Spinner from "../../../helpers/CrowdSourcingSpinner";
import { useAxios } from "../../../context/AxiosContext";
import { useAppInsights } from "../../../context/TelemetryContext";
import { hashtagValidator } from "../../../helpers/Validation";
import HashtagSuggestions from "../HashtagSuggestions";
import Dropdown from "../../Dropdown";
import MultiSelectDropdown from "../ToolsDropdown";
import { toast } from "react-toastify";

const TutorialForm = ({ selectedTutorial, setCurrentTab }) => {
  const { track } = useAppInsights();
  const { axiosGetService, axiosPostService } = useAxios();
  const [showLoader, setShowLoader] = useState(false);
  const [tutorialName, setTutorialName] = useState("");
  const [tutorialCategory, setTutorialCategory] = useState("");
  const [tutorialMinutes, setTutorialMinutes] = useState("");
  const [tutorialSeconds, setTutorialSeconds] = useState("");
  const [tutorialUrl, setTutorialUrl] = useState("");
  const [hashTagInput, setHashTagInput] = useState("");
  const [addedHashTag, setAddedHashTag] = useState([]);
  const [hashtagError, setHashtagError] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [artifactsList, setArtifactsList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [categories, setcategories] = useState([]);
  const [updateApiCall, setUpdateApiCall] = useState(false);
  const [categoryName, setCategoryName] = useState({
    name: "",
    type: "option",
    id: "",
  });
  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const validateInputField = () => {
    if (tutorialName.trim().length === 0) {
      toast.error("Title is required", { position: "top-right" });
      return false;
    }
    if (tutorialMinutes > 60) {
      toast.error("Minutes value should be less than or equal to 60", { position: "top-right" });
      return false;
    }
    if (tutorialSeconds > 60) {
      toast.error("Seconds value should be less than or equal to 60", { position: "top-right" });
      return false;
    }
    if (tutorialUrl.trim().length === 0) {
      toast.error("Video Tutorial link is required", { position: "top-right" });
      return false;
    } else {
      const url = tutorialUrl.split("/");
      if (
        url[0] === "<iframe src=\"https:" &&
        !url[1] &&
        url[2].includes("sharepoint.com") &&
        (url[3] === "teams" || url[3] === "personal") &&
        url[8] === "iframe>"
      ) {
        return true;
      } else {
        toast.error("Invalid Video tutorial link", { position: "top-right" });
        return false;
      }
    }
  };

  const validateUrl = () => {
    const url = tutorialUrl.split("/");
    if (
      url[0] === "<iframe src=\"https:" &&
      !url[1] &&
      url[2].includes("sharepoint.com") &&
      (url[3] === "teams" || url[3] === "personal") &&
      url[8] === "iframe>"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const removeDuplicateHashtag = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index);
  };

  const updateHashTag = (type) => {
    setAddedHashTag([...userAddedHashtags, ...addedHashTag]);
    setHashtagSuggestionInput("");
    setUserAddedHashtags([]);
  };

  const getEmbededUrl = () => {
    return (
      tutorialUrl
    );
  };

  const submitTutorial = async () => {
    const validation = validateInputField() && validateUrl();
    if (validation) {
      const postData = {
        id: selectedTutorial.id,
        name: tutorialName,
        url: getEmbededUrl(),
        hashTags: addedHashTag.toString(),
        pageTypeId: 11,
        isVisible: isVisible,
        category: categoryName.id,
        durationMM: tutorialMinutes === "" ? 0 : tutorialMinutes,
        durationSS: tutorialSeconds === "" ? 0 : tutorialSeconds,
        linkedArtifactIds: artifactsList
          .reduce((filteredaritfactList, artifactType) => {
            if (
              artifactType.artifactData.some(({ isSelected }) => isSelected)
            ) {
              const selectedArtifacts = artifactType.artifactData.filter(
                ({ isSelected }) => isSelected
              );
              filteredaritfactList.push(
                `${artifactType.artifactTypeId}-${selectedArtifacts
                  .map(({ id }) => id)
                  .toString()}`
              );
            }
            return filteredaritfactList;
          }, [])
          .join("|"),
      };
      setShowLoader(true);
      await axiosPostService(
        `api/sdmtutorials/videoTutorials/update`,
        postData
      ).then((response) => {
        if (response.data.status) {
          track(1, 1101, tutorialName, selectedTutorial.id);
          toast.info("Video Tutorial updated", { position: "top-right" });
          //setCurrentTab(2);
        } else {
          toast.error(response.data.errorMsg, { position: "top-right" });
        }
        setShowLoader(false);
      });
    }
  };

  const getTutorialData = async () => {
    const response = await axiosGetService(
      `api/sdmtutorials/videoTutorials/getbyid?videoTutorialId=${selectedTutorial.id}`
    );
    const existingHashTag = response.data.hashTagsList
      .filter(({ name }) => name.length > 0)
      .map(({ name }) => {
        return name;
      });
    const mappedCategoryList = response.data.mappedCategoryList.map(
      (item) => ({
        name: item.name,
        id: item.id,
        option: "type",
      })
    );
    setCategoryName({
      name: response.data.categoryName,
      id: response.data.category,
      type: "option",
    });
    setcategories(mappedCategoryList);
    setAddedHashTag(existingHashTag);
    setTutorialName(response.data.name);
    setTutorialUrl(response.data.url);
    setIsVisible(response.data.isVisible);
    setTutorialCategory(response.data.category);
    setTutorialMinutes(response.data.durationMM);
    setTutorialSeconds(response.data.durationSS);

  };

  const getFilterArtifactsData = async () => {
    const response = await axiosGetService(
      `api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=11&isFilter=false&artifactId=${selectedTutorial.id
      }&filterIds=0&complexityIds=0&searchValue=0`
    );
    setArtifactsList(response.data);
  };

  const removeArtifact = (artifactTypeIndex, index) => {
    const updatedArtifactList = [...artifactsList];
    updatedArtifactList[artifactTypeIndex].artifactData[
      index
    ].isSelected = false;
    setArtifactsList(updatedArtifactList);
  };

  const updateSelectedArtifacts = (data, artifactTypeIndex) => {
    const updatedArtifactList = [...artifactsList];
    updatedArtifactList[artifactTypeIndex].artifactData = data;
    setArtifactsList(updatedArtifactList);
  };

  const removeHashTag = (item) => {
    const filteredHashTag = addedHashTag.filter((hashTag) => item !== hashTag);
    setAddedHashTag(filteredHashTag);
  };

  useEffect(() => {
    setShowLoader(true);
    Promise.all([getTutorialData(), getFilterArtifactsData()]).then(() =>
      setShowLoader(false)
    );

    track(2, 1109, selectedTutorial.id, selectedTutorial.name);
  }, []);

  useEffect(() => {
    if (hashTagInput.length > 0) {
      const hashTag =
        hashTagInput[0] === "#" ? hashTagInput : "#" + hashTagInput;
      if (!hashtagValidator.test(hashTag) || hashTag.length > 19) {
        setHashtagError(true);
      }
    }
    return () => {
      setHashtagError(false);
    };
  }, [hashTagInput]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);

  return (
    <>
      {showLoader ? (
        <Spinner />
      ) : (
        <div className="addTutorialPopup editTutorialPopUp" id="editTutorialModal">
          <div className="addTutorialDetails">
            <label className="category-label">Category *</label>
            <div className="">
              {/* <select
                value={tutorialCategory}
                onChange={(e) => setTutorialCategory(e.target.value)}
              >
                <option value="1">Project Delivery Management</option>
                <option value="2">Project Schedule Management</option>
                <option value="3">Project Cost Management </option>
              </select> */}
              <Dropdown
                className="dropDowncontainerVideo"
                data={categories}
                setValue={setCategoryName}
                value={categoryName}
                enableDropdown={true}
              />
            </div>
            <div className="tutorialInputFields">
              <span>
                Title <span>*</span>
              </span>
              <div>
                <input
                  placeholder="Enter Title"
                  onChange={(e) => setTutorialName(e.target.value)}
                  value={tutorialName}
                />
                <span>
                  <input
                    type="checkbox"
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                  Is Visible
                </span>
              </div>
            </div>
            <div className="tutorialAddFields">
              <div>
                <div className="tutorialInputFields">
                  <span>
                    Embed Link <span>*</span>{" "}
                  </span>
                  <input
                    placeholder="Enter Link"
                    onChange={(e) => setTutorialUrl(e.target.value)}
                    value={tutorialUrl}
                  />
                </div>

                {artifactsList.map((artifactType, artifactTypeIndex) => (
                  <>
                    <div className="tutorialInputFields">
                      <span>{artifactType.artifactTypeName}</span>
                      <MultiSelectDropdown
                        id={artifactType.artifactTypeName}
                        className="tutorialToolsDropdown"
                        enableDropdown={true}
                        data={artifactType.artifactData}
                        setUpdatedData={(data) =>
                          updateSelectedArtifacts(data, artifactTypeIndex)
                        }
                        label={artifactType.artifactTypeName}
                      />
                    </div>

                    <div className="tutorialToolsTags">
                      {artifactType.artifactData.map(
                        (item, index) =>
                          item.isSelected && (
                            <span key={item.id}>
                              {item.name}
                              <Icon
                                iconName="cancel"
                                onClick={() =>
                                  removeArtifact(artifactTypeIndex, index)
                                }
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    removeArtifact(artifactTypeIndex, index);
                                  }
                                }}
                                aria-label={`Remove ${item.name}`}
                                role="button"
                                tabIndex={0}
                              />
                            </span>
                          )
                      )}
                    </div>
                    <label>
                      Duration <span>*</span>
                      <span><div class="tooltipInfo"><Icon className="infoIcon" iconName="Info" />
                        <span class="tooltiptext">Please manually enter the duration of the video in mm:ss format. Example: if the duration is 5 minutes and 15 seconds, please enter 05:15</span>
                      </div></span>
                    </label>

                    <div className="tutorialInputFields duration">
                      <div className="minsContainer">
                        <label>Mins</label>
                        <div>
                          <input
                            placeholder="MM"
                            onChange={(e) => setTutorialMinutes(e.target.value.slice(0, 2))}
                            onInput={(e) => setTutorialMinutes(e.target.value.slice(0, 2))}
                            onBlur={(e) => {
                              if (e.target.value.length === 1) {
                                setTutorialMinutes('0' + tutorialMinutes)
                              }
                            }}
                            value={tutorialMinutes}
                            maxLength={2}
                            pattern="[0-9]*"
                            type="number"
                            className="minutes"
                            min="00" max="60"
                          /></div></div>
                      <span className="timeformat"><div>:</div></span>
                      <div className="secContainer">
                        <label>Secs</label>
                        <div>
                          <input
                            placeholder="SS"
                            onChange={(e) => setTutorialSeconds(e.target.value.slice(0, 2))}
                            onInput={(e) => setTutorialSeconds(e.target.value.slice(0, 2))}
                            onBlur={(e) => {
                              if (e.target.value.length === 1) {
                                setTutorialSeconds('0' + tutorialSeconds)
                              }
                            }}
                            value={tutorialSeconds}
                            maxLength={2}
                            type="number"
                            className="seconds"
                            pattern="[0-9]*"
                            min="00" max="60"
                          /></div>
                      </div>
                    </div>
                  </>
                ))}
              </div>

              <div className="tutorialPreview">
                <p>Preview</p>
                <div className="tutorialPreviewBlank">
                  {validateUrl() ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${tutorialUrl}`,
                      }}
                    />
                  ) : (
                    <p>A valid link will play video here</p>
                  )}
                </div>
              </div>
            </div>
            <div className="tutorialsHashTags">
              <span>Hashtags</span>

              <HashtagSuggestions
                className="topicsHashtagsInput"
                enableDropdown={true}
                data={hashtagSuggestions}
                setUpdateApiCall={setUpdateApiCall}
                setHashtagSuggestions={setHashtagSuggestions}
                searchValue={hashTagInput}
                setSearchValue={setHashTagInput}
                existingHashtags={addedHashTag}
                userAddedHashtags={userAddedHashtags}
                setUserAddedHashtags={setUserAddedHashtags}
                hashtagSuggestionInput={hashtagSuggestionInput}
                setHashtagSuggestionInput={setHashtagSuggestionInput}
                closeHashtagForm={() => {
                  setHashTagInput("");
                  setUserAddedHashtags([]);
                }}
                submitHashtags={updateHashTag}
                autoFocus={false}
                showSubmitButton={true}
              />
            </div>
            <div className="hashTagsContainer">
              {addedHashTag.map((hashTag) => (
                <span key={hashTag}>
                  {hashTag}
                  <Icon
                    iconName="cancel"
                    onClick={() => removeHashTag(hashTag)}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        removeHashTag(hashTag);
                      }
                    }}
                    aria-label={`Remove ${hashTag}`}
                    role="button"
                    tabIndex={0}
                  />
                </span>
              ))}
            </div>
          </div>
          <div
            className="saveContinueDetailsContainer"
            onClick={submitTutorial}
            onKeyPress={(e) => {
              if (e.which === 13) {
                submitTutorial();
              }
            }}
            aria-label="Save"
            role="button"
            tabIndex={0}
          >
            <button className="forwardButton">
              <span className="saveContinueButton">Save</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TutorialForm;
