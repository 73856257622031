import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';
// import getUsersList from '../../helpers/UsersList';
import { getUsersList } from '../../helpers/GraphService';
import { titleValidator, urlValidator } from '../../helpers/Validation';

import Breadcrumbs from '../Breadcrumbs';
import Modal from 'react-bootstrap/Modal';
import { activityTitleValidator } from '../../helpers/Validation';
import ProcessGroupDropdown from './ProcessGroupDropdown';
import ActivityTitle from './ActivityTitle';
import AddTools from './AddTools';
import AddTopics from './AddTopics';
import AssignRACI from './AssignRaci';
import ActivityDescripion from './ActivityDescription';
import SupportingActivities from './SupportingActivities';
import Deliverables from './Deliverables';
import TipsAndTechniques from './TipsAndTechniques';
import EntryCriteria from './EntryCriteria';
import ExitCriteria from './ExitCriteria';
import Tasks from './Tasks';
import InputsForSMM from './InputsForSMM';
import ComplexitySelection from './ComplexitySelection';
import TileDescription from './TileDescription';
import ActivityPreview from './ActivityPreview';
import DiscardModal from './DiscardModal';
import ErrorMessageModal from './ErrorMessageModal';
import AdminMessage from './AdminMessage';
import LockModalMessage from './LockModal';
import AddHashtags from './AddHashtags';
import AddCollaborators from './AddApproversOrReviewers';
import OwnerSelectionDropdown from '../TopicsBuilder/OwnerSelectionDropdown';
import UserSelectionDropdown from '../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown';

import { useAxios } from '../../context/AxiosContext';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { artifactMapData } from '../../static/ArtifactMapData';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getOtherUserProfile } from '../../helpers/GraphService';
import Spinner from '../../helpers/Spinner';
import SaveSpinner from '../../helpers/CrowdSourcingSpinner';
import { TabTitle } from '../../utils/GeneralFunctions';
import RaciDropdown from './RaciDropdown';
import RemoveRaci from './RemoveRaci';
import './ActivityDetails.css';
import './newActivityEdit.css'
import BusinessRule from './BusinessRule';
import AddOwnersOrCoOwners from './AddOwnersOrCoOwners';
const NewActivityDetails = ({
	isUserValidated,
	getSolutionMethodData,
	coOwners,
	isEnableComplexity,
	solutionMethodOwner,
	existingCoOwners
}) => {
	const titleLength = 120;
	const versionTypes = [
		{
			id: 'majorVersion',
			label: 'Major Version',
			increment: 1.0,
		},
		{
			id: 'minorVersion',
			label: 'Minor Version',
			increment: 0.01,
		},
		// {
		// 	id: 'noChange',
		// 	label: 'No Change',
		// 	increment: 0.0,
		// },
	];
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		owner: [],
	});
	const { track } = useAppInsights();
	const { breadcrumbs, setBreadcrumbs, isAdmin } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService, axiosDeleteService, userAlias } =
		useAxios();
	const [pageLockUser, setPageLockUser] = useState(null);
	const [isPageLock, setIsPageLock] = useState(false);
	const [showActivityPreview, setShowActivityPreview] = useState(false);
	const [showDiscardModal, setShowDiscardModal] = useState(false);
	const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [apiError, setApiError] = useState(false);
	const [complexities, setComplexities] = useState([]);
	const [showFields, setshowFields] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);
	const [businessRelatedContent, setBusinessRelatedContent] = useState([{ name: "", url: "" }]);
	const [activityGeneralDetails, setActivityGeneralDetails] = useState({
		activityTitle: '',
		activityTools: '',
		activityTopics: '',
		activityHashTags: [],
		approvers: [],
		reviewers: [],
		owner: {},
		coOwner: []
	});
	const [originalBusinessContent, setOriginalBusinessContent] = useState([]);
	const [activityGeneralUpdatedDetails, setActivityGeneralUpdatedDetails] =
		useState({
			activityTitle: '',
			activityTools: '',
			activityTopics: '',
			activityHashTags: [],
			approvers: [],
			reviewers: [],
		});
	const {
		activityId,
		complexityIds,
		parentPhaseId,
		phaseId,
		projectTypeId,
		solutionMethodId,
		draftId,
		defaultComplexity,
	} = useParams();

	const [lockEmailId, setLockEmailId] = useState(null);
	const [complexityLevelDetails, setComplexityLevelDetails] = useState({});
	const [isApiCalled, setIsApiCalled] = useState(
		complexityIds.split(',').map((item) => {
			return Number(item);
		})
	);
	const [showSaveSpinner, setShowSaveSpinner] = useState(false);
	// complexity level details update
	const [selectedComplexity, setSelectedComplexity] = useState(
		Number(defaultComplexity)
	);
	const [publishComplexityList, setPublishComplexityList] = useState([
		Number(defaultComplexity),
	]);
	const [complexityLevelUpdatedDetails, setComplexityLevelUpdatedDetails] =
		useState({});
	const [availableComplexity, setAvailableComplexity] = useState(
		complexityIds.split(',').map((item) => {
			return Number(item);
		})
	);
	// tools section states update
	const [toolsData, setToolsData] = useState([]);
	const [selectedTools, setSelectedTools] = useState([]);
	const [updatedTools, setUpdatedTools] = useState([]);
	const [toolSearchValue, setToolSearchValue] = useState('');
	const [toolsMapper, setToolsMapper] = useState({});

	// topics section state updates
	const [topicsData, setTopicsData] = useState([]);
	const [proceduresData, setProceduresData] = useState([]);
	const [selectedTopics, setSelectedTopics] = useState([]);
	const [selectedProcedures, setSelectedProcedures] = useState([]);
	const [updatedTopics, setUpdatedTopics] = useState([]);
	const [updatedProcedures, setUpdatedProcedures] = useState([]);
	const [topicSearchValue, setTopicSearchValue] = useState('');
	const [topicsCategorySearchValue, setTopicsCategorySearchValue] =
		useState('');
	const [topicsMapper, setTopicsMapper] = useState({});
	const [topicsCategoryData, setTopicsCategoryData] = useState([]);
	const [topicCategoryId, setTopicCategoryId] = useState([]);
	const [proceduresMapper, setProceduresMapper] = useState({});
	const [proceduresCategoryData, setProceduresCategoryData] = useState([]);
	const [proceduresCategoryIds, setProceduresCategoryIds] = useState([]);
	const [procedureSearchValue, setProcedureSearchValue] = useState('');
	const [proceduresCategorySearchValue, setProceduresCategorySearchValue] =
		useState('');
	const [isCoOwner, setIsCoOwner] = useState(true);

	// template section states update
	const [templatesData, setTemplatesData] = useState([]);
	const [templateSearchValue, setTemplateSearchValue] = useState({
		templatesList: '',
	});
	const [templatesMapper, setTemplatesMapper] = useState({});

	// phases state updates
	const [phases, setPhases] = useState([]);
	const [phaseSearchValue, setPhaseSearchvalue] = useState('');

	//process group state updates
	const [processGroup, setProcessGroup] = useState([]);
	const [showBusinessRuleModal, setShowBusinessRuleModal] = useState(false);
	const [processGroupMap, setProcessGroupMap] = useState({});
	const [isActivityCrowdSourced, setIsActivityCrowdSourced] = useState(false);
	const [criteriaTypes, setCriteriaTypes] = useState([]);
	const [showDraftButton, setShowDraftButton] = useState(false);
	const [rolesData, setRolesData] = useState([]);
	const [businessRuleContent, setBusinessRuleContent] = useState([])

	const [isActivityOwnerOrCoOwner, setIsActivityOwnerOrCoOwner] = useState([])

	const [approvers, setApprovers] = useState(activityGeneralDetails['approvers']);
	// const [coOwners, setCoOwners] = useState(activityGeneralDetails['coOwners']);
	const [reviewers, setReviewers] = useState(activityGeneralDetails['reviewers']);
	const [owner, setOwner] = useState(activityGeneralDetails['owner'])
	const [coOwner, setCoOwner] = useState([])
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [activityOwnerEmail, setActivityOwnerEmail] = useState('')
	const [coOwnerEmail, setCoOwnerEmail] = useState('')
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		owner: false
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		owner: false
	});

	const [activityOrMethodOwner, setActivityOrMethodOwner] = useState(solutionMethodOwner)
	const [activityOrMethodCoOwner, setActivityOrMethodCoOwner] = useState(existingCoOwners);
	const [showRelatedContentUrlError, setShowRelatedContentUrlError] = useState(false);
	const [showRelatedContentTitleError, setShowRelatedContentTitleError] = useState(false);
	const [showRelatedContentUrlErrorMsg, setShowRelatedContentUrlErrorMsg] = useState("");
	const [showRelatedContentTitleErrorMsg, setShowRelatedContentTitleErrorMsg] = useState("");
	const [showRelatedContentIndexError, setShowRelatedContentIndexError] = useState();
	const [showBusinessRuleUrlError, setShowBusinessRuleUrlError] = useState(false);
	const [showBusinessRuleTitleError, setShowBusinessRuleTitleError] = useState(false);
	const [showBusinessRuleUrlErrorMsg, setShowBusinessRuleUrlErrorMsg] = useState("");
	const [showBusinessRuleTitleErrorMsg, setShowBusinessRuleTitleErrorMsg] = useState("");
	const [showBusinessRuleIndexError, setShowBusinessRuleIndexError] = useState();
	const [businessRuleUpdatedArray, setBusinessRuleUpdatedArray] = useState([]);
	const [raciErrorMessages, setRaciErrorMessages] = useState([]);

	// Automatically update complexityLevelDetails when the dropdown selection changes
	const updateComplexityDetails = (updatedDetails) => {
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...prevState[selectedComplexity],
				...updatedDetails,
				isRaciValid: true,
			},
		}));
	};

	//   useEffect(() => {
	// 	getRaciErrorMessages();
	//   }, [complexityLevelDetails, selectedComplexity]);

	useEffect(() => {
		setActivityOrMethodOwner(solutionMethodOwner)
		setActivityOrMethodCoOwner(existingCoOwners)
	}, [solutionMethodOwner, existingCoOwners])


	const removeUser = (email, value, setValue) => {
		const updatedList = value.filter(({ mail }) => mail !== email);
		setValue(updatedList);
	};
	const getdataUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};



	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getdataUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);
	useEffect(() => {
		// Fetch business rules when the component mounts
		fetchBusinessRules();
	}, [selectedComplexity]);

	useEffect(() => {
		// Fetch business rules when modal is shown
		if (showBusinessRuleModal) {
			fetchBusinessRules();
		}
	}, [showBusinessRuleModal, selectedComplexity]);
	const fetchBusinessRules = async () => {
		let id = activityId !== 'null' ? activityId : draftId
		let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${id}/2/${complexityIds}/${false}`
		await axiosGetService(api).then(
			(response) => {
				let fiterByComplexity = response.data.filter(e => e.complexityId === selectedComplexity)
				if (fiterByComplexity.length > 0) {
					setBusinessRelatedContent(fiterByComplexity);
					setOriginalBusinessContent(fiterByComplexity);
					getBusinessRule(fiterByComplexity); // Assuming this function processes the filtered content further
				} else {
					// If no results, initialize with a default empty object
					setBusinessRelatedContent([{ name: "", url: "" }]);
					setOriginalBusinessContent([]);
				}
			})
	}




	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getdataUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (activityOwnerEmail) {
				getdataUser(activityOwnerEmail, 'owner');
			} else {
				setUsersList((prevState) => ({ ...prevState, owner: [] }));
				setError({ ...error, ['owner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [activityOwnerEmail]);


	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getdataUser(coOwnerEmail, 'coOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
				setError({ ...error, ['coOwner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);
	useEffect(() => {
		setApprovers(activityGeneralDetails['approvers']);
		setReviewers(activityGeneralDetails['reviewers']);
		setOwner(activityGeneralDetails['owner'])
		setCoOwner(activityGeneralDetails['coOwner'])
	}, [activityGeneralDetails]);

	// useEffect(() => {
	// 	setActivityGeneralDetails(prevState => ({
	// 		...prevState,
	// 		approvers: approvers,
	// 	}));
	// }, []);
	$("#showDraftButtondropdown").on('keyup', function (e) {
		if (e.key === 'Enter' || e.keyCode === 13) {
			setShowDraftButton(!showDraftButton);
		}
	});
	// const publishBusinessRule = async (item, id, isUpdate) => {
	// 	const url = item.id === undefined ? 'api/sdmbusinessrules/addBusinessRule':'api/sdmbusinessrules/updateBusinessRule'
		

	// 	const postData = JSON.stringify({
	// 		id: isUpdate ? item.id : undefined,
	// 		name: item.name,
	// 		url: item.url,
	// 		isDrafted: true,
	// 		ArtifactId: id,
	// 		ArtifactTypeId: 2,
	// 		ComplexityId: selectedComplexity
	// 	});

	// 	try {
	// 		const response = await axiosPostService(url, postData);
	// 		if (response.data.status && response.data.errorMsg === null) {
	// 			toast.info(isUpdate ? 'Content has been updated successfully' : 'Content has been added successfully', {
	// 				position: toast.POSITION.TOP_RIGHT,
	// 			});
	// 		} else {
	// 			toast.error(response.data.errorMsg, {
	// 				position: toast.POSITION.TOP_RIGHT,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		toast.error(isUpdate ? 'An error occurred while updating content' : 'An error occurred while adding content', {
	// 			position: toast.POSITION.TOP_RIGHT,
	// 		});
	// 	}
	// };
	

	// const addBusinessRelatedContent = async () => {
	// 	const id = activityId !== 'null' ? activityId : draftId;
	// 	const validItems = businessRelatedContent.filter(item => item.name && item.url);

	// 	if (validItems.length > 0) {
	// 		setError(false);

	// 		validItems.forEach(item => {
	// 			const isUpdate = businessRelatedContent.some(content => content.id === item.id);
	// 			publishBusinessRule(item, id, isUpdate);
	// 		});

	// 		// Reset the form and fetch updated business rules after processing
	// 		// changeToDefault();
	// 		fetchBusinessRules();
	// 	} else {
	// 		setError(true);
	// 	}
	// };
	// const addBusinessRelatedContent = async () => {
	// 	const id = activityId !== 'null' ? activityId : draftId;

	// 	// Filter out valid items to be added or updated
	// 	const validItems = businessRelatedContent.filter(item => item.name && item.url);

	// 	if (validItems.length > 0) {
	// 		setError(false);

	// 		// Identify existing content to delete
	// 		const existingContent = await fetchBusinessRules(); // Assuming fetchBusinessRules returns the current content
	// 		const existingIds = existingContent.map(item => item.id);

	// 		const itemsToDelete = existingContent.filter(item => 
	// 			!validItems.some(validItem => validItem.id === item.id)
	// 		);

	// 		// Delete removed items
	// 		for (const item of itemsToDelete) {
	// 			await deleteBusinessRelatedContent(item.id);
	// 		}

	// 		// Add or update valid items
	// 		for (const item of validItems) {
	// 			const isUpdate = existingIds.includes(item.id);
	// 			await publishBusinessRule(item, id, isUpdate);
	// 		}

	// 		// Reset the form and fetch updated business rules after processing
	// 		// changeToDefault();
	// 		fetchBusinessRules();
	// 	} else {
	// 		setError(true);
	// 	}
	// };


	const addBusinessRelatedContent = async () => {
		const id = activityId !== 'null' ? activityId : draftId;

		// Filter out valid items to be added or updated
		const validItems = businessRelatedContent.filter(item => item.name && item.url);

		businessRelatedContent.map(async (item) => {
            if (item.id === undefined) {
                const relatedContent =
                {
					id: item?.id,
					name: item.name,
					url: item.url,
					isDrafted: true,
					ArtifactId: id,
					ArtifactTypeId: 2,
					ComplexityId: selectedComplexity
                }
                const postData = JSON.stringify(relatedContent)
                await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData).then(
                    (response) => {
                        if (response.data.status && response.data.errorMsg === null) {
                            toast.info('Content has been added successfully', {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                            fetchBusinessRules();
                        } else {
                            toast.error(response.data.errorMsg, {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                        }
                    }
                )
            } else {
                if (businessRuleUpdatedArray.includes(item.id)) {
                    const updatedRelatedContent =
                    {
						id: item?.id,
						name: item.name,
						url: item.url,
						isDrafted: true,
						ArtifactId: id,
						ArtifactTypeId: 2,
						ComplexityId: selectedComplexity
                    }
                    const postData = JSON.stringify(updatedRelatedContent)
                    await axiosPostService(`api/sdmbusinessrules/updateBusinessRule`, postData).then(
                        (response) => {
                            if (response.data.status && response.data.errorMsg === null) {
                                toast.info('Content has been updated successfully', {
                                    position: toast.POSITION.TOP_RIGHT,
                                })
                                fetchBusinessRules();
                            } else {
                                toast.error(response.data.errorMsg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                        })
                }
            }
        });

        setShowBusinessRuleModal(false);






		if (validItems.length > 0) {
			const originalIds = originalBusinessContent.map(item => item.id);
			const validIds = validItems.map(item => item.id);
			const itemsToDelete = originalBusinessContent.filter(item => !validIds.includes(item.id));

		// 	// Delete removed items
			for (const item of itemsToDelete) {
				await deleteBusinessRelatedContent(item.id);
			}
		}
		// 	setError(false);

		// 	// Identify items to delete
		// 	const originalIds = originalBusinessContent.map(item => item.id);
		// 	const validIds = validItems.map(item => item.id);
		// 	const itemsToDelete = originalBusinessContent.filter(item => !validIds.includes(item.id));

		// 	// Delete removed items
		// 	for (const item of itemsToDelete) {
		// 		await deleteBusinessRelatedContent(item.id);
		// 	}

		// 	// Add or update valid items
		// 	for (const item of validItems) {
		// 		const isUpdate = originalIds.includes(item.id);
		// 		await publishBusinessRule(item, id, isUpdate);
		// 	}

		// 	// Update original content state to reflect current state
		// 	setOriginalBusinessContent(validItems);

		// 	// Reset the form and fetch updated business rules after processing
		// 	changeToDefault();
		// } else {
		// 	setError(true);
		// }
	};

	const deleteBusinessRelatedContent = async (id) => {
		let complexity = selectedComplexity.toString();
		await axiosDeleteService(
			`api/sdmbusinessrules/deletebusinessrule/${id}/${activityId}/${2}/${complexity}`
		).then(response => {
			if (response.status) {
				toast.info('Content has been deleted successfully', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}).catch(error => {
			toast.error('An error occurred while deleting content', {
				position: toast.POSITION.TOP_RIGHT,
			});
		});
	};

	const changeToDefault = () => {
		setError(false);
		setShowBusinessRuleModal(false);
	};


	// const deleteBusinessRelatedContent = async (id) => {
	// 	let complexity = selectedComplexity.toString();
	// 	await axiosDeleteService(
	// 		`api/sdmbusinessrules/deletebusinessrule/${id}/${activityId}/${2}/${complexity}`
	// 	).then(response => {
	// 		if (response.status) {
	// 			toast.info('Content has been deleted successfully', {
	// 				position: toast.POSITION.TOP_RIGHT,
	// 			});
	// 			getBusinessRelatedContent();
	// 		}
	// 	});
	// };

	const handelFormView = (formName) => {
		if (showFields?.includes(formName)) {
			const updateFieldsName = showFields?.filter((name) => name !== formName);
			setshowFields(updateFieldsName);
		} else {
			setshowFields([...showFields, formName]);
		}
	};
	const handelFormViewEdit = formName => {
		handelFormView(formName);
	};
	const handelFormViewEditPerventClose = formName => {
		setshowFields([...showFields, formName]);
	};
	const getUser = async (userEmail, type) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setActivityGeneralDetails((prevState) => ({
				...prevState,
				[type]: [...prevState[type], response],
			}));
			if (type === 'coOwner') {
				setIsActivityOwnerOrCoOwner((prevState) => (
					[...prevState, response.mail]
				))
			}
		});
	};

	const getOwner = async (userEmail, type) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setActivityGeneralDetails((prevState) => ({
				...prevState,
				[type]: response,
			}));
			setIsActivityOwnerOrCoOwner((prevState) => (
				[...prevState, response.mail]
			))
		});
	};
	const getCollaboratorsInfo = async (membersList, type) => {
		for (const userEmail of membersList) {
			getUser(userEmail, type);
		}
	};

	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${draftId !== 'null' ? draftId : 0}-${activityId !== 'null' ? activityId : 0
			}&itemTypeId=2
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId !== 'null'
				? phaseId
				: parentPhaseId !== 'null'
					? parentPhaseId
					: 0
			}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());

			const projectBreadcrumb = {
				title: breadcrumbTitle[1],
				type: 'projectType',
				path: `/project/${projectTypeId}`,
				parent: ['home'],
			};
			const solutionMethodBreadcrumb = {
				title: breadcrumbTitle[2],
				type: 'solutionMethod',
				path: `/project/${projectTypeId}/method/${solutionMethodId}`,
				parent: 'projectType',
			};
			const phaseBreadcrumb = {
				title: breadcrumbTitle[3],
				type: 'Phase',
				path: `/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`,
				parent: 'solutionMethod',
			};
			const pageBreadcrumb = {
				title: breadcrumbTitle[4] + ' - ' + 'Edit',
				type: 'activity',
				path: window.location.pathname,
				parent: ['pageTitle'],
			};
			const newBreadcrumbs = AddBreadCrumb(
				breadcrumbs.slice(
					0,
					breadcrumbs.length > 1 && breadcrumbs[1].type === 'myContributions'
						? 2
						: 1
				),
				[
					projectBreadcrumb,
					solutionMethodBreadcrumb,
					phaseBreadcrumb,
					pageBreadcrumb,
				]
			);
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[4] + ' - Edit Activity');
		});
	};

	const getProcessGroup = async () => {
		await axiosGetService(`api/sdmprocessgroups/getallforactivity`).then(
			(response) => {
				const data = response.data.map((item) => {
					return {
						name: item.name,
						id: item.id,
					};
				});
				const IdsMap = {
					0: 'Select a process group',
				};
				for (const process of data) {
					IdsMap[process.id] = process.name;
				}
				setProcessGroup(data);
				setProcessGroupMap(IdsMap);
			}
		);
	};

	const createMockData = (complexityId, setValue) => {
		setValue((prevState) => ({
			...prevState,
			[complexityId]: {
				description: '',
				toolsList: [],
				topicsList: [],
				supportingActivities: '',
				deliverables: '',
				tileDescription: '',
				tipsAndTechniques: '',
				entryCriteria: [],
				exitCriteria: [],
				taskList: [],
				isCritical: false,
				complexityId: complexityId,
				processGroupId: 0,
				templatesList: [],
				responsibleList: [],
				accountableList: [],
				consultedList: [],
				informedList: [],
				activityVersion: '1.00',
				selectedVersionType: versionTypes[0],
				id: 0,
				imagePaths: [],
				isProcessStep: false,
				newComplexity: true,
			},
		}));
	};

	const storeResponse = (activity, setValue) => {
		setIsActivityCrowdSourced(activity.isCrowdSourced);
		const entryPhaseCriteria = activity.entryCriteriaList.map((item) => {
			return {
				id: item.id,
				name: item.name,
				description: item.description,
				criteriaType: item.criteriaType,
				valueType: item.valueType,
				status: 'old',
				criteriaTypeID: item.criteriaTypeID,
				criteriaTypeName: item.criteriaTypeName,
				sequence: item.sequence
			};
		});
		const exitPhaseCriteria = activity.exitCriteriaList.map((item) => {
			return {
				id: item.id,
				name: item.name,
				description: item.description,
				criteriaType: item.criteriaType,
				valueType: item.valueType,
				status: 'old',
				criteriaTypeID: item.criteriaTypeID,
				criteriaTypeName: item.criteriaTypeName,
				sequence: item.sequence
			};
		});
		const taskList = activity.tasksList.map((item) => {
			return {
				id: item.id,
				name: item.name,
				description: item.description,
				status: 'old',
				sequence: item.sequence
			};
		});
		const existingTopicsList = activity.topicsList.map((item) => {
			return {
				id: item.id,
				name: item.name,
				procedureTypeId: Number(item.procedureTypeId),
			};
		});
		const existingToolsList = activity.toolsList.map((item) => {
			return {
				id: item.id,
				name: item.name,
			};
		});
		const existingTemplateList = activity.templatesList.map((item) => {
			return {
				id: item.id,
				name: item.title,
				previewUrl: item.previewUrl,
				fileLocation: item.fileLocation,
			};
		});
		const existingProceduresList = activity.proceduresList.map((item) => {
			return {
				id: item.id,
				name: item.name,
			};
		});
		const hashtagsList = activity.hashTagsList.map(({ name }) => {
			return name;
		});
		setSelectedProcedures([...existingProceduresList]);
		setUpdatedProcedures([...existingProceduresList]);
		setSelectedTopics([...existingTopicsList]);
		setUpdatedTopics([...existingTopicsList]);
		setSelectedTools([...existingToolsList]);
		setUpdatedTools([...existingToolsList]);
		setActivityGeneralDetails((prevState) => ({
			...prevState,
			activityTitle: activity.title,
			activityHashTags: hashtagsList,
		}));
		setActivityGeneralUpdatedDetails((prevState) => ({
			...prevState,
			activityTitle: activity.title,
			activityHashTags: hashtagsList,
		}));
		setValue((prevState) => ({
			...prevState,
			[activity.complexityId]: {
				description: activity.description,
				toolsList: activity.toolsList,
				topicsList: activity.topicsList,
				supportingActivities: activity.supportingActivities,
				deliverables: activity.deliverables,
				tileDescription: activity.tileDescription,
				tipsAndTechniques: activity.tipsAndTechniques,
				entryCriteria: [...entryPhaseCriteria],
				exitCriteria: [...exitPhaseCriteria],
				taskList: [...taskList],
				isCritical: activity.isCritical,
				complexityId: activity.complexityId,
				templatesList: existingTemplateList,
				responsibleList: activity.responsibleRolesList,
				accountableList: activity.accountableRolesList,
				consultedList: activity.consultedRolesList,
				informedList: activity.informedRolesList,
				activityVersion: activity.activityVersion,
				selectedVersionType: versionTypes[1],
				id: activity.id,
				imagePaths: activity.imagePaths,
				isProcessStep: activity.isProcessStep,
				isRaciValid: activity.isRaciValid,
				isBusinessRules: activity.isBusinessRules,
				isDeliverables: activity.isDeliverables,
				secureByDefault: activity.secureByDefault,
			},
			groupId: activity.groupId,
			sequence: activity.sequence,
			groupGuid: activity.groupGuid,
			parentPhaseId: activity.parentPhaseId,
			solutionMethodId: activity?.solutionMethodId,
			processGroupId: activity.processGroupId,
		}));
	};

	const getActivityData = async () => {
		await axiosGetService(
			`api/sdmactivity/getactivitybyidandcomplexity/${draftId !== 'null' ? draftId : 0
			}/${activityId !== 'null' ? activityId : 0}/${complexityIds}`
		).then((response) => {

			validateUser(response.data[0]?.solutionMethodId);
			getCollaboratorsInfo(response?.data[0]?.approverMembersList, 'approvers');
			getCollaboratorsInfo(response?.data[0]?.reviewerMembersList, 'reviewers');
			getCollaboratorsInfo(response?.data[0]?.coOwnerMembersList, 'coOwner');
			getOwner(response?.data[0]?.owner, 'owner')
			for (const activityData of response.data) {
				storeResponse(activityData, setComplexityLevelDetails);
				storeResponse(activityData, setComplexityLevelUpdatedDetails);
			}
			track(2, 901, activityId ? activityId : draftId, response.data[0].title, {
				complexityId: selectedComplexity,
			});
			// if(complexityIds.length > 1){
			// const complexityIds = complexities.map(complexity => complexity.id);
			// setPublishComplexityList(complexityIds);
			// }
			setShowSpinner(false);
		});
	};

	const validateUser = async (id) => {
		await axiosGetService(
			`api/sdmsolutionmethod/ismethodownerorcoowner/${id}`
		).then((response) => {
			if (response.data) {
				setIsCoOwner(true);
			} else {
				setIsCoOwner(false);
			}
		});
	};

	const getTools = async () => {
		await axiosGetService(
			`api/sdmtools/getAllWithDrafts?searchValue=${toolSearchValue ? toolSearchValue : 0
			}`
		).then((response) => {
			const data = response.data;
			const tools = data.map((item) => {
				return {
					id: item.id,
					name: item.name,
					url: item.url,
					isSelected: item.isSelected,
				};
			});
			if (Object.keys(toolsMapper).length === 0) {
				const toolsIdMap = {};
				for (const tool of data) {
					toolsIdMap[tool.id] = {
						name: tool.name,
						url: tool.url,
					};
				}
				setToolsMapper(toolsIdMap);
			}
			setToolsData(tools);
		});
	};

	const getTopics = async () => {
		const categoryIds = topicCategoryId.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmtopics/getbysearch?searchValue=${topicSearchValue ? topicSearchValue : 0
			}&isTopic=${true}&topicCategoryIds=${categoryIds.length > 0 ? categoryIds.toString() : 0
			}`
		).then((response) => {
			const data = response.data;
			const topicIds = data.map((item) => {
				return {
					id: item.id,
					name: item.name,
					procedureTypeId: Number(item.procedureTypeId),
				};
			});
			const topicIdsMap = {};
			for (const topic of data) {
				topicIdsMap[topic.id] = {
					name: topic.name,
				};
			}
			setTopicsMapper((prevState) => ({ ...prevState, ...topicIdsMap }));
			setTopicsData([...topicIds]);
		});
	};
	// useEffect(() => {
	// 	if (showBusinessRuleModal) {
	// 		// setTimeout(() => {
	// 			setBusinessRelatedContent([...businessRelatedContent]);
	// 		// }, 100);
	// 	}
	// }, [showBusinessRuleModal]);


	// BusinessrULES
	const handleAddBusinessContentFields = () => {
		const updatedBusinessContent = businessRelatedContent.filter(
			({ name, url }) => name === "" && url === ""
		);
		if (!updatedBusinessContent.length) {
			setBusinessRelatedContent([...businessRelatedContent, { name: "", url: "" }]);
		}
	};
	const handleToChangeBusinessContent = (e, item, index) => {
		if (e.target.name === 'name') {
			const duplicateTitle = businessRelatedContent.filter(({ name }) => name.toLowerCase() === e.target.value.toLowerCase());
			const isError = e.target.value && !titleValidator.test(e.target.value);
			if (duplicateTitle.length) {
				setShowBusinessRuleTitleError(true);
				setShowBusinessRuleTitleErrorMsg("Duplicate title");
				setShowBusinessRuleIndexError(index);
			} else if (isError) {
				setShowBusinessRuleTitleError(true);
				setShowBusinessRuleTitleErrorMsg("Invalid title");
				setShowBusinessRuleIndexError(index);
			} else if (!isError) {
				setShowBusinessRuleTitleError(false);
				setShowBusinessRuleTitleErrorMsg('');
			}
		} else {
			const duplicateUrl = businessRelatedContent.filter(({ url }) => url === e.target.value);
			const isError = e.target.value && !urlValidator.test(e.target.value);
			if (duplicateUrl.length) {
				setShowBusinessRuleUrlError(true);
				setShowBusinessRuleUrlErrorMsg("Duplicate url");
				setShowBusinessRuleIndexError(index);
			} else if (isError) {
				setShowBusinessRuleUrlError(true);
				setShowBusinessRuleUrlErrorMsg("Invalid Url");
				setShowBusinessRuleIndexError(index);
			} else if (!isError) {
				setShowBusinessRuleUrlError(false);
				setShowBusinessRuleUrlErrorMsg('');
			}
		}
		if (item.id !== undefined) {
			setBusinessRuleUpdatedArray([...businessRuleUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...businessRelatedContent];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setBusinessRelatedContent(updatedBusinessRuleContent);
	}

	const removeBusinessRelatedContent = (index) => {
		const updatedContent = [...businessRelatedContent];
		updatedContent.splice(index, 1);
		// setOriginalBusinessContent(updatedContent);
		setBusinessRelatedContent(updatedContent);
	}
	const addBusinessRuleContent = async () => {
		const updatedRelatedContent = businessRelatedContent.filter(
			({ name, url }) => name !== "" || url !== ""
		);
		setBusinessRelatedContent(updatedRelatedContent);
		setShowBusinessRuleModal(false);
	}
	//END BUSINESS RULES



	const getTopicsCategoryData = async () => {
		await axiosGetService(
			`api/sdmtopiccategories/getdraftbyprocedure?IsTopic=${true}&searchValue=${topicsCategorySearchValue ? topicsCategorySearchValue : 0
			}`
		).then((response) => {
			const data = response.data.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});
			setTopicsCategoryData(data);
		});
	};

	const getProceduresCategoryData = async () => {
		await axiosGetService(
			`api/sdmtopiccategories/getdraftbyprocedure?IsTopic=${false}&searchValue=${proceduresCategorySearchValue ? proceduresCategorySearchValue : 0
			}`
		).then((response) => {
			const data = response.data.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});
			setProceduresCategoryData(data);
		});
	};

	const getProcedures = async () => {
		const categoryIds = proceduresCategoryIds.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmtopics/getbysearch?searchValue=${procedureSearchValue ? procedureSearchValue : 0
			}&isTopic=${false}&topicCategoryIds=${categoryIds.length > 0 ? categoryIds.toString() : 0
			}`
		).then((response) => {
			const data = response.data;
			const procedureIds = data.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});
			const procedureIdsMap = {};
			for (const procedure of data) {
				procedureIdsMap[procedure.id] = {
					name: procedure.name,
				};
			}
			setProceduresMapper((prevState) => ({
				...prevState,
				...procedureIdsMap,
			}));
			setProceduresData([...procedureIds]);
		});
	};

	const getTemplates = async () => {
		await axiosGetService(
			`api/sdmtemplate/getTemplatesForUpdateActivity?searchValue=${templateSearchValue['templatesList']
				? templateSearchValue['templatesList']
				: 0
			}&complexityId=${selectedComplexity}`
		).then((response) => {
			const data = response.data;
			const templateIds = data.map((item) => {
				return {
					id: item.id,
					name: item.name,
					fileLocation: item.fileLocation,
					documentId: item.documentId,
					previewUrl: item.previewUrl,
				};
			});
			const templateIdsMap = {};
			for (const template of data) {
				templateIdsMap[template.id] = {
					name: template.name,
					fileLocation: template.fileLocation,
					previewUrl: template.previewUrl,
					fileType: template.fileType,
					inProgress: template.inProgress,
				};
			}
			setTemplatesMapper((prevState) => ({ ...prevState, ...templateIdsMap }));
			setTemplatesData([...templateIds]);
		});
	};

	const getComplexityList = async () => {
		await axiosGetService(`api/activities/projectdynamiccomplexity?solutionMethodId=${solutionMethodId}`).then(
			(response) => {
				setComplexities(response.data);
			}
		);
	};
	const getPhases = async () => {
		await axiosGetService(
			`api/sdmphase/getdraftphases?searchValue=${phaseSearchValue.trim().length > 0
				? encodeURIComponent(phaseSearchValue)
				: 0
			}&methodIds=${0}`
		).then((response) => {
			const phase = response.data.map((item) => {
				return {
					id: item.phaseId,
					name: item.name,
				};
			});
			setPhases(phase);
		});
	};

	const exitLockMode = async () => {
		if (pageLockUser === userAlias) {
			await axiosDeleteService(
				`api/sdmartifactmaster/deleteLockedArtifact?artifactId=${activityId !== 'null' ? activityId : 0
				}&artifactTypeConstId=${artifactMapData['activities']
				}&artifactDraftId=${draftId ? draftId : 0}`
			);
		}
	};

	const enterLockMode = async () => {
		const postData = {
			artifactId: activityId !== 'null' ? activityId : 0,
			artifactTypeConstId: artifactMapData['activities'],
			artifactDraftId: draftId,
		};
		await axiosPostService(`api/sdmartifactmaster/lockArtifact`, postData).then(
			() => {
				setPageLockUser(userAlias);
			}
		);
	};

	const checkLockMode = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifacts?artifactId=${activityId !== 'null' ? activityId : 0
			}&artifactTypeConstId=${artifactMapData['activities']}&artifactDraftId=${draftId ? draftId : 0
			}`
		).then((response) => {
			setLockEmailId(response.data.email);
			if (response.data.status && userAlias !== response.data.email) {
				setIsPageLock(true);
			} else if (!response.data.status) {
				enterLockMode();
			} else {
				enterLockMode();
				setPageLockUser(response.data.email);
			}
		});
	};

	const convertToString = (data) => {
		const list = data?.map(({ id }) => {
			return id;
		});
		if (list?.length) {
			return list.toString();
		}
		return null;
	};

	const removeId = (data, type) => {
		if (type === 'Entry' || type === 'Exit') {
			const updatedData = data.map((item) => {
				if (item.status === 'new') {
					delete item.id;
					return {
						...item,
					};
				}
				return {
					...item,
				};
			});
			return updatedData;
		} else {
			const updatedData = data.map((item) => {
				if (item.status === 'new') {
					return {
						description: item.description,
						name: item.name,
						sequence: item.sequence
					};
				}
				return {
					description: item.description,
					name: item.name,
					id: item.id,
					sequence: item.sequence
				};
			});
			return updatedData;
		}
	};
	const handleDropdownSelection = (updatedComplexityLevelDetails) => {
		// Update the complexityLevelUpdatedDetails state
		setComplexityLevelUpdatedDetails(updatedComplexityLevelDetails);

		// Then, update the complexityLevelDetails state with the selected dropdown values
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...prevState[selectedComplexity],
				responsibleList: updatedComplexityLevelDetails[selectedComplexity]?.responsibleList || [],
				accountableList: updatedComplexityLevelDetails[selectedComplexity]?.accountableList || [],
				consultedList: updatedComplexityLevelDetails[selectedComplexity]?.consultedList || [],
				informedList: updatedComplexityLevelDetails[selectedComplexity]?.informedList || [],
				isRaciValid: true,
			},
		}));
	};

	const initializeComplexityDetails = (complexityId) => {
		if (!complexityLevelUpdatedDetails[complexityId]) {
			setComplexityLevelUpdatedDetails((prevState) => ({
				...prevState,
				[complexityId]: {
					responsibleList: [],
					accountableList: [],
					consultedList: [],
					informedList: [],
				},
			}));
		}
	};

	useEffect(() => {
		initializeComplexityDetails(selectedComplexity);
	}, [selectedComplexity]);

	const updateComplexityLevelDetails = (complexityId, type, updatedData) => {
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[complexityId]: {
				...prevState[complexityId],
				[type]: updatedData,
			},
		}));
	};

	useEffect(() => {
		// Ensure to update details when complexityLevelUpdatedDetails changes
		if (complexityLevelUpdatedDetails[selectedComplexity]) {
			updateComplexityLevelDetails(selectedComplexity, 'responsibleList', complexityLevelUpdatedDetails[selectedComplexity]?.responsibleList || []);
			updateComplexityLevelDetails(selectedComplexity, 'accountableList', complexityLevelUpdatedDetails[selectedComplexity]?.accountableList || []);
			updateComplexityLevelDetails(selectedComplexity, 'consultedList', complexityLevelUpdatedDetails[selectedComplexity]?.consultedList || []);
			updateComplexityLevelDetails(selectedComplexity, 'informedList', complexityLevelUpdatedDetails[selectedComplexity]?.informedList || []);
		}
	}, [complexityLevelUpdatedDetails, selectedComplexity]);
	const getRaciErrorMessages = async (complexity) => {
		const postBody = {
			responsibleRoleIds: convertToString(
				complexityLevelDetails[complexity]?.responsibleList
			),
			accountableRoleIds: convertToString(
				complexityLevelDetails[complexity]?.accountableList
			),
			consultedRoleIds: convertToString(
				complexityLevelDetails[complexity]?.consultedList
			),
			informedRoleIds: convertToString(
				complexityLevelDetails[complexity]?.informedList
			),
		};
		const response = await axiosPostService(
			'api/sdmactivity/getracierrors',
			postBody
		);
		return !response.data || response.data.length === 0;
	};

	// const getRaciErrorMessages = async () => {
	// 	if (!complexityLevelUpdatedDetails[selectedComplexity]) return;
	// 	const postBody = {
	// 		responsibleRoleIds: convertToString(
	// 			complexityLevelUpdatedDetails[selectedComplexity].responsibleList
	// 		),
	// 		accountableRoleIds: convertToString(
	// 			complexityLevelUpdatedDetails[selectedComplexity].accountableList
	// 		),
	// 		consultedRoleIds: convertToString(
	// 			complexityLevelUpdatedDetails[selectedComplexity].consultedList
	// 		),
	// 		informedRoleIds: convertToString(
	// 			complexityLevelUpdatedDetails[selectedComplexity].informedList
	// 		),
	// 	};
	// 	const response = await axiosPostService(
	// 		'api/sdmactivity/getracierrors',
	// 		postBody
	// 	);
	// 	// setRaciErrorMessages(response.data ?? []);
	// 	return !response.data || response.data.length === 0;
	// };
	const draftActivity = async (type) => {
		const imagesUrl = sessionStorage.getItem('activityEditUploadedImages')
			? JSON.parse(sessionStorage.getItem('activityEditUploadedImages'))
			: [];
		const postValue = [];
		const postData = {};
		for (const complexity of publishComplexityList) {
			postData[complexity] = {
				id: complexityLevelDetails[complexity].id,
				isDrafted: true,
				complexityId: complexity,
				activityId: Number(activityId),
				title: activityGeneralDetails['activityTitle'],
				parentPhaseId: complexityLevelDetails['parentPhaseId'],
				solutionMethodId: complexityLevelDetails['solutionMethodId'],
				groupId: complexityLevelDetails['groupId'],
				sequence: complexityLevelDetails['sequence'],
				modifiedBy: userAlias,
				toolIds: convertToString(selectedTools),
				topicIds: convertToString(selectedTopics),
				procedureIds: convertToString(selectedProcedures),
				processGroupId: complexityLevelDetails.processGroupId,
				deliverableId: complexityLevelDetails.isDeliverables ? 1 : 2,
				responsibleRoleIds: convertToString(
					complexityLevelDetails[complexity].responsibleList
				),
				accountableRoleIds: convertToString(
					complexityLevelDetails[complexity].accountableList
				),
				consultedRoleIds: convertToString(
					complexityLevelDetails[complexity].consultedList
				),
				informedRoleIds: convertToString(
					complexityLevelDetails[complexity].informedList
				),
				templateIds: convertToString(
					complexityLevelDetails[complexity].templatesList
				),
				description: complexityLevelDetails[complexity].description,
				supportingActivities:
					complexityLevelDetails[complexity].supportingActivities,
				// deliverables: complexityLevelDetails[complexity].deliverables,
				deliverables :
  				Array.isArray(complexityLevelDetails[complexity]?.deliverables) &&
  						complexityLevelDetails[complexity].deliverables.length === 0
   					 ? null
    					: complexityLevelDetails[complexity]?.deliverables,
				tileDescription: complexityLevelDetails[complexity].tileDescription,
				tipsAndTechniques: complexityLevelDetails[complexity].tipsAndTechniques,
				// entryCriteriaList: removeId(
				// 	complexityLevelDetails[complexity].entryCriteria,
				// 	'Entry'
				// ),
				entryCriteriaList: removeId(
					complexityLevelDetails[complexity]?.entryCriteria?.filter(
						(criteria) => criteria.name.trim() !== ""
					),
					'Entry'
				),
				exitCriteriaList: removeId(
					complexityLevelDetails[complexity]?.exitCriteria?.filter(
						(criteria) => criteria.name.trim() !== ""
					),
					'Exit'
				),
				tasksList: removeId(
					complexityLevelDetails[complexity]?.taskList?.filter(
						(criteria) => criteria.name.trim() !== ""
					),
					'task'
				),
				isCritical: complexityLevelDetails[complexity].isCritical,
				secureByDefault: complexityLevelDetails[complexity].secureByDefault,
				isProcessStep: complexityLevelDetails[complexity].isProcessStep,

				isDeliverables: complexityLevelDetails[complexity].isDeliverables,
				isSecureByDefault: complexityLevelDetails[complexity].secureByDefault,
				isBusinessRules: complexityLevelDetails[complexity].isBusinessRules,

				groupGuid: complexityLevelDetails.groupGuid
					? complexityLevelDetails.groupGuid
					: null,
				activityVersion: complexityLevelDetails[complexity].activityVersion,
				hashTags: activityGeneralDetails['activityHashTags'].toString(),
				imagePaths: [
					...complexityLevelDetails[complexity].imagePaths,
					...imagesUrl,
				],
				// approverMembersList: activityGeneralDetails['approvers'].map(
				// 	({ mail }) => {
				// 		return mail;
				// 	}
				// ),
				approverMembersList: approvers.map(({ mail }) => mail),
				reviewerMembersList: reviewers.map(({ mail }) => mail),
				coOwnerMembersList: coOwner.map(({ mail }) => mail),
				owner: owner.mail,
				// reviewerMembersList: activityGeneralDetails['reviewers'].map(
				// 	({ mail }) => {
				// 		return mail;
				// 	}
				// ),
				// coOwnerMembersList: activityGeneralDetails['coOwner'].map(
				// 	({ mail }) => {
				// 		return mail;
				// 	}
				// ),
				// owner: activityGeneralDetails['owner'].mail,
				newVersion: isAdmin
					? getUpdatedVersion(complexity)
					: Math.floor(
						Number(complexityLevelDetails[complexity].activityVersion + 1)
					).toFixed(2),
			};
			if (
				type === 'publish' &&
				!complexityLevelDetails[complexity].description &&
				!complexityLevelDetails[complexity].taskList.length
			) {
				toast.error(
					<div>
						Please check all mandatory fields:
						<br />
						Activity Description
						<br />
						Tasks
					</div>
				);
				return;
			} else if (
				type === 'publish' &&
				(!complexityLevelDetails[complexity].description ||
					!complexityLevelDetails[complexity].taskList.length)
			) {
				toast.error(
					<div>
						Please check all mandatory fields:
						<br />
						{!complexityLevelDetails[complexity].description
							? 'Activity Description'
							: 'Tasks'}
					</div>
				);

				return;
			}

			const raciVaid = await getRaciErrorMessages(complexity);
			if (!raciVaid) {
				toast.error(
					` Please check the errors in adding the RACI for ${complexities.filter((x) => x.id === complexity)[0].name
					} Complexity`,
					{
						position: 'top-right',
					}
				);
				return;
			}
			postValue.push({ ...postData[complexity] });
		}
		setShowSaveSpinner(true);
		console.log('postValue', postValue);
		await axiosPostService(
			`api/sdmactivity/${type === 'draft' ? 'updatedraft' : 'publishactivities'
			}`,
			postValue
		).then((response) => {
			if (!response.data.status) {
				setApiError(true);
				setErrorMessage(response.data.errorMessage);
				exitLockMode();
			} else if (
				type === 'publish' &&
				response.data.notPublishedActivities?.length > 0
			) {
				setErrorMessage(
					`Activity not published for ${response.data.notPublishedActivities.map(
						({ complexityId }) =>
							complexities.find(({ id }) => id === complexityId).name
					)}  complexity`
				);
				exitLockMode();
			} else {
				for (const activity of response.data.draftedActivities) {
					track(
						1,
						902,
						activity.activityId ? activity.activityId : activity.id,
						activityGeneralDetails['activityTitle'],
						{
							phaseId: phaseId,
							type: 'Activity Draft',
							complexityId: activity.complexityIds,
						}
					);
				}
				setErrorMessage(
					type === 'draft'
						? 'You have successfully saved the activity'
						: 'You have successfully published the activity'
				);
				sessionStorage.setItem(
					'activityEditUploadedImages',
					JSON.stringify([])
				);
				getSolutionMethodData(coOwners, 'update');
			}
			setShowErrorMessageModal(true);
		});
		setShowSaveSpinner(false);
	};

	const getCriteriaTypes = async () => {
		const { data } = await axiosGetService(
			`api/sdmactivity/getallactivitycriterias`
		);
		setCriteriaTypes(data);
	};

	const setActivityTitle = (value) => {
		setActivityGeneralUpdatedDetails((prevState) => ({
			...prevState,
			activityTitle: value,
		}));
	};

	const getUpdatedVersion = (complexityId) => {
		let newVersion = (
			Number(complexityLevelDetails[complexityId]?.activityVersion) +
			complexityLevelDetails[complexityId]?.selectedVersionType.increment
		).toFixed(2);
		if (
			complexityLevelDetails[complexityId]?.selectedVersionType.id ===
			'majorVersion'
		)
			newVersion = Math.floor(newVersion).toFixed(2);
		return newVersion;
	};

	const setSelectedVersionType = (newVersionType) => {
		setComplexityLevelDetails((prevState) => {
			return {
				...prevState,
				[selectedComplexity]: {
					...prevState[selectedComplexity],
					selectedVersionType: newVersionType,
				},
			};
		});
	};

	const getRolesData = async () => {
		const { data } = await axiosGetService('api/sdmphase/getallroles');
		setRolesData(data);
	};

	const getBusinessRule = (data) => {
		setBusinessRuleContent(data)
	}

	useEffect(() => {
		getComplexityList();
		getActivityData();
		loadBreadcrumb();
		getCriteriaTypes();
		getProcessGroup();
		getRolesData();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTools();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [toolSearchValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTopics();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [topicSearchValue, topicCategoryId]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTopicsCategoryData();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [topicsCategorySearchValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getProcedures();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [procedureSearchValue, proceduresCategoryIds]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getProceduresCategoryData();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [proceduresCategorySearchValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTemplates();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [templateSearchValue, selectedComplexity]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phaseSearchValue]);

	useEffect(() => {
		const availableComplexityIds = complexityIds.split(',').map((item) => {
			return Number(item);
		});
		for (const complexity of complexities) {
			if (!availableComplexityIds.includes(complexity.id)) {
				createMockData(complexity.id, setComplexityLevelDetails);
				createMockData(complexity.id, setComplexityLevelUpdatedDetails);
			}
		}
	}, [complexities]);

	useEffect(() => {
		checkLockMode();
		return () => {
			if (pageLockUser === userAlias) {
				exitLockMode();
			}
		};
	}, [pageLockUser]);

	useEffect(() => {
		if (!publishComplexityList.includes(selectedComplexity)) {
			setSelectedComplexity(+defaultComplexity);
		}
	}, [publishComplexityList]);

	useEffect(() => {
		let timeOutId = 0;
		if (pageLockUser === userAlias) {
			timeOutId = setInterval(() => {
				enterLockMode();
			}, 300000);
		}
		return () => {
			clearInterval(timeOutId);
		};
	}, [pageLockUser]);

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (type && type.split(' ')[0] === 'image') {
				const imgElement = element.children[0];
				if (typeof $(element).attr('style') !== 'undefined') {
					$(imgElement).attr({ style: $(element).attr('style') });
				} else {
					$(imgElement).attr({ style: ' width: 100%' });
				}
			}
		});
	});

	if (showSpinner) {
		return <Spinner />;
	}
	// if (!isCoOwner && !isActivityOwnerOrCoOwner.includes(userAlias) && isUserValidated) {
	// 	return (
	// 		<AdminMessage
	// 			projectTypeId={projectTypeId}
	// 			solutionMethodId={solutionMethodId}
	// 			phaseId={phaseId}
	// 			parentPhaseId={parentPhaseId}
	// 		/>
	// 	);
	// }

	if (isPageLock || isActivityCrowdSourced) {
		return (
			<LockModalMessage
				showDiscardModal={true}
				setShowDiscardModal={setShowDiscardModal}
				projectTypeId={projectTypeId}
				solutionMethodId={solutionMethodId}
				phaseId={phaseId}
				parentPhaseId={parentPhaseId}
				isActivityCrowdSourced={isActivityCrowdSourced}
				lockEmailId={lockEmailId}
			/>
		);
	}
	console.log(publishComplexityList, 'publishComplexityList', complexities, 'complexities');
	console.log(activityGeneralDetails['approvers'], 'approvers123', activityGeneralDetails['reviewers'], 'reviewers', activityGeneralDetails['coOwner'], 'coOwner', activityGeneralDetails['owner'], 'owner');
	return (
		// <div class="newActivityDetailsMainDiv">
		// 	{showSaveSpinner ? <SaveSpinner /> : <></>}

		// 	<div id="previewSaveBanner" className="fixedPreviewSaveBanner">
		// <div className="activityEditBreadcrumb">
		// 	<Breadcrumbs />
		// </div>
		// 	<div className="activityEditSavePreviewButtons">
		// 		<div className="activityVersioningDiv topicBuilderHeaderVersionDiv"></div>

		// 		<button
		// 			onClick={() => {
		// 				setShowActivityPreview(true);
		// 				track(
		// 					1,
		// 					908,
		// 					activityId ? activityId : draftId,
		// 					activityGeneralDetails['activityTitle'],
		// 					{
		// 						complexityId: selectedComplexity,
		// 					}
		// 				);
		// 			}}
		// 		>
		// 			Preview
		// 		</button>
		// 		<button
		// 			onClick={() => {
		// 				setShowDiscardModal(true);
		// 				track(
		// 					1,
		// 					909,
		// 					activityId ? activityId : draftId,
		// 					activityGeneralDetails['activityTitle'],
		// 					{
		// 						complexityId: selectedComplexity,
		// 					}
		// 				);
		// 			}}
		// 		>
		// 			Discard
		// 		</button>
		// 		<div className="activityPublishSaveAsDraftButtons">
		// 			<div className="activityPublishSaveAsDraftUpdateButton">
		// 				<button
		// 					tabIndex="0"
		// 					aria-label="Publish"
		// 					disabled={availableComplexity.length !== isApiCalled.length}
		// 					onClick={() => {
		// 						if (showFields.length > 0) {
		// 							toast.error(
		// 								'Looks like you have not closed all sections, Please close all sections before saving',
		// 								{
		// 									position: toast.POSITION.TOP_RIGHT,
		// 								}
		// 							);
		// 						} else {
		// 							draftActivity('publish');
		// 						}
		// 					}}
		// 				>
		// 					Publish
		// 				</button>
		// 				<Icon
		// 					id="showDraftButtondropdown"
		// 					tabIndex={0}
		// 					aria-label={showDraftButton ? "Collapse Button" : "Expand Button"}
		// 					iconName={!showDraftButton ? 'ChevronDown' : 'ChevronUp'}
		// 					onClick={() => {
		// 						setShowDraftButton(!showDraftButton);
		// 					}}
		// 				/>
		// 			</div>
		// 			{showDraftButton && (
		// 				<button
		// 					tabIndex="0"
		// 					aria-label="Save as draft"
		// 					disabled={availableComplexity.length !== isApiCalled.length}
		// 					className="activitySaveasDraftDpButton"
		// 					onClick={() => {
		// 						if (showFields.length > 0) {
		// 							toast.error(
		// 								'Looks like you have not closed all sections, Please close all sections before saving',
		// 								{
		// 									position: toast.POSITION.TOP_RIGHT,
		// 								}
		// 							);
		// 						} else {
		// 							draftActivity('draft');
		// 						}
		// 					}}
		// 					onKeyUp={(ev) => {
		// 						if (ev.keyCode == 13) {
		// 							if (showFields.length > 0) {
		// 								toast.error(
		// 									'Looks like you have not closed all sections, Please close all sections before saving',
		// 									{
		// 										position: toast.POSITION.TOP_RIGHT,
		// 									}
		// 								);
		// 							} else {
		// 								draftActivity('draft');
		// 							}

		// 						}
		// 					}}
		// 				>
		// 					Save as draft
		// 				</button>
		// 			)}
		// 		</div>
		// 	</div>
		// </div>
		// 	<div className="newActivityDetailsContainer">
		// {showActivityPreview && (
		// 	<ActivityPreview
		// 		showActivityPreview={showActivityPreview}
		// 		setShowActivityPreview={setShowActivityPreview}
		// 		complexityLevelDetails={complexityLevelDetails}
		// 		selectedComplexity={selectedComplexity}
		// 		setSelectedComplexity={setSelectedComplexity}
		// 		activityTitle={activityGeneralDetails.activityTitle}
		// 		tools={selectedTools}
		// 		topics={selectedTopics}
		// 		procedures={selectedProcedures}
		// 		hashTags={activityGeneralDetails.activityHashTags}
		// 		businessRuleContent={businessRuleContent}
		// 	/>
		// )}
		// {showDiscardModal && (
		// 	<DiscardModal
		// 		showDiscardModal={showDiscardModal}
		// 		setShowDiscardModal={setShowDiscardModal}
		// 		projectTypeId={projectTypeId}
		// 		solutionMethodId={solutionMethodId}
		// 		phaseId={phaseId}
		// 		parentPhaseId={parentPhaseId}
		// 		activityId={activityId}
		//         complexityIds={complexityIds}
		// 		selectedComplexity={selectedComplexity}
		// 		draftId={draftId}
		// 	/>
		// )}
		// {showErrorMessageModal && (
		// 	<ErrorMessageModal
		// 		showErrorMessageModal={showErrorMessageModal}
		// 		setShowErrorMessageModal={setShowErrorMessageModal}
		// 		errorMessage={errorMessage}
		// 		setErrorMessage={setErrorMessage}
		// 		apiError={apiError}
		// 		setApiError={setApiError}
		// 		projectTypeId={projectTypeId}
		// 		solutionMethodId={solutionMethodId}
		// 		phaseId={phaseId}
		// 		parentPhaseId={parentPhaseId}
		// 	/>
		// )}

		// 		<div className="DetailsTab">
		// 			<h6>General Details</h6>
		// 			<div className="DetailsTabList">
		// <ActivityTitle
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	setActivityTitle={setActivityTitle}
		// 	activityGeneralDetails={activityGeneralDetails}
		// 	setActivityGeneralDetails={setActivityGeneralDetails}
		// 	activityGeneralUpdatedDetails={activityGeneralUpdatedDetails}
		// 	setActivityGeneralUpdatedDetails={
		// 		setActivityGeneralUpdatedDetails
		// 	}
		// />

		// <AddHashtags
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	activityGeneralDetails={activityGeneralDetails}
		// 	setActivityGeneralDetails={setActivityGeneralDetails}
		// 	activityGeneralUpdatedDetails={activityGeneralUpdatedDetails}
		// 	setActivityGeneralUpdatedDetails={
		// 		setActivityGeneralUpdatedDetails
		// 	}
		// />
		// 				<AddOwnersOrCoOwners
		// 				showFields={showFields}
		// 				handelFormView={handelFormView}
		// 				value={activityGeneralDetails}
		// 				setValue={setActivityGeneralDetails}
		// 				solutionMethodOwner={solutionMethodOwner}
		// 				existingCoOwners={existingCoOwners}
		// 				isCoOwner={isCoOwner}
		// 				/>

		// 				<AddCollaborators
		// 					showFields={showFields}
		// 					handelFormView={handelFormView}
		// 					value={activityGeneralDetails}
		// 					setValue={setActivityGeneralDetails}
		// 				/>

		// <AddTopics
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	data={topicsData}
		// 	value={selectedTopics}
		// 	setValue={setSelectedTopics}
		// 	enableDropdown={true}
		// 	className={'topicsDropdown'}
		// 	searchValue={topicSearchValue}
		// 	setSearchValue={setTopicSearchValue}
		// 	updatedValue={updatedTopics}
		// 	setUpdatedValue={setUpdatedTopics}
		// 	topicsMapper={topicsMapper}
		// 	topicCategoryId={topicCategoryId}
		// 	setTopicCategoryId={setTopicCategoryId}
		// 	proceduresData={proceduresData}
		// 	proceduresMapper={proceduresMapper}
		// 	selectedProcedures={selectedProcedures}
		// 	setSelectedProcedures={setSelectedProcedures}
		// 	proceduresCategoryIds={proceduresCategoryIds}
		// 	setProceduresCategoryIds={setProceduresCategoryIds}
		// 	updatedProcedures={updatedProcedures}
		// 	setUpdatedProcedures={setUpdatedProcedures}
		// 	topicsCategoryData={topicsCategoryData}
		// 	proceduresCategoryData={proceduresCategoryData}
		// 	topicsCategorySearchValue={topicsCategorySearchValue}
		// 	setTopicsCategorySearchValue={setTopicsCategorySearchValue}
		// 	proceduresCategorySearchValue={proceduresCategorySearchValue}
		// 	setProceduresCategorySearchValue={
		// 		setProceduresCategorySearchValue
		// 	}
		// 	procedureSearchValue={procedureSearchValue}
		// 	setProcedureSearchValue={setProcedureSearchValue}
		// />

		// <AddTools
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	data={toolsData}
		// 	value={selectedTools}
		// 	setValue={setSelectedTools}
		// 	enableDropdown={true}
		// 	className={'toolsDropdown'}
		// 	searchValue={toolSearchValue}
		// 	setSearchValue={setToolSearchValue}
		// 	updatedValue={updatedTools}
		// 	setUpdatedValue={setUpdatedTools}
		// 	toolsMapper={toolsMapper}
		// 	setToolsMapper={setToolsMapper}
		// 	getTools={getTools}
		// />
		// 			</div>
		// 		</div>
		// 		<div className="DetailsTab">
		// 			{isEnableComplexity && <h6>Complexity level details</h6>}
		// 			<div className="DetailsTabList">
		// {isEnableComplexity && <div className="Detailswrapper complexitySelection">
		// 	<ComplexitySelection
		// 		publishComplexityList={publishComplexityList}
		// 		setPublishComplexityList={setPublishComplexityList}
		// 		defaultComplexityId={Number(defaultComplexity)}
		// 		complexities={complexities}
		// 		activityName={activityGeneralDetails['activityTitle']}
		// 		complexityLevelDetails={complexityLevelDetails}
		// 		setComplexityLevelDetails={setComplexityLevelDetails}
		// 		complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 		setComplexityLevelUpdatedDetails={
		// 			setComplexityLevelUpdatedDetails
		// 		}
		// 		isApiCalled={isApiCalled}
		// 		setIsApiCalled={setIsApiCalled}
		// 		availableComplexity={availableComplexity}
		// 		setAvailableComplexity={setAvailableComplexity}
		// 		setSelectedComplexity={setSelectedComplexity}
		// 	/>
		// </div>}
		// 				<ul role="tablist" className="activityComplexityMenuWrapper" style={{backgroundColor:'white'}}>
		// 					{isEnableComplexity && publishComplexityList.map((item) => (
		// 						<li role="tab"
		// 							className={
		// 								selectedComplexity === item ? 'activeComplexity' : ''
		// 							}
		// 							tabIndex={0}
		// 							onClick={() => {
		// 								if (showFields.length > 0) {
		// 									toast.error(
		// 										'Looks like you have not closed all sections, Please close all sections before changing complexity',
		// 										{
		// 											position: toast.POSITION.TOP_RIGHT,
		// 										}
		// 									);
		// 								} else if (
		// 									isApiCalled.length === availableComplexity.length
		// 								) {
		// 									setSelectedComplexity(item);
		// 								} else {
		// 									setShowSpinner(true);
		// 									setSelectedComplexity(item);
		// 								}
		// 							}}
		// 							onKeyPress={(e) => {
		// 								if (e.which === 13) {
		// 									if (showFields.length > 0) {
		// 										toast.error(
		// 											'Looks like you have not closed all sections, Please close all sections before changing complexity',
		// 											{
		// 												position: toast.POSITION.TOP_RIGHT,
		// 											}
		// 										);
		// 									} else if (
		// 										isApiCalled.length === availableComplexity.length
		// 									) {
		// 										setSelectedComplexity(item);
		// 									} else {
		// 										setShowSpinner(true);
		// 										setSelectedComplexity(item);
		// 									}
		// 								}
		// 							}}
		// 							key={item}
		// 						>
		// 							{complexities.filter((x) => x.id === item)[0].name}
		// 						</li>
		// 					))}
		// 					<li className="activityVersioningLi">
		// 						{isAdmin &&
		// 							!complexityLevelDetails[selectedComplexity].newComplexity && (
		// 								<div className="activityVersioningDiv topicBuilderHeaderVersionDiv">
		// 									<p>
		// 										Current version :{' '}
		// 										{
		// 											complexityLevelDetails[selectedComplexity]
		// 												.activityVersion
		// 										}
		// 									</p>
		// 									<ul>
		// 										{versionTypes.map((item) => (
		// 											<li>
		// 												<label tabIndex="0" class="checkBoxContainer">
		// 													<span class="settingsLabel">{item.label}</span>
		// 													<input
		// 														type="radio"
		// 														checked={
		// 															complexityLevelDetails[selectedComplexity]
		// 																.selectedVersionType.id === item.id
		// 														}
		// 														onChange={() => {
		// 															setSelectedVersionType(item);
		// 														}}
		// 													/>
		// 													<span class="checkmark" title={item.label}></span>
		// 												</label>
		// 											</li>
		// 										))}
		// 									</ul>
		// 									<p>
		// 										New version : {getUpdatedVersion(selectedComplexity)}
		// 									</p>
		// 								</div>
		// 							)}
		// 					</li>
		// 				</ul>

		// 				<AssignRACI
		// 					showFields={showFields}
		// 					handelFormView={handelFormView}
		// 					selectedComplexity={selectedComplexity}
		// 					complexityLevelDetails={complexityLevelDetails}
		// 					setComplexityLevelDetails={setComplexityLevelDetails}
		// 					complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 					setComplexityLevelUpdatedDetails={
		// 						setComplexityLevelUpdatedDetails
		// 					}
		// 					reduceRoles={convertToString}
		// 					rolesData={rolesData}
		// 				/>
		// 				<TileDescription
		// 					showFields={showFields}
		// 					setshowFields={setshowFields}
		// 					handelFormView={handelFormView}
		// 					selectedComplexity={selectedComplexity}
		// 					complexityLevelDetails={complexityLevelDetails}
		// 					setComplexityLevelDetails={setComplexityLevelDetails}
		// 					complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 					setComplexityLevelUpdatedDetails={
		// 						setComplexityLevelUpdatedDetails
		// 					}
		// 				/>
		// <ActivityDescripion
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	selectedComplexity={selectedComplexity}
		// 	complexityLevelDetails={complexityLevelDetails}
		// 	setComplexityLevelDetails={setComplexityLevelDetails}
		// 	complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 	setComplexityLevelUpdatedDetails={
		// 		setComplexityLevelUpdatedDetails
		// 	}
		// />
		// <SupportingActivities
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	selectedComplexity={selectedComplexity}
		// 	complexityLevelDetails={complexityLevelDetails}
		// 	setComplexityLevelDetails={setComplexityLevelDetails}
		// 	complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 	setComplexityLevelUpdatedDetails={
		// 		setComplexityLevelUpdatedDetails
		// 	}
		// />
		// {criteriaTypes.length > 0 && (
		// 	<EntryCriteria
		// 		showFields={showFields}
		// 		setshowFields={setshowFields}
		// 		handelFormView={handelFormView}
		// 		selectedComplexity={selectedComplexity}
		// 		complexityLevelDetails={complexityLevelDetails}
		// 		setComplexityLevelDetails={setComplexityLevelDetails}
		// 		complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 		handelFormViewEditPerventClose={handelFormViewEditPerventClose}
		// 		setComplexityLevelUpdatedDetails={
		// 			setComplexityLevelUpdatedDetails
		// 		}
		// 		criteriaTypes={criteriaTypes}
		// 	/>
		// )}

		// <Tasks
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	handelFormViewEdit={handelFormViewEdit}
		// 	handelFormViewEditPerventClose={handelFormViewEditPerventClose}
		// 	selectedComplexity={selectedComplexity}
		// 	complexityLevelDetails={complexityLevelDetails}
		// 	setComplexityLevelDetails={setComplexityLevelDetails}
		// 	complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 	setComplexityLevelUpdatedDetails={
		// 		setComplexityLevelUpdatedDetails
		// 	}
		// />

		// {criteriaTypes.length > 0 && (
		// 	<ExitCriteria
		// 		showFields={showFields}
		// 		setshowFields={setshowFields}
		// 		handelFormView={handelFormView}
		// 		selectedComplexity={selectedComplexity}
		// 		complexityLevelDetails={complexityLevelDetails}
		// 		setComplexityLevelDetails={setComplexityLevelDetails}
		// 		complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 		handelFormViewEditPerventClose={handelFormViewEditPerventClose}
		// 		setComplexityLevelUpdatedDetails={
		// 			setComplexityLevelUpdatedDetails
		// 		}
		// 		criteriaTypes={criteriaTypes}
		// 	/>
		// )}

		// <Deliverables
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	selectedComplexity={selectedComplexity}
		// 	complexityLevelDetails={complexityLevelDetails}
		// 	setComplexityLevelDetails={setComplexityLevelDetails}
		// 	complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 	setComplexityLevelUpdatedDetails={
		// 		setComplexityLevelUpdatedDetails
		// 	}
		// 	data={templatesData}
		// 	value={complexityLevelDetails}
		// 	setValue={setComplexityLevelDetails}
		// 	enableDropdown={true}
		// 	className={'templatesList'}
		// 	searchValue={templateSearchValue}
		// 	setSearchValue={setTemplateSearchValue}
		// 	updatedValue={complexityLevelUpdatedDetails}
		// 	setUpdatedValue={setComplexityLevelUpdatedDetails}
		// 	templatesMapper={templatesMapper}
		// 	complexityList={complexities}
		// 	phases={phases}
		// 	phaseSearchValue={phaseSearchValue}
		// 	setPhaseSearchvalue={setPhaseSearchvalue}
		// 	setTemplatesMapper={setTemplatesMapper}
		// />
		// 				<BusinessRule
		// 				showFields={showFields}
		// 				setshowFields={setshowFields}
		// 				handelFormView={handelFormView}
		// 				handelFormViewEdit={handelFormViewEdit}
		// 				handelFormViewEditPerventClose={handelFormViewEditPerventClose}
		// 				selectedComplexity={selectedComplexity}
		// 				complexityLevelDetails={complexityLevelDetails}
		// 				setComplexityLevelDetails={setComplexityLevelDetails}
		// 				complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 				setComplexityLevelUpdatedDetails={
		// 					setComplexityLevelUpdatedDetails
		// 				}
		// 				activityId={activityId}
		//                 complexityIds={complexityIds}
		//                 defaultComplexity={defaultComplexity}
		// 				showDiscardModal={showDiscardModal}
		// 				draftId={draftId}
		// 				getBusinessRule={getBusinessRule}
		// 				/>
		// 				<TipsAndTechniques
		// 					showFields={showFields}
		// 					setshowFields={setshowFields}
		// 					handelFormView={handelFormView}
		// 					selectedComplexity={selectedComplexity}
		// 					complexityLevelDetails={complexityLevelDetails}
		// 					setComplexityLevelDetails={setComplexityLevelDetails}
		// 					complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 					setComplexityLevelUpdatedDetails={
		// 						setComplexityLevelUpdatedDetails
		// 					}
		// 				/>						

		// <InputsForSMM
		// 	showFields={showFields}
		// 	setshowFields={setshowFields}
		// 	handelFormView={handelFormView}
		// 	selectedComplexity={selectedComplexity}
		// 	complexityLevelDetails={complexityLevelDetails}
		// 	setComplexityLevelDetails={setComplexityLevelDetails}
		// 	complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
		// 	setComplexityLevelUpdatedDetails={
		// 		setComplexityLevelUpdatedDetails
		// 	}
		// 	processGroup={processGroup}
		// 	processGroupMap={processGroupMap}
		// />
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>

		<div className='ActivityEditMainDiv'>
			<div className='row borderStyle breadCrumbContainer'>
				<div className='col-xl-12'>
					<Breadcrumbs />
				</div>
			</div>
			<div className='row borderStyle activityButtonContainer'>
				<div className='col-xl-12'>
					<div className="activityEditButtons">

						<button
							className='activityButton'
							onClick={() => {
								setShowActivityPreview(true);
								track(
									1,
									908,
									activityId ? activityId : draftId,
									activityGeneralDetails['activityTitle'],
									{
										complexityId: selectedComplexity,
									}
								);
							}}
						>
							Preview
						</button>
						<button
							className='activityButton'
							onClick={() => {
								setShowDiscardModal(true);
								track(
									1,
									909,
									activityId ? activityId : draftId,
									activityGeneralDetails['activityTitle'],
									{
										complexityId: selectedComplexity,
									}
								);
							}}
						>
							Discard
						</button>

						<span className="activityPublishSaveAsDraftButtons">
							<span className="activityPublishButton">
								<button
									tabIndex="0"
									aria-label="Publish"
									disabled={availableComplexity.length !== isApiCalled.length}
									onClick={() => {
										// if (showFields.length > 0) {
										// 	toast.error(
										// 		'Looks like you have not closed all sections, Please close all sections before saving',
										// 		{
										// 			position: toast.POSITION.TOP_RIGHT,
										// 		}
										// 	);
										// } else {
										draftActivity('publish');
										// }
									}}
								>
									Publish
								</button>
								<Icon
									id="showDraftButtondropdown"
									tabIndex={0}
									aria-label={showDraftButton ? "Collapse Button" : "Expand Button"}
									iconName={!showDraftButton ? 'ChevronDown' : 'ChevronUp'}
									onClick={() => {
										setShowDraftButton(!showDraftButton);
									}}
								/>
							</span>
							{showDraftButton && (
								<button
									tabIndex="0"
									aria-label="Save as draft"
									disabled={availableComplexity.length !== isApiCalled.length}
									className="activityeditSaveasDraftDpButton"
									onClick={() => {

										draftActivity('draft');

									}}
									onKeyUp={(ev) => {
										if (ev.keyCode == 13) {

											draftActivity('draft');


										}
									}}
								>
									Save as draft
								</button>
							)}
						</span>
					</div>
				</div>
			</div>
			<div className='activityEditContentDiv'>
				{showSaveSpinner ? <SaveSpinner /> : <></>}
				<div className='row activityFirstContainer'>
					<div className='col-xl-9 activityFirstLeftContainer pb-3'>
						<div>
							<div className='row borderStyle'>
								<div className='col-xl-12'>
									<div className='containerTitle'>
										<h6>General Details</h6>
									</div>

								</div>
							</div>
							<div className='row pt-2 pb-2'>
								{showActivityPreview && (
									<ActivityPreview
										showActivityPreview={showActivityPreview}
										setShowActivityPreview={setShowActivityPreview}
										complexityLevelDetails={complexityLevelDetails}
										selectedComplexity={selectedComplexity}
										setSelectedComplexity={setSelectedComplexity}
										activityTitle={activityGeneralDetails.activityTitle}
										tools={selectedTools}
										topics={selectedTopics}
										procedures={selectedProcedures}
										hashTags={activityGeneralDetails.activityHashTags}
										businessRuleContent={businessRuleContent}
									/>
								)}
								{showDiscardModal && (
									<DiscardModal
										showDiscardModal={showDiscardModal}
										setShowDiscardModal={setShowDiscardModal}
										projectTypeId={projectTypeId}
										solutionMethodId={solutionMethodId}
										phaseId={phaseId}
										parentPhaseId={parentPhaseId}
										activityId={activityId}
										complexityIds={complexityIds}
										selectedComplexity={selectedComplexity}
										draftId={draftId}
									/>
								)}
								{showErrorMessageModal && (
									<ErrorMessageModal
										showErrorMessageModal={showErrorMessageModal}
										setShowErrorMessageModal={setShowErrorMessageModal}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										apiError={apiError}
										setApiError={setApiError}
										projectTypeId={projectTypeId}
										solutionMethodId={solutionMethodId}
										phaseId={phaseId}
										parentPhaseId={parentPhaseId}
									/>
								)}
								<div className='col-lg-2 col-xl-2 activityLabelstoplabels'>
									Activity Name <span className='red-asterisk'>*</span>
								</div>

								<div className='col-lg-10 col-xl-10'>
									<ActivityTitle
										showFields={showFields}
										setshowFields={setshowFields}
										handelFormView={handelFormView}
										setActivityTitle={setActivityTitle}
										activityGeneralDetails={activityGeneralDetails}
										setActivityGeneralDetails={setActivityGeneralDetails}
										activityGeneralUpdatedDetails={activityGeneralUpdatedDetails}
										setActivityGeneralUpdatedDetails={
											setActivityGeneralUpdatedDetails
										}
									/>
									{/* <input
										className="NewActivityTitle"
										value={activityGeneralUpdatedDetails['activityTitle']}
										onChange={(e) => {
											setActivityTitle(e.target.value);
										}}
										placeholder="Enter activity title"
										autoFocus={true}
									/>
									{activityGeneralUpdatedDetails['activityTitle'].trim().length ===
										0 ? (
										<p>Title is required</p>
									) : (
										<></>
									)}
									{activityGeneralUpdatedDetails['activityTitle'].length >
										titleLength ? (
										<p>Name length should not exceeds 120</p>
									) : (
										<></>
									)}
									{activityGeneralUpdatedDetails['activityTitle'] &&
										!activityTitleValidator.test(
											activityGeneralUpdatedDetails['activityTitle']
										) ? (
										<p className="red-asterisk">Invalid name</p>
									) : (
										<></>
									)} */}
								</div>

							</div>
							<div className='row pb-2'>
								<div className='col-lg-2 col-xl-2 activityLabelstoplabels'>
									Activity Hashtags
								</div>
								<div className='col-lg-10 col-xl-10'>
									<AddHashtags
										showFields={showFields}
										setshowFields={setshowFields}
										handelFormView={handelFormView}
										activityGeneralDetails={activityGeneralDetails}
										setActivityGeneralDetails={setActivityGeneralDetails}
										activityGeneralUpdatedDetails={activityGeneralUpdatedDetails}
										setActivityGeneralUpdatedDetails={
											setActivityGeneralUpdatedDetails
										}
									/>

								</div>
							</div>
							<div className='row pb-2'>
								<div className='col-lg-2 col-xl-2 activityLabelstoplabels'>
									Add Topics & Job Aids
								</div>
								<div className='col-lg-10 col-xl-10'>
									<AddTopics
										showFields={showFields}
										setshowFields={setshowFields}
										handelFormView={handelFormView}
										data={topicsData}
										value={selectedTopics}
										setValue={setSelectedTopics}
										enableDropdown={true}
										className={'topicsDropdown'}
										searchValue={topicSearchValue}
										setSearchValue={setTopicSearchValue}
										updatedValue={updatedTopics}
										setUpdatedValue={setUpdatedTopics}
										topicsMapper={topicsMapper}
										topicCategoryId={topicCategoryId}
										setTopicCategoryId={setTopicCategoryId}
										proceduresData={proceduresData}
										proceduresMapper={proceduresMapper}
										selectedProcedures={selectedProcedures}
										setSelectedProcedures={setSelectedProcedures}
										proceduresCategoryIds={proceduresCategoryIds}
										setProceduresCategoryIds={setProceduresCategoryIds}
										updatedProcedures={updatedProcedures}
										setUpdatedProcedures={setUpdatedProcedures}
										topicsCategoryData={topicsCategoryData}
										proceduresCategoryData={proceduresCategoryData}
										topicsCategorySearchValue={topicsCategorySearchValue}
										setTopicsCategorySearchValue={setTopicsCategorySearchValue}
										proceduresCategorySearchValue={proceduresCategorySearchValue}
										setProceduresCategorySearchValue={
											setProceduresCategorySearchValue
										}
										procedureSearchValue={procedureSearchValue}
										setProcedureSearchValue={setProcedureSearchValue}
									/>

								</div>
							</div>
							<div className='row pb-3'>
								<div className='col-lg-2 col-xl-2 activityLabelstoplabels'>
									Add Tools
								</div>
								<div className='col-lg-10 col-xl-10'>
									<AddTools
										showFields={showFields}
										setshowFields={setshowFields}
										handelFormView={handelFormView}
										data={toolsData}
										value={selectedTools}
										setValue={setSelectedTools}
										enableDropdown={true}
										className={'toolsDropdown'}
										searchValue={toolSearchValue}
										setSearchValue={setToolSearchValue}
										updatedValue={updatedTools}
										setUpdatedValue={setUpdatedTools}
										toolsMapper={toolsMapper}
										setToolsMapper={setToolsMapper}
										getTools={getTools}
									/>
								</div>

							</div>

						</div>

					</div>
					<div className='col-xl-3 activityFirstRightContainer pb-3'>
						<div>
							<div className='row borderStyle activityOwnersLabel'>
								<div className='col-xl-12'>
									<div className='containerTitle'>
										<h6>Owner/Collaborators</h6>
									</div>

								</div>
							</div>
							<div className='row'>

								<div className='addPolygonTopicRightFields'>
									<div className='addPolygonTopicRightFieldsDiv pt-2 pb-0'>
										<span className='addEditRightLabel'>
											{isAdmin || isCoOwner ? 'Add Owner' : 'Owner'}
										</span>
										{(isAdmin || isCoOwner) &&
											<OwnerSelectionDropdown
												className="owner"
												collaboratorDropDownList='collaboratorDropDownList'
												ownerRadioButton='CustomCheckmarkActivityOwnerCreation'
												enableDropdown={true}
												data={usersList}
												value={owner}
												setValue={setOwner}
												type="owner"
												searchValue={activityOwnerEmail}
												setSearchValue={setActivityOwnerEmail}
												error={error}
												emailFound={emailFound["owner"]}
											/>}
										{owner.mail ? (
											<>

												<ul
													className={
														showFields.includes('owner')
															? ' afterSaveList beforEditSavedList'
															: 'afterSaveListTopic collaboratorsApprovers mb-0 '
													}
												>
													<li key={owner.mail} className={
														`${(isAdmin || isCoOwner) ? 'editusersdropdown mt-1' : ' editusersdropdown mt-1 usercolorchnageedit'}`
													}>
														<span className="collabaratorPersonaImage">
															<img src={owner.photo} alt="img" />
														</span>
														<span className="collabaratorPersonaTitle mr-1">
															{owner.firstName + ' ' + owner.surname}
														</span>
														{(isAdmin || isCoOwner) ? (
															<span>
																<Icon
																	iconName="Cancel"
																	className='acitivityOwnerRemove'
																	onClick={() => {
																		setOwner({});
																	}}
																	tabIndex={0}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			setOwner({});
																		}
																	}}
																	role="button"
																	aria-label={`Remove ${activityOrMethodOwner.firstName} ${activityOrMethodOwner.surname}`}
																	title="Remove approver"
																/>
															</span>
														) : (
															<></>
														)}
													</li>

												</ul>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='addPolygonTopicRightFields'>
									<div className='addPolygonTopicRightFieldsDiv pt-2 pb-0'>
										<span className='addEditRightLabel'>
											{!(coOwner?.some((owner) => owner?.mail === userAlias)) || isAdmin || isCoOwner || userAlias === owner?.mail ? 'Add Co-owner' : 'Co-owner'}
										</span>
										{!(coOwner?.some((owner) => owner?.mail === userAlias)) || isAdmin || isCoOwner || userAlias === owner?.mail ? <UserSelectionDropdown
											className="coOwner"
											enableDropdown={true}
											data={usersList}
											value={coOwner}
											setValue={setCoOwner}
											type="coOwner"
											searchValue={coOwnerEmail}
											setSearchValue={setCoOwnerEmail}
											error={error}
											emailFound={emailFound['coOwner']}
											pageType={"Activity"}
										/> : <></>}
										{coOwner.length > 0 ? (
											<>
												<ul
													className={
														showFields.includes('owner')
															? ' afterSaveList beforEditSavedList'
															: 'afterSaveListTopic collaboratorsApprovers mb-0'
													}
												>
													{coOwner?.map((user) => (
														<li key={user.mail} className={
															`${!(coOwner?.some((owner) => owner?.mail === userAlias)) || isAdmin || isCoOwner || userAlias === owner?.mail ? 'editusersdropdown mt-1 ' : 'editusersdropdown mt-1 usercolorchnageedit'}`
														}>
															<span className="collabaratorPersonaImage">
																<img src={user.photo} alt="img" />
															</span>
															<span className="collabaratorPersonaTitle mr-1">
																{user.firstName + ' ' + user.surname}
															</span>
															{/* {showFields.includes('owner') ? ( */}
															{!(coOwner?.some((owner) => owner?.mail === userAlias)) || isAdmin || isCoOwner || userAlias === owner?.mail ? (
																<span>
																	<Icon
																		iconName="Cancel"
																		className='acitivityOwnerRemove'
																		onClick={() => {
																			removeUser(user.mail, coOwner, setCoOwner);
																		}}
																		tabIndex={0}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				removeUser(user.mail, coOwner, setCoOwner);
																			}
																		}}
																		role="button"
																		aria-label={`Remove ${user.firstName} ${user.surname}`}
																		title="Remove reviewer"
																	/>
																</span>) : (<></>)}
															{/* ) : (
																<></>
															)} */}
														</li>
													))}
												</ul>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='addPolygonTopicRightFields'>
									<div className='addPolygonTopicRightFieldsDiv pt-2 pb-0'>
										<span className='addEditRightLabel'>
											Add Approvers
										</span>
										<UserSelectionDropdown
											className="approver"
											enableDropdown={true}
											data={usersList}
											value={approvers}
											setValue={setApprovers}
											type="approver"
											searchValue={approverEmail}
											setSearchValue={setApproverEmail}
											error={error}
											emailFound={emailFound['approver']}
											pageType={"Activity"}
										/>
										{approvers.length > 0 ? (
											<>
												<ul
													className={
														showFields.includes('collaborators')
															? ' afterSaveList beforEditSavedList'
															: 'afterSaveListTopic collaboratorsApprovers mb-0'
													}
												>
													{approvers.map((user) => (
														<li key={user.mail} className='editusersdropdown mt-1'>
															<span className="collabaratorPersonaImage">
																<img src={user.photo} alt="img" />
															</span>
															<span className="collabaratorPersonaTitle mr-1">
																{user.firstName + ' ' + user.surname}
															</span>
															{/* {showFields.includes('collaborators') ? ( */}
															<span>
																<Icon
																	iconName="Cancel"
																	className='acitivityOwnerRemove'
																	onClick={() => {
																		removeUser(user.mail, approvers, setApprovers);
																	}}
																	tabIndex={0}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			removeUser(user.mail, approvers, setApprovers);
																		}
																	}}
																	role="button"
																	aria-label={`Remove ${user.firstName} ${user.surname}`}
																	title="Remove approver"
																/>
															</span>
															{/* ) : (
																<></>
															)} */}
														</li>
													))}
												</ul>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='addPolygonTopicRightFields'>
									<div className='addPolygonTopicRightFieldsDiv  pt-2 pb-0'>
										<span className='addEditRightLabel'>
											Add Reviewers
										</span>
										<UserSelectionDropdown
											className="reviewer"
											enableDropdown={true}
											data={usersList}
											value={reviewers}
											setValue={setReviewers}
											type="reviewer"
											searchValue={reviewerEmail}
											setSearchValue={setReviewerEmail}
											error={error}
											emailFound={emailFound['reviewer']}
											pageType={"Activity"}
										/>
										{reviewers.length > 0 ? (
											<>

												<ul
													className={
														showFields.includes('collaborators')
															? ' afterSaveList beforEditSavedList'
															: 'afterSaveListTopic collaboratorsApprovers mb-0'
													}
												>
													{reviewers.map((user) => (
														<li key={user.mail} className='editusersdropdown mt-1'>
															<span className="collabaratorPersonaImage">
																<img src={user.photo} alt="img" />
															</span>
															<span className="collabaratorPersonaTitle mr-1">
																{user.firstName + ' ' + user.surname}
															</span>
															{/* {showFields.includes('collaborators') ? ( */}
															<span>
																<Icon
																	iconName="Cancel"
																	className='acitivityOwnerRemove'
																	onClick={() => {
																		removeUser(user.mail, reviewers, setReviewers);
																	}}
																	tabIndex={0}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			removeUser(user.mail, reviewers, setReviewers);
																		}
																	}}
																	role="button"
																	aria-label={`Remove ${user.firstName} ${user.surname}`}
																	title="Remove reviewer"
																/>
															</span>
															{/* ) : (
																<></>
															)} */}
														</li>
													))}
												</ul>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>


						</div>

					</div>
				</div>
				<div className='activityEditContentDiv' >
					<div className=' row activityFirstContainer'>
						<div className='col-xl-9 activityFirstLeftContainer pb-3'>
							<div>
								<div className='row '>
									<div className='col-xl-8'>
										<div className='containerTitle'>
											<h6>Complexity level details</h6>
										</div>

									</div>
									<div className='col-xl-4 d-flex justify-content-end'>

										{isEnableComplexity && <div className="EditComplexityBtn">
											<ComplexitySelection
												publishComplexityList={publishComplexityList}
												setPublishComplexityList={setPublishComplexityList}
												defaultComplexityId={Number(defaultComplexity)}
												complexities={complexities}
												activityName={activityGeneralDetails['activityTitle']}
												complexityLevelDetails={complexityLevelDetails}
												setComplexityLevelDetails={setComplexityLevelDetails}
												complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
												setComplexityLevelUpdatedDetails={
													setComplexityLevelUpdatedDetails
												}
												isApiCalled={isApiCalled}
												setIsApiCalled={setIsApiCalled}
												availableComplexity={availableComplexity}
												setAvailableComplexity={setAvailableComplexity}
												setSelectedComplexity={setSelectedComplexity}
												selectedComplexity={selectedComplexity}
												complexityIds={complexityIds}
											/>
										</div>}
									</div>
								</div>
								<div className='row borderStyle'>
									<div className="col-xl-12 complexitiesmaindiv">
										<ul
											role="tablist"
											className="editactivityComplexityMenuWrapper pr-0"
											style={{ backgroundColor: 'white' }}
										>
											{isEnableComplexity &&
												publishComplexityList.map((item) => (
													<li
														role="tab"
														className={selectedComplexity === item ? 'activeComplexity' : ''}
														tabIndex={0}
														onClick={() => setSelectedComplexity(item)}
														onKeyPress={() => setSelectedComplexity(item)}
														key={item}
													>
														{complexities.filter((x) => x.id === item)[0].name}
													</li>
												))}
										</ul>

										<div className="activityVersioningLi pl-0">
											{isAdmin &&
												!complexityLevelDetails[selectedComplexity].newComplexity && (
													<div
														className={`activityVersioningDiv acitivityHeaderVersionDiv${publishComplexityList.length <= 1 ? ' mediumstylecomponent' : ''
															}`}
													>
														<span className='complexityrightsidespans mr-2'>
															Current Version:{' '}
															{complexityLevelDetails[selectedComplexity].activityVersion}
														</span>
														<span className='complexityrightsidespans'>
															<ul>
																{versionTypes.map((item) => (
																	<li key={item.id} className='ml-2 mr-2 pl-3'>
																		<label tabIndex="0" className="checkBoxContaineredit mb-0">
																			<span className="settingsLabel">{item.label}</span>
																			<input
																			className='editacitivityCheckmarkradio'
																				type="radio"
																				checked={
																					complexityLevelDetails[selectedComplexity]
																						.selectedVersionType.id === item.id
																				}
																				onChange={() => setSelectedVersionType(item)}
																			/>
																			<span
																				className="editacitivityCheckmark"
																				title={item.label}
																			></span>
																		</label>
																	</li>
																))}
															</ul>
														</span>
														<span className='complexityrightsidespans ml-2'>New Version: {getUpdatedVersion(selectedComplexity)}</span>
													</div>
												)}
										</div>
									</div>

								</div>

								<div className='row pb-2 pt-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Activity Description <span className='red-asterisk'>*</span>
									</div>
									<div className='col-xl-10'>
										<ActivityDescripion
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
										/>
									</div>
								</div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Entry Criteria/Input
										<span className="messageOfEntryExit">
											<span className="descOfMessageOfEntryExit">
												<span
													className="messageOfEntryExitIcon mr-0"
													tabIndex="0"
													role="button"
													aria-label="Entry criteria information"
													aria-labelledby="Entry criteria information"
												></span>
												<span
													className="messageOfEntryExitHoverIcon"
													tabIndex="0"
													role="button"
													aria-label="Entry criteria information"
													aria-labelledby="Entry criteria information"
												></span>
											</span>
											<div class="showMoreMenu">
												<span class="showMoreArrow"></span>
												<ul class="menuItemUL">
													<li>
														<b>Entry Criteria</b> Entry Criteria that must be met before
														initiating an activity E.g.: "All test cases drafted" before
														"test case execution"
													</li>
													<li>
														<b>Input</b> Work Product or event that is required to perform
														this activity E.g.: Draft Conditions of Satisfaction for
														Internal Kickoff Meeting
													</li>
												</ul>
											</div>
										</span>
									</div>
									<div className='col-xl-10'>
										{criteriaTypes.length > 0 && (
											<EntryCriteria
												showFields={showFields}
												setshowFields={setshowFields}
												handelFormView={handelFormView}
												selectedComplexity={selectedComplexity}
												complexityLevelDetails={complexityLevelDetails}
												setComplexityLevelDetails={setComplexityLevelDetails}
												complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
												handelFormViewEditPerventClose={handelFormViewEditPerventClose}
												setComplexityLevelUpdatedDetails={
													setComplexityLevelUpdatedDetails
												}
												criteriaTypes={criteriaTypes}
											/>
										)}
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Tasks <span className='red-asterisk'>*</span>
									</div>
									<div className='col-xl-10'>
										<Tasks
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											handelFormViewEdit={handelFormViewEdit}
											handelFormViewEditPerventClose={handelFormViewEditPerventClose}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
										/>
									</div>
								</div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Deliverables
									</div>
									<div className='col-xl-10'>
										<Deliverables
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
											data={templatesData}
											value={complexityLevelDetails}
											setValue={setComplexityLevelDetails}
											enableDropdown={true}
											className={'templatesList'}
											searchValue={templateSearchValue}
											setSearchValue={setTemplateSearchValue}
											updatedValue={complexityLevelUpdatedDetails}
											setUpdatedValue={setComplexityLevelUpdatedDetails}
											templatesMapper={templatesMapper}
											complexityList={complexities}
											phases={phases}
											phaseSearchValue={phaseSearchValue}
											setPhaseSearchvalue={setPhaseSearchvalue}
											setTemplatesMapper={setTemplatesMapper}
										/>
									</div>
								</div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Exit Criteria/Output
										<span className="messageOfEntryExit">
											<span className="descOfMessageOfEntryExit">
												<span
													className="messageOfEntryExitIcon mr-0"
													tabIndex="0"
													role="button"
													aria-label="Exit criteria information"
													aria-labelledby="Exit criteria information"
												></span>
												<span
													className="messageOfEntryExitHoverIcon"
													tabIndex="0"
													role="button"
													aria-label="Exit criteria information"
													aria-labelledby="Exit criteria information"
												></span>
											</span>
											<div class="showMoreMenu">
												<span class="showMoreArrow"></span>
												<ul class="menuItemUL">
													<li>
														<b>Exit Criteria</b> Are the Criteria or requirements which must
														be met before completing a specific task or a process.. E.g.:
														Project Closure Document once the project is closed
													</li>
													<li>
														<b>Output</b> Work Product or Decision or trigger for performing
														the activity. E.g.: Go/No Go Decision after UAT Demo
													</li>
												</ul>
											</div>
										</span>



									</div>
									<div className='col-xl-10'>
										{criteriaTypes.length > 0 && (
											<ExitCriteria
												showFields={showFields}
												setshowFields={setshowFields}
												handelFormView={handelFormView}
												selectedComplexity={selectedComplexity}
												complexityLevelDetails={complexityLevelDetails}
												setComplexityLevelDetails={setComplexityLevelDetails}
												complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
												handelFormViewEditPerventClose={handelFormViewEditPerventClose}
												setComplexityLevelUpdatedDetails={
													setComplexityLevelUpdatedDetails
												}
												criteriaTypes={criteriaTypes}
											/>
										)}

									</div>
								</div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Guidance, Tips and Techniques
									</div>
									<div className='col-xl-10'>
										<TipsAndTechniques
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
										/>

									</div>
								</div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
										Supporting Activity
									</div>
									<div className='col-xl-10'>
										<SupportingActivities
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
										/>
									</div></div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>
									Activity Attributes
									</div>
									<div className='col-xl-10'>
										<InputsForSMM
											showFields={showFields}
											setshowFields={setshowFields}
											handelFormView={handelFormView}
											selectedComplexity={selectedComplexity}
											complexityLevelDetails={complexityLevelDetails}
											setComplexityLevelDetails={setComplexityLevelDetails}
											complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
											setComplexityLevelUpdatedDetails={
												setComplexityLevelUpdatedDetails
											}
											processGroup={processGroup}
											processGroupMap={processGroupMap}
										/>
									</div></div>
								<div className='row pb-2'>
									<div className='col-lg-2 col-xl-2 activityLabels'>

										Process Group

									</div>
									<div className='col-xl-10 pl-0'>
										<ProcessGroupDropdown
											className="processGroupDropdown"
											enableDropdown={true}
											data={processGroup}
											value={complexityLevelDetails}
											setValue={setComplexityLevelDetails}
											processGroupMap={processGroupMap}
											selectedComplexity={selectedComplexity}
											pageType={"Activity"}
										/>
									</div></div>


							</div>
						</div>
						<div className='col-xl-3 activityFirstRightContainer pb-3'>

							<div>
								<div className='row borderStyle activityOwnersLabel'>
									<div className='col-xl-12'>
										<div className='containerTitle'>
											<h6>Assign RACI</h6>
										</div>

									</div>
								</div>
								{/* raci filter */}
								<div className='row racidropdowncontainer'>

									<div className="col-xl-12 ">
										<h6 className='RaciTitles'>Responsible Roles</h6>
									</div>
									<div className="col-xl-12">
										<RaciDropdown
											className="responsibleRolesList"
											enableDropdown={true}
											data={rolesData}
											complexityId={selectedComplexity}
											// updatedValue={complexityLevelUpdatedDetails}
											// // setUpdatedValue={setComplexityLevelUpdatedDetails}
											// setUpdatedValue={handleDropdownSelection}
											updatedValue={complexityLevelUpdatedDetails}
											setUpdatedValue={(newState) => {
												setComplexityLevelUpdatedDetails(newState);
											}}
											type="responsibleList"
											key="responsibleList"
											pageType={"Activity"}
										/>
									</div>
									<div className="col-xl-12">
										<RemoveRaci
											complexityLevelDetails={complexityLevelUpdatedDetails}
											setComplexityLevelDetails={setComplexityLevelUpdatedDetails}
											allowEdit={true}
											type="responsibleList"
											selectedComplexity={selectedComplexity}
											key="responsibleListRemove"
										/>
									</div>
								</div>
								<div className='row racidropdowncontainer'>

									<div className="col-xl-12">
										<h6 className='RaciTitles'>Accountable Roles</h6>
									</div>
									<div className="col-xl-12">
										<RaciDropdown
											className="accountableRolesList"
											enableDropdown={true}
											data={rolesData}
											complexityId={selectedComplexity}
											// updatedValue={complexityLevelUpdatedDetails}
											// // setUpdatedValue={setComplexityLevelUpdatedDetails}
											// setUpdatedValue={handleDropdownSelection}
											updatedValue={complexityLevelUpdatedDetails}
											setUpdatedValue={(newState) => {
												setComplexityLevelUpdatedDetails(newState);
											}}
											type="accountableList"
											key="accountableList"
											pageType={"Activity"}
										/>
									</div>
									<div className="col-xl-12">
										<RemoveRaci
											complexityLevelDetails={complexityLevelUpdatedDetails}
											setComplexityLevelDetails={setComplexityLevelUpdatedDetails}
											allowEdit={true}
											type="accountableList"
											selectedComplexity={selectedComplexity}
											key="accountableListRemove"
										/>
									</div>
								</div>
								<div className='row racidropdowncontainer'>

									<div className="col-xl-12">
										<h6 className='RaciTitles'>Consulted Roles</h6>
									</div>
									<div className="col-xl-12">
										<RaciDropdown
											className="consultedRolesList"
											enableDropdown={true}
											data={rolesData}
											complexityId={selectedComplexity}
											// updatedValue={complexityLevelUpdatedDetails}
											// // setUpdatedValue={setComplexityLevelUpdatedDetails}
											// setUpdatedValue={handleDropdownSelection}
											updatedValue={complexityLevelUpdatedDetails}
											setUpdatedValue={(newState) => {
												setComplexityLevelUpdatedDetails(newState);
											}}

											type="consultedList"
											key="consultedList"
											pageType={"Activity"}
										/>
									</div>
									<div className="col-xl-12">
										<RemoveRaci
											complexityLevelDetails={complexityLevelUpdatedDetails}
											setComplexityLevelDetails={setComplexityLevelUpdatedDetails}
											allowEdit={true}
											type="consultedList"
											selectedComplexity={selectedComplexity}
											key="consultedListRemove"
										/>
									</div>
								</div>
								<div className='row racidropdowncontainer racidropdowndowncontainer'>

									<div className="col-xl-12">
										<h6 className='RaciTitles'>Informed Roles</h6>
									</div>
									<div className="col-xl-12">
										<RaciDropdown
											className="informedRolesList"
											enableDropdown={true}
											data={rolesData}
											complexityId={selectedComplexity}
											// updatedValue={complexityLevelUpdatedDetails}
											// // setUpdatedValue={setComplexityLevelUpdatedDetails}
											// setUpdatedValue={handleDropdownSelection}
											updatedValue={complexityLevelUpdatedDetails}
											setUpdatedValue={(newState) => {
												setComplexityLevelUpdatedDetails(newState);
											}}

											type="informedList"
											key="informedList"
											pageType={"Activity"}
										/>
									</div>
									<div className="col-xl-12">
										<RemoveRaci
											complexityLevelDetails={complexityLevelUpdatedDetails}
											setComplexityLevelDetails={setComplexityLevelUpdatedDetails}
											allowEdit={true}
											type="informedList"
											selectedComplexity={selectedComplexity}
											key="informedListRemove"
										/>
									</div>
								</div>
								<div className='row borderStyle '>
									<div className='col-xl-12'>
										<div className='row activityOwnersLabel'>
											<div className='containerTitle col-xl-6'>
												<h6>Business Rules</h6>

											</div>
											<div className='col-xl-6 d-flex justify-content-end businessRuleIcon'>
												<Icon
													iconName="EditSolid12"
													onClick={() => {
														setShowBusinessRuleModal(true);
													}}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setShowBusinessRuleModal(true);
														}
													}}
													role="button"
													aria-label={`Add Business Rule`}
													title='Add Business Rule'
												/>
											</div></div>

										{showBusinessRuleModal ?
											<Modal
												size="lg"
												centered
												show={true}
												onHide={() => {
													/* Not calling any function to close modal*/
												}}
												dialogClassName="modal-90w"
											>
												<Modal.Header>
													<Modal.Title>
														<div className="polygonTopicModalRelatedContenTitle">
															<span>Business Rule</span>
														</div>
													</Modal.Title>
													<Icon
														className="relatedContentClose"
														iconName="ChromeClose"
														title="Close"
														onClick={() => {
															setShowBusinessRuleModal(false);
														}}
													/>
												</Modal.Header>
												<Modal.Body>
													<div className="addPolygonTopicContenUrlContainer">
														<div className="addPolygonTopicRelatedContentLabel">
															<div className="addPolygonTopicFieldContenUrlLabel">
																<span className="addPolygonTopicFieldLabel">
																	Content Title
																</span>
															</div>
															<div className="addPolygonTopicFieldContenUrlLabel">
																<span className="addPolygonTopicFieldLabel">
																	Content URL
																</span>
															</div>
														</div>
														<div className="addPolygonTopicRelatedInputContainer">
															<div className="polygonInputContainer">
																{businessRelatedContent.map((item, index) => (
																	<>
																		<div className="addPolygonTopicRelatedInput">
																			<div className="addPolygonTopicFieldContenUrl">
																				<input
																					placeholder="Enter Title"
																					name="name"
																					value={item.name}
																					onChange={(e) => {
																						handleToChangeBusinessContent(e, item, index);
																					}}
																				/>
																			</div>
																			<div className="addPolygonTopicFieldContenUrl">
																				<input
																					placeholder="Enter url"
																					name="url"
																					value={item.url}
																					onChange={(e) => {
																						handleToChangeBusinessContent(e, item, index);
																					}}
																				/>
																			</div>
																			<div className="addPolygonTopicFieldSave">
																				<button className="relatedContentDelete"
																					onClick={() => {
																						removeBusinessRelatedContent(index);
																						// if (item.id === undefined) {

																						// } else {
																						// 	deleteBusinessRelatedContent(item.id)
																						// }
																					}}>
																					<Icon
																						className="relatedContentDeleteIcon"
																						iconName="ChromeClose"
																						title="Close"
																					/>
																				</button>
																			</div>
																			{index === businessRelatedContent.length - 1 && (
																				<div className="addPolygonTopicFieldSave">
																					<button
																						className="relatedContentAdd"
																						onClick={() => {
																							handleAddBusinessContentFields();
																						}}
																						onKeyPress={(e) => {
																							if (e.which === 13) {
																								handleAddBusinessContentFields();
																							}
																						}}
																					>
																						<Icon
																							className="relatedContentAddIcon"
																							iconName="Add"
																							title="Add"
																						/>
																					</button>
																				</div>)}
																		</div>

																		{(showBusinessRuleTitleError || showBusinessRuleUrlError) &&
																			(showBusinessRuleIndexError === index) && (
																				<div className="errorBusinessRule">
																					<div className="addPolygonTopicFieldContenUrl">
																						{showBusinessRuleTitleError &&
																							<p className="errorMsg">
																								{showBusinessRuleTitleErrorMsg}
																							</p>
																						}</div><div className="addPolygonTopicFieldContenUrl">
																						{showBusinessRuleUrlError &&
																							<p className="errorMsg">
																								{showBusinessRuleUrlErrorMsg}
																							</p>
																						}</div>
																				</div>
																			)}
																	</>
																))}

															</div>
														</div>
													</div>
												</Modal.Body>
												<Modal.Footer>
													<div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
														<button
															className="cancelBusinessContent  mr-2"
															onClick={() => {
																setShowBusinessRuleModal(false);
																setBusinessRelatedContent(originalBusinessContent);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setShowBusinessRuleModal(false);
																	setBusinessRelatedContent(originalBusinessContent);
																}
															}}
														>
															Cancel
														</button>
														<button
															className="addBtnBusinessContent"
															onClick={() => {
																addBusinessRelatedContent();
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	addBusinessRelatedContent();
																}
															}}
														>
															Add
														</button>
													</div>
												</Modal.Footer>
											</Modal>
											:
											<></>
										}
										<div className='row'>
											{businessRelatedContent.map((item) => (
												<>
													{item.name !== '' ?
														<a className="relatedContentAnchoractivity" href={item.url} target="_blank" title={item.url}>
															<span>{item.name}</span>
														</a>
														:
														<></>
													}
												</>
											))}
										</div>



									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div >
	);
};

export default NewActivityDetails;
