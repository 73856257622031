const graph = require('@microsoft/microsoft-graph-client');
const uiavatars = require('ui-avatars');

function getAuthenticatedClient(accessToken) {
	// Initialize Graph client
	const client = graph.Client.init({
		// Use the provided access token to authenticate
		authProvider: (done) => {
			done(null, accessToken.accessToken);
		},
	});

	return client;
}

async function getUserProfile(accessToken) {
	const profile = {
		displayName: 'Guest',
		firstName: 'Guest',
		mail: null,
		photo: null,
		surname: '',
		jobTitle: '',
		error: null,
		email: null,
	};
	const client = getAuthenticatedClient(accessToken);

	await client
		.api('/me')
		.get()
		.then((response) => {
			profile.displayName = response.displayName ? response.displayName : 'Guest';
			profile.firstName = response.givenName ? response.givenName : 'Guest';
			profile.mail = response.userPrincipalName;
			profile.surname = response.surname === null ? 'User' : response.surname;
			profile.jobTitle = response.jobTitle;
			profile.email = response.mail;
		})
		.catch((error) => {
			profile.error = 'error';
		});

	await client
		.api('/me/photo/$value')
		.get()
		.then((response) => {
			const url = window.url || window.webkitURL;
			const blobURL = url.createObjectURL(response);

			profile.photo = blobURL;
		})
		.catch((error) => {
			profile.photo = uiavatars.generateAvatar({
				uppercase: true,
				name: profile.firstName[0] + profile.surname[0],
				fontsize: 0.5,
				bold: true,
				length: 2,
				rounded: true,
				size: 250,
			});
		});

	return profile;
}

async function getOtherUserProfile(accessToken, userEmail) {
	const client = getAuthenticatedClient(accessToken);
	const profile = {
		displayName: userEmail,
		firstName: userEmail,
		mail: userEmail,
		photo: null,
		surname: '',
		jobTitle: '',
		error: null,
	};

	await client
		.api(`/users/${userEmail}`)
		.get()
		.then((response) => {
			profile.displayName = response.displayName ? response.displayName : 'Guest';
			profile.firstName = response.givenName ? response.givenName : 'Guest';
			profile.mail = response.userPrincipalName;
			profile.surname = response.surname === null ? 'User' : response.surname;
			profile.jobTitle = response.jobTitle;
		})
		.catch((error) => {
			profile.error = 'error';
		});

	await client
		.api(`/users/${userEmail}/photo/$value`)
		.get()
		.then((response) => {
			const url = window.url || window.webkitURL;
			const blobURL = url.createObjectURL(response);
			profile.photo = blobURL;
		})
		.catch((error) => {
			profile.photo = uiavatars.generateAvatar({
				uppercase: true,
				name: profile.firstName[0] + profile.surname[0],
				fontsize: 0.5,
				bold: true,
				length: 2,
				rounded: true,
				size: 250,
			});
		});

	return profile;
}

async function getGroupInfo(accessToken, groupId, userEmail) {
	const groupMember = {
		verifiedUser: null,
		sdmGroupMemebers: [],
	};

	const client = getAuthenticatedClient(accessToken);

	await client
		.api(`/groups/${groupId}/members`)
		.get()
		.then((response) => {
			const groupData = response.value;
			for (const id in groupData) {
				if (groupData[id].userPrincipalName === userEmail) {
					groupMember.verifiedUser = userEmail;
				}
				groupMember.sdmGroupMemebers.push(groupData[id].userPrincipalName);
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return groupMember;
}

async function getUsersList(accessToken, userName) {
	const client = getAuthenticatedClient(accessToken);
	const usersList = {
		error: '',
		data: [],
	};
	await client
		.api(
			`/users?$filter=startswith(displayName,'${userName}') or startswith(givenName,'${userName}') or startswith(surname,'${userName}') or startswith(mail,'${userName}') or startswith(userPrincipalName,'${userName}')`
		)
		.get()
		.then((response) => {
			usersList.error = '';
			for (const user of response.value) {
				usersList.data.push({
					displayName: user.displayName,
					firstName: user.givenName ? user.givenName : user.displayName,
					mail: user.userPrincipalName,
					surname: user.surname ? user.surname : ' ',
					jobTitle: user.jobTitle,
					photo: uiavatars.generateAvatar({
						uppercase: true,
						name: user.surname
							? (user.givenName ? user.givenName[0] : user.displayName[0]) + user.surname[0]
							: user.displayName,
						fontsize: 0.5,
						bold: true,
						length: 2,
						rounded: true,
						size: 250,
					}),
				});
			}
		});

	return usersList;
}

async function getUserImage(accessToken, userEmail, firstName, surname) {
	const client = getAuthenticatedClient(accessToken);
	const profile = {
		displayName: userEmail,
		firstName: firstName,
		mail: userEmail,
		photo: null,
		surname: surname,
		jobTitle: '',
		error: null,
	};

	await client
		.api(`/users/${userEmail}/photo/$value`)
		.get()
		.then((response) => {
			const url = window.url || window.webkitURL;
			const blobURL = url.createObjectURL(response);
			profile.photo = blobURL;
		})
		.catch((error) => {
			profile.photo = uiavatars.generateAvatar({
				uppercase: true,
				name: profile.firstName[0] + profile.surname[0],
				fontsize: 0.5,
				bold: true,
				length: 2,
				rounded: true,
				size: 250,
			});
		});

	return profile;
}
export { getUserProfile, getOtherUserProfile, getGroupInfo, getUsersList, getUserImage };
