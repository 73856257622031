import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/Spinner';

const TutorialDeleteModal = ({
	setEnableTutorialDeleteModal,
	selectedTutorial,
	setSelectedTutorial,
	getConsultingOrSupportTopics,
}) => {
	const { axiosDeleteService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(false);

	const reset = () => {
		setEnableTutorialDeleteModal(false);
		setSelectedTutorial({});
	};

	const deleteTutorial = async () => {
		setShowSpinner(true);
		await axiosDeleteService(
			`api/sdmtutorials/videoTutorials/delete/${selectedTutorial.id}`
		).then((response) => {
			setShowSpinner(false);
			if (response.data.status) {
				toast.info(`You have successfully deleted ${selectedTutorial.name}`, {
					position: 'top-right',
				});
				getConsultingOrSupportTopics();
				reset();
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
		});
	};
	return (
		<Modal
			className="templateRejectModalPopUp templateDeleteModal"
			show={true}
			onHide={() => {
				reset();
			}}
			size="md"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					<span>Delete Video Tutorial</span>
					<div>
						<Icon
							iconName="Cancel"
							tabIndex={0}
							onClick={() => {
								setEnableTutorialDeleteModal(false);
							}}
						/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showSpinner ? (
					<p>
						<Spinner />
					</p>
				) : (
					<>
						<p>
							Are you sure that you want to delete the Video Tutorial
							"{selectedTutorial.name}"?
						</p>
						<div className="templateDeleteButtons">
							<button
								onClick={() => {
									reset();
								}}
							>
								Cancel
							</button>
							<button
								onClick={() => {
									deleteTutorial();
								}}
							>
								Delete
							</button>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default TutorialDeleteModal;
