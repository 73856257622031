import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { useAuth } from '../../../context/UserAuthContext';
import { useAxios } from '../../../context/AxiosContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../context/TelemetryContext';
import Spinner from '../../../helpers/Spinner';
import TemporaryCkAlert from '../../CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../CkEditor/DecryptionComponent';
import { useConfig } from '../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionTopics/MyContribution/TopicsContributionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

const TopicsMyContributionsPreview = ({
	topicDescriptionData,
	reviewerInfo,
	setReviewerInfo,
}) => {
	const { ckEdKEY } = useConfig();
	const { axiosGetService } = useAxios();
	const { user } = useAuth();
	const { track } = useAppInsights();
	const currentUser = user.profile.mail;
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const [description, setDescription] = useState(null);
	const [licenseKey, setLicenseKey] = useState(null);

	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();

	const getContributor = (suggestions) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		if (topicDescriptionData.reviewerSuggestionsJson) {
			getContributor(JSON.parse(topicDescriptionData.reviewerSuggestionsJson));
		}
		if (topicDescriptionData.resultCalloutJson) {
			getContributor(topicDescriptionData.resultCalloutJson);
		}
		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
		}
		setCkEditorUsers(contributorInfo);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		if (topicDescriptionData) {
			getUsersInfo();
		}
	}, []);

	if (!topicDescriptionData) {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				There were no contributions from reviewer.
			</MessageBar>
		);
	}

	if (ckEditorUsers.length < 1 || !licenseKey) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}

	return (
		<div className="PreviewAndSubmitPopUp historicalPreview">
			<div className="topicsCrowdSourcingContainer">
				<div className="topicsDescriptionDetails">
					<div className="topicDescriptionDiv">
						<h4 className="topicTitle">{topicDescriptionData.topicName}</h4>
						<TemporaryCkAlert/>
						<DescriptionCkEditor
							users={ckEditorUsers}
							currentUser={currentUser}
							initialData={topicDescriptionData.description}
							initalSuggestions={topicDescriptionData.resultCalloutJson}
							reviewedSuggestions={topicDescriptionData.resultReviewedJson}
							licenseKey={licenseKey}
							previewType="contribution"
							stateId={0}
							comment={
								topicDescriptionData.resultApproverComments
									? topicDescriptionData.resultApproverComments
									: []
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopicsMyContributionsPreview;
