import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import dompurify from 'dompurify';
import { Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/DescriptionTrackChanges';
import DeliverablesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/DeliverablesTrackChanges';
import SupportAndActivitiesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/SupportingActivitieTrackChanges';
import TipsAndTechniquesCkEditor from '../CkEditor/ActivityPreviewModeTrackChanges/TipsAndTechniquesTrackChanges';
import Dropdown from '../Dropdown';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import FileDropModal from '../TemplateCard/FileDropModal';
import PreviewModal from '../TemplateCard/PreviewModal';
import SampleTemplateModal from '../TemplateCard/SampleTemplateModal';
import RejectionReasonModal from './RejectionReasonModal';
import { Icon } from '@fluentui/react/lib/Icon';

import '../Activity/Activity.css';
import '../ActivityContributorCrowdSourcing/PreviewAndSubmitPopUp.css';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import { getCommandButtonStyles } from '@fluentui/react';
import { toBeRequired } from '@testing-library/jest-dom/matchers';

function PreviewAndSubmitPopUp({
	activityContent,
	activityDescription,
	activitySupportingActivities,
	templateData,
	topicData,
	procedureData,
	activityToolsAndGuidance,
	activityTipsAndTechniques,
	descriptionSuggestions,
	supportingActivitiesSuggestions,
	toolsAndGuidanceSuggestions,
	tipsAndTechniquesSuggestions,
	setShowPreviewAndSubmitPopUp,
	complexities,
	complexityId,
	complexityName,
	setComplexityName,
	reviewedDescriptionSuggestions,
	reviewedSupportSuggestions,
	reviewedToolsAndGuidanceSuggestions,
	reviewedTipsAndTechniquesSuggestions,
	users,
	currentUser,
	setLeavingWithoutSave,
	contributorDescSuggestions,
	contributorSupportSuggestions,
	contributorTipsSuggestions,
	contributorToolsSuggestions,
	setSubmitMessage,
	contributorsData,
	licenseKey,
	selectedToolsAndGuidanceSuggestions,
	toolsUrl,
	noContributionMessage,
	reviewError,
	messagesType,
	contributorSuggestionsInfo,
	existingHashtags,
	ratingPoints,
	enableGamification,
	templateSuggestions,
	contributorTemplateSuggestions,
	reviewedTemplateSuggestions,
	entryCriteriaWithSuggestions,
	exitCriteriaWithSuggestions,
	taskWithSuggestions,
	entryCriteriaData,
	exitCriteriaData,
	taskData,
	contributorEntryCriteriaSuggestions,
	contributorExitCriteriaSuggestions,
	contributorTaskSuggestions,
	reviewedEntryCriteriaSuggestions,
	reviewedExitCriteriaSuggestions,
	reviewedTaskSuggestions,
	entryCriteriaSuggestions,
	exitCriteriaSuggestions,
	taskSuggestions,
	activityDeliverables,
	deliverablesSuggestions,
	reviewedDeliverablesSuggestions,
	contributorDeliverablesSuggestions,
	taskReorderArray,
	exitCriteriaReorderArray,
	entryCriteriaReorderArray,
	inputCriteriaReorderArray,
	outputCriteriaReorderArray,
	reviewedReorderedTaskSuggestions,
	reviewedReorderedEntrySuggestions,
	reviewedReorderedExitSuggestions,
	reviewedReorderedInputSuggestions,
	reviewedReorderedOutputSuggestions,
	isReviewerTaskReordered,
	isReviewerEntryReordered,
	isReviewerInputReordered,
	isReviewerExitReordered,
	isReviewerOutputReordered,
	reorderedTaskRejectionCardInfo,
    reorderedEntryRejectionCardInfo,
    reorderedInputRejectionCardInfo,
    reorderedExitRejectionCardInfo,
    reorderedOutputRejectionCardInfo,
	user,
	reorderContributionCount,
	addedTaskSequence,
    addedEntrySequence,
    addedExitSequence
}) {

	
	const history = useHistory();
	const htmlSanitizer = (data) => {
		const config = {
		  ADD_ATTR: [ 'target'],
		  };
		return dompurify.sanitize(data, config);
	  };
	const { axiosPostService, axiosGetService } = useAxios();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { activityId } = useParams();
	const [modalShow, setModalShow] = useState(true);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState(1);
	const [previewActivityDeliverables, setPreviewActivityDeliverables] =
		useState(1);
	const [showLoader, setShowLoader] = useState(false);
	const [
		previewActivitySupportingActivities,
		setPreviewActivitySupportingActivities,
	] = useState(1);
	const [
		previewActivityTipsAndTechniques,
		setpreviewActivityTipsAndTechniques,
	] = useState(1);
	const [sampleTemplates, setSampleTemplates] = useState({
		enable: false,
		data: null,
		parentData: null,
	});
	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);
	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};
	const [showRejectionReasonModal, setShowRejectionReasonModal] =
		useState(false);
	const [rejectionComments, setRejectionComments] = useState({});
	const [reorderRejectionComments, setReorderRejectionComments] = useState({task:{},entryCriteria:{},exitCrteria:{},inputCriteria:{},outputCriteria:{}})
	const [usersRating, setUsersRating] = useState({});

	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
		setShowRejectionReasonModal(false);
	};
	const getToolsCount = () => {
		let toolsCount = 0;
		for (const tool of selectedToolsAndGuidanceSuggestions) {
			if (JSON.parse(tool).suggestionType !== 'deletion') {
				toolsCount += 1;
			}
		}
		return toolsCount;
	};

	
	const getApprovalStatus = (contribuotrSuggestion, reviewedSuggestions) => {
		if (!contribuotrSuggestion) {
			return true;
		}
		if (contribuotrSuggestion.length > reviewedSuggestions.length) {
			return false;
		}
		for (const contrId in contribuotrSuggestion) {
			for (const revId in reviewedSuggestions) {
				if (
					JSON.parse(contribuotrSuggestion[contrId]).id ===
					JSON.parse(reviewedSuggestions[revId]).id
				) {
					if (JSON.parse(reviewedSuggestions[revId]).state === 'open') {
						return false;
					}
				}
			}
		}
		return true;
	};
   const getReorderApprovalStatus = ()=>{
      if(reorderContributionCount === 0){
		return true;
	   } else {
		return false
	   }
   }

  
	useEffect(() => {
		track(2, 135, activityContent.activityId, activityContent.activityName);
	}, []);
	const getSuggestionsInfo = (
		emCollection,
		suggestionsData,
		suggestionsMap
	) => {
		for (var i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-after')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-end-after').split(':')
						.length > 3
						? $(emCollection[i]).attr('data-suggestion-end-after').split(':')[2]
						: $(emCollection[i])
							.attr('data-suggestion-end-after')
							.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-start-before').split(':')
						.length > 3
						? $(emCollection[i])
							.attr('data-suggestion-start-before')
							.split(':')[2]
						: $(emCollection[i])
							.attr('data-suggestion-start-before')
							.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			}
		}
	};

	const findSuggestionsInHtmData = (htmlData) => {
		const domParser = new DOMParser();
		const docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		let emCollection = docElement.getElementsByTagName('suggestion');
		const suggestionsData = [];
		const suggestionsMap = new Map();
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionId = $(emCollection[i]).attr('id').split(':')[0];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionId =
				$(emCollection[i]).attr('name').split(':').length > 3
					? $(emCollection[i]).attr('name').split(':')[2]
					: $(emCollection[i]).attr('name').split(':')[1];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		return suggestionsData;
	};
  
	const verifyContribution = (suggestionIds, type) => {
		if(contributorSuggestionsInfo[type]){
		const rejectedSuggestionsIds = suggestionIds
			.filter(
				(suggestion) =>
					JSON.parse(suggestion).state === 'rejected' ||
					JSON.parse(suggestion).state === 'accepted'
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const contributedSuggestionsIds = Object.keys(
			contributorSuggestionsInfo[type]
		).map((suggestion) => {
			return contributorSuggestionsInfo[type][suggestion].id;
		});
		const contributorsRejectedIds = contributedSuggestionsIds.filter((id) =>
			rejectedSuggestionsIds.includes(id)
		);

		return contributorsRejectedIds.length > 0 ? true : false;
	}  else{
		return false;
	}
	};
	const verifyReorderContribution = (suggestionIds) => {
        // const rejectedSuggestionsIds = suggestionIds
        // .filter(
        //     (suggestion) =>
        //         JSON.parse(suggestion).state === 'rejected'
        //         // ||
        //         // JSON.parse(suggestion).state === 'accepted'
        // )
        // .map((item) => {
        //     return JSON.parse(item).id;
        // });
        // // Need to confirm what is contributorSuggestionsInfo
        // return rejectedSuggestionsIds.length > 0 ? true : false;
		//return suggestionIds.status === 'accepted' || 'rejected' ? true : false;
    }


	const checkContribution = (requestType) => {
		const reviewStatus =
			verifyContribution(reviewedDescriptionSuggestions, 'description') ||
			verifyContribution(reviewedSupportSuggestions, 'supportingActivities') ||
			verifyContribution(
				reviewedToolsAndGuidanceSuggestions,
				'toolsAndGuidance'
			) ||
			verifyContribution(
				reviewedTipsAndTechniquesSuggestions,
				'tipsAndTechniques'
			) ||
			verifyContribution(reviewedTemplateSuggestions, 'templates') ||
			verifyContribution(reviewedEntryCriteriaSuggestions, 'entryCriteria') ||
			verifyContribution(reviewedExitCriteriaSuggestions, 'exitCriteria') ||
			verifyContribution(reviewedTaskSuggestions, 'task') ||
			verifyContribution(reviewedDeliverablesSuggestions, 'deliverables');
			let reviewReorderStatus;
		 if(contributorsData.reorderJson !== null || contributorsData.reorderJson !== 'null'){
		  reviewReorderStatus =
		  JSON.parse(contributorsData.reorderJson)?.isTaskReorder ||
		  JSON.parse(contributorsData.reorderJson)?.isEntryReorder ||
		  JSON.parse(contributorsData.reorderJson)?.isInputReorder ||
		  JSON.parse(contributorsData.reorderJson)?.isExitReorder  ||
		  JSON.parse(contributorsData.reorderJson)?.isOutputReorder
		  }
		
		  const usersEmail = users.filter(({ id }) => id !== currentUser);
		if (requestType === 'submit') {
			if (reviewStatus || usersEmail.length > 0 || reviewReorderStatus) {
				setShowRejectionReasonModal(true);
			} else {
				submitData();
			}
		} else {
			if (reviewStatus) {
				return true;
			}
			return false;
		}
	};
	
	
	const updateSuggestionsArray = (allSuggestions, htmlData) => {
		if (!htmlData || htmlData.length === 0) {
			return null;
		}
		const suggestionFromHtmlData = findSuggestionsInHtmData(htmlData);
		const verifiedSuggestions = [];
		for (const id in suggestionFromHtmlData) {
			for (const allSuggestionId in allSuggestions) {
				if (
					suggestionFromHtmlData[id] ===
					JSON.parse(allSuggestions[allSuggestionId]).id
				) {
					verifiedSuggestions.push(allSuggestions[allSuggestionId]);
					break;
				}
			}
		}
		if (verifiedSuggestions.length > 0) {
			return verifiedSuggestions;
		} else {
			return null;
		}
	};
	$(document).ready(function () {
		$('figure').each(function (index, element) {
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				$(imgElement).attr({ style: $(element).attr('style') });
			}
		});
	});
	const getToolsAndGuidanceSuggestion = () => {
		const updatedSuggestion = selectedToolsAndGuidanceSuggestions.filter(
			(suggestion) =>
				JSON.parse(suggestion).suggestionType !== 'none' &&
				JSON.parse(suggestion).authorId === currentUser
		);
		if (updatedSuggestion.length > 0) {
			return updatedSuggestion;
		}
		return null;
	};

	const getToolsAndGuidanceId = () => {
		const id = [];
		for (const key in selectedToolsAndGuidanceSuggestions) {
			if (
				JSON.parse(selectedToolsAndGuidanceSuggestions[key]).suggestionType !==
				'deletion'
			) {
				id.push(
					JSON.stringify(
						JSON.parse(selectedToolsAndGuidanceSuggestions[key]).toolsId
					)
				);
			}
		}
		return id.toString();
	};

	const getTemplateIds = () => {
		const templates = [
			...templateData.map(({ id }) => {
				return id;
			}),
			...reviewedTemplateSuggestions
				.filter(
					(suggestion) =>
						JSON.parse(suggestion).state === 'accepted' &&
						!templateData.some(
							(existingTemplate) =>
								existingTemplate.id === JSON.parse(suggestion).id
						)
				)
				.map((item) => {
					return JSON.parse(item).id;
				}),
		];
		return templates.toString();
	};
	const postData = {
		activityId: activityId,
		complexityId: complexityId,
		description: activityDescription,
		supportingActivities: activitySupportingActivities,
		toolsAndGuidance: getToolsAndGuidanceId(),
		tipsAndTechniques: activityTipsAndTechniques,
		deliverables: activityDeliverables,
		resultReviewedJson: {
			description: descriptionSuggestions,
			// updateSuggestionsArray(
			// 	descriptionSuggestions,
			// 	activityDescription
			// ),
			supportingActivities: supportingActivitiesSuggestions,
			// updateSuggestionsArray(
			// 	supportingActivitiesSuggestions,
			// 	activitySupportingActivities
			// ),
			toolsAndGuidance: getToolsAndGuidanceSuggestion(),
			tipsAndTechniques: tipsAndTechniquesSuggestions,
			// updateSuggestionsArray(
			// 	tipsAndTechniquesSuggestions,
			// 	activityTipsAndTechniques
			// ),
			deliverables: deliverablesSuggestions,
			// updateSuggestionsArray(
			// 	deliverablesSuggestions,
			// 	activityDeliverables
			// ),
			templateSuggestions:
				templateSuggestions.length > 0 ? templateSuggestions : null,
			entryCriteriaSuggestions:
				entryCriteriaSuggestions.filter(
					(item) => JSON.parse(item).suggestionType !== 'none'
				).length > 0
					? entryCriteriaWithSuggestions.filter(
						(item) => JSON.parse(item).suggestionType !== 'none'
					)
					: null,
			exitCriteriaSuggestions:
				exitCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).suggestionType !== 'none'
				).length > 0
					? exitCriteriaWithSuggestions.filter(
						(item) => JSON.parse(item).suggestionType !== 'none'
					)
					: null,
			taskSuggestions:
				taskWithSuggestions.filter(
					(item) => JSON.parse(item).suggestionType !== 'none'
				).length > 0
					? taskWithSuggestions.filter(
						(item) => JSON.parse(item).suggestionType !== 'none'
					)
					: null,
					
			isReviewerTaskReordered: [isReviewerTaskReordered], 
			isReviewerEntryReordered:[isReviewerEntryReordered],
	        isReviewerInputReordered:[isReviewerInputReordered],
	        isReviewerExitReordered:[isReviewerExitReordered],
	        isReviewerOutputReordered:[isReviewerOutputReordered],
			originalAddedTask:addedTaskSequence ? addedTaskSequence.map(e=>JSON.stringify(e)):null,
			originalAddedEntry:addedEntrySequence?addedEntrySequence.map(e=>JSON.stringify(e)):null,
			originalAddedExit:addedExitSequence?addedExitSequence.map(e=>JSON.stringify(e)):null
		},
		resultReviewedSuggestionsJson: {
			descriptionReviewedSuggestions: reviewedDescriptionSuggestions,
			supportReviewedSuggestions: reviewedSupportSuggestions,
			toolsAndGuidancesReviewedSuggestions: reviewedToolsAndGuidanceSuggestions,
			tipsAndtechniquesReviewedSuggestions:
			reviewedTipsAndTechniquesSuggestions,
			templateReviewedSuggestions: reviewedTemplateSuggestions,
			entryCriteriaReviewedSuggestions: reviewedEntryCriteriaSuggestions,
			exitCriteriaReviewedSuggestions: reviewedExitCriteriaSuggestions,
			taskReviewedSuggestions: reviewedTaskSuggestions,
			deliverablesReviewedSuggestions: reviewedDeliverablesSuggestions,
			reorderedTaskReviewedSuggestions: reviewedReorderedTaskSuggestions,
			reorderedEntryReviewedSuggestions: reviewedReorderedEntrySuggestions,
			reorderedExitReviewedSuggestions:reviewedReorderedExitSuggestions,
			reorderedInputReviewedSuggestions:reviewedReorderedInputSuggestions,
			reorderedOutputReviewedSuggestions:reviewedReorderedOutputSuggestions,
		},		
    	resultReorderJson:{
			isTaskReorder: taskReorderArray, 
			isEntryReorder:entryCriteriaReorderArray,
			isExitReorder: exitCriteriaReorderArray,
      		isInputReorder:inputCriteriaReorderArray,
      		isOutputReorder:outputCriteriaReorderArray,
		},
		tileDescription: contributorsData.tileDescription,
		isCritical: contributorsData.isCritical,
		isProcessStep: contributorsData.isProcessStep,
		isLite: contributorsData.isLite,
		isFull: contributorsData.isFull,
		sequence: contributorsData.sequence,
		//deliverables: contributorsData.deliverables,
		contributorCalloutJson: contributorsData.calloutJson,
		url: contributorsData.url,
		activityVersion: contributorsData.activityVersion,
		modifiedOn: contributorsData.modifiedOn,
		activityName: contributorsData.activityName,
		lastContributorActivityContent:
			contributorsData.lastContributorActivityContent,
		templateIds: getTemplateIds(),
		entryCriteriaIds: [
			...entryCriteriaData.map((item) => {
				return item.id;
			}),
		].toString(),
		entryCriteriaData: [...entryCriteriaData.map((item) =>
			JSON.stringify({ ...item })
		)].toString(),
		exitCriteriaIds: [
			...exitCriteriaData.map((item) => {
				return item.id;
			}),
		].toString(),
		exitCriteriaData: [...exitCriteriaData.map((item) => {
			return JSON.stringify(item);
		})].toString(),
		taskIds: [
			...taskData.map((item) => {
				return item.id;
			}),
		].toString(),
		taskData: [
			...taskData.map((item) => {
				return JSON.stringify({ ...item })
			}),
		].toString(),
	};

	
	   
	const verifySuggestions = (previousSuggestions, latestSuggestions) => {
		if (!previousSuggestions && !latestSuggestions) {
			return true;
		}
		for (const data in latestSuggestions) {
			let currentIdFound = false;
			for (const revData in previousSuggestions) {
				if (
					JSON.parse(latestSuggestions[data]).id ===
					JSON.parse(previousSuggestions[revData]).id
				) {
					currentIdFound = true;
					break;
				}
			}
			if (!currentIdFound) {
				return false;
			}
		}
		return true;
	};

	const addReviewerComment = (type) => {
		var contributedSuggestions= [];
		if(contributorSuggestionsInfo[type] !== undefined){
			contributedSuggestions = Object.keys(
				contributorSuggestionsInfo[type]
			).map((suggestion) => {
				if(type === 'templates' || type === 'entryCriteria' || type === 'exitCriteria'
			       || type === 'task' || type === 'toolsAndGuidance'){
					return contributorSuggestionsInfo[type][suggestion];
				}else{
					return JSON.parse(contributorSuggestionsInfo[type][suggestion]);
				}
				// return JSON.parse(contributorSuggestionsInfo[type][suggestion]);
			});
		}
		if (rejectionComments[type] !== undefined) {
			const commentsInfo = Object.keys(rejectionComments[type]).map((id) => {
				const contributor = contributedSuggestions.filter((contributor) => { return contributor.id == id });

				return JSON.stringify({
					id: id,
					authorId: contributor[0].authorId,
					data:
						type === 'toolsAndGuidance' ||
							type === 'templates' ||
							type === 'entryCriteria' ||
							type === 'exitCriteria' ||
							type === 'task'
							? contributor[0].name
							: contributor[0].data,

					type:
						type === 'toolsAndGuidance' ||
							type === 'entryCriteria' ||
							type === 'exitCriteria' ||
							type === 'task'
							? contributor[0].suggestionType
							: contributor[0].type,

					comment: rejectionComments[type][id],
				});
			});
			return commentsInfo;
		}else{
			return [];
		}
	};
	const addReviewerReorderedComments = (type)=>{
		if(reorderRejectionComments[type]){
			const commentsInfo = 
				[{
				id: reorderRejectionComments[type].id,
				comments:reorderRejectionComments[type].comments,
				state:reorderRejectionComments[type].state
			}].map(e=>JSON.stringify(e))
			return commentsInfo;
		}
	}
	
  
	const submitData = async () => {
		const imagesUrl = sessionStorage.getItem('crowdSourcing')
			? JSON.parse(sessionStorage.getItem('crowdSourcing'))
			: [];
		postData.imagePaths = [...contributorsData.imagePaths, ...imagesUrl];

		const editingtateId = await axiosGetService(
			`api/sdmactivity/getActivity2/${activityId}/${complexityId}`
		);
		if (editingtateId.data.stateId === 2) {
			setShowLoader(true);
			setLeavingWithoutSave(false);
			setSubmitMessage(messagesType['pendingApprovalForReviewer']);
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				successMessagePopUp: true,
				successMessageComplexityId: complexityId,
				successMessageTitle: activityContent.activityName,
				userLeaveWithSave: true,
			});
			setShowPreviewAndSubmitPopUp(false);
		} else if (editingtateId.data.stateId === 0) {
			if (
				postData.resultReviewedJson.description ||
				postData.resultReviewedJson.supportingActivities ||
				postData.resultReviewedJson.toolsAndGuidance ||
				postData.resultReviewedJson.tipsAndTechniques ||
				postData.resultReviewedJson.templateSuggestions ||
				postData.resultReviewedJson.entryCriteriaSuggestions ||
				postData.resultReviewedJson.exitCriteriaSuggestions ||
				postData.resultReviewedJson.taskSuggestions ||
				postData.resultReviewedJson.deliverables ||
				postData.resultReorderJson.isTaskReorder
			) {
				setShowLoader(true);
				track(1, 201, activityContent.activityId, activityContent.activityName);
				postData.ratingPoints = null;
				await axiosPostService(`api/sdmactivity/saveReviewer`, postData)
					.then(() => {
						setLeavingWithoutSave(false);
						setSubmitMessage(messagesType['reviewSubmissionMessage']);
						setCrowdSourcingAppState({
							...crowdSourcingAppState,
							successMessagePopUp: true,
							successMessageComplexityId: complexityId,
							successMessageTitle: activityContent.activityName,
						});
						setShowPreviewAndSubmitPopUp(false);
						sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				toast.error(noContributionMessage, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else if (editingtateId.data.stateId === 1) {
			const response = editingtateId.data;
			if (response.resultCalloutJson) {
				const descVerified = verifySuggestions(
					contributorDescSuggestions,
					response.resultCalloutJson.description
				);
				const supportVerified = verifySuggestions(
					contributorSupportSuggestions,
					response.resultCalloutJson.supportingActivities
				);
				const tipsVerified = verifySuggestions(
					contributorTipsSuggestions,
					response.resultCalloutJson.tipsAndTechniques
				);
				const toolsVerified = verifySuggestions(
					contributorToolsSuggestions,
					response.resultCalloutJson.toolsAndGuidance
				);
				const templatesVerified = verifySuggestions(
					contributorTemplateSuggestions,
					response.resultCalloutJson.templates
				);
				const entryCriteriaVerified = verifySuggestions(
					contributorEntryCriteriaSuggestions,
					response.resultCalloutJson.entryCriteriaSuggestions
				);
				const exitCriteriaVerified = verifySuggestions(
					contributorExitCriteriaSuggestions,
					response.resultCalloutJson.exitCriteriaSuggestions
				);
				const taskVerified = verifySuggestions(
					contributorTaskSuggestions,
					response.resultCalloutJson.taskSuggestions
				);
				const checkDescSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.description,
					reviewedDescriptionSuggestions
				);
				const checkSupportSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.supportingActivities,
					reviewedSupportSuggestions
				);
				const checkToolsSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.toolsAndGuidance,
					reviewedToolsAndGuidanceSuggestions
				);
				const checkTipsSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.tipsAndTechniques,
					reviewedTipsAndTechniquesSuggestions
				);
				const checkTemplatesSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.templateSuggestions,
					reviewedTemplateSuggestions
				);
				const checkEntryCriteriaSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.entryCriteriaSuggestions,
					reviewedEntryCriteriaSuggestions
				);
				const checkExitCriteriaSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.exitCriteriaSuggestions,
					reviewedExitCriteriaSuggestions
				);
				const checkTaskSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.taskSuggestions,
					reviewedTaskSuggestions
				);
				const deliverablesVerified = verifySuggestions(
					contributorDeliverablesSuggestions,
					response.resultCalloutJson.deliverables
				);
				const checkDeliverablesSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson.deliverables,
					reviewedDeliverablesSuggestions
				);

				if (
					!(
						descVerified &&
						supportVerified &&
						tipsVerified &&
						toolsVerified &&
						templatesVerified &&
						entryCriteriaVerified &&
						exitCriteriaVerified &&
						taskVerified &&
						deliverablesVerified
					)
				) {
					setShowLoader(true);
					setLeavingWithoutSave(false);
					setSubmitMessage(messagesType['contentUpdate']);
					setCrowdSourcingAppState({
						...crowdSourcingAppState,
						reviewerError: true,
						successMessagePopUp: true,
						successMessageComplexityId: complexityId,
						successMessageTitle: activityContent.activityName,
						userLeaveWithSave: true,
					});
					setShowPreviewAndSubmitPopUp(false);
				} else if (
					!(
						checkDescSuggestionsStatus &&
						checkSupportSuggestionsStatus &&
						checkTipsSuggestionsStatus &&
						checkToolsSuggestionsStatus &&
						checkTemplatesSuggestionsStatus &&
						checkEntryCriteriaSuggestionsStatus &&
						checkExitCriteriaSuggestionsStatus &&
						checkTaskSuggestionsStatus &&
						checkDeliverablesSuggestionsStatus &&
						getReorderApprovalStatus()
					)
				) {
					toast.error(reviewError, {
						position: toast.POSITION.TOP_RIGHT,
					});      

					         
				} else {
					postData.resultReviewerComments = {
						description: addReviewerComment('description'),
						supportingActivities: addReviewerComment('supportingActivities'),
						tipsAndTechniques: addReviewerComment('tipsAndTechniques'),
						toolsAndGuidance: addReviewerComment('toolsAndGuidance'),
						templates: addReviewerComment('templates'),
						entryCriteria: addReviewerComment('entryCriteria'),
						exitCriteria: addReviewerComment('exitCriteria'),
						task: addReviewerComment('task'),
						deliverables: addReviewerComment('deliverables'),
						entryReorderComments:addReviewerReorderedComments('entryCriteria'),
						inputReorderComments:addReviewerReorderedComments('inputCriteria'),
						taskReorderComments:addReviewerReorderedComments('task'),
						exitReorderComments:addReviewerReorderedComments('exitCriteria'),
						outputReorderedComments:addReviewerReorderedComments('outputCriteria')
					};

					postData.resultReorderJson = {
                        isTaskReorder: taskReorderArray,
                        isEntryReorder:entryCriteriaReorderArray,
                        isExitReorder: exitCriteriaReorderArray,
                        isInputReorder:inputCriteriaReorderArray,
                        isOutputReorder:outputCriteriaReorderArray
                    };
					postData.ratingPoints = usersRating;
					setShowLoader(true);
					track(
						1,
						201,
						activityContent.activityId,
						activityContent.activityName
					);
					await axiosPostService(`api/sdmactivity/saveReviewer`, postData)
						.then(() => {
							setLeavingWithoutSave(false);
							setSubmitMessage(messagesType['reviewSubmissionMessage']);
							setCrowdSourcingAppState({
								...crowdSourcingAppState,
								successMessagePopUp: true,
								successMessageComplexityId: complexityId,
								successMessageTitle: activityContent.activityName,
							});
							sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
							setShowPreviewAndSubmitPopUp(false);
						})
						.catch((err) => {
							console.log(err);
						});
				
				}
			}
		}
	};
	
  
	function removePTags(html) {
		// Replace all <p> tags with an empty string
		return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
	  }

	  useEffect(() => {
		$('#previewId').focus();
	  }, []);

	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					{showRejectionReasonModal ? (
						<RejectionReasonModal
							users={users}
							closeModal={closeModal}
							reviewedDescriptionSuggestions={reviewedDescriptionSuggestions}
							reviewedSupportSuggestions={reviewedSupportSuggestions}
							reviewedToolsAndGuidanceSuggestions={
								reviewedToolsAndGuidanceSuggestions
							}
							reviewedTipsAndTechniquesSuggestions={
								reviewedTipsAndTechniquesSuggestions
							}
							reviewedTemplateSuggestions={reviewedTemplateSuggestions}
							reviewedEntryCriteriaSuggestions={
								reviewedEntryCriteriaSuggestions
							}
							reviewedExitCriteriaSuggestions={reviewedExitCriteriaSuggestions}
							reviewedTaskSuggestions={reviewedTaskSuggestions}
							contributorSuggestionsInfo={contributorSuggestionsInfo}
							submit={submitData}
							rejectionComments={rejectionComments}
							setRejectionComments={setRejectionComments}
							reorderRejectionComments={reorderRejectionComments}
							setReorderRejectionComments={setReorderRejectionComments}
							type="review"
							currentUser={currentUser}
							postData={postData}
							ratingPoints={ratingPoints}
							checkRejection={checkContribution}
							usersRating={usersRating}
							setUsersRating={setUsersRating}
							enableGamification={enableGamification}
							reviewedDeliverablesSuggestions={reviewedDeliverablesSuggestions}
							reviewedReorderedTaskSuggestions={reviewedReorderedTaskSuggestions}
							reviewedReorderedEntrySuggestions={reviewedReorderedEntrySuggestions}
							reviewedReorderedExitSuggestions={reviewedReorderedExitSuggestions}
							reviewedReorderedInputSuggestions={reviewedReorderedInputSuggestions}
							reviewedReorderedOutputSuggestions={reviewedReorderedOutputSuggestions}
							addReviewerReorderedComments={addReviewerReorderedComments}
							reorderedTaskRejectionCardInfo={reorderedTaskRejectionCardInfo}
							reorderedEntryRejectionCardInfo={reorderedEntryRejectionCardInfo}
							reorderedInputRejectionCardInfo={reorderedInputRejectionCardInfo}
							reorderedExitRejectionCardInfo={reorderedExitRejectionCardInfo}
							reorderedOutputRejectionCardInfo={reorderedOutputRejectionCardInfo}
							/>
							) : (
						<Modal
							className="PreviewAndSubmitPopUp activityPreviewAndSubmitModal"
							show={modalShow}
							onHide={() => {
								/* Not calling any function to close modal*/
							}}
							dialogClassName="modal-90w"
						>
							<Modal.Header>
								
								<Modal.Title>
								<span tabIndex={0} role="dialog" id="previewId" aria-label="Preview dialog">Preview</span>
									<div className="discardAndPreviewButton">
										<Button
											title="Cancel"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													closeModal();
												}
											}}
											onClick={closeModal}
											className="activityDiscardButton"
										>
											Cancel
										</Button>
										<Button
											title="Submit"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													checkContribution('submit');
												}
											}}
											onClick={() => {
												checkContribution('submit');
											}}
											className={`activityPreviewButton`}
										>
											Submit
										</Button>
									</div>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div>
									<div className="activityLandinPage">
										<div className="activityMainContainer">
											<div className="activityDescriptionDetails">
												<div className="activityDescTitlePart">
													<div className="activityTitleRightPart">
														<span className="activityVersion">
															{`Document Version: ${activityContent.activityVersion},`}
															&nbsp;&nbsp;{`${activityContent.date} `}
														</span>
														<div className="activityComplexity">
															<span>Complexity:</span>
															<Dropdown
																className="complexityDisabled"
																data={complexities}
																setValue={setComplexityName}
																value={complexityName}
																enableDropdown={false}
															/>
														</div>
													</div>
												</div>
												<div className="previewDescOfActivity">
													<h4
														title={activityContent.activityName}
														className="activityLandingPageTitle"
													>
														{activityContent.activityName}
													</h4>
													<div class="existingHashTagsOfActivity">
														{existingHashtags.map((name) => (
															<span
																key={name}
																onClick={() => {
																	history.push(
																		`/search?searchParam=${encodeURIComponent(
																			name
																		)}`
																	);
																}}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		history.push(
																			`/search?searchParam=${encodeURIComponent(
																				name
																			)}`
																		);
																	}
																}}
															>
																{name}
															</span>
														))}
													</div>
													<ActivityRaciMatrix activityData={activityContent} />
													<div
														className="innerContent"
														id="previewInnerContent"
													>
														{activityDescription ? (
															<>
																<h2
																	className="sub-heading"
																	id="previewDesc"
																	aria-label="Description"
																	tabIndex={0}
																>
																	Description
																</h2>
																<DescriptionCkEditor
																	users={users}
																	currentUser={currentUser}
																	initialData={activityDescription}
																	setContent={setPreviewActivityDescription}
																	initalSuggestions={descriptionSuggestions}
																	licenseKey={licenseKey}
																/>
															</>
														) : null}

														{activitySupportingActivities ? (
															<>
																<h2
																	className="sub-heading"
																	id="previewSupportAct"
																>
																	Supporting Activities
																</h2>
																<SupportAndActivitiesCkEditor
																	users={users}
																	currentUser={currentUser}
																	initialData={activitySupportingActivities}
																	setContent={
																		setPreviewActivitySupportingActivities
																	}
																	initalSuggestions={
																		supportingActivitiesSuggestions
																	}
																	licenseKey={licenseKey}
																/>
															</>
														) : null}


														{activityContent.activityEntryCriteria || entryCriteriaWithSuggestions.length > 0 ? (
															<>
																<h2 className="sub-heading" id="previewEntryc">
																	Entry Criteria/Input  {isReviewerEntryReordered || isReviewerInputReordered ? <span className='ReorderMessage'>You have reordered the data</span> : <></>}
																</h2>
																{entryCriteriaWithSuggestions.length > 0 ? (
																	<div id="previewTempList">
																		<ul>
																			{entryCriteriaWithSuggestions.some(
																				(value) =>
																					JSON.parse(value).criteriaTypeName ===
																					'Entry Criteria'
																			) && (
																					<>
																						<>Entry Criteria</>
																						{entryCriteriaWithSuggestions.map(
																							(item) => (
																								<>
																									{JSON.parse(item)
																										.criteriaTypeName ===
																										'Entry Criteria' && (
																											<li
																												className={`${JSON.parse(item)
																													.suggestionType === 'none'
																													? ''
																													: JSON.parse(item)
																														.suggestionType ===
																														'deletion'
																														? 'userDeletedToolsAndGuidance'
																														: 'userAddedItem'
																													}`}
																												key={JSON.parse(item).id}
																											>
																												<span className="">
																													{/* {JSON.parse(item).name} */}
																													<span
																														className="descEn"
																														//id="entryCriteriaSanitizer"
																														id={`${JSON.parse(item)
																																.suggestionType === "none"
																																? "criteriaDesc"
																																: "entryCriteriaSanitizer"

																															}`}
																														dangerouslySetInnerHTML={{
																															__html: htmlSanitizer(JSON.parse(item).name),
																														}}
																													/>
																												</span>
																											</li>
																										)}
																								</>
																							)
																						)}
																					</>
																				)}
																			{entryCriteriaWithSuggestions.some(
																				(value) =>
																					JSON.parse(value).criteriaTypeName ===
																					'Input'
																			) && (
																					<>
																						<>Input</>
																						{entryCriteriaWithSuggestions.map(
																							(item) => (
																								<>
																									{JSON.parse(item)
																										.criteriaTypeName ===
																										'Input' && (
																											<li
																												className={`${JSON.parse(item)
																													.suggestionType === 'none'
																													? ''
																													: JSON.parse(item)
																														.suggestionType ===
																														'deletion'
																														? 'userDeletedToolsAndGuidance'
																														: 'userAddedItem'
																													}`}
																												key={JSON.parse(item).id}
																											>
																												<span className="">
																													{/* {JSON.parse(item).name} */}
																													<span
																														className="descEn"
																														//id="entryCriteriaSanitizer"
																														id={`${JSON.parse(item)
																																.suggestionType === "none"
																																? "criteriaDesc"
																																: "entryCriteriaSanitizer"

																															}`}
																														dangerouslySetInnerHTML={{
																															__html: htmlSanitizer(JSON.parse(item).name),
																														}}
																													/>
																												</span>
																											</li>
																										)}
																								</>
																							)
																						)}
																					</>
																				)}
																		</ul>
																	</div>
																) : null}
															</>
														) : null}

														{activityContent.activityExitCriteria || exitCriteriaWithSuggestions.length > 0 ? (
															<>
																<h2 className="sub-heading" id="previewExitc">
																	Exit Criteria/Output {isReviewerExitReordered || isReviewerOutputReordered ? <span className='ReorderMessage'>{`You have reordered the data`}</span> : <></>}
																</h2>
																{exitCriteriaWithSuggestions.length > 0 ? (
																	<div id="previewTempList">
																		<ul>
																			{exitCriteriaWithSuggestions.some(
																				(value) =>
																					JSON.parse(value).criteriaTypeName ===
																					'Exit Criteria'
																			) && (
																					<>
																						<>Exit Criteria</>
																						{exitCriteriaWithSuggestions.map(
																							(item) => (
																								<>
																									{JSON.parse(item)
																										.criteriaTypeName ===
																										'Exit Criteria' && (
																											<li
																												className={`${JSON.parse(item)
																													.suggestionType === 'none'
																													? ''
																													: JSON.parse(item)
																														.suggestionType ===
																														'deletion'
																														? 'userDeletedToolsAndGuidance'
																														: 'userAddedItem'
																													}`}
																												key={JSON.parse(item).id}
																											>
																												<span className="">
																													{/* {JSON.parse(item).name} */}
																													<span
																														className="descEn"
																														//id="entryCriteriaSanitizer"
																														id={`${JSON.parse(item)
																																.suggestionType === "none"
																																? "criteriaDesc"
																																: "entryCriteriaSanitizer"

																															}`}
																														dangerouslySetInnerHTML={{
																															__html: htmlSanitizer(JSON.parse(item).name),
																														}}
																													/>
																												</span>
																											</li>
																										)}
																								</>
																							)
																						)}
																					</>
																				)}
																			{exitCriteriaWithSuggestions.some(
																				(value) =>
																					JSON.parse(value).criteriaTypeName ===
																					'Output'
																			) && (
																					<>
																						<>Output</>
																						{exitCriteriaWithSuggestions.map(
																							(item) => (
																								<>
																									{JSON.parse(item)
																										.criteriaTypeName ===
																										'Output' && (
																											<li
																												className={`${JSON.parse(item)
																													.suggestionType === 'none'
																													? ''
																													: JSON.parse(item)
																														.suggestionType ===
																														'deletion'
																														? 'userDeletedToolsAndGuidance'
																														: 'userAddedItem'
																													}`}
																												key={JSON.parse(item).id}
																											>
																												<span className="">
																													{/* {JSON.parse(item).name} */}
																													<span
																														className="descEn"
																														//id="entryCriteriaSanitizer"
																														id={`${JSON.parse(item)
																																.suggestionType === "none"
																																? "criteriaDesc"
																																: "entryCriteriaSanitizer"

																															}`}
																														dangerouslySetInnerHTML={{
																															__html: htmlSanitizer(JSON.parse(item).name),
																														}}
																													/>
																												</span>
																											</li>
																										)}
																								</>
																							)
																						)}
																					</>
																				)}
																		</ul>
																	</div>
																) : null}
															</>
														) : null}



														{activityContent.activityTasks || taskWithSuggestions.length > 0 ? (
															<>
																<h2 className="sub-heading" id="previewEntryc">
																	Task {isReviewerTaskReordered && <span className='ReorderMessage'>You have reordered the data</span>}
																</h2>
																{taskWithSuggestions.length > 0 ? (
																	<div id="previewTempList">
																		<ul>
																			<>
																				{taskWithSuggestions.map(
																					(item) => (
																						<>
																							{
																								<li
																									className={`${JSON.parse(item)
																										.suggestionType === 'none'
																										? ''
																										: JSON.parse(item)
																											.suggestionType ===
																											'deletion'
																											? 'userDeletedToolsAndGuidance'
																											: 'userAddedItem'
																										}`}
																									key={JSON.parse(item).id}
																								>
																									{/* <span className="templateEntityName">
																										{JSON.parse(item).name}
																									</span> */}
																									<span
                                                                                                    className="templateEntityName"
                                                                                                    dangerouslySetInnerHTML={{ __html: htmlSanitizer(removePTags(JSON.parse(item).name)) }}
                                                                                                  />
																								</li>
																							}
																						</>
																					)
																				)}
																			</>
																		</ul>
																	</div>
																) : null}
															</>
														) : null}

														{procedureData.length > 0 ? (
															<h2
																className="sub-heading"
																id="previewActivityProcedures"
															>
																Procedures
															</h2>
														) : (
															<></>
														)}
														{procedureData.length > 0 ? (
															<div id="previewActivityProcedure">
																<ul>
																	{procedureData.map((procedure) => (
																		<li>
																			{
																				<a href={procedure.url} target="_blank">
																					{procedure.name}
																				</a>
																			}
																		</li>
																	))}
																</ul>
															</div>
														) : null}
														{topicData.length > 0 ? (
															<h2 className="sub-heading" id="previewTopics">
																Related Topics
															</h2>
														) : (
															<></>
														)}
														{topicData.length > 0 ? (
															<div id="previewTopicList">
																<ul>
																	{topicData.map((topic) => (
																		<li>
																			{
																				<a href={topic.url} target="_blank">
																					{topic.name}
																				</a>
																			}
																		</li>
																	))}
																</ul>
															</div>
														) : null}


														{activityContent.activityDeliverables ||
															(templateData && templateData.length > 0) ||
															(templateSuggestions &&
																templateSuggestions.length > 0) ? (
															<h2
																className="sub-heading"
																id="previewDeliver"
															>
																Deliverables
															</h2>
														) : (
															<></>
														)}
														{templateData.length > 0 ||
															templateSuggestions.length > 0 ? (
															<div id="previewTempList">
																<ul>
																	{templateData.map((template) => (
																		<li>
																			{template.inProgress === true ? (
																				<a
																					className="noClick"
																					href={template.fileLocation}
																					target="_blank"
																				>
																					{template.title}
																				</a>
																			) : (
																				<a
																					href={template.fileLocation}
																					target="_blank"
																				>
																					{template.title}
																				</a>
																			)}
																			{template.sampleTemplates &&
																				template.sampleTemplates.length !== 0 &&
																				!template.inProgress ? (
																				<button
																					className="activitySampleButton"
																					tabIndex={0}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setSampleTemplates({
																								enable: true,
																								id: template.id,
																								parentTemplate: template,
																							});
																						}
																					}}
																					onClick={() => {
																						setSampleTemplates({
																							enable: true,
																							id: template.id,
																							parentTemplate: template,
																						});
																					}}
																				>
																					(Sample)
																				</button>
																			) : (
																				<></>
																			)}
																		</li>
																	))}
																	{templateSuggestions.map((item) => (
																		<li
																			className={`${JSON.parse(item).suggestionType ===
																				'insertion'
																				? 'userAddedItem'
																				: 'none'
																				}`}
																			key={item.id}
																		>
																			<span className="templateEntityName">
																				<a href={JSON.parse(item).fileLocation}>
																					{JSON.parse(item).title}
																				</a>
																			</span>
																		</li>
																	))}
																</ul>
															</div>
														) : null}
														{/* {activityContent.activityDeliverables ? (
															<div
																id="previewActivityDeliverables"
																dangerouslySetInnerHTML={{
																	__html: htmlSanitizer(
																		activityContent.activityDeliverables
																	),
																}}
															/>
														) : null} */}


														{/* {activityContent.activityDeliverables ||
															templateData.length > 0 ||
															templateSuggestions.length > 0 ? (
															<h2 className="sub-heading" id="previewDeliver">
																Deliverables
															</h2>
														) : (
															<></>
														)} */}

														{activityDeliverables ? (
															<>
																{/* <h2
																	className="sub-heading"
																	id="previewDesc"
																	aria-label="Deliverables"
																	tabIndex={0}
																>
																	Deliverables
																</h2> */}
																<DeliverablesCkEditor
																	users={users}
																	currentUser={currentUser}
																	initialData={activityDeliverables}
																	setContent={setPreviewActivityDeliverables}
																	initalSuggestions={deliverablesSuggestions}
																	licenseKey={licenseKey}
																/>
															</>
														) : null}

														{getToolsCount() ||
															postData.resultReviewedJson.toolsAndGuidance ? (
															<>
																<h2 className="sub-heading" id="previewTool">
																	Tools
																</h2>
																<ul>
																	{postData.resultReviewedJson
																		.toolsAndGuidance &&
																		postData.resultReviewedJson.toolsAndGuidance.map(
																			(tools) => (
																				<React.Fragment
																					key={JSON.parse(tools).toolsId}
																				>
																					<li
																						className={
																							JSON.parse(tools)
																								.suggestionType === 'deletion'
																								? 'userDeletedToolsAndGuidance'
																								: 'userAddedItemPreview'
																						}
																					>
																						<a
																							href={
																								toolsUrl[
																								JSON.parse(tools).toolsId
																								]
																							}
																						>
																							{JSON.parse(tools).name}
																						</a>
																					</li>
																				</React.Fragment>
																			)
																		)}
																	{selectedToolsAndGuidanceSuggestions.map(
																		(tools) => (
																			<React.Fragment
																				key={JSON.parse(tools).toolsId}
																			>
																				{JSON.parse(tools).suggestionType !==
																					'deletion' &&
																					JSON.parse(tools).suggestionType !==
																					'insertion' ? (
																					<li>
																						<a
																							href={
																								toolsUrl[
																								JSON.parse(tools).toolsId
																								]
																							}
																						>
																							{JSON.parse(tools).name}
																						</a>
																					</li>
																				) : (
																					<></>
																				)}
																			</React.Fragment>
																		)
																	)}
																</ul>
															</>
														) : (
															<></>
														)}

														{activityTipsAndTechniques ? (
															<>
																<h2
																	className="sub-heading"
																	id="previewTipstech"
																>
																	Guidance, Tips and Techniques
																</h2>
																<TipsAndTechniquesCkEditor
																	users={users}
																	currentUser={currentUser}
																	initialData={activityTipsAndTechniques}
																	setContent={
																		setpreviewActivityTipsAndTechniques
																	}
																	initalSuggestions={
																		tipsAndTechniquesSuggestions
																	}
																	licenseKey={licenseKey}
																/>
															</>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
									{enableTemplatePreview ? (
										<PreviewModal
											enableTemplatePreview={enableTemplatePreview}
											closeModal={() => setEnableTemplatePreview(false)}
											data={templatePreviewData}
										/>
									) : (
										<></>
									)}
									{enableFileDropModal ? (
										<FileDropModal
											enableFileDropModal={enableFileDropModal}
											closeModal={() => setEnableFileDropModal(false)}
											data={sampleTemplates.parentTemplate}
										/>
									) : (
										<></>
									)}
									<Modal
										show={sampleTemplates.enable}
										onHide={() => {
											setSampleTemplates({
												id: null,
												enable: false,
												parentTemplate: null,
											});
										}}
										centered
										dialogClassName="templateModal"
									>
										{sampleTemplates.enable ? (
											<SampleTemplateModal
												id={sampleTemplates.id}
												handleTemplatePreview={handleTemplatePreview}
												setEnableFileDropModal={setEnableFileDropModal}
												closeModal={() => {
													setSampleTemplates({
														id: null,
														enable: false,
														parentTemplate: null,
													});
												}}
												enableFileDropModal={enableFileDropModal}
												complexityId={complexityName.id}
											/>
										) : (
											<></>
										)}
									</Modal>
								</div>
							</Modal.Body>
						</Modal>
					)}
				</>
			)}
		</>
	);
}

export default PreviewAndSubmitPopUp;
