import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import Spinner from '../../helpers/Spinner';
import { useAxios } from '../../context/AxiosContext';
import { useConfig } from '../../context/ConfigContext';
import { useAuth } from '../../context/UserAuthContext';
import { useAppInsights } from '../../context/TelemetryContext';

import './CustomYammer.css';

const uiavatars = require('ui-avatars');

const CustomYammer = ({ activityId, filData, complexityId, activityName }) => {
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const { yammerGroupId, yammerInstance } = useConfig();
	const { user } = useAuth();
	const [tcode, setTopicUniquecode] = useState(null);
	const [yammerConnection, setYammerConnection] = useState(false);
	const [loginStatusChecked, setLoginStatusChecked] = useState(false);
	const [parentId, setParentId] = useState(-1);
	const [users, setUsers] = useState({});
	const [inputBoxId, setInputBoxId] = useState(-1);
	const [commentCount, setCommentCount] = useState({});
	const [openedChatIds, setOpenChatIds] = useState([]);
	const [currentUser, setCurrentUser] = useState({});
	const [text, setText] = useState('');
	const [parentYammerChat, setParentYammerChat] = useState({});
	const [childYammerChat, setChildYammerChat] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	const [showPostLoader, setShowPostLoader] = useState(false);
	const [ishashTagAvailable, setIsHashTagAvailable] = useState(false);

	const parentYammerId = {};
	const childYammerId = {};
	const commentCountWithId = {};
	const usersDetails = {};
	let messages = [];
	let references = [];
	let apiCallCount = 0;

	const getYammerTopics = async () => {
		const response = await axiosGetService(
			`/api/activities/uniquecode?activityIdentifier=${activityId}`
		);
		let uniqueCode = response.data
			.replace(/[^\w]/gi, '_')
			.replace(/____/g, '_')
			.replace(/___/g, '_')
			.replace(/__/g, '_');

		const res = uniqueCode.slice(-1);
		if (res === '_') {
			uniqueCode = uniqueCode.substr(0, uniqueCode.length - 1);
		}
		setTopicUniquecode(uniqueCode);
	};

	const yammerFeed = async () => {
		if (tcode) {
			setShowLoader(true);
			const uniqueCode = tcode + '_' + complexityId + '_' + yammerInstance;
			window.yam.platform.request({
				url: `${'https://' + 'api.yammer.com/api/v1/search.json?search='
					}${encodeURIComponent(`#${uniqueCode}`)}`,
				method: 'GET',
				async: false,

				success(e) {
					if (e.messages.messages.length > 0) {
						const message_length = e.messages.messages.length;
						parentYammerId[e.messages.messages[message_length - 1].id] = [];
						getYammerConversations(
							e.messages.messages[message_length - 1].id,
							false,
							0
						);
						setParentId(e.messages.messages[message_length - 1].id);
						setIsHashTagAvailable(true);
					} else {
						setChildYammerChat({});
						setParentYammerChat({});
						setCommentCount({});
						setUsers({});
						setParentId(-1);
						setInputBoxId(-1);
						setOpenChatIds([]);
						setShowLoader(false);
					}
				},
			});
			window.yam.platform.request({
				url: `https://api.yammer.com/api/v1/users/by_email.json?email=${user.profile.email}`,
				method: 'GET',
				async: false,
				success(e) {
					setCurrentUser({
						id: e[0].id,
						image: e[0].mugshot_url,
						name: e[0].full_name,
					});
				},
			});
		}
	};

	const createFirstPost = async (replied_to_id) => {
		const data = {
			body: text,
			replied_to_id: replied_to_id,
		};
		window.yam.platform.request({
			url: `https://api.yammer.com/api/v1/messages.json`,
			method: 'POST',
			async: false,
			data: data,
			success() {
				track(1, 701, activityId, activityName, {
					comment: text,
					complexityId: complexityId,
				});
				getYammerConversations(replied_to_id, false, 0);
				setTimeout(() => {
					setInputBoxId(-1);
					setText('');
				}, 500);
			},
		});
	};

	const createHashtag = async () => {
		setShowPostLoader(true);
		const uniqueCode = tcode + '_' + yammerInstance;
		window.yam.platform.request({
			url: 'https:' + '//api.yammer.com/api/v1/messages.json',
			method: 'POST',
			data: { body: `#${uniqueCode}`, group_id: yammerGroupId },
			success(response) {
				const message_length = response.messages.length;
				if (message_length > 0) {
					parentYammerId[response.messages[0].id] = [];
					setParentId(response.messages[0].id);
					createFirstPost(response.messages[0].id);
					setIsHashTagAvailable(true);
				} else {
					setShowPostLoader(false);
				}
			},
		});
	};

	const updateData = () => {
		for (const message of messages) {
			childYammerId[message.id] = [];
			commentCountWithId[message.id] = 0;
		}
		for (const message of messages) {
			if (message.replied_to_id in parentYammerId) {
				parentYammerId[message.replied_to_id].push({
					id: message.id,
					group_id: message.group_id,
					network_id: message.network_id,
					sender_id: message.sender_id,
					text: message.body.plain,
					replied_to_id: message.replied_to_id,
					created_at: message.created_at,
					liked_by: message.liked_by,
				});
			} else if (message.replied_to_id in childYammerId) {
				childYammerId[message.replied_to_id].push({
					id: message.id,
					group_id: message.group_id,
					network_id: message.network_id,
					sender_id: message.sender_id,
					text: message.body.plain,
					replied_to_id: message.replied_to_id,
					created_at: message.created_at,
					liked_by: message.liked_by,
				});
				commentCountWithId[message.replied_to_id] += 1;
			}
		}
		for (const reference of references) {
			if (reference.type === 'user') {
				usersDetails[reference.id] = {
					name: reference.full_name,
					image: reference.mugshot_url,
				};
			}
		}
		setUsers(usersDetails);
		setParentYammerChat(parentYammerId);
		setChildYammerChat(childYammerId);
		setCommentCount(commentCountWithId);
		setShowLoader(false);
		setShowPostLoader(false);
	};

	const getYammerConversations = (id, showOlderMessage, oldMessageId) => {
		window.yam.platform.request({
			url: `${showOlderMessage
					? 'https://' +
					`api.yammer.com/api/v1/messages/in_thread/${id}.json?older_than=${oldMessageId}`
					: 'https://' + `api.yammer.com/api/v1/messages/in_thread/${id}.json`
				}`,
			method: 'GET',
			async: false,

			success(e) {
				apiCallCount += 1;
				messages = [...messages, ...e.messages];
				references = [...references, ...e.references];
				if (e.meta.older_available) {
					if (apiCallCount === 10) {
						updateData();
						apiCallCount = 0;
						setTimeout(() => {
							getYammerConversations(id, true, e.messages[19].id);
						}, 30000);
					}
					getYammerConversations(id, true, e.messages[19].id);
				} else {
					updateData();
				}
			},
		});
	};

	const checkLoginStatus = () => {
		setShowLoader(true);
		window.yam.getLoginStatus(function (response) {
			if (response.status === 'connected') {
				setYammerConnection(true);
				setLoginStatusChecked(true);
			} else {
				setShowLoader(false);
				setLoginStatusChecked(true);
			}
		});
	};

	const getCurrentUserLikeStatus = (liked_by_useres) => {
		for (const user_detail of liked_by_useres) {
			if (user_detail.user_id === currentUser.id) {
				return true;
			}
		}
		return false;
	};
	const timeSince = (date) => {
		const seconds = Math.abs(Math.floor((Date.now() - new Date(date)) / 1000));
		const secondsInYear = 31536000;
		let interval = Math.floor(seconds / secondsInYear);

		if (interval > 1) {
			return interval + ' years';
		}
		const secondsInMonth = 2592000;
		interval = Math.floor(seconds / secondsInMonth);
		if (interval > 1) {
			return interval + ' months';
		}
		const secondsInDay = 86400;
		interval = Math.floor(seconds / secondsInDay);
		if (interval > 1) {
			return interval + ' days';
		}
		const secondsInHour = 3600;
		interval = Math.floor(seconds / secondsInHour);
		if (interval > 1) {
			return interval + ' hours';
		}
		const secondsInMinute = 60;
		interval = Math.floor(seconds / secondsInMinute);
		if (interval > 1) {
			return interval + ' minutes';
		}
		return Math.floor(seconds) + ' seconds';
	};
	const postConversation = (message, replied_to_id, group_id, network_id) => {
		const data = {
			body: message,
			replied_to_id: replied_to_id,
			group_id: group_id,
			network_id: network_id,
		};
		window.yam.platform.request({
			url: `https://api.yammer.com/api/v1/messages.json`,
			method: 'POST',
			async: false,
			data: data,
			success() {
				track(1, 701, activityId, activityName, {
					comment: message,
					complexityId: complexityId,
				});
				setTimeout(() => {
					yammerFeed();
					setInputBoxId(-1);
				}, 500);
			},
		});
	};
	const postToParentConversation = (replied_to_id) => {
		if (ishashTagAvailable) {
			const data = {
				body: text,
				replied_to_id: replied_to_id,
			};
			window.yam.platform.request({
				url: `https://api.yammer.com/api/v1/messages.json`,
				method: 'POST',
				async: false,
				data: data,
				success() {
					track(1, 701, activityId, activityName, {
						comment: text,
						complexityId: complexityId,
					});
					setShowPostLoader(true);
					setTimeout(() => {
						yammerFeed();
						setInputBoxId(-1);
						setText('');
					}, 500);
				},
			});
		} else {
			createHashtag();
		}
	};
	const login = () => {
		window.yam.platform.login(function (response) {
			//prompt user to login and authorize your app, as necessary
			if (response.authResponse) {
				setYammerConnection(true);

			}
		});
	};
	const logout = () => {
		window.yam.platform.logout(function (response) {

			//prompt user to login and authorize your app, as necessary
			if (response.authResponse || response === true) {
				setYammerConnection(false);
				setChildYammerChat({});
				setParentYammerChat({});
				setCommentCount({});
				setUsers({});
				setParentId(-1);
				setInputBoxId(-1);
				setOpenChatIds([]);
			}
		});
	};
	const updateUserImage = (sender_id) => {
		const image = uiavatars.generateAvatar({
			uppercase: true,
			name: users[sender_id].name,
			fontsize: 0.5,
			bold: true,
			length: 2,
			rounded: true,
			size: 250,
		});
		setUsers((prevState) => ({
			...prevState,
			[sender_id]: { name: users[sender_id].name, image: image },
		}));
	};
	const updateCurrentUserImage = () => {
		const image = uiavatars.generateAvatar({
			uppercase: true,
			name: currentUser.name,
			fontsize: 0.5,
			bold: true,
			length: 2,
			rounded: true,
			size: 250,
		});
		setCurrentUser({
			id: currentUser.id,
			image: image,
			name: currentUser.name,
		});
	};
	const sendLikeRequest = (messageid, liked_by_useres) => {
		let current_user_liked = false;
		for (const user_detail of liked_by_useres) {
			if (user_detail.user_id === currentUser.id) {
				current_user_liked = true;
				break;
			}
		}
		if (current_user_liked) {
			track(1, 701, activityId, activityName, {
				complexityId: complexityId,
				messageId: messageid,
				liked: 'false',
			});
			window.yam.platform.request({
				url: `https://api.yammer.com/api/v1/messages/liked_by/current.json?message_id=${messageid}`,
				method: 'DELETE',
				async: false,
			});
			yammerFeed();
		} else {
			window.yam.platform.request({
				url: `https://api.yammer.com/api/v1/messages/liked_by/current.json?message_id=${messageid}`,
				method: 'POST',
				async: false,
				success() {
					track(1, 701, activityId, activityName, {
						complexityId: complexityId,
						messageId: messageid,
						liked: 'true',
					});
					yammerFeed();
				},
			});
		}
	};

	const showChildMessage = (message) => {
		if (childYammerChat[message.id].length > 0) {
			return childYammerChat[message.id].map((childMessage) => (
				<React.Fragment key={childMessage.id}>
					<div className="yammerPersonaHeader">
						<span className="yammerPersonaImage">
							<img
								src={users[childMessage.sender_id].image}
								onError={() => updateUserImage(childMessage.sender_id)}
								alt="img"
							/>
						</span>
						<span className="yammerPersonaTitleAndMessage">
							<span className="yammerTitlePartDiv">
								<span className="yammerPersonaTitle">
									{users[childMessage.sender_id].name}
								</span>
								<span className="yammerPersonaTime">
									{timeSince(childMessage.created_at)} ago
								</span>
							</span>
							<div className="yammerUserMessage">{childMessage.text}</div>
						</span>
					</div>

					<div className="yammerActivities">
						<span className="yammerLike">
							<Icon
								iconName={
									getCurrentUserLikeStatus(childMessage.liked_by.names)
										? `LikeSolid`
										: `Like`
								}
								onClick={() =>
									sendLikeRequest(childMessage.id, childMessage.liked_by.names)
								}
							/>
							<span>
								{childMessage.liked_by.count > 0 ? (
									childMessage.liked_by.count
								) : (
									<></>
								)}
							</span>
						</span>
						<span className="yammerPersonaMessage">
							<Icon
								onClick={() => {
									if (inputBoxId !== childMessage.id) {
										if (!openedChatIds.includes(childMessage.id)) {
											setOpenChatIds([...openedChatIds, childMessage.id]);
										}
										setInputBoxId(childMessage.id);
									} else {
										setInputBoxId(-1);
										if (openedChatIds.includes(childMessage.id)) {
											const filterSelectedId = openedChatIds.filter(
												(chatId) => chatId !== childMessage.id
											);
											setOpenChatIds(filterSelectedId);
										}
									}
								}}
								iconName="Chat"
							/>
							<span>
								{commentCount[childMessage.id] > 0 ? (
									commentCount[childMessage.id]
								) : (
									<></>
								)}
							</span>
						</span>
					</div>
					{inputBoxId === childMessage.id ? (
						<div className="replytoMsgContainer">
							<span className="yammerPersonaImage">
								<img
									src={currentUser.image}
									onError={() => updateCurrentUserImage()}
									alt="img"
								/>
							</span>
							<textarea
								aria-label="yammer comment box"
								placeholder="write a comment"
								className="yammerTextArea"
								required={true}
								onKeyPress={(e) => {
									if (e.which === 13 && e.target.value.trim().length > 0) {
										postConversation(
											e.target.value,
											childMessage.id,
											childMessage.group_id,
											childMessage.network_id
										);
									}
								}}
							/>
						</div>
					) : (
						<></>
					)}
					{openedChatIds.includes(childMessage.id) ? (
						showChildMessage(childMessage)
					) : (
						<></>
					)}
				</React.Fragment>
			));
		}
	};

	useEffect(() => {
		if (window.yam && tcode && loginStatusChecked && yammerConnection) {
			yammerFeed();
		}
		setText('');
	}, [
		tcode,
		window.yam,
		loginStatusChecked,
		yammerConnection,
		activityId,
		complexityId,
		filData,
	]);

	useEffect(() => {
		if (window.yam) {
			checkLoginStatus();
		}
	}, [window.yam]);

	useEffect(() => {
		getYammerTopics();
	}, [activityId]);

	return (
		<div className="activityYammerFeedContainer">
			<div className="activityYammerTitle">
				<h4>Collaboration Panel</h4>
				{yammerConnection ? (
					<span
						className="yammerLogout"
						title="Yammer Logout"
						onClick={() => logout()}
					>
						<Icon iconName="PowerButton" />
					</span>
				) : (
					<></>
				)}
			</div>
			{showLoader ? (
				<Spinner />
			) : (
				<>
					{window.yam && loginStatusChecked && !yammerConnection ? (
						<div className="yammerLoginContainer">
							<div className="yammerImageSection">
								<span className="yammerCustoImage"></span>
								<h6>Yammer</h6>
							</div>
							<p>
								Login with Yammer and participate in the discussion around this
								Topic
							</p>
							<button onClick={() => login()}>Login</button>
						</div>
					) : loginStatusChecked && yammerConnection ? (
						<div className="yammerCollaborationPanel">
							<div className="yammerMsgContentUpdate">
								<textarea
									aria-label="yammer comment box"
									className="yammerTextAreaContentUpdate"
									value={text}
									onChange={(e) => setText(e.target.value)}
									required={true}
									placeholder="Write something for this activity..."
								/>
								<button
									onClick={() => {
										if (text.trim().length > 0) {
											postToParentConversation(parentId);
										}
									}}
									title={
										text.trim().length > 0 ? 'post' : 'Write something to post'
									}
								>
									{showPostLoader ? <Spinner /> : 'Post'}
								</button>
							</div>
							{parentYammerChat[parentId] &&
								parentYammerChat[parentId].length > 0 ? (
								parentYammerChat[parentId].map((message) => (
									<React.Fragment key={message.id}>
										<div className="yammerContainer">
											<div className="yammerParentContainer">
												<div className="yammerPersonaHeader">
													<span className="yammerPersonaImage">
														<img
															src={users[message.sender_id].image}
															onError={() => updateUserImage(message.sender_id)}
															alt="img"
														/>
													</span>
													<span className="yammerPersonaTitleAndMessage">
														<span className="yammerTitlePartDiv">
															<span className="yammerPersonaTitle">
																{users[message.sender_id].name}
															</span>
															<span className="yammerPersonaTime">
																{timeSince(message.created_at)} ago
															</span>
														</span>
														<div className="yammerUserMessage">
															{message.text}
														</div>
													</span>
												</div>

												<div className="yammerActivities">
													<span className="yammerLike">
														<Icon
															iconName={
																getCurrentUserLikeStatus(message.liked_by.names)
																	? `LikeSolid`
																	: `Like`
															}
															onClick={() =>
																sendLikeRequest(
																	message.id,
																	message.liked_by.names
																)
															}
														/>
														<span>
															{message.liked_by.count > 0 ? (
																message.liked_by.count
															) : (
																<></>
															)}
														</span>
													</span>
													<span className="yammerPersonaMessage">
														<Icon
															onClick={() => {
																if (inputBoxId !== message.id) {
																	if (!openedChatIds.includes(message.id)) {
																		setOpenChatIds([
																			...openedChatIds,
																			message.id,
																		]);
																	}
																	setInputBoxId(message.id);
																} else {
																	setInputBoxId(-1);
																	if (openedChatIds.includes(message.id)) {
																		const filterSelectedId =
																			openedChatIds.filter(
																				(chatId) => chatId !== message.id
																			);
																		setOpenChatIds(filterSelectedId);
																	}
																}
															}}
															iconName="Chat"
														/>
														<span>
															{commentCount[message.id] > 0 ? (
																commentCount[message.id]
															) : (
																<></>
															)}
														</span>
													</span>
												</div>
											</div>
											<div className="yammerChildContainer">
												{inputBoxId === message.id ? (
													<div className="replytoMsgContainer">
														<span className="yammerPersonaImage">
															<img
																src={currentUser.image}
																onError={() => updateCurrentUserImage()}
																alt="img"
															/>
														</span>
														<textarea
															aria-label="yammer comment box"
															placeholder="write a comment"
															className="yammerTextArea"
															onKeyPress={(e) => {
																if (
																	e.which === 13 &&
																	e.target.value.trim().length > 0
																) {
																	postConversation(
																		e.target.value,
																		message.id,
																		message.group_id,
																		message.network_id
																	);
																}
															}}
															required={true}
														/>
													</div>
												) : (
													<></>
												)}
												{openedChatIds.includes(message.id) ? (
													showChildMessage(message)
												) : (
													<></>
												)}
											</div>
										</div>
									</React.Fragment>
								))
							) : (
								<p>Be the first one to start the conversation</p>
							)}
						</div>
					) : (
						<></>
					)}
				</>
			)}
		</div>
	);
};

export default CustomYammer;
