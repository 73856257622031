import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { getDate } from '../../helpers/Validation';
import Shapes from './Shapes';
import { useAxios } from '../../context/AxiosContext';


const PolygonPreviewModal = ({
	topicId,
	setShowPreviewModal,
	// hashTags,
	// setUserAddedHashtags,
	topicVersion,
	topicTitle,
	content,
	setShowPublishModal,
	relatedContent,
	topicOwnerInfo,
	businessRelatedContent,
	topicsData,
	setTopicsData,
	count,
	setBusinessRelatedContent,
	setRelatedContent
}) => {
	const [activeContent, setActiveContent] = useState(1)
	const [openRelatedContent, setOpenRelatedContent] = useState(false)
	const [selectedPath, setSelectedPath] = useState(0)
	const [showLinking, setLinking] = useState(false);
	const [title, setTitle] = useState(topicTitle);
	const [topicContent, setTopicContent] = useState(content);
	const { axiosGetService } = useAxios();
	const [hashTags, setHashTags] = useState();

	const getPolygonTopic = async () => {
		try {
			if (topicId !== null && topicId !== undefined) {
				await axiosGetService(`api/sdmtopics/getpolygontopicbyid/${topicId}`,).then((response) => {
					setTopicsData(response.data);
					const existingRelatedContent = response.data.filter(e => e.polygonSequence === selectedPath)[0].topicRelatedContentList
						.map((item) => {
							return {
								title: item.title,
								url: item.url,
							};
						});
					setRelatedContent(existingRelatedContent);
					const existingHastag = response.data.filter(e => e.polygonSequence === selectedPath)[0].hashTagsList;
					setHashTags(existingHastag);

				})
			}
		} catch (error) {
			console.log("Error", error);
		}
	}
	useEffect(() => {
		getPolygonTopic();
	}, [topicId, selectedPath])

	useEffect(() => {
		getBusinessRelatedContent()
	}, [selectedPath])

	const getBusinessRelatedContent = async () => {
		const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
		let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${filteredTopicId}/10/0/${false}`
		await axiosGetService(api).then(
			(response) => {
				setBusinessRelatedContent(response.data)
			})
	}

	const saveNextTopic = (category) => {
		setSelectedPath(category);
		const topicpolygon = topicsData.find((item) => item.polygonSequence === category);
		setTopicContent(topicpolygon.diagramName);
		setTitle(topicpolygon.topicName.replace(/_.*/, ''));
		// setHashTags(topicpolygon.hashTagsList);
	}
	return (
		<Modal
			className="topicPreviewAndSubmitPopUp topicBuilderPreviewPopUp 
            hexagonTopicBuilderPreviewPopUp"
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="modal-90w"
		>
			<Modal.Header>
				<Modal.Title>
					<div className="topicOwnerPreviewPopupTitle">
						<span>Preview</span>
					</div>
				</Modal.Title>
				<Icon
					className="relatedContentClose"
					iconName="ChromeClose"
					title="Close"
					onClick={() => setShowPreviewModal(false)}
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="topicsCrowdSourcingContainer topicPreview">
					<div class="topicsDescriptionDetails">
						<div className="topicDescriptionDiv row">
							<div className="col-xl-9 col-lg-9 previewModalBorder">
								<div className="">
									<h4 className="topicTitle">{title}</h4>
								</div>
								<div class="existingHashTagsOfTopic">
									{hashTags && hashTags.map((hashtag) => (
										<span className='hashtagPreview' key={hashtag.id}>{hashtag.name}</span>
									))}
								</div>
								<div>
									<div
										className="desc ck-content polygonPreviewDesc"
										id="previewDescription"
										dangerouslySetInnerHTML={{
											__html: HtmlSanitizer(topicContent),
										}}
									/>
								</div>
							</div>
							<div className='col-xl-3 col-lg-3'>
								<div className="topicsOwner">
									<div class="previewTopicsOwnerDetails">
										<img src={topicOwnerInfo.photo} alt="topicOwnerImage" />
										<div class="topicsOwnerPersonalDetails">
											<p>Owner</p>
											<a
												href={`mailto:${topicOwnerInfo.mail}`}
												linkchanged="1"
											>
												<h6>
													{topicOwnerInfo.firstName}&nbsp;
													{topicOwnerInfo.surname}
												</h6>
											</a>
										</div>
									</div>
								</div>
								{topicsData.length > 0 ?
									<div className='polygonPreviewArea'>
										<Shapes
											count={count}
											selectedPath={selectedPath}
											topicsData={topicsData}
											saveNextTopic={saveNextTopic}
											showLinking={showLinking}
											setLinking={setLinking}
										/>
									</div>
									: <></>
								}
								<div className=''>
									<div class={count === 5
										? "topicAssociateContentPanel previewAssociateSquare"
										: count === 7
											? "topicAssociateContentPanel previewAssociateHexa"
											: "topicAssociateContentPanel previewAssociateContent"}>
										<div className='topicsRelatedContentDiv'>
											<div className={activeContent === 1 ? 'topicsRelatedContentDivActive' : 'topicsRelatedContentDivAssociate'}>
												<h5 onClick={() => setActiveContent(1)}>Associated Content</h5>
											</div>
											<div className={activeContent === 2 ? 'topicsRelatedContentDivActive' : 'topicsRelatedContentDivAssociate'}>
												<h5 onClick={() => setActiveContent(2)}>Business Rule</h5>
											</div>
										</div>
										{activeContent === 1 && <>{relatedContent.length ? (
											<ul>
												{relatedContent?.map((item) => (
													<li key={item.id}>
														<div>
															<Icon iconName="LocationDot" />
															<a href={item.url} target="_blank">
																{item.title}
															</a>
														</div>
													</li>
												))}
											</ul>
										) : (
											<p className="noRelatedContentMessage">
												Content not available
											</p>
										)}</>}
										{activeContent === 2 && <>{businessRelatedContent.length ? (
											<ul>
												{businessRelatedContent?.map((item) => (
													<li key={item.id}>
														<div>
															<Icon iconName="LocationDot" />
															<a href={item.url} target="_blank">
																{item.name}
															</a>
														</div>
													</li>
												))}
											</ul>
										) : (
											<p className="noRelatedContentMessage">
												Content not available
											</p>
										)}</>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="polygonPreivewHeader">
					<div className="discardAndPreviewButton">
						<Button
							title="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowPreviewModal(false);
								}
							}}
							onClick={() => {
								setShowPreviewModal(false);
							}}
							className="activityDiscardButton"
						>
							Cancel
						</Button>
						<Button
							title="Publish"
							tabIndex={0}
							onClick={() => {
								setShowPublishModal(true);
								setShowPreviewModal(false);
							}}
							className="activityPreviewButton"
						>
							Publish
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PolygonPreviewModal;
