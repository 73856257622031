import React, { useEffect, useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import dompurify from 'dompurify';
import StarRatings from 'react-star-ratings';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import fileIcons from '../../static/TemplateIcons';
import FileDropModal from '../TemplateCard/FileDropModal';
import PreviewModal from '../TemplateCard/PreviewModal';

function TemplateCard({
	data,
	enableSearchSuggestion,
	searchSuggestionId,
	setSearchSuggestionId,
	searchTemplateCard,
	currentPage,
	complexityTag,
}) {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const htmlSanitizer = dompurify.sanitize;
	const [templateData, setTemplateData] = useState(() => {
		return data;
	});

	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	// fileDrop modal
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);

	// feedbackModal
	const [showText, setShowText] = useState(false);

	//search suggestion data for search results page
	const [searchSuggestionData, setSearchSuggestionData] = useState(null);
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(
		enableSearchSuggestion
	);

	const existingHashtags = data.hashTagsList.map(({ name }) => {
		return name;
	});

	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};

	// search suggestion
	const getSearchSuggestions = () => {
		axiosGetService(
			`api/sdmuser/getSearchRecommendations?artifactTypeName=sampleTemplates&artifactId=${data.sampleTemplateId}&activityIdentifier=0&complexityId=${complexityTag.id}`
		).then((res) => {
			setSearchSuggestionData(res.data);
		});
	};

	useEffect(() => {
		if (searchSuggestionId) {
			setShowSearchSuggestion(
				searchSuggestionId === `search_template_${data.id}_${complexityTag.id}`
			);
		} else if (enableSearchSuggestion) {
			setSearchSuggestionId(`search_template_${data.id}_${complexityTag.id}`);
		}
	}, [searchSuggestionId]);

	useEffect(() => {
		if (showSearchSuggestion) {
			getSearchSuggestions();
		}
	}, [showSearchSuggestion]);

	return (
		<div
			className="tabsWrapper templateTabsSearch sampleTabs"
			id={
				searchTemplateCard
					? `search_template_${data.id}_${complexityTag.id}`
					: ''
			}
			onClick={() => {
				if (searchTemplateCard) {
					setSearchSuggestionId(
						`search_template_${data.id}_${complexityTag.id}`
					);
				}
			}}
			tabIndex={searchTemplateCard ? 0 : null}
			onKeyPress={(e) => {
				if (searchTemplateCard && e.which === 13) {
					setSearchSuggestionId(
						`search_template_${data.id}_${complexityTag.id}`
					);
				}
			}}
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={
							templateData.inProgress
								? fileIcons['inProgress']
								: templateData.fileType !== null
								? fileIcons[templateData.fileType.toLowerCase()] ||
								  fileIcons['defaultTemplate']
								: fileIcons['defaultTemplate']
						}
						className="imageOfSearchResult"
						alt="File Type"
					/>
				</div>
				<StarRatings
					rating={templateData.rating ? templateData.rating : 0}
					starDimension="19px"
					starRatedColor={'#ffd700'}
					starHoverColor={'#ffd700'}
					starEmptyColor={'#e9e9f0'}
					starSpacing="5px"
					svgIconViewBox="0 0 31.951 32"
					svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
				/>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					{templateData.inProgress ? (
						<span className="inProgressTemplate">{templateData.title}</span>
					) : (
						<a
							href={templateData.fileLocationNew}
							key={templateData.title}
							target="_blank"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(
										1,
										searchTemplateCard ? 403 : 112,
										templateData.id,
										templateData.title,
										{
											phaseName: templateData.phaseName,
											complexityId: templateData.complexityId,
											pageNumber: currentPage,
										}
									);
								}
							}}
							onClick={() => {
								track(
									1,
									searchTemplateCard ? 403 : 112,
									templateData.id,
									templateData.title,
									{
										phaseName: templateData.phaseName,
										complexityId: templateData.complexityId,
										pageNumber: currentPage,
									}
								);
							}}
						>
							{templateData.title}
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Download</span>
							</div>
						</a>
					)}
					{['zip', 'aspx', 'html', null].includes(templateData.fileType) ||
					templateData.inProgress ? (
						<button tabIndex={-1} title="Disabled">
							<span className="previewIconDisabled"></span>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Disabled</span>
							</div>
						</button>
					) : (
						<button
							tabIndex={0}
							aria-label="Preview"
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleTemplatePreview(templateData);
									track(
										1,
										searchTemplateCard ? 407 : 126,
										templateData.id,
										templateData.title,
										{
											phaseName: templateData.phaseName,
											complexityId: templateData.complexityId,
											pageNumber: currentPage,
										}
									);
								}
							}}
							onClick={() => {
								handleTemplatePreview(templateData);
								track(
									1,
									searchTemplateCard ? 407 : 126,
									templateData.id,
									templateData.title,
									{
										phaseName: templateData.phaseName,
										complexityId: templateData.complexityId,
										pageNumber: currentPage,
									}
								);
							}}
							title="Preview"
						>
							<span className="previewIcon"></span>
							<span className="previewIconBlack"></span>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Preview</span>
							</div>
						</button>
					)}
				</div>

				<div class="existingHashTagsOfTopic">
					{showText &&
						existingHashtags.map((name) => (
							<span
								key={name}
								onClick={() => {
									history.push(
										`/search?searchParam=${encodeURIComponent(name)}`
									);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}
								}}
							>
								{name}
							</span>
						))}
					{!showText &&
						existingHashtags.slice(0, 4).map((name) => (
							<span
								key={name}
								onClick={() => {
									history.push(
										`/search?searchParam=${encodeURIComponent(name)}`
									);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}
								}}
							>
								{name}
							</span>
						))}
				</div>
				{existingHashtags.length === 0 ? (
					<p className="noHashtagsDescription">
						No hashtags have been added to this sample{' '}
					</p>
				) : (
					<></>
				)}

				{showText ? (
					<>
						<div className="descriptionOfTabs">
							<p
								dangerouslySetInnerHTML={{
									__html:
										!templateData.tileDescription ||
										(templateData.tileDescription &&
											templateData.tileDescription.length === 0)
											? htmlSanitizer('To be updated')
											: htmlSanitizer(templateData.tileDescription),
								}}
							></p>
						</div>
						<div className="typeAndPubDataOfTemplate">
							{/* <div className="typeOfTemplate">
								<span>Type: </span>
								<span>{data.templateType}</span>
							</div> */}
							<div className="publishedDateOfTemplate">
								<span>Published Date: </span>
								<span>{data.date}</span>
							</div>
						</div>
					</>
				) : (
					<></>
				)}
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						<div className="phaseDetails">
							<span className="phaseLabel">Phase: </span>
							<span className="phaseName">{templateData.phaseName}</span>
							<div
								className="tabExpandedViewIcon"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								onClick={() => setShowText(!showText)}
								title="Expand Template"
								aria-expanded={showText}
								role="button"
								aria-label={
									showText
										? 'Click to Collapse Sample Template information'
										: 'Click to expand  Sample Template information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div>
						</div>
					</div>
					<div className="tabsRightWrapper">
						<div className="tabContributer">
							<div
								className={'contributerButton'}
								tabIndex={0}
								onClick={() => {
									setEnableFileDropModal(true);
								}}
								onKeyPress={(e) => {
									setEnableFileDropModal(true);
								}}
								title="Contribute Sample"
								aria-haspopup="Dialog"
								role="button"
							>
								<Icon iconName="ReleaseGate" />
								<div className="tabsTitleToolTip">
									<span className="tabsToolTipArrow"></span>
									<span>Contribute Sample</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{enableTemplatePreview ? (
				<PreviewModal
					enableTemplatePreview={enableTemplatePreview}
					closeModal={() => setEnableTemplatePreview(false)}
					data={templatePreviewData}
				/>
			) : (
				<></>
			)}

			{enableFileDropModal ? (
				<FileDropModal
					enableFileDropModal={enableFileDropModal}
					closeModal={() => setEnableFileDropModal(false)}
					data={templateData}
				/>
			) : (
				<></>
			)}

			{searchSuggestionData && showSearchSuggestion ? (
				<div className="relatedSearchSuggestion">
					<span className="tabsbeakArrow"></span>
					<h6>
						Activities that produce this template
						<br />
						<span>{`"${templateData.title}"`}</span>
					</h6>
					{searchSuggestionData.activities &&
					searchSuggestionData.activities.length > 0 ? (
						<>
							<div className="titleOfSuggestionWrap"></div>
							<ul className="activityRecommandations">
								{searchSuggestionData.activities.map((activity) => (
									<li
										tabIndex="0"
										key={activity.id + '_' + activity.complexityId}
									>
										<div className="relatedTitles">
											<img
												src={fileIcons.activity}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(
														1,
														165,
														activity.activityIdentifier,
														activity.activityName,
														{
															suggestedItemName: 'Activity',
															suggestedItemId: 2,
															suggestedComplexity: activity.complexityName,
														}
													);
												}}
												title={activity.activityName}
												aria-label={activity.activityName}
												to={`/project/${activity.projectTypeId}/method/${
													activity.methodId
												}/phase/${
													activity.phaseId ? activity.phaseId : 'null'
												}/parentPhase/${
													activity.parentPhaseId
														? activity.parentPhaseId
														: 'null'
												}/activity/${activity.activityIdentifier}?complexity=${
													activity.complexityId
												}`}
											>
												<span>{activity.activityName}</span>
											</NavLink>
										</div>
										<div className="relatedPhaseName">
											Phase:{' '}
											{activity.solutionMethodName +
												'-' +
												activity.activityPhaseName}
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Activities produce this Template
						</span>
					)}
				</div>
			) : null}
		</div>
	);
}
export default TemplateCard;
