// Import the AES-256 key
export const importKey = async (keyBuffer) => {
    return await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-CBC', length: 256 },
      false,
      ['encrypt', 'decrypt']
    );
  };
  
  // Encrypt data using AES-256
  export const encryptData = async (dataBuffer, key, ivBuffer) => {
    return await crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: new Uint8Array(ivBuffer) },
      key,
      dataBuffer
    );
  };
  
  // Decrypt data using AES-256
  export const decryptData = async (encryptedBuffer, key, ivBuffer) => {
    return await crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: new Uint8Array(ivBuffer) },
      key,
      encryptedBuffer
    );
  };
  