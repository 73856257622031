import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../../context/AxiosContext';

const ApplicationAverage = ({ setMaximizeEntity, maximizeEntity }) => {
	const { axiosGetService } = useAxios();
	const [myPerformanceTrendsData, setMyPerformanceTrendsData] = useState({
		userPoints: [],
		averageApplicationPoints: [],
	});

	const getMyPerformanceData = async () => {
		setMyPerformanceTrendsData({
			userPoints: [],
			averageApplicationPoints: [],
		});
		await axiosGetService(
			`api/sdmuser/getaverageapplicationgraphdata?isLastTwelveMonths=${
				maximizeEntity === 'applicationAverage' ? true : false
			}&isDaily=${false}&isWeekly=${
				maximizeEntity !== 'applicationAverage' ? true : false
			}`
		).then((response) => {
			setMyPerformanceTrendsData(response.data);
		});
	};

	const xAxisLabel = (type) => {
		const weeklyLabels = myPerformanceTrendsData[type].map(
			({ weekStartDate }) => {
				return weekStartDate + ' ';
			}
		);
		return weeklyLabels;
	};

	const getGraphData = (type) => {
		const weeklyGraphData = myPerformanceTrendsData[type].map(({ points }) => {
			return points;
		});

		return weeklyGraphData;
	};

	const getStepSize = () => {
		const averageApplicationPoints = myPerformanceTrendsData[
			'averageApplicationPoints'
		].map(({ points }) => {
			return points;
		});
		const userPoints = myPerformanceTrendsData['userPoints'].map(
			({ points }) => {
				return points;
			}
		);
		const stepSize = Math.ceil(
			Math.max(Math.max(...averageApplicationPoints), Math.max(...userPoints)) /
				(maximizeEntity === 'applicationAverage' ? 10 : 5)
		);
		return averageApplicationPoints.length > 0 || userPoints.length > 0
			? stepSize
			: 0;
	};

	const graphData = {
		labels: xAxisLabel('averageApplicationPoints'),
		datasets: [
			{
				label: 'Your performance',
				data: getGraphData('userPoints'),
				fill: false,
				backgroundColor: '#007bff',
				borderColor: '#007bff',
				borderWidth: 1,
			},
			{
				label: 'Application average',
				data: getGraphData('averageApplicationPoints'),
				fill: false,
				backgroundColor: '#f58220',
				borderColor: '#f58220',
				borderWidth: 1,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		scales: {
			x: {
				title: {
					display: false,
					text: 'Month',
				},
				grid: {
					display: false,
				},
				ticks: {
					//  maxTicksLimit: maximizeEntity === 'applicationAverage' ? 12 : 11,
				},
			},
			y: {
				ticks: {
					stepSize: getStepSize(),
				},
				beginAtZero: true,
				title: {
					display: true,
					text: 'Points',
					font: {
						weight: 500,
						family: "'Segoe UI','Segoe UI'",
					},
				},
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			text: '',
		},
		backgroundColor: '#f8f9fa',
	};

	useEffect(() => {
		if (maximizeEntity === 'applicationAverage' || !maximizeEntity) {
			getMyPerformanceData();
		}
	}, [maximizeEntity]);

	return (
		<div
			className={
				maximizeEntity === 'applicationAverage'
					? 'myAchievementsDashboardTile maximized'
					: 'myAchievementsDashboardTile'
			}
		>
			<div className="dashBoardGraphExpand">
				<Icon
					iconName={
						maximizeEntity !== 'applicationAverage'
							? 'FullScreen'
							: 'BackToWindow'
					}
					onClick={() => {
						if (!maximizeEntity) {
							setMaximizeEntity('applicationAverage');
						} else {
							setMaximizeEntity('');
						}
					}}
				/>
			</div>
			<div className="dashBoardGraphContainer avgAppliactionsGraph">
				<div className="dashBoardTitlePart">
					<h6>With Application average</h6>
				</div>
				<p>
					<div class="graphLegends">
						<span class="graphLegendsColorIndicator"></span>
						<span>Your performance</span>
					</div>
					<div class="graphLegends">
						<span class="graphLegendsColorIndicator appsAvg"></span>
						<span>Application average</span>
					</div>
				</p>
				<div className="myPerformanceTrendsGraph">
					<Line data={graphData} options={options} />
				</div>
			</div>
		</div>
	);
};

export default ApplicationAverage;
