import React, { useState, useEffect } from 'react';
import { getUserImage } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	value,
	setValue,
	type,
	error,
	emailFound,
	setUpdateApiCall,
	updateApiCall,
	pageType
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (dropdown && !dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const checkUserImage = async (item) => {
		const accessToken = await axiosTokenService();
		await getUserImage(accessToken, item.mail, item.firstName, item.surname).then((response) => {
			item.photo = response.photo;
			setValue([...value, item]);
			if(updateApiCall !== undefined){
				setUpdateApiCall(true);
			}
		});
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = value.filter(({ mail }) => mail === item.mail);
		if (filteredData.length > 0) {
			const updatedData = value.filter(({ mail }) => mail !== item.mail);
			setValue(updatedData);
			if(updateApiCall !== undefined){
			setUpdateApiCall(true);}
		} else {
			checkUserImage(item);
		}
	};

	const findSelectedTools = (checkId) => {
		const filterId = value.filter(({ mail }) => mail === checkId);
		if (filterId.length > 0) {
			return true;
		}
		return false;
	};

	return (
		<div title={enableDropdown ? className : ''} id={className}>
			{error['coOwner'] && <p>Invalid Email</p>}
			<input
				onChange={(e) => setSearchValue(e.target.value)}
				value={searchValue}
				onFocus={() => setVisible(true)}
				placeholder="Enter name or email to search"
				aria-label="Enter name or email to search"
			/>
			{emailFound && visible ? <span className="complexityLegend"></span> : <></>}

			<div className={`dropDowncontainer ${className} ${enableDropdown ? '' : 'disableDropdown'}`}>
				<div>
					{data[type].length > 0 ? (
						<div  className={
							pageType === "topics"
							  ? "dropDownList polygonUserDropdown"
							  : pageType === "Activity"
							  ? "dropDownList userActivityDropdown"
							  : "dropDownList coOwner"
						  } style={{ display: visible ? 'block' : '' }}>
							<ul className={pageType == "topics" ? 'polygonDropdownStyle':''}>
								{data[type].map((item) => {
									if (item.type === 'header') {
										return (
											<li className="dropdownHeader" title={item.mail + ' Header'} key={item.mail}>
												<span>{item.displyaName}</span>
											</li>
										);
									}

									return (
										<li title={item.mail} key={item.mail}>
											<label className="NotificationChkboxContainer">
												<input
													name={item.name}
													type="checkbox"
													checked={findSelectedTools(item.mail)}
													onChange={() => handleDropdown(item)}
												/>
												<span className="CustomCheckmark"></span>
												<span className="complexityLabel userName">
													{item.firstName + ' ' + item.surname}
												</span>
												<span className="userEmail">{item.mail}</span>
											</label>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						<>
							{/* {searchValue ? (
								<div className="dropDownList coOwner" style={{ display: visible ? 'block' : '' }}>
									<ul>
										<li>No result found.</li>
									</ul>
								</div>
							) : (
								<></>
							)} */}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
