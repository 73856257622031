import React, { memo } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useRaciGrid } from './RaciContext';
import { useAppInsights } from '../../context/TelemetryContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from 'react';

const complexityCountToClassMapper = {
	1: 'complexity1',
	2: 'complexity2',
	3: 'complexity3',
	4: 'complexity4',
};
const RaciGridHeader = memo(({ raciGridData, roleGroupsData, setRoleGroupsData }) => {
	const [totalPhaseCount, setTotalPhaseCount] = useState(0);
	const [totalGroupCount, setTotalGroupCount] = useState(0);
	const [totalMethodCount, setTotalMethodCount] = useState(0);
	const complexityCount = Object.keys(roleGroupsData).reduce(
		(currentVal, complexityId) => {
			if (roleGroupsData[complexityId].isSelected) {
				return currentVal + 1;
			} else {
				return currentVal;
			}
		},
		0
	);
	const getTotalActivityCount = () => {
		let totalActivityCount = 0;
		raciGridData.map(({ phases }) => {
			phases.map(({ activities }) => {
				if (!activities.isRefered) {
					activities.map((group) => {
						if (group.hasOwnProperty('groupId') && !group.isRefered) {
							group.activities.map((activity) => {
								if (activity.hasOwnProperty('subGroupId') && !activity.isRefered) {

									totalActivityCount += getActivityCount(activity)
								}
								else {

									totalActivityCount += getActivityCount(activity)
								}
							})
						}
						else {
							totalActivityCount += 1

						}
					})
				}
			})
		})
		return totalActivityCount;
	}
	const getActivityCount = (activityData) => {
		let totalActivityCount = 0;
		if (activityData.activityId && !activityData.isRefered) {
			return 1;
		}
		if (activityData.solutionMethodId && !activityData.isRefered) {
			for (const phase of activityData.phases) {
				if (!phase.isRefered) {
					totalActivityCount += getActivityCount(phase);
				}
			}
		} else {
			if (activityData?.activities) {
				for (const activity of activityData?.activities) {
					if (!activity?.isRefered) {
						totalActivityCount += getActivityCount(activity);
					}

				}
			}

		}
		return totalActivityCount;
	};
	const getSubGroupCount = () => {
		let totalSubGroupCount = 0;
		raciGridData.map(({ phases }) => {
			phases.map(({ activities }) => {
				if (!activities.isRefered) {
					activities.map((group) => {
						if (!group.isRefered && group.hasOwnProperty('groupId')) {
							group.activities.map((activity) => {
								activity.hasOwnProperty('subGroupId') && !activity.isRefered ? totalSubGroupCount += 1 : totalSubGroupCount += 0;
							})
						}
					})
				}
			})
		})
		return totalSubGroupCount;

	}
	const getGroupCount = () => {
		let totalGroupCount = 0;
		raciGridData.map(({ phases }) => {
			phases.map(({ activities }) => {
				if (!activities.isRefered) {
					activities.map((group) => {
						!group.isRefered && group.hasOwnProperty('groupId') ? totalGroupCount += 1 : totalGroupCount += 0;

					})
				}
			})
		})
		return totalGroupCount;

	}
	const entityCount = () => {
		const selectedMethods = raciGridData.filter((solutionMethod) => solutionMethod.isSelected && !solutionMethod.isReferred);
		const methodCount = selectedMethods.length;

		setTotalMethodCount(methodCount);
		const phaseCount = selectedMethods.reduce(
			(totalPhases, solutionMethod) =>
				totalPhases + solutionMethod.phases.filter((phase) => phase.isSelected && !phase.isRefered).length, 0);
		setTotalPhaseCount(phaseCount);


	}

	useEffect(() => {
		entityCount();
	}, [raciGridData])


	return (
		<Row>
			<Col md="auto" id='box-section'>
				<div className="box">
					<div className="box1"><span className='solutionMethodName'>Methodology</span> <span>({totalMethodCount})</span></div>
					<div className="box1 box2"><span className='solutionMethodName'>Phase</span> <span>({totalPhaseCount})</span></div>
					<div className="box1 box3"><span className='solutionMethodName'>Group</span> <span>({getGroupCount()})</span></div>
					<div className="box1 box4"><span className='solutionMethodName'>Subgroup</span> <span>({getSubGroupCount()})</span></div>
					<div className="box1 box5"><span className='solutionMethodName'>Activity</span> <span>({getTotalActivityCount()})</span></div>
				</div>
			</Col>

			<Col md="auto" id='header-complexity'>
				<div
					className={`genericTableComplexityMenu ${complexityCountToClassMapper[complexityCount]}`}
				>
					{Object.keys(roleGroupsData).map(
						(complexityId) =>
							roleGroupsData[complexityId]?.isSelected && (
								<RaciGridHeaderComplexity
									roleGroupsData={roleGroupsData}
									setRoleGroupsData={setRoleGroupsData}
									complexityId={complexityId}
									key={`headerComplexity_${complexityId}`}
									complexityCount={complexityCount}
								/>
							)
					)}
				</div>
			</Col>
		</Row>
	);
});

const RaciGridHeaderComplexity = memo(
	({ roleGroupsData, setRoleGroupsData, complexityId, complexityCount }) => {
		const { track } = useAppInsights();
		const { setSelectedComplexites, selectedComplexites } = useRaciGrid();
		const { complexityName, roleGroups, roles, isExpanded } =
			roleGroupsData[complexityId];
		const setComplexityLevelProperty = (propertyName, propertyValue) => {
			const newValue = { ...roleGroupsData };
			newValue[complexityId][propertyName] = propertyValue;
			setRoleGroupsData(newValue);
		};
		const firstSelected = Object.keys(roleGroups).find(
			(roleGroupId) => roleGroups[roleGroupId].isSelected
		);
		return (
			<div
				className={`genericTableComplexityMenuContainer 
				${isExpanded ? 'complexityExpanded' : 'complexityCollapsed'} 
				${roleGroups[firstSelected]?.isExpanded === false ? 'firstCollapsed' : ''}`}
			>
				<div className="genericTableComplexityMenuHeader">
					<div>
						<div>{complexityName}</div>
						{complexityCount > 1 && (
							<Icon 
							tabIndex={0}
							aria-label={`${complexityName}" Complexity Dismiss"`}
								iconName="Clear"
								role="button"
								onClick={() => {
									setSelectedComplexites(
										selectedComplexites.filter(
											(id) => id !== Number(complexityId)
										)
									);
								}}
							/>
						)}
					</div>
					<Icon tabIndex="0"
					aria-label={complexityName}
						iconName={
							isExpanded ? 'DoubleChevronLeft12' : 'DoubleChevronRight12'
						}
						aria-expanded="true"
						role="button"
						onKeyPress={e => {
							if (e.key === "Enter") {
								setComplexityLevelProperty('isExpanded', !isExpanded);
								track(1, 2104, complexityId, complexityName);
							}
							}}
						onClick={() => {
							setComplexityLevelProperty('isExpanded', !isExpanded);
							track(1, 2104, complexityId, complexityName);
						}}
					/>
				</div>
				<div
					className={`genericTableComplexityMenuSub ${isExpanded ? 'complexityExpanded' : 'complexityCollapsed'
						}`}
					data-complexityid={complexityId}
				>
					{Object.keys(roleGroups).map((roleGroupId) => {
						if (roleGroups[roleGroupId].isSelected && isExpanded)
							return (
								<RaciRoleGroups
									roleGroupsData={roleGroupsData}
									setRoleGroupsData={setRoleGroupsData}
									complexityId={complexityId}
									roleGroupId={roleGroupId}
									key={`headerRoleGroup_${complexityId}_${roleGroupId}`}
								/>
							);
					})}
					{roles.length > 0 && (<div className="genericTableComplexityRolesMenu nonRoleGroups">
						<div>
							{isExpanded &&
								roles.map((role) => (
									<div
										className="genericTablePreview"
										key={`headerRole_${complexityId}_${role.roleId}`}
										tabIndex={0}
									>
										<div className="genericTableValue" title={role.roleName}>
											{role.roleName}
										</div>
									</div>
								))}
						</div>
					</div>)}

				</div>
			</div>
		);
	}
);
const RaciRoleGroups = memo(
	({ roleGroupsData, setRoleGroupsData, complexityId, roleGroupId }) => {
		const { name, roles, isExpanded } =
			roleGroupsData[complexityId].roleGroups[roleGroupId];
		const { track } = useAppInsights();
		const setRoleGroupLevelProperty = (propertyName, propertyValue) => {
			const newValue = { ...roleGroupsData };
			newValue[complexityId].roleGroups[roleGroupId][propertyName] =
				propertyValue;
			setRoleGroupsData(newValue);
		};
		return (
			<div
				className={`genericTableComplexityRolesMenu 
				${isExpanded ? 'roleGroupExpanded' : 'roleGroupCollapsed'}
				${roles.length <= 1 ? 'shortOneRoleMenu' : ''} 
				${roles.length == 2 ? 'shortTwoRoleMenu' : ''} `}
			>
		
				<div className="genericTableComplexityMenuHeader" tabIndex={0}>
					<div title={name}>
						<div>{name} </div>
						{/* <Icon
							iconName="Clear"
							onClick={() => setRoleGroupLevelProperty('isSelected', false)}
							className="roleGroupClear"
						/> */}
					</div>
					{/* <Icon tabIndex="0"
						className={Object.keys(roleGroupsData[complexityId].roleGroups).length === 1 ? 'disableIcon' : ''}
						iconName={
							isExpanded ? 'DoubleChevronLeft12' : 'DoubleChevronRight12'
						}
						onClick={() => {
							setRoleGroupLevelProperty('isExpanded', !isExpanded);
							track(1, 2105, roleGroupId, name);
						}}
					/> */}
				</div>
				<div>
					{isExpanded &&
						roles.map((role) => (
							<div
								className="genericTablePreview"
								key={`headerRole_${complexityId}_${roleGroupId}_${role.roleId}`}
								tabIndex={0}
							>
								<div className="genericTableValue" title={role.roleName}>
									{role.roleName}
								</div>
							</div>
						))}
				</div>
			</div>
		);
	}
);
export default RaciGridHeader;
