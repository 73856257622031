import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/Spinner';

const TemplateDeleteModal = ({
	enableTemplateDeleteModal,
	setEnableTemplateDeleteModal,
	templateInfo,
	setTemplateInfo,
	getTemplates,
}) => {
	const { axiosDeleteService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(false);

	const reset = () => {
		setEnableTemplateDeleteModal(false);
		setTemplateInfo({});
	};

	const deleteTemplate = async () => {
		setShowSpinner(true);
		await axiosDeleteService(
			`/api/sdmtemplate/deletetemplate?templateId=${templateInfo.id}&complexityId=0`
		).then((response) => {
			setShowSpinner(false);
			if (response.data.status) {
				toast.info(`You have successfully deleted ${templateInfo.title}`, {
					position: 'top-right',
				});
				getTemplates();
				reset();
			} else {
				toast.info(response.data.errorMsg, {
					position: 'top-right',
				});
				getTemplates();
				reset();
			}
		});
	};
	return (
		<Modal
			className="templateRejectModalPopUp templateDeleteModal"
			show={enableTemplateDeleteModal}
			onHide={() => {
				reset();
			}}
			size="md"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					<span>Delete Templates</span>
					<div>
						<Icon
							iconName="Cancel"
							onClick={() => {
								setEnableTemplateDeleteModal(false);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setEnableTemplateDeleteModal(false);
								}
							}}
							role="button"
							aria-label="Close Delete Templates"
							tabIndex={0}
						/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showSpinner ? (
					<p>
						<Spinner />
					</p>
				) : (
					<>
						<p>
							{parseInt(templateInfo.mappedActivitiesCount, 10) > 0 &&
								`This template is mapped to ${templateInfo.mappedActivitiesCount} Activities.`}
							Are you sure that you want to delete the template{' '}
							{templateInfo.title}?
						</p>
						<div className="templateDeleteButtons">
							<button
								onClick={() => {
									reset();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										reset();
									}
								}}
								role="button"
								aria-label="Cancel"
								tabIndex={0}
							>
								Cancel
							</button>
							<button
								onClick={() => {
									deleteTemplate();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										deleteTemplate();
									}
								}}
								role="button"
								aria-label="Delete"
								tabIndex={0}
							>
								Delete
							</button>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default TemplateDeleteModal;
