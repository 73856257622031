const ProdBackupConfig = {
    clientId: 'fd65f274-dc0b-40f3-912e-fad9c3367c31',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://sdmplusbackup2.azurewebsites.net/',
    apiScopes: ['https://microsoft.onmicrosoft.com/ApiSdmplusbackup/.default'],
    copilotApiScope: ['https://vww-apim.azure-api.net' + '/.default'],
    copilotApiURL: 'https://api.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://apisdmplusbackup.azurewebsites.net/',
    instrumentationKey: 'e2881c05-8127-4288-a79d-ded712715cb2',
    solutionMethodIconsUrl: 'https://sdmplusprodstorage.z19.web.core.windows.net',
    sdm1URL: 'https://sdmplusbackup.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://sdmplusbackup2.azurewebsites.net',
    appInsightsAPIKey: '3znpzx11p66qs2ue18gfpw22kz0vsnjegfmzguhg',
    appInsightsAppId: 'bb03c284-5982-4bb1-bac2-e43ddcda90a0',
    yammerGroupId: '20349108224',
    yammerInstance: 'Prod',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - Prod Back Up',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'true',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusVideoTutorials/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey'
};
export default ProdBackupConfig;
