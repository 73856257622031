import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import { useHistory, useLocation } from 'react-router';

const AdminManageProcessGroupModal = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [processGroups, setProcessGroups] = useState([]);
	const [deletedProcessGroups, setDeletedProcessGroups] = useState([]);
	const [expandedProcessGroups, setExpandedProcessGroups] = useState(false);
	const [newProcessGroup, setNewProcessGroup] = useState(null);
	const [updatedProcessGroupIds, setUpdatedProcessGroupIds] = useState(
		new Set()
	);
	const { axiosGetService, axiosPostService } = useAxios();
	const [selectedProccessGroup, setSelectedProccessGroup] = useState({});
	const saveProcessGroups = async () => {
		const updatedProcessGroups = processGroups.filter(
			(processGroup) =>
				updatedProcessGroupIds.has(processGroup.id) || processGroup.id === 0
		);
		if (
			updatedProcessGroups.some(
				(processGroup) => getProcessGroupMessage(processGroup) != ''
			)
		) {
			toast.error('Some Process Groups are not valid', {
				position: 'top-right',
			});
		} else if (
			new Set(
				processGroups.map((processGroup) =>
					processGroup.name.toLowerCase().trim()
				)
			).size !== processGroups.length
		) {
			toast.error('Some Process Groups have duplicate values', {
				position: 'top-right',
			});
		} else {
			const deletedProcessGroupIds = deletedProcessGroups
				.map((processGroup) => processGroup.id)
				.join();
			const response = await axiosPostService(
				'api/sdmprocessgroups/updateprocessgroupsforadmin',
				{
					updatedProcessGroups,
					deletedProcessGroupIds,
				}
			);
			if (response.data.status) {
				toast.info('Process Groups updates successfully', {
					position: 'top-right',
				});
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
			history.push(pathname);
		}
	};

	const addProcessGroup = () => {
		if (getProcessGroupMessage(newProcessGroup) === '') {
			if (
				processGroups.some(
					(tool) =>
						tool.name.toLowerCase().trim() ===
						newProcessGroup.name.toLowerCase().trim()
				)
			) {
				toast.error('Process Group already exists', {
					position: 'top-right',
				});
			} else {
				setProcessGroups([
					{ ...newProcessGroup, id: 0, canBeDeleted: true },
					...processGroups,
				]);
				setNewProcessGroup({ name: '' });
			}
		} else {
			toast.error(getProcessGroupMessage(newProcessGroup), {
				position: 'top-right',
			});
		}
	};
	const deleteProcessGroup = (processGroupIndex) => {
		if (processGroups[processGroupIndex].canBeDeleted) {
			if (processGroups[processGroupIndex].id != 0) {
				setDeletedProcessGroups([
					...deletedProcessGroups,
					processGroups[processGroupIndex],
				]);
			}
			const newProcessGroups = [...processGroups];
			newProcessGroups.splice(processGroupIndex, 1);
			setProcessGroups(newProcessGroups);
		} else {
			toast.error('Process Group linked to activity', {
				position: 'top-right',
			});
		}
	};
	const getProcessGroupMessage = (processGroup) => {
		if (processGroup.name.length === 0)
			return 'Process Group name cannot be empty';
		return '';
	};
	const updateProcessGroup = (processGroupIndex, propetyName, newValue) => {
		const newProcessGroups = [...processGroups];
		newProcessGroups[processGroupIndex][propetyName] = newValue;
		setProcessGroups(newProcessGroups);
	};
	useEffect(() => {
		axiosGetService(`api/sdmprocessgroups/getallforactivity`).then(
			(response) => {
				setProcessGroups(response.data);
			}
		);
		setNewProcessGroup({ name: '' });
	}, []);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Process Groups
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer manageProcessGroup">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li class="activePhaseTab">
										<span>Edit Process Groups</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div class="roleAdditionContainer">
											<div class="addRoleButtonAction">
												<div
													class="selectAddRoleButton"
													onClick={() =>
														setExpandedProcessGroups(!expandedProcessGroups)
													}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setExpandedProcessGroups(!expandedProcessGroups);
														}
													}}
													aria-label="Add New Tool"
													role="button"
													tabIndex={0}
												>
													+&nbsp;Add New Process group
												</div>
												{expandedProcessGroups ? (
													<div class="addRoleFields">
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">
																Process Group Name*
															</span>
															<input
																title=" Process group Title"
																placeholder="Enter Process group Title"
																value={newProcessGroup.name}
																onChange={(e) =>
																	setNewProcessGroup({
																		name: e.target.value,
																	})
																}
															/>
														</div>
														<div>
															<span
																class="addRoleIcon"
																tabIndex="0"
																title="Add Process group"
																onClick={addProcessGroup}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		addProcessGroup();
																	}
																}}
																aria-label="Add Process Group"
																role="button"
															></span>
														</div>
													</div>
												) : (
													<></>
												)}
											</div>
										</div>
										<div class="addRolesListContainer">
											<ul>
												<li>
													<div class="addRoleButtonAction">
														<div class="addRoleTitleEditPart">
															<h6>Process Group Name</h6>
															<div class="editRoleDetailsDiv">
																<h6>Delete</h6>
															</div>
														</div>
													</div>
												</li>
												{processGroups.map((processGroup, index) => (
													<li>
														<div class="addRoleButtonAction">
															<div class="addRoleTitleEditPart">
																<input
																	value={processGroup.name}
																	placeholder="Process Group Name"
																	onChange={(e) => {
																		updateProcessGroup(
																			index,
																			'name',
																			e.target.value
																		);
																		setUpdatedProcessGroupIds(
																			updatedProcessGroupIds.add(
																				processGroup.id
																			)
																		);
																	}}
																/>
																<div class="editRoleDetailsDiv">
																	{selectedProccessGroup?.id ===
																		processGroup.id && (
																		<div className="deleteSamplesPopUp">
																			<p>
																				Are you sure that you want to delete
																				this Process Group?
																			</p>
																			<div>
																				<button
																					onClick={() => {
																						setSelectedProccessGroup({});
																					}}
																				>
																					Cancel
																				</button>
																				<button
																					onClick={() => {
																						deleteProcessGroup(index);
																					}}
																				>
																					Delete
																				</button>
																			</div>
																		</div>
																	)}
																	<span
																		class="deleteRoleIcon"
																		title="Delete Process Group"
																		onClick={() =>
																			setSelectedProccessGroup(processGroup)
																		}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setSelectedProccessGroup(processGroup);
																			}
																		}}
																		aria-label="Select Proccess Group"
																		role="button"
																		tabIndex={0}
																	></span>
																</div>
															</div>
														</div>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="saveContinueDetailsContainer">
								<button
									class="forwardButton"
									onClick={saveProcessGroups}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveProcessGroups();
										}
									}}
								>
									<span class="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AdminManageProcessGroupModal;
