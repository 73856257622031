import React, { useState, useEffect } from 'react';
import {
	Switch,
	Route,
	useRouteMatch,
	useLocation,
	useHistory,
} from 'react-router-dom';

import TopicsContributorEditPage from '../TopicsContributorCrowdSourcing';
import TopicsReviewerEditPage from '../TopicsReviewerCrowdSourcing';
import TopicsApproverEditPage from '../TopicsApproverCrowdSourcing';
import TopicsLandingPage from '../TopicsLanding';
import TopicsDetailPage from '../TopicsDetail';
// import TopicsTutorials from '../TopicsTutorials';
// import TopicsTutorialsDetails from '../TopicsTutorialsDetails';
// import { useAxios } from '../../context/AxiosContext';

function Topics() {
	// const { pathname } = useLocation();
	// const history = useHistory();
	// const { axiosGetService } = useAxios();
	const { url } = useRouteMatch();
	const [processGroupFilterTag, setProcessGroupFilterTag] = useState([]);
	const [solutionMethodsFilterTag, setSolutionMethodsFilterTag] = useState([]);
	const [phasesFilterTag, setPhasesFilterTag] = useState([]);
	const [activitiesFilterTag, setActivitiesFilterTag] = useState([]);
	const [dynamicFilterTag, setDynamicFilterTag] = useState({});
	const [dynamicFilterTagMapping, setDynamicFilterTagMapping] = useState({});
	// const [tutorialDynamicFilterTag, setTutorialDynamicFilterTag] = useState({});
	// const [tutorialDynamicFilterTagMapping, setTutorialDynamicFilterTagMapping] =
	// 	useState({});
	const [expandTopicsLeftMenu, setExpandTopicsLeftMenu] = useState(true);
	const [isUserAction, setIsUserAction] = useState(false);
	const [leftMenuSelectedId, setLeftMenuSelectedId] = useState(1);
	// const [tutorialSearchInput, setTutorialSearchInput] = useState('');
	const [topicSearchInput, setTopicSearchInput] = useState('');
	const [topicHashtag, setTopicHashtag] = useState([]);
	// const [tutorialHashtag, setTutorialHashtag] = useState([]);
	const [showTutorials, setShowTutorials] = useState(true);

	const resetSolutionMethodFilterTag = () => {
		if (solutionMethodsFilterTag.length) {
			setSolutionMethodsFilterTag([]);
		}
		if (processGroupFilterTag.length) {
			setProcessGroupFilterTag([]);
		}
		if (Object.keys(dynamicFilterTag).length) {
			setDynamicFilterTag({});
		}
		// setTutorialHashtag([]);
		setTopicHashtag([]);
	};

	const resetPhaseFilterTag = () => {
		if (phasesFilterTag.length) {
			setPhasesFilterTag([]);
		}
	};

	const resetActivitiesFilterTag = () => {
		if (activitiesFilterTag.length) {
			setActivitiesFilterTag([]);
		}
	};

	// const getTutorialStatus = async () => {
	// 	await axiosGetService(`api/sdmsettings?sdmKey=ShowVideoTutorials`).then(
	// 		(response) => {
	// 			if (response.data[0].value === 'true') {
	// 				setShowTutorials(true);
	// 			} else {
	// 				setShowTutorials(false);
	// 			}
	// 		}
	// 	);
	// };

	// useEffect(() => {
	// 	getTutorialStatus();
	// }, []);

	// useEffect(() => {
	// 	const paths = pathname.split('/').filter((item) => item);
	// 	if (paths.includes('tutorials') && !showTutorials) {
	// 		history.push('/pageNotFound');		
	// 	}
	// }, [showTutorials]);

	return (
		<Switch>
			{/* <Route exact path={`${url}/tutorials`}>
				<TopicsTutorials
					resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
					resetPhaseFilterTag={resetPhaseFilterTag}
					resetActivitiesFilterTag={resetActivitiesFilterTag}
					expandTopicsLeftMenu={expandTopicsLeftMenu}
					setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
					isUserAction={isUserAction}
					setIsUserAction={setIsUserAction}
					leftMenuSelectedId={leftMenuSelectedId}
					setLeftMenuSelectedId={setLeftMenuSelectedId}
					tutorialDynamicFilterTag={tutorialDynamicFilterTag}
					setTutorialDynamicFilterTag={setTutorialDynamicFilterTag}
					tutorialDynamicFilterTagMapping={tutorialDynamicFilterTagMapping}
					setTutorialDynamicFilterTagMapping={setTutorialDynamicFilterTagMapping}
					searchInput={tutorialSearchInput}
					setSearchInput={setTutorialSearchInput}
					tutorialHashtag={tutorialHashtag}
					setTutorialHashtag={setTutorialHashtag}
					showTutorials={showTutorials}
				/>
			</Route>
			<Route exact path={`${url}/tutorials/:tutorialType(consulting|support)/:tutorialId(\\d+)`}>
				<TopicsTutorialsDetails
					resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
					resetPhaseFilterTag={resetPhaseFilterTag}
					resetActivitiesFilterTag={resetActivitiesFilterTag}
					expandTopicsLeftMenu={expandTopicsLeftMenu}
					setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
					isUserAction={isUserAction}
					setIsUserAction={setIsUserAction}
					leftMenuSelectedId={leftMenuSelectedId}
					setLeftMenuSelectedId={setLeftMenuSelectedId}
					showTutorials={showTutorials}
				/>
			</Route>
			<Route exact path={`${url}/tutorials/:tutorialType(consulting|support)`}>
				<TopicsTutorials
					resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
					resetPhaseFilterTag={resetPhaseFilterTag}
					resetActivitiesFilterTag={resetActivitiesFilterTag}
					expandTopicsLeftMenu={expandTopicsLeftMenu}
					setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
					isUserAction={isUserAction}
					setIsUserAction={setIsUserAction}
					leftMenuSelectedId={leftMenuSelectedId}
					setLeftMenuSelectedId={setLeftMenuSelectedId}
					tutorialDynamicFilterTag={tutorialDynamicFilterTag}
					setTutorialDynamicFilterTag={setTutorialDynamicFilterTag}
					tutorialDynamicFilterTagMapping={tutorialDynamicFilterTagMapping}
					setTutorialDynamicFilterTagMapping={setTutorialDynamicFilterTagMapping}
					searchInput={tutorialSearchInput}
					setSearchInput={setTutorialSearchInput}
					tutorialHashtag={tutorialHashtag}
					setTutorialHashtag={setTutorialHashtag}
					showTutorials={showTutorials}
				/>
			</Route>*/}
			<Route exact path={`${url}/approver/edit/:id(\\d+)`}>
				<TopicsApproverEditPage />
			</Route>
			<Route exact path={`${url}/reviewer/edit/:id(\\d+)`}>
				<TopicsReviewerEditPage />
			</Route>
			<Route exact path={`${url}/edit/:id(\\d+)`}>
				<TopicsContributorEditPage />
			</Route>
			<Route exact path={`${url}/:topicsId(\\d+)`}>
				<TopicsDetailPage
					resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
					resetPhaseFilterTag={resetPhaseFilterTag}
					resetActivitiesFilterTag={resetActivitiesFilterTag}
					expandTopicsLeftMenu={expandTopicsLeftMenu}
					setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
					isUserAction={isUserAction}
					setIsUserAction={setIsUserAction}
					leftMenuSelectedId={leftMenuSelectedId}
					setLeftMenuSelectedId={setLeftMenuSelectedId}
					showTutorials={showTutorials}
				/>
			</Route>
			<Route exact path={url}>
				<TopicsLandingPage
					dynamicFilterTag={dynamicFilterTag}
					setDynamicFilterTag={setDynamicFilterTag}
					dynamicFilterTagMapping={dynamicFilterTagMapping}
					setDynamicFilterTagMapping={setDynamicFilterTagMapping}
					resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
					resetPhaseFilterTag={resetPhaseFilterTag}
					resetActivitiesFilterTag={resetActivitiesFilterTag}
					expandTopicsLeftMenu={expandTopicsLeftMenu}
					setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
					isUserAction={isUserAction}
					setIsUserAction={setIsUserAction}
					leftMenuSelectedId={leftMenuSelectedId}
					setLeftMenuSelectedId={setLeftMenuSelectedId}
					topicSearchInput={topicSearchInput}
					setTopicSearchInput={setTopicSearchInput}
					topicHashtag={topicHashtag}
					setTopicHashtag={setTopicHashtag}
					showTutorials={showTutorials}
				/>
			</Route>
			<Route path="*">
				<ComingSoon />
			</Route>
		</Switch>
	);
}

const ComingSoon = () => (
	<h2 className="pageNotFound">
		<b>404 Page not found!</b>
	</h2>
);
export default Topics;
