import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import './Myfavorites.css';

function MyFavorites() {
	const { track } = useAppInsights();

	const { favorites, appState, setAppState, getFavorites } =
		useLeftMenuCollapse();

	useEffect(() => {
		getFavorites();
	}, []);

	return (
		<div className="myFavouritesRightPanel">
			<ul className="myFavouritesList">
				{favorites === null ? (
					<span>No Favorites to show</span>
				) : (
					favorites.map((item) => (
						<li key={`favorite_${item.id}`}>
							<Link
								to={item.url}
								onClick={() => {
									track(1, 60, `sdmFavorite_${item.title}`, item.title);

									// close RightPanel
									setAppState({
										...appState,
										rightPanelContent: null,
										parentRightPanelComponent: null,
									});
								}}
							>
								<span className="favoritesImg"></span>
								<span className="favoriteDesc">{item.title}</span>
							</Link>
						</li>
					))
				)}
			</ul>
		</div>
	);
}

export default MyFavorites;
