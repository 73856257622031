import React from 'react';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { urlValidator } from '../../helpers/Validation';

import TutorialTypes from './TutorialTypes';

const Form = ({
	showForm,
	setShowForm,
	tutorialTitle,
	setTutorialTitle,
	tutorialUrl,
	setTutorialUrl,
	tutorialTypeId,
	setTutorialTypeId,
	isActive,
	setIsActive,
	updateTutorial,
	removeTutorial,
	saveTutorial,
	tutorials,
	addTutorial,
	tutorialTypesData,
}) => {
	return (
		<div class="methodOwnerMiddleContainer">
			<div class="methodPhaseDetailsTab">
				<div class="roleAdditionContainer ">
					<div class="addRoleButtonAction">
						<div class="selectAddRoleButton">
							<span
								onClick={() => {
									setShowForm(true);
								}}
							>
								+&nbsp;Add a New Video Tutorial
							</span>
							{showForm ? (
								<Icon
									iconName="ChevronUp"
									onClick={() => {
										setShowForm(!showForm);
									}}
								/>
							) : (
								<></>
							)}
						</div>
						{showForm ? (
							<>
								<div class="addRoleFields">
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Video Tutorial Title *</span>
										<input
											placeholder="Enter Video Tutorial Title"
											value={tutorialTitle}
											onChange={(e) => setTutorialTitle(e.target.value)}
										/>
									</div>
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Video Tutorial Url *</span>
										<input
											placeholder="Enter Video Tutorial Url"
											value={tutorialUrl}
											onChange={(e) => setTutorialUrl(e.target.value)}
										/>
										{tutorialUrl && !urlValidator.test(tutorialUrl) && (
											<p className="errorMsg">Invaild url</p>
										)}
									</div>
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Video Tutorial Type *</span>
										<TutorialTypes
											className="manageFeaturesType"
											data={tutorialTypesData}
											setValue={(typeId) => {
												setTutorialTypeId(typeId);
											}}
											value={tutorialTypesData.find(
												({ id }) => id === tutorialTypeId
											)}
											enableDropdown={true}
										/>
									</div>

									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Is Active</span>

										<label className="NotificationChkboxContainer">
											<input
												type="checkbox"
												aria-label="Is Active Checkbox"
												checked={isActive}
												onChange={(e) => setIsActive(!isActive)}
											/>
											<span className="CustomCheckmark"></span>
										</label>
									</div>

									<div>
										<span
											className="addRoleIcon"
											tabIndex="0"
											title="Add Tutorial"
											onClick={() => {
												if (!tutorialTitle || !urlValidator.test(tutorialUrl)) {
													toast.error('Please validate the required input', {
														position: 'top-right',
													});
												} else {
													addTutorial();
												}
											}}
											onKeyPress={() => {
												if (!tutorialTitle || !urlValidator.test(tutorialUrl)) {
													toast.error('Please validate the required input', {
														position: 'top-right',
													});
												} else {
													addTutorial();
												}
											}}
										></span>
									</div>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="addRolesListContainer addDeliveryPlaybookContainer">
					<ul>
						<li>
							<div class="entryCriteriaList">
								<div class="criteriaHeader">
									<div class="criteriaIcon">Video Tutorial Title</div>
									<div class="criteriaName">Video Tutorial Url</div>
									<div class="criteriaDescription">Video Tutorial Type</div>
									<div class="criteriaIsActive">Is Active</div>

									<div class="criteriaEditDeleteIcons">Delete</div>
									<div class="criteriaSaveUpdate">Update</div>
								</div>
								<ul>
									{tutorials.map((item) => (
										<li key={item.id.toString()}>
											<div class="criteriaTable">
												<div class="criteriaIcon">
													<input
														value={item.title}
														title="Video Tutorial Name"
														onChange={(e) => {
															updateTutorial({
																...item,
																title: e.target.value,
															});
														}}
													/>
												</div>

												<div class="criteriaName">
													<div>
														<input
															value={item.url}
															title="Video Tutorial Url"
															onChange={(e) => {
																updateTutorial({
																	...item,
																	url: e.target.value,
																});
															}}
														/>
														{item.url && !urlValidator.test(item.url) && (
															<p className="errorMsg">Invaild url</p>
														)}
													</div>
												</div>
												<div class="criteriaDescription">
													<TutorialTypes
														className={`manageFeaturesType ${item.id}`}
														data={tutorialTypesData}
														setValue={(typeId) => {
															updateTutorial({
																...item,
																tutorialTypeId: typeId,
															});
														}}
														value={tutorialTypesData.find(
															({ id }) => id === item.tutorialTypeId
														)}
														enableDropdown={true}
													/>
												</div>
												<div class="criteriaIsActive">
													<div>
														<label className="NotificationChkboxContainer">
															<input
																type="checkbox"
																checked={item.isActive}
																aria-label="Is Active Checkbox"
																onChange={(e) => {
																	updateTutorial({
																		...item,
																		isActive: !item.isActive,
																	});
																}}
															/>
															<span className="CustomCheckmark"></span>
														</label>
													</div>
												</div>

												<div class="criteriaEditDeleteIcons">
													<span
														className="deliverPlaybookDeleteIcon"
														title="Delete"
														tabIndex="0"
														onClick={() => {
															removeTutorial(item.id, item.title);
														}}
														onKeyPress={() => {
															removeTutorial(item.id, item.title);
														}}
													></span>
												</div>
												<div class="criteriaSaveUpdate">
													<button
														disabled={
															!urlValidator.test(item.url) || !item.title
														}
														onClick={() => {
															saveTutorial(item);
														}}
														onKeyPress={() => {
															saveTutorial(item);
														}}
													>
														Update
													</button>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Form;
