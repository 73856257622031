import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const MethodDeleteModal = ({
	showDeleteMethodModal,
	setShowDeleteMethodModal,
	selectedSolutionMethod,
	setSelectedSolutionMethod,
	selectedMethodName,
	setSelectedMethodName,
	projectTypeId,
	isDeleteSolutionMethod,
	setIsDeleteSolutionMethod
}) => {
	const { getSolutionMethodsByProjectId } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { axiosDeleteService, axiosPostService } = useAxios();
	const history = useHistory();

	const [errorMessage, setErrorMessage] = useState(null);
	const [apiCalled, setApiCalled] = useState(false);

	const deleteMethod = async () => {
		setApiCalled(true);
		await axiosDeleteService(
			`api/sdmsolutionmethod/deletesolutionmethod?solutionMethodId=${selectedSolutionMethod}`,
			{}
		).then((response) => {
			if (response.data.status) {
				toast.info(`You have successfully deleted ${selectedMethodName}`, {
					position: 'top-right',
				});
				getSolutionMethodsByProjectId(projectTypeId);
				setShowDeleteMethodModal(false);
				setIsDeleteSolutionMethod(!isDeleteSolutionMethod);
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
				setApiCalled(false);
				setShowDeleteMethodModal(false);
			}
		});
	};

	useEffect(() => {
		return () => {
			setSelectedMethodName('');
			setSelectedSolutionMethod(0);
		};
	}, []);

	return (
		<>
			{apiCalled ? (
				<Spinner />
			) : (
				<Modal
					className="activityDiscardPopUp"
					show={showDeleteMethodModal}
					onHide={() => {
						setShowDeleteMethodModal(false);
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						<p>Are you sure that you want to delete {selectedMethodName}</p>
						<div className="discardPopUpActivityButtons">
							<Button
								title="Cancel"
								className="activityDiscardPopUpCancel"
								variant="primary"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowDeleteMethodModal(false);
									}
								}}
								onClick={() => {
									setShowDeleteMethodModal(false);
								}}
							>
								Cancel
							</Button>

							<Button
								title="Delete"
								tabIndex={-1}
								onClick={() => {
									deleteMethod();
								}}
								disabled={apiCalled}
								className="activityDiscardPopUpAccept"
								variant="info"
							>
								Delete
							</Button>
						</div>

						{errorMessage ? (
							<span className="errorMsg">{errorMessage}</span>
						) : (
							<></>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default MethodDeleteModal;
