import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from 'react-router';
import EditCategory from './EditCategory';

const AdminManageVideoTutorialsCategoryModal = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(1);
	const tabs = [
		{
			id: 1,
			title: 'Edit Category',
		 },
		// { id: 2, title: 'Sequence' },
	];
	const components = {
		1: <EditCategory setCurrentTab={setCurrentTab} />,
		// 2: <EditRoleGroups />,
	};
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles rolesGroup"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Category
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									{tabs.map((item) => (
										<li
											key={item.title}
											className={currentTab === item.id ? 'activePhaseTab' : ''}
											onClick={() => setCurrentTab(item.id)}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setCurrentTab(item.id);
												}
											}}
										>
											<span>{item.title}</span>
										</li>
									))}
								</ul>
							</div>
							{components[currentTab]}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AdminManageVideoTutorialsCategoryModal;
