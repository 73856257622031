import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { Icon } from '@fluentui/react';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const PhasePublishModal = ({
	solutionMethodId,
	phaseData,
	type,
	showPublishCancelModal,
	setShowPublishCancelModal,
	setParentPhaseCreated,
	parentPhaseCreated,
	phaseCreated,
	setPhaseCreated,
	formatPublishDate,
}) => {
	const { track } = useAppInsights();

	const { axiosPostService, axiosGetService, userAlias, setApiDown } =
		useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const [publishActivityDetails, setPublishActivityDetails] = useState({
		publishedActivities: [],
		notPublishedActivities: [],
	});
	const [showSpinner, setShowSpinner] = useState(true);
	const [successMessage, setSuccessMessage] = useState('');

	const checkPublishData = async () => {
		await axiosGetService(
			`api/sdmactivity/checkpublishvalidations/${phaseData.phaseId}`
		).then((response) => {
			if (response.data.status) {
				setPublishActivityDetails((prevState) => ({
					...prevState,
					publishedActivities: response.data.publishedActivities,
					notPublishedActivities: response.data.notPublishedActivities,
					referedActivities: response.data.referedActivities,
				}));
			} else {
				setApiDown(true);
				setErrorMessage(response.data.errorMessage);
			}
			setShowSpinner(false);
		});
	};

	const cancelSchedulePublish = async () => {
		setShowSpinner(true);
		const postData = {
			parentPhaseId: phaseData.phaseId,
		};
		await axiosPostService(
			`api/sdmactivity/publishschedulecancel`,
			postData
		).then((response) => {
			if (response.data.status) {
				setSuccessMessage('You have successfully cancelled the schedule');
				if (type === 'parentPhase') {
					track(1, 1905, phaseData.phaseId, phaseData.phaseName);
				} else {
					track(1, 1906, phaseData.phaseId, phaseData.phaseName);
				}
			} else {
				setApiDown(true);
				setErrorMessage(response.data.errorMessage);
			}
			setShowSpinner(false);
		});
	};

	useEffect(() => {
		checkPublishData();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="phasePublishModal"
					show={showPublishCancelModal}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					onHide={() => {
						if (type === 'parentPhase' && successMessage) {
							setParentPhaseCreated(!parentPhaseCreated);
						} else if (successMessage) {
							setPhaseCreated(!phaseCreated);
						}
						setShowPublishCancelModal(false);
					}}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Cancel the scheduled publication
						</Modal.Title>
						<Icon
							onClick={() => {
								if (type === 'parentPhase' && successMessage) {
									setParentPhaseCreated(!parentPhaseCreated);
								} else if (successMessage) {
									setPhaseCreated(!phaseCreated);
								}
								setShowPublishCancelModal(false);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									if (type === 'parentPhase' && successMessage) {
										setParentPhaseCreated(!parentPhaseCreated);
									} else if (successMessage) {
										setPhaseCreated(!phaseCreated);
									}
									setShowPublishCancelModal(false);
								}
							}}
							tabIndex={0}
							role="button"
							aria-label="Cancel the scheduled publication"
							iconName="ChromeClose"
						></Icon>
					</Modal.Header>
					<Modal.Body>
						{successMessage || errorMessage ? (
							<>
								{successMessage ? (
									<Alert variant="success">{successMessage}</Alert>
								) : (
									<Alert variant="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: HtmlSanitizer(errorMessage),
											}}
										/>
									</Alert>
								)}
							</>
						) : (
							<>
								<div className="phasePublishModalDiv">
									{publishActivityDetails['publishedActivities'].length > 0 ? (
										<>
											<p>
												Following activity(ies) are scheduled to be published at{' '}
												{formatPublishDate(
													phaseData.publishScheduledDateTimeString
												)}{' '}
												(Local).
											</p>
											<ul>
												{publishActivityDetails['publishedActivities'].map(
													(item) => (
														<li
															key={item.title + '_' + item.complexityName}
															className="publishActivity"
														>
															{item.title}&nbsp;-&nbsp;{item.complexityName}
														</li>
													)
												)}
											</ul>
										</>
									) : (
										<></>
									)}
								</div>
								<div className="cancelSchedulePublishDiv">
									<p>Are you sure you want to cancel this ?</p>
									<div>
										<button
											onClick={() => {
												cancelSchedulePublish();
											}}
										>
											Yes, Cancel
										</button>
										<button
											onClick={() => {
												setShowPublishCancelModal(false);
											}}
										>
											No, Keep the schedule
										</button>
									</div>
								</div>
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default PhasePublishModal;
