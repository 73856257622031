import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import StarRatings from 'react-star-ratings';

import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

function RatingModal({ enableRatingModal, closeModal, templateData }) {
	const { axiosPostService } = useAxios();
	const { getNotifications } = useLeftMenuCollapse();
	const [rating, setRating] = useState(0);
	const [ratingSubmitted, setRatingSubmitted] = useState(false);

	const handleSubmit = async () => {
		if (rating > 0) {
			const postBody = {
				sampleId: templateData.itemId,
				rating,
			};
			await axiosPostService(`api/sdmtemplate/Rating`, postBody)
				.then((response) => {
					setRatingSubmitted(true);
				})
				.catch((error) => console.log(error));
		}
	};

	return (
		<Modal
			show={enableRatingModal}
			onHide={() => {
				getNotifications();
				closeModal();
			}}
			centered
			className="ratingSamplePopUp"
		>
			<Modal.Body>
				{ratingSubmitted ? (
					<div className="feedbackSubmitted">
						<span>Your feedback was submitted successfully</span>
						<button
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									getNotifications();
									closeModal();
								}
							}}
							onClick={() => {
								getNotifications();
								closeModal();
							}}
						>
							OK
						</button>
					</div>
				) : (
					<div>
						<div className="titlePartOfSample">
							<span>Rate the downloaded sample</span>
							<br />
							<span>{`"${templateData.title}"`}</span>
						</div>
						<div className="detailsPartOfSample">
							<span>Phase: {templateData.phaseName}</span>
						</div>
						<StarRatings
							rating={rating}
							starDimension="19px"
							starRatedColor={'#f58220'}
							starHoverColor={'#f58220'}
							starEmptyColor={'#e9e9f0'}
							starSpacing="5px"
							svgIconViewBox="0 0 31.951 32"
							svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
							changeRating={(value) => setRating(value)}
						/>
						<button
							className="ratingSubmit"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleSubmit();
								}
							}}
							onClick={handleSubmit}
							disabled={rating > 0 ? false : true}
						>
							Submit
						</button>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default RatingModal;
