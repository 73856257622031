import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import RatingModal from './RatingModal';


const RejectionReasonModal = ({
	users,
	closeModal,
	reviewedDescriptionSuggestions,
	contributorSuggestionsInfo,
	submit,
	rejectionComments: comments,
	setRejectionComments,
	currentUser,
	postData,
	usersRating,
	setUsersRating,
	ratingPoints,
	checkRejection,
	enableGamification,
}) => {
	const [commentIds, setCommentIds] = useState([]);
	const [error, setError] = useState(null);
	const [selectedMenu, setSelectedtMenu] = useState(enableGamification ? 1 : 2);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const [contributedSuggestions, setContributedSuggestions] = useState([]);
	const suggestionState = {};
	reviewedDescriptionSuggestions.forEach((suggestion) => {
		const { id, state } = JSON.parse(suggestion);
		suggestionState[id] = state;
	});

	const validateInput = () => {
		let validInput = true;
		for (const id of Object.keys(comments)) {
			if (
				(!comments[id] || comments[id].length === 0) &&
				suggestionState[id] === 'rejected'
			) {
				setError('All rejected changes comments are required');
				validInput = false;
				break;
			}
		}
		if (validInput) {
			submit();
		}
	};

	const getUserInfo = (type, email) => {
		for (const user of users) {
			if (user.id === email) {
				return user[type];
			}
		}
	};

	const updateComments = (e, id) => {
		const comment = e.target.value;
		setRejectionComments((prevState) => ({ ...prevState, [id]: comment }));
	};

	const checkUsersDeatils = (isContributionRejected) => {
		const usersEmail = users.filter(({ id }) => id !== currentUser);
		if (usersEmail.length === 0 && isContributionRejected) {
			setSelectedtMenu(2);
		} else if (usersEmail.length === 0 && !isContributionRejected && false) {
			submit();
		}
	};

	const checkUserContribution = (suggestions, email) => {
		const contributedSuggestionsIds = Object.keys(contributorSuggestionsInfo)
			.filter(
				(suggestion) =>
					JSON.parse(contributorSuggestionsInfo[suggestion]).authorId === email
			)
			.map((suggestion) => {
				return JSON.parse(contributorSuggestionsInfo[suggestion]).id;
			});
		const rejectedSuggestionsIds = reviewedDescriptionSuggestions
			.filter(
				(suggestion) =>
					(JSON.parse(suggestion).state === 'rejected' ||
						JSON.parse(suggestion).state === 'accepted') &&
					contributedSuggestionsIds.includes(JSON.parse(suggestion).id)
			)
			.map((item) => {
				return JSON.parse(item).id;
			});

		if (contributedSuggestionsIds.length === rejectedSuggestionsIds.length) {
			return false;
		}
		return true;
	};

	const updateUsersForRating = () => {
		for (const user of users) {
			if (
				checkUserContribution(reviewedDescriptionSuggestions, user.id) &&
				user.id !== currentUser
			) {
				setUpdatedUsers((prevState) => [...prevState, user.id]);
			}
		}
	};

	useEffect(() => {
		const contributedSuggestionsIds = Object.keys(
			contributorSuggestionsInfo
		).map((suggestion) => {
			return JSON.parse(contributorSuggestionsInfo[suggestion]).id;
		});
		const contributedSuggestions = Object.keys(
			contributorSuggestionsInfo
		).map((suggestion) => {
			return JSON.parse(contributorSuggestionsInfo[suggestion]);
		});
		const rejectedSuggestionsIds = reviewedDescriptionSuggestions
			.filter((suggestion) =>
				contributedSuggestionsIds.includes(JSON.parse(suggestion).id)
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const commentsIds = {};
		for (const id of rejectedSuggestionsIds) {
			commentsIds[id] = '';
		}
		setContributedSuggestions(contributedSuggestions);
		setCommentIds(rejectedSuggestionsIds);
		setRejectionComments(commentsIds);
		checkUsersDeatils(rejectedSuggestionsIds.length > 0);
		updateUsersForRating();
	}, []);

	return (
		<>
		{users.length>0?(
		<Modal
			className="topicPreviewAndSubmitPopUp rejectionReasonModal"
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="modal-90w"
		>
			<Modal.Header>
				<Modal.Title>Send for approval</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{selectedMenu === 1 && updatedUsers.length > 0 ? (
					<RatingModal
						users={users}
						currentUser={currentUser}
						postData={postData}
						usersRating={usersRating}
						setUsersRating={setUsersRating}
						ratingPoints={ratingPoints}
						updatedUsers={updatedUsers}
					/>
				) : (
					<div className="topicsCrowdSourcingContainer">
						<div class="topicsDescriptionDetails">
							<div className="topicDescriptionDiv">
								<h5>
									Please provide comments to help the contributor to improve
								</h5>
								{error ? <span className="erroMsg">{error}</span> : <></>}
								{commentIds.map((id) => (
									<RejectionCard
										id={id}
										contributedSuggestions = {contributedSuggestions}
										suggestionState = {suggestionState}
										getUserInfo = {getUserInfo}
										error={error}
										comments = {comments}
										updateComments={updateComments}
									/>
								))}
							</div>
						</div>
					</div>
				)}
				<div className="reviwerRejectedRatingButton">
					<Button
						title="Cancel"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => {
							closeModal();
						}}
						className="activityDiscardButton"
					>
						Cancel
					</Button>
					<Button
						title="Submit"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								if (
									selectedMenu === 1 &&
									checkRejection('rating') &&
									updatedUsers.length > 0
								) {
									setSelectedtMenu(2);
								} else if (
									!checkRejection('rating') ||
									updatedUsers.length === 0
								) {
									submit();
								} else {
									validateInput();
								}
							}
						}}
						onClick={() => {
							if (
								selectedMenu === 1 &&
								checkRejection('rating') &&
								updatedUsers.length > 0
							) {
								setSelectedtMenu(2);
							} else if (!checkRejection('rating') || updatedUsers.length > 0) {
								submit();
							} else {
								validateInput();
							}
						}}
						className="activityPreviewButton"
					>
						{selectedMenu === 1 &&
							checkRejection('rating') &&
							updatedUsers.length > 0
							? 'Next'
							: 'Submit'}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
		):(
			<Modal
				className="activityDiscardPopUp"
				show={true}
				onHide={() => {
					/* Not calling any function to close modal*/
				}}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
			<Modal.Body>
				<p>Select "Publish" to proceed, or "Cancel" to return and make edits.</p>
				<div className="reviwerRejectedRatingButton">
					<Button
						title="Cancel"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => {
							closeModal();
						}}
						className="activityDiscardButton"
					>
						Cancel
					</Button>
					<Button
						title="Submit"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								if (
									selectedMenu === 1 &&
									checkRejection('rating') &&
									updatedUsers.length > 0
								) {
									setSelectedtMenu(2);
								} else if (
									!checkRejection('rating') ||
									updatedUsers.length === 0
								) {
									submit();
								} else {
									validateInput();
								}
							}
						}}
						onClick={() => {
							if (
								selectedMenu === 1 &&
								checkRejection('rating') &&
								updatedUsers.length > 0
							) {
								setSelectedtMenu(2);
							} else if (!checkRejection('rating') || updatedUsers.length > 0) {
								submit();
							} else {
								validateInput();
							}
						}}
						className="activityPreviewButton"
					>
						{selectedMenu === 1 &&
							checkRejection('rating') &&
							updatedUsers.length > 0
							? 'Next'
							: 'Publish'}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
		)}
		</>
	);
};

const RejectionCard = ({
	id,
	contributedSuggestions,
	suggestionState,
	getUserInfo,
	error,
	comments,
	updateComments,
}) => {
	const contributor = contributedSuggestions.filter((contributor) => { return contributor.id == id });
	return (
		<>
			<div className="rejectionCard">
				<div
					class={`sideBarCard ${suggestionState[id] === 'rejected'
							? 'userDeletedToolsAndGuidance'
							: 'userAddedItem'
						}`}
				>
					<div class="sideBarCardHeader">
						<img
							class="userImg"
							src={getUserInfo(
								'avatar',
								contributor[0].id === id && contributor[0].authorId
							)}
							alt=""
						/>
						<span>
							{getUserInfo(
								'name',
								contributor[0].id === id && contributor[0].authorId
							)}
						</span>
						<div class="itemStatus">
							<span>
								{(contributor[0].id === id && contributor[0].type) ===
									'insertion'
									? 'Add'
									: (contributor[0].id === id && contributor[0].type) ===
										'deletion'
										? 'Remove'
										: 'Format'}
							</span>
							&nbsp;
							<div className='topicsImgDimensions'
								// dangerouslySetInnerHTML={{
								// 	__html: (contributor[0].id === id && contributor[0].data.newValue),
								// }}
							>
								{(contributor[0].id === id) &&  
									(contributor[0].data) !== null ? 
										contributor[0].data.key === "$elementName" ? contributor[0].data.newValue : contributor[0].data.key
									: ''}
								</div>
						</div>
					</div>
				</div>
				{suggestionState[id] === 'rejected' ? (
					<div
						className={`rejectionRequiredFields ${error && !comments[id] ? 'rejected' : ''
							}`}
					>
						<div>
							<textarea
								placeholder="Enter your Comments"
								value={comments[id]}
								onChange={(e) => {
									updateComments(e, id);
								}}
							/>
							{error && !comments[id] ? (
								<></>
							) : (
								<span className="requiredField">Required*</span>
							)}
						</div>
						{error && !comments[id] ? (
							<span className="requiredField">
								This is a required field*
							</span>
						) : (
							<></>
						)}
					</div>
				) : (
					<textarea
						placeholder="Enter your Comments"
						value={comments[id]}
						onChange={(e) => {
							updateComments(e, id);
						}}
					/>
				)}
			</div>

		</>
	)
}

export default RejectionReasonModal;
