import React, { useState, useEffect, createElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from 'react-router';
import { useAxios } from '../../../context/AxiosContext';
import SelectToggleFilters from './SelectToggleFilters';
import Sequence from './Sequence';

const ManageToggleFilters = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [pages, setPages] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const { axiosGetService } = useAxios();
	const tabs = [
		{
			title: 'Select Dynamic Filters',
			component: SelectToggleFilters,
		},
		{ title: 'Sequence', component: Sequence },
	];
	const getPages = async () => {
		const response = await axiosGetService(`api/sdmToggleFilters/getall`);
		setPages(
			response.data.map(({ id, pageName }) => {
				return { id, name: pageName };
			})
		);
	};
	useEffect(() => {
		getPages();
	}, []);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageToggleFilters"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Dynamic Filters
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									{tabs.map((item, index) => (
										<li
											key={item.title}
											className={currentTab === index ? 'activePhaseTab' : ''}
											onClick={() => setCurrentTab(index)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setCurrentTab(index);
												}
											}}
											aria-label={item.title}
											role="button"
											tabIndex={0}
										>
											<span>{item.title}</span>
										</li>
									))}
								</ul>
							</div>
							{createElement(tabs[currentTab].component, { pages })}
							
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageToggleFilters;
