import React, { useState, useEffect, useRef, useCallback } from "react";
import ComplexityFilter from "./ComplexityFilter";
import RolesFilter from "./RolesFilter";
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { Icon } from "@fluentui/react/lib/Icon";
import DynamicFilter from "./DynamicFilter";

function Complexity({
  setSelectedComplexity,
  solutionMethodId,
  setSelectedRoles,
  setShowFilters,
  selectedRoles,
  showFilters,
  searchInput,
  setShowSpinner,
  setSelectedComplexityId,
  setSelectedRoleId,
  showMatrixView,
  phaseCreated,
  updateComplexityFilter,
  parentId,
  complexities,
  setComplexities,
  isEnableComplexity
}) {
  const {
    appState,
    setComplexityValidActivities,
    phase,
    parentPhase,
    complexity,
    setComplexity,
    role,
    setRole,
    phaseHashtags,
    phaseDynamicFilterTag: dynamicFilterTag,
    setPhaseDynamicFilterTag: setDynamicFilterTag,
    setPhaseDynamicFilterTagMapping: setDynamicFilterTagMapping,
  } = useLeftMenuCollapse();
  const { track } = useAppInsights();
  const { axiosGetService } = useAxios();
  const { defaultRole } = appState;
  const searchInputRef = useRef(null);
  // list of all complexities and roles
  //const [complexities, setComplexities] = useState([]);
  const [roleSearchValue, setRoleSearchValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [deliveryRoles, setDeliveryRoles] = useState([]);
  const [totalRoles, setTotalRoles] = useState([]);
  const [enableSearch, setEnableSearch] = useState({
    Tools: false,
    Roles: false,
  });
  const [totalFilteredRoles, setTotalFilteredRoles] = useState([]);
  // list of all complexity valid activities
  const [validActivities, setValidActivities] = useState([]);
  const [validDeliveryActivities, setValidDeliveryActivities] = useState([]);
  const [dynamicFilterData, setDynamicFilterData] = useState([]);
  const [dynamicSearchText, setDynamicSearchText] = useState({});
  const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
    {}
  );
  const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
    {}
  );

  // const updateComplexity = (complexityData, type) => {
  //   const complexityDetails = complexityData.filter(
  //     ({ name }) => name === type
  //   );
  //   setSelectedComplexity(type);
  //   setComplexity(complexityDetails[0]);
  //   setSelectedComplexityId(complexityDetails[0].id);
  // };
  // get complexities for dropDown
  // const getComplexities = useCallback(async () => {
  //   await axiosGetService(
  //     `api/sdmphase/getcomplexity/${solutionMethodId}/${
  //       phase.id && !showMatrixView ? phase.id : 0
  //     }/${parentPhase.id && !showMatrixView ? parentPhase.id : 0}`
  //   ).then((res) => {
  //     const data = res.data.map((item) => {
  //       return {
  //         name: item.name,
  //         id: item.id,
  //         type: "option",
  //       };
  //     });

  //     const availableComplexities = data.map(({ name }) => {
  //       return name;
  //     });
  //     if (!data.some((value) => value.id === complexity.id)) {
  //       if (availableComplexities.includes("Medium")) {
  //         updateComplexity(data, "Medium");
  //       } else if (availableComplexities.includes("Critical")) {
  //         updateComplexity(data, "Critical");
  //       } else if (availableComplexities.includes("High")) {
  //         updateComplexity(data, "High");
  //       } else if (availableComplexities.includes("Low")) {
  //         updateComplexity(data, "Low");
  //       }
  //     } else {
  //       updateComplexity(
  //         data,
  //         data.find((value) => value.id === complexity.id).name
  //       );
  //     }
  //     setComplexities(data);
  //   });
  // }, [
  //   parentPhase.id,
  //   phase.id,
  //   showMatrixView,
  //   phaseCreated,
  //   updateComplexityFilter,
  // ]);

  const searchRoles = (searchText) => {
    if (searchText.trim().length !== 0) {
      const filteredRoles = totalRoles.filter((role) =>
        role.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setTotalFilteredRoles(filteredRoles);
    }
  };

  // get Roles for respective PhaseId, activities for Dropdown
  const getRoles = (id, type) => {
    axiosGetService(
      `api/sdmphase/getphaseroles/${Number(id)}/${solutionMethodId}`
    ).then((res) => {
      const roleData =
        res.data === null
          ? []
          : res.data.map((item) => {
            const roleDataStringified = JSON.stringify({
              name: item.roleName,
              id: item.roleId,
              type: "option",
            });

            if (type === "phase") {
              return deliveryRoles.includes(roleDataStringified)
                ? null
                : roleDataStringified;
            } else {
              return roles.includes(roleDataStringified)
                ? null
                : roleDataStringified;
            }
          });

      const filteredRoles = roleData.filter((item) => item !== null);

      if (type === "parentPhase") {
        setDeliveryRoles(filteredRoles);
      } else {
        setRoles(filteredRoles);
      }
    });
  };

  const getAllRoles = async () => {
    await axiosGetService(
      `api/sdmphase/getphaseroles/${0}/${solutionMethodId}`
    ).then((response) => {
      const roleIds = response.data.map((item) => {
        return {
          name: item.roleName,
          id: item.roleId,
          type: "option",
        };
      });
      setTotalRoles(roleIds);
    });
  };

  // get activity Complexities and RACI info  
  const getActivityAndRaciData = async (type) => {
    const id = type === "parentPhase" ? parentPhase.id : phase.id;
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    if (searchInput.trim().length > 0) {
      setShowSpinner(true);
    }
    const selectedFiltersIds = [];
    const filterIds = Object.keys(dynamicFilterTag);
    for (const id of filterIds) {
      if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          dynamicFilterTag[Number(id)].selectedTagId.toString()
        );
      }
    }
    if (role.id !== 0) {
      selectedFiltersIds.push(`16-${role.id}`);
    }
    await axiosGetService(
      `api/sdmactivity/getactivity/${solutionMethodId}/${id}/${complexity.id}/${selectedFiltersIds.length > 0 ? `${selectedFiltersIds.join("|")}` : 0
      }/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}`
    ).then((res) => {
      const dataFilter =
        res?.data === null
          ? []
          : res?.data?.map((item) => ({
            id: item.activityIdentifier,
            responsible: item.responsible,
            accountable: item.accountable,
            consulted: item.consulted,
            informed: item.informed,
            activityId: item.activityId,
          }));
      if (type === "parentPhase") {
        setValidDeliveryActivities(dataFilter);
      } else {
        setValidActivities(dataFilter);
      }
      setShowSpinner(false);
      if (phaseHashtags.length > 0 || searchInput.trim().length > 0) {
        track(1, 1207, "Job Aides Landing Page", "Job Aides", {
          hashtag: phaseHashtags.length > 0 ? phaseHashtags.toString() : null,
          title: searchInput.trim().length > 0 ? searchInput : null,
        });
      }
    });
  };

  const getDynamicFilterData = async (dynamicFilter = dynamicFilterTag) => {
    const selectedFiltersIds = [];
    const filterIds = Object.keys(dynamicFilter);
    for (const id of filterIds) {
      if (dynamicFilter[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          dynamicFilter[Number(id)].selectedTagId.toString()
        );
      }
    }
    if (role.id !== 0) {
      selectedFiltersIds.push(`16-${role.id}`);
    }
    await axiosGetService(
      `api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${showMatrixView ? 17 : 1
      }&isFilter=true&artifactId=${showMatrixView
        ? `${solutionMethodId}`
        : parentId && parentPhase.id
          ? `${[solutionMethodId, parentId].filter((val) => val).toString()}-${[
            phase.id,
            parentPhase.id,
          ]
            .filter((val) => val)
            .toString()}`
          : `${solutionMethodId}-${phase.id}`
      }&filterIds=${selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : 0
      }&complexityIds=${complexity.id}&searchValue=0`
    ).then((response) => {
      const nameMapping = {};
      const filterTag = {};
      const filterTagMapping = {};
      const serachFilterData = {};
      let searchText = {};
      const data = response.data.map((item) => {
        return {
          artifactTypeId: item.artifactTypeId,
          artifactTypeName: item.artifactTypeName,
          artifactData: item.artifactData,
        };
      });
      for (const id in data) {
        nameMapping[data[id].artifactTypeId] =
          dynamicFilterSearchEnable[data[id].artifactTypeId];
        filterTag[data[id].artifactTypeId] = {
          artifactTypeId: data[id].artifactTypeId,
          artifactTypeName: data[id].artifactTypeName,
          selectedTagId: [],
        };
        const tag = data[id].artifactData;
        let artifactTag = {};
        for (const tagId in tag) {
          artifactTag = {
            ...artifactTag,
            [tag[tagId].id]: tag[tagId].name,
          };
        }
        filterTagMapping[data[id].artifactTypeId] = artifactTag;
        searchText = {
          ...searchText,
          [data[id].artifactTypeId]:
            dynamicSearchText[data[id].artifactTypeId] ?? "",
        };
        serachFilterData[data[id].artifactTypeId] = dynamicSearchText[
          data[id].artifactTypeId
        ]
          ? tag.filter((item) =>
            item.name
              .toLowerCase()
              .includes(
                dynamicSearchText[data[id].artifactTypeId].toLowerCase()
              )
          )
          : [];
      }
      setDynamicFilterSearchResult({ ...serachFilterData });
      setDynamicSearchText({ ...searchText });
      setDynamicFilterTagMapping({ ...filterTagMapping });
      setDynamicFilterSearchEnable({ ...nameMapping });
      setDynamicFilterData([...data]);
      const newDynamicFilter = { ...dynamicFilter };
      for (const artifactTypeId in newDynamicFilter) {
        const { artifactData } =
          data.find(({ artifactTypeId: id }) => id == artifactTypeId) ?? [];
        newDynamicFilter[artifactTypeId].selectedTagId = newDynamicFilter[
          artifactTypeId
        ].selectedTagId.filter((tagId) =>
          artifactData.map(({ id: itemId }) => itemId).includes(tagId)
        );
      }
      if (
        JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilter)
      ) {
        setDynamicFilterTag(newDynamicFilter);
      }
    });
  };

  const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
    if (searchText.trim().length > 0) {
      const filteredRoles = artifactData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setDynamicFilterSearchResult((prevState) => ({
        ...prevState,
        [artifactTypeId]: filteredRoles,
      }));
    }
    setDynamicSearchText((prevState) => ({
      ...prevState,
      [artifactTypeId]: searchText.trim().length > 0 ? searchText : "",
    }));
  };

  useEffect(() => {
    if(complexity.id !== ''){
      getDynamicFilterData();
    }
  }, [solutionMethodId, phase.id, parentPhase.id, complexity.id]);

  // useEffect(() => {
  //   getDynamicFilterData();
  // }, [solutionMethodId, showMatrixView, phase.id, parentPhase.id, complexity]);

  useEffect(() => {
    const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
    if (dynamicRefValue.includes(true) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [dynamicFilterSearchEnable]);

  // onload getComplexities, Roles and complexityActivities
  // useEffect(() => {
  //   getComplexities();
  // }, [
  //   parentPhase.id,
  //   phase.id,
  //   showMatrixView,
  //   phaseCreated,
  //   updateComplexityFilter,
  // ]);

  useEffect(() => {
    if (!showMatrixView) {
      const None = { name: "None", id: defaultRole.id, type: "option" };
      const parsedRoles = roles.map((role) => JSON.parse(role));
      const parsedDeliveryRoles = deliveryRoles.map((role) => JSON.parse(role));
      if (parsedDeliveryRoles.length !== 0 || parsedRoles.length !== 0) {
        setTotalRoles([None, ...parsedRoles, ...parsedDeliveryRoles]);
      }
    }
  }, [roles, deliveryRoles]);

  useEffect(() => {
    if (!showMatrixView) {
      const selectedRole = totalRoles.filter((item) => item.name === role.name);
      if (
        (roles.length !== 0 || phase.id === null) &&
        (deliveryRoles.length !== 0 || parentPhase.id === null) &&
        selectedRole.length === 0 &&
        totalRoles.length !== 0
      ) {
        setRole(defaultRole);
        setSelectedRoles(defaultRole.name);
      }
    }
  }, [totalRoles]);

  useEffect(() => {
    const totalValidActivities = [
      ...validDeliveryActivities,
      ...validActivities,
    ];
    setComplexityValidActivities(totalValidActivities);
  }, [validActivities, validDeliveryActivities]);

  // on deliveryPhase Change get Roles
  const [loadgetActivityAndRaciData, setgetActivityAndRaciData] = useState(0);
  // useEffect(() => {
  //   let phaseTimeOutId;
  //   let parentPhaseTimeOutId;
  //   if (!showMatrixView) {
  //     if (
  //       parentPhase.id !== null && parentPhase.id !== loadgetActivityAndRaciData &&
  //       dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)
  //     ) {
  //       getRoles(parentPhase.id, "parentPhase");
  //       ///getActivityAndRaciData("parentPhase");
  //       setgetActivityAndRaciData(parentPhase.id);
  //     }
  //     if (
  //       phase.id !== null && phase.id !== loadgetActivityAndRaciData &&
  //       dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)
  //     ) {
  //       getRoles(phase.id, "phase");
  //       //getActivityAndRaciData("phase");
  //       setgetActivityAndRaciData(phase.id);
  //     }
  //   }
  //   if (phase.id !== null || parentPhase.id !== null) {
  //     phaseTimeOutId = setTimeout(() => {
  //       getActivityAndRaciData("phase");
  //     }, 1000);
  //   }
  //   if (parentPhase.id !== null) {
  //     parentPhaseTimeOutId = setTimeout(() => {
  //       getActivityAndRaciData("parentPhase");
  //     }, 1000);
  //   }
  // }, [phase.id, parentPhase.id, dynamicFilterData, showMatrixView, complexity.id,
  //   role,
  //   dynamicFilterTag,
  //   phaseHashtags,
  //   searchInput]);

  useEffect(() => {
    let phaseTimeOutId;
    let parentPhaseTimeOutId;
    if(complexity.id !==''){
      if (!showMatrixView) {
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("phase");
          }, 1000);
  
          if (phase.id !== loadgetActivityAndRaciData &&
            dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)) {
            getRoles(phase.id, "phase");
            // getActivityAndRaciData("phase");
            setgetActivityAndRaciData(phase.id);
          }
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("parentPhase");
          }, 1000);
  
          if (parentPhase.id !== loadgetActivityAndRaciData &&
            dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)) {
            getRoles(parentPhase.id, "parentPhase");
            // getActivityAndRaciData("parentPhase");
            setgetActivityAndRaciData(parentPhase.id);
          }
        }
      }
      else {
        if (phase.id !== null) {
          phaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("phase");
          }, 1000);
        }
        if (parentPhase.id !== null) {
          parentPhaseTimeOutId = setTimeout(() => {
            getActivityAndRaciData("parentPhase");
          }, 1000);
        }
      }
    }
     return () => {
      clearTimeout(phaseTimeOutId);
      clearTimeout(parentPhaseTimeOutId);
    };
  }, [complexity.id, parentPhase.id, phase.id, role, dynamicFilterData, dynamicFilterTag, phaseHashtags, searchInput]);

  // on Phase Change reset complexity & roles & get new info
  //   useEffect(() => {
  //     if (!showMatrixView) {
  //       if (
  //         phase.id !== null &&
  //         dynamicFilterData.some(({ artifactTypeId }) => artifactTypeId === 16)
  //       ) {
  //         getRoles(phase.id, "phase");
  //         getActivityAndRaciData("phase");
  //       }
  //     }
  //   }, [phase.id, dynamicFilterData, showMatrixView]);

  useEffect(() => {
    if (showMatrixView) {
      getAllRoles();
    }
  }, [showMatrixView]);

  // useEffect(() => {
  //   let phaseTimeOutId;
  //   let parentPhaseTimeOutId;
  //   if (phase.id !== null || parentPhase.id !== null) {
  //     phaseTimeOutId = setTimeout(() => {
  //       getActivityAndRaciData("phase");
  //     }, 1000);
  //   }
  //   if (parentPhase.id !== null) {
  //     parentPhaseTimeOutId = setTimeout(() => {
  //       getActivityAndRaciData("parentPhase");
  //     }, 1000);
  //   }
  //   return () => {
  //     clearTimeout(phaseTimeOutId);
  //     clearTimeout(parentPhaseTimeOutId);
  //   };
  // }, [
  //   complexity.id,
  //   parentPhase.id,
  //   phase.id,
  //   role,
  //   dynamicFilterTag,
  //   phaseHashtags,
  //   searchInput,
  // ]);

  useEffect(() => {
    const staticRefValue = Object.values(enableSearch);

    if (staticRefValue.includes(true) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [enableSearch]);

  return (
    <div
      id="filtersRightPanel"
      className={!showFilters ? "hideFiltersRightPanel" : ""}
    >
      <div className="filterPanelHeader">
        <h5>Filters</h5>
        <Icon
          className="searchIcon"
          iconName="cancel"
          onClick={() => {
            setShowFilters(false);
          }}
          onKeyPress={(e) => {
            if (e.which === 13) {
              setShowFilters(false);
            }
          }}
          tabIndex={0}
          role="button"
          aria-label="Close Filters"
        />
      </div>
      <div className="filtersPanelBody">
        {isEnableComplexity && <div className="filtersCategoryPanel">
          <h6>Complexity</h6>
          <ComplexityFilter
            className="complexityFilter"
            data={complexities}
            setValue={setComplexity}
            value={complexity}
            enableDropdown={
              phase.enableComplexity ||
              (phase.id === null && parentPhase.enableComplexity)
            }
            setSelectedComplexity={setSelectedComplexity}
            setSelectedComplexityId={setSelectedComplexityId}
          />
        </div>}
        {dynamicFilterData.map((item) => {
          if (item.artifactTypeId === 16)
            return (
              <div className="filtersCategoryPanel" key={item.artifactTypeId}>
                {enableSearch["Roles"] ? (
                  <div className="rolesSearch">
                    <input
                      type="text"
                      placeholder="Search for a role"
                      value={roleSearchValue}
                      onChange={(e) => {
                        setRoleSearchValue(e.target.value);
                        searchRoles(e.target.value);
                      }}
                      ref={searchInputRef}
                    />
                    <Icon
                      className="searchIcon"
                      iconName="cancel"
                      onClick={() => {
                        setEnableSearch((prev) => ({ ...prev, Roles: false }));
                        setRoleSearchValue("");
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setEnableSearch((prev) => ({
                            ...prev,
                            Roles: false,
                          }));
                          setRoleSearchValue("");
                        }
                      }}
                      tabIndex={0}
                      role="button"
                      aria-label="Cancel Search Filter"
                    />
                  </div>
                ) : (
                  <div className="filtersearch">
                    <h6>Roles</h6>
                    <span
                      className="searchIcon"
                      onClick={() =>
                        setEnableSearch((prev) => ({ ...prev, Roles: true }))
                      }
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setEnableSearch((prev) => ({ ...prev, Roles: true }));
                        }
                      }}
                      tabIndex={0}
                      role="button"
                      aria-label="Click to enable Roles search"
                    >
                      <Icon iconName="search" className="searchIcon" />
                    </span>
                  </div>
                )}

                <RolesFilter
                  className="roleFilter"
                  data={roleSearchValue ? totalFilteredRoles : totalRoles}
                  setValue={setRole}
                  value={role}
                  enableDropdown={
                    phase.enableComplexity ||
                    (phase.id === null && parentPhase.enableComplexity)
                  }
                  setSelectedRoles={setSelectedRoles}
                  selectedRoles={selectedRoles}
                  setSelectedRoleId={setSelectedRoleId}
                />
              </div>
            );
          else {
            return (
              <div className="filtersCategoryPanel" key={item.artifactTypeId}>
                {item.artifactData.length > 5 ? (
                  <>
                    {dynamicFilterSearchEnable[item.artifactTypeId] ? (
                      <div className="rolesSearch">
                        <input
                          type="text"
                          placeholder={`Search for  ${item.artifactTypeName}`}
                          value={dynamicSearchText[item.artifactTypeId]}
                          onChange={(e) => {
                            e.persist();
                            searchForArtifact(
                              e.target.value,
                              item.artifactTypeId,
                              item.artifactData
                            );
                          }}
                          ref={searchInputRef}
                        />
                        <Icon
                          className="searchIcon"
                          iconName="cancel"
                          onClick={() => {
                            setDynamicFilterSearchEnable({
                              ...dynamicFilterSearchEnable,
                              [item.artifactTypeId]: false,
                            });
                            setDynamicSearchText({
                              ...dynamicSearchText,
                              [item.artifactTypeId]: "",
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: false,
                              });
                              setDynamicSearchText({
                                ...dynamicSearchText,
                                [item.artifactTypeId]: "",
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label="Cancel Search Filter"
                        />
                      </div>
                    ) : (
                      <div className="filtersearch">
                        <h6>{item.artifactTypeName}</h6>
                        <span
                          className="searchIcon"
                          onClick={() =>
                            setDynamicFilterSearchEnable({
                              ...dynamicFilterSearchEnable,
                              [item.artifactTypeId]: true,
                            })
                          }
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: true,
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label={`click to search ${item.artifactTypeName} `}
                        >
                          <Icon iconName="search" className="searchIcon" />
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <h6>{item.artifactTypeName}</h6>
                )}
                <DynamicFilter
                  data={
                    dynamicSearchText[item.artifactTypeId]
                      ? dynamicFilterSearchResult[item.artifactTypeId]
                      : item.artifactData
                  }
                  value={dynamicFilterTag}
                  artifactTypeName={item.artifactTypeName}
                  setValue={setDynamicFilterTag}
                  artifactTypeId={item.artifactTypeId}
                  getDynamicFilterData={getDynamicFilterData}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Complexity;
