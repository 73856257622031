import React, { useContext, createContext, useState } from 'react';
import axios from 'axios';

import { useConfig } from './ConfigContext';
import { useAuth } from './UserAuthContext';

const AxiosService = () => {
	const [apiDown, setApiDown] = useState(false);
	const { apiEndPoint } = useConfig();
	const { user, getMsalAccessToken } = useAuth();
	const userAlias = user.profile.mail;

	const getAxiosInstance = async () => {
		const accessToken = await getMsalAccessToken();
		sessionStorage.setItem('acceesKey', accessToken);
		sessionStorage.setItem('userAlias', userAlias);
		const axiosInstance = axios.create({
			baseURL: apiEndPoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				UserAlias: userAlias,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods':
					'GET, POST, PATCH, PUT, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
		});
		return axiosInstance;
	};

	const getAxiosInstanceSwimlane = async () => {
		const accessToken = await getMsalAccessToken();
		sessionStorage.setItem('acceesKey', accessToken);
		sessionStorage.setItem('userAlias', userAlias);
		const axiosInstance = axios.create({
			baseURL: apiEndPoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				UserAlias: userAlias,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods':
					'GET, POST, PATCH, PUT, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'multipart/form-data',
			},
		});
		return axiosInstance;
	};

	const getAxiosInstanceWithCancel = async (cancelToken) => {
		const accessToken = await getMsalAccessToken();
		sessionStorage.setItem('acceesKey', accessToken);
		sessionStorage.setItem('userAlias', userAlias);
		const axiosInstance = axios.create({
			baseURL: apiEndPoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				UserAlias: userAlias,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods':
					'GET, POST, PATCH, PUT, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
			cancelToken: cancelToken,
		});
		return axiosInstance;
	};

	const getAxiosFileInstance = async () => {
		const accessToken = await getMsalAccessToken();
		sessionStorage.setItem('acceesKey', accessToken);
		sessionStorage.setItem('userAlias', userAlias);
		const axiosInstance = axios.create({
			baseURL: apiEndPoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				UserAlias: userAlias,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods':
					'GET, POST, PATCH, PUT, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'multipart/form-data',
			},
			responseType: 'blob',
		});
		return axiosInstance;
	};

	const axiosGetFileService = async (path) => {
		const axiosInstance = await getAxiosFileInstance();
		axiosInstance.responseType = 'blob';
		let response = {};
		await axiosInstance
			.get(path)
			.then((res) => {
				response = res;
			})
			.catch(function (error) {
				const auth = error.response && error.response.status === 401;
				if (user.isAuthenticated && !auth) {
					setApiDown(true);
				}
			});
		return response;
	};

	const axiosGetService = async (path) => {
		const axiosInstance = await getAxiosInstance();
		let response = {};
		await axiosInstance
			.get(path)
			.then((res) => {
				response = res;
			})
			.catch(function (error) {
				const auth = error.response && error.response.status === 401;
				if (user.isAuthenticated && !auth) {
					setApiDown(true);
				}
			});
		return response;
	};

	const axiosGetServiceWithCancel = async (path, cancelToken) => {
		const axiosInstance = await getAxiosInstanceWithCancel(cancelToken);
		let response = {};
		await axiosInstance
			.get(path)
			.then((res) => {
				response = res;
			})
			.catch(function (error) {
				const auth = error.response && error.response.status === 401;
				if (user.isAuthenticated && !auth && !axios.isCancel(error)) {
					setApiDown(true);
				}
			});
		return response;
	};

	const axiosPostService = async (path, body) => {
		const axiosInstance = await getAxiosInstance();
		const response = await axiosInstance.post(path, body);
		return response;
	};

	const axiosPostServiceSwimlane = async (path, body) => {
		const axiosInstance = await getAxiosInstanceSwimlane();
		const response = await axiosInstance.post(path, body);
		return response;
	};

	const axiosDeleteService = async (path) => {
		const axiosInstance = await getAxiosInstance();
		let response = {};
		await axiosInstance
			.delete(path)
			.then((res) => {
				response = res;
			})
			.catch(function (error) {
				const auth = error.response && error.response.status === 401;
				if (user.isAuthenticated && !auth) {
					setApiDown(true);
				}
			});
		return response;
	};
	return {
		axiosGetService,
		axiosGetFileService,
		axiosPostService,
		axiosDeleteService,
		axiosGetServiceWithCancel,
		userAlias,
		apiDown,
		setApiDown,
		axiosPostServiceSwimlane,
	};
};

const AxiosContext = createContext();
const AxiosProvider = ({ children }) => {
	const axiosFunctions = AxiosService();
	return (
		<AxiosContext.Provider value={axiosFunctions}>
			{children}
		</AxiosContext.Provider>
	);
};

const useAxios = () => useContext(AxiosContext);

export { AxiosProvider, useAxios, AxiosContext };
