import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { v4 as uniqueIdGenerator } from 'uuid';

import MyContributionInfo from '../MyContributionInfo';
import ReviewersOrApproversList from '../ReviewersOrApproversList';
//context
import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';

const ActivityContribution = ({
	currentPage,
	pageLimit,
	setDataCount,
	setCurrentPage,
	handleStatusClassName,
	enableGamification,
}) => {
	const { url } = useRouteMatch();
	const { track } = useAppInsights();
	const { getNotifications } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService } = useAxios();
	const [searchText, setSearchText] = useState('');
	const [contributions, setContributions] = useState([]);
	const [contributorsInfo, setContributorsInfo] = useState({});
	const [documentStatusId, setDocumentStatusId] = useState(-1);
	const [showApproversOrReviewersList, setShowApproversOrReviewersList] =
		useState(false);
	const [selectedArtifactId, setSelectedArtifactId] = useState(-1);

	const getContributions = async () => {
		const searchParam = searchText === '' ? 0 : searchText;
		setDataCount(0);
		setContributions([]);
		await axiosGetService(
			`api/sdmactivity/getactivitycontributions?activityName=${searchParam}&pageSize=${pageLimit}&pageNumber=${currentPage}`
		).then((res) => {
			if (Number(res.data.count) > 0) {
				const contributionData = [];
				for (const activity of res.data.activities) {
					contributionData.push({ ...activity, uniqueId: uniqueIdGenerator() });
				}
				setContributions(contributionData);
				setDataCount(Number(res.data.count));
			} else {
				setContributions(null);
				setDataCount(null);
			}
		});
	};

	const getUserDetails = async (
		contributedEmail,
		reviewerEmail,
		approverEmail
	) => {
		const accessToken = await axiosTokenService();
		const userDetails = {};
		if (contributedEmail && !(contributedEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, contributedEmail);
			userDetails[contributedEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (reviewerEmail && !(reviewerEmail in userDetails)) {
			const userData = await getOtherUserProfile(accessToken, reviewerEmail);
			userDetails[reviewerEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (approverEmail && !(approverEmail in userDetails)) {
			const userData = await getOtherUserProfile(accessToken, approverEmail);
			userDetails[approverEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		setContributorsInfo((prevState) => ({ ...prevState, ...userDetails }));
	};

	const postNotificationRead = async (contribution) => {
		const postBody = {
			actionTypeId: contribution.actionTypeId,
			complexityId: contribution.complexityId,
			itemId: contribution.activityId,
			itemTypeName: 'Activity',
			isNotifiedUI: true,
			createdOn: new Date().toUTCString(),
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then((response) => {
				getNotifications();
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [searchText]);

	useEffect(() => {
		const timeOut = setTimeout(() => {
			getContributions();
		}, 800);
		return () => {
			clearTimeout(timeOut);
		};
	}, [currentPage, searchText]);

	useEffect(() => {
		track(2, 164, 'Activity', 'My Contributions');
	}, []);

	return (
		<div className="myContributionListDiv">
			<div className="myContributionParentDiv">
				<div className="myConributionHeaderDiv">
					<div className="newTableChildRow header">
						<div className="newTableRow">
							<div className="newWrapper newAttributes">
								<div className="newWrapper newTitleCommentModuleReporter ">
									<div className="newWrapper newTitleComment">
										<div className="newColumn newTitle newActTd ">
											Activity Name{' '}
											<div className="myContrTitleSearch">
												<Icon iconName="Search" />
												<input
													type="text"
													title="Search for an Activity"
													value={searchText}
													placeholder="Search by name"
													onChange={(e) => setSearchText(e.target.value)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Complexity</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">
									Contribution Date
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status  newActTd ">
									Contribution Status
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status newActTd ">
									Document Status
								</div>
							</div>
							{enableGamification ? (
								<div className="newWrapper ">
									<div className="newColumn points newActTd ">Points</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{contributions &&
					contributions.map((contribution, id) => (
						<div className="mycontributionChildWrapper" key={id}>
							<div className="newTableChildRow">
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment">
												<div className="newColumn newTitle newActTd ">
													<Link
														title={contribution.activityName}
														aria-label={contribution.activityName}
														onClick={() => {
															postNotificationRead(contribution);
															track(
																1,
																164,
																contribution.activityId,
																contribution.activityName,
																{
																	artifactType: 'Activity',
																	crowdSourcingType: 'My Contributions',
																	complexity: contribution.complexityName,
																}
															);
														}}
														to={
															contribution.documentStatus === 'Pending Review'
																? `/project/${contribution.projectTypeId}/method/${contribution.solutionMethodId}/phase/${contribution.phaseId != 0 ? contribution.phaseId : contribution.parentPhaseId}/parentPhase/null/activity/${contribution.activityId}/edit?complexity=${contribution.complexityId}&identifier=${contribution.activityIdentifier}`
																: `${url}/historicalpreview/activity/${contribution.id}/${contribution.activityId}/${contribution.stateId}?complexity=${contribution.complexityId}`
														}
													>
														{contribution.activityName}
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.complexityName}
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date  newActTd">
											{contribution.contributionDate || '-'}
										</div>
									</div>
									<div className="newWrapper ">
										<div
											className={`newColumn status newActTd  ${handleStatusClassName(
												contribution.contributionStatus
											)}`}
										>
											{contribution.contributionStatus || '-'}
										</div>
									</div>
									<div className="newWrapper ">
										<div
											className={`newColumn status newActTd contributionsInfo ${handleStatusClassName(
												contribution.documentStatus
											)}`}
											tabIndex={0}
											role="button"
											aria-label={contribution.documentStatus}
											onMouseEnter={() => {
												setDocumentStatusId(id);
												getUserDetails(
													contribution.contributedEmail,
													contribution.reviewerEmail,
													contribution.approverEmail
												);
											}}
											onMouseLeave={() => {
												setDocumentStatusId(-1);
											}}
											onBlur={(e) => {
												setDocumentStatusId(-1);
											}}
											onFocus={(e) => {
												setDocumentStatusId(id);
												getUserDetails(
													contribution.contributedEmail,
													contribution.reviewerEmail,
													contribution.approverEmail
												);
											}}
											onClick={() => {
												if (
													contribution.documentStatus === 'Pending Review' ||
													contribution.documentStatus === 'Pending Approval'
												) {
													setShowApproversOrReviewersList(true);
													setSelectedArtifactId(contribution.uniqueId);
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													if (
														contribution.documentStatus === 'Pending Review' ||
														contribution.documentStatus === 'Pending Approval'
													) {
														setShowApproversOrReviewersList(true);
														setSelectedArtifactId(contribution.uniqueId);
													}
												}
											}}
										>
											{contribution.documentStatus || '-'}
											{documentStatusId === id ? (
												<MyContributionInfo
													contributorEmail={contribution.contributedEmail}
													reviewerEmail={contribution.reviewerEmail}
													approverEmail={contribution.approverEmail}
													contributionData={contribution}
													contributorsInfo={contributorsInfo}
													type="myContribution"
													documentStatus={contribution.documentStatus}
												/>
											) : (
												<></>
											)}
										</div>
									</div>
									{enableGamification ? (
										<div className="newWrapper ">
											<div className="newColumn points  newActTd">
												{contribution.points}
											</div>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
							{showApproversOrReviewersList &&
							contribution.uniqueId === selectedArtifactId ? (
								<ReviewersOrApproversList
									showList={setShowApproversOrReviewersList}
									setShowList={setShowApproversOrReviewersList}
									actionTypeId={
										contribution.documentStatus === 'Pending Review' ? 1 : 2
									}
									artifactId={contribution.activityId}
									artifactType="activities"
									setSelectedArtifactId={setSelectedArtifactId}
								/>
							) : (
								<></>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default ActivityContribution;
