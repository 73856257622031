import React, { useState, useEffect } from 'react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { useAppInsights } from '../../context/TelemetryContext';
import { ComboBox,Dropdown } from 'office-ui-fabric-react';
import $ from 'jquery';
import '../Dropdown/Dropdown.css';
import './MultiSelectDropdownFluent.css';

const MultiSelectDropdownFluent = ({
	className,
	setValue,
	value,
	toolsAndGuidanceSuggestions,
	setToolsAndGuidanceSuggestions,
	enableDropdown,
	adminToolsAndGuidance,
	currentUser,
	checkForAdminToolsUpdate,
	setCheckForAdminToolsUpdate,
	contributionToolsId,
}) => {
	const [visible, setVisible] = useState(false);
	const [selectionCount, setSelectionCount] = useState(0);
	const [captureUserToolsSelection, setCaptureUserToolsSelection] = useState([
		...value,
	]);
	
	const [
		toolsAndGuidanceSuggestionsUpdate,
		setToolsAndGuidanceSuggestionsUpdate,
	] = useState([...toolsAndGuidanceSuggestions]);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown?.contains(e.target)) {
			setVisible(false);
		}
	};
	const dropdownCollapseFluent = (e) => {		
		const dropdown = document.getElementById("toolDropdownFluent");
		if (!dropdown?.contains(e.target)) {
			setVisible(false);			
		}
	};
	const handleToolsReset = () => {
		if (!visible) {
			setCaptureUserToolsSelection([...value]);
			setToolsAndGuidanceSuggestionsUpdate([...toolsAndGuidanceSuggestions]);
			//setSelectedKeys(getSelectedKeys(value))
		}
	};
	const getSelectionCount = () => {
		let count = 0;
		for (const key in value) {
			if (JSON.parse(value[key]).suggestionType !== 'deletion') {
				count = count + 1;
			}
		}
		setSelectionCount(count);
	};

	useEffect(() => {
		setCaptureUserToolsSelection([...value]);
		getSelectionCount();
		setSelectedKeys(getSelectedKeys(value))
	}, [value]);

	useEffect(() => {
		if (!visible) {
			handleToolsReset();
		}
	}, [visible,value]);

	useEffect(() => {
		setToolsAndGuidanceSuggestionsUpdate([...toolsAndGuidanceSuggestions]);
		setOptions(getAddOnTemplatesDropdownOptions(toolsAndGuidanceSuggestions))
	}, [toolsAndGuidanceSuggestions]);
	
	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		window.addEventListener('click', dropdownCollapseFluent)
		return () => {
			window.removeEventListener('click', dropdownCollapse);
			window.removeEventListener('click', dropdownCollapseFluent);
		};
	}, []);

	const handleDropdown = (event, item) => {
		//event.persist();		
		if (
			contributionToolsId[item.toolsId] &&
			contributionToolsId[item.toolsId] !== currentUser
		) {
			return;
		}
		let data = [];
		for (const key in toolsAndGuidanceSuggestionsUpdate) {
			const updatedData = {
				toolsId: toolsAndGuidanceSuggestionsUpdate[key].toolsId,
				isSelected: toolsAndGuidanceSuggestionsUpdate[key].isSelected,
				name: toolsAndGuidanceSuggestionsUpdate[key].name,
				option: 'type',
			};
			if (updatedData.toolsId === item.toolsId) {
				updatedData.isSelected = item.selected;
			}
			data.push(updatedData);
		}
		setToolsAndGuidanceSuggestionsUpdate(data);

		const selectedSuggestionCardData = {
			toolsId: item.toolsId,
			id: uniqueIdGenerator(),
			authorId: currentUser,
			suggestionType:
				adminToolsAndGuidance.includes(item.toolsId) && !item.selected
					? 'deletion'
					: adminToolsAndGuidance.includes(item.toolsId)
					? 'none'
					: 'insertion',
			createdAt: new Date(),
			name: item.name,
			type: 'option',
			isSelected: item.selected,
		};
		if (captureUserToolsSelection.length > 0) {
			data = [];
			let found = false;
			for (const key in captureUserToolsSelection) {
				const suggestionCardData = {
					toolsId: JSON.parse(captureUserToolsSelection[key]).toolsId,
					id: uniqueIdGenerator(),
					authorId: JSON.parse(captureUserToolsSelection[key]).authorId
						? JSON.parse(captureUserToolsSelection[key]).authorId
						: currentUser,
					suggestionType: !adminToolsAndGuidance.includes(
						JSON.parse(captureUserToolsSelection[key]).toolsId
					)
						? 'insertion'
						: checkForAdminToolsUpdate.includes(
								JSON.parse(captureUserToolsSelection[key]).toolsId
						  )
						? 'deletion'
						: 'none',
					name: JSON.parse(captureUserToolsSelection[key]).name,
					type: 'option',
					createdAt: new Date(),
					isSelected: JSON.parse(captureUserToolsSelection[key]).isSelected,
				};
				if (
					Number(JSON.parse(captureUserToolsSelection[key]).toolsId) ===
					Number(item.toolsId)
				) {
					found = true;
					if (item.selected) {
						suggestionCardData['isSelected'] = item.selected;
						suggestionCardData['suggestionType'] =
							adminToolsAndGuidance.includes(item.toolsId) &&
							!item.selected
								? 'deletion'
								: 'none';
						if (
							adminToolsAndGuidance.includes(item.toolsId) &&
							!item.selected
						) {
							if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
								setCheckForAdminToolsUpdate([
									...checkForAdminToolsUpdate,
									item.toolsId,
								]);
							}
						} else if (
							adminToolsAndGuidance.includes(item.toolsId) &&
							item.selected
						) {
							const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
								(id) => id === item.toolsId
							);							
							setCheckForAdminToolsUpdate(removeCheckedSuggestion);
						}
						data.push(JSON.stringify(suggestionCardData));
					} else if (adminToolsAndGuidance.includes(item.toolsId)) {
						suggestionCardData['isSelected'] = false;
						suggestionCardData['suggestionType'] = 'deletion';

						data.push(JSON.stringify(suggestionCardData));
					}
				} else if (
					Number(JSON.parse(captureUserToolsSelection[key]).toolsId) !==
					Number(item.toolsId)
				) {
					suggestionCardData['suggestionType'] =
						JSON.parse(captureUserToolsSelection[key]).suggestionType !== 'none'
							? JSON.parse(captureUserToolsSelection[key]).suggestionType
							: 'none';
					data.push(JSON.stringify(suggestionCardData));
				}
			}
			if (!found) {
				if (
					adminToolsAndGuidance.includes(item.toolsId) &&
					!item.selected
				) {
					if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
						setCheckForAdminToolsUpdate([
							...checkForAdminToolsUpdate,
							item.toolsId,
						]);
					}
				} else if (
					adminToolsAndGuidance.includes(item.toolsId) &&
					item.selected
				) {
					const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
						(id) => id === item.toolsId
					);
					setCheckForAdminToolsUpdate(removeCheckedSuggestion);
				}
				data.push(JSON.stringify(selectedSuggestionCardData));
			}			

			setCaptureUserToolsSelection(data);
		} else {
			if (
				adminToolsAndGuidance.includes(item.toolsId) &&
				!item.selected
			) {
				if (!checkForAdminToolsUpdate.includes(item.toolsId)) {
					setCheckForAdminToolsUpdate([
						...checkForAdminToolsUpdate,
						item.toolsId,
					]);
				}
			} else if (
				adminToolsAndGuidance.includes(item.toolsId) &&
				item.selected
			) {
				const removeCheckedSuggestion = checkForAdminToolsUpdate.filter(
					(id) => id === item.toolsId
				);
				setCheckForAdminToolsUpdate(removeCheckedSuggestion);
			}
			setCaptureUserToolsSelection([
				...captureUserToolsSelection,
				JSON.stringify(selectedSuggestionCardData),
			]);
		
	}
	};
	
	const getAddOnTemplatesDropdownOptions = (templates) => {	
        const templatesArray = [];	
        templates.map(items => {			
        templatesArray.push({	
        key: items.toolsId,	
        text: items.name,	
		toolsId:items.toolsId,	
		isSelected: items.isSelected,	
		name:items.name,	
		option:items.option,
		activities: items.activities,
		createdBy: items.createdBy,
		createdOn:items.createdOn,
		email:items.email,		
		modifiedBy: items.modifiedBy,
		modifiedOn:items.modifiedOn,
		modifiedUserEmail: items.modifiedUserEmail,		
		url:items.url
        })	
      })	
      return templatesArray;	
    }
	const getSelectedKeys = (value) => {		
        const templatesArray = [];
		const templates=[];
		const tempArray=value.map((item)=>{
			templates.push(JSON.parse(item))}); 
	  		templates.map((item)=>{
			return templatesArray.push(item.toolsId);
	  })	  
      return templatesArray;	
    }		
	  let newKey = 1;	
	  const [selectedKeys, setSelectedKeys] = React.useState(getSelectedKeys(value));
	  const [options, setOptions] = React.useState(getAddOnTemplatesDropdownOptions(toolsAndGuidanceSuggestionsUpdate));	
	  
	  const onmenuOpen=()=>{
		$('.toolsHeaderEditor:contains(Tools)').css('border','1px solid blue');	
		setVisible(true);	
	  }
	  
	  const onblur=()=>{		
		setSelectedKeys(getSelectedKeys(value));
		$('.toolsHeaderEditor:contains(Tools)').css({'border-bottom':'1px solid #c4c4c4',
		'border-right':'none',
		'border-left':'none',
		'border-top':'none'
	});
			
	  }  
	  
	  const onChange = React.useCallback(	
		(event, option, index, value)=> {	
		  let selected = option?.selected;	
		  setVisible(true);	
		  if (!option && value) {        	
			selected = true;	
			option = { key: `${newKey++}`, text: value };	
			setOptions(prevOptions => [...prevOptions, option]);	
			handleDropdown(event,option);
		  }	
		
		  if (option) {	
			setSelectedKeys(prevSelectedKeys =>	
			  selected ? [...prevSelectedKeys, option.key] : prevSelectedKeys.filter(k => k !== option.key),	
			);	
			handleDropdown(event,option);
		  }	
		},	
			
	  );
	  const dropdownStyles = {		
		callout: { maxHeight: 300, overflow: 'scroll' },			
	  };
	 return (
		<div id="toolDropdown">
			<div id="toolDropdownFluent">
		<Dropdown	
		disabled={!enableDropdown}	
        multiSelect	
        selectedKeys={selectedKeys}	
		onRenderTitle={()=>{return `${selectedKeys?.length} Selected`}} 
        //label={`${selectedKeys?.length} Selected` }        	
        options={options}	
        onChange={onChange}
		onClick={onmenuOpen}
		onDismiss={onblur}	
		//onMenuOpen={onmenuOpen}	
		onBlur={onblur}		
        styles={dropdownStyles}	
		aria-label="Tools"	
      />	</div>
	  {visible ? <div className="filtersApplyButtonsFluent">
							<button
								tabIndex={0}
								onClick={() => {									
									setVisible(!visible);
									setCaptureUserToolsSelection([...value]);
									setToolsAndGuidanceSuggestionsUpdate([
										...toolsAndGuidanceSuggestions,
									]);
								}}
								aria-label="cancel"
							>
								Cancel
							</button>
							<button
								tabIndex={0}
								onClick={() => {
									setValue([...captureUserToolsSelection]);
									setToolsAndGuidanceSuggestions([
										...toolsAndGuidanceSuggestionsUpdate,
									]);
									setVisible(!visible);
								}}
							>
								Apply
							</button>
						</div>: ''}
		</div>
	);
};

export default MultiSelectDropdownFluent;
