import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import MyContributions from './MyContributions';
import MyContributionsPreview from './HistoricalPreview/MyContributions';
import MyReviewsPreview from './HistoricalPreview/MyReviews';
import MyApprovals from './HistoricalPreview/MyApprovals';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';

const MyContributionIndex = () => {
 const artifactTypes = ['activity', 'topics', 'template'];
 const { pathname } = useLocation();
 const history = useHistory();
 const { url } = useRouteMatch();
 const { axiosGetService } = useAxios();
 const [selectedArtifact, setSelectedArtifact] = useState('Activities');
 const [selectedCrowdSourcingType, setSelectedCrowdSourcingType] =
  useState('My Contributions');
 const [enableGamification, setEnableGamification] = useState(false);

 const checkGamificationStatus = async () => {
  await axiosGetService(`api/sdmsettings?sdmKey=EnableGamification`).then(
   (response) => {
    if (response.data[0].value === 'true') {
     setEnableGamification(true);
    }
   }
  );
 };

 const validateArtifact = (paths) => {
  const isValidPath = artifactTypes.filter((artifact) =>
   paths.some((item) => item === artifact)
  );
  return isValidPath.length > 0 ? true : false;
 };

 const validateUrl = () => {
  const paths = pathname.split('/').filter((path) => path);
  if (paths.length > 1 && !validateArtifact(paths)) {
   history.push('/pageNotFound');
  }
 };

 useEffect(() => {
  checkGamificationStatus();
  validateUrl();
 }, []);

 return (
  <Switch>
   <Route exact path={`${url}`}>
    <MyContributions
     selectedArtifact={selectedArtifact}
     setSelectedArtifact={setSelectedArtifact}
     selectedCrowdSourcingType={selectedCrowdSourcingType}
     setSelectedCrowdSourcingType={setSelectedCrowdSourcingType}
     enableGamification={enableGamification}
     setEnableGamification={setEnableGamification}
    />
   </Route>
   <Route
    exact
    path={`${url}/historicalpreview/:artifactType/:id/:artifactId/:stateId`}
   >
    <MyContributionsPreview enableGamification={enableGamification} />
   </Route>
   <Route
    exact
    path={`${url}/historicalpreview/myreviews/:artifactType/:id/:artifactId/:stateId`}
   >
    <MyReviewsPreview enableGamification={enableGamification} />
   </Route>
   <Route
    exact
    path={`${url}/historicalpreview/myapprovals/:artifactType/:id/:artifactId/:stateId`}
   >
    <MyApprovals enableGamification={enableGamification} />
   </Route>
   <Route path="*">
    <ComingSoon />
   </Route>
  </Switch>
 );
};

const ComingSoon = () => (
 <h2 className="pageNotFound">
  <b>404 Page not found!</b>
 </h2>
);

export default MyContributionIndex;
