import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import TimezoneSelect from 'react-timezone-select';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import addMonths from 'date-fns/addMonths';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { Icon } from '@fluentui/react';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import Timepicker from './Timepicker';
import { time as TimeData } from '../../helpers/Validation';

import 'react-datepicker/dist/react-datepicker.css';
const millisecondsInHour = 3600000;
const PhasePublishModal = ({
	phaseData,
	showPublishModal,
	setShowPublishModal,
	solutionMethodId,
	setPhaseCreated,
	isActivityCountZero,
	complexity,
	setPhaseData,
	coOwners,
	type,
	parentId,
	getParentSolutionMethodData,
	setParentPhaseCreated,
	parentPhaseCreated,
	setShowActivityBuilder,
	setShowPhaseUpdateModal,
	setUpdatePhaseId,
	setSelectedTab,
	getSolutionMethodData,
	phaseCreated,
	activityGroups,
	setShowSubgroupForm,
	setActivityBuilderTabId,
	setSelectedGroupDetails,
	setSelectedSubgroupDetails,
	setShowSubgroupEditForm,
}) => {
	const currentTime = new Date().getHours();
	const publishType = [
		{
			id: 1,
			name: 'Publish Now',
			type: 'Publish',
		},
		{ id: 2, name: 'Publish Later', type: 'Schedule' },
	];

	const { track } = useAppInsights();
	const { url } = useRouteMatch();
	const { axiosPostService, axiosGetService, userAlias, setApiDown } =
		useAxios();
	const [validatePhaseDetails, setValidatePhaseDetails] = useState(false);
	const { phaseId, parentPhaseId } = useParams();
	const [approvalStatus, setApprovalStatus] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [publishActivityDetails, setPublishActivityDetails] = useState({
		publishedActivities: [],
		notPublishedActivities: [],
	});
	const [showSpinner, setShowSpinner] = useState(true);
	const [successMessage, setSuccessMessage] = useState('');
	const [orphanGroups, setOrphanGroups] = useState([]);
	const [orphanSubgroups, setOrphanSubgroups] = useState([]);
	const [selectedPublishType, setSelectedPublishType] = useState({
		id: 1,
		name: 'Publish Now',
		type: 'Publish',
	});
	const [publishSchedule, setPublishSchedule] = useState({
		time: { value: '', format: '' },
		date: new Date(),
	});
	const [selectedTimezone, setSelectedTimezone] = useState({});

	function convertTime() {
		const selectedDate = publishSchedule.date;
		const date = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate()
		);
		date.setHours(publishSchedule.time.format.split(':')[0], '0', '0', '0');
		const UTCdate = new Date(
			date.getTime() - selectedTimezone.offset * millisecondsInHour
		);
		return UTCdate.toLocaleString().split('GMT')[0] + ' GMT';
	}

	const getOrphanGroupsAndSubgroup = () => {
		const groups = [];
		const subgroups = [];
		for (const group of activityGroups) {
			if (group.groupId) {
				if (group.activities.length === 0) {
					groups.push(group);
				} else {
					let activityCount = 0;
					for (const activity of group.activities) {
						if (activity.isSubgroup && activity.activities.length === 0) {
							subgroups.push({ group: group, subgroup: activity });
						} else {
							activityCount += 1;
						}
					}
					if (activityCount === 0) {
						groups.push(group);
					}
				}
			}
		}
		setOrphanGroups(groups);
		setOrphanSubgroups(subgroups);
	};

	const submitPhaseDetails = async () => {
		setShowSpinner(true);
		const postData = {
			parentPhaseId: phaseData.phaseId,
			solutionMethodId:
				type === 'parentPhase' ? parentId : Number(solutionMethodId),
		};
		await axiosPostService(`api/sdmactivity/publish`, postData).then(
			(response) => {
				if (response.data.status) {
					setSuccessMessage('You have successfully published');
					if (type === 'parentPhase') {
						track(1, 805, phaseData.phaseId, phaseData.phaseName);
					} else {
						track(1, 804, phaseData.phaseId, phaseData.phaseName);
					}
				} else {
					setApiDown(true);
					setErrorMessage(response.data.errorMessage);
				}
				setShowSpinner(false);
			}
		);
	};

	const checkApprovalStatus = async () => {
		await axiosGetService(
			`api/sdmPhaseSwimlane/getbyphase/${phaseData.phaseId}`
		).then((response) => {
			if (response.data && response.data.length > 0) {
				setApprovalStatus(response.data[0].reviewedStatusMessage);
			}
		});
	};

	const checkPublishData = async () => {
		await axiosGetService(
			`api/sdmactivity/checkpublishvalidations/${phaseData.phaseId}`
		).then((response) => {
			if (response.data.status) {
				setPublishActivityDetails((prevState) => ({
					...prevState,
					publishedActivities: response.data.publishedActivities,
					notPublishedActivities: response.data.notPublishedActivities,
					referedActivities: response.data.referedActivities,
				}));
			} else {
				setApiDown(true);
				setErrorMessage(response.data.errorMessage);
			}
		});
		axiosGetService(
			`api/sdmphase/getphasemergedactivities/${
				type === 'parentPhase' ? parentId : Number(solutionMethodId)
			}/${phaseData.phaseId}/${complexity.id}/${coOwners.includes(
				userAlias
			)}/0/0/0/false/0`
		).then((response) => {
			setValidatePhaseDetails(
				response.data.phaseDescription &&
					response.data.svgFileName &&
					!isActivityCountZero 
					// &&
					// response.data.enableComplexity
			);
			setPhaseData({
				...phaseData,
				svgFileName: response.data.svgFileName,
			});
			setShowSpinner(false);
		});
	};

	const schedulePublish = async () => {
		if (!publishSchedule.time.format || !selectedTimezone.value) {
			toast.error('Please select a date, time and timezone', {
				position: 'top-right',
			});
		} else {
			setShowSpinner(true);
			const postData = {
				parentPhaseId: phaseData.phaseId,
				solutionMethodId:
					type === 'parentPhase' ? parentId : Number(solutionMethodId),
				publishScheduledDateTime: new Date(convertTime()).toUTCString(),
			};
			await axiosPostService(`api/sdmactivity/publishschedule`, postData).then(
				(response) => {
					if (response.data.status) {
						setSuccessMessage('You have successfully scheduled to publish');
						if (type === 'parentPhase') {
							track(1, 1903, phaseData.phaseId, phaseData.phaseName);
						} else {
							track(1, 1904, phaseData.phaseId, phaseData.phaseName);
						}
					} else {
						setApiDown(true);
						setErrorMessage(response.data.errorMessage);
					}
					setShowSpinner(false);
				}
			);
		}
	};

	const getTimeData = () => {
		const selectedDate = publishSchedule.date.toLocaleDateString();
		const currentDate = new Date().toLocaleDateString();
		if (selectedDate === currentDate) {
			return TimeData.filter(
				({ format }) => Number(format.split(':')[0]) > currentTime
			);
		}
		return TimeData;
	};

	useEffect(() => {
		checkApprovalStatus();
		checkPublishData();
	}, []);

	useEffect(() => {
		getOrphanGroupsAndSubgroup();
	}, []);

	useEffect(() => {
		getTimeData();
	}, [publishSchedule]);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="phasePublishModal"
					show={showPublishModal}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					onHide={() => {
						if (type === 'parentPhase' && successMessage) {
							getParentSolutionMethodData(parentId, coOwners);
							setParentPhaseCreated(!parentPhaseCreated);
						} else if (successMessage) {
							getSolutionMethodData(coOwners, 'update');
							setPhaseCreated(!phaseCreated);
						}
						setShowPublishModal(false);
					}}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Publish {phaseData.phaseName}
						</Modal.Title>
						<Icon
							onClick={() => {
								if (type === 'parentPhase' && successMessage) {
									getParentSolutionMethodData(parentId, coOwners);
									setParentPhaseCreated(!parentPhaseCreated);
								} else if (successMessage) {
									getSolutionMethodData(coOwners, 'update');
									setPhaseCreated(!phaseCreated);
								}
								setShowPublishModal(false);
							}}
							iconName="ChromeClose"
							onKeyPress={(e) => {
								if (e.which === 13) {
									if (type === 'parentPhase' && successMessage) {
										getParentSolutionMethodData(parentId, coOwners);
										setParentPhaseCreated(!parentPhaseCreated);
									} else if (successMessage) {
										getSolutionMethodData(coOwners, 'update');
										setPhaseCreated(!phaseCreated);
									}
									setShowPublishModal(false);
								}
							}}
							tabIndex={0}
							role="button"
							aria-label="Close Publish Phase"
						></Icon>
					</Modal.Header>
					<Modal.Body>
						{successMessage || errorMessage ? (
							<>
								{successMessage ? (
									<Alert variant="success">{successMessage}</Alert>
								) : (
									<Alert variant="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: HtmlSanitizer(errorMessage),
											}}
										/>
									</Alert>
								)}
							</>
						) : (
							<>
								{!validatePhaseDetails ? (
									<>
										<h6>Please add the following before publishing:</h6>
										<ul>
											{!phaseData.phaseDescription && (
												<li>
													<Link
														to="/"
														onClick={(e) => {
															e.preventDefault();
															if (type === 'parentPhase') {
																setUpdatePhaseId(parentPhaseId);
															} else {
																setUpdatePhaseId(phaseId);
															}
															setSelectedTab(1);
															setShowPhaseUpdateModal(true);
															setShowPublishModal(false);
														}}
													>
														Phase description
													</Link>
												</li>
											)}
											{!phaseData.svgFileName && (
												<>
													{approvalStatus ? (
														<li>Phase swimlane status : {approvalStatus}</li>
													) : (
														<li>
															<Link
																to="/"
																onClick={(e) => {
																	e.preventDefault();
																	if (type === 'parentPhase') {
																		setUpdatePhaseId(parentPhaseId);
																	} else {
																		setUpdatePhaseId(phaseId);
																	}
																	setSelectedTab(4);
																	setShowPhaseUpdateModal(true);
																	setShowPublishModal(false);
																}}
															>
																Phase swimlane
															</Link>
														</li>
													)}
												</>
											)}
											{isActivityCountZero ? (
												<li>
													<Link
														to="/"
														onClick={(e) => {
															e.preventDefault();
															setShowActivityBuilder(true);
															setShowPublishModal(false);
														}}
													>
														At least one activity
													</Link>
												</li>
											) : (
												<></>
											)}
											{/* {!phaseData.enableComplexity ? (
												<li>
													<Link
														to="/"
														onClick={(e) => {
															e.preventDefault();
															if (type === 'parentPhase') {
																setUpdatePhaseId(parentPhaseId);
															} else {
																setUpdatePhaseId(phaseId);
															}
															setSelectedTab(5);
															setShowPhaseUpdateModal(true);
															setShowPublishModal(false);
														}}
													>
														Enable Complexity
													</Link>
												</li>
											) : (
												<></>
											)} */}
										</ul>
									</>
								) : (
									<>
										<div className="phasePublishModalDiv">
											{publishActivityDetails['publishedActivities'].length >
											0 ? (
												<>
													<p>Following activity(ies) will be published</p>
													<ul>
														{publishActivityDetails['publishedActivities'].map(
															(item) => (
																<li
																	key={item.title + '_' + item.complexityName}
																	className="publishActivity"
																>
																	{item.title}&nbsp;-&nbsp;{item.complexityName}
																</li>
															)
														)}
													</ul>
												</>
											) : (
												<></>
											)}
											{publishActivityDetails['notPublishedActivities'].length >
											0 ? (
												<>
													<p>
														Following Activity(ies) will not be published as
														mandatory field(s) are missing
													</p>
													<ul>
														{publishActivityDetails[
															'notPublishedActivities'
														].map((item) => (
															<li
																key={item.title + '_' + item.complexityName}
																className="notPublishActivity"
															>
																<Link
																	onClick={() => {
																		setShowPublishModal(false);
																	}}
																	to={`${url}/updateActivity/${item.activityId}/draft/${item.id}/complexity/${item.complexityIds}/default/${item.complexityId}`}
																>
																	{item.title}&nbsp;-&nbsp;{item.complexityName}
																</Link>
															</li>
														))}
													</ul>
												</>
											) : (
												<></>
											)}
											{publishActivityDetails['referedActivities'] &&
											publishActivityDetails['referedActivities'].length > 0 ? (
												<>
													<p>
														The following Linked activities will not be
														published as the user does not have the right to
														publish them
													</p>
													<ul>
														{publishActivityDetails['referedActivities'].map(
															(item) => (
																<li
																	key={item.title + '_' + item.complexityName}
																	className="publishActivity"
																>
																	{item.title}
																	{/* &nbsp;-&nbsp;{item.complexityName} */}
																</li>
															)
														)}
													</ul>
												</>
											) : (
												<></>
											)}
										</div>
										{orphanGroups.length > 0 ? (
											<>
												<p>
													Following group(s) will not be published as activities
													are missing
												</p>
												<ul>
													{orphanGroups.map((item) => (
														<li
															key={item.groupName}
															className="notPublishActivity"
														>
															<Link
																onClick={(e) => {
																	e.preventDefault();
																	setShowSubgroupForm(true);
																	setActivityBuilderTabId(2);
																	setShowActivityBuilder(true);
																	setSelectedGroupDetails(item);
																	setShowPublishModal(false);
																}}
																to="/"
															>
																{item.groupName}
															</Link>
														</li>
													))}
												</ul>
											</>
										) : (
											<></>
										)}
										{orphanSubgroups.length > 0 ? (
											<>
												<p>
													Following subgroup(s) will not be published as
													activities are missing
												</p>
												<ul>
													{orphanSubgroups.map((item) => (
														<li
															key={item.subgroup.subGroupName}
															className="notPublishActivity"
														>
															<Link
																onClick={(e) => {
																	e.preventDefault();
																	setSelectedSubgroupDetails(item.subgroup);
																	setShowSubgroupEditForm(true);
																	setActivityBuilderTabId(2);
																	setShowActivityBuilder(true);
																	setSelectedGroupDetails(item.group);
																	setShowPublishModal(false);
																}}
																to="/"
															>
																{item.subgroup.subGroupName}
															</Link>
														</li>
													))}
												</ul>
											</>
										) : (
											<></>
										)}

										{publishActivityDetails['publishedActivities'].length ===
											0 &&
										publishActivityDetails['notPublishedActivities'].length ===
											0 ? (
											<p>Are you sure you want to publish ?</p>
										) : (
											<></>
										)}
										<div className="laterPublishingActivitiesSection">
											<h6 aria-label='When would you like to publish?' tabIndex={0}>When would you like to publish?</h6>
											<div className="publishNowOrLaterDiv">
												<ul role="radiogroup">
													{publishType.map((item) => (
														<li
															key={item.name}
															onClick={() => {
																setSelectedPublishType(item);
															}}
															role="radio"
															tabIndex={0}
														>
															<label class="checkBoxContainer">
																<span class="settingsLabel">{item.name}</span>
																<input
																	name="methodSelection"
																	type="radio"
																	checked={selectedPublishType.id === item.id}
																	title={item.name}
																	aria-label={item.name}
																	role="radio"
																	aria-checked={
																		selectedPublishType.id === item.id
																			? true
																			: false
																	}
																	tabIndex="0"
																/>
																<span class="checkmark"></span>
															</label>
														</li>
													))}
												</ul>
												<div>
													<div className="phasePublishButtons">
														<button
															disabled={
																!validatePhaseDetails ||
																selectedPublishType.type !== 'Publish' ||
																(publishActivityDetails['publishedActivities']
																	.length === 0 &&
																	publishActivityDetails[
																		'notPublishedActivities'
																	].length !== 0)
															}
															onClick={() => submitPhaseDetails()}
														>
															Publish
														</button>
													</div>
													<div className="dateAndTimeJoneDiv">
														<span>Date</span>
														<DatePicker
															selected={publishSchedule.date}
															onChange={(date) => {
																setPublishSchedule({
																	...publishSchedule,
																	date: date,
																});
															}}
															minDate={new Date()}
															placeholderText="Select a date "
															maxDate={addMonths(new Date(), 1)}
														/>
													</div>
													<div className="dateAndTimeJoneDiv">
														<span>Time</span>
														<Timepicker
															data={getTimeData()}
															className={'phasePublishTimePicker'}
															setValue={(time) => {
																setPublishSchedule({
																	...publishSchedule,
																	time: time,
																});
															}}
															aria-label="Select a time"
															value={publishSchedule.time.value}
															tabIndex="0"
														/>
													</div>
													<div className="dateAndTimeJoneDiv timeZoneDiv">
														<span>Time Zone</span>
														<TimezoneSelect
															className="timeJoneDivDropdown"
															value={selectedTimezone}
															onChange={setSelectedTimezone}
															aria-label="Time zone"
															tabIndex={0}
														/>
													</div>
													<div className="phasePublishButtons schedule">
														<button
															disabled={
																!validatePhaseDetails ||
																selectedPublishType.type !== 'Schedule' ||
																(publishActivityDetails['publishedActivities']
																	.length === 0 &&
																	publishActivityDetails[
																		'notPublishedActivities'
																	].length !== 0)
															}
															onClick={() => {
																schedulePublish();
															}}
														>
															Schedule
														</button>
													</div>
												</div>
											</div>
										</div>
										<div className="phasePublishButton">
											<button onClick={() => setShowPublishModal(false)} role="button" tabIndex={0} aria-label='Cancel'>
												Cancel
											</button>
										</div>
									</>
								)}
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default PhasePublishModal;
