import React from 'react';
import { useAppInsights } from '../../context/TelemetryContext';

const ComplexityFilter = ({
	className,
	data,
	setValue,
	value,
	enableSelection,
	setSelectedComplexity,
	setSelectedComplexityId,
}) => {
	const { track } = useAppInsights();
	return (
		<ul>
			{data.map((complexity) => (
				<li key={complexity.id}>
					<label className="checkBoxContainer filterChkboxContainer">
						<input
							aria-label="Complexity Category"
							name="complexity"
							type="radio"
							checked={complexity.id === value.id}
							onChange={() => {
								setValue(complexity);
								setSelectedComplexity(complexity.name);
								setSelectedComplexityId(complexity.id);
								track(
									1,
									2109,
									complexity.id,
									complexity.name
								);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setValue(complexity);
									setSelectedComplexity(complexity.name);
									setSelectedComplexityId(complexity.id);
									track(
										1,
										2109,
										complexity.id,
										complexity.name
									);
								}
							}}
						/>
						<span className="checkmark checkmarkRadioButton"></span>
						<span className="filterLabel">{complexity.name}</span>
					</label>
				</li>
			))}
		</ul>
	);
};

export default ComplexityFilter;
