import React, { useState } from "react";
import "./PolygonTopic.css";

const Square = (props) => {
    return (
        <div className="container">
            <ul className="squareContent">
                {props.showLinking ?
                    <>
                        <a href={window.location.origin + `/topics/${props.topicsData[1]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon squareTopicli1" : "squareTopicli1"}>
                                <span className="squareTopicLiTitle" >
                                    {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[2]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon squareTopicli2" : "squareTopicli2"}>
                                <span className="squareTopicLiTitle" >
                                    {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[0]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon squareTopicli3" : "squareTopicli3"}>
                                <span className="squareTopicLiTitle" >
                                    {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[3]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon squareTopicli4" : "squareTopicli4"}>
                                <span className="squareTopicLiTitle" >
                                    {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                        <a href={window.location.origin + `/topics/${props.topicsData[4]?.topicId}`}>
                            <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon squareTopicli5" : "squareTopicli5"}>
                                <span className="squareTopicLiTitle" >
                                    {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                                </span>
                            </li>
                        </a>
                    </>
                    :
                    <>
                        <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon squareTopicli1" : "squareTopicli1"}
                            onClick={() => props.saveNextTopic(1)}>
                            <span className="squareTopicLiTitle" >
                                {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon squareTopicli2" : "squareTopicli2"}
                            onClick={() => props.saveNextTopic(2)}>
                            <span className="squareTopicLiTitle" >
                                {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                        <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon squareTopicli3" : "squareTopicli3"}
                            onClick={() => props.saveNextTopic(0)}>
                            <span className="squareTopicLiTitle" >
                                {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>

                        <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon squareTopicli4" : "squareTopicli4"}
                            onClick={() => props.saveNextTopic(3)}>
                            <span className="squareTopicLiTitle" >
                                {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>

                        <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon squareTopicli5" : "squareTopicli5"}
                            onClick={() => props.saveNextTopic(4)}>
                            <span className="squareTopicLiTitle" >
                                {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </>
                }
            </ul>
        </div>
    );
}

export default Square;