import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { toast } from 'react-toastify';
import { handleTernaryCondition } from '../../../helpers/Validation';
import { urlValidator } from '../../../helpers/Validation';


const Activities = ({
	data,
	quickLinkTypeId,
	setGroupCreated,
	setGroupUpdated,
	setLeftMenuGroupData,
	phaseId,
	isInternalQuickLink,
	complexityId,
	solutionMethodId,
	activityIdentifier,
	setLinkCreated,
	setLinkUpdated,
	setLinkDeleted,
	setGroupAndLinksChanged
}) => {
	

	const { url } = useRouteMatch();
	const { axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
	const { track } = useAppInsights();
	const [showEditGroup, setShowEditGroup] = useState(false);
	const [showDeleteGroup, setShowDeleteGroup] = useState(false);
	const [showDeleteWarning, setShowDeleteWarning] = useState(false);
	const [groupName, setGroupName] = useState(data.quickLinkGroupName);
	const [showEditLink, setShowEditLink] = useState(null);
	const [ showDeleteLink, setShowDeleteLink] = useState(null);
	const [showDuplicateGroup, setShowDuplicateGroup] = useState(false);
    const [showDuplicateLink, setShowDuplicateLink] = useState(false);
    const [showDuplicateLinkUrl, setShowDuplicateLinkUrl] = useState(false);
	
	const [validateUrl, setValidateUrl] = useState(false);
    const [validateTitle, setValidateTitle] = useState(false);
	const [linkTextMessage, setlinkTextMessage] = useState();
    const [linkUrlMessage, setlinkUrlMessage] = useState();
	const [quickLinkId, setQuickLinkId] = useState(0);
	const [linkValidation, setLinkValidation] = useState();

	const {
		phase,
		parentPhase,
		complexity,
		setExpandedActivityGroups,
		expandedActivityGroups,
		complexityValidActivities,
		phaseHashtags,
		role,
		phaseDynamicFilterTag,
		endUserView,
	} = useLeftMenuCollapse();

	const handleGroupExpand = (type) => {
		const id = `${data.quickLinkGroupName}_${data.quickLinkGroupId}`;
		if (!expandedActivityGroups.includes(id)) {
			setExpandedActivityGroups((prevState) => [...prevState, id]);
		} else if (type !== 'filterUpdate') {
			const newt = expandedActivityGroups.filter((item) => item !== id);
			setExpandedActivityGroups(newt);
		}
	};

	const validateLinkText = async() => {
        if( showEditLink.linkName === '' || showEditLink.linkName === undefined){
            setShowDuplicateLink(true);
            setlinkTextMessage("Please enter the link text");
            setValidateTitle(false);
            return;
        }else{
            var postData = {
                SearchText: showEditLink.linkName,
                QuickLinkTypeId: quickLinkTypeId,
                QuickLinkId: showEditLink.linkId,
                GroupId: showEditLink.groupId,
                PhaseId: showEditLink.linkData.phaseId,
                ComplexityId: showEditLink.linkData.complexityId,
                IsGroup: false,
                IsUrl: false,
                IsUpdate: true
            };

            await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
                (response) => {
                    if(response.data.status === 1){
                        setShowDuplicateLink(true);
                        setlinkTextMessage("Duplicate Link Text not allowed");
                        setValidateTitle(false);
                        return;
                    } else {
                        setShowDuplicateLink(false);
                        setValidateTitle(true);
						setLinkValidation(true);
                        return;
                    }
                }
            );
        }
    }

	const validateLinkUrl = async(event) => {
        if(showEditLink.linkUrl === '' || showEditLink.linkUrl === undefined){
            setShowDuplicateLinkUrl(true);
            setlinkUrlMessage("Please enter the link url");
            setValidateUrl(false);
            return;
        }else if(!urlValidator.test(showEditLink.linkUrl)){
            setShowDuplicateLinkUrl(true);
            setlinkUrlMessage("Please enter the valid link url");
            setValidateUrl(false);
            return;
        }else{
            var postData = {
                SearchText: showEditLink.linkUrl,
                QuickLinkTypeId: quickLinkTypeId,
                QuickLinkId: showEditLink.linkId,
                GroupId: showEditLink.groupId,
                PhaseId: showEditLink.linkData.phaseId,
                ComplexityId: showEditLink.linkData.complexityId,
                IsGroup: false,
                IsUrl: true,
                IsUpdate: true
            };

            await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
                (response) => {
                    if(response.data.status === 1){
                        setShowDuplicateLinkUrl(true);
                        setlinkUrlMessage("Duplicate Link Url not allowed");
                        setValidateUrl(false);
                        return;
                    } else {
                        setShowDuplicateLinkUrl(false);
                        setValidateUrl(true);
                        setLinkValidation(false);
                        return;
                    }
                }
            );
        }
    }

	const validateGroup = async () => {
		var postData = {
			SearchText: groupName,
			QuickLinkTypeId: quickLinkTypeId,
			QuickLinkId: quickLinkId,
			GroupId: data.quickLinkGroupId,
			PhaseId: data.phaseId,
			ComplexityId: data.complexityId,
			IsGroup: true,
			IsUrl: false,
			IsUpdate: true
		};

		await axiosPostService(`api/sdmquicklink/quicklinkgroupvalidation`,postData).then(
            (response) => {
                if(response.data.status === 1){
                    setShowDuplicateGroup(true);
                } else {
					setShowDuplicateGroup(false);
					updateGroupDetails();
				}
            }
        );
	}

	const updateGroupDetails = async() => {
		const postData = {
			Id: data.quickLinkGroupId,
			QuickLinkGroupName: groupName,
			Sequence: data.sequence,
			QuickLinkTypeId: quickLinkTypeId,
			PhaseId: data.phaseId,
		};
		await axiosPostService(`api/sdmquicklink/updatequicklinkgroup`, postData).then(
			(response) => {
				if (response.data.status) {
					setGroupUpdated(true);
					setGroupAndLinksChanged(prev=> prev + 1)
					toast.info(`You have successfully updated the group`, {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
				setShowEditGroup(false);
			}
		);
	}

	const removeGroupDetails = async() => {
		await axiosDeleteService(`api/sdmquicklink/deletequicklinkgroup/${data.quickLinkGroupId}/${quickLinkTypeId}/${data.phaseId}/${data.complexityId}`).then(
            (response) => {
                if (response.data.status) {
					setLeftMenuGroupData(response.data.sdmQuickLinkGroups);
                    toast.info(`You have successfully deleted the group`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
					setGroupAndLinksChanged(prev=> prev + 1)
                } else {
                    toast.error(response.data.errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
				setShowDeleteGroup(false);
            }
        );
	}

	const handleEditClick = (groupId, linkId) => {
		const link = data.quickLinkActivities.find(link => link.quickLinkActivityId === linkId);
		setShowEditLink({ groupId, linkId, linkName: link.linkName, linkUrl: link.linkUrl, linkData: link });
	};

	const handleDeleteClick = (groupId, linkId) => {
		const link = data.quickLinkActivities.find(link => link.quickLinkActivityId === linkId);
		setShowDeleteLink({ groupId, linkId, linkData: link });
	}
	
	const handleLinkNameChange = (e) => {
		setShowEditLink({ ...showEditLink, linkName: e.target.value });
	};

	const handleLinkUrlChange = (e) => {
		setShowEditLink({ ...showEditLink, linkUrl: e.target.value });
	};

	useEffect(() => {
        if(validateUrl && validateTitle) {
            const postData = {
				Id: showEditLink.linkId,
				LinkName: showEditLink.linkName,
				LinkUrl: showEditLink.linkUrl,
				ActivityIdentifier: showEditLink.linkData.activityIdentifier ==="" ? 0 : showEditLink.linkData.activityIdentifier, 
				SequenceNumber: showEditLink.linkData.sequenceNumber,
				SolutionMethodId: showEditLink.linkData.solutionMethodId,
				PhaseId: showEditLink.linkData.phaseId,
				GroupId: showEditLink.groupId,
				ComplexityId: showEditLink.linkData.complexityId,
				IsInternalQuickLink: showEditLink.linkData.isInternalQuickLink,
				QuickLinkTypeId: quickLinkTypeId,
			};
			axiosPostService(`api/sdmquicklink/updatequicklinkactivity`, postData).then(
				(response) => {
					if (response.data.status) {
						setLinkUpdated(true);
						setValidateUrl(false);
						setValidateTitle(false);
						toast.info(`You have successfully updated the quick link`, {
							position: toast.POSITION.TOP_RIGHT,
						});
						setGroupAndLinksChanged(prev=> prev + 1)
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
					setShowEditLink(null);
				}
			);
        }
    },[linkValidation])
	
	const handleSaveEdit = async () => {
		await validateLinkUrl();
		await validateLinkText();
	};
	
	const handleCancelEdit = () => {
		setShowEditLink(null);
	};
	
	const handleDeleteLink = async () => {
		await axiosDeleteService(`api/sdmquicklink/deletequicklinkactivity/${showDeleteLink.linkId}/${showDeleteLink.groupId}/${showDeleteLink.linkData.phaseId}/${quickLinkTypeId}`).then(
            (response) => {
                if (response.data.status) {
					setLinkDeleted(true);
                    toast.info(`You have successfully deleted the link`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
					setGroupAndLinksChanged(prev=> prev + 1)
                } else {
                    toast.error(response.data.errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
				setShowDeleteLink(null);
            }
        );
	}

	const handleCancelDelete = () => {
		setShowDeleteLink(null);
	};

	const linkOrder = (array) => {
		return array.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}

	return (
		<>	
			{showDeleteWarning ? (
				<div className='deleteGroupWarning'>
					<Icon
						iconName='WarningSolid' />
					<span>Please remove the Quick Links first to remove the Group.</span>
					<Icon
						iconName="Cancel"
						tabIndex={0}
						aria-label={`Close warning `}
						onClick={() => { setShowDeleteWarning(false); }}
					/>
				</div>
			): (
				<> </>
			)}
			{showDuplicateGroup? (
				<div className='duplicateGroup'>
					<span>Duplicate Group Name not allowed</span>
				</div>
			) : (
				<></>
			)}
			<div className="leftLinkListMainContainer leftLinkGroupParent">
				<div
					className="linkContainer leftLinkGroup"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							handleGroupExpand('userUpdate');
						}
					}}
					onClick={() => handleGroupExpand('userUpdate')}
					aria-expanded={handleTernaryCondition(
						expandedActivityGroups.includes(
							`${data.quickLinkGroupName}_${data.quickLinkGroupId}`
						)
							? 'true'
							: 'false'
					)}
					role="tree"
				>
					<span className="leftLinkTypeColor" />
					<div className="leftLinkTitle" title={data.quickLinkGroupName}>
						<Icon
							iconName={handleTernaryCondition(
								expandedActivityGroups.includes(
									`${data.quickLinkGroupName}_${data.quickLinkGroupId}`
								),
								'CaretDownSolid8',
								'CaretRightSolid8'
							)}
						/>
						{(showEditGroup ? (
							<div class="leftEditGroupName">
								<input
									id="group-name"
									title="Group Name"
									placeholder="Group Name"
									value={groupName}
									onChange={(e) => setGroupName(e.target.value)}
									autoFocus={true}
								/>
								<Icon 
									className='editGroupActions'
									iconName='CheckMark'
									tabIndex={0}
									aria-label={`Update Group Name`}
									title='Add Group'
									onClick={() => {
										validateGroup();
									}}
									/>
								<Icon 
									className='editGroupActions'
									iconName='Cancel'
									tabIndex={0}
									aria-label={`Cancel`}
									title='Cancel'
									onClick={() => {
										setGroupName(data.quickLinkGroupName);
										setShowEditGroup(false);
									}}
								/>
							</div>
						) : (
							<>
								<span
									aria-label={data.quickLinkGroupName}
									className="titleOfLink"
									role="tree"
									title={data.quickLinkGroupName}
									aria-selected={handleTernaryCondition(
										expandedActivityGroups.includes(
											`${data.quickLinkGroupName}_${data.quickLinkGroupId}`
										),
										'true',
										'false'
									)}
								>
									{data.quickLinkGroupName}
								</span>

								{(showDeleteGroup ? (
									<div className="deleteLeftGroupPopUp">
										<span>
											Are you sure you want to delete it?
										</span>
										<div>
											<button
												onClick={() => { removeGroupDetails(); }}
												onKeyPress={() => { removeGroupDetails(); }}
											>
												Yes
											</button>
											<button
												onClick={() => { setShowDeleteGroup(false); }}
												onKeyPress={() => { setShowDeleteGroup(false); }}
											>
												No
											</button>
										</div>
									</div>
								) : (
									<></>
								))}

								<div className='leftGroupIcons'>
									<Icon
										className='editGroup'
										iconName="EditSolid12"
										onClick={() => {
											setShowEditGroup(true);
										}}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowEditGroup(true);
											}
										}}
										role="button"
										aria-label={`Edit Group Name`}
										title='Edit Group'
									/>
									<span
										className="leftGroupDeleteIcon"
										onClick={() => {
											if(data.quickLinkActivities.length !== 0 && data.quickLinkActivities[0] !== null){
												setShowDeleteWarning(true);
											}else{
												setShowDeleteGroup(true);
											}
											
										}}
										tabIndex={0}
										onKeyPress={(e) => {
											if(data.quickLinkActivities.length !== 0 && (data.quickLinkActivities.length !==1 && data.quickLinkActivities[0] !== null)){
												setShowDeleteWarning(true);
											}else{
												setShowDeleteGroup(true);
											}
										}}
										role="button"
										aria-label={`Delete Group`}
										title='Delete Group'
									></span>
								</div>
							</>
						))}
					</div>
				</div>
				{expandedActivityGroups.includes(
					`${data.quickLinkGroupName}_${data.quickLinkGroupId}`
				) &&
					(data.quickLinkActivities.length !== 0  && data.quickLinkActivities[0] !== null ? (
						linkOrder(data.quickLinkActivities).map((item) => (

							<div key={item.quickLinkActivityId}>
								{showEditLink?.linkId === item.quickLinkActivityId ? (
                                   <div className="leftEditQuickLinkForm">
                                    	<div className="leftInputLinkEdit">
                                    		<label htmlFor="link-name-input" className="input-label">Link Text</label>
											<input
												type="text"
												className="leftEditQuickLinkInput"
												value={showEditLink.linkName}
												onChange={ handleLinkNameChange}
												placeholder="Link text"
												title='Link Text'
											/>
											{showDuplicateLink? (
												<span>{linkTextMessage}</span> 
											) : (
												<></>
											)}
                                   		</div>
                                   		<div className="leftInputLinkUrlEdit">
                                  			<label htmlFor="link-url-input" className="input-label">Link URL</label>
											<input
												type="url"
												className="leftEditQuickLinkInput"
												value={showEditLink.linkUrl}
												onChange={handleLinkUrlChange}
												placeholder="Link URL"
												title='Link URL'
											/>
											{showDuplicateLinkUrl? (
												<span>{linkUrlMessage}</span> 
											) : (
												<></>
											)}
                                   		</div>
										<div className="leftBtnGroup">
											<button className="leftBtnFooter leftCancelButton" onClick={() => {
													handleCancelEdit();
												}}
												title='Cancel'
											>Cancel</button>
											<button className="leftBtnFooter leftSaveButton" onClick={()=>{
													handleSaveEdit();
												}}
												title='Add Link'
											>Add Link</button>
										</div>
                                 	</div>
                                ) :(
									<>
										<div
											className='leftOrangeDelivery'
										>
											<div className="leftLinkChildContainer">
												<div>
													<div
														className="leftLinkChildTitle"
														title={item.linkName}
													>
														<span className="titleOfLink">
															{item.linkName}
														</span>
														{showDeleteLink?.linkId === item.quickLinkActivityId ? (
															<div className="deleteLeftGroupPopUp">
																<span>
																	Are you sure you want to delete it?
																</span>
																<div>
																	<button
																		onClick={() => { handleDeleteLink(); }}
																		onKeyPress={() => { handleDeleteLink(); }}
																	>
																		Yes
																	</button>
																	<button
																		onClick={() => { handleDeleteClick(false); }}
																		onKeyPress={() => { handleDeleteClick(false); }}
																	>
																		No
																	</button>
																</div>
															</div>
														) : (
															<></>
														)}
														<div className='leftLinkIcons'>
															<Icon
																iconName="EditSolid12"
																onClick={() => {
																	handleEditClick(data.quickLinkGroupId, item.quickLinkActivityId);
																}}
																tabIndex={0}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		handleEditClick(data.quickLinkGroupId, item.quickLinkActivityId);
																	}
																}}
																role="button"
																aria-label={`Edit Link Name`}
																title='Edit Link'
															/>
															<span
																className="leftLinkDeleteIcon"
																onClick={() => {
																	handleDeleteClick(data.quickLinkGroupId, item.quickLinkActivityId);
																}}
																tabIndex={0}
																onKeyPress={(e) => {
																	handleDeleteClick(data.quickLinkGroupId, item.quickLinkActivityId);
																}}
																role="button"
																aria-label={`Delete Link`}
																title='Delete Link'
															></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						))
					) : (
						<div className="leftLinkChildContainer">
							<div className="leftLinkChildTitle noActivities">
								<span className="titleOfLink">No Activities Found</span>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default Activities;