import React, { useState, useEffect } from 'react';

import './MyContributions.css';

// components
import Breadcrumbs from '../../components/Breadcrumbs';
import Pagination from '../../components/Pagination';
import ActivityContribution from './Activity/ActivityContribution';
import TopicsMyContribution from './Topics/TopicsMyContributions';
import TopicsMyReviews from './Topics/TopicsMyReviews';
import TopicsMyApprovals from './Topics/TopicsMyApprovals';
import ActivityReview from './Activity/ActivityReview';
import ActivityApproval from './Activity/ActivityApproval';
import NoContributions from './NoContributions';
import TemplateContribution from './Template/TemplateContribution';
import TemplateReview from './Template/TemplateReview';
import TemplateApproval from './Template/TemplateApproval';
import Spinner from '../../helpers/Spinner';

// context
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';

// helpers
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import {TabTitle} from '../../utils/GeneralFunctions'

const MyContributions = ({
	selectedArtifact,
	setSelectedArtifact,
	selectedCrowdSourcingType,
	setSelectedCrowdSourcingType,
	enableGamification,
	setEnableGamification,
}) => {
	// context
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
		useLeftMenuCollapse();

	// state
	const [dataCount, setDataCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	// const [selectedArtifact, setSelectedArtifact] = useState('Activities');
	// const [selectedCrowdSourcingType, setSelectedCrowdSourcingType] = useState('My Contributions');

	//functions
	const loadBreadcrumbs = () => {
		const pageBreadcrumb = {
			title: 'My Contributions',
			type: 'myContributions',
			parent: 'home',
			path: window.location.pathname,
		};

		const newBreadcrumbs = AddBreadCrumb(breadcrumbs, pageBreadcrumb);
		setBreadcrumbs(newBreadcrumbs);
		TabTitle('My Contributions');
	};

	const handleStatusClassName = (status) => {
		const rejected_status = ['All Rejected', 'Rejected'];
		const pending_status = [
			'Pending Approval',
			'Pending Review',
			'Sent for Approval',
		];
		const accepted_status = [
			'Published',
			'All Approved',
			'Partially Approved',
			'Published',
			'Approved',
		];

		if (rejected_status.includes(status)) return 'rejectedStatus';
		else if (pending_status.includes(status)) return 'pendingStatus';
		else if (accepted_status.includes(status)) return 'acceptedStatus';
		return '';
	};

	//constants
	const pageLimit = 20;
	const crowdSourcingTypes = ['My Contributions', 'My Reviews', 'My Approvals'];
	const noContributionsMessage = {
		'My Contributions': 'You have not contributed anything yet',
		'My Reviews': 'You have not reviewed anything yet',
		'My Approvals': 'You have not approved anything yet',
	};
	const artifacts = {
		Activities: {
			'My Contributions': (
				<ActivityContribution
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Reviews': (
				<ActivityReview
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Approvals': (
				<ActivityApproval
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
		},
		'Topics & Job Aids': {
			'My Contributions': (
				<TopicsMyContribution
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Reviews': (
				<TopicsMyReviews
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Approvals': (
				<TopicsMyApprovals
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
				/>
			),
		},
		Templates: {
			'My Contributions': (
				<TemplateContribution
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Reviews': (
				<TemplateReview
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
					enableGamification={enableGamification}
				/>
			),
			'My Approvals': (
				<TemplateApproval
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageLimit={pageLimit}
					setDataCount={setDataCount}
					handleStatusClassName={handleStatusClassName}
				/>
			),
		},
	};
   
	useEffect(() => {
		loadBreadcrumbs();
		setSkipNavigationId('myContributionsLeftMenuId');
	}, []);

	return (
		<>
			<div className="myContributionsContainer">
				<Breadcrumbs />
				<div className="myContibutionsMainContainer">
					<div
						className="myContributionsLeftMenu"
						id="myContributionsLeftMenuId"
					>
						<ul role="tablist" aria-label="artifact menu">
							{Object.keys(artifacts).map((artifact) => (
								<li
									tabIndex={0}
									key={`MyContributionsLeftMenu_${artifact}`}
									onClick={() => {
										if (selectedArtifact !== artifact) {
											setSelectedArtifact(artifact);
											setDataCount(0);
											track(1, 164, 'Artifact Type', artifact);
										}
									}}
									onKeyPress={(e) => {
										if (e.which === 13 && selectedArtifact !== artifact) {
											setSelectedArtifact(artifact);
											setDataCount(0);
											track(1, 164, 'Artifact Type', artifact);
										}
									}}
									className={
										selectedArtifact === artifact
											? 'activeMyContributionArtifactTab'
											: ''
									}
									role="tab"
									aria-label={artifact}
									aria-selected={
										selectedArtifact === artifact ? 'true' : 'false'
									}
								>
									<h6>{artifact}</h6>
								</li>
							))}
						</ul>
					</div>
					<div className="myContributionsRightContent">
						<div className="myContributionsContentMenuContainer">
							<ul className="myContributionsContentMenuTabs" role="tablist">
								{crowdSourcingTypes.map((tab) => (
									<li
										tabIndex={0}
										role="tab"
										aria-selected={
											selectedCrowdSourcingType === tab ? 'true' : 'false'
										}
										title={tab}
										key={`CrowdSourcingType_${tab}`}
										onClick={() => {
											if (selectedCrowdSourcingType !== tab) {
												setSelectedCrowdSourcingType(tab);
												setDataCount(0);
												track(1, 164, 'CrowdSourcing Type', tab);
											}
										}}
										onKeyPress={(e) => {
											if (e.which === 13 && selectedCrowdSourcingType !== tab) {
												setSelectedCrowdSourcingType(tab);
												setDataCount(0);
												track(1, 164, 'CrowdSourcing Type', tab);
											}
										}}
										className={
											selectedCrowdSourcingType === tab
												? 'activeMyContributionCrowdSourcingTab'
												: ''
										}
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
						{artifacts[selectedArtifact][selectedCrowdSourcingType]}
						{dataCount === null ? (
							<NoContributions
								message={noContributionsMessage[selectedCrowdSourcingType]}
							/>
						) : dataCount === 0 ? (
							<div className="spinner">
								<Spinner animation="border" />
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
			{dataCount > pageLimit ? (
				<div>
					<Pagination
						pageCount={Math.ceil(dataCount / pageLimit)}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default MyContributions;
