import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';
import MultiSelectDropdown from './MultiSelectDropdown';
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import Spinner from '../../../helpers/Spinner';
import { toast } from 'react-toastify';

const ActivityRefer = ({
	showGroupReferForm,
	setShowGroupReferForm,
	activityGroups,
	setActivityGroups,
	projectTypeId,
	solutionMethodId,
	phaseId,
	setDisableSaveAndContinue,
	phaseType,
	parentId,
}) => {
	const { parentPhaseId } = useParams();
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];

	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const { complexity } = useLeftMenuCollapse();
	const [showSpinner, setShowSpinner] = useState(true);
	const [groupSearchValue, setGroupSearchValue] = useState('');
	const [foundGroups, setFoundGroups] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [apiCalled, setApiCalled] = useState(false);
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([
		{
			id: Number(projectTypeId),
			name: Number(projectTypeId) == 1 ? 'Consulting' : 'Support',
		},
	]);

	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);

	// phase state
	const [phasesSearchValue, setPhasesSearchValue] = useState('');
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [phases, setPhases] = useState([]);
	const [collapseGroups, setCollapseGroups] = useState([]);
	const [collapseSubgroup, setCollapseSubgroup] = useState([]);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const checkSelectedGroups = (value, item) => {
		const filteredData = value.filter(({ activityId }) => activityId === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const removeSelectedItem = (value, setValue, item) => {
		const filteredData = value.filter(({ activityId }) => activityId !== item);
		setValue(filteredData);
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data
				.map((item) => {
					return {
						id: item.solutionMethodId,
						name: item.solutionMethodName,
					};
				})
				.filter(({ id }) => Number(id) !== Number(solutionMethodId));
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};

	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=${
				phasesSearchValue.trim().length > 0
					? encodeURIComponent(phasesSearchValue)
					: 0
			}&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			const filterId = phaseType === 'parentPhase' ? parentId : phaseId;
			const data = response.data.phases
				.map((item) => {
					return {
						name: item.phaseName,
						id: item.phaseId,
					};
				})
				.filter(({ id }) => Number(id) !== Number(filterId));
			const filterTag = selectedPhases.filter(({ id }) =>
				findSelectedItem(data, id)
			);
			if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
				setSelectedPhases(filterTag);
			}
			setPhases(data);
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	const getGroupActivities = (groups) => {
		const activities = [];
		for (const group in groups) {
			if (group.includes('activity')) {
				activities.push({ ...groups[group], isSubgroup: false });
			} else if (group.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in groups[group]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...groups[group][subgroupActivity],
						});
					}
				}
				activities.push({
					...groups[group],
					isSubgroup: true,
					activities: subgroupActivities,
				});
			}
		}
		return activities;
	};

	const getGroups = async () => {
		const phaseIds = selectedPhases.map(({ id }) => {
			return id;
		});
		const methods = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmactivity/getactivitiestorefergroups?searchValue=${
				groupSearchValue ? groupSearchValue : 0
			}&methodIds=${methods.length ? methods.toString() : 0}&phaseIds=${
				phaseIds.length ? phaseIds.toString() : 0
			}&solutionMethodId=${solutionMethodId}`
		).then((response) => {
			const groups = [];
			const data = response.data;
			for (const group in data) {
				if (data[group].groupId) {
					const groupDetails = {
						groupName: data[group].groupName,
						groupId: data[group].groupId,
						activities: getGroupActivities(data[group]),
						projectTypeName: data[group].projectTypeName,
						referedSolutionMethodName: data[group].referedSolutionMethodName,
						phaseName: data[group].phaseName,
					};
					groups.push(groupDetails);
				}
			}
			setFoundGroups(groups);
			closeSpinner();
		});
	};

	const cloneActivity = (activities) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
			}
		}
		return activityClone;
	};

	const cloneGroup = (data) => {
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group]),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityGroupClone.push(groupClone);
			}
		}
		setActivityGroups(activityGroupClone);
		setShowGroupReferForm(false);
		setSelectedGroups([]);
	};

	const referGroups = async () => {
		if (selectedGroups.length > 0) {
			setApiCalled(true);
			setDisableSaveAndContinue(true);
			await axiosPostService(
				`api/sdmactivity/addreferedactivitygroup`,
				selectedGroups
			).then((response) => {
				if (response.data.status) {
					for (const group of selectedGroups) {
						track(1, 1701, group.groupId, group.groupName);
					}
					cloneGroup(response.data.activityGroupsData);
					toast.info('You have successfully Linked the group(s)', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setDisableSaveAndContinue(false);
				setApiCalled(false);
			});
		}
	};

	const handleGroupsCollapse = (sourceId) => {
		if (collapseGroups.includes(sourceId)) {
			const updateCollapseGroupIds = collapseGroups.filter(
				(groupId) => sourceId !== groupId
			);

			setCollapseGroups(updateCollapseGroupIds);
		} else {
			setCollapseGroups([...collapseGroups, sourceId]);
		}
	};

	const addGroupToSelection = (item) => {
		const isGroupAvailable = selectedGroups.filter(
			({ groupId }) => groupId === item.groupId
		);
		if (isGroupAvailable.length > 0) {
			const updatedSelectedGroups = selectedGroups.filter(
				({ groupId }) => groupId !== item.groupId
			);
			setSelectedGroups(updatedSelectedGroups);
		} else {
			const updatedSelectedGroups = [];
			for (const activity of item.activities) {
				if (activity.isSubgroup) {
					for (const subgroupActivity of activity.activities) {
						updatedSelectedGroups.push({
							groupId: item.groupId,
							groupName: item.groupName,
							phaseId: phaseType === 'parentPhase' ? parentPhaseId : phaseId,
							activityId: subgroupActivity.activityId,
							solutionMethodId:
								phaseType === 'parentPhase' ? parentId : solutionMethodId,
							complexityId: complexity.id,
							subGroupId: activity.subGroupId,
							isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
						});
					}
				} else {
					updatedSelectedGroups.push({
						groupId: item.groupId,
						groupName: item.groupName,
						phaseId: phaseType === 'parentPhase' ? parentPhaseId : phaseId,
						activityId: activity.activityId,
						solutionMethodId:
							phaseType === 'parentPhase' ? parentId : solutionMethodId,
						complexityId: complexity.id,
						subGroupId: 0,
						isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
					});
				}
			}
			setSelectedGroups([...updatedSelectedGroups, ...selectedGroups]);
		}
	};

	const getGroupSelection = (item) => {
		const isGroupSelected = selectedGroups.filter(
			({ groupId }) => groupId === item.groupId
		);
		return isGroupSelected.length > 0;
	};

	const checkActivitySelection = (item) => {
		const isActivitySelected = selectedGroups.filter(
			({ activityId }) => activityId === item.activityId
		);
		return isActivitySelected.length > 0;
	};

	const checkSubgroupSelection = (item) => {
		const isSubgroupSelected = selectedGroups.filter(
			({ subGroupId }) => subGroupId === item.subGroupId
		);
		return isSubgroupSelected.length > 0;
	};

	const addActivityToSelection = (group, activity) => {
		const isActivityAvailable = selectedGroups.filter(
			({ activityId }) => activityId === activity.activityId
		);
		if (isActivityAvailable.length > 0) {
			const updatedSelectedGroups = selectedGroups.filter(
				({ activityId }) => activityId !== activity.activityId
			);
			setSelectedGroups(updatedSelectedGroups);
		} else {
			setSelectedGroups([
				...selectedGroups,
				{
					groupId: group.groupId,
					phaseId: phaseType === 'parentPhase' ? parentPhaseId : phaseId,
					activityId: activity.activityId,
					solutionMethodId:
						phaseType === 'parentPhase' ? parentId : solutionMethodId,
					complexity: complexity.id,
				},
			]);
		}
	};

	const addSubgroupActivityToSelection = (group, subgroup, activity) => {
		const isActivityAvailable = selectedGroups.filter(
			({ activityId }) => activityId === activity.activityId
		);
		if (isActivityAvailable.length > 0) {
			const updatedSelectedGroups = selectedGroups.filter(
				({ activityId }) => activityId !== activity.activityId
			);
			setSelectedGroups(updatedSelectedGroups);
		} else {
			setSelectedGroups([
				...selectedGroups,
				{
					groupId: group.groupId,
					phaseId: phaseType === 'parentPhase' ? parentPhaseId : phaseId,
					activityId: activity.activityId,
					solutionMethodId:
						phaseType === 'parentPhase' ? parentId : solutionMethodId,
					complexity: complexity.id,
					subGroupId: subgroup.subGroupId,
				},
			]);
		}
	};

	const addSubgroupToSelection = (group, subgroup) => {
		const isSubgroupAvailable = selectedGroups.filter(
			({ subGroupId }) => subGroupId === subgroup.subGroupId
		);
		if (isSubgroupAvailable.length > 0) {
			const updatedSelectedGroups = selectedGroups.filter(
				({ subGroupId }) => subGroupId !== subgroup.subGroupId
			);
			setSelectedGroups(updatedSelectedGroups);
		} else {
			const updatedSubgroup = [];
			for (const activity of group.activities) {
				if (
					activity.isSubgroup &&
					activity.subGroupId === subgroup.subGroupId
				) {
					for (const subgroupActivity of activity.activities) {
						updatedSubgroup.push({
							groupId: group.groupId,
							phaseId: phaseType === 'parentPhase' ? parentPhaseId : phaseId,
							activityId: subgroupActivity.activityId,
							solutionMethodId:
								phaseType === 'parentPhase' ? parentId : solutionMethodId,
							complexityId: complexity.id,
							subGroupId: activity.subGroupId,
						});
					}
				}
			}
			setSelectedGroups([...selectedGroups, ...updatedSubgroup]);
		}
	};

	const renderSubgroup = (group, subgroup) => {
		return (
			<li>
				<div class="referActivityGroupContainer">
					{collapseGroups.includes(
						`${subgroup.subGroupId}_${subgroup.subGroupName}`
					) ? (
						<Icon
							onClick={() => {
								handleGroupsCollapse(
									`${subgroup.subGroupId}_${subgroup.subGroupName}`
								);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleGroupsCollapse(
										`${subgroup.subGroupId}_${subgroup.subGroupName}`
									);
								}
							}}
							tabIndex="0"
							iconName="CaretDownSolid8"
						/>
					) : (
						<Icon
							onClick={() => {
								handleGroupsCollapse(
									`${subgroup.subGroupId}_${subgroup.subGroupName}`
								);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleGroupsCollapse(
										`${subgroup.subGroupId}_${subgroup.subGroupName}`
									);
								}
							}}
							tabIndex="0"
							iconName="CaretRightSolid8"
						/>
					)}
					<label class="NotificationChkboxContainer">
						<input
							name={subgroup.subGroupName}
							type="checkbox"
							checked={checkSubgroupSelection(subgroup)}
							onChange={() => {
								addSubgroupToSelection(group, subgroup);
							}}
						/>

						<span class="CustomCheckmark"></span>
						<span class="complexityLabel cloneActivityTitle">
							{subgroup.subGroupName}
						</span>
					</label>
				</div>
				<div class="referActivityChildContainer">
					<ul>
						{collapseGroups.includes(
							`${subgroup.subGroupId}_${subgroup.subGroupName}`
						) &&
							subgroup.activities.map((activity) => (
								<>
									<li key={activity.activityTitle}>
										<label class="NotificationChkboxContainer">
											<input
												name={activity.activityTitle}
												type="checkbox"
												checked={checkActivitySelection(activity)}
												onChange={() => {
													addSubgroupActivityToSelection(
														group,
														subgroup,
														activity
													);
												}}
											/>
											<span class="CustomCheckmark"></span>
											<span class="complexityLabel cloneActivityTitle">
												{activity.activityTitle}
											</span>
										</label>
									</li>
								</>
							))}
					</ul>
				</div>
			</li>
		);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phasesSearchValue, selectedSolutionMethods]);

	useEffect(() => {
		setShowSpinner(true);
		const timeOutId = setTimeout(() => {
			getGroups();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [groupSearchValue, selectedSolutionMethods, selectedPhases]);

	return (
		<div className="addActivityFields">
			<div className="cloneActivityContainer">
				<div className="cloneActivityFieldsWrapper">
					<input
						title={groupSearchValue}
						placeholder="Group name"
						value={groupSearchValue}
						onChange={(e) => setGroupSearchValue(e.target.value)}
					/>
					<Icon iconName="FilterSolid" />
					<Icon iconName="CaretUpSolid8" className="cloneDropDownArrow" />
				</div>
			</div>
			<div className="cloneActivityFiltersWrapper">
				<div className="cloneActivitySolTypeFilters">
					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Consulting / Support</span>
						<MultiSelectDropdown
							className="activityReferProjectSelectionDropdown"
							data={projectTypeData}
							setValue={setSelectedProjectType}
							value={selectedProjectType}
							enableDropdown={true}
						/>
					</div>

					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Solution Methods</span>
						<MultiSelectDropdown
							className="activityReferSolutionMethodDropdown"
							data={solutionMethods}
							setValue={setSelectedSolutionMethods}
							value={selectedSolutionMethods}
							enableDropdown={true}
						/>
					</div>
				</div>
				<div className="cloneActivitySolTypeFilters">
					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Phases</span>
						<MultiSelectDropdown
							className="activityReferSolutionMethodphasesDropdown"
							data={phases}
							setValue={setSelectedPhases}
							value={selectedPhases}
							enableDropdown={true}
						/>
					</div>
				</div>
				<div className="AddActivityCloneButtonSection actvityAddResultsButton">
					<h6>{foundGroups.length} Result(s) Found</h6>
					<button
						className="AddActivityCloneButton"
						disabled={apiCalled}
						onClick={() => {
							referGroups();
						}}
					>
						Link Group(s)
					</button>
				</div>
			</div>

			<div className="cloneActivityResultsWrapper">
				{showSpinner ? (
					<Spinner />
				) : (
					<>
						{foundGroups.length ? (
							<>
								<ul>
									{showMore ? (
										<>
											{foundGroups.map((item) => (
												<li>
													<div class="referActivityGroupMainContainer">
														<div class="referActivityGroupContainer">
															{collapseGroups.includes(item.groupId) ? (
																<Icon
																	onClick={() => {
																		handleGroupsCollapse(item.groupId);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			handleGroupsCollapse(item.groupId);
																		}
																	}}
																	tabIndex="0"
																	iconName="CaretDownSolid8"
																/>
															) : (
																<Icon
																	onClick={() => {
																		handleGroupsCollapse(item.groupId);
																	}}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			handleGroupsCollapse(item.groupId);
																		}
																	}}
																	tabIndex="0"
																	iconName="CaretRightSolid8"
																/>
															)}
															<label class="NotificationChkboxContainer">
																<input
																	name={item.groupName}
																	type="checkbox"
																	checked={getGroupSelection(item)}
																	onChange={() => {
																		addGroupToSelection(item);
																	}}
																/>

																<span class="CustomCheckmark"></span>
																<span class="complexityLabel cloneActivityTitle">
																	{item.groupName}
																</span>
															</label>
															<div class="cloneInfiActivity">
																<i
																	data-icon-name="Info"
																	aria-hidden="true"
																	class="root-41"
																></i>
																<div class="tabsTitleToolTip">
																	<span class="tabsToolTipArrow"></span>
																	<h5>Group Details</h5>
																	<h6>{item.projectTypeName}</h6>
																	<span class="detailsOfCloneActivity">
																		<span class="detailsCloneActivitySol">
																			<span>Delivery Method</span>:&nbsp;
																			<span>
																				{item.referedSolutionMethodName}
																			</span>
																		</span>
																		<span class="detailsCloneActivitySol">
																			<span>Phase</span>:&nbsp;
																			<span>{item.phaseName}</span>
																		</span>
																	</span>
																</div>
															</div>
														</div>
														<div class="referActivityChildContainer">
															<ul>
																{collapseGroups.includes(item.groupId) &&
																	item.activities.map((activity) => (
																		<li key={activity.activityTitle}>
																			<label class="NotificationChkboxContainer">
																				<input
																					name={activity.activityTitle}
																					type="checkbox"
																					checked={checkActivitySelection(
																						activity
																					)}
																					onChange={() => {
																						addActivityToSelection(
																							item,
																							activity
																						);
																					}}
																				/>
																				<span class="CustomCheckmark"></span>
																				<span class="complexityLabel cloneActivityTitle">
																					{activity.activityTitle}
																				</span>
																			</label>
																		</li>
																	))}
															</ul>
														</div>
													</div>
												</li>
											))}
										</>
									) : (
										<>
											{foundGroups
												.slice(
													0,
													foundGroups.length > 10 ? 10 : foundGroups.length
												)
												.map((item) => (
													<li>
														<div class="referActivityGroupMainContainer">
															<div class="referActivityGroupContainer">
																{collapseGroups.includes(item.groupId) ? (
																	<Icon
																		onClick={() => {
																			handleGroupsCollapse(item.groupId);
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				handleGroupsCollapse(item.groupId);
																			}
																		}}
																		tabIndex="0"
																		iconName="CaretDownSolid8"
																	/>
																) : (
																	<Icon
																		onClick={() => {
																			handleGroupsCollapse(item.groupId);
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				handleGroupsCollapse(item.groupId);
																			}
																		}}
																		tabIndex="0"
																		iconName="CaretRightSolid8"
																	/>
																)}
																<label class="NotificationChkboxContainer">
																	<input
																		name={item.groupName}
																		type="checkbox"
																		checked={getGroupSelection(item)}
																		onChange={() => {
																			addGroupToSelection(item);
																		}}
																	/>

																	<span class="CustomCheckmark"></span>
																	<span class="complexityLabel cloneActivityTitle">
																		{item.groupName}
																	</span>
																</label>
																<div class="cloneInfiActivity">
																	<i
																		data-icon-name="Info"
																		aria-hidden="true"
																		class="root-41"
																	></i>
																	<div class="tabsTitleToolTip">
																		<span class="tabsToolTipArrow"></span>
																		<h5>Group Details</h5>
																		<h6>{item.projectTypeName}</h6>
																		<span class="detailsOfCloneActivity">
																			<span class="detailsCloneActivitySol">
																				<span>Delivery Method</span>:&nbsp;
																				<span>
																					{item.referedSolutionMethodName}
																				</span>
																			</span>
																			<span class="detailsCloneActivitySol">
																				<span>Phase</span>:&nbsp;
																				<span>{item.phaseName}</span>
																			</span>
																		</span>
																	</div>
																</div>
															</div>
															<div class="referActivityChildContainer">
																<ul>
																	{collapseGroups.includes(item.groupId) &&
																		item.activities.map((activity) => (
																			<>
																				{!activity.isSubgroup ? (
																					<li key={activity.activityTitle}>
																						<label class="NotificationChkboxContainer">
																							<input
																								name={activity.activityTitle}
																								type="checkbox"
																								checked={checkActivitySelection(
																									activity
																								)}
																								onChange={() => {
																									addActivityToSelection(
																										item,
																										activity
																									);
																								}}
																							/>
																							<span class="CustomCheckmark"></span>
																							<span class="complexityLabel cloneActivityTitle">
																								{activity.activityTitle}
																							</span>
																						</label>
																					</li>
																				) : (
																					<>{renderSubgroup(item, activity)}</>
																				)}
																			</>
																		))}
																</ul>
															</div>
														</div>
													</li>
												))}
										</>
									)}
								</ul>
							</>
						) : (
							<></>
						)}
						{foundGroups.length > 10 && !showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(true)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(true);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show more Groups"
							>
								Show more
							</span>
						) : (
							<></>
						)}
						{showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(false)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(false);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show less Groups"
							>
								Show less
							</span>
						) : (
							<></>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ActivityRefer;
