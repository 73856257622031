import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { urlValidator } from '../../helpers/Validation';
import { useHistory, useLocation } from 'react-router';

const AdminManageToolsModal = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [tools, setTools] = useState([]);
	const [deletedTools, setDeletedTools] = useState([]);
	const [expandedTools, setExpandedTools] = useState([]);
	const [newTool, setNewTool] = useState(null);
	const [updatedToolIds, setUpdatedToolIds] = useState([]);
	const { axiosGetService, axiosPostService } = useAxios();
	const [selectedTool, setSelectedTool] = useState({});
	const [expandedNewTool, setExpandedNewTool] = useState(false);
	const saveTools = async () => {
		const updatedTools = tools.filter(
			(tool) => updatedToolIds.includes(tool.id) || tool.id === 0
		);
		if (updatedTools.some((tool) => getToolMessage(tool) != '')) {
			toast.error('Some tools are not valid', {
				position: 'top-right',
			});
		} 
		// else if (
		// 	new Set(tools.map((tool) => tool.name.toLowerCase().trim())).size !==
		// 	tools.length
		// ) {
		// 	toast.error('Some tools have duplicate names', {
		// 		position: 'top-right',
		// 	});
		// } else if (
		// 	new Set(tools.map((tool) => tool.url.toLowerCase().trim())).size !==
		// 	tools.length
		// ) 
		// {
		// 	toast.error('Some tools have duplicate urls', {
		// 		position: 'top-right',
		// 	});
		// } 
		else {
			const deletedToolIds = deletedTools.map((tool) => tool.id).join();
			const response = await axiosPostService(
				'api/sdmtools/updateToolsForAdmin',
				{
					updatedTools,
					deletedToolIds,
				}
			);
			if (response.data.status) {
				toast.info('Tools updates successfully', {
					position: 'top-right',
				});
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
			history.push(pathname);
		}
	};
	const checkUrl = (url) => {
		return !urlValidator.test(url) && url;
	};

	const addTool = () => {
		if (getToolMessage(newTool) === '') {
			if (
				tools.some(
					(tool) =>
						tool.name.toLowerCase().trim() === newTool.name.toLowerCase().trim()
				)
			) {
				toast.error('Tool Name already exists', {
					position: 'top-right',
				});
			} else if (
				tools.some(
					(tool) =>
						tool.url.toLowerCase().trim() === newTool.url.toLowerCase().trim()
				)
			) {
				toast.error('Tool Url already exists', {
					position: 'top-right',
				});
			} else {
				setTools([{ ...newTool, id: 0, canBeDeleted: true }, ...tools]);
				setExpandedTools(expandedTools.map((expandedTool) => expandedTool + 1));
				setNewTool({ name: '', url: '' });
			}
		} else {
			toast.error(getToolMessage(newTool), {
				position: 'top-right',
			});
		}
	};
	const deleteRole = (toolIndex) => {
		if (tools[toolIndex].canBeDeleted) {
			if (tools[toolIndex].id != 0) {
				setDeletedTools([...deletedTools, tools[toolIndex]]);
			}
			const newTools = [...tools];
			newTools.splice(toolIndex, 1);
			setTools(newTools);
			setExpandedTools(
				expandedTools
					.filter((expandedToolIndex) => expandedToolIndex != toolIndex)
					.map((expandedToolIndex) =>
						expandedToolIndex > toolIndex
							? expandedToolIndex - 1
							: expandedToolIndex
					)
			);
		} else {
			toast.error('Tool linked to activity or topic', {
				position: 'top-right',
			});
		}
		setSelectedTool({});
	};
	const getToolMessage = (tool) => {
		if (tool.name.length === 0) return 'Tool name cannot be empty';
		if (tool.url.length === 0) return 'Url cannot be empty';
		if (checkUrl(tool.url)) return 'Invalid Url';
		return '';
	};
	const toggleToolEditor = (toolIndex) => {
		if (expandedTools.includes(toolIndex)) {
			setExpandedTools(expandedTools.filter((index) => index !== toolIndex));
		} else {
			setExpandedTools([...expandedTools, toolIndex]);
		}
	};
	const updateTool = (toolIndex, propetyName, newValue) => {
		const newTools = [...tools];
		newTools[toolIndex][propetyName] = newValue;
		setTools(newTools);
	};
	useEffect(() => {
		axiosGetService(`api/sdmtools/getToolsForAdmin`).then((response) => {
			setTools(response.data);
		});
		setNewTool({ name: '', url: '' });
	}, []);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Tools
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li class="activePhaseTab">
										<span>Edit Tools</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div class="roleAdditionContainer">
											<div class="addRoleButtonAction">
												<div
													class="selectAddRoleButton"
													onClick={() => setExpandedNewTool(!expandedNewTool)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setExpandedNewTool(!expandedNewTool);
														}
													}}
													aria-label="Add New Tool"
													role="button"
													tabIndex={0}
												>
													+&nbsp;Add New Tool
												</div>
												{expandedNewTool && (
													<div class="addRoleFields">
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">Tool Name*</span>
															<input
																title=""
																placeholder="Enter Tool Name"
																value={newTool.name}
																onChange={(e) =>
																	setNewTool({
																		...newTool,
																		name: e.target.value,
																	})
																}
															/>
														</div>
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">URL*</span>
															<input
																title=""
																placeholder="Enter URL"
																value={newTool.url}
																onChange={(e) =>
																	setNewTool({
																		...newTool,
																		url: e.target.value,
																	})
																}
															/>
														</div>
														<div>
															<span
																className="addRoleIcon"
																title="Add Tool"
																onClick={addTool}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		addTool();
																	}
																}}
																aria-label="Save New Tool"
																role="button"
																tabIndex={0}
															></span>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="addRolesListContainer">
											<ul>
												{tools.map((tool, index) => (
													<li key={index}>
														<div class="addRoleButtonAction">
															<div class="addRoleTitleEditPart">
																<h6>{tool.name}</h6>
																<div className="editRoleDetailsDiv">
																	<span
																		className="editRoleIcon"
																		onClick={() => {
																			toggleToolEditor(index);
																			setUpdatedToolIds([
																				...updatedToolIds,
																				tool.id,
																			]);
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				toggleToolEditor(index);
																				setUpdatedToolIds([
																					...updatedToolIds,
																					tool.id,
																				]);
																			}
																		}}
																		aria-label="Edit Tool"
																		role="button"
																		tabIndex={0}
																		title="Edit Tool"
																	></span>
																	{selectedTool.id === tool.id && (
																		<div className="deleteSamplesPopUp">
																			<p>
																				Are you sure that you want to delete
																				this tool?
																			</p>
																			<div>
																				<button
																					onClick={() => {
																						setSelectedTool({});
																					}}
																				>
																					Cancel
																				</button>
																				<button
																					onClick={() => {
																						deleteRole(index);
																					}}
																				>
																					Delete
																				</button>
																			</div>
																		</div>
																	)}
																	<span
																		className="deleteRoleIcon"
																		onClick={() => setSelectedTool(tool)}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setSelectedTool(tool);
																			}
																		}}
																		aria-label="Delete Tool"
																		role="button"
																		tabIndex={0}
																		title="Delete Tool"
																	></span>
																</div>
															</div>
															{expandedTools.includes(index) ? (
																<div class="addRoleFields">
																	<div class="addRoleNameContainer">
																		<span class="AddRoleFieldLabel">
																			Tool Name*
																		</span>
																		<input
																			title=""
																			placeholder="Role Title"
																			value={tool.name}
																			onChange={(e) =>
																				updateTool(
																					index,
																					'name',
																					e.target.value
																				)
																			}
																		/>
																	</div>
																	<div class="addRoleNameContainer">
																		<span class="AddRoleFieldLabel">URL*</span>
																		<input
																			title=""
																			placeholder="URL"
																			value={tool.url}
																			onChange={(e) =>
																				updateTool(index, 'url', e.target.value)
																			}
																		/>
																	</div>
																</div>
															) : (
																<></>
															)}
															{expandedTools.includes(index) ? (
																<p className="extractMethodologyMessage">
																	{getToolMessage(tool)}
																</p>
															) : (
																<></>
															)}
														</div>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="saveContinueDetailsContainer">
								<button
									disabled=""
									class="forwardButton"
									onClick={saveTools}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveTools();
										}
									}}
								>
									<span class="saveContinueButton">Save</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AdminManageToolsModal;
