import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import loadable from '@loadable/component';
import { MessageBar, MessageBarType } from '@fluentui/react';

import { useAuth } from '../../../../../context/UserAuthContext';
import { useAxios } from '../../../../../context/AxiosContext';
import { getOtherUserProfile } from '../../../../../helpers/GraphService';
import { axiosTokenService } from '../../../../../components/CkEditor/api/axiosBaseUrl';
import { useLeftMenuCollapse } from '../../../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../../../context/TelemetryContext';

import Spinner from '../../../../../helpers/Spinner';
import TemporaryCkAlert from '../../../../../components/CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../../../../components/CkEditor/DecryptionComponent';
import { useConfig } from '../../../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../../../components/CkEditor/MyContributionTopics/MyContribution/TopicsContributionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

initializeIcons();

const TopicsMyContributionsPreview = (props) => {
	const { ckEdKEY } = useConfig();
	const { axiosGetService } = useAxios();

	const { artifactType, id, artifactId, stateId } = useParams();
	const { user } = useAuth();
	const { track } = useAppInsights();
	const currentUser = user.profile.mail;

	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const [description, setDescription] = useState(null);
	const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
	const [topicDescriptionData, setTopicDescriptionData] = useState(null);

	const [licenseKey, setLicenseKey] = useState(null);
	const [reviewerInfo, setReviewerInfo] = useState(null);

	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();

	const getTopicsContributionPreviewData = async () => {
		await axiosGetService(
			`api/sdmtopics/gettopicreviewerorapprovercontribution?id=${id}&topicId=${artifactId}&stateId=2`
		).then((response) => {
			if (response.data) {
				setTopicDescriptionData(response.data);
				if (topicDescriptionData) {
					setDescription(response.data.description);
				}
				if (response.data.resultCalloutJson) {
					setDescriptionSuggestions(response.data.resultCalloutJson);
				}
			} else {
				setDescription('noData');
			}
		});
	};

	const getContributor = (suggestions) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		if (topicDescriptionData.reviewerSuggestionsJson) {
			getContributor(JSON.parse(topicDescriptionData.reviewerSuggestionsJson));
		}
		if (topicDescriptionData.resultCalloutJson) {
			getContributor(topicDescriptionData.resultCalloutJson);
		}
		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
		}
		setCkEditorUsers(contributorInfo);
		if (topicDescriptionData.reviewerEmail) {
			await getOtherUserProfile(
				accessToken,
				topicDescriptionData.reviewerEmail
			).then((response) => {
				setReviewerInfo(response.firstName + ' ' + response.surname);
			});
		}
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	useEffect(() => {
		if (topicDescriptionData) {
			setTopicDescriptionData(null);
			setCkEditorUsers([]);
		}
		getLicense();
		getTopicsContributionPreviewData();
	}, [id, artifactId, stateId]);

	useEffect(() => {
		if (topicDescriptionData) {
			getUsersInfo();
		}
	}, [topicDescriptionData]);

	if (description === 'noData') {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				You did not make any contribution to this Topic.
			</MessageBar>
		);
	}

	if (!topicDescriptionData || ckEditorUsers.length < 1 || !licenseKey) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}

	return (
		<div className="PreviewAndSubmitPopUp historicalPreview">
			<div className="topicsCrowdSourcingContainer">
				<div className="topicsDescriptionDetails">
					<div className="topicDescriptionDiv">
						<div className="topicDescTitlePart">
							<span className="topicVersion">
								Document Version: {topicDescriptionData.topicVersion}
								,&nbsp;&nbsp;
								{`${topicDescriptionData.modifiedDate} `}
							</span>
							{reviewerInfo ? (
								<p className="reviewerNameLabel">
									Reviewer Name:&nbsp;{reviewerInfo}
								</p>
							) : (
								<></>
							)}
						</div>
						<h4 className="topicTitle">{topicDescriptionData.topicName}</h4>
						<TemporaryCkAlert/>
						<DescriptionCkEditor
							users={ckEditorUsers}
							currentUser={currentUser}
							initialData={topicDescriptionData.description}
							initalSuggestions={topicDescriptionData.resultCalloutJson}
							reviewedSuggestions={topicDescriptionData.resultReviewedJson}
							licenseKey={licenseKey}
							previewType="contribution"
							stateId={topicDescriptionData.stateId}
							comment={
								topicDescriptionData.resultApproverComments
									? topicDescriptionData.resultApproverComments
									: []
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopicsMyContributionsPreview;
