import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { Icon } from '@fluentui/react/lib/Icon';
import fileIcons from '../../static/TemplateIcons';

function LocalizedTemplateModal({ id, handleTemplatePreview, closeModal }) {
	const { axiosGetService } = useAxios();
	const [data, setData] = useState([]);
	const { track } = useAppInsights();

	const getTemplates = async () => {
		await axiosGetService(`api/sdmtemplate/getComplexityTemplate/${id}`).then(
			(response) => {
				setData(response.data);
			}
		);
	};

	useEffect(() => {
		getTemplates();
	}, []);

	return (
		<>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					Complexity Templates
				</Modal.Title>
				<Icon
					iconName="Cancel"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							closeModal();
						}
					}}
					onClick={closeModal}
				/>
			</Modal.Header>
			<Modal.Body>
				<Table striped>
					<thead>
						<tr>
							<th>Name</th>
							<th>Complexity Type</th>
							<th>Detail View</th>
						</tr>
					</thead>
					<tbody>
						{data.map((template) => {
							return (
								<tr>
									<td>
										<img
											src={
												template.inProgress
													? fileIcons['inProgress']
													: template.fileType !== null
													? fileIcons[template.fileType.toLowerCase()]
													: fileIcons['defaultTemplate']
											}
											className="imageOfSearchResult"
											alt="File Type"
										/>
										{!template.inProgress ? (
											<a
												href={template.fileLocationNew}
												target="_blank"
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														track(1, 112, template.id, template.title, {
															phaseName: template.phaseName,
															complexityId: template.complexityId,
														});
													}
												}}
												onClick={() =>
													track(1, 112, template.id, template.title, {
														phaseName: template.phaseName,
														complexityId: template.complexityId,
													})
												}
											>
												{template.title}
											</a>
										) : (
											<span>{template.title}</span>
										)}
									</td>
									<td>{template.projectComplexity}</td>
									<td>
										<button
											title="View Document"
											className="templateViewDocument"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													handleTemplatePreview(template);
												}
											}}
											onClick={() => {
												handleTemplatePreview(template);
											}}
											disabled={
												['zip', 'aspx', 'html', null].includes(
													template.fileType
												) ||
												template.inProgress ||
												template.previewUrl === null
											}
										>
											View Document
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Modal.Body>
		</>
	);
}

export default LocalizedTemplateModal;
