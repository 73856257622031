import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Icon } from '@fluentui/react';

import { getUsersList } from '../../../helpers/GraphService';
import { useAxios } from '../../../context/AxiosContext';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import UserSelectionDropdown from './UserSelectionDropdown';

const Collaborators = ({
	coOwners,
	setCoOwners,
	reviewers,
	setReviewers,
	approvers,
	setApprovers,
	showSpinner,
}) => {
	const { axiosGetService } = useAxios();
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
	});
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});
	const [showUsersList, setShowUsersList] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});

	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getUser(coOwnerEmail, 'coOwner');
			} else {
				setError({ ...error, ['coOwner']: false });
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	return (
		<div className="methodPhaseCollabaratorsTab">
			{showSpinner ? (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			) : (
				<div className="phaseCollabaratorsFields">
					<p>
						<span className="aprroverStatusIcon"></span>Once you add Reviewers
						and Approvers at a phase level, the method level Reviewers and
						Approvers will not have rights to Review or Approve content for this
						phase.
					</p>
					<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
							<h6>Add Approvers</h6>
							<UserSelectionDropdown
								className="approver"
								enableDropdown={true}
								data={usersList}
								value={approvers}
								setValue={setApprovers}
								type="approver"
								searchValue={approverEmail}
								setSearchValue={setApproverEmail}
								error={error}
								emailFound={emailFound['approver']}
							/>
						</div>

						{approvers.length > 0 && (
							<div className="collabaratorsChildContainers">
								<ul>
									{approvers.map((user) => (
										<li key={user.mail}>
											<span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
											<span className="collabaratorPersonaTitle">
												{user.firstName + ' ' + user.surname}
											</span>
											<span>
												<Icon
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, approvers, setApprovers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, approvers, setApprovers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove approver"
												/>
											</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>

					<div className="collabaratorsMainContainers">
						<div className="collabaratorsParentContainers">
							<h6>Add Reviewers</h6>
							<UserSelectionDropdown
								className="reviewer"
								enableDropdown={true}
								data={usersList}
								value={reviewers}
								setValue={setReviewers}
								type="reviewer"
								searchValue={reviewerEmail}
								setSearchValue={setReviewerEmail}
								error={error}
								emailFound={emailFound['reviewer']}
							/>
						</div>

						{reviewers.length > 0 && (
							<div className="collabaratorsChildContainers">
								<ul>
									{reviewers.map((user) => (
										<li key={user.mail}>
											<span className="collabaratorPersonaImage">
												<img src={user.photo} alt="img" />
											</span>
											<span className="collabaratorPersonaTitle">
												{user.firstName + ' ' + user.surname}
											</span>
											<span>
												<Icon
													iconName="Cancel"
													onClick={() => {
														removeCoOwner(user.mail, reviewers, setReviewers);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeCoOwner(user.mail, reviewers, setReviewers);
														}
													}}
													role="button"
													tabIndex={0}
													aria-label={`Remove ${user.firstName} ${user.surname}`}
													title="Remove reviewer"
												/>
											</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Collaborators;
