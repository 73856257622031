import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Dropdown({
	className,
	data,
	setValue,
	value,
	enableDropdown,
	subgroupKey,
}) {
	const [visible, setVisible] = useState(false);
	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const handleDropdown = (item) => {
		const selectedValue = item;
		const filteredData = value[subgroupKey].activities.filter(
			({ dragNDropId }) => dragNDropId !== item.dragNDropId
		);
		if (filteredData.length !== value[subgroupKey].activities.length) {
			setValue((prevState) => ({
				...prevState,
				[subgroupKey]: {
					...value[subgroupKey],
					activities: filteredData,
				},
			}));
		} else {
			setValue((prevState) => ({
				...prevState,
				[subgroupKey]: {
					...value[subgroupKey],
					activities: [...value[subgroupKey].activities, selectedValue],
				},
			}));
		}
	};

	const findSelectedItem = (item) => {
		const filteredData = value[subgroupKey].activities.filter(
			({ dragNDropId }) => dragNDropId === item.dragNDropId
		);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div title={!enableDropdown ? 'No activites to select' : ''} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">
						{value[subgroupKey].activities.length > 0 ? (
							`${value[subgroupKey].activities.length} ${
								value[subgroupKey].activities.length > 0
									? 'Activities'
									: 'Activity'
							} Selected`
						) : (
							<>Select Activities</>
						)}
					</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.activityTitle + ' Header'}
											key={item.activityTitle}
										>
											<span>{item.activityTitle}</span>
										</li>
									);
								}
								return (
									<li
										className={
											findSelectedItem(item) ? 'selectedGroupActivity' : 'none'
										}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.activityTitle}
										key={item.activityTitle}
									>
										<span>{item.activityTitle}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
