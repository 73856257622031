import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';

import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../../components/CkEditor/api/axiosBaseUrl';
import { useAxios } from '../../context/AxiosContext';
import { artifactMapData } from '../../static/ArtifactMapData';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const ReviewersOrApproversList = ({
	showList,
	setShowList,
	actionTypeId,
	artifactId,
	artifactType,
	setSelectedArtifactId,
}) => {
	const { axiosGetService } = useAxios();
	const [usersDetails, setUsersdetails] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);

	const getUser = async (userEmail) => {
		setShowSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setUsersdetails((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};
	const getUsersList = async () => {
		await axiosGetService(
			`api/sdmuser/getreviewerorapprovermembers?artifactId=${artifactId}&artifactTypeId=${artifactMapData[artifactType]}&actionTypeId=${actionTypeId}`
		).then((response) => {
			if (response.data.length > 0) {
				for (const userEmail of response.data) {
					getUser(userEmail);
				}
			} else {
				setShowSpinner(false);
			}
		});
	};

	const closeModal = () => {
		setSelectedArtifactId(-1);
		setShowList(false);
	};

	useEffect(() => {
		getUsersList();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="approverAndReviwerPopUp"
					show={showList}
					onHide={() => {
						closeModal();
					}}
					size="md"
					centered
				>
					<Modal.Header>
						Pending {actionTypeId === 1 ? 'Review' : 'Approval'}
						<Icon
							onClick={() => {
								closeModal();
							}}
							iconName="Cancel"
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							tabIndex={0}
							role="button"
							aria-label="Close Pending review Modal"
						/>
					</Modal.Header>
					<Modal.Body>
						<p>
							{actionTypeId === 1 ? 'Review' : 'Approval'} is pending with
							following {actionTypeId === 1 ? 'reviewers' : 'approvers'}
						</p>
						<ul>
							{usersDetails.map((item) => (
								<li>
									<span>{item.firstName + ' ' + item.surname}</span>
									<a href={`mailto:${item.mail}`}>{item.mail}</a>
								</li>
							))}
						</ul>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default ReviewersOrApproversList;
