import DevConfig from './DevConfig';
import TestConfig from './TestConfig';
import LocalConfig from './LocalConfig';
import UatConfig from './UatConfig';
import ProdBackupConfig from './ProdBackupConfig';
import PreprodConfig from './PreprodConfig';
import ProdConfig from './ProdConfig';

const DomainConfigMapper = {
	localhost: LocalConfig,
	devsdmplus2: DevConfig,
	test2sdmplus: TestConfig,
	uat2sdmplus: UatConfig,
	sdmplusbackup2: ProdBackupConfig,
	sdmpluspreprod2: PreprodConfig,
	sdmplus2: ProdConfig,
};

//devsdmplus2.azurewebsites.net/

const urlContains = (domain) => {
	if (domain.split('.').length > 0) {
		return DomainConfigMapper[domain.split('.')[0]];
	} else {
		return DomainConfigMapper['localhost'];
	}
};

export default urlContains;

