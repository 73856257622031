import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';

import MultiSelectDropdown from '../ActivityAddition/MultiSelectDropdown';
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import Spinner from '../../../helpers/Spinner';
import { toast } from 'react-toastify';

const PhaseRefer = ({
	setShowReferPhaseForm,
	phases,
	setPhases,
	projectTypeId,
	solutionMethodId,
	type,
	setApiCallNeeded,
	parentId,
}) => {
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(true);
	const [showMore, setShowMore] = useState(false);
	const [phasesSearchValue, setPhasesSearchValue] = useState('');
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [avilablePhases, setAvilablePhases] = useState([]);
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([
		{
			id: Number(projectTypeId),
			name: Number(projectTypeId) == 1 ? 'Consulting' : 'Support',
		},
	]);

	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};

	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=${
				phasesSearchValue.trim().length > 0
					? encodeURIComponent(phasesSearchValue)
					: 0
			}&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			setAvilablePhases(response.data.phases);
			closeSpinner();
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	const checkSelectedPhase = (value, item) => {
		const filteredData = value.filter(
			({ parentPhaseId }) => parentPhaseId === item
		);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const removeSelectedItem = (value, setValue, item) => {
		const filteredData = value.filter(
			({ parentPhaseId }) => parentPhaseId !== item
		);
		setValue(filteredData);
	};

	const updatePhase = () => {
		const referredPhase = selectedPhases.map((item) => {
			return {
				id: item.parentPhaseId.toString(),
				name: item.phaseName,
				type: 'new',
			};
		});
		const filterPhase = phases.filter(({ id }) => Number(id) !== 0);
		setPhases([
			...filterPhase,
			...referredPhase,
			{ id: '0', name: '', type: 'new' },
		]);
		setSelectedPhases([]);
		setShowReferPhaseForm(false);
	};

	const referPhase = async () => {
		await axiosPostService(`api/sdmphase/addreferedphase`, selectedPhases).then(
			(response) => {
				if (response.data.status) {
					for (const phase of selectedPhases) {
						track(1, 911, phase.parentPhaseId, phase.phaseName);
					}
					updatePhase();
					setApiCallNeeded(true);
					toast.info('You have successfully Linked the phase(s)', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			}
		);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phasesSearchValue, selectedSolutionMethods]);

	return (
		<div className="addActivityFields">
			<div className="cloneActivityContainer">
				<div className="cloneActivityFieldsWrapper">
					<input
						title={phasesSearchValue}
						placeholder="Phase name"
						value={phasesSearchValue}
						onChange={(e) => setPhasesSearchValue(e.target.value)}
					/>
					<Icon iconName="FilterSolid" />
					<Icon iconName="CaretUpSolid8" className="cloneDropDownArrow" />
				</div>
			</div>
			<div className="cloneActivityFiltersWrapper">
				<div className="cloneActivitySolTypeFilters">
						<div className='hidingConsultingSupport'>
						<span>Consulting / Support</span>
						<MultiSelectDropdown
							className="activityReferProjectSelectionDropdown"
							data={projectTypeData}
							setValue={setSelectedProjectType}
							value={selectedProjectType}
							enableDropdown={true}
						/>
					</div>

					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Solution Methods</span>
						<MultiSelectDropdown
							className="activityReferSolutionMethodDropdown"
							data={solutionMethods}
							setValue={setSelectedSolutionMethods}
							value={selectedSolutionMethods}
							enableDropdown={true}
						/>
					</div>
				</div>
				<div className="AddActivityCloneButtonSection">
					<button
						className="AddActivityCloneButton"
						onClick={() => {
							referPhase();
						}}
					>
						Link Phase(s)
					</button>
				</div>
			</div>
			<div className="cloneActivityResultsWrapper">
				{showSpinner ? (
					<Spinner />
				) : (
					<>
						<h6>{avilablePhases.length} Result(s) Found</h6>
						{avilablePhases.length ? (
							<>
								<ul>
									{showMore ? (
										<>
											{avilablePhases.map((item) => (
												<li key={item.id + item.name}>
													<label class="NotificationChkboxContainer">
														<input
															name={item.name}
															type="checkbox"
															onChange={() => {
																if (
																	checkSelectedPhase(
																		selectedPhases,
																		item.phaseId
																	)
																) {
																	removeSelectedItem(
																		selectedPhases,
																		setSelectedPhases,
																		item.phaseId
																	);
																} else {
																	setSelectedPhases([
																		...selectedPhases,
																		{
																			solutionMethodId: solutionMethodId,
																			parentSolutionMethodId:
																				item.solutionMethodId,
																			parentPhaseId: item.phaseId,
																			phaseName: item.phaseName,
																		},
																	]);
																}
															}}
															checked={checkSelectedPhase(
																selectedPhases,
																item.phaseId
															)}
														/>
														<span class="CustomCheckmark"></span>
														<span class="complexityLabel cloneActivityTitle">
															{item.name}
														</span>
													</label>
													<div class="cloneInfiActivity">
														<Icon iconName="Info" />
														<div className="tabsTitleToolTip">
															<span className="tabsToolTipArrow"></span>
															<h5>Phase Details</h5>
															<h6>{item.projectTypeName}</h6>
															<span className="detailsOfCloneActivity">
																<span className="detailsCloneActivitySol">
																	<span>Delivery Method</span>:&nbsp;
																	<span>{item.solutionMethodName}</span>
																</span>
															</span>
														</div>
													</div>
												</li>
											))}
										</>
									) : (
										<>
											{avilablePhases
												.slice(
													0,
													avilablePhases.length > 10
														? 10
														: avilablePhases.length
												)
												.map((item) => (
													<li key={item.id + item.name}>
														<label class="NotificationChkboxContainer">
															<input
																name={item.name}
																type="checkbox"
																onChange={() => {
																	if (
																		checkSelectedPhase(
																			selectedPhases,
																			item.phaseId
																		)
																	) {
																		removeSelectedItem(
																			selectedPhases,
																			setSelectedPhases,
																			item.phaseId
																		);
																	} else {
																		setSelectedPhases([
																			...selectedPhases,
																			{
																				solutionMethodId:
																					type === 'parentPhase'
																						? Number(parentId)
																						: Number(solutionMethodId),
																				parentSolutionMethodId:
																					item.solutionMethodId,
																				parentPhaseId: item.phaseId,
																				phaseName: item.phaseName,
																			},
																		]);
																	}
																}}
																checked={checkSelectedPhase(
																	selectedPhases,
																	item.phaseId
																)}
															/>
															<span class="CustomCheckmark"></span>
															<span class="complexityLabel cloneActivityTitle">
																{item.name}
															</span>
														</label>
														<div class="cloneInfiActivity">
															<Icon iconName="Info" tabIndex={0} />
															<div className="tabsTitleToolTip">
																<span className="tabsToolTipArrow"></span>
																<h5>Phase Details</h5>
																<h6>{item.projectTypeName}</h6>
																<span className="detailsOfCloneActivity">
																	<span className="detailsCloneActivitySol">
																		<span>Delivery Method</span>:&nbsp;
																		<span>{item.solutionMethodName}</span>
																	</span>
																</span>
															</div>
														</div>
													</li>
												))}
										</>
									)}
								</ul>
							</>
						) : (
							<></>
						)}
						{avilablePhases.length > 10 && !showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(true)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(true);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show more Phases"
							>
								Show more
							</span>
						) : (
							<></>
						)}
						{showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(false)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(false);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Click to Show Less Phases"
							>
								Show less
							</span>
						) : (
							<></>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PhaseRefer;
