import React, { useState, useEffect } from "react";
import {
	Switch,
	Route,
	useRouteMatch,
	useLocation,
	useHistory,
} from 'react-router-dom';
import TopicsTutorials from "../TopicsTutorials";
import TopicsTutorialsDetails from "../TopicsTutorialsDetails";
import { useAxios } from '../../context/AxiosContext';

const TutorialLanding = () => {
    const { url } = useRouteMatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { axiosGetService } = useAxios();
    const [leftMenuSelectedId, setLeftMenuSelectedId] = useState(1);
    const [tutorialDynamicFilterTag, setTutorialDynamicFilterTag] = useState({});
    const [tutorialDynamicFilterTagMapping, setTutorialDynamicFilterTagMapping] =useState({});
    const [tutorialSearchInput, setTutorialSearchInput] = useState('');
    const [tutorialHashtag, setTutorialHashtag] = useState([]);
    const [showTutorials, setShowTutorials] = useState(true);
    // const [expandTopicsLeftMenu, setExpandTopicsLeftMenu] = useState(true);
    // const [solutionMethodsFilterTag, setSolutionMethodsFilterTag] = useState([]);
    // const [processGroupFilterTag, setProcessGroupFilterTag] = useState([]);
    // const [dynamicFilterTag, setDynamicFilterTag] = useState({});
    // const [topicHashtag, setTopicHashtag] = useState([]);
	// const [tutorialHashtag, setTutorialHashtag] = useState([]);

    // const resetSolutionMethodFilterTag = () => {
	// 	if (solutionMethodsFilterTag.length) {
	// 		setSolutionMethodsFilterTag([]);
	// 	}
	// 	if (processGroupFilterTag.length) {
	// 		setProcessGroupFilterTag([]);
	// 	}
	// 	if (Object.keys(dynamicFilterTag).length) {
	// 		setDynamicFilterTag({});
	// 	}
	// 	setTutorialHashtag([]);
	// 	setTopicHashtag([]);
	// };

    // const resetPhaseFilterTag = () => {
	// 	if (phasesFilterTag.length) {
	// 		setPhasesFilterTag([]);
	// 	}
	// };

    const getTutorialStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=ShowVideoTutorials`).then(
			(response) => {
				if (response.data[0].value === 'true') {
					setShowTutorials(true);
				} else {
					setShowTutorials(false);
				}
			}
		);
	};

	useEffect(() => {
		getTutorialStatus();
	}, []);

    useEffect(() => {
		const paths = pathname.split('/').filter((item) => item);
		if (paths.includes('tutorials') && !showTutorials) {
			history.push('/pageNotFound');		
		}
	}, [showTutorials]);

    return(
        <Switch>
            <Route exact path={url}>
				<TopicsTutorials
					tutorialDynamicFilterTag={tutorialDynamicFilterTag}
					setTutorialDynamicFilterTag={setTutorialDynamicFilterTag}
					tutorialDynamicFilterTagMapping={tutorialDynamicFilterTagMapping}
					setTutorialDynamicFilterTagMapping={
						setTutorialDynamicFilterTagMapping
					}
					searchInput={tutorialSearchInput}
					setSearchInput={setTutorialSearchInput}
					tutorialHashtag={tutorialHashtag}
					setTutorialHashtag={setTutorialHashtag}
					showTutorials={showTutorials}
				/>
			</Route>
			<Route
				exact
				path={`${url}/:tutorialId(\\d+)`}
			>
				<TopicsTutorialsDetails
					// setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
				/>
			</Route>
            <Route path="*">
				<ComingSoon />
			</Route>
        </Switch>
    )
}

const ComingSoon = () => (
	<h2 className="pageNotFound">
		<b>404 Page not found!</b>
	</h2>
);
export default TutorialLanding;