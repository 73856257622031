const PreprodConfig = {
    clientId: '0dc44a95-765f-4872-be4d-6096bc1f0dbd',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://sdmpluspreprod2.azurewebsites.net/',
    apiScopes: [
        'https://microsoft.onmicrosoft.com/c4a5b2ba-30a1-42dd-bab3-0580e6e7eca7/.default',
    ],
    copilotApiScope: ['api://dd95f4e6-1439-46f7-a5af-29d9d54801cd' + '/.default'],
    copilotApiURL: 'https://api.buddy.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://sdmpluspreprodapi.azurewebsites.net/',
    instrumentationKey: '52a1530f-87cc-4b54-987d-1d7f6387060b',
    solutionMethodIconsUrl: 'https://sdmplusprodstorage.z19.web.core.windows.net',
    sdm1URL: 'https://sdmpluspreprod.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://sdmpluspreprod2.azurewebsites.net',
    appInsightsAPIKey: 'tm0uigqq6freqfu3sgd4eyegw17wll6n6i0jcbyh',
    appInsightsAppId: '8d70dfbc-fbc4-47f0-af12-44293e2f14d3',
    yammerGroupId: '144161210368',
    yammerInstance: 'Prod',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - Preprod',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'false',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusTutorials_Test/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey'
};
export default PreprodConfig;
