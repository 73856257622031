import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';

import './TemplateCrowdSourcing.css';
import Breadcrumbs from '../Breadcrumbs';
import TemplateApproveCard from './TemplateApproveCard';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function TemplateApproveSingle() {
	const { track } = useAppInsights();
	const { itemId, itemTypeId, complexityId, languageId } = useParams();
	const [templateContributions, setTemplateContributions] = useState([]);
	const [complexities, setComplexities] = useState({});
	const { axiosGetService } = useAxios();
	const [accepedtRejectedTemplate, setAccepedtRejectedTemplate] = useState([]);
	const [successMsg, setSuccessMsg] = useState(null);
	const [noAccess, setNoAccess] = useState(null);
	const [messagesType, setMessagesType] = useState({});

	const getContributions = async () => {
		setTemplateContributions([]);
		await axiosGetService(
			`api/sdmtemplate/${
				Number(itemTypeId) === 44
					? 'getapproverloctemplate'
					: 'getapprovertemplate'
			}/${itemId}/${Number(itemTypeId) === 44 ? languageId : complexityId}`
		).then((response) => {
			if (response.data !== null && response.data.length !== 0) {
				setTemplateContributions([response.data]);
				if (response.data && response.data.isApprover) {
					setNoAccess(false);
				} else {
					setNoAccess(true);
				}
				track(2, 157, itemId, response.data.title);
			} else {
				setTemplateContributions(null);
			}
			getComplexities();
		});
	};

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity')
			.then((res) => {
				const complexityObject = {};
				res.data.map((item) => {
					complexityObject[item.id] = item.name;
				});
				return complexityObject;
			})
			.then((complexityObject) => setComplexities(complexityObject));
	};

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=approvePrivilegeMessage&title=0`
		).then((response) => {
			message['approvePrivilegeMessage'] = response.data;
		});

		setMessagesType(message);
	};

	useEffect(() => {
		getMessage();
	}, []);

	useEffect(() => {
		if (successMsg) {
			setTimeout(() => {
				setSuccessMsg(null);
			}, 60000);
		}
	}, [successMsg]);

	useEffect(() => {
		getContributions();
	}, [itemId, complexityId]);

	return (
		<div className="templateCrowdSourcingMainContainer">
			<div className="templateCrowdSourcingBreadcrumb">
				<Breadcrumbs />
			</div>
			<div className="templateCrowdSourcingContainer">
				<div className="templateCrSourcingListWrapper">
					<div className="templateFixedHeaderPart">
						<div className="templateCrSourcingTitleWrapper">
							<h4>
								Suggested Templates <span>(Pending for approval)</span>
							</h4>
							{successMsg ? (
								<div className="approverSuccessMsg">
									<span className="successMsgIcon"></span>
									<span>{successMsg}</span>
								</div>
							) : null}
						</div>
						{noAccess === true ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								{messagesType['approvePrivilegeMessage']}
							</MessageBar>
						) : templateContributions === null ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								There are no templates for you to approve
							</MessageBar>
						) : (
							<div
								className="newTableParentRow header"
								id="templateCrowdSourcing"
							>
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment ">
												<div className="newColumn newTitle newActTd ">
													<span className="templateNewTitle">
														Template Name
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn templateComplexity newActTd ">
											Complexity
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Upload Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Review Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn details newActTd ">Details</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn approveAndReject newActTd ">
											Publish/Reject
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="listOfTemplateCrSourcing">
						{templateContributions &&
							noAccess === false &&
							templateContributions.map((contribution) => (
								<>
									{!accepedtRejectedTemplate.includes(contribution.id) ? (
										<TemplateApproveCard
											data={contribution}
											complexities={complexities}
											setAccepedtRejectedTemplate={setAccepedtRejectedTemplate}
											key={contribution.title}
											singleView={true}
											accepedtRejectedTemplate={accepedtRejectedTemplate}
											setSuccessMsg={setSuccessMsg}
										/>
									) : null}
								</>
							))}
						{noAccess === true ? (
							<></>
						) : (
							<Link
								className="approveAllNavigation"
								to="/template/crowdsourcing/approve/all"
							>
								Approve all template suggestions
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TemplateApproveSingle;
