const closeFullScreen = () => {
	if (document.msExitFullscreen) {
		document.msExitFullscreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.exitFullscreen) {
		document.exitFullscreen();
	}
};

const openFullScreen = (elem) => {
	if (elem.msRequestFullscreen) {
		/* IE/Edge */
		elem.msRequestFullscreen();
	} else if (elem.webkitRequestFullscreen) {
		/* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		/* Firefox */
		elem.mozRequestFullScreen();
	} else if (elem.requestFullscreen) {
		elem.requestFullscreen();
	}
	// } else if (elem.msRequestFullscreen) {
	//   /* IE/Edge */
	//   elem.msRequestFullscreen();
	// }
};

export { closeFullScreen, openFullScreen };
