import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import '../ActivityContributorCrowdSourcing';

function DiscardPopUp({ message }) {
	const [showModal, steShowModal] = useState(true);
	const searchComplexityParam = Number(queryString.parse(window.location.search).complexity);
	const activityIdentifier = queryString.parse(window.location.search).identifier;
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId } = useParams();

	const closeModal = () => {
		steShowModal(false);
	};

	return (
		<Modal
			className="activityDiscardPopUp"
			show={showModal}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{message}</p>
				<div className="discardPopUpActivityButtons">
					<Link
						to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier}?complexity=${searchComplexityParam}`}
					>
						<Button
							title="OK"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							className="activityDiscardPopUpAccept"
							variant="info"
						>
							OK
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
