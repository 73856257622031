import { hexToArrayBuffer, base64ToArrayBuffer, arrayBufferToString } from './cryptoUtils';
import { importKey, decryptData } from './cryptoService';

const stringToHex = (str) => {
  const bytes = new Uint8Array(new TextEncoder().encode(str));
  return Array.from(bytes).map(byte => byte.toString(16).padStart(2, '0')).join('');
};

const decryptAES256 = async (encryptedData, keyString) => {
  try {
    const ivString = keyString.slice(0, 16);

    const keyHex = stringToHex(keyString).padEnd(64, '0');
    const ivHex = stringToHex(ivString).padEnd(32, '0');



    const keyBuffer = hexToArrayBuffer(keyHex);
    const ivBuffer = hexToArrayBuffer(ivHex);
    const encryptedBuffer = base64ToArrayBuffer(encryptedData);

    

    const key = await importKey(keyBuffer);

    const decryptedBuffer = await decryptData(encryptedBuffer, key, ivBuffer);

    const decryptedText = arrayBufferToString(decryptedBuffer);
    return decryptedText;
  } catch (err) {
    console.error('Decryption error:', err);
    throw new Error(`Failed to decrypt data: ${err.message}`);
  }
};

export default decryptAES256;
