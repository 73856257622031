import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { saveAs } from 'file-saver';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import Spinner from '../../../helpers/Spinner';
import { useAppInsights } from '../../../context/TelemetryContext';

const ManageSwimlaneApprovals = ({
	usersDetail,
	getUsersDetails,
	swimlanes,
	setSwimlanes,
}) => {
	const [expandedSwimlanes, setExpandedSwimlanes] = useState([]);
	const { axiosGetService, axiosPostService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(true);
	const { track } = useAppInsights();
	const getSwimlanes = () => {
		setShowSpinner(true);
		axiosGetService(`api/sdmPhaseSwimlane/get`).then((response) => {
			setSwimlanes(response.data.sdmPhaseSwimlanes);
			for (const user of response.data.sdmPhaseSwimlanes) {
				getUsersDetails(user.uploadedEmail);
			}
			setShowSpinner(false);
		});
	};
	const toggleExpandedSwimlane = (swimlaneId) => {
		if (expandedSwimlanes.includes(swimlaneId)) {
			setExpandedSwimlanes(expandedSwimlanes.filter((id) => id !== swimlaneId));
		} else {
			setExpandedSwimlanes([...expandedSwimlanes, swimlaneId]);
		}
	};
	const dataURItoBlob = (dataURI) => {
		const byteString = atob(dataURI.split(',')[1]);
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	};
	const reviewSwimlane = (swimlane, reviewedStatus) => {
		const postBody = {
			...swimlane,
			reviewedStatus: reviewedStatus,
			reviewerComments: swimlane.comments,
		};
		if (!swimlane.comments && swimlane.reviewedStatus === 2) {
			toast.error('Please provide comments to uploader to improve', {
				position: 'top-right',
			});
			return;
		}
		setShowSpinner(true);
		axiosPostService(`api/sdmPhaseSwimlane/update`, postBody).then(
			(response) => {
				if (response.data.status) {
					toast.info('Reviewed Successfully', { position: 'top-right' });
					setSwimlanes(
						swimlanes.map((val) => (val.id !== swimlane.id ? val : postBody))
					);
				} else {
					toast.error(response.data.errorMsg, { position: 'top-right' });
				}
				setShowSpinner(false);
			}
		);
	};
	const updateSwimlaneProperty = (swimlaneIndex, propetyName, newValue) => {
		const newSwimlanes = [...swimlanes];
		newSwimlanes[swimlaneIndex][propetyName] = newValue;
		setSwimlanes(newSwimlanes);
	};
	useEffect(() => {
		getSwimlanes();
	}, []);
	return (
		<div class="middleDetailsContainer">
			<div class="methodOwnerMiddleContainer">
				<div class="methodPhaseDetailsTab">
					{showSpinner ? (
						<Spinner />
					) : (
						<div className="addRolesListContainer addDeliveryPlaybookContainer">
							<ul>
								<li>
									<div class="genericTableList">
										<div class="genericTableHeader">
											<div class="genericTableTitle">Solution Method</div>

											<div class="genericTablePhase">Phase</div>
											<div class="genericTableUpload">Uploaded by</div>
											<div class="genericTablePreview">Preview</div>

											<div class="genericTableDownload">Download</div>
											<div class="genericTableApproveSentBack">
												Approve/Send back
											</div>
										</div>
										<ul>
											{swimlanes.map((swimlane, index) => (
												<li>
													<div class="genericTableContainer">
														<div class="genericTableTitle">
															<span>{swimlane.solutionMethodName}</span>
														</div>

														<div class="genericTablePhase">
															<span>{swimlane.phaseName}</span>
														</div>
														<div class="genericTableUpload">
															<a
																target="_blank"
																href={`https://teams.microsoft.com/l/chat/0/0?users=${swimlane.uploadedEmail}`}
															>
																{usersDetail[swimlane.uploadedEmail] &&
																	usersDetail[swimlane.uploadedEmail]
																		.firstName +
																		' ' +
																		usersDetail[swimlane.uploadedEmail].surname}
															</a>
														</div>
														<div class="genericTablePreview">
															<span className="previewIconBlack"></span>

															<Icon
																iconName={
																	expandedSwimlanes.includes(swimlane.id)
																		? 'ChevronUp'
																		: 'ChevronDown'
																}
																onClick={() => {
																	toggleExpandedSwimlane(swimlane.id);
																}}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		toggleExpandedSwimlane(swimlane.id);
																	}
																}}
																aria-label={
																	expandedSwimlanes.includes(swimlane.id)
																		? 'Hide Swimlane Diagram'
																		: 'Show Swimlane Diagram'
																}
																role="button"
																tabIndex={0}
															/>
														</div>

														<div class="genericTableDownload">
															{swimlane.fileName.length !== 0 && (
																<span className="preview">
																	<Icon
																		iconName="ReleaseGate"
																		onClick={() =>
																			saveAs(
																				dataURItoBlob(swimlane.fileName),
																				swimlane.swimlaneFileName
																			)
																		}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				saveAs(
																					dataURItoBlob(swimlane.fileName),
																					swimlane.swimlaneFileName
																				);
																			}
																		}}
																		title="Download"
																		role="button"
																		tabIndex={0}
																		aria-label="Click to download swimlane"
																	/>
																</span>
															)}
														</div>
														<div class="genericTableApproveSentBack">
															{!swimlane.reviewedStatus ? (
																<>
																	<button
																		onClick={() => {
																			reviewSwimlane(swimlane, 1);
																			track(
																				1,
																				2006,
																				swimlane.fileName,
																				swimlane.id
																			);
																		}}
																	>
																		Approve
																	</button>
																	<button
																		onClick={() => {
																			updateSwimlaneProperty(
																				index,
																				'reviewedStatus',
																				2
																			);
																		}}
																	>
																		Send back
																	</button>
																</>
															) : swimlane.reviewedStatus === 1 ? (
																<span className="approved">Approved</span>
															) : (
																swimlane.reviewedStatus === 2 &&
																swimlane.reviewerComments && (
																	<span className="sentBack">Sent Back</span>
																)
															)}
														</div>
													</div>
													{expandedSwimlanes.includes(swimlane.id) && (
														<div className="SwimlanePreviewDiv">
															<img src={swimlane.fileName} />
														</div>
													)}
													{swimlane.reviewedStatus === 2 &&
														!swimlane.reviewerComments && (
															<div className="SwimlaneSentBackCommentsDiv">
																<div className="swimlaneApprovalComments">
																	<h6>
																		Please provide comments to uploader to
																		improve
																	</h6>
																	<textarea
																		value={swimlane.comments}
																		onChange={(e) => {
																			updateSwimlaneProperty(
																				index,
																				'comments',
																				e.target.value
																			);
																		}}
																	/>
																	<button
																		onClick={() => {
																			reviewSwimlane(swimlane, 2);
																			track(
																				1,
																				2007,
																				swimlane.fileName,
																				swimlane.id
																			);
																		}}
																	>
																		Send
																	</button>
																</div>
															</div>
														)}
												</li>
											))}
										</ul>
									</div>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ManageSwimlaneApprovals;
