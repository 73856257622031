import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const PhaseDeleteModal = ({
	showDeletePhaseModal,
	setShowDeletePhaseModal,
	setShowParentDeletePhaseModal,
	phaseId,
	solutionMethodId,
	projectTypeId,
	setDeletePhase,
	getSolutionMethodData,
	coOwners,
	isActivityCountZero,
	setShowPhaseDeletionMessage,
	phaseName,
	apiCalled,
	setApiCalled,
	type,
	parentId,
	getParentSolutionMethodData,
	selectedPhase,
	setSelectedPhase,
}) => {
	const { track } = useAppInsights();
	const { axiosDeleteService } = useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const history = useHistory();
	const deleteSelectedPhase = async () => {
		setApiCalled(true);
		if (selectedPhase.isRefered) {
			await axiosDeleteService(
				`api/sdmphase/unreferphase?solutionMethodId=${
					type === 'parentPhase' ? parentId : solutionMethodId
				}&phaseId=${phaseId}`
			).then((response) => {
				if (response.data.status) {
					if (type === 'parentPhase') {
						setShowParentDeletePhaseModal(false);
						getParentSolutionMethodData(parentId, coOwners);
					} else {
						setDeletePhase(false);
						getSolutionMethodData(coOwners, 'update');
					}
					setShowDeletePhaseModal(false);
					setShowPhaseDeletionMessage(true);
					track(1, 1707, phaseId, phaseName);
					history.push(`/project/${projectTypeId}/method/${solutionMethodId}`);
				} else {
					setErrorMessage(response.data.errorMessage);
				}
				setApiCalled(false);
			});
		} else {
			await axiosDeleteService(
				`api/sdmphase/delete/${phaseId}/${
					type === 'parentPhase' ? parentId : solutionMethodId
				}`
			).then((response) => {
				if (response.data.status) {
					if (type === 'parentPhase') {
						setShowParentDeletePhaseModal(false);
						getParentSolutionMethodData(parentId, coOwners);
					} else {
						setDeletePhase(false);
						getSolutionMethodData(coOwners, 'update');
					}
					setShowDeletePhaseModal(false);
					setShowPhaseDeletionMessage(true);
					track(1, 1706, phaseId, phaseName);
					history.push(`/project/${projectTypeId}/method/${solutionMethodId}`);
				} else {
					setErrorMessage(response.data.errorMessage);
				}
				setApiCalled(false);
			});
		}
	};

	return (
		<>
			{apiCalled ? (
				<Spinner />
			) : (
				<Modal
					className="activityDiscardPopUp"
					show={showDeletePhaseModal}
					onHide={() => {
						setShowDeletePhaseModal(false);
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						{isActivityCountZero ? (
							<>
								<p>
									Are you sure that you want to{' '}
									{selectedPhase.isRefered ? 'de-link' : 'delete'} "{phaseName}
									"?
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="Cancel"
										className="activityDiscardPopUpCancel"
										variant="primary"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (type === 'parentPhase') {
													setShowParentDeletePhaseModal(false);
												} else {
													setDeletePhase(false);
												}
												setShowDeletePhaseModal(false);
											}
										}}
										onClick={() => {
											if (type === 'parentPhase') {
												setShowParentDeletePhaseModal(false);
											} else {
												setDeletePhase(false);
											}
											setShowDeletePhaseModal(false);
										}}
									>
										Cancel
									</Button>

									<Button
										title="Delete"
										tabIndex={-1}
										onClick={() => {
											deleteSelectedPhase();
										}}
										disabled={apiCalled}
										className="activityDiscardPopUpAccept"
										variant="info"
									>
										{selectedPhase.isRefered ? 'De-link' : 'Delete'}
									</Button>
								</div>
							</>
						) : (
							<>
								<p>
									You have to delete the existing activities before deleting "
									{phaseName}"{' '}
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="OK"
										onClick={() => {
											if (type === 'parentPhase') {
												setShowParentDeletePhaseModal(false);
											} else {
												setDeletePhase(false);
											}

											setShowDeletePhaseModal(false);
										}}
										tabIndex={-1}
										className="activityDiscardPopUpAccept"
										variant="info"
									>
										OK
									</Button>
								</div>
							</>
						)}
						{errorMessage ? (
							<span className="errorMsg">{errorMessage}</span>
						) : (
							<></>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default PhaseDeleteModal;
