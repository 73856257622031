import React, { useState } from 'react';

import MyContributionsPreview from './MyContributionsPreview';
import HistoricalPreview from './MyApprovalsPreview';

import '../../MyReviews/Activity/ActivityMyReviews.css';

const ActivityMyApprovals = () => {
	const myReviewsTab = ['Historical Preview', 'Your Contributions'];
	const [activeTab, setActiveTab] = useState('Historical Preview');
	const artifact = {
		'Historical Preview': <HistoricalPreview />,
		'Your Contributions': <MyContributionsPreview />,
	};
	return (
		<div className="myContributionPreviewContainer">
			<div className="myContributionsRightContent">
				<div className="myContributionsContentMenuContainer">
					<ul className="myContributionsContentMenuTabs" id="myContributionsContentMenuTabsId">
						{myReviewsTab.map((tab) => (
							<li
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setActiveTab(tab);
									}
								}}
								onClick={() => setActiveTab(tab)}
								className={activeTab === tab ? 'activeMyContributionCrowdSourcingTab' : null}
							>
								{tab}
							</li>
						))}
					</ul>
				</div>
			</div>
			{artifact[activeTab]}
		</div>
	);
};

export default ActivityMyApprovals;
