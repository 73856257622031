import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { Icon } from '@fluentui/react';
import AdminEditSolutionMethod from '../SolutionMethod/SolutionMethod';

const ManageSolutionMethods = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { axiosGetService, axiosPostService } = useAxios();
	const { consultingSolutionMethods, supportSolutionMethods } =
		useLeftMenuCollapse();
	const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(0);
	const [showEditSolutionMethod, setShowEditSolutionMethod] = useState(false);
	const [selectedProjectTypeId, setSelectedProjectTypeId] = useState(0);
	const projectTypes = [
		{
			heading: 'Consulting',
			solutionMethods: consultingSolutionMethods,
			projectTypeId: 1,
		},
		{
			heading: 'Support',
			solutionMethods: supportSolutionMethods,
			projectTypeId: 2,
		},
	];
	if (showEditSolutionMethod) {
		return (
			<AdminEditSolutionMethod
				showEditSolutionMethodlogy={showEditSolutionMethod}
				setShowEditSolutionMethodlogy={setShowEditSolutionMethod}
				selectedSolutionMethod={selectedSolutionMethod}
				setSelectedSolutionMethod={setSelectedSolutionMethod}
				methods={
					selectedProjectTypeId === 1
						? selectedSolutionMethod
							? consultingSolutionMethods
							: [
									...consultingSolutionMethods,
									{
										solutionMethodId: 0,
										solutionMethodName: '',
										type: 'new',
									},
							  ]
						: selectedSolutionMethod
						? supportSolutionMethods
						: [
								...supportSolutionMethods,
								{
									solutionMethodId: 0,
									solutionMethodName: '',
									type: 'new',
								},
						  ]
				}
				isAdminMenu={true}
			/>
		);
	}
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Solution Methods
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Add and Edit Solution Methods</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div class="roleAdditionContainer ">
											<div
												class="addRoleButtonAction"
												onClick={() => setShowEditSolutionMethod(true)}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setShowEditSolutionMethod(true);
													}
												}}
												aria-label="Add New Solution Method"
												role="button"
												tabIndex={0}
											>
												<div class="selectAddRoleButton">
													<span>+&nbsp;Add New Solution Method</span>
												</div>
											</div>
										</div>
										<div className="addRolesListContainer addDeliveryPlaybookContainer AddEditManageSolMethod">
											{projectTypes.map(
												(projectType) =>
													projectType.solutionMethods.length > 0 && (
														<div
															className="adminMenuTileConatiner"
															key={projectType.heading}
														>
															<h6>{projectType.heading}</h6>
															<div className="adminMenuTiles">
																{projectType.solutionMethods.map(
																	(solutionMethod) => (
																		<div
																			className="menuTilePart"
																			onClick={() => {
																				setSelectedProjectTypeId(
																					projectType.projectTypeId
																				);
																				setSelectedSolutionMethod(
																					solutionMethod.solutionMethodId
																				);
																				setShowEditSolutionMethod(true);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					setSelectedProjectTypeId(
																						projectType.projectTypeId
																					);
																					setSelectedSolutionMethod(
																						solutionMethod.solutionMethodId
																					);
																					setShowEditSolutionMethod(true);
																				}
																			}}
																			aria-label={
																				solutionMethod.solutionMethodName
																			}
																			role="button"
																			tabIndex={0}
																			key={solutionMethod.solutionMethodName}
																		>
																			<span className="adminMenuIcon"></span>
																			<h6>
																				{solutionMethod.solutionMethodName}
																			</h6>
																			<Icon iconName="ChevronRight" />
																		</div>
																	)
																)}
															</div>
														</div>
													)
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageSolutionMethods;
