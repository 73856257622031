import React from 'react';
import RaciType from './RaciType';

const ActivityRaciMatrix = ({ activityData }) => {
	return (
		<div className="ractMatrix">
			<div id="containerId" className="activityRaciRoleGroupContainer row">
				<RaciType
					label="Responsible Role"
					rolesData={activityData.responsibleList}
					id="previewResponsibleRole"
				/>
				<RaciType
					label="Accountable Role"
					rolesData={activityData.accountableList}
					id="previewAccountableRole"
				/>
				<RaciType
					label="Consulted Role"
					rolesData={activityData.consultedList}
					id="previewConsultedRole"
				/>
				<RaciType
					label="Informed Role"
					rolesData={activityData.informedList}
					id="previewInformedRole"
				/>
			</div>
			<div className="raciMsg">
				<span>
					**The RACI here depicts the ideal state where all roles are staffed on
					the project/engagement. In scenarios where the particular role is not
					staffed, the person playing the role on the project/engagement is
					expected to perform the activity.
				</span>
			</div>
		</div>
	);
};

export default ActivityRaciMatrix;
