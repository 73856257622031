import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import './MyAchievements.css';

function MyAchievementsLeftMenu({
	expandMyAchievementsLeftMenu,
	setExpandMyAchievementsLeftMenu,
}) {
	const location = window.location;
	const { url } = useRouteMatch();
	const [selectedTab, setSelectedTab] = useState(null);
	const [tabDetails, setTabDetails] = useState([
		{
			id: 1,
			name: 'Me',
			className: 'topicBulbIcon',
			selectedClassName: 'topicBulbIconOrange',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Group_9758" data-name="Group 9758" transform="translate(5188 -2033)">
              <rect id="Rectangle_1033" data-name="Rectangle 1033" width="23" height="23" transform="translate(-5188 2033)" fill="#fff" opacity="0"/>
              <path id="Icon_awesome-user-circle" data-name="Icon awesome-user-circle" d="M9.063.563a9.063,9.063,0,1,0,9.063,9.063A9.061,9.061,0,0,0,9.063.563Zm0,3.508A3.216,3.216,0,1,1,5.847,7.287,3.216,3.216,0,0,1,9.063,4.071Zm0,12.571a7,7,0,0,1-5.354-2.492,4.074,4.074,0,0,1,3.6-2.185.894.894,0,0,1,.259.04,4.839,4.839,0,0,0,1.495.252A4.82,4.82,0,0,0,10.558,12a.894.894,0,0,1,.259-.04,4.074,4.074,0,0,1,3.6,2.185A7,7,0,0,1,9.063,16.642Z" transform="translate(-5186 2034.438)" fill="#a5a4bf"/>
            </g>
		  </svg>`,
			url: url,
		},
		{
			id: 2,
			name: 'Dashboard',
			className: 'topicProceduresIcon',
			selectedClassName: 'topicProceduresIconOrange',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Group_9756" data-name="Group 9756" transform="translate(5191 -2062)">
              <rect id="Rectangle_1032" data-name="Rectangle 1032" width="23" height="23" transform="translate(-5191 2062)" fill="#fff" opacity="0"/>
              <path id="Icon_metro-meter" data-name="Icon metro-meter" d="M12.153,3.856a9.583,9.583,0,0,1,4.641,17.966H7.512A9.583,9.583,0,0,1,12.153,3.856Zm5.426,15.008a7.623,7.623,0,0,0,2.247-5.426H18.142v-1.2h1.591a7.59,7.59,0,0,0-.8-2.4H16.944v-1.2h1.2a7.8,7.8,0,0,0-.568-.635,7.64,7.64,0,0,0-3.03-1.868v1.3h-1.2V5.858a7.775,7.775,0,0,0-2.4,0V7.449h-1.2v-1.3a7.64,7.64,0,0,0-3.03,1.868,7.8,7.8,0,0,0-.568.635h1.2v1.2H5.369a7.589,7.589,0,0,0-.8,2.4H6.164v1.2H4.48a7.623,7.623,0,0,0,2.247,5.426,7.8,7.8,0,0,0,.628.563h3.6l.684-9.582h1.027l.684,9.582h3.6a7.805,7.805,0,0,0,.628-.563Z" transform="translate(-5191.571 2061.144)" fill="#a5a4bf"/>
            </g>
		  </svg>`,
			url: url + '/dashboard',
		},
		{
			id: 3,
			name: 'Community',
			className: 'topicProceduresIcon',
			selectedClassName: 'topicProceduresIconOrange',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Group_9754" data-name="Group 9754" transform="translate(5191 -2089)">
              <rect id="Rectangle_1030" data-name="Rectangle 1030" width="23" height="23" transform="translate(-5191 2089)" fill="#fff" opacity="0"/>
              <path id="Icon_material-group-work" data-name="Icon material-group-work" d="M12.439,3a9.439,9.439,0,1,0,9.439,9.439A9.442,9.442,0,0,0,12.439,3ZM8.663,17.63a2.36,2.36,0,1,1,2.36-2.36A2.361,2.361,0,0,1,8.663,17.63Zm1.416-8.967a2.36,2.36,0,1,1,2.36,2.36A2.361,2.361,0,0,1,10.079,8.663Zm6.135,8.967a2.36,2.36,0,1,1,2.36-2.36A2.361,2.361,0,0,1,16.215,17.63Z" transform="translate(-5192 2088)" fill="#a5a4bf"/>
            </g>
          </svg>`,
			url: url + '/community',
		},
		{
			id: 4,
			name: 'Badges',
			className: 'topicProceduresIcon',
			selectedClassName: 'topicProceduresIconOrange',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23.142" viewBox="0 0 23 23.142">
            <g id="Group_9757" data-name="Group 9757" transform="translate(5191 -2122)">
              <rect id="Rectangle_1031" data-name="Rectangle 1031" width="23" height="23" transform="translate(-5191 2122)" fill="#fff" opacity="0"/>
              <path id="Icon_open-badge" data-name="Icon open-badge" d="M5.786,0a5.786,5.786,0,1,0,5.786,5.786A5.8,5.8,0,0,0,5.786,0ZM2.893,13.914v9.228l2.893-2.893,2.893,2.893V13.914a8.708,8.708,0,0,1-2.893.55A8.708,8.708,0,0,1,2.893,13.914Z" transform="translate(-5185 2122)" fill="#a5a4bf"/>
            </g>
		  </svg>`,
			url: url + '/badges',
		},
	]);

	useEffect(() => {
		setSelectedTab(location.pathname);
	}, [location]);

	return (
		<div
			className={
				!expandMyAchievementsLeftMenu
					? 'myAchievementsLeftMenuContainer'
					: 'myAchievementsLeftMenuContainer expanded'
			}
		>
			<ul>
				{tabDetails.map((tab) => (
					<li
						key={tab.id}
						className={
							selectedTab === tab.url ? 'selectedMyAchievementTab' : ''
						}
						onClick={() => {
							setSelectedTab(tab.url);
						}}
					>
						<Link to={tab.url} className="NavLink">
							<div
								className="myAchievementsTypeIcon"
								dangerouslySetInnerHTML={{ __html: tab.icon }}
							></div>
							{expandMyAchievementsLeftMenu ? <span>{tab.name}</span> : null}
						</Link>
					</li>
				))}
			</ul>
			<div className="myAchievementsLeftMenuIcon">
				<Icon
					iconName={
						expandMyAchievementsLeftMenu
							? 'DoubleChevronLeftMed'
							: 'DoubleChevronLeftMedMirrored'
					}
					onClick={() => {
						setExpandMyAchievementsLeftMenu(!expandMyAchievementsLeftMenu);
					}}
				/>
			</div>
		</div>
	);
}

export default MyAchievementsLeftMenu;
