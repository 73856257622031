import React from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function PolygonResponseModal({ showResponseModal, setShowResponseModal, message, setSavedTopic, savedTopic }) {
	const closeModal = () => {
		setShowResponseModal(false);
		setSavedTopic(!savedTopic);
	};

	return (
		<Modal
			className="activityDiscardPopUp"
			show={true}
			onHide={() => {
				closeModal();
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{message}</p>
				<div className="discardPopUpActivityButtons">
                    <Button 
                        title="Yes" 
                        tabIndex={-1} 
                        className="activityDiscardPopUpAccept" 
                        onClick={() => {
                            setShowResponseModal(false);
							setSavedTopic(!savedTopic);
                        }} 
                        variant="info"
                    >
                        OK
                    </Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default PolygonResponseModal;
