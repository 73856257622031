import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Icon } from '@fluentui/react/lib/Icon';

import FeedbackModal from './FeedbackModal';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import './FavoriteAndFeedback.css';

function FavoriteAndFeedback() {
	const { axiosGetService } = useAxios();
	const [showSharePointBanner, setShowSharePointBanner] = useState(false);
	const getBannerStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=ShowHomePageBanner`).then(
			(response) => {				
				if(response.data[0].value === 'true'){
					setShowSharePointBanner(true);
					}
			}
		);
	};

	useEffect(() => {
		getBannerStatus();
		if(showSharePointBanner){
		$('.feedbackComps').css('margin-top', '2%');
		}
	}, [showSharePointBanner]);
	const location = window.location.pathname + window.location.search;
	const { axiosPostService } = useAxios();
	const [enableFavorite, setEnableFavorite] = useState(false);
	const [enableFeedbackModal, setEnableFeedbackModal] = useState(false);
	const { favorites, breadcrumbs, getFavorites } = useLeftMenuCollapse();
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	const handleToggleClass = () => {
		setShowFeedbackModal(!showFeedbackModal);
		$('#feedbackComp').toggleClass('expand  collapsed');
		$('.handle').toggleClass('handlebrdShadowinside');
	};

	const handleFavorite = async () => {
		if (enableFavorite) {
			const body = {
				url: location,
			};
			await axiosPostService('/api/sdmfavorite/delete', body).then(
				(response) => {
					setEnableFavorite(false);
					getFavorites();
				}
			);
		} else {
			const [artifact] = breadcrumbs.slice(-1);
			const body = {
				url: location,
				title: artifact.title,
			};
			await axiosPostService('/api/sdmfavorite/insert', body).then(
				(response) => {
					setEnableFavorite(true);
					getFavorites();
				}
			);
		}
	};

	const onLoad = () => {
		const artifact = favorites.filter((item) => item.url === location);
		if (artifact.length === 0) {
			setEnableFavorite(false);
		} else {
			setEnableFavorite(true);
		}
	};

	useEffect(() => {
		if (favorites !== null) {
			onLoad();
		}
	}, [favorites, location]);
	useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && showFeedbackModal) {
                handleToggleClass();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showFeedbackModal]);

	return (
		<div id="feedbackComp" className="feedbackComps">
			<div
				id='feedbackCompId'
				className="handle handlebrdShadowoutside"
				tabIndex={-1}
				onKeyPress={(e) => {
					if (e.which === 13) {
						handleToggleClass();
					}
				}}
				onClick={handleToggleClass}
				aria-label="Click here to Expand feedback"
				role="button"
				aria-expanded={showFeedbackModal ? 'true' : 'false'}
				title="FeedBack"
			>
				<div className="feedbackIcon">
					<span className="globalFeedbackThumbUp"></span>
					<span className="filledStars">
						<Icon iconName="FavoriteStarFill" />
						<Icon iconName="FavoriteStarFill" />
						<Icon iconName="FavoriteStarFill" />
					</span>
				</div>
				<div
					className="crossIcon"
					role="button"
					aria-label="click to collapse feedback menu"
				>
					<Icon iconName="Cancel" />
				</div>
			</div>

			{showFeedbackModal ? (
				<div className="feedbackAndFavoriteDiv">
					<div
						className="feedbackDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setEnableFeedbackModal(true);
							}
						}}
						onClick={() => setEnableFeedbackModal(true)}
						aria-label="provide Feedback"
						role="button"
					>
						<span className="globalFeedbackThumbUp"></span>
						<span>Feedback</span>
					</div>
					<div
						className="globalFavoriteDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handleFavorite();
							}
						}}
						onClick={handleFavorite}
						aria-label={!enableFavorite ? 'Favorite Page' : '	unfavorite page'}
						role="button"
					>
						{!enableFavorite ? (
							<span className="globalFavoriteOutlineIcon" />
						) : (
							<span className="globalFavoriteIcon"></span>
						)}
						<span>Favorite</span>
					</div>
				</div>
			) : null}
			{enableFeedbackModal ? (
				<FeedbackModal
					enableFeedbackModal={enableFeedbackModal}
					closeModal={() => setEnableFeedbackModal(false)}
				/>
			) : (
				<></>
			)}
		</div>
	);
}

export default FavoriteAndFeedback;
