import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { useAxios } from '../../context/AxiosContext';

const GroupActualDeleteModal = ({
    showActualDeleteGrpModal,
    setShowActualDeleteGrpModal,
    setPhaseCreated,
    phaseCreated,
    selectedDeleteGroupDetails
}) => {
    const { axiosDeleteService } = useAxios();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const errMessage = 'Not able to delete the Group. Please contact administrator.'

    const deleteGroup = async () => {
        setShowSpinner(true)
        await axiosDeleteService(
            `api/sdmactivity/deletegroup/${selectedDeleteGroupDetails.groupId}` 
        ).then((response) => {
            if (response.data.status) {
                setShowSpinner(false)
                setSuccessMessage(true)
            } else {
                setShowSpinner(false)
                setErrorMessage(true);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 5000);
            }
        }).catch((err)=>{
            setShowSpinner(false)
            console.log(err)
        })

    };

    return (
        <>{showSpinner ? <Spinner></Spinner> :
            (
                <Modal
                    className="deletePopup"
                    show={showActualDeleteGrpModal}
                    onHide={() => {
                        setShowActualDeleteGrpModal(false);
                        setPhaseCreated(!phaseCreated);
                    }}
                    size="md"
                    centered
                >
                    <Modal.Header>
                        Delete {selectedDeleteGroupDetails.groupName}
                        <Icon
                            onClick={() => {
                                setShowActualDeleteGrpModal(false);
                                setPhaseCreated(!phaseCreated);
                            }}
                            iconName="Cancel"
                        />
                    </Modal.Header>

                    <Modal.Body>
                        <div className="deletePopupContainer">
                            {successMessage ? (
                                <Alert variant="success">
                                    You have successfully deleted the {selectedDeleteGroupDetails.groupName} group.
                                </Alert>
                            ) : (
                                <><p>
                                    Are you sure you want to delete the {selectedDeleteGroupDetails.groupName} group?
                                </p>
                                    <div className="deletePopupButton">
                                        <Button
                                            className="templateRejectButton"
                                            onClick={() => {
                                                setShowActualDeleteGrpModal(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                deleteGroup();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                    {errorMessage ? (
                                        <span className="errorMsg">{errMessage}</span>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default GroupActualDeleteModal;
