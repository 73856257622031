import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useAxios } from '../../../context/AxiosContext';
import { saveAs } from 'file-saver';
import Spinner from '../../../helpers/Spinner';
import MultiSelectDropdownWithAll from '../MultiSelectDropdownWithAll';

const ManageSwimlaneHistory = ({
	usersDetail,
	getUsersDetails,
	swimlanes,
	setSwimlanes,
}) => {
	const [expandedSwimlanes, setExpandedSwimlanes] = useState([]);
	const [expandedComments, setExpandedComments] = useState([]);
	const { axiosGetService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(true);
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		},
		{
			id: 2,
			name: 'Support',
		},
	];
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([]);
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [phases, setPhases] = useState([]);
	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);
	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};
	const getSwimlanes = () => {
		setShowSpinner(true);
		axiosGetService(
			`api/sdmPhaseSwimlane/getReviewHistory?projectTypeIds=${
				selectedProjectType.length > 0
					? selectedProjectType.map((type) => type.id).toString()
					: 0
			}&methodIds=${
				selectedSolutionMethods.length > 0
					? selectedSolutionMethods.map((method) => method.id).toString()
					: 0
			}&phaseIds=${
				selectedPhases.length > 0
					? selectedPhases.map((phase) => phase.id).toString()
					: 0
			}`
		).then((response) => {
			setSwimlanes(response.data);
			for (const user of response.data) {
				getUsersDetails(user.uploadedEmail);
			}
			setShowSpinner(false);
		});
	};
	const toggleExpandedSwimlane = (swimlaneId) => {
		if (expandedSwimlanes.includes(swimlaneId)) {
			setExpandedSwimlanes(expandedSwimlanes.filter((id) => id !== swimlaneId));
		} else {
			setExpandedSwimlanes([...expandedSwimlanes, swimlaneId]);
		}
	};
	const toggleExpandedComments = (swimlaneId) => {
		if (expandedComments.includes(swimlaneId)) {
			setExpandedComments(expandedComments.filter((id) => id !== swimlaneId));
		} else {
			setExpandedComments([...expandedComments, swimlaneId]);
		}
	};
	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};
	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=0&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			const data = response.data.phases.map((item) => {
				return {
					name: item.phaseName,
					id: item.phaseId,
				};
			});
			const filterTag = selectedPhases.filter(({ id }) =>
				findSelectedItem(data, id)
			);
			if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
				setSelectedPhases(filterTag);
			}
			setPhases(data);
		});
	};
	const dataURItoBlob = (dataURI) => {
		const byteString = atob(dataURI.split(',')[1]);
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	};
	useEffect(() => {
		getSwimlanes();
	}, [selectedProjectType, selectedSolutionMethods, selectedPhases]);
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedSolutionMethods]);

	return (
		<div className="middleDetailsContainer">
			<div className="methodOwnerMiddleContainer">
				<div className="methodPhaseDetailsTab">
					<div className="addRolesListContainer addDeliveryPlaybookContainer">
						<div className="phaseSwimlaneFilters">
							<div className='hidingConsultingSupport'>
								<div title="activityReferProjectSelectionDropdown">
									<MultiSelectDropdownWithAll
										id="projectTypes"
										className="activityReferProjectSelectionDropdown"
										data={projectTypeData}
										value={selectedProjectType}
										setValue={setSelectedProjectType}
										label="Project Type"
										enableDropdown={true}
									/>
								</div>
							</div>
							<div className="multiSelectdropDown CloseMultiSelectDp">
								<div title="activityReferProjectSelectionDropdown">
									<MultiSelectDropdownWithAll
										id="solutionMethods"
										className="activityReferSolutionMethodDropdown "
										data={solutionMethods}
										value={selectedSolutionMethods}
										setValue={setSelectedSolutionMethods}
										label="Solution Methods"
										enableDropdown={true}
									/>
								</div>
							</div>
							<div className="multiSelectdropDown CloseMultiSelectDp">
								<div title="activityReferProjectSelectionDropdown">
									<div className="dropDowncontainer activityReferProjectSelectionDropdown ">
										<MultiSelectDropdownWithAll
											id="phases"
											className="activityReferSolutionMethodphasesDropdown"
											data={phases}
											value={selectedPhases}
											setValue={setSelectedPhases}
											label="Phases"
											enableDropdown={true}
										/>
									</div>
								</div>
							</div>
						</div>
						<ul className="phaseSwimlaneHistory">
							<li>
								<div className="genericTableList">
									<div className="genericTableHeader">
										<div className="genericTablePhase">Date</div>
										<div className="genericTableUpload">Uploaded by</div>
										<div className="genericTablePreview">Preview</div>
										<div className="genericTableDownload">Download</div>
										<div className="genericTableApproveSentBack">Status</div>
									</div>
									{showSpinner ? (
										<Spinner />
									) : (
										<ul>
											{swimlanes.map((swimlane) => (
												<li>
													<div className="genericTableContainer">
														<div className="genericTablePhase">
															<span>{swimlane.reviewedDate}</span>
														</div>
														<div className="genericTableUpload">
															<a
																target="_blank"
																href={`https://teams.microsoft.com/l/chat/0/0?users=${swimlane.uploadedEmail}`}
															>
																{usersDetail[swimlane.uploadedEmail] &&
																	usersDetail[swimlane.uploadedEmail]
																		.firstName +
																		' ' +
																		usersDetail[swimlane.uploadedEmail].surname}
															</a>
														</div>
														<div className="genericTablePreview">
															<span className="previewIconBlack"></span>
															<Icon
																iconName={
																	expandedSwimlanes.includes(swimlane.id)
																		? 'ChevronUp'
																		: 'ChevronDown'
																}
																onClick={() => {
																	toggleExpandedSwimlane(swimlane.id);
																}}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		toggleExpandedSwimlane(swimlane.id);
																	}
																}}
																aria-label={
																	expandedSwimlanes.includes(swimlane.id)
																		? 'Hide Swimlane Diagram'
																		: 'Show Swimlane Diagram'
																}
																role="button"
																tabIndex={0}
															/>
														</div>
														<div className="genericTableDownload">
															{swimlane.fileName.length !== 0 && (
																<span className="preview">
																	<Icon
																		iconName="ReleaseGate"
																		onClick={() =>
																			saveAs(
																				dataURItoBlob(swimlane.fileName),
																				swimlane.swimlaneFileName
																			)
																		}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				saveAs(
																					dataURItoBlob(swimlane.fileName),
																					swimlane.swimlaneFileName
																				);
																			}
																		}}
																		role="button"
																		tabIndex={0}
																		aria-label="Click to download Swimlane"
																	/>
																</span>
															)}
														</div>
														<div className="genericTableApproveSentBack">
															{swimlane.reviewedStatus === 1 ? (
																<span className="approved">Approved</span>
															) : (
																<>
																	<span className="sentBack">Sent Back</span>
																	<div>
																		<span>Comments</span>
																		<Icon
																			iconName={
																				expandedComments.includes(swimlane.id)
																					? 'ChevronUp'
																					: 'ChevronDown'
																			}
																			onClick={() => {
																				toggleExpandedComments(swimlane.id);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					toggleExpandedComments(swimlane.id);
																				}
																			}}
																			aria-label={
																				expandedComments.includes(swimlane.id)
																					? 'Hide Comments'
																					: 'Show Comments'
																			}
																			role="button"
																			tabIndex={0}
																		/>
																	</div>
																</>
															)}
														</div>
													</div>
													{expandedSwimlanes.includes(swimlane.id) && (
														<div className="SwimlanePreviewDiv">
															<img src={swimlane.fileName} />
														</div>
													)}
													{expandedComments.includes(swimlane.id) && (
														<div className="SwimlaneSentBackCommentsDiv">
															<span>{swimlane.reviewerComments}</span>
														</div>
													)}
												</li>
											))}
										</ul>
									)}
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageSwimlaneHistory;
