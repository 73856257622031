import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { getOtherUserProfile } from '../../../helpers/GraphService';
import { getUsersList } from '../../../helpers/GraphService';
import { useAxios } from '../../../context/AxiosContext';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import { Spinner } from 'react-bootstrap';
import UserSelectionDropdown from '../PhaseAddition/UserSelectionDropdown';

import './MethodOwnerCollaborator.css';

const MethodOwnerCollaborator = ({
	showPhaseMethoOwnerModal,
	setShowPhaseMethoOwnerModal,
	solutionMethodId,
	type,
	parentId,
	isSolutionCollaboratorsEdited,
	setIsSolutionCollaboratorsEdited,
	setExistingCollaboratorsApprovers,
	setExistingCollaboratorsCoOwners,
	setExistingCollaboratorsReviewers
}) => {
	const { axiosGetService, axiosPostService } = useAxios();
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [showSpinner, setshowSpinner] = useState(true);
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
	const [owners, setOwners] = useState('');
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
	});
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});

	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
	});
	const [apiCalled, setApiCalled] = useState(false);
	const [previousPostData, setPreviousPostData] = useState({});

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	const getUsersFromIdWeb = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const getUser = async (userEmail, setValue) => {
		setshowSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setshowSpinner(false);
		}, 1000);
	};

	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	};

	const getOwnerInfo = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue(response);
		});
	};

	const getCollaborators = async () => {
		await axiosGetService(
			`api/sdmsolutionmethod/getCollaborators/${
				type === 'parentPhase' ? parentId : solutionMethodId
			}`
		).then((response) => {
			getCollaboratorsInfo(
				response.data.coOwnerMembersList,
				setExistingCoOwners
			);
			getCollaboratorsInfo(
				response.data.reviewerMembersList,
				setExistingReviewers
			);
			getCollaboratorsInfo(
				response.data.approverMembersList,
				setExistingApprovers
			);
			setPreviousPostData({
				coOwnerMembersList: response.data.coOwnerMembersList.sort(),
				approverMembersList: response.data.approverMembersList.sort(),
				reviewerMembersList: response.data.reviewerMembersList.sort(),
			});
			if (response.data.methodOwner) {
				getOwnerInfo(response.data.methodOwner, setOwners);
			}
			if (
				response.data.coOwnerMembersList.length === 0 &&
				response.data.reviewerMembersList.length === 0 &&
				response.data.approverMembersList.length === 0
			) {
				setshowSpinner(false);
			}
		});
	};
	const handleApproverState = (owner) => {
		// Check if user already exists in approvers list
		if (!approvers.some((item) => item.mail === owner.mail)) {
			  setApprovers((prevApprovers) => [...prevApprovers, owner]);
		}
	  }; 

	const saveDetails = async () => {
		const coOwnersEmails = coOwners.map(({ mail }) => {
			return mail;
		});
		const reviewersEmails = reviewers.map(({ mail }) => {
			return mail;
		});
		const approversEmails = approvers.map(({ mail }) => {
			return mail;
		});

		const postData = {
			coOwnerMembersList: coOwnersEmails.sort(),
			approverMembersList: approversEmails.sort(),
			reviewerMembersList: reviewersEmails.sort(),
			solutionMethodId: type === 'parentPhase' ? parentId : solutionMethodId,
		};

		if (JSON.stringify(previousPostData) !== JSON.stringify(postData)) {
			setApiCalled(true);
			await axiosPostService(
				`api/sdmsolutionmethod/savecollaborations`,
				postData
			).then((response) => {
				if (response.data.status) {
					setExistingCollaboratorsApprovers([])
	                setExistingCollaboratorsCoOwners([])
	                setExistingCollaboratorsReviewers([])
	                setIsSolutionCollaboratorsEdited(!isSolutionCollaboratorsEdited)
					setShowPhaseMethoOwnerModal(false);
				} else {
					toast.error(response.data.errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
				setApiCalled(false);
			});
		} else {
			setShowPhaseMethoOwnerModal(false);
		}
	};

	useEffect(() => {
		getCollaborators();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getUsersFromIdWeb(coOwnerEmail, 'coOwner');
			} else {
				setError({ ...error, ['coOwner']: false });
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUsersFromIdWeb(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUsersFromIdWeb(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		setCoOwners(existingCoOwners);
	}, [existingCoOwners]);

	useEffect(() => {
		setReviewers(existingReviewers);
	}, [existingReviewers]);

	useEffect(() => {
		setApprovers(existingApprovers);
	}, [existingApprovers]);

	return (
		<Modal
			className="phaseCreationRightPopup methodOwnerCollaborator"
			show={showPhaseMethoOwnerModal}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<div className="methodOwnerCollaboratorTitle">
					<Modal.Title>Collaborators</Modal.Title>
					<Icon
						iconName="ChromeClose"
						title="Close"
						onClick={() => setShowPhaseMethoOwnerModal(false)}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowPhaseMethoOwnerModal(false);
							}
						}}
						tabIndex={0}
						role="button"
						aria-label="Close panel"
					/>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="CollaboratorContainer">
				<div id="collaboratorId"className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="middleDetailsContainer">
								<div className="methodOwnerMiddleContainer">
									<div id="methodPhaseCollabaratorsTabId" className="methodPhaseCollabaratorsTab">
										{showSpinner ? (
											<div className="spinner">
												<Spinner animation="border" />
											</div>
										) : (
											<div className="phaseCollabaratorsFields">
												<div className="collabaratorsMainContainers">
													<div className="collabaratorsParentContainers">
														<h6>Add co-owners <span className="red-asterisk">*</span></h6>
														<UserSelectionDropdown
															className="coOwner"
															enableDropdown={true}
															data={usersList}
															value={coOwners}
															setValue={setCoOwners}
															updateApprovers={handleApproverState}
															type="coOwner"
															searchValue={coOwnerEmail}
															setSearchValue={setCoOwnerEmail}
															error={error}
															emailFound={emailFound['coOwner']}
														/>
													</div>

													{coOwners.length > 0 && (
														<div className="collabaratorsChildContainers">
															<ul>
																{coOwners.map((user) => (
																	<li key={user.mail}>
																		<span className="collabaratorPersonaImage">
																			<img src={user.photo} alt="img" />
																		</span>
																		<span className="collabaratorPersonaTitle">
																			{user.firstName + ' ' + user.surname}
																		</span>
																		<span>
																			<Icon
																				iconName="Cancel"
																				onClick={() => {
																					removeCoOwner(
																						user.mail,
																						coOwners,
																						setCoOwners
																					);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						removeCoOwner(
																							user.mail,
																							coOwners,
																							setCoOwners
																						);
																					}
																				}}
																				role="button"
																				tabIndex={0}
																				aria-label={`Remove ${user.firstName} ${user.surname}`}
																				title="Remove CoOwner"
																			/>
																		</span>
																	</li>
																))}
															</ul>
														</div>
													)}
												</div>

												<div className="collabaratorsMainContainers">
													<div className="collabaratorsParentContainers">
														<h6>Add Approvers <span className="red-asterisk">*</span></h6>
														<UserSelectionDropdown
															className="approver"
															enableDropdown={true}
															data={usersList}
															value={approvers}
															setValue={setApprovers}
															type="approver"
															searchValue={approverEmail}
															setSearchValue={setApproverEmail}
															error={error}
															emailFound={emailFound['approver']}
														/>
													</div>

													{approvers.length > 0 && (
														<div className="collabaratorsChildContainers">
															<ul>
																{approvers.map((user) => (
																	<li key={user.mail}>
																		<span className="collabaratorPersonaImage">
																			<img src={user.photo} alt="img" />
																		</span>
																		<span className="collabaratorPersonaTitle">
																			{user.firstName + ' ' + user.surname}
																		</span>
																		<span>
																			<Icon
																			 hidden={ (owners?.mail === user.mail)}
																				iconName="Cancel"
																				onClick={() => {
																					removeCoOwner(
																						user.mail,
																						approvers,
																						setApprovers
																					);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						removeCoOwner(
																							user.mail,
																							approvers,
																							setApprovers
																						);
																					}
																				}}
																				role="button"
																				tabIndex={0}
																				aria-label={`Remove ${user.firstName} ${user.surname}`}
																				title="Remove approver"
																			/>
																		</span>
																	</li>
																))}
															</ul>
														</div>
													)}
												</div>

												<div className="collabaratorsMainContainers">
													<div className="collabaratorsParentContainers">
														<h6>Add Reviewers <span className="red-asterisk">*</span></h6>
														<UserSelectionDropdown
															className="reviewer"
															enableDropdown={true}
															data={usersList}
															value={reviewers}
															setValue={setReviewers}
															type="reviewer"
															searchValue={reviewerEmail}
															setSearchValue={setReviewerEmail}
															error={error}
															emailFound={emailFound['reviewer']}
														/>
													</div>

													{reviewers.length > 0 && (
														<div className="collabaratorsChildContainers">
															<ul>
																{reviewers.map((user) => (
																	<li key={user.mail}>
																		<span className="collabaratorPersonaImage">
																			<img src={user.photo} alt="img" />
																		</span>
																		<span className="collabaratorPersonaTitle">
																			{user.firstName + ' ' + user.surname}
																		</span>
																		<span>
																			<Icon
																				iconName="Cancel"
																				onClick={() => {
																					removeCoOwner(
																						user.mail,
																						reviewers,
																						setReviewers
																					);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						removeCoOwner(
																							user.mail,
																							reviewers,
																							setReviewers
																						);
																					}
																				}}
																				role="button"
																				tabIndex={0}
																				aria-label={`Remove ${user.firstName} ${user.surname}`}
																				title="Remove reviewer"
																			/>
																		</span>
																	</li>
																))}
															</ul>
														</div>
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<div id="saveContinueCollaboratorId" className="saveContinueDetailsContainer">
								<button
									className="forwardButton"
									disabled={apiCalled || coOwners.length == 0 || reviewers.length == 0 || approvers.length == 0}
									onClick={() => saveDetails()}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveDetails();
										}
									}}
								>
									<span className="saveContinueButton">Save</span>
								</button>
							</div></div>
			</Modal.Body>
		</Modal>
	);
};

export default MethodOwnerCollaborator;
