import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavBar from "../Navbar";
import Topics from "../Topics";
import PageContent from "../PageContent";
import SearchResultPage from "../SearchResultPage";
import TemplateLanding from "../TemplateLanding";
import ReAuthenticatePopUp from "../ReAuthenticatePopUp";
import MethodologyGuidelines from "../MethodologyGuidelines";
import KnownIssue from "../../views/KnownIssue";
import TemplateCrowdSourcing from "../TemplateCrowdSourcing";
import MyContributions from "../../views/MyContributions";
import { UserAuthProvider, useAuth } from "../../context/UserAuthContext";
import { LeftMenuCollapseProvider } from "../../context/LeftMenuCollapseContext";
import $ from 'jquery';
import { Link } from "office-ui-fabric-react";
import {
  TelemetryProvider,
  useAppInsights,
} from "../../context/TelemetryContext";
import { ConfigProvider } from "../../context/ConfigContext";
import MsalInstanceProvider from "../../context/MsalInstanceProvider";
import { AxiosProvider, useAxios } from "../../context/AxiosContext";
import { ReAuthProvider } from "../../context/ReAuthContext";
import { CrowdSourcingProvider } from "../../context/CrowdSourcingContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import SiteDownPage from "../../views/SiteDown";
import { useConfig } from "../../context/ConfigContext";
import MyAchievements from "../../views/MyAchievements";
import TopicsBuilder from "../TopicsBuilder";
import PolygonTopicBuilder from "../PolygonTopicBuilder";
import Script from "react-load-script";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import AdminMenu from "../AdminMenu";
import AdminModals from "../AdminMenu/AdminModals";
import RaciGrid from "../RaciGrid";
import { RaciGridProvider } from "../RaciGrid/RaciContext";
import SupportConfirmationModal from "../../helpers/SupportConfirmationModal";
import TutorialLanding from "../Tutorials/TutorialLanding";

const App = () => (
  <ConfigProvider>
    <MsalInstanceProvider>
      <UserAuthProvider>
        <AxiosProvider>
          <LeftMenuCollapseProvider>
            <CrowdSourcingProvider>
              <TelemetryProvider>
                <ErrorBoundary
                  FallbackComponent={SiteDownPage}
                  onReset={() => { }}
                >
                  <RaciGridProvider>
                    <AppContent />
                  </RaciGridProvider>{" "}
                </ErrorBoundary>
              </TelemetryProvider>
            </CrowdSourcingProvider>
          </LeftMenuCollapseProvider>
        </AxiosProvider>
      </UserAuthProvider>
    </MsalInstanceProvider>
  </ConfigProvider>
);

const AppContent = () => {
  const { user } = useAuth();
  const { track } = useAppInsights();
  const { appState, setAppState, userLeaveWithSave } = useLeftMenuCollapse();
  const { apiDown } = useAxios();
  const { yammerAppId, browserTitle } = useConfig();
  const [supportmodalShow, setsupportModalShow] = useState(false);
  const [activitySupportmodalShow, setactivitySupportModalShow] =
    useState(false);
  const [showRatingConfirmationPopUp, setShowRatingConfirmationPopUp] =
    useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();

  useEffect(() => {
    const handleRedirection = () => {
      const currentUrl = window.location.href;

      if (currentUrl.includes('/consulting')) {
        const modifiedUrl = currentUrl.replace('/consulting', '');
        if (modifiedUrl !== currentUrl) {
          window.location.replace(modifiedUrl);
          return;
        }
      } else if (currentUrl.includes('/support')) {
        const modifiedUrl = currentUrl.replace('/support', '');
        if (modifiedUrl !== currentUrl) {
          window.location.replace(modifiedUrl);
          return;
        }
      } else if (currentUrl.includes('/topics/tutorials')) {
        const modifiedUrl = currentUrl.replace('/topics/tutorials', '/tutorials');
        if (modifiedUrl !== currentUrl) {
          window.location.replace(modifiedUrl);
          return;
        }
      } else if (currentUrl.includes('/topics/tutorials/consulting')) {
        const modifiedUrl = currentUrl.replace('/topics/tutorials/consulting', '/tutorials');
        if (modifiedUrl !== currentUrl) {
          window.location.replace(modifiedUrl);
          return;
        }
      } else if (currentUrl.includes('/topics/tutorials/support')) {
        const modifiedUrl = currentUrl.replace('/topics/tutorials/support', '/tutorials');
        if (modifiedUrl !== currentUrl) {
          window.location.replace(modifiedUrl);
          return;
        }
      }
    };

    handleRedirection();
  }, [history]);


  useEffect(() => {
    if (
      window.location.href.split("/").at(-3) === "2" &&
      window.location.href.split("/").at(-1) !== "13" &&
      window.location.href.split("/").at(-1) !== "16"
    ) {
      if (
        window.location.href.split("/").at(-4) === "complexity"
      ) {
        setsupportModalShow(false);
      }
      else {
        setsupportModalShow(true);
        setactivitySupportModalShow(true);
      }
    } else if (
      window.location.href.split("/").at(-7) !== "13" &&
      window.location.href.split("/").at(-7) !== "16" &&
      !activitySupportmodalShow &&
      (window.location.href.split("/").at(-7) === "undefined" ||
        window.location.href.split("/").at(-7) === "https:")
    ) {
      setsupportModalShow(false);
    }
    else if (
      window.location.href.split("/").at(-2) === 'approver'
    ) {
      setsupportModalShow(false);
    } else if (
      window.location.href.split("/").at(-2) === 'reviewer'
    ) {
      setsupportModalShow(false);
    } else if (
      window.location.href.split("/").at(-9) === "2" &&
      window.location.href.split("/").at(-7) !== "13" &&
      window.location.href.split("/").at(-7) !== "16"
    ) {
      setsupportModalShow(true);
    }
  });

  useEffect(() => {
    if (user.isAuthenticated) {
      track(2, 5, user.profile.mail, "User logged In");
    }
  }, [user.isAuthenticated]);

  useEffect(() => {
    if (!userLeaveWithSave) {
      setAppState({
        ...appState,
        userLeaveWithoutSave: false,
      });
    } else {
      setAppState({
        ...appState,
        userLeaveWithSave: false,
      });
    }
  }, []);

  if (!user.isAuthenticated) {
    return (
      <div className="CustomLoadingSpinner">
        <span className="sdmCustomLoader"></span>
      </div>
    );
  }
  if (apiDown) {
    return <SiteDownPage />;
  }
  if (supportmodalShow) {
    setsupportModalShow(false);
    if (!showRatingConfirmationPopUp) {
      if (
        window.location.href.split("/").at(-5) !== "" &&
        window.location.href.split("/").at(-5) !== undefined
      ) {
        //history.push(url);       
        setShowRatingConfirmationPopUp(true);
      }
    }
  }

  const breadcrumbfocus = () => {
    $('#breadcrumbId_0').find('span').focus();
  }

  const handleContentFocus = () => {
    if (window.location.pathname === '/') {
      $('.LinkContentQuick').first().find('a').focus();;
    }
    if (window.location.pathname.toLowerCase().includes('/topics') ||
      window.location.pathname.toLowerCase().includes('/racigrid') ||
      window.location.pathname.toLowerCase().includes('/tutorials/') ||
      window.location.pathname.toLowerCase().includes('/template') ||
      window.location.pathname.toLowerCase().includes('/project')
    ) {
      breadcrumbfocus();
    }
  }

  return (
    <div>
      {/* <SkipNavigations /> */}
      <div className="skipToMain">
        <Link id="skipLinksIdAdmin" tabindex="0" role="link"
          aria-label="Skip to Main content. Press control Enter keys to navigate."
          onKeyDown={(event) => {
            if (event.key.toLocaleLowerCase() === 'enter') {
              handleContentFocus();
            }
          }}
          onClick={(event) => {
            handleContentFocus();
          }}
        >
          Skip to main content
        </Link>
      </div>
      <ToastContainer theme="colored" icon={false} role="alert" />
      <Script
        url="https://c64.assets-yammer.com/assets/platform_js_sdk.js"
        attributes={{ type: "text/javascript", "data-app-id": yammerAppId }}
      />
      <Script
        url="https://c64.assets-yammer.com/assets/platform_embed.js"
        attributes={{ type: "text/javascript", "data-app-id": yammerAppId }}
      />
      <div className="main-page-container">
        <NavBar />
        <Switch>
          <Route path="/racigrid">
            <RaciGrid />
          </Route>
          <Route path="/addTopics">
            <TopicsBuilder />
          </Route>
          <Route path="/polygon/addTopics">
            <PolygonTopicBuilder />
          </Route>
          <Route path="/myachievements">
            <MyAchievements />
          </Route>
          <Route path="/SiteDown">
            <SiteDownPage />
          </Route>
          <Route path="/template/crowdsourcing">
            <TemplateCrowdSourcing />
          </Route>
          <Route path="/template">
            <TemplateLanding />
          </Route>
          <Route path="/search">
            <SearchResultPage />
          </Route>
          <Route path="/topics">
            <Topics />
          </Route>
          <Route path="/tutorials">
            <TutorialLanding />
          </Route>
          <Route path="/dmg">
            <MethodologyGuidelines />
          </Route>
          <Route path="/known-issue">
            <KnownIssue />
          </Route>
          <Route path="/mycontributions">
            <MyContributions />
          </Route>
          <Route path="/admin">
            <AdminMenu />
          </Route>
          <Route path="/">
            <PageContent />
          </Route>
        </Switch>
        <Route path="/" component={AdminModals} />

        {showRatingConfirmationPopUp ? (
          <SupportConfirmationModal
            showRatingConfirmationPopUp={showRatingConfirmationPopUp}
            setShowRatingConfirmationPopUp={setShowRatingConfirmationPopUp}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default App;
