
import React, { useState, useEffect } from "react";
import { Icon } from "@fluentui/react";
import { Link, useHistory } from "react-router-dom";
import { useAppInsights } from "../../context/TelemetryContext";
import $ from "jquery";
const TopicLeftMenu = ({
  resetSolutionMethodFilterTag,
  resetPhaseFilterTag,
  resetActivitiesFilterTag,
  selectedProcedures,
  expandTopicsLeftMenu,
  setExpandTopicsLeftMenu,
  selectedTab,
  type,
  isUserAction,
  setIsUserAction,
  leftMenuSelectedId,
  setLeftMenuSelectedId,
  showTutorials,
  selectedReadiness
}) => {
  const history = useHistory();
  const { track } = useAppInsights();
  const tabDetails = [
    {
      id: 1,
      name: "Topics",
      className: "topicBulbIcon",
      selectedClassName: "topicBulbIconOrange",
      icon: `<svg id="Component_330_1" data-name="Component 330 – 1" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
		<rect id="Rectangle_992" data-name="Rectangle 992" width="21" height="20" fill="#2b314c" opacity="0"/>
		<path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M3.216,15.21A1.072,1.072,0,0,0,3.4,15.8l.572.86a1.071,1.071,0,0,0,.892.478H6.925a1.071,1.071,0,0,0,.892-.478l.572-.86a1.071,1.071,0,0,0,.179-.592V13.926H3.214l0,1.284ZM0,5.892A5.859,5.859,0,0,0,1.458,9.768a9.621,9.621,0,0,1,1.748,3.061l0,.026H8.574l0-.026a9.621,9.621,0,0,1,1.748-3.061A5.891,5.891,0,1,0,0,5.892ZM5.892,3.214A2.681,2.681,0,0,0,3.214,5.892a.536.536,0,1,1-1.071,0A3.754,3.754,0,0,1,5.892,2.143a.536.536,0,1,1,0,1.071Z" transform="translate(5 1)" fill="#a5a4bf"/>
	  </svg>		  
	  `,
    },
    {
      id: 2,
      name: "Job Aids",
      className: "topicProceduresIcon",
      selectedClassName: "topicProceduresIconOrange",
      icon: `<svg id="Component_331_1" data-name="Component 331 – 1" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
		<rect id="Rectangle_991" data-name="Rectangle 991" width="21" height="20" fill="#2b314c" opacity="0"/>
		<g id="process" transform="translate(2 1.693)">
		  <path id="Union_17" data-name="Union 17" d="M13.716,15.424a1.712,1.712,0,1,1,1.714,1.717A1.716,1.716,0,0,1,13.716,15.424ZM5.593,15.3a1.763,1.763,0,0,1,3.5-.3h3.433l-.378-.378.413-.416L13.653,15.3l-1.091,1.088-.413-.413.378-.381H9.094a1.763,1.763,0,0,1-3.5-.294Zm-1.681.675.381-.381H3.678a3.677,3.677,0,1,1,0-7.354H4.44a1.763,1.763,0,1,1,0,.59H3.678a3.087,3.087,0,1,0,0,6.174h.615l-.381-.378.419-.416L5.419,15.3,4.331,16.386Zm4.2-7.44L9.208,7.437l.413.419-.381.381h4.733a3.088,3.088,0,0,0,0-6.175h-1.94a1.768,1.768,0,1,1,0-.589h1.94a3.677,3.677,0,0,1,0,7.354H9.241l.381.379-.413.412ZM.3,1.767a1.764,1.764,0,0,1,3.5-.294h3.43l-.378-.382L7.269.676,8.362,1.767,7.269,2.855l-.416-.413.378-.379H3.8a1.764,1.764,0,0,1-3.5-.3Z" transform="translate(0)" fill="#a5a4bf"/>
		</g>
	  </svg>
	  `,
    }
  ];
  const tutorialTabDetails = [
    {
      id: 3,
      name: "Tutorials",
      className: "topicTutorialsIcon",
      selectedClassName: "topicTutorialsIconOrange",
      icon: `<svg id="Component_330_1_1_" data-name="Component 330 – 1" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
			<rect id="Rectangle_993" data-name="Rectangle 993" width="21" height="20" fill="#2b314c" opacity="0"></rect>
			<path id="Icon_metro-film" data-name="Icon metro-film" d="M2.571,5.784V19.22H20.485V5.784H2.571ZM5.93,18.1H3.69V15.861H5.93Zm0-4.479H3.69V11.382H5.93Zm0-4.479H3.69V6.9H5.93V9.143ZM16.006,18.1H7.049V6.9h8.957V18.1Zm3.359,0H17.126V15.861h2.239Zm0-4.479H17.126V11.382h2.239Zm0-4.479H17.126V6.9h2.239V9.143Zm-10.077,0v6.718L13.767,12.5Z" transform="translate(-0.57 -2.091)" fill="#a5a4bf"></path>
		  </svg>		  
		  `,
    },
  ];

  const changeUrl = (id) => {
    console.log("id123", id);
    if (leftMenuSelectedId !== id) {
      resetSolutionMethodFilterTag();
      resetPhaseFilterTag();
      resetActivitiesFilterTag();
      setLeftMenuSelectedId(id);
    }
    if (id === 1) {
      history.push({
        pathname: `/topics`,
      });
      track(
        1,
        10,
        `topics_landingPage_${selectedTab.toLowerCase().charAt(0) + selectedTab.slice(1)
        }`,
        "Topics"
      );
    } else if (id === 2) {
      history.push({
        pathname: `/topics`,
        search: `?topicType=job&selectedJobAides=2`,
      });
      track(
        1,
        11,
        `topics_landingPage_jobaides_${selectedProcedures === 2 ? "consulting" : "support"
        }`,
        "Job Aides"
      );
    }
    else if (id === 4) {
      history.push({
        pathname: `/topics/${selectedTab.toLowerCase().charAt(0) + selectedTab.slice(1)
          }`,
        search: `?topicType=read&selectedReadiness=${selectedTab === "Consulting" ? 2 : 3
          }`,
      });
      track(
        1,
        12,
        `topics_landingPage_Readiness_${selectedReadiness === 2 ? "consulting" : "support"
        }`,
        "Readiness"
      );
    }
    else {
      history.push({
        pathname: `/tutorials`,
      });
      track(
        1,
        1103,
        `tutorials_landingPage_${selectedProcedures === 2 ? "consulting" : "support"
        }`,
        "Tutorials"
      );
    }
  };


  useEffect(() => {
    if (selectedProcedures !== 0) {
      setLeftMenuSelectedId(2);
    }
  }, [selectedProcedures]);

  useEffect(() => {
    if (selectedReadiness !== 0) {
      setLeftMenuSelectedId(4);
    }
  }, [selectedReadiness]);

  useEffect(() => {
    if (selectedProcedures !== 0 && type === "landingPage" && selectedTab) {
      history.push({
        pathname: `/topics`,
        search: `?topicType=job&selectedJobAides=2`,
      });
    }
    if (selectedReadiness !== 0 && type === "landingPage" && selectedTab) {
      history.push({
        pathname: `/topics
          }`,
        search: `?topicType=read&selectedReadiness=2`,
      });
    }
  }, [selectedTab]);
  return (
    <div
      role="navigation" aria-label="Left Navigation"
      className={
        !expandTopicsLeftMenu
          ? "topicsLeftMenuContainer"
          : "topicsLeftMenuContainer expanded"
      }
    >
      {window.location.href.indexOf("tutorials") === -1 ? (
        <ul role="menu">
          {tabDetails.map((tab) => (
            <li key={tab.id} role="menuitem">
              <Link
                className={
                  leftMenuSelectedId === tab.id
                    ? "NavLink SelectedTopicsTab"
                    : "NavLink"
                }
                to={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  changeUrl(tab.id);
                  if (!isUserAction) {
                    setExpandTopicsLeftMenu(false);
                  }
                }}
                title={tab.name}
                aria-label={tab.name}
              >
                <div
                  className="topicTypeIcon"
                  dangerouslySetInnerHTML={{ __html: tab.icon }}
                />
                {expandTopicsLeftMenu ? <span>{tab.name}</span> : null}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <ul role="menu">
          {tutorialTabDetails.map((tab) => (
            <li key={tab.id} role="menuitem">
              <Link
                className={
                  leftMenuSelectedId === tab.id
                    ? "NavLink SelectedTopicsTab"
                    : "NavLink"
                }
                to={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  changeUrl(tab.id);
                  if (!isUserAction) {
                    setExpandTopicsLeftMenu(false);
                  }
                }}
                title={tab.name}
                aria-label={tab.name}
              >
                <div
                  className="topicTypeIcon"
                  dangerouslySetInnerHTML={{ __html: tab.icon }}
                />
                {expandTopicsLeftMenu ? <span>{tab.name}</span> : null}
              </Link>
            </li>
          ))}
        </ul>
      )}
      <div className="topicsLeftMenuIcon">
        <Icon
          title={
            expandTopicsLeftMenu ? "Collapse left menu" : "expand left menu"
          }
          iconName={
            expandTopicsLeftMenu
              ? "DoubleChevronLeftMed"
              : "DoubleChevronLeftMedMirrored"
          }
          onClick={() => {
            setExpandTopicsLeftMenu(!expandTopicsLeftMenu);
            setIsUserAction(true);
          }}
          tabIndex={0}
          onKeyDown={(e)=>{
            if(e.key === 'Enter' || e.key === ' '){
              setExpandTopicsLeftMenu(!expandTopicsLeftMenu);
              setIsUserAction(true);
            }
          }}
          role="button"
          aria-label={
            expandTopicsLeftMenu ? "Collapse left menu" : "expand left menu"
          }
        />
      </div>
    </div>
  );
};

export default TopicLeftMenu;
