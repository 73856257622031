import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import loadable from '@loadable/component';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Prompt } from 'react-router';
import { useAuth } from '../../context/UserAuthContext';
import $ from 'jquery';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DiscardPopUp from './DiscardPopUp';
import PreviewAndSubmitPopUp from './PreviewAndSubmitPopUp';
import SuccessMessagePopUp from './SuccessPopUp';
import ContributorPopUp from './ContributorPopUp';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import Breadcrumbs from '../Breadcrumbs';
import Spinner from '../../helpers/Spinner';
import { TabTitle } from "../../utils/GeneralFunctions";
import TemporaryCkAlert from '../CkEditor/TemporaryCkAlert';
import '../TopicsDetail/TopicsDetailPage.css';
import './TopicsContributorCrowdSourcing.css';
import decryptAES256 from '../CkEditor/DecryptionComponent';
import { useConfig } from '../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() => import('../CkEditor/TopicContributorTrackChange/Description'),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

const TopicsContributorCrowdSourcing = (props) => {
	const { ckEdKEY } = useConfig();
	const history = useHistory();
	const { axiosGetService } = useAxios();
	const { breadcrumbs, setBreadcrumbs, setSkipNavigationId, getImageBase64 } =
		useLeftMenuCollapse();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { id } = useParams();
	const { userLeaveWithoutSave } = crowdSourcingAppState;
	const { user } = useAuth();
	const CkEditorCurrentUser = user.profile.mail;
	const { track } = useAppInsights();
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const [leavingWithoutSave, setLeavingWithoutSave] = useState(true);
	const [message, setSubmitMessage] = useState(null);
	const [showDiscardPopUp, setShowDiscardPopUp] = useState(false);
	const [showPreviewAndSubmitPopUp, setShowPreviewAndSubmitPopUp] =
		useState(false);
	const [description, setDescription] = useState(null);
	const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
	const [topicDescriptionData, setTopicDescriptionData] = useState(null);
	const [rejectedDescSuggestions, setRejectedDescSuggestions] = useState([]);
	const [licenseKey, setLicenseKey] = useState(null);
	const [messagesType, setMessagesType] = useState({});
	const [topicLockedBy, setTopicLockedBy] = useState('');
	const [lockedByUsername, setLockedByUsername] = useState('');

	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();

	const getTopicData = async () => {
		const response = await axiosGetService(`api/sdmtopics/getTopic2/${id}`);
		setTopicDescriptionData(response.data);
		if (response.data.isDrafted) {
			const res = await axiosGetService(`api/sdmtopics/get/${id}`);
			setTopicLockedBy(res.data.lastDraftChangesMadeBy);
			const accessToken = await axiosTokenService();
			const userData = await getOtherUserProfile(
				accessToken,
				res.data.lastDraftChangesMadeBy
			);
			if (userData)
				setLockedByUsername(userData.firstName + ' ' + userData.surname);
			else setLockedByUsername(res.data.lastDraftChangesMadeBy);
		}
	};

	if (leavingWithoutSave && !showDiscardPopUp && !userLeaveWithoutSave) {
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			userLeaveWithoutSave: true,
		});
	}

	useEffect(() => {
		return () => {
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				userLeaveWithoutSave: false,
			});
		};
	}, []);

	// load and set the breadcrumb
	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${id}&itemTypeId=10
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false
			&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());
				const topicsBreadcrumb = {
					title: breadcrumbTitle[1],
					path:
					  breadcrumbTitle[1] === "Job Aides"
						? "/topics?topicType=job&selectedJobAides=2"
						: breadcrumbTitle[1] === "Readiness"
							? "/topics?topicType=read&selectedReadiness=2"
							: "/topics",
				parent: ['home', 'myContributions'],
				type: 'topics',
			};
			const topicsCategoryBreadcrumb = {
				title: breadcrumbTitle[2],
				path: breadcrumbTitle[1] === "Job Aides"
					? `/topics/${id}?topicType=job&selectedJobAides=2
					}`
					: breadcrumbTitle[1] === "Readiness"
						? `/topics/${id}?topicType=read&selectedReadiness=2
						}`
						: `/topics/${id}`,
				parent: 'topics',
				type: 'topicType',
			};
			// const pageTitle = {
			// 	title: breadcrumbTitle[3],
			// 	path: breadcrumbTitle[1] === "Job Aides"
			// 		? `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
			// 		breadcrumbTitle[2].slice(1)
			// 		}/${id}?topicType=job&selectedJobAides=${breadcrumbTitle[2].toLowerCase() === "support" ? 3 : 2
			// 		}`
			// 		: breadcrumbTitle[1] === "Readiness"
			// 			? `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
			// 			breadcrumbTitle[2].slice(1)
			// 			}/${id}?topicType=read&selectedReadiness=${breadcrumbTitle[2].toLowerCase() === "support" ? 3 : 2
			// 			}`
			// 			: `/topics/${breadcrumbTitle[2].charAt(0).toLowerCase() +
			// 			breadcrumbTitle[2].slice(1)
			// 			}/${id}`,
			// 	type: 'pageTitle',
			// 	parent: 'topicType',
			// };
			const currentTopicsContentBreadcrumb = {
				title: breadcrumbTitle[3],
				parent: ['pageTitle'],
			};

			const newBreadcrumbs = breadcrumbs.slice(
				0,
				breadcrumbs.length > 1 && breadcrumbs[1].type === 'myContributions'
					? 2
					: 1
			);
			newBreadcrumbs.push(topicsBreadcrumb);
			newBreadcrumbs.push(topicsCategoryBreadcrumb);
			// newBreadcrumbs.push(pageTitle);
			newBreadcrumbs.push(currentTopicsContentBreadcrumb);
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[2] + " - Edit Topic");
		});
	};

	const getMessage = async (title) => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=contentUpdate&title=0`
		).then((response) => {
			message['contentUpdate'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=noContributionMessage&title=0`
		).then((response) => {
			message['noContributionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=contributionSubmissionMessage&title=${encodeURIComponent(
				title
			)}`
		).then((response) => {
			message['contributionSubmissionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingApproval&title=0`
		).then((response) => {
			message['pendingApproval'] = response.data;
		});
		setMessagesType(message);
	};

	const getContributor = (suggestions) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		if (topicDescriptionData.resultCalloutJson) {
			getContributor(topicDescriptionData.resultCalloutJson);
		}

		if (!userDataMap.has(CkEditorCurrentUser)) {
			graphUserData.push(CkEditorCurrentUser);
			userDataMap.set(CkEditorCurrentUser, 1);
		}
		const accessToken = await axiosTokenService();
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
		}
		setCkEditorUsers(contributorInfo);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	useEffect(() => {
		getLicense();
		getTopicData();
		setSkipNavigationId('topicsInnerContent');
	}, []);

	useEffect(() => {
		if (topicDescriptionData) {
			getUsersInfo();
		}
	}, [topicDescriptionData]);

	useEffect(() => {
		if (topicDescriptionData) {
			track(2, 140, topicDescriptionData.topicId, topicDescriptionData.title);
			getMessage(topicDescriptionData.title);
		}
	}, [topicDescriptionData]);

	useEffect(() => {
		loadBreadcrumb();
	}, []);

	useEffect(() => {
		if (topicDescriptionData) {
			setDescription(topicDescriptionData.description);
		}
		if (topicDescriptionData && topicDescriptionData.resultCalloutJson) {
			setDescriptionSuggestions(topicDescriptionData.resultCalloutJson);
		}
	}, [topicDescriptionData]);

	if (!topicDescriptionData || ckEditorUsers.length < 1 || !licenseKey) {
		return null;
	}

	if (!topicDescriptionData.isEditable) {
		return (
			<Modal
				className="activityDiscardPopUp contributorPopUp"
				show={true}
				onHide={() => {
					/* Not calling any function to close modal*/
				}}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<p>
						This topic is not enabled for crowd sourcing based on the author's
						request. Please reach out to the author if you want to suggest
						changes to the topic
					</p>
					<div className="discardPopUpActivityButtons">
						<Link to={`/`}>
							<Button
								tabIndex={0}
								className="activityDiscardPopUpAccept"
								variant="info"
							>
								ok
							</Button>
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	if (topicDescriptionData.isDrafted) {
		return (
			<Modal
				className="activityDiscardPopUp contributorPopUp"
				show={true}
				onHide={() => {
					/* Not calling any function to close modal*/
				}}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<p>
						You can not edit this content now. Content is being updated by{' '}
						<a
							target="_blank"
							href={`https://teams.microsoft.com/l/chat/0/0?users=${topicLockedBy}`}
						>
							{lockedByUsername}
						</a>
						{' .'} Please check later for editing.
					</p>
					<div className="discardPopUpActivityButtons">
						<Link to={`/`}>
							<Button
								tabIndex={0}
								className="activityDiscardPopUpAccept"
								variant="info"
							>
								ok
							</Button>
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	return (
		<div className="topicLandingMainContainer">
			<div className="topicsLandingPage">
				<div className="topicFixedPart">
					<Breadcrumbs />
					<div className="topicDescTitlePart">
						<div className="topicTitlePartDiv">
							<span className="topicVersion">
								Document Version: {topicDescriptionData.topicVersion}
								,&nbsp;&nbsp;
								{`${topicDescriptionData.modifiedDate} `}
							</span>
							<div className="discardAndPreviewButton">
								<Button
									title="Discard"
									className="activityDiscardButton"
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowDiscardPopUp(true);
											track(
												1,
												144,
												topicDescriptionData.topicId,
												topicDescriptionData.title
											);
										}
									}}
									onClick={() => {
										setShowDiscardPopUp(true);
										track(
											1,
											144,
											topicDescriptionData.topicId,
											topicDescriptionData.title
										);
									}}
								>
									Discard
								</Button>
								<Button
									className="activityPreviewButton"
									title="Preview &amp; Submit"
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowPreviewAndSubmitPopUp(true);
											track(
												1,
												145,
												topicDescriptionData.topicId,
												topicDescriptionData.title
											);
										}
									}}
									onClick={() => {
										setShowPreviewAndSubmitPopUp(true);
										track(
											1,
											145,
											topicDescriptionData.topicId,
											topicDescriptionData.title
										);
									}}
								>
									Preview &amp; Submit
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="topicsCrowdSourcingContainer topicsCrowdSourcingContent">
					<div class="topicsDescriptionDetails topicEditPage">
						<div className="topicDescriptionDiv" id="topicsInnerContent">
							<h4 className="topicTitle">{topicDescriptionData.title}</h4>
							<div class="existingHashTagsOfTopic">
								{topicDescriptionData.hashTagsList.map(({ name }) => (
									<span
										key={name}
										onClick={() => {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												history.push(
													`/search?searchParam=${encodeURIComponent(name)}`
												);
											}
										}}
									>
										{name}
									</span>
								))}
							</div>
							<TemporaryCkAlert width={'77'}/>

							<DescriptionCkEditor
								users={ckEditorUsers}
								currentUser={CkEditorCurrentUser}
								initialData={
									topicDescriptionData.description
										? topicDescriptionData.description
										: ' '
								}
								setContent={setDescription}
								initalSuggestions={
									topicDescriptionData.resultCalloutJson
										? topicDescriptionData.resultCalloutJson
										: null
								}
								setSuggestions={setDescriptionSuggestions}
								setRejectedDescSuggestions={setRejectedDescSuggestions}
								licenseKey={licenseKey}
							/>
						</div>
						{showDiscardPopUp ? (
							<DiscardPopUp setShowDiscardPopUp={setShowDiscardPopUp} />
						) : null}
						{showPreviewAndSubmitPopUp ? (
							<PreviewAndSubmitPopUp
								setShowPreviewAndSubmitPopUp={setShowPreviewAndSubmitPopUp}
								users={ckEditorUsers}
								currentUser={CkEditorCurrentUser}
								description={description}
								descriptionSuggestions={descriptionSuggestions}
								setLeavingWithoutSave={setLeavingWithoutSave}
								setSubmitMessage={setSubmitMessage}
								rejectedDescSuggestions={rejectedDescSuggestions}
								contributorDescSuggestions={
									topicDescriptionData.resultCalloutJson
										? topicDescriptionData.resultCalloutJson
										: null
								}
								topicDescriptionData={topicDescriptionData}
								licenseKey={licenseKey}
								messagesType={messagesType}
							/>
						) : null}
						{topicDescriptionData.stateId === 2 ? <ContributorPopUp /> : null}
						<SuccessMessagePopUp message={message} />
						<Prompt
							when={
								leavingWithoutSave &&
								!showDiscardPopUp &&
								topicDescriptionData.stateId !== 2
							}
							message="You have unsaved changes, are you sure you want to leave?"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
// '<h3 style="color:Blue;">Writing up a Get to Green Action Plan</h3>'
export default TopicsContributorCrowdSourcing;
