import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAxios } from '../../context/AxiosContext';
import ActivityHistory from './ActivityHistory';
import AdminHistory from './ActivityHistory/Admin';
import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';
import Spinner from '../../helpers/Spinner';
import { handleTernaryCondition } from '../../helpers/Validation';

const TopicsVersionHistory = ({
	activityId,
	complexityId,
	showActivityVersionHistory,
	setShowActivityVersionHistory,
	activityName,
}) => {
	const { axiosGetService } = useAxios();
	const [contributionHistory, setShowContributionHistory] = useState([]);
	const [
		showActivityCrowdSourcingHistory,
		setShowActivityCrowdSourcingHistory,
	] = useState(false);
	const [selectedVersion, setSelectedVersion] = useState(null);
	const [apiCalled, setApiCalled] = useState(false);
	const [showAdminHistory, setShowAdminHistory] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [showLoaderWithBlur, setShowLoaderWithBlur] = useState(false);
	const [adminHistoryChanges, setAdminHistoryChanges] = useState([]);

	const getActivityHistory = async () => {
		await axiosGetService(
			`api/sdmactivity/getCrowdsourcingData/${activityId}`
		).then((response) => {
			setShowContributionHistory(response.data);
			setApiCalled(true);
			setShowLoader(false);
		});
	};

	const getAdminChanges = async (
		activityId,
		complexityId,
		versionNumber,
		isCrowdSourced,
		setShowHistoryPreview
	) => {
		await axiosGetService(
			`api/sdmactivity/getActivityDataForMethodOwner?activityId=${activityId}&complexityId=${complexityId}&versionNumber=${versionNumber}&isCrowdSourced=${isCrowdSourced}`
		).then((response) => {
			setAdminHistoryChanges(response.data);
			setShowHistoryPreview(true);
			setShowLoaderWithBlur(false);
		});
	};

	useEffect(() => {
		getActivityHistory();
	}, []);

	return (
		<>
			{showLoaderWithBlur && <CrowdSourcingSpinner />}
			{showActivityCrowdSourcingHistory && (
				<ActivityHistory
					showActivityCrowdSourcingHistory={showActivityCrowdSourcingHistory}
					setShowActivityCrowdSourcingHistory={
						setShowActivityCrowdSourcingHistory
					}
					historyData={adminHistoryChanges}
					versionDetails={selectedVersion}
				/>
			)}
			{showAdminHistory && (
				<AdminHistory
					showAdminHistory={showAdminHistory}
					setShowAdminHistory={setShowAdminHistory}
					historyData={adminHistoryChanges}
					versionDetails={selectedVersion}
				/>
			)}
			{!showLoaderWithBlur &&
				!showActivityCrowdSourcingHistory &&
				!showAdminHistory && (
					<Modal
						className="phaseCreationRightPopup methodOwnerCollaborator topicOwnerHistoryPopUp"
						show={showActivityVersionHistory}
						onHide={() => {
							// Don't do anything.
						}}
					>
						<Modal.Header>
							<div className="methodOwnerCollaboratorTitle">
								<Modal.Title>
									<h3>{activityName}</h3>
									<span>Edit History</span>
								</Modal.Title>
								<Icon
									iconName="ChromeClose"
									title="Close"
									onClick={() => setShowActivityVersionHistory(false)}
								/>
							</div>
						</Modal.Header>
						<Modal.Body>
							{showLoader && <Spinner />}
							{!showLoader && (
								<div>
									{((contributionHistory.length === 0 && apiCalled) ||
										!activityId) && <p>No history available</p>}
									{!(
										(contributionHistory.length === 0 && apiCalled) ||
										!activityId
									) && (
										<table>
											<tr>
												<th>Document Version</th>
												<th>Published Date</th>
												<th>Complexity</th>
											</tr>
											{contributionHistory.map((item) => (
												<tr>
													<td>
														<span
															className="historyVirsionNumber"
															onClick={() => {
																setSelectedVersion(item);
																setShowLoaderWithBlur(true);
																getAdminChanges(
																	activityId,
																	item.complexityId,
																	item.versionNumber,
																	item.isCrowdSourced,
																	handleTernaryCondition(
																		item.isCrowdSourced,
																		setShowActivityCrowdSourcingHistory,
																		setShowAdminHistory
																	)
																);
															}}
														>
															{item.versionNumber}
														</span>
													</td>
													<td>{item.modifiedDate}</td>
													<td>{item.complexityName}</td>
												</tr>
											))}
										</table>
									)}
								</div>
							)}
						</Modal.Body>
					</Modal>
				)}
		</>
	);
};

export default TopicsVersionHistory;
