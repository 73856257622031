import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';

import './DiscardPopUp.css';
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";

function DiscardPopUp(props) {
	const [modalShow, setModalShow] = useState(true);
	const { topicType, id } = useParams();
	const [messagesType, setMessagesType] = useState({});
	const { breadcrumbs, setBreadcrumbs } =
    useLeftMenuCollapse();

	const { axiosGetService } = useAxios();

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=discardPopUpMessage&title=0`
		).then((response) => {
			message['discardPopUpMessage'] = response.data;
		});

		setMessagesType(message);
	};

	const closeModal = () => {
		setModalShow(false);
		props.setShowDiscardPopUp(false);
	};
	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="activityDiscardPopUp"
			show={modalShow}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{messagesType['discardPopUpMessage']}</p>
				<div className="discardPopUpActivityButtons">
					<Button
						title="No"
						className="activityDiscardPopUpCancel"
						variant="primary"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => closeModal()}
					>
						No
					</Button>
					<Link aria-label="Discard PopUp Yes" to={breadcrumbs[2].path}>
						<Button title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info">
							Yes
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
