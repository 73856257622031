export const activityProperties = [
    {
        id: 1,
        name: 'All',
        isChecked: true
    },
    {
        id: 2,
        name: 'Activity Description',
        isChecked: true
    },
    {
        id: 3,
        name: 'Entry Criteria',
        isChecked: true
    },
    {
        id: 4,
        name: 'Templates',
        isChecked: true
    },

    {
        id: 5,
        name: 'RACI',
        isChecked: true
    },
    {
        id: 6,
        name: 'Tasks',
        isChecked: true
    },
    {
        id: 7,
        name: 'Exit Criteria',
        isChecked: true
    },
    {
        id: 8,
        name: 'Guidance, Tips and Techniques',
        isChecked: true
    },

    {
        id: 9,
        name: 'Deliverables',
        isChecked: true
    },
    {
        id: 10,
        name: 'Supporting Activity',
        isChecked: true
    },
    {
        id: 11,
        name: 'Tile Description',
        isChecked: true
    },
    {
        id: 12, 
        name: 'Is Critical',
        isChecked: true
    },
    {
        id: 13,
        name: 'Process Group',
        isChecked: true
    },
    {
        id: 14,
        name: 'SMMI Is Deliverable',
        isChecked: true
    },
    {
        id: 15,
        name: 'SMMI Is Mandatory Process Step',
        isChecked: true
    },
    {
        id: 16,
        name: 'SMMI Secure by Default',
        isChecked: true
    },
    {
        id: 17,
        name: 'Business Rules',
        isChecked: true
    },

];
