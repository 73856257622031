import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import dompurify from 'dompurify';
import { v4 as uniqueIdGenerator } from 'uuid';
import Dropdown from '../Dropdown';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExitCriteriaContribution from './ExitCriteriaContribution';

const EntryCriteriaContribution = ({
	contributorsData,
	entryCriteriaButtonClicked,
	setEntryCriteriaButtonClicked,
	ckEditorUserMap,
	CkEditorCurrentUser,
	entryCriteriaSuggestions,
	setEntryCriteriaSuggestions,
	entryCriteriaWithSuggestions,
	setEntryCriteriaWithSuggestions,
	reviewedEntryCriteriaSuggestions,
	setReviewedEntryCriteriaSuggestions,
	reviewerEntryCriteriaSuggestions,
	source,
	entryCriteriaData,
	setEntryCriteriaData,
	currentTab,
	setCurrentTab,
	entryContributionReorder,
	setEntryContributionReorder,
	setInputContributionReorder,
	inputContributionReorder,
	setInputCriteriaReorderArray,
	setEntryCriteriaReorderArray,
	entryCriteriaReorderArray,
	inputCriteriaReorderArray,
	setReviewedReorderedEntrySuggestions,
    reviewedReorderedEntrySuggestions,
	reviewedReorderedInputSuggestions,
	setReviewedReorderedInputSuggestions,
	initialEntryIdBeforeReorder,
	setInitialEntryIdBeforeReorder,
	disableEntryDrag,
	setDisableEntryDrag,
	disableInputDrag,
	setDisableInputDrag,
	ckEditorCurrentEntryReorderUsername,
    setCkEditorCurrentEntryReorderUsername,
    ckEditorCurrentEntryReorderUserImage,
    setCkEditorCurrentEntryReorderUserImage,
	ckEditorCurrentInputReorderUserImage,
	setCkEditorCurrentInputReorderUserImage,
	ckEditorCurrentInputReorderUsername,
	setCkEditorCurrentInputReorderUsername,
	user,
	setIsReviewerEntryReordered,
	setIsReviewerInputReordered,
	setIsApproverEntryReordered,
	setIsApproverInputReordered,
	setReorderContributionCount,
	setAddedEntrySequence,
	setIsOrderChanged
}) => {
	const pageType = ['review', 'approve'];
	const htmlSanitizer = dompurify.sanitize;
	const defaultCriteriType = {
		id: 1,
		name: 'Text',
	};
	const defaultCriteriaTypeValue = {
		2: 'Numeric',
		1: 'Text',
	};
	const dropdownValue = [
		{ id: 1, name: 'Text', type: 'option' },
		{ id: 2, name: 'Numeric', type: 'option' },
	];
	const [criteriaName, setCriteriaName] = useState('');
	const [criteriaDescription, setCriteriaDescription] = useState('');
	const [criteriaType, setCriteriaType] = useState(defaultCriteriType);
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updateId, setUpdateId] = useState(0);
	const [inputEditMode, setInputEditMode] = useState(false);
	const [inputUpdateId, setInputUpdateId] = useState(0);

	const [entryCriteriaList, setEntryCriteriaList] = useState([
		...contributorsData.entryCriteriaList.filter(
			(item) => item.criteriaTypeName === 'Entry Criteria'
		),
	]);
	const [inputList, setInputList] = useState([
		...contributorsData.entryCriteriaList.filter(
			(item) => item.criteriaTypeName !== 'Entry Criteria'
		),
	]);
	const [inputDescription, setInputDescription] = useState('');
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
	const [sidebarCard, setSidebarCard] = useState([]);
	const [userImgWithReorder, setUserImgWithReorder] = useState(null)
	const [userNameWithReorder, setUserNameWithReorder] = useState(null)
	const [entryListwithInsert,  setEntryListwithInsert] = useState([...contributorsData.entryCriteriaList.filter(
		(item) => item.criteriaTypeName === 'Entry Criteria'
	),
]); 
const [inputListwithInsert,  setInputListwithInsert] = useState([...contributorsData.entryCriteriaList.filter(
	(item) => item.criteriaTypeName === 'Input'
),
]);
    const [initialEntryCriteriaBeforeReorder, setInitialEntryCriteriaBeforeReorder]=useState([...initialEntryIdBeforeReorder.filter(
		(item)=> JSON.parse(item).criteriaTypeName === 'Entry Criteria'
	)])
	const [initialInputCriteriaBeforeReorder, setInitialInputCriteriaBeforeReorder] = useState([...initialEntryIdBeforeReorder.filter(
		(item)=> JSON.parse(item).criteriaTypeName === 'Input')])

	const updateEntryCriteria = () => {
		if (criteriaType.id !== 0 && criteriaDescription !== '' && criteriaDescription.trim().length !== 0) {
			if (editMode) {
				if (entryCriteriaList.some((item) => item.id === updateId)) {
					const updatedDtails = entryCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								suggestionType: 'updation',
								suggestionId: uniqueIdGenerator(),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					setEntryCriteriaWithSuggestions([...updatedDtails]);

					if (
						!entryCriteriaSuggestions.some(
							(item) => JSON.parse(item).id === updateId
						)
					) {
						const entryCriteria = entryCriteriaList
							.filter((item) => item.id === updateId)
							.map((value) => {
								return JSON.stringify({
									...value,
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: criteriaDescription,
									valueType: criteriaType.name === 'Text' ? 1 : 2,
								});
							});
						setEntryCriteriaSuggestions((prevState) => [
							...prevState,
							...entryCriteria,
						]);
					} else {
						const updatedSuggestions = entryCriteriaSuggestions.map((item) =>
							JSON.parse(item).id === updateId
								? JSON.stringify({
									...JSON.parse(item),
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: criteriaDescription,

									valueType: criteriaType.name === 'Text' ? 1 : 2,
								})
								: item
						);
						setEntryCriteriaSuggestions([...updatedSuggestions]);
					}
				} else {
					const updatedDtails = entryCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					const updateSuggestions = entryCriteriaSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					setEntryCriteriaSuggestions([...updateSuggestions]);
					setEntryCriteriaWithSuggestions([...updatedDtails]);
				}
				setEditMode(false);
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setUpdateId(0);
				setCriteriaName('');

				setError(false);
			} else {
				const entryCriteria = {
					id: uniqueIdGenerator(),
					suggestionId: uniqueIdGenerator(),
					name: criteriaDescription,
					criteriaType: 'Entry',
					valueType: criteriaType.name === 'Text' ? 1 : 2,
					status: 'new',
					criteriaTypeID: 1,
					criteriaTypeName: 'Entry Criteria',
					suggestionType: 'insertion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
					sequence: entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeID === 1).length + 1
				};
				const entryCriteriaJson = JSON.stringify(entryCriteria);
				setAddedEntrySequence(prev=>[...prev, {id:JSON.parse(entryCriteriaJson).id,
					sequence:JSON.parse(entryCriteriaJson).sequence,criteriaTypeID:JSON.parse(entryCriteriaJson).criteriaTypeID}])
				if(entryCriteriaReorderArray.length !== 0){
					const insertionId =  {id: entryCriteria.id, name: entryCriteria.name}
					setEntryCriteriaReorderArray(prev=> [...prev, JSON.stringify(insertionId)])
				  }
				 setEntryListwithInsert([...entryListwithInsert, entryCriteria])

				setEntryCriteriaSuggestions([
					...entryCriteriaSuggestions,
					entryCriteriaJson,
				]);
				setEntryCriteriaWithSuggestions([
					...entryCriteriaWithSuggestions,
					entryCriteriaJson,
				]);
				setInitialEntryCriteriaBeforeReorder([
					...initialEntryCriteriaBeforeReorder,
					entryCriteriaJson,
				]);

				setCriteriaName('');
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setError(false);
			}
		} else {
			setError(true);
		}
	};


	const updateInput = () => {
		if (inputDescription !== '' && inputDescription.trim().length !== 0) {
			if (inputEditMode) {
				if (inputList.some((item) => item.id === inputUpdateId)) {
					const updatedDtails = entryCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === inputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								suggestionType: 'updation',
								suggestionId: uniqueIdGenerator(),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: inputDescription,
								valueType: 0,
							})
							: item
					);
					setEntryCriteriaWithSuggestions([...updatedDtails]);

					if (
						!entryCriteriaSuggestions.some(
							(item) => JSON.parse(item).id === inputUpdateId
						)
					) {
						const entryCriteria = inputList
							.filter((item) => item.id === inputUpdateId)
							.map((value) => {
								return JSON.stringify({
									...value,
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: inputDescription,
									valueType: 0,
								});
							});
						setEntryCriteriaSuggestions((prevState) => [
							...prevState,
							...entryCriteria,
						]);
					} else {
						const updatedSuggestions = entryCriteriaSuggestions.map((item) =>
							JSON.parse(item).id === inputUpdateId
								? JSON.stringify({
									...JSON.parse(item),
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: inputDescription,
									valueType: 0,
								})
								: item
						);
						setEntryCriteriaSuggestions([...updatedSuggestions]);
					}
				} else {
					const updatedDtails = entryCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === inputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: inputDescription,
								valueType: 0,
							})
							: item
					);
					const updateSuggestions = entryCriteriaSuggestions.map((item) =>
						JSON.parse(item).id === inputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: inputDescription,
								valueType: 0,
							})
							: item
					);
					setEntryCriteriaSuggestions([...updateSuggestions]);
					setEntryCriteriaWithSuggestions([...updatedDtails]);
				}
				setInputEditMode(false);
				setInputDescription('');
				setInputUpdateId(0);
				setError(false);
			}
			else {
				const entryCriteria = {
					id: uniqueIdGenerator(),
					suggestionId: uniqueIdGenerator(),
					name: inputDescription,
					criteriaType: 'Entry',
					valueType: 0,
					status: 'new',
					criteriaTypeID: 3,
					criteriaTypeName: 'Input',
					suggestionType: 'insertion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
					sequence: entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeID === 3).length + 1
				};


				if(inputCriteriaReorderArray.length !== 0){
					const insertionId =  {id: entryCriteria.id, name: entryCriteria.name}
					setInputCriteriaReorderArray(prev=> [...prev, JSON.stringify(insertionId)])
				}
				setInputListwithInsert([...inputListwithInsert, entryCriteria])
				const entryCriteriaJson = JSON.stringify(entryCriteria);
				setAddedEntrySequence(prev=>[...prev, {id:JSON.parse(entryCriteriaJson).id,

					sequence:JSON.parse(entryCriteriaJson).sequence,criteriaTypeID:JSON.parse(entryCriteriaJson).criteriaTypeID}])

				
				setEntryCriteriaSuggestions([
					...entryCriteriaSuggestions,
					entryCriteriaJson,
				]);
				setEntryCriteriaWithSuggestions([
					...entryCriteriaWithSuggestions,
					entryCriteriaJson,
				]);
				setInitialInputCriteriaBeforeReorder([
					...initialInputCriteriaBeforeReorder,
					entryCriteriaJson,
				])
				setInputDescription('');
				setError(false);
			}
		} else {
			setError(true);
		}
	};
	const deleteOtherDetails = (item) => {
		const entryCriteria = {
			...item,
			suggestionId: uniqueIdGenerator(),
			suggestionType: 'deletion',
			authorId: CkEditorCurrentUser,
			createdAt: new Date(),
		};
		const entryCriteriaJson = JSON.stringify(entryCriteria);

		setEntryCriteriaSuggestions([
			entryCriteriaJson,
			...entryCriteriaSuggestions,
		]);

		const updatedSuggestions = entryCriteriaWithSuggestions.map((value) =>
			JSON.parse(value).id !== item.id
				? value
				: JSON.stringify({
					...JSON.parse(value),
					suggestionId: uniqueIdGenerator(),
					suggestionType: 'deletion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
				})
		);
		setEntryCriteriaWithSuggestions([...updatedSuggestions]);
	};

	const removeSuggestion = (value) => {
		const filteredValue = entryCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setEntryCriteriaSuggestions([...filteredValue]);
		if (
			pageType.includes(source) &&
			JSON.parse(value).authorId !== CkEditorCurrentUser
		) {
			setReviewedEntryCriteriaSuggestions([
				...reviewedEntryCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(entryContributionReorder && ckEditorCurrentEntryReorderUsername !== user.profile.firstName + ' '+user.profile.surname
			    && criteriaType === 'Entry Criteria' && JSON.parse(value).suggestionType==='insertion'){
			    const initialEntry = initialEntryCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
			    const filteredEntry = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Entry Criteria')
			    const currentEntry = filteredEntry.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialEntry.some((element, index) => element !== currentEntry[index])
				if(!isSimilar){
				  removeReorderedSuggestions('Entry');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			  }
		}
		if (
			source === 'approve'
			&& JSON.parse(value).authorId === CkEditorCurrentUser
			&& reviewerEntryCriteriaSuggestions?.some((item) => JSON.parse(item).id === JSON.parse(value).id)
		) {
			setReviewedEntryCriteriaSuggestions([
				...reviewedEntryCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
		}
		if (entryCriteriaList.some((item) => item.id === JSON.parse(value).id)) {
			const updatedList = entryCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...entryCriteriaList.filter(
							(item) => JSON.parse(value).id === item.id
						)[0],
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);

			setEntryCriteriaWithSuggestions([...updatedList]);
		    setInitialEntryIdBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))

		} else {

			const filteredList = entryCriteriaWithSuggestions.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			const initialEntryFilteredList = initialEntryCriteriaBeforeReorder.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			
			setEntryCriteriaWithSuggestions([...filteredList]);
			setInitialEntryCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
            setEntryListwithInsert([...initialEntryFilteredList.map(e=>JSON.parse(e))])
            setEntryCriteriaReorderArray(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
		}
	};
	const acceptSuggestion = (value) => {
		//setEntryCriteriaList([...entryCriteriaList, JSON.parse(item)]);
		if (JSON.parse(value).suggestionType === 'deletion') {
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(entryContributionReorder && ckEditorCurrentEntryReorderUsername !== user.profile.firstName + ' '+user.profile.surname
			    && criteriaType === 'Entry Criteria'){
				const initialEntry = initialEntryCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const filteredEntry = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Entry Criteria')
				const currentEntry = filteredEntry.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialEntry.some((element, index) => element !== currentEntry[index])
				if(!isSimilar){
				  removeReorderedSuggestions('Entry');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			}
			
			if (entryCriteriaList.some((item) => item.id === JSON.parse(value).id)) { 
				const updatedEntryCriteria = entryCriteriaList.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setEntryCriteriaList([...updatedEntryCriteria]);
				const updatedDataList = entryCriteriaData.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setEntryCriteriaData([...updatedDataList]);
				const filteredList = entryCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setEntryCriteriaWithSuggestions([...filteredList]);
				setInitialEntryCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
				if(entryCriteriaReorderArray !== null){
				  setEntryCriteriaReorderArray(prev=>prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
				}
			} else {
				const filteredList = entryCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setEntryCriteriaWithSuggestions([...filteredList]);
			}
		} else {
			const updatedList = entryCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...JSON.parse(item),
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);
			setEntryCriteriaWithSuggestions([...updatedList]);
			
			if (!entryCriteriaList.some((item) => item.id === JSON.parse(value).id)) {
				const criteria = {
					id: JSON.parse(value).id,
					name: JSON.parse(value).name,
					criteriaType: JSON.parse(value).criteriaType,
					valueType: JSON.parse(value).valueType,
					createdDate: JSON.parse(value).createdDate,
					createdBy: JSON.parse(value).authorId,
					criteriaTypeID: JSON.parse(value).criteriaTypeID,
					criteriaTypeName: JSON.parse(value).criteriaTypeName,
					sequence:entryCriteriaList.indexOf(entryCriteriaList.length-1) + 2
				};
				    let increment = 1;
				    const updatedEntryData = ([...entryCriteriaData, criteria])
					updatedEntryData.forEach((e,i)=>{
						if(e.criteriaTypeID === 1){
							e.sequence = increment;
                            increment ++
						} 
					})
				setEntryCriteriaData(updatedEntryData);
				setEntryCriteriaList([...entryCriteriaList, criteria]);
			} else {
				const updatedList = entryCriteriaList.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setEntryCriteriaList([...updatedList]);
				const updatedData = entryCriteriaData.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setEntryCriteriaData([...updatedData]);
			}
		}

		const filteredValue = entryCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setEntryCriteriaSuggestions([...filteredValue]);

		setReviewedEntryCriteriaSuggestions([
			...reviewedEntryCriteriaSuggestions,
			JSON.stringify({ id: JSON.parse(value).id, state: 'accepted' }),
		]);
	};

	const acceptEntryReorderSuggestion = ()=>{
		const isNotCurrentUser = entryCriteriaSuggestions.some(e=>JSON.parse(e).authorId !== CkEditorCurrentUser )
		if(entryCriteriaSuggestions && entryCriteriaSuggestions.length !==0 && isNotCurrentUser){
			toast.error('Please accept or reject other Entry/Input suggestions before accepting reorder', {
				position: toast.POSITION.TOP_RIGHT,
			  })
		} else {
		setReviewedReorderedEntrySuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'accepted',name:'Entry' })])
		  setEntryContributionReorder(false);		 
		  const currentEntryCriteria = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Entry Criteria')

		setInitialEntryCriteriaBeforeReorder(currentEntryCriteria)
		setReorderContributionCount((prev)=> prev - 1)
		}
	}
	
	const acceptInputReorderSuggestion = ()=>{
		const isNotCurrentUser = entryCriteriaSuggestions.some(e=>JSON.parse(e).authorId !== CkEditorCurrentUser )
		if(entryCriteriaSuggestions && entryCriteriaSuggestions.length !==0 && isNotCurrentUser){
			toast.error('Please accept or reject other Entry/Input suggestions before accepting reorder', {
				position: toast.POSITION.TOP_RIGHT,
			  })
		}else{
		setReviewedReorderedInputSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'accepted',name:'Input' })])
		  setInputContributionReorder(false);	
		  const currentInputCriteria = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		  === 'Input')
  
		  setInitialInputCriteriaBeforeReorder(currentInputCriteria)
		  setReorderContributionCount((prev)=> prev - 1)
		  }
			 
	}

	const removeReorderedSuggestions = (field) => {
        
		const parsedTasks = entryCriteriaWithSuggestions.map((item) => {
			return JSON.parse(item)
		})
		if (field === 'Entry') {
			const filteredEntryParsed = parsedTasks.filter((item) => {
				return item.criteriaTypeName === "Entry Criteria"
			})
			//Creating this for Concatination. Parse Not required
			const  filteredInputParsed = entryCriteriaWithSuggestions.filter((item) => {
				return JSON.parse(item).criteriaTypeName === "Input"
			})
			const parsedInitialEntryCriteriaBeforeReorder = initialEntryCriteriaBeforeReorder.map(e=>JSON.parse(e))
			const parsedArray = filteredEntryParsed.map((item) => {
				return JSON.stringify({
					...item,
					sequence: parsedInitialEntryCriteriaBeforeReorder.filter((v) => v.id === item.id)[0].sequence,
					authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				})
			})
			const beforeSortingArray = parsedArray.map((item) => {
				return JSON.parse(item)
			})
			beforeSortingArray.sort((a, b) => a.sequence - b.sequence)

			const afterSortAndStringify = beforeSortingArray.map((item) => {
				return JSON.stringify(item)
			})
			setEntryCriteriaWithSuggestions([...afterSortAndStringify.concat(filteredInputParsed)]);
			setEntryContributionReorder(false)
			setEntryCriteriaReorderArray([])
			if(source === 'review'){
				setIsReviewerEntryReordered(false)
			}
			if(source === 'approve'){
	            setIsApproverEntryReordered(false)
			}
			if (
				pageType.includes(source) && ckEditorCurrentEntryReorderUsername !== user.profile.firstName + ' '+user.profile.surname
				
			  ) {
				setReviewedReorderedEntrySuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'rejected',name:'Entry' })])
				setReorderContributionCount((prev)=> prev - 1)
			}
			setDisableEntryDrag(false)
		}
		else {
			const filteredInputParsed = parsedTasks.filter((item) => {
				return item.criteriaTypeName === "Input"
			})
            //Creating this for Concatination. Parse Not required

			const  filteredEntryParsed = entryCriteriaWithSuggestions.filter((item) => {
				return JSON.parse(item).criteriaTypeName === "Entry Criteria"
			})
			const parsedInitialInputCriteriaBeforeReorder = initialInputCriteriaBeforeReorder.map(e=>JSON.parse(e))
			const parsedArray = filteredInputParsed.map((item) => {
				return JSON.stringify({
					...item,
					sequence: parsedInitialInputCriteriaBeforeReorder.filter((v) => v.id === item.id)[0].sequence,
					authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				})
			})
			const beforeSortingArray = parsedArray.map((item) => {
				return JSON.parse(item)
			})
			beforeSortingArray.sort((a, b) => a.sequence - b.sequence)

			const afterSortAndStringify = beforeSortingArray.map((item) => {
				return JSON.stringify(item)
			})
			setEntryCriteriaWithSuggestions([...filteredEntryParsed.concat(afterSortAndStringify)]);
			setInputContributionReorder(false)
			setInputCriteriaReorderArray([])
			if(source === 'review'){
				setIsReviewerInputReordered(false)
			}
			if(source === 'approve'){
	            setIsApproverInputReordered(false)
			}
			if (pageType.includes(source) && ckEditorCurrentInputReorderUsername !== user.profile.firstName + ' '+user.profile.surname)
			  {
				setReviewedReorderedInputSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'rejected',name:'Input' })])
				setReorderContributionCount((prev)=> prev - 1)
			}
			setDisableInputDrag(false) 
		}
		
	}
	const removeInputSuggestion = (value) => {
		const filteredValue = entryCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setEntryCriteriaSuggestions([...filteredValue]);
		if (
			pageType.includes(source) &&
			JSON.parse(value).authorId !== CkEditorCurrentUser
		) {
			setReviewedEntryCriteriaSuggestions([
				...reviewedEntryCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(inputContributionReorder && ckEditorCurrentInputReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			   && criteriaType === 'Input' && JSON.parse(value).suggestionType==='insertion'){
				const initialInput = initialInputCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const filteredInput = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Input')
				const currentInput = filteredInput.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialInput.some((element, index) => element !== currentInput[index])
				if(!isSimilar){
				  removeReorderedSuggestions('Input');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			  }
		}
		if (
			source === 'approve'
			&& JSON.parse(value).authorId === CkEditorCurrentUser
			&& reviewerEntryCriteriaSuggestions?.some((item) => JSON.parse(item).id === JSON.parse(value).id)
		) {
			setReviewedEntryCriteriaSuggestions([
				...reviewedEntryCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
		}
		if (inputList.some((item) => item.id === JSON.parse(value).id)) {
			const updatedList = entryCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...inputList.filter(
							(item) => JSON.parse(value).id === item.id
						)[0],
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);

			setEntryCriteriaWithSuggestions([...updatedList]);
		} else {
			const filteredList = entryCriteriaWithSuggestions.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			const initialInputFilteredList = initialInputCriteriaBeforeReorder.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			
			setEntryCriteriaWithSuggestions([...filteredList]);
			setInitialInputCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
			setInputListwithInsert([...initialInputFilteredList.map(e=>JSON.parse(e))])
            setInputCriteriaReorderArray(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
		}
	};

	const acceptInputSuggestion = (value) => {
		//setEntryCriteriaList([...entryCriteriaList, JSON.parse(item)]);
		if (JSON.parse(value).suggestionType === 'deletion') {
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(inputContributionReorder && ckEditorCurrentInputReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			   && criteriaType === 'Input'){
				const initialInput = initialInputCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const filteredInput = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Input')
				const currentInput = filteredInput.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialInput.some((element, index) => element !== currentInput[index])
				if(!isSimilar){
				  removeReorderedSuggestions('Input');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			  }
			if (inputList.some((item) => item.id === JSON.parse(value).id)) {
				const updatedEntryCriteria = inputList.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setInputList([...updatedEntryCriteria]);
				const updatedDataList = entryCriteriaData.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setEntryCriteriaData([...updatedDataList]);
				const filteredList = entryCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setEntryCriteriaWithSuggestions([...filteredList]);
				setInitialInputCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
                if(inputCriteriaReorderArray !== null){
                setInputCriteriaReorderArray(prev=>prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
        }
			} else {
				const filteredList = entryCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setEntryCriteriaWithSuggestions([...filteredList]);
			}
		} else {
			const updatedList = entryCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...JSON.parse(item),
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);
			setEntryCriteriaWithSuggestions([...updatedList]);
			if (!inputList.some((item) => item.id === JSON.parse(value).id)) {
				const criteria = {
					id: JSON.parse(value).id,   
					name: JSON.parse(value).name,
					criteriaType: JSON.parse(value).criteriaType,
					valueType: JSON.parse(value).valueType,
					createdDate: JSON.parse(value).createdDate,
					createdBy: JSON.parse(value).authorId,
					criteriaTypeID: JSON.parse(value).criteriaTypeID,
					criteriaTypeName: JSON.parse(value).criteriaTypeName,
					sequence: inputList.indexOf(inputList.length-1) + 2
				};
				let increment = 1;
                const updatedInputData = ([...entryCriteriaData, criteria])
				updatedInputData.forEach((e,i)=>{
					if(e.criteriaTypeID === 3){
						e.sequence = increment;
						increment ++
					} 
				})
				setEntryCriteriaData(updatedInputData);
				setInputList([...inputList, criteria]);
			} else {
				const updatedList = inputList.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setInputList([...updatedList]);
				const updatedData = entryCriteriaData.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setEntryCriteriaData([...updatedData]);
			}
		}

		const filteredValue = entryCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setEntryCriteriaSuggestions([...filteredValue]);

		setReviewedEntryCriteriaSuggestions([
			...reviewedEntryCriteriaSuggestions,
			JSON.stringify({ id: JSON.parse(value).id, state: 'accepted' }),
		]);
	};

	const handleCriteriaDescription = (event) => {
		setCriteriaDescription(event.target.value);
	};

	const handleInputDescription = (event) => {
		setInputDescription(event.target.value);
	};

	$(document).ready(function () {
		$('ul.entry-criteria-tabs li').click(function () {
			var tab_id = $(this).attr('data-tab');
			setCurrentTab(tab_id);
			$('ul.entry-criteria-tabs li').removeClass('currentEntry');
			$('.entry-criteria-tab-content').removeClass('currentEntry');
			
			$(this).addClass('currentEntry');
			$('#' + tab_id).addClass('currentEntry');
		});
	});
	useEffect(()=>{
		const currentEntryCriteria = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Entry Criteria').map(e=>JSON.parse(e).id)

		const currentInputCriteria = entryCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Input').map(e=>JSON.parse(e).id)
		const arraysAreEqual =(arr1, arr2) => {
			if(!arr1 && !arr2){
				return false
			}
			const initial = arr1.map((e) => JSON.parse(e).id);
;			if (arr1.length !== initial.length) {
			  return false;
			}
		  return initial.some((element, index) => element !== arr2[index]);
		  }
        const entryHasChanged = arraysAreEqual(initialEntryCriteriaBeforeReorder, currentEntryCriteria)
		
		const inputHasChanged = arraysAreEqual(initialInputCriteriaBeforeReorder, currentInputCriteria)
		setEntryContributionReorder(entryHasChanged)
		setIsOrderChanged(prevState => ({
			...prevState,
			entry: entryHasChanged 
		  }))

		setInputContributionReorder(inputHasChanged)
		setIsOrderChanged(prevState => ({
			...prevState,
			input: inputHasChanged 
		  }))
	
	},[entryCriteriaWithSuggestions])



	const onDragEnd = (result, field) => {   
		if (!result.destination) return;
		const items = Array.from(entryCriteriaWithSuggestions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		let i = 1;
		let j = 1;
		let author;
		const entryArray = [];
		const inputArray = [];
		const finalArray = items.map((item) => {

			const parsedItem = JSON.parse(item)
			author = parsedItem.authorId


			if (parsedItem.criteriaTypeName === "Entry Criteria") {
				parsedItem.sequence = j;
				j++;
				entryArray.push({ id: parsedItem.id,name:parsedItem.name,authorId: [user.profile.firstName + ' '+user.profile.surname,user.profile.photo],
					createdAt: new Date()})                                 
			} else {
				parsedItem.sequence = i;
				i++;
				inputArray.push({ id: parsedItem.id,name:parsedItem.name,authorId: [user.profile.firstName + ' '+user.profile.surname,user.profile.photo],
					createdAt: new Date() })

			}
			return JSON.stringify(parsedItem)
		})
		  const entryArrayString = entryArray.map(e => JSON.stringify(e))
		  const inputArrayString = inputArray.map(e => JSON.stringify(e))
		  
		  if (field === 'Entry Criteria') {
			setEntryCriteriaReorderArray(entryArrayString)
			setCkEditorCurrentEntryReorderUserImage(user.profile.photo)
			setCkEditorCurrentEntryReorderUsername(user.profile.firstName + ' '+user.profile.surname)
			if(source === 'review'){
				setIsReviewerEntryReordered(true)
			}
			if(source === 'approve'){
				setIsApproverEntryReordered(true)
			}
		} else {
			setInputCriteriaReorderArray(inputArrayString)
			setCkEditorCurrentInputReorderUserImage(user.profile.photo)
			setCkEditorCurrentInputReorderUsername(user.profile.firstName + ' '+user.profile.surname)
			if(source === 'review'){
				setIsReviewerInputReordered(true)
			}
			if(source === 'approve'){
	            setIsApproverInputReordered(true)
			}
		}
		setEntryCriteriaWithSuggestions(finalArray);
	};

    
	const renderSuggestions = () => {
		return (
			<div
				className={`toolsSidebar ${currentTab === 'tab-entry' ? '' : 'hiddenEntryCriteria'
					} `}
				style={{ height: toolsSidebarHeight }}
			>
				{
					entryContributionReorder && <>
						<div className='sideBarCard userAddedItem'>
							<div className="sideBarCardHeader">
								<img
									className="userImg"
									src={ckEditorCurrentEntryReorderUserImage}
									alt=""
								/>
								<span>{ckEditorCurrentEntryReorderUsername} </span>
								<Icon
									title="Reject Suggestion"
									ariaLabel={`Click to reject the Reorder`}
									role="button"
									iconName="Cancel"
									onClick={() => removeReorderedSuggestions('Entry')}
									onKeyPress={(e) => {
										if (e.which === 13) {
											removeReorderedSuggestions('Entry');
										}
									}}
									tabIndex={0}
								/>
							 {pageType.includes(source) &&
							 ckEditorCurrentEntryReorderUsername !== user.profile.firstName + ' '+user.profile.surname &&
                                 (
                                        <Icon
                                  title="Accept Suggestion"
                                  iconName="Accept"
                                  onClick={() => acceptEntryReorderSuggestion()}
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      acceptEntryReorderSuggestion();
                                    }
                                  }}
                                  ariaLabel={'click to accept as suggestion'}
                                  role="button"
                                  tabIndex={0}
                                />
                              
                              )}
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description: Entry Criteria has been Reordered"
								</div>

							</div>
						</div>

					</>
				}
				{entryCriteriaSuggestions &&
					entryCriteriaWithSuggestions.map((card) => (
						<>
							{JSON.parse(card).criteriaTypeName === 'Entry Criteria' &&
								(JSON.parse(card).suggestionType === 'none' ? (
									<></>
								) : (
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>
												{ckEditorUserMap[JSON.parse(card).authorId][0]}
											</span>

											{JSON.parse(card).authorId === CkEditorCurrentUser ||
												(pageType.includes(source) &&
													JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
												<Icon
													title="Reject Suggestion"
													ariaLabel={`Click to reject ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													iconName="Cancel"
													onClick={() => removeSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeSuggestion(card);
														}
													}}
													tabIndex={0}
												/>
											) : (
												<></>
											)}
											{pageType.includes(source) &&
												JSON.parse(card).authorId !== CkEditorCurrentUser ? (
												<Icon
													title="Accept Suggestion"
													iconName="Accept"
													onClick={() => acceptSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															acceptSuggestion(card);
														}
													}}
													ariaLabel={`Click to Accept ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													tabIndex={0}
												/>
											) : (
												<></>
											)}
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'insertion'
													? 'Add'
													: JSON.parse(card).suggestionType === 'deletion'
														? 'Remove'
														: 'Edit'}
											</span>
											&nbsp;"Text/Numeric:
											{JSON.parse(card).valueType === 1
												? 'Text'
												: 'Numeric'}{' '}
											Description:{JSON.parse(card).name}"
										</div>
									</div>
								))}
						</>
					))}
			</div>
		);
	};
	const renderInputSuggestions = () => {
		return (
			<div
				className={`toolsSidebar ${currentTab === 'tab-entry' ? 'hiddenExitCriteria' : ''
					} `}
				style={{ height: toolsSidebarHeight }}
			>
				{
					inputContributionReorder && <>
						<div className='sideBarCard userAddedItem'>
							<div className="sideBarCardHeader">
								<img
									className="userImg"
									src={ckEditorCurrentInputReorderUserImage}
									alt=""
								/>
								<span>{ckEditorCurrentInputReorderUsername}</span>
								<Icon
									title="Reject Suggestion"
									ariaLabel={`Click to reject the Reorder`}
									role="button"
									iconName="Cancel"
									onClick={() => removeReorderedSuggestions('Input')}
									onKeyPress={(e) => {
										if (e.which === 13) {
											removeReorderedSuggestions('Input');
										}
									}}
									tabIndex={0}
								/>
								{pageType.includes(source) &&
								ckEditorCurrentInputReorderUsername !== user.profile.firstName + ' '+user.profile.surname &&
                               (
                               <Icon
                                 title="Accept Suggestion"
                                 iconName="Accept"
                                 onClick={() => acceptInputReorderSuggestion()}
                                 onKeyPress={(e) => {
                                   if (e.which === 13) {
									acceptInputReorderSuggestion();
                                   }
                                 }}
                                 ariaLabel={'click to accept as suggestion'}
                                 role="button"
                                 tabIndex={0}
                               />
                             
                             )}
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description: Input Criteria has been Reordered"
								</div>

							</div>
						</div>
					</>
				}
				{entryCriteriaSuggestions &&
					entryCriteriaWithSuggestions.map((card) => (
						<>
							{JSON.parse(card).criteriaTypeName === 'Input' &&
								(JSON.parse(card).suggestionType === 'none' ? (
									<></>
								) : (
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>
												{ckEditorUserMap[JSON.parse(card).authorId][0]}
											</span>

											{JSON.parse(card).authorId === CkEditorCurrentUser ||
												(pageType.includes(source) &&
													JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
												<Icon
													title="Reject Suggestion"
													ariaLabel={`Click to reject ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													iconName="Cancel"
													onClick={() => removeInputSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeInputSuggestion(card);
														}
													}}
													tabIndex={0}
												/>
											) : (
												<></>
											)}
											{pageType.includes(source) &&
												JSON.parse(card).authorId !== CkEditorCurrentUser ? (
												<Icon
													title="Accept Suggestion"
													iconName="Accept"
													onClick={() => acceptInputSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															acceptInputSuggestion(card);
														}
													}}
													ariaLabel={`Click to Accept ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													tabIndex={0}
												/>
											) : (
												<></>
											)}
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'insertion'
													? 'Add'
													: JSON.parse(card).suggestionType === 'deletion'
														? 'Remove'
														: 'Edit'}
											</span>
											&nbsp;"
											{JSON.parse(card).name}"
										</div>
									</div>
								))}
						</>
					))}
			</div>
		);
	};

	return (
		<>
			<div className="topicDetailsCkEditor entryExitCriTab">
				<div className="criteria-tab-container">
					{error && (
						<span className="errorMsg">Type and Description are required</span>
					)}
					<ul className="entry-criteria-tabs" role="tablist">
						<li
							className={`tab-link ${currentTab === 'tab-entry' ? 'currentEntry' : ''}`}
							data-tab="tab-entry"
							tabIndex={0}
							role="tab"
							aria-selected={currentTab === 'tab-entry'}
							aria-label="Entry Criteria"
						>
							Entry Criteria
						</li>
						<li
							className={`tab-link ${currentTab === 'tab-input' ? 'currentEntry' : ''}`}
							data-tab="tab-input"
							tabIndex={0}
							role="tab"
							aria-selected={currentTab === 'tab-input'}
							aria-label="Input"
						>
							Input
						</li>
					</ul>

					<div
						id="tab-entry"
						className="entry-criteria-tab-content currentEntry"
					>
						<div
							className={
								entryCriteriaButtonClicked
									? 'add-details '
									: 'add-details hiddenEntryCriteria'
							}
						>
							<Dropdown
								className="entryCriteriadropdown"
								data={dropdownValue}
								value={criteriaType}
								setValue={setCriteriaType}
								enableDropdown={true}
							/>
							<span><div class="tooltipInfoContribution"><Icon className="infoIcon" iconName="Info" />
								<span class="tooltiptextContribution">Select "numeric" for quantifiable entry criteria/input; Select "text" for everything else.</span>
							</div></span>
							<input
								type="text"
								placeholder="Enter Description"
								className="add-description"
								value={criteriaDescription}
								onChange={(e) => handleCriteriaDescription(e)}
							></input>
							<button
								className="add-button"
								onClick={() => {
									updateEntryCriteria();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										updateEntryCriteria();
									}
								}}
							>
								Add{' '}
								<Icon className="add-icon" iconName="BoxAdditionSolid"></Icon>
							</button>

							<button
								className="add-button"
								onClick={() => {
									setEntryCriteriaButtonClicked(false);
									setCriteriaDescription('');
									setCriteriaName('');
									setCriteriaType(defaultCriteriType);
									setError(false);
									setUpdateId(0);
									setEditMode(false);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEntryCriteriaButtonClicked(false);
										setCriteriaDescription('');
										setCriteriaName('');
										setCriteriaType(defaultCriteriType);
										setError(false);
										setUpdateId(0);
										setEditMode(false);
									}
								}}
							>
								Cancel
							</button>
						</div>

						<div className="entry-cri-main-container">
							<DragDropContext onDragEnd={result=> onDragEnd(result, 'Entry Criteria')}>
								<div className="table-container">
									<div className="table-row heading">
										<div className="row-item w15">Text/Numeric</div>
										<div className="row-item w75">Description</div>
										<div className="row-item w10">Edit/Delete</div>
									</div>
									<Droppable droppableId='EntryCriteriaContributionList'>
										{
											(provided) => (
												<div ref={provided.innerRef} {...provided.droppableProps}>
													{entryCriteriaWithSuggestions.map((value, index) => (
														<>
															<Draggable key={value} draggableId={value} index={index} isDragDisabled={disableEntryDrag}>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																	>
																		{JSON.parse(value).criteriaTypeName ===
																			'Entry Criteria' && (
																				<div
																					className={`table-row  ${JSON.parse(value).suggestionType === 'none'
																						? ``
																						: JSON.parse(value).suggestionType ===
																							'insertion' ||
																							JSON.parse(value).suggestionType === 'updation'
																							? `addedCriteria`
																							: `deletedCriteria`
																						}`}
																				>
																					<div className="row-item w15">
																						<div className="entryNameAndTypeDiv">
																							<div className="criteriaType">
																								<span key={JSON.parse(value).id} />
																								{JSON.parse(value).valueType === 1
																									? 'Text'
																									: 'Numeric'}
																							</div>
																						</div>
																					</div>
																					<div className="row-item w75">
																						<div
																							className="descEn"
																							id="entryCriteria"
																							dangerouslySetInnerHTML={{
																								__html: htmlSanitizer(JSON.parse(value).name),
																							}}
																						/>
																					</div>

																					<div className="row-item edit-delete-column w10">
																						<Icon
																						    title='Edit'
																							aria-label="Edit Criteria"
																							tabIndex={0}
																							iconName="EditSolid12"
																							className="edit-icon"
																							onClick={() => {
																								setCriteriaName(
																									htmlSanitizer(JSON.parse(value).name)
																								);
																								setCriteriaDescription(
																									htmlSanitizer(JSON.parse(value).name)
																								);
																								setCriteriaType({
																									id: JSON.parse(value).valueType,
																									name: defaultCriteriaTypeValue[
																										JSON.parse(value).valueType
																									],
																									type: 'option',
																								});
																								setEditMode(true);
																								setUpdateId(JSON.parse(value).id);
																								setError(false);
																								setEntryCriteriaButtonClicked(true);
																							}}
																							onKeyDown={(e)=>{
																								if(e.key === 'Enter' || e.key === ' '){
																									setCriteriaName(
																										htmlSanitizer(JSON.parse(value).name)
																									);
																									setCriteriaDescription(
																										htmlSanitizer(JSON.parse(value).name)
																									);
																									setCriteriaType({
																										id: JSON.parse(value).valueType,
																										name: defaultCriteriaTypeValue[
																											JSON.parse(value).valueType
																										],
																										type: 'option',
																									});
																									setEditMode(true);
																									setUpdateId(JSON.parse(value).id);
																									setError(false);
																									setEntryCriteriaButtonClicked(true);
																								}
																							}}
																						/>
																						{JSON.parse(value).suggestionType !== 'insertion' ? (
																							<Icon
																							aria-label="Delete Criteria"
																								tabIndex={0}
																								title="DeleteEntry"
																								iconName="Cancel"
																								className="cancel-icon"
																								onClick={() => {
																									deleteOtherDetails(JSON.parse(value));
																									setEditMode(false);
																									setCriteriaDescription('');
																									setCriteriaType(defaultCriteriType);
																									setError(false);
																									setUpdateId(0);
																								}}
																								onKeyDown={(e)=>{
																									if(e.key === 'Enter' || e.key === ' '){
																										deleteOtherDetails(JSON.parse(value));
																										setEditMode(false);
																										setCriteriaDescription('');
																										setCriteriaType(defaultCriteriType);
																										setError(false);
																										setUpdateId(0);
																									}
																								}}
																							/>
																						) : (
																							<></>
																						)}
																					</div>
																				</div>
																			)}
																	</div>
																)}
															</Draggable>

														</>
													))}
													{provided.placeholder}
												</div>
											)
										}
									</Droppable>

								</div>
							</DragDropContext>
						</div>
					</div>
					<div id="tab-input" className="entry-criteria-tab-content">
						<div
							className={
								entryCriteriaButtonClicked
									? 'add-details '
									: 'add-details hiddenEntryCriteria'
							}
						>
							<input
								type="text"
								placeholder="Enter Description"
								className="add-description"
								value={inputDescription}
								onChange={(e) => handleInputDescription(e)}
							></input>
							<button
								className="add-button"
								onClick={() => {
									updateInput();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										updateInput();
									}
								}}
							>
								Add{' '}
								<Icon className="add-icon" iconName="BoxAdditionSolid"></Icon>
							</button>
							<button
								className="add-button"
								onClick={() => {
									setEntryCriteriaButtonClicked(false);
									setInputDescription('');
									setError(false);
									setInputUpdateId(0);
									setEditMode(false);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEntryCriteriaButtonClicked(false);
										setInputDescription('');
										setError(false);
										setInputUpdateId(0);
										setInputEditMode(false);
									}
								}}
							>
								Cancel
							</button>
						</div>
						<div className="entry-cri-main-container">
							<DragDropContext onDragEnd={result=> onDragEnd(result, 'Input')}>
								<div className="table-container">
									<div className="table-row heading">
										<div className="row-item w90">Description</div>
										<div className="row-item w10">Edit/Delete</div>
									</div>
									<Droppable droppableId='InputListContributionList'>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.droppableProps}>
												{entryCriteriaWithSuggestions.map((value, index) => (
													<>
														<Draggable key={value} draggableId={value} index={index} isDragDisabled={disableInputDrag}>
															{(provided) => (
																<div
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	ref={provided.innerRef}
																>
																	{JSON.parse(value).criteriaTypeName === 'Input' && (
																		<div
																			className={`table-row  ${JSON.parse(value).suggestionType === 'none'
																				? ``
																				: JSON.parse(value).suggestionType ===
																					'insertion' ||
																					JSON.parse(value).suggestionType === 'updation'
																					? `addedCriteria`
																					: `deletedCriteria`
																				}`}
																		>
																			<div className="row-item w90">
																				<div
																					className="descEn"
																					id="entryCriteria"
																					dangerouslySetInnerHTML={{
																						__html: htmlSanitizer(JSON.parse(value).name),
																					}}
																				/>
																			</div>

																			<div className="row-item edit-delete-column w10">
																				<Icon
																					iconName="EditSolid12"
																					className="edit-icon"
																					onClick={() => {
																						setInputDescription(
																							htmlSanitizer(JSON.parse(value).name)
																						);
																						setInputEditMode(true);
																						setInputUpdateId(JSON.parse(value).id);
																						setError(false);
																						setEntryCriteriaButtonClicked(true);
																					}}
																				/>
																				{JSON.parse(value).suggestionType !== 'insertion' ? (
																					<Icon
																						title="DeleteEntry"
																						iconName="Cancel"
																						className="cancel-icon"
																						onClick={() => {
																							deleteOtherDetails(JSON.parse(value));
																							setInputEditMode(false);
																							setInputDescription('');
																							setError(false);
																							setInputUpdateId(0);
																						}}
																						tabIndex={0}
																					/>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	)}
																</div>
															)}
														</Draggable>
													</>
												))}
												{provided.placeholder}
											</div>

										)}
									</Droppable>


									{/* {inputList.map((value, index) => (
									<div className="table-row">
										<div className="row-item">
											{contributorsData.entryCriteriaList ? (
												<div
													className="descEn"
													id="entryCriteria"
													dangerouslySetInnerHTML={{
														__html: htmlSanitizer(value.name),
													}}
												/>
											) : null}
										</div>
										<div className="row-item edit-delete-column">
											<Icon
												iconName="EditSolid12"
												className="edit-icon"
												onClick={() => {
													//handelFormView('entryCriteria');
													setInputDescription(value.name);
													setCriteriaName(value.name);
													setCriteriaType({
														id: value.valueType,
														name: defaultCriteriaTypeValue[value.valueType],
														//type: 'option',
													});
													setEditMode(true);
													setUpdateId(value.id);
													setError(false);
													setEntryCriteriaButtonClicked(true);
													// if (showFields.includes('entryCriteria')) {
													// 	handelFormView('entryCriteria');
													// }
												}}
											></Icon>
											<Icon
												title="DeleteEntry"
												iconName="Cancel"
												className="cancel-icon"
												onClick={() => {
													deleteOtherDetails(value.id);
													setEditMode(false);
													setCriteriaDescription('');
												}}
												tabIndex={0}
											/>
										</div>
									</div>
								))} */}
								</div>
							</DragDropContext>

						</div>
						{/* <textarea rows="7" cols="80"></textarea> */}
					</div>
				</div>
				
				{renderSuggestions()}
				{renderInputSuggestions()}
			</div>
		</>
	);
};

export default EntryCriteriaContribution;
