import React, { useState, useEffect } from "react";
import { Icon } from "@fluentui/react";
import { useParams, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import { getUsersList, getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import UserSelectionDropdown from "../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown";
import OwnerSelectionDropdown from '../TopicsBuilder/OwnerSelectionDropdown';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from "../../context/TelemetryContext";
import HashtagSuggestions from "../HashtagSuggestions";
import ProcessGroupDropdown from '../TopicsBuilder/ProcessGroupDropdown';
import AddTools from '../TopicsBuilder/AddTools';
import CKEditor from '../TopicsBuilder/CkEditor';
import AddRoles from '../TopicsBuilder/AddRoles';
import PolygonPreviewModal from './PolygonPreviewModal';
import PolygonResponseModal from "./PolygonResponseModal";
import Spinner from "../../helpers/Spinner";
import SaveSpinner from "../../helpers/CrowdSourcingSpinner";
import DiscardModalPolygon from "./DiscardModalPolygon";
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {
    handleTernaryCondition,
    titleValidator,
    urlValidator,
} from "../../helpers/Validation";
import { artifactMapData } from "../../static/ArtifactMapData";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import Breadcrumbs from "../Breadcrumbs";
import TopicLockModalMessage from "../TopicsBuilder/LockModal";
import AdminModal from '../TopicsBuilder/AdminMessage';
import Dropdown from '../TopicsBuilder/Dropdown';
import TopicPublishModal from '../TopicsBuilder/TopicPublishModal';
import { MessageBar, MessageBarType } from "@fluentui/react";
import TopicPublishCancelModal from "../TopicsBuilder/TopicPublishCancelModal";
import { TabTitle } from "../../utils/GeneralFunctions";
import TemporaryCkAlert from "../CkEditor/TemporaryCkAlert";
import Shapes from "./Shapes";
import './PolygonTopic.css';
import { isError } from "@ms/centro-hvc-loader";

const PolygonBuilder = ({
    selectedSection,
    count,
    setPolygonSelectedTab,
    setTopicsData,
    setTopics,
    topics,
    setPolygonTopicTitle,
    polygonTopicTitle,
    topicsData,
    setIsBack,
    setDiscardTopic,
    discardTopic
}) => {
    const versionTypes = [
        {
            id: "majorVersion",
            label: "Major Version",
            increment: 1.0,
        },
        {
            id: "minorVersion",
            label: "Minor Version",
            increment: 0.01,
        },
        // {
        //   id: "noChange",
        //   label: "No Change",
        //   increment: 0.0,
        // },
    ];
    const tabs = [
        { id: 1, name: "Topic Details" },
        { id: 2, name: "Sequence" },
    ];
    const source = window.location.search
        ? queryString.parse(window.location.search).source === "proc"
        : null;
    const { track } = useAppInsights();
    const { categoryId } = useParams();
    const history = useHistory();
    const { breadcrumbs, setBreadcrumbs, isAdmin } = useLeftMenuCollapse();
    const { userAlias, axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
    const [topicTitle, setTopicTitle] = useState('');
    const [description, setDescription] = useState("");
    const [content, setContent] = useState("");
    const [relatedContent, setRelatedContent] = useState([]);
    const [hashtagsInput, setHashtagsInput] = useState("");
    const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
    const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
    const [userAddedHashtags, setUserAddedHashtags] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [reviewers, setReviewers] = useState([]);
    const [coOwners, setCoOwners] = useState([]);
    const [reviewerEmail, setReviewerEmail] = useState("");
    const [approverEmail, setApproverEmail] = useState("");
    const [coOwnwerEmail, setCoOwnwerEmail] = useState("");
    const [emailFound, setEmailFound] = useState({
        coOwner: false,
        approver: false,
        reviewer: false,
        topicOwner: false,
    });
    const [error, setError] = useState({
        coOwner: false,
        approver: false,
        reviewer: false,
        topicOwner: false,
    });
    const [usersList, setUsersList] = useState({
        coOwner: [],
        reviewer: [],
        approver: [],
        topicOwner: [],
    });
    const [processGroups, setProcessGroups] = useState([]);
    const [selectedProcessGroup, setSelectedProcessgroup] = useState([]);
    const [toolsData, setToolsData] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [toolSearchValue, setToolSearchValue] = useState("");
    const [toolsMapper, setToolsMapper] = useState({});
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [roleSearchValue, setRoleSearchValue] = useState("");
    const [relatedContentTitle, setRelatedContentTitle] = useState("");
    const [relatedContentUrl, setRelatedContentUrl] = useState("");
    const [isVisible, setIsVisible] = useState(true);
    const [isCrowdSourceable, setIsCrowdSourceable] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [message, setMessage] = useState("");
    const [showSaveSpinner, setShowSaveSpinner] = useState(false);
    const [imagePaths, setImagePaths] = useState([]);
    const [topicVersion, setTopicVersion] = useState("1.0");
    const [relatedContentEditMode, setRelatedContentEditMode] = useState(false);
    const [relatedContentUpdateInfo, setRelatedContentUpdateInfo] = useState({});
    const [pageLockUser, setPageLockUser] = useState(null);
    const [lockEmailId, setLockEmailId] = useState(null);
    const [isTopicCrowdSourced, setIsTopicCrowdSourced] = useState(false);
    const [isPageLock, setIsPageLock] = useState(false);
    const [topicOwnerInfo, setTopicOwnerInfo] = useState({});
    const [topicOwnerEmail, setTopicOwnerEmail] = useState("");
    const [isOwnerOrCoOwner, setIsOwnerOrCoOwner] = useState(true);
    const [topicType, setTopicType] = useState(3);
    const [sequence, setSequence] = useState(0);
    const [isCategoryOwnerOrCoOwner, setIsCategoryOwnerOrCoOwner] = useState(false);
    // const [topics, setTopics] = useState([]);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const topicTypes = [
        { id: 1, name: "Visio" },
        { id: 2, name: "Url" },
        { id: 3, name: "Plain Text" },
    ];
    // version type
    const [selectedVersionType, setSelectedVersionType] = useState({
        id: "minorVersion",
        label: "Minor Version",
        increment: 0.01,
    });
    const [selectedTab, setSelectedTab] = useState(1);
    const [topicCategories, setTopicCategories] = useState([]);
    const [updatedCategoryId, setUpdatedCategoryId] = useState(categoryId);
    const [publishScheduledDateTime, setPublishScheduledDateTime] = useState("");
    const [showTopicPublishCancelModal, setShowTopicPublishCancelModal] = useState(false);
    const [businessRelatedContentTitle, setBusinessRelatedContentTitle] = useState("");
    const [businessRelatedContentUrl, setBusinessRelatedContentUrl] = useState("");
    const [businessRelatedContent, setBusinessRelatedContent] = useState([]);
    const [businessRelatedContentEditMode, setBusinessRelatedContentEditMode] = useState(false);
    const [updatedId, setUpdateId] = useState(0)
    const [selectedPath, setSelectedPath] = useState(0)
    const [selectedTopicId, setSelectedTopicId] = useState();
    const [topicId, setTopicId] = useState(topicsData.filter(e => e.polygonSequence === 0)[0].topicId)
    const [topicData, setTopicData] = useState([])
    const [savedTopic, setSavedTopic] = useState(false);
    const [showLinking, setLinking] = useState(false);
    const [showRelatedContentModal, setShowRelatedContentModal] = useState(false);
    const [showBusinessRuleModal, setShowBusinessRuleModal] = useState(false);
    const [showRelatedContentUrlError, setShowRelatedContentUrlError] = useState(false);
    const [showRelatedContentTitleError, setShowRelatedContentTitleError] = useState(false);
    const [showRelatedContentUrlErrorMsg, setShowRelatedContentUrlErrorMsg] = useState("");
    const [showRelatedContentTitleErrorMsg, setShowRelatedContentTitleErrorMsg] = useState("");
    const [showRelatedContentIndexError, setShowRelatedContentIndexError] = useState();
    const [showBusinessRuleUrlError, setShowBusinessRuleUrlError] = useState(false);
    const [showBusinessRuleTitleError, setShowBusinessRuleTitleError] = useState(false);
    const [showBusinessRuleUrlErrorMsg, setShowBusinessRuleUrlErrorMsg] = useState("");
    const [showBusinessRuleTitleErrorMsg, setShowBusinessRuleTitleErrorMsg] = useState("");
    const [showBusinessRuleIndexError, setShowBusinessRuleIndexError] = useState();

    const [businessRuleUpdatedArray, setBusinessRuleUpdatedArray] = useState([]);
    const [businessRuleOrgContent, setBusinessRuleOrgContent] = useState([]);
    const [activeKey, setActiveKey] = useState('');
    const [activeKeyRelativeContent, setactiveKeyRelativeContent] = useState('');
    const [activeKeyBusinessRule, setactiveKeyBusinessRule] = useState('');
    const [updateApiCall, setUpdateApiCall] = useState(false);

    const formatPublishDate = (publishDate) => {
        const date = new Date(publishDate);
        return date.toLocaleString();
    };
    const getTopicsCategoryDataForAdmin = async () => {
        let check = topicId
        const response = await axiosGetService(
            `api/admin/topics/${topicId}?pageSize=10&pageNumber=1&id=${topicId}&searchValue=""`
        );
        setTopicCategories(
            response.data.adminCategory.map((topicCategory) => {
                return {
                    id: topicCategory.id,
                    name: topicCategory.displayName,
                    categoryType: topicCategory.categoryTypeId,
                };
            })
        );
    };
    const getTopicOwnerInfo = async (email) => {
        const accessToken = await axiosTokenService();
        await getOtherUserProfile(accessToken, email).then((response) => {
            setTopicOwnerInfo(response);
        });
    };

    const removeUser = (email, value, setValue) => {
        const updatedList = value.filter(({ mail }) => mail !== email);
        setValue(updatedList);
    };

    const getUserInfo = async (userEmail, setValue) => {
        const accessToken = await axiosTokenService();
        await getOtherUserProfile(accessToken, userEmail).then((response) => {
            setValue((prevState) => [...prevState, response]);
        });
    };

    const getCollaboratorsInfo = async (membersList, setValue) => {
        for (const userEmail of membersList) {
            getUserInfo(userEmail, setValue);
        }
    };

    const getUser = async (input, type) => {
        const accessToken = await axiosTokenService();
        await getUsersList(accessToken, input).then((response) => {
            if (!response.error) {
                setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
            } else {
                setError({ ...error, [type]: true });
            }
        });
    };

    const hashtagsAutoComplete = async () => {
        await axiosGetService(
            `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
                hashtagSuggestionInput
            )}`
        ).then((response) => {
            setHashtagSuggestions(response.data);
        });
    };

    const submitHashtags = () => {
        // Don't do anything
    };

    const getProcessGroup = async () => {
        await axiosGetService(`api/sdmprocessgroups/getallforactivity`).then(
            (response) => {
                const data = response.data.map((item) => {
                    return {
                        name: item.name,
                        id: item.id,
                    };
                });
                setProcessGroups(data);
            }
        );
    };

    const getTools = async () => {
        await axiosGetService(
            `api/sdmtools/getAllWithDrafts?searchValue=${toolSearchValue ? toolSearchValue : 0
            }`
        ).then((response) => {
            const data = response.data;
            const tools = data.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    url: item.url,
                    isSelected: item.isSelected,
                };
            });
            if (Object.keys(toolsMapper).length === 0) {
                const toolsIdMap = {};
                for (const tool of data) {
                    toolsIdMap[tool.id] = {
                        name: tool.name,
                        url: tool.url,
                    };
                }
                setToolsMapper(toolsIdMap);
            }
            setToolsData(tools);
        });
    };

    const getRoles = async () => {
        await axiosGetService(`api/sdmphase/getallroles`).then((response) => {
            const roleIds = response.data.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
            setRoles(roleIds);
        });
    };

    const getTopicDetails = async () => {
        const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
        await axiosGetService(`api/sdmtopics/gettopicbyid/${filteredTopicId}`).then(response => {
            // getCollaboratorsInfo(response.data.reviewerMembersList, setReviewers);
            // getCollaboratorsInfo(response.data.approverMembersList, setApprovers);
            // getCollaboratorsInfo(response.data.coOwnerMembersList, setCoOwners);
            setTopicTitle(response.data.topicName);
            setPolygonTopicTitle(response.data.topicName);
            setContent(response.data.diagramName ? response.data.diagramName : "");
            setDescription(
                response.data.description ? response.data.description : ""
            );
            const hashTagsList = response.data.hashTagsList.map(({ name }) => {
                return name;
            });
            const exisitngProcessGroup = response.data.processGroupsList.map(
                (item) => {
                    return { id: item.id, name: item.name };
                }
            );
            const exisitngRoles = response.data.rolesList.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
            const existingRelatedContent =
                response.data.topicRelatedContentList.map((item) => {
                    return {
                        title: item.title,
                        url: item.url,
                    };
                });
            setTopicData(response.data)
            setUserAddedHashtags(hashTagsList);
            setSelectedRoles(exisitngRoles);
            setSelectedProcessgroup(exisitngProcessGroup);
            setIsVisible(response.data.isVisible);
            setIsCrowdSourceable(response.data.isCrowdSourceable);
            setImagePaths(response.data.imagePaths);
            setTopicVersion(response.data.topicVersion);
            setRelatedContent([...existingRelatedContent, { title: "", url: "" }]);
            setIsTopicCrowdSourced(response.data.isCrowdSourced);
            // setIsOwnerOrCoOwner(response.data.isOwnerOrCoOwner);
            setSequence(response.data.sequence);
            setTopicType(response.data.topicType);
            // if (response.data.owner) {
            //     getTopicOwnerInfo(response.data.owner);
            // }
            setIsCategoryOwnerOrCoOwner(response.data.isCategoryOwnerOrCoOwner);
            setUpdatedCategoryId(response.data.categoryId);
            setPublishScheduledDateTime(
                response.data.publishScheduledDateTimeString
            );
        })
        setShowSpinner(false);
        window.scroll(0, 0);
    };

    // load and set the breadcrumb
    const loadBreadcrumb = async () => {
        await axiosGetService(
            `api/sdmuser/getbreadcrumb?itemId=${topicId}&itemTypeId=10
			&isEdit=true&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false
			&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
        ).then((response) => {
            const breadcrumbTitle = response.data
                .split(">")
                .map((item) => item.trim());
            const topicsBreadcrumb = {
                title: breadcrumbTitle[1],
                path:
                    breadcrumbTitle[1] === "Job Aides"
                        ? "/topics?topicType=job&selectedJobAides=2"
                        : breadcrumbTitle[1] === "Readiness"
                            ? "/topics?topicType=read&selectedReadiness=2"
                            : "/topics",
                parent: ["home", "myContributions"],
                type: "topics",
            };
            // const topicsCategoryBreadcrumb = {
            //     title: breadcrumbTitle[2],
            //     path:
            //         breadcrumbTitle[1] === "Job Aides"
            //             ? `/topics?topicType=job&selectedJobAides=2`
            //             : breadcrumbTitle[1] === "Readiness"
            //                 ? `/topics?topicType=read&selectedReadiness=2`
            //                 : `/topics`,
            //     parent: "topics",
            //     type: "topicType",
            // };
            const currentTopicsContentBreadcrumb = {
                title: breadcrumbTitle[2],
                parent: ["pageTitle"],
            };

            const newBreadcrumbs = breadcrumbs.slice(0, 1);
            newBreadcrumbs.push(topicsBreadcrumb);
            // newBreadcrumbs.push(topicsCategoryBreadcrumb);
            newBreadcrumbs.push(currentTopicsContentBreadcrumb);
            setBreadcrumbs(newBreadcrumbs);
            TabTitle(breadcrumbTitle[3] + " - Edit " + breadcrumbTitle[1]);
        });
    };

    const saveNextTopic = async (category) => {
        if (updateApiCall) {

            saveTopic(true);
            //Call the SaveDraft API
            setSelectedPath(category)
            toast.info(`${topicTitle} has been saved successfully!`, {
                position: "top-right",
            });
        } else {
            setSelectedPath(category);
        }
    }

    const getPolygonTopic = async () => {
        try {
            if (topicId !== null && topicId !== undefined) {
                await axiosGetService(`api/sdmtopics/getpolygontopicbyid/${topicId}`,).then((response) => {
                    setTopicsData(response.data);
                    getCollaboratorsInfo(response.data[0].reviewerMembersList, setReviewers);
                    getCollaboratorsInfo(response.data[0].approverMembersList, setApprovers);
                    getCollaboratorsInfo(response.data[0].coOwnerMembersList, setCoOwners);
                    setIsOwnerOrCoOwner(response.data[0].isOwnerOrCoOwner);
                    if (response.data[0].owner) {
                        getTopicOwnerInfo(response.data[0].owner);
                    }
                    setDiscardTopic(false);
                })
            }
        } catch (error) {
            console.log("Error", error);
        }
    }

    const getPostData = (id) => {
        const selectedTopicData = topicsData.filter(e => e.topicId === id);
        const processGroupIds = selectedProcessGroup.map(({ id }) => {
            return id;
        });
        const rolesIds = selectedRoles.map(({ id }) => {
            return id;
        });
        const reviewersList = reviewers.map(({ mail }) => {
            return mail;
        });
        const approversList = approvers.map(({ mail }) => {
            return mail;
        });
        const coOwnerList = coOwners.map(({ mail }) => {
            return mail;
        });
        const toolIds = selectedTools.map(({ id }) => {
            return id;
        });
        const imagesUrl = sessionStorage.getItem("topicBuilderImage")
            ? JSON.parse(sessionStorage.getItem("topicBuilderImage"))
            : [];
        const postData = {
            imagePaths: [...imagePaths, ...imagesUrl],
            topicName: topicTitle,
            diagramName: content,
            description: description,
            categoryId: updatedCategoryId,
            isVisible: isVisible,
            isCrowdSourceable: topicData.polygonSequence == 0 ? false : isCrowdSourceable,
            reviewerMembersList: reviewersList,
            approverMembersList: approversList,
            processGroupIds: processGroupIds.toString(),
            HashTags: userAddedHashtags.toString(),
            topicRelatedContentList: relatedContent.filter(({ title, url }) => title !== "" || url !== ""),
            topicId: id,
            toolIds: toolIds.toString(),
            roleIds: rolesIds.toString(),
            sequence: sequence,
            topicVersion: topicVersion,
            owner: topicOwnerInfo.mail,
            coOwnerMembersList: coOwnerList,
            newVersion: getUpdatedVersion(),
            topicType: topicType,
            polygonSides: selectedTopicData[0].polygonSides,
            polygonSequence: selectedTopicData[0].polygonSequence,
            polygonParent: selectedTopicData[0].polygonParent
        };
        return postData;
    };

    const handlePublish = () => {
        const updatedBusinessContent = businessRelatedContent.filter(
            ({ name, url }) => name !== "" || url !== ""
        );
        setBusinessRelatedContent(updatedBusinessContent);

        const updatedRelatedContent = relatedContent.filter(
            ({ title, url }) => title !== "" || url !== ""
        );
        setRelatedContent(updatedRelatedContent);

        setShowPreview(true);
    }


    const searchRoles = () => {
        if (roleSearchValue.trim().length !== 0) {
            const filterRoles = roles.filter((role) =>
                role.name.toLowerCase().includes(roleSearchValue.toLowerCase())
            );
            setFilteredRoles(filterRoles);
        }
    };

    // const updateRelatedContent = (duplicateTitle, duplicateUrl) => {
    //     const isError =
    //         (relatedContentTitle && !titleValidator.test(relatedContentTitle)) ||
    //         (relatedContentUrl && !urlValidator.test(relatedContentUrl));
    //     if (duplicateTitle.length) {
    //         toast.error("Duplicate title", {
    //             position: "top-right",
    //         });
    //     } else if (duplicateUrl.length) {
    //         toast.error("Duplicate url", {
    //             position: "top-right",
    //         });
    //     } else if (!isError) {
    //         const updatedContent = relatedContent.map((item) => {
    //             if (item.title === relatedContentUpdateInfo.title) {
    //                 return {
    //                     title: relatedContentTitle,
    //                     url: relatedContentUrl,
    //                 };
    //             } else {
    //                 return item;
    //             }
    //         });
    //         setRelatedContent([...updatedContent]);
    //         setRelatedContentUrl("");
    //         setRelatedContentTitle("");
    //         setRelatedContentEditMode(false);
    //         setRelatedContentUpdateInfo({});
    //     }
    // };

    const handleToChangeContent = (e, index) => {
        if (e.target.name === 'title') {
            const duplicateTitle = relatedContent.filter(({ title }) => title.toLowerCase() === e.target.value.toLowerCase());
            const isError = e.target.value && !titleValidator.test(e.target.value);
            if (duplicateTitle.length) {
                setShowRelatedContentTitleError(true);
                setShowRelatedContentTitleErrorMsg("Duplicate title");
                setShowRelatedContentIndexError(index);
            } else if (isError) {
                setShowRelatedContentTitleError(true);
                setShowRelatedContentTitleErrorMsg("Invalid title");
                setShowRelatedContentIndexError(index);
            } else if (!isError) {
                setShowRelatedContentTitleError(false);
                setShowRelatedContentTitleErrorMsg('');
            }
        } else {
            const duplicateUrl = relatedContent.filter(({ url }) => url === e.target.value);
            const isError = e.target.value && !urlValidator.test(e.target.value);
            if (duplicateUrl.length) {
                setShowRelatedContentUrlError(true);
                setShowRelatedContentUrlErrorMsg("Duplicate url");
                setShowRelatedContentIndexError(index);
            } else if (isError) {
                setShowRelatedContentUrlError(true);
                setShowRelatedContentUrlErrorMsg("Invalid url");
                setShowRelatedContentIndexError(index);
            } else if (!isError) {
                setShowRelatedContentUrlError(false);
                setShowRelatedContentUrlErrorMsg('');
            }
        }
        const field = e.target.name;
        const updatedContent = [...relatedContent];
        updatedContent[index][field] = e.target.value;
        setRelatedContent(updatedContent);
        setUpdateApiCall(true);
    }

    const handleAddRelatedContentFields = () => {
        const updatedRelatedContent = relatedContent.filter(
            ({ title, url }) => title === "" && url === ""
        );
        if (!updatedRelatedContent.length) {
            setRelatedContent([...relatedContent, { title: "", url: "" }]);
        }
    };

    const handleToChangeBusinessContent = (e, item, index) => {
        if (e.target.name === 'name') {
            const duplicateTitle = businessRelatedContent.filter(({ name }) => name.toLowerCase() === e.target.value.toLowerCase());
            const isError = e.target.value && !titleValidator.test(e.target.value);
            if (duplicateTitle.length) {
                setShowBusinessRuleTitleError(true);
                setShowBusinessRuleTitleErrorMsg("Duplicate title");
                setShowBusinessRuleIndexError(index);
            } else if (isError) {
                setShowBusinessRuleTitleError(true);
                setShowBusinessRuleTitleErrorMsg("Invalid title");
                setShowBusinessRuleIndexError(index);
            } else if (!isError) {
                setShowBusinessRuleTitleError(false);
                setShowBusinessRuleTitleErrorMsg('');
            }
        } else {
            const duplicateUrl = businessRelatedContent.filter(({ url }) => url === e.target.value);
            const isError = e.target.value && !urlValidator.test(e.target.value);
            if (duplicateUrl.length) {
                setShowBusinessRuleUrlError(true);
                setShowBusinessRuleUrlErrorMsg("Duplicate url");
                setShowBusinessRuleIndexError(index);
            } else if (isError) {
                setShowBusinessRuleUrlError(true);
                setShowBusinessRuleUrlErrorMsg("Invalid Url");
                setShowBusinessRuleIndexError(index);
            } else if (!isError) {
                setShowBusinessRuleUrlError(false);
                setShowBusinessRuleUrlErrorMsg('');
            }
        }
        if (item.id !== undefined) {
            setBusinessRuleUpdatedArray([...businessRuleUpdatedArray, item.id]);
        }
        const field = e.target.name;
        const updatedBusinessRuleContent = [...businessRelatedContent];
        updatedBusinessRuleContent[index][field] = e.target.value;
        setBusinessRelatedContent(updatedBusinessRuleContent);
        setUpdateApiCall(true);
    }

    const handleAddBusinessContentFields = () => {
        const updatedBusinessContent = businessRelatedContent.filter(
            ({ name, url }) => name === "" && url === ""
        );
        if (!updatedBusinessContent.length) {
            setBusinessRelatedContent([...businessRelatedContent, { name: "", url: "" }]);
        }
    };

    // const addRelatedContent = () => {
    //     if (relatedContentEditMode) {
    //         const duplicateTitle = relatedContent.filter(
    //             ({ title }) =>
    //                 title === relatedContentTitle &&
    //                 title !== relatedContentUpdateInfo.title
    //         );
    //         const duplicateUrl = relatedContent.filter(
    //             ({ url }) =>
    //                 url === relatedContentUrl && url !== relatedContentUpdateInfo.url
    //         );
    //         updateRelatedContent(duplicateTitle, duplicateUrl);
    //     } else {
    //         const duplicateTitle = relatedContent.filter(
    //             ({ title }) => title === relatedContentTitle
    //         );
    //         const duplicateUrl = relatedContent.filter(
    //             ({ url }) => url === relatedContentUrl
    //         );
    //         const isError =
    //             (relatedContentTitle && !titleValidator.test(relatedContentTitle)) ||
    //             (relatedContentUrl && !urlValidator.test(relatedContentUrl));
    //         if (duplicateTitle.length) {
    //             toast.error("Duplicate title", {
    //                 position: "top-right",
    //             });
    //         } else if (duplicateUrl.length) {
    //             toast.error("Duplicate url", {
    //                 position: "top-right",
    //             });
    //         } else if (!isError && relatedContentTitle && relatedContentUrl) {
    //             setRelatedContent([
    //                 ...relatedContent,
    //                 { title: relatedContentTitle, url: relatedContentUrl },
    //             ]);
    //             setRelatedContentUrl("");
    //             setRelatedContentTitle("");
    //         }
    //     }
    // };

    const getBusinessRelatedContent = async () => {
        const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
        let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${filteredTopicId}/10/0/${false}`
        await axiosGetService(api).then(
            (response) => {
                const businessRuleContentData = response.data;
                setBusinessRelatedContent([...businessRuleContentData, { name: "", url: "" }]);
                setBusinessRuleOrgContent(response.data)
            })
    }

    const addBusinessRuleContent = async () => {
        const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId;
        const updatedBusinessContent = businessRelatedContent.filter(
            ({ name, url }) => name !== "" || url !== ""
        );
        setBusinessRelatedContent(updatedBusinessContent);
        businessRelatedContent.map(async (item) => {
            if (item.id === undefined) {
                const relatedContent =
                {
                    name: item.name,
                    url: item.url,
                    isDrafted: true,
                    ArtifactId: filteredTopicId,
                    ArtifactTypeId: 10,
                    ComplexityId: 0
                }
                const postData = JSON.stringify(relatedContent)
                await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData).then(
                    (response) => {
                        if (response.data.status && response.data.errorMsg === null) {
                            toast.info('Content has been added successfully', {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                            getBusinessRelatedContent();
                        } else {
                            toast.error(response.data.errorMsg, {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                        }
                    }
                )
            } else {
                if (businessRuleUpdatedArray.includes(item.id)) {
                    const updatedRelatedContent =
                    {
                        id: item.id,
                        name: item.name,
                        url: item.url,
                        isDrafted: true,
                        ArtifactId: filteredTopicId,
                        ArtifactTypeId: 10,
                        ComplexityId: 0
                    }
                    const postData = JSON.stringify(updatedRelatedContent)
                    await axiosPostService(`api/sdmbusinessrules/updateBusinessRule`, postData).then(
                        (response) => {
                            if (response.data.status && response.data.errorMsg === null) {
                                toast.info('Content has been updated successfully', {
                                    position: toast.POSITION.TOP_RIGHT,
                                })
                                getBusinessRelatedContent();
                            } else {
                                toast.error(response.data.errorMsg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                        })
                }
            }
        });

        setShowBusinessRuleModal(false);
    }


    // const addBusinessRelatedContent = async () => {
    //     if (businessRelatedContentTitle && businessRelatedContentUrl) {
    //         const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
    //         if (businessRelatedContent.some(value => value.id === updatedId)) {
    //             const updatedRelatedContent =
    //             {
    //                 id: updatedId,
    //                 name: businessRelatedContentTitle,
    //                 url: businessRelatedContentUrl,
    //                 isDrafted: true,
    //                 ArtifactId: filteredTopicId,
    //                 ArtifactTypeId: 10,
    //                 ComplexityId: 0
    //             }
    //             const postData = JSON.stringify(updatedRelatedContent)
    //             await axiosPostService(`api/sdmbusinessrules/updateBusinessRule`, postData).then(
    //                 (response) => {
    //                     if (response.data.status && response.data.errorMsg === null) {
    //                         toast.info('Content has been updated successfully', {
    //                             position: toast.POSITION.TOP_RIGHT,
    //                         })
    //                         setBusinessRelatedContentTitle('')
    //                         setBusinessRelatedContentUrl('')
    //                         getBusinessRelatedContent();
    //                     } else {
    //                         toast.error(response.data.errorMsg, {
    //                             position: toast.POSITION.TOP_RIGHT,
    //                         })
    //                     }
    //                 })
    //         } else {
    //             const relatedContent =
    //             {
    //                 name: businessRelatedContentTitle,
    //                 url: businessRelatedContentUrl,
    //                 isDrafted: true,
    //                 ArtifactId: filteredTopicId,
    //                 ArtifactTypeId: 10,
    //                 ComplexityId: 0
    //             }
    //             const postData = JSON.stringify(relatedContent)
    //             await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData).then(
    //                 (response) => {
    //                     if (response.data.status && response.data.errorMsg === null) {
    //                         toast.info('Content has been added successfully', {
    //                             position: toast.POSITION.TOP_RIGHT,
    //                         })

    //                         setBusinessRelatedContentTitle('')
    //                         setBusinessRelatedContentUrl('')
    //                         getBusinessRelatedContent();
    //                     } else {
    //                         toast.error(response.data.errorMsg, {
    //                             position: toast.POSITION.TOP_RIGHT,
    //                         })
    //                     }
    //                 }
    //             )
    //         }

    //     } else {
    //         setError(true);
    //     }

    // }

    const removeBusinessRelatedContent = (index) => {
        const updatedContent = [...businessRelatedContent];
        updatedContent.splice(index, 1);
        setBusinessRelatedContent(updatedContent);
    }

    const deleteBusinessRelatedContent = async (id) => {
        await axiosDeleteService(
            `api/sdmbusinessrules/deletebusinessrule/${id}/${topicId}/10/0`
        ).then(response => {
            if (response.status) {
                toast.info('Content has been deleted successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getBusinessRelatedContent();
            }
        });
    }

    const removeRelatedContent = (item, index) => {
        const updatedContent = [...relatedContent];
        updatedContent.splice(index, 1);
        setRelatedContent(updatedContent);
    };

    const updateRelatedContentPolygon = () => {
        const updatedRelatedContent = relatedContent.filter(
            ({ title, url }) => title !== "" || url !== ""
        );
        setRelatedContent(updatedRelatedContent);
        setShowRelatedContentModal(false);
    };


    const saveTopic = async (showMessage) => {
        const postData = getPostData(selectedTopicId);
        postData.sequenceTopicIds = isAdmin
            ? topics
                .map(({ id }) => {
                    if (id === 0) return 0;
                    return Number(id);
                })
                .toString()
            : "";
        setShowSaveSpinner(true);
        await axiosPostService(`api/sdmtopics/updatedraft`, postData).then(
            (response) => {
                if (response.data.status) {
                    if (!showMessage) {
                        setMessage("You have successfully saved the topic");
                        setShowResponseModal(true);
                        setDiscardTopic(false);
                    }
                    sessionStorage.setItem("topicBuilderImage", JSON.stringify([]));
                    track(1, 1800, "Topics Builder", topicTitle);
                    resetCollaboratorsData();
                    getPolygonTopic();
                } else {
                    toast.error(response.data.errorMessage, {
                        position: "top-right",
                    });
                }
            }
        );
        setShowSaveSpinner(false);
    };

    const publishTopic = async () => {
        setShowSaveSpinner(true);
        const overviewTopicId = topicsData.filter(e => e.polygonSequence === 0)[0].topicId
        const overviewRelatedContent = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicRelatedContentList
            .map((item) => {
                return {
                    title: item.title,
                    url: item.url,
                };
            });
        const postData = getPostData(overviewTopicId);
        postData.topicRelatedContentList = overviewRelatedContent
        let response = await axiosPostService(
            `api/sdmtopics/publishtopic`,
            postData
        );
        if (isAdmin && response.data.status) {
            const updatedSequencesIds = topics.map(({ id }) => {
                return Number(id);
            });
            const postSequenceData = {
                sequenceTopicIds: updatedSequencesIds.toString(),
                categoryId: updatedCategoryId,
            };
            response = await axiosPostService(
                `api/sdmtopics/updatetopicsequence`,
                postSequenceData
            );
        }
        if (response.data.status) {
            sessionStorage.setItem("topicBuilderImage", JSON.stringify([]));
            track(1, 1805, "Topics Builder", topicTitle);
            toast.info("You have successfully published the topic", {
                position: "top-right",
            });
            history.push('/topics');
        } else {
            toast.error(response.data.errorMessage, {
                position: "top-right",
            });
        }
        setShowSaveSpinner(false);
    };

    const schedulePublishTopic = async (publishScheduledDateTime) => {
        setShowSaveSpinner(true);
        const postData = getPostData();
        postData.publishScheduledDateTime = publishScheduledDateTime;
        let response = await axiosPostService(
            `api/sdmtopics/publishscheduletopic`,
            postData
        );
        if (isAdmin && response.data.status) {
            const updatedSequencesIds = topics.map(({ id }) => {
                return Number(id);
            });
            const postSequenceData = {
                sequenceTopicIds: updatedSequencesIds.toString(),
                categoryId: updatedCategoryId,
            };
            response = await axiosPostService(
                `api/sdmtopics/updatetopicsequence`,
                postSequenceData
            );
        }
        if (response.data.status) {
            setMessage("You have successfully scheduled to publish the topic");
            setShowResponseModal(true);
            sessionStorage.setItem("topicBuilderImage", JSON.stringify([]));
            track(1, 1805, "Topics Builder", topicTitle);
        } else {
            toast.error(response.data.errorMessage, {
                position: "top-right",
            });
        }
        setShowSaveSpinner(false);
    };

    const exitLockMode = async () => {
        if (pageLockUser === userAlias) {
            await axiosDeleteService(
                `api/sdmartifactmaster/deleteLockedArtifact?artifactId=${topicId}&artifactTypeConstId=${artifactMapData["topics"]
                }&artifactDraftId=${0}`
            );
        }
    };

    const enterLockMode = async () => {
        const postData = {
            artifactId: topicId,
            artifactTypeConstId: artifactMapData["topics"],
            artifactDraftId: 0,
        };
        await axiosPostService(`api/sdmartifactmaster/lockArtifact`, postData).then(
            () => {
                setPageLockUser(userAlias);
            }
        );
    };

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? '' : key);
        setactiveKeyRelativeContent('');
        setactiveKeyBusinessRule('');

    };
    const toggleAccordionRelativeContent = (key) => {
        setactiveKeyRelativeContent(activeKeyRelativeContent === key ? '' : key);
        setActiveKey('');
        setactiveKeyBusinessRule('');
    };
    const toggleAccordionBusineesRule = (key) => {
        setactiveKeyBusinessRule(activeKeyBusinessRule === key ? '' : key);
        setActiveKey('');
        setactiveKeyRelativeContent('');
    };

    const checkLockMode = async () => {
        await axiosGetService(
            `api/sdmartifactmaster/getLockedArtifacts?artifactId=${topicId}&artifactTypeConstId=${artifactMapData["topics"]
            }&artifactDraftId=${0}`
        ).then((response) => {
            setLockEmailId(response.data.email);
            if (response.data.status && userAlias !== response.data.email) {
                setIsPageLock(true);
            } else if (!response.data.status) {
                enterLockMode();
            } else {
                enterLockMode();
                setPageLockUser(response.data.email);
            }
        });
    };

    const removeProcessGroup = (item) => {
        const updatedValue = selectedProcessGroup.filter(
            ({ id }) => id !== item.id
        );
        setSelectedProcessgroup(updatedValue);
    };

    const getUpdatedVersion = () => {
        let newVersion = Number(topicVersion) + selectedVersionType.increment;
        if (selectedVersionType.id === "majorVersion")
            newVersion = Math.floor(newVersion);
        return newVersion.toFixed(2);
    };

    const resetCollaboratorsData = () => {
        setCoOwners([])
        setApprovers([])
        setReviewers([])
        setTopicOwnerEmail("")
        setCoOwnwerEmail("")
        setReviewerEmail("")
        setApproverEmail("")

    }

    useEffect(() => {
        getPolygonTopic();
    }, [])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getRoles();
        }, 1000);
        return () => {
            clearTimeout(timeOutId);
        };
    }, []);

    useEffect(() => {
        searchRoles();
    }, [roleSearchValue]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (hashtagSuggestionInput.trim()) {
                hashtagsAutoComplete();
            }
        }, 1000);

        return () => {
            setHashtagSuggestions([]);
            clearTimeout(timeOutId);
        };
    }, [hashtagSuggestionInput]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (reviewerEmail) {
                getUser(reviewerEmail, "reviewer");
            } else {
                setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
                setError({ ...error, ["reviewer"]: false });
            }
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [reviewerEmail]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (approverEmail) {
                getUser(approverEmail, "approver");
            } else {
                setUsersList((prevState) => ({ ...prevState, approver: [] }));
                setError({ ...error, ["approver"]: false });
            }
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [approverEmail]);

    useEffect(() => {
        getProcessGroup();
    }, []);

    useEffect(() => {
        if (isAdmin) {
            getTopicsCategoryDataForAdmin();
        }
    }, [isAdmin]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getTools();
        }, 1000);
        return () => {
            clearTimeout(timeOutId);
        };
    }, [toolSearchValue]);

    useEffect(() => {
        getTopicDetails();
        // return () => {
        //     resetCollaboratorsData()
        // }
    }, [selectedPath]);

    useEffect(() => {
        checkLockMode();
        return () => {
            if (pageLockUser === userAlias) {
                exitLockMode();
            }
        };
    }, [pageLockUser]);

    useEffect(() => {
        loadBreadcrumb();
    }, []);

    useEffect(() => {
        let timeOutId = 0;
        if (pageLockUser === userAlias) {
            timeOutId = setInterval(() => {
                enterLockMode();
            }, 300000);
        }
        return () => {
            clearInterval(timeOutId);
        };
    }, [pageLockUser]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (coOwnwerEmail) {
                getUser(coOwnwerEmail, "coOwner");
            } else {
                setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
                setError({ ...error, ["coOwner"]: false });
            }
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [coOwnwerEmail]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (topicOwnerEmail) {
                getUser(topicOwnerEmail, "topicOwner");
            } else {
                setUsersList((prevState) => ({ ...prevState, topicOwner: [] }));
                setError({ ...error, ["topicOwner"]: false });
            }
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [topicOwnerEmail]);

    // useEffect(() => {
    //     if (isAdmin) {
    //         axiosGetService(
    //             `api/sdmtopics/getbycategoryid/${updatedCategoryId}`
    //         ).then((response) => {
    //             const data = response.data.map((item) => {
    //                 return {
    //                     id: item.id.toString(),
    //                     name: item.name,
    //                     type: item.isDrafted ? "Draft" : "",
    //                 };
    //             });
    //             if (updatedCategoryId != categoryId) {
    //                 data.push({ id: topicId, name: topicTitle });
    //             }
    //             setTopics(data);
    //         });
    //     }
    // }, [updatedCategoryId, isAdmin]);

    // useEffect(() => {
    //     setTopics(
    //         topics.map((topic) => {
    //             if (topic.id === topicId) {
    //                 topic.name = topicTitle;
    //             }
    //             return topic;
    //         })
    //     );
    // }, [selectedTab]);

    useEffect(() => {
        getBusinessRelatedContent();
    }, [selectedPath, showPreview])

    useEffect(() => {
        const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
        setSelectedTopicId(filteredTopicId)
    }, [selectedPath])



    if (showSpinner) {
        return <Spinner />;
    }



    if (isPageLock || isTopicCrowdSourced) {
        return (
            <TopicLockModalMessage
                isTopicCrowdSourced={isTopicCrowdSourced}
                lockEmailId={lockEmailId}
                url="/topics"
            />
        );
    }


    if ((!isOwnerOrCoOwner || topicType !== 3) && !isAdmin) {
        return <AdminModal />;
    }

    return (
        <>
            <div className="topicBulderMainContainer hexagonBuilderMainContainer">
                <div className="topicBulderHeaderContainer hexagonHeaderContainer">
                    <div className="topicBulderHeaderButtons">
                        <div>
                            <button
                                onClick={() => {
                                    setShowDiscardModal(true);
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        setShowDiscardModal(true);
                                    }
                                }}
                                title="Discard"
                            >
                                Discard
                            </button>
                            <button
                                onClick={() => {
                                    saveTopic(false);
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        saveTopic(false);
                                    }
                                }}
                                title="Save"
                            >
                                Save
                            </button>
                            <button
                                disabled={selectedPath === 0 || publishScheduledDateTime ? false : true}
                                onClick={() => {
                                    handlePublish();
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        handlePublish();
                                    }
                                }}
                                title="Preview & Publish"
                            >
                                Preview & Publish
                            </button>
                        </div>
                    </div>
                </div>
                <div className='polygonMainCountainer'>
                    <div className="topicBulderFieldsContainer topicBulderPolygonFieldsContainer">
                        <div className="topicAdditionCont">
                            <div className="topicAdditionContainer">
                                <div
                                    className={
                                        !selectedSection.includes("addNewTopics")
                                            ? "addTopicButtonAction"
                                            : "addTopicButtonAction expanded mb-0 pt-0"
                                    }
                                >
                                    {selectedSection.includes("addNewTopics") ? (
                                        <>
                                            <div className="addPolygonTopicFields">
                                                <div className="addPolygonTopicFieldsDiv">
                                                    <span className="addTopicFieldLabel">
                                                        Topic Title *
                                                    </span>
                                                    <input
                                                        placeholder="Topic title"
                                                        value={topicTitle.replace(/_.*/, '')}
                                                        onChange={(e) => {
                                                            setTopicTitle(e.target.value);
                                                            setPolygonTopicTitle(e.target.value);
                                                            setUpdateApiCall(true);
                                                        }}
                                                        disabled={topicId === '1001' ||
                                                            topicId === '1002' ||
                                                            topicId === '1003' ||
                                                            topicId === '1004' ||
                                                            topicId === '1005' ||
                                                            topicId === '1006' ||
                                                            topicId === '1006' ||
                                                            topicId === '1007' ||
                                                            topicId === '272'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addPolygonTopicFields">
                                                <div className="addPolygonTopicFieldsDiv">
                                                    <span className="addTopicFieldLabel">
                                                        Topic Hashtags
                                                    </span>
                                                    <HashtagSuggestions
                                                        className="topicsHashtagsInput"
                                                        enableDropdown={true}
                                                        data={hashtagSuggestions}
                                                        setUpdateApiCall={setUpdateApiCall}
                                                        setHashtagSuggestions={setHashtagSuggestions}
                                                        searchValue={hashtagsInput}
                                                        setSearchValue={setHashtagsInput}
                                                        existingHashtags={userAddedHashtags}
                                                        userAddedHashtags={userAddedHashtags}
                                                        setUserAddedHashtags={setUserAddedHashtags}
                                                        hashtagSuggestionInput={hashtagSuggestionInput}
                                                        setHashtagSuggestionInput={
                                                            setHashtagSuggestionInput
                                                        }
                                                        closeHashtagForm={() => {
                                                            setHashtagsInput("");
                                                            setUserAddedHashtags([]);
                                                        }}
                                                        submitHashtags={submitHashtags}
                                                        autoFocus={false}
                                                        showSubmitButton={false}
                                                        pageType="topics"
                                                        setUpdateApiCall={setUpdateApiCall}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addPolygonTopicFields">
                                                <div className="addPolygonTopicFieldsDiv">
                                                    <span className="addTopicFieldLabel">
                                                        Topic Content *
                                                    </span>
                                                    <TemporaryCkAlert />
                                                    <CKEditor value={content} setValue={setContent} setUpdateApiCall={setUpdateApiCall} updateApiCall={updateApiCall} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className='hexagonBorder'></div>
                            </div>
                        </div>
                    </div>
                    <div className='polygonTopicRightContainer'>
                        {topicsData.length > 0 ?
                            <div className="polygonContainer">
                                <Shapes
                                    count={count}
                                    selectedPath={selectedPath}
                                    topicsData={topicsData}
                                    saveNextTopic={saveNextTopic}
                                    showLinking={showLinking}
                                    setLinking={setLinking}
                                />
                            </div>
                            : <></>
                        }
                        <div className="polygonTopicRightContent">
                            <Accordion defaultActiveKey="0" className="mb-0">
                                <Card className="userPolygonCard">
                                    <Card.Header className="polygonTopicCollaboratorLabel row">
                                        <span className="col-lg-10 col-xl-10">Owner / Collaborators</span>
                                        <span className="col-lg-2 col-xl-2 clickableIcon" >
                                            <Icon onClick={(e) => { e.stopPropagation(); toggleAccordion('0'); }} iconName={activeKey === '0' ? "ChevronUp" : "ChevronDown"} className="ml-2" />
                                        </span>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" in={activeKey === '0'}>
                                        <Card.Body>
                                            {/* Owner */}
                                            {isCategoryOwnerOrCoOwner && (

                                                <div className="addPolygonTopicRightFields">
                                                    <div className="addPolygonTopicRightFieldsDiv">
                                                        <span className="addPolygonTopicRightLabel">
                                                            Add Owner
                                                        </span>
                                                        <OwnerSelectionDropdown
                                                            className="topicOwner"
                                                            enableDropdown={true}
                                                            data={usersList}
                                                            value={topicOwnerInfo}
                                                            setValue={setTopicOwnerInfo}
                                                            type="topicOwner"
                                                            searchValue={topicOwnerEmail}
                                                            setSearchValue={setTopicOwnerEmail}
                                                            error={error}
                                                            emailFound={emailFound["topicOwner"]}
                                                            setUpdateApiCall={setUpdateApiCall}
                                                            updateApiCall={updateApiCall}
                                                            pageType="topics"
                                                        />
                                                        {topicOwnerInfo.mail ? (
                                                            <ul
                                                                className={
                                                                    "afterSaveListTopic polygonCollaboratorsApprovers"
                                                                }
                                                            >
                                                                <li key={topicOwnerInfo.mail}>
                                                                    <span className="collabaratorPersonaImage">
                                                                        <img src={topicOwnerInfo.photo} alt="img" />
                                                                    </span>
                                                                    <span className="collabaratorPersonaTitle">
                                                                        {topicOwnerInfo.firstName +
                                                                            " " +
                                                                            topicOwnerInfo.surname}
                                                                    </span>
                                                                    <span>
                                                                        <Icon
                                                                            iconName="Cancel"
                                                                            onClick={() => {
                                                                                setTopicOwnerInfo({});
                                                                            }}
                                                                            onKeyPress={(e) => {
                                                                                if (e.which === 13) {
                                                                                    setTopicOwnerInfo({});
                                                                                }
                                                                            }}
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            aria-label={`Remove ${topicOwnerInfo.firstName} ${topicOwnerInfo.surname}`}
                                                                            title="Remove Owner"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Co Owner */}
                                            <div className="addPolygonTopicRightFields">
                                                <div className="addPolygonTopicRightFieldsDiv">
                                                    <span className="addPolygonTopicRightLabel">
                                                        Add Co-owners
                                                    </span>
                                                    <UserSelectionDropdown
                                                        className="coOwner"
                                                        enableDropdown={true}
                                                        data={usersList}
                                                        value={coOwners}
                                                        setValue={setCoOwners}
                                                        type="coOwner"
                                                        searchValue={coOwnwerEmail}
                                                        setSearchValue={setCoOwnwerEmail}
                                                        error={error}
                                                        emailFound={emailFound["coOwner"]}
                                                        setUpdateApiCall={setUpdateApiCall}
                                                        updateApiCall={updateApiCall}
                                                        pageType="topics"
                                                    />
                                                    {coOwners.length > 0 ? (
                                                        <ul
                                                            className={
                                                                "afterSaveListTopic polygonCollaboratorsApprovers"
                                                            }
                                                        >
                                                            {coOwners.map((user) => (
                                                                <li key={user.mail}>
                                                                    <span className="collabaratorPersonaImage">
                                                                        <img src={user.photo} alt="img" />
                                                                    </span>
                                                                    <span className="collabaratorPersonaTitle">
                                                                        {user.firstName + " " + user.surname}
                                                                    </span>
                                                                    <span>
                                                                        <Icon
                                                                            iconName="Cancel"
                                                                            onClick={() => {
                                                                                removeUser(
                                                                                    user.mail,
                                                                                    coOwners,
                                                                                    setCoOwners
                                                                                );
                                                                            }}
                                                                            onKeyPress={(e) => {
                                                                                if (e.which === 13) {
                                                                                    removeUser(
                                                                                        user.mail,
                                                                                        coOwners,
                                                                                        setCoOwners
                                                                                    );
                                                                                }
                                                                            }}
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                                                                            title="Remove CoOwner"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Approver */}
                                            <div className="addPolygonTopicRightFields">
                                                <div className="addPolygonTopicRightFieldsDiv">
                                                    <span className="addPolygonTopicRightLabel">
                                                        Add Approvers
                                                    </span>
                                                    <UserSelectionDropdown
                                                        className="approver"
                                                        enableDropdown={true}
                                                        data={usersList}
                                                        value={approvers}
                                                        setValue={setApprovers}
                                                        type="approver"
                                                        searchValue={approverEmail}
                                                        setSearchValue={setApproverEmail}
                                                        error={error}
                                                        emailFound={emailFound["approver"]}
                                                        setUpdateApiCall={setUpdateApiCall}
                                                        updateApiCall={updateApiCall}
                                                        pageType="topics"
                                                    />
                                                    {approvers.length > 0 ? (
                                                        <ul
                                                            className={
                                                                "afterSaveListTopic polygonCollaboratorsApprovers"
                                                            }
                                                        >
                                                            {approvers.map((user) => (
                                                                <li key={user.mail}>
                                                                    <span className="collabaratorPersonaImage">
                                                                        <img src={user.photo} alt="img" />
                                                                    </span>
                                                                    <span className="collabaratorPersonaTitle">
                                                                        {user.firstName + " " + user.surname}
                                                                    </span>
                                                                    <span>
                                                                        <Icon
                                                                            iconName="Cancel"
                                                                            onClick={() => {
                                                                                removeUser(
                                                                                    user.mail,
                                                                                    approvers,
                                                                                    setApprovers
                                                                                );
                                                                            }}
                                                                            onKeyPress={(e) => {
                                                                                if (e.which === 13) {
                                                                                    removeUser(
                                                                                        user.mail,
                                                                                        approvers,
                                                                                        setApprovers
                                                                                    );
                                                                                }
                                                                            }}
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                                                                            title="Remove approver"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Reviewer */}
                                            <div className="addPolygonTopicRightFields">
                                                <div className="addPolygonTopicRightFieldsDiv">
                                                    <span className="addPolygonTopicRightLabel">
                                                        Add Reviewers
                                                    </span>
                                                    <UserSelectionDropdown
                                                        className="reviewer"
                                                        enableDropdown={true}
                                                        data={usersList}
                                                        value={reviewers}
                                                        setValue={setReviewers}
                                                        type="reviewer"
                                                        searchValue={reviewerEmail}
                                                        setSearchValue={setReviewerEmail}
                                                        error={error}
                                                        emailFound={emailFound["reviewer"]}
                                                        setUpdateApiCall={setUpdateApiCall}
                                                        updateApiCall={updateApiCall}
                                                        pageType="topics"
                                                    />
                                                    {reviewers.length > 0 ? (
                                                        <ul
                                                            className={
                                                                "afterSaveListTopic polygonCollaboratorsApprovers"
                                                            }
                                                        >
                                                            {reviewers.map((user) => (
                                                                <li key={user.mail}>
                                                                    <span className="collabaratorPersonaImage">
                                                                        <img src={user.photo} alt="img" />
                                                                    </span>
                                                                    <span className="collabaratorPersonaTitle">
                                                                        {user.firstName + " " + user.surname}
                                                                    </span>
                                                                    <span>
                                                                        <Icon
                                                                            iconName="Cancel"
                                                                            onClick={() => {
                                                                                removeUser(
                                                                                    user.mail,
                                                                                    reviewers,
                                                                                    setReviewers
                                                                                );
                                                                            }}
                                                                            onKeyPress={(e) => {
                                                                                if (e.which === 13) {
                                                                                    removeUser(
                                                                                        user.mail,
                                                                                        reviewers,
                                                                                        setReviewers
                                                                                    );
                                                                                }
                                                                            }}
                                                                            role="button"
                                                                            tabIndex={0}
                                                                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                                                                            title="Remove reviewer"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="polygonTopicRelatedContentLabel row">
                                        <span className="col-lg-7 col-xl-7">Related Content</span>
                                        <span className='col-lg-3 col-xl-3 d-flex justify-content-end chevronContentSpan' >
                                            <Icon
                                                iconName="EditSolid12"
                                                onClick={() => {
                                                    setShowRelatedContentModal(true);
                                                }}
                                                tabIndex={0}
                                                onKeyPress={(e) => {
                                                    if (e.which === 13) {
                                                        setShowRelatedContentModal(true);
                                                    }
                                                }}
                                                role="button"
                                                aria-label={`Add Related Content`}
                                                title='Add Related Content'
                                            />
                                        </span>
                                        <span className="col-lg-2 col-xl-2 clickableIcon" >
                                            <Icon onClick={(e) => { e.stopPropagation(); toggleAccordionRelativeContent('0'); }} iconName={activeKeyRelativeContent === '0' ? "ChevronUp" : "ChevronDown"} className="ml-2" />
                                        </span>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" in={activeKeyRelativeContent === '0'}>
                                        <Card.Body>
                                            {showRelatedContentModal ?
                                                <Modal
                                                    size="lg"
                                                    centered
                                                    show={true}
                                                    onHide={() => {
                                                        /* Not calling any function to close modal*/
                                                    }}
                                                    dialogClassName="modal-90w"
                                                >
                                                    <Modal.Header>
                                                        <Modal.Title>
                                                            <div className="polygonTopicModalRelatedContenTitle">
                                                                <span>Related Content</span>
                                                            </div>
                                                        </Modal.Title>
                                                        <Icon
                                                            className="relatedContentClose"
                                                            iconName="ChromeClose"
                                                            title="Close"
                                                            onClick={() => setShowRelatedContentModal(false)}
                                                        />
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="addPolygonTopicContenUrlContainer">
                                                            <div className="addPolygonTopicRelatedContentLabel">
                                                                <div className="addPolygonTopicFieldContenUrlLabel">
                                                                    <span className="addPolygonTopicFieldLabel">
                                                                        Content Title
                                                                    </span>
                                                                </div>
                                                                <div className="addPolygonTopicFieldContenUrlLabel">
                                                                    <span className="addPolygonTopicFieldLabel">
                                                                        Content URL
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="addPolygonTopicRelatedInputContainer">
                                                                <div className="polygonInputContainer">
                                                                    {relatedContent.map((item, index) => (
                                                                        <>
                                                                            <div key={index} className="addPolygonTopicRelatedInput">
                                                                                <div className="addPolygonTopicFieldContenUrl">
                                                                                    <input
                                                                                        placeholder="Enter Content Title"
                                                                                        name="title"
                                                                                        value={item.title}
                                                                                        onChange={(e) => handleToChangeContent(e, index)}
                                                                                        disabled={topicId === '1001' ||
                                                                                            topicId === '1002' ||
                                                                                            topicId === '1003' ||
                                                                                            topicId === '1004' ||
                                                                                            topicId === '1005' ||
                                                                                            topicId === '1006' ||
                                                                                            topicId === '1007' ||
                                                                                            topicId === '272'}
                                                                                    />
                                                                                </div>
                                                                                <div className="addPolygonTopicFieldContenUrl">
                                                                                    <input
                                                                                        placeholder="Enter url"
                                                                                        name="url"
                                                                                        value={item.url}
                                                                                        onChange={(e) => handleToChangeContent(e, index)}
                                                                                        disabled={topicId === '1001' ||
                                                                                            topicId === '1002' ||
                                                                                            topicId === '1003' ||
                                                                                            topicId === '1004' ||
                                                                                            topicId === '1005' ||
                                                                                            topicId === '1006' ||
                                                                                            topicId === '1007' ||
                                                                                            topicId === '272'}
                                                                                    />
                                                                                </div>
                                                                                <div className="addPolygonTopicFieldSave">
                                                                                    <button className="relatedContentDelete"
                                                                                        onClick={() => removeRelatedContent(item.title, index)} >
                                                                                        <Icon
                                                                                            className="relatedContentDeleteIcon"
                                                                                            iconName="ChromeClose"
                                                                                            title="Close"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                                {index === relatedContent.length - 1 && (
                                                                                    <div className="addPolygonTopicFieldAdd">
                                                                                        <button
                                                                                            className="relatedContentAdd"
                                                                                            onClick={handleAddRelatedContentFields}
                                                                                            onKeyPress={(e) => {
                                                                                                if (e.which === 13) {
                                                                                                    handleAddRelatedContentFields();
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <Icon
                                                                                                className="relatedContentAddIcon"
                                                                                                iconName="Add"
                                                                                                title="Add"
                                                                                            />
                                                                                        </button>
                                                                                    </div>)}

                                                                            </div>
                                                                            {(showRelatedContentTitleError || showRelatedContentUrlError) &&
                                                                                (showRelatedContentIndexError === index) && (
                                                                                    <div className="errorBusinessRule">
                                                                                        <div className="addPolygonTopicFieldContenUrl">
                                                                                            {showRelatedContentTitleError && (
                                                                                                <p className="errorMsg">
                                                                                                    {showRelatedContentTitleErrorMsg}
                                                                                                </p>
                                                                                            )}</div><div className="addPolygonTopicFieldContenUrl">
                                                                                            {showRelatedContentUrlError && (
                                                                                                <p className="errorMsg urlRelativeContent">
                                                                                                    {showRelatedContentUrlErrorMsg}
                                                                                                </p>
                                                                                            )}</div>
                                                                                    </div>
                                                                                )}
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
                                                            <button
                                                                className="cancelRelatedContent mr-2"
                                                                onClick={() => {
                                                                    setShowRelatedContentModal(false);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.which === 13) {
                                                                        setShowRelatedContentModal(false);
                                                                    }
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="addBtnRelatedContent"
                                                                onClick={() => {
                                                                    updateRelatedContentPolygon();
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.which === 13) {
                                                                        updateRelatedContentPolygon();
                                                                    }
                                                                }}
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                                :
                                                <></>
                                            }

                                            {relatedContent.map((item) => (
                                                <>
                                                    {item.title !== '' ?
                                                        <a className="relatedContentAnchor" href={item.url} target="_blank" title={item.url}>
                                                            <span>{item.title}</span>
                                                        </a>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="polygonTopicRelatedContentLabel row">
                                        <span className="col-lg-7 col-xl-7">Business Rule</span>
                                        <span className='col-lg-3 col-xl-3 d-flex justify-content-end chevronContentSpan'>
                                            <Icon
                                                iconName="EditSolid12"
                                                onClick={() => {
                                                    setShowBusinessRuleModal(true);
                                                }}
                                                tabIndex={0}
                                                onKeyPress={(e) => {
                                                    if (e.which === 13) {
                                                        setShowBusinessRuleModal(true);
                                                    }
                                                }}
                                                role="button"
                                                aria-label={`Add Business Rule`}
                                                title='Add Business Rule'
                                            />
                                        </span>
                                        <span className="col-lg-2 col-xl-2 clickableIcon">
                                            <Icon onClick={() => toggleAccordionBusineesRule('0')} iconName={activeKeyBusinessRule === '0' ? "ChevronUp" : "ChevronDown"} className="ml-2" />
                                        </span>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" in={activeKeyBusinessRule === "0"}>
                                        <Card.Body>
                                            {showBusinessRuleModal ?
                                                <Modal
                                                    size="lg"
                                                    centered
                                                    show={true}
                                                    onHide={() => {
                                                        /* Not calling any function to close modal*/
                                                    }}
                                                    dialogClassName="modal-90w"
                                                >
                                                    <Modal.Header>
                                                        <Modal.Title>
                                                            <div className="polygonTopicModalRelatedContenTitle">
                                                                <span>Business Rule</span>
                                                            </div>
                                                        </Modal.Title>
                                                        <Icon
                                                            className="relatedContentClose"
                                                            iconName="ChromeClose"
                                                            title="Close"
                                                            onClick={() => setShowBusinessRuleModal(false)}
                                                        />
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="addPolygonTopicContenUrlContainer">
                                                            <div className="addPolygonTopicRelatedContentLabel">
                                                                <div className="addPolygonTopicFieldContenUrlLabel">
                                                                    <span className="addPolygonTopicFieldLabel">
                                                                        Content Title
                                                                    </span>
                                                                </div>
                                                                <div className="addPolygonTopicFieldContenUrlLabel">
                                                                    <span className="addPolygonTopicFieldLabel">
                                                                        Content URL
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="addPolygonTopicRelatedInputContainer">
                                                                <div className="polygonInputContainer">
                                                                    {businessRelatedContent.map((item, index) => (
                                                                        <>
                                                                            <div key={index} className="addPolygonTopicRelatedInput">
                                                                                <div className="addPolygonTopicFieldContenUrl">
                                                                                    <input
                                                                                        placeholder="Enter Title"
                                                                                        name="name"
                                                                                        value={item.name}
                                                                                        onChange={(e) => {
                                                                                            handleToChangeBusinessContent(e, item, index);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="addPolygonTopicFieldContenUrl">
                                                                                    <input
                                                                                        placeholder="Enter url"
                                                                                        name="url"
                                                                                        value={item.url}
                                                                                        onChange={(e) => {
                                                                                            handleToChangeBusinessContent(e, item, index);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="addPolygonTopicFieldSave">
                                                                                    <button className="relatedContentDelete"
                                                                                        onClick={() => {
                                                                                            if (item.id === undefined) {
                                                                                                removeBusinessRelatedContent(index);
                                                                                            } else {
                                                                                                deleteBusinessRelatedContent(item.id)
                                                                                            }
                                                                                        }}>
                                                                                        <Icon
                                                                                            className="relatedContentDeleteIcon"
                                                                                            iconName="ChromeClose"
                                                                                            title="Close"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                                {index === businessRelatedContent.length - 1 && (
                                                                                    <div className="addPolygonTopicFieldAdd">
                                                                                        <button
                                                                                            className="relatedContentAdd"
                                                                                            onClick={handleAddBusinessContentFields}
                                                                                            onKeyPress={(e) => {
                                                                                                if (e.which === 13) {
                                                                                                    handleAddBusinessContentFields();
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <Icon
                                                                                                className="relatedContentAddIcon"
                                                                                                iconName="Add"
                                                                                                title="Add"
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {(showBusinessRuleTitleError || showBusinessRuleUrlError) &&
                                                                                (showBusinessRuleIndexError === index) && (
                                                                                    <div className="errorBusinessRule">
                                                                                        <div className="addPolygonTopicFieldContenUrl">
                                                                                            {showBusinessRuleTitleError &&
                                                                                                <p className="errorMsg">
                                                                                                    {showBusinessRuleTitleErrorMsg}
                                                                                                </p>
                                                                                            }</div><div className="addPolygonTopicFieldContenUrl">
                                                                                            {showBusinessRuleUrlError &&
                                                                                                <p className="errorMsg">
                                                                                                    {showBusinessRuleUrlErrorMsg}
                                                                                                </p>
                                                                                            }</div>
                                                                                    </div>
                                                                                )}
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
                                                            <button
                                                                className="cancelBusinessContent mr-2"
                                                                onClick={() => {
                                                                    setShowBusinessRuleModal(false);
                                                                    setBusinessRelatedContent(businessRuleOrgContent);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.which === 13) {
                                                                        setShowBusinessRuleModal(false);
                                                                        setBusinessRelatedContent(businessRuleOrgContent);
                                                                    }
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="addBtnBusinessContent"
                                                                onClick={() => {
                                                                    addBusinessRuleContent();
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.which === 13) {
                                                                        addBusinessRuleContent();
                                                                    }
                                                                }}
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                                :
                                                <></>
                                            }
                                            {businessRelatedContent.map((item) => (
                                                <>
                                                    {item.name !== '' ?
                                                        <a className="relatedContentAnchor" href={item.url} target="_blank" title={item.url}>
                                                            <span>{item.name}</span>
                                                        </a>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <div className="polygonTopicCheckbox">
                                <div className="polygonTopicIsVisible">
                                    {topicData.polygonSequence != 0 ? (
                      <div className="isVisibleDivPolygon">
                        <input
                          type="checkbox"
                          title="Is crowd source checkbox"
                          checked={isCrowdSourceable}
                          onChange={() => {
                            setIsCrowdSourceable(!isCrowdSourceable);
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setIsCrowdSourceable(!isCrowdSourceable);
                            }
                          }}
                        />
                        <span className="ml-1">Iscrowdsourceable</span>
                      </div>
                    ) 
                    : ''
                  }

                                    <div className="isVisibleDivPolygon">
                                        <input
                                            type="checkbox"
                                            title="Is visible checkbox"
                                            checked={isVisible}
                                            onChange={() => {
                                                setIsVisible(!isVisible);
                                                setUpdateApiCall(true);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.which === 13) {
                                                    setIsVisible(!isVisible);
                                                    setUpdateApiCall(true);
                                                }
                                            }}
                                        />
                                        <span className="ml-1">Isvisible</span>
                                    </div>
                                </div>

                            </div>
                            <div className="addTopicFields">
                                <div className="addTopicFieldsDiv">
                                    <span className="AddActivityFieldLabel">
                                        Process Group
                                    </span>
                                    <ProcessGroupDropdown
                                        className="topicBuilderProcessGroup"
                                        data={processGroups}
                                        setValue={setSelectedProcessgroup}
                                        value={selectedProcessGroup}
                                        enableDropdown={true}
                                        setUpdateApiCall={setUpdateApiCall}
                                    />
                                    {selectedProcessGroup.length > 0 ? (
                                        <ul className="afterSaveList beforEditSavedList">
                                            {selectedProcessGroup.map((item) => (
                                                <li key={item.id}>
                                                    {item.name}{" "}
                                                    <Icon
                                                        iconName="Cancel"
                                                        onClick={() => removeProcessGroup(item)}
                                                        tabIndex="0"
                                                        onKeyPress={(e) => {
                                                            if (e.which === 13) {
                                                                removeProcessGroup(item);
                                                            }
                                                        }}
                                                        aria-label={`Click to cancel added ${item.name} prcedure group `}
                                                        role="button"
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {showPreview ? (
                    <PolygonPreviewModal
                        topicId={topicId}
                        showPreviewModal={showPreview}
                        setShowPreviewModal={setShowPreview}
                        hashTags={userAddedHashtags}
                        setUserAddedHashtags={setUserAddedHashtags}
                        topicVersion={topicVersion}
                        topicTitle={topicTitle}
                        content={content}
                        setShowPublishModal={setShowPublishModal}
                        relatedContent={relatedContent}
                        setRelatedContent={setRelatedContent}
                        topicOwnerInfo={topicOwnerInfo}
                        businessRelatedContent={businessRelatedContent}
                        setBusinessRelatedContent={setBusinessRelatedContent}
                        saveNextTopic={saveNextTopic}
                        topicsData={topicsData}
                        setTopicsData={setTopicsData}
                        selectedPath={selectedPath}
                        count={count}
                    />
                ) : (
                    <></>
                )}
                {showDiscardModal ? (
                    <DiscardModalPolygon
                        showDiscardModal={showDiscardModal}
                        setShowDiscardModal={setShowDiscardModal}
                        url={`/topics`}
                        topicId={topicId}
                        type={'updateTopics'}
                        setDiscardTopic={setDiscardTopic}
                        discardTopic={discardTopic}
                        topicsData={topicsData}
                    />
                ) : (
                    <></>
                )}
                {showResponseModal ? (
                    <PolygonResponseModal
                        showResponseModal={showResponseModal}
                        setShowResponseModal={setShowResponseModal}
                        message={message}
                        setSavedTopic={setSavedTopic}
                        savedTopic={savedTopic}
                    />
                ) : (
                    <></>
                )}
                {showPublishModal ? (
                    <TopicPublishModal
                        showPublishModal={showPublishModal}
                        setShowPublishModal={setShowPublishModal}
                        title={topicTitle}
                        publishTopic={publishTopic}
                        schedulePublishTopic={schedulePublishTopic}
                    />
                ) : (
                    <></>
                )}{" "}
                {showTopicPublishCancelModal ? (
                    <TopicPublishCancelModal
                        topicId={topicId}
                        publishScheduledDateTime={publishScheduledDateTime}
                        name={topicTitle}
                        showPublishCancelModal={showTopicPublishCancelModal}
                        setShowPublishCancelModal={setShowTopicPublishCancelModal}
                        formatPublishDate={formatPublishDate}
                        setPublishScheduledDateTime={setPublishScheduledDateTime}
                    />
                ) : (
                    <></>
                )}
                {showSaveSpinner ? <SaveSpinner /> : <></>}
                <div className=" hexagonSaveContainer">
                    <div className="polygonStepsButtons">
                        <Link to={`/polygon/addtopics/${categoryId}/${topicId}?source="topics"`}>
                            <button className="polygonBackBtn"
                                onClick={() => {
                                    setPolygonSelectedTab(1)
                                }
                                }>
                                Back
                            </button>
                        </Link>
                    </div>
                    <div className="topicBulderHeaderContainer">
                        <div className="topicBulderHeaderButtons">
                            <button
                                onClick={() => {
                                    setShowDiscardModal(true);
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        setShowDiscardModal(true);
                                    }
                                }}
                                title="Discard"
                            >
                                Discard
                            </button>
                            <button
                                onClick={() => {
                                    saveTopic(false);
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        saveTopic(false);
                                    }
                                }}
                                title="Save"
                            >
                                Save
                            </button>
                            <button
                                disabled={selectedPath === 0 || publishScheduledDateTime ? false : true}
                                onClick={() => {
                                    setShowPreview(true);
                                }}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        setShowPreview(true);
                                    }
                                }}
                                title="Preview & Publish"
                            >
                                Preview & Publish
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PolygonBuilder;
