import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAxios } from '../../context/AxiosContext';
import FeaturesType from './FeaturesType';
import './ManageFeatures.css';
import { useHistory, useLocation } from 'react-router';

const ManageFeatures = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const featuresTypeData = [
		{ name: 'New', id: 0 },
		{ name: 'Upcoming', id: 1 },
	];

	const { axiosGetService, axiosPostService, axiosDeleteService } = useAxios();

	const [features, setFeatures] = useState([]);
	const [featureName, setFeatureName] = useState('');
	const [featureType, setFeatureType] = useState(0);
	const [featureDescription, setFeatureDescription] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [isFlyout, setIsFlyout] = useState(false);
	const [manageFeatures, setManageFeatures] = useState(false);
	const [selectedFeature, setSelectedFeature] = useState({});

	const resetStates = () => {
		setFeatureName('');
		setFeatureType(0);
		setFeatureDescription('');
		setIsActive(false);
		setIsFlyout(false);
		setManageFeatures(false);
	};

	const getFeatures = async () => {
		await axiosGetService(
			`api/sdmfeatures/features?pageSize=${10}&pageNumber=${1}&searchValue=${0}&id=${0}`
		).then((response) => {
			if (response.data.sdmFeatures) {
				setFeatures(response.data.sdmFeatures);
			}
		});
	};

	const addFeature = async () => {
		if (!featureName.trim() || !featureDescription.trim()) {
		} else {
			const postData = {
				description: featureDescription,
				fType: featureType,
				isActive: isActive,
				isFlyout: isFlyout,
				title: featureName,
			};
			await axiosPostService(`api/sdmfeatures/features`, postData).then(
				(response) => {
					if (response.data.status) {
						toast.info(
							`You have successfully added the feature ${featureName}`,
							{
								position: 'top-right',
							}
						);
						getFeatures();
						resetStates();
					} else {
						toast.error(response.data.errorMsg, {
							position: 'top-right',
						});
					}
				}
			);
		}
	};

	const updateFeature = ({
		id,
		title,
		fType,
		description,
		isActive,
		isFlyout,
	}) => {
		const updatedFeature = features.map((item) => {
			if (item.id == id) {
				return {
					...item,
					title: title,
					fType: fType,
					description: description,
					isActive: isActive,
					isFlyout: isFlyout,
				};
			} else {
				return item;
			}
		});
		setFeatures(updatedFeature);
	};

	const saveFeatures = async ({
		id,
		title,
		fType,
		description,
		isActive,
		isFlyout,
	}) => {
		const postData = {
			description: description,
			fType: fType,
			isActive: isActive,
			isFlyout: isFlyout,
			title: title,
			id: id,
		};

		await axiosPostService(`/api/sdmfeatures/features/update`, postData).then(
			(response) => {
				if (response.data.status) {
					toast.info(`You have successfully updated the feature ${title}`, {
						position: 'top-right',
					});
				} else {
					toast.error(response.data.errorMsg, {
						position: 'top-right',
					});
				}
			}
		);
	};

	const removeFeature = async (featureId) => {
		await axiosDeleteService(`/api/sdmfeatures/features/${featureId}`).then(
			(response) => {
				if (response.data.status) {
					toast.info('You have successfully deleted', {
						position: 'top-right',
					});
					setFeatures(features.filter(({ id }) => id !== featureId));
				} else {
					toast.error(response.data.errorMsg, {
						position: 'top-right',
					});
				}
				setSelectedFeature({});
			}
		);
	};

	useEffect(() => {
		getFeatures();
	}, []);

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Features
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li class="activePhaseTab">
										<span>Edit Features</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div class="roleAdditionContainer ">
											<div class="addRoleButtonAction">
												<div class="selectAddRoleButton">
													<span
														tabIndex="0"
														onClick={() => {
															setManageFeatures(true);
														}}
														onKeyPress={() => {
															setManageFeatures(true);
														}}
													>
														+&nbsp;Add a New Feature
													</span>
													{manageFeatures ? (
														<Icon
															iconName="ChevronUp"
															onClick={() => {
																setManageFeatures(!manageFeatures);
															}}
															onKeyPress={() => {
																setManageFeatures(!manageFeatures);
															}}
														/>
													) : (
														<></>
													)}
												</div>
												{manageFeatures ? (
													<>
														<div class="addRoleFields">
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">
																	Feature Name
																</span>
																<input
																	placeholder="Enter Feature Name"
																	value={featureName}
																	onChange={(e) =>
																		setFeatureName(e.target.value)
																	}
																/>
															</div>
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">Type</span>
																<FeaturesType
																	className="manageFeaturesType"
																	data={featuresTypeData}
																	setValue={(fType) => {
																		setFeatureType(fType);
																	}}
																	value={featuresTypeData.find(
																		({ id }) => id === featureType
																	)}
																	enableDropdown={true}
																/>
															</div>
															<div class="addRoleNameContainer featureDescription">
																<span class="AddRoleFieldLabel">
																	Description
																</span>
																<textarea
																	placeholder="Enter Description"
																	value={featureDescription}
																	onChange={(e) =>
																		setFeatureDescription(e.target.value)
																	}
																/>
															</div>
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">Is Active</span>

																<label className="NotificationChkboxContainer">
																	<input
																		type="checkbox"
																		checked={isActive}
																		aria-label="Is Active Checkbox"
																		onChange={(e) => setIsActive(!isActive)}
																	/>
																	<span className="CustomCheckmark"></span>
																</label>
															</div>
															<div class="addRoleNameContainer">
																<span class="AddRoleFieldLabel">Is Flyout</span>

																<label className="NotificationChkboxContainer">
																	<input
																		type="checkbox"
																		checked={isFlyout}
																		aria-label="Is Flyout Checkbox"
																		onChange={(e) => setIsFlyout(!isFlyout)}
																	/>
																	<span className="CustomCheckmark"></span>
																</label>
															</div>
															<div>
																<span
																	className="addRoleIcon"
																	title="Add New Feature"
																	onClick={() => {
																		addFeature();
																	}}
																></span>
															</div>
														</div>
													</>
												) : (
													<></>
												)}
											</div>
										</div>
										<div className="addRolesListContainer addDeliveryPlaybookContainer">
											<ul>
												<li>
													<div class="entryCriteriaList">
														<div class="criteriaHeader">
															<div class="criteriaIcon">Feature Name</div>
															<div class="criteriaName">Type</div>
															<div class="criteriaDescription">
																Feature Description
															</div>
															<div class="criteriaIsActive">Is Active</div>
															<div class="criteriaIsFlyout">Is Flyout</div>

															<div class="criteriaEditDeleteIcons">Delete</div>
															<div class="criteriaSaveUpdate">Update</div>
														</div>
														<ul>
															{features.map((item) => (
																<li key={item.id.toString()}>
																	<div class="criteriaTable">
																		<div class="criteriaIcon">
																			<input
																				value={item.title}
																				title="Feature Name"
																				onChange={(e) => {
																					updateFeature({
																						...item,
																						title: e.target.value,
																					});
																				}}
																			/>
																		</div>

																		<div class="criteriaName">
																			<FeaturesType
																				className={`manageFeaturesType ${item.id}`}
																				data={featuresTypeData}
																				setValue={(fType) => {
																					updateFeature({
																						...item,
																						fType: fType,
																					});
																				}}
																				value={featuresTypeData.find(
																					({ id }) => id === item.fType
																				)}
																				enableDropdown={true}
																			/>
																		</div>
																		<div class="criteriaDescription">
																			<div>
																				<textarea
																					value={item.description}
																					title="Feature Description"
																					onChange={(e) => {
																						updateFeature({
																							...item,
																							description: e.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div class="criteriaIsActive">
																			<div>
																				<label className="NotificationChkboxContainer">
																					<input
																						type="checkbox"
																						checked={item.isActive}
																						aria-label="Is Active Checkbox"
																						onChange={(e) => {
																							updateFeature({
																								...item,
																								isActive: !item.isActive,
																							});
																						}}
																					/>
																					<span className="CustomCheckmark"></span>
																				</label>
																			</div>
																		</div>
																		<div class="criteriaIsFlyout">
																			<div>
																				<label className="NotificationChkboxContainer">
																					<input
																						type="checkbox"
																						checked={item.isFlyout}
																						aria-label="Is Flyout Checkbox"
																						onChange={(e) => {
																							updateFeature({
																								...item,
																								isFlyout: !item.isFlyout,
																							});
																						}}
																					/>
																					<span className="CustomCheckmark"></span>
																				</label>
																			</div>
																		</div>

																		<div class="criteriaEditDeleteIcons">
																			<span
																				className="deliverPlaybookDeleteIcon"
																				title="Delete"
																				tabIndex="0"
																				onClick={() => {
																					setSelectedFeature(item);
																				}}
																				onKeyPress={() => {
																					setSelectedFeature(item);
																				}}
																			></span>
																			{selectedFeature.id === item.id && (
																				<div className="deleteSamplesPopUp">
																					<p>
																						Are you sure that you want to delete
																						this feature?
																					</p>
																					<div>
																						<button
																							onClick={() => {
																								setSelectedFeature({});
																							}}
																							onKeyPress={() => {
																								setSelectedFeature({});
																							}}
																						>
																							Cancel
																						</button>
																						<button
																							onClick={() => {
																								removeFeature(item.id);
																							}}
																							onKeyPress={() => {
																								removeFeature(item.id);
																							}}
																						>
																							Delete
																						</button>
																					</div>
																				</div>
																			)}
																		</div>
																		<div class="criteriaSaveUpdate">
																			<button
																				onClick={() => {
																					saveFeatures(item);
																				}}
																				onKeyPress={() => {
																					saveFeatures(item);
																				}}
																			>
																				Update
																			</button>
																		</div>
																	</div>
																</li>
															))}
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageFeatures;
