import React from 'react';
// import { CKEditor } from 'ckeditor4-react';
import urlContains from '../../config/index';
import TemporaryCkAlert from '../CkEditor/TemporaryCkAlert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import Font from '@ckeditor/ckeditor5-font/src/font';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials  from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableOfContents from '@ckeditor/ckeditor5-document-outline/src/tableofcontents';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Undo from '@ckeditor/ckeditor5-undo/src/undo';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import CustomTableAttributes from '../CkEditor/TableAttributes';
import EnableStyleAttribute from '../CkEditor/CustomClassElement';


class MyUploadAdapter {
	constructor(loader) {
	 // The file loader instance to use during the upload.
	 this.loader = loader;
	}
	// Starts the upload process.
	upload() {
	 return this.loader.file.then(
	  (file) =>
	   new Promise((resolve, reject) => {
		this._initRequest();
		this._initListeners(resolve, reject, file);
		this._sendRequest(file);
	   })
	 );
	}
	// Aborts the upload process.
	abort() {
	 if (this.xhr) {
	  this.xhr.abort();
	 }
	}
	// Initializes the XMLHttpRequest object using the URL passed to the constructor.
	_initRequest() {
	 const xhr = (this.xhr = new XMLHttpRequest());
	 const { apiEndPoint } = urlContains(window.location.hostname);
   
	 // Note that your request may look different. It is up to you and your editor
	 // integration to choose the right communication channel. This example uses
	 // a POST request with JSON as a data structure but your configuration
	 // could be different.
	 xhr.open('POST', `${apiEndPoint}/api/sdmactivity/imageupload`, true);
	 xhr.responseType = 'json';
	}
   
	// Initializes XMLHttpRequest listeners.
	_initListeners(resolve, reject, file) {
	 const xhr = this.xhr;
	 const loader = this.loader;
	 const genericErrorText = `Couldn't upload file: ${file.name}.`;
   
	 xhr.addEventListener('error', () => reject(genericErrorText));
	 xhr.addEventListener('abort', () => reject());
	 xhr.addEventListener('load', () => {
	  const response = xhr.response;
   
	  // This example assumes the XHR server's "response" object will come with
	  // an "error" which has its own "message" that can be passed to reject()
	  // in the upload promise.
	  //
	  // Your integration may handle upload errors in a different way so make sure
	  // it is done properly. The reject() function must be called when the upload fails.
	  if (!response || response.error) {
	   return reject(
		response && response.error ? response.error.message : genericErrorText
	   );
	  }
   
	  // If the upload is successful, resolve the upload promise with an object containing
	  // at least the "default" URL, pointing to the image on the server.
	  // This URL will be used to display the image in the content. Learn more in the
	  // UploadAdapter#upload documentation.
	  if (response.url) {
	   const uploadedImage = sessionStorage.getItem('topicBuilderImage');
	   if (uploadedImage === null) {
		sessionStorage.setItem(
		 'topicBuilderImage',
		 JSON.stringify([response.imagePath])
		);
	   } else {
		const updatedUrls = JSON.parse(uploadedImage);
		sessionStorage.setItem(
		 'topicBuilderImage',
		 JSON.stringify([...updatedUrls, response.imagePath])
		);
	   }
	   resolve({
		default: response.url,
	   });
	  }
	 });
   
	 // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
	 // properties which are used e.g. to display the upload progress bar in the editor
	 // user interface.
	 if (xhr.upload) {
	  xhr.upload.addEventListener('progress', (evt) => {
	   if (evt.lengthComputable) {
		loader.uploadTotal = evt.total;
		loader.uploaded = evt.loaded;
	   }
	  });
	 }
	}
   
	// Prepares the data and sends the request.
	_sendRequest(file) {
	 // Prepare the form data.
	 const data = new FormData();
   
	 data.append('upload', file);
   
	 // Important note: This is the right place to implement security mechanisms
	 // like authentication and CSRF protection. For instance, you can use
	 // XMLHttpRequest.setRequestHeader() to set the request headers containing
	 // the CSRF token generated earlier by your application.
	 // Send the request.
	 this.xhr.setRequestHeader(
	  'Authorization',
	  'Bearer ' + sessionStorage.getItem('acceesKey')
	 );
	 this.xhr.setRequestHeader('userAlias', sessionStorage.getItem('userAlias'));
	 this.xhr.send(data);
	}
   }
   
   function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
	 // Configure the URL to the upload script in your back-end here!
	 return new MyUploadAdapter(loader);
	};
   }

const ActivityEditor = ({ value, setValue, type, selectedComplexity,onChange}) => {
	const { apiEndPoint } = urlContains(window.location.hostname);

	const editorConfiguration = {
		plugins: [
			Alignment,
			Autoformat,
			BlockQuote,
			Bold,
			Font,
			Essentials,
			FontFamily,
			FontSize,
			GeneralHtmlSupport,
			Heading,
			Highlight,
			HorizontalLine,
			Image,
			ImageCaption,
			ImageResize,
			ImageStyle,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			List,
			Paragraph,
			RemoveFormat,
			SpecialCharacters,
			SpecialCharactersEssentials,
			SourceEditing,
			Strikethrough,
			Table,
			TableProperties,
			TableCellProperties,
			TableToolbar,
			// TableOfContents,
			Underline,
			Undo,
			UploadAdapter,
			MyCustomUploadAdapterPlugin,
			// CustomTableAttributes,
			EnableStyleAttribute,
		],
		link: {
            addTargetToExternalLinks: true,
		}, 
		alignment: {
            options: [ 'left', 'right','center', 'justify' ]
        },
		heading: {
			options: [
				{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
				{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
				{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
				{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
			]
		},
		// licenseKey: 'your-license-key',
		toolbar: {
			items: [
				'undo',
				'redo',
				'heading',
				'|',
				'alignment',
				'fontsize',
				'fontfamily',
				'fontcolor',
				'highlight',
				'|',
				'bold',
				'italic',
				'strikethrough',
				'underline',
				'|',
				'removeFormat',
				'|',
				'numberedList',
				'bulletedList',
				'|',
				'outdent',
				'indent',
				'horizontalLine',
				'specialCharacters',
				'|',
				'blockQuote',
				'|',
				'link',
				'insertTable',
				'imageUpload',
				'sourceEditing',
				// 'tableOfContents',
			],
			shouldNotGroupWhenFull: true
		},

		image: {
			toolbar: [
				'imageTextAlternative',
				'|',
				'imageStyle:alignLeft',
				'imageStyle:full',
				'imageStyle:alignRight',
				'imageStyle:block',
			],
			styles: [
				// This option is equal to a situation where no style is applied.
				'full',

				// This represents an image aligned to the left.
				'alignLeft',

				// This represents an image aligned to the right.
				'alignRight',

				'block',
			],
			resizeUnit: 'px',
		},
		indentBlock: {
            offset: 1,
            unit: 'em'
        }
	};
	return (
		<div >
			<TemporaryCkAlert />
			<CKEditor
				config={editorConfiguration}
				editor={ClassicEditor}
				data={value[selectedComplexity][type] || ''}
				onChange={(event, editor) => {
					const updatedComplexityData = value[selectedComplexity] || {}; // Initialize if undefined
					
					setValue((prevState) => ({
						...prevState,
						[selectedComplexity]: { 
							...updatedComplexityData, 
							[type]: editor.getData() 
						},
					}));
				}}
				// onChange={(event, _editor) =>
				// 	setValue(prevState => ({
				// 		...prevState,
				// 		[selectedComplexity]: { ...value[selectedComplexity], [type]: _editor.getData() },
				// 	}))
				// }
				
			/>
			{/* <CKEditor 
				initData={value[selectedComplexity][type]}
				onChange={(event, _editor) =>
					setValue(prevState => ({
						...prevState,
						[selectedComplexity]: { ...value[selectedComplexity], [type]: event.editor.getData() },
					}))
				}
				config={{
					filebrowserImageUploadUrl: `${apiEndPoint}/api/sdmactivity/imageupload`,
				}}
				onFileUploadRequest={evt => {
					const xhr = evt.data.fileLoader.xhr;
					xhr.setRequestHeader(
						'Authorization',
						'Bearer ' + sessionStorage.getItem('acceesKey')
					);
					xhr.setRequestHeader('userAlias', sessionStorage.getItem('userAlias'));
				}}
				onFileUploadResponse={evt => {
					const imagePath = JSON.parse(
						evt.data.fileLoader.xhr.response
					).imagePath;
					const uploadedImage = sessionStorage.getItem('topicBuilderImage');
					if (uploadedImage === null) {
						if (imagePath) {
							sessionStorage.setItem(
								'topicBuilderImage',
								JSON.stringify([imagePath])
							);
						}
					} else {
						const updatedUrls = JSON.parse(uploadedImage);
						if (imagePath) {
							sessionStorage.setItem(
								'topicBuilderImage',
								JSON.stringify([...updatedUrls, imagePath])
							);
						}
					}
				}}
			/> */}
		</div>
	);
};

export default ActivityEditor;
