import React, { Component } from 'react';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'react-toastify/dist/ReactToastify.css';

import ClassicEditorBuild from '../classic-editor-with-track-changes';

import '../CkEditor.css';

const appData = {
	users: [],
	currentUser: null,
	suggestionsData: [],
	reviewedSuggestions: [],
};

const time1 = null;
let time2 = null;
let time3 = null;

export default class ActivityContributorTrackChange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// You need this state to render the <CKEditor /> component after the layout is ready.
			// <CKEditor /> needs the HTMLElement of the `Sidebar` plugin provided through
			// the `config` property and you have to ensure that is already rendered.
			isLayoutReady: false,
			editor: null,
			editorInitialData: null,
			licenseKey: null,
		};

		this.sidebarElementRef = React.createRef();

		// Switch between inline and sidebar annotations according to the window size.
		this.refreshDisplayMode = this.refreshDisplayMode.bind(this);
		// Prevent closing the tab when any action is pending.
		this.checkPendingActions = this.checkPendingActions.bind(this);

		window.addEventListener('resize', this.refreshDisplayMode);
		window.addEventListener('beforeunload', this.checkPendingActions);
	}

	componentDidMount() {
		appData.users = this.props.users;
		appData.currentUser = this.props.currentUser;
		if (this.props.initalSuggestions) {
			appData.suggestionsData = this.props.initalSuggestions;
		}
		if (this.props.reviewedSuggestions) {
			appData.reviewedSuggestions = this.props.reviewedSuggestions;
		}
		const licenseKey = this.props.licenseKey;
		this.setState({
			// When the layout is ready, you can switch the state and render the `<CKEditor />` component.
			isLayoutReady: true,
			editorInitialData: this.props.initialData,
			// Save the provided license key in the local storage.
			licenseKey,
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.refreshDisplayMode);
		window.removeEventListener('beforeunload', this.checkPendingActions);
		appData.suggestionsData = [];
		appData.reviewedSuggestions = [];
		clearInterval(time1);
		clearInterval(time2);
		clearInterval(time3);
	}

	render() {
		$(document).ready(function () {
			time3 = setInterval(acceptOrRejectSuggestion, 500);
			function acceptOrRejectSuggestion() {
				// $("time").css('display', 'none');
				// To fix reactivated accessibility to add tabindex for 'X' button.
				$(".ck .ck-annotation .ck-button").attr('tabindex', 0);
				$('.ck-sidebar-item').each(function (index, element) {
					const suggestionid = $(this)
						.children() // has access to class ck ck-annotation-wrapper ck-rounded-corners
						.children() // has access to class ck-suggestion-wrapper

						.attr('data-suggestion-id'); // has access to suggestionsid

					const reject_button_identifier = $(this)
						.children() // has access to ck ck-annotation-wrapper ck-rounded-corners
						.children() // has access to ck-suggestion-wrapper
						.children() // has acces to ck-suggestion ck-annotation ck-suggestion-insertion
						.children() // has access to ck ck-user ck-suggestion__user ck-annotation__user
						.children() // has acces to ck-suggestion__main ck-annotation__main
						.children() // has acces to ck-suggestion__info ck-annotation__info
						.children()[5] // has access to ck-suggestion__actions ck-annotation__actions
						.getAttribute('id'); // has acces to id of reject button

					const accept_button_identifier = $(this)
						.children() // has access to ck ck-annotation-wrapper ck-rounded-corners
						.children() // has access to ck-suggestion-wrapper
						.children() // has acces to ck-suggestion ck-annotation ck-suggestion-insertion
						.children() // has access to ck ck-user ck-suggestion__user ck-annotation__user
						.children() // has acces to ck-suggestion__main ck-annotation__main
						.children() // has acces to ck-suggestion__info ck-annotation__info
						.children()[1] // has acces to ck-suggestion__actions ck-annotation__actions
						.getAttribute('id'); // has access to id of accept button

					for (const data in appData.reviewedSuggestions) {
						if (JSON.parse(appData.reviewedSuggestions[data]).state === 'rejected') {
							// $(`[data-suggestion-id=${JSON.parse(appData.reviewedSuggestions[data]).id}]`).css({
							// 	background: '#F5C8D2',
							// 	'border-left-width': '7px',
							// });
							if (JSON.parse(appData.reviewedSuggestions[data]).id === suggestionid) {
								// $(`[aria-labelledby=${reject_button_identifier}]`).text('Rejected');
								// $(`[aria-labelledby=${reject_button_identifier}]`).css({
								// 	'font-weight': 'bold',
								// 	color: 'rgb(239, 2, 60)',
								// 	'background-color': ' rgb(245, 200, 210)',
								// 	background: 'rgb(245, 200, 210)',
								// 	'font-size': '14px',
								// 	'padding-top': '6px',
								// 	'pointer-events': 'none',
								// });
								$(`[aria-labelledby=${accept_button_identifier}]`).css({
									display: 'none',
								});
							}
						} else if (JSON.parse(appData.reviewedSuggestions[data]).state === 'accepted') {
							// $(`[data-suggestion-id=${JSON.parse(appData.reviewedSuggestions[data]).id}]`).css({
							// 	background: '#CEF6D1',
							// 	'border-left-width': '7px',
							// });
							if (JSON.parse(appData.reviewedSuggestions[data]).id === suggestionid) {
								// $(`[aria-labelledby=${reject_button_identifier}]`).text('Approved');
								// $(`[aria-labelledby=${reject_button_identifier}]`).css({
								// 	'font-weight': 'bold',
								// 	color: '#039817',
								// 	'background-color': ' rgb(245, 200, 210)',
								// 	background: 'rgb(206, 246, 209)',
								// 	'font-size': '14px',
								// 	'padding-top': '6px',
								// 	'pointer-events': 'none',
								// });
								$(`[aria-labelledby=${accept_button_identifier}]`).css('display', 'none');
							}
						}
					}
				});
			}
		});
		return (
			<div className="App">
				<main>
					<div className="centered topicDetailsCkEditor">{this.renderEditor()}</div>
				</main>
			</div>
		);
	}

	renderEditor() {
		return (
			<div className="row row-editor" id="HistoryTipsAndTechniquesEditor">
				{/* Do not render the <CKEditor /> component before the layout is ready. */}
				{this.state.isLayoutReady && this.state.editorInitialData && (
					<CKEditor
						onReady={(editor) => {
							editor.execute('trackChanges');

							// this.setState({ editor });
							//this.refreshDisplayMode();
						}}
						onChange={() => {
							/* Since this a view mode only we don't need to update data */
						}}
						editor={ClassicEditorBuild}
						config={{
							extraPlugins: [TrackChangesIntegration],
							sidebar: {
								container: this.sidebarElementRef.current,
							},
							licenseKey: this.state.licenseKey,
						}}
						data={this.state.editorInitialData}
					/>
				)}
				<div
					ref={this.sidebarElementRef}
					className="sidebar"
					id="HistoryTipsAndTechniquesSidebar"
				></div>
			</div>
		);
	}

	refreshDisplayMode() {
		if (!this.state.editor) {
			return;
		}

		const annotations = this.state.editor.plugins.get('Annotations');
		const sidebarElement = this.sidebarElementRef.current;
		const mediumSize = 0;
		const smallerSize = 0;
		if (window.innerWidth < mediumSize) {
			sidebarElement.classList.remove('narrow');
			sidebarElement.classList.add('hidden');
			annotations.switchTo('inline');
		} else if (window.innerWidth < smallerSize) {
			sidebarElement.classList.remove('hidden');
			sidebarElement.classList.add('narrow');
			annotations.switchTo('narrowSidebar');
		} else {
			sidebarElement.classList.remove('hidden', 'narrow');
			annotations.switchTo('wideSidebar');
		}
	}

	checkPendingActions(domEvt) {
		if (!this.state.editor) {
			return;
		}

		if (this.state.editor.plugins.get('PendingActions').hasAny) {
			domEvt.preventDefault();
			domEvt.returnValue = true;
		}
	}
}

class TrackChangesIntegration {
	constructor(editor) {
		this.editor = editor;
	}

	init() {
		const usersPlugin = this.editor.plugins.get('Users');
		const trackChangesPlugin = this.editor.plugins.get('TrackChanges');
		// this.editor.isReadOnly = true;
		this.editor.enableReadOnlyMode( 'my-feature-id' );
		// Load the users data.
		for (const user of appData.users) {
			usersPlugin.addUser(user);
		}
		usersPlugin.defineMe(appData.currentUser);
		$(document).ready(function () {
			const editorHeight = $('#HistoryTipsAndTechniquesEditor').css('height');

			$('#HistoryTipsAndTechniquesSidebar').css({ height: editorHeight });

			time2 = setInterval(contributor, 1000);
			// setTimeout(function( ) { clearInterval( time2 ); }, 5000);
			function contributor() {
				$('time').css('display', 'none');
				$('.ck-comment__input-container').css('display', 'none');
				// we iterate through sidebar items to find out, what are the suggestions made by the reviewer.
				const editorHeight = $('.ck-focused').css('height');
				if (editorHeight) {
					const newSidebarHeight = parseInt(editorHeight.replace(/px/, ''), 10) + 39 + 'px';
					if ($('.ck-focused').parents('#HistoryTipsAndTechniquesEditor').length == 1) {
						$('#HistoryTipsAndTechniquesSidebar').css({
							height: newSidebarHeight,
						});
					}
				} else {
					$('.ck-blurred').each(function (index, element) {
						if (
							$(element).parent().parent().parent().parent().find('#HistoryTipsAndTechniquesEditor')
								.length
						) {
							const editorHeightS = $(element).parent().css('height');
							if (editorHeightS) {
								const newSidebarHeightS = parseInt(editorHeightS.replace(/px/, ''), 10) + 39 + 'px';

								$('#HistoryTipsAndTechniquesSidebar').css({
									height: newSidebarHeightS,
								});
							}
						}
					});
				}
			}
			// clearInterval(time2);
		});
		// jquery function to highlight the accepted and rejected suggestions.
		// $(document).ready(function() {

		trackChangesPlugin.adapter = {
			getSuggestion: (suggestionId) => {
				// This function should query the database for data for a suggestion with a `suggestionId`.

				return new Promise((resolve) => {
					for (const data in appData.suggestionsData) {
						if (JSON.parse(appData.suggestionsData[data]).id === suggestionId) {
							resolve(JSON.parse(appData.suggestionsData[data]));
						}
					}
				});
			},

			addSuggestion: (suggestionData) => {
				// This function should save `suggestionData` in the database.
				return Promise.resolve({
					createdAt: new Date(), // Should be set server-side.
				});
			},

			updateSuggestion: (id, suggestionData) => {
				// This function should update `suggestionData` in the database.
				return Promise.resolve();
			},
		};
	}
}
