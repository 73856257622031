import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';

function DiscardPopUp({
	showDiscardModal,
	setShowDiscardModal,
	projectTypeId,
	solutionMethodId,
	phaseId,
	parentPhaseId,
	activityId,
	complexityIds,
	selectedComplexity,
	draftId
}) {
	const [messagesType, setMessagesType] = useState({});

	const { axiosGetService } = useAxios();

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=discardPopUpMessage&title=0`
		).then((response) => {
			message['discardPopUpMessage'] = response.data;
		});

		setMessagesType(message);
	};

	const closeModal = () => {
		setShowDiscardModal(false);
	};
	const removeDraftedBusinessRule = async ()=>{
		let id = activityId !== 'null'? activityId : draftId
		let api  = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${id}/2/${complexityIds}/${true}`
		await axiosGetService(api)
	}
	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="activityDiscardPopUp"
			show={showDiscardModal}
			onHide={() => {
				closeModal();
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{messagesType['discardPopUpMessage']}</p>
				{/* <p>Are you sure that you want to exit?</p> */}
				<div className="discardPopUpActivityButtons">
					<Button
						title="No"
						className="activityDiscardPopUpCancel"
						variant="primary"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								closeModal();
							}
						}}
						onClick={() => closeModal()}
					>
						No
					</Button>
					<Link
						aria-label="Discard PopUp Yes"
						to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`}
					>
						<Button title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info"
						  onClick={removeDraftedBusinessRule}>
							Yes
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
