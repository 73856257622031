import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class EnableIdAttribute extends Plugin {
	init() {
		const INLINEDATAID = 'inlineDataId';
		const LINKCLASS = 'linkClass';
		const editor = this.editor;
		// Upcast converter inline elements => text attribute
		// By commenting out the name in the view you make this a global upcast converter
		// for inline elements like spans, links etc.
		editor.model.schema.extend('$text', { allowAttributes: [INLINEDATAID, LINKCLASS] });

		editor.conversion.for('upcast').elementToAttribute({
			view: {
				name: 'a', // uncomment if you want to upcast only links
				attributes: { 'data-attr-id': true },
			},
			model: {
				name: '$text',
				key: INLINEDATAID,
				value: (viewElement) => viewElement.getAttribute('data-attr-id'),
			},
			converterPriority: 'low',
		});

		// Both the data and the editing pipelines are affected by this conversion.
		editor.conversion.for('downcast').add((dispatcher) => {
			// Links are represented in the model as a "linkHref" attribute!!!
			// Use the "low" listener priority to apply the changes after the link feature!!!
			dispatcher.on(
				'attribute:linkHref',
				(evt, data, conversionApi) => {
					const viewWriter = conversionApi.writer;
					const viewSelection = viewWriter.document.selection;
					// Adding a new attribute is done by wrapping all link ranges and selection
					// in a new attribute element with the "data-attr-id" attribute.
					const viewElement = viewWriter.createAttributeElement(
						'a',
						{
							'data-attr-id': data.item.getAttribute(INLINEDATAID),
						},
						{
							priority: 5,
						}
					);

					if (data.item.is('selection')) {
						viewWriter.wrap(viewSelection.getFirstRange(), viewElement);
					} else {
						viewWriter.wrap(conversionApi.mapper.toViewRange(data.range), viewElement);
					}
				},
				{ priority: 'low' }
			);
		});

		editor.conversion.for('upcast').elementToAttribute({
			view: {
				name: 'a', // uncomment if you want to upcast only links
				attributes: { class: true },
			},
			model: {
				name: '$text',
				key: LINKCLASS,
				value: (viewElement) => viewElement.getAttribute('class'),
			},
			converterPriority: 'low',
		});

		// Both the data and the editing pipelines are affected by this conversion.
		editor.conversion.for('downcast').add((dispatcher) => {
			// Links are represented in the model as a "linkHref" attribute!!!
			// Use the "low" listener priority to apply the changes after the link feature!!!
			dispatcher.on(
				'attribute:linkHref',
				(evt, data, conversionApi) => {
					const viewWriter = conversionApi.writer;
					const viewSelection = viewWriter.document.selection;
					// Adding a new attribute is done by wrapping all link ranges and selection
					// in a new attribute element with the "data-attr-id" attribute.
					const viewElement = viewWriter.createAttributeElement(
						'a',
						{
							class: data.item.getAttribute(LINKCLASS),
						},
						{
							priority: 5,
						}
					);

					if (data.item.is('selection')) {
						viewWriter.wrap(viewSelection.getFirstRange(), viewElement);
					} else {
						viewWriter.wrap(conversionApi.mapper.toViewRange(data.range), viewElement);
					}
				},
				{ priority: 'low' }
			);
		});
	}
}
