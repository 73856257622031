import React, { useState, useEffect } from 'react';
import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';
// import '../../HashtagSuggestions/HashtagSuggestions.css';

const HashtagSuggestions = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	existingHashtags,
	setDataSearchInput,
	setExistingHashtags,
	setHashtagSuggestions,
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const addHashtag = () => {
		if (searchValue && searchValue[0] === '#') {
			const checkSpace = searchValue.split(' ');
			const updatedHashtag = existingHashtags.filter(
				(hashTag) => hashTag === checkSpace[0]
			);
			if (updatedHashtag.length === 0) {
				setSearchValue('');
				setExistingHashtags([checkSpace[0], ...existingHashtags]);
				setHashtagSuggestions([]);
			}
		} else {
			setDataSearchInput(searchValue);
		}
	};

	const selectHashtagFromSuggestions = async (item) => {
		const updatedHashtag = existingHashtags.filter(
			(hashTag) => hashTag === item
		);
		if (updatedHashtag.length === 0) {
			setExistingHashtags([item, ...existingHashtags]);
			setHashtagSuggestions([]);
			setDataSearchInput('');
			setSearchValue('');
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div id={className}>
			<div
				className={`dropDowncontainer hashtagsDropDownContainer ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					placeholder="search for hashtags"
					onKeyPress={(e) => {
						if (e.which === 13) {
							addHashtag();
						}
					}}
					onFocus={() => {
						setVisible(true);
					}}
					onClick={() => {
						if (!visible) {
							setVisible(true);
						}
					}}
					className="hashtagInput"
				/>
				<div className="tabsTitleToolTip">
					<span className="tabsToolTipArrow"></span>
					<span>Search Input</span>
				</div>
				<div>
					{data.length > 0 ? (
						<div
							className="dropDownList hashtagDpList"
							style={{ display: visible ? 'block' : '' }}
						>
							<ul>
								{data.map((item) => {
									if (item.type === 'header') {
										return (
											<li
												className="dropdownHeader"
												title={item.name + ' Header'}
												key={item.id}
											>
												<span>{item.name}</span>
											</li>
										);
									}

									return (
										<li title={item.name} key={item.id}>
											<span
												className="complexityLabel"
												onClick={() => {
													selectHashtagFromSuggestions(item.name);
												}}
											>
												{item.name}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default HashtagSuggestions;
