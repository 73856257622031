import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { v4 as uniqueIdGenerator } from 'uuid';

import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import ReviewersOrApproversList from '../ReviewersOrApproversList';
import MyApprovalsInfo from '../MyReviewsInfo';

const ActivityApproval = ({
	currentPage,
	pageLimit,
	setDataCount,
	setCurrentPage,
	handleStatusClassName,
}) => {
	const { url } = useRouteMatch();
	const { track } = useAppInsights();
	const { getNotifications } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService } = useAxios();
	const [searchText, setSearchText] = useState('');
	const [contributions, setContributions] = useState([]);
	const [contributorsInfo, setContributorsInfo] = useState({});
	const [documentStatusId, setDocumentStatusId] = useState(-1);
	const [showApproversOrReviewersList, setShowApproversOrReviewersList] =
		useState(false);
	const [selectedArtifactId, setSelectedArtifactId] = useState(-1);

	const getContributions = async () => {
		const searchParam = searchText === '' ? 0 : searchText;
		setDataCount(0);
		setContributions([]);
		await axiosGetService(
			`api/sdmactivity/getactivityapprovals?activityName=${searchParam}&pageSize=${pageLimit}&pageNumber=${currentPage}`
		).then((res) => {
			if (Number(res.data.count) > 0) {
				const contributionData = [];
				for (const activity of res.data.activities) {
					contributionData.push({ ...activity, uniqueId: uniqueIdGenerator() });
				}
				setContributions(contributionData);
				setDataCount(Number(res.data.count));
			} else {
				setContributions(null);
				setDataCount(null);
			}
		});
	};
	const getUserDetails = async (
		contributedEmail,
		reviewerEmail,
		approverEmail
	) => {
		const accessToken = await axiosTokenService();
		const userDetails = {};
		if (contributedEmail && !(contributedEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, contributedEmail);
			userDetails[contributedEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (reviewerEmail && !(reviewerEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, reviewerEmail);
			userDetails[reviewerEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (approverEmail && !(approverEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, approverEmail);
			userDetails[approverEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		setContributorsInfo((prevState) => ({ ...prevState, ...userDetails }));
	};

	const postNotificationRead = async (contribution) => {
		const postBody = {
			actionTypeId: contribution.actionTypeId,
			itemId: contribution.activityId,
			complexityId: contribution.complexityId,
			itemTypeName: 'Activity',
			isNotifiedUI: true,
			createdOn: new Date().toUTCString(),
		};
		await axiosPostService('api/sdmnotification/insert', [postBody])
			.then((response) => {
				getNotifications();
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [searchText]);

	useEffect(() => {
		const timeOut = setTimeout(() => {
			getContributions();
		}, 800);
		return () => {
			clearTimeout(timeOut);
		};
	}, [currentPage, searchText]);

	useEffect(() => {
		track(2, 164, 'Activity', 'My Approvals');
	}, []);

	return (
		<div className="myContributionListDiv">
			<div className="myContributionParentDiv">
				<div className="myConributionHeaderDiv">
					<div className="newTableChildRow header">
						<div className="newTableRow">
							<div className="newWrapper newAttributes">
								<div className="newWrapper newTitleCommentModuleReporter ">
									<div className="newWrapper newTitleComment">
										<div className="newColumn newTitle newActTd ">
											Activity Name{' '}
											<div className="myContrTitleSearch">
												<Icon iconName="Search" />
												<input
													type="text"
													title="Search for an Activity"
													value={searchText}
													placeholder="Search by name"
													onChange={(e) => setSearchText(e.target.value)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Complexity</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Acted On</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status  newActTd ">
									Approval Status
								</div>
							</div>
						</div>
					</div>
				</div>
				{contributions &&
					contributions.map((contribution, id) => (
						<div className="mycontributionChildWrapper" key={id}>
							<div className="newTableChildRow">
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment">
												<div className="newColumn newTitle newActTd ">
													<Link
														title={contribution.activityName}
														aria-label={contribution.activityName}
														to={
															contribution.approvalStatus === 'Pending Approval'
																? `/project/${contribution.projectTypeId}/method/${contribution.solutionMethodId}/phase/${contribution.phaseId != 0 ? contribution.phaseId : contribution.parentPhaseId}/parentPhase/null/activity/${contribution.activityId}/approver/edit?complexity=${contribution.complexityId}&identifier=${contribution.activityIdentifier}`
																: `${url}/historicalpreview/myapprovals/activity/${contribution.id}/${contribution.activityId}/${contribution.stateId}?complexity=${contribution.complexityId}`
														}
														onClick={() => {
															postNotificationRead(contribution);
															track(
																1,
																164,
																contribution.activityId,
																contribution.activityName,
																{
																	artifactType: 'Activity',
																	crowdSourcingType: 'My Approvals',
																	complexity: contribution.complexityName,
																}
															);
														}}
													>
														{contribution.activityName}
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.complexityName}
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn date newActTd">
											{contribution.approvalDate || '-'}
										</div>
									</div>
									<div className="newWrapper ">
										<div
											className={`newColumn status newActTd contributionsInfo ${handleStatusClassName(
												contribution.approvalStatus
											)}`}
											tabIndex={0}
											role="button"
											aria-label={contribution.approvalStatus}
											onClick={() => {
												if (
													contribution.approvalStatus === 'Pending Approval'
												) {
													setShowApproversOrReviewersList(true);
													setSelectedArtifactId(contribution.uniqueId);
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													if (
														contribution.approvalStatus === 'Pending Approval'
													) {
														setShowApproversOrReviewersList(true);
														setSelectedArtifactId(contribution.uniqueId);
													}
												}
											}}
											onMouseEnter={() => {
												setDocumentStatusId(contribution.uniqueId);
												getUserDetails(
													contribution.contributorEmail,
													contribution.reviewerEmail
														? contribution.reviewerEmail
														: contribution.reviewerRejectedEmail,
													contribution.approverEmail
														? contribution.approverEmail
														: contribution.approverRejectedEmail
												);
											}}
											onMouseLeave={() => {
												setDocumentStatusId(-1);
											}}
											onBlur={(e) => {
												setDocumentStatusId(-1);
											}}
											onFocus={(e) => {
												setDocumentStatusId(contribution.uniqueId);
												getUserDetails(
													contribution.contributorEmail,
													contribution.reviewerEmail
														? contribution.reviewerEmail
														: contribution.reviewerRejectedEmail,
													contribution.approverEmail
														? contribution.approverEmail
														: contribution.approverRejectedEmail
												);
											}}
										>
											{contribution.approvalStatus || '-'}
											{documentStatusId === contribution.uniqueId ? (
												<MyApprovalsInfo
													contributorEmail={contribution.contributorEmail}
													reviewerEmail={
														contribution.reviewerEmail
															? contribution.reviewerEmail
															: contribution.reviewerRejectedEmail
													}
													approverEmail={
														contribution.approverEmail
															? contribution.approverEmail
															: contribution.approverRejectedEmail
													}
													contributionData={contribution}
													contributorsInfo={contributorsInfo}
													type="myApprovals"
													documentStatus={contribution.approvalStatus}
												/>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</div>
							{showApproversOrReviewersList &&
								contribution.uniqueId === selectedArtifactId ? (
								<ReviewersOrApproversList
									showList={setShowApproversOrReviewersList}
									setShowList={setShowApproversOrReviewersList}
									actionTypeId={
										contribution.documentStatus === 'Pending Review' ? 1 : 2
									}
									artifactId={contribution.activityId}
									artifactType="activities"
									setSelectedArtifactId={setSelectedArtifactId}
								/>
							) : (
								<></>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default ActivityApproval;
