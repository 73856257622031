import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@fluentui/react/lib/Icon';

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: 'white',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: 'white',
});

const PhaseSequence = ({
	subgroup,
	activityGroup,
	setActivityGroup,
	group,
	detachActivityFromSubgroup,
	dragNDropId,
}) => {
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const item = reorder(
			subgroup.activities,
			result.source.index,
			result.destination.index
		);
	};

	return (
		<>
			<Droppable droppableId={dragNDropId} type={`droppableSubgroupActivity`}>
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{subgroup.activities.map((activity, index) => (
							<Draggable
								key={activity.dragNDropId}
								draggableId={activity.dragNDropId}
								index={index}
							>
								{(childProvided, childSnapshot) => (
									<div
										ref={childProvided.innerRef}
										{...childProvided.draggableProps}
										{...childProvided.dragHandleProps}
										style={getItemStyle(
											childSnapshot.isDragging,
											childProvided.draggableProps.style
										)}
									>
										<div
											className={
												activity.isParentActivity
													? 'delivery'
													: !activity.isParentActivity
													? 'orangeDelivery'
													: ''
											}
										>
											<div className="activityChildContainer">
												<div>
													<div
														className="activityChildTitle"
														title={activity.activityTitle}
													>
														{activity.isMethodOwnerOrCoOwner &&
														activity.isDrafted ? (
															<span
																className={
																	activity.isDrafted
																		? 'draftActivity'
																		: 'nonDraftActivity'
																}
															>
																Draft
															</span>
														) : (
															<></>
														)}
														<span className="titleOfActivity">
															{activity.activityTitle}
														</span>
														{!group.isGroupRefered &&
														!group.isGroupReferredWithActivity ? (
															<span
																title="Move from subgroup to group"
																className="removeFromSubgroupIcon"
																tabIndex="0"
																onClick={() => {
																	detachActivityFromSubgroup(
																		group,
																		subgroup,
																		activity
																	);
																}}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		detachActivityFromSubgroup(
																			group,
																			subgroup,
																			activity
																		);
																	}
																}}
															></span>
														) : (
															<></>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</Draggable>
						))}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</>
	);
};

export default PhaseSequence;
