import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';

const PhaseSelectionModal = ({
	setShowParentPhasePublishModal,
	setShowPublishModal,
	phasePublishTitle,
	parentPhasePublishTitle,
	setShowPhaseSelectionModal,
}) => {
	useEffect(() => {
		if (!phasePublishTitle || !parentPhasePublishTitle) {
			if (phasePublishTitle) {
				setShowPublishModal(true);
			} else {
				setShowParentPhasePublishModal(true);
			}
			setShowPhaseSelectionModal(false);
		}
	}, []);
	return (
		<>
			{phasePublishTitle && parentPhasePublishTitle ? (
				<Modal
					className="templatesingleSelectionPopUp"
					show={true}
					onHide={() => {
						setShowPhaseSelectionModal(false);
					}}
					size="md"
					centered
				>
					<Modal.Header className="singleContributionModal">
						<div className="templateContributionSelection">
							<span className="settingsLabel">
								Please Select the phase you want to publish
							</span>
						</div>
						<Icon
							iconName="Cancel"
							onClick={() => {
								setShowPhaseSelectionModal(false);
							}}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowPhaseSelectionModal(false);
								}
							}}
						/>
					</Modal.Header>
					<Modal.Body>
						<div className="singleContributionDiv">
							<div class="commonContContrButton">
								<div>
									<label className="checkBoxContainer filterChkboxContainer">
										<input
											name="contribution"
											type="radio"
											onChange={() => {
												setShowPublishModal(true);
												setShowPhaseSelectionModal(false);
											}}
											checked={false}
											tabIndex="0"
											title={phasePublishTitle}
											role="radio"
										/>
										<span className="checkmark"></span>
										<span className="filterLabel">{phasePublishTitle}</span>
									</label>
								</div>
							</div>
							<div class="commonContContrButton">
								<div role="none">
									<label className="checkBoxContainer filterChkboxContainer">
										<input
											name="contribution"
											type="radio"
											onChange={() => {
												setShowParentPhasePublishModal(true);
												setShowPhaseSelectionModal(false);
											}}
											checked={false}
											tabIndex="0"
											title={parentPhasePublishTitle}
											role="radio"
										/>
										<span className="checkmark"></span>
										<span className="filterLabel">
											{parentPhasePublishTitle}
										</span>
									</label>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			) : (
				<></>
			)}
		</>
	);
};

export default PhaseSelectionModal;
