export const titleValidator = new RegExp('^[a-zA-Z0-9s /&_-]+$');

export const hashtagValidator = new RegExp('^[a-zA-Z0-9s#]+$');

export const activityTitleValidator = new RegExp(
 '^[a-zA-Z0-9s \\[\\](){};:_-]+$'
);

export const urlValidator = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);

export const getDate = () => {
 const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
 };

 const today = new Date();
 const day = today.getDate();
 const month = today.getMonth();
 const year = today.getFullYear();
 return `${day} ${months[month]},${year}`;
};

export const getDateWithMonth = (date) => {
 const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
 };

 const dateParam = new Date(date);
 const day = dateParam.getDate();
 const month = dateParam.getMonth();
 const year = dateParam.getFullYear();
 return `${day} ${months[month]},${year[2] + year[3]}`;
};

export const handleTernaryCondition = (isValid, firstValue, secondValue) => {
 return isValid ? firstValue : secondValue;
};

export const time = [
 { id: 1, value: '12 : 00 AM', format: '00:00' },
 { id: 2, value: '01 : 00 AM', format: '01:00' },
 { id: 3, value: '02 : 00 AM', format: '02:00' },
 { id: 4, value: '03 : 00 AM', format: '03:00' },
 { id: 5, value: '04 : 00 AM', format: '04:00' },
 { id: 6, value: '05 : 00 AM', format: '05:00' },
 { id: 7, value: '06 : 00 AM', format: '06:00' },
 { id: 8, value: '07 : 00 AM', format: '07:00' },
 { id: 9, value: '08 : 00 AM', format: '08:00' },
 { id: 10, value: '09 : 00 AM', format: '09:00' },
 { id: 11, value: '10 : 00 AM', format: '10:00' },
 { id: 12, value: '11 : 00 AM', format: '11:00' },
 { id: 13, value: '12 : 00 PM', format: '12:00' },
 { id: 14, value: '01 : 00 PM', format: '13:00' },
 { id: 15, value: '02 : 00 PM', format: '14:00' },
 { id: 16, value: '03 : 00 PM', format: '15:00' },
 { id: 17, value: '04 : 00 PM', format: '16:00' },
 { id: 18, value: '05 : 00 PM', format: '17:00' },
 { id: 19, value: '06 : 00 PM', format: '18:00' },
 { id: 20, value: '07 : 00 PM', format: '19:00' },
 { id: 21, value: '08 : 00 PM', format: '20:00' },
 { id: 22, value: '09 : 00 PM', format: '21:00' },
 { id: 23, value: '10 : 00 PM', format: '22:00' },
 { id: 24, value: '11 : 00 PM', format: '23:00' },
];
