import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	updatedValue,
	setUpdatedValue,
	label,
	hidePhaseDropDown = '',
}) => {
	const [visible, setVisible] = useState(false);
	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (dropdown && !dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = updatedValue.filter(({ id }) => id === item.id);
		if (filteredData.length > 0) {
			const updatedData = updatedValue.filter(({ id }) => id !== item.id);
			setUpdatedValue(updatedData);
		} else {
			setUpdatedValue([...updatedValue, item]);
		}
	};

	const findSelectedTools = (checkId) => {
		const filterId = updatedValue.filter(({ id }) => id === checkId);
		if (filterId.length > 0) {
			return true;
		}
		return false;
	};

	return (
		<div title={enableDropdown ? className : ''} id={className}>
			<div
				className={`dropDowncontainer ${hidePhaseDropDown} ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					onFocus={() => setVisible(true)}
					placeholder={
						updatedValue.length > 0
							? `${updatedValue.length} selected`
							: `Type something to search or select from list`
					}
					role="listbox"
					aria-roledescription="dropdown"
					aria-expanded={visible ? true : false}
					aria-label={label}
				/>
				<Icon
					iconName="ChevronDown"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					role={'button'}
					aria-label={label}
					aria-expanded={visible ? true : false}
				/>
				<div>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}

								return (
									<li title={item.name} key={item.id}>
										<label className="NotificationChkboxContainer">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedTools(item.id)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
