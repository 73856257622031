import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from 'react-router';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { Icon } from '@fluentui/react';
import { Link } from 'react-router-dom';

const ManagePhases = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { consultingSolutionMethods, supportSolutionMethods } =
		useLeftMenuCollapse();
	const projectTypes = [
		{
			heading: 'Consulting',
			solutionMethods: consultingSolutionMethods,
			projectTypeId: 1,
		},
		{
			heading: 'Support',
			solutionMethods: supportSolutionMethods,
			projectTypeId: 2,
		},
	];
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Phases
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Select Solution Method</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div className="addRolesListContainer addDeliveryPlaybookContainer AddEditManageSolMethod">
											{projectTypes.map(
												(projectType) =>
													projectType.solutionMethods.length > 0 && (
														<div
															className="adminMenuTileConatiner"
															key={projectType.heading}
														>
															<h6>{projectType.heading}</h6>
															<div className="adminMenuTiles">
																{projectType.solutionMethods.map(
																	(solutionMethod) => (
																		<Link
																			className="menuTilePart"
																			to={`/project/${projectType.projectTypeId}/method/${solutionMethod.solutionMethodId}`}
																			key={solutionMethod.solutionMethodName}
																			role="button"
																		>
																			<span className="adminMenuIcon"></span>
																			<h6>
																				{solutionMethod.solutionMethodName}
																			</h6>
																			<Icon iconName="ChevronRight" />
																		</Link>
																	)
																)}
															</div>
														</div>
													)
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManagePhases;
