import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@fluentui/react/lib/Icon";
import { Button } from "react-bootstrap";
import { useConfig } from "../context/ConfigContext";

const SupportConfirmationModal = ({
  showRatingConfirmationPopUp,
  setShowRatingConfirmationPopUp,
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { sdm2URL } = useConfig();
  return (
    <Modal
      className="templateRejectModalPopUp"
      show={showRatingConfirmationPopUp}
      onHide={() => {
        setShowRatingConfirmationPopUp(false);
      }}
      size="md"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div>
          <Icon iconName="error"></Icon>
        </div>
        <p>
          Support Delivery related artefacts have been revised and
          rehomed to the CSAM Wiki (site for CSAMs by CSAM/HQ collaboration): <a target="_blank" href="https://aka.ms/CSAM_WIKI">
            <i>https://aka.ms/CSAM_WIKI</i>
          </a>
        </p>
        <p>
          Please reach out to the {" "} <a href="mailto:CsRdDesign@service.microsoft.com?subject=SDMPlus Support Content Retirement">
            <i> CSAM Strategy-Design </i>
          </a> to raise any questions about the legacy SDMPlus Support methods and content
              retirement
            
        </p>
        <Button
          className=""
          onClick={() => {
            setShowRatingConfirmationPopUp(false);
            if (url.indexOf("topics/support") >= 1) {
              window.location.assign(sdm2URL);
            } else {
              history.push(url);
            }
          }}
          type="button"
          data-dismiss="modal"
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SupportConfirmationModal;
