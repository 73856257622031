import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const MethodDeleteModal = ({
	setShowCategoryDeleteModal,
	setSelectedCategoryInfo,
	selectedCategoryInfo,
	getConsultingOrSupportTopics,
	projectTypeId,
}) => {
	const { track } = useAppInsights();
	const { axiosDeleteService } = useAxios();
	const [apiCalled, setApiCalled] = useState(false);

	const deleteTopicCategory = async () => {
		setApiCalled(true);
		await axiosDeleteService(
			`api/sdmtopiccategories/deletetopiccategory?topicCategoryId=${selectedCategoryInfo.categoryId}`
		).then((response) => {
			if (response.data.status) {
				toast.info(
					`You have successfully deleted ${selectedCategoryInfo.categoryName}`,
					{
						position: 'top-right',
					}
				);
				getConsultingOrSupportTopics(projectTypeId);
				setApiCalled(false);
				setShowCategoryDeleteModal(false);
			} else {
				toast.error(response.data.errorMessage, {
					position: 'top-right',
				});
				setApiCalled(false);
			}
		});
	};

	useEffect(() => {
		return () => {
			setSelectedCategoryInfo({ categoryId: 0 });
		};
	}, []);

	return (
		<>
			{apiCalled ? (
				<Spinner />
			) : (
				<Modal
					className="activityDiscardPopUp"
					show={true}
					onHide={() => {
						setShowCategoryDeleteModal(false);
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						{selectedCategoryInfo.sdmTopics &&
						selectedCategoryInfo.sdmTopics.length > 0 ? (
							<>
								<p>
									Please delete all the topics before deleting{' '}
									{selectedCategoryInfo.categoryName}
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="Cancel"
										className="activityDiscardPopUpCancel"
										variant="primary"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowCategoryDeleteModal(false);
												setSelectedCategoryInfo({ categoryId: 0 });
											}
										}}
										onClick={() => {
											setShowCategoryDeleteModal(false);
											setSelectedCategoryInfo({ categoryId: 0 });
										}}
										aria-label="Okay"
									>
										Ok
									</Button>
								</div>
							</>
						) : (
							<>
								<p>
									Are you sure that you want to delete{' '}
									{selectedCategoryInfo.categoryName}
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="Cancel"
										className="activityDiscardPopUpCancel"
										variant="primary"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setShowCategoryDeleteModal(false);
												setSelectedCategoryInfo({ categoryId: 0 });
											}
										}}
										onClick={() => {
											setShowCategoryDeleteModal(false);
											setSelectedCategoryInfo({ categoryId: 0 });
										}}
										aria-label="cancel"
									>
										Cancel
									</Button>

									<Button
										title="Delete"
										tabIndex={-1}
										onClick={() => {
											deleteTopicCategory();
										}}
										disabled={apiCalled}
										className="activityDiscardPopUpAccept"
										variant="info"
										aria-label="delete"
									>
										Delete
									</Button>
								</div>
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default MethodDeleteModal;
