import React from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function ResponseModal({ showResponseModal, setShowResponseModal, message, url }) {
	const closeModal = () => {
		setShowResponseModal(false);
	};

	return (
		<Modal
			className="activityDiscardPopUp"
			show={true}
			onHide={() => {
				closeModal();
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{message}</p>
				<div className="discardPopUpActivityButtons">
					<Link aria-label="Discard PopUp Yes" to={url}>
						<Button title="Yes" tabIndex={-1} className="activityDiscardPopUpAccept" variant="info">
							OK
						</Button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default ResponseModal;
