import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import ActivityMyReviews from './Activity';
import TopicsMyReviews from './Topics';
import TemplatePreview from '../TemplateMyContributionPreview';

import { useLeftMenuCollapse } from '../../../../context/LeftMenuCollapseContext';

const ArtifactType = ({ enableGamification }) => {
	const { artifactType } = useParams();
	const { setSkipNavigationId } = useLeftMenuCollapse();
	useEffect(() => {
		setSkipNavigationId('myContributionsContentMenuTabsId');
	}, []);
	return (
		<div className="historicalPreviewContainer">
			<div className="fullWidthBreadcrumb">
				<Breadcrumbs />
			</div>
			{artifactType === 'topics' ? (
				<TopicsMyReviews />
			) : artifactType === 'activity' ? (
				<ActivityMyReviews />
			) : (
				<TemplatePreview enableGamification={enableGamification} />
			)}
		</div>
	);
};

export default ArtifactType;
