import React, {
	useEffect,
	lazy,
	Suspense,
	createElement,
	useMemo,
} from 'react';
import { useLocation } from 'react-router';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import ManageRoles from './ManageRoles';
import RaciGridModal from './RaciGrid';
import ActivityGridModal from './ActivityGrid';
import ManageAnnouncements from './ManageAnnouncements';
import ManagePhaseSwimlanes from './ManageSwimlane/ManageSwimlane';
import ManageToggleFilters from './ManageToggleFilters';
import ManageFeatures from '../AdminManageFeature/ManageFeatures';
import AddDeliveryPlayBook from '../MultiLevelDropdown/AddDeliveryPlaybook';
import Tutorials from '../ManageTutorials';
import AdminManageToolsModal from './MangeTools';
import AdminManageProcessGroupModal from './ManageProcessGroup';
import ManageSolutionMethods from './ManageSolutionMethods';
import ManagePhases from './ManagePhases';
import ManageActivities from './ManageActivites';
import ManageProceduresArtifacts from './ManageProceduresArtifacts';
import ManageVideoTutorialsCategory from './ManageVideoTutorialsCategory';
import ManageLeftPanel from './ManageLeftPanel';
import ManageQuickLinks from './ManageQuickLinks';

function AdminModals() {
	const { pathname, search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);
	const { isAdmin } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	useEffect(() => {
		if (
			isAdmin &&
			Object.keys(modals).some((modalKey) => params.get(modalKey))
		) {
			const currentModal = Object.keys(modals).find((modalKey) =>
				params.get(modalKey)
			);
			track(2, 2107, currentModal, currentModal);
		}
	}, [search]);

	const modals = {
		manageRoles: ManageRoles,
		manageTools: AdminManageToolsModal,
		manageFeatures: ManageFeatures,
		manageDeliveryPlayBook: AddDeliveryPlayBook,
		manageProcessGroups: AdminManageProcessGroupModal,
		manageTutorials: Tutorials,
		manageRaciGrid: RaciGridModal,
		manageActivityGrid: ActivityGridModal,
		manageAnnouncements: ManageAnnouncements,
		managePhaseSwimlanes: ManagePhaseSwimlanes,
		manageDynamicFilters: ManageToggleFilters,
		manageSolutionMethods: ManageSolutionMethods,
		managePhases: ManagePhases,
		manageActivities: ManageActivities,
		manageJobAidesArtifacts: ManageProceduresArtifacts,

		manageVideoTutorialsCategory: ManageVideoTutorialsCategory,
		manageLeftPanel: ManageLeftPanel,
		manageQuickLinks:ManageQuickLinks
	};
	return (
		isAdmin && (
			<>
				{Object.keys(modals).map(
					(modalKey) =>
						params.get(modalKey) && (
							<Suspense fallback={<></>}>
								{createElement(modals[modalKey])}
							</Suspense>
						)
				)}
			</>
		)
	);
}

export default AdminModals;
