import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import { artifactMapData } from '../../static/ArtifactMapData';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';

const DeleteActivity = ({
	showActivityDeleteModal,
	setShowActivityDeleteModal,
	selectedActivity,
	complexities,
	activityGroups,
	setActivityGroups,
	setIsActivityCountZero,
	setPhaseCreated,
	phaseCreated,
	phaseId,
	solutionMethodId,
	type,
}) => {
	const { track } = useAppInsights();
	const { parentPhaseId } = useParams();
	const { axiosGetService, axiosDeleteService } = useAxios();
	const [selectedComplexity, setSelectedComplexity] = useState(
		selectedActivity.complexityList
	);
	const [topics, setTopics] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(false);
	const [showSpinner, setShowSpinner] = useState(true);
	const [isActivityLock, setIsActivityLock] = useState(false);

	const findComplexityName = (id) => {
		for (const complexity of complexities) {
			if (complexity.id === id) {
				return complexity.name;
			}
		}
	};

	const handleInputChange = (id) => {
		const filterIds = selectedComplexity.filter((item) => item === id);
		if (filterIds.length > 0) {
			const filterValue = selectedComplexity.filter((item) => item !== id);
			setSelectedComplexity(filterValue);
		} else {
			setSelectedComplexity([...selectedComplexity, id]);
		}
	};
	const getActivity = (activities) => {
		const filterActivities = activities.filter(
			({ dragNDropId }) => dragNDropId !== selectedActivity.dragNDropId
		);
		return filterActivities;
	};

	const updateActivityGroup = () => {
		let activityCount = 0;
		const updatedActivityGroup = [];
		for (const group of activityGroups) {
			const updatedGroup = {
				dragNDropId: group.dragNDropId,
				groupId: group.groupId,
				groupName: group.groupName,
				isParentActivity: group.isParentActivity,
				activities: getActivity(group.activities),
			};
			activityCount += updatedGroup.activities.length;
			updatedActivityGroup.push(updatedGroup);
		}
		if (activityCount === 0) {
			setIsActivityCountZero(true);
		} else {
			setIsActivityCountZero(false);
		}
		setActivityGroups(updatedActivityGroup);
		setSuccessMessage(true);
	};

	const getTopics = async () => {
		await axiosGetService(
			`api/sdmtopics/getbyactivity?activityDraftId=${
				selectedActivity.id ? selectedActivity.id : 0
			}&activityId=${
				selectedActivity.activityId ? selectedActivity.activityId : 0
			}`
		).then((response) => {
			setTopics(response.data);
		});
	};

	const getTemplates = async () => {
		await axiosGetService(
			`api/sdmtemplate/getbyactivity?activityDraftId=${
				selectedActivity.id ? selectedActivity.id : 0
			}&activityId=${
				selectedActivity.activityId ? selectedActivity.activityId : 0
			}&complexityIds=${selectedComplexity.toString()}`
		).then((response) => {
			if (response.data) {
				setTemplates(response.data);
			}
			setShowSpinner(false);
		});
	};

	const deleteActivity = async () => {
		if (selectedActivity.isRefered) {
			await axiosDeleteService(
				`api/sdmactivity/unreferactivity?solutionMethodId=${solutionMethodId}&phaseId=${
					type === 'parentPhase' ? parentPhaseId : phaseId
				}&activityId=${selectedActivity.activityId}&groupId=${
					selectedActivity.groupId
				}`
			).then((response) => {
				if (response.data.status) {
					setSuccessMessage(true);
					track(
						1,
						1704,
						selectedActivity.activityId,
						selectedActivity.activityTitle
					);
				} else {
					setErrorMessage(response.data.errorMessage);
					setTimeout(() => {
						setErrorMessage(null);
					}, 5000);
				}
			});
		} else {
			await axiosDeleteService(
				`api/sdmactivity/deleteactivity?activityDraftId=${
					selectedActivity.id ? selectedActivity.id : 0
				}&activityId=${
					selectedActivity.activityId ? selectedActivity.activityId : 0
				}&complexityIds=${
					selectedActivity.complexityList.length === selectedComplexity.length
						? 0
						: selectedComplexity.toString()
				}`
			).then((response) => {
				if (response.data.status) {
					setSuccessMessage(true);
					track(
						1,
						1705,
						selectedActivity.activityId,
						selectedActivity.activityTitle
					);
				} else {
					setErrorMessage(response.data.errorMessage);
					setTimeout(() => {
						setErrorMessage(null);
					}, 5000);
				}
			});
		}
	};

	const getLockStatus = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifacts?artifactId=${
				selectedActivity.activityId ? selectedActivity.activityId : 0
			}&artifactTypeConstId=${artifactMapData['activities']}&artifactDraftId=${
				selectedActivity.id ? selectedActivity.id : 0
			}`
		).then((response) => {
			setIsActivityLock(response.data.status);
		});
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (selectedComplexity.length > 0) {
				getTemplates();
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedComplexity]);

	useEffect(() => {
		getTopics();
		getLockStatus();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="deletePopup"
					show={showActivityDeleteModal}
					onHide={() => {
						setShowActivityDeleteModal(false);
						setPhaseCreated(!phaseCreated);
					}}
					size="md"
					centered
				>
					<Modal.Header>
						{selectedActivity.isRefered ? 'De-link' : 'Delete'}{' '}
						{selectedActivity.activityTitle}
						<Icon
							onClick={() => {
								setShowActivityDeleteModal(false);
								setPhaseCreated(!phaseCreated);
							}}
							iconName="Cancel"
						/>
					</Modal.Header>

					<Modal.Body>
						<div className="deletePopupContainer">
							{successMessage ? (
								<Alert variant="success">
									You have successfully{' '}
									{selectedActivity.isRefered ? 'de-linked' : 'deleted'} the
									activity.
								</Alert>
							) : (
								<>
									{isActivityLock ? (
										<p>
											You can not{' '}
											{selectedActivity.isRefered ? 'de-link' : 'delete'} "
											{selectedActivity.activityTitle}" as admin is updating the
											content
										</p>
									) : (
										<>
											{selectedActivity.isRefered ? (
												<p>Are you sure, you want to de-link this activity</p>
											) : (
												<>
													<div className="deletePopupdelinkContainer">
														{topics.length > 0 || templates.length > 0 ? (
															<>
																<p>
																	This will delink following Topics and
																	Templates.
																</p>
																<ul>
																	{topics.map((item) => (
																		<li key={item.id}>
																			<a href={item.url} target="_blank">
																				{item.name}
																			</a>
																		</li>
																	))}
																	{templates.map((item) => (
																		<li key={item.id}>
																			<a
																				href={item.fileLocation}
																				target="_blank"
																			>
																				{item.title}
																			</a>
																		</li>
																	))}
																</ul>
															</>
														) : (
															<></>
														)}
													</div>
													<p>
														Select the complexities you want to{' '}
														{selectedActivity.isRefered ? 'de-link' : 'delete'}
													</p>
													<div className="deleteComplexity">
														{selectedActivity.complexityList.map((id) => (
															<label className="NotificationChkboxContainer">
																<input
																	name={findComplexityName(id)}
																	type="checkbox"
																	checked={selectedComplexity.includes(id)}
																	onChange={() => handleInputChange(id)}
																/>
																<span className="CustomCheckmark"></span>
																<span className="complexityLabel">
																	{findComplexityName(id)}
																</span>
															</label>
														))}
													</div>
												</>
											)}
											<div className="deletePopupButton">
												<Button
													className="templateRejectButton"
													onClick={() => {
														setShowActivityDeleteModal(false);
													}}
												>
													Cancel
												</Button>

												<Button
													onClick={() => {
														deleteActivity();
													}}
													disabled={
														selectedComplexity.length > 0 ? false : true
													}
												>
													{selectedActivity.isRefered ? 'De-link' : 'Delete'}
												</Button>
											</div>
											{errorMessage ? (
												<span className="errorMsg">{errorMessage}</span>
											) : (
												<></>
											)}
										</>
									)}
								</>
							)}
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default DeleteActivity;
