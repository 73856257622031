import React, { useState } from 'react';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import Dropdonw from '../../Dropdown';

const OtherDetails = ({
	enableComplexity,
	setEnableComplexity,
	phaseEntryCriteria,
	setPhaseEntryCriteria,
	phaseExitCriteria,
	setPhaseExitCriteria,
}) => {
	const defaultCriteriType = {
		id: 0,
		name: 'Select Text/Numeric',
		type: 'option',
	};
	const defaultCriteriaTypeValue = {
		2: 'Numeric',
		1: 'Text',
	};
	const [selectedCriteria, setSelectedCriteria] = useState(0);
	const [criteriaName, setCriteriaName] = useState('');
	const [criteriaDescription, setCriteriaDescription] = useState('');
	const [criteriaType, setCriteriaType] = useState(defaultCriteriType);
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updatedId, setUpdateId] = useState(0);
	const dropdownValue = [
		{ id: 2, name: 'Text', type: 'option' },
		{ id: 1, name: 'Numeric', type: 'option' },
	];

	const otherDetailsTab = [
		{
			id: 1,
			name: 'Add Entry Criteria',
			type: 'Entry',
			value: phaseEntryCriteria,
			setValue: setPhaseEntryCriteria,
		},
		{
			id: 2,
			name: 'Add Exit Criteria',
			type: 'Exit',
			value: phaseExitCriteria,
			setValue: setPhaseExitCriteria,
		},
	];

	const saveOtherDetails = (value, setValue) => {
		if (criteriaName && criteriaDescription && criteriaType.id !== 0) {
			if (editMode) {
				const updatedDtails = value.map((item) => {
					if (item.id === updatedId) {
						return {
							id: item.id,
							name: criteriaName,
							description: criteriaDescription,
							criteriaType: criteriaType.name,
							valueType: criteriaType.name === 'Text' ? 1 : 2,
							status: item.status,
						};
					} else {
						return item;
					}
				});
				setValue(updatedDtails);
				setError(false);
			} else {
				setValue([
					{
						id: uniqueIdGenerator(),
						name: criteriaName,
						description: criteriaDescription,
						criteriaType: criteriaType.name,
						valueType: criteriaType.name === 'Text' ? 1 : 2,
						status: 'new',
					},
					...value,
				]);
				setCriteriaName('');
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setError(false);
			}
		} else {
			setError(true);
		}
	};

	const deleteOtherDetails = (deleteId, value, setValue) => {
		const filteredValue = value.filter(({ id }) => id !== deleteId);
		setValue(filteredValue);
	};
	return (
		<>
			<div className="otherDetailsContainer">
				<div className="otherDetailsEnableComplexity">
					<label className="NotificationChkboxContainer">
						<input
							name="complexity"
							type="checkbox"
							checked={enableComplexity}
							onChange={() => setEnableComplexity(!enableComplexity)}
						/>
						<span className="CustomCheckmark"></span>
						<span className="complexityLabel">Enable Complexity</span>
					</label>
				</div>
				<div className="entryExitCriteriadiv">
					<h6>Add Criteria</h6>
					{otherDetailsTab.map((item) => (
						<React.Fragment key={item.id}>
							<div className="entryCriteriaDiv">
								<div
									className={
										item.type === 'Exit'
											? 'criteriaButtonAction exitCriteria'
											: 'criteriaButtonAction'
									}
								>
									<div
										className="selectCriteriaButton"
										onClick={() => {
											if (selectedCriteria === item.id) {
												setSelectedCriteria(0);
											} else {
												setSelectedCriteria(item.id);
											}
											setCriteriaType(defaultCriteriType);
											setCriteriaName('');
											setCriteriaDescription('');
											setEditMode(false);
											setUpdateId(0);
											setError(false);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (selectedCriteria === item.id) {
													setSelectedCriteria(0);
												} else {
													setSelectedCriteria(item.id);
												}
												setCriteriaType(defaultCriteriType);
												setCriteriaName('');
												setCriteriaDescription('');
												setEditMode(false);
												setUpdateId(0);
												setError(false);
											}
										}}
										tabIndex={0}
										role="button"
										aria-label={item.name}
										title={item.name}
										aria-expanded={selectedCriteria === item.id ? 'true' : 'false'}
									>
										&#43;&nbsp;{item.name}
									</div>

									{selectedCriteria === item.id && (
										<div className="entryCriteriaFields">
											{error && <span>All fields are required</span>}
											<div className="entryNameAndTypeDiv">
												<div className="entryCriteriaName">
													<span className="CriteriaFieldLabel">
														{item.type} Criteria Name*
													</span>

													<input
														title={`${item.type} Criteria Name`}
														placeholder={`${item.type} Criteria Name`}
														value={criteriaName}
														onChange={(e) => setCriteriaName(e.target.value)}
													/>
												</div>
												<div className="entryCriteriaDdown">
													<span className="CriteriaFieldLabel">
														{item.type} Criteria Type*
													</span>
													<Dropdonw
														className="entryCriteriadropdown"
														data={dropdownValue}
														value={criteriaType}
														setValue={setCriteriaType}
														enableDropdown={true}
													/>
												</div>
											</div>
											<div className="entryCriteriaDescr">
												<span className="CriteriaFieldLabel">
													{item.type} Criteria Description*
												</span>
												<textarea
													title={`${item.type} Criteria Description`}
													placeholder={`${item.type} Criteria Description`}
													value={criteriaDescription}
													onChange={(e) =>
														setCriteriaDescription(e.target.value)
													}
												/>
											</div>

											<div className="criteriaButtons">
												<button
													onClick={() => {
														setSelectedCriteria(0);
														setCriteriaType(defaultCriteriType);
														setCriteriaName('');
														setCriteriaDescription('');
														setEditMode(false);
														setUpdateId(0);
														setError(false);
													}}
												>
													Cancel
												</button>
												<button
													onClick={() =>
														saveOtherDetails(item.value, item.setValue)
													}
												>
													Save
												</button>
											</div>
										</div>
									)}
								</div>
							</div>

							{item.value.length > 0 && (
								<div className="entryCriteriaList">
									<div className="criteriaHeader">
										<div className="criteriaIcon">No</div>
										<div className="criteriaName">
											{item.type} Criteria Name
										</div>
										<div className="criteriaDesc">
											{item.type} Criteria Description
										</div>
										<div className="criteriaType">
											{item.type} Criteria Type
										</div>
										<div className="criteriaEditDeleteIcons"></div>
									</div>
									<ul>
										{item.value.map((value, index) => (
											<li key={value.id}>
												<div className="criteriaTable">
													<div className="criteriaIcon">{index + 1}</div>
													<div className="criteriaName">{value.name}</div>
													<div className="criteriaDesc">
														{value.description}
													</div>
													<div className="criteriaType">
														{value.valueType === 1 ? 'Text' : 'Numeric'}
													</div>
													<div className="criteriaEditDeleteIcons">
														<Icon
															title="Edit"
															iconName="EditSolid12"
															onClick={() => {
																setCriteriaName(value.name);
																setCriteriaDescription(value.description);
																setCriteriaType({
																	id: value.valueType,
																	name: defaultCriteriaTypeValue[
																		value.valueType
																	],
																	type: 'option',
																});
																setSelectedCriteria(item.id);
																setEditMode(true);
																setUpdateId(value.id);
																setError(false);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setCriteriaName(value.name);
																	setCriteriaDescription(value.description);
																	setCriteriaType({
																		id: value.valueType,
																		name: defaultCriteriaTypeValue[
																			value.valueType
																		],
																		type: 'option',
																	});
																	setSelectedCriteria(item.id);
																	setEditMode(true);
																	setUpdateId(value.id);
																	setError(false);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label="Edit Criteria"
														/>
														<Icon
															title="Delete"
															iconName="Cancel"
															onClick={() => {
																deleteOtherDetails(
																	value.id,
																	item.value,
																	item.setValue
																);
																setEditMode(false);
																setCriteriaName('');
																setCriteriaDescription('');
																setCriteriaType(defaultCriteriType);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	deleteOtherDetails(
																		value.id,
																		item.value,
																		item.setValue
																	);
																	setEditMode(false);
																	setCriteriaName('');
																	setCriteriaDescription('');
																	setCriteriaType(defaultCriteriType);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label="Delete Criteria"
														/>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
		</>
	);
};

export default OtherDetails;
