import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import TimezoneSelect from 'react-timezone-select';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import addMonths from 'date-fns/addMonths';
import { useAppInsights } from '../../context/TelemetryContext';
import { Icon } from '@fluentui/react';
import { time as TimeData } from '../../helpers/Validation';

import 'react-datepicker/dist/react-datepicker.css';
import Timepicker from '../Phase/Timepicker';

const TopicPublishModal = ({
	showPublishModal,
	setShowPublishModal,
	title,
	publishTopic,
	schedulePublishTopic,
}) => {
	const currentTime = new Date().getHours();
	const publishType = [
		{
			id: 1,
			name: 'Publish Now',
			type: 'Publish',
		},
		{ id: 2, name: 'Publish Later', type: 'Schedule' },
	];

	const { track } = useAppInsights();
	const [selectedPublishType, setSelectedPublishType] = useState({
		id: 1,
		name: 'Publish Now',
		type: 'Publish',
	});
	const [publishSchedule, setPublishSchedule] = useState({
		time: { value: '', format: '' },
		date: new Date(),
	});
	const [selectedTimezone, setSelectedTimezone] = useState({});

	function convertTime() {
		const selectedDate = publishSchedule.date;
		const date = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate()
		);
		date.setHours(publishSchedule.time.format.split(':')[0], '0', '0', '0');
		const UTCdate = new Date(
			date.getTime() - selectedTimezone.offset * 3600000
		);
		return UTCdate.toLocaleString().split('GMT')[0] + ' GMT';
	}

	const schedulePublish = async () => {
		if (!publishSchedule.time.format || !selectedTimezone.value) {
			toast.error('Please select a date, time and timezone', {
				position: 'top-right',
			});
		} else {
			const publishScheduledDateTime = new Date(convertTime()).toUTCString();
			setShowPublishModal(false);
			schedulePublishTopic(publishScheduledDateTime);
		}
	};

	const getTimeData = () => {
		const selectedDate = publishSchedule.date.toLocaleDateString();
		const currentDate = new Date().toLocaleDateString();
		if (selectedDate === currentDate) {
			return TimeData.filter(
				({ format }) => Number(format.split(':')[0]) > currentTime
			);
		}
		return TimeData;
	};

	useEffect(() => {
		getTimeData();
	}, [publishSchedule]);

	return (
		<Modal
			className="phasePublishModal"
			show={showPublishModal}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				setShowPublishModal(false);
			}}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					Publish {title}
				</Modal.Title>
				<Icon
					onClick={() => {
						setShowPublishModal(false);
					}}
					iconName="ChromeClose"
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowPublishModal(false);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label={`click to cancel the publish ${title}`}
					title="Cancel button"
				></Icon>
			</Modal.Header>
			<Modal.Body>
				<>
					<div className="phasePublishModalDiv"></div>
					<div className="laterPublishingActivitiesSection">
						<h6>When would you like to publish?</h6>
						<div className="publishNowOrLaterDiv"
							role="radiogroup"
							aria-labelledby="publish">
							<ul>
								{publishType.map((item) => (
									<li
										key={item.name}
										onClick={() => {
											setSelectedPublishType(item);
										}}
									>
										<label class="checkBoxContainer">
											<span class="settingsLabel">{item.name}</span>
											<input
												name="methodSelection"
												type="radio"
												checked={selectedPublishType.id === item.id}
												tabIndex="0"
												title={item.name}
												role="radio"
												aria-label={`select the ${item.name}`}
												aria-checked={
													selectedPublishType.id === item.id ? true : false
												}
											/>
											<span class="checkmark"></span>
										</label>
									</li>
								))}
							</ul>
							<div>
								<div className="phasePublishButtons">
									<button
										disabled={selectedPublishType.type !== 'Publish'}
										onClick={() => {
											setShowPublishModal(false);
											publishTopic();
										}}
									>
										Publish
									</button>
								</div>
								<div className="dateAndTimeJoneDiv">
									<span>Date</span>
									<DatePicker
										selected={publishSchedule.date}
										onChange={(date) => {
											setPublishSchedule({
												...publishSchedule,
												date: date,
											});
										}}
										minDate={new Date()}
										placeholderText="Select a date "
										maxDate={addMonths(new Date(), 1)}
									/>
								</div>
								<div className="dateAndTimeJoneDiv">
									<span>Time</span>
									<Timepicker
										data={getTimeData()}
										className={'phasePublishTimePicker'}
										setValue={(time) => {
											setPublishSchedule({
												...publishSchedule,
												time: time,
											});
										}}
										value={publishSchedule.time.value}
									/>
								</div>
								<div className="dateAndTimeJoneDiv timeZoneDiv">
									<span>Time Zone</span>
									<TimezoneSelect
										className="timeJoneDivDropdown"
										value={selectedTimezone}
										onChange={setSelectedTimezone}
										aria-label="Time zone"
									/>
								</div>
								<div className="phasePublishButtons schedule">
									<button
										disabled={selectedPublishType.type !== 'Schedule'}
										onClick={() => {
											schedulePublish();
										}}
									>
										Schedule
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="phasePublishButton">
						<button onClick={() => setShowPublishModal(false)}>Cancel</button>
					</div>
				</>
			</Modal.Body>
		</Modal>
	);
};

export default TopicPublishModal;
