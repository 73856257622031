import React from 'react';
import Iframe from 'react-iframe';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';

function PreviewModal({ enableTemplatePreview, closeModal, data }) {
	return (
		<Modal
			show={enableTemplatePreview}
			onHide={closeModal}
			centered
			dialogClassName="templateModal"
		>
			{enableTemplatePreview ? (
				<>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">{`Preview - ${data.name}`}</Modal.Title>
						<Icon
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							aria-label={`Click to close ${data.name} Preview modal`}
							role="button"
						/>
					</Modal.Header>
					<Modal.Body>
						{data.previewUrl ? (
							<Iframe
								name="preview"
								url={data.previewUrl}
								id="templatePreviewIframe"
								role="presentation"
								aria-label={`${data.name} template preview`}
								title={`${data.name} template preview`}
							/>
						) : (
							<p>This template does not have preview</p>
						)}
					</Modal.Body>
				</>
			) : (
				<></>
			)}
		</Modal>
	);
}

export default PreviewModal;
