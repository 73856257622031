import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { IconButton } from '@fluentui/react';
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import ComplexitySelectionDropdown from './ComplexitySelectionDropdown'

import CloneModal from './CloneModal';
const ComplexitySelection = ({
	publishComplexityList,
	setPublishComplexityList,
	defaultComplexityId,
	complexities,
	activityName,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	setComplexityLevelUpdatedDetails,
	isApiCalled,
	setIsApiCalled,
	availableComplexity,
	setAvailableComplexity,
	setSelectedComplexity
}) => {
	console.log('complexities', complexities, 'publishComplexityList', publishComplexityList, 'isApiCalled', isApiCalled, 'availableComplexity', availableComplexity, 'complexityLevelDetails', complexityLevelDetails, 'complexityLevelUpdatedDetails', complexityLevelUpdatedDetails)
	const [showCloneModal, setShowCloneModal] = useState(false);
	const [cloneErrorMessage, setCloneErrorMessage] = useState(false);
	const [prePublishComplexity, setPrePublishComplexity] = useState([])
	// const removeComplexity = (selectedId) => {
	// 	if (publishComplexityList.includes(selectedId)) {
	// 		const filteredList = publishComplexityList
	// 			.filter((item) => item !== selectedId)
	// 			.sort(function (id1, id2) {
	// 				return id1 - id2;
	// 			});
	// 		setPublishComplexityList([...filteredList]);
	// 	} else {
	// 		const filteredList = publishComplexityList.map((item) => item);
	// 		filteredList.push(selectedId);
	// 		setPublishComplexityList(
	// 			filteredList.sort(function (id1, id2) {
	// 				return id1 - id2;
	// 			})
	// 		);
	// 	}
	// };
	useEffect(() => {
		if (availableComplexity?.length > 1) {
			const complexityIds = complexities?.map(complexity => complexity.id);
			setPublishComplexityList(complexityIds);
		}
	}, [ availableComplexity]);
	const prePublishComplexityfunction = (data) => {
		const filterValue = data.map((item) => item.id)
		setPublishComplexityList(filterValue)
	}
	const filterSourceComplexity = () => {
		const filterValue = complexities.filter(({ id }) =>
			publishComplexityList.includes(id)
		);
		return filterValue;
	};

	return (
		<div className="NewActivitycomplexityWRapper">
			{/* <div aria-label='Select the complexities for which you want to publish this activity' tabindex="0" style={{ width: '230px' }}>
				Select the complexities for which you want to publish this activity
			</div> */}
			{/* <div style={{marginTop:'10px'}}>
			<TooltipHost 
				content={
					<div style={{fontWeight:'400px',color:'white',margin:'0',padding:'0'}}>
					  You can create copies of this activity for different <br/>complexities.<br />
					  <hr style={{borderTop: '1px solid white'}}/>
					  <strong>Step 1:</strong> Select the Complexities from the Dropdown<br />
					  <strong>Step 2:</strong> Click on Clone Complexity &amp; follow the steps.
					</div>
				  }
				  id="infoTooltip"
				  calloutProps={{ 
					gapSpace: 0,
					beakWidth: 20, 
					styles: {
					  // Styles for the callout (tooltip container)
					  calloutMain: {
						backgroundColor: 'rgba(0,0,0,0.9) !important',
						padding: '8px' // Dark background with !important
					  },
					  // Styles for the tooltip content
					  content: {
						color: 'white' // Text color
					  }
					}
				  }}
				  directionalHint={DirectionalHint.bottomCenter}
			>
				 <div style={{ backgroundColor: 'cornflowerblue', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				 <Icon iconName="Info" aria-labelledby="infoTooltip" styles={{
					root: {
						cursor: 'pointer',
						color: 'white',  // Icon content color set to white
						fontSize: '24px',
						
					}
				}} />
				 </div>
				
			</TooltipHost>
			</div> */}
			<div>

				{/* {complexities.map((item) => (
						<li key={item.id}>
							<label className="NotificationChkboxContainer">
								<input
									name="complexitySelection"
									type="checkbox"
									checked={publishComplexityList.includes(item.id)}
									onChange={() => {
										if (item.id !== defaultComplexityId) {
											removeComplexity(item.id);
										}
									}}
									aria-label={item.name}
									title={item.name}
								/>
								<span className="CustomCheckmark"></span>
								<span className="filterLabel">{item.name}</span>
							</label>
						</li>
					))} */}
				{/* <ul>
					<ComplexitySelectionDropdown
						className='complexitySelectionDropdown'
						enableDropdown={false}
						data={complexities}
						label='Publish complexities'
						value={filterSourceComplexity()}
						setValue={prePublishComplexityfunction}
					/>
				</ul> */}
			</div>
			<div>
				{isApiCalled.length === availableComplexity.length ? (
					<>
						<div className="complexity-button" style={{ color: 'black', fontWeight: '400',borderRadius:'5px' }}
						onKeyPress={(e) => {
							if (e.which === 13) {
								// if (publishComplexityList.length > 1) {
									setShowCloneModal(true);
								// } else {
								// 	setCloneErrorMessage(true);
								// 	setTimeout(() => {
								// 		setCloneErrorMessage(false);
								// 	}, 3000);
								// }
							}}}
						 onClick={() => {
							// if (publishComplexityList.length > 1) {
								setShowCloneModal(true);
							// } else {
							// 	setCloneErrorMessage(true);
							// 	setTimeout(() => {
							// 		setCloneErrorMessage(false);
							// 	}, 3000);
							// }
						}}>
							<IconButton 
							className="custom-icon-button"
							iconProps={{ iconName: 'Copy' }} title="Copy" disabled={
								isApiCalled.length !== availableComplexity.length
									? true
									: false
							}
								styles={{
									icon: { color: 'black' },								
									rootHovered: {
										color: 'white',  // Color for text and icon on hover
										backgroundColor: '#666',  // Optional: background color on hover
									},  // Color on hover
									rootPressed: { color: 'black' }   // Color when pressed
								}}
								tabIndex={0}
								onClick={() => {
									// if (publishComplexityList.length > 1) {
										setShowCloneModal(true);
									// } else {
									// 	setCloneErrorMessage(true);
									// 	setTimeout(() => {
									// 		setCloneErrorMessage(false);
									// 	}, 3000);
									// }
								}}
								aria-label="Copy to Complexity"
								onKeyPress={(e) => {
									if (e.which === 13) {
										// if (publishComplexityList.length > 1) {
											setShowCloneModal(true);
										// } else {
										// 	setCloneErrorMessage(true);
										// 	setTimeout(() => {
										// 		setCloneErrorMessage(false);
										// 	}, 3000);
										// }
									}
								}} />
						<span>Clone Complexity</span>
						</div>
						{/* <Icon
							iconName="Copy"
							className="cloneActivityIcon"
							disabled={
								isApiCalled.length !== availableComplexity.length
									? true
									: false
							}
							style={{ fontSize: '20px' }}
							tabIndex={0}
							onClick={() => {
								if (publishComplexityList.length > 1) {
									setShowCloneModal(true);
								} else {
									setCloneErrorMessage(true);
									setTimeout(() => {
										setCloneErrorMessage(false);
									}, 3000);
								}
							}}
							aria-label="Copy to Complexity"
							onKeyPress={(e) => {
								if (e.which === 13) {
									if (publishComplexityList.length > 1) {
										setShowCloneModal(true);
									} else {
										setCloneErrorMessage(true);
										setTimeout(() => {
											setCloneErrorMessage(false);
										}, 3000);
									}
								}
							}}
						/> */}
						{/* <div class="tabsTitleToolTip">
							<span class="tabsToolTipArrow"></span>
							<span>Clone to other complexity</span>
						</div> */}
					</>
				) : (
					<></>
				)}
			</div>
			{/* <div style={{ paddingLeft: '8px' }}>
				{cloneErrorMessage ? (
					<span className="errorMsg">
						You need to select more than one complexity to clone
					</span>
				) : (
					<></>
				)}
			</div> */}




			{showCloneModal ? (
				<CloneModal
					showCloneModal={showCloneModal}
					setShowCloneModal={setShowCloneModal}
					activityName={activityName}
					publishComplexityList={publishComplexityList}
					complexities={complexities}
					complexityLevelDetails={complexityLevelDetails}
					setComplexityLevelDetails={setComplexityLevelDetails}
					complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
					setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
					availableComplexity={availableComplexity}
					setAvailableComplexity={setAvailableComplexity}
					setIsApiCalled={setIsApiCalled}
					isApiCalled={isApiCalled}
					setSelectedComplexity={setSelectedComplexity}
					prePublishComplexityfunction={prePublishComplexityfunction}
				/>
			) : (
				<></>
			)}

		</div>
	);
};

export default ComplexitySelection;
