import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function MultiSelectDropdown({ className, data, setValue, value, enableDropdown }) {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = value.filter(({ id }) => id === item.id);
		if (filteredData.length > 0) {
			const updatedData = value.filter(({ id }) => id !== item.id);
			setValue(updatedData);
		} else {
			setValue([...value, item]);
		}
	};

	const findSelectedTools = (checkId) => {
		const filterId = value.filter(({ id }) => id === checkId);
		if (filterId.length > 0) {
			return true;
		}
		return false;
	};

	return (
		<div
			title={
				enableDropdown
					? ''
					: 'Select a source complexity to enable destination complexity'
			}
			id={className}
		>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
				style={{width:'288px'}}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					role="combobox"
					aria-label={`${value.length} selected`}
					aria-expanded={enableDropdown ? 'true' : 'false'}
					title="Select Complexity Dropdown"
				>
					<span className="wrapHeader">{`${value.length} selected`}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li title={item.name} key={item.id}>
										<label className="NotificationChkboxContainer1">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedTools(item.id)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MultiSelectDropdown;
