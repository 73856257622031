import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function DiscardPopUp({
	showErrorMessageModal,
	setShowErrorMessageModal,
	errorMessage,
	setErrorMessage,
	apiError,
	setApiError,
	projectTypeId,
	solutionMethodId,
	phaseId,
	parentPhaseId,
}) {
	const closeModal = () => {
		setErrorMessage(null);
		setShowErrorMessageModal(false);
	};
	return (
		<Modal
			className="activityDiscardPopUp"
			show={showErrorMessageModal}
			onHide={() => {
				// do nothing
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{errorMessage}</p>
				<div className="discardPopUpActivityButtons">
					{apiError ? (
						<Button
							title="OK"
							className="activityDiscardPopUpCancel"
							variant="primary"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={() => {
								closeModal();
								setApiError(false);
							}}
						>
							OK
						</Button>
					) : (
						<Link
							to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}`}
						>
							<Button
								title="OK"
								className="activityDiscardPopUpCancel"
								variant="primary"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={() => closeModal()}
							>
								OK
							</Button>
						</Link>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
