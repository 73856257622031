import React from 'react';
import { Link } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';

import './HelpAndSupport.css';

function HelpAndSupport() {
	const { track } = useAppInsights();
	return (
		<div className="helpandSupportRightPane">
			<div className="helpContainer">
				<h6>Support</h6>
				<div className="knownIssuesLink">
					<Link to="/known-issue">Known Issues</Link>
				</div>

				<div className="supportImgContainer">
					<span className="supportImg" />
				</div>
				<a
					onClick={() => track(1, 10001, 1, 'Support')}
					href={'https://aka.ms/sdmsupport'}
					target="_blank"
					tabIndex="0"
					role="button"
					className='ineedsupButton'
				>
					I need Support
				</a>
			</div>
			<div className="feedbackContainer">
				<h6>Feedback</h6>
				<div className="feedbackImgContainer">
					<span className="feedbackImg" />
				</div>
				<a
					onClick={() => track(1, 10001, 2, 'Feedback')}
					href={
						'https://aka.ms/sdmfeedback'
					}
					target="_blank"
					tabIndex="-1"
				>
					<button>I have Feedback</button>
				</a>
			</div>
		</div>
	);
}

export default HelpAndSupport;
