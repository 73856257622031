import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import Activities from './Activities';
import Select, { components } from 'react-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { handleTernaryCondition } from '../../../helpers/Validation';
import { Icon } from '@fluentui/react/lib/Icon';
import { NavLink, useRouteMatch } from 'react-router-dom';

const GroupAndSequence = ({
    quickLinkTypeId,
    leftMenuGroupData,
    setLeftMenuGroupData,
    setGroupCreated,
	setGroupUpdated,
    phaseId,
	complexityId,
    setLinkCreated,
	setLinkUpdated,
    setLinkDeleted,
    setDisableBtn
}) => {
    const { pathname } = useLocation();
	const history = useHistory();
    const { axiosGetService, axiosPostService } = useAxios();
    const [showExternalLinkForm, setShowExternalLinkForm] = useState(false);
    const [showGroupForm, setShowGroupForm] = useState(false);
    const [groupName, setGroupName] = useState();
    const [sequenceNumber, setSequenceNumber] = useState(leftMenuGroupData.length + 1);
    const [groupSelectedOptions, setGroupSelectedOptions] = useState();
    const [linkSequenceNumber, setlinkSequenceNumber] =useState();
    const [linkTitle, setLinkTitle] = useState();
    const [linkUrl, setLinkUrl] =useState();
    const [activityIdentifier, setActivityIdentifier] = useState(0);
    const [solutionMethodId, setSolutionMethodId] = useState(0);
    const [isInternalQuickLink, setIsInternalQuickLink] =useState(0);
    const [groupSelectedName, setGroupSelectedName] = useState();

    const {
		phase,
		parentPhase,
		complexity,
		setExpandedActivityGroups,
		expandedActivityGroups,
		complexityValidActivities,
		phaseHashtags,
		role,
		phaseDynamicFilterTag,
		endUserView,
	} = useLeftMenuCollapse();

    
    const handleGroupExpand = (groupId, type) => {
        setExpandedActivityGroups(prevState => {
            const isExpanded = prevState.includes(groupId);
            if (type !== 'filterUpdate' && isExpanded) {
                // Collapse the group
                return prevState.filter(id => id !== groupId);
            } else if (!isExpanded) {
                // Expand the group
                return [...prevState, groupId];
            }
            return prevState;
        });
    };
    
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        if (result.type === 'GROUPS') {
            // Reorder groups
            const newGroups = reorder(
                leftMenuGroupData,
                result.source.index,
                result.destination.index
            );
            newGroups.forEach((item, index) => {
                item.sequenceNumber = index+1;
            })
            setLeftMenuGroupData(newGroups);
            setDisableBtn(false);
        } else {
            const group = leftMenuGroupData.find(group => group.quickLinkGroupId === result.type);
            const newQuickLinks = reorder(
                group.quickLinkActivities,
                result.source.index,
                result.destination.index
            );
            newQuickLinks.forEach((item, index) => {
                item.sequenceNumber = index+1;
            })
            setLeftMenuGroupData(
                leftMenuGroupData.map(g =>
                g.quickLinkGroupId === group.quickLinkGroupId
                    ? { ...g, quickLinkActivities: newQuickLinks }
                    : g
                )
            );
            setDisableBtn(false);
        }
    };
    
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const order = (a, b) => {
		return a.sequence < b.sequence ? -1 : (a.sequence > b.sequence ? 1 : 0);
	}

    const linkOrder = (a, b) => {
		return a.sequenceNumber < b.sequenceNumber ? -1 : (a.sequenceNumber > b.sequenceNumber ? 1 : 0);
	}

    return(
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="activitiesList">
                    <Droppable droppableId="all-groups" type="GROUPS">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {leftMenuGroupData.length !== 0 && leftMenuGroupData.map((item,index) => (
                                    <div
                                        className='leftOrangeDelivery'
                                        key={item.quickLinkGroupId}
                                    >
                                        <Draggable key={String(item.quickLinkGroupName)} draggableId={item.quickLinkGroupName} index={index}>
                                            {(provided) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <div className="leftLinkListMainContainer leftLinkGroupParent">
                                                        <div
                                                            className="linkContainer leftLinkGroup"
                                                            tabIndex={0}
                                                            onKeyPress={(e) => {
                                                                if (e.which === 13) {
                                                                    handleGroupExpand(`${item.quickLinkGroupName}_${item.quickLinkGroupId}`, 'userUpdate');
                                                                }
                                                            }}
                                                            onClick={() => handleGroupExpand(`${item.quickLinkGroupName}_${item.quickLinkGroupId}`, 'userUpdate')}
                                                            aria-expanded={handleTernaryCondition(
                                                                expandedActivityGroups.includes(
                                                                    `${item.quickLinkGroupName}_${item.quickLinkGroupId}`
                                                                )
                                                                    ? 'true'
                                                                    : 'false'
                                                            )}
                                                            role="tree"
                                                        >
                                                            <span className="leftLinkTypeColor" />
                                                            <div className="leftLinkTitle" title={item.quickLinkGroupName}>
                                                                <Icon
                                                                    iconName={handleTernaryCondition(
                                                                        expandedActivityGroups.includes(
                                                                            `${item.quickLinkGroupName}_${item.quickLinkGroupId}`
                                                                        ),
                                                                        'CaretDownSolid8',
                                                                        'CaretRightSolid8'
                                                                    )}
                                                                />
                                                                    <span
                                                                        aria-label={item.quickLinkGroupName}
                                                                        className="titleOfLink"
                                                                        role="tree"
                                                                        title={item.quickLinkGroupName}
                                                                        aria-selected={handleTernaryCondition(
                                                                            expandedActivityGroups.includes(
                                                                                `${item.quickLinkGroupName}_${item.quickLinkGroupId}`
                                                                            ),
                                                                            'true',
                                                                            'false'
                                                                        )}
                                                                    >
                                                                        {item.quickLinkGroupName}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                        <Droppable droppableId={item.quickLinkGroupId} type={item.quickLinkGroupId}>
                                                            {(provided) => (
                                                                <div {...provided.droppableProps} ref={provided.innerRef}>

                                                                    {expandedActivityGroups.includes(
                                                                        `${item.quickLinkGroupName}_${item.quickLinkGroupId}`
                                                                    ) &&
                                                                    (item.quickLinkActivities.length !== 0 && item.quickLinkActivities[0] !== null ? (
                                                                        item.quickLinkActivities.map((data,linkIndex) => (
                                                                            <Draggable key={data.quickLinkActivityId} draggableId={String(data.quickLinkActivityId)} index={linkIndex}>
                                                                                {(provided) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        className="leftPanelQuicklinkIitem"
                                                                                    >
                                                                                        <div key={data.quickLinkActivityId}>
                                                                                            <div
                                                                                                className='leftOrangeDelivery'
                                                                                            >
                                                                                                <div className="leftLinkChildContainer">
                                                                                                    <div>
                                                                                                        <div
                                                                                                            className="leftLinkChildTitle"
                                                                                                            title={data.linkName}
                                                                                                        >
                                                                                                            <span className="titleOfLink">
                                                                                                                {data.linkName}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))
                                                                    ) : (
                                                                        <div className="leftLinkChildContainer">
                                                                            <div className="leftLinkChildTitle noActivities">
                                                                                <span className="titleOfLink">No Activities Found</span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>                  
        </>
    );
}

export default GroupAndSequence;