import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import dompurify from 'dompurify';


const ExitCriteriaHistory = ({
	criteriaData,
	toolsSidebarHeight,
	criteriaSuggestions,
	ckEditorUserMap,
	reviewedCriteriaSuggestions,
	criteria,
	currentTab,
	setCurrentTab,
	reorderedExitReviewedSuggestions,
	reorderedOutputReviewedSuggestions,
	reorderHistory
}) => {
		const htmlSanitizer = dompurify.sanitize;

	const criteriaType = {
		entry: {
			0: 'Entry Criteria',
			1: 'Input',
		},
		exit: {
			0: 'Exit Criteria',
			1: 'Output',
		},
	};
	const getReviewStatus = (criteria, cardclass) => {
		if (reviewedCriteriaSuggestions.length > 0) {
			const status = JSON.parse(
				reviewedCriteriaSuggestions.filter(
					(item) => JSON.parse(item).id === criteria.id
				)[0]
			).state;
			if (cardclass) {
				if (status === 'accepted') {
					return 'Approved';
				} else {
					return 'Rejected';
				}
			} else {
				return status;
			}
		}
		return null;
	};
	$(document).ready(function () {
		
			$('ul.exit-criteria-history-tabs li').click(function () {
				var tab_id = $(this).attr('data-tab');
				setCurrentTab(tab_id);
				$('ul.exit-criteria-history-tabs li').removeClass('currentExitTab');
				$('.exit-criteria-history-tab-content').removeClass('currentExitTab');
				//$('.exit-criteria-history-suggestions-tab-content').addClass('currentExitTab');
				$(this).addClass('currentExitTab');
				$('#' + tab_id).addClass('currentExitTab');
				
			});
	});


	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer no-border">
					<div className="">
						<div class="criteria-history-tab-container">
							<ul class="exit-criteria-history-tabs">
								<li 
									className='tab-link currentExitTab'
									data-tab='tab-exit-hist'
								>
									{criteriaType[criteria][0]}
								</li>
								<li 
									className='tab-link'
									data-tab='tab-output-hist'
								>
									{criteriaType[criteria][1]}
								</li>
							</ul>

							<div
								id='tab-exit-hist'
								className='exit-criteria-history-tab-content currentExitTab'
							>
								<ul>
									{criteriaData.map(
										(item) =>
											//JSON.parse(item).suggestionType === 'none' ? (
											!criteriaSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											item.criteriaTypeName === criteriaType[criteria][0] && (
												<li className={'none'} key={JSON.parse(item.id)}>
													<div
														className="descEn"
														id="criteriaDesc"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(item.name),
														}}
													/>
												</li>
											)
									)}

									{criteriaSuggestions.map(
										(item) =>
											JSON.parse(item).criteriaTypeName ===
												criteriaType[criteria][0] && (
												<li
													className={`${
														JSON.parse(item).suggestionType === 'deletion'
															? 'userDeletedCriteria'
															: 'userAddedCriteria'
													}`}
													key={JSON.parse(item).id}
												>													
													<div
														className="descEn"
														id="entryCriteriaSanitizer"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(item).name),
														}}
													/>
												</li>
											)
									)}
								</ul>
							</div>
							<div
								id='tab-output-hist'
								className='exit-criteria-history-tab-content'
							>
								<ul>
									{criteriaData.map(
										(item) =>											
											!criteriaSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											item.criteriaTypeName === criteriaType[criteria][1] && (
												<li className={'none'} key={item.id}>
													<div
														className="descEn"
														id="criteriaDesc"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(item.name),
														}}
													/>
												</li>
											)
									)}
									{criteriaSuggestions.map(
										(item) =>
											JSON.parse(item).criteriaTypeName ===
												criteriaType[criteria][1] && (
												<li
													className={`${
														JSON.parse(item).suggestionType === 'deletion'
															? 'userDeletedCriteria'
															: 'userAddedCriteria'
													}`}
													key={JSON.parse(item).id}
												>
													<div
														className="descEn"
														id="entryCriteriaSanitizer"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(item).name),
														}}
													/>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="" style={{ height: toolsSidebarHeight }}> */}
					<div
						style={{ height: toolsSidebarHeight }}
						className={`exit-criteria-history-suggestions-tab-content currentExitTab toolsSidebar ${
							currentTab === 'tab-exit-hist' ? '' : 'hiddenExitCriteria'
						} `}
					>   
						{reorderedExitReviewedSuggestions.length !== 0 && (
							<>
							<div
								className={`sideBarCard ${
									JSON.parse(reorderedExitReviewedSuggestions).state === 'rejected'
										? 'userDeletedToolsAndGuidance'
										: 'userAddedItem'
								} ${JSON.parse(reorderedExitReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}`}
							>
								<div className="sideBarCardHeader">
									<img
										className="userImg"
										src={reorderHistory.isExitReorder && JSON.parse(reorderHistory.isExitReorder[0]).authorId[1]}
										alt=""
									/>
									<span>{reorderHistory.isExitReorder && JSON.parse(reorderHistory.isExitReorder[0]).authorId[0]}</span>
									<span className="suggestionStatus myContributionSuggestionStatus">
										{JSON.parse(reorderedExitReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}
									</span>
								</div>
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description:"
									<div
									> Exit Criteria has been reordered </div>
									
								</div>
							</div>
						</>

						)}

					    
						{criteriaSuggestions.map(
							(card) => (
								JSON.parse(card).criteriaTypeName ===
												criteriaType[criteria][0] && (
							<>
								<div
									className={`sideBarCard ${
										JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
									} ${getReviewStatus(JSON.parse(card), false)}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={ckEditorUserMap[JSON.parse(card).authorId][1]}
											alt=""
										/>
										<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
										<span className="suggestionStatus myContributionSuggestionStatus">
											{getReviewStatus(JSON.parse(card), true)}
										</span>
									</div>
									<div className="itemStatus">
										<span>
											{JSON.parse(card).suggestionType === 'deletion'
												? 'Remove'
												: JSON.parse(card).suggestionType === 'insertion'
												? 'Add'
												: 'Edit'}
										</span>
										&nbsp;"Text/Numeric:
										{JSON.parse(card).valueType === 1 ? 'Text' : 'Numeric'}{' '}
										Description:<span
														className="descEn"
														id="criteriaSuggestionCard"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(card).name),
														}}
													/>"
									</div>
								</div>
							</>
						)))}
					</div>
					<div
						
						style={{ height: toolsSidebarHeight }}
						className={`exit-criteria-history-suggestions-tab-content currentExitTab toolsSidebar ${
							currentTab === 'tab-output-hist' ? '' : 'hiddenExitCriteria'
						} `}
					>
						
							{reorderedOutputReviewedSuggestions.length !== 0 && (
								<>
								<div
									className={`sideBarCard ${
										JSON.parse(reorderedOutputReviewedSuggestions).state === 'rejected'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
									} ${JSON.parse(reorderedOutputReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={reorderHistory.isOutputReorder && JSON.parse(reorderHistory.isOutputReorder[0]).authorId[1]}
											alt=""
										/>
										<span>{reorderHistory.isOutputReorder && JSON.parse(reorderHistory.isOutputReorder[0]).authorId[0]}</span>
										<span className="suggestionStatus myContributionSuggestionStatus">
											{JSON.parse(reorderedOutputReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}
										</span>
									</div>
									<div className="itemStatus">
										<span>
											Reordered
										</span>
										&nbsp;"Description:"
										<div
										> Output has been reordered </div>
										
									</div>
								</div>
							</>
	
							)}
						
						{criteriaSuggestions.map(
							(card) => (
								JSON.parse(card).criteriaTypeName ===
												criteriaType[criteria][1] && (
							<>
								<div
									className={`sideBarCard ${
										JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
									} ${getReviewStatus(JSON.parse(card), false)}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={ckEditorUserMap[JSON.parse(card).authorId][1]}
											alt=""
										/>
										<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
										<span className="suggestionStatus myContributionSuggestionStatus">
											{getReviewStatus(JSON.parse(card), true)}
										</span>
									</div>
									<div className="itemStatus">
										<span>
											{JSON.parse(card).suggestionType === 'deletion'
												? 'Remove'
												: JSON.parse(card).suggestionType === 'insertion'
												? 'Add'
												: 'Edit'}
										</span>
										&nbsp;"Text/Numeric:
										{JSON.parse(card).valueType === 1 ? 'Text' : 'Numeric'}{' '}
										Description:<span
														className="descEn"
														id="criteriaSuggestionCard"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(card).name),
														}}
													/>"
									</div>
								</div>
							</>
						)))}
					</div>
				{/* </div> */}
			</div>
		</>
	);
};

export default ExitCriteriaHistory;
