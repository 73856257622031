export const artifactMapData = {
 tools: 73,
 'process Groups': 75,
 roles: 16,
 templates: 1,
 activities: 2,
 topics: 10,
 phases: 25,
 'solution Methods': 15,
 'knowledge Area': 77,
 'job Aides': 82,
 tutorials: 81,
 sampleTemplates: 45,
 localisedTemplates: 44,
 readiness: 84,
};
