import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useAppInsights } from '../../../context/TelemetryContext';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import PhaseDetails from './PhaseDetails';
import Collaborators from './Collaborators';
import PhaseSequence from './PhaseSequence';
import AddVisio from './AddSwimlane';
import OtherDetails from './OtherDetails';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { Icon } from '@fluentui/react';
import { HtmlSanitizer } from '../../../helpers/HtmlSanitizer';

import './CreatePhase.css';

const CreatePhase = ({
	projectTypeId,
	solutionMethodId,
	showPhaseCreationModal,
	setShowPhaseCreationModal,
	setPhaseCreated,
	type,
	parentId,
	getParentSolutionMethodData,
	methodOwners,
	parentPhaseCreated,
	setParentPhaseCreated,
	getSolutionMethodData,
	phaseCreated,
	phaseBuilderType,
}) => {
	const { track } = useAppInsights();
	const { phase, parentPhase } = useLeftMenuCollapse();
	const [swimlanePreviewUrl, setSwimlanePreviewUrl] = useState([]);
	const [swimlaneSharePointUrl, setSwimlaneSharePointUrl] = useState(null);
	const [enableSendForApproval, setEnableSendForApproval] = useState(false);
	const [phaseTitleError, setPhaseTitleError] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [disableSaveAndContinue, setDisableSaveAndContinue] = useState(false);
	const [fileSize, setFileSize] = useState(0);
	const history = useHistory();
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
		accept: 'image/svg+xml',
		onDrop: (acceptedFile) => {
			setSwimlanePreviewUrl(
				acceptedFile.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			setEnableSendForApproval(true);
		},
	});
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const [phaseTitle, setPhaseTitle] = useState('');
	const [phaseDescription, setPhaseDescription] = useState('');
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [methodOwner, setMethodOwner] = useState('');
	const [selectedTab, setSelectedTab] = useState(1);
	const [phases, setPhases] = useState([]);
	const [swimlaneFile, setSwimlaneFile] = useState(null);
	const [enableComplexity, setEnableComplexity] = useState(true);
	const [phaseId, setPhaseId] = useState(0);
	const [uploadSwimlane, setUploadSwimlane] = useState(false);
	const [phaseEntryCriteria, setPhaseEntryCriteria] = useState([]);
	const [phaseExitCriteria, setPhaseExitCriteria] = useState([]);
	const [error, setError] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState('');
	const [existingPhaseTitle, setExistingPhaseTitle] = useState([]);
	const [apiCallNeeded, setApiCallNeeded] = useState(false);

	const phaseTab = [
		{ id: 1, name: 'Phase Details' },
		{ id: 2, name: 'Collaborators' },
		{ id: 3, name: 'Sequence Phase' },
		{ id: 4, name: 'Add Swimlane' },
		{ id: 5, name: 'Other Details' },
	];

	const submitPhaseDetails = async () => {
		setDisableSaveAndContinue(true);
		if (!phaseTitle) {
			const phaseSequenceIds = phases
				.filter(({ id }) => Number(id) != 0)
				.map(({ id }) => id);
			const postData = {
				solutionMethodId: type === 'parentPhase' ? parentId : solutionMethodId,
				phaseSequenceIds: phaseSequenceIds.toString(),
			};
			await axiosPostService(`api/sdmphase/updateordraft`, postData).then(
				(response) => {
					if (response.data.status) {
						setSelectedTab(selectedTab + 1);
						setApiCallNeeded(true);
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
					setDisableSaveAndContinue(false);
				}
			);
		} else {
			const phaseSequenceIds = phases.map(({ id }) => id);
			const entryPhaseCriteria = updateCriteriaDetails(phaseEntryCriteria);
			const exitPhaseCriteria = updateCriteriaDetails(phaseExitCriteria);
			const coOwnersEmails = coOwners.map(({ mail }) => {
				return mail;
			});
			const reviewersEmails = reviewers.map(({ mail }) => {
				return mail;
			});
			const approversEmails = approvers.map(({ mail }) => {
				return mail;
			});
			const imagesUrl = sessionStorage.getItem('phaseUploadedImages');
			const postData = {
				phaseName: HtmlSanitizer(phaseTitle),
				phaseDescription: HtmlSanitizer(phaseDescription),
				enableComplexity: enableComplexity,
				solutionMethodId: type === 'parentPhase' ? parentId : solutionMethodId,
				phaseSequenceIds: phaseSequenceIds.toString(),
				isDrafted: true,
				createdBy: userAlias,
				phaseId: phaseId,
				methodOwner: methodOwner,
				entryPhaseCriteria:
					entryPhaseCriteria.length > 0 ? entryPhaseCriteria : null,
				exitPhaseCriteria:
					exitPhaseCriteria.length > 0 ? exitPhaseCriteria : null,
				modifiedBy: userAlias,
				coOwnerMembersList: coOwnersEmails,
				approverMembersList: approversEmails,
				reviewerMembersList: reviewersEmails,
				isColllaborators: selectedTab === 2 ? true : false,
				imagePaths: imagesUrl ? JSON.parse(imagesUrl) : [],
			};
			await axiosPostService(
				`api/sdmphase/${phaseId === 0 ? 'addordraft' : 'updateordraft'}`,
				postData
			).then((response) => {
				const selectedTabName = phaseTab.filter(({ id }) => id === selectedTab);
				if (response.data.status) {
					track(
						1,
						type === 'parentPhase' ? 802 : 800,
						response.data.phaseId,
						response.data.phaseName,
						{
							SaveAndContinueTabName: selectedTabName[0].name,
						}
					);
					setPhaseId(response.data.phaseId);
					setExistingCoOwners([...coOwners]);
					setExistingReviewers([...reviewers]);
					setExistingApprovers([...approvers]);
					if (selectedTab === 5) {
						if (type === 'parentPhase') {
							getParentSolutionMethodData(parentId, methodOwners);
							setParentPhaseCreated(!parentPhaseCreated);
						} else {
							getSolutionMethodData(methodOwners, 'update');
							setPhaseCreated(!phaseCreated);
						}

						history.push(
							`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
								type !== 'parentPhase' ? phaseId : phase.id
							}/parentPhase/${
								type === 'parentPhase' ? phaseId : parentPhase.id
							}`
						);
						setShowPhaseCreationModal(false);
					} else {
						setSelectedTab(selectedTab + 1);
					}
				} else {
					toast.error(response.data.errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
				setDisableSaveAndContinue(false);
			});
		}
	};

	const leftMenu = {
		1: (
			<PhaseDetails
				phaseTitle={phaseTitle}
				setPhaseTitle={setPhaseTitle}
				phaseDescription={phaseDescription}
				setPhaseDescription={setPhaseDescription}
				existingPhaseTitle={existingPhaseTitle}
				phaseTitleError={phaseTitleError}
				setPhaseTitleError={setPhaseTitleError}
				currentPhaseTitle=""
				phases={phases}
				setPhases={setPhases}
				projectTypeId={projectTypeId}
				solutionMethodId={solutionMethodId}
				type={type}
				parentId={parentId}
				setApiCallNeeded={setApiCallNeeded}
				phaseBuilderType={phaseBuilderType}
			/>
		),
		2: (
			<Collaborators
				coOwners={coOwners}
				setCoOwners={setCoOwners}
				reviewers={reviewers}
				setReviewers={setReviewers}
				approvers={approvers}
				setApprovers={setApprovers}
				showSpinner={showSpinner}
			/>
		),
		3: <PhaseSequence phases={phases} setPhases={setPhases} />,
		4: (
			<AddVisio
				file={swimlaneFile}
				setFile={setSwimlaneFile}
				getRootProps={getRootProps}
				getInputProps={getInputProps}
				open={open}
				acceptedFiles={acceptedFiles}
				isDragActive={isDragActive}
				isDragAccept={isDragAccept}
				isDragReject={isDragReject}
				rejectedFiles={rejectedFiles}
				setUploadSwimlane={setUploadSwimlane}
				sizeError={sizeError}
				setSizeError={setSizeError}
				fileError={fileError}
				setFileError={setFileError}
				error={error}
				setError={setError}
				swimlanePreviewUrl={swimlanePreviewUrl}
				swimlaneSharePointUrl={swimlaneSharePointUrl}
				setSwimlaneSharePointUrl={setSwimlaneSharePointUrl}
				enableSendForApproval={enableSendForApproval}
				setEnableSendForApproval={setEnableSendForApproval}
				phaseId={phaseId}
				downloadUrl={downloadUrl}
				submitPhaseDetails={submitPhaseDetails}
				fileSize={fileSize}
			/>
		),
		5: (
			<OtherDetails
				enableComplexity={enableComplexity}
				setEnableComplexity={setEnableComplexity}
				phaseExitCriteria={phaseExitCriteria}
				setPhaseExitCriteria={setPhaseExitCriteria}
				phaseEntryCriteria={phaseEntryCriteria}
				setPhaseEntryCriteria={setPhaseEntryCriteria}
			/>
		),
	};
	const filterFileType = (uploadedFile) => {
		const fileTypes = ['svg'];
		const selectedFileType = uploadedFile.name
			.split('.')
			.slice(-1)
			.pop()
			.toLowerCase();
		if (fileTypes.includes(selectedFileType)) {
			setSwimlaneFile(uploadedFile);
			setUploadSwimlane(true);
			setFileError(false);
			setError(false);
			setSizeError(false);
		} else {
			setSwimlaneFile(null);
			setFileError(true);
			setError(false);
			setSizeError(false);
		}
	};

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const getUser = async (userEmail, setValue) => {
		setShowSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};
	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	};
	const getPhases = async () => {
		await axiosGetService(
			`api/sdmphase/getbysolutionmethod/${
				type === 'parentPhase' ? parentId : solutionMethodId
			}`
		).then((response) => {
			const data = response.data.map((item) => {
				return {
					id: item.phaseId.toString(),
					name: item.phaseName,
					type: 'old',
				};
			});
			data.push({ id: '0', name: '', type: 'new' });
			setPhases(data);
			const phaseTitleData = response.data.map((item) => {
				return item.phaseName.toLowerCase();
			});
			setExistingPhaseTitle(phaseTitleData);
		});
	};
	const getSecurityGroupIds = async () => {
		await axiosGetService(
			`api/sdmphase/getbyid/${phaseId}/${
				type === 'parentPhase' ? parentId : solutionMethodId
			}`
		).then((response) => {
			getCollaboratorsInfo(
				response.data.coOwnerMembersList,
				setExistingCoOwners
			);
			getCollaboratorsInfo(
				response.data.reviewerMembersList,
				setExistingReviewers
			);
			getCollaboratorsInfo(
				response.data.approverMembersList,
				setExistingApprovers
			);
			setMethodOwner(response.data.methodOwner);
			setDownloadUrl(response.data.svgFileName);
		});
	};

	const updateCriteriaDetails = (value) => {
		const criteriaDetails = value.map((item) => {
			return {
				name: item.name,
				description: item.description,
				criteriaType: item.criteriaType,
				valueType: item.valueType,
			};
		});
		return criteriaDetails;
	};
	

	const checkDisableButtonStatus = () => {
		if (!phaseTitle || phaseTitleError) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		const updatedPhase = phases.map((item) => {
			if (item.id === '0' || item.id === phaseId.toString()) {
				return { id: phaseId.toString(), name: phaseTitle, type: item.type };
			}
			return { id: item.id, name: item.name, type: item.type };
		});
		setPhases(updatedPhase);
	}, [phaseTitle, phaseId]);

	useEffect(() => {
		getPhases();
		getSecurityGroupIds();
	}, []);

	useEffect(() => {
		setCoOwners(existingCoOwners);
	}, [existingCoOwners]);

	useEffect(() => {
		setReviewers(existingReviewers);
	}, [existingReviewers]);

	useEffect(() => {
		setApprovers(existingApprovers);
	}, [existingApprovers]);

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			swimlanePreviewUrl.forEach((previewUrl) =>
				URL.revokeObjectURL(previewUrl.preview)
			);
		},
		[swimlaneFile]
	);

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setError(false);
			setFileError(false);
			setSizeError(true);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length === 0) {
			setSwimlaneFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		return () => {
			sessionStorage.setItem('phaseUploadedImages', JSON.stringify([]));
		};
	}, []);
	useEffect(() => {
		getFileSize();
	}, []);
	const closeModal = () => {
		setTimeout(() => {
			if (phaseId) {
				if (type === 'parentPhase') {
					getParentSolutionMethodData(parentId, methodOwners);
					setParentPhaseCreated(!parentPhaseCreated);
				} else {
					getSolutionMethodData(methodOwners, 'update');
					setPhaseCreated(!phaseCreated);
				}
				history.push(
					`/project/${projectTypeId}/method/${solutionMethodId}/phase/${
						type !== 'parentPhase' ? phaseId : phase.id
					}/parentPhase/${type === 'parentPhase' ? phaseId : parentPhase.id}`
				);
			}
			if (apiCallNeeded) {
				getSolutionMethodData(methodOwners, 'update');
				setPhaseCreated(!phaseCreated);
			}
			setShowPhaseCreationModal(false);
		}, 500);
	};
	return (
		<Modal
			className="phaseCreationRightPopup" id="phaseBuilderModal"
			show={showPhaseCreationModal}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<Modal.Title>Phase Builder </Modal.Title>
				<Icon
					iconName="ChromeClose"
					title="Close"
					onClick={closeModal}
					onKeyPress={(e) => {
						if (e.which === 13) {
							closeModal();
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Close Phase Builder"
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu" role="tablist">
									{phaseTab.map((tab) => (
										<li
											onClick={() => setSelectedTab(tab.id)}
											key={tab.id}
											className={
												selectedTab === tab.id ? 'activePhaseTab' : null
											}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedTab(tab.id);
												}
											}}
											tabIndex={0}
											role="tab"
											aria-label={tab.name}
										>
											<span>{tab.name}</span>
										</li>
									))}
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div className="methodOwnerMiddleContainer">
									{leftMenu[selectedTab]}
								</div>
							</div>
							<div className="saveContinueDetailsContainer">
								<button
									disabled={selectedTab === 1}
									onClick={() => setSelectedTab(selectedTab - 1)}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setSelectedTab(selectedTab - 1);
										}
									}}
									className="backwardButton"
									aria-label="Back"
								>
									<span className="backDetailsButton">
										<Icon iconName="ChromeBack" />
										Back
									</span>
								</button>
								<button
									onClick={() => {
										if (selectedTab === 3 || phaseTitle) {
											submitPhaseDetails();
										}
										if (selectedTab === 5) {
											closeModal();
										}
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											if (selectedTab === 3 || phaseTitle) {
												submitPhaseDetails();
											}
											if (selectedTab === 5) {
												closeModal();
											}
										}
									}}
									disabled={
										checkDisableButtonStatus() || disableSaveAndContinue
									}
									className="forwardButton"
									aria-label="save & continue"
								>
									<span className="saveContinueButton">
										Save & Continue <Icon iconName="ChromeBackMirrored" />
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CreatePhase;
