import React, { useState, useEffect } from 'react';

import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import './Announcements.css';

function Announcements() {
 const { pendingAnnouncements, setPendingAnnouncements } =
  useLeftMenuCollapse();
 const [announcements, setAnnouncements] = useState([]);
 const [features, setFeatures] = useState([]);
 const { axiosGetService, axiosPostService } = useAxios();

 const getAnnouncements = async () => {
  const response = await axiosGetService(`/api/sdmannouncement/getactive`);
  const data = response.data;
  setAnnouncements(data);
 };

 const updateAnnouncements = async () => {
  if (pendingAnnouncements.count > 0) {
   await axiosPostService(
    `api/sdmuser/updateflyout?id=${0}&itemTypeId=
			${0}&itemId=${0}&notificationType=${0}
			`,
    {}
   ).then((response) => {
    if (response.data.status) {
     setPendingAnnouncements({
      data: {
       features: null,
       aonnuncements: null,
       pendingApprovals: {},
       pendingReviews: {},
       approvedActivities: [],
       rejectedActivities: [],
       approvedTopics: [],
       rejectedTopics: [],
       approvedTemplates: [],
       rejectedTemplates: [],
       featuresList: [],
       announcementsList: [],
      },
      count: 0,
     });
    }
   });
  }
 };

 const getFeatures = async () => {
  const response = await axiosGetService(`/api/sdmfeatures/getactive`);
  const data = response.data;
  setFeatures(data);
 };

 useEffect(() => {
  getAnnouncements();
  getFeatures();
  updateAnnouncements();
 }, []);

 return (
  <div className="announceAndFeaturePane">
   <div className="announceMainDiv">
    <h4>Announcements</h4>
    <div className="announcementsEquals">
     {announcements.map((item) => {
      return (
       <div className="announcement" key={item.title}>
        <h5>{item.title}</h5>
        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
       </div>
      );
     })}
    </div>
   </div>
   <div className="featureMainDiv">
    <h4>Upcoming Features</h4>
    <div className="announcementsEquals">
     {features.map((item) => {
      return (
       <div className="featureUpdate" key={item.title}>
        <h5>{item.title}</h5>
        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
       </div>
      );
     })}
    </div>
   </div>
  </div>
 );
}

export default Announcements;
