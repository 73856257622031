import React, { useState, useEffect } from "react";
import $ from "jquery";
import dompurify from 'dompurify';


const TaskMyContributions = ({
  taskData,
  toolsSidebarHeight,
  taskSuggestions,
  ckEditorUserMap,
  reviewedTaskSuggestions,  
}) => {
  const getReviewStatus = (task, cardclass) => {
    if (reviewedTaskSuggestions.length > 0) {
      const status = JSON.parse(
        reviewedTaskSuggestions.filter(
          (item) => JSON.parse(item).id === task.id
        )[0]
      ).state;
      if (cardclass) {
        if (status === "accepted") {
          return "Approved";
        } else {
          return "Rejected";
        }
      } else {
        return status;
      }
    }
    return null;
  };

  const htmlSanitizer = (data) => {
	const config = {
	  ADD_ATTR: [ 'target'],
	  };
	return dompurify.sanitize(data, config);
  };

  function removePTags(html) {
    // Replace all <p> tags with an empty string
    return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }

	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer">
					<div className="">
						<div class="criteria-history-tab-container">
							
							<div>
								<ul>
									{taskData.map(
										(item) =>
											//JSON.parse(item).suggestionType === 'none' ? (
											!taskSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											 (
												<li className={'none'} key={JSON.parse(item.id)}
													dangerouslySetInnerHTML={{
												__html: htmlSanitizer(removePTags(item.name)),
											     }} 
												/>
											)
									)}

									{taskSuggestions.map(
										(item) =>
										(
											
										<li
												className={`${
													JSON.parse(item).suggestionType === 'deletion'
														? 'userDeletedCriteria'
														: 'userAddedCriteria'
												}`}
												key={JSON.parse(item).id}
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(removePTags(JSON.parse(item).name)),
											}} />
											
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="" style={{ height: toolsSidebarHeight }}> */}
					<div
						style={{ height: toolsSidebarHeight }}
						className='toolsSidebar'
					>
						{taskSuggestions.map(
							(card) => (
								 (
							<>
								<div
									className={`sideBarCard ${
										JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
									} ${getReviewStatus(JSON.parse(card), false)}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={ckEditorUserMap[JSON.parse(card).authorId][1]}
											alt=""
										/>
										<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
										<span className="suggestionStatus myContributionSuggestionStatus">
											{getReviewStatus(JSON.parse(card), true)}
										</span>
									</div>
									<div className="itemStatus">
										<span>
											{JSON.parse(card).suggestionType === 'deletion'
												? 'Remove'
												: JSON.parse(card).suggestionType === 'insertion'
												? 'Add'
												: 'Edit'}
										</span>
										&nbsp;"Description:<div  dangerouslySetInnerHTML={{__html: htmlSanitizer(removePTags(JSON.parse(card).name))}}/>
									</div>
								</div>
							</>
						)))}
					</div>
				{/* </div> */}
			</div>
		</>
	);
};

export default TaskMyContributions;
