import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import FavoriteAndFeedback from '../../components/FavoriteAndFeedback';
import AnnouncementFlyOut from '../AnnouncementFlyOut';
import { useAxios } from "../../context/AxiosContext";
import { TabTitle } from "../../utils/GeneralFunctions";
import { MessageBar, MessageBarType } from '@fluentui/react';
import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner.js';
import '../../components/Home/Home.css';
import { useAppInsights } from '../../context/TelemetryContext.js';

const NewHome = ({
	isTopPhaseMenu,
	setIsTopPhaseMenu,
	setSelected,
	selected,
	dynamicPhaseCSS,
	setDynamicPhaseCSS
}) => {
	const { projectTypeId } = useParams();
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const {
		appState,
		setAppState,
		setCollapsedLeftMenu,
		setBreadcrumbs,
		breadcrumbs,
		setSkipNavigationId,
	} = useLeftMenuCollapse();
	const {track } = useAppInsights();
	const { userDefaultProjectTypeId } = appState;
	const [showBanner, setShowBanner] = useState(false);
	const [bannerText, setBannerText] = useState();
	const [mainContentData, setMainContentData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [mcemPhases, setMcemPhases] = useState([]);
	const [deliveryManagementID, setDeliveryManagementID] = useState();

	const getBannerStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=ShowHomePageBanner`).then(
			(response) => {
				if (response?.data[0].value === 'true') {
					setShowBanner(true);
				}
			}
		);
	};
	const getBannerText = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=HomePageBannerText`).then(
			(response) => {
				setBannerText(response?.data[0].value)
			}
		);
	};
	useEffect(() => {
		getBannerText();
		getBannerStatus();
	}, [showBanner]);
	useEffect(() => {
		setBreadcrumbs(breadcrumbs.slice(0, 1));
		const projectId = isNaN(projectTypeId)
			? appState.userDefaultProjectTypeId
			: projectTypeId;
		setAppState({
			...appState,
			projectTypeId: Number(projectId),
		});
	}, [userDefaultProjectTypeId, projectTypeId]);

	TabTitle('Home');

	const getQuickHomeData = async () => {
		await axiosGetService(
			`api/sdmquicklink/getphasequicklinkgroupsandactivities`
		).then(async (response) => {
			setLoading(false);
			setMainContentData(response.data);
		});
	};

	const getNonRealizeMCEMstagesWithRV = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=NonRealizeMCEMstagesWithRV`).then(
			(response) => {
				setMcemPhases(response.data[0].value.split(','));
			}
		);
	};

	const getDeliveryManagementID = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=DeliveryManagementID`).then(
			(response) => {
				setDeliveryManagementID(response.data[0].value);
			}
		);
	};

	useEffect(() => {
		getDeliveryManagementID();
		getNonRealizeMCEMstagesWithRV();
	},[]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getQuickHomeData();
			
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, []);

	const sortData = (data) => {
		// Sorting Groups alphabetically for MCEM dashboard phases 
		// const sortedData = data.sort((a, b) => a.group.localeCompare(b.group))
		const sortedData = data.sort(function(a, b) {
			if (b.group.length == 0) {
			  return -1;
			}
			if (a.group.length == 0) {
			  return 1;
			}
			return a.group.localeCompare(b.group);
		  });
		return sortedData;
	}

	return (
		<>
			{loading ? <CrowdSourcingSpinner /> : <></>}
			{showBanner && bannerText !== '' && bannerText !== null && (
				<MessageBar
					messageBarType={MessageBarType.warning}
					isMultiline={true}
					style={{ color: "red" }}

				>
					{bannerText}
				</MessageBar>
			)}
			<div className="homePageContainer">
				<p class="homePageContainerText">Most Used Quick Links</p>
				<AnnouncementFlyOut />
				<FavoriteAndFeedback />
				<div className="home-flex-container">
					{mcemPhases && mcemPhases?.map((item) => (
						<div class={dynamicPhaseCSS}>
							{parseInt(item) === parseInt(deliveryManagementID) ?
								<HomeRealizeActivities 
									item = {item}
									data = {mainContentData}
									track = {track}
									mcemPhases = {mcemPhases}
									sortData = {sortData}
								/>
							:
							<HomeActivities 
								item = {item}
								data = {mainContentData}
								track = {track}
								sortData = {sortData}
							/>
							}
						</div>
					))}
				</div>
			</div>
		</>
	);
}

const HomeActivities = ({
	item,
	data,
	track,
	sortData
}) => {
	const filteredData = data.filter((value) => value.phaseId === parseInt(item));
	const findGroupName = (group, index) => {
		const occurance = filteredData.findIndex(function(item, i){
			return item.group === group
		});

		if(occurance >= index){
			return(
				<span>{group}</span>
			);
		}else{
			return (<></>);
		}
	}
	return(
		<>
			{sortData(filteredData).map((value,index)=> (
				<div className="groupContentQuick" key={value?.phaseId}>
					{findGroupName(value.group,index)} 
					<div class="LinkContentQuick">
						<a href={value?.activityLink}
							tabIndex={0}
							onClick={() => {
								track(
									1,
									2111,
									value?.activityId,
									value?.activityName
								)
							}}
							aria-label={value?.activityName}
							target='_blank'
							rel="noopener noreferrer">
							{value?.activityName}
						</a>
					</div>
				</div>
			))}
		</>
	);
}

const HomeRealizeActivities = ({
	item,
	data,
	track,
	mcemPhases,
	sortData
}) => {
	const filteredData = data.filter((value) => !mcemPhases.includes(value.phaseId.toString()));
	const findGroupName = (group, index) => {
		const occurance = filteredData.findIndex(function(item, i){
			return item.group === group
		});

		if(occurance >= index){
			return(
				<span>{group}</span>
			);
		}else{
			return (<></>);
		}
	}
	return(
		<>
			{filteredData.map((value, index)=> (
				<div className="groupContentQuick" key={value?.phaseId}>
					{findGroupName(value.group,index)}
					<div class="LinkContentQuick">
						<a href={value?.activityLink}
							tabIndex={0}
							onClick={() => {
								track(
									1,
									2111,
									value?.activityId,
									value?.activityName
								)
							}}
							aria-label={value?.activityName}
							target='_blank'
							rel="noopener noreferrer">
							{value?.activityName}
						</a>
					</div>
				</div>
			))}
		</>
	);
}

export default NewHome;
