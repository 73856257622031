import React, { useState } from 'react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import { activityProperty } from './ActivityProperty';
import { useAxios } from '../../../context/AxiosContext';
import { useAppInsights } from '../../../context/TelemetryContext';

import { Icon } from '@fluentui/react/lib/Icon';

const Clone = ({
	selectedActivity,
	setSelectedActivity,
	activityGroups,
	setActivityGroups,
	setShowCloneActivityForm,
	selectedComplexity,
	setDisableSaveAndContinue,
	solutionMethodId,
	type,
	parentId,
}) => {
	const { track } = useAppInsights();
	const { axiosPostService, userAlias } = useAxios();
	const { phaseId, parentPhaseId } = useParams();
	const [detailSelection, setDetailSelection] = useState(activityProperty);
	const [disableCloning, setDisableCloning] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const handlePropertySelection = (item) => {
		const extractId = detailSelection.map(({ id }) => {
			return id;
		});
		if (item.id === 1 && extractId.includes(1)) {
			setDetailSelection([]);
		} else if (item.id === 1 && !extractId.includes(1)) {
			setDetailSelection(activityProperty);
		} else if (extractId.includes(item.id)) {
			const filterValue = detailSelection.filter(
				({ id }) => id !== item.id && id !== 1
			);
			setDetailSelection(filterValue);
		} else if (detailSelection.length === activityProperty.length - 2) {
			setDetailSelection(activityProperty);
		} else {
			setDetailSelection([...detailSelection, item]);
		}
	};

	const findSelectedItem = (itemId) => {
		const extractId = detailSelection.map(({ id }) => {
			return id;
		});
		return extractId.includes(itemId);
	};

	const updateGroup = (activityResponse) => {
		const activityDetails = {
			groupId: 0,
			groupName: null,
			isParentActivity: type === 'parentPhase' ? true : false,
			isDrafted: true,
			dragNDropId: uniqueIdGenerator(),
			isMethodOwnerOrCoOwner: true,
			activities: [
				{
					activityId: selectedActivity.activityId,
					activityTitle: selectedActivity.name,
					isParentActivity: type === 'parentPhase' ? true : false,
					activityIdentifier: activityResponse.activityIdentifier,
					complexityIds: activityResponse.complexityIds,
					complexityList: activityResponse.complexityList,
					isDrafted: true,
					activityToolsList: null,
					id: activityResponse.id,
					dragNDropId: uniqueIdGenerator(),
					isMethodOwnerOrCoOwner: true,
				},
			],
		};
		setActivityGroups([...activityGroups, activityDetails]);
		setShowCloneActivityForm(false);
	};

	const cloneActivity = async () => {
		setDisableCloning(true);
		setDisableSaveAndContinue(true);
		const extractName = detailSelection.map(({ name }) => {
			return name;
		});
		const postData = {
			parentPhaseId:
				type === 'parentPhase' ? Number(parentPhaseId) : Number(phaseId),
			solutionMethodId:
				type === 'parentPhase' ? Number(parentId) : Number(solutionMethodId),
			createdBy: userAlias,
			complexityId: selectedComplexity.id,
			isRACI: extractName.includes('RACI'),
			isActivityName: true,
			isActivityDescription: extractName.includes('Activity Description'),
			isTasks: extractName.includes('Tasks'),
			isTools: extractName.includes('Tools'),
			isEntryCriteria: extractName.includes('Entry Criteria'),
			isExitCriteria: extractName.includes('Exit Criteria'),
			isDeliverables: extractName.includes('Deliverables'),
			isTemplates: extractName.includes('Templates'),
			isTipsAndTechniques: extractName.includes(
				'Guidance, Tips and Techniques'
			),
			isTopics: extractName.includes('Topics'),
			isInputsForSMMI: extractName.includes('Inputs for SMMI'),
			isHashTags: extractName.includes('Hashtags'),
			activityId: selectedActivity.id,
			groupGuid: selectedActivity.groupGuid,
		};

		await axiosPostService(`api/sdmactivity/cloneactivity`, postData).then(
			(response) => {
				if (response.data.status) {
					track(1, 903, selectedActivity.id, selectedActivity.name);
					updateGroup(response.data);
					toast.info('You have successfully cloned the activity', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					setErrorMessage(response.data.errorMessage);
				}
				setDisableSaveAndContinue(false);
				setDisableCloning(false);
			}
		);
	};

	return (
		<div className="cloneActivityDatasWrapper">
			<div>
				<span
					className="cloneActivityBackToresults"
					onClick={() => setSelectedActivity(null)}
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setSelectedActivity(null);
						}
					}}
					aria-label="click to view Activities"
					title="Back to results"
					role="button"
				>
					<Icon iconName="ChevronLeft" />
					Back to results
				</span>
			</div>
			{errorMessage ? <span className="errorMsg">{errorMessage}</span> : <></>}
			<p>
				This will clone the following details of "{selectedActivity.name}" and
				save it as a draft. And then you can make further changes to suit your
				requirements
			</p>
			<div className="resltedActCloseListwrapper">
				<h6>{selectedActivity.name}</h6>
				<div>
					<div className="resltedActCloseList">
						<ul>
							{activityProperty.slice(0, 5).map((item) => (
								<li key={item.id}>
									<label className="NotificationChkboxContainer">
										<input
											name="Task Assignment Planned Hours"
											type="checkbox"
											checked={findSelectedItem(item.id)}
											onChange={() => handlePropertySelection(item)}
										/>
										<span class="CustomCheckmark"></span>
										<span className="cloneCheckBoxLabel">{item.name}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="resltedActCloseList">
						<ul>
							{activityProperty.slice(5, 9).map((item) => (
								<li key={item.id}>
									<label className="NotificationChkboxContainer">
										<input
											name="Task Assignment Planned Hours"
											type="checkbox"
											checked={findSelectedItem(item.id)}
											onChange={() => handlePropertySelection(item)}
										/>
										<span class="CustomCheckmark"></span>
										<span className="cloneCheckBoxLabel">{item.name}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="resltedActCloseList">
						<ul>
							{activityProperty.slice(9, 13).map((item) => (
								<li key={item.id}>
									<label className="NotificationChkboxContainer">
										<input
											name="Task Assignment Planned Hours"
											type="checkbox"
											checked={findSelectedItem(item.id)}
											onChange={() => handlePropertySelection(item)}
										/>
										<span class="CustomCheckmark"></span>
										<span className="cloneCheckBoxLabel">{item.name}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			{detailSelection.length === 0 ? (
				<p className="errorMsg">Please select at least one property to clone</p>
			) : (
				<></>
			)}
			<div className="AddActivityCloneButtonSection">
				<button
					className="AddActivityCloneButton"
					disabled={disableCloning}
					onClick={() => {
						cloneActivity();
					}}
				>
					Clone
				</button>
			</div>
		</div>
	);
};

export default Clone;
