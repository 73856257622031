import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAxios } from '../../context/AxiosContext';
import './Home.css';
import { Icon } from "@fluentui/react";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import ExtractMethodlogy from "../LeftMenu/ExtractMethodology";
import { useAppInsights } from "../../context/TelemetryContext";

const LeftMenu = ({
    isTopPhaseMenu,
    setIsTopPhaseMenu,
    setSelected,
    selected,
}) => {
    const {
        appState,
        endUserView,
    } = useLeftMenuCollapse();

    const ref = useRef(null);
    const { track } = useAppInsights();
    const { axiosGetService } = useAxios();
    const [quickLinkTypeId, setQuickLinkTypeId] = useState(3);
    const [complexityId, setComplexityId] = useState(0);
    const [phaseId, setPhaseId] = useState(0);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [projectDelivery, setIsProjectDelivery] = useState('');
    const [showMethodAdminMenu, setShowMethodAdminMenu] = useState(false);
    const [showExtractMethodlogy, setShowExtractMethodlogy] = useState(true);
    const [extractMethodlogyId, setExtractMethodologyId] = useState(0);
    const [complexities, setComplexities] = useState([]);
    const [solutionMethodName, setSolutionMethodName] = useState("MCEM for IS");
    const { defaultComplexity, projectTypeId } = appState;
    const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity);

    const getLeftMenuHomeData = async () => {
        await axiosGetService(
            `api/sdmquicklink/gethomepagegroupsquicklinks/${quickLinkTypeId}/${complexityId}/${phaseId}`
        ).then((response) => {
            setData(response.data);
        });
    };

    const getProjectDeliveryId = async () => {
        await axiosGetService(`api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`).then(
            (response) => {
                setIsProjectDelivery(response.data[0].value.split(",").at(1))
            }
        );
    };

    const getIsAdmin = async () => {
        await axiosGetService('api/user/profile').then((res) => {
            setIsAdmin(res.data.isAdmin);
        });
    }

    const getComplexities = async () => {
        await axiosGetService("api/activities/projectcomplexity").then((res) => {
            const data = res.data.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                    type: "option",
                };
            });
            setComplexities(data);
        });
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getLeftMenuHomeData();
        }, 1000);
        return () => {
            clearTimeout(timeOutId);
        };
    }, []);

    useEffect(() => {
        getIsAdmin();
        getProjectDeliveryId();
    }, []);

    useEffect(() => {
        getComplexities();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowMethodAdminMenu(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ref]);

    return (
        <div className="LeftMenuContainer">
            <div class="blockMCEM_Menus">
                <div style={{ display: "flex" }}>
                    <p>MCEM Stages</p>
                    <div id="divMainLeftMenu" className="editMCEMSolutionMethod">
                        <Icon
                            id='divMainLeftMenuId'
                            className="extractMCEMMoreIcon"
                            iconName="MoreVertical"
                            title="Extract Methodology"
                            aria-label="Click to view extract Methodology"
                            tabIndex={0}
                            onClick={() => {
                                setShowMethodAdminMenu(true);
                                track(
                                    1,
                                    2111,
                                    projectDelivery,
                                    'MCEM Stages'
                                );
                            }}
                            onKeyDown={(e) => {
                                if (e.which === 13) {
                                    setShowMethodAdminMenu(true);
                                    track(
                                        1,
                                        2111,
                                        projectDelivery,
                                        'MCEM Stages'
                                    );
                                }
                            }}
                        />

                        <div
                            className={`showMCEMMoreMenu ${showMethodAdminMenu
                                ? ""
                                : "hideMethodEditMenu"
                                }`}
                            ref={ref}
                        >
                            <span className="showMoreArrow"></span>
                            <ul className="menuMCEMItemUL">
                                {isAdmin && !endUserView && (
                                    <>
                                        <li className="moreMCEMItemLi">
                                            <div
                                                className="ellipseMCEMMenuItem"
                                                aria-label="Click to edit Solution Method"
                                            >
                                                <NavLink to={`/project/${1}/method/${projectDelivery}`}
                                                    onClick={() => {
                                                        setIsTopPhaseMenu(false);
                                                        setSelected(false);
                                                        setShowMethodAdminMenu(false);
                                                        track(
                                                            1,
                                                            2111,
                                                            projectDelivery,
                                                            'MCEM Stages'
                                                        );
                                                    }} >
                                                    <span>Edit Solution Method</span>
                                                </NavLink>
                                            </div>
                                        </li>
                                    </>
                                )}
                                <li className="moreMCEMItemLi" > 
                                    <div
                                        className="ellipseMCEMMenuItem"
                                        tabIndex={0}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setExtractMethodologyId(projectDelivery);
                                            setShowExtractMethodlogy(true);
                                            setShowMethodAdminMenu(false);
                                            track(
                                                1,
                                                2111,
                                                projectDelivery,
                                                'MCEM Stages'
                                            );
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.which === 13) {
                                                setExtractMethodologyId(projectDelivery);
                                                setShowExtractMethodlogy(true);
                                                setShowMethodAdminMenu(false);
                                                track(
                                                    1,
                                                    2111,
                                                    projectDelivery,
                                                    'MCEM Stages'
                                                );
                                            }
                                        }}
                                        aria-label="Click to extract Solution Method"
                                    >
                                        <span>Extract Solution Method</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {showExtractMethodlogy &&
                            extractMethodlogyId === projectDelivery ? (
                            <ExtractMethodlogy
                                showExtractMethodlogy={showExtractMethodlogy}
                                extractMethodlogyId={extractMethodlogyId}
                                solutionMethodName={solutionMethodName}
                                selectedComplexity={selectedComplexity}
                                setSelectedComplexity={setSelectedComplexity}
                                setExtractMethodologyId={setExtractMethodologyId}
                                setShowExtractMethodlogy={setShowExtractMethodlogy}
                                complexities={complexities}
                            />
                        ) : null}
                    </div>


                    {/* {isAdmin && !endUserView &&
                        <NavLink to={`/project/${1}/method/${projectDelivery}`}
                            onClick={() => {
                                setIsTopPhaseMenu(false);
                                setSelected(false);
                            }}>
                            <span style={{
                                cursor: "pointer",
                                color: "white",
                                position: "relative",
                                top: "23px",
                            }}>
                                <Icon
                                    title="Edit Solution Method"
                                    iconName="MoreVertical"
                                    tabIndex={0}
                                    role="button"
                                />
                            </span>
                        </NavLink>
                    } */}
                </div>
            </div>
            <div class="left-menu" role="navigation" aria-label="LeftNavigation">
                {data && data?.map((items) => (
                    <div className="leftGroups" key={items?.quickLinkGroupId}>
                        <span>{items?.quickLinkGroupName}</span>
                        {items.quickLinkActivities?.sort((a, b) => parseFloat(a.sequenceNumber) - parseFloat(b.sequenceNumber)).map((value) => (
                            <div className="leftLinks">
                                <a href={value?.linkUrl}
                                    key={value?.quickLinkActivityId}
                                    tabIndex={0}
                                    aria-label={value?.linkName}
                                    target="_blank"
                                    onClick={()=> {
                                        track(
                                            1,
                                            2111,
                                            value?.quickLinkActivityId,
                                            value?.linkName
                                        );
                                    }}
                                    rel="noopener noreferrer">
                                    {value?.linkName}
                                </a>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div role="contentinfo" class="footerContent">
                <a  id="contentinfoId"
                    href="https://aka.ms/sdmplusprivacy"
                    target="_blank"
                    aria-label="Click to view Privacy Policy"
                    role="link"
                    tabIndex={0}
                >
                    Privacy Policy
                </a>
            </div>
        </div>
    );
}

export default LeftMenu;