import React, { useEffect, useState } from 'react';
import CSTCopilot from "cst-web-chat";
import "cst-web-chat/style.css";
import CstCopilotLogo from "../../assets/Copilot/CopilotLogo.svg";
import { copilotTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useAuth } from '../../context/UserAuthContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useConfig } from '../../context/ConfigContext';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useAxios } from '../../context/AxiosContext';
import OCVFeedback from './OCVFeedback';
import { CopilotConfig } from './Copilot';
import { jwtDecode } from "jwt-decode";

const Copilot = (props) => {
    const [showCopilot, setShowCopilot] = useState(true);
    const { user } = useAuth();
    const { copilotApiURL } = useConfig();
    const { appState, setAppState } = useLeftMenuCollapse();
    const [message, setMessage] = useState({});
    const [isFeedbackPositiveData, setisFeedbackPositiveData] = useState();
    const { axiosGetService } = useAxios();
    const [instrumentationKey, setInstrumentationKey] = useState();
    const [copilotName, setCopilotName] = useState();
    const [appInsights, setAppInsights] = useState();
    const [objectId, setObjectId] = useState();

    const getInstrumentationKey = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=VirtuosoAppInstrumentationKey`).then(
			(response) => {
                if(response.data && response.data.length > 0){
                    setInstrumentationKey(response.data[0].value);
                }
			}
		);
	};
    
    const getCopilotTeam = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SDMCopilotName`).then(
			(response) => {
                if(response.data && response.data.length > 0){
                    setCopilotName(response.data[0].value);
                }
			}
		);
	};

    useEffect(()=>{
        getInstrumentationKey();
        getCopilotTeam();
    },[]);

    useEffect(() => {
        if(instrumentationKey !== undefined){
             // Appinsight configurations for tracking events and data.
            const reactPlugin = new ReactPlugin();
            const ai = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    autoTrackPageVisitTime: true,
                    overridePageViewDuration: true,
                    extensions: [reactPlugin]
                }
            })
            ai.loadAppInsights()
            setAppInsights(ai.appInsights);
        }
    },[instrumentationKey]);

    const trackCustomEvents = (name, key, value) => {
        // To track the custom events for eg like/dislike of the response.
        // failure in copilot as well as starting of the copilot.
        // Basically this function is to track the events data performed on copilot or by copilot on appinsight.
        if(appInsights){
            appInsights.trackEvent(
                {
                    name: name,
                    properties:
                    {
                        CopilotClient : 'SDM Copilot',          
                        ActionKey: key,
                        ActionValue: value,
                        URL: window.location.href,
                        UserId: user.profile.email,
                    }
                }
            );
        }
        
    }

    const closeMCAPSBot = () => {
        // To close the chat bot(copilot);
        setShowCopilot(false);
        setAppState({
			...appState,
			rightPanelContent: null,
			parentRightPanelComponent: null,
		});
    }

    const ocvFeedback = (isFeedbackPositive, message) => {
        setMessage(message)
        setisFeedbackPositiveData(isFeedbackPositive)
    }
    const generateToken = () => new Promise((resolve) => {
        // To give the authentication token to copilot team
        return resolve (copilotTokenService());
    });

    const token  = generateToken().then((result) => {
        const decodedValue = jwtDecode(result);
        setObjectId(decodedValue.oid);
    });

    const renderCopilot = () =>{
        const copilotProps = {...CopilotConfig, 
            applicationName: copilotName,
            userName: user.profile.firstName +" "+ user.profile.surname,
            closeBot: closeMCAPSBot,
            isVisible: showCopilot,
            image: CstCopilotLogo,
            trackCustomEvents: trackCustomEvents,
            getBearerTokenCallback: generateToken,
            apiUrls: {questionApi:copilotApiURL},
            appInsightConfig: { instrumentationKey: instrumentationKey },
            ocvData: ocvFeedback
        }
        return (
            <CSTCopilot
            {...copilotProps}
            />
        );
    }
    
    return (
        <>
            {(showCopilot && instrumentationKey !== undefined && copilotName!== undefined) && renderCopilot()}
            {objectId && <OCVFeedback isFeedbackPositive={isFeedbackPositiveData} objectId={objectId} aiResponse={message}/>}
        </>
    );
}
export default Copilot;