import React, { useEffect, useState, appState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import fileIcons from '../../static/TemplateIcons';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function SearchResultPageTutorials({ data, currentPage }) {
	const history = useHistory();
	const { track } = useAppInsights();
	const [showText, setShowText] = useState(false);

	return (
		<div
			tabIndex={0}
			id={`search_topic_${data.id}`}
			className="tabsWrapper topicsTabsSearch"
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={fileIcons[data.fileType]}
						className="imageOfSearchResult"
						alt="fileType"
					/>
				</div>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					<NavLink
						onClick={() =>
							track(1, 402, data.id, data.title, {
								pageNumber: currentPage,
							})
						}
						to={data.fileLocationNew}
						key={data.title}
					>
						{data.title}
					</NavLink>
				</div>
				<div class="existingHashTagsOfTopic">
					{showText ? (
						<>
							{data.hashTagsList.map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					) : (
						<>
							{data.hashTagsList.slice(0, 4).map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					)}
				</div>
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						{data.toolNames ? (
							<div className="searchTopicDetails">
								<span className="topicLabel">Tools: </span>
								<span className="topicCatogry">{data.toolNames}</span>
							</div>
						) : (
							<></>
						)}

						<div className="hidingConsultingSupport">
							<span className="topicLabel">Project Type: </span>
							<span className="topicCatogry">{data.projectTypeName}</span>

							{/* <div
								className="tabExpandedViewIcon"
								onClick={() => setShowText(!showText)}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								title="Expand Tutorial"
								aria-expanded={showText}
								role="button"
								aria-label={
									showText
										? 'Click to Collapse tutorial information'
										: 'Click to expand  tutorial Template information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default SearchResultPageTutorials;
