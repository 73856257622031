import React, { useState, useEffect } from 'react';

const PreviewLeftPanel = ({
    previewData,
	setPreviewData,
}) => {

    const order = (array) => {
		return array.sort((a, b) => a.sequence - b.sequence);
	}

    const linkOrder = (array) => {
		return array.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}
    return(
        <>
            <div className='previewLeftPanel'>
                <div className='preivewPhase'>
                    <span>MCEM Stages</span>
                </div>
                <div className='previewGroupsLeftPanel'>
                    {previewData?.map((item) => (
                        <div className='previewGroups' key={item.quickLinkGroupId}>
                            <span>
                                {item?.quickLinkGroupName}
                            </span>
                            {linkOrder(item.quickLinkActivities)?.map((data) => (
                                <div className='previewLinks'>
                                    <a
                                        href={data?.linkUrl}
                                        key={data?.quickLinkActivityId}
                                        tabIndex={0}
                                        aria-label={data?.linkName}
                                        target="_blank"
                                        rel="noopener noreferrer" // This is important for security reasons
                                    >
                                        {data?.linkName}
                                    </a>
                                </div>
                            ))}
                        </div>
                    ))}
                    
                </div>
            </div>
        </>
    );
}

export default PreviewLeftPanel;