import React, { useState, useEffect } from 'react';

const RolesFilter = ({
	className,
	data,
	setValue,
	value,
	enableSelection,
	setSelectedRoles,
	selectedRoles,
	setSelectedRoleId,
}) => {
	const [showMoreRoles, setShowMoreRoles] = useState(false);
	const [rolesData, setRolesData] = useState([]);

	useEffect(() => {
		if (data.length > 5 && !showMoreRoles) {
			setRolesData(data.slice(0, 5));
		} else {
			setRolesData(data);
		}
	}, [data]);
	useEffect(() => {
		if (!selectedRoles) {
			setValue({ name: 'None', id: 0, type: 'option' });
		}
	}, [selectedRoles]);
	return (
		<>
			{data.length !== 0 && data.length > 5 ? (
				<ul>
					{rolesData.map((role) => (
						<li key={role.id}>
							<label className="checkBoxContainer filterChkboxContainer">
								<input
									aria-label="Roles Category"
									name="rolesFilter"
									type="radio"
									checked={role.id === value.id}
									onChange={() => {
										setValue(role);
										setSelectedRoles(role.name);
										setSelectedRoleId(role.id);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setValue(role);
											setSelectedRoles(role.name);
											setSelectedRoleId(role.id);
										}
									}}
								/>
								<span className="checkmark checkmarkRadioButton"></span>
								<span className="filterLabel">{role.name}</span>
							</label>
						</li>
					))}
					{showMoreRoles ? (
						<li>
							<p
								onClick={() => {
									setRolesData(data.slice(0, 5));
									setShowMoreRoles(!showMoreRoles);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setRolesData(data.slice(0, 5));
										setShowMoreRoles(!showMoreRoles);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label={'Show Less'}
							>
								- Show Less
							</p>
						</li>
					) : (
						<li>
							<p
								onClick={() => {
									setRolesData([...data]);
									setShowMoreRoles(!showMoreRoles);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setRolesData([...data]);
										setShowMoreRoles(!showMoreRoles);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label={data.length > 5 ? `${data.length - 5} More` : ''}
							>
								+ {data.length > 5 ? `${data.length - 5} More` : <></>}
							</p>
						</li>
					)}
				</ul>
			) : data.length !== 0 && data.length <= 5 ? (
				<ul>
					{data.map((role) => (
						<li key={role.id}>
							<label className="checkBoxContainer filterChkboxContainer">
								<input
									name="rolesFilter"
									type="radio"
									checked={role.id === value.id}
									onChange={() => {
										setValue(role);
										setSelectedRoles(role.name);
										setSelectedRoleId(role.id);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setValue(role);
											setSelectedRoles(role.name);
											setSelectedRoleId(role.id);
										}
									}}
								/>
								<span className="checkmark"></span>
								<span className="filterLabel">{role.name}</span>
							</label>
						</li>
					))}
				</ul>
			) : (
				<p className="noFiltersFound">No roles available</p>
			)}
		</>
	);
};

export default RolesFilter;
