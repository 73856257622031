import React, { useEffect } from 'react';

import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import ActivityEditor from '../PhaseLeftMenu/ActivityEditor';
import AdminActivityEditor from './AdminActivityEditor';

const TipsAndTechniques = ({
	showFields,
	setshowFields,
	handelFormView,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	setComplexityLevelUpdatedDetails,
}) => {
	// Get the current and original descriptions based on the selected complexity level
	const currentDescription = complexityLevelUpdatedDetails?.[selectedComplexity]?.tipsAndTechniques || '';
	const originalDescription = complexityLevelDetails?.[selectedComplexity]?.tipsAndTechniques || '';


	useEffect(() => {
		// Update complexityLevelDetails when the current description differs from the original one
		if (currentDescription !== originalDescription) {
			setComplexityLevelDetails(prevState => ({
				...prevState,
				[selectedComplexity]: {
					...prevState?.[selectedComplexity],
					tipsAndTechniques: currentDescription,
				},
			}));
		}
	}, [currentDescription, originalDescription, selectedComplexity, setComplexityLevelDetails]);

	useEffect(() => {
		// Update `originalDescription` whenever `selectedComplexity` changes
		const newDescription = complexityLevelDetails?.[selectedComplexity]?.tipsAndTechniques || '';
		setComplexityLevelUpdatedDetails(prevState => ({
			...prevState,
			[selectedComplexity]: {
				...prevState?.[selectedComplexity],
				tipsAndTechniques: newDescription,
			},
		}));
	}, [selectedComplexity]);

	return (
		<div className={'Detailswrapper p-0'}>
			<div className="DetailswrapperTitle">
				<AdminActivityEditor
					value={complexityLevelUpdatedDetails}
					setValue={setComplexityLevelUpdatedDetails}
					type="tipsAndTechniques"
					selectedComplexity={selectedComplexity}
				/>
			</div>
		</div>
	);
};

export default TipsAndTechniques;
