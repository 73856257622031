import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import dompurify from 'dompurify';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAppInsights } from '../../context/TelemetryContext';
import $ from 'jquery';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import { useAxios } from '../../context/AxiosContext';
import DescriptionCkEditor from '../CkEditor/ActivityHistoryTrackChanges/DescriptionTrackChanges';
import SupportAndActivitiesCkEditor from '../CkEditor/ActivityHistoryTrackChanges/SupportingActivitieTrackChanges';
import TipsAndTechniquesCkEditor from '../CkEditor/ActivityHistoryTrackChanges/TipsAndTechniquesTrackChanges';
import DeliverablesCkEditor from '../CkEditor/ActivityHistoryTrackChanges/DeliverablesTrackChanges';
import Dropdown from '../Dropdown';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import ActivityTemplateHisotry from '../ActivityTemplateHisotry';

import '../Activity/Activity.css';
import '../ActivityContributorCrowdSourcing/PreviewAndSubmitPopUp.css';
import './HistoryPreviewPopUp.css';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import EntryCriteriaHistory from './EntryCriteriaHistory';
import ExitCriteriaHistory from './ExitCriteriaHistory';
import TaskHistory from './TaskHistory';
import decryptAES256 from '../CkEditor/DecryptionComponent';
import { useConfig } from '../../context/ConfigContext';

function HistoryPopUp({
	contriutorLastSuggestions,
	reviewedSuggestions,
	contributorContent,
	activityContent,
	topicData,
	procedureData,
	complexities,
	setComplexityName,
	complexityName,
	complexityId,
	users,
	currentUser,
	historyToolsId,
	historicalPreviewTools,
	historyToolsSuggestion,
	toolsReviewedIds,
	ckEditorUserMap,
	reviewerInfo,
	activityToolsList,
	toolsUrl,
	existingHashtags,
	entryCriteriaData,
	exitCriteriaData,
	taskData,
	reorderedTaskReviewedSuggestions,
    reorderedEntryReviewedSuggestions,
    reorderedExitReviewedSuggestions,
    reorderedInputReviewedSuggestions,
    reorderedOutputReviewedSuggestions,
	reorderHistory
}) {
	const { ckEdKEY } = useConfig();
	const history = useHistory();
	const htmlSanitizer = dompurify.sanitize;
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const [licenseKey, setLicenseKey] = useState(null);
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const [modalShow, setModalShow] = useState(true);
	const [sampleTemplates, setSampleTemplates] = useState({
		enable: false,
		data: null,
		parentData: null,
	});
	const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);
	// template Preview Data
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	const { url } = useRouteMatch();
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
	const activityDetailPageUrl = url.split('/').slice(0, 10).join('/');
	const [activityTemplate, setActivityTemplate] = useState([]);
	const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');
	const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry-hist');
	const [currentExitTab, setCurrentExitTab] = useState('tab-exit-hist');

	let descriptionReviewdSuggestions = null;
	if (reviewedSuggestions) {
		descriptionReviewdSuggestions =
			JSON.parse(reviewedSuggestions).descriptionReviewedSuggestions;
	}
	let supportReviewedSuggestions = null;
	if (reviewedSuggestions) {
		supportReviewedSuggestions =
			JSON.parse(reviewedSuggestions).supportReviewedSuggestions;
	}

	let tipsAndTechniquesReviewedSuggestions = null;
	if (reviewedSuggestions) {
		tipsAndTechniquesReviewedSuggestions =
			JSON.parse(reviewedSuggestions).tipsAndtechniquesReviewedSuggestions;
	}

	let deliverablesReviewedSuggestions = null;
	if (reviewedSuggestions) {
		deliverablesReviewedSuggestions =
			JSON.parse(reviewedSuggestions).deliverablesReviewedSuggestions;
	}

	let description = null;
	if (contributorContent) {
		description = JSON.parse(contributorContent).Description;
	} else {
		description = activityContent.activityDescription;
	}
	let supportAndActivities = null;
	if (contributorContent) {
		supportAndActivities = JSON.parse(contributorContent).SupportingActivities;
	} else {
		supportAndActivities = activityContent.activitySupportingActivities;
	}

	let tipsAndTechniques = null;
	if (contributorContent) {
		tipsAndTechniques = JSON.parse(contributorContent).TipsAndTechniques;
	} else {
		tipsAndTechniques = activityContent.activityTipsAndTechniques;
	}

	let deliverables = null;
	if (contributorContent) {
		deliverables = JSON.parse(contributorContent).Deliverables;
	} else {
		deliverables = activityContent.activityDeliverables;
	}

	const getTemplateSidebarHeight = () => {
		const height = $('.templateContentContainer').height();
		setTemplateSidebarHeight(height + 63);
	};

	const closeModal = () => {
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			showHistory: false,
		});
		setModalShow(false);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};
	const getToolsConatinerHeight = () => {
		const height = $('.ToolsContentContainer').height();
		setToolsSidebarHeight(height + 63);
	};

	const getActivityTemplates = () => {
		const reviewedTemplates =
			JSON.parse(reviewedSuggestions).templateReviewedSuggestions;
		const existingTemplates = activityContent.activityTemplateDetails.filter(
			({ id }) => !reviewedTemplates.some((item) => JSON.parse(item).id === id)
		);
		setActivityTemplate(existingTemplates);
	};

	useEffect(() => {
		getToolsConatinerHeight();
	});

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		track(2, 137, activityContent.activityId, activityContent.activityName);
	}, []);

	useEffect(() => {
		getActivityTemplates();
	}, []);

	useEffect(() => {
		getTemplateSidebarHeight();
	});

	return (
		<>
			{!licenseKey ? (
				<Spinner />
			) : (
				<Modal
					className={`PreviewAndSubmitPopUp historicalPreview ${enableTemplatePreview ? 'hideHistoryPreview' : 'none'
						}`}
					show={modalShow}
					onHide={() => {
						/* Not calling any function to close modal*/
					}}
					dialogClassName="modal-90w"
				>
					<Modal.Header>
						<Modal.Title>
							Historical Preview
							<p className="reviewerNameLabel">
								Reviewer Name:&nbsp;{reviewerInfo}
							</p>
							<div className="discardAndPreviewButton">
								<Link
									to={`${activityDetailPageUrl}/${activityContent.activityIdentifier}?complexity=${complexityId}`}
								>
									<Icon
										ariaLabel="Close"
										iconName="Cancel"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												closeModal();
											}
										}}
										onClick={closeModal}
									/>
								</Link>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="activityLandinPage">
								<div className="activityMainContainer">
									<div className="activityDescriptionDetails">
										<div className="activityDescTitlePart">
											<div className="activityTitleRightPart">
												<span className="activityVersion">
													{`Document Version: ${activityContent.activityVersion},`}
													&nbsp;&nbsp;{`${activityContent.date} `}
												</span>
												<div className="activityComplexity">
													<span>Complexity:</span>
													<Dropdown
														className="complexityDisabled"
														data={complexities}
														setValue={setComplexityName}
														value={complexityName}
														enableDropdown={false}
													/>
												</div>
											</div>
										</div>
										<div className="previewDescOfActivity">
											<h4
												title={activityContent.activityName}
												className="activityLandingPageTitle"
											>
												{activityContent.activityName}
											</h4>
											<div class="existingHashTagsOfActivity">
												{existingHashtags.map((name) => (
													<span
														key={name}
														onClick={() => {
															history.push(
																`/search?searchParam=${encodeURIComponent(
																	name
																)}`
															);
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
																history.push(
																	`/search?searchParam=${encodeURIComponent(
																		name
																	)}`
																);
															}
														}}
													>
														{name}
													</span>
												))}
											</div>
											<ActivityRaciMatrix activityData={activityContent} />
											<div className="innerContent" id="historyInnerContent">
												{description ? (
													<>
														<h2
															className="sub-heading"
															id="historyDesc"
															aria-label="Description"
															tabIndex={0}
														>
															Description
														</h2>

														<DescriptionCkEditor
															users={users}
															currentUser={currentUser}
															initialData={description}
															initalSuggestions={
																contriutorLastSuggestions
																	? contriutorLastSuggestions.description
																	: []
															}
															reviewedSuggestions={
																descriptionReviewdSuggestions
															}
															licenseKey={licenseKey}
														/>
													</>
												) : null}

												{supportAndActivities ? (
													<>
														<h2 className="sub-heading" id="historySupportAct">
															Supporting Activities
														</h2>
														<SupportAndActivitiesCkEditor
															users={users}
															currentUser={currentUser}
															initialData={supportAndActivities}
															initalSuggestions={
																contriutorLastSuggestions
																	? contriutorLastSuggestions.supportingActivities
																	: []
															}
															reviewedSuggestions={supportReviewedSuggestions}
															licenseKey={licenseKey}
														/>
													</>
												) : null}

												{/* {activityContent.activityEntryCriteria || contriutorLastSuggestions.entryCriteriaSuggestions.length > 0 ? ( */}
												<div className="addingTemplatesEditAcivity">
													<h2 className="sub-heading" id="deliver">
														<span>Entry Criteria/Input</span>
													</h2>

													<EntryCriteriaHistory
														criteriaData={entryCriteriaData}
														toolsSidebarHeight={templateSidebarHeight}
														criteriaSuggestions={
															contriutorLastSuggestions &&
																contriutorLastSuggestions.entryCriteriaSuggestions
																? contriutorLastSuggestions.entryCriteriaSuggestions
																: []
														}
														ckEditorUserMap={ckEditorUserMap}
														reviewedCriteriaSuggestions={
															JSON.parse(reviewedSuggestions)
																.entryCriteriaReviewedSuggestions
																? JSON.parse(reviewedSuggestions)
																	.entryCriteriaReviewedSuggestions
																: []
														}
														reorderedEntryReviewedSuggestions={
															reorderedEntryReviewedSuggestions
																
																? reorderedEntryReviewedSuggestions
																	
																: []
														}
														reorderedInputReviewedSuggestions={
															reorderedInputReviewedSuggestions
																
																? reorderedInputReviewedSuggestions
																	
																: []
														}
														criteria="entry"
														currentTab={currentEntryTab}
														setCurrentTab={setCurrentEntryTab}
														reorderHistory={reorderHistory}
													/>
												</div>
												{/* ) : null} */}

												{/* {activityContent.activityTasks || contriutorLastSuggestions.taskSuggestions.length > 0 ? ( */}
												<div className="addingTemplatesEditAcivity">
													<h2 className="sub-heading" id="deliver">
														<span>Task</span>
													</h2>

													<TaskHistory
														taskData={taskData}
														toolsSidebarHeight={templateSidebarHeight}
														taskSuggestions={
															contriutorLastSuggestions &&
																contriutorLastSuggestions.taskSuggestions
																? contriutorLastSuggestions.taskSuggestions
																: []
														}
														ckEditorUserMap={ckEditorUserMap}
														reviewedTaskSuggestions={
															JSON.parse(reviewedSuggestions)
																.taskReviewedSuggestions
																? JSON.parse(reviewedSuggestions)
																	.taskReviewedSuggestions
																: []
														}
														reorderedTaskReviewedSuggestions={
															reorderedTaskReviewedSuggestions
																
																? reorderedTaskReviewedSuggestions
																: []
														}
														reorderHistory={reorderHistory}

													/>
												</div>
												{/* // ) : null} */}

												{/* {activityContent.activityExitCriteria || contriutorLastSuggestions.exitCriteriaSuggestions.length > 0 ? ( */}
												<div className="addingTemplatesEditAcivity">
													<h2 className="sub-heading" id="deliver">
														<span>Exit Criteria/Output</span>
													</h2>

													<ExitCriteriaHistory
														criteriaData={exitCriteriaData}
														toolsSidebarHeight={templateSidebarHeight}
														criteriaSuggestions={
															contriutorLastSuggestions &&
																contriutorLastSuggestions.exitCriteriaSuggestions
																? contriutorLastSuggestions.exitCriteriaSuggestions
																: []
														}
														ckEditorUserMap={ckEditorUserMap}
														reviewedCriteriaSuggestions={
															JSON.parse(reviewedSuggestions)
																.exitCriteriaReviewedSuggestions
																? JSON.parse(reviewedSuggestions)
																	.exitCriteriaReviewedSuggestions
																: []
														}
														reorderedExitReviewedSuggestions={
															reorderedExitReviewedSuggestions
																? reorderedExitReviewedSuggestions
																: []
														}
														reorderedOutputReviewedSuggestions={
															reorderedOutputReviewedSuggestions
																? reorderedOutputReviewedSuggestions
																: []
														}
														criteria="exit"
														currentTab={currentExitTab}
														setCurrentTab={setCurrentExitTab}
														reorderHistory={reorderHistory}

													/>
												</div>
												{/* ) : null} */}



												{procedureData.length > 0 ? (
													<div className="nonEditable">
														<h2 className="sub-heading" id="historyDeliver">
															Procedures
															<div className="nonEditableSection">
																<span className="nonEditableIcon"></span>
																<div class="nonEditableIconToolTip">
																	<span class="nonEditablaToolTipArrow"></span>
																	<span>
																		You can not edit the contents of this text
																	</span>
																</div>
															</div>
														</h2>

														{procedureData.length > 0 ? (
															<div id="previewProcedureList">
																<ul>
																	{procedureData.map((procedure) => (
																		<li>
																			{
																				<a href={procedure.url} target="_blank">
																					{procedure.name}
																				</a>
																			}
																		</li>
																	))}
																</ul>
															</div>
														) : null}
													</div>
												) : (
													<></>
												)}
												{topicData.length > 0 ? (
													<div className="nonEditable">
														<h2 className="sub-heading" id="historyDeliver">
															Related Topics
															<div className="nonEditableSection">
																<span className="nonEditableIcon"></span>
																<div class="nonEditableIconToolTip">
																	<span class="nonEditablaToolTipArrow"></span>
																	<span>
																		You can not edit the contents of this text
																	</span>
																</div>
															</div>
														</h2>

														{topicData.length > 0 ? (
															<div id="previewTopicList">
																<ul>
																	{topicData.map((topic) => (
																		<li>
																			{
																				<a href={topic.url} target="_blank">
																					{topic.name}
																				</a>
																			}
																		</li>
																	))}
																</ul>
															</div>
														) : null}
													</div>
												) : (
													<></>
												)}

												<div className="addingTemplatesEditAcivity">
													<h2 className="sub-heading" id="deliver">
														<span>Deliverables</span>
													</h2>
													<ActivityTemplateHisotry
														templateData={activityTemplate}
														toolsSidebarHeight={templateSidebarHeight}
														templateSuggestions={
															contriutorLastSuggestions &&
																contriutorLastSuggestions.templateSuggestions
																? contriutorLastSuggestions.templateSuggestions
																: []
														}
														ckEditorUserMap={ckEditorUserMap}
														reviewedTemplateSuggestions={
															JSON.parse(reviewedSuggestions)
																.templateReviewedSuggestions
														}
														enableTemplatePreview={enableTemplatePreview}
														setEnableTemplatePreview={setEnableTemplatePreview}
													/>
													{/* {activityContent.activityDeliverables ? (
														<div
															id="activityDeliverables"
															dangerouslySetInnerHTML={{
																__html: htmlSanitizer(
																	activityContent.activityDeliverables
																),
															}}
														/>
													) : null} */}

													{deliverables ? (
														<>
															<div className='deliverables-container-historical'>
																<h2 className="sub-heading" id="desc"
																	aria-label='Description'>
																	Description
																</h2>

																<DeliverablesCkEditor
																	users={users}
																	currentUser={currentUser}
																	initialData={deliverables}
																	initalSuggestions={
																		contriutorLastSuggestions
																			? contriutorLastSuggestions.deliverables
																			: []
																	}
																	reviewedSuggestions={
																		deliverablesReviewedSuggestions
																	}
																	licenseKey={licenseKey}
																/>
															</div>
														</>
													) : null}
												</div>


												<h2 className="sub-heading" id="tool">
													Tools
												</h2>
												<div className="customToolsEditor">
													<div className="toolsEditorMainContainer">
														<div className="ToolsContentContainer">
															<ul>
																{activityToolsList.map((tools) => (
																	<>
																		{tools.isSelected &&
																			!historyToolsId.includes(tools.id) ? (
																			<li>
																				<a href={tools.url} target="_blank">
																					{tools.name}
																				</a>
																			</li>
																		) : (
																			<></>
																		)}
																	</>
																))}
																{historicalPreviewTools.map((item) => (
																	<>
																		{!adminToolsAndGuidance.includes(
																			JSON.parse(item).toolsId
																		) ? (
																			<li
																				className={
																					JSON.parse(item).suggestionType ===
																						'deletion'
																						? 'userDeletedToolsAndGuidance'
																						: 'userSelectedToolsAndGuidance'
																				}
																			>
																				<div className={'userAddedItem'}>
																					<a
																						href={
																							toolsUrl[JSON.parse(item).toolsId]
																						}
																					>
																						{JSON.parse(item).name}
																					</a>
																				</div>
																			</li>
																		) : (
																			<li
																				className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType ===
																					'deletion'
																					? 'userDeletedToolsAndGuidance'
																					: 'none'
																					}`}
																			>
																				<a
																					href={
																						toolsUrl[JSON.parse(item).toolsId]
																					}
																					target="_blank"
																				>
																					{JSON.parse(item).name}
																				</a>

																				{JSON.parse(item).suggestionType ===
																					'deletion' ? (
																					<div className="sideBarCard">
																						<div className="sideBarCardHeader">
																							<img
																								className="userImg"
																								src={
																									ckEditorUserMap[
																									JSON.parse(item).authorId
																									][1]
																								}
																								alt=""
																							/>
																							<span>
																								{
																									ckEditorUserMap[
																									JSON.parse(item).authorId
																									][0]
																								}
																							</span>
																						</div>
																						<div className="itemStatus">
																							<span>Remove</span>&nbsp;"
																							{JSON.parse(item).name}"
																						</div>
																					</div>
																				) : (
																					<></>
																				)}
																			</li>
																		)}
																	</>
																))}
															</ul>
														</div>
													</div>
													<div
														className="toolsSidebar"
														style={{ height: toolsSidebarHeight }}
													>
														{historicalPreviewTools.map((item) => (
															<>
																{toolsReviewedIds[JSON.parse(item).id] ===
																	'accepted' ? (
																	<div
																		className={`sideBarCard acceptedSideBarCard ${JSON.parse(item).suggestionType ===
																			'insertion'
																			? 'userAddedItem'
																			: 'userDeletedToolsAndGuidance'
																			}`}
																	>
																		<div className="sideBarCardHeader">
																			<img
																				className="userImg"
																				src={
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][1]
																				}
																				alt=""
																			/>
																			<span>
																				{
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][0]
																				}
																			</span>
																			<span className="suggestionStatus">
																				Approved
																			</span>
																		</div>
																		<div className="itemStatus">
																			<span>
																				{JSON.parse(item).suggestionType ===
																					'insertion'
																					? 'Add'
																					: 'Remove'}
																			</span>
																			<span>
																				&nbsp;"{JSON.parse(item).name}"
																			</span>
																		</div>
																	</div>
																) : (
																	<div
																		className={`sideBarCard rejectedSideBarCard ${JSON.parse(item).suggestionType ===
																			'insertion'
																			? 'userAddedItem'
																			: 'userDeletedToolsAndGuidance'
																			}`}
																	>
																		<div className="sideBarCardHeader">
																			<img
																				className="userImg"
																				src={
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][1]
																				}
																				alt=""
																			/>
																			<span>
																				{
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][0]
																				}
																			</span>
																			<span className="suggestionStatus">
																				Rejected
																			</span>

																			<div className="itemStatus">
																				<span>
																					{JSON.parse(item).suggestionType ===
																						'insertion'
																						? 'Add'
																						: 'Remove'}
																				</span>
																				<span>
																					&nbsp;"{JSON.parse(item).name}"
																				</span>
																			</div>
																		</div>
																	</div>
																)}
															</>
														))}
													</div>
												</div>

												{tipsAndTechniques ? (
													<>
														<h2 className="sub-heading" id="historyTipstech">
															Guidance, Tips and Techniques
														</h2>
														<TipsAndTechniquesCkEditor
															users={users}
															currentUser={currentUser}
															initialData={tipsAndTechniques}
															initalSuggestions={
																contriutorLastSuggestions
																	? contriutorLastSuggestions.tipsAndTechniques
																	: []
															}
															reviewedSuggestions={
																tipsAndTechniquesReviewedSuggestions
															}
															licenseKey={licenseKey}
														/>
													</>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default HistoryPopUp;
