import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dompurify from 'dompurify';
import { useParams, useHistory } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/TopicPreviewModeTrachChanges/DescriptionTrackChanges';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import RejectionReasonModal from './RejectionReasonModal';

import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../TopicsContributorCrowdSourcing/PreviewAndSubmitPopUp.css';

function PreviewAndSubmitPopUp({
	setShowPreviewAndSubmitPopUp,
	users,
	currentUser,
	description,
	descriptionSuggestions,
	setLeavingWithoutSave,
	setSubmitMessage,
	reviewedDescriptionSuggestions,
	contributorDescSuggestions,
	topicDescriptionData,
	licenseKey,
	messagesType,
	contributorSuggestionsInfo,
	ratingPoints,
	enableGamification,
}) {
	const history = useHistory();
	const config = {
		ADD_TAGS: ['iframe', 'oembed'],
		ADD_ATTR: ['url', 'allowfullscreen'],
	};
	const htmlSanitizer = dompurify.sanitize;
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { id } = useParams();
	const [modalShow, setModalShow] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState(1);
	const [showRejectionReasonModal, setShowRejectionReasonModal] =
		useState(false);
	const [rejectionComments, setRejectionComments] = useState({});
	const [usersRating, setUsersRating] = useState({});

	const { axiosPostService, axiosGetService } = useAxios();
	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
		setShowRejectionReasonModal(false);
	};

	const GetImageBase64 = () => {
		var imagesFromDescription = $(previewActivityDescription).find('img');
		$.each(imagesFromDescription, function (i, img) {
			var imageSource = img.src;
			if (imageSource.indexOf('base64') <= 0) {
				imageSource = decodeURI(imageSource);
				axiosGetService(
					`api/sdmuser/getimagebase64fromblob?sourceUrl=` + imageSource
				).then((response) => {
					setPreviewActivityDescription(
						previewActivityDescription.replace(imageSource, response.data)
					);
				});
			}
		});
	};

	const getSuggestionsInfo = (
		emCollection,
		suggestionsData,
		suggestionsMap
	) => {
		for (var i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-after')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-end-after').split(':')
						.length > 3
						? $(emCollection[i]).attr('data-suggestion-end-after').split(':')[2]
						: $(emCollection[i])
							.attr('data-suggestion-end-after')
							.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
				const suggestionId =
					$(emCollection[i]).attr('data-suggestion-start-before').split(':')
						.length > 3
						? $(emCollection[i])
							.attr('data-suggestion-start-before')
							.split(':')[2]
						: $(emCollection[i])
							.attr('data-suggestion-start-before')
							.split(':')[1];
				if (!suggestionsMap.has(suggestionId)) {
					suggestionsData.push(suggestionId);
					suggestionsMap.set(suggestionId, 1);
				}
			}
		}
	};

	const findSuggestionsInHtmData = (htmlData) => {
		var domParser = new DOMParser();

		var docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		var emCollection = docElement.getElementsByTagName('suggestion');
		const suggestionsData = [];
		const suggestionsMap = new Map();
		for (var i = 0; i < emCollection.length; i++) {
			const suggestionId = $(emCollection[i]).attr('id').split(':')[0];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (var i = 0; i < emCollection.length; i++) {
			const suggestionId =
				$(emCollection[i]).attr('name').split(':').length > 3
					? $(emCollection[i]).attr('name').split(':')[2]
					: $(emCollection[i]).attr('name').split(':')[1];
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsData.push(suggestionId);
				suggestionsMap.set(suggestionId, 1);
			}
		}
		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(emCollection, suggestionsData, suggestionsMap);
		return suggestionsData;
	};

	const updateSuggestionsArray = (allSuggestions, htmlData) => {
		// if (htmlData.length === 0) {
		// 	return null;
		// }
		// const suggestionFromHtmlData = findSuggestionsInHtmData(htmlData);
		// const verifiedSuggestions = [];
		// for (const id in suggestionFromHtmlData) {
		// 	for (const allSuggestionId in allSuggestions) {
		// 		if (
		// 			suggestionFromHtmlData[id] ===
		// 			JSON.parse(allSuggestions[allSuggestionId]).id
		// 		) {
		// 			verifiedSuggestions.push(allSuggestions[allSuggestionId]);
		// 			break;
		// 		}
		// 	}
		// }

		// if (verifiedSuggestions.length > 0) {
		// 	return verifiedSuggestions;
		// } else {
		// 	return null;
		// }
	};

	const verifySuggestions = (previousSuggestions, latestSuggestions) => {
		if (!previousSuggestions && !latestSuggestions) {
			return true;
		}
		if (previousSuggestions && !latestSuggestions) {
			return false;
		}
		for (const data in latestSuggestions) {
			let currentIdFound = false;
			for (const revData in previousSuggestions) {
				if (
					JSON.parse(latestSuggestions[data]).id ===
					JSON.parse(previousSuggestions[revData]).id
				) {
					currentIdFound = true;
					break;
				}
			}
			if (!currentIdFound) {
				return false;
			}
		}
		return true;
	};

	const getApprovalStatus = (contribuotrSuggestion, reviewedSuggestions) => {
		if (!contribuotrSuggestion) {
			return true;
		}
		if (contribuotrSuggestion.length > reviewedSuggestions.length) {
			return false;
		}
		for (const contrId in contribuotrSuggestion) {
			for (const revId in reviewedSuggestions) {
				if (
					JSON.parse(contribuotrSuggestion[contrId]).id ===
					JSON.parse(reviewedSuggestions[revId]).id
				) {
					if (JSON.parse(reviewedSuggestions[revId]).state === 'open') {
						return false;
					}
				}
			}
		}
		return true;
	};

	const postData = {
		topicId: id,
		description: description,
		resultCalloutJson: descriptionSuggestions,
		// updateSuggestionsArray(reviewedDescriptionSuggestions, descriptionSuggestions),
		// descriptionSuggestions,
		// updateSuggestionsArray(
		// 	descriptionSuggestions,
		// 	description
		// ),
		resultReviewedJson: reviewedDescriptionSuggestions,
		topicVersion: topicDescriptionData.topicVersion,
		contributorCalloutJson: topicDescriptionData.calloutJson,
		title: topicDescriptionData.title,
		isEditable: true,
		lastContributorDescription: topicDescriptionData.lastContributorDescription,
	};

	useEffect(() => {
		track(2, 145, topicDescriptionData.topicId, topicDescriptionData.title);
	}, []);

	const checkContribution = (requestType) => {
		const rejectedSuggestionsIds = reviewedDescriptionSuggestions
			.filter(
				(suggestion) =>
					JSON.parse(suggestion).state === 'rejected' ||
					JSON.parse(suggestion).state === 'accepted'
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const contributedSuggestionsIds = Object.keys(
			contributorSuggestionsInfo
		).map((suggestion) => {
			return contributorSuggestionsInfo[suggestion].id;
		});
		const contributorsRejectedIds = contributedSuggestionsIds.filter((id) =>
			rejectedSuggestionsIds.includes(id)
		);
		if (requestType === 'submit') {
			if (contributorsRejectedIds.length > 0 || users.length > 1) {
				setShowRejectionReasonModal(true);
			} else {
				submitData();
			}
		} else {
			if (contributorsRejectedIds.length > 0) {
				return true;
			}
			return false;
		}
	};

	const addReviewerComment = () => {
		const commentsInfo = Object.keys(rejectionComments).map((id) => {
			const contributedSuggestions = Object.keys(
				contributorSuggestionsInfo
			).map((suggestion) => {
				return JSON.parse(contributorSuggestionsInfo[suggestion]);
			});
			const contributor = contributedSuggestions.filter((contributor) => { return contributor.id == id });
			return JSON.stringify({
				id: id,
				authorId: contributor[0].authorId,
				data: contributor[0].data,
				type: contributor[0].type,
				comment: rejectionComments[id],
			});
		});
		return commentsInfo;
	};

	const submitData = async () => {
		const editingtateId = await axiosGetService(
			`api/sdmtopics/getTopic2/${id}`
		);
		const imagesUrl = sessionStorage.getItem('crowdSourcing')
			? JSON.parse(sessionStorage.getItem('crowdSourcing'))
			: [];
		postData.imagePaths = [...topicDescriptionData.imagePaths, ...imagesUrl];
		if (editingtateId.data.stateId === 2) {
			setShowLoader(true);
			setLeavingWithoutSave(false);
			setSubmitMessage(messagesType['pendingApprovalForReviewer']);
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				successMessagePopUp: true,
				userLeaveWithSave: true,
			});
			setShowPreviewAndSubmitPopUp(false);
		} else if (editingtateId.data.stateId === 0) {
			if (postData.resultCalloutJson && postData.resultCalloutJson.length > 0) {
				setShowLoader(true);
				track(1, 204, topicDescriptionData.topicId, topicDescriptionData.title);
				postData.ratingPoints = null;
				await axiosPostService(`api/sdmtopics/addTopic2Reviewer`, postData)
					.then((response) => {
						setLeavingWithoutSave(false);
						setSubmitMessage(messagesType['reviewSubmissionMessage']);
						setCrowdSourcingAppState({
							...crowdSourcingAppState,
							successMessagePopUp: true,
						});
						sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
						setShowPreviewAndSubmitPopUp(false);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				toast.error(messagesType['noContributionMessage'], {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else if (editingtateId.data.stateId === 1) {
			const response = editingtateId.data;
			if (response.resultCalloutJson) {
				const descVerified = verifySuggestions(
					contributorDescSuggestions,
					response.resultCalloutJson
				);
				const checkDescSuggestionsStatus = getApprovalStatus(
					response.resultCalloutJson,
					reviewedDescriptionSuggestions
				);
				if (!descVerified) {
					setShowLoader(true);
					setLeavingWithoutSave(false);
					setSubmitMessage(messagesType['contentUpdate']);
					setCrowdSourcingAppState({
						...crowdSourcingAppState,
						reviewerError: true,
						successMessagePopUp: true,
						userLeaveWithSave: true,
					});
					setShowPreviewAndSubmitPopUp(false);
				} else if (!checkDescSuggestionsStatus) {
					toast.error(messagesType['reviewError'], {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					postData.resultReviewerComments = addReviewerComment();
					setShowLoader(true);
					track(
						1,
						204,
						topicDescriptionData.topicId,
						topicDescriptionData.title
					);
					postData.ratingPoints = usersRating;
					await axiosPostService(`api/sdmtopics/addTopic2Reviewer`, postData)
						.then(() => {
							setLeavingWithoutSave(false);
							setSubmitMessage(messagesType['reviewSubmissionMessage']);
							setCrowdSourcingAppState({
								...crowdSourcingAppState,
								successMessagePopUp: true,
							});
							sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
							setShowPreviewAndSubmitPopUp(false);
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}
		}
	};

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				if (type && type.split(' ')[0] === 'image') {
					if (typeof $(element).attr('style') !== 'undefined') {
						$(imgElement).attr({ style: $(element).attr('style') });
					} else {
						$(imgElement).attr({ style: ' width: 100%' });
					}
				} else if (type === 'media') {
					const iframeUrl = $(imgElement).attr('url');
					if (imgElement.children.length < 1) {
						imgElement.innerHTML = htmlSanitizer(
							`<iframe src=${iframeUrl} style="border:none;" width="640" height="360" arial-label="Preview" allowfullscreen="true"></iframe> `,
							config
						);
					}
				}
			}
		});
	});

	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					{showRejectionReasonModal ? (
						<RejectionReasonModal
							users={users}
							closeModal={closeModal}
							reviewedDescriptionSuggestions={reviewedDescriptionSuggestions}
							contributorSuggestionsInfo={contributorSuggestionsInfo}
							submit={submitData}
							rejectionComments={rejectionComments}
							setRejectionComments={setRejectionComments}
							currentUser={currentUser}
							postData={postData}
							ratingPoints={ratingPoints}
							usersRating={usersRating}
							setUsersRating={setUsersRating}
							checkRejection={checkContribution}
							enableGamification={enableGamification}
						/>
					) : (
						<Modal
							className="topicPreviewAndSubmitPopUp"
							show={modalShow}
							onHide={() => {
								/* Not calling any function to close modal*/
							}}
							dialogClassName="modal-90w"
						>
							<Modal.Header>
								<Modal.Title>
									Preview
									<div className="discardAndPreviewButton">
										<Button
											title="Cancel"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													closeModal();
													track(
														1,
														148,
														topicDescriptionData.topicId,
														topicDescriptionData.title
													);
												}
											}}
											onClick={() => {
												closeModal();
												track(
													1,
													148,
													topicDescriptionData.topicId,
													topicDescriptionData.title
												);
											}}
											className="activityDiscardButton"
										>
											Cancel
										</Button>
										<Button
											title="Submit"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													checkContribution('submit');
												}
											}}
											onClick={() => {
												checkContribution('submit');
											}}
											className="activityPreviewButton"
										>
											Submit
										</Button>
									</div>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="topicsCrowdSourcingContainer">
									<div class="topicsDescriptionDetails">
										<div className="topicDescriptionDiv">
											<div className="topicDescTitlePart">
												<span className="topicVersion">
													Document Version: {topicDescriptionData.topicVersion}
													,&nbsp;&nbsp;
													{`${topicDescriptionData.modifiedDate} `}
												</span>
											</div>
											<h4 className="topicTitle">
												{topicDescriptionData.title}
											</h4>
											<div class="existingHashTagsOfTopic">
												{topicDescriptionData.hashTagsList.map(({ name }) => (
													<span
														key={name}
														onClick={() => {
															history.push(
																`/search?searchParam=${encodeURIComponent(
																	name
																)}`
															);
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
																history.push(
																	`/search?searchParam=${encodeURIComponent(
																		name
																	)}`
																);
															}
														}}
													>
														{name}
													</span>
												))}
											</div>

											{description ? (
												<DescriptionCkEditor
													users={users}
													currentUser={currentUser}
													initialData={description}
													setContent={setPreviewActivityDescription}
													initalSuggestions={descriptionSuggestions}
													licenseKey={licenseKey}
												/>
											) : null}
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					)}
				</>
			)}
		</>
	);
}

export default PreviewAndSubmitPopUp;
