import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import loadable from '@loadable/component';
import $ from 'jquery';
import { MessageBar, MessageBarType } from '@fluentui/react';

import { useAuth } from '../../../context/UserAuthContext';
import { useAxios } from '../../../context/AxiosContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../context/TelemetryContext';
import Spinner from '../../../helpers/Spinner';
import TemporaryCkAlert from '../../CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../CkEditor/DecryptionComponent';
import { useConfig } from '../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../components/CkEditor/MyContributionTopics/ApproverContributions/TopicsContributionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

initializeIcons();

const TopicsMyContributionsPreview = ({
	topicDescriptionData,
	reviewerInfo,
	setReviewerInfo,
}) => {
	const { ckEdKEY } = useConfig();
	const { axiosGetService } = useAxios();
	const { user } = useAuth();
	const { track } = useAppInsights();
	const currentUser = topicDescriptionData
		? topicDescriptionData.approverEmail
		: null;
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const [reviewedDescriptionSuggestions, setReviewedDescriptionSuggestions] =
		useState([]);
	const [licenseKey, setLicenseKey] = useState(null);

	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();

	const getApprovedSuggestions = (suggestions) => {
		const approvedSuggestions = [];
		for (const id in suggestions) {
			const approvedData = {};
			approvedData['id'] = JSON.parse(suggestions[id]).id;
			approvedData['state'] = 'accepted';
			approvedSuggestions.push(JSON.stringify(approvedData));
		}
		return approvedSuggestions;
	};

	const updateSuggestionInfo = (
		suggestionInfo,
		reviewerSuggestions,
		suggestionsMapperData,
		suggestionsMap
	) => {
		const suggestionId =
			suggestionInfo.split(':').length > 3
				? suggestionInfo.split(':')[2]
				: suggestionInfo.split(':')[1];
		const type =
			suggestionInfo.split(':').length > 3
				? suggestionInfo.split(':').slice(0, 2).join(':')
				: suggestionInfo.split(':')[0];
		const userEmail =
			suggestionInfo.split(':').length > 3
				? suggestionInfo.split(':')[3]
				: suggestionInfo.split(':')[2];
		if (userEmail && !userDataMap.has(userEmail)) {
			graphUserData.push(userEmail);
			userDataMap.set(userEmail, 1);
		}
		if (!suggestionsMap.has(suggestionId)) {
			suggestionsMapperData[suggestionId] = userEmail;
			suggestionsMap.set(suggestionId, 1);
		}
		const suggestionsData = {
			id: suggestionId,
			type: type,
			hasComments: false,
			data: null,
			originalSuggestionId: null,
			authorId: userEmail,
			createdAt: Date.now(),
		};
		reviewerSuggestions.push(JSON.stringify(suggestionsData));
	};

	const getSuggestionsInfo = (
		emCollection,
		reviewerSuggestions,
		suggestionsMapperData,
		suggestionsMap
	) => {
		for (let i = 0; i < emCollection.length; i++) {
			if ($(emCollection[i]).attr('data-suggestion-end-after')) {
				const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-end-after'
				);
				updateSuggestionInfo(
					suggestionInfo,
					reviewerSuggestions,
					suggestionsMapperData,
					suggestionsMap
				);
			} else if ($(emCollection[i]).attr('data-suggestion-start-before')) {
				const suggestionInfo = $(emCollection[i]).attr(
					'data-suggestion-start-before'
				);
				updateSuggestionInfo(
					suggestionInfo,
					reviewerSuggestions,
					suggestionsMapperData,
					suggestionsMap
				);
			}
		}
	};

	const getContributor = (suggestions, htmlData) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
		const suggestionsMapperData = {};
		const domParser = new DOMParser();
		const docElement = domParser.parseFromString(
			htmlData,
			'text/html'
		).documentElement;
		let emCollection = docElement.getElementsByTagName('suggestion');
		const suggestionsMap = new Map();
		const reviewerSuggestions = [];
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionId = $(emCollection[i]).attr('id').split(':')[0];
			const userEmail = $(emCollection[i]).attr('id').split(':')[1];
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsMapperData[suggestionId] = userEmail;
				suggestionsMap.set(suggestionId, 1);
			}
			const suggestionsData = {
				id: suggestionId,
				type: $(emCollection[i]).attr('suggestion-type'),
				hasComments: false,
				data: null,
				originalSuggestionId: null,
				authorId: userEmail,
				createdAt: Date.now(),
			};
			reviewerSuggestions.push(JSON.stringify(suggestionsData));
		}
		emCollection = docElement.getElementsByTagName('suggestion-start');
		for (let i = 0; i < emCollection.length; i++) {
			const suggestionInfo = emCollection[i].attributes.name.value;
			const suggestionId =
				suggestionInfo.split(':').length > 3
					? suggestionInfo.split(':')[2]
					: suggestionInfo.split(':')[1];
			const type =
				suggestionInfo.split(':').length > 3
					? suggestionInfo.split(':').slice(0, 2).join(':')
					: suggestionInfo.split(':')[0];
			const userEmail =
				suggestionInfo.split(':').length > 3
					? suggestionInfo.split(':')[3]
					: suggestionInfo.split(':')[2];
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
			if (!suggestionsMap.has(suggestionId)) {
				suggestionsMapperData[suggestionId] = userEmail;
				suggestionsMap.set(suggestionId, 1);
			}
			const suggestionsData = {
				id: suggestionId,
				type: type,
				hasComments: false,
				data: null,
				originalSuggestionId: null,
				authorId: userEmail,
				createdAt: Date.now(),
			};
			reviewerSuggestions.push(JSON.stringify(suggestionsData));
		}
		emCollection = docElement.getElementsByTagName('figure');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('th');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('td');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('tr');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('table');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('tbody');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		emCollection = docElement.getElementsByTagName('p');
		getSuggestionsInfo(
			emCollection,
			reviewerSuggestions,
			suggestionsMapperData,
			suggestionsMap
		);
		topicDescriptionData.resultCalloutJson = reviewerSuggestions;
		return suggestionsMapperData;
	};

	const getUsersInfo = async () => {
		setReviewedDescriptionSuggestions(
			getContributor(
				topicDescriptionData.resultCalloutJson,
				topicDescriptionData.description
			)
		);
		topicDescriptionData.resultReviewedJson = getApprovedSuggestions(
			topicDescriptionData.resultCalloutJson
		);
		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
		}
		setCkEditorUsers(contributorInfo);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		if (topicDescriptionData) {
			getUsersInfo();
		}
	}, []);

	if (!topicDescriptionData) {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				There were no contributions from approver.
			</MessageBar>
		);
	}

	if (ckEditorUsers.length < 1 || !licenseKey) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}

	return (
		<div className="PreviewAndSubmitPopUp historicalPreview">
			<div className="topicsCrowdSourcingContainer">
				<div className="topicsDescriptionDetails">
					<div className="topicDescriptionDiv">
						<h4 className="topicTitle">{topicDescriptionData.topicName}</h4>
						<TemporaryCkAlert/>
						<DescriptionCkEditor
							users={ckEditorUsers}
							currentUser={currentUser}
							initialData={topicDescriptionData.description}
							initalSuggestions={topicDescriptionData.resultCalloutJson}
							reviewedSuggestions={topicDescriptionData.resultReviewedJson}
							licenseKey={licenseKey}
							suggestionsMapper={reviewedDescriptionSuggestions}
							comment={[]}
							previewType="contribution"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopicsMyContributionsPreview;
