import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';


const GroupDeleteModal = ({
    showDeleteGroupModal,
    setShowDeleteGroupModal,
    selectedDeleteGroupDetails
}) => {
    return (
        <>
            <Modal
                className="deletePopup"
                show={showDeleteGroupModal}
                onHide={() => {
                    setShowDeleteGroupModal(false);
                }}
                size="md"
                centered
            >
                <Modal.Header>
                    Delete {selectedDeleteGroupDetails.groupName} Group
                    <Icon
                        onClick={() => {
                            setShowDeleteGroupModal(false);
                        }}
                        iconName="Cancel"
                    />
                </Modal.Header>

                <Modal.Body>
                    <div>
                    Delete/delink activities/subgroups first.
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default GroupDeleteModal;
