import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';
import Spinner from '../../helpers/Spinner';

const SearchDropdown = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	updatedValue,
	setUpdatedValue,
	showSpinner,
	ariaLabel,
}) => {
	const [visible, setVisible] = useState(false);
	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);
	
	const handleDropdown = (item) => {
		if (updatedValue.some(({ id }) => id === item.id)) {
			const updatedData = updatedValue.filter(({ id }) => id !== item.id);
			setUpdatedValue(updatedData);
		} else {
			setUpdatedValue([...updatedValue, item]);
		}
	};

	const findSelectedTools = (checkId) => {
		const filterId = updatedValue.filter(({ id }) => id === checkId);
		if (filterId.length > 0) {
			return true;
		}
		return false;
	};
	const handleCheckboxKeyDown = (e, item, index) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault();
			handleDropdown(item);
		} else if (e.key === 'ArrowUp' && index > 0) {
			e.preventDefault();
			const prevIndex = index - 1;
			const prevItem = document.querySelectorAll('.dropDownList li')[prevIndex];
			prevItem.focus();
		} else if (e.key === 'ArrowDown' && index < data.length - 1) {
			e.preventDefault();
			const nextIndex = index + 1;
			const nextItem = document.querySelectorAll('.dropDownList li')[nextIndex];
			nextItem.focus();
		}else if (e.key === 'Tab') {
			e.preventDefault();
			const currentIndex = index;
        if (currentIndex === data.length - 1) {
            const dropdownInput = document.getElementById(className).querySelector('input');
            dropdownInput.focus();
        } else {
            const forwardButton = document.querySelector('.forwardButton');
            if (forwardButton) {
                forwardButton.focus();
            }
        }
		}
	};
	useEffect(() => {
		const handleDropdownEsc = (e) => {
		  if (e.key === 'Escape' && enableDropdown) {
			  setVisible(!visible);
		  }
		};
		window.addEventListener('keydown', handleDropdownEsc);
		return () => {
		  window.removeEventListener('keydown',handleDropdownEsc);
		}
	  }, [visible])
  
	
	return (
		<div title={enableDropdown ? className : ''} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => {
						if(e.target.value != ' '){
							setSearchValue(e.target.value)
						}
                        if(e.target.value.length>0){
                            setVisible(true);
                        }else{
                            setVisible(false);
                        }
                    }}
					onKeyPress={(e) => {
						if(e.key === 'Space' || e.key === 'Enter'){
							setVisible(true);
						}
					}}
					value={searchValue}
					placeholder={
						updatedValue.length > 0
							? `${updatedValue.length} selected`
							: `Type something to search or select from list`
					}
					aria-label={ariaLabel}
				/>
				<Icon
					iconName="ChevronDown"
					
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				/>
				{showSpinner && visible ? <Spinner /> : <></>}
				<div>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul role="listbox" aria-label={ariaLabel}>
							{data.map((item, index) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}

								return (
									<li 
									title={item.name} 
									tabIndex="0" 
									role="option" 
									key={item.id} 
									aria-selected={findSelectedTools(item.id)}
									onKeyDown={(e) => handleCheckboxKeyDown(e, item, index)}
									
									>
										<label className="NotificationChkboxContainer">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedTools(item.id)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchDropdown;
