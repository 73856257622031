import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAppInsights } from '../../context/TelemetryContext';

import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import { useAxios } from '../../context/AxiosContext';
import DescriptionCkEditor from '../CkEditor/ActivityHistoryTrackChanges/DescriptionTrackChanges';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import decryptAES256 from '../CkEditor/DecryptionComponent';
import { useConfig } from '../../context/ConfigContext';

import '../TopicsContributorCrowdSourcing/PreviewAndSubmitPopUp.css';

function HistoryPopUp({
	contriutorLastSuggestions,
	reviewedSuggestions,
	contributorContent,
	topicDescriptionData,
	users,
	currentUser,
}) {
	const { ckEdKEY } = useConfig();
	const history = useHistory();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { topicType, id } = useParams();
	const [licenseKey, setLicenseKey] = useState(null);
	const { axiosGetService } = useAxios();

	const [modalShow, setModalShow] = useState(true);

	const closeModal = () => {
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			showHistory: false,
		});
		setModalShow(false);
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		track(2, 147, topicDescriptionData.topicId, topicDescriptionData.title);
	}, []);

	return (
		<>
			{!licenseKey ? (
				<Spinner />
			) : (
				<Modal
					className="PreviewAndSubmitPopUp historicalPreview"
					show={modalShow}
					onHide={() => {
						/* Not calling any function to close modal*/
					}}
					dialogClassName="modal-90w"
				>
					<Modal.Header>
						<Modal.Title>
							Historical Preview
							<div className="discardAndPreviewButton">
								<Link
									to={`/topics/${topicDescriptionData.topicId}`}
								>
									<Icon
										ariaLabel="Close"
										iconName="Cancel"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												closeModal();
											}
										}}
										onClick={closeModal}
									/>
								</Link>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="topicsCrowdSourcingContainer">
							<div class="topicsDescriptionDetails">
								<div className="topicDescriptionDiv">
									<div className="topicDescTitlePart">
										<span className="topicVersion">
											Document Version: {topicDescriptionData.topicVersion}
											,&nbsp;&nbsp;
											{`${topicDescriptionData.modifiedDate} `}
										</span>
									</div>
									<h4 className="topicTitle">{topicDescriptionData.title}</h4>
									<div class="existingHashTagsOfTopic">
										{topicDescriptionData.hashTagsList.map(({ name }) => (
											<span
												key={name}
												onClick={() => {
													history.push(
														`/search?searchParam=${encodeURIComponent(name)}`
													);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														history.push(
															`/search?searchParam=${encodeURIComponent(name)}`
														);
													}
												}}
											>
												{name}
											</span>
										))}
									</div>

									<DescriptionCkEditor
										users={users}
										currentUser={currentUser}
										initialData={contributorContent}
										initalSuggestions={
											contriutorLastSuggestions ? contriutorLastSuggestions : []
										}
										reviewedSuggestions={reviewedSuggestions}
										licenseKey={licenseKey}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default HistoryPopUp;
