import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { toast } from 'react-toastify';

import ActivityDetails from './ActivityDetails';
import GroupAndSequence from './GroupAndSequence';
import { useAxios } from '../../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { hashtagValidator } from '../../../helpers/Validation';

const CreateActivity = ({
	showActivityBuilder,
	setShowActivityBuilder,
	activityGroups,
	setActivityGroups,
	solutionMethodId,
	phaseId,
	previousActivityGroups,
	setPreviousActivityGroups,
	projectTypeId,
	activityBuilderTabId,
	setActivityBuilderTabId,
	coOwners,
	getSolutionMethodData,
	getPhaseData,
	phaseType,
	parentId,
	setPhaseCreated,
	phaseCreated,
	getParentSolutionMethodData,
	setParentPhaseCreated,
	parentPhaseCreated,
	showSubgroupForm,
	setShowSubgroupForm,
	selectedGroupDetails,
	setSelectedGroupDetails,
	showSubgroupEditForm,
	setShowSubgroupEditForm,
	selectedSubgroupDetails,
	setSelectedSubgroupDetails,
	searchInput
}) => {
	const { track } = useAppInsights();
	const { axiosPostService, userAlias,axiosGetService } = useAxios();
	const [existingActivityTitles, setExistingActivityTitles] = useState([]);
	const [existingGroupNames, setExistingGroupNames] = useState([]);
	const [disableSaveAndContinue, setDisableSaveAndContinue] = useState(false);
	const { complexity } = useLeftMenuCollapse();
	const activityBuilderTab = [
		{
			id: 1,
			name: 'Activity Details',
		},
		{
			id: 2,
			name: 'Group & Sequence',
		},
	];
	const [selectedTab, setSelectedTab] = useState(activityBuilderTabId);
	const [expandedActivityGroups, setExpandedActivityGroups] = useState([]);
	const [showAddActivityForm, setShowAddActivityForm] = useState(false);

	const [activityTitle, setActivityTitle] = useState('');
	const [selectedGroup, setSelectedGroup] = useState({
		id: 0,
		name: 'Add to a group',
	});
	const [addToGroup, setAddToGroup] = useState(false);
	const [activityTitleError, setActivityTitleError] = useState(false);
	const [hashtags, setHashtags] = useState([]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [activityLevelApprovers, setActivityLevelApprovers] = useState([]);
	const [activityLevelreviewers, setActivityLevelReviewers] = useState([]);
	const [activityLevelOwner, setActivityLevelOwner] = useState({});
	const [activityLevelCoOwner, setActivityLevelCoOwner] = useState([]);
	const [groupLevelApprovers, setGroupLevelApprovers] = useState([]);
	const [groupLevelReviewers, setGroupLevelReviewers] = useState([]);
	const [solutionMethodsData, setSolutionMethodsData] = useState({});
	const [phasesData, setPhasesData] = useState({});
	const [isEnableComplexity,setIsEnableComplexity] = useState();
	const [dynamicComplexities,setDynamicComplexities] = useState([]);
	const [selectedComplexityId,setSelectedComplexityId] = useState();
	const [solutionMethodDeafultComplexity, setSolutionMethodDeafultComplexity] = useState();
	useEffect(()=>{
		getMethodDetails();
	},[])
	const getMethodDetails = async () => {
	await axiosGetService(
		`api/sdmsolutionmethod/getsolutionmethod?solutionMethodId=${solutionMethodId}`
	).then((response) => {
		setIsEnableComplexity(response.data.isComplexity)
	})}

	useEffect(() => {
		if (solutionMethodDeafultComplexity) {
			getComplexityList();
		}
	}, [solutionMethodDeafultComplexity]);

	useEffect(() => {
        const fetchDefaultComplexity = async () => {
            await getDefaultdynamiccomplexity();
        };

        fetchDefaultComplexity();
    }, []);

	const getDefaultdynamiccomplexity = async () => {
        await axiosGetService(`api/sdmsolutionmethod/defaultdynamiccomplexity?solutionMethodId=${solutionMethodId}`).then(
			(response) => {
				const data = JSON.parse(response.data)
                setSolutionMethodDeafultComplexity(data)
            }
        );
    };
	
	const getComplexityList = async () => {
		await axiosGetService(`api/activities/projectdynamiccomplexity?solutionMethodId=${solutionMethodId}`).then(
			(response) => {
				const complexityToSelect = response.data.find(complexity => complexity.id === solutionMethodDeafultComplexity.id);
				setDynamicComplexities(response.data);
				setSelectedComplexityId(complexityToSelect);
			}
		)}
		
	const updateGroup = (activityResponse) => {
		if (selectedGroup.id !== 0 && addToGroup) {
			const updatedGroup = [];
			for (const group of activityGroups) {
				if (group.groupId === selectedGroup.id) {
					group.activities = [
						...group.activities,
						{
							activityId: activityResponse.activityId,
							activityTitle: activityTitle,
							isParentActivity: phaseType === 'parentPhase' ? true : false,
							activityIdentifier: activityResponse.activityIdentifier,
							complexityIds: activityResponse.complexityIds,
							complexityList: activityResponse.complexityList,
							isDrafted: true,
							activityToolsList: null,
							id: activityResponse.id,
							dragNDropId: uniqueIdGenerator(),
							isMethodOwnerOrCoOwner: true,
						},
					];
				}
				updatedGroup.push(group);
			}
			setActivityGroups([...updatedGroup]);
			setPreviousActivityGroups([...updatedGroup]);
		} else {
			const activityDetails = {
				groupId: 0,
				groupName: null,
				isParentActivity: phaseType === 'parentPhase' ? true : false,
				isDrafted: true,
				activities: [
					{
						activityId: activityResponse.activityId,
						activityTitle: activityTitle,
						isParentActivity: phaseType === 'parentPhase' ? true : false,
						activityIdentifier: activityResponse.activityIdentifier,
						complexityIds: activityResponse.complexityIds,
						complexityList: activityResponse.complexityList,
						isDrafted: true,
						activityToolsList: null,
						id: activityResponse.id,
						dragNDropId: uniqueIdGenerator(),
						isMethodOwnerOrCoOwner: true,
					},
				],
			};
			setActivityGroups([...activityGroups, activityDetails]);
			setPreviousActivityGroups([...previousActivityGroups, activityDetails]);
		}
	};

	const saveActivityDetails = async (type) => {
		if (activityTitle.trim().length === 0) {
			setActivityTitleError(true);
		} else {
			setDisableSaveAndContinue(true);
			setActivityTitleError(false);
			const postData = {
				title: activityTitle,
				groupId: selectedGroup.id !== 0 && addToGroup ? selectedGroup.id : 0,
				complexityId:isEnableComplexity?selectedComplexityId.id:complexity.id,
				isDrafted: true,
				createdBy: userAlias,
				parentPhaseId: phaseId,
				solutionMethodId:
					phaseType === 'parentPhase' ? parentId : solutionMethodId,
				hashTags: userAddedHashtags.toString(),
				reviewerMembersList: activityLevelreviewers.map(({ mail }) => {
					return mail;
				}),
				approverMembersList: activityLevelApprovers.map(({ mail }) => {
					return mail;
				}),
				coOwnerMembersList: activityLevelCoOwner.map(({ mail }) => {
                    return mail;
				}),
				owner: activityLevelOwner.mail,
				isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
			};
			await axiosPostService(`api/sdmactivity/adddraft`, postData).then(
				(response) => {
					if (response.data.status) {
						track(1, 904, response.data.id, activityTitle, {
							phaseId: phaseId,
						});
						updateGroup(response.data);
						if (type === 'continue') {
							setSelectedTab(selectedTab + 1);
						}
						setUserAddedHashtags([]);
						changeToDeafultState();
						toast.info(`You have successfully added the activity`, {
							position: toast.POSITION.TOP_RIGHT,
						});
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
					setDisableSaveAndContinue(false);
				}
			);
		}
	};

	const cloneActivity = (activities) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
			}
		}
		return activityClone;
	};

	const cloneGroup = (data, updateType) => {
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group]),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
				};
				activityGroupClone.push(groupClone);
			}
		}
		setActivityGroups([...activityGroupClone]);
		setPreviousActivityGroups([...activityGroupClone]);
		if (selectedTab === 2 && updateType === 'sequence') {
			if (phaseType === 'parentPhase') {
				getParentSolutionMethodData(parentId, coOwners);
				setParentPhaseCreated(!parentPhaseCreated);
			} else {
				getSolutionMethodData(coOwners, 'update');
				setPhaseCreated(!phaseCreated);
			}
			setShowActivityBuilder(false);
			setActivityBuilderTabId(1);
		}
	};

	const saveGroupSequencing = async () => {
		setDisableSaveAndContinue(true);
		const updatedSequenceIds = [];
		for (const group of activityGroups) {
			if (group.isSolutionMethod) {
				updatedSequenceIds.push(`0-0-0-0-${group.solutionMethodId}`);
			} else if (group.activities.length > 0) {
				for (const activity of group.activities) {
					if (activity.isSubgroup) {
						if (activity.activities.length > 0) {
							for (const subgroupActivity of activity.activities) {
								updatedSequenceIds.push(
									`${
										(subgroupActivity.id ? subgroupActivity.id : 0) +
										'-' +
										(subgroupActivity.activityId
											? subgroupActivity.activityId
											: 0) +
										'-' +
										(group.groupId ? group.groupId : 0) +
										'-' +
										activity.subGroupId +
										'-0'
									}`
								);
							}
						} else {
							updatedSequenceIds.push(
								`${
									'0-' +
									'0-' +
									(group.groupId ? group.groupId : 0) +
									'-' +
									activity.subGroupId +
									'-0'
								}`
							);
						}
					} else {
						updatedSequenceIds.push(
							`${
								(activity.id ? activity.id : 0) +
								'-' +
								(activity.activityId ? activity.activityId : 0) +
								'-' +
								(group.groupId ? group.groupId : 0) +
								'-0' +
								'-0'
							}`
						);
					}
				}
			} else {
				updatedSequenceIds.push(`0-0-${group.groupId}-0-0`);
			}
		}
		const postData = {
			parentPhaseId: phaseId,
			modifiedBy: userAlias,
			sequenceActivityIds: updatedSequenceIds.toString(),
			solutionMethodId:
				phaseType === 'parentPhase' ? parentId : solutionMethodId,
			complexityId: complexity.id,
			isRightSolutionMethod: phaseType === 'parentPhase' ? true : false,
		};
		axiosPostService(`api/sdmactivity/updateactivitysequence`, postData).then(
			(response) => {
				if (response.data.status) {
					track(1, 907, phaseId, 'Sequence Update');
					cloneGroup(response.data.activityGroupsData, 'sequence');
					toast.info(`You have successfully updated the sequence`, {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
				setDisableSaveAndContinue(false);
			}
		);
	};

	const createOrUpdateGroups = async (type, groupDetails, deafultState) => {
		setDisableSaveAndContinue(true);
		if (type !== 'subgroupUpdate') {
			groupDetails[0].phaseId = phaseId;
			groupDetails[0].solutionMethodId =
				phaseType === 'parentPhase' ? parentId : solutionMethodId;
			groupDetails[0].complexityId = complexity.id;
			groupDetails[0].reviewerMembersList = groupLevelReviewers.map(
				({ mail }) => {
					return mail;
				}
			);
			groupDetails[0].approverMembersList = groupLevelApprovers.map(
				({ mail }) => {
					return mail;
				}
			);
			groupDetails[0].isRightSolutionMethod =
				phaseType === 'parentPhase' ? true : false;
		} else {
			groupDetails[0].phaseId = phaseId;
			groupDetails[0].solutionMethodId =
				phaseType === 'parentPhase' ? parentId : solutionMethodId;
			groupDetails[0].complexityId = complexity.id;
			groupDetails[0].isRightSolutionMethod =
				phaseType === 'parentPhase' ? true : false;
		}
		for (const group of groupDetails) {
			group.phaseId = phaseId;
		}
		await axiosPostService(
			`api/sdmactivity/addDraftedActivityGroup`,
			groupDetails
		).then((response) => {
			if (response.data.status) {
				track(
					1,
					type !== 'update' ? 905 : 906,
					'Group',
					groupDetails[0].groupName,
					{
						phaseId: phaseId,
					}
				);
				toast.info(`You have successfully updated the group`, {
					position: toast.POSITION.TOP_RIGHT,
				});
				cloneGroup(response.data.activityGroupsData, 'groups');
				deafultState();
			} else {
				toast.error(response.data.errorMessage, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			setDisableSaveAndContinue(false);
		});
	};

	const changeToDeafultState = () => {
		setSelectedGroup({ id: 0, name: 'Add to a group' });
		setActivityTitle('');
		setAddToGroup(false);
		setHashtagsInput('');
		setHashtags([]);
		setGroupLevelReviewers([]);
		setGroupLevelApprovers([]);
		setActivityLevelReviewers([]);
		setActivityLevelApprovers([]);
		setActivityLevelOwner([])
		setActivityLevelCoOwner([])
	};

	const matchActivityComplexityId = (complexityList) => {
		for (const id in complexityList) {
			if (complexityList[id] === complexity.id) {
				return true;
			}
		}
		return false;
	};

	const filterData = (value, setValue) => {
		const filteredGroups = [];
		for (const group of value) {
			if (group.activities.length > 0) {
				const filteredActivities = [];
				for (const activity of group.activities) {
					// if (matchActivityComplexityId(activity.complexityList) || ) {
					filteredActivities.push(activity);
					// }
				}
				group.activities = filteredActivities;
				filteredGroups.push(group);
			} else {
				filteredGroups.push(group);
			}
		}
		setValue(filteredGroups);
	};
	const updateExistingActivityTitle = () => {
		const activityTitles = [];
		const groupNames = [];
		for (const group of activityGroups) {
			for (const activity of group.activities) {
				if (!activity.isSubgroup && activity.activityTitle) {
					activityTitles.push(activity.activityTitle.trim().toLowerCase());
				}
			}
			if (group.groupName) {
				groupNames.push(group.groupName.trim().toLowerCase());
			}
		}
		setExistingActivityTitles(activityTitles);
		setExistingGroupNames(groupNames);
	};

	const activityMenu = {
		1: (
			<ActivityDetails
				activityGroups={activityGroups}
				setActivityGroups={setActivityGroups}
				showAddActivityForm={showAddActivityForm}
				setShowAddActivityForm={setShowAddActivityForm}
				activityTitle={activityTitle}
				setActivityTitle={setActivityTitle}
				selectedGroup={selectedGroup}
				setSelectedGroup={setSelectedGroup}
				addToGroup={addToGroup}
				setAddToGroup={setAddToGroup}
				activityTitleError={activityTitleError}
				setActivityTitleError={setActivityTitleError}
				saveActivityDetails={saveActivityDetails}
				changeToDeafultState={changeToDeafultState}
				projectTypeId={projectTypeId}
				solutionMethodId={solutionMethodId}
				phaseId={phaseId}
				existingActivityTitles={existingActivityTitles}
				setDisableSaveAndContinue={setDisableSaveAndContinue}
				hashtags={hashtags}
				setHashtags={setHashtags}
				hashtagsInput={hashtagsInput}
				setHashtagsInput={setHashtagsInput}
				userAddedHashtags={userAddedHashtags}
				setUserAddedHashtags={setUserAddedHashtags}
				hashtagSuggestionInput={hashtagSuggestionInput}
				setHashtagSuggestionInput={setHashtagSuggestionInput}
				type={phaseType}
				parentId={parentId}
				disableSaveAndContinue={disableSaveAndContinue}
				approvers={activityLevelApprovers}
				setApprovers={setActivityLevelApprovers}
				reviewers={activityLevelreviewers}
				setReviewers={setActivityLevelReviewers}
                owner={activityLevelOwner}
				setOwner={setActivityLevelOwner}
				coOwners={activityLevelCoOwner}
				setCoOwners={setActivityLevelCoOwner}
				complexityId={complexity.id}
				isEnableComplexity={isEnableComplexity}
				dynamicComplexities={dynamicComplexities}
				selectedComplexityId={selectedComplexityId}
				setSelectedComplexityId={setSelectedComplexityId}
			/>
		),
		2: (
			<GroupAndSequence
				activityGroups={activityGroups}
				setActivityGroups={setActivityGroups}
				expandedActivityGroups={expandedActivityGroups}
				setExpandedActivityGroups={setExpandedActivityGroups}
				createOrUpdateGroups={createOrUpdateGroups}
				coOwners={coOwners}
				userAlias={userAlias}
				existingGroupNames={existingGroupNames}
				setDisableSaveAndContinue={setDisableSaveAndContinue}
				phaseType={phaseType}
				parentId={parentId}
				disableSaveAndContinue={disableSaveAndContinue}
				approvers={groupLevelApprovers}
				setApprovers={setGroupLevelApprovers}
				reviewers={groupLevelReviewers}
				setReviewers={setGroupLevelReviewers}
				showSubgroupForm={showSubgroupForm}
				setShowSubgroupForm={setShowSubgroupForm}
				selectedGroupDetails={selectedGroupDetails}
				setSelectedGroupDetails={setSelectedGroupDetails}
				showSubgroupEditForm={showSubgroupEditForm}
				setShowSubgroupEditForm={setShowSubgroupEditForm}
				selectedSubgroupDetails={selectedSubgroupDetails}
				setSelectedSubgroupDetails={setSelectedSubgroupDetails}
				projectTypeId={projectTypeId}
				solutionMethodId={solutionMethodId}
				phaseId={phaseId}
				searchInput={searchInput}
				phasesData={phasesData}
				setPhasesData={setPhasesData}
				solutionMethodsData={solutionMethodsData}
				setSolutionMethodsData={setSolutionMethodsData}
			/>
		),
	};

	useEffect(() => {
		filterData(previousActivityGroups, setPreviousActivityGroups);
		filterData(activityGroups, setActivityGroups);
	}, []);

	useEffect(() => {
		updateExistingActivityTitle();
	}, [activityGroups]);

	return (
		<Modal
			className="phaseCreationRightPopup activityBuilderModal"
			show={showActivityBuilder}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<Modal.Title>Activity Builder </Modal.Title>
				<Icon
					iconName="ChromeClose"
					title="Close"
					onClick={() => {
						if (phaseType === 'parentPhase') {
							getParentSolutionMethodData(parentId, coOwners);
						} else {
							getSolutionMethodData(coOwners, 'update');
						}
						getPhaseData();
						setShowActivityBuilder(false);
						setActivityBuilderTabId(1);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							if (phaseType === 'parentPhase') {
								getParentSolutionMethodData(parentId, coOwners);
							} else {
								getSolutionMethodData(coOwners, 'update');
							}
							getPhaseData();
							setShowActivityBuilder(false);
							setActivityBuilderTabId(1);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Close Activity Builder"
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu" role='tablist'>
									{activityBuilderTab.map((tab) => (
										<li
											onClick={() => setSelectedTab(tab.id)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedTab(tab.id);
												}
											}}
											tabIndex={0}
											key={tab.id}
											aria-label={
												selectedTab === tab.id
													? `${tab.name}`
													: `${tab.name} not selected`
											}
											aria-selected={selectedTab === tab.id ? 'true' : 'false'}
											role="tab"
											className={
												selectedTab === tab.id ? 'activePhaseTab' : null
											}
										>
											<span>
												{tab.name}
											</span>
										</li>
									))}
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div id="adjustWidthId" className="methodOwnerMiddleContainer">									
									{activityMenu[selectedTab]}
								</div>
							</div>
							<div id="SaveAndBackId" className="saveContinueDetailsContainer">
								<button
									disabled={selectedTab === 1}
									onClick={() => setSelectedTab(selectedTab - 1)}
									className="backwardButton"
									onKeyPress={(e) => {
										if (e.which === 13) {
											setSelectedTab(selectedTab - 1);
										}
									}}
								>
									<span className="backDetailsButton">
										<Icon iconName="ChromeBack" />
										Back
									</span>
								</button>
								<button
									onClick={() => {
										if (selectedTab < 2) {
											if (
												userAddedHashtags.length > 0 ||
												addToGroup ||
												activityTitle
											) {
												saveActivityDetails('continue');
											} else {
												setSelectedTab(2);
												setActivityTitleError(false);
											}
										} else if (selectedTab === 2) {
											saveGroupSequencing();
										}
									}}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											if (selectedTab < 2) {
												if (
													userAddedHashtags.length > 0 ||
													addToGroup ||
													activityTitle
												) {
													saveActivityDetails('continue');
												} else {
													setSelectedTab(2);
													setActivityTitleError(false);
												}
											} else if (selectedTab === 2) {
												saveGroupSequencing();
											}
										}
									}}
									disabled={disableSaveAndContinue}
									className="forwardButton"
								>
									<span className="saveContinueButton">
										Save & Continue <Icon iconName="ChromeBackMirrored" />
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CreateActivity;
