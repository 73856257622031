import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';

const ProceduresTabFilter = ({
	data,
	artifactTypeName,
	value,
	setValue,
	artifactTypeId,
}) => {
	const { track } = useAppInsights();
	const [showMore, setShowMore] = useState(false);
	const [showLessData, setShowLessData] = useState([]);

	useEffect(() => {
		if (data.length > 5 && !showMore) {
			setShowLessData(data.slice(0, 5));
		} else {
			setShowLessData(data);
		}
	}, [data]);

	const handleCheckbox = (item) => {
		const dynamicFilter = Object.keys(value);
		if (dynamicFilter.includes(artifactTypeId.toString())) {
			if (value[artifactTypeId].selectedTagId.includes(item.id)) {
				const updatedProcessGroupFilterTag = value[
					artifactTypeId
				].selectedTagId.filter((id) => id !== item.id);
				const updatedValue = {
					artifactTypeId: artifactTypeId,
					artifactTypeName: artifactTypeName,
					selectedTagId: [...updatedProcessGroupFilterTag],
				};
				setValue({ ...value, [artifactTypeId]: updatedValue });
			} else {
				const updatedValue = {
					artifactTypeId: artifactTypeId,
					artifactTypeName: artifactTypeName,
					selectedTagId: [...value[artifactTypeId].selectedTagId, item.id],
				};
				setValue({ ...value, [artifactTypeId]: updatedValue });
			}
		} else {
			const updatedValue = {
				artifactTypeId: artifactTypeId,
				artifactTypeName: artifactTypeName,
				selectedTagId: [item.id],
			};
			setValue({ ...value, [artifactTypeId]: updatedValue });
		}
	};
	return (
		<>
			{data.length !== 0 && data.length > 5 ? (
				<ul>
					{showLessData.map((item) => (
						<li key={item.id}>
							<label className="NotificationChkboxContainer">
								<input
									name={artifactTypeName}
									type="checkbox"
									checked={
										value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false
									}
									onChange={() => {
										handleCheckbox(item);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleCheckbox(item);
										}
									}}
								/>
								<span className="CustomCheckmark"></span>
								<span className="filterLabel">{item.name}</span>
							</label>
							<div class="tabsTitleToolTip">
								<span class="tabsToolTipArrow"></span>
								{item.toolTip.split('\\n').map((val) => (
									<span>{val}</span>
								))}
							</div>
						</li>
					))}
					{showMore ? (
						<li>
							<p
								onClick={() => {
									setShowLessData(data.slice(0, 5));
									setShowMore(!showMore);
								}}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowLessData(data.slice(0, 5));
										setShowMore(!showMore);
									}
								}}
								role="button"
								aria-label="Show Less"
							>
								- Show Less
							</p>
						</li>
					) : (
						<li>
							<p
								onClick={() => {
									setShowLessData([...data]);
									setShowMore(!showMore);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowLessData([...data]);
										setShowMore(!showMore);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label={data.length > 5 ? `${data.length - 5} More` : ''}
							>
								+ {data.length > 5 ? `${data.length - 5} More` : <></>}
							</p>
						</li>
					)}
				</ul>
			) : data.length !== 0 && data.length <= 5 ? (
				<ul>
					{data.map((item) => (
						<li key={item.id}>
							<label className="NotificationChkboxContainer">
								<input
									name={artifactTypeName}
									type="checkbox"
									checked={
										value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false
									}
									onChange={() => {
										handleCheckbox(item);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleCheckbox(item);
										}
									}}
								/>
								<span className="CustomCheckmark"></span>
								<span className="filterLabel">{item.name}</span>
							</label>
							<div class="tabsTitleToolTip">
								<span class="tabsToolTipArrow"></span>
								{item.toolTip.split('\\n').map((val) => (
									<span>{val}</span>
								))}
							</div>
						</li>
					))}
				</ul>
			) : (
				<p className="noFiltersFound">No {artifactTypeName} available</p>
			)}
		</>
	);
};
export default ProceduresTabFilter;
