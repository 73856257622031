import React from 'react';
import { useAppInsights } from '../../context/TelemetryContext';

import './SiteDown.css';
function SiteDownPage() {
	const { track } = useAppInsights();
	return (
		<div className="siteDownMainContainer">
			<div className="siteDownContainer">
				<span className="downTimeImage"></span>
				<span className="technicalSiteDown"></span>
				<p>
					<h6>Technical issues</h6>
				</p>
				<p>
					For support please contact{' '}
					<a
						onClick={() => track(1, 15000, 'SDMPlus support team', 'SDMPlus support team')}
						href="mailto:SDMSupport@microsoft.com"
					>
						SDMPlus support team
					</a>{' '}
					or raise a&nbsp;
					<a
						onClick={() =>
							track(1, 15001, 'SDMPlus support ticket raise', 'SDMPlus support ticket raise')
						}
						href="https://aka.ms/sdmsupport"
						target="_blank"
					>
						support request
					</a>
					.
				</p>
			</div>
		</div>
	);
}

export default SiteDownPage;
