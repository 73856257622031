import React from "react";
import dompurify from "dompurify";

const TaskHistory = ({
  taskData,
  toolsSidebarHeight,
  taskSuggestions,
  ckEditorUserMap,
  reviewedTaskSuggestions,
  task,
  reorderedTaskReviewedSuggestions,
  reorderHistory
  // currentTab,
  // setCurrentTab,
}) => {

	const htmlSanitizer = (data) => {
		const config = {
		  ADD_ATTR: [ 'target'],
		  };
		return dompurify.sanitize(data, config);
	  };
 
	  const getReviewStatus = (task, cardclass) => {
    if (reviewedTaskSuggestions.length > 0) {
      const status = JSON.parse(
        reviewedTaskSuggestions.filter(
          (item) => JSON.parse(item).id === task.id
        )[0]
      ).state;
      if (cardclass) {
        if (status === "accepted") {
          return "Approved";
        } else {
          return "Rejected";
        }
      } else {
        return status;
      }
    }
    return null;
  };

  const getReorderedReviewStatus = (task)=>{
	if(task !== null){
		const status = task.state
		if(status === 'rejected'){
			return 'Approved'
		} else {
			return 'Rejected'
		}
	} else{
		return null
	}

  }
  function removePTags(html) {
    // Replace all <p> tags with an empty string
    return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }

	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer">
					<div className="">
						<div class="criteria-history-tab-container">
							
							<div>
								<ul>
									{taskData.map(
										(item) =>
											//JSON.parse(item).suggestionType === 'none' ? (
											!taskSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											 (
											<li className={'none'} key={JSON.parse(item.id)} dangerouslySetInnerHTML={{ __html: htmlSanitizer(removePTags(item.name)) }}>
											</li>
											

											)
									)}
									

									{taskSuggestions.map(
										(item) =>
										(
												<li
													className={`${
														JSON.parse(item).suggestionType === 'deletion'
															? 'userDeletedCriteria'
															: 'userAddedCriteria'
													}`}
													key={JSON.parse(item).id}
												>
													{JSON.parse(item).name}
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="" style={{ height: toolsSidebarHeight }}> */}
					<div
						style={{ height: toolsSidebarHeight }}
						className='toolsSidebar'
					>
						{reorderedTaskReviewedSuggestions.length !== 0 && (
							<>
							<div
								className={`sideBarCard ${
									JSON.parse(reorderedTaskReviewedSuggestions).state === 'rejected'
										? 'userDeletedToolsAndGuidance'
										: 'userAddedItem'
								} ${JSON.parse(reorderedTaskReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}`}
							>
								<div className="sideBarCardHeader">
									<img
										className="userImg"
										src={reorderHistory.isTaskReorder && JSON.parse(reorderHistory.isTaskReorder[0]).authorId[1]}
										alt=""
									/>
									<span>{reorderHistory.isTaskReorder && JSON.parse(reorderHistory.isTaskReorder[0]).authorId[0]}</span>
									<span className="suggestionStatus myContributionSuggestionStatus">
										{JSON.parse(reorderedTaskReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}
									</span>
								</div>
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description:"
									<div
									> Tasks has been reordered </div>
									
								</div>
							</div>
						</>

						)}
						{taskSuggestions.map(
							(card) => (
								 (
							<>
								<div
									className={`sideBarCard ${
										JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
									} ${getReviewStatus(JSON.parse(card), false)}`}
								>
									<div className="sideBarCardHeader">
										<img
											className="userImg"
											src={ckEditorUserMap[JSON.parse(card).authorId][1]}
											alt=""
										/>
										<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
										<span className="suggestionStatus myContributionSuggestionStatus">
											{getReviewStatus(JSON.parse(card), true)}
										</span>
									</div>
									<div className="itemStatus">
										<span>
											{JSON.parse(card).suggestionType === 'deletion'
												? 'Remove'
												: JSON.parse(card).suggestionType === 'insertion'
												? 'Add'
												: 'Edit'}
										</span>
										&nbsp;"Description:"
										<div
                                          dangerouslySetInnerHTML={{ __html: removePTags(JSON.parse(card).name) }}
                                        />
										
									</div>
								</div>
							</>
						)))}
					</div>
				{/* </div> */}
			</div>
		</>
	);
};

export default TaskHistory;
