import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import $ from 'jquery';

function FeedbackModal({ enableFeedbackModal, closeModal }) {
	const { track } = useAppInsights();
	const location = window.location.pathname + window.location.search;
	const { axiosPostService } = useAxios();
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
	const [feedbackData, setFeedbackData] = useState(null);
	const [feedbackMessage, setFeedbackMessage] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const handleFeedbackClick = (selectedFeedback) => {
		if (feedbackData !== selectedFeedback) {
			setFeedbackData(selectedFeedback);
		} else {
			setFeedbackData(null);
		}
	};

	const handleSubmit = async () => {
		let data;

		if (feedbackData !== null) {
			const postBody = {
				feedbackType: feedbackData == 'Yes' ? 1 : 2,
				feedbackComments: feedbackMessage,
				url: location,
			};

			const regexp = /^[a-zA-Z0-9\s\.()\d\w,:;""''_\-\*]+$/;
			const checkError =
				feedbackMessage.trim().length > 0
					? regexp.test(postBody.feedbackComments)
					: true;
			if (checkError) {
				await axiosPostService(`api/sdmfeedback/insert`, postBody).then(
					(response) => {

						data = response.data;
						track(
							1,
							150,
							'feedbackSubmit',
							'favorite & feedback_feedbackSubmit',
							{
								feedbackType: postBody.feedbackType,
								feedbackComments: postBody.feedbackComments,
							}
						);
					}
				);

				if (data == null) {
					setInputError('inputerror');
					setErrorMessage('Error occurred when providing the feedback');
				} else if (data.status == 'false') {
					setInputError('inputerror');
					setErrorMessage(data.errorMessage);
				} else {
					setFeedbackSubmitted(true);
				}
			} else {
				setInputError('inputerror');
				setErrorMessage('Input string is not in correct format');
			}
		} else {
			setError(true);
		}
	};

	useEffect(() => {
		return () => setFeedbackSubmitted(false);
	}, []);

	  useEffect(() => {
		$('#closeButton').focus();
	  }, [enableFeedbackModal]);
	return (
		<Modal
			show={enableFeedbackModal}
			onHide={loading ? null : closeModal}
			centered
			size="sm"
			className="templateFeedbackPopUp"
		>
			{loading ? (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			) : feedbackSubmitted ? (
				<>
					<Modal.Body>
						<p>Your feedback was submitted successfully</p>
						<button
							tabIndex={0}
							onKeyPress={(e) => {
								if (Number(e.which) === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							className="feedbackSubmittedOk"
						>
							OK
						</button>
					</Modal.Body>
				</>
			) : (
				<>
					<Modal.Header >
						<span id='feedbackmodel' tabIndex={0} role='dialog'>Your Opinion Matters</span>
						<Icon
							id='closeButton'
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (Number(e.which) === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							aria-label="Close Feedback modal"
							role="button"
							title="close"
						/>
					</Modal.Header>
					<Modal.Body>
						<p> Please provide your feedback</p>
						<div className="templateFeedbackPopUpDiv">
							<div
								className="feedbackYesDiv"
								tabIndex={0}
								onKeyPress={(e) => {
									if (Number(e.which) === 13) {
										handleFeedbackClick('Yes');
									}
								}}
								role="button"
								onClick={() => handleFeedbackClick('Yes')}
							>
								{feedbackData === null || feedbackData !== 'Yes' ? (
									<span
										title="like"
										aria-label="Like"
										className="feedbackThumbUp"
										onKeyPress={(e) => {
											if (Number(e.which) === 13) {
												handleFeedbackClick('Yes');
											}
										}}
										role="button"
										onClick={() => handleFeedbackClick('Yes')}
									></span>
								) : (
									<span
										title="liked"
										aria-label="Liked"
										className="feedbackThumbUpOrange"
										onKeyPress={(e) => {
											if (Number(e.which) === 13) {
												handleFeedbackClick('Yes');
											}
										}}
										role="button"
										onClick={() => handleFeedbackClick('Yes')}
									></span>
								)}
								<span>Yes</span>
							</div>
							<div
								className="feedbackNoDiv"
								tabIndex={0}
								onKeyPress={(e) => {
									if (Number(e.which) === 13) {
										handleFeedbackClick('No');
									}
								}}
								onClick={() => handleFeedbackClick('No')}
							>
								{feedbackData === null || feedbackData !== 'No' ? (
									<span
										title="dislike"
										aria-label="dislike"
										role="button"
										onKeyPress={(e) => {
											if (Number(e.which) === 13) {
												handleFeedbackClick('No');
											}
										}}
										onClick={() => handleFeedbackClick('No')}
										className="feedbackThumbDown"
									></span>
								) : (
									<span
										title="disliked"
										aria-label="disliked"
										role="button"
										className="feedbackThumbDownOrange"
										onKeyPress={(e) => {
											if (Number(e.which) === 13) {
												handleFeedbackClick('No');
											}
										}}
										onClick={() => handleFeedbackClick('No')}
									></span>
								)}
								<span>No</span>
							</div>
						</div>
						<textarea
							placeholder="Tell us why? (optional)"
							value={feedbackMessage}
							onChange={(e) => setFeedbackMessage(e.target.value)}
							maxLength={2000}
						/>
						{error ? (
							<span className="errorMsg">Please select your opinion</span>
						) : (
							<></>
						)}
						{inputError == 'inputerror' ? (
							<span className="errorMsg">{errorMessage}</span>
						) : (
							<></>
						)}
						<div>
							<button
								tabIndex={0}
								onKeyPress={(e) => {
									if (Number(e.which) === 13) {
										closeModal();
									}
								}}
								onClick={closeModal}
								className="feedbackPopUpCancel"
							>
								Cancel
							</button>
							<button
								tabIndex={0}
								onKeyPress={(e) => {
									if (Number(e.which) === 13) {
										handleSubmit();
									}
								}}
								onClick={handleSubmit}
								className="feedbackPopUpSubmit"
							>
								Submit
							</button>
						</div>
					</Modal.Body>
				</>
			)}
		</Modal>
	);
}

export default FeedbackModal;
