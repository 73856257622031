import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';

import './TemplateCrowdSourcing.css';
import Breadcrumbs from '../Breadcrumbs';
import TemplateReviewCard from './TemplateReviewCard';
import { useAxios } from '../../context/AxiosContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../context/TelemetryContext';

function TemplateReviewSingle() {
	const { track } = useAppInsights();
	const { itemId, itemTypeId, complexityId, languageId } = useParams();
	const [templateContributions, setTemplateContributions] = useState([]);
	const [complexities, setComplexities] = useState({});
	const { axiosGetService } = useAxios();
	const [contributorInfo, setContributorInfo] = useState({});
	const [noAccess, setNoAccess] = useState(null);
	const [messagesType, setMessagesType] = useState({});
	const [ratingPoints, setRatingPoints] = useState(null);
	const [userRatingPoints, setUserRatingPoints] = useState(null);
	const [ratingStars, setRatingStars] = useState(null);

	const getRatingPoints = async () => {
		await axiosGetService(`api/sdmuser/gamification/getRatingPoints`).then(
			(response) => {
				setRatingPoints(response.data);
			}
		);
	};

	const getContributions = async () => {
		setTemplateContributions([]);
		await axiosGetService(
			`api/sdmtemplate/${
				Number(itemTypeId) === 44
					? 'getreviewerloctemplate'
					: 'getreviewertemplate'
			}/${itemId}/${Number(itemTypeId) === 44 ? languageId : complexityId}`
		).then((response) => {
			if (response.data && response.data.length !== 0) {
				setTemplateContributions(response.data);
				getAllContributors(response.data);
				if (response.data[0].isReviewer) {
					setNoAccess(false);
				} else {
					setNoAccess(true);
				}
				track(2, 153, itemId, response.data.title);
			} else {
				setTemplateContributions(null);
			}
			getComplexities();
		});
	};

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity')
			.then((res) => {
				const complexityObject = {};
				res.data.map((item) => {
					complexityObject[item.id] = item.name;
				});
				return complexityObject;
			})
			.then((complexityObject) => setComplexities(complexityObject));
	};

	const getAllContributors = async (data) => {
		const accessToken = await axiosTokenService();
		const contributors = contributorInfo;
		for (let i = 0; i < data.length; i++) {
			const userEmail = data[i].contributorEmail;
			if (!Object.keys(contributors).includes(userEmail)) {
				contributors[userEmail] = await getOtherUserProfile(
					accessToken,
					userEmail
				);
			}
		}
		setContributorInfo({
			...contributors,
			...contributorInfo,
		});
	};

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewPrivilegeMessage&title=0`
		).then((response) => {
			message['reviewPrivilegeMessage'] = response.data;
		});

		setMessagesType(message);
	};

	useEffect(() => {
		getMessage();
		getRatingPoints();
	}, []);

	useEffect(() => {
		getContributions();
	}, [itemId, complexityId, languageId, itemTypeId]);

	return (
		<div className="templateCrowdSourcingMainContainer">
			<div className="templateCrowdSourcingBreadcrumb">
				<Breadcrumbs />
			</div>
			<div className="templateCrowdSourcingContainer">
				<div className="templateCrSourcingListWrapper">
					<div className="templateFixedHeaderPart">
						<div className="templateCrSourcingTitleWrapper">
							<h4>
								Suggested Templates <span>(Pending for review)</span>
							</h4>
						</div>
						{noAccess === true ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								{messagesType['reviewPrivilegeMessage']}
							</MessageBar>
						) : templateContributions === null ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								There are no templates for you to review
							</MessageBar>
						) : (
							<div
								className="newTableParentRow header"
								id="templateCrowdSourcing"
							>
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment ">
												<div className="newColumn newTitle newActTd ">
													<span className="templateNewTitle">
														Template Name
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn templateComplexity newActTd ">
											Complexity
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Upload Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn details newActTd ">Details</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn approveAndReject newActTd ">
											Approve/Reject
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="listOfTemplateCrSourcing">
						{templateContributions &&
							noAccess === false &&
							templateContributions.map((contribution) => (
								<TemplateReviewCard
									data={contribution}
									complexities={complexities}
									key={contribution.title}
									singleView={true}
									getContributions={getContributions}
									contributorInfo={contributorInfo}
									setContributorInfo={setContributorInfo}
									getAllContributors={getAllContributors}
									key={JSON.stringify(contribution)}
									ratingPoints={ratingPoints}
									userRatingPoints={userRatingPoints}
									setUserRatingPoints={setUserRatingPoints}
									setRatingStars={setRatingStars}
									ratingStars={ratingStars}
								/>
							))}
						{noAccess === true ? (
							<></>
						) : (
							<Link
								className="approveAllNavigation"
								to="/template/crowdsourcing/review/all"
							>
								Review all template suggestions
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TemplateReviewSingle;
