import React, { useEffect, useState, appState } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import fileIcons from '../../static/TemplateIcons';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function SearchSolutionMethod({
	data,
	enableSearchSuggestion,
	searchSuggestionId,
	setSearchSuggestionId,
	currentPage,
}) {
	const history = useHistory();
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const [showText, setShowText] = useState(false);
	const [searchSuggestionData, setSearchSuggestionData] = useState(null);
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(
		enableSearchSuggestion
	);

	const getSearchSuggestions = () => {
		axiosGetService(
			`api/sdmuser/getSearchRecommendations?artifactTypeName=SolutionMethod&artifactId=${data.id}&activityIdentifier=0&complexityId=0`
		).then((res) => {
			setSearchSuggestionData(res.data);
		});
	};

	useEffect(() => {
		if (searchSuggestionId) {
			setShowSearchSuggestion(
				searchSuggestionId === `search_method_${data.id}`
			);
		} else if (enableSearchSuggestion) {
			setSearchSuggestionId(`search_method_${data.id}`);
		}
	}, [searchSuggestionId]);

	useEffect(() => {
		if (showSearchSuggestion) {
			getSearchSuggestions();
		}
	}, [showSearchSuggestion]);

	return (
		<div
			tabIndex={0}
			className="tabsWrapper solmethodTabsSearch"
			id={`search_method_${data.id}`}
			onClick={() => setSearchSuggestionId(`search_method_${data.id}`)}
			onKeyPress={(e) => {
				if (e.which === 13) {
					setSearchSuggestionId(`search_method_${data.id}`);
				}
			}}
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={fileIcons[data.fileType]}
						className="imageOfSearchResult"
						alt="fileType"
					/>
				</div>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					<NavLink
						onClick={() =>
							track(1, 404, data.id, data.title, {
								pageNumber: currentPage,
							})
						}
						to={data.fileLocationNew}
						key={data.title}
					>
						{data.title}
					</NavLink>
				</div>
				<div class="existingHashTagsOfTopic">
					{data.hashTagsList.map(({ name }) => (
						<span
							key={name}
							onClick={() => {
								history.push(`/search?searchParam=${encodeURIComponent(name)}`);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									history.push(
										`/search?searchParam=${encodeURIComponent(name)}`
									);
								}
							}}
						>
							{name}
						</span>
					))}
				</div>
				{showText ? (
					<div className="descriptionOfTabs">
						<p>
							{data.description === null || data.description.length === 0 ? (
								<span>To be Updated</span>
							) : data.description.length > 800 ? (
								`${data.description.slice(0, 800)}...`
							) : (
								data.description
							)}
						</p>
					</div>
				) : null}
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						<div className="phaseDetails hidingConsultingSupport">
							<span className="phaseLabel">Project Type: </span>
							<span class="phaseName">{data.projectTypeName}</span>
							<div
								className="tabExpandedViewIcon"
								onClick={() => setShowText(!showText)}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								title="Expand Method"
								aria-expanded={showText}
								role="button"
								aria-label={
									showText
										? 'Click to Collapse Solution method information'
										: 'Click to expand Solution method information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{searchSuggestionData && showSearchSuggestion ? (
				<div className="relatedSearchSuggestion">
					<span className="tabsbeakArrow"></span>
					<div className="titleOfSuggestionWrap">
						<h6>
							Phases associated to the Solution Method
							<br />
							<span>{`"${data.title}"`}</span>
						</h6>
					</div>
					{searchSuggestionData.phases &&
					searchSuggestionData.phases.length > 0 ? (
						<>
							<ul className="activityRecommandations">
								{searchSuggestionData.phases.map((phase) => (
									<li key={phase.id}>
										<div className="relatedTitles">
											<img
												src={fileIcons.phase}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(1, 165, phase.phaseId, phase.phaseName, {
														suggestedItemName: 'Phase',
														suggestedItemId: 25,
													});
												}}
												title={phase.phaseName}
												aria-label={phase.phaseName}
												to={`/project/${phase.methodologyTypeId}/method/${phase.solutionMethodId}/phase/${phase.phaseId}/parentPhase/null`}
											>
												<span>{phase.phaseName}</span>
											</NavLink>
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<>
							<span className="tabsbeakArrow"></span>
							<span className="noSearchSuggestions">
								No Phases associated to this Solution Method
							</span>
						</>
					)}
				</div>
			) : null}
		</div>
	);
}
export default SearchSolutionMethod;
