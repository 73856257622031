import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Dropdown({
	className,
	data,
	setValue,
	value,
	enableDropdown,
	setShowAddToGroupDropdown,
	selectedActivityId,
}) {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
			setShowAddToGroupDropdown(false);
		}
	};

	const findSelectedActivity = () => {
		for (const group of value) {
			if (group.activities && group.activities.length > 0) {
				for (const activity of group.activities) {
					if (activity.dragNDropId === selectedActivityId) {
						return activity;
					}
				}
			}
		}
		return null;
	};

	const findActivity = (group) => {
		if (group.activities && group.activities.length > 0) {
			for (const activity of group.activities) {
				if (activity.dragNDropId === selectedActivityId) {
					return true;
				}
			}
		}
		return false;
	};

	const handleDropdown = (item) => {
		const updatedActivitiesGroup = [];
		const selectedActivity = findSelectedActivity();
		for (const group of value) {
			if (group.groupId === item.id) {
				if (selectedActivity) {
					group.activities = [selectedActivity, ...group.activities];
				}
				updatedActivitiesGroup.push(group);
			} else if (!findActivity(group)) {
				updatedActivitiesGroup.push(group);
			}
		}
		setValue([...updatedActivitiesGroup]);
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div title={'Select a group'} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">{'Select a group'}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.name}
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.name}
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
