import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';

import '../Dropdown/Dropdown.css';

function Dropdown({ className, data, setValue, value, enableDropdown }) {
	const { track } = useAppInsights();
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const getSelectedItemName = () => {
		return data.find((item) => item.id === value)?.name;
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const selectedValue = item;

		setValue(selectedValue.id);
		setVisible(!visible);

		track(1, 9, className, selectedValue);
	};

	return (
		<div title={getSelectedItemName()} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">{getSelectedItemName()}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.name}
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
