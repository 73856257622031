import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';

import './Dropdown.css';

function Dropdown({ className, data, setValue, value, enableDropdown,
	newDropDownBox = '',
	newDropDownContainer='',
	newWrapHeader='',pageType = undefined 
 }) {	
	const { track } = useAppInsights();
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		// debugger;
		const dropdown = document.getElementById(className);
		// Check if pageType is undefined
	if (pageType === undefined && dropdown && !dropdown?.contains(e.target)) {
		setVisible(false);
	}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const selectedValue = item;

		setValue(selectedValue);
		setVisible(!visible);

		track(1, 9, className, selectedValue.name);
	};
	useEffect(() => {
		const handleDropDownEscape = (e) => {
		  if (e.key === 'Escape' && enableDropdown) {
			  setVisible(!visible);
		  }
	  };
	  window.addEventListener('keydown',handleDropDownEscape);
		return () => {
		  window.removeEventListener('keydown',handleDropDownEscape);
		}
	  }, [visible])
	
	
	const dropdownContainerRef = useRef(null);
	useEffect(() => {
		const handleFocusOut = (event) => {
			if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.relatedTarget)) {
				setVisible(false);
			}
		};
	
		const dropdownContainer = dropdownContainerRef.current;
		dropdownContainer.addEventListener('focusout', handleFocusOut);
	
		return () => {
			dropdownContainer.removeEventListener('focusout', handleFocusOut);
		};
	}, []);
	
	  
	return (
		<div
			id={className}
			title={
				enableDropdown
					? value.name
					: className === 'complexityFilter'
						? 'Complexity Not Applicable'
						: className === 'complexityDisabled'
							? 'You can not change the complexity'
							: className === 'extractMethodologyComplexityDropdown' &&
								!enableDropdown
								? 'Complexity not applicable'
								: 'Role Not Applicable'
			}
		>
			<div
				className={`dropDowncontainer ${newDropDownContainer} ${className} ${enableDropdown ? '' : 'disableDropdown' 
					}`}
				ref={dropdownContainerRef}
			>
			<div
			id="complexityBoxId"
			className={`dropDownBox ${newDropDownBox}`}
			tabIndex={0}
			onKeyPress={(e) => {
				if (e.which === 13 && enableDropdown) {
				setVisible(!visible);
				}
			}}
			onClick={() => setVisible(!visible)}
			aria-expanded={visible ? 'true' : 'false'}
			role="link"
			aria-label={`Complexity dropdown ${visible ? 'expanded' : 'collapsed'} ${value.name} selected`}
			>
			<span className={`wrapHeader ${newWrapHeader}`} aria-hidden="true">{value.name}</span>
			<Icon iconName="ChevronDown" style={{ fontWeight: pageType ==='Activity' ? 'Bolder':'' ,fontSize: pageType==='Activity' ? '12px':''}} aria-hidden="true" />
			</div>


				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul role="listbox" aria-labelledby="size">
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.name}
											role="listitem"
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.name}
										role="option"
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
