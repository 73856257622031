import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";
import loadable from "@loadable/component";
import { MessageBar, MessageBarType } from "@fluentui/react";

import { useAuth } from "../../../../../context/UserAuthContext";
import { useAxios } from "../../../../../context/AxiosContext";
import { getOtherUserProfile } from "../../../../../helpers/GraphService";
import { axiosTokenService } from "../../../../../components/CkEditor/api/axiosBaseUrl";
import { useLeftMenuCollapse } from "../../../../../context/LeftMenuCollapseContext";
import { useAppInsights } from "../../../../../context/TelemetryContext";
import AddBreadCrumb from "../../../../../helpers/BreadcrumbService";
import Spinner from "../../../../../helpers/Spinner";
import { TabTitle } from "../../../../../utils/GeneralFunctions";
import TemporaryCkAlert from "../../../../../components/CkEditor/TemporaryCkAlert";
import decryptAES256 from "../../../../../components/CkEditor/DecryptionComponent";
import { useConfig } from "../../../../../context/ConfigContext";

const DescriptionCkEditor = loadable(
  () =>
    import(
      "../../../../../components/CkEditor/MyContributionTopics/MyContribution/TopicsContributionPreview"
    ),
  {
    fallback: (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ),
  }
);

initializeIcons();

const TopicsMyReviewsPreview = (props) => {
  const { ckEdKEY } = useConfig();
  const { axiosGetService } = useAxios();
  const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
  const { artifactType, id, artifactId, stateId } = useParams();
  const { user } = useAuth();
  const { track } = useAppInsights();
  const currentUser = user.profile.mail;

  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const [description, setDescription] = useState(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [topicDescriptionData, setTopicDescriptionData] = useState(null);

  const [licenseKey, setLicenseKey] = useState(null);

  const contributorInfo = [];
  const graphUserData = [];
  const userDataMap = new Map();

  const getTopicsContributionPreviewData = async () => {
    await axiosGetService(
      `api/sdmtopics/gettopicreviewerspreview?id=${id}&topicId=${artifactId}`
    ).then((response) => {
      if (!response.data) {
        setDescription("noData");
      } else {
        loadBreadcrumb();
        setTopicDescriptionData(response.data);
        if (topicDescriptionData) {
          setDescription(response.data.description);
        }
        if (response.data.resultCalloutJson) {
          setDescriptionSuggestions(response.data.resultCalloutJson);
        }
      }
    });
  };

  // load and set the breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${artifactId}&itemTypeId=10
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=true&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());

      const parentBreadcrumb = {
        title: breadcrumbTitle[1],
        type: "projectType",
        path: `/mycontributions`,
        parent: ["home"],
      };

      const projectBreadcrumb = {
        title: breadcrumbTitle[2] + " - Historical Preview",
        type: "projectType",
        path: window.location.pathname,
        parent: "myContributions",
      };

      const newBreadcrumbs = AddBreadCrumb(breadcrumbs.slice(0, 1), [
        parentBreadcrumb,
        projectBreadcrumb,
      ]);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[2] + " - Historical Preview");
    });
  };

  const getContributor = (suggestions) => {
    for (const id in suggestions) {
      const userEmail = JSON.parse(suggestions[id]).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };

  const getUsersInfo = async () => {
    if (topicDescriptionData.reviewerSuggestionsJson) {
      getContributor(JSON.parse(topicDescriptionData.reviewerSuggestionsJson));
    }
    if (topicDescriptionData.resultCalloutJson) {
      getContributor(topicDescriptionData.resultCalloutJson);
    }
    if (!userDataMap.has(currentUser)) {
      graphUserData.push(currentUser);
      userDataMap.set(currentUser, 1);
    }
    const accessToken = await axiosTokenService();
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName + " " + userData.surname,
        avatar: userData.photo,
      });
    }
    setCkEditorUsers(contributorInfo);
  };

  const getLicense = async () => {
    const response = await axiosGetService(
      `api/sdmsettings?sdmKey=CKEditorLicenseKey`
    );
    const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
  };

  useEffect(() => {
    if (topicDescriptionData) {
      setTopicDescriptionData(null);
      setCkEditorUsers([]);
    }
    getLicense();
    getTopicsContributionPreviewData();
  }, [id, artifactId, stateId]);

  useEffect(() => {
    if (topicDescriptionData) {
      getUsersInfo();
    }
  }, [topicDescriptionData]);

  if (!topicDescriptionData || ckEditorUsers.length < 1 || !licenseKey) {
    return (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  if (description === "noData") {
    return (
      <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
        You did not make any contribution to this Topic.
      </MessageBar>
    );
  }

  if (!topicDescriptionData.description) {
    return (
      <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
        No Historical Preview available as contributions were made by you as a
        Reviewer.
      </MessageBar>
    );
  }

  return (
    <div className="PreviewAndSubmitPopUp historicalPreview">
      <div className="topicsCrowdSourcingContainer">
        <div className="topicsDescriptionDetails">
          <div className="topicDescriptionDiv">
            <div className="topicDescTitlePart">
              <span className="topicVersion">
                Document Version: {topicDescriptionData.topicVersion}
                ,&nbsp;&nbsp;
                {`${topicDescriptionData.modifiedDate} `}
              </span>
            </div>
            <h4 className="topicTitle">{topicDescriptionData.topicName}</h4>
            <TemporaryCkAlert/>
            <DescriptionCkEditor
              users={ckEditorUsers}
              currentUser={currentUser}
              initialData={
                topicDescriptionData.description
                  ? topicDescriptionData.description
                  : topicDescriptionData.publishDescription
              }
              comment={
                topicDescriptionData.resultReviewerComments
                  ? topicDescriptionData.resultReviewerComments
                  : []
              }
              initalSuggestions={topicDescriptionData.resultCalloutJson}
              reviewedSuggestions={topicDescriptionData.resultReviewedJson}
              licenseKey={licenseKey}
              previewType="reviews"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsMyReviewsPreview;
