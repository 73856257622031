import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dompurify from 'dompurify';
import { useAuth } from "../../context/UserAuthContext";



import RatingModal from './RatingModal';

const RejectionReasonModal = ({
	users,
	closeModal,
	reviewedDescriptionSuggestions,
	reviewedSupportSuggestions,
	reviewedToolsAndGuidanceSuggestions,
	reviewedTipsAndTechniquesSuggestions,
	reviewedTemplateSuggestions,
	reviewedEntryCriteriaSuggestions,
	reviewedExitCriteriaSuggestions,
	reviewedTaskSuggestions,
	contributorSuggestionsInfo,
	submit,
	rejectionComments: comments,
	setRejectionComments,
	type,
	currentUser,
	postData,
	ratingPoints,
	checkRejection,
	usersRating,
	setUsersRating,
	enableGamification,
	reviewedDeliverablesSuggestions,
	taskReorder,
	setReviewedReorderedTaskSuggestions,
	reviewedReorderedTaskSuggestions,
	reviewedReorderedEntrySuggestions,
	reviewedReorderedExitSuggestions,
	reviewedReorderedInputSuggestions,
	reviewedReorderedOutputSuggestions,
	reorderRejectionComments,
	setReorderRejectionComments,
	taskReorderArray,
	exitCriteriaReorderArray,
	entryCriteriaReorderArray,
	inputCriteriaReorderArray,
	outputCriteriaReorderArray,
	reorderedTaskRejectionCardInfo,
	reorderedEntryRejectionCardInfo,
	reorderedInputRejectionCardInfo,
	reorderedExitRejectionCardInfo,
	reorderedOutputRejectionCardInfo,
}) => {
	const [selectedMenu, setSelectedMenu] = useState(enableGamification ? 1 : 2);
	const [commentIds, setCommentIds] = useState({});
	const [error, setError] = useState(null);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const { user } = useAuth();
	const CkEditorCurrentUser = user.profile.mail;
	const htmlSanitizer = (data) => {
		const config = {
			ADD_ATTR: ['target'],
		};
		return dompurify.sanitize(data, config);
	};
	
	const checkUsersDeatils = (isContributionRejected) => {
		const usersEmail = users.filter(({ id }) => id !== currentUser);
		if (usersEmail.length === 0 && isContributionRejected) {
			setSelectedMenu(2);
		} else if (usersEmail.length === 0 && !isContributionRejected && false) {
			submit();
		}
	};


	const checkValidReorderInput = (suggestions, type) => {
		if (suggestions !== null) {
			if (JSON.parse(suggestions)?.state === 'rejected') {

				if (reorderRejectionComments[type]?.comments && reorderRejectionComments[type].comments !== '') {

					return true
				}
				setError('All rejected changes comments are required')
				return false;
			} else {
				return true;
			}
		} return true;
	}

	const checkValidInput = (type, suggestions) => {
		if (suggestions.length !== 0) {
			const suggestionState = {};
			suggestions.forEach((suggestion) => {
				const { id, state } = JSON.parse(suggestion);
				suggestionState[id] = state;
			});
			if (comments[type]) {
				for (const id of Object.keys(comments[type])) {
					if (
						(!comments[type][id] || comments[type][id].length === 0) &&
						suggestionState[id] === 'rejected'
					) {
						setError('All rejected changes comments are required');
						return false;
					}
				}
			}
			return true;
		} else {
			return true;
		}
	};
	const validateInput = () => {

		const validInput =

			checkValidInput('description', reviewedDescriptionSuggestions) &&
			checkValidInput('supportingActivities', reviewedSupportSuggestions) &&
			checkValidInput(
				'toolsAndGuidance',
				reviewedToolsAndGuidanceSuggestions
			) &&
			checkValidInput(
				'tipsAndTechniques',
				reviewedTipsAndTechniquesSuggestions
			) &&
			checkValidInput('templates', reviewedTemplateSuggestions) &&
			checkValidInput('entryCriteria', reviewedEntryCriteriaSuggestions) &&
			checkValidInput('exitCriteria', reviewedExitCriteriaSuggestions) &&
			checkValidInput('task', reviewedTaskSuggestions) &&
			checkValidInput('deliverables', reviewedDeliverablesSuggestions)
			&&
			checkValidReorderInput(reviewedReorderedTaskSuggestions, 'task') &&
			checkValidReorderInput(reviewedReorderedEntrySuggestions, 'entryCriteria') &&
			checkValidReorderInput(reviewedReorderedInputSuggestions, 'inputCriteria') &&
			checkValidReorderInput(reviewedReorderedExitSuggestions, 'exitCriteria') &&
			checkValidReorderInput(reviewedReorderedOutputSuggestions, 'outputCriteria')

		if (validInput) {
			submit();

		}
	};

	const getUserInfo = (type, email) => {
		for (const user of users) {
			if (user.id === email) {
				return user[type];
			}
		}
	};
	const updateReorderedComments = (e, id, activityType) => {
		const comment = e.target.value;
		setReorderRejectionComments((prevState) => ({
			...prevState,
			[activityType]: { id: JSON.parse(id[0]).id, comments: comment, state: JSON.parse(id[0]).state },
		}));
	}


	const updateComments = (e, type, id) => {
		const comment = e.target.value;
		setRejectionComments((prevState) => ({
			...prevState,
			[type]: { ...comments[type], [id]: comment },
		}));
	};

	const getRejectedSuggestionsIds = (suggestions, type) => {
		if (contributorSuggestionsInfo[type]) {
			
			const contributedSuggestionsIds = Object.keys(
				contributorSuggestionsInfo[type]
			).map((suggestion) => {
				if(type === 'templates' || type === 'entryCriteria' || type === 'exitCriteria'
			       || type === 'task' || type === 'toolsAndGuidance'){
					return contributorSuggestionsInfo[type][suggestion].id;
				}else{
					return JSON.parse(contributorSuggestionsInfo[type][suggestion]).id;
				}
			});

			const rejectedSuggestionsIds = suggestions
				.filter(
					(suggestion) =>
						(JSON.parse(suggestion).state === 'rejected' ||
							JSON.parse(suggestion).state === 'accepted') &&
						contributedSuggestionsIds.includes(JSON.parse(suggestion).id)
				)
				.map((item) => {
					return JSON.parse(item).id;
				});
			const commentsIds = {};
			for (const id of rejectedSuggestionsIds) {
				commentsIds[id] = '';
			}
			setCommentIds((prevState) => ({
				...prevState,
				[type]: rejectedSuggestionsIds,
			}));
			setRejectionComments((prevState) => ({
				...prevState,
				[type]: commentsIds,
			}));
			return rejectedSuggestionsIds.length > 0;
		}

		return false;
	};

	const checkUserContribution = (suggestions, type, email) => {
		if (contributorSuggestionsInfo[type]) {
			const contributedSuggestionsIds = Object.keys(
				contributorSuggestionsInfo[type]
			)
				.filter(
					(suggestion) =>
						contributorSuggestionsInfo[type][suggestion].authorId === email
				)
				.map((suggestion) => contributorSuggestionsInfo[type][suggestion].id);
			const rejectedSuggestionsIds = suggestions
				.filter(
					(suggestion) =>
						JSON.parse(suggestion).state === 'rejected' &&
						contributedSuggestionsIds.includes(JSON.parse(suggestion).id)
				)
				.map((item) => JSON.parse(item).id);
			if (contributedSuggestionsIds.length === rejectedSuggestionsIds.length) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const updateUsersForRating = () => {
		for (const user of users) {
			if (
				checkUserContribution(
					reviewedDescriptionSuggestions,
					'description',
					user.id
				) ||
				checkUserContribution(
					reviewedSupportSuggestions,
					'supportingActivities',
					user.id
				) ||
				checkUserContribution(
					reviewedTemplateSuggestions,
					'templates',
					user.id
				) ||
				checkUserContribution(
					reviewedDeliverablesSuggestions,
					'deliverables',
					user.id
				) ||
				checkUserContribution(
					reviewedEntryCriteriaSuggestions,
					'entryCriteria',
					user.id
				) ||
				checkUserContribution(
					reviewedExitCriteriaSuggestions,
					'exitCriteria',
					user.id
				) ||
				checkUserContribution(
					reviewedTaskSuggestions,
					'task',
					user.id
				)
				||
				checkUserContribution(
					reviewedToolsAndGuidanceSuggestions,
					'toolsAndGuidance',
					user.id
				) ||
				checkUserContribution(
					reviewedTipsAndTechniquesSuggestions,
					'tipsAndTechniques',
					user.id
				) &&
				user.id !== currentUser
			) {
				setUpdatedUsers((prevState) => [...prevState, user.id]);
			}
		}
	};

	function removePTags(html) {
		// Replace all <p> tags with an empty string
		return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
	}
	const renderReorderedSection = (type, activityType, reorderArray) => {
		if (type !== null) {
			return (
				<>
					{type && <h5>{JSON.parse(type).name}</h5>}
					{type && (
						<div className={`rejectionCard ${JSON.parse(type[0])}`}>
							<div
								class={`sideBarCard ${JSON.parse(type).state === 'rejected'
									? 'userDeletedToolsAndGuidance'
									: 'userAddedItem'
									}`}
							>
								<div class="sideBarCardHeader">
									<img
										class="userImg"
										src={reorderArray[0]}
										alt=""
									/>
									<span>
										{reorderArray[1]}
									</span>
									<div class="itemStatus">
										<span>Reorder</span>
										&nbsp;
										<p> {`${activityType} has been reordered`}</p>
									</div>
								</div>
							</div>
							{JSON.parse(type).state === 'rejected' ? (
								<div
									className={`rejectionRequiredFields ${error ? 'rejected' : ''
										}`}
								>
									<div>
										<textarea
											placeholder="Enter your Comments"
											value={reorderRejectionComments[activityType]?.comment}
											onChange={(e) => {
												updateReorderedComments(e, type, activityType);
											}}
										/>
										{error ? (
											<></>
										) : (
											<span className="requiredField">Required*</span>
										)}
									</div>
									{error ? (
										<span className="requiredField">
											This is a required field*
										</span>
									) : (
										<></>
									)}
								</div>
							) : (
								<textarea
									placeholder="Enter your Comments"
									value={reorderRejectionComments[activityType]?.comment}
									onChange={(e) => {
										updateReorderedComments(e, type, activityType);
									}}
								/>
							)}
						</div>


					)}
				</>
			)
		}
	}
	const renderSection = (type, title, suggestions) => {
		const suggestionState = {};
		suggestions.forEach((suggestion) => {
			const { id, state } = JSON.parse(suggestion);
			suggestionState[id] = state;
		});

		const contributedSuggestions = Object.keys(
			contributorSuggestionsInfo[type]
		).map((suggestion) => {
			if(type === 'templates' || type === 'entryCriteria' || type === 'exitCriteria'
			       || type === 'task' || type === 'toolsAndGuidance'){
				return contributorSuggestionsInfo[type][suggestion];
			}else{
				return JSON.parse(contributorSuggestionsInfo[type][suggestion]);
			}
		});		
		return (
			<>
				{commentIds[type] && commentIds[type].length > 0 && <h5>{title}</h5>}
				{commentIds[type] &&
					commentIds[type].map((id) => (
						<RejectionCard
							id={id}
							contributedSuggestions={contributedSuggestions}
							suggestionState={suggestionState}
							type={type}
							getUserInfo={getUserInfo}
							error={error}
							comments={comments}
							updateComments={updateComments}
							contributorSuggestionsInfo={contributorSuggestionsInfo}
						/>
					))}
			</>
		);
	};

	useEffect(() => {
		const isDescriptionRejected = getRejectedSuggestionsIds(
			reviewedDescriptionSuggestions,
			'description'
		);
		const isSupprotRejected = getRejectedSuggestionsIds(
			reviewedSupportSuggestions,
			'supportingActivities'
		);

		const isToolRejected = getRejectedSuggestionsIds(
			reviewedToolsAndGuidanceSuggestions,
			'toolsAndGuidance'
		);
		const isTipsRejected = getRejectedSuggestionsIds(
			reviewedTipsAndTechniquesSuggestions,
			'tipsAndTechniques'
		);
		const isTemplateRejected = getRejectedSuggestionsIds(
			reviewedTemplateSuggestions,
			'templates'
		);
		const isDeliverablesRejected = getRejectedSuggestionsIds(
			reviewedDeliverablesSuggestions,
			'deliverables'
		);
		const isEntryCriteriaRejected = getRejectedSuggestionsIds(
			reviewedEntryCriteriaSuggestions,
			'entryCriteria'
		);
		const isExitCriteriaRejected = getRejectedSuggestionsIds(
			reviewedExitCriteriaSuggestions,
			'exitCriteria'
		);
		const isTaskRejected = getRejectedSuggestionsIds(
			reviewedTaskSuggestions,
			'task'
		);

		checkUsersDeatils(
			isDescriptionRejected ||
			isSupprotRejected ||
			isToolRejected ||
			isTipsRejected ||
			isTemplateRejected ||
			isDeliverablesRejected ||
			isEntryCriteriaRejected ||
			isExitCriteriaRejected ||
			isTaskRejected
		);
		updateUsersForRating();
	}, []);
	
	return (
		<>
			{users.length>0?(
				<Modal
					className="topicPreviewAndSubmitPopUp rejectionReasonModal"
					show={true}
					onHide={() => {
						/* Not calling any function to close modal*/
					}}
					dialogClassName="modal-90w"
				>
					<Modal.Header>
						<Modal.Title>
							{type === 'review' ? 'Send for approval' : 'Publish'}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedMenu === 1 && updatedUsers.length > 0 ? (
							<RatingModal
								users={users}
								currentUser={currentUser}
								postData={postData}
								usersRating={usersRating}
								setUsersRating={setUsersRating}
								ratingPoints={ratingPoints}
								updatedUsers={updatedUsers}
							/>
						) : (
							<div className="topicsCrowdSourcingContainer">
								<div class="topicsDescriptionDetails">
									<div className="topicDescriptionDiv">
										<h5>
											Please provide comments to help the contributor to improve
										</h5>
										{error ? <span className="erroMsg">{error}</span> : <></>}
										{renderReorderedSection(reviewedReorderedTaskSuggestions, 'task', reorderedTaskRejectionCardInfo)}
										{renderReorderedSection(reviewedReorderedEntrySuggestions, 'entryCriteria', reorderedEntryRejectionCardInfo)}
										{renderReorderedSection(reviewedReorderedInputSuggestions, 'inputCriteria', reorderedInputRejectionCardInfo)}
										{renderReorderedSection(reviewedReorderedExitSuggestions, 'exitCriteria', reorderedExitRejectionCardInfo)}
										{renderReorderedSection(reviewedReorderedOutputSuggestions, 'outputCriteria', reorderedOutputRejectionCardInfo)}

										{reviewedDescriptionSuggestions.length > 0 ? 
											renderSection(
												'description',
												'Description',
												reviewedDescriptionSuggestions
											)
										: ''}
										{reviewedSupportSuggestions.length > 0 ? 
											renderSection(
												'supportingActivities',
												'Supporting Activities',
												reviewedSupportSuggestions
											)
										: ''}
										{reviewedToolsAndGuidanceSuggestions.length > 0 ? 
											renderSection(
												'toolsAndGuidance',
												'Tools',
												reviewedToolsAndGuidanceSuggestions
											)
										: ''}
										{reviewedTipsAndTechniquesSuggestions.length > 0 ? 
											renderSection(
												'tipsAndTechniques',
												'Guidance, Tips and Techniques',
												reviewedTipsAndTechniquesSuggestions
											)
										: ''}
										{reviewedTemplateSuggestions.length > 0 ? 
											renderSection(
												'templates',
												'Templates',
												reviewedTemplateSuggestions
											)
										: ''}
										{reviewedDeliverablesSuggestions.length > 0 ? 
											renderSection(
												'deliverables',
												'Deliverables',
												reviewedDeliverablesSuggestions
											)
										: ''}
										{reviewedEntryCriteriaSuggestions.length > 0 ? 
											renderSection(
												'entryCriteria',
												'Entry Criteria/Input',
												reviewedEntryCriteriaSuggestions
											)
										: ''}
										{reviewedExitCriteriaSuggestions.length > 0 ? 
											renderSection(
												'exitCriteria',
												'Exit Criteria/Output',
												reviewedExitCriteriaSuggestions
											)
										: ''}
										{reviewedTaskSuggestions.length > 0 ? 
											renderSection(
												'task',
												'Task',
												reviewedTaskSuggestions
											)
										: ''}
									</div>
								</div>
							</div>
						)}
						<div className="reviwerRejectedRatingButton">
							<Button
								title="Cancel"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={() => {
									closeModal();
								}}
								className="activityDiscardButton"
							>
								Cancel
							</Button>
							<Button
								title="Submit"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										if (
											selectedMenu === 1 &&
											checkRejection('rating') &&
											updatedUsers.length > 0
										) {
											setSelectedMenu(2);
										} else {
											validateInput();
										}
									}
								}}
								onClick={() => {
									if (
										selectedMenu === 1 &&
										checkRejection('rating') &&
										updatedUsers.length > 0
									) {
										setSelectedMenu(2);
									} else {
										validateInput();
									}
								}}
								className="activityPreviewButton"
							>
								{selectedMenu === 1 &&
									checkRejection('rating') &&
									updatedUsers.length > 0
									? 'Next'
									: type === 'review'
										? 'Submit'
										: 'Publish'}
							</Button>
						</div>
					</Modal.Body>
				</Modal>
			):(
				<Modal
					className="activityDiscardPopUp"
					show={true}
					onHide={() => {
						/* Not calling any function to close modal*/
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
				<Modal.Body>
					<p>Select "Publish" to proceed, or "Cancel" to return and make edits.</p>
					<div className="reviwerRejectedRatingButton">
						<Button
							title="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={() => {
								closeModal();
							}}
							className="activityDiscardButton"
						>
							Cancel
						</Button>
						<Button
							title="Submit"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									if (
										selectedMenu === 1 &&
										checkRejection('rating') &&
										updatedUsers.length > 0
									) {
										setSelectedMenu(2);
									} else {
										validateInput();
									}
								}
							}}
							onClick={() => {
								if (
									selectedMenu === 1 &&
									checkRejection('rating') &&
									updatedUsers.length > 0
								) {
									setSelectedMenu(2);
								} else {
									validateInput();
								}
							}}
							className="activityPreviewButton"
						>
							{selectedMenu === 1 &&
								checkRejection('rating') &&
								updatedUsers.length > 0
								? 'Next'
								: type === 'review'
									? 'Submit'
									: 'Publish'}
						</Button>
					</div>
				</Modal.Body>
				</Modal>
			)}
		</>
	);
};

const RejectionCard = ({
	id,
	contributedSuggestions,
	suggestionState,
	type,
	getUserInfo,
	error,
	comments,
	updateComments,
	contributorSuggestionsInfo
}) => {
	const contributor = contributedSuggestions.filter((contributor) => { return contributor.id == id });
	return (
		<div key={id}>
			<div className={`rejectionCard ${suggestionState[id]}`}>
				<div
					class={`sideBarCard ${suggestionState[id] === 'rejected'
						? 'userDeletedToolsAndGuidance'
						: 'userAddedItem'
						}`}
				>
					<div class="sideBarCardHeader">
						<img
							class="userImg"
							src={getUserInfo(
								'avatar',
								contributor[0]  &&
								contributor[0].authorId
							)}
							alt=""
						/>
						<span>
							{getUserInfo(
								'name',
								contributor[0] &&
								contributor[0].authorId
							)}
						</span>
						<div class="itemStatus">
							<span>
								{type === 'toolsAndGuidance' ? (
									<>
										{contributor[0] &&
											contributor[0]
												.suggestionType === 'insertion'
											? 'Add'
											: 'Remove'}
									</>
								) : (
									<>
										{contributor[0] &&
											contributor[0].type ===
											'insertion'
											? 'Add'
											: contributor[0] &&
												contributor[0].type ===
												'deletion'
												? 'Remove'
												: 'Edit'}
									</>
								)}
							</span>
							&nbsp;
							{
								type === 'templates' ||
								type === 'task' ||
								type === 'entryCriteria' ||
								type === 'exitCriteria'? (
								contributor[0] &&
								<div className='topicsImgDimensions'
									dangerouslySetInnerHTML={{
										__html:
											contributor[0].name,
									}}
								>
								</div>
							) : ( <>
								{type === 'toolsAndGuidance'  ?(
									contributor[0] &&
										<div className='topicsImgDimensions'
									>
										{contributor[0].name}
									</div>
	
									):<>
									{contributor[0] && (
										<div className='topicsImgDimensions'
											// dangerouslySetInnerHTML={{
											// 	__html:
											// 		contributor[0].data,
											// }}
										>
											{(contributor[0].data) !== null ? 
													contributor[0].data.key === "$elementName" ? contributor[0].data.newValue : contributor[0].data.key
												: ''}
										</div>
									)}
								</>
								}
								
							</>)}
						</div>
					</div>
				</div>{' '}
				{suggestionState[id] === 'rejected' ? (
					<div
						className={`rejectionRequiredFields ${error && !comments[type]?.[id] ? 'rejected' : ''
							}`}
					>
						<div>
							<textarea
								placeholder="Enter your Comments"
								value={comments[type] && comments[type][id]}
								onChange={(e) => {
									updateComments(e, type, id);
								}}
							/>
							{error && !comments[type]?.[id] ? (
								<></>
							) : (
								<span className="requiredField">Required*</span>
							)}
						</div>
						{error && !comments[type]?.[id] ? (
							<span className="requiredField">
								This is a required field*
							</span>
						) : (
							<></>
						)}
					</div>
				) : (
					<textarea
						placeholder="Enter your Comments"
						value={comments[type] && comments[type][id]}
						onChange={(e) => {
							updateComments(e, type, id);
						}}
					/>
				)}
			</div>
		</div>
	);
}
export default RejectionReasonModal;
