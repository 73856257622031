import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';

import HashtagSuggestions from '../HashtagSuggestions';
import { useAxios } from '../../context/AxiosContext';

const ActivityHashtags = ({
	showFields,
	handelFormView,
	activityGeneralDetails,
	setActivityGeneralDetails,
	activityGeneralUpdatedDetails,
	setActivityGeneralUpdatedDetails,
}) => {
	const { axiosGetService } = useAxios();
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const [existingHashtags, setExistingHashtag] = useState(
		activityGeneralUpdatedDetails['activityHashTags'].length > 0
			? activityGeneralUpdatedDetails['activityHashTags'].map((item) => {
					return item;
			  })
			: []
	);

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const removeHashtag = (tag) => {
		const updatedHashtag = activityGeneralUpdatedDetails[
			'activityHashTags'
		].filter((item) => tag !== item);
		setActivityGeneralUpdatedDetails((prevState) => ({
			...prevState,
			activityHashTags: updatedHashtag,
		}));
	};

	const saveHashtag = () => {
		setActivityGeneralDetails((prevState) => ({
			...prevState,
			activityHashTags: [...activityGeneralUpdatedDetails['activityHashTags']],
		}));
	};
		// UseEffect to call saveHashtag when hashtags are added/updated
		useEffect(() => {
			if (activityGeneralUpdatedDetails['activityHashTags'].length > 0) {
				saveHashtag();
			}
		}, [activityGeneralUpdatedDetails['activityHashTags']]);

	const draftHashtags = () => {
		setActivityGeneralUpdatedDetails((prevState) => ({
			...prevState,
			activityHashTags: [
				...userAddedHashtags,
				...activityGeneralUpdatedDetails['activityHashTags'],
			],
		}));
		setUserAddedHashtags([]);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	return (
		<div>
			<div className="activityHashtags">
				{true ? (
					<>
						<HashtagSuggestions
							className="activityEditHashtagsInput"
							enableDropdown={true}
							data={hashtagSuggestions}
							setUpdateApiCall={setUpdateApiCall}
							setHashtagSuggestions={setHashtagSuggestions}
							searchValue={hashtagsInput}
							setSearchValue={setHashtagsInput}
							existingHashtags={existingHashtags}
							userAddedHashtags={userAddedHashtags}
							setUserAddedHashtags={setUserAddedHashtags}
							hashtagSuggestionInput={hashtagSuggestionInput}
							setHashtagSuggestionInput={setHashtagSuggestionInput}
							closeHashtagForm={() => {
								setHashtagsInput('');
								setUserAddedHashtags([]);
							}}
							submitHashtags={draftHashtags}
							autoFocus={true}
							showSubmitButton={true}
							pageType="activityEdit"
						/>

						{activityGeneralUpdatedDetails['activityHashTags'].length > 0 ? (
							<div className="hashTagsContaineredit">
								{activityGeneralUpdatedDetails['activityHashTags'].map(
									(item) => (
										<span key={item} tabIndex={0}>
											{item}{' '}
											<Icon
												iconName="Cancel"
												onClick={() => removeHashtag(item)}
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														removeHashtag(item);
													}
												}}
												aria-label={`click to Remove ${item} Hashtag`}
												title="Cancel"
												role="Button"
											/>
										</span>
									)
								)}
							</div>
						) : (
							<></>
						)}
					</>
				) : (
					<>
						{activityGeneralDetails['activityHashTags'].length > 0 ? (
							<div className="hashTagsContaineredit">
								{activityGeneralDetails['activityHashTags'].map((item) => (
									<span key={item}>{item}</span>
								))}
							</div>
						) : (
							<></>
						)}
					</>
				)}
			</div>
			
		</div>
	);
};
export default ActivityHashtags;
