import React, { useState, useEffect, useMemo } from "react";
import { Icon } from "@fluentui/react/lib/Icon";

import { useAxios } from "../../context/AxiosContext";
import { useAuth } from "../../context/UserAuthContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";

import { getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../../components/CkEditor/api/axiosBaseUrl";
import Spinner from "../../helpers/Spinner";
import MyPerformanceTrends from "./MyPerformanceTrends";
import { TabTitle } from "../../utils/GeneralFunctions";

const MyAchievements = ({
  myPerformanceTrendsData,
  setMyPerformanceTrendsData,
}) => {
  const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
  const { axiosGetService } = useAxios();
  const { user } = useAuth();
  const [myAchievements, setMyAchievements] = useState({});
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [usersDetails, setUserDetails] = useState({});
  const [myPerformanceProgressBarsData, setMyPerformanceProgressBarsData] =
    useState([]);
  const [myPerformanceTrendsHoc, setMyPerformanceTrendsHoc] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);

  const getMyPerformaceProgressBarsData = () => {
    axiosGetService(`api/sdmuser/getperformancedata`).then((response) => {
      const {
        totalApprovedContributions,
        totalContributions,
        ratingStars,
        points,
        highestRankPoints,
      } = response.data;
      setMyPerformanceProgressBarsData([
        {
          title: "Points Earned",
          value: points,
          max: highestRankPoints,
          valueTitle: "Your Points",
          maxTitle: "Topper's Points",
        },
        {
          title: "Approved to Submitted ratio",
          value: totalApprovedContributions,
          max: totalContributions,
          valueTitle: "Approved",
          maxTitle: "Submitted",
        },
        {
          title: "Rating",
          value: ratingStars,
          max: 5,
          valueTitle: "Avg. rating you got",
          maxTitle: "Maximum Rating",
        },
      ]);
    });
  };
  const getMyAchievements = async () => {
    setShowSpinner(true);
    await axiosGetService(`api/sdmuser/gamification/contributionStatus`).then(
      (response) => {
        setMyAchievements(response.data);
        setShowSpinner(false);
      }
    );
  };

  const getMyPerformanceData = async () => {
    await axiosGetService(
      `api/sdmuser/getperformancetrendbyfrequency?isLastTwelveMonths=${false}&isDaily=${false}&isWeekly=${true}`
    ).then((response) => {
      setMyPerformanceTrendsData(response.data);
    });
  };

  const getUserProfile = async (email) => {
    const accessToken = await axiosTokenService();
    const userData = await getOtherUserProfile(accessToken, email);
    setUserDetails((prevState) => ({
      ...prevState,
      [email]: {
        id: userData.mail,
        name: userData.firstName + " " + userData.surname,
        avatar: userData.photo,
      },
    }));
  };

  const getLeaderBoard = async () => {
    await axiosGetService(`api/sdmuser/getleaderboarddetails?top=6`).then(
      (response) => {
        setLeaderBoard(response.data);
        for (const userEmail of response.data) {
          getUserProfile(userEmail.email);
        }
      }
    );
  };

  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=0&itemTypeId=${83}&isEdit=false
			&isAutoComplete=false&isSearchResultsHome=false&isSearchResults=false
			&isMyContributions=false&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const pageBreadcrumb = {
        title: breadcrumbTitle[1],
        parent: "Home",
        path: window.location.pathname,
      };
      const newBreadcrumbs = breadcrumbs.slice(0, 1);
      newBreadcrumbs.push(pageBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[1]);
    });
  };

  useEffect(() => {
    getMyAchievements();
    getLeaderBoard();
    loadBreadcrumb();
  }, []);

  useEffect(() => {
    getMyPerformaceProgressBarsData();
  }, []);
  useEffect(() => {
    getMyPerformanceData();
  }, []);

  useMemo(() => {
    setMyPerformanceTrendsHoc(
      <MyPerformanceTrends performanceTrendsData={myPerformanceTrendsData} />
    );
  }, [myPerformanceTrendsData]);

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="myAchievementsStatsMainContainer">
      <div className="myAchievementsStatsContainer">
        <div className="myAchievementsUserProfileDiv">
          <div className="myAchievementsUserProfileDetails">
            <div className="myAchievementsUserProfilePic">
              <img
                className="userImage"
                alt="profileImage"
                src={user.profile.photo}
              />
              <span className="profileBadge"></span>
            </div>
            <div className="myAchievementsUserProfileTitle">
              <h6>
                {user.profile.firstName}
                &nbsp;
                {user.profile.surname}
              </h6>
              <span>Contributor</span>
            </div>
            <div className="myAchievementsUserProfileBadgeBar">
              <span className="profileBadges"></span>
              <span className="profileBadges"></span>
              <span className="profileBadges"></span>
              <span className="profileBadges"></span>
            </div>
          </div>
          <div className="myAchievementsUserProfileRank">
            <div>
              <h6>Rank</h6>
              <span>
                {myAchievements.userRank ? myAchievements.userRank : "NA"}
              </span>
            </div>
            <div>
              <h6>Level</h6>
              <span>{myAchievements.level ? myAchievements.level : "NA"}</span>
            </div>
            <div>
              <h6>Points</h6>
              <span>
                {myAchievements.points ? myAchievements.points : "NA"}
              </span>
            </div>
          </div>
        </div>
        {myAchievements.gamificationLevels && LevelProgressBar(myAchievements)}
        <div className="myAchievementsStatsMyPerformances">
          <h5>My Performance</h5>
          <div className="myAchievementsProgressBar">
            {myPerformanceProgressBarsData.map((progressBar) => {
              return ProgressBar(progressBar);
            })}
          </div>
        </div>
      </div>
      <div className="myAchievementsContributionsContainer">
        <div className="myAchievementsStatsContainer">
          <h6>Contributions</h6>
          <div className="myAchievementsContributionsDiv">
            <span>Total Approved Contributions</span>
            <span className="myAchievementsContributionsCount">
              <span>{myAchievements.totalAcceptedContributions}</span>
            </span>
          </div>
          <div className="myAchievementsContributionsDiv pendingReview">
            <span>Pending For Review</span>
            <span className="myAchievementsContributionsCount">
              <span>{myAchievements.pendingForReviewEntities}</span>
            </span>
          </div>
          <div className="myAchievementsContributionsDiv rejectedReview">
            <span>Rejected Contributions</span>
            <span className="myAchievementsContributionsCount">
              <span>{myAchievements.totalRejectedContributions}</span>
            </span>
          </div>
        </div>
        <div className="myAchievementsStatsContainer">
          <h6>My Performance Trend</h6>
          {myPerformanceTrendsHoc}
        </div>
      </div>
      <div className="myAchievementsStatsContainer">
        <div className="myAchievementsRewardsContainer">
          <h6>Rewards</h6>
          <div>
            <div className="myAchievementsCongratzDiv">
              <h4>Alas! It's Empty!</h4>
              <h6>Keep contributing for more rewards!</h6>
              <div className="myAchievementsEmptyBadgeIcon"></div>
            </div>
            <div className="myAchievementsShareColleagues">
              <a
                href={`mailto:?subject=Achievement Badge&body=Hi,
							I have reached a new level`}
              >
                <span>Share with your colleagues !</span>
                <Icon iconName="Forward" />
              </a>
            </div>
            <div className="myAchievementsClickToOpenDiv">
              <h4>There's nothing here!!</h4>
              <h6>Keep contributing for more rewards!</h6>
              <div className="myAchievementsEmptyBadgeIcon"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="myAchievementsStatsContainer">
        <div className="myAchievementsLeaderBoardContainer">
          <h6>Leaderboard</h6>
          <div className="myAchievementsLeaderBoardHeader">
            <span>Name</span>
            <span>Rank</span>
          </div>
          <ul className="myAchievementsLeaderBoardTab">
            {leaderBoard.map((item) => (
              <li key={item.email}>
                <div className="myAchievementsLeaderBoardUserDetails">
                  <span className="myAchievementsLeaderBoardUserImg">
                    <img
                      src={
                        usersDetails[item.email] &&
                        usersDetails[item.email].avatar
                      }
                      alt="userImage"
                    />
                  </span>
                  <span>
                    {usersDetails[item.email] && usersDetails[item.email].name}
                  </span>
                </div>
                <div className="leaderBoardRank">
                  <span>{item.rank}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

function ProgressBar({ title, value, max, valueTitle, maxTitle }) {
  const sepratorspace = (value / max) * 100;
  return (
    <p key={title}>
      <h6>{title}</h6>
      <div>
        <div className="separator zero">
          <span>0</span>
          <span className="separatorLine"></span>
        </div>

        <progress
          id="file"
          value={value}
          max={max}
          title={`${valueTitle} : ${value}`}
        >
          {" "}
          {(value / max) * 100}%{" "}
        </progress>
        <div
          className="separator zero actualPoints"
          style={{ left: `${sepratorspace - 0.5}%`, fontWeight: "bold" }}
        >
          <span className="separatorLine"></span>
          <span>{value}</span>
        </div>

        <div className="separator hundred">
          <span>{max}</span>
          <span className="separatorLine"></span>
        </div>

        <div className="progressBarLevelTitlesDesc">
          <span className="valueTitle">
            <Icon iconName="CircleShapeSolid" />
            {valueTitle}
          </span>
          <span className="maxTitle">
            {maxTitle}
            <Icon iconName="CircleShapeSolid" />
          </span>
        </div>
      </div>
    </p>
  );
}
function LevelProgressBar({ gamificationLevels, level, progressBarPoints }) {
  const index = parseInt(level, 10);
  const currentLevel = gamificationLevels[index - 1];
  const nextLevl =
    index < gamificationLevels.length
      ? gamificationLevels[index]
      : currentLevel;
  const pointsAsPrecentage =
    ((progressBarPoints - currentLevel.minimumPoints) /
      (currentLevel.maximumPoints - currentLevel.minimumPoints)) *
    100;
  return (
    <div className="myAchievementsUserProfileProgressBar">
      <div className="separator zero">
        <span>{currentLevel.minimumPoints}</span>
        <span className="separatorLine"></span>
      </div>
      <progress
        id="file"
        value={progressBarPoints - currentLevel.minimumPoints}
        max={currentLevel.maximumPoints - currentLevel.minimumPoints}
        title={progressBarPoints}
      >
        {" "}
        {pointsAsPrecentage}%{" "}
      </progress>
      {currentLevel.gamificationSubLevels.map((sublevel, index) => {
        if (
          (Math.abs(progressBarPoints - sublevel.maximumPoints) /
            currentLevel.maximumPoints) *
            100 >
          2
        ) {
          const sepratorspace =
            (100 / (currentLevel.gamificationSubLevels.length + 1)) *
            (index + 1);
          return (
            <div
              className="separator"
              style={{ left: `${sepratorspace - 0.5}%` }}
            >
              <span>{sublevel.maximumPoints}</span>
              <span className="separatorLine"></span>
            </div>
          );
        }
        return <></>;
      })}
      <div
        className="separator zero actualPoints"
        style={{
          left: `${pointsAsPrecentage - 0.5}%`,
          fontWeight: "bold",
        }}
      >
        <span className="separatorLine"></span>
        <span>{progressBarPoints}</span>
      </div>
      <div className="separator hundred">
        <span>{currentLevel.maximumPoints}</span>
        <span className="separatorLine"></span>
      </div>
      <div className="progressBarLevelTitles">
        <span>{currentLevel.levelName}</span>
        <span>Progress</span>
        <span>
          {currentLevel.levelName === nextLevl.levelName
            ? `${currentLevel.maximumPoints}+`
            : nextLevl.levelName}
        </span>
      </div>
    </div>
  );
}
export default MyAchievements;
