import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { titleValidator, urlValidator } from '../../helpers/Validation';

const TopicsRelatedContentModal = ({
	showRelatedContentUpdateModal,
	setShowRelatedContentUpdateModal,
	id,
	setUpdateContentrelatedData,
	updateRelatedContentData,
	getRelatedContent,
	type,
}) => {
	const { track } = useAppInsights();
	const { axiosPostService } = useAxios();
	const [contentTitle, setContentTitle] = useState(
		updateRelatedContentData ? updateRelatedContentData.title : ''
	);
	const [contentUrl, setContentUrl] = useState(
		updateRelatedContentData ? updateRelatedContentData.url : ''
	);
	const [error, setError] = useState(false);

	const closeModal = () => {
		setShowRelatedContentUpdateModal(false);
		setUpdateContentrelatedData(null);
	};

	const setToDefault = () => {
		setContentTitle('');
		setContentUrl('');
		setUpdateContentrelatedData(null);
	};

	const addAssociatedContent = async () => {
		if (!error && contentTitle && contentUrl) {
			const postData = {
				topicOrVideoTutorialId: id,
				title: contentTitle,
				url: contentUrl,
			};
			await axiosPostService(`api/sdmtopics/addrelatedcontent`, postData).then(
				(response) => {
					if (response.data.status) {
						track(
							1,
							type === 'topic' ? 1401 : type === 'job aide' ? 1404 : 1410,
							id,
							type === 'topic'
								? 'topics_related_content'
								: type === 'job aide' ? 'jobaides_related_content' : 'readiness_related_content',
							{
								data: postData,
							}
						);
						getRelatedContent();
						setToDefault();
						closeModal();
						toast.info('You have added the content successfully', {
							position: 'top-right',
						});
					} else {
						toast.error(response.data.errorMessage, {
							position: 'top-right',
						});
					}
				}
			);
		}
	};

	const updateAssociatedContent = async () => {
		if (!error && contentTitle && contentUrl) {
			const postData = {
				topicOrVideoTutorialId: id,
				title: contentTitle,
				url: contentUrl,
				id: updateRelatedContentData.id,
			};
			await axiosPostService(
				`api/sdmtopics/updaterelatedcontent`,
				postData
			).then((response) => {
				if (response.data.status) {
					track(
						1,
						type === 'topic' ? 1402 : type === 'job aide' ? 1405 : 1411,
						id,
						type === 'topic'
							? 'topics_related_content'
							: type === 'job aide' ? 'jobaides_related_content' : 'readiness_related_content',
						{
							data: postData,
						}
					);
					getRelatedContent();
					setToDefault();
					closeModal();
					toast.info('You have updated the content successfully', {
						position: 'top-right',
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			});
		}
	};

	useEffect(() => {
		if (
			(contentTitle && !titleValidator.test(contentTitle)) ||
			(contentUrl && !urlValidator.test(contentUrl))
		) {
			setError(true);
		} else {
			setError(false);
		}
	}, [contentTitle, contentUrl]);

	return (
		<Modal
			show={showRelatedContentUpdateModal}
			className="relatedContentUpdateModal"
			onHide={() => {
				closeModal();
			}}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{updateRelatedContentData ? 'Update' : 'Add'} Associated Content
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="associatedContentModalSection">
					<p>Content Title</p>
					<input
						placeholder="Enter Title"
						value={contentTitle}
						onChange={(e) => setContentTitle(e.target.value)}
					/>
					{contentTitle && !titleValidator.test(contentTitle) ? (
						<p className="errorMsg">Invalid title</p>
					) : (
						<></>
					)}
				</div>
				<div className="associatedContentModalSection">
					<p>Content Url</p>
					<input
						value={contentUrl}
						placeholder="Enter URL"
						onChange={(e) => {
							setContentUrl(e.target.value);
						}}
					/>
					{contentUrl && !urlValidator.test(contentUrl) ? (
						<p className="errorMsg">Invalid url</p>
					) : (
						<></>
					)}
				</div>
				<div className="associatedContentModalButtons">
					<button
						onClick={() => {
							closeModal();
						}}
					>
						Cancel
					</button>
					<button
						onClick={() => {
							if (updateRelatedContentData) {
								updateAssociatedContent();
							} else {
								addAssociatedContent();
							}
						}}
					>
						{updateRelatedContentData ? 'Update' : 'Add'}
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopicsRelatedContentModal;
