import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import PhaseCreationModal from '../PhaseLeftMenu/PhaseAddition/CreatePhase';
import PhaseUpdateModal from '../PhaseLeftMenu/PhaseAddition/UpdatePhase';
import UpdatePhasePopUp from '../PhaseLeftMenu/PhaseAddition/UpdatePhasePopUp';
import PhaseLockModal from '../PhaseLeftMenu/LockModal';
import MethodOwnerCollaborator from '../PhaseLeftMenu/MethodOwnerCollaborator';
import ExtractMethodlogy from '../LeftMenu/ExtractMethodology';
import './DeliveryManagement.css';

function DeliveryManagement({
	phasesList,
	parentMethodName,
	solutionMethodName,
	projectTypeId,
	solutionMethodId,
	setPhaseCreated,
	coOwners,
	setDeletePhase,
	showUpdatePhasePopUp,
	setShowPhaseUpdatePopUp,
	parentId,
	showParentDeletePhaseModal,
	setShowParentDeletePhaseModal,
	setShowDeletePhaseModal,
	getParentSolutionMethodData,
	parentPhaseCreated,
	setParentPhaseCreated,
	showPhaseUpdateModal,
	setShowPhaseUpdateModal,
	updatePhaseId,
	setUpdatePhaseId,
	selectedTab,
	setSelectedTab,
	parentSolutionMethod,
	selectedPhase,
	setSelectedPhase,
	getSolutionMethodData,
}) {
	const phaseNameLength = 37;
	const { userAlias, axiosGetService } = useAxios();
	const { url } = useRouteMatch();
	const { track } = useAppInsights();
	const location = window.location.pathname;
	const {
		phase,
		parentPhase,
		setPhase,
		setParentPhase,
		svg,
		setSvg,
		endUserView,
		appState
	} = useLeftMenuCollapse();
	const { defaultComplexity } = appState;
	const [showPhaseLockModal, setShowPhaseLockModal] = useState(false);
	const [showPhaseCreationModal, setShowPhaseCreationModal] = useState(false);
	const [lockEmailId, setLockEmailId] = useState(null);
	const [showPhaseMethoOwnerModal, setShowPhaseMethoOwnerModal] =
		useState(false);
	const [showExtractMethodlogy, setShowExtractMethodlogy] = useState(false);
	const [extractMethodlogyId, setExtractMethodologyId] = useState(0);
	const [complexities, setComplexities] = useState([]);
	const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity);

	const handleSvgLoad = () => {
		if (parentPhase.id !== null && svg.type === 'parentPhase' && svg.enable) {
			track(2, 151, parentPhase.id, parentPhase.title);
			setSvg({
				...svg,
				enable: true,
				type: 'parentPhase',
				url: parentPhase.svgFileName,
			});
		} else if (svg.source !== 'matrixView') {
			setSvg({
				enable: false,
				url: null,
				type: null,
			});
		}
	};

	const handleSvgClick = () => {
		track(1, 151, 'Phase SVG Switch', 'Phase SVG Switch');
		if (svg.enable && svg.type === 'parentPhase') {
			setSvg({
				enable: false,
				url: null,
				type: null,
			});
		} else if (parentPhase.id !== null) {
			track(2, 151, parentPhase.id, parentPhase.title);
			setSvg({
				...svg,
				enable: true,
				type: 'parentPhase',
				url: parentPhase.svgFileName,
			});
		} else if (parentPhase.id === null) {
			setSvg({
				enable: true,
				url: null,
				type: 'parentPhase',
			});
		}
	};

	const updateSvgFileName = () => {
		const selectedPhaseInfo = phasesList.filter(
			({ phaseId }) => parentPhase['id'] === phaseId
		);
		if (selectedPhaseInfo.length > 0) {
			setParentPhase((prevState) => ({
				...prevState,
				svgFileName: selectedPhaseInfo[0].svgFileName,
			}));
		}
	};

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity').then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: 'option',
				};
			});
			setComplexities(data);
		});
	};

	useEffect(() => {
		handleSvgLoad();
	}, [parentPhase]);

	useEffect(() => {
		if (location === url) {
			if (svg.source !== 'matrixView') {
				setSvg({
					enable: false,
					type: null,
					url: null,
				});
			}
		}
	}, [location]);

	useEffect(() => {
		getComplexities();
	}, []);

	return (
		<div className="deliveryManagementMenu">
			<div
				className={
					coOwners.includes(userAlias)
						? `deliveryMgntTitle ${
								parentPhase.id === null ? 'deliveryMenuActive' : ''
						  } coOwnersLeftMenu`
						: `deliveryMgntTitle ${
								parentPhase.id === null ? 'deliveryMenuActive' : ''
						  }`
				}
			>
				{showExtractMethodlogy && extractMethodlogyId === parentId ? (
					<ExtractMethodlogy
						showExtractMethodlogy={showExtractMethodlogy}
						extractMethodlogyId={parentId}
						solutionMethodName={parentMethodName}
						selectedComplexity={selectedComplexity}
						setSelectedComplexity={setSelectedComplexity}
						setExtractMethodologyId={() => {
							// don't do anything
						}}
						setShowExtractMethodlogy={setShowExtractMethodlogy}
						complexities={complexities}
					/>
				) : null}
				<Icon
					className="deliveryExtractIcon"
					iconName="MoreVertical"
					onClick={() => {
						setExtractMethodologyId(parentId);
						setShowExtractMethodlogy(true);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setExtractMethodologyId(parentId);
							setShowExtractMethodlogy(true);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Extract Methodology"
					title="Extract Methodology"
				/>
				<div>
					<h4>{parentMethodName}</h4>
					<div className="solutionMethodTitleDetals">
						<div>
							{coOwners.includes(userAlias) && !endUserView && (
								<>
									<span
										className="phaseCollaboratorDiv"
										title="Edit Approvers/Reviewers"
									>
										<span
											className="phaseCollaboratorAddIcon"
											tabIndex="0"
											onClick={() => {
												setShowPhaseMethoOwnerModal(true);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setShowPhaseMethoOwnerModal(true);
												}
											}}
											aria-label={`${parentMethodName} edit approvers/reviewers`}
											role="button"
										></span>
									</span>
									<span className="addNewPhaseDiv" title="Add New Phase">
										<span
											className="phaseAddIcon"
											tabIndex="0"
											onClick={() => {
												setShowPhaseCreationModal(true);
												track(1, 800, 'Phase_Create', solutionMethodName);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setShowPhaseCreationModal(true);
													track(1, 800, 'Phase_Create', solutionMethodName);
												}
											}}
											aria-label={`${parentMethodName} Add a new phase`}
											role="button"
										></span>
									</span>
								</>
							)}
							<button
								type="button"
								onClick={() => {
									handleSvgClick();
									updateSvgFileName();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleSvgClick();
										updateSvgFileName();
									}
								}}
								aria-label="Toggle between Visio and Blade View"
								aria-pressed={svg.type !== 'phase' ? 'false' : 'true'}
							>
								<span
									className={
										svg.type !== 'parentPhase'
											? 'swimlineIcon'
											: 'swimlineIconactive'
									}
									title="Toggle between Visio and Blade View"
								></span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<ul className="deliveryMgntRightMenu">
				{phasesList.map((item) => (
					<>
						{item.isDrafted && coOwners.includes(userAlias) ? (
							<li
								key={item.phaseId}
								className={
									item.isDrafted && item.isRefered
										? 'draftPhase isRefferedPhase'
										: 'nonDraftPhase'
								}
							>
								<div>
									<NavLink
										to={`${url}/phase/${phase.id}/parentPhase/${item.phaseId}`}
										key={`phase_${item.phaseId}`}
										activeClassName="deliveryMenuActive"
										onClick={(e) => {
											if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
												track(1, 25, `${item.phaseId}`, item.phaseName);
												setParentPhase({
													...parentPhase,
													id: item.phaseId,
													svgFileName: item.svgFileName,
												});
											}
										}}
										title={item.phaseName}
										tabIndex={0}
										aria-label={item.phaseName}
									>
										<span>
											{item.phaseName.length > phaseNameLength
												? `${item.phaseName.slice(0, phaseNameLength)}...`
												: item.phaseName}
										</span>
									</NavLink>
									{coOwners.includes(userAlias) && (
										<>
											<span
												className={
													item.isDrafted ? 'draftPhaseText' : 'nonDraftPhase'
												}
											>
												Draft
											</span>
											{item.isRefered ? (
												<div className="phaseReferredDiv">
													<span
														className="phaseReferred"
														title="Linked Phase"
													></span>
												</div>
											) : (
												<></>
											)}
											<span
												className="phaseEditDeletMore"
												tabIndex="0"
												onClick={() => {
													setUpdatePhaseId(item.phaseId);
													setShowPhaseUpdatePopUp(true);
													setSelectedPhase(item);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setUpdatePhaseId(item.phaseId);
														setShowPhaseUpdatePopUp(true);
														setSelectedPhase(item);
													}
												}}
												aria-label="More Options"
												role="button"
											>
												<Icon iconName="MoreVertical" />
												{updatePhaseId === item.phaseId &&
												showUpdatePhasePopUp ? (
													<UpdatePhasePopUp
														setShowPhaseUpdateModal={setShowPhaseUpdateModal}
														setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
														solutionMethodId={solutionMethodId}
														phaseId={updatePhaseId}
														setDeletePhase={setDeletePhase}
														phaseName={item.phaseName}
														projectTypeId={projectTypeId}
														parentPhaseId={parentPhase.id}
														setShowDeletePhaseModal={setShowDeletePhaseModal}
														setShowParentDeletePhaseModal={
															setShowParentDeletePhaseModal
														}
														setUpdatePhaseId={setUpdatePhaseId}
														className="phaseUpdatePopUp"
														type="parentPhase"
														parentId={parentId}
														selectedPhase={selectedPhase}
													/>
												) : (
													<></>
												)}
											</span>
										</>
									)}
								</div>
								<hr tabIndex={-1} aria-hidden="true" />
							</li>
						) : (
							<></>
						)}
						{!item.isDrafted ? (
							<li
								key={item.phaseId}
								className={
									coOwners.includes(userAlias) && item.isRefered
										? 'coOwner isRefferedPhase'
										: 'nonOwner'
								}
							>
								<div>
									<NavLink
										to={`${url}/phase/${phase.id}/parentPhase/${item.phaseId}`}
										key={`phase_${item.phaseId}`}
										onClick={() => {
											track(1, 25, `phase_${item.phaseName}`, item.phaseName);
											setParentPhase({
												...parentPhase,
												id: item.phaseId,
												svgFileName: item.svgFileName,
											});
										}}
										activeClassName="deliveryMenuActive"
										title={item.phaseName}
									>
										<span>{item.phaseName}</span>
									</NavLink>
									{coOwners.includes(userAlias) && (
										<>
											{item.isRefered ? (
												<div className="phaseReferredDiv">
													<span
														className="phaseReferred"
														title="Linked Phase"
													></span>
												</div>
											) : (
												<></>
											)}
											<span
												className="phaseEditDeletMore"
												tabIndex="0"
												onClick={() => {
													setUpdatePhaseId(item.phaseId);
													setShowPhaseUpdatePopUp(true);
													setSelectedPhase(item);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setUpdatePhaseId(item.phaseId);
														setShowPhaseUpdatePopUp(true);
														setSelectedPhase(item);
													}
												}}
												aria-label="More Options"
												role="button"
											>
												<Icon iconName="MoreVertical" />
												{updatePhaseId === item.phaseId &&
												showUpdatePhasePopUp ? (
													<UpdatePhasePopUp
														setShowPhaseUpdateModal={setShowPhaseUpdateModal}
														setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
														solutionMethodId={solutionMethodId}
														phaseId={updatePhaseId}
														setDeletePhase={setDeletePhase}
														phaseName={item.phaseName}
														projectTypeId={projectTypeId}
														parentPhaseId={parentPhase.id}
														setShowParentDeletePhaseModal={
															setShowParentDeletePhaseModal
														}
														setUpdatePhaseId={setUpdatePhaseId}
														className="phaseUpdatePopUp"
														type="parentPhase"
														parentId={parentId}
														setShowDeletePhaseModal={setShowDeletePhaseModal}
														selectedPhase={selectedPhase}
													/>
												) : (
													<></>
												)}
											</span>
										</>
									)}
								</div>
								<hr tabIndex={-1} aria-hidden="true" />
							</li>
						) : (
							<></>
						)}
					</>
				))}
			</ul>
			{showPhaseLockModal ? (
				<PhaseLockModal
					showPhaseLockModal={showPhaseLockModal}
					setShowPhaseLockModal={setShowPhaseLockModal}
					lockEmailId={lockEmailId}
					setLockEmailId={setLockEmailId}
				/>
			) : (
				<></>
			)}
			{showPhaseCreationModal && (
				<PhaseCreationModal
					showPhaseCreationModal={showPhaseCreationModal}
					setShowPhaseCreationModal={setShowPhaseCreationModal}
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					setPhaseCreated={setPhaseCreated}
					type="parentPhase"
					parentId={parentId}
					getParentSolutionMethodData={getParentSolutionMethodData}
					methodOwners={coOwners}
					parentPhaseCreated={parentPhaseCreated}
					setParentPhaseCreated={setParentPhaseCreated}
					phaseBuilderType="create"
					getSolutionMethodData={getSolutionMethodData}
				/>
			)}
			{showPhaseUpdateModal && (
				<PhaseUpdateModal
					showPhaseUpdateModal={showPhaseUpdateModal}
					setShowPhaseUpdateModal={setShowPhaseUpdateModal}
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					setPhaseCreated={setPhaseCreated}
					updatePhaseId={updatePhaseId}
					showPhaseLockModal={showPhaseLockModal}
					setShowPhaseLockModal={setShowPhaseLockModal}
					setLockEmailId={setLockEmailId}
					type="parentPhase"
					parentId={parentId}
					getParentSolutionMethodData={getParentSolutionMethodData}
					methodOwners={coOwners}
					parentPhaseCreated={parentPhaseCreated}
					setParentPhaseCreated={setParentPhaseCreated}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
					phaseBuilderType="update"
					getSolutionMethodData={getSolutionMethodData}
				/>
			)}
			{showPhaseMethoOwnerModal && (
				<MethodOwnerCollaborator
					showPhaseMethoOwnerModal={showPhaseMethoOwnerModal}
					setShowPhaseMethoOwnerModal={setShowPhaseMethoOwnerModal}
					solutionMethodId={solutionMethodId}
					type="parentPhase"
					parentId={parentId}
				/>
			)}
		</div>
	);
}

export default DeliveryManagement;
