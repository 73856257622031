import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function ComplexityModal({
	setShowRejectionModal,
	templateData,
	accepedtRejectedTemplate,
	setAccepedtRejectedTemplate,
	singleView,
	setSuccessMsg,
}) {
	const [modalShow, setModalShow] = useState(true);
	const [messagesType, setMessagesType] = useState({});
	const [rejectComment, setRejectComment] = useState('');
	const [error, setError] = useState(false);

	const history = useHistory();
	const { axiosPostService, axiosGetService } = useAxios();

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=approvalRejectionMessage&title=${encodeURIComponent(templateData.title)}`
		).then((response) => {
			message['approvalRejectionMessage'] = response.data;
		});
		setMessagesType(message);
	};

	const handleRejection = async () => {
		const postData = {
			id: templateData.id,
			stateId: 5,
			title: templateData.title,
			templateId: templateData.templateId,
			contributorEmail: templateData.contributorEmail,
			reviewerEmail: templateData.reviewerEmail,
			complexityId: templateData.complexityId,
			isLocalized: templateData.isLocalized,
			regionName: templateData.regionName,
			languageName: templateData.languageName,
			countryName: templateData.countryName,
			languageId: templateData.languageId,
			countryId: templateData.countryId,
			rejectedComments: rejectComment,
		};

		await axiosPostService('api/sdmtemplate/inserttemplateapprover', postData).then((response) => {
			const updatedArray = [];
			for (const id in accepedtRejectedTemplate) {
				updatedArray.push(accepedtRejectedTemplate[id]);
			}
			updatedArray.push(templateData.id);
			setAccepedtRejectedTemplate(updatedArray);
			setModalShow(false);
			setShowRejectionModal(false);
			setSuccessMsg(messagesType['approvalRejectionMessage']);
			if (singleView) {
				setTimeout(() => {
					history.push('/template/crowdsourcing/approve/all');
				}, 2000);
			}
		});
	};

	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="templateRejectModalPopUp reviewerRejectMessage"
			show={modalShow}
			onHide={() => {
				/* Don't call any function as we are showing modal when template is rejected*/
			}}
			size="md"
			centered
		>
			<Modal.Body>
				<p>{`Are you sure you want to reject "${templateData.title}" ?`}</p>
				<p className="reviewerRejectMessagePara">Let the reviewer know the reason for rejection </p>
				<textarea
					placeholder="Type here"
					value={rejectComment}
					onChange={(e) => {
						setRejectComment(e.target.value);
					}}
				/>
				{error ? <p className="errorMsg">Comment is required</p> : <></>}
				<Button
					className="templateRejectButton"
					onClick={() => {
						setModalShow(false);
						setShowRejectionModal(false);
					}}
				>
					No
				</Button>

				<Button
					onClick={() => {
						if (rejectComment) {
							setError(false);
							handleRejection();
						} else {
							setError(true);
						}
					}}
				>
					Yes
				</Button>
			</Modal.Body>
		</Modal>
	);
}

export default ComplexityModal;
