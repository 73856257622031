import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import SDMReducer from '../src/store/Reducers';
import thunk from 'redux-thunk';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// if (process.env.NODE_ENV !== 'production') {
// 	const axe = require('@axe-core/react');
// 	axe(React, ReactDOM, 1000);
// }
const rootReducer = combineReducers({
    SDMReducer: SDMReducer,    
});

const store = createStore(rootReducer,
    applyMiddleware(thunk)
);

ReactDOM.render(
	<Provider store={store}>
	<BrowserRouter>
		<App />
	</BrowserRouter></Provider>,
	document.getElementById('root')
);
