import React from 'react';
import { Icon } from '@fluentui/react';
import HtmlDiff from 'htmldiff-js';

import { handleTernaryCondition } from '../../helpers/Validation';
import ActivityRaciMatrix from '../ActivityRaciMatrix';
import { useHistory } from 'react-router-dom';

const CopyToComplexity = ({
	complexities,
	complexityName,
	publishComplexity,
	setPublishComplexity,
	selectedComplexityTab,
	setSelectedComplexityTab,
	complexitySectionModal,
	publishComplexityEntity,
	setPublishComplexityEntity,
	description,
	supportingActivities,
	toolsAndGuidance,
	tipsAndTechniques,
	activityComplexityData,
	setComplexitySectionModal,
	taskData,
	entryCriteriaData,
	exitCriteriaData,
	templateData,
	setSampleTemplates,
	deliverables
}) => {
	const history = useHistory();
	const getVersionDifference = (oldData, newData) => {
		return HtmlDiff.execute(
			handleTernaryCondition(oldData, oldData, ''),
			handleTernaryCondition(newData, newData, '')
		);
	};

	const renderEntryCriteriaData = (criteriaType) => {
		return (<>
			{

				(activityComplexityData[
					selectedComplexityTab
				].entryCriteriaList.some(
					(value) =>
						value.criteriaTypeName ===
						criteriaType
				) || entryCriteriaData.some(
					(value) =>
						value.criteriaTypeName ===
						criteriaType
				))

				&&
				(<>
					<>{criteriaType}</>
					{publishComplexityEntity[selectedComplexityTab]?.[
						'Entry Criteria'
					] ? (
						<>

							{activityComplexityData[
								selectedComplexityTab
							].entryCriteriaList.map((entryCriteria) => (
								<>

									{entryCriteria.criteriaTypeName === criteriaType && (<>
										{entryCriteriaData.some(
											({ name }) => name === entryCriteria.name
										) && (
												<li>

													{entryCriteria.name}

												</li>
											)
										}
										{!entryCriteriaData.some(
											({ name }) => name === entryCriteria.name
										) && (
												<li>

													<del class="diffmod">
														{entryCriteria.name}
													</del>

												</li>
											)}
									</>)
									}
								</>
							))
							}

							{entryCriteriaData.map((entryCriteria) => (
								<>
									{entryCriteria.criteriaTypeName === criteriaType && !activityComplexityData[
										selectedComplexityTab
									].entryCriteriaList.some(
										({ name }) => name === entryCriteria.name
									) && (
											<li>
												<ins class="diffmod">
													{entryCriteria.name}
												</ins>

											</li>
										)}
								</>
							))}
						</>

					) : (
						<>
							{activityComplexityData[selectedComplexityTab]
								.entryCriteriaList.length > 0 &&
								activityComplexityData[
									selectedComplexityTab
								].entryCriteriaList.map((entryCriteria) => (

									entryCriteria.criteriaTypeName === criteriaType && (<li>
										{entryCriteria.name}
									</li>)

								))}
						</>
					)}
				</>)}
		</>
		)
	}
	const renderExitCriteriaData = (criteriaType) => {
		return (<>
			{
				(activityComplexityData[
					selectedComplexityTab
				].exitCriteriaList.some(
					(value) =>
						value.criteriaTypeName ===
						criteriaType
				) || exitCriteriaData.some(
					(value) =>
						value.criteriaTypeName ===
						criteriaType
				))

				&&
				(<>
					<>{criteriaType}</>
					{publishComplexityEntity[selectedComplexityTab]?.[
						'Exit Criteria'
					] ? (
						<>

							{activityComplexityData[
								selectedComplexityTab
							].exitCriteriaList.map((exitCriteria) => (
								<>

									{exitCriteria.criteriaTypeName === criteriaType && (<>
										{exitCriteriaData.some(
											({ name }) => name === exitCriteria.name
										) && (
												<li>

													{exitCriteria.name}

												</li>
											)
										}
										{!exitCriteriaData.some(
											({ name }) => name === exitCriteria.name
										) && (
												<li>

													<del class="diffmod">
														{exitCriteria.name}
													</del>

												</li>
											)}
									</>)
									}
								</>
							))
							}

							{exitCriteriaData.map((exitCriteria) => (
								<>
									{exitCriteria.criteriaTypeName === criteriaType && !activityComplexityData[
										selectedComplexityTab
									].exitCriteriaList.some(
										({ name }) => name === exitCriteria.name
									) && (
											<li>
												<ins class="diffmod">
													{exitCriteria.name}
												</ins>

											</li>
										)}
								</>
							))}
						</>

					) : (
						<>
							{activityComplexityData[selectedComplexityTab]
								.exitCriteriaList.length > 0 &&
								activityComplexityData[
									selectedComplexityTab
								].exitCriteriaList.map((exitCriteria) => (

									exitCriteria.criteriaTypeName === criteriaType && (<li>
										{exitCriteria.name}
									</li>)

								))}
						</>
					)}
				</>)}
		</>
		)
	}


	return (
		<div>
			<div className="destinationComplexityContainer">
				<div></div>
				{publishComplexity.length > 0 && (
					<>
						<div class="existingHashTagsOfActivity">
							{activityComplexityData[selectedComplexityTab]?.hashTagsList.map(
								({ name }) => (
									<span
										key={name}
										onClick={() => {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												history.push(
													`/search?searchParam=${encodeURIComponent(name)}`
												);
											}
										}}
									>
										{name}
									</span>
								)
							)}
						</div>
						<ActivityRaciMatrix
							activityData={activityComplexityData[selectedComplexityTab]}
						/>
						<div className="innerContent" id="previewInnerContent">
							{activityComplexityData[selectedComplexityTab]
								?.activityDescription ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Description'
								] ? (
								<>
									<h2
										className="sub-heading"
										id="previewDesc"
										aria-label="Description"
									>
										Description
									</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: publishComplexityEntity[selectedComplexityTab]?.[
												'Description'
											]
												? getVersionDifference(
													activityComplexityData[selectedComplexityTab]
														.activityDescription,
													description
												)
												: activityComplexityData[selectedComplexityTab]
													?.activityDescription,
										}}
									/>
								</>
							) : null}

							{activityComplexityData[selectedComplexityTab]
								?.activitySupportingActivities ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Supporting Activities'
								] ? (
								<>
									<h2 className="sub-heading" id="previewSupportAct">
										Supporting Activities
									</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: publishComplexityEntity[selectedComplexityTab]?.[
												'Supporting Activities'
											]
												? getVersionDifference(
													activityComplexityData[selectedComplexityTab]
														.activitySupportingActivities,
													supportingActivities
												)
												: activityComplexityData[selectedComplexityTab]
													?.activitySupportingActivities,
										}}
									/>
								</>
							) : null}



							{activityComplexityData[selectedComplexityTab]?.entryCriteriaList ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Entry Criteria'
								] ? (
								<>
									<h2 className="sub-heading" id="previewEntryCriteria">
										Entry Criteria/Input
									</h2>
									<div id="previewTempList">


										<ul>
											{renderEntryCriteriaData('Entry Criteria')}
											{renderEntryCriteriaData('Input')}
										</ul>
									</div>
								</>
							) : null}
							{activityComplexityData[selectedComplexityTab]?.activityTasks ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Tasks'
								] ? (
								<>
									<h2 className="sub-heading" id="previewTasks">
										Tasks
									</h2>
									<div id="previewTempList">
										<ul>

											{publishComplexityEntity[selectedComplexityTab]?.[
												'Tasks'
											] ? (
												<>
													{activityComplexityData[
														selectedComplexityTab
													].tasksList.map((task) => (
														<>
															{taskData.some(
																({ name }) => name === task.name
															) && (
																	<li>

																		{task.name}

																	</li>
																)}
															{!taskData.some(
																({ name }) => name === task.name
															) && (
																	<li>

																		<del class="diffmod">
																			{task.name}
																		</del>

																	</li>
																)}
														</>
													))}

													{taskData.map((task) => (
														<>
															{!activityComplexityData[
																selectedComplexityTab
															].tasksList.some(
																({ name }) => name === task.name
															) && (
																	<li>
																		<ins class="diffmod">
																			{task.name}
																		</ins>

																	</li>
																)}
														</>
													))}
												</>
											) : (
												<>
													{activityComplexityData[selectedComplexityTab]
														.tasksList.length > 0 &&
														activityComplexityData[
															selectedComplexityTab
														].tasksList.map((task) => (
															<li>
																{task.name}
															</li>
														))}
												</>
											)}
										</ul>
									</div>
								</>
							) : null}

							{activityComplexityData[selectedComplexityTab]?.exitCriteriaList ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Exit Criteria'
								] ? (
								<>
									<h2 className="sub-heading" id="previewExitCriteria">
										Exit Criteria/Output
									</h2>
									<div id="previewTempList">
										<ul>
											{renderExitCriteriaData('Exit Criteria')}
											{renderExitCriteriaData('Output')}
										</ul>
									</div>
								</>
							) : null}
							{activityComplexityData[selectedComplexityTab]
								?.activityProcedureType.length > 0 ? (
								<>
									<h2 className="sub-heading" id="previewTopics">
										Procedures
									</h2>
									<div id="previewActivityProcedures">
										<ul>
											{activityComplexityData[
												selectedComplexityTab
											].activityProcedureType.map((procedure) => (
												<li>
													<a href={procedure.url} target="_blank">
														{procedure.name}
													</a>
												</li>
											))}
										</ul>
									</div>
								</>
							) : (
								<></>
							)}

							{activityComplexityData[selectedComplexityTab]?.activityTopics
								.length > 0 ? (
								<>
									<h2 className="sub-heading" id="previewTopics">
										Related Topics
									</h2>
									<div id="previewTopicList">
										<ul>
											{activityComplexityData[
												selectedComplexityTab
											].activityTopics.map((topic) => (
												<li>
													{
														<a href={topic.url} target="_blank">
															{topic.name}
														</a>
													}
												</li>
											))}
										</ul>
									</div>
								</>
							) : (
								<></>
							)}



							{activityComplexityData[selectedComplexityTab]
								?.activityDeliverables ||
								(activityComplexityData[selectedComplexityTab]
									?.activityTemplateDetails &&
									activityComplexityData[selectedComplexityTab]
										.activityTemplateDetails.length > 0) ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Deliverables'
								] ? (
								<>
									<h2 className="sub-heading" id="previewDeliver">
										Deliverables
									</h2>
									<>
										{(activityComplexityData[selectedComplexityTab]
											?.activityTemplateDetails &&
											activityComplexityData[selectedComplexityTab]
												.activityTemplateDetails.length > 0) ||
											publishComplexityEntity[selectedComplexityTab]?.[
											'Deliverables'
											] ? (
											<div id="previewTempList">
												<ul>
													{publishComplexityEntity[selectedComplexityTab]?.[
														'Deliverables'
													] ? (
														<>
															{activityComplexityData[
																selectedComplexityTab
															].activityTemplateDetails.map((template) => (
																<>
																	{templateData.some(
																		({ title }) => title === template.title
																	) && (
																			<li>
																				<a
																					className={
																						template.inProgress ? 'noClick' : ''
																					}
																					href={template.fileLocation}
																					target="_blank"
																				>
																					{template.title}
																				</a>
																			</li>
																		)}
																	{!templateData.some(
																		({ title }) => title === template.title
																	) && (
																			<li>
																				<a
																					className={
																						template.inProgress ? 'noClick' : ''
																					}
																					href={template.fileLocation}
																					target="_blank"
																				>
																					<del class="diffmod">
																						{template.title}
																					</del>
																				</a>
																			</li>
																		)}
																</>
															))}
															{templateData.map((template) => (
																<>
																	{!activityComplexityData[
																		selectedComplexityTab
																	].activityTemplateDetails.some(
																		({ title }) => title === template.title
																	) && (
																			<li>
																				<a
																					className={
																						template.inProgress ? 'noClick' : ''
																					}
																					href={template.fileLocation}
																					target="_blank"
																				>
																					<ins class="diffmod">
																						{template.title}
																					</ins>
																				</a>
																			</li>
																		)}
																</>
															))}
														</>
													) : (
														<>
															{activityComplexityData[selectedComplexityTab]
																.activityTemplateDetails.length > 0 &&
																activityComplexityData[
																	selectedComplexityTab
																].activityTemplateDetails.map((template) => (
																	<li>
																		{template.inProgress === true ? (
																			<a
																				className="noClick"
																				href={template.fileLocation}
																				target="_blank"
																			>
																				{template.title}
																			</a>
																		) : (
																			<a
																				href={template.fileLocation}
																				target="_blank"
																			>
																				{template.title}
																			</a>
																		)}
																		{template.sampleTemplates &&
																			template.sampleTemplates.length !== 0 &&
																			!template.inProgress ? (
																			<button
																				className="activitySampleButton"
																				tabIndex={0}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						setSampleTemplates({
																							enable: true,
																							id: template.id,
																							parentTemplate: template,
																						});
																					}
																				}}
																				onClick={() => {
																					setSampleTemplates({
																						enable: true,
																						id: template.id,
																						parentTemplate: template,
																					});
																				}}
																			>
																				(Sample)
																			</button>
																		) : (
																			<></>
																		)}
																	</li>
																))}
														</>
													)}
												</ul>
											</div>
										) : null}
										{/* {activityComplexityData[selectedComplexityTab]
											.activityDeliverables ? (
											<div
												id="previewActivityDeliverables"
												dangerouslySetInnerHTML={{
													__html:
														activityComplexityData[selectedComplexityTab]
															.activityDeliverables,
												}}
											/>
										) : null} */}
										{activityComplexityData[selectedComplexityTab]
											?.activityDeliverables ||
											publishComplexityEntity[selectedComplexityTab]?.[
											'Deliverables'
											] ? (
											<>
												{/* <h2
													className="sub-heading"
													id="previewDesc"
													aria-label="Description"
												>
													Description
												</h2> */}
												<div
													dangerouslySetInnerHTML={{
														__html: publishComplexityEntity[selectedComplexityTab]?.[
															'Deliverables'
														]
															? getVersionDifference(
																activityComplexityData[selectedComplexityTab]
																	.activityDeliverables,
																deliverables
															)
															: activityComplexityData[selectedComplexityTab]
																?.activityDeliverables,
													}}
												/>
											</>
										) : null}
									</>
								</>
							) : (
								<></>
							)}

							{activityComplexityData[selectedComplexityTab]
								?.activityToolsCount > 0 ||
								publishComplexityEntity[selectedComplexityTab]?.['Tools'] ? (
								<>
									<h2 className="sub-heading" id="previewTool">
										Tools
									</h2>
									<ul>
										{publishComplexityEntity[selectedComplexityTab]?.[
											'Tools'
										] ? (
											<>
												{activityComplexityData[
													selectedComplexityTab
												].activityToolsList.map((tool) => (
													<>
														{toolsAndGuidance.some(
															(item) => JSON.parse(item).name === tool.name
														) &&
															tool.isSelected && (
																<li key={tool.name}>
																	<a href={tool.url} target="_blank">
																		{tool.name}
																	</a>
																</li>
															)}
														{!toolsAndGuidance.some(
															(item) => JSON.parse(item).name === tool.name
														) &&
															tool.isSelected && (
																<li key={tool.name}>
																	<a href={tool.url} target="_blank">
																		<del class="diffmod">{tool.name}</del>
																	</a>
																</li>
															)}
													</>
												))}
												{toolsAndGuidance.map((tool) => (
													<>
														{!activityComplexityData[
															selectedComplexityTab
														].activityToolsList.some(
															(item) =>
																item.name === JSON.parse(tool).name &&
																item.isSelected
														) && (
																<li key={JSON.parse(tool).name}>
																	<a href={JSON.parse(tool).url} target="_blank">
																		<ins class="diffmod">
																			{JSON.parse(tool).name}
																		</ins>
																	</a>
																</li>
															)}
													</>
												))}
											</>
										) : (
											<>
												{activityComplexityData[
													selectedComplexityTab
												].activityToolsList.map((toolsData) => (
													<>
														{toolsData.isSelected ? (
															<li>
																<a href={toolsData.url} target="_blank">
																	{toolsData.name}
																</a>
															</li>
														) : (
															<></>
														)}
													</>
												))}
											</>
										)}
									</ul>
								</>
							) : (
								<></>
							)}

							{activityComplexityData[selectedComplexityTab]
								?.activityTipsAndTechniques ||
								publishComplexityEntity[selectedComplexityTab]?.[
								'Guidance, Tips and Techniques'
								] ? (
								<>
									<h2 className="sub-heading" id="previewTipstech">
										Guidance, Tips and Techniques
									</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: publishComplexityEntity[selectedComplexityTab]?.[
												'Guidance, Tips and Techniques'
											]
												? getVersionDifference(
													activityComplexityData[selectedComplexityTab]
														.activityTipsAndTechniques,
													tipsAndTechniques
												)
												: activityComplexityData[selectedComplexityTab]
													.activityTipsAndTechniques,
										}}
									/>
								</>
							) : null}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CopyToComplexity;
