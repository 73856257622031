import React, { useState, useEffect } from 'react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAppInsights } from '../../context/TelemetryContext';
import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	complexityId,
	updatedValue,
	setUpdatedValue,
	type,
	setSelectedRaci,
	pageType
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown?.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = updatedValue[complexityId][type].filter(
			({ id }) => id === item.id
		);
		if (filteredData.length > 0) {
			const updatedData = updatedValue[complexityId][type].filter(
				({ id }) => id !== item.id
			);
			setUpdatedValue((prevState) => ({
				...prevState,
				[complexityId]: { ...updatedValue[complexityId], [type]: updatedData },
			}));
		} else {
			setUpdatedValue((prevState) => ({
				...prevState,
				[complexityId]: {
					...updatedValue[complexityId],
					[type]: [...updatedValue[complexityId][type], item],
				},
			}));
		}
	};

	const findSelectedTools = (checkId) => {
		const filterId = updatedValue[complexityId][type].filter(
			({ id }) => id === checkId
		);
		if (filterId.length > 0) {
			return true;
		}
		return false;
	};

	const updateSearchValue = (text) => {
		setSearchValue((prevState) => ({ ...prevState, [className]: text }));
	};

	return (
		<div title={enableDropdown ? className : ''} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => {
						updateSearchValue(e.target.value);
					}}
					value={searchValue[className]}
					onFocus={() => {
						setVisible(true);
						setSelectedRaci(className);
					}}
					placeholder={
						updatedValue[complexityId][type].length > 0
							? `${updatedValue[complexityId][type].length} selected`
							: `Type something to search or select from list`
					}
				/>
				<Icon
					iconName="ChevronDown"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				/>
				<div>
					<div
						className={`dropDownList  ${
							pageType="Activity" ? 'mr-0 menuedititem templateDropdownclass':''
						}`}
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}

								return (
									<li title={item.name} key={item.id}>
										<label className="NotificationChkboxContainer">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedTools(item.id)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
