import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dompurify from 'dompurify';

import loadable from '@loadable/component';
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { MessageBar, MessageBarType } from '@fluentui/react';
import $ from 'jquery';
import Dropdown from '../../../../../components/Dropdown';
import FileDropModal from '../../../../../components/TemplateCard/FileDropModal';
import PreviewModal from '../../../../../components/TemplateCard/PreviewModal';
import SampleTemplateModal from '../../../../../components/TemplateCard/SampleTemplateModal';
import { useAxios } from '../../../../../context/AxiosContext';
import AddBreadCrumb from '../../../../../helpers/BreadcrumbService';
import { useAuth } from '../../../../../context/UserAuthContext';
import { useLeftMenuCollapse } from '../../../../../context/LeftMenuCollapseContext';

import Spinner from '../../../../../helpers/Spinner';
import { getOtherUserProfile } from '../../../../../helpers/GraphService';
import { axiosTokenService } from '../../../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../../../context/TelemetryContext';
import ActivityTemplateHisotry from '../../../../../components/ActivityTemplateHisotry';
import ActivityRaciMatrix from '../../../../../components/ActivityRaciMatrix';
import { TabTitle } from '../../../../../utils/GeneralFunctions'
import EntryCriteriaMyContributions from '../../MyContributions/Activity/EntryCriteriaMyContributions';
import ExitCriteriaMyContributions from '../../MyContributions/Activity/ExitCriteriaMyContributions';
import TaskMyContributions from '../../MyContributions/Activity/TaskMyContributions';
import TemporaryCkAlert from '../../../../../components/CkEditor/TemporaryCkAlert';
import decryptAES256 from '../../../../../components/CkEditor/DecryptionComponent';
import { useConfig } from '../../../../../context/ConfigContext';

const DescriptionCkEditor = loadable(
	() =>
		import(
			'../../../../../components/CkEditor/MyContributionActivity/MyContributions/DescriptionPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const SupportAndActivitiesCkEditor = loadable(
	() =>
		import(
			'../../../../../components/CkEditor/MyContributionActivity/MyContributions/SupportingActivitiesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const TipsAndTechniquesCkEditor = loadable(
	() =>
		import(
			'../../../../../components/CkEditor/MyContributionActivity/MyContributions/TipsAndTechniquesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);
const DeliverablesCkEditor = loadable(
	() =>
		import(
			'../../../../../components/CkEditor/MyContributionActivity/MyContributions/DeliverablesPreview'
		),
	{
		fallback: (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		),
	}
);

function MyApprovalsPreview() {
	const { ckEdKEY } = useConfig();
	const contributorInfo = [];
	const graphUserData = [];
	const userDataMap = new Map();
	const htmlSanitizer = dompurify.sanitize;
	const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
		useLeftMenuCollapse();
	const [ckEditorUsers, setCkEditorUsers] = useState([]);
	const { user } = useAuth();
	const currentUser = user.profile.mail;
	const { track } = useAppInsights();
	const { artifactType, id, artifactId, stateId } = useParams();
	const { axiosGetService } = useAxios();
	const complexityId = Number(
		queryString.parse(window.location.search).complexity
	);
	const [licenseKey, setLicenseKey] = useState(null);
	const [activityContent, setActivityContent] = useState(null);
	const [contributorsData, setContributorsData] = useState(null);
	const [toolsUrl, setToolsUrl] = useState({});
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');

	// template Preview Data
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	const [templateData, setTemplateData] = useState([]);
	const [topicData, setTopicData] = useState([]);
	const [procedureData, setProcedureData] = useState([]);
	const [complexities, setComplexities] = useState([]);
	const [complexityName, setComplexityName] = useState({
		name: '',
		type: 'option',
		id: '',
	});

	const [ckEditorUserMap, setCkEditorUserMap] = useState({});
	const [
		selectedToolsAndGuidanceSuggestions,
		setSelectedToolsAndGuidanceSuggestions,
	] = useState([]);
	const [
		contributiorToolsAndGuidanceSuggestion,
		setContributiorToolsAndGuidanceSuggestion,
	] = useState([]);
	const [toolsAndGuidanceSuggestions, setToolsAndGuidanceSuggestions] =
		useState([]);
	const [adminToolsAndGuidance, setAdminToolsAndGuidance] = useState([]);
	const [contributiorToolsId, setContributiorToolsId] = useState([]);
	const [toolsReviewedIds, setToolsReviewedIds] = useState({});
	const [reviewerInfo, setReviewerInfo] = useState(null);
	const [templateSidebarHeight, setTemplateSidebarHeight] = useState('239px');
	const [noContribution, setNoContribution] = useState(null);
	const [entryCriteriaData, setEntryCriteriaData] = useState([]);
	const [exitCriteriaData, setExitCriteriaData] = useState([]);
	const [taskData, setTaskData] = useState([]);
	const [currentEntryTab, setCurrentEntryTab] = useState('tab-entry-hist');
	const [currentExitTab, setCurrentExitTab] = useState('tab-exit-hist');

	const getTemplatesInfo = (data) => {
		let contributedTemplates = [];
		if (data.resultCalloutJson && data.resultCalloutJson.templateSuggestions) {
			contributedTemplates = [...data.resultCalloutJson.templateSuggestions];
		}
		const filteredTemplates = data.activityTemplateDetails.filter(
			({ id }) =>
				!contributedTemplates.some((item) => JSON.parse(item).id === id)
		);
		setTemplateData(filteredTemplates);
	};

	const getTemplateSidebarHeight = () => {
		const height = $('.templateContentContainer').height();
		setTemplateSidebarHeight(height + 63);
	};

	const getActivityContents = async () => {
		await axiosGetService(
			`api/sdmactivity/getactivityapproverspreview?id=${id}&activityId=${artifactId}&complexityId=${complexityId}`
		)
			.then((response) => {
				if (!response.data) {
					setNoContribution('noData');
				} else {
					loadBreadcrumb();
					setContributorsData(response.data);
					setActivityContent(JSON.parse(response.data.activityContent));
					const mappedComplexities = [
						{
							name: response.data.complexityName,
							id: response.data.complexityId,
							option: 'type',
						},
					];
					setComplexityName({
						name: response.data.complexityName,
						id: response.data.complexityId,
						type: 'option',
					});
					getTemplatesInfo(response.data);
					setTopicData(response.data.activityTopics);
					setProcedureData(response.data.activityProcedureType);
					setComplexities(mappedComplexities);
					const contributionsToolsId = [];
					if (
						response.data.resultCalloutJson &&
						response.data.resultCalloutJson.toolsAndGuidance
					) {
						setSelectedToolsAndGuidanceSuggestions(
							response.data.resultCalloutJson.toolsAndGuidance
						);
						const toolsSuggestions =
							response.data.resultCalloutJson.toolsAndGuidance;
						const reviewedData = {};
						for (const id in toolsSuggestions) {
							contributionsToolsId.push(
								JSON.parse(toolsSuggestions[id]).toolsId
							);
							reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
								toolsSuggestions[id]
							).state;
						}
						setToolsReviewedIds(reviewedData);
					}
					if (
						response.data.resultReviewedJson &&
						response.data.resultReviewedJson.approvedToolsSuggestions
					) {
						const toolsSuggestions =
							response.data.resultReviewedJson.approvedToolsSuggestions;
						const reviewedData = {};
						for (const id in toolsSuggestions) {
							reviewedData[JSON.parse(toolsSuggestions[id]).id] = JSON.parse(
								toolsSuggestions[id]
							).state;
						}
						setToolsReviewedIds(reviewedData);
					}
					setContributiorToolsId(contributionsToolsId);

					const contributorToolsData = JSON.parse(response.data.activityContent)
						.ToolsAndGuidance
						? JSON.parse(response.data.activityContent).ToolsAndGuidance.split(
							','
						)
						: [];
					setContributiorToolsAndGuidanceSuggestion(contributorToolsData);

					const url = {};
					for (const id in response.data.activityToolsList) {
						if (
							contributionsToolsId.includes(
								response.data.activityToolsList[id].id
							)
						) {
							response.data.activityToolsList[id].isSelected = true;
						}
						url[response.data.activityToolsList[id].id] =
							response.data.activityToolsList[id].url;
					}
					setToolsUrl(url);

					setToolsAndGuidanceSuggestions(response.data.activityToolsList);
				}

				getEntryCriteriaData(response.data);
				getExitCriteriaData(response.data);
				getTaskData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getEntryCriteriaData = async (data) => {

		let contributedEntryCriteria = [];
		if (data?.resultCalloutJson && data?.resultCalloutJson.entryCriteriaSuggestions) {
			contributedEntryCriteria = [...data.resultCalloutJson.entryCriteriaSuggestions];
		}
		const filteredEntryCriteria = data?.entryCriteriaList.filter(
			({ id }) =>
				!contributedEntryCriteria.some((item) => JSON.parse(item).id === id)
		);
		setEntryCriteriaData(filteredEntryCriteria);

	};

	const getExitCriteriaData = async (data) => {
		let contributedExitCriteria = [];
		if (data?.resultCalloutJson && data?.resultCalloutJson.exitCriteriaSuggestions) {
			contributedExitCriteria = [...data.resultCalloutJson.exitCriteriaSuggestions];
		}
		const filteredExitCriteria = data?.exitCriteriaList.filter(
			({ id }) =>
				!contributedExitCriteria.some((item) => JSON.parse(item).id === id)
		);
		setExitCriteriaData(filteredExitCriteria);
	};

	const getTaskData = async (data) => {
		let contributedTask = [];
		if (data?.resultCalloutJson && data?.resultCalloutJson.taskSuggestions) {
			contributedTask = [...data.resultCalloutJson.taskSuggestions];
		}
		const filteredTask = data?.taskList.filter(
			({ id }) =>
				!contributedTask.some((item) => JSON.parse(item).id === id)
		);
		setTaskData(filteredTask);

	};

	const getContributor = (suggestions) => {
		for (const id in suggestions) {
			const userEmail = JSON.parse(suggestions[id]).authorId;
			if (userEmail && !userDataMap.has(userEmail)) {
				graphUserData.push(userEmail);
				userDataMap.set(userEmail, 1);
			}
		}
	};

	const getUsersInfo = async () => {
		if (contributorsData.resultCalloutJson) {
			getContributor(contributorsData.resultCalloutJson.description);
			getContributor(contributorsData.resultCalloutJson.supportingActivities);
			getContributor(contributorsData.resultCalloutJson.toolsAndGuidance);
			getContributor(contributorsData.resultCalloutJson.tipsAndTechniques);
			getContributor(contributorsData.resultCalloutJson.templateSuggestions);
			getContributor(contributorsData.resultCalloutJson.entryCriteriaSuggestions);
			getContributor(contributorsData.resultCalloutJson.exitCriteriaSuggestions);
			getContributor(contributorsData.resultCalloutJson.taskSuggestions);
			getContributor(contributorsData.resultCalloutJson.deliverables);
		}

		if (!userDataMap.has(currentUser)) {
			graphUserData.push(currentUser);
			userDataMap.set(currentUser, 1);
		}
		const accessToken = await axiosTokenService();
		const userProfileMapping = {};
		for (const id in graphUserData) {
			const userEmail = graphUserData[id];
			const userData = await getOtherUserProfile(accessToken, userEmail);
			contributorInfo.push({
				id: userData.mail,
				name: userData.firstName + ' ' + userData.surname,
				avatar: userData.photo,
			});
			userProfileMapping[userData.mail] = [
				userData.firstName + ' ' + userData.surname,
				userData.photo,
			];
		}
		setCkEditorUserMap(userProfileMapping);
		setCkEditorUsers(contributorInfo);
		if (contributorsData.reviewerEmail) {
			await getOtherUserProfile(
				accessToken,
				contributorsData.reviewerEmail
			).then((response) => {
				setReviewerInfo(response.firstName + ' ' + response.surname);
			});
		}
	};

	const getToolsReviewerComment = (suggestionId, comments) => {
		if (comments && comments.toolsAndGuidance) {
			for (const comment of comments.toolsAndGuidance) {
				if (JSON.parse(comment).id === suggestionId) {
					return `Comment from reviewer : ${JSON.parse(comment).comment}`;
				}
			}
		} else {
			return ' ';
		}
	};

	const getLicense = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=CKEditorLicenseKey`
		);
		const decryptedLicenseKey = await decryptAES256(response.data[0].value,ckEdKEY);
		setLicenseKey(decryptedLicenseKey);
	};
	const getToolsConatinerHeight = () => {
		const height = $('.ToolsContentContainer').height();
		setToolsSidebarHeight(height + 63);
	};

	useEffect(() => {
		getToolsConatinerHeight();
	});

	useEffect(() => {
		getLicense();
	}, []);

	useEffect(() => {
		if (contributorsData) {
			getUsersInfo();
		}
	}, [contributorsData]);

	useEffect(() => {
		getTemplateSidebarHeight();
	});

	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${artifactId}&itemTypeId=2
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=true&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());

			const parentBreadcrumb = {
				title: breadcrumbTitle[1],
				type: 'projectType',
				path: `/mycontributions`,
				parent: ['home'],
			};

			const projectBreadcrumb = {
				title: breadcrumbTitle[2] + ' - Historical Preview',
				type: 'projectType',
				path: window.location.pathname,
				parent: 'myContributions',
			};

			const newBreadcrumbs = AddBreadCrumb(breadcrumbs.slice(0, 1), [
				parentBreadcrumb,
				projectBreadcrumb,
			]);
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[2] + ' - Historical Preview');
		});
	};

	useEffect(() => {
		if (activityContent) {
			setActivityContent(null);
			setContributorsData(null);
			setCkEditorUsers([]);
		}
		getActivityContents();
	}, [artifactType, id, artifactId, stateId, complexityId]);

	if (noContribution === 'noData') {
		return (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				You did not make any contribution to this Activity.
			</MessageBar>
		);
	}

	if (
		!activityContent ||
		ckEditorUsers.length < 1 ||
		!contributorsData ||
		!licenseKey
	) {
		return (
			<div className="spinner">
				<Spinner animation="border" />
			</div>
		);
	}
	return (
		<div className="historicalPreview">
			<div className="activityLandinPage">
				<div className="activityMainContainer">
					<div className="activityDescriptionDetails">
						<div className="activityDescTitlePart">
							<div className="activityTitleRightPart">
								<span className="activityVersion">
									{`Document Version: ${contributorsData.activityVersion},`}
									&nbsp;&nbsp;{`${contributorsData.date} `}
								</span>
								<div className="activityComplexity">
									<span>Complexity:</span>
									<Dropdown
										className="complexityDisabled"
										data={complexities}
										setValue={setComplexityName}
										value={complexityName}
										enableDropdown={false}
									/>
								</div>
								{reviewerInfo ? (
									<p className="reviewerNameLabel">
										Reviewer Name:&nbsp;{reviewerInfo}
									</p>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className="descOfActivity">
							<h4
								title={contributorsData.activityName}
								className="activityLandingPageTitle"
							>
								{contributorsData.activityName}
							</h4>
							<ActivityRaciMatrix activityData={contributorsData} />
							<div className="innerContent" id="innerContent">
								{activityContent.Description ? (
									<>
										<h6 className="sub-heading" id="desc"
											aria-label='Description'>
											Description
										</h6>
										<TemporaryCkAlert width={'77'}/>
										<DescriptionCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.Description}
											initalSuggestions={
												contributorsData.resultCalloutJson.description
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													.approvedDescSuggestions
											}
											licenseKey={licenseKey}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments.description
													? contributorsData.resultApproverComments.description
													: []
											}
											previewType="reviews"
										/>
									</>
								) : null}

								{activityContent.SupportingActivities ? (
									<>
										<h6 className="sub-heading" id="supportAct">
											Supporting Activities
										</h6>
										<TemporaryCkAlert width={'77'}/>

										<SupportAndActivitiesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.SupportingActivities}
											initalSuggestions={
												contributorsData.resultCalloutJson.supportingActivities
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													.approvedSupportSuggestions
											}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments
														.supportingActivities
													? contributorsData.resultApproverComments
														.supportingActivities
													: []
											}
											licenseKey={licenseKey}
											previewType="reviews"
										/>
									</>
								) : null}


								{entryCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.entryCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Entry Criteria/Input</span>
										</h2>

										<EntryCriteriaMyContributions
											criteriaData={entryCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.entryCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.entryCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedEntryCriteriaSuggestions
													: []
											}
											criteria="entry"
											currentTab={currentEntryTab}
											setCurrentTab={setCurrentEntryTab}
										/>
									</div>
								) : <></>}
								{taskData.length > 0 || (
									contributorsData.resultCalloutJson.taskSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Task</span>
										</h2>

										<TaskMyContributions
											taskData={taskData}
											toolsSidebarHeight={templateSidebarHeight}
											taskSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.taskSuggestions
													? contributorsData.resultCalloutJson
														.taskSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedTaskSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedTaskSuggestions
													: []
											}
										/>
									</div>
								) : <></>}

								{exitCriteriaData.length > 0 || (
									contributorsData.resultCalloutJson.exitCriteriaSuggestions?.length > 0) ? (
									<div className="addingTemplatesEditAcivity">
										<h2 className="sub-heading" id="deliver">
											<span>Exit Criteria/Output</span>
										</h2>

										<ExitCriteriaMyContributions
											criteriaData={exitCriteriaData}
											toolsSidebarHeight={templateSidebarHeight}
											criteriaSuggestions={
												contributorsData.resultCalloutJson &&
													contributorsData.resultCalloutJson.exitCriteriaSuggestions
													? contributorsData.resultCalloutJson
														.exitCriteriaSuggestions
													: []
											}
											ckEditorUserMap={ckEditorUserMap}
											reviewedCriteriaSuggestions={
												contributorsData.resultReviewedJson
													? contributorsData.resultReviewedJson
														.approvedExitCriteriaSuggestions
													: []
											}
											criteria="exit"
											currentTab={currentExitTab}
											setCurrentTab={setCurrentExitTab}
										/>
									</div>
								) : <></>}


								{procedureData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Procedures
											<div className="nonEditableSection" tabIndex={0}>
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{procedureData.length > 0 ? (
											<div id="activityProcedure">
												<ul>
													{procedureData.map((procedure) => (
														<li>
															{
																<a href={procedure.url} target="_blank">
																	{procedure.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}
								{topicData.length > 0 ? (
									<div className="nonEditable">
										<h2 className="sub-heading" id="deliver">
											Related Topics
											<div className="nonEditableSection" tabIndex={0}>
												<span className="nonEditableIcon"></span>
												<div class="nonEditableIconToolTip">
													<span class="nonEditablaToolTipArrow"></span>
													<span>
														You can not edit the contents of this text
													</span>
												</div>
											</div>
										</h2>
										{topicData.length > 0 ? (
											<div id="topicList">
												<ul>
													{topicData.map((topic) => (
														<li>
															<a href={topic.url} target="_blank">
																{topic.name}
															</a>
														</li>
													))}
												</ul>
											</div>
										) : null}
									</div>
								) : (
									<></>
								)}

								<div className="addingTemplatesEditAcivity">
								<TemporaryCkAlert width={'77'}/>

									<h2 className="sub-heading" id="deliver">
										<span>Deliverables</span>
									</h2>
									<ActivityTemplateHisotry
										templateData={templateData}
										toolsSidebarHeight={templateSidebarHeight}
										templateSuggestions={
											contributorsData.resultCalloutJson &&
												contributorsData.resultCalloutJson.templateSuggestions
												? contributorsData.resultCalloutJson.templateSuggestions
												: []
										}
										ckEditorUserMap={ckEditorUserMap}
										reviewedTemplateSuggestions={
											contributorsData.resultReviewedJson
												? contributorsData.resultReviewedJson
													.approvedTemplateSuggestions
												: []
										}
										enableTemplatePreview={enableTemplatePreview}
										setEnableTemplatePreview={setEnableTemplatePreview}
									/>
									{/* {contributorsData.activityDeliverables ? (
										<div
											id="activityDeliverables"
											dangerouslySetInnerHTML={{
												__html: htmlSanitizer(
													contributorsData.activityDeliverables
												),
											}}
										/>
									) : null} */}
									{activityContent.Deliverables ? (
										<>
											<div className='deliverables-container mycontribution-pr'>
											<TemporaryCkAlert width={'77'}/>

												<h2 className="sub-heading" id="desc"
													aria-label='Description'>
													Description
												</h2>
												<DeliverablesCkEditor
													users={ckEditorUsers}
													currentUser={currentUser}
													initialData={activityContent.Deliverables}
													initalSuggestions={
														contributorsData.resultCalloutJson.deliverables
													}
													reviewedSuggestions={
														contributorsData.resultReviewedJson
															.approvedDeliverablesSuggestions
													}
													licenseKey={licenseKey}
													comment={
														contributorsData.resultApproverComments &&
															contributorsData.resultApproverComments.deliverables
															? contributorsData.resultApproverComments.deliverables
															: []
													}
													previewType="reviews"
												/>
											</div>
										</>
									) : null}
								</div>

								<h2 className="sub-heading" id="tool">
									Tools
								</h2>
								<div className="customToolsEditor">
									<div className="toolsEditorMainContainer">
										<div className="ToolsContentContainer">
											<ul>
												{contributorsData.activityToolsList.map((tools) => (
													<>
														{tools.isSelected &&
															!contributiorToolsId.includes(tools.id) ? (
															<li key={tools.id}>
																<a href={tools.url} target="_blank">
																	{tools.name}
																</a>
															</li>
														) : (
															<></>
														)}
													</>
												))}
												{selectedToolsAndGuidanceSuggestions.map((item) => (
													<>
														{!adminToolsAndGuidance.includes(
															JSON.parse(item).toolsId
														) ? (
															<li
																className={
																	JSON.parse(item).suggestionType === 'deletion'
																		? 'userDeletedToolsAndGuidance'
																		: 'userSelectedToolsAndGuidance'
																}
															>
																<div className={'userAddedItem'}>
																	<a href={toolsUrl[JSON.parse(item).toolsId]}>
																		{JSON.parse(item).name}
																	</a>
																</div>
															</li>
														) : (
															<li
																className={`adminToolsAndGuidance ${JSON.parse(item).suggestionType === 'deletion'
																	? 'userDeletedToolsAndGuidance'
																	: 'none'
																	}`}
															>
																<a
																	href={toolsUrl[JSON.parse(item).toolsId]}
																	target="_blank"
																>
																	{JSON.parse(item).name}
																</a>

																{JSON.parse(item).suggestionType ===
																	'deletion' ? (
																	<div className="sideBarCard">
																		<div className="sideBarCardHeader">
																			<img
																				className="userImg"
																				src={
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][1]
																				}
																				alt=""
																			/>
																			<span>
																				{
																					ckEditorUserMap[
																					JSON.parse(item).authorId
																					][0]
																				}
																			</span>
																		</div>
																		<div className="itemStatus">
																			<span>Remove</span>&nbsp;
																			{JSON.parse(item).name}
																		</div>
																	</div>
																) : (
																	<></>
																)}
															</li>
														)}
													</>
												))}
											</ul>
										</div>
									</div>
									<div
										className="toolsSidebar"
										style={{ height: toolsSidebarHeight }}
									>
										{selectedToolsAndGuidanceSuggestions.map((item) => (
											<>
												{toolsReviewedIds[JSON.parse(item).id] ===
													'accepted' ? (
													<div
														className={`sideBarCard acceptedSideBarCard ${JSON.parse(item).suggestionType === 'insertion'
															? 'userAddedItem'
															: 'userDeletedToolsAndGuidance'
															}`}
													>
														<div className="sideBarCardHeader">
															<img
																className="userImg"
																src={
																	ckEditorUserMap[JSON.parse(item).authorId][1]
																}
																alt=""
															/>
															<span>
																{ckEditorUserMap[JSON.parse(item).authorId][0]}
															</span>
															<span className="suggestionStatus">Approved</span>
														</div>
														<div className="itemStatus">
															<span>
																{JSON.parse(item).suggestionType === 'insertion'
																	? 'Add'
																	: 'Remove'}
															</span>
															<span>&nbsp;"{JSON.parse(item).name}"</span>
														</div>
													</div>
												) : (
													<div
														className={`sideBarCard rejectedSideBarCard ${JSON.parse(item).suggestionType === 'insertion'
															? 'userAddedItem'
															: 'userDeletedToolsAndGuidance'
															}`}
													>
														<div className="sideBarCardHeader">
															<img
																className="userImg"
																src={
																	ckEditorUserMap[JSON.parse(item).authorId][1]
																}
																alt=""
															/>
															<span>
																{ckEditorUserMap[JSON.parse(item).authorId][0]}
															</span>
															<span className="suggestionStatus myContributionSuggestionStatus">
																Rejected
															</span>

															<div className="itemStatus">
																<span>
																	{JSON.parse(item).suggestionType ===
																		'insertion'
																		? 'Add'
																		: 'Remove'}
																</span>
																<span>&nbsp;"{JSON.parse(item).name}"</span>
															</div>
														</div>
													</div>
												)}
											</>
										))}
									</div>
								</div>

								{activityContent.TipsAndTechniques ? (
									<>
										<h6 className="sub-heading" id="tipstech">
											Guidance, Tips and Techniques
										</h6>
										<TemporaryCkAlert width={'77'}/>

										<TipsAndTechniquesCkEditor
											users={ckEditorUsers}
											currentUser={currentUser}
											initialData={activityContent.TipsAndTechniques}
											initalSuggestions={
												contributorsData.resultCalloutJson.tipsAndTechniques
											}
											reviewedSuggestions={
												contributorsData.resultReviewedJson
													.approvedTipsSuggestions
											}
											comment={
												contributorsData.resultApproverComments &&
													contributorsData.resultApproverComments
														.tipsAndTechniques
													? contributorsData.resultApproverComments
														.tipsAndTechniques
													: []
											}
											licenseKey={licenseKey}
											previewType="reviews"
										/>
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MyApprovalsPreview;
