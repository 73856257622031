import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import Contributions from './Contribution';
import Review from './Review';
import Approve from './Approve';

const TopicsHistory = ({
	showTopicsCrowdSourcingHistory,
	setShowTopicsCrowdSourcingHistory,
	historyData,
	versionDetails,
}) => {

	const tabs = ['Contributor', 'Reviewer', 'Approver'];
	const [selectedTab, setSelectedTab] = useState('Contributor');
	const [reviewerInfo, setReviewerInfo] = useState(null);

	const getUserInfo = async (email) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, email).then((response) => {
			setReviewerInfo(response.firstName + ' ' + response.surname);
		});
	};

	const component = {
		Contributor: (
			<Contributions
				topicDescriptionData={historyData[0]}
				reviewerInfo={reviewerInfo}
				setReviewerInfo={setReviewerInfo}
			/>
		),
		Reviewer: (
			<Review
				topicDescriptionData={historyData[1]}
				reviewerInfo={reviewerInfo}
				setReviewerInfo={setReviewerInfo}
			/>
		),
		Approver: (
			<Approve
				topicDescriptionData={historyData[2]}
				reviewerInfo={reviewerInfo}
				setReviewerInfo={setReviewerInfo}
			/>
		),
	};

	useEffect(() => {
		if (
			selectedTab === 'Reviewer' &&
			historyData[1] &&
			historyData[1].reviewerEmail
		) {
			getUserInfo(historyData[1].reviewerEmail);
		} else if (
			selectedTab === 'Approver' &&
			historyData[1] &&
			historyData[1].approverEmail
		) {
			getUserInfo(historyData[1].approverEmail);
		} else {
			setReviewerInfo(null);
		}
	}, [selectedTab]);

	return (
		<Modal
			dialogClassName={'topicsCrowdsourceHistoryModal'}
			show={true}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				setShowTopicsCrowdSourcingHistory(false);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<div>
						<h5>Historical Preview</h5>
						<div>
							<span>
								Document Version:&nbsp;{versionDetails.versionNumber},&nbsp;
								{versionDetails.modifiedDate}
							</span>
						</div>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="topicsCrowdsourcingHistoryModalContainer historyContainer">
					<div className="topicsMenuContainer">
						<ul className="topicsMenu">
							{tabs.map((item) => (
								<li
									name={item}
									tabIndex="0"
									className={
										selectedTab === item
											? 'selectedTopicMenu'
											: 'notSelectedTopicMenu'
									}
									onClick={() => {
										setSelectedTab(item);
									}}
								>
									<span className="consultingMenu">{item}</span>
								</li>
							))}
						</ul>
						<div className="topicDescTitlePart">
							{reviewerInfo ? (
								<p className="reviewerNameLabel">
									{' '}
									{selectedTab === 'Reviewer' ? 'Reviewer' : 'Approver'}{' '}
									Name:&nbsp;
									{reviewerInfo}
								</p>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className="topicsCrowdsourcingHistoryContentContainer">
						{component[selectedTab]}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopicsHistory;
