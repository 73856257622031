import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dompurify from 'dompurify';
import { v4 as uniqueIdGenerator } from 'uuid';
import { MessageBar, MessageBarType } from '@fluentui/react';

import ActivityDeleteModal from '../Phase/ActivityDeleteModal';
import PhaseDeleteModal from '../Phase/PhaseDeleteModal';
import ActivityBuilder from '../PhaseLeftMenu/ActivityAddition/CreateActivity';
import PhasePublishModal from '../Phase/PhasePublishModal';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import DeletedEntityModel from '../DeletedEntity';
import GroupDerefer from '../Phase/GroupDerefer';
import DereferSolutionMethod from '../Phase/DereferSolutionMethod';
import PhasePublishCancelModal from '../Phase/PhasePublishCancelModal';

import Activities from '../Activities';
const getDynamicFilterData = (phaseDynamicFilterTag, role) => {
	const selectedFiltersIds = [];
	const filterIds = Object.keys(phaseDynamicFilterTag);
	for (const id of filterIds) {
		if (phaseDynamicFilterTag[Number(id)].selectedTagId.length > 0) {
			selectedFiltersIds.push(
				`${Number(id)}` +
					'-' +
					phaseDynamicFilterTag[Number(id)].selectedTagId.toString()
			);
		}
	}
	if (role.id !== 0) {
		selectedFiltersIds.push(`16-${role.id}`);
	}
	return selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0;
};
function DeliveryPhase({
	setShowMoreDescription,
	setShowMoreVisible,
	projectTypeId,
	solutionMethodId,
	showGroup,
	showFilters,
	selectedRoles,
	isMerged,
	setIsParentPhaseDrafted,
	showPublishModal,
	setShowPublishModal,
	setPhaseCreated,
	coOwners,
	searchInput,
	deletePhase,
	setDeletePhase,
	showParentDeletePhaseModal,
	setShowParentDeletePhaseModal,
	isActivityCountZero,
	setIsActivityCountZero,
	getSolutionMethodData,
	setDeletePhaseName,
	selectedComplexity,
	setShowPhaseDeletionMessage,
	parentId,
	parentPhaseCreated,
	setParentPhaseCreated,
	showDeletePhaseModal,
	setShowDeletePhaseModal,
	getParentSolutionMethodData,
	setParentPhasePublishTitle,
	parentSolutionMethod,
	setShowPhaseUpdateModal,
	setUpdatePhaseId,
	setSelectedTab,
	updatePhaseId,
	selectedPhase,
	setSelectedPhase,
	updateComplexityFilter,
	setUpdateComplexityFilter,
}) {
	const htmlSanitizer = dompurify.sanitize;
	const urlParams = useParams();
	const {
		parentPhase,
		setParentPhase,
		appState,
		phase,
		setRole,
		complexity,
		complexityValidActivities,
		phaseHashtags,
		phaseDynamicFilterTag,
		role,
		endUserView,
	} = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { defaultRole } = appState;
	const { axiosGetService, userAlias } = useAxios();

	const [templateDownload, setTemplateDownload] = useState();
	const [phaseData, setPhaseData] = useState({});
	const [activityGroups, setActivityGroups] = useState([]);
	const [htmlThreshold, setHtmlThreshold] = useState(0);
	const [descriptionTextLength, setDescriptionTextLength] = useState(0);
	const [showActivityBuilder, setShowActivityBuilder] = useState(false);
	const [previousActivityGroups, setPreviousActivityGroups] = useState([]);
	const [showActivityDeleteModal, setShowActivityDeleteModal] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [complexities, setComplexities] = useState([]);
	const [activityBuilderTabId, setActivityBuilderTabId] = useState(1);
	const [apiCalled, setApiCalled] = useState(false);
	const [isMethodOwnerOrCoOwner, setIsMethodOwnerOrCoOwner] = useState(false);
	const [noActivityMessage, setNoActivityMessage] = useState(false);
	const [showSubgroupForm, setShowSubgroupForm] = useState(false);
	const [selectedGroupDetails, setSelectedGroupDetails] = useState(null);
	const [showSubgroupEditForm, setShowSubgroupEditForm] = useState(false);
	const [selectedSubgroupDetails, setSelectedSubgroupDetails] = useState('');
	const [showParentDereferGroupModal, setShowParentDereferGroupModal] =
		useState(false);
	const [
		showParentDereferSolutionMethodModal,
		setShowParentDereferSolutionMethodModal,
	] = useState(false);
	const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(null);
	const [referrefdSolutionMethodsData, setreferrefdSolutionMethodsData] =
		useState({});
	const [referrefdPhasesData, setReferrefdPhasesData] = useState({});
	const [showPublishCancelModal, setShowPublishCancelModal] = useState(false);

	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};

	const cloneGroup = (data, setValue, isRefered) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
					projectTypeId: data[group].projectTypeId,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		if (activityCount === 0 || isRefered) {
			setIsActivityCountZero(true);
		} else {
			setIsActivityCountZero(false);
		}
		setValue(activityGroupClone);
	};

	const getPhaseData = async () => {
		setPhaseData({});
		setApiCalled(true);
		setActivityGroups([]);
		await axiosGetService(
			`api/sdmphase/getphasemergedactivities/${
				parentSolutionMethod.solutionMethodId
			}/${urlParams.parentPhaseId}/${complexity.id}/${
				coOwners.includes(userAlias) && !endUserView
			}/0/0/0/true/0`
		)
			.then((res) => {
				if (res.data && !res.data.isDeleted) {
					setIsParentPhaseDrafted(res.data.isDrafted);
					setIsMethodOwnerOrCoOwner(res.data.isMethodOwnerOrCoOwner);
					if (res.data.isDrafted && !res.data.publishScheduledDateTime) {
						setParentPhasePublishTitle(res.data.phaseName);
					} else {
						setParentPhasePublishTitle('');
					}
					setDeletePhaseName(res.data.phaseName);
					const description = res.data.phaseDescription
						? res.data.phaseDescription
						: '';

					const firstParagraph = description.split('<br />')[0];
					const span = document.createElement('span');

					span.innerHTML = firstParagraph;
					const filteredFirstParagraph = span.textContent || span.innerText;

					span.innerHTML = description;
					const filteredDescription = span.textContent || span.innerText;

					let thresholdCharCount = 0;
					let countThreshold = 0;

					for (let i = 0; i < description.length; i++) {
						if (countThreshold === 120 || i === description.length - 1) {
							setHtmlThreshold(thresholdCharCount);
							setDescriptionTextLength(countThreshold);
							break;
						}
						if (description[i] === '<') {
							while (description[i] !== '>') {
								thresholdCharCount++;
								i++;
							}
							i--;
							thresholdCharCount++;
						} else if (description[i] === '&' && i + 5 < description.length) {
							const space = description.slice(i, i + 6);
							if (space === '&nbsp;') {
								thresholdCharCount += 5;
								i += 4;
							}
						} else {
							countThreshold++;
							thresholdCharCount++;
						}
					}

					setPhaseData({
						...res.data,
						shortDescription: description,
						filteredDescription,
						filteredFirstParagraph,
					});

					setParentPhase({
						...parentPhase,
						id: urlParams.parentPhaseId,
						title: res.data.phaseName,
						enableComplexity: res.data.enableComplexity,
						activityCount: res.data.activityGroups.length,
						svgFileName: res.data.svgFileName,
					});

					cloneGroup(
						res.data.activityGroupsData,
						setActivityGroups,
						res.data.isRefered
					);
					cloneGroup(
						res.data.activityGroupsData,
						setPreviousActivityGroups,
						res.data.isRefered
					);

					if (res.data.activityGroups.length === 0 && phase.id === null) {
						setRole(defaultRole);
					}
				} else if (res.data) {
					setPhaseData({
						...res.data,
					});
				}
				setApiCalled(false);
			})

			.catch((err) => console.log(err));
	};

	const getComplexities = async () => {
		axiosGetService('api/activities/projectcomplexity').then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: 'option',
				};
			});
			setComplexities(data);
		});
	};

	const getActivityGroups = async () => {
		await axiosGetService(
			`api/sdmphase/getphasemergedactivities/${
				parentSolutionMethod.solutionMethodId
			}/${urlParams.parentPhaseId}/${complexity.id}/${
				coOwners.includes(userAlias) && !endUserView
			}/0/0/0/true/0`
		)
			.then((res) => {
				if (res.data && !res.data.isDeleted) {
					cloneGroup(
						res.data.activityGroupsData,
						setActivityGroups,
						res.data.isRefered
					);
					cloneGroup(
						res.data.activityGroupsData,
						setPreviousActivityGroups,
						res.data.isRefered
					);
				} else if (res.data && res.data.isDeleted) {
					setPhaseData({
						...res.data,
					});
				}
			})
			.catch((err) => console.log(err));
	};

	const formatPublishDate = (publishDate) => {
		const date = new Date(publishDate);
		return date.toLocaleString();
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getActivityGroups();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [complexity]);

	useEffect(() => {
		getComplexities();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhaseData();
			setTemplateDownload(null);
		}, 1000);
		return () => {
			setParentPhasePublishTitle('');
			clearTimeout(timeOutId);
		};
	}, [urlParams.parentPhaseId, parentPhaseCreated]);

	useEffect(() => {
		let groupCount = 0;
		for (const group of activityGroups) {
			if (group.groupId) {
				groupCount += 1;
			}
		}

		if (
			(groupCount == 0 &&
				phaseHashtags.length > 0 &&
				complexityValidActivities.length === 0) ||
			(groupCount == 0 &&
				searchInput.trim().length > 0 &&
				complexityValidActivities.length === 0) ||
			(groupCount == 0 &&
				getDynamicFilterData(phaseDynamicFilterTag, role) !== 0 &&
				complexityValidActivities.length === 0)
		) {
			setNoActivityMessage(true);
		} else {
			setNoActivityMessage(false);
		}
	}, [searchInput, phaseHashtags, complexityValidActivities, activityGroups]);

	if (phaseData.isDeleted) {
		return <DeletedEntityModel errorMsg={phaseData.errorMessage} />;
	}
	return (
		<>
			{apiCalled ? (
				<Spinner />
			) : (
				<>
					{showPublishModal ? (
						<PhasePublishModal
							phaseData={phaseData}
							showPublishModal={showPublishModal}
							setShowPublishModal={setShowPublishModal}
							solutionMethodId={solutionMethodId}
							setPhaseCreated={setPhaseCreated}
							isActivityCountZero={isActivityCountZero}
							complexity={complexity}
							coOwners={coOwners}
							setPhaseData={setPhaseData}
							type="parentPhase"
							parentId={parentId}
							getParentSolutionMethodData={getParentSolutionMethodData}
							setParentPhaseCreated={setParentPhaseCreated}
							parentPhaseCreated={parentPhaseCreated}
							setShowActivityBuilder={setShowActivityBuilder}
							setSelectedTab={setSelectedTab}
							setShowPhaseUpdateModal={setShowPhaseUpdateModal}
							setUpdatePhaseId={setUpdatePhaseId}
							activityGroups={activityGroups}
							setShowSubgroupForm={setShowSubgroupForm}
							setActivityBuilderTabId={setActivityBuilderTabId}
							setSelectedGroupDetails={setSelectedGroupDetails}
							setSelectedSubgroupDetails={setSelectedSubgroupDetails}
							setShowSubgroupEditForm={setShowSubgroupEditForm}
						/>
					) : (
						<></>
					)}
					{phaseData.publishScheduledDateTime && (
						<MessageBar
							messageBarType={MessageBarType.warning}
							isMultiline={true}
						>
							<p>
								Changes made to this phase has been scheduled to be published at{' '}
								{formatPublishDate(phaseData.publishScheduledDateTimeString)}{' '}
								(Local). you can cancel here if you want to
							</p>
							<button
								onClick={() => {
									setShowPublishCancelModal(true);
								}}
							>
								Cancel
							</button>
						</MessageBar>
					)}
					<h4>{phaseData.phaseName}</h4>
					<div>
						<span
							dangerouslySetInnerHTML={{
								__html: phaseData.shortDescription
									? htmlSanitizer(
											phaseData.shortDescription.slice(0, htmlThreshold)
									  )
									: htmlSanitizer(null),
							}}
						></span>
						{phaseData.phaseDescription ? (
							<>
								{descriptionTextLength < 120 ? null : (
									<>
										<span>...&nbsp;&nbsp;</span>
										<button
											type="button"
											className="showMoreButton"
											onClick={() => {
												setShowMoreDescription({
													title: phaseData.phaseName,
													description: phaseData.phaseDescription,
													type: 'parentPhase',
												});
												setShowMoreVisible(true);
											}}
										>
											Show More
										</button>
									</>
								)}
							</>
						) : (
							<></>
						)}
					</div>
					{isMethodOwnerOrCoOwner && !phaseData.isRefered && !endUserView ? (
						<div className="addNewActivityButton">
							<button
								onClick={() => {
									track(1, 900, phaseData.phaseId, phaseData.phaseName);
									setShowActivityBuilder(true);
								}}
							>
								Link or Add a new Activity
								<span className="phaseAddIcon"></span>
							</button>
						</div>
					) : (
						<></>
					)}
					{isMethodOwnerOrCoOwner && !phaseData.isRefered && !endUserView ? (
						<div className="addNewActivityButton">
							<button
								onClick={() => {
									track(1, 900, phaseData.phaseId, phaseData.phaseName);
									setShowActivityBuilder(true);
								}}
							>
								Link solution method
								<span className="phaseAddIcon"></span>
							</button>
						</div>
					) : (
						<></>
					)}
					{showActivityDeleteModal ? (
						<ActivityDeleteModal
							setShowActivityDeleteModal={setShowActivityDeleteModal}
							showActivityDeleteModal={showActivityDeleteModal}
							selectedActivity={selectedActivity}
							setSelectedActivity={setSelectedActivity}
							complexities={complexities}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							setIsActivityCountZero={setIsActivityCountZero}
							type="parentPhase"
							parentId={parentId}
							solutionMethodId={parentId}
							setPhaseCreated={setParentPhaseCreated}
						/>
					) : (
						<></>
					)}
					{showActivityBuilder ? (
						<ActivityBuilder
							showActivityBuilder={showActivityBuilder}
							setShowActivityBuilder={setShowActivityBuilder}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							solutionMethodId={solutionMethodId}
							phaseId={phaseData.phaseId}
							previousActivityGroups={previousActivityGroups}
							setPreviousActivityGroups={setPreviousActivityGroups}
							projectTypeId={projectTypeId}
							activityBuilderTabId={activityBuilderTabId}
							setActivityBuilderTabId={setActivityBuilderTabId}
							coOwners={coOwners}
							userAlias={userAlias}
							getParentSolutionMethodData={getParentSolutionMethodData}
							getPhaseData={getPhaseData}
							phaseType="parentPhase"
							parentId={parentId}
							setParentPhaseCreated={setParentPhaseCreated}
							parentPhaseCreated={parentPhaseCreated}
							getSolutionMethodData={getParentSolutionMethodData}
							showSubgroupForm={showSubgroupForm}
							setShowSubgroupForm={setShowSubgroupForm}
							selectedGroupDetails={selectedGroupDetails}
							setSelectedGroupDetails={setSelectedGroupDetails}
							selectedSubgroupDetails={selectedSubgroupDetails}
							setSelectedSubgroupDetails={setSelectedSubgroupDetails}
							showSubgroupEditForm={showSubgroupEditForm}
							setShowSubgroupEditForm={setShowSubgroupEditForm}
							searchInput={searchInput}
							updateComplexityFilter={updateComplexityFilter}
							setUpdateComplexityFilter={setUpdateComplexityFilter}
						/>
					) : (
						<></>
					)}
					{showParentDeletePhaseModal &&
					Number(updatePhaseId) === Number(phaseData.phaseId) ? (
						<PhaseDeleteModal
							setShowDeletePhaseModal={setShowDeletePhaseModal}
							showParentDeletePhaseModal={showParentDeletePhaseModal}
							showDeletePhaseModal={showDeletePhaseModal}
							setShowParentDeletePhaseModal={setShowParentDeletePhaseModal}
							phaseId={phaseData.phaseId}
							solutionMethodId={solutionMethodId}
							projectTypeId={projectTypeId}
							setDeletePhase={setDeletePhase}
							getSolutionMethodData={getSolutionMethodData}
							coOwners={coOwners}
							isActivityCountZero={isActivityCountZero}
							setShowPhaseDeletionMessage={setShowPhaseDeletionMessage}
							phaseName={phaseData.phaseName}
							apiCalled={apiCalled}
							setApiCalled={setApiCalled}
							type="parentPhase"
							parentId={parentId}
							getParentSolutionMethodData={getParentSolutionMethodData}
							selectedPhase={selectedPhase}
							setSelectedPhase={setSelectedPhase}
						/>
					) : (
						<></>
					)}

					{activityGroups.length > 0 && !phaseData.isRefered ? (
						<h6 className="mainTitleOfActivity">
							Activities&nbsp;
							{(isMethodOwnerOrCoOwner && !endUserView) || deletePhase ? (
								<span className="activityEditArrangeMode">
									<span
										onClick={() => {
											setActivityBuilderTabId(2);
											setShowActivityBuilder(true);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setActivityBuilderTabId(2);
												setShowActivityBuilder(true);
											}
										}}
										tabIndex={0}
										role="button"
										aria-label="Rearrange Activities or Groups"
									>
										Rearrange Activities or Groups
										<span className="activityRearrange"></span>
									</span>
								</span>
							) : (
								<></>
							)}
						</h6>
					) : (
						<></>
					)}
					{noActivityMessage ? <p>No activities found</p> : <></>}
					<div className="activitiesList delivery">
						{activityGroups.map((item) => (
							<Activities
								data={item}
								key={item.dragNDropId}
								templateDownload={templateDownload}
								setTemplateDownload={setTemplateDownload}
								phaseType="phase"
								showGroup={showGroup}
								showFilters={showFilters}
								selectedRoles={selectedRoles}
								isMerged={isMerged}
								isParentActivity={item.isParentActivity}
								activityGroups={activityGroups}
								solutionMethodId={solutionMethodId}
								setShowActivityDeleteModal={setShowActivityDeleteModal}
								setSelectedActivity={setSelectedActivity}
								coOwners={coOwners}
								userAlias={userAlias}
								searchInput={searchInput}
								selectedComplexity={selectedComplexity}
								type="parentPhase"
								parentId={parentId}
								activityPhaseId={parentId}
								isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
								setShowDereferGroupModal={setShowParentDereferGroupModal}
								setSelectedGroupDetails={setSelectedGroupDetails}
								setSelectedSubgroupDetails={setSelectedSubgroupDetails}
								setShowSubgroupEditForm={setShowSubgroupEditForm}
								projectTypeId={projectTypeId}
								setShowDereferSolutionMethodModal={
									setShowParentDereferSolutionMethodModal
								}
								setSelectedSolutionMethod={setSelectedSolutionMethod}
								solutionMethodsData={referrefdSolutionMethodsData}
								setSolutionMethodsData={setreferrefdSolutionMethodsData}
								phasesData={referrefdPhasesData}
								setPhasesData={setReferrefdPhasesData}
								isPhaseRefered={phaseData.isRefered}
								setShowSubgroupForm={setShowSubgroupForm}
								setActivityBuilderTabId={setActivityBuilderTabId}
							/>
						))}
					</div>
					{showParentDereferGroupModal ? (
						<GroupDerefer
							showDereferGroupModal={showParentDereferGroupModal}
							setShowDereferGroupModal={setShowParentDereferGroupModal}
							solutionMethodId={solutionMethodId}
							getPhaseData={getPhaseData}
							coOwners={coOwners}
							apiCalled={apiCalled}
							setApiCalled={setApiCalled}
							selectedGroup={selectedGroupDetails}
							setSelectedGroup={setSelectedGroupDetails}
							type="parentPhase"
							parentId={parentId}
						/>
					) : (
						<></>
					)}
					{showParentDereferSolutionMethodModal ? (
						<DereferSolutionMethod
							showParentDereferSolutionMethodModal={
								showParentDereferSolutionMethodModal
							}
							setShowParentDereferSolutionMethodModal={
								setShowParentDereferSolutionMethodModal
							}
							phaseId={parentId}
							type="parentPhase"
							selectedSolutionMethod={selectedSolutionMethod}
							setSelectedSolutionMethod={setSelectedSolutionMethod}
							getPhaseData={getPhaseData}
						/>
					) : (
						<></>
					)}
					{showPublishCancelModal && (
						<PhasePublishCancelModal
							phaseData={phaseData}
							solutionMethodId={solutionMethodId}
							type="parentPhase"
							showPublishCancelModal={showPublishCancelModal}
							setShowPublishCancelModal={setShowPublishCancelModal}
							parentPhaseCreated={parentPhaseCreated}
							setParentPhaseCreated={setParentPhaseCreated}
							formatPublishDate={formatPublishDate}
						/>
					)}
				</>
			)}
		</>
	);
}

export default DeliveryPhase;
