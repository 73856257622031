import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dompurify from "dompurify";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAxios } from "../../context/AxiosContext";
import AddBreadCrumb from "../../helpers/BreadcrumbService";
import { TabTitle } from "../../utils/GeneralFunctions";
import "./SolutionMethod.css";
import { axiosTokenService } from "../../components/CkEditor/api/axiosBaseUrl";
import { getOtherUserProfile } from "../../helpers/GraphService";
import ActivityCollaborators from "../../components/Activity/ActivityCollaborators";

function SolutionMethod({
  solutionMethod,
  solutionMethodIdBreadcrumb,
  projectTypeId,
  setShowFilters,
  showFilters,
  setShowGroup,
  isSolutionCollaboratorsEdited,
	setIsSolutionCollaboratorsEdited
}) {
  const htmlSanitizer = dompurify.sanitize;
  const params = useParams();
  const { solutionMethodName, description, solutionMethodId } = solutionMethod;
  const { track } = useAppInsights();
  const { breadcrumbs, setBreadcrumbs, setPhase } = useLeftMenuCollapse();
  const { axiosGetService } = useAxios();
  const [showPhaseOwnerCollaborators,setShowPhaseOwnerCollaborators] = useState(false)
  const [solutionMethodOwner,setSolutionMethodOwner] = useState({})
  const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
  const [existingCoOwners, setExistingCoOwners] = useState([]);
  const [solutionMethodCollaborators, setSolutionMethodCollaborators]=useState({})
  const [showCollaboratorSpinner, setShowCollaboratorSpinner] = useState(true);
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, "text/html");
  const images = doc.images;
  for (let i = 0; i < images.length; i++) {
    if (!images[i].alt) {
      images[i].alt = solutionMethodName;
    }
  }

  const loadBreadcrumb = async () => {
    TabTitle(solutionMethodName);
       await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${
        params.solutionMethodId
          ? params.solutionMethodId
          : solutionMethodIdBreadcrumb
      }&itemTypeId=15&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0&isNotifications=false
			&solutionMethodId=${
        params.solutionMethodId
          ? params.solutionMethodId
          : solutionMethodIdBreadcrumb
      }&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response?.data?.split(">").map((item) => item.trim());
      TabTitle(breadcrumbTitle[1]);      
      if (!solutionMethod.isRetired) {
        const projectBreadcrumb = {
          title: breadcrumbTitle[1],
          type: "projectType",
          path: `/project/${
            params.projectId ? params.projectId : projectTypeId
          }`,
          parent: ["home"],
        };
        const solutionMethodBreadcrumb = {
          title: breadcrumbTitle[2],
          type: "solutionMethod",
          path: `/project/${
            params.projectId ? params.projectId : projectTypeId
          }/method/${params.solutionMethodId}`,
          parent: "projectType",
        };
        const newBreadcrumbs = breadcrumbs.slice(
          0,
          breadcrumbs.length > 1 && breadcrumbs[1].type === "search" ? 2 : 1
        );
        newBreadcrumbs.push(projectBreadcrumb);
        newBreadcrumbs.push(solutionMethodBreadcrumb);
        setBreadcrumbs(newBreadcrumbs);
      } else {
        TabTitle(breadcrumbTitle[2]);
        const solutionMethodBreadcrumb = {
          title: breadcrumbTitle[2],
          type: "solutionMethod",
          path: `/project/${
            params.projectId ? params.projectId : projectTypeId
          }/method/${params.solutionMethodId}`,
          parent: ["home"],
        };
        const newBreadcrumbs = breadcrumbs.slice(
          0,
          breadcrumbs.length > 1 && breadcrumbs[1].type === "search" ? 2 : 1
        );
        newBreadcrumbs.push(solutionMethodBreadcrumb);
        setBreadcrumbs(newBreadcrumbs);       
      }
    });
  };

  useEffect(() => {
    if (typeof solutionMethod.isRetired !== "undefined") {
      loadBreadcrumb();
    }
    if (
      Object.prototype.hasOwnProperty.call(
        solutionMethod,
        "solutionMethodName"
      ) &&
      params.solutionMethodId == solutionMethodId
    ) {
      track(2, 15, params.solutionMethodId, solutionMethodName);
    }
    setPhase({
      id: null,
      title: null,
    });
  }, [solutionMethod]);

  useEffect(
    () => () => {
      window.onpopstate = (e) => {
        setBreadcrumbs(breadcrumbs.slice(0, 1));
      };
    },
    []
  );

  useEffect(() => {
    return () => {
      if (showFilters) {
        setShowFilters(false);
      }
      setShowGroup(false);
    };
  }, []);

  const getSolutionMethodOwnerDetails = async (email) => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, email);
		setSolutionMethodOwner(userData);
	  };

    const getUser = async (userEmail, setValue) => {
     setShowCollaboratorSpinner(true);
     const accessToken = await axiosTokenService();
     await getOtherUserProfile(accessToken, userEmail).then((response) => {
       setValue((prevState) => [...prevState, response]);
     });
     setTimeout(() => {
       setShowCollaboratorSpinner(false);
     }, 1000);
   };
  
   const getCollaboratorsInfo = async (membersList, setValue) => {
     for (const userEmail of membersList) {
       getUser(userEmail, setValue);
     }
   } 


    const getCollaborators = async () => {
      const solutionMethodId = params.solutionMethodId
      await axiosGetService(
        `api/sdmactivity/getactivityorphaseormethodcollaborators/0/0/${solutionMethodId}`
      ).then((response) => {
       setSolutionMethodCollaborators(response.data)
       getCollaboratorsInfo(response.data.coOwnerMembersList,setExistingCoOwners)
       getCollaboratorsInfo(response.data.approverMembersList, setExistingApprovers)
       getCollaboratorsInfo(response.data.reviewerMembersList, setExistingReviewers)
       getSolutionMethodOwnerDetails(response.data.owner)
       if (
         response.data.coOwnerMembersList.length === 0 &&
         response.data.reviewerMembersList.length === 0 &&
         response.data.approverMembersList.length === 0
       ) {
         setShowCollaboratorSpinner(false);
       }
      }).catch((error)=>{
       console.log(error)
      })
     }

	  useEffect(()=>{
      setExistingCoOwners([])
      setExistingApprovers([])
      setExistingReviewers([])
      getCollaborators()
	  },[isSolutionCollaboratorsEdited])

  return (
    <> 
      <div className="solutionMethodCollaboratorContainer phaseCollaboratorContainer">
      {solutionMethodName !== undefined ? <h4>{solutionMethodName}</h4> : null}
      <div className="imgContainer  imgContainerPhase">
            <img className="ownerImg" 
					  src={solutionMethodOwner.photo}
					  alt="phaseOwnerImage"
             />
            <span className="ownerInfo">
            <span>Owner</span>
            <a href={`mailto:${solutionMethodOwner.mail}`}>
            <span style={{color:'#1979c2',fontWeight:'bolder'}}>
              {solutionMethodOwner.firstName? solutionMethodOwner.firstName + " "+
						  solutionMethodOwner.surname: ''}
              </span>
            </a>
            </span>
            <span className="collaboratorsImg" 
				    onClick={()=>setShowPhaseOwnerCollaborators(true)} ></span>
      </div>
      </div>
      <div className="ck-content"
        dangerouslySetInnerHTML={{
          __html:
            doc.documentElement.innerHTML !==
            "<head></head><body>undefined</body>"
              ? htmlSanitizer(doc.documentElement.innerHTML)
              : null,
        }}
      />
      {showPhaseOwnerCollaborators ? <ActivityCollaborators
			setShowActivityOwnerCollaborators={setShowPhaseOwnerCollaborators}
			activityName={solutionMethodName}
			artifactName={'Solution Method'}
      artifactOwner={solutionMethodOwner}
			existingCoOwners={existingCoOwners}
			existingReviewers={existingReviewers}
			existingApprovers = {existingApprovers}
			showCollaboratorSpinner={showCollaboratorSpinner}
			/>
			:<></>}
    </>
  );
}

export default SolutionMethod;
