import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

function Dropdown({
	className,
	data,
	setValue,
	value,
	enableDropdown,
	setShowAddToGroupDropdown,
	selectedActivity,
	parentGroup,
}) {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
			setShowAddToGroupDropdown(false);
		}
	};

	const handleDropdown = (item) => {
		const updatedActivitiesGroup = [];
		for (const group of value) {
			if (group.groupId === parentGroup.groupId) {
				for (const activity of group.activities) {
					if (activity.isSubgroup && activity.subGroupId === item.id) {
						activity.activities = [...activity.activities, selectedActivity];
					}
				}
				group.activities = group.activities.filter(
					({ dragNDropId }) => dragNDropId !== selectedActivity.dragNDropId
				);
			}
			updatedActivitiesGroup.push(group);
		}
		setValue([...updatedActivitiesGroup]);
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div title={'Select a subgroup'} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">{'Select a group'}</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.name}
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.name}
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
