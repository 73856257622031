import React, { useEffect, useState, appState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import fileIcons from '../../static/TemplateIcons';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

function SearchResultPageProcedures({
	data,
	enableSearchSuggestion,
	searchSuggestionId,
	setSearchSuggestionId,
	currentPage,
}) {
	const history = useHistory();
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const [showText, setShowText] = useState(false);
	const [searchSuggestionData, setSearchSuggestionData] = useState(null);
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(
		enableSearchSuggestion
	);

	const getSearchSuggestions = () => {
		axiosGetService(
			`api/sdmuser/getSearchRecommendations?artifactTypeName=Topic&artifactId=${data.id}&activityIdentifier=0&complexityId=0`
		).then((res) => {
			setSearchSuggestionData(res.data);
		});
	};

	useEffect(() => {
		if (searchSuggestionId) {
			setShowSearchSuggestion(
				searchSuggestionId === `search_procedure_${data.id}`
			);
		} else if (enableSearchSuggestion) {
			setSearchSuggestionId(`search_procedure_${data.id}`);
		}
	}, [searchSuggestionId]);

	useEffect(() => {
		if (showSearchSuggestion) {
			getSearchSuggestions();
		}
	}, [showSearchSuggestion]);

	return (
		<div
			tabIndex={0}
			id={`search_procedure_${data.id}`}
			className="tabsWrapper topicsTabsSearch"
			onClick={() => setSearchSuggestionId(`search_procedure_${data.id}`)}
			onKeyPress={(e) => {
				if (e.which === 13) {
					setSearchSuggestionId(`search_procedure_${data.id}`);
				}
			}}
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={fileIcons[data.fileType]}
						className="imageOfSearchResult"
						alt="fileType"
					/>
				</div>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					<NavLink
						onClick={() =>
							track(1, 408, data.id, data.title, {
								pageNumber: currentPage,
							})
						}
						to={data.fileLocationNew}
						key={data.title}
					>
						{data.title}
					</NavLink>
				</div>
				<div class="existingHashTagsOfTopic">
					{showText ? (
						<>
							{data.hashTagsList.map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					) : (
						<>
							{data.hashTagsList.slice(0, 4).map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					)}
				</div>
				{showText ? (
					<div className="descriptionOfTabs">
						<p>
							{data.description === null || data.description.length === 0 ? (
								<span>To be Updated</span>
							) : data.description.length > 800 ? (
								`${data.description.slice(0, 800)}...`
							) : (
								data.description
							)}
						</p>
					</div>
				) : null}
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						<div className="hidingConsultingSupport">
							<span className="topicLabel">Project Type: </span>
							<span className="topicCatogry">{data.projectTypeName}</span>
						</div>
						<div className="searchTopicDetails">
							<span className="topicLabel">Category: </span>
							<span className="topicCatogry">{data.topicCategoryName}</span>
							<div
								className="tabExpandedViewIcon"
								onClick={() => setShowText(!showText)}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								title="Expand Procedure"
								aria-expanded={showText}
								role="button"
								aria-label={
									showText
										? 'Click to Collapse Procedure information'
										: 'Click to expand Procedure information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{searchSuggestionData && showSearchSuggestion ? (
				<div className="relatedSearchSuggestion">
					<span className="tabsbeakArrow"></span>
					<div className="titleOfSuggestionWrap">
						<h6>
							Activities Associated to the Procedure
							<br />
							<span>{`"${data.title}"`}</span>
						</h6>
					</div>
					{searchSuggestionData.activities &&
					searchSuggestionData.activities.length > 0 ? (
						<>
							<ul className="activityRecommandations">
								{searchSuggestionData.activities.map((activity) => (
									<li key={activity.id + '_' + activity.complexityId}>
										<div className="relatedTitles">
											<img
												src={fileIcons.activity}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(
														1,
														165,
														activity.activityIdentifier,
														activity.activityName,
														{
															suggestedItemName: 'Activity',
															suggestedItemId: 2,
															suggestedComplexity: activity.complexityName,
														}
													);
												}}
												title={activity.activityName}
												aria-label={activity.activityName}
												to={`/project/${activity.projectTypeId}/method/${
													activity.methodId
												}/phase/${
													activity.phaseId ? activity.phaseId : 'null'
												}/parentPhase/${
													activity.parentPhaseId
														? activity.parentPhaseId
														: 'null'
												}/activity/${activity.activityIdentifier}?complexity=${
													activity.complexityId
												}`}
											>
												<span>{activity.activityName}</span>
											</NavLink>
										</div>
										<div className="relatedPhaseName">
											Phase:{' '}
											{activity.solutionMethodName +
												' - ' +
												activity.activityPhaseName}
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Activities associated to this Procedure
						</span>
					)}
				</div>
			) : null}
		</div>
	);
}
export default SearchResultPageProcedures;
