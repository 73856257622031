import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import Dropdown from '../Dropdown';
// import CriteriaActivityEditor from './CriteriaActivityEditor';

const EntryCriteria = ({
	showFields,
	setshowFields,
	handelFormView,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	handelFormViewEditPerventClose,
	setComplexityLevelUpdatedDetails,
	criteriaTypes,
}) => {
	const defaultCriteriType = {
		id: 1,
		name: 'Text',
		//type: 'option',
	};
	const defaultCriteriaTypeValue = {
		2: 'Numeric',
		1: 'Text',
	};
	const tabs = criteriaTypes.filter(
		(item) => item.title === 'Entry Criteria' || item.title === 'Input'
	);
	const dropdownValue = [
		{ id: 2, name: 'Text', type: 'option' },
		{ id: 1, name: 'Numeric', type: 'option' },
	];
	const [criteriaName, setCriteriaName] = useState('');
	const [criteriaDescription, setCriteriaDescription] = useState('');
	const [criteriaType, setCriteriaType] = useState(defaultCriteriType);
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updatedId, setUpdateId] = useState(0);
	const [selectedEntryTab, setSelectedEntryTab] = useState(
		criteriaTypes.filter((item) => item.title === 'Entry Criteria')[0].id
	);
	// const [entryCriteriaList, setEntryCriteriaList] = useState([
	// 	...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
	// 		(item) => item.criteriaTypeName === 'Entry Criteria'
	// 	),
	// ]);
	// const [inputList, setInputList] = useState([
	// 	...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
	// 		(item) => item.criteriaTypeName !== 'Entry Criteria'
	// 	),
	// ]);

	const [entryCriteriaList, setEntryCriteriaList] = useState(
		[...(complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName === 'Entry Criteria'
		) || [
				{
					id: uniqueIdGenerator(),
					name: "",
					criteriaType: 'Entry',
					valueType: defaultCriteriType.id,
					status: 'new',
					criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Entry Criteria')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Entry Criteria')[0]?.id))[0]?.title,
					sequence: 1
				}
			])]
	);

	const [inputList, setInputList] = useState(
		[...(complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName !== 'Entry Criteria'
		) || [
				{
					id: uniqueIdGenerator(),
					name: "",
					status: 'new',
					criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Input')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Input')[0]?.id))[0]?.title,
					criteriaType: 'Entry',
					sequence: 1
				}
			])]
	);

	useEffect(() => {
		const entryCriteria = complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName === 'Entry Criteria'
		);

		const inputCriteria = complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName !== 'Entry Criteria'
		);

		setEntryCriteriaList(entryCriteria.length > 0 ? entryCriteria : [
			{
				id: uniqueIdGenerator(),
				name: "",
				criteriaType: 'Entry',
				valueType: defaultCriteriType.id,
				status: 'new',
				criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Entry Criteria')[0]?.id))[0]?.id,
				criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Entry Criteria')[0]?.id))[0]?.title,
				sequence: 1
			}
		]);

		setInputList(inputCriteria.length > 0 ? inputCriteria : [
			{
				id: uniqueIdGenerator(),
				name: "",
				status: 'new',
				criteriaTypeID: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Input')[0]?.id))[0]?.id,
					criteriaTypeName: tabs?.filter(({ id }) => id === (criteriaTypes?.filter((item) => item?.title === 'Input')[0]?.id))[0]?.title,
				criteriaType: 'Entry',
				sequence: 1
			}
		]);
	}, [selectedComplexity]);
	const [inputDescription, setInputDecription] = useState('');

	useEffect(() => {
		if (editMode && updatedId !== 0) {
			handelFormViewEditPerventClose('entryCriteria');
		}
	}, [criteriaName, inputDescription]);

	
	const deleteOtherDetails = (deleteId, value, setValue) => {
		const filteredValue = value.filter(({ id }) => id !== deleteId);
		filteredValue.forEach((item, index) => {
			item.sequence = index + 1
		})
		setValue(filteredValue);
	};

	const tabComponent = {
		[criteriaTypes.filter((item) => item.title === 'Entry Criteria')[0].id]: (
			<EntryCriteriaComponent
				error={error}
				dropdownValue={dropdownValue}
				criteriaType={criteriaType}
				setCriteriaType={setCriteriaType}
				setCriteriaName={setCriteriaName}
				criteriaDescription={criteriaDescription}
				setCriteriaDescription={setCriteriaDescription}
				criteriaName={criteriaName}
				entryCriteriaList={entryCriteriaList}
				defaultCriteriaTypeValue={defaultCriteriaTypeValue}
				setEditMode={setEditMode}
				setUpdateId={setUpdateId}
				showFields={showFields}
				handelFormView={handelFormView}
				deleteOtherDetails={deleteOtherDetails}
				setError={setError}
				setEntryCriteriaList={setEntryCriteriaList}
				defaultCriteriType={defaultCriteriType}
				setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
				complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
				selectedComplexity={selectedComplexity}
				complexityLevelDetails={complexityLevelDetails}
				setComplexityLevelDetails={setComplexityLevelDetails}
				inputList={inputList}
				tabs={tabs}
				selectedEntryTab={selectedEntryTab}
			/>
		),
		[criteriaTypes.filter((item) => item.title === 'Input')[0].id]: (
			<InputComponent
				error={error}
				showFields={showFields}
				inputDescription={inputDescription}
				setInputDecription={setInputDecription}
				inputList={inputList}
				setInputList={setInputList}
				setEditMode={setEditMode}
				setUpdateId={setUpdateId}
				setError={setError}
				deleteOtherDetails={deleteOtherDetails}
				handelFormView={handelFormView}
				setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
				complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
				selectedComplexity={selectedComplexity}
				complexityLevelDetails={complexityLevelDetails}
				setComplexityLevelDetails={setComplexityLevelDetails}
				entryCriteriaList={entryCriteriaList}
				tabs={tabs}
				selectedEntryTab={selectedEntryTab}
			/>
		),
	};


	return (
		<div
			className={
				'Detailswrapper p-0'
			}
		>

			<div className="DetailswrapperTitle entryExit">
				<div className="otherDetailsContainer">
					<div className="entryExitCriteriadiv">

						{true && (

							<div className="entryAndInputTabs">
								<ul>
									{tabs.map((tab) => (
										<li
											className={tab.id === selectedEntryTab ? 'selectedEntryTab pt-0' : 'pt-0'}
											onClick={() => {
												setSelectedEntryTab(tab.id);
												setEditMode(false);
											}}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedEntryTab(tab.id);
													setEditMode(false);
												}
											}}
											key={tab.id}
										>
											{tab.title}
										</li>
									))}
								</ul>

							</div>
						)}
						{true ? (
							tabComponent[selectedEntryTab]
						) : (
							<>
								{tabs.map((item) => (
									<React.Fragment key={item.id}>
										{tabComponent[item.id]}
									</React.Fragment>
								))}
							</>
						)}

					</div>
				</div>
			</div>

		</div>
	);
};

const EntryCriteriaComponent = ({
	error,
	dropdownValue,
	criteriaType,
	setCriteriaType,
	setCriteriaName,
	criteriaDescription,
	setCriteriaDescription,
	criteriaName,
	entryCriteriaList,
	defaultCriteriaTypeValue,
	setEditMode,
	setUpdateId,
	showFields,
	handelFormView,
	deleteOtherDetails,
	updateEntryCriteria,
	setError,
	setEntryCriteriaList,
	defaultCriteriType,
	setComplexityLevelUpdatedDetails,
	complexityLevelUpdatedDetails,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	inputList,
	tabs,selectedEntryTab
}) => {
	const [dragState, setDragState] = useState(true);
	const [showEntryTitleError, setShowEntryTitleError] = useState(false);
	const [showEntryTitleErrorMsg, setShowEntryTitleErrorMsg] = useState("");
	const [showEntryIndexError, setShowEntryIndexError] = useState();
	const [showEntryUrlError, setShowEntryUrlError] = useState(false);
	const [showEntryUrlErrorMsg, setShowEntryUrlErrorMsg] = useState("");
	const [entryUpdatedArray, setEntryUpdatedArray] = useState([]);
	const [entryContent, setEntryContent] = useState([{ name: "", url: "" }]);
	const [showBusinessRuleTitleError, setShowBusinessRuleTitleError] = useState(false);

	const handleToChangeEntryContent = (e, item, index) => {

		if (item.id !== undefined) {
			setEntryUpdatedArray([...entryUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...entryCriteriaList];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setEntryCriteriaList(updatedBusinessRuleContent);
		saveEntryCriteria(updatedBusinessRuleContent);
	}


	const saveEntryCriteria = (entryCriteriaList) => {
		const validInputList = Array.isArray(inputList) ? inputList : [];
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				entryCriteria: [...entryCriteriaList,...validInputList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				entryCriteria: [...entryCriteriaList,...validInputList],
			},
		}));
		handelFormView('entryCriteria');
	};
	const removeEntryContent = (index) => {
		// Only allow removal if there is more than one item in the list
		if (entryCriteriaList.length > 1) {
			const updatedContent = [...entryCriteriaList];
			updatedContent.splice(index, 1);
			setEntryCriteriaList(updatedContent);
			saveEntryCriteria(updatedContent);
		}
	};
	const handleAddBusinessContentFields = () => {
		const lastItem = entryCriteriaList[entryCriteriaList.length - 1];

		// Check if the last item has all required fields filled
		if (lastItem.valueType !== "" && lastItem.name !== "") {

			setEntryCriteriaList([
				...entryCriteriaList,
				{

					name: "",
					criteriaType: 'Entry',
					valueType: defaultCriteriType.id,
					status: 'new',
					// criteriaTypeID: lastItem.criteriaTypeID,
					// criteriaTypeName: lastItem.criteriaTypeName,
					criteriaTypeID: tabs.filter(({ id }) => id === selectedEntryTab)[0]
						.id,
					criteriaTypeName: tabs.filter(({ id }) => id === selectedEntryTab)[0]
						.title,
					sequence: entryCriteriaList.length + 1,

				}
			]);
		} else {
			// Optionally, show an alert or error message if the fields are not filled
			alert("Please fill in the current row before adding a new one.");
		}
	}; 
	// const handleDropdownChange = (newId, index) => {
	// 	debugger;
	// 	const updatedList = entryCriteriaList.map((item, i) =>
	// 		i === index
	// 			? { ...item, valueType: parseInt(newId) }
	// 			: item
	// 	);
	// 	setEntryCriteriaList(updatedList);
	// };

	const handleDropdownChange = (newId, index) => {
		
		// Extract the id from newId object
		const newValueType = parseInt(newId.id);
	
		const updatedList = entryCriteriaList.map((item, i) => {
			if (i === index) {
				
				return { ...item, valueType: newValueType };
			}
			return item;
		});
		
		console.log('Updated list:', updatedList);
		setEntryCriteriaList(updatedList);
		saveEntryCriteria(updatedList);
	};
	
	console.log("entryCriteriaList", entryCriteriaList);
	return (
		<>



			<div className="addPolygonTopicRelatedInputContainer">
				<div className="polygonInputContainer">

					{/* entryCriteriaList */}
					{entryCriteriaList.map((item, index) => (

						<div className="row " key={item.id || index}>
							<div className="col-xl-4">
								<Dropdown
									className="entryEditCriteriadropdown"
									data={dropdownValue}

									value={dropdownValue.find(option => option.id === item.valueType) || dropdownValue[0]} // Use item.valueType directly
									setValue={(newVal) => handleDropdownChange(newVal, index)}
									enableDropdown={true}
									pageType={'Activity'}
								/>
							</div>
							<div className="col-xl-7">
								<input
									className='entryEditCriteriaInput'
									title={`Entry Criteria Description`}
									placeholder={`Enter Description`}
									value={item.name}
									name="name"
									onChange={(e) => {
										handleToChangeEntryContent(e, item, index);
									}}
								></input>
							</div>
							<div className="addPolygonTopicFieldSave mt-2">
								<button className="relatededitContentDelete"
									onClick={() => {
										removeEntryContent(index);
									}}>
									<Icon
										className="relatedContentDeleteIcon"
										iconName="ChromeClose"
										title="Close"
									/>
								</button>
							</div>
							{index === entryCriteriaList.length - 1 && (
								<div className="addPolygonTopicFieldSave mt-2">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddBusinessContentFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddBusinessContentFields();
											}
										}}
									>
										<Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/>
									</button>
								</div>)}
						</div>
					))}



				</div></div>
		</>
	);
};

const InputComponent = ({
	error,
	inputDescription,
	setInputDecription,
	updateInputList,
	showFields,
	inputList,
	setInputList,
	setEditMode,
	setUpdateId,
	deleteOtherDetails,
	setError,
	handelFormView,
	setComplexityLevelUpdatedDetails,
	complexityLevelUpdatedDetails,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	entryCriteriaList,
	tabs,
	selectedEntryTab
}) => {
	const [entryUpdatedArray, setEntryUpdatedArray] = useState([]);
	const handleToChangeInputContent = (e, item, index) => {

		if (item.id !== undefined) {
			setEntryUpdatedArray([...entryUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...inputList];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setInputList(updatedBusinessRuleContent);

		saveEntryCriteria(updatedBusinessRuleContent);


	}


	const saveEntryCriteria = (updatedInputList) => {
		const validentryCriteriaList = Array.isArray(entryCriteriaList) ? entryCriteriaList : [];
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				entryCriteria: [...validentryCriteriaList,...updatedInputList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				entryCriteria: [...validentryCriteriaList,...updatedInputList],
			},
		}));
		handelFormView('entryCriteria');
	};
	const removeEntryContent = (index) => {
		// Only allow removal if there is more than one item in the list
		if (inputList.length > 1) {
			const updatedContent = [...inputList];
			updatedContent.splice(index, 1);
			setInputList(updatedContent);
			saveEntryCriteria(updatedContent);
		}
	};
	const handleAddInputFields = () => {
		const lastItem = inputList[inputList.length - 1];

		// Check if the last item has all required fields filled
		if (lastItem.valueType !== "" && lastItem.name !== "") {

			setInputList([
				...inputList,
				{
					name: "",
					status: "new",
					// criteriaTypeID: lastItem.criteriaTypeID,
					criteriaType: 'Entry',

					criteriaTypeID: tabs.filter(({ id }) => id === selectedEntryTab)[0]
						.id,
					criteriaTypeName: tabs.filter(({ id }) => id === selectedEntryTab)[0]
						.title,
					// criteriaTypeName: lastItem.criteriaTypeName,
					// criteriaType: lastItem.criteriaType,
					sequence: inputList.length + 1,

				}
			]);
		} else {
			// Optionally, show an alert or error message if the fields are not filled
			alert("Please fill in the current row before adding a new one.");
		}
	};
	return (
		<>

			<div className="addPolygonTopicRelatedInputContainer">
				<div className="polygonInputContainer">

					{inputList.map((item, index) => (
						<div className="row " key={item.id || index}>

							<div className="col-xl-11">



								<input
									className='entryEditCriteriaInputdesc'
									title={`Input Description`}
									placeholder={`Enter Description`}
									value={item.name}
									onChange={(e) => handleToChangeInputContent(e, item, index)}
									name='name'
								></input>
							</div>
							<div className="addPolygonTopicFieldSave mt-1">
								<button className="relatededitContentDelete"
									onClick={() => {
										removeEntryContent(index);
									}}>
									<Icon
										className="relatedContentDeleteIcon"
										iconName="ChromeClose"
										title="Close"
									/>
								</button>
							</div>
							{index === inputList.length - 1 && (
								<div className="addPolygonTopicFieldSave mt-1">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddInputFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddInputFields();
											}
										}}
									>
										<Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/>
									</button>
								</div>
							)}
						</div>
					))}
				</div>
			</div>



		</>
	);
};

export default EntryCriteria;
