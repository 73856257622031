import React, { useEffect, useState, appState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import Dropdown from '../Dropdown';
import fileIcons from '../../static/TemplateIcons';
import { useAxios } from '../../context/AxiosContext';

import { TemplateDownloadShowMore } from '../Activities/Activities';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';

function SearchResultPageActivity({
	data,
	index,
	enableSearchSuggestion,
	searchSuggestionId,
	setSearchSuggestionId,
	complexityValue,
	currentPage,
}) {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const [enableShowMoreActivities, setEnableShowMoreActivities] =
		useState(false);
	const [templates, setTemplates] = useState([]);
	const [showText, setShowText] = useState(false);

	const [searchSuggestionData, setSearchSuggestionData] = useState(null);
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(
		enableSearchSuggestion
	);

	const getTemplates = async () => {
		axiosGetService(
			`api/sdmactivity/getactivitytemplates/${data.id}/${complexityValue.id}`
		)
			.then((res) => {
				if (res.data.length !== 0 && res !== []) {
					setTemplates(res.data);
				} else {
					setTemplates(null);
				}
			})
			.catch((err) => {
				// console.log(err);
			});
	};

	const getSearchSuggestions = () => {
		axiosGetService(
			`api/sdmuser/getSearchRecommendations?artifactTypeName=Activity&artifactId=0&activityIdentifier=${data.id}&complexityId=${complexityValue.id}`
		).then((res) => {
			if (res.data.templates.length > 0) {
				// grouping templates
				const groupedTemplatesData = {};
				for (let i = 0; i < res.data.templates.length; i++) {
					const template = res.data.templates[i];
					const templateId = Number(template.id);

					if (Object.keys(groupedTemplatesData).includes(String(templateId))) {
						groupedTemplatesData[templateId][template.complexityName] =
							template;
					} else {
						groupedTemplatesData[templateId] = {
							[template.complexityName]: template,
						};
					}
				}
				res.data.templates = Object.values(groupedTemplatesData);
			}
			setSearchSuggestionData(res.data);
		});
	};

	const activityShowMoreCollapse = (e) => {
		const icon = document.getElementById(`activityShowMore_${index}`);
		if (icon !== null && !icon.contains(e.target)) {
			setEnableShowMoreActivities(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', activityShowMoreCollapse);
		return () => {
			window.removeEventListener('click', activityShowMoreCollapse);
		};
	}, [data]);

	useEffect(() => {
		if (showSearchSuggestion) {
			getSearchSuggestions();
		}
	}, [showSearchSuggestion]);

	useEffect(() => {
		if (searchSuggestionId) {
			setShowSearchSuggestion(
				searchSuggestionId === `search_activity_${data.id}_${data.complexityId}`
			);
		} else if (enableSearchSuggestion) {
			setSearchSuggestionId(`search_activity_${data.id}_${data.complexityId}`);
		}
	}, [searchSuggestionId]);

	return (
		<div
			id={`search_activity_${data.id}_${data.complexityId}`}
			className="tabsWrapper activityTabsSearch"
			onClick={() =>
				setSearchSuggestionId(`search_activity_${data.id}_${data.complexityId}`)
			}
			tabIndex={0}
			onKeyPress={(e) => {
				if (e.which === 13) {
					setSearchSuggestionId(
						`search_activity_${data.id}_${data.complexityId}`
					);
				}
			}}
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={fileIcons.activity}
						className="imageOfSearchResult"
						alt="fileType"
					/>
				</div>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					{data.isExternal ? (
						<a key={data.title} href={data.fileLocation}>
							{data.title}
						</a>
					) : (
						<NavLink
							onClick={() =>
								track(1, 401, data.id, data.title, {
									pageNumber: currentPage,
								})
							}
							to={data.fileLocationNew}
							key={data.title}
						>
							{data.title}
						</NavLink>
					)}
					<div className="ComplexityShowContainer">
						{data.fileLocationNew &&
							data.allAvailableComplexities &&
							data.allAvailableComplexities.map((complexity) => {
								let url = new URL(data.fileLocationNew, window.location.origin);
								const search_params = url.searchParams;
								search_params.set('complexity', complexity.id);
								url = url.pathname + '?' + search_params;
								return (
									<span
										className={
											data.searchComplexities.includes(complexity.id)
												? 'SearchComplexity'
												: ''
										}
									>
										<NavLink
											onClick={() =>
												track(1, 401, data.id, data.title, {
													pageNumber: currentPage,
												})
											}
											to={url}
											key={complexity.name}
										>
											{complexity.name}
										</NavLink>
									</span>
								);
							})}
					</div>
				</div>
				<div class="existingHashTagsOfTopic">
					{showText ? (
						<>
							{data.hashTagsList.map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					) : (
						<>
							{data.hashTagsList.slice(0, 4).map(({ name }) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
								>
									{name}
								</span>
							))}
						</>
					)}
				</div>
				{showText ? (
					<div className="descriptionOfTabs">
						<p>
							{data.tileDescription === null ||
							data.tileDescription.length === 0 ? (
								<span>To be Updated</span>
							) : data.tileDescription.length > 800 ? (
								`${data.tileDescription.slice(0, 800)}...`
							) : (
								data.tileDescription
							)}
						</p>
					</div>
				) : null}
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						{data.toolName ? (
							<div className="phaseDetails">
								<span className="phaseLabel">Tools: </span>
								<span className="phaseName">{data.toolName}</span>
							</div>
						) : (
							<></>
						)}
						<div className="phaseDetails">
							<span className="phaseLabel">Phase: </span>
							<span className="phaseName">{data.phaseName}</span>
							<div
								className="tabExpandedViewIcon"
								tabIndex={0}
								title="Expand Template"
								aria-expanded={showText}
								role="button"
								onClick={() => setShowText(!showText)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								aria-label={
									showText
										? 'Click to Collapse Activity information'
										: 'Click to expand Activity information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div>
						</div>
					</div>
					<div className="tabsRightWrapper">
						<div
							className={`tabsShowMore ${
								enableShowMoreActivities
									? 'showMoreExpanded'
									: 'showMoreCollapsed'
							}`}
							id={`activityShowMore_${index}`}
						>
							<div
								className="showMoreButtonTabs"
								tabIndex={0}
								onClick={() => {
									getTemplates();
									setEnableShowMoreActivities(!enableShowMoreActivities);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										getTemplates();
										setEnableShowMoreActivities(!enableShowMoreActivities);
									}
								}}
								aria-haspopup="Dialog"
								role="listbox"
								aria-label="Show More Templates"
							>
								<div className="tabsMoreIcon">
									<Icon iconName="MoreVertical" />
									{enableShowMoreActivities ? (
										<TemplateDownloadShowMore
											activity={data}
											templates={templates}
											isSearchPage={true}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{searchSuggestionData && showSearchSuggestion ? (
				<div className="relatedSearchSuggestion">
					<span className="tabsbeakArrow"></span>
					<div className="titleOfSuggestionWrap">
						<h6>
							Templates Associated to the Activity
							<br />
							<span>{`"${data.title}"`}</span>
						</h6>
					</div>
					{searchSuggestionData.templates &&
					searchSuggestionData.templates.length > 0 ? (
						<>
							<ul>
								{searchSuggestionData.templates.map((template) => (
									<TemplateSearchSuggestion
										templateGroup={template}
										complexityValue={complexityValue}
									/>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Templates associated to this Activity
						</span>
					)}
					<div className="titleOfSuggestionWrap activityRecommandations">
						<h6>Successor Activities</h6>
					</div>
					{searchSuggestionData.activities &&
					searchSuggestionData.activities.length > 0 ? (
						<>
							<ul className="activityRecommandations">
								{searchSuggestionData.activities.map((activity) => (
									<li key={activity.id + '_' + activity.complexityId}>
										<div className="relatedTitles">
											<img
												src={fileIcons.activity}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(
														1,
														165,
														activity.activityIdentifier,
														activity.activityName,
														{
															suggestedItemName: 'Activity',
															suggestedItemId: 2,
															suggestedComplexity: activity.complexityName,
														}
													);
												}}
												title={activity.activityName}
												aria-label={activity.activityName}
												to={`/project/${activity.projectTypeId}/method/${
													activity.methodId
												}/phase/${
													activity.phaseId ? activity.phaseId : 'null'
												}/parentPhase/${
													activity.parentPhaseId
														? activity.parentPhaseId
														: 'null'
												}/activity/${activity.activityIdentifier}?complexity=${
													activity.complexityId
												}`}
											>
												<span>{activity.activityName}</span>
											</NavLink>
										</div>
										<div className="relatedPhaseName">
											Phase:{' '}
											{activity.solutionMethodName +
												' - ' +
												activity.activityPhaseName}
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Activities associated to this Activity
						</span>
					)}
					<div className="titleOfSuggestionWrap activityRecommandations">
						<h6>Topics Associated to the Activity</h6>
					</div>
					{searchSuggestionData.topics &&
					searchSuggestionData.topics.length > 0 ? (
						<>
							<ul className="activityRecommandations">
								{searchSuggestionData.topics.map((topic) => (
									<li key={topic.id}>
										<div className="relatedTitles">
											<img
												src={fileIcons.topic}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(1, 165, topic.id, topic.name, {
														suggestedItemName: 'Topic',
														suggestedItemId: 10,
													});
												}}
												to={`/topics/${
													topic.id
												}`}
												title={topic.name}
												aria-label={topic.name}
											>
												<span>{topic.name}</span>
											</NavLink>
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Topics associated to this Activity
						</span>
					)}
				</div>
			) : null}
		</div>
	);
}

const TemplateSearchSuggestion = ({ templateGroup, complexityValue }) => {
	const { track } = useAppInsights();
	const [complexity, setComplexity] = useState(() => {
		const complexities = Object.keys(templateGroup);
		if (complexities.includes(complexityValue.name)) {
			return {
				name: complexityValue.name,
				type: 'option',
			};
		} else {
			return {
				name: complexities[0],
				type: 'option',
			};
		}
	});
	const [template, setTemplate] = useState(templateGroup[complexity.name]);
	const complexityData = Object.keys(templateGroup).map((complexityName) => ({
		name: complexityName,
	}));

	useEffect(() => {
		setTemplate(templateGroup[complexity.name]);
	}, [complexity.name]);

	return (
		<li>
			<div className="relatedTitles">
				<img
					src={
						template.inProgress
							? fileIcons['inProgress']
							: fileIcons[template.fileType] || fileIcons['defaultTemplate']
					}
					alt="File Type"
					className="searchSuggestionImage"
				/>
				{template.inProgress ? (
					<span title={template.title} aria-label={template.title}>
						{template.title}
					</span>
				) : (
					<a
						onClick={() => {
							track(1, 165, template.templateId, template.title, {
								suggestedItemName: 'Templates',
								suggestedItemId: 1,
								suggestedComplexity: template.complexityName,
							});
						}}
						href={template.fileLocation}
						target="_blank"
						title={template.title}
						aria-label={template.title}
					>
						<span>{template.title}</span>
					</a>
				)}
			</div>
			<div className="searchSuggestionComplexityDropdown">
				<Dropdown
					className={template.title}
					data={complexityData}
					value={complexity}
					setValue={setComplexity}
					enableDropdown={true}
				/>
			</div>
		</li>
	);
};

export default SearchResultPageActivity;
