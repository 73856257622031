import React, { useState } from 'react';
import { Icon } from '@fluentui/react';
import ProcessGroupDropdown from './ProcessGroupDropdown';

const InputsForSMM = ({
	processGroup,
	processGroupMap,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
}) => {
	return (
		<div className="Detailswrapper inputSMMIFlag p-0">
			{/* <div className="DetailswrapperHeading">Inputs for SMMI</div> */}
			<div className="DetailswrapperTitle">
				<div className="NewActivitycomplexityWRapper">
					<div>
					<ul className="checkbox-list">
  <li>
    <label className="NotificationChkboxContainer">
      <input
        name="complexity"
        type="checkbox"
        checked={complexityLevelDetails[selectedComplexity].isDeliverables}
        onChange={() =>
          setComplexityLevelDetails((prevState) => ({
            ...prevState,
            [selectedComplexity]: {
              ...complexityLevelDetails[selectedComplexity],
              isDeliverables: !complexityLevelDetails[selectedComplexity].isDeliverables,
            },
          }))
        }
      />
      <span className="CustomCheckmark"></span>
      <span className="filterLabel ml-2" aria-label="Is Deliverable" tabIndex={0}>
        Is Deliverable
      </span>
    </label>
  </li>
  <li>
    <label className="NotificationChkboxContainer">
      <input
        name="complexity"
        type="checkbox"
        checked={
          complexityLevelDetails[selectedComplexity] &&
          complexityLevelDetails[selectedComplexity].isCritical
        }
        onChange={() =>
          setComplexityLevelDetails((prevState) => ({
            ...prevState,
            [selectedComplexity]: {
              ...complexityLevelDetails[selectedComplexity],
              isCritical: !complexityLevelDetails[selectedComplexity].isCritical,
            },
          }))
        }
      />
      <span className="CustomCheckmark"></span>
      <span className="filterLabel ml-2" aria-label="Is Critical" tabIndex={0}>
        Is Critical
      </span>
    </label>
  </li>
  <li>
    <label className="NotificationChkboxContainer">
      <input
        name="complexity"
        type="checkbox"
        checked={
          complexityLevelDetails[selectedComplexity] &&
          complexityLevelDetails[selectedComplexity].isProcessStep
        }
        onChange={() =>
          setComplexityLevelDetails((prevState) => ({
            ...prevState,
            [selectedComplexity]: {
              ...complexityLevelDetails[selectedComplexity],
              isProcessStep: !complexityLevelDetails[selectedComplexity].isProcessStep,
            },
          }))
        }
      />
      <span className="CustomCheckmark"></span>
      <span className="filterLabel ml-2" aria-label="Is Process Step" tabIndex={0}>
        Is Mandatory Process Step
      </span>
    </label>
  </li>
  <li>
    <label className="NotificationChkboxContainer">
      <input
        name="complexity"
        type="checkbox"
        checked={complexityLevelDetails[selectedComplexity].secureByDefault}
        onChange={() =>
          setComplexityLevelDetails((prevState) => ({
            ...prevState,
            [selectedComplexity]: {
              ...complexityLevelDetails[selectedComplexity],
              secureByDefault: !complexityLevelDetails[selectedComplexity].secureByDefault,
            },
          }))
        }
      />
      <span className="CustomCheckmark"></span>
      <span className="filterLabel ml-2" aria-label="Secure By Default" tabIndex={0}>
        Secure By Default
      </span>
    </label>
  </li>
</ul>

					</div>
				</div>
			</div>
		</div>
	);
};

export default InputsForSMM;
