import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import Spinner from '../../helpers/Spinner';
import { useAxios } from '../../context/AxiosContext';
import MultiSelectDropdown from './MultiSelectDropdown';
import { Link, useHistory } from 'react-router-dom';

const PhaseSelect = () => {
	const projectTypeData = [
		{
			id: 1,
			name: 'Consulting',
		}
	];
	const { axiosGetService } = useAxios();
	const [showSpinner, setShowSpinner] = useState(true);
	const [showMore, setShowMore] = useState(false);
	const [phasesSearchValue, setPhasesSearchValue] = useState('');
	const [avilablePhases, setAvilablePhases] = useState([]);
	const history = useHistory();
	// project type state
	const [selectedProjectType, setSelectedProjectType] = useState([]);

	//solution method state
	const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
	const [solutionMethods, setSolutionMethods] = useState([]);

	const findSelectedItem = (value, item) => {
		const filteredData = value.filter(({ id }) => id === item);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	const getSolutionMethods = async () => {
		const selecteProject = selectedProjectType.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${
				selecteProject.length === 1 ? selecteProject.toString() : 0
			}&isSvgRequired=${false}`
		).then((response) => {
			const methods = response.data.map((item) => {
				return {
					id: item.solutionMethodId,
					name: item.solutionMethodName,
				};
			});
			const filterTag = selectedSolutionMethods.filter(({ id }) =>
				findSelectedItem(methods, id)
			);
			if (
				JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
			) {
				setSelectedSolutionMethods(filterTag);
			}
			setSolutionMethods(methods);
		});
	};

	const getPhases = async () => {
		const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
			return id;
		});
		await axiosGetService(
			`api/sdmphase/getphases?searchValue=${
				phasesSearchValue.trim().length > 0
					? encodeURIComponent(phasesSearchValue)
					: 0
			}&methodIds=${
				solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
			}&top=${0}`
		).then((response) => {
			setAvilablePhases(response.data.phases);
			closeSpinner();
		});
	};

	const closeSpinner = () => {
		setTimeout(() => {
			setShowSpinner(false);
		}, 1000);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSolutionMethods();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [selectedProjectType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhases();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phasesSearchValue, selectedSolutionMethods]);

	return (
		<div className="addActivityFields">
			<div className="cloneActivityContainer">
				<div className="cloneActivityFieldsWrapper">
					<input
						title={phasesSearchValue}
						placeholder="Phase name"
						value={phasesSearchValue}
						onChange={(e) => setPhasesSearchValue(e.target.value)}
					/>
					<Icon
						iconName="FilterSolid"
						role="button"
						title="phase filter icon"
						aria-label={`phase filter icon`}
						tabIndex={0}
					/>
					<Icon
						iconName="CaretUpSolid8"
						className="cloneDropDownArrow"
						role="listbox"
						title="phase drop down"
						aria-label={`phase drop down`}
						tabIndex={0}
					/>
				</div>
			</div>
			<div className="cloneActivityFiltersWrapper">
				<div className="cloneActivitySolTypeFilters">
					<div className='hidingConsultingSupport'>
						<span>Consulting / Support</span>
						<MultiSelectDropdown
							id="selectedProjectType"
							className="activityReferProjectSelectionDropdown"
							data={projectTypeData}
							setValue={setSelectedProjectType}
							value={selectedProjectType}
							enableDropdown={true}
							label="Project Types"
						/>
					</div>
					<div className="multiSelectdropDown CloseMultiSelectDp">
						<span>Solution Methods</span>
						<MultiSelectDropdown
							id="selectedSolutionMethods"
							className="activityReferSolutionMethodDropdown"
							data={solutionMethods}
							setValue={setSelectedSolutionMethods}
							value={selectedSolutionMethods}
							enableDropdown={true}
							label="Solution Methods"
						/>
					</div>
				</div>
			</div>
			<div className="cloneActivityResultsWrapper">
				{showSpinner ? (
					<Spinner />
				) : (
					<>
						<h6>{avilablePhases.length} Result(s) Found</h6>
						{avilablePhases.length ? (
							<>
								<ul>
									{showMore ? (
										<>
											{avilablePhases.map((item) => (
												<li key={item.id + item.name}>
													<label className="NotificationChkboxContainer">
														<Link
															className="complexityLabel cloneActivityTitle"
															to={`/project/${
																item.projectTypeName === 'Consulting' ? 1 : 2
															}/method/${item.solutionMethodId}/phase/${
																item.phaseId
															}/parentPhase/${null}`}
														>
															{item.name}
														</Link>
													</label>
													<div className="cloneInfiActivity">
														<Icon
															iconName="Info"
															tabIndex={0}
															role="button"
															title={`${item.name} info icon`}
															aria-label={`${item.name} info icon`}
														/>
														<div className="tabsTitleToolTip">
															<span className="tabsToolTipArrow"></span>
															<h5>Phase Details</h5>
															<h6>{item.projectTypeName}</h6>
															<span className="detailsOfCloneActivity">
																<span className="detailsCloneActivitySol">
																	<span>Delivery Method</span>:&nbsp;
																	<span>{item.solutionMethodName}</span>
																</span>
															</span>
														</div>
													</div>
												</li>
											))}
										</>
									) : (
										<>
											{avilablePhases
												.slice(
													0,
													avilablePhases.length > 10
														? 10
														: avilablePhases.length
												)
												.map((item) => (
													<li key={item.id + item.name}>
														<label className="NotificationChkboxContainer">
															<Link
																className="complexityLabel cloneActivityTitle"
																to={`/project/${
																	item.projectTypeName === 'Consulting' ? 1 : 2
																}/method/${item.solutionMethodId}/phase/${
																	item.phaseId
																}/parentPhase/${null}`}
															>
																{item.name}
															</Link>
														</label>
														<div className="cloneInfiActivity">
															<Icon
																iconName="Info"
																tabIndex={0}
																role="button"
																title={`${item.name} info icon`}
																aria-label={`${item.name} info icon`}
															/>
															<div className="tabsTitleToolTip">
																<span className="tabsToolTipArrow"></span>
																<h5>Phase Details</h5>
																<h6>{item.projectTypeName}</h6>
																<span className="detailsOfCloneActivity">
																	<span className="detailsCloneActivitySol">
																		<span>Delivery Method</span>:&nbsp;
																		<span>{item.solutionMethodName}</span>
																	</span>
																</span>
															</div>
														</div>
													</li>
												))}
										</>
									)}
								</ul>
							</>
						) : (
							<></>
						)}
						{avilablePhases.length > 10 && !showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(true)}
								aria-label="Click to Show more Phases"
								role="Link"
								title="show more phases"
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(true);
									}
								}}
								tabIndex={0}
							>
								Show more
							</span>
						) : (
							<></>
						)}
						{showMore ? (
							<span
								className="closeShowMoreLess"
								onClick={() => setShowMore(false)}
								aria-label="Click to Show less Phases"
								role="Link"
								title="show less phases"
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowMore(false);
									}
								}}
								tabIndex={0}
							>
								Show less
							</span>
						) : (
							<></>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PhaseSelect;
