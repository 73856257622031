import React,{useState} from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { getDate } from '../../helpers/Validation';

const PreviewModal = ({
	setShowPreviewModal,
	hashTags,
	topicVersion,
	title,
	content,
	setShowPublishModal,
	relatedContent,
	topicOwnerInfo,
	businessRelatedContent
}) => {
	
	const [activeContent, setActiveContent] = useState(1)
	return (
		<Modal
			className="topicPreviewAndSubmitPopUp topicBuilderPreviewPopUp"
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="modal-90w"
		>
			<Modal.Header>
				<Modal.Title>
					<div className="topicOwnerPreviewPopupTitle">
						<span>Preview</span>
						<span className="topicVersion">
							Document Version: {topicVersion}
							,&nbsp;&nbsp;
							{`${getDate()} `}
						</span>
					</div>
					<div className="discardAndPreviewButton">
						<Button
							title="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowPreviewModal(false);
								}
							}}
							onClick={() => {
								setShowPreviewModal(false);
							}}
							className="activityDiscardButton"
						>
							Cancel
						</Button>
						<Button
							title="Publish"
							tabIndex={0}
							onClick={() => {
								setShowPublishModal(true);
								setShowPreviewModal(false);
							}}
							className="activityPreviewButton"
						>
							Publish
						</Button>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="topicsCrowdSourcingContainer simpleTopicPreview">
					<div class="topicsDescriptionDetails">
						<div className="topicDescriptionDiv row">
							<div className="col-xl-9 col-lg-9">
									<div className="">
										<h4 className="topicTitle">{title}</h4>
										{/* <div class="topicsOwnerDetails">
											<img src={topicOwnerInfo.photo} alt="topicOwnerImage" />
											<div class="topicsOwnerPersonalDetails">
												<p>Owner</p>
												<a
													href={`mailto:${topicOwnerInfo.mail}`}
													linkchanged="1"
												>
													<h6>
														{topicOwnerInfo.firstName}&nbsp;
														{topicOwnerInfo.surname}
													</h6>
												</a>
											</div>
										</div> */}
									</div>
									<div class="existingHashTagsOfTopic">
										{hashTags.map((hashtag) => (
											<span className='hashtagPreview' key={hashtag}>{hashtag}</span>
										))}
									</div>
									<div
										className="desc ck-content"
										id="previewDescription"
										dangerouslySetInnerHTML={{
											__html: HtmlSanitizer(content),
										}}
									/>
								{/* <div class="associateContentPanel">
									<div className='topicsRelatedContentDiv'>
										<h5 className={activeContent===1?'topicsRelatedContentDivActive':''}
										    onClick={()=>setActiveContent(1)}>Associated Content</h5>
										<h5 className={activeContent===2?'topicsRelatedContentDivActive':''}
										onClick={()=>setActiveContent(2)}>Business Rule</h5>
									</div>
									{activeContent===1 && <>{relatedContent.length ? (
										<ul>
											{relatedContent.map((item) => (
												<li key={item.id}>
													<div>
														<Icon iconName="LocationDot" />
														<a href={item.url} target="_blank">
															{item.title}
														</a>
													</div>
													<div></div>
												</li>
											))}
										</ul>
									) : (
										<p className="noRelatedContentMessage">
											Content not available
										</p>
									)}</>}
									{activeContent===2 && <>{businessRelatedContent.length ? (
										<ul>
											{businessRelatedContent.map((item) => (
												<li key={item.id}>
													<div>
														<Icon iconName="LocationDot" />
														<a href={item.url} target="_blank">
															{item.name}
														</a>
													</div>
													<div></div>
												</li>
											))}
										</ul>
									) : (
										<p className="noRelatedContentMessage">
											Content not available
										</p>
									)}</>}
								</div> */}
							</div>
							<div className='col-xl-3 col-lg-3 normalTopicPreview'>
								<div className="topicsOwner">
									<div class="previewNormalTopicsOwnerDetails previewTopicsOwnerDetails">
										<img src={topicOwnerInfo.photo} alt="topicOwnerImage" />
										<div class="topicsOwnerPersonalDetails">
											<p>Owner</p>
											<a
												href={`mailto:${topicOwnerInfo.mail}`}
												linkchanged="1"
											>
												<h6>
													{topicOwnerInfo.firstName}&nbsp;
													{topicOwnerInfo.surname}
												</h6>
											</a>
										</div>
									</div>
								</div>
								<div className='polygonAssociateContentPanel'>
									<div class="topicAssociateContentPanel ">
										<div className='topicsRelatedContentDiv'>
											<div className={activeContent === 1 ? 'topicsRelatedContentDivActive' : 'topicsRelatedContentDivAssociate'}>
												<h5 onClick={() => setActiveContent(1)}>Associated Content</h5>
											</div>
											<div className={activeContent === 2 ? 'topicsRelatedContentDivActive' : 'topicsRelatedContentDivAssociate'}>
												<h5 onClick={() => setActiveContent(2)}>Business Rule</h5>
											</div>
										</div>
										{activeContent === 1 && <>{relatedContent.length ? (
											<ul>
												{relatedContent?.map((item) => (
													<li key={item.id}>
														<div>
															<Icon iconName="LocationDot" />
															<a href={item.url} target="_blank">
																{item.title}
															</a>
														</div>
													</li>
												))}
											</ul>
										) : (
											<p className="noRelatedContentMessage">
												Content not available
											</p>
										)}</>}
										{activeContent === 2 && <>{businessRelatedContent.length ? (
											<ul>
												{businessRelatedContent?.map((item) => (
													<li key={item.id}>
														<div>
															<Icon iconName="LocationDot" />
															<a href={item.url} target="_blank">
																{item.name}
															</a>
														</div>
														<div></div>
													</li>
												))}
											</ul>
										) : (
											<p className="noRelatedContentMessage">
												Content not available
											</p>
										)}</>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PreviewModal;
