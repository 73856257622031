import React, { useState, useEffect } from 'react';
import dompurify from 'dompurify';
import $ from 'jquery';

const EntryCriteriaHistory = ({
	criteriaData,
	toolsSidebarHeight,
	criteriaSuggestions,
	ckEditorUserMap,
	reviewedCriteriaSuggestions,
	criteria,
	currentTab,
	setCurrentTab,
	reorderedEntryReviewedSuggestions,
	reorderedInputReviewedSuggestions,
	reorderHistory
}) => {
	const htmlSanitizer = dompurify.sanitize;
	const criteriaType = {
		entry: {
			0: 'Entry Criteria',
			1: 'Input',
		},
	};

	const getReviewStatus = (criteria, cardclass) => {

		if (reviewedCriteriaSuggestions.length > 0) {
			const status = JSON.parse(
				reviewedCriteriaSuggestions.filter(
					(item) => JSON.parse(item).id === criteria.id
				)[0]
			).state;
			if (cardclass) {
				if (status === 'accepted') {
					return 'Approved';
				} else {
					return 'Rejected';
				}
			} else {
				return status;
			}
		}
		return null;
	};
	$(document).ready(function () {

		$('ul.entry-criteria-history-tabs li').click(function () {
			var tab_id = $(this).attr('data-tab');
			setCurrentTab(tab_id);
			$('ul.entry-criteria-history-tabs li').removeClass('currentEntryTab');
			$('.entry-criteria-history-tab-content').removeClass('currentEntryTab');
			//$('.entry-criteria-history-suggestions-tab-content').addClass('currentEntryTab');
			$(this).addClass('currentEntryTab');
			$('#' + tab_id).addClass('currentEntryTab');

		});
	});


	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer no-border">
					<div className="">
						<div class="criteria-history-tab-container">
							<ul class="entry-criteria-history-tabs">
								<li
									className='tab-link currentEntryTab'
									data-tab='tab-entry-hist'
								>
									{criteriaType[criteria][0]}
								</li>
								<li
									className='tab-link'
									data-tab='tab-input-hist'
								>
									{criteriaType[criteria][1]}
								</li>
							</ul>

							<div
								id='tab-entry-hist'
								className='entry-criteria-history-tab-content currentEntryTab'
							>
								<ul>
									{criteriaData.map(
										(item) =>
											//JSON.parse(item).suggestionType === 'none' ? (
											!criteriaSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											item.criteriaTypeName === criteriaType[criteria][0] && (

												<li className={'none'} key={item.id}>
													<div
														className="descEn"
														id="criteriaDesc"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(item.name),
														}}
													/>
												</li>
											)
									)}

									{criteriaSuggestions.map(
										(item) =>
											JSON.parse(item).criteriaTypeName ===
											criteriaType[criteria][0] && (
												<li
													className={`${JSON.parse(item).suggestionType === 'deletion'
														? 'userDeletedCriteria'
														: 'userAddedCriteria'
														}`}
													key={JSON.parse(item).id}
												>

													<div
														className="descEn"
														id="entryCriteriaSanitizer"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(item).name),
														}}
													/>
												</li>
											)
									)}
								</ul>
							</div>
							<div
								id='tab-input-hist'
								className='entry-criteria-history-tab-content'
							>
								<ul>
									{criteriaData.map(
										(item) =>
											//JSON.parse(item).suggestionType === 'none' ? (
											!criteriaSuggestions.some(
												(value) => JSON.parse(value).id === item.id
											) &&
											item.criteriaTypeName === criteriaType[criteria][1] && (
												<li className={'none'} key={JSON.parse(item.id)}>
													<div
														className="descEn"
														id="criteriaDesc"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(item.name),
														}}
													/>
												</li>
											)
									)}
									{criteriaSuggestions.map(
										(item) =>
											JSON.parse(item).criteriaTypeName ===
											criteriaType[criteria][1] && (
												<li
													className={`${JSON.parse(item).suggestionType === 'deletion'
														? 'userDeletedCriteria'
														: 'userAddedCriteria'
														}`}
													key={JSON.parse(item).id}
												>
													<div
														className="descEn"
														id="entryCriteriaSanitizer"
														dangerouslySetInnerHTML={{
															__html: htmlSanitizer(JSON.parse(item).name),
														}}
													/>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="" style={{ height: toolsSidebarHeight }}> */}
				<div
					style={{ height: toolsSidebarHeight }}
					className={`entry-criteria-history-suggestions-tab-content currentEntryTab toolsSidebar ${currentTab === 'tab-entry-hist' ? '' : 'hiddenEntryCriteria'
						} `}
				>    {reorderedEntryReviewedSuggestions.length !== 0 && (
					<>
					<div
						className={`sideBarCard ${
							JSON.parse(reorderedEntryReviewedSuggestions).state === 'rejected'
								? 'userDeletedToolsAndGuidance'
								: 'userAddedItem'
						} ${JSON.parse(reorderedEntryReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}`}
					>
						<div className="sideBarCardHeader">
							<img
								className="userImg"
								src={reorderHistory.isEntryReorder && JSON.parse(reorderHistory.isEntryReorder[0]).authorId[1]}
								alt=""
							/>
							<span>{reorderHistory.isEntryReorder && JSON.parse(reorderHistory.isEntryReorder[0]).authorId[0]}</span>
							<span className="suggestionStatus myContributionSuggestionStatus">
								{JSON.parse(reorderedEntryReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}
							</span>
						</div>
						<div className="itemStatus">
							<span>
								Reordered
							</span>
							&nbsp;"Description:"
							<div
							> Entry has been reordered </div>
							
						</div>
					</div>
				</>

				)}
					{criteriaSuggestions.map(
						(card) => (
							JSON.parse(card).criteriaTypeName ===
							criteriaType[criteria][0] && (
								<>
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											} ${getReviewStatus(JSON.parse(card), false)}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
											<span className="suggestionStatus myContributionSuggestionStatus">
												{getReviewStatus(JSON.parse(card), true)}
											</span>
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'deletion'
													? 'Remove'
													: JSON.parse(card).suggestionType === 'insertion'
														? 'Add'
														: 'Edit'}
											</span>
											&nbsp;"Text/Numeric:
											{JSON.parse(card).valueType === 1 ? 'Text' : 'Numeric'}{' '}
											Description:<div
												className="descEn"
												id="criteriaSuggestionCard"
												dangerouslySetInnerHTML={{
													__html: htmlSanitizer(JSON.parse(card).name),
												}}
											/>"
										</div>
									</div>
								</>
							)))}
				</div>
				<div

					style={{ height: toolsSidebarHeight }}
					className={`entry-criteria-history-suggestions-tab-content currentEntryTab toolsSidebar ${currentTab === 'tab-input-hist' ? '' : 'hiddenEntryCriteria'
						} `}
				>
					{reorderedInputReviewedSuggestions.length !== 0 && (
					<>
					<div
						className={`sideBarCard ${
							JSON.parse(reorderedInputReviewedSuggestions).state === 'rejected'
								? 'userDeletedToolsAndGuidance'
								: 'userAddedItem'
						} ${JSON.parse(reorderedInputReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}`}
					>
						<div className="sideBarCardHeader">
							<img
								className="userImg"
								src={reorderHistory.isInputReorder && JSON.parse(reorderHistory.isInputReorder[0]).authorId[1]}
								alt=""
							/>
							<span>{reorderHistory.isInputReorder && JSON.parse(reorderHistory.isInputReorder[0]).authorId[0]}</span>
							<span className="suggestionStatus myContributionSuggestionStatus">
								{JSON.parse(reorderedInputReviewedSuggestions).state === 'rejected' ? 'Rejected' : 'Approved'}
							</span>
						</div>
						<div className="itemStatus">
							<span>
								Reordered
							</span>
							&nbsp;"Description:"
							<div
							> Input has been reordered </div>
							
						</div>
					</div>
				</>

				)}
					{criteriaSuggestions.map(
						(card) => (
							JSON.parse(card).criteriaTypeName ===
							criteriaType[criteria][1] && (
								<>
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											} ${getReviewStatus(JSON.parse(card), false)}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
											<span className="suggestionStatus myContributionSuggestionStatus">
												{getReviewStatus(JSON.parse(card), true)}
											</span>
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'deletion'
													? 'Remove'
													: JSON.parse(card).suggestionType === 'insertion'
														? 'Add'
														: 'Edit'}
											</span>
											&nbsp;"Text/Numeric:
											{JSON.parse(card).valueType === 1 ? 'Text' : 'Numeric'}{' '}
											Description:<div
												className="descEn"
												id="criteriaSuggestionCard"
												dangerouslySetInnerHTML={{
													__html: htmlSanitizer(JSON.parse(card).name),
												}}
											/>"
										</div>
									</div>
								</>
							)))}
				</div>
				{/* </div> */}
			</div>
		</>
	);
};

export default EntryCriteriaHistory;
