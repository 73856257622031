import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@fluentui/react/lib/Icon';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: 'white',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: 'white',
});

const SolutionMethodSequence = ({ sequence, setSequence }) => {
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const item = reorder(
			sequence,
			result.source.index,
			result.destination.index
		);
		setSequence(item);
	};

	return (
		<>
			<div className="solutionMethodSequenceContainer">
				<h6>Sequence the solution methods</h6>
				<div className="sequentialPhaseList">
					<DragDropContext
						onDragEnd={(result) => {
							onDragEnd(result);
						}}
					>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									<div className="sequentialdraggblePhaseList">
										{sequence.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={item.id.toString()}
												index={index}
											>
												{(childProvided, childSnapshot) => (
													<div
														ref={childProvided.innerRef}
														{...childProvided.draggableProps}
														{...childProvided.dragHandleProps}
														style={getItemStyle(
															childSnapshot.isDragging,
															childProvided.draggableProps.style
														)}
													>
														<div className={`sequentialPhaseItem ${item.type}`}>
															<Icon iconName="GlobalNavButton" />
															<span>{item.title}</span>
														</div>
													</div>
												)}
											</Draggable>
										))}
									</div>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</>
	);
};

export default SolutionMethodSequence;
