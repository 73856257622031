import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from 'react-router';
import PhaseSelect from './PhaseSelect';

const ManageActivities = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements manageActivities"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Activities
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										<span>Add or Edit Activities</span>
									</li>
								</ul>
							</div>
							<div class="middleDetailsContainer">
								<div class="methodOwnerMiddleContainer">
									<div class="methodPhaseDetailsTab">
										<div className="addRolesListContainer addDeliveryPlaybookContainer manageACtivities">
											<PhaseSelect />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageActivities;
