import React, { useEffect, useState } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import dompurify from "dompurify";
import { v4 as uniqueIdGenerator } from "uuid";
import TasksCKEditorLink from '../NewActivityDetailsPage/TasksCKEditorLink';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';

const TaskContribution = ({
  contributorsData,
  taskButtonClicked,
  setTaskButtonClicked,
  ckEditorUserMap,
  CkEditorCurrentUser,
  taskSuggestions,
  setTaskSuggestions,
  taskWithSuggestions,
  setTaskWithSuggestions,
  reviewedTaskSuggestions,
  setReviewedTaskSuggestions,
  reviewerTaskSuggestions,
  reviewedReorderedTaskSuggestions,
  setReviewedReorderedTaskSuggestions,
  source,
  taskData,
  setTaskData,
  taskReorder,
  setTaskReorder,
  setTaskReorderArray,
  taskReorderArray,
  initialTaskIdBeforeReorder,
  setInitialTaskIdBeforeReorder,
  disableDrag,
  setDisableDrag,
  ckEditorCurrentReorderUsername,
  setCkEditorCurrentReorderUsername,
  ckEditorCurrentReorderUserImage,
  setCkEditorCurrentReorderUserImage,
  user,
  setIsReviewerTaskReordered,
  setIsApproverTaskReordered,
  setReorderContributionCount,
  setAddedTaskSequence,
  setIsOrderChanged
}) => {

  const pageType = ['review', 'approve'];
  const htmlSanitizer = (data) => {
    const config = {
      ADD_ATTR: [ 'target'],
      };
    return dompurify.sanitize(data, config);
  };
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [taskList, setTaskList] = useState([...contributorsData.taskList]);
  const [toolsSidebarHeight, setToolsSidebarHeight] = useState("239px");
  const [userImgWithReorder, setUserImgWithReorder] = useState(null)
  const [userNameWithReorder, setUserNameWithReorder] = useState(null)
  const [taskListwithInsert,  setTaskListwithInsert] = useState([...contributorsData.taskList])
  const [contributorReorderName, setContributorReorderName] = useState('')
  const [contributorReorderImage, setContributorReorderImage] = useState('')

  const updateTask = () => {
    if (taskDescription !== '' && taskDescription.trim().length !== 0) {
      if (editMode) {
        if (taskList.some((item) => item.id === updateId)) {
          const updatedDtails = taskWithSuggestions.map((item) =>
            JSON.parse(item).id === updateId
              ? JSON.stringify({
                ...JSON.parse(item),
                suggestionType:"updation",
                suggestionId: uniqueIdGenerator(),
                authorId: CkEditorCurrentUser,
                createdAt: new Date(),
                name: taskDescription,
              })
              : item
          );
          setTaskWithSuggestions([...updatedDtails]);
          if (!taskSuggestions.some((item) => JSON.parse(item).id === updateId)) {
            const task = taskList
              .filter((item) => item.id === updateId)
              .map((value) => {
                return JSON.stringify({
                  ...value,
                  suggestionType:"updation",
                  suggestionId: uniqueIdGenerator(),
                  authorId: CkEditorCurrentUser,
                  createdAt: new Date(),
                  name: taskDescription,
                });
              });
            setTaskSuggestions((prevState) => [...prevState, ...task]);
          } else {
            const updatedSuggestions = taskSuggestions.map((item) =>
              JSON.parse(item).id === updateId
                ? JSON.stringify({
                  ...JSON.parse(item),
                  suggestionType:"updation",
                  suggestionId: uniqueIdGenerator(),
                  authorId: CkEditorCurrentUser,
                  createdAt: new Date(),
                  name: taskDescription,
                })
                : item
            );
            setTaskSuggestions([...updatedSuggestions]);
          }
        } else {
          const updatedDtails = taskWithSuggestions.map((item) =>
            JSON.parse(item).id === updateId
              ? JSON.stringify({
                ...JSON.parse(item),
                authorId: CkEditorCurrentUser,
                createdAt: new Date(),
                name: taskDescription,
              })
              : item
          );
          const updateSuggestions = taskSuggestions.map((item) =>
            JSON.parse(item).id === updateId
              ? JSON.stringify({
                ...JSON.parse(item),
                authorId: CkEditorCurrentUser,
                createdAt: new Date(),
                name: taskDescription,
              })
              : item
          );
          setTaskSuggestions([...updateSuggestions]);
          setTaskWithSuggestions([...updatedDtails]);
        }
        setEditMode(false);
        setTaskDescription("");
        setUpdateId(0);
        setTaskName("");
        setError(false);
      } else {
        const task = {
          id: uniqueIdGenerator(),
          suggestionId: uniqueIdGenerator(),
          name: taskDescription,
          status: "new",
          suggestionType: "insertion",
          authorId: CkEditorCurrentUser,
          createdAt: new Date(),
          sequence: taskWithSuggestions.length + 1
        };
        if(taskReorderArray.length !== 0){
          const insertionId =  {id: task.id, name: task.name}
          setTaskReorderArray(prev=> [...prev, JSON.stringify(insertionId)])
        }
        setTaskListwithInsert([...taskListwithInsert, task])
        const taskJson = JSON.stringify(task);
        setAddedTaskSequence(prev=>[...prev, {id:JSON.parse(taskJson).id,
                                 sequence:JSON.parse(taskJson).sequence}])
        setTaskSuggestions([...taskSuggestions, taskJson]);
        setTaskWithSuggestions([...taskWithSuggestions, taskJson]);
        setInitialTaskIdBeforeReorder([...initialTaskIdBeforeReorder, taskJson])
        setTaskName("");
        setTaskDescription("");
        setError(false);
      }
    } else {
      setError(true);
    }
  };
 

  const deleteOtherDetails = (item) => {
    const task = {
      ...item,
      suggestionId: uniqueIdGenerator(),
      suggestionType: "deletion",
      authorId: CkEditorCurrentUser,
      createdAt: new Date(),
    };
    const taskJson = JSON.stringify(task);

    setTaskSuggestions([taskJson, ...taskSuggestions]);

    const updatedSuggestions = taskWithSuggestions.map((value) =>
      JSON.parse(value).id !== item.id
        ? value
        : JSON.stringify({
          ...JSON.parse(value),
          suggestionId: uniqueIdGenerator(),
          suggestionType: "deletion",
          authorId: CkEditorCurrentUser,
          createdAt: new Date(),
        })
    );
    setTaskWithSuggestions([...updatedSuggestions]);
    // setInitialTaskIdBeforeReorder([...updatedSuggestions])
  };
  
  const acceptSuggestion = (value) => {
    if (JSON.parse(value).suggestionType === 'deletion') {
      if(taskReorder && ckEditorCurrentReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
        && JSON.parse(value).suggestionType==='deletion'){
        const initialTask = initialTaskIdBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
        const currentTask = taskWithSuggestions.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
        const isSimilar = initialTask.some((element, index) => element !== currentTask[index])
        if(!isSimilar){
          removeReorderedSuggestions();
          toast.info('Reorder suggestion has rejected automatically', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      if (taskList.some((item) => item.id === JSON.parse(value).id)) {
        const updatedTask = taskList.filter(
          (item) => item.id !== JSON.parse(value).id
        );
        setTaskList([...updatedTask]);
        const updatedDataList = taskData.filter(
          (item) => item.id !== JSON.parse(value).id
        );
        setTaskData([...updatedDataList]);
        const filteredList = taskWithSuggestions.filter(
          (item) => JSON.parse(item).id !== JSON.parse(value).id
        );
        setTaskWithSuggestions([...filteredList]);
        setInitialTaskIdBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
        if(taskReorderArray !== null){
          setTaskReorderArray(prev=>prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
        }
   
      } else {
        const filteredList = taskWithSuggestions.filter(
          (item) => JSON.parse(item).id !== JSON.parse(value).id
        );
        setTaskSuggestions([...filteredList]);
      }
    } else {
      const updatedList = taskWithSuggestions.map((item) =>
        JSON.parse(item).id === JSON.parse(value).id
          ? JSON.stringify({
            ...JSON.parse(item),
            suggestionType: 'none',
            suggestionId: null,
            authorId: CkEditorCurrentUser,
            createdAt: new Date(),
          })
          : item
      );
      setTaskWithSuggestions([...updatedList]);
      if (!taskList.some((item) => item.id === JSON.parse(value).id)) {
        const criteria = {
          id: JSON.parse(value).id,
          name: JSON.parse(value).name,
          activityId:JSON.parse(value).activityId,
          complexityId:JSON.parse(value).complexityId,
          description:JSON.parse(value).description,
          createdDate: JSON.parse(value).createdDate,
          createdBy: JSON.parse(value).authorId,
          sequence: taskList.indexOf(taskList.length-1) + 2
        };
        const updatedTaskData = ([...taskData, criteria])
        updatedTaskData.map((e,i)=>{
         return e.sequence = i + 1;
        })
        setTaskData(updatedTaskData);
        setTaskList([...taskList, criteria]);
      } else {
        const updatedList = taskList.map((item) =>  
          item.id === JSON.parse(value).id ? JSON.parse(value) : item
        );
        setTaskList([...updatedList]);
        const updatedData = taskData.map((item) =>
          item.id === JSON.parse(value).id ? JSON.parse(value) : item
        );
        setTaskData([...updatedData]);
      }
    }

    const filteredValue = taskSuggestions.filter(
      (item) => JSON.parse(item).id !== JSON.parse(value).id
    );
    setTaskSuggestions([...filteredValue]);

    setReviewedTaskSuggestions([
      ...reviewedTaskSuggestions,
      JSON.stringify({ id: JSON.parse(value).id, state: 'accepted' }),
    ]);
    
  };
 
  const removeSuggestion = (value) => {
    const filteredValue = taskSuggestions.filter(
      (item) => JSON.parse(item).id !== JSON.parse(value).id
    );
    setTaskSuggestions([...filteredValue]);


    if (
      pageType.includes(source) &&
      JSON.parse(value).authorId !== CkEditorCurrentUser
    ) {
      setReviewedTaskSuggestions([
        ...reviewedTaskSuggestions,
        JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
      ]);
      if(taskReorder && ckEditorCurrentReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
        && JSON.parse(value).suggestionType==='insertion'){
        const initialTask = initialTaskIdBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
        const currentTask = taskWithSuggestions.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
        const isSimilar = initialTask.some((element, index) => element !== currentTask[index])
        if(!isSimilar){
          removeReorderedSuggestions();
          toast.info('Reorder suggestion has rejected automatically', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
    if (
      source === 'approve'
      && JSON.parse(value).authorId === CkEditorCurrentUser
      && reviewerTaskSuggestions?.some((item) => JSON.parse(item).id === JSON.parse(value).id)
    ) {
      setReviewedTaskSuggestions([
        ...reviewedTaskSuggestions,
        JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
      ]);
    }

    if (taskList.some((item) => item.id === JSON.parse(value).id)) {
      const filteredList = taskWithSuggestions.filter(
        (item) => JSON.parse(item).id !== JSON.parse(value).id
      );
          const exit = taskList
          .filter((item) => JSON.parse(value).id === item.id)
          .map((exit) => {
            return JSON.stringify({
              ...exit,
              suggestionType: "none",
              authorId: CkEditorCurrentUser,
              suggestionId: null,
              createdAt: new Date(),
            });
          });
        const updatedList = taskWithSuggestions.map((item) =>
          JSON.parse(item).id === JSON.parse(value).id
            ? JSON.stringify({
              ...taskList.filter((item) => JSON.parse(value).id === item.id)[0],
              suggestionType: "none",
              suggestionId: null,
              authorId: CkEditorCurrentUser,
              createdAt: new Date(),
            })
            : item
        );

       setTaskWithSuggestions([...updatedList]);
       const updatedInitialList = initialTaskIdBeforeReorder.map((item) =>
          JSON.parse(item).id === JSON.parse(value).id
            ? JSON.stringify({
              ...taskList.filter((item) => JSON.parse(value).id === item.id)[0],
              suggestionType: "none",
              suggestionId: null,
              authorId: CkEditorCurrentUser,
              createdAt: new Date(),
            })
            : item
        );
       setInitialTaskIdBeforeReorder([...updatedInitialList])

      
    } else {
      const filteredList = taskWithSuggestions.filter(
        (item) => JSON.parse(item).id !== JSON.parse(value).id
        );
      const fileredInitialList = initialTaskIdBeforeReorder.filter(
        (item)=> JSON.parse(item).id !== JSON.parse(value).id
      );  
      setTaskWithSuggestions([...filteredList]);
      setInitialTaskIdBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
      setTaskListwithInsert([...fileredInitialList.map(e=>JSON.parse(e))])
      setTaskReorderArray(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
      


    }
    
  };
 
  const handletaskDescription = (event) => {
    setTaskDescription(event.target.value);
  };
  function removePTags(html) {
		// Replace all <p> tags with an empty string
		return html.replace(/<p>/g, '').replace(/<\/p>/g, '');
	  }

    const removeReorderedSuggestions = ()=>{
      const parsedTasks = taskWithSuggestions.map((item) => {
        return JSON.parse(item)
      })
      const parsedinitialTaskIdBeforeReorder = initialTaskIdBeforeReorder.map((item)=>{
        return JSON.parse(item)
      })
      const parsedArray = parsedTasks.map((item) => {
        return JSON.stringify({
          ...item,
           sequence: parsedinitialTaskIdBeforeReorder.filter((v) => v.id === item.id)[0]?.sequence,
           authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
          createdAt: new Date(),
        })
      })
      const beforeSortingArray = parsedArray.map((item) => {
        return JSON.parse(item)
      })
      beforeSortingArray.sort((a, b) => a.sequence - b.sequence)
      const afterSortAndStringify = beforeSortingArray.map((item) => {
        return JSON.stringify(item)
      })
      setTaskWithSuggestions([...afterSortAndStringify]);
      setTaskReorder(false)
      setTaskReorderArray([])
      if(source === 'review'){
				setIsReviewerTaskReordered(false)
			}
      if(source === 'approve'){
        setIsApproverTaskReordered(false)
      }
      if (
        pageType.includes(source) && ckEditorCurrentReorderUsername !== user.profile.firstName + ' '+ user.profile.surname
        
      ) {
        setReviewedReorderedTaskSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'rejected',name:'Task' })]);
        setTaskReorderArray([])
        setReorderContributionCount((prev)=> prev - 1)

      }
      setDisableDrag(false)
  }

  useEffect(()=>{

      const   arraysAreEqual = (arr1, arr2)=> {
      const beforeReorderId = arr1.map(e=> JSON.parse(e).id)
      const afterReorderId = arr2.map(e=> JSON.parse(e).id)
      if (beforeReorderId.length !== afterReorderId.length) {
        return false;
      }
    return beforeReorderId.some((element, index) => element !== afterReorderId[index]);
    }
    const orderHasChanged = arraysAreEqual(initialTaskIdBeforeReorder, taskWithSuggestions);
     setTaskReorder(orderHasChanged)
     setIsOrderChanged(prevState => ({
      ...prevState,
      task: orderHasChanged 
    }))

  },[taskWithSuggestions])


  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(taskWithSuggestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let author;
    const taskArray=[];
    const finalArray = items.map((item, index) => { 
      const parsedItem = JSON.parse(item)
      taskArray.push({id: parsedItem.id,name:parsedItem.name,authorId: [user.profile.firstName + ' '+user.profile.surname,user.profile.photo],
        createdAt: new Date()})
      author = parsedItem.authorId
      parsedItem.sequence = index + 1;
      return JSON.stringify(parsedItem)
    })
    setCkEditorCurrentReorderUserImage(user.profile.photo)
    setCkEditorCurrentReorderUsername(user.profile.firstName + ' '+user.profile.surname)
    setTaskWithSuggestions(finalArray);
    const taskArrayString = taskArray.map(e=> JSON.stringify(e))
    setTaskReorderArray(taskArrayString)
    if(source === 'review'){
      setIsReviewerTaskReordered(true)
    }
    if(source === 'approve'){
      setIsApproverTaskReordered(true)
    }
  };


 const acceptReorderSuggestion=()=>{
  const isNotCurrentUser = taskSuggestions.some(e=>JSON.parse(e).authorId !== CkEditorCurrentUser )
  if(taskSuggestions && taskSuggestions.length !==0 && isNotCurrentUser){
      toast.error('Please accept or reject other Tasks suggestions before accepting reorder', {
        position: toast.POSITION.TOP_RIGHT,
      });
  } else {
    setReviewedReorderedTaskSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'accepted',name:'Task' })])
    setTaskReorder(false);
    setInitialTaskIdBeforeReorder(taskWithSuggestions)
    setReorderContributionCount((prev)=> prev - 1)
   
 }

  }
 const renderSuggestions = () => {
    return (
      <div className="toolsSidebar" style={{ height: toolsSidebarHeight }}>
        {
          taskReorder &&  <>
          <div className="sideBarCard userAddedItem">
          <div className="sideBarCardHeader">
                    <img
                      className="userImg"
                      src={ckEditorCurrentReorderUserImage}
                      alt=""
                    />
                    <span>{ckEditorCurrentReorderUsername}</span>
                    <Icon
                        title="Reject Suggestion"
                        ariaLabel={`Click to reject the Reorder`}
                        role="button"
                        iconName="Cancel"
                        onClick={() => removeReorderedSuggestions()}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            removeReorderedSuggestions();
                          }
                        }}
                        tabIndex={0}
                      />
                    {pageType.includes(source) && 
                    ckEditorCurrentReorderUsername !== user.profile.firstName + ' '+user.profile.surname &&
                      (
                      <Icon
                        title="Accept Suggestion"
                        iconName="Accept"
                        onClick={() => acceptReorderSuggestion()}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            acceptReorderSuggestion();
                          }
                        }}
                        ariaLabel={'click to accept as suggestion'}
                        role="button"
                        tabIndex={0}
                      />
                    
                    )}
                    
                    <div className="itemStatus">
                    <span>
                     Reordered
                    </span>
                    &nbsp;"Description: Tasks has been Reordered"
                  </div>
                  
                  </div>
                  </div>
          
          </>
        }
        {taskSuggestions &&
          taskWithSuggestions.map((card) => (
            <>
              {JSON.parse(card).suggestionType === "none" ? (
                <></>
              ) : (
                <div
                  className={`sideBarCard ${JSON.parse(card).suggestionType === "deletion"
                    ? 'userDeletedToolsAndGuidance'
                    : 'userAddedItem'
                    }`}
                >
                  <div className="sideBarCardHeader">
                    <img
                      className="userImg"
                      src={ckEditorUserMap[JSON.parse(card).authorId][1]}
                      alt=""
                    />
                    <span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>

                    {JSON.parse(card).authorId === CkEditorCurrentUser ||
                      (pageType.includes(source) &&
                        JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
                      <Icon
                        title="Reject Suggestion"
                        ariaLabel={`Click to reject ${JSON.parse(card).name
                          } as suggestion`}
                        role="button"
                        iconName="Cancel"
                        onClick={() => removeSuggestion(card)}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            removeSuggestion(card);
                          }
                        }}
                        tabIndex={0}
                      />
                    ) : (
                      <></>
                    )}
                    {pageType.includes(source) &&
                      JSON.parse(card).authorId !== CkEditorCurrentUser ? (
                        
                      <Icon
                        title="Accept Suggestion"
                        iconName="Accept"
                        onClick={() => acceptSuggestion(card)}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            acceptSuggestion(card);
                          }
                        }}
                        ariaLabel={`Click to Accept ${JSON.parse(card).name
                          } as suggestion`}
                        role="button"
                        tabIndex={0}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="itemStatus">
                    <span>
                      {JSON.parse(card).suggestionType === "insertion"
                        ? "Add"
                        : JSON.parse(card).suggestionType === "deletion"
                          ? "Remove"
                          : JSON.parse(card).suggestionType === "Reorder" ? "Reordered" : "Edit"}
                    </span>
                    &nbsp;
                     Description:  
                   <span dangerouslySetInnerHTML={{ __html: htmlSanitizer(removePTags(JSON.parse(card).name)) }} />
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="topicDetailsCkEditor entryExitCriTab">
        <div className="criteria-tab-container task-tab-container">
          {error && (
            <span className="errorMsg">Type and Description are required</span>
          )}
          {/* <ul className="exit-criteria-tabs">
            <li className="">
              Task List
            </li>
          </ul> */}

          <div id="tab-tasks" className="">
            <div
              className={
                taskButtonClicked
                  ? "add-details"
                  : "add-details hiddenExitCriteria"
              }
            >
              <TasksCKEditorLink  value={taskDescription} setValue={setTaskDescription}/>
              <div style={{paddingLeft: '348px', marginTop:'5px'}}>
              <button
                style={{marginTop:'5px'}}
                className="add-button"
                onClick={() => {
                  updateTask();
                  setTaskButtonClicked(false);

                }}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    updateTask();
                  }
                }}
              >
                Add{" "}
                <Icon className="add-icon" iconName="BoxAdditionSolid"></Icon>
              </button>
              <button
                className="add-button"
                onClick={() => {
                  setTaskButtonClicked(false);
                  setTaskDescription("");
                  setTaskName("");
                  setError(false);
                  setUpdateId(0);
                  setEditMode(false);
                }}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    setTaskButtonClicked(false);
                    setTaskDescription("");
                    setTaskName("");
                    setError(false);
                    setUpdateId(0);
                    setEditMode(false);
                  }
                }}
              >
                Cancel
              </button>
            </div>
            </div>

            <div className="task-main-container">
              <DragDropContext onDragEnd={onDragEnd} >
                <div className="table-container">
                  <div className="table-row heading">
                    <div className="row-item w90">Description</div>
                    <div className="row-item w10">Edit/Delete</div>
                  </div>
                  <Droppable droppableId="TaskSuggestionList">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {taskWithSuggestions.map((value, index) => (
                          <>
                            <Draggable draggableId={value} key={value} index={index} isDragDisabled={disableDrag}>
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div
                                    className={`table-row  ${JSON.parse(value).suggestionType === "none" 
                                      ? ``
                                      : JSON.parse(value).suggestionType === "insertion" ||
                                        JSON.parse(value).suggestionType === "updation"
                                        ? `addedCriteria`
                                        : `deletedCriteria`
                                      }`}
                                  >
                                    <div className="row-item w90">
                                      <div
                                        className="descEn"
                                        id="task"
                                        dangerouslySetInnerHTML={{
                                          __html: htmlSanitizer(JSON.parse(value).name),
                                        }}
                                      />
                                    </div>

                                    <div className="row-item edit-delete-column w10">
                                      <Icon
                                        aria-label="Edit"
                                        tabIndex={0}
                                        iconName="EditSolid12"
                                        className="edit-icon"
                                        onClick={() => {
                                          setTaskName(htmlSanitizer(JSON.parse(value).name));
                                          setTaskDescription(
                                            htmlSanitizer(JSON.parse(value).name)
                                          );
                                          setEditMode(true);
                                          setUpdateId(JSON.parse(value).id);
                                          setError(false);
                                          setTaskButtonClicked(true);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter' || e.key === ' ') {
                                            setTaskName(htmlSanitizer(JSON.parse(value).name));
                                            setTaskDescription(htmlSanitizer(JSON.parse(value).name));
                                            setEditMode(true);
                                            setUpdateId(JSON.parse(value).id);
                                            setError(false);
                                            setTaskButtonClicked(true);
                                          }
                                        }}
                                      />
                                      {JSON.parse(value).suggestionType !== "insertion" ? (
                                        <Icon
                                          aria-label="Delete"
                                          title="DeleteExit"
                                          iconName="Cancel"
                                          className="cancel-icon"
                                          onClick={() => {
                                            deleteOtherDetails(JSON.parse(value));
                                            setEditMode(false);
                                            setTaskName("");
                                            setTaskDescription("");
                                            setError(false);
                                            setUpdateId(0);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                              deleteOtherDetails(JSON.parse(value));
                                              setEditMode(false);
                                              setTaskName("");
                                              setTaskDescription("");
                                              setError(false);
                                              setUpdateId(0);
                                            }
                                          }}
                                          tabIndex={0}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>

                          </>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
        {renderSuggestions()}
      </div>
    </>
  );
};

export default TaskContribution;
