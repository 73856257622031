import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import $ from 'jquery';

function FeedbackModal({
	enableFeedbackModal,
	setEnableFeedbackModal,
	data,
	closeModal,
}) {
	const { track } = useAppInsights();
	const { axiosPostService } = useAxios();
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
	const [feedbackData, setFeedbackData] = useState(null);
	const [feedbackMessage, setFeedbackMessage] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleFeedbackClick = (selectedFeedback) => {
		if (feedbackData !== selectedFeedback) {
			setFeedbackData(selectedFeedback);
		} else {
			setFeedbackData(null);
		}
	};

	const handleSubmit = async () => {
		if (feedbackData !== null) {
			setLoading(true);
			const postBody = {
				feedbackType: feedbackData === 'Yes' ? 1 : 2,
				feedbackComments: feedbackMessage,
				url: `/template/${data.id}?complexityId=${data.complexityId}`,
			};
			await axiosPostService(`api/sdmtemplate/TemplateFeedback`, postBody).then(
				(response) => {
					setLoading(false);
					setFeedbackSubmitted(true);

					track(1, 152, data.id, data.title, {
						feedbackType: postBody.feedbackType,
						feedbackComments: postBody.feedbackComments,
					});
				}
			);
		} else {
			setError(true);
		}
	};

	useEffect(() => {
		return () => setFeedbackSubmitted(false);
	}, []);

	  useEffect(() => {
		$('#closeButton').focus();
	  }, [enableFeedbackModal]);

	  return (
		<Modal
			show={enableFeedbackModal}
			onHide={loading ? null : closeModal}
			centered
			size="sm"
			className="templateFeedbackPopUp"
		>
			{loading ? (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			) : feedbackSubmitted ? (
				<>
					<Modal.Body>
						<p>Your feedback was submitted successfully</p>
						<button
							title="OK"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							className="feedbackSubmittedOk"
						>
							OK
						</button>
					</Modal.Body>
				</>
			) : (
				<>
					<Modal.Header>
						<span id='feedbackmodel' tabIndex={0} role='dialog'>Your Opinion Matters</span>
						<Icon
							id='closeButton'
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={closeModal}
							aria-label="Close Feedback modal"
							role="button"
							title="close"
						/>
					</Modal.Header>
					<Modal.Body>
						<p>Did "{data.title}" meet the needs of your project?</p>
						<div className="templateFeedbackPopUpDiv">
							<div
								className="feedbackYesDiv"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleFeedbackClick('Yes');
									}
								}}
								onClick={() => handleFeedbackClick('Yes')}
							>
								{feedbackData === null || feedbackData !== 'Yes' ? (
									<span className="feedbackThumbUp"></span>
								) : (
									<span className="feedbackThumbUpOrange"></span>
								)}
								<span>Yes</span>
							</div>
							<div
								className="feedbackNoDiv"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleFeedbackClick('No');
									}
								}}
								onClick={() => handleFeedbackClick('No')}
							>
								{feedbackData === null || feedbackData !== 'No' ? (
									<span className="feedbackThumbDown"></span>
								) : (
									<span className="feedbackThumbDownOrange"></span>
								)}
								<span>No</span>
							</div>
						</div>
						<textarea
							title="feedback description"
							placeholder="Tell us why? (optional)"
							value={feedbackMessage}
							onChange={(e) => setFeedbackMessage(e.target.value)}
							maxLength={2000}
						/>
						{error ? (
							<span className="errorMsg">Please select your opinion</span>
						) : (
							<></>
						)}
						<div>
							<button
								title="Cancel"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={closeModal}
								className="feedbackPopUpCancel"
							>
								Cancel
							</button>
							<button
								title="Submit"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleSubmit();
									}
								}}
								onClick={handleSubmit}
								className="feedbackPopUpSubmit"
							>
								Submit
							</button>
						</div>
					</Modal.Body>
				</>
			)}
		</Modal>
	);
}

export default FeedbackModal;
