export const activityProperty = [
	{
		id: 1,
		name: 'All',
	},
	{
		id: 2,
		name: 'Activity Description',
	},
	{
		id: 3,
		name: 'Entry Criteria',
	},
	{
		id: 4,
		name: 'Templates',
	},
	{
		id: 5,
		name: 'Inputs for SMMI',
	},
	{
		id: 6,
		name: 'RACI',
	},
	{
		id: 7,
		name: 'Tasks',
	},
	{
		id: 8,
		name: 'Exit Criteria',
	},
	{
		id: 9,
		name: 'Guidance, Tips and Techniques',
	},

	{
		id: 10,
		name: 'Tools',
	},
	{
		id: 11,
		name: 'Deliverables',
	},
	{
		id: 12,
		name: 'Topics',
	},
	{
		id: 13,
		name: 'Hashtags',
	},
];
