import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';

import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';
import Dropdown from '../Dropdown';
import { templateFileTypes } from '../../static/TemplateIcons';
import { Icon } from '@fluentui/react/lib/Icon';
import { getGroupInfo } from '../../helpers/GraphService';
import { useAxios } from '../../context/AxiosContext';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { artifactMapData } from '../../static/ArtifactMapData';
import HashtagSuggestions from '../HashtagSuggestions';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	padding: '18px 20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

function TemplateContributionModal({
	closeModal,
	data,
	enableLocalizedTemplate,
	complexityId,
}) {
	const [messagesType, setMessagesType] = useState({});
	const { getNotifications, isAdmin } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService, userAlias, axiosPostServiceSwimlane } = useAxios();
	const [fileSize, setFileSize] = useState(0);

	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
	});
	const defaultLanguage = { name: 'Select Language', id: 0 };
	const defaultCountry = { name: 'Select Country', id: 0 };
	const [file, setFile] = useState(null);
	const [comments, setComments] = useState('');
	const [enableDiscardPopUp, setEnableDiscardPopup] = useState(false);
	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
	const [inputError, setInputError] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState(null);
	const [fileTypeError, setFileTypeError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [updateApiCall, setUpdateApiCall] = useState(false);

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const handleSubmit = async () => {
		const regexp = /^[a-zA-Z0-9\s\.()\d\w,:;""''_\-\*]+$/;
		setFileTypeError(false);
		if (
			file === null ||
			comments.length === 0 ||
			selectedCountry.id === 0 ||
			selectedLanguage.id === 0
		) {
			setInputError(true);
		} else if (!regexp.test(comments)) {
			setFileUploadStatus('inputerror');
			setErrorMessage('Input string is not in correct Format');
		} else {
			setInputError(false);
			let reviewers = [];
			const formData = new FormData();
			formData.append('fileLocation', file);
			setFileUploadStatus('uploading');

			const accessToken = await axiosTokenService();
			const groups = await axiosGetService(
				`api/sdmapprovalmatrix/getbyactiontypeanditemtypeanditem?actionTypeId=1&itemTypeId=1&itemId=${data.id}`
			);
			for (let i = 0; i < groups.data.length; i++) {
				const groupData = groups.data[i];
				const groupInfo = await getGroupInfo(
					accessToken,
					groupData.secGroupId,
					userAlias
				);
				const groupMembers = groupInfo.sdmGroupMemebers.filter(
					(user) => !reviewers.includes(user)
				);
				reviewers = [...reviewers, ...groupMembers];
			}

			const templateUrlResponse = await axiosPostServiceSwimlane(
				`api/sdmtemplate/${
					selectedLanguage.id == 15 ? 'uploadtemplate' : 'uploadLocTemplate'
				}`,
				formData
			);

			if (templateUrlResponse.data != null) {
				if (templateUrlResponse.data.status == 'false') {
					setFileUploadStatus('inputerror');
					setErrorMessage(templateUrlResponse.data.errorMessage);
				} else {
					const postBody = {
						templateId: data.id,
						complexityId: data.complexityId ? data.complexityId : complexityId,
						stateId: 1,
						filePath: templateUrlResponse.data.templateURL,
						actionTypeId: 1,
						contributorComments: comments,
						groupMembers: reviewers,
						isLocalized: selectedLanguage.id === 15 ? false : true,
						languageId: selectedLanguage.id,
						countryId: selectedCountry.id,
						languageName: selectedLanguage.languageName,
						countryName: selectedCountry.countryName,
						hashtags: userAddedHashtags.toString(),
						title: templateUrlResponse.data.fileName,
					};
					await axiosPostService(
						'api/sdmtemplate/InsertContributorAsync',
						postBody
					)
						.then((response) => {
							if (response.data.status == 'false') {
								setFileUploadStatus('inputerror');
								setErrorMessage(response.data.errorMessage);
							} else {
								setFileUploaded(true);
								setFileUploadStatus(null);
								setUserAddedHashtags([]);
								setExistingHashtags([]);
								getNotifications();
							}
						})
						.catch((error) => {
							setFileUploadStatus('error');
						});
				}
			} else {
				setFileUploadStatus('error');
			}
		}
	};

	const getLanguages = async () => {
		await axiosGetService(`api/sdmtemplate/languages/${selectedCountry.id}`)
			.then((response) => {
				const data = response.data.map((item) => ({
					name: item.name,
					id: item.id,
					option: 'type',
				}));
				setLanguages(data);
				setSelectedLanguage({
					id: data[0].id,
					name: data[0].name,
					option: 'type',
				});
			})
			.catch((error) => console.log(error));
	};

	const getCountries = async () => {
		await axiosGetService('api/sdmtemplate/countries')
			.then((response) => {
				const data = response.data.map((item) => ({
					name: item.name,
					id: item.id,
					option: 'type',
				}));
				setCountries(data);
			})
			.catch((error) => console.log(error));
	};

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=contributionSubmissionMessage&title=${encodeURIComponent(
				data.title
			)}`
		).then((response) => {
			message['contributionSubmissionMessage'] = response.data;
		});
		setMessagesType(message);
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	const filterFileType = (file) => {
		const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
		if (templateFileTypes.includes(selectedFileType)) {
			setFile(file);
			setFileTypeError(false);
			setInputError(false);
			setSizeError(false);
			setErrorMessage(null);
		} else {
			setFile(null);
			setFileTypeError(true);
			setInputError(false);
			setSizeError(false);
			setErrorMessage(null);
		}
	};

	useEffect(() => {
		if (data) {
			getMessage();
		}
	}, [data]);

	useEffect(() => {
		getCountries();
	}, []);

	useEffect(() => {
		if (selectedCountry.id !== 0) {
			getLanguages();
		}
	}, [selectedCountry]);

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setFileTypeError(false);
			setInputError(false);
			setSizeError(true);
			setErrorMessage(null);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		getFileSize();
	}, []);

	if (fileUploadStatus === 'uploading') {
		return <CrowdSourcingSpinner />;
	}

	return (
		<Modal
			dialogClassName={
				fileUploaded || enableDiscardPopUp
					? 'templateFeedbackPopUp'
					: 'templateModal'
			}
			show={enableLocalizedTemplate}
			onHide={() => {
				if (fileUploadStatus !== 'uploading') {
					closeModal();
				}
			}}
			centered
			size="sm"
		>
			{enableLocalizedTemplate ? (
				fileUploaded ? (
					<>
						<Modal.Body>
							<p>
								{isAdmin
									? 'Localized Template uploaded successfully'
									: messagesType['contributionSubmissionMessage']}
							</p>
							<button
								title="OK"
								className="feedbackSubmittedOk"
								tabIndex={0}
								onClick={closeModal}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							>
								OK
							</button>
						</Modal.Body>
					</>
				) : enableDiscardPopUp ? (
					<Modal.Body className="templateDiscardPopup">
						<p>Your suggested changes will be lost</p>
						<p>Are you sure you want to discard this?</p>
						<div className="discardPopUpButtons">
							<button
								title="No"
								className="templateDiscardButton"
								tabIndex={0}
								onClick={() => setEnableDiscardPopup(false)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableDiscardPopup(false);
									}
								}}
							>
								No
							</button>
							<button
								title="Yes"
								className="templateSubmitButton"
								tabIndex={0}
								onClick={closeModal}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							>
								Yes
							</button>
						</div>
					</Modal.Body>
				) : (
					<>
						<Modal.Header className="sampleSubmitTemplatePopUp">
							<div className="templateContributionSelection">
								<span className="settingsLabel">
									Contribute Localized Template
								</span>
							</div>
							<Icon
								iconName="Cancel"
								onClick={closeModal}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							/>
						</Modal.Header>
						<Modal.Body className="TemplateContributionPopUp">
							<div
								className={
									fileUploadStatus === 'uploading'
										? 'sampleSubmitTemplatePopUp noClickEvents'
										: 'sampleSubmitTemplatePopUp'
								}
							>
								<div className="detailsOfSampleTemplate">
									<p>
										<span className="bold">Template:&nbsp;</span>
										<span>{data.title}</span>
									</p>
									<p>
										<span className="bold">Phase Name:&nbsp; </span>
										<span>{data.phaseName}</span>
									</p>

									<p>
										<div className="localizedDropdown">
											<span className="labelDropdown bold">Country</span>
											<Dropdown
												className="countryFilter"
												data={countries}
												setValue={setSelectedCountry}
												value={selectedCountry}
												enableDropdown={enableLocalizedTemplate}
											/>
										</div>
										<div className="localizedDropdown">
											<span className="labelDropdown bold">Language</span>
											<Dropdown
												className="languageFilter"
												data={languages}
												setValue={setSelectedLanguage}
												value={selectedLanguage}
												enableDropdown={selectedCountry.id}
											/>
										</div>
									</p>
								</div>
								{selectedLanguage.id === 15 ? (
									<div className="detailsOfSampleTemplate">
										<p>
											<span>
												**Note - The Localized Template that you have uploaded
												is in English. It will be published as a main template
												and not a Localized one.
											</span>
										</p>
									</div>
								) : (
									<></>
								)}

								<div className="container">
									<div {...getRootProps({ style })}>
										<h6>Choose File*</h6>
										<input {...getInputProps()} />
										{file === null || file === 'uploaded' ? (
											<div>
												<span>Drag and drop a file here or</span>
												<button
													title="Choose File"
													className="dragDropButton"
													type="button"
													onClick={open}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															open();
														}
													}}
												>
													Choose File
												</button>
											</div>
										) : (
											<div className="templateContributionFileNameContainer">
												<div
													key={file.path}
													className="templateContributionFileName"
												>
													{file.path}
													<button
														onClick={() => setFile(null)}
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setFile(null);
															}
														}}
														title="Remove Uploaded File"
														role="Button"
														aria-label="Remove"
													>
														X
													</button>
												</div>
												<button
													title="Choose File"
													className="dragDropButton"
													type="button"
													onClick={open}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															open();
														}
													}}
												>
													Change
												</button>
											</div>
										)}
									</div>
									<div className="detOfContributionTemplate">
										<h6>Add comments about changes made*</h6>
										<textarea
											placeholder="Type here"
											onChange={(e) => setComments(e.target.value)}
											value={comments}
											maxLength={280}
											minLength={1}
											title="comments"
										/>
										<span className="contributionCommentInfo">
											{comments.length}/280 Characters left
										</span>
									</div>
									<div className="detOfContributionTemplateforHashtags">
										<h6>Add Hashtags</h6>

										<HashtagSuggestions
											className="templateHashtagsInput"
											enableDropdown={true}
											data={hashtagSuggestions}
											setUpdateApiCall={setUpdateApiCall}
											setHashtagSuggestions={setHashtagSuggestions}
											searchValue={hashtagsInput}
											setSearchValue={setHashtagsInput}
											existingHashtags={existingHashtags}
											userAddedHashtags={userAddedHashtags}
											setUserAddedHashtags={setUserAddedHashtags}
											hashtagSuggestionInput={hashtagSuggestionInput}
											setHashtagSuggestionInput={setHashtagSuggestionInput}
											closeHashtagForm={() => {
												setHashtagsInput('');
												setUserAddedHashtags([]);
											}}
											submitHashtags={() => {
												//do nothing
											}}
											autoFocus={false}
											showSubmitButton={false}
										/>
									</div>
								</div>
								<div className="templateContributionErrorMsgContainer">
									{fileTypeError ? (
										<span className="errorMsg">
											Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf,
											doc, docx, dotx, xlsm, msg, mpp, mpt" are
											allowed.
										</span>
									) : (
										<></>
									)}
									{inputError ? (
										<span className="errorMsg">
											Choose a template, add comments , select country and
											language to submit
										</span>
									) : (
										<></>
									)}
									{fileUploadStatus === 'error' ? (
										<>
											<br />
											<span className="errorMsg">
												Error occurred when uploading the file
											</span>
										</>
									) : (
										<></>
									)}
									{fileUploadStatus === 'inputerror' ? (
										<>
											<br />
											<span className="errorMsg">{errorMessage}</span>
										</>
									) : (
										<></>
									)}
									{sizeError ? (
										<span className="errorMsg">
											Choose a file size less than {fileSize / 1048576} MB.
										</span>
									) : (
										<></>
									)}
								</div>
								<div className="templateSubmitDiscard">
									<button
										title="Discard"
										className="templateDiscardButton"
										onClick={() => setEnableDiscardPopup(true)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setEnableDiscardPopup(true);
											}
										}}
									>
										Discard
									</button>
									<button
										title="Submit"
										className="templateSubmitButton"
										onClick={handleSubmit}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleSubmit();
											}
										}}
									>
										Submit
									</button>
								</div>
							</div>
						</Modal.Body>
					</>
				)
			) : (
				<></>
			)}
		</Modal>
	);
}
export default TemplateContributionModal;
