import React, { useEffect } from 'react';
import { activityTitleValidator } from '../../helpers/Validation';

const ActivityTitle = ({
    showFields,
    handelFormView,
    setActivityTitle,
    activityGeneralDetails,
    setActivityGeneralDetails,
    activityGeneralUpdatedDetails,
    setActivityGeneralUpdatedDetails,
}) => {
    const titleLength = 120;

    useEffect(() => {
        // Automatically save the title whenever it is updated
        if (
            activityGeneralUpdatedDetails['activityTitle'].trim().length > 0 &&
            activityGeneralUpdatedDetails['activityTitle'].length <= titleLength &&
            activityTitleValidator.test(activityGeneralUpdatedDetails['activityTitle'])
        ) {
            setActivityGeneralDetails((prevState) => ({
                ...prevState,
                activityTitle: activityGeneralUpdatedDetails['activityTitle'],
            }));
        }
    }, [activityGeneralUpdatedDetails['activityTitle'], setActivityGeneralDetails]);

    return (
        <div className="Detailswrapper acitivityeditStyle p-0">
            <div className="DetailswrapperTitle">
         
                    <>
                        <input
                            className="DetailswrapperNeweditActivityTitle"
                            value={activityGeneralUpdatedDetails['activityTitle']}
                            onChange={(e) => {
                                setActivityTitle(e.target.value);
                            }}
                            placeholder="Enter activity title"
                            autoFocus={true}
                        />
                        {activityGeneralUpdatedDetails['activityTitle'].trim().length ===
                        0 ? (
                            <span className='errorMsg'>Title is required</span>
                        ) : null}
                        {activityGeneralUpdatedDetails['activityTitle'].length >
                        titleLength ? (
                            <span className='errorMsg'>Name length should not exceed 120 characters</span>
                        ) : null}
                        {activityGeneralUpdatedDetails['activityTitle'] &&
                        !activityTitleValidator.test(
                            activityGeneralUpdatedDetails['activityTitle']
                        ) ? (
                            <span className="errorMsg">Invalid name</span>
                        ) : null}
                    </>
               
            </div>
        </div>
    );
};

export default ActivityTitle;
