import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAppInsights } from '../../context/TelemetryContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { useAxios } from '../../context/AxiosContext';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import Collaborators from './Collaborators';
import TopicsSequence from './TopicsSequence';
import { artifactMapData } from '../../static/ArtifactMapData';
import TopicLockModalMessage from './LockModal';

const TopicsOwner = ({
	categoryId,
	setCategoryId,
	showCollaboratorsPanel,
	setShowCollaboratorsPanel,
	userAction,
	setUserAction,
	topicsSequenceData,
	categoryName,
}) => {
	const tabs = [
		{ id: 1, name: 'Collaborators' },
		{ id: 2, name: 'Sequence Topics' },
	];
	const { track } = useAppInsights();
	const { userAlias, axiosGetService, axiosPostService, axiosDeleteService } =
		useAxios();
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [showSpinner, setshowSpinner] = useState(true);
	const [apiCalled, setApiCalled] = useState(false);
	const [previousPostData, setPreviousPostData] = useState({});
	const [selectedTab, setSelectedTab] = useState(1);
	const [topics, setTopics] = useState([]);
	const [pageLockUser, setPageLockUser] = useState(null);
	const [lockEmailId, setLockEmailId] = useState(null);
	const [isPageLock, setIsPageLock] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [IsCoOwner, setIsCoOwner] = useState(false);
	const [IsOwner, setIsOwner] = useState(false);
	const [categoryOwner,setCategoryOwner] = useState({})

	const getUser = async (userEmail, setValue) => {
		setshowSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setshowSpinner(false);
		}, 1000);
	};
	const getCategoryOwnerDetails = async (email) => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, email);
		setCategoryOwner(userData);
	  };
	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	};
	const getCollaborators = async () => {
		await axiosGetService(`api/sdmtopics/getCollaborators/${categoryId}`).then(
			(response) => {
				getCollaboratorsInfo(
					response.data.coOwnerMembersList,
					setExistingCoOwners
				);
				getCollaboratorsInfo(
					response.data.reviewerMembersList,
					setExistingReviewers
				);
				getCollaboratorsInfo(
					response.data.approverMembersList,
					setExistingApprovers
				);
				getCategoryOwnerDetails(response.data.owner);
				setIsAdmin(response.data.isAdmin);
				setIsCoOwner(response.data.isCoOwner);
				setIsOwner(response.data.isOwner);
				setPreviousPostData({
					coOwnerMembersList: response.data.coOwnerMembersList.sort(),
					approverMembersList: response.data.approverMembersList.sort(),
					reviewerMembersList: response.data.reviewerMembersList.sort(),
				});
				if (
					response.data.coOwnerMembersList.length === 0 &&
					response.data.reviewerMembersList.length === 0 &&
					response.data.approverMembersList.length === 0
				) {
					setshowSpinner(false);
				}
			}
		);
	};

	const getTopicsForSequence = () => {
		const data = topicsSequenceData.map((item) => {
			return {
				id: item.id.toString(),
				name: item.name,
				isDrafted: item.isDrafted,
			};
		});
		setTopics(data);
	};

	const resetStates = () => {
		setUserAction(!userAction);
		setCategoryId(0);
		setShowCollaboratorsPanel(false);
	};

	const saveDetails = async () => {
		const coOwnersEmails = coOwners.map(({ mail }) => {
			return mail;
		});
		const reviewersEmails = reviewers.map(({ mail }) => {
			return mail;
		});
		const approversEmails = approvers.map(({ mail }) => {
			return mail;
		});

		const postData = {
			coOwnerMembersList: coOwnersEmails.sort(),
			approverMembersList: approversEmails.sort(),
			reviewerMembersList: reviewersEmails.sort(),
		};
		if (JSON.stringify(previousPostData) !== JSON.stringify(postData)) {
			postData.id = categoryId;
			setApiCalled(true);
			await axiosPostService(`api/sdmtopics/savecollaborations`, postData).then(
				(response) => {
					if (response.data.status) {
						toast.info('You have successfully updated the collaborators', {
							position: 'top-right',
						});
						track(1, 1803, categoryId, 'Topic collaborators update', {
							posData: postData,
						});
						setPreviousPostData({
							coOwnerMembersList: postData.coOwnerMembersList,
							approverMembersList: postData.approverMembersList,
							reviewerMembersList: postData.reviewerMembersList,
						});
					} else {
						toast.error(response.data.errorMessage, {
							position: 'top-right',
						});
					}
					setApiCalled(false);
				}
			);
		}
		setSelectedTab(selectedTab + 1);
	};

	const updateTopicsSequence = async () => {
		const updatedSequencesIds = topics.map(({ id }) => {
			return Number(id);
		});
		const previousSequenceIds = topicsSequenceData.map(({ id }) => {
			return Number(id);
		});
		if (
			JSON.stringify(updatedSequencesIds) !==
			JSON.stringify(previousSequenceIds)
		) {
			const postData = {
				sequenceTopicIds: updatedSequencesIds.toString(),
				categoryId: categoryId,
			};
			setApiCalled(true);
			await axiosPostService(
				`api/sdmtopics/updatetopicsequence`,
				postData
			).then((response) => {
				if (response.data.status) {
					toast.info('You have successfully updated the sequence of topics', {
						position: 'top-right',
					});
					track(1, 1802, categoryId, 'Topic sequence update', {
						posData: postData,
					});
					resetStates();
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setApiCalled(false);
			});
		} else {
			setShowCollaboratorsPanel(false);
		}
	};

	const exitLockMode = async () => {
		if (pageLockUser === userAlias) {
			await axiosDeleteService(
				`api/sdmartifactmaster/deleteLockedArtifact?artifactId=${categoryId}&artifactTypeConstId=${
					artifactMapData['topics']
				}&artifactDraftId=${0}`
			);
		}
	};

	const enterLockMode = async () => {
		const postData = {
			artifactId: categoryId,
			artifactTypeConstId: artifactMapData['topics'],
			artifactDraftId: 0,
		};
		await axiosPostService(`api/sdmartifactmaster/lockArtifact`, postData).then(
			() => {
				setPageLockUser(userAlias);
			}
		);
	};

	const checkLockMode = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getLockedArtifacts?artifactId=${categoryId}&artifactTypeConstId=${
				artifactMapData['topics']
			}&artifactDraftId=${0}`
		).then((response) => {
			setLockEmailId(response.data.email);
			if (response.data.status && userAlias !== response.data.email) {
				setIsPageLock(true);
			} else if (!response.data.status) {
				enterLockMode();
			} else {
				enterLockMode();
				setPageLockUser(response.data.email);
			}
		});
	};

	const menu = {
		1: (
			<Collaborators
			categoryOwner={categoryOwner}
				coOwners={coOwners}
				setCoOwners={setCoOwners}
				reviewers={reviewers}
				setReviewers={setReviewers}
				approvers={approvers}
				setApprovers={setApprovers}
				showSpinner={showSpinner}
				editingMode={isAdmin || IsOwner || IsCoOwner}
				astrikFlag="editCategory"
			/>
		),
		2: <TopicsSequence topics={topics} setTopics={setTopics} />,
	};

	const updatesApisIndex = {
		1: saveDetails,
		2: updateTopicsSequence,
	};

	useEffect(() => {
		getCollaborators();
	}, []);

	useEffect(() => {
		getTopicsForSequence();
	}, []);

	useEffect(() => {
		setCoOwners(existingCoOwners);
	}, [existingCoOwners]);

	useEffect(() => {
		setReviewers(existingReviewers);
	}, [existingReviewers]);

	useEffect(() => {
		setApprovers(existingApprovers);
	}, [existingApprovers]);

	useEffect(() => {
		checkLockMode();
		return () => {
			exitLockMode();
		};
	}, []);

	useEffect(() => {
		let timeOutId = 0;
		if (pageLockUser === userAlias) {
			timeOutId = setInterval(() => {
				enterLockMode();
			}, 300000);
		}
		return () => {
			clearInterval(timeOutId);
		};
	}, [pageLockUser]);

	if (isPageLock) {
		return <TopicLockModalMessage lockEmailId={lockEmailId} url="/topics" />;
	}
console.log(userAlias,coOwners,"line12345")
	return (
		<Modal
			className="phaseCreationRightPopup"
			show={true}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<Modal.Title>{categoryName}</Modal.Title>
				<Icon
					iconName="ChromeClose"
					title="Close"
					onClick={() => setShowCollaboratorsPanel(false)}
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className="methodLeftContainer">
								<ul className="methodPhaseLeftMenu" role="tablist">
									{tabs.map((tab) => (
										<li
											onClick={() => setSelectedTab(tab.id)}
											key={tab.id}
											className={
												selectedTab === tab.id ? 'activePhaseTab' : null
											}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedTab(tab.id);
												}
											}}
											tabIndex="0"
											aria-label={tab.name}
											role="tab"
										>
											<span>{tab.name}</span>
										</li>
									))}
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div className="methodOwnerMiddleContainer">
									{menu[selectedTab]}
								</div>
							</div>
							<div className="saveContinueDetailsContainer">
								<button
									disabled={selectedTab === 1}
									onClick={() => setSelectedTab(selectedTab - 1)}
									className="backwardButton"
									aria-label="Back"
									onKeyPress={(e) => {
										if (e.which === 13) {
											setSelectedTab(selectedTab - 1);
										}
									}}
								>
									<span className="backDetailsButton">
										<Icon iconName="ChromeBack" />
										Back
									</span>
								</button>
								<button
									onClick={() => {
										updatesApisIndex[selectedTab]();
									}}
									disabled={apiCalled || coOwners.length === 0 || approvers.length === 0 || reviewers.length === 0
										}
									className="forwardButton"
									aria-label="save & continue"
									onKeyPress={(e) => {
										if (e.which === 13) {
											updatesApisIndex[selectedTab]();
										}
									}}
								>
									<span className="saveContinueButton">
										Save & Continue <Icon iconName="ChromeBackMirrored" />
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopicsOwner;
