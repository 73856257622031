import React, { useState, useContext, createContext, useEffect } from 'react';
import {
	InteractionRequiredAuthError,
	InteractionStatus,
} from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { getUserProfile } from '../helpers/GraphService';
import { useConfig } from './ConfigContext';

const UserAuthentication = () => {
	const { apiScopes, authority } = useConfig(); // get configuration
	// userState
	const [user, setUser] = useState({
		isAuthenticated: false,
		profile: {},
		error: '',
	});
	const { instance: msalInstance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	// handle graph requests and setState
	const handleGraphRequest = async (tokenResponse) => {
		const profile = await getUserProfile(tokenResponse);
		if (profile.mail !== null) {
			setUser({
				...user,
				isAuthenticated: true,
				profile,
			});
		}
	};

	//  get accessToken and call graphAPI
	const acquireTokenRedirectAndCallMSGraph = async () => {
		const accessTokenRequest = {
			scopes: ['user.read'],
			authority: authority,
		};
		if (!accounts.length) {
			return msalInstance.acquireTokenRedirect(accessTokenRequest);
		}
		accessTokenRequest.account = accounts[0];
		try {
			const accessTokenResponse = await msalInstance.acquireTokenSilent(
				accessTokenRequest
			);
			handleGraphRequest(accessTokenResponse);
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				msalInstance.acquireTokenRedirect(accessTokenRequest);
			}
		}
	};

	const getMsalAccessToken = async () => {
		const accessTokenRequest = {
			scopes: apiScopes,
			authority,
			account: accounts[0],
		};
		try {
			const { accessToken } = await msalInstance.acquireTokenSilent(
				accessTokenRequest
			);
			return accessToken;
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				msalInstance.acquireTokenRedirect(accessTokenRequest);
			}
		}
	};

	const logout = () => {
		const logoutRequest = {
			account: accounts[0],
			postLogoutRedirectUri: `https://login.microsoftonline.com/${accounts[0].tenantId}/oauth2/logout`,
		};
		msalInstance.logoutRedirect(logoutRequest);
	};

	useEffect(() => {
		if (
			(!user.isAuthenticated || !isAuthenticated) &&
			inProgress === InteractionStatus.None
		) {
			acquireTokenRedirectAndCallMSGraph();
		}
	}, [isAuthenticated, inProgress]);
	return { user, logout, getMsalAccessToken };
};

// UserAuth Context
const UserAuthContext = createContext();

// eslint-disable-next-line react/prop-types
const UserAuthProvider = ({ children }) => {
	const userAuth = UserAuthentication();
	return (
		<UserAuthContext.Provider value={userAuth}>
			{children}
		</UserAuthContext.Provider>
	);
};

const useAuth = () => useContext(UserAuthContext);

export { UserAuthProvider, useAuth };
