import React, { useState, useEffect } from 'react';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import dompurify from 'dompurify';

import './MethodologyGuidelines.css';

const MethodologyGuidelines = () => {
	const htmlSanitizer = dompurify.sanitize;
	const { axiosGetService } = useAxios();
	const { setSkipNavigationId } = useLeftMenuCollapse();
	const [data, setData] = useState('');

	const getData = async () => {
		await axiosGetService('api/sdmsettings?sdmKey=MethodologyGuidelines').then((response) => {
			const dataObject = response.data[0];
			setData(dataObject.value);
		});
	};

	useEffect(() => {
		getData();
		setSkipNavigationId('methodologyGuidelines');
	}, []);

	return (
		<div
			className="methodologyGuidelines"
			dangerouslySetInnerHTML={{ __html: htmlSanitizer(data) }}
		/>
	);
};

export default MethodologyGuidelines;
