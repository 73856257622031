export const templateTypeData = [
	{
		id: 1,
		name: 'Checklist',
	},
	{
		id: 2,
		name: 'Guide',
	},
	{
		id: 3,
		name: 'Template',
	},
	{
		id: 4,
		name: 'Tool',
	},
];

export const templateTypeDataMapper = {
	Checklist: 1,
	Guide: 2,
	Template: 3,
	Tool: 4,
};

export const templateFocusData = [
	{
		id: 1,
		name: 'External',
	},
	{
		id: 2,
		name: 'Int/Ext',
	},
	{
		id: 3,
		name: 'Internal',
	},
];

export const templateFocusDataMapper = {
	External: 1,
	'Int/Ext': 2,
	Internal: 3,
};
