import React from 'react';
import { useRaciGrid } from './RaciContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';



const ComplexityFilter = ({ complexities }) => {
	const { appState } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { 
		selectedComplexites,
		setSelectedComplexites,
		dynamicFilterTagObject,
        setDynamicFilterTagObject,
	} = useRaciGrid();
	const handleCheckbox = (item) => {
		if (selectedComplexites.includes(item.id.toString())) {
			if (selectedComplexites.length === 1) {
				item.id === 3 ? setSelectedComplexites([...selectedComplexites]) : setSelectedComplexites([appState.defaultComplexity.id.toString()]);

			}
			else {
				setSelectedComplexites(
					selectedComplexites.filter((comp) => comp != item.id)
				);
			}
		} else {
			setSelectedComplexites([...selectedComplexites, item.id.toString()]);
		}

		
	};

	const handleCheckboxJson = (item) => {
		const ComplexitiesExist = dynamicFilterTagObject.Complexities.some(element => element.Id === item.id.toString());
		if(!ComplexitiesExist){
			const itemObject = {
				Id: item.id.toString(),
				Name: item.name
			}
			setDynamicFilterTagObject({...dynamicFilterTagObject, Complexities: [...dynamicFilterTagObject.Complexities, itemObject]});
		}else{
			if(item.id !== 3){
				const updatedDynamicFilterTagObject = dynamicFilterTagObject.Complexities.filter((value) => value.Id !== item.id.toString());
				setDynamicFilterTagObject({...dynamicFilterTagObject, Complexities: [...updatedDynamicFilterTagObject]});
			}
			
		}

		Object.keys(dynamicFilterTagObject).forEach(key => {
			if (Array.isArray(dynamicFilterTagObject[key]) && dynamicFilterTagObject[key].length === 0) {
			delete dynamicFilterTagObject[key];
			}
		});
	}

	const handleKeyPress = (item, e) => {
		if (e.key === 'Enter' || e.key === ' ') {
		  e.preventDefault();
		  handleCheckbox(item);
		  handleCheckboxJson(item);
		}
	  };

	return (
		<>

			<ul title='Complexity'>
				{complexities.map((item) => (
					<li	key={item.id} >
						<label 
							className="NotificationChkboxContainer"
							onKeyPress={(e) => handleKeyPress(item, e)}
						>
							<input
								name={item.name}
								type="checkbox"
								checked={selectedComplexites.includes(item.id.toString())}
								onChange={() => {
									handleCheckbox(item);
									handleCheckboxJson(item);
									track(
										1,
										2108,
										item.id,
										item.name
									);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleCheckbox(item);
										handleCheckboxJson(item);
										track(
											1,
											2108,
											item.id,
											item.name
										);
									}
								}}
								
							/>
							<span className="CustomCheckmark" 
							aria-checked={selectedComplexites.includes(item.id)} 
							tabIndex={0}
							role="checkbox" 
							title={item.name}
							  ></span>
							<span className="filterLabel" title={item.name}>
								{item.name}
							</span>
						</label>
					</li>
				))}
			</ul>
		</>
	);
};

export default ComplexityFilter;
