import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Icon } from '@fluentui/react/lib/Icon';

import DragItem from './DragItem';
import { Grid, GridText, GridItem } from './Grid';

function move(array, oldIndex, newIndex) {
	if (newIndex >= array.length) {
		newIndex = array.length - 1;
	}
	array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
	return array;
}

function moveElement(array, index, offset) {
	const newIndex = index + offset;

	return move(array, index, newIndex);
}

const GridSequence = ({ sequence, setSequence }) => {
	const moveItem = (sourceId, destinationId) => {
		const sourceIndex = sequence.findIndex((item) => item.id === sourceId);

		const destinationIndex = sequence.findIndex(
			(item) => item.id === destinationId
		);

		// If source/destination is unknown, do nothing.
		if (sourceId === -1 || destinationId === -1) {
			return;
		}

		const offset = destinationIndex - sourceIndex;
		setSequence((prevState) => [
			...moveElement(prevState, sourceIndex, offset),
		]);
	};
	return (
		<div className="topicSequenceContainer">
			<div class="sequentialPhaseList">
				<div class="sequentialdraggblePhaseList">
					<DndProvider backend={HTML5Backend}>
						{sequence.map((item) => (
							<DragItem key={item.id} id={item.id} onMoveItem={moveItem}>
								<GridItem>
									<div className={`sequentialPhaseItem ${item.type}`}>
										<Icon iconName="GlobalNavButton" />
										<span>{item.name}</span>
									</div>
								</GridItem>
							</DragItem>
						))}
					</DndProvider>
				</div>
			</div>
		</div>
	);
};

export default GridSequence;
