import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import noResults from '../../assets/noResults.svg';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';

function RecentlyDownloadedTemplates({ changeLastFiveDownload }) {
	const { track } = useAppInsights();
	const { axiosGetService } = useAxios();
	const [lastFiveDownloads, setLastFiveDownloads] = useState([]);

	const getRecentlyDownloadedTemplates = async () => {
		await axiosGetService(`api/sdmtemplate/getDownloadedTemplates`).then((response) => {
			if (response.data.length > 5) {
				setLastFiveDownloads(response.data.slice(0, 5));
			} else {
				setLastFiveDownloads(response.data);
			}
		});
	};

	useEffect(() => {
		getRecentlyDownloadedTemplates();
	}, [changeLastFiveDownload]);

	return (
		<div className="listOfTemplateDownload">
			<div className="titleOfDownloads">
				<h6>Last 5 Downloads</h6>
			</div>
			<div className="templateDownloadList">
				{lastFiveDownloads.length === 0 ? (
					<div className="noResultsFound">
						<img src={noResults} alt="noRecentlyDownloaded" className="noRecentlyDownloaded" />
						<p>We didn't find anything</p>
					</div>
				) : (
					<>
						{lastFiveDownloads.map((template) => (
							<div className="downloadedWrapper" key={template.templateId}>
								<Link
									to={`/template/${template.templateId}?complexityId=${template.complexityId}`}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											track(1, 119, template.templateId, template.title);
										}
									}}
									onClick={() => track(1, 119, template.templateId, template.title)}
								>
									<h6>{template.title}</h6>
								</Link>
								<div className="templatePhaseDetails">Phase</div>
								<div>{template.phaseName}</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
}

export default RecentlyDownloadedTemplates;
