import { Icon } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useAppInsights } from '../../../context/TelemetryContext';
import MultiSelectDropdown from './MultiSelectDropdown';
import Spinner from '../../../helpers/Spinner';

const EditRoleGroups = ({}) => {
	const [expandedNewRoleGroups, setExpandedNewRoleGroups] = useState(false);
	const { axiosGetService, axiosPostService } = useAxios();
	const [roleGroups, setRoleGroups] = useState([]);
	const [newRoleGroup, setNewRoleGroup] = useState({});
	const [roles, setRoles] = useState([]);
	const [selectedRoleGroup, setSelectedRoleGroup] = useState({});
	const [showSpinner, setShowSpinner] = useState(true);

	const { track } = useAppInsights();
	const getRoleGroups = async () => {
		setShowSpinner(true);
		const response = await axiosGetService(`api/sdmrole/getRoleGroups`);
		setRoleGroups(
			response.data.map((roleGroup) => {
				return {
					...roleGroup,
					linkedRoles:
						roleGroup.linkedRoleIds?.split(',').map((id) => {
							return { id };
						}) ?? [],
					isDeleteAvailable:
						!roleGroup.linkedRoleIds || roleGroup.linkedRoleIds.length === 0,
				};
			})
		);
		setShowSpinner(false);
	};
	const getRoles = async () => {
		const response = await axiosGetService(`api/sdmphase/getallroles`);
		setRoles(
			response.data.map(({ id, name }) => {
				return { id: id.toString(), name: name };
			})
		);
	};
	const getRoleGroupMessage = (roleGroup) => {
		if (roleGroup.roleGroupName.length === 0)
			return 'Role name cannot be empty';
		if (
			roleGroups.some(
				({ roleGroupName, roleGroupId }) =>
					roleGroupName.toLowerCase() ===
						roleGroup.roleGroupName.toLowerCase() &&
					roleGroupId != roleGroup.roleGroupId
			)
		)
			return 'Role Group Name already exists';
		return '';
	};
	const createRoleGroup = async () => {
		if (getRoleGroupMessage(newRoleGroup)) {
			toast.error(getRoleGroupMessage(newRoleGroup), { position: 'top-right' });
			return;
		}
		const postBody = {
			...newRoleGroup,
			linkedRoleIds: newRoleGroup.linkedRoles.map(({ id }) => id).toString(),
		};
		const response = await axiosPostService(
			'api/sdmrole/updaterolegroup',
			postBody
		);
		if (response.data.status) {
			setNewRoleGroup({
				roleGroupId: 0,
				roleGroupName: '',
				linkedRoles: [],
			});
			getRoleGroups();
			toast.info('Added Role Group', { position: 'top-right' });
		} else {
			toast.error(response.data.errorMessage, { position: 'top-right' });
		}
	};
	const updateRoleGroup = async (selectedRoleGroup) => {
		if (getRoleGroupMessage(selectedRoleGroup)) {
			toast.error(getRoleGroupMessage(selectedRoleGroup), {
				position: 'top-right',
			});
			return;
		}
		const postBody = {
			...selectedRoleGroup,
			linkedRoleIds: selectedRoleGroup.linkedRoles
				.map(({ id }) => id)
				.toString(),
		};
		const response = await axiosPostService(
			'api/sdmrole/updaterolegroup',
			postBody
		);
		if (response.data.status) {
			getRoleGroups();
			toast.info('Updated Role Group', { position: 'top-right' });
		} else {
			toast.error(response.data.errorMessage, { position: 'top-right' });
		}
	};

	const deleteRoleGroup = async (selectedRoleGroup) => {
		const postBody = {
			roleGroupId: selectedRoleGroup.roleGroupId,
		};
		const response = await axiosPostService(
			'api/sdmrole/deleterolegroup',
			postBody
		);
		setSelectedRoleGroup({});
		if (response.data.status) {
			getRoleGroups();
			toast.info('Deleted Role Group', { position: 'top-right' });
		} else {
			toast.error(response.data.errorMessage, { position: 'top-right' });
		}
	};
	const updateRoleGroupsProperty = (roleGroupIndex, propetyName, newValue) => {
		const newRoleGroups = [...roleGroups];
		newRoleGroups[roleGroupIndex][propetyName] = newValue;
		setRoleGroups(newRoleGroups);
	};

	useEffect(() => {
		getRoleGroups();
		getRoles();
	}, []);

	useEffect(() => {
		setNewRoleGroup({ roleGroupId: 0, roleGroupName: '', linkedRoles: [] });
	}, []);
	return (
		<div class="middleDetailsContainer">
			<div class="methodOwnerMiddleContainer">
				{showSpinner ? (
					<Spinner />
				) : (
					<div class="methodPhaseDetailsTab">
						<div class="roleAdditionContainer ">
							<div class="addRoleButtonAction">
								<div class="selectAddRoleButton">
									<span
										tabIndex="0"
										onClick={() => {
											setExpandedNewRoleGroups(!expandedNewRoleGroups);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setExpandedNewRoleGroups(!expandedNewRoleGroups);
											}
										}}
									>
										+&nbsp;Add New Role Group
									</span>
									{expandedNewRoleGroups && (
										<Icon
											iconName="ChevronUp"
											tabIndex={0}
											onClick={() => {
												setExpandedNewRoleGroups(!expandedNewRoleGroups);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setExpandedNewRoleGroups(!expandedNewRoleGroups);
												}
											}}
										/>
									)}
								</div>
								{expandedNewRoleGroups && (
									<div class="addRoleFields">
										<div class="addRoleNameContainer">
											<span class="AddRoleFieldLabel">Role Group Name</span>
											<input
												placeholder="Enter Role Group Name"
												value={newRoleGroup.roleGroupName}
												onChange={(e) =>
													setNewRoleGroup({
														...newRoleGroup,
														roleGroupName: e.target.value,
													})
												}
											/>
										</div>

										<div class="addRoleNameContainer featureDescription">
											<span class="AddRoleFieldLabel">Select Roles</span>
											<MultiSelectDropdown
												id={`newRoleGroupRoleIds`}
												className="activityReferSolutionMethodphasesDropdown"
												data={roles}
												value={newRoleGroup.linkedRoles}
												label="Roles"
												enableDropdown={true}
												setValue={(val) =>
													setNewRoleGroup({
														...newRoleGroup,
														linkedRoles: val,
													})
												}
											/>
										</div>

										<div>
											<span
												className="addRoleIcon"
												title="Add New Role Group"
												tabIndex={0}
												onClick={createRoleGroup}
												onKeyPress={(e) => {
													if (e.which === 13) {
														createRoleGroup();
													}
												}}
											></span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="addRolesListContainer addDeliveryPlaybookContainer editRoleGroupModal">
							<ul>
								<li>
									<div class="entryCriteriaList">
										<div class="criteriaHeader">
											<div class="criteriaIcon">Role Group Name</div>

											<div class="criteriaDescription">Select Roles</div>

											<div class="criteriaEditDeleteIcons">Delete</div>
											<div class="criteriaSaveUpdate">Update</div>
										</div>
										<ul>
											{roleGroups.map((roleGroup, index) => (
												<li>
													<div class="criteriaTable">
														<div class="criteriaIcon">
															<input
																value={roleGroup.roleGroupName}
																placeholder="Role Group Name"
																onChange={(e) =>
																	updateRoleGroupsProperty(
																		index,
																		'roleGroupName',
																		e.target.value
																	)
																}
															/>
														</div>
														<div class="criteriaDescription">
															<div>
																<MultiSelectDropdown
																	id={roleGroup.roleGroupId}
																	className="activityReferSolutionMethodphasesDropdown"
																	data={roles}
																	value={roleGroup.linkedRoles}
																	label="Roles"
																	enableDropdown={true}
																	setValue={(val) =>
																		updateRoleGroupsProperty(
																			index,
																			'linkedRoles',
																			val
																		)
																	}
																/>
															</div>
														</div>
														<div class="criteriaEditDeleteIcons">
															{selectedRoleGroup?.roleGroupId ===
																roleGroup.roleGroupId && (
																<div className="deleteSamplesPopUp">
																	<p>
																		{selectedRoleGroup.isDeleteAvailable
																			? `Are you sure that you want to delete this Role Group?`
																			: 'Please De-link roles and update  before deleting Role Group'}
																	</p>
																	<div>
																		<button
																			onClick={() => {
																				setSelectedRoleGroup({});
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					setSelectedRoleGroup({});
																				}
																			}}
																		>
																			Cancel
																		</button>
																		{selectedRoleGroup.isDeleteAvailable && (
																			<button
																				onClick={() => {
																					deleteRoleGroup(roleGroup);
																				}}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						deleteRoleGroup(roleGroup);
																					}
																				}}
																			>
																				Delete
																			</button>
																		)}
																	</div>
																</div>
															)}
															<span
																className="deliverPlaybookDeleteIcon"
																title="Delete"
																tabIndex="0"
																onClick={() => setSelectedRoleGroup(roleGroup)}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setSelectedRoleGroup(roleGroup);
																	}
																}}
															></span>
														</div>
														<div class="criteriaSaveUpdate">
															<button
																onClick={() => updateRoleGroup(roleGroup)}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		updateRoleGroup(roleGroup);
																	}
																}}
															>
																Update
															</button>
														</div>
													</div>
												</li>
											))}
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditRoleGroups;
