import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';

import UserProfile from '../../views/UserProfile';
import Announcements from '../../views/Announcements';
import ExternalApps from '../../views/ExternalApps';
import HelpAndSupport from '../../views/HelpAndSupport';
import Notifications from '../../views/Notifications';
import NotificationSettings from '../../views/Notifications/NotificationSettings';
import Copilot from '../Copilot';
import $ from 'jquery';

import './RightPanel.css';

function RightPanel({
	setTriggerElement,
	triggerElement
}) {
	const { track } = useAppInsights();
	const { axiosPostService } = useAxios();
	const { appState, setAppState, notificationExpand, setNotificationExpand } =
		useLeftMenuCollapse();

	const { rightPanelContent, parentRightPanelComponent } = appState;
	const [settings, setSettings] = useState({});

	const handleCloseButton = () => {
		if (content.name === 'Notification Settings') {
			postNotificationSettings();
		}
		setAppState({
			...appState,
			rightPanelContent: null,
			parentRightPanelComponent: null,
		});
		if (triggerElement) {
			triggerElement.focus();
		}
	};

	const rightPanelContentMapper = {
		myProfile: {
			name: 'My Profile',
			component: <UserProfile />,
			isInternalComponent: false,
		},
		copilot: {
			name: 'Copilot',
			component: <Copilot />,
			isInternalComponent: true,
		},
		whatsNew: {
			name: "What's New",
			component: <Announcements />,
			isInternalComponent: false,
		},
		externalApps: {
			name: 'Other Tools',
			component: <ExternalApps />,
			isInternalComponent: false,
		},
		mySettings: {
			name: 'My Settings',
			component: <UserProfile />,
			isInternalComponent: true,
		},
		myFavorites: {
			name: 'My Favorites',
			component: <UserProfile />,
			isInternalComponent: true,
		},
		notifications: {
			name: 'Action Center',
			component: <Notifications handleCloseButton={handleCloseButton} />,
			isInternalComponent: false,
		},
		helpAndSupport: {
			name: 'Help & Support',
			component: <HelpAndSupport />,
			isInternalComponent: false,
		},
		notificationSettings: {
			name: 'Notification Settings',
			component: <NotificationSettings setNotificationSettings={setSettings} />,
			isInternalComponent: false,
		},
	};
	const [content, setContent] = useState(
		rightPanelContentMapper[rightPanelContent]
	);

	const postNotificationSettings = async () => {
		const postBody = Object.values(settings);
		await axiosPostService('api/sdmnotifications/insert', postBody)
			.then((response) => console.log(response))
			.catch((error) => console.log(error));
	};

	const handleBackButton = () => {
		if (content.hasOwnProperty('isInternalComponent')) {
			setContent(rightPanelContentMapper[parentRightPanelComponent]);
			setAppState({
				...appState,
				rightPanelContent: parentRightPanelComponent,
			});
		}
	};
	useEffect(() => {
		const handleCloseButtonByEscape = (e) => e.key === 'Escape' && handleCloseButton();
		window.addEventListener('keydown',handleCloseButtonByEscape);
		return () => window.removeEventListener('keydown',handleCloseButtonByEscape);
	}, [])
	
	useEffect(() => {
		if (rightPanelContent != null) {
			setContent(rightPanelContentMapper[rightPanelContent]);
		}
	}, [rightPanelContent]);

	useEffect(() => {
		setNotificationExpand(false);
		return () => setNotificationExpand(false);
	}, []);

	useEffect(() => {
			$('#h6DropdownMainId').focus();
	}, []);
	
	const rightPanelRef = useRef(null);
	const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

	useEffect(() => {
		const rightPanel = rightPanelRef.current;
		if (rightPanel) {
			const focusable = rightPanel.querySelectorAll(focusableElements);
			const firstElement = focusable[0];
			const lastElement = focusable[focusable.length - 1];
	
			if (firstElement) {
				firstElement.focus();
			}
	
			const handleKeyDown = (e) => {
				if (e.key === 'Tab') {
					if (e.shiftKey) {
						if (document.activeElement === firstElement) {
							e.preventDefault();
							lastElement.focus();
						}
					} else {
						if (document.activeElement === lastElement) {
							e.preventDefault();
							firstElement.focus();
						}
					}
				}
			};
	
			rightPanel.addEventListener('keydown', handleKeyDown);
	
			return () => {
				if (rightPanel) {
					rightPanel.removeEventListener('keydown', handleKeyDown);
				}
			};
		}
	}, [rightPanelContent]);
	
	
	useEffect(() => {
	 $('#rightPanelClose').focus()
	}, [appState])
	


	return (
		<div
			id="rightPanel"
			ref={rightPanelRef}
			className={
				content.name === 'Action Center' && notificationExpand
					? 'notificationPanel notificationExpand'
					: content.name === 'Action Center' && !notificationExpand
					? 'notificationPanel'
					: content.name === 'Notification Settings'
					? 'notificationSettings'
					: ''
			}
			// onHide={()=>handleCloseButton()}
		>
			<div className="rightPanelTitle">
				{content.isInternalComponent ? (
					<button
						type="button"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handleBackButton();
							}
						}}
						onClick={() => {
							handleBackButton();
						}}
						className="backButton"
						title="back"
					>
						<Icon iconName="ChevronLeftMed" />
					</button>
				) : null}
				{/* {content.name === 'Action Center' ? (
					<div
						className="notificationExpandFwdButton"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setNotificationExpand(!notificationExpand);
								track(1, 124, 'Notification Settings', 'Notification Settings');
							}
						}}
						onClick={() => {
							setNotificationExpand(!notificationExpand);
							track(1, 124, 'Notification Settings', 'Notification Settings');
						}}
						// aria-label={
						// 	notificationExpand
						// 		? 'Collapse Action center'
						// 		: 'expand action center'
						// }
						role="button"
						title={
							notificationExpand
								? 'Collapse Action center'
								: 'expand action center'
						}
					>
						{!notificationExpand ? (
							<Icon iconName="Back" />
						) : (
							<Icon iconName="Forward" />
						)}
					</div>
				) : (
					<></>
				)} */}
				<h6 id='h6DropdownMainId' tabIndex={0}>{content.name}</h6>
				{content.name === 'Action Center' ? (
					<div
						tabIndex="0"
						className="notifySettingsButton"
						onKeyPress={(e) => {
							if (e.which === 13) {
								setAppState({
									...appState,
									rightPanelContent: 'notificationSettings',
								});
							}
						}}
						onClick={() => {
							setAppState({
								...appState,
								rightPanelContent: 'notificationSettings',
							});
						}}
						title="Notification settings"
						role="button"
					>
						<span className="notificationSettingsIcon"></span>
					</div>
				) : (
					<></>
				)}
				<button
					id="rightPanelClose"
					type="button"
					className="closeButtonIcon"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							handleCloseButton();
						}
					}}
					onClick={() => {
						handleCloseButton();
					}}
					aria-label={'Opened '+ content.name + ' Click to close'}
					title={'Close'}
				>
					<Icon iconName="cancel" />
				</button>
			</div>
			<div className="rightPanelBody">{content.component}</div>
		</div>
	);
}

export default RightPanel;
