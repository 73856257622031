import React from "react";
// import Triangle from "./Triangle";
// import Square from "./Square";
import Pentagon from "./PentagonComponent";
// import Hexagon from "./Hexagon";
import Hexagon from "./HexagonComponent";
import Triangle from "./TriangleComponent";
import Square from "./SquareComponent";

const Shapes = ({
    count,
    selectedPath,
    saveNextTopic,
    topicsData,
    showLinking,
    setLinking

}) => {
    switch (count) {
        case 4:
            return <Triangle
                width="640"
                height="480"
                color="white"
                selectedPath={selectedPath}
                saveNextTopic={saveNextTopic}
                topicsData={topicsData}
                showLinking={showLinking}
                setLinking={setLinking}
            />
        case 5:
            return <Square
                width="640"
                height="480"
                color="white"
                selectedPath={selectedPath}
                saveNextTopic={saveNextTopic}
                topicsData={topicsData}
                showLinking={showLinking}
                setLinking={setLinking}
            />
        case 6:
            return <Pentagon
                width="640"
                height="480"
                color="white"
                selectedPath={selectedPath}
                saveNextTopic={saveNextTopic}
                topicsData={topicsData}
                showLinking={showLinking}
                setLinking={setLinking}
            />
        case 7:
            return <Hexagon
                width="640"
                height="480"
                color="white"
                selectedPath={selectedPath}
                saveNextTopic={saveNextTopic}
                topicsData={topicsData}
                showLinking={showLinking}
                setLinking={setLinking}
            />
        default:
            return <Triangle
                width="640"
                height="480"
                color="white"
                selectedPath={selectedPath}
                saveNextTopic={saveNextTopic}
                topicsData={topicsData}
                showLinking={showLinking}
                setLinking={setLinking}
            />
    }
}

export default Shapes;