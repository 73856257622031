import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dompurify from 'dompurify';
import { useParams, useHistory } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import DescriptionCkEditor from '../CkEditor/TopicPreviewModeTrachChanges/DescriptionTrackChanges';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import $ from 'jquery';
import { toast } from 'react-toastify';
import RejectionReasonModal from '../TopicsReviewerCrowdSourcing/RejectionReasonModal';
import 'react-toastify/dist/ReactToastify.css';
import TopicApproval from '../CkEditor/TopicPreviewModeTrachChanges/TopicApproval';

import '../TopicsContributorCrowdSourcing/PreviewAndSubmitPopUp.css';

function PreviewAndSubmitPopUp({
	setShowPreviewAndSubmitPopUp,
	users,
	currentUser,
	description,
	descriptionSuggestions,
	setLeavingWithoutSave,
	setSubmitMessage,
	contributorDescSuggestions,
	topicDescriptionData,
	approverDescSuggestions,
	approvedDescSuggestions,
	licenseKey,
	messagesType,
	reviewerSuggestionsInfo,
	ratingPoints,
	enableGamification,
}) {
	const history = useHistory();
	const { axiosPostService, axiosGetService } = useAxios();
	const config = {
		ADD_TAGS: ['iframe', 'oembed'],
		ADD_ATTR: ['url', 'allowfullscreen'],
	};
	const htmlSanitizer = dompurify.sanitize;
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { track } = useAppInsights();
	const { id } = useParams();
	const [modalShow, setModalShow] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [previewActivityDescription, setPreviewActivityDescription] =
		useState('');
	const [showRejectionReasonModal, setShowRejectionReasonModal] =
		useState(false);
	const [rejectionComments, setRejectionComments] = useState({});
	const [usersRating, setUsersRating] = useState({});

	const closeModal = () => {
		setModalShow(false);
		setShowPreviewAndSubmitPopUp(false);
		setShowRejectionReasonModal(false);
	};

	const updateSuggestionsArray = (allSuggestions, rejectedSuggestions) => {
		if (allSuggestions.length === 0) {
			return null;
		}
		if (rejectedSuggestions.length === 0) {
			return allSuggestions;
		} else {
			const updatedSuggestions = [];
			for (const data in allSuggestions) {
				let suggestionsRejected = false;
				for (const rejectId in rejectedSuggestions) {
					if (
						JSON.parse(allSuggestions[data]).id ===
						JSON.parse(rejectedSuggestions[rejectId]).id
					) {
						if (
							JSON.parse(rejectedSuggestions[rejectId]).state === 'rejected'
						) {
							suggestionsRejected = true;
							break;
						}
					}
				}
				if (!suggestionsRejected) {
					updatedSuggestions.push(allSuggestions[data]);
				}
			}
			if (updatedSuggestions.length > 0) {
				return updatedSuggestions;
			} else {
				return null;
			}
		}
	};

	const getApprovedSuugestions = (reviewerSuggestions, approvedSuggestions) => {
		if (reviewerSuggestions) {
			const suggestionsArray = [];
			for (const revId in reviewerSuggestions) {
				let foundApprovedId = false;
				for (const apprvId in approvedSuggestions) {
					if (
						JSON.parse(approvedSuggestions[apprvId]).id ===
						JSON.parse(reviewerSuggestions[revId]).id
					) {
						if (JSON.parse(approvedSuggestions[apprvId]).state !== 'open') {
							foundApprovedId = true;
							suggestionsArray.push(approvedSuggestions[apprvId]);
							break;
						}
					}
				}
				if (!foundApprovedId) {
					suggestionsArray.push(
						JSON.stringify({
							id: JSON.parse(reviewerSuggestions[revId]).id,
							state: 'accepted',
						})
					);
				}
			}
			if (suggestionsArray.length > 0) {
				return suggestionsArray;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const checkContribution = (requestType) => {
		const rejectedSuggestionsIds = approvedDescSuggestions
			.filter(
				(suggestion) =>
					JSON.parse(suggestion).state === 'rejected' ||
					JSON.parse(suggestion).state === 'accepted'
			)
			.map((item) => {
				return JSON.parse(item).id;
			});
		const contributedSuggestionsIds = Object.keys(reviewerSuggestionsInfo).map(
			(suggestion) => {
				return reviewerSuggestionsInfo[suggestion].id;
			}
		);
		const contributorsRejectedIds = contributedSuggestionsIds.filter((id) =>
			rejectedSuggestionsIds.includes(id)
		);
		if (requestType === 'submit') {
			if (
				contributorsRejectedIds.length > 0 ||
				(topicDescriptionData.reviewerSuggestionsJson !== 'null' &&
					users.length > 1)
			) {
				setShowRejectionReasonModal(true);
			} else {
				submitData();
			}
		} else {
			if (contributorsRejectedIds.length > 0) {
				return true;
			}
			return false;
		}
	};

	const addReviewerComment = () => {
		const commentsInfo = Object.keys(rejectionComments).map((id) => {
			const reviewedSuggestions = Object.keys(
				reviewerSuggestionsInfo
			).map((suggestion) => {
				return JSON.parse(reviewerSuggestionsInfo[suggestion]);
			});
			const reviewed = reviewedSuggestions.filter((reviewed) => { return reviewed.id == id });
			return JSON.stringify({
				id: id,
				authorId: reviewed[0].authorId,
				data: reviewed[0].data,
				type: reviewed[0].type,
				comment: rejectionComments[id],
			});
		});
		return commentsInfo;
	};

	const postData = {
		topicId: id,
		description: description,
		publishDescription: previewActivityDescription,
		resultCalloutJson: updateSuggestionsArray(
			approverDescSuggestions,
			approvedDescSuggestions
		),
		resultReviewedJson: null,
		topicVersion: topicDescriptionData.topicVersion,
		reviewerEmail: topicDescriptionData.reviewerEmail,
		contributorCalloutJson: topicDescriptionData.calloutJson,
		title: topicDescriptionData.title,
		isEditable: true,
		lastContributorDescription: topicDescriptionData.lastContributorDescription,
		contributorReviewedJson: topicDescriptionData.reviewerSuggestionsJson,
		reviewerCalloutJson: topicDescriptionData.reviewerCalloutJson,
		reviewerReviewedJson: topicDescriptionData.reviewerReviewedJson,
	};

	useEffect(() => {
		track(2, 145, topicDescriptionData.topicId, topicDescriptionData.title);
	}, []);

	const submitData = async () => {
		const editingtateId = await axiosGetService(
			`api/sdmtopics/getTopic2/${id}`
		);
		if (editingtateId.data.stateId === 0) {
			setLeavingWithoutSave(false);
			setSubmitMessage(messagesType['publishedDocumentMessage']);
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				successMessagePopUp: true,
				userLeaveWithSave: true,
			});
			setShowPreviewAndSubmitPopUp(false);
		} else {
			const response = editingtateId.data;

			if (response.reviewerSuggestionsJson) {
				postData.resultReviewedJson = getApprovedSuugestions(
					JSON.parse(response.reviewerSuggestionsJson),
					approvedDescSuggestions
				);
			}
			postData.resultApproverComments = addReviewerComment();
			setShowLoader(true);
			track(1, 205, topicDescriptionData.topicId, topicDescriptionData.title);
			const imagesUrl = sessionStorage.getItem('crowdSourcing')
				? JSON.parse(sessionStorage.getItem('crowdSourcing'))
				: [];
			postData.imagePaths = [...topicDescriptionData.imagePaths, ...imagesUrl];
			postData.ratingPoints = usersRating;
			await axiosPostService(`api/sdmtopics/addTopic2Approver`, postData)
				.then(() => {
					setLeavingWithoutSave(false);
					setSubmitMessage(messagesType['approvalSubmissionMessage']);
					setCrowdSourcingAppState({
						...crowdSourcingAppState,
						successMessagePopUp: true,
					});
					sessionStorage.setItem('crowdSourcing', JSON.stringify([]));
					setShowPreviewAndSubmitPopUp(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				if (type && type.split(' ')[0] === 'image') {
					if (typeof $(element).attr('style') !== 'undefined') {
						$(imgElement).attr({ style: $(element).attr('style') });
					} else {
						$(imgElement).attr({ style: ' width: 100%' });
					}
				} else if (type === 'media') {
					const iframeUrl = $(imgElement).attr('url');
					if (imgElement.children.length < 1) {
						imgElement.innerHTML = htmlSanitizer(
							`<iframe src=${iframeUrl} style="border:none;" width="640" height="360" allowfullscreen="true"></iframe> `,
							config
						);
					}
				}
			}
		});
	});

	const getReviewerInfo = () => {
		return users.filter(({ id }) => id === topicDescriptionData.reviewerEmail);
	};

	return (
		<>
			{!licenseKey || showLoader ? (
				<Spinner />
			) : (
				<>
					{showRejectionReasonModal ? (
						<RejectionReasonModal
							users={getReviewerInfo()}
							closeModal={closeModal}
							reviewedDescriptionSuggestions={approvedDescSuggestions}
							contributorSuggestionsInfo={reviewerSuggestionsInfo}
							submit={submitData}
							rejectionComments={rejectionComments}
							setRejectionComments={setRejectionComments}
							currentUser={currentUser}
							postData={postData}
							ratingPoints={ratingPoints}
							usersRating={usersRating}
							setUsersRating={setUsersRating}
							checkRejection={checkContribution}
							enableGamification={enableGamification}
						/>
					) : (
						<Modal
							className="topicPreviewAndSubmitPopUp"
							show={modalShow}
							onHide={() => {
								/* Not calling any function to close modal*/
							}}
							dialogClassName="modal-90w"
						>
							<Modal.Header>
								<Modal.Title>
									Preview
									<div className="discardAndPreviewButton">
										<Button
											title="Cancel"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													closeModal();
													track(
														1,
														148,
														topicDescriptionData.topicId,
														topicDescriptionData.title
													);
												}
											}}
											onClick={() => {
												closeModal();
												track(
													1,
													148,
													topicDescriptionData.topicId,
													topicDescriptionData.title
												);
											}}
											className="activityDiscardButton"
										>
											Cancel
										</Button>
										<Button
											title="Publish"
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													checkContribution('submit');
												}
											}}
											onClick={() => {
												checkContribution('submit');
											}}
											className="activityPreviewButton"
										>
											Publish
										</Button>
									</div>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="topicsCrowdSourcingContainer">
									<div class="topicsDescriptionDetails">
										<div className="topicDescriptionDiv">
											<div className="topicDescTitlePart">
												<span className="topicVersion">
													Document Version: {topicDescriptionData.topicVersion}
													,&nbsp;&nbsp;
													{`${topicDescriptionData.modifiedDate} `}
												</span>
											</div>
											<h4 className="topicTitle">
												{topicDescriptionData.title}
											</h4>
											<div class="existingHashTagsOfTopic">
												{topicDescriptionData.hashTagsList.map(({ name }) => (
													<span
														key={name}
														onClick={() => {
															history.push(
																`/search?searchParam=${encodeURIComponent(
																	name
																)}`
															);
														}}
														onKeyPress={(e) => {
															if (e.which === 13) {
																history.push(
																	`/search?searchParam=${encodeURIComponent(
																		name
																	)}`
																);
															}
														}}
													>
														{name}
													</span>
												))}
											</div>

											{description ? (
												<>
													<DescriptionCkEditor
														users={users}
														currentUser={currentUser}
														initialData={description}
														setContent={setPreviewActivityDescription}
														initalSuggestions={descriptionSuggestions}
														licenseKey={licenseKey}
													/>
													<div className="hideApprovalCKEditor">
														<TopicApproval
															users={users}
															currentUser={currentUser}
															initialData={description}
															setContent={setPreviewActivityDescription}
															initalSuggestions={descriptionSuggestions}
															licenseKey={licenseKey}
														/>
													</div>
												</>
											) : null}
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					)}
				</>
			)}
		</>
	);
}

export default PreviewAndSubmitPopUp;
