import React, { useContext, createContext, useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfig } from './ConfigContext';
import { useAuth } from './UserAuthContext';
import { useAxios } from './AxiosContext';
import { ItemTypeMap, EventTypeMap } from '../static/TelemetryData';

function Telemetry(appInsights) {
	const { user } = useAuth();
	const { pathname, search } = useLocation();
	const { axiosPostService } = useAxios();
	const itemTypeIdList = [1, 2, 10, 15, 25, 126];

	const saveRecentActivities = async (itemTypeId, itemId, optional) => {
		const postData = {
			ArtifactTypeName: ItemTypeMap[itemTypeId],
			ArtifactId: itemId,
			ComplexityId: optional ? optional.complexityId : 0,
			Url: pathname + search,
		};

		if (!isNaN(Number(itemId))) {
			await axiosPostService(`api/sdmactivity/insertRecentActivities`, postData);
		}
	};

	const saveTopFiveDownload = async (itemId, optional) => {
		const postData = {
			Id: itemId,
			ComplexityId: optional.complexityId,
		};
		await axiosPostService(`api/sdmtemplate/insertDownlodedTemplate`, postData);
		savePreviewAndDownload(itemId, optional);
	};
	const savePreviewAndDownload = async (itemId, optional) => {
		const savePostData = {
			ArtifactTypeName: ItemTypeMap[1],
			ArtifactId: itemId,
			ComplexityId: optional ? optional.complexityId : 0,
			Url: `/template/${itemId}?complexityId=${optional.complexityId}`,
		};
		await axiosPostService(`api/sdmactivity/insertRecentActivities`, savePostData);
	};

	const track = (eventTypeId, itemTypeId, itemId, itemName, optional) => {
		let trackData = {
			itemTypeId,
			itemTypeName: ItemTypeMap[itemTypeId],
			itemId,
			itemName,
			userAlias: user.profile.mail,
			url: pathname + search,
		};

		if (optional !== undefined) {
			trackData = {
				...trackData,
				...optional,
			};
		}
		if (eventTypeId === 2 && itemTypeIdList.includes(itemTypeId)) {
			saveRecentActivities(itemTypeId, itemId, optional);
		} else if (itemTypeId === 112) {
			saveTopFiveDownload(itemId, optional);
		} else if (itemTypeId === 126) {
			savePreviewAndDownload(itemId, optional);
		}
		appInsights.trackEvent({ name: EventTypeMap[eventTypeId] }, trackData);
	};

	return { track };
}

const TelemetryContext = createContext();
const TelemetryProvider = ({ children }) => {
	const { instrumentationKey } = useConfig();
	const { user } = useAuth();

	const history = useHistory();
	const reactPlugin = new ReactPlugin();
	const appInsights = new ApplicationInsights({
		config: {
			instrumentationKey,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: {
					history,
				},
			},
		},
	});
	appInsights.loadAppInsights();

	useEffect(() => {
		if (user.isAuthenticated) {
			appInsights.setAuthenticatedUserContext(user.profile.mail);
		}
	}, [user.isAuthenticated]);

	const useTelemetry = Telemetry(appInsights);
	return <TelemetryContext.Provider value={useTelemetry}>{children}</TelemetryContext.Provider>;
};

const useAppInsights = () => useContext(TelemetryContext);
export { TelemetryProvider, useAppInsights };

/* class TelemetryProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
    if (
      !initialized
      && Boolean(AppInsightsInstrumentationKey)
      && Boolean(history)
    ) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }

    this.props.after();
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider)); */
