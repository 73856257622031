import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import $ from 'jquery';
import ActivityRaciMatrix from '../ActivityRaciMatrix';

const getRolesName = (roles) => roles.map(({ name }) => name);

const ActivityPreview = ({
	showActivityPreview,
	setShowActivityPreview,
	complexityLevelDetails,
	selectedComplexity,
	hashTags,
	activityTitle,
	tools,
	topics,
	procedures,
	businessRuleContent
}) => {
	const [entryCriteria, setEntryCriteria] = useState([
		...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName === 'Entry Criteria'
		),
	]);
	const [input, setInput] = useState([
		...complexityLevelDetails[selectedComplexity].entryCriteria.filter(
			(item) => item.criteriaTypeName !== 'Entry Criteria'
		),
	]);
	const [exitCriteria, setExitCriteria] = useState([
		...complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName === 'Exit Criteria'
		),
	]);
	const [output, setOutput] = useState([
		...complexityLevelDetails[selectedComplexity].exitCriteria.filter(
			(item) => item.criteriaTypeName !== 'Exit Criteria'
		),
	]);

	const [raciData] = useState(() => ({
		responsibleList: getRolesName(
			complexityLevelDetails[selectedComplexity].responsibleList
		),
		accountableList: getRolesName(
			complexityLevelDetails[selectedComplexity].accountableList
		),
		consultedList: getRolesName(
			complexityLevelDetails[selectedComplexity].consultedList
		),
		informedList: getRolesName(
			complexityLevelDetails[selectedComplexity].informedList
		),
	}));

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			const type = element.className;
			if (type && type.split(' ')[0] === 'image') {
				const imgElement = element.children[0];
				if (typeof $(element).attr('style') !== 'undefined') {
					$(imgElement).attr({ style: $(element).attr('style') });
				} else {
					$(imgElement).attr({ style: ' width: 100%' });
				}
			}
		});
	});
	return (
		<Modal
			className="PreviewAndSubmitPopUp"
			show={showActivityPreview}
			onHide={() => {
				setShowActivityPreview(false);
			}}
			dialogClassName="modal-90w"
		>
			<Modal.Header>
				<Modal.Title>
					Preview
					<div className="discardAndPreviewButton">
						<Icon
							iconName="Cancel"
							title="Cancel"
							tabIndex={0}
							onClick={() => setShowActivityPreview(false)}
						/>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div className="activityLandinPage">
						<div className="activityMainContainer">
							<div className="activityDescriptionDetails">
								<div className="previewDescOfActivity">
									<h4
										title={activityTitle}
										className="activityLandingPageTitle"
									>
										{activityTitle}
									</h4>

									<div class="existingHashTagsOfActivity methodOwner">
										{hashTags.map((hashtag) => (
											<span key={hashtag}>{hashtag}</span>
										))}
									</div>
									<ActivityRaciMatrix activityData={raciData} />

									<div className="innerContent" id="previewInnerContent">
										<h2 className="sub-heading" id="previewDescr"
											aria-label='Description'>
											Description
										</h2>
										{!complexityLevelDetails[selectedComplexity].description ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div
												className="desc ck-content"
												id="previewDescription"
												dangerouslySetInnerHTML={{
													__html:
														complexityLevelDetails[selectedComplexity]
															.description,
												}}
											/>
										)}

										<h2 className="sub-heading" id="previewSupportAct">
											Supporting Activities
										</h2>
										{!complexityLevelDetails[selectedComplexity]
											.supportingActivities ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div
												className="descSA  ck-content"
												id="previewSupport"
												dangerouslySetInnerHTML={{
													__html:
														complexityLevelDetails[selectedComplexity]
															.supportingActivities,
												}}
											/>
										)}

										<h2 className="sub-heading" id="previewEntryc">
											Entry Criteria/Input
										</h2>
										<h6 className="sub-heading criteriaNameLabel">
											Entry Criteria
										</h6>
										{entryCriteria.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<ul>
												{entryCriteria
													?.filter((value) => value.name.trim() !== "") // Filter out empty names
													?.map((value) => (
														<li key={value.id}>
															<div
																className="ck-content"
																dangerouslySetInnerHTML={{ __html: value.name }}
															/>
														</li>
													))}
											</ul>
										)}

										<h6 className="sub-heading criteriaNameLabel">Input</h6>
										{input.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<ul>
												{input
												?.filter((value) => value.name.trim() !== "")
												?.map((value) => (
													<li key={value.id}>
														<div className=' ck-content'
															dangerouslySetInnerHTML={{ __html: value.name }}
														/>
													</li>
												))}
											</ul>
										)}

										<h2 className="sub-heading" id="previewTasks">
											Tasks
										</h2>
										{complexityLevelDetails[selectedComplexity].taskList
											.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<ul>
												{complexityLevelDetails[
													selectedComplexity
												]?.taskList?.map((value) => (
													<li key={value.id}>
														<div className=' ck-content mb-0'
															dangerouslySetInnerHTML={{ __html: value.name }}
														/>
														{value.description ? (
															<ul>
																<li key={value.id}>
																	<div className=' ck-content mb-0'
																		dangerouslySetInnerHTML={{
																			__html: value.description,
																		}}
																	/>
																</li>
															</ul>
														) : (
															<></>
														)}
													</li>
												))}
											</ul>
										)}
										<h2 className="sub-heading" id="topics">
											Procedures
										</h2>
										{procedures.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div id="previewProcedureList">
												<ul>
													{procedures.map((procedure) => (
														<li key={procedure.id}>
															{
																<a
																	href={`/topics/
																	${procedure.id}`}
																	target="_blank"
																>
																	{procedure.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										)}
										<h2 className="sub-heading" id="topics">
											Related Topics
										</h2>
										{topics.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div id="previewProcedureList">
												<ul>
													{topics.map((topic) => (
														<li key={topic.id}>
															{
																<a
																	href={`/topics/${topic.id}`}
																	target="_blank"
																>
																	{topic.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										)}
										<h2 className="sub-heading" id="previewExitc">
											Exit Criteria/Output
										</h2>
										<h6 className="sub-heading criteriaNameLabel">
											Exit Criteria
										</h6>
										{exitCriteria.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<>
												<ul>
													{exitCriteria
													?.filter((value) => value.name.trim() !== "")
													?.map((value) => (
														<li key={value.id}>
															<div className=' ck-content'
																dangerouslySetInnerHTML={{ __html: value.name }}
															/>
														</li>
													))}
												</ul>
											</>
										)}
										<h6 className="sub-heading criteriaNameLabel">Output</h6>
										{output.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<>
												<ul>
													{output
													?.filter((value) => value.name.trim() !== "")
													?.map((value) => (
														<li key={value.id}>
															<div className=' ck-content'
																dangerouslySetInnerHTML={{ __html: value.name }}
															/>
														</li>
													))}
												</ul>
											</>
										)}
										{businessRuleContent.length ? (
											<>
												<h2 className="sub-heading" id="previewSupportAct">
													Business Rule
												</h2>
												<ul>
													{businessRuleContent?.map(e => (
														<li>
															<a href={e.url} target="_blank">
																<span>{e.name}</span>
															</a>
														</li>
													))}
												</ul>
											</>
										) : <></>
										}

										<h2 className="sub-heading" id="previewSupportAct">
											Deliverables
										</h2>
										{complexityLevelDetails[selectedComplexity]?.deliverables?.length === 0 &&
											complexityLevelDetails[selectedComplexity]?.templatesList
												?.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<>
												{complexityLevelDetails[selectedComplexity]
													?.templatesList?.length > 0 ? (
													<ul className='mb-0'>
														{complexityLevelDetails[
															selectedComplexity
														].templatesList.map((item) => (
															<li key={item.id}>
																<a href={item.fileLocation}>{item.name}</a>
															</li>
														))}
													</ul>
												) : (
													<></>
												)}
												<div
													className="descSA ck-content"
													id="previewSupport"
													dangerouslySetInnerHTML={{
														__html:
															complexityLevelDetails[selectedComplexity]
																.deliverables,
													}}
												/>
											</>
										)}
										<h2 className="sub-heading" id="previewTool">
											Tools
										</h2>
										{tools.length === 0 ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div id="previewTopicList">
												<ul>
													{tools.map((tool) => (
														<li key={tool.id}>
															{
																<a href={tool.url} target="_blank">
																	{tool.name}
																</a>
															}
														</li>
													))}
												</ul>
											</div>
										)}
										<h2 className="sub-heading" id="previewTipstech">
											Guidance, Tips and Techniques
										</h2>
										{!complexityLevelDetails[selectedComplexity]
											.tipsAndTechniques ? (
											<ul>
												<li>None</li>
											</ul>
										) : (
											<div
												className="descTT  ck-content"
												id="previewDescTt"
												dangerouslySetInnerHTML={{
													__html:
														complexityLevelDetails[selectedComplexity]
															.tipsAndTechniques,
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ActivityPreview;
