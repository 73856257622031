import React, { useState } from "react";
import "./PolygonTopic.css";

const Pentagon = (props) => {
    return (
        <ul className="pentagonTopicList">
            {props.showLinking ?
                <>
                    <a href={`${window.location.origin + `/topics/${props.topicsData[1]?.topicId}`}`}>
                        <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon pentagonTopicListItem1" : "pentagonTopicListItem1"}>
                            <span className="pentagonTopicTitle1">
                                {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                    <a href={window.location.origin + `/topics/${props.topicsData[2]?.topicId}`}>
                        <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon pentagonTopicListItem2" : "pentagonTopicListItem2"}>
                            <span className="pentagonTopicTitle2" >
                                {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                    <a href={window.location.origin + `/topics/${props.topicsData[3]?.topicId}`}>
                        <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon pentagonTopicListItem3" : "pentagonTopicListItem3"}>
                            <span className="pentagonTopicTitle3" >
                                {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                    <a href={window.location.origin + `/topics/${props.topicsData[0]?.topicId}`}>
                        <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon pentagonTopicListItem4" : "pentagonTopicListItem4"}>
                            <span className="pentagonTopicTitle4" >
                                {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                    <a href={window.location.origin + `/topics/${props.topicsData[4]?.topicId}`}>
                        <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon pentagonTopicListItem5" : "pentagonTopicListItem5"}>
                            <span className="pentagonTopicTitle5" >
                                {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                    <a href={window.location.origin + `/topics/${props.topicsData[5]?.topicId}`}>
                        <li className={props.selectedPath.toString().includes(5) ? "selectedPolygon pentagonTopicListItem6" : "pentagonTopicListItem6"}>
                            <span className="pentagonTopicTitle6">
                                {props.topicsData ? props.topicsData[5]?.topicName.replace(/_.*/, '') : ''}
                            </span>
                        </li>
                    </a>
                </>
                :
                <>
                    <li className={props.selectedPath.toString().includes(1) ? "selectedPolygon pentagonTopicListItem1" : "pentagonTopicListItem1"}
                        onClick={() => props.saveNextTopic(1)}>
                        <span className="pentagonTopicTitle1">
                            {props.topicsData ? props.topicsData[1]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                    <li className={props.selectedPath.toString().includes(2) ? "selectedPolygon pentagonTopicListItem2" : "pentagonTopicListItem2"}
                        onClick={() => props.saveNextTopic(2)}>
                        <span className="pentagonTopicTitle2" >
                            {props.topicsData ? props.topicsData[2]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                    <li className={props.selectedPath.toString().includes(3) ? "selectedPolygon pentagonTopicListItem3" : "pentagonTopicListItem3"}
                        onClick={() => props.saveNextTopic(3)}>
                        <span className="pentagonTopicTitle3" >
                            {props.topicsData ? props.topicsData[3]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                    <li className={props.selectedPath.toString().includes(0) ? "selectedPolygon pentagonTopicListItem4" : "pentagonTopicListItem4"}
                        onClick={() => props.saveNextTopic(0)}>
                        <span className="pentagonTopicTitle4" >
                            {props.topicsData ? props.topicsData[0]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                    <li className={props.selectedPath.toString().includes(4) ? "selectedPolygon pentagonTopicListItem5" : "pentagonTopicListItem5"}
                        onClick={() => props.saveNextTopic(4)}>
                        <span className="pentagonTopicTitle5" >
                            {props.topicsData ? props.topicsData[4]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                    <li className={props.selectedPath.toString().includes(5) ? "selectedPolygon pentagonTopicListItem6" : "pentagonTopicListItem6"}
                        onClick={() => props.saveNextTopic(5)}>
                        <span className="pentagonTopicTitle6">
                            {props.topicsData ? props.topicsData[5]?.topicName.replace(/_.*/, '') : ''}
                        </span>
                    </li>
                </>
            }
        </ul>
    );
}

export default Pentagon;
