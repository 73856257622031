import React from 'react';
import { useAppInsights } from '../../context/TelemetryContext';

const ComplexityFilter = ({ data, setValue, value, telemetryId }) => {
	const { track } = useAppInsights();
	return (
		<ul>
			{data.map((complexity) => (
				<li key={complexity.id}>
					<label className="checkBoxContainer filterChkboxContainer">
						<input
							name="complexity"
							type="radio"
							checked={complexity.id === value.id}
							onChange={() => {
								setValue(complexity);
								track(1, telemetryId, complexity.id, complexity.name);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setValue(complexity);
									track(1, telemetryId, complexity.id, complexity.name);
								}
							}}
						/>
						<span className="checkmark"></span>
						<span className="filterLabel">{complexity.name}</span>
					</label>
				</li>
			))}
		</ul>
	);
};

export default ComplexityFilter;
