import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';

import TutorialForm from './Form';
import TutorialSequence from './Sequence';
import './ManageTutorials.css';
import { useHistory, useLocation } from 'react-router';
import { useAppInsights } from '../../context/TelemetryContext';

const AdminEditSolutionMethod = ({ }) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { track } = useAppInsights();
	const tabs = [
		{
			id: 1,
			title: 'Edit Video Tutorials',
		},
		{ id: 2, title: 'Sequence' },
	];
	const tutorialTypesData = [
		{ id: 0, name: 'Document' },
		{ id: 1, name: 'Video' },
	];

	const { axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
	const [currentTab, setCurrentTab] = useState(1);
	const [showForm, setShowForm] = useState(false);
	const [tutorialTitle, setTutorialTitle] = useState('');
	const [tutorialUrl, setTutorialUrl] = useState('');
	const [tutorialTypeId, setTutorialTypeId] = useState(0);
	const [isActive, setIsActive] = useState(true);
	const [tutorials, setTutorials] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);

	const getTutorials = async () => {
		await axiosGetService(
			`/api/sdmtutorials/tutorials?pageSize=10&pageNumber=1&searchValue=0&id=0`
		).then((response) => {
			setShowSpinner(false);
			if (response.data && response.data.sdmTutorials)
				setTutorials(response.data.sdmTutorials);
		});
	};

	const addTutorial = async () => {
		const postData = {
			title: tutorialTitle,
			url: tutorialUrl,
			isActive: isActive,
			tutorialTypeId: tutorialTypeId,
		};
		await axiosPostService(`/api/sdmtutorials/addtutorial`, postData).then(
			(response) => {
				if (response.data.status) {
					toast.info('You have successfully created tutorial', {
						position: 'top-right',
					});
					getTutorials();
					setTutorialTitle('');
					setTutorialTypeId(0);
					setTutorialUrl('');
					setIsActive(true);
					track(1, 2008, 0, tutorialTitle);
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			}
		);
	};

	const saveTutorial = async ({ id, title, url, isActive, tutorialTypeId }) => {
		const postData = {
			id: id,
			title: title,
			url: url,
			isActive: isActive,
			tutorialTypeId: tutorialTypeId,
		};
		await axiosPostService(`/api/sdmtutorials/updatetutorial`, postData).then(
			(response) => {
				if (response.data.status) {
					if (response.data.status) {
						toast.info('You have successfully updated tutorial', {
							position: 'top-right',
						});
					}
					track(1, 2009, id, title);
					getTutorials();
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			}
		);
	};

	const removeTutorial = async (id, title) => {
		await axiosDeleteService(`/api/sdmtutorials/tutorials/${id}`).then(
			(response) => {
				if (response.data.status) {
					toast.info('You have successfully deleted', {
						position: 'top-right',
					});
					getTutorials();
					const removeTutorialTrackID = 2010;
					track(1, removeTutorialTrackID, id, title);
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			}
		);
	};

	const updateSequence = async () => {
		const postData = {
			tutorialIdsInSequence: tutorials
				.reverse()
				.map((item) => {
					return item.id;
				})
				.toString(),
		};
		await axiosPostService(
			`/api/sdmtutorials/updateTutorialSequence`,
			postData
		).then((response) => {
			if (response.data.status) {
				toast.info('You have successfully updated the sequence', {
					position: 'top-right',
				});
				history.push(pathname);
			} else {
				toast.error(response.data.errorMessage, {
					position: 'top-right',
				});
			}
		});
	};

	const updateTutorial = ({ id, title, url, isActive, tutorialTypeId }) => {
		const updatedTutorial = tutorials.map((item) => {
			if (item.id == id) {
				return {
					...item,
					title: title,
					url: url,
					isActive: isActive,
					tutorialTypeId: tutorialTypeId,
				};
			} else {
				return item;
			}
		});
		setTutorials(updatedTutorial);
	};

	const components = {
		1: (
			<TutorialForm
				showForm={showForm}
				setShowForm={setShowForm}
				tutorialTitle={tutorialTitle}
				setTutorialTitle={setTutorialTitle}
				tutorialUrl={tutorialUrl}
				setTutorialUrl={setTutorialUrl}
				tutorialTypeId={tutorialTypeId}
				setTutorialTypeId={setTutorialTypeId}
				isActive={isActive}
				setIsActive={setIsActive}
				updateTutorial={updateTutorial}
				removeTutorial={removeTutorial}
				saveTutorial={saveTutorial}
				tutorials={tutorials}
				tutorialTypesData={tutorialTypesData}
				addTutorial={addTutorial}
			/>
		),
		2: <TutorialSequence sequence={tutorials} setSequence={setTutorials} />,
	};

	useEffect(() => {
		getTutorials();
	}, []);

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles addSolMethod ManageTutorials"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => history.push(pathname)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Video Tutorials
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showSpinner ? (
					<div className="spinner">
						<Spinner animation="border" />
					</div>
				) : (
					<div className="methodOwnerMainContainer">
						<div className="methodOwnerPage">
							<div className="methodOwnerContainer">
								<div className="methodLeftContainer">
									<ul className="methodPhaseLeftMenu">
										{tabs.map((item) => (
											<li
												key={item.title}
												className={
													currentTab === item.id ? 'activePhaseTab' : ''
												}
												onClick={() => {
													setCurrentTab(item.id);
												}}
												aria-label={`click here to select ${item.title}`}
												role="tab"
												aria-selected={
													currentTab === item.id ? 'true' : 'false'
												}
											>
												<span>{item.title}</span>
											</li>
										))}
									</ul>
								</div>
								{components[currentTab]}
								<div className="saveContinueDetailsContainer">
									{currentTab === 2 && (
										<button className="forwardButton">
											<span
												className="saveContinueButton"
												onClick={() => {
													updateSequence();
												}}
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														updateSequence();
													}
												}}
											>
												Save
											</span>
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default AdminEditSolutionMethod;
