import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../context/AxiosContext';

import '../TemplateCard/TemplateCard.css';

const ReviewerApproveRejectPopup = ({
	templateData,
	closeModal,
	singleView,
	showApproveRejectPopup,
	approveRejectData,
	submitApproveOrReject,
	getContributions,
	reviewComment,
	setReviewComment,
}) => {
	const history = useHistory();
	const { axiosGetService } = useAxios();
	const [messagesType, setMessagesType] = useState({});
	const [error, setError] = useState(false);

	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewSubmissionMessage&title=${encodeURIComponent(
				templateData.title
			)}`
		).then((response) => {
			message['reviewSubmissionMessage'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=reviewRejectSubmissionMessage&title=${encodeURIComponent(
				templateData.title
			)}`
		).then((response) => {
			message['reviewRejectSubmissionMessage'] = response.data;
		});
		setMessagesType(message);
	};

	useEffect(() => {
		getMessage();
		setReviewComment('');
	}, []);

	return (
		<Modal
			show={showApproveRejectPopup}
			onHide={closeModal}
			centered
			dialogClassName={
				approveRejectData.type === 'approve'
					? 'templateRejectModalPopUp reviewerRejectMessage templateApproval'
					: 'templateRejectModalPopUp reviewerRejectMessage'
			}
		>
			{showApproveRejectPopup ? (
				<Modal.Body>
					{approveRejectData.type === 'approve' ? (
						<>
							<p>{messagesType['reviewSubmissionMessage']}</p>
							<button
								onClick={() => {
									submitApproveOrReject(
										approveRejectData.data,
										approveRejectData.type
									);
								}}
								className="templateSubmitButton submittedforApprover"
							>
								OK
							</button>
						</>
					) : (
						<>
							<p>{messagesType['reviewRejectSubmissionMessage']}</p>
							<p className="reviewerRejectMessagePara">
								Let the contributor know the reason for rejection
							</p>
							<textarea
								placeholder="Type here"
								value={reviewComment}
								onChange={(e) => {
									setReviewComment(e.target.value);
								}}
							/>
							{error ? <p className="errorMsg">Comment is required</p> : <></>}
							<div>
								<button onClick={closeModal} className="templateDiscardButton">
									No
								</button>
								<button
									className="templateSubmitButton"
									onClick={() => {
										if (reviewComment) {
											setError(false);
											submitApproveOrReject(
												approveRejectData.data,
												approveRejectData.type
											);
										} else {
											setError(true);
										}
									}}
								>
									Yes
								</button>
							</div>
						</>
					)}
				</Modal.Body>
			) : (
				<></>
			)}
		</Modal>
	);
};

export default ReviewerApproveRejectPopup;
