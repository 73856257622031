import React from 'react';
import { Line } from 'react-chartjs-2';

const MyPerformanceTrends = ({ performanceTrendsData }) => {
 const xAxisLabel = () => {
  const labels = performanceTrendsData.map(({ weekStartDate }) => {
   return weekStartDate;
  });
  return labels;
 };

 const getGraphData = () => {
  const data = performanceTrendsData.map(({ points }) => {
   return points;
  });
  return data;
 };

 const getStepSize = () => {
  const data = performanceTrendsData.map(({ points }) => {
   return points;
  });
  const stepSize = Math.ceil(Math.max(...data) / 5);
  return data.length > 0 ? stepSize : 0;
 };

 const graphData = {
  labels: xAxisLabel(),
  datasets: [
   {
    label: 'Points',
    data: getGraphData(),
    fill: false,
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    borderWidth: 1,
   },
  ],
 };

 const options = {
  maintainAspectRatio: false,
  scales: {
   x: {
    title: {
     display: false,
     text: 'Month',
    },
    grid: {
     display: false,
    },
   },
   y: {
    ticks: {
     stepSize: getStepSize(),
    },
    beginAtZero: true,
    title: {
     display: true,
     text: 'Points',
     font: {
      weight: 500,
      family: "'Segoe UI','Segoe UI'",
     },
    },
    grid: {
     display: false,
    },
   },
  },
  plugins: {
   legend: {
    display: false,
   },
   text: '',
  },
  backgroundColor: '#f8f9fa',
 };
 return (
  <div className="myPerformanceTrendsGraph">
   <Line data={graphData} options={options} />
  </div>
 );
};

export default MyPerformanceTrends;
