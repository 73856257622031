import React, { useState, useEffect } from 'react';
import MultiSelectDropdownWithAll from './MultiSelectDropdownWithAll';
import { useAxios } from '../../context/AxiosContext';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';

const ExtractRolesModal = ({
	handleExtractRoles,
	setHandleExtractRoles,
	solutionMethodId,
	solutionMethodName,
}) => {
	const { axiosGetService, axiosGetFileService } = useAxios();
	const [selectedComplexities, setSelectedComplexities] = useState([]);
	const [availableComplexites, setAvailableComplexites] = useState([]);
	const [showMessage, setShowMessage] = useState(false);
	const [message, setMessage] = useState('');
	const getComplexities = async () => {
		await axiosGetService(
			`api/sdmphase/getcomplexity/${solutionMethodId}/${0}/${0}`
		).then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
				};
			});
			setAvailableComplexites(data);
		});
	};
	useEffect(() => {
		getComplexities();
	}, []);
	const downloadRACIData = () => {
		setShowMessage(true);
		if (selectedComplexities.length > 0) {
			setMessage('Downloading File ...');
			axiosGetFileService(
				`api/sdmrole/downloadactivityraciexcel?projectTypeIds=0&methodIds=${solutionMethodId}&phaseIds=0&groupIds=0&subgroupIds=0&complexityIds=${selectedComplexities
					.map((complexity) => complexity.id)
					.join()}&searchValue=0`
			).then((response) => {
				setMessage('File Downloaded');
				saveAs(
					new Blob([response.data]),
					`${solutionMethodName}-RACIData.xlsx`
				);
			});
		} else {
			setMessage('No complexities Selected');
		}
	};
	return (
		<Modal
			dialogClassName="extractRolesMainModal"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => setHandleExtractRoles(false)}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Extract Roles for "{solutionMethodName}"
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="extractRolesMainWrapperModal">
					<div class="extractPopupBody">
						<h6>The Roles will be extracted in Excel format.</h6>
						<p>Select Complexity</p>
						<MultiSelectDropdownWithAll
							className="toolsAndGuidanceMultiSelectFilter"
							data={availableComplexites}
							setValue={setSelectedComplexities}
							value={selectedComplexities}
							enableDropdown={true}
							title="Select Complexity"
						/>
						<div class="extractMethodButtons">
							<div
								class="extractSummeryButton"
								onClick={downloadRACIData}
								title="Extract Roles"
							>
								Extract Roles
							</div>
						</div>
						{showMessage ? (
							<p className="extractMethodologyMessage">{message}</p>
						) : (
							<></>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ExtractRolesModal;
