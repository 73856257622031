import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import dompurify from 'dompurify';
import { v4 as uniqueIdGenerator } from 'uuid';
import Dropdown from '../Dropdown';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ExitCriteriaContribution = ({
	contributorsData,
	exitCriteriaButtonClicked,
	setExitCriteriaButtonClicked,
	outputButtonClicked,
	setOutputButtonClicked,
	ckEditorUserMap,
	CkEditorCurrentUser,
	exitCriteriaSuggestions,
	setExitCriteriaSuggestions,
	exitCriteriaWithSuggestions,
	setExitCriteriaWithSuggestions,
	outputSuggestions,
	setOutputSuggestions,
	reviewedExitCriteriaSuggestions,
	setReviewedExitCriteriaSuggestions,
	reviewerExitCriteriaSuggestions,
	source,
	exitCriteriaData,
	setExitCriteriaData,
	currentTab,
	setCurrentTab,
	exitContributionReorder,
	outputContributionReorder,
	setExitContributionReorder,
	setOutputContributionReorder,
	setExitCriteriaReorderArray,
	exitCriteriaReorderArray,
	setOutputCriteriaReorderArray,
	outputCriteriaReorderArray,
	reviewedReorderedExitSuggestions,
	setReviewedReorderedExitSuggestions,
	reviewedReorderedOutputSuggestions,
	setReviewedReorderedOutputSuggestions,
	initialExitIdBeforeReorder,
    setInitialExitIdBeforeReorder,
	ckEditorCurrentExitReorderUsername,
    setCkEditorCurrentExitReorderUsername,
    ckEditorCurrentExitReorderUserImage,
    setCkEditorCurrentExitReorderUserImage,
	ckEditorCurrentOutputReorderUserImage,
	setCkEditorCurrentOutputReorderUserImage,
	ckEditorCurrentOutputReorderUsername,
	setCkEditorCurrentOutputReorderUsername,
	disableExitDrag,
	setDisableExitDrag,
	disableOutputDrag,
	setDisableOutputDrag,
	user,
	setIsReviewerExitReordered,
	setIsReviewerOutputReordered,
	setIsApproverExitReordered,
    setIsApproverOutputReordered,
	setReorderContributionCount,
	setAddedExitSequence,
	setIsOrderChanged
}) => {
	const pageType = ['review', 'approve'];
	const htmlSanitizer = dompurify.sanitize;
	const defaultCriteriType = {
		id: 1,
		name: 'Text',
	};
	const defaultCriteriaTypeValue = {
		2: 'Numeric',
		1: 'Text',
	};
	const dropdownValue = [
		{ id: 1, name: 'Text', type: 'option' },
		{ id: 2, name: 'Numeric', type: 'option' },
	];
	const [criteriaName, setCriteriaName] = useState('');
	const [criteriaDescription, setCriteriaDescription] = useState('');
	const [criteriaType, setCriteriaType] = useState(defaultCriteriType);
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updateId, setUpdateId] = useState(0);
	const [outputEditMode, setOutputEditMode] = useState(false);
	const [outputUpdateId, setOutputUpdateId] = useState(0);
	const [exitCriteriaList, setExitCriteriaList] = useState([
		...contributorsData.exitCriteriaList.filter(
			(item) => item.criteriaTypeName === 'Exit Criteria'
		),
	]);
	const [outputList, setOutputList] = useState([
		...contributorsData.exitCriteriaList.filter(
			(item) => item.criteriaTypeName !== 'Exit Criteria'
		),
	]);
	const [outputDescription, setOutputDescription] = useState('');
	const [toolsSidebarHeight, setToolsSidebarHeight] = useState('239px');
	const [sidebarCard, setSidebarCard] = useState([]);
	const [userImgWithReorder, setUserImgWithReorder] = useState(null)
	const [userNameWithReorder, setUserNameWithReorder] = useState(null)
	const [exitListwithInsert,  setExitListwithInsert] = useState([...contributorsData.exitCriteriaList.filter(
		(item) => item.criteriaTypeName === 'Exit Criteria'
	       ),])
	const [outputListwithInsert,  setOutputListwithInsert] = useState([...contributorsData.exitCriteriaList.filter(
		(item) => item.criteriaTypeName === 'Output'
	      ),
	      ]);
	const [initialExitCriteriaBeforeReorder, setInitialExitCriteriaBeforeReorder]=useState([...initialExitIdBeforeReorder.filter(
			(item)=> JSON.parse(item).criteriaTypeName === 'Exit Criteria'
		  )])
	const [initialOutputCriteriaBeforeReorder, setInitialOutputCriteriaBeforeReorder] = useState([...initialExitIdBeforeReorder.filter(
			(item)=> JSON.parse(item).criteriaTypeName === 'Output')])

	const updateExitCriteria = () => {
		if (criteriaType.id !== 0 && criteriaDescription !== '' && criteriaDescription.trim().length !== 0) {
			if (editMode) {
				if (exitCriteriaList.some((item) => item.id === updateId)) {
					const updatedDtails = exitCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								suggestionType: 'updation',
								suggestionId: uniqueIdGenerator(),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					setExitCriteriaWithSuggestions([...updatedDtails]);
					if (
						!exitCriteriaSuggestions.some(
							(item) => JSON.parse(item).id === updateId
						)
					) {
						const exitCriteria = exitCriteriaList
							.filter((item) => item.id === updateId)
							.map((value) => {
								return JSON.stringify({
									...value,
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: criteriaDescription,
									valueType: criteriaType.name === 'Text' ? 1 : 2,
								});
							});
						setExitCriteriaSuggestions((prevState) => [
							...prevState,
							...exitCriteria,
						]);
					} else {
						const updatedSuggestions = exitCriteriaSuggestions.map((item) =>
							JSON.parse(item).id === updateId
								? JSON.stringify({
									...JSON.parse(item),
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: criteriaDescription,

									valueType: criteriaType.name === 'Text' ? 1 : 2,
								})
								: item
						);
						setExitCriteriaSuggestions([...updatedSuggestions]);
					}
				} else {
					const updatedDtails = exitCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					const updateSuggestions = exitCriteriaSuggestions.map((item) =>
						JSON.parse(item).id === updateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: criteriaDescription,
								valueType: criteriaType.name === 'Text' ? 1 : 2,
							})
							: item
					);
					setExitCriteriaSuggestions([...updateSuggestions]);
					setExitCriteriaWithSuggestions([...updatedDtails]);
				}
				setEditMode(false);
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setUpdateId(0);
				setCriteriaName('');
				setError(false);
			} else {
				const exitCriteria = {
					id: uniqueIdGenerator(),
					suggestionId: uniqueIdGenerator(),
					name: criteriaDescription,
					criteriaType: 'Exit',
					valueType: criteriaType.name === 'Text' ? 1 : 2,
					status: 'new',
					criteriaTypeID: 2,
					criteriaTypeName: 'Exit Criteria',
					suggestionType: 'insertion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
					sequence: exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeID === 2).length + 1
				};
				if(exitCriteriaReorderArray.length !== 0){
					const insertionId =  {id: exitCriteria.id, name: exitCriteria.name}
					setExitCriteriaReorderArray(prev=> [...prev, JSON.stringify(insertionId)])
				  }
				 setExitListwithInsert([...exitListwithInsert, exitCriteria])


				const exitCriteriaJson = JSON.stringify(exitCriteria);
				setAddedExitSequence(prev=>[...prev, {id:JSON.parse(exitCriteriaJson).id,
					sequence:JSON.parse(exitCriteriaJson).sequence,criteriaTypeID:JSON.parse(exitCriteriaJson).criteriaTypeID}])

				setExitCriteriaSuggestions([
					...exitCriteriaSuggestions,
					exitCriteriaJson,
				]);
				setExitCriteriaWithSuggestions([
					...exitCriteriaWithSuggestions,
					exitCriteriaJson,
				]);
				setInitialExitCriteriaBeforeReorder([
					...initialExitCriteriaBeforeReorder,
					exitCriteriaJson,
				]);
				setCriteriaName('');
				setCriteriaDescription('');
				setCriteriaType(defaultCriteriType);
				setError(false);
			}
		} else {
			setError(true);
		}
	}; 

	const updateOutput = () => {
		if (outputDescription !== '' && outputDescription.trim().length !== 0) {
			if (outputEditMode) {
				if (outputList.some((item) => item.id === outputUpdateId)) {
					const updatedDtails = exitCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === outputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								suggestionType: 'updation',
								suggestionId: uniqueIdGenerator(),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: outputDescription,
								valueType: 0,
							})
							: item
					);
					setExitCriteriaWithSuggestions([...updatedDtails]);

					if (
						!exitCriteriaSuggestions.some(
							(item) => JSON.parse(item).id === outputUpdateId
						)
					) {
						const exitCriteria = outputList
							.filter((item) => item.id === outputUpdateId)
							.map((value) => {
								return JSON.stringify({
									...value,
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: outputDescription,
									valueType: 0,
								});
							});
						setExitCriteriaSuggestions((prevState) => [
							...prevState,
							...exitCriteria,
						]);
					} else {
						const updatedSuggestions = exitCriteriaSuggestions.map((item) =>
							JSON.parse(item).id === outputUpdateId
								? JSON.stringify({
									...JSON.parse(item),
									suggestionType: 'updation',
									suggestionId: uniqueIdGenerator(),
									authorId: CkEditorCurrentUser,
									createdAt: new Date(),
									name: outputDescription,
									valueType: 0,
								})
								: item
						);
						setExitCriteriaSuggestions([...updatedSuggestions]);
					}
				} else {
					const updatedDtails = exitCriteriaWithSuggestions.map((item) =>
						JSON.parse(item).id === outputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: outputDescription,
								valueType: 0,
							})
							: item
					);
					const updateSuggestions = exitCriteriaSuggestions.map((item) =>
						JSON.parse(item).id === outputUpdateId
							? JSON.stringify({
								...JSON.parse(item),
								authorId: CkEditorCurrentUser,
								createdAt: new Date(),
								name: outputDescription,
								valueType: 0,
							})
							: item
					);
					setExitCriteriaSuggestions([...updateSuggestions]);
					setExitCriteriaWithSuggestions([...updatedDtails]);
				}
				setOutputEditMode(false);
				setOutputDescription('');
				setOutputUpdateId(0);
				setError(false);
			} else {
				const exitCriteria = {
					id: uniqueIdGenerator(),
					suggestionId: uniqueIdGenerator(),
					name: outputDescription,
					criteriaType: 'Exit',
					valueType: 0,
					status: 'new',
					criteriaTypeID: 4,
					criteriaTypeName: 'Output',
					suggestionType: 'insertion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
					sequence: exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeID === 4).length + 1
				};
				if(outputCriteriaReorderArray.length !== 0){
					const insertionId =  {id: exitCriteria.id, name: exitCriteria.name}
					setOutputCriteriaReorderArray(prev=> [...prev, JSON.stringify(insertionId)])
				  }
				 setOutputListwithInsert([...outputListwithInsert, exitCriteria])

				const exitCriteriaJson = JSON.stringify(exitCriteria);
				setAddedExitSequence(prev=>[...prev, {id:JSON.parse(exitCriteriaJson).id,
					sequence:JSON.parse(exitCriteriaJson).sequence,criteriaTypeID:JSON.parse(exitCriteriaJson).criteriaTypeID}])


				setExitCriteriaSuggestions([
					...exitCriteriaSuggestions,
					exitCriteriaJson,
				]);
				setExitCriteriaWithSuggestions([
					...exitCriteriaWithSuggestions,
					exitCriteriaJson,
				]);
				setInitialOutputCriteriaBeforeReorder([
					...initialOutputCriteriaBeforeReorder,
					exitCriteriaJson,
				])
				setOutputDescription('');
				setError(false);
			}
		} else {
			setError(true);
		}
	};

	const deleteSuggestion = (deleteId, value, setValue) => {

		const filteredValue = value.filter(
			(item) => JSON.parse(item).id !== deleteId
		);
		setValue(filteredValue);
	};

	const deleteOtherDetails = (item) => {
		const exitCriteria = {
			...item,
			suggestionId: uniqueIdGenerator(),
			suggestionType: 'deletion',
			authorId: CkEditorCurrentUser,
			createdAt: new Date(),
		};
		const exitCriteriaJson = JSON.stringify(exitCriteria);

		setExitCriteriaSuggestions([exitCriteriaJson, ...exitCriteriaSuggestions]);

		const updatedSuggestions = exitCriteriaWithSuggestions.map((value) =>
			JSON.parse(value).id !== item.id
				? value
				: JSON.stringify({
					...JSON.parse(value),
					suggestionId: uniqueIdGenerator(),
					suggestionType: 'deletion',
					authorId: CkEditorCurrentUser,
					createdAt: new Date(),
				})
		);
		setExitCriteriaWithSuggestions([...updatedSuggestions]);
	};

	const removeSuggestion = (value) => {
		const filteredValue = exitCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setExitCriteriaSuggestions([...filteredValue]);
		if (
			pageType.includes(source) &&
			JSON.parse(value).authorId !== CkEditorCurrentUser
		) {
			setReviewedExitCriteriaSuggestions([
				...reviewedExitCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(exitContributionReorder && ckEditorCurrentExitReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			   && criteriaType === 'Exit Criteria'&& JSON.parse(value).suggestionType==='insertion'){
				const initialExit = initialExitCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const filteredExit = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Exit Criteria')
				const currentExit = filteredExit.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialExit.some((element, index) => element !== currentExit[index])
				if(!isSimilar){
				  removeReorderedSuggestions('exit');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			  }
			 
		}
		if (
			source === 'approve'
			&& JSON.parse(value).authorId === CkEditorCurrentUser
			&& reviewerExitCriteriaSuggestions?.some((item) => JSON.parse(item).id === JSON.parse(value).id)
		) {
			setReviewedExitCriteriaSuggestions([
				...reviewedExitCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
		}
		if (exitCriteriaList.some((item) => item.id === JSON.parse(value).id)) {
			const updatedList = exitCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...exitCriteriaList.filter(
							(item) => JSON.parse(value).id === item.id
						)[0],
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);

			setExitCriteriaWithSuggestions([...updatedList]);
			setInitialExitIdBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))

		} else {
			const filteredList = exitCriteriaWithSuggestions.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			const initialExitFilteredList = initialExitCriteriaBeforeReorder.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			setExitCriteriaWithSuggestions([...filteredList]);
			setInitialExitCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
            setExitListwithInsert([...initialExitFilteredList.map(e=>JSON.parse(e))])
            setExitCriteriaReorderArray(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
		}
	};

	const acceptSuggestion = (value) => {
		//setExitCriteriaList([...exitCriteriaList, JSON.parse(item)]);
		if (JSON.parse(value).suggestionType === 'deletion') {
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(exitContributionReorder && ckEditorCurrentExitReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			   && criteriaType === 'Exit Criteria'){
				const initialExit = initialExitCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const filteredExit = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Exit Criteria')
				const currentExit = filteredExit.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
				const isSimilar = initialExit.some((element, index) => element !== currentExit[index])
				if(!isSimilar){
				  removeReorderedSuggestions('exit');
				  toast.info('Reorder suggestion has rejected automatically', {
					position: toast.POSITION.TOP_RIGHT,
				  });
				}
			  }
			if (exitCriteriaList.some((item) => item.id === JSON.parse(value).id)) {
				const updatedExitCriteria = exitCriteriaList.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setExitCriteriaList([...updatedExitCriteria]);
				const updatedDataList = exitCriteriaData.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setExitCriteriaData([...updatedDataList]);
				const filteredList = exitCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setExitCriteriaWithSuggestions([...filteredList]);
				setInitialExitCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
				if(exitCriteriaReorderArray !== null){
				  setExitCriteriaReorderArray(prev=>prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
				}
			} else {
				const filteredList = exitCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setExitCriteriaWithSuggestions([...filteredList]);
			}
		} else {
			const updatedList = exitCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...JSON.parse(item),
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);
			setExitCriteriaWithSuggestions([...updatedList]);
			if (!exitCriteriaList.some((item) => item.id === JSON.parse(value).id)) {
				const criteria = {
					id: JSON.parse(value).id,
					name: JSON.parse(value).name,
					criteriaType: JSON.parse(value).criteriaType,
					valueType: JSON.parse(value).valueType,
					createdDate: JSON.parse(value).createdDate,
					createdBy: JSON.parse(value).authorId,
					criteriaTypeID: JSON.parse(value).criteriaTypeID,
					criteriaTypeName: JSON.parse(value).criteriaTypeName,
				};
				let increment = 1;
                const updatedExitData = ([...exitCriteriaData, criteria])
				updatedExitData.forEach((e,i)=>{
					if(e.criteriaTypeID === 2){
						e.sequence = increment;
						increment ++
					} 
				})
				setExitCriteriaData(updatedExitData);
				setExitCriteriaList([...exitCriteriaList, criteria]);
			} else {
				const updatedList = exitCriteriaList.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setExitCriteriaList([...updatedList]);
				const updatedData = exitCriteriaData.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setExitCriteriaData([...updatedData]);
			}
		}

		const filteredValue = exitCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setExitCriteriaSuggestions([...filteredValue]);
		setReviewedExitCriteriaSuggestions([
			...reviewedExitCriteriaSuggestions,
			JSON.stringify({ id: JSON.parse(value).id, state: 'accepted' }),
		]);
	};

	const removeOutputSuggestion = (value) => {
		const filteredValue = exitCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setExitCriteriaSuggestions([...filteredValue]);
		if (
			pageType.includes(source) &&
			JSON.parse(value).authorId !== CkEditorCurrentUser
		) {
			setReviewedExitCriteriaSuggestions([
				...reviewedExitCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(outputContributionReorder && ckEditorCurrentOutputReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			&& criteriaType === 'Output'&& JSON.parse(value).suggestionType==='insertion'){
		   const initialOutput = initialOutputCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
		   const filteredOutput = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Output')
		   const currentOutput = filteredOutput.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
		   const isSimilar = initialOutput.some((element, index) => element !== currentOutput[index])
		   if(!isSimilar){
			 removeReorderedSuggestions('output');
			 toast.info('Reorder suggestion has rejected automatically', {
				position: toast.POSITION.TOP_RIGHT,
			  });
		   }
		 }
		}
		if (
			source === 'approve'
			&& JSON.parse(value).authorId === CkEditorCurrentUser
			&& reviewerExitCriteriaSuggestions?.some((item) => JSON.parse(item).id === JSON.parse(value).id)
		) {
			setReviewedExitCriteriaSuggestions([
				...reviewedExitCriteriaSuggestions,
				JSON.stringify({ id: JSON.parse(value).id, state: 'rejected' }),
			]);
		}
		if (outputList.some((item) => item.id === JSON.parse(value).id)) {
			const updatedList = exitCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...outputList.filter(
							(item) => JSON.parse(value).id === item.id
						)[0],
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);

			setExitCriteriaWithSuggestions([...updatedList]);
		} else {
			const filteredList = exitCriteriaWithSuggestions.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			const initialOutpuFilteredList = initialOutputCriteriaBeforeReorder.filter(
				(item) => JSON.parse(item).id !== JSON.parse(value).id
			);
			setExitCriteriaWithSuggestions([...filteredList]);
			setInitialOutputCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
			setOutputListwithInsert([...initialOutpuFilteredList.map(e=>JSON.parse(e))])
			setOutputCriteriaReorderArray(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
		}
	};

	const acceptOutputSuggestion = (value) => {
		if (JSON.parse(value).suggestionType === 'deletion') {
			const criteriaType = JSON.parse(value).criteriaTypeName;
			if(outputContributionReorder && ckEditorCurrentOutputReorderUsername !== user.profile.firstName + ' '+user.profile.surname 
			&& criteriaType === 'Output'){
		   const initialOutput = initialOutputCriteriaBeforeReorder.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
		   const filteredOutput = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName === 'Output')
		   const currentOutput = filteredOutput.filter(e=> JSON.parse(e).id !== JSON.parse(value).id).map(e=>JSON.parse(e).id)
		   const isSimilar = initialOutput.some((element, index) => element !== currentOutput[index])
		   if(!isSimilar){
			 removeReorderedSuggestions('output');
			 toast.info('Reorder suggestion has rejected automatically', {
				position: toast.POSITION.TOP_RIGHT,
			  });
		   }
		 }
			if (outputList.some((item) => item.id === JSON.parse(value).id)) {
				const updatedExitCriteria = outputList.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setOutputList([...updatedExitCriteria]);
				const updatedDataList = exitCriteriaData.filter(
					(item) => item.id !== JSON.parse(value).id
				);
				setExitCriteriaData([...updatedDataList]);
				const filteredList = exitCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setExitCriteriaWithSuggestions([...filteredList]);
				setInitialOutputCriteriaBeforeReorder(prev=> prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
                if(outputCriteriaReorderArray !== null){
                setOutputCriteriaReorderArray(prev=>prev.filter(e=>JSON.parse(e).id !== JSON.parse(value).id))
				}
			} else {
				const filteredList = exitCriteriaWithSuggestions.filter(
					(item) => JSON.parse(item).id !== JSON.parse(value).id
				);
				setExitCriteriaWithSuggestions([...filteredList]);
			}
		} else {
			const updatedList = exitCriteriaWithSuggestions.map((item) =>
				JSON.parse(item).id === JSON.parse(value).id
					? JSON.stringify({
						...JSON.parse(item),
						suggestionType: 'none',
						suggestionId: null,
						authorId: CkEditorCurrentUser,
						createdAt: new Date(),
					})
					: item
			);
			setExitCriteriaWithSuggestions([...updatedList]);
			if (!outputList.some((item) => item.id === JSON.parse(value).id)) {
				const criteria = {
					id: JSON.parse(value).id,
					name: JSON.parse(value).name,
					criteriaType: JSON.parse(value).criteriaType,
					valueType: JSON.parse(value).valueType,
					createdDate: JSON.parse(value).createdDate,
					createdBy: JSON.parse(value).authorId,
					criteriaTypeID: JSON.parse(value).criteriaTypeID,
					criteriaTypeName: JSON.parse(value).criteriaTypeName,
				};
				let increment = 1;
                const updatedOutputData = ([...exitCriteriaData, criteria])
				updatedOutputData.forEach((e,i)=>{
					if(e.criteriaTypeID === 4){
						e.sequence = increment;
						increment ++
					} 
				})
				setExitCriteriaData(updatedOutputData);
				setOutputList([...outputList, criteria]);
			} else {
				const updatedList = outputList.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setOutputList([...updatedList]);
				const updatedData = exitCriteriaData.map((item) =>
					item.id === JSON.parse(value).id ? JSON.parse(value) : item
				);
				setExitCriteriaData([...updatedData]);
			}
		}

		const filteredValue = exitCriteriaSuggestions.filter(
			(item) => JSON.parse(item).id !== JSON.parse(value).id
		);
		setExitCriteriaSuggestions([...filteredValue]);
		setReviewedExitCriteriaSuggestions([
			...reviewedExitCriteriaSuggestions,
			JSON.stringify({ id: JSON.parse(value).id, state: 'accepted' }),
		]);
	};

	const handleCriteriaDescription = (event) => {
		setCriteriaDescription(event.target.value);
	};

	const handleOutputDescription = (event) => {
		setOutputDescription(event.target.value);
	};

	$(document).ready(function () {
		$('ul.exit-criteria-tabs li').click(function () {
			var tab_id = $(this).attr('data-tab');
			setCurrentTab(tab_id);
			$('ul.exit-criteria-tabs li').removeClass('currentExit');
			$('.exit-criteria-tab-content').removeClass('currentExit');

			$(this).addClass('currentExit');
			$('#' + tab_id).addClass('currentExit');
		});
	});
	const removeReorderedSuggestions = (field) => {
		const parsedTasks = exitCriteriaWithSuggestions.map((item) => {
			return JSON.parse(item)
		})
		if (field === 'exit') {
			const filteredExitParsed = parsedTasks.filter((item) => {
				return item.criteriaTypeName === "Exit Criteria"
			})
			//Creating this for Concatination. Parse Not required
	        const  filteredOutputParsed = exitCriteriaWithSuggestions.filter((item) => {
				return JSON.parse(item).criteriaTypeName === "Output"
			})
			const parsedInitialExitCriteriaBeforeReorder =initialExitCriteriaBeforeReorder.map(e=>JSON.parse(e))
			const parsedArray = filteredExitParsed.map((item) => {
				return JSON.stringify({
					...item,
					sequence: parsedInitialExitCriteriaBeforeReorder.filter((v) => v.id === item.id)[0].sequence,
					authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				})
			})
			const beforeSortingArray = parsedArray.map((item) => {
				return JSON.parse(item)
			})
			beforeSortingArray.sort((a, b) => a.sequence - b.sequence)
			const afterSortAndStringify = beforeSortingArray.map((item) => {
				return JSON.stringify(item)
			})
			setExitCriteriaWithSuggestions([...afterSortAndStringify.concat(filteredOutputParsed)]);
			setExitContributionReorder(false)
			setExitCriteriaReorderArray([])
			if(source === 'review'){
				setIsReviewerExitReordered(false)
			}
			if(source === 'approve'){
				setIsApproverExitReordered(false)
			}
			if (
				pageType.includes(source)  && ckEditorCurrentExitReorderUsername !== user.profile.firstName + ' '+user.profile.surname
				
			  ) {
				setReviewedReorderedExitSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'rejected',name:'Exit' })])
				setReorderContributionCount((prev)=> prev - 1)
			}
			setDisableExitDrag(false)
		}
		else {
			const filteredOutputParsed = parsedTasks.filter((item) => {
				return item.criteriaTypeName === "Output"
			})
			//Creating this for Concatination. Parse Not required

			const  filteredExitParsed = exitCriteriaWithSuggestions.filter((item) => {
				return JSON.parse(item).criteriaTypeName === "Exit Criteria"
			})
			const parsedInitialOutputCriteriaBeforeReorder = initialOutputCriteriaBeforeReorder.map(e=>JSON.parse(e))
			const parsedArray = filteredOutputParsed.map((item) => {
				return JSON.stringify({
					...item,
					sequence: parsedInitialOutputCriteriaBeforeReorder.filter((v) => v.id === item.id)[0].sequence,
					authorId: item.authorId !== CkEditorCurrentUser ? item.authorId :CkEditorCurrentUser,
					createdAt: new Date(),
				})
			})
			const beforeSortingArray = parsedArray.map((item) => {
				return JSON.parse(item)
			})
			beforeSortingArray.sort((a, b) => a.sequence - b.sequence)
			const afterSortAndStringify = beforeSortingArray.map((item) => {
				return JSON.stringify(item)
			})
			setExitCriteriaWithSuggestions([...filteredExitParsed.concat(afterSortAndStringify)]);
			setOutputContributionReorder(false)
			setOutputCriteriaReorderArray([])
			if(source === 'review'){
				setIsReviewerOutputReordered(false)
			}
			if(source === 'approve'){
				setIsApproverOutputReordered(false)
			}
			if (
				pageType.includes(source)  && ckEditorCurrentOutputReorderUsername !== user.profile.firstName + ' '+user.profile.surname
			  ) {
				setReviewedReorderedOutputSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'rejected',name:'Output' })])
				setReorderContributionCount((prev)=> prev - 1)
			}
			setDisableOutputDrag(false)
		}

	}

	useEffect(()=>{
		const currentExitCriteria = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Exit Criteria').map(e=>JSON.parse(e).id)

		const currentOutputCriteria = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Output').map(e=>JSON.parse(e).id)
		const arraysAreEqual =(arr1, arr2) => {
			if(!arr1 && !arr2){
				return false
			}
			const initial = arr1.map((e) => JSON.parse(e).id);
			if (arr1.length !== initial.length) {
			  return false;
			}
		  return initial.some((element, index) => element !== arr2[index]);
		  }
		  
        const exitHasChanged = arraysAreEqual(initialExitCriteriaBeforeReorder, currentExitCriteria)
		
		const outputHasChanged = arraysAreEqual(initialOutputCriteriaBeforeReorder, currentOutputCriteria)
	
		setExitContributionReorder(exitHasChanged)
		setIsOrderChanged(prevState => ({
			...prevState,
			exit: exitHasChanged 
		  }))
		setOutputContributionReorder(outputHasChanged)
		setIsOrderChanged(prevState => ({
			...prevState,
			output: outputHasChanged 
		  }))
	},[exitCriteriaWithSuggestions])

	const onDragEnd = (result, field) => {
		if (!result.destination) return;

		const items = Array.from(exitCriteriaWithSuggestions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		let i = 1;
		let j = 1;
		let author;
		const exitArray = [];
		const outputArray = [];
		const finalArray = items.map((item) => {

			const parsedItem = JSON.parse(item)
			author = parsedItem.authorId

			if (parsedItem.criteriaTypeName === "Exit Criteria") {
				parsedItem.sequence = j;
				j++;
				exitArray.push({ id: parsedItem.id,name:parsedItem.name,authorId: [user.profile.firstName + ' '+user.profile.surname,user.profile.photo],
					createdAt: new Date() })                              
			} else {
				parsedItem.sequence = i;
				i++;
				outputArray.push({ id: parsedItem.id,name:parsedItem.name,authorId: [user.profile.firstName + ' '+user.profile.surname,user.profile.photo],
					createdAt: new Date() })

			}
			return JSON.stringify(parsedItem)
		})
		const exitArrayString = exitArray.map(e => JSON.stringify(e))
		const outputArrayString = outputArray.map(e => JSON.stringify(e))
		if (field === 'exit') {
			setExitCriteriaReorderArray(exitArrayString)
			setCkEditorCurrentExitReorderUserImage(user.profile.photo)
			setCkEditorCurrentExitReorderUsername(user.profile.firstName + ' '+user.profile.surname)
			if(source === 'review'){
				setIsReviewerExitReordered(true)
			}
			if(source === 'approve'){
				setIsApproverExitReordered(true)
			}
			
		} else {
			setOutputCriteriaReorderArray(outputArrayString)
			setCkEditorCurrentOutputReorderUserImage (user.profile.photo)
			setCkEditorCurrentOutputReorderUsername(user.profile.firstName + ' '+user.profile.surname)
			if(source === 'review'){
				setIsReviewerOutputReordered(true)
			}
			if(source === 'approve'){
				setIsApproverOutputReordered(true)
			}
		}
		
		setExitCriteriaWithSuggestions(finalArray);
	};

	const acceptExitReorderSuggestion=()=>{
		const isNotCurrentUser = exitCriteriaSuggestions.some(e=>JSON.parse(e).authorId !== CkEditorCurrentUser )
		
			if(exitCriteriaSuggestions && exitCriteriaSuggestions.length !==0 && isNotCurrentUser){
        toast.error('Please accept or reject other Exit/Output suggestions before accepting reorder', {
        position: toast.POSITION.TOP_RIGHT,
      })
	 }  else {
		setReviewedReorderedExitSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'accepted',name:'Exit' })])
		setExitContributionReorder(false);
		const currentExitCriteria = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Exit Criteria')

		setInitialExitCriteriaBeforeReorder(currentExitCriteria)
		setReorderContributionCount((prev)=> prev - 1)
	   }
	}
	
	   const acceptOutputReorderSuggestion=()=>{
		const isNotCurrentUser = exitCriteriaSuggestions.some(e=>JSON.parse(e).authorId !== CkEditorCurrentUser )
		if(exitCriteriaSuggestions && exitCriteriaSuggestions.length !==0 && isNotCurrentUser){
        toast.error('Please accept or reject other Exit/Output suggestions before accepting reorder', {
        position: toast.POSITION.TOP_RIGHT,
      })
	 }  else {
		setReviewedReorderedOutputSuggestions([JSON.stringify({ id: uniqueIdGenerator(), state: 'accepted',name:'Output' })])
		setOutputContributionReorder(false);
		const currentOutputCriteria = exitCriteriaWithSuggestions.filter(e=>JSON.parse(e).criteriaTypeName
		=== 'Output')

		setInitialOutputCriteriaBeforeReorder(currentOutputCriteria)
		setReorderContributionCount((prev)=> prev - 1)
	   }
	}


	const renderSuggestions = () => {
		return (
			<div
				className={`toolsSidebar ${currentTab === 'tab-exit' ? '' : 'hiddenExitCriteria'
					} `}
				style={{ height: toolsSidebarHeight }}
			>    
			{
					exitContributionReorder && <>
						<div className='sideBarCard userAddedItem'>
							<div className="sideBarCardHeader">
								<img
									className="userImg"
									src={ckEditorCurrentExitReorderUserImage}
									alt=""
								/>
								<span>{ckEditorCurrentExitReorderUsername}</span>
								<Icon
									title="Reject Suggestion"
									ariaLabel={`Click to reject the Reorder`}
									role="button"
									iconName="Cancel"
									onClick={() => removeReorderedSuggestions('exit')}
									onKeyPress={(e) => {
										if (e.which === 13) {
											removeReorderedSuggestions('exit');
										}
									}}
									tabIndex={0}
								/>
								  {pageType.includes(source) && 
								  ckEditorCurrentExitReorderUsername !== user.profile.firstName + ' '+user.profile.surname &&
                               (
                               <Icon
                        title="Accept Suggestion"
                        iconName="Accept"
                        onClick={() => acceptExitReorderSuggestion()}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            acceptExitReorderSuggestion();
                          }
                        }}
                        ariaLabel={'click to accept as suggestion'}
                        role="button"
                        tabIndex={0}
                      />
                    
                    )}
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description: Exit Criteria has been Reordered"
								</div>

							</div>
						</div>
					</>
				}
				
				{exitCriteriaSuggestions &&
					exitCriteriaWithSuggestions.map((card) => (
						<>
							{JSON.parse(card).criteriaTypeName === 'Exit Criteria' &&
								(JSON.parse(card).suggestionType === 'none' ? (
									<></>
								) : (
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>
												{ckEditorUserMap[JSON.parse(card).authorId][0]}
											</span>

											{JSON.parse(card).authorId === CkEditorCurrentUser ||
												(pageType.includes(source) &&
													JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
												<Icon
													title="Reject Suggestion"
													ariaLabel={`Click to reject ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													iconName="Cancel"
													onClick={() => removeSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeSuggestion(card);
														}
													}}
													// onClick={() => removeTemplate(JSON.parse(card))}
													// onKeyPress={(e) => {
													// 	if (e.which === 13) {
													// 		removeTemplate(JSON.parse(card));
													// 	}
													// }}
													tabIndex={0}
												/>
											) : (
												<></>
											)}
											{pageType.includes(source) &&
												JSON.parse(card).authorId !== CkEditorCurrentUser ? (
												<Icon
													title="Accept Suggestion"
													iconName="Accept"
													onClick={() => acceptSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															acceptSuggestion(card);
														}
													}}
													ariaLabel={`Click to Accept ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													tabIndex={0}
												/>
											) : (
												<></>
											)}
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'insertion'
													? 'Add'
													: JSON.parse(card).suggestionType === 'deletion'
														? 'Remove'
														: 'Edit'}
											</span>
											&nbsp;"Text/Numeric:
											{JSON.parse(card).valueType === 1
												? 'Text'
												: 'Numeric'}{' '}
											Description:{JSON.parse(card).name}"
										</div>
									</div>
								))}
						</>
					))}
			</div>
		);
	};
   
	const renderOutputSuggestions = () => {
		return (
			<div
				className={`toolsSidebar ${currentTab === 'tab-exit' ? 'hiddenExitCriteria' : ''
					} `}
				style={{ height: toolsSidebarHeight }}
			>
				{
					outputContributionReorder && <>
						<div className="sideBarCard userAddedItem">
							<div className="sideBarCardHeader">
								<img
									className="userImg"
									src={ckEditorCurrentOutputReorderUserImage}
									alt=""
								/>
								<span>{ckEditorCurrentOutputReorderUsername}</span>
								<Icon
									title="Reject Suggestion"
									ariaLabel={`Click to reject the Reorder`}
									role="button"
									iconName="Cancel"
									onClick={() => removeReorderedSuggestions('output')}
									onKeyPress={(e) => {
										if (e.which === 13) {
											removeReorderedSuggestions('output');
										}
									}}
									tabIndex={0}
								/>
								 {pageType.includes(source) &&
								 ckEditorCurrentOutputReorderUsername !== user.profile.firstName + ' '+ user.profile.surname &&
                                 (
                                        <Icon
                                  title="Accept Suggestion"
                                  iconName="Accept"
                                  onClick={() => acceptOutputReorderSuggestion()}
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
										acceptOutputReorderSuggestion();
                                    }
                                  }}
                                  ariaLabel={'click to accept as suggestion'}
                                  role="button"
                                  tabIndex={0}
                                />
                              
                              )}
								<div className="itemStatus">
									<span>
										Reordered
									</span>
									&nbsp;"Description: Output has been Reordered"
								</div>

							</div>
						</div>

					</>
				}
				{exitCriteriaSuggestions &&
					exitCriteriaWithSuggestions.map((card) => (
						<>
							{JSON.parse(card).criteriaTypeName === 'Output' &&
								(JSON.parse(card).suggestionType === 'none' ? (
									<></>
								) : (
									<div
										className={`sideBarCard ${JSON.parse(card).suggestionType === 'deletion'
											? 'userDeletedToolsAndGuidance'
											: 'userAddedItem'
											}`}
									>
										<div className="sideBarCardHeader">
											<img
												className="userImg"
												src={ckEditorUserMap[JSON.parse(card).authorId][1]}
												alt=""
											/>
											<span>
												{ckEditorUserMap[JSON.parse(card).authorId][0]}
											</span>

											{JSON.parse(card).authorId === CkEditorCurrentUser ||
												(pageType.includes(source) &&
													JSON.parse(card).authorId !== CkEditorCurrentUser) ? (
												<Icon
													title="Reject Suggestion"
													ariaLabel={`Click to reject ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													iconName="Cancel"
													onClick={() => removeOutputSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeOutputSuggestion(card);
														}
													}}
													tabIndex={0}
												/>
											) : (
												<></>
											)}
											{pageType.includes(source) &&
												JSON.parse(card).authorId !== CkEditorCurrentUser ? (
												<Icon
													title="Accept Suggestion"
													iconName="Accept"
													onClick={() => acceptOutputSuggestion(card)}
													onKeyPress={(e) => {
														if (e.which === 13) {
															acceptOutputSuggestion(card);
														}
													}}
													ariaLabel={`Click to Accept ${JSON.parse(card).name
														} as suggestion`}
													role="button"
													tabIndex={0}
												/>
											) : (
												<></>
											)}
										</div>
										<div className="itemStatus">
											<span>
												{JSON.parse(card).suggestionType === 'insertion'
													? 'Add'
													: JSON.parse(card).suggestionType === 'deletion'
														? 'Remove'
														: 'Edit'}
											</span>
											&nbsp;"
											{JSON.parse(card).name}"
										</div>
									</div>
								))}
						</>
					))}
			</div>
		);
	};

	

	return (
		<>
			<div className="topicDetailsCkEditor entryExitCriTab">
				<div className="criteria-tab-container">
					{error && (
						<span className="errorMsg">Type and Description are required</span>
					)}
					<ul className="exit-criteria-tabs" role="tablist">
						<li
							className={`tab-link ${currentTab === 'tab-exit' ? 'currentExit' : ''}`}
							data-tab="tab-exit"
							tabIndex={0}
							role="tab"
							aria-selected={currentTab === 'tab-exit'}
							aria-label="Exit Criteria"
						>
							Exit Criteria
						</li>
						<li
							className={`tab-link ${currentTab === 'tab-output' ? 'currentExit' : ''}`}
							data-tab="tab-output"
							tabIndex={0}
							role="tab"
							aria-selected={currentTab === 'tab-output'}
							aria-label="Output"
						>
							Output
						</li>
					</ul>

					<div id="tab-exit" className="exit-criteria-tab-content currentExit">
						<div
							className={
								exitCriteriaButtonClicked
									? 'add-details '
									: 'add-details hiddenExitCriteria'
							}
						>
							<Dropdown
								className="exitCriteriadropdown"
								data={dropdownValue}
								value={criteriaType}
								setValue={setCriteriaType}
								enableDropdown={true}
							/>
							<span><div class="tooltipInfoContribution"><Icon className="infoIcon" iconName="Info" />
								<span class="tooltiptextContribution">Select "numeric" for quantifiable exit criteria/output; Select "text" for everything else.</span>
							</div></span>
							<input
								type="text"
								placeholder="Enter Description"
								className="add-description"
								value={criteriaDescription}
								onChange={(e) => handleCriteriaDescription(e)}
							></input>
							<button
								className="add-button"
								onClick={() => {
									updateExitCriteria();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										updateExitCriteria();
									}
								}}
							>
								Add{' '}
								<Icon className="add-icon" iconName="BoxAdditionSolid"></Icon>
							</button>
							<button
								className="add-button"
								onClick={() => {
									setExitCriteriaButtonClicked(false);
									setCriteriaDescription('');
									setCriteriaName('');
									setCriteriaType(defaultCriteriType);
									setError(false);
									setUpdateId(0);
									setEditMode(false);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setExitCriteriaButtonClicked(false);
										setCriteriaDescription('');
										setCriteriaName('');
										setCriteriaType(defaultCriteriType);
										setError(false);
										setUpdateId(0);
										setEditMode(false);
									}
								}}
							>
								Cancel
							</button>
						</div>

						<div className="entry-cri-main-container">
							<DragDropContext onDragEnd={result => onDragEnd(result, 'exit')}>
								<div className="table-container">
									<div className="table-row heading">
										<div className="row-item w15">Text/Numeric</div>
										<div className="row-item w75">Description</div>
										<div className="row-item w10">Edit/Delete</div>
									</div>
									<Droppable droppableId='ExitCriteriaContributionList'>
										{
											(provided) => (
												<div ref={provided.innerRef} {...provided.droppableProps}>
													{exitCriteriaWithSuggestions.map((value, index) => (
														<>
															<Draggable key={value} draggableId={value} index={index}isDragDisabled={disableExitDrag}>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																	>
																		{JSON.parse(value).criteriaTypeName === 'Exit Criteria' && (
																			<div
																				className={`table-row  ${JSON.parse(value).suggestionType === 'none'
																					? ``
																					: JSON.parse(value).suggestionType ===
																						'insertion' ||
																						JSON.parse(value).suggestionType === 'updation'
																						? `addedCriteria`
																						: `deletedCriteria`
																					}`}
																			>
																				<div className="row-item w15">
																					<div className="exitNameAndTypeDiv">
																						<div className="criteriaType">
																							<span key={JSON.parse(value).id} />
																							{JSON.parse(value).valueType === 1
																								? 'Text'
																								: 'Numeric'}
																						</div>
																					</div>
																				</div>
																				<div className="row-item w75">
																					<div
																						className="descEn"
																						id="exitCriteria"
																						dangerouslySetInnerHTML={{
																							__html: htmlSanitizer(JSON.parse(value).name),
																						}}
																					/>
																				</div>

																				<div className="row-item edit-delete-column w10">
																					<Icon
																					    title='Edit'
																						tabIndex={0}
																						aria-label='Edit criteria'
																						iconName="EditSolid12"
																						className="edit-icon"
																						onClick={() => {
																							setCriteriaName(
																								htmlSanitizer(JSON.parse(value).name)
																							);
																							setCriteriaDescription(
																								htmlSanitizer(JSON.parse(value).name)
																							);
																							setCriteriaType({
																								id: JSON.parse(value).valueType,
																								name: defaultCriteriaTypeValue[
																									JSON.parse(value).valueType
																								],
																								type: 'option',
																							});
																							setEditMode(true);
																							setUpdateId(JSON.parse(value).id);
																							setError(false);
																							setExitCriteriaButtonClicked(true);
																						}}
																						onKeyDown={(e)=>{
																							if(e.key === 'Enter' || e.key === ' '){
																								setCriteriaName(
																									htmlSanitizer(JSON.parse(value).name)
																								);
																								setCriteriaDescription(
																									htmlSanitizer(JSON.parse(value).name)
																								);
																								setCriteriaType({
																									id: JSON.parse(value).valueType,
																									name: defaultCriteriaTypeValue[
																										JSON.parse(value).valueType
																									],
																									type: 'option',
																								});
																								setEditMode(true);
																								setUpdateId(JSON.parse(value).id);
																								setError(false);
																								setExitCriteriaButtonClicked(true);
																							}
																						}}
																					/>
																					{JSON.parse(value).suggestionType !== 'insertion' ? (
																						<Icon
																							title="DeleteExit"
																							iconName="Cancel"
																							className="cancel-icon"
																							onClick={() => {
																								deleteOtherDetails(JSON.parse(value));
																								setEditMode(false);
																								setCriteriaDescription('');
																								setCriteriaType(defaultCriteriType);
																								setError(false);
																								setUpdateId(0);
																							}}
																							tabIndex={0}
																						/>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		)}
																	</div>
																)}
															</Draggable>

														</>
													))}
													{provided.placeholder}
												</div>
											)
										}
									</Droppable>



									{/* {renderExitCriteriaSuggestions()} */}
								</div>
							</DragDropContext>
						</div>
					</div>
					<div id="tab-output" className="exit-criteria-tab-content">
						<div
							className={
								exitCriteriaButtonClicked
									? 'add-details '
									: 'add-details hiddenExitCriteria'
							}
						>
							<input
								type="text"
								placeholder="Enter Description"
								className="add-description"
								value={outputDescription}
								onChange={(e) => handleOutputDescription(e)}
							></input>
							<button
								className="add-button"
								onClick={() => {
									updateOutput();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										updateOutput();
									}
								}}
							>
								Add{' '}
								<Icon className="add-icon" iconName="BoxAdditionSolid"></Icon>
							</button>

							<button
								className="add-button"
								onClick={() => {
									setExitCriteriaButtonClicked(false);
									setOutputDescription('');
									setError(false);
									setOutputUpdateId(0);
									setEditMode(false);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setExitCriteriaButtonClicked(false);
										setOutputDescription('');
										setError(false);
										setOutputUpdateId(0);
										setOutputEditMode(false);
									}
								}}
							>
								Cancel
							</button>
						</div>
						<div className="entry-cri-main-container">
							<DragDropContext onDragEnd={result => onDragEnd(result, 'output')}>
								<div className="table-container">
									<div className="table-row heading">
										<div className="row-item w90">Description</div>
										<div className="row-item w10">Edit/Delete</div>
									</div>
									<Droppable droppableId='OutputListContribution'>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.droppableProps}>
												{exitCriteriaWithSuggestions.map((value, index) => (
													<>
														<Draggable draggableId={value} index={index} key={value} isDragDisabled={disableOutputDrag}>
															{(provided) => (
																<div
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	ref={provided.innerRef}
																>
																	{JSON.parse(value).criteriaTypeName === 'Output' && (
																		<div
																			className={`table-row  ${JSON.parse(value).suggestionType === 'none'
																				? ``
																				: JSON.parse(value).suggestionType ===
																					'insertion' ||
																					JSON.parse(value).suggestionType === 'updation'
																					? `addedCriteria`
																					: `deletedCriteria`
																				}`}
																		>
																			<div className="row-item w90">
																				<div
																					className="descEn"
																					id="exitCriteria"
																					dangerouslySetInnerHTML={{
																						__html: htmlSanitizer(JSON.parse(value).name),
																					}}
																				/>
																			</div>

																			<div className="row-item edit-delete-column w10">
																				<Icon
																					iconName="EditSolid12"
																					className="edit-icon"
																					onClick={() => {
																						setOutputDescription(
																							htmlSanitizer(JSON.parse(value).name)
																						);
																						setOutputEditMode(true);
																						setOutputUpdateId(JSON.parse(value).id);
																						setError(false);
																						setExitCriteriaButtonClicked(true);
																					}}
																				/>
																				{JSON.parse(value).suggestionType !== 'insertion' ? (
																					<Icon
																						title="DeleteExit"
																						iconName="Cancel"
																						className="cancel-icon"
																						onClick={() => {
																							deleteOtherDetails(JSON.parse(value));
																							setOutputEditMode(false);
																							setOutputDescription('');
																							setError(false);
																							setOutputUpdateId(0);
																						}}
																						tabIndex={0}
																					/>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	)}
																</div>
															)}
														</Draggable>

													</>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

								</div>
							</DragDropContext>
						</div>
						{/* <textarea rows="7" cols="80"></textarea> */}
					</div>
				</div>
				{renderSuggestions()}
				{renderOutputSuggestions()}
			</div>
		</>
	);
};

export default ExitCriteriaContribution;
