import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getOtherUserProfile } from '../../helpers/GraphService';

import Spinner from '../../helpers/Spinner';

function DiscardPopUp({ lockEmailId, url }) {
	const [userDetails, setUserDetails] = useState({});
	const [showSpinner, setShowSpinner] = useState(true);

	const getUserDetails = async () => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, lockEmailId);
		setUserDetails({
			id: userData.mail,
			name: userData.firstName + ' ' + userData.surname,
			image: userData.photo,
		});
		setShowSpinner(false);
	};
	useEffect(() => {
		getUserDetails();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="activityDiscardPopUp"
					show={true}
					onHide={() => {
						// don't do anything
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						<p className="lockMessage">
							Looks like{' '}
							<a
								target="_blank"
								href={`https://teams.microsoft.com/l/chat/0/0?users=${lockEmailId}`}
							>
								{userDetails.name}
							</a>{' '}
							is already doing changes on this page. Please come back later or
							send a message.
						</p>

						<div className="discardPopUpActivityButtons">
							<Link aria-label="Discard PopUp Yes" to={url}>
								<Button
									title="Yes"
									tabIndex={-1}
									className="activityDiscardPopUpAccept"
									variant="info"
								>
									OK
								</Button>
							</Link>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default DiscardPopUp;
