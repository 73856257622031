import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

const MultiSelectDropdownWithAll = ({
	className,
	enableDropdown,
	data,
	value,
	setValue,
}) => {
	const [visible, setVisible] = useState(false);
	const [allSelected, setAllSelected] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const handleAllSelected = () => {
		setValue(!allSelected ? [...data] : []);
		setAllSelected(!allSelected);
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		const filteredData = value.filter(({ id }) => id === item.id);
		if (filteredData.length > 0) {
			const updatedData = value.filter(({ id }) => id !== item.id);
			setValue(updatedData);
		} else {
			setValue([...value, item]);
		}
		if (allSelected) {
			setAllSelected(false);
		}
	};

	const findSelectedItem = (item) => {
		const filteredData = value.filter(({ id }) => id === item.id);
		if (filteredData.length) {
			return true;
		}
		return false;
	};

	return (
		<div title={enableDropdown ? className : ''} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div
					className="dropDownBox"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				>
					<span className="wrapHeader">
						{value.length === 0
							? `Select`
							: allSelected === true
							? `All`
							: value.length === 1
							? `${value[0].name}`
							: `Selected - ${value.length}`}
					</span>
					<Icon iconName="ChevronDown" />
				</div>
				<div>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							<li title={'All'} key={'All'}>
								<label className="NotificationChkboxContainer">
									<input
										name={'All'}
										type="checkbox"
										checked={allSelected}
										onChange={() => handleAllSelected()}
									/>
									<span className="CustomCheckmark"></span>
									<span className="complexityLabel">{'All'}</span>
								</label>
							</li>
							{data.map((item) => {
								return (
									<li title={item.name} key={item.id}>
										<label className="NotificationChkboxContainer">
											<input
												name={item.name}
												type="checkbox"
												checked={findSelectedItem(item)}
												onChange={() => handleDropdown(item)}
											/>
											<span className="CustomCheckmark"></span>
											<span className="complexityLabel">{item.name}</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdownWithAll;
