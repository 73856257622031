import dompurify from 'dompurify';

export const HtmlSanitizer = (data) => {
	const config = {
		ADD_TAGS: ['iframe', 'oembed'],
		ADD_ATTR: ['url', 'allowfullscreen', 'target'],
		USE_PROFILES: { html: true, svg: true, svgFilters: true },
	};
	return dompurify.sanitize(data, config);
};
