import React, { useState, useEffect } from 'react';
import { useAxios } from '../../context/AxiosContext';

function NotificationSettings({ setNotificationSettings }) {
	const { axiosGetService } = useAxios();
	const [selectedTab, setSelectedTab] = useState('Contributions');
	const NotificationSettingsTab = ['Contributions', 'Reviews / Approvals'];
	const [settings, setSettings] = useState({
		ActivityReview: {
			itemTypeName: 'Activity',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 1,
		},
		ActivityApprove: {
			itemTypeName: 'Activity',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 2,
		},
		MyContributions: {
			itemTypeName: 'MyContributions',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 3,
		},
		TopicReview: {
			itemTypeName: 'Topic',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 1,
		},
		TopicApprove: {
			itemTypeName: 'Topic',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 2,
		},

		TemplateReview: {
			itemTypeName: 'Template',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 1,
		},
		TemplateApprove: {
			itemTypeName: 'Template',
			notifyEmailEach: false,
			notifyEmailDigest: false,
			eventId: 2,
		},

		SampleTemplate: {
			itemTypeName: 'SampleTemplate',
			notifyEmailEach: true,
			notifyEmailDigest: true,
			eventId: 1,
		},
	});

	const getNotificationSettings = async () => {
		await axiosGetService('api/sdmnotifications/getNotificationSettings')
			.then((response) => {
				const newSettings = {};
				response.data.map((artifact) => {
					const name = artifact.itemTypeName;
					if (artifact.eventId === 1) {
						newSettings[`${name}Review`] = {
							itemTypeName: artifact.itemTypeName,
							notifyEmailEach: artifact.notifyEmailEach,
							notifyEmailDigest: artifact.notifyEmailDigest,
							notifyUI: artifact.notifyUI,
							eventId: artifact.eventId,
						};
					} else if (artifact.eventId === 2) {
						newSettings[`${name}Approve`] = {
							itemTypeName: artifact.itemTypeName,
							notifyEmailEach: artifact.notifyEmailEach,
							notifyEmailDigest: artifact.notifyEmailDigest,
							notifyUI: artifact.notifyUI,
							eventId: artifact.eventId,
						};
					} else {
						newSettings[`MyContributions`] = {
							itemTypeName: 'MyContributions',
							notifyEmailEach: artifact.notifyEmailEach,
							notifyEmailDigest: artifact.notifyEmailDigest,
							notifyUI: artifact.notifyUI,
							eventId: artifact.eventId,
						};
					}
				});
				return newSettings;
			})
			.then((newSettings) => {
				setSettings({
					...settings,
					...newSettings,
				});
			});
	};

	const handleSettingChange = (e) => {
		const artifact = e.target.name;
		const key = e.target.value;
		const artifactSettings = settings[artifact];
		Object.keys(artifactSettings).forEach((key) => {
			if (typeof artifactSettings[key] === 'boolean') {
				artifactSettings[key] = false;
			}
		});

		setSettings({
			...settings,
			[artifact]: {
				...artifactSettings,
				[key]: true,
			},
		});
	};

	useEffect(() => {
		setNotificationSettings(settings);
	}, [settings]);

	useEffect(() => {
		getNotificationSettings();
	}, []);

	return (
		<div id="notificationContainer" class="notificationDiv">
			<div className="notificationTabMenu">
				<ul role="tablist">
					{NotificationSettingsTab.map((tab) => (
						<li
							name={tab}
							key={tab}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setSelectedTab(tab);
								}
							}}
							onClick={() => setSelectedTab(tab)}
							className={
								tab === selectedTab ? 'selectedNotifySettingsMenu' : <></>
							}
							aria-label={
								selectedTab === tab ? `${tab}` : `${tab} not selected`
							}
							aria-selected={selectedTab === tab ? 'true' : 'false'}
							role="tab"
							aria-selected={tab === selectedTab ? 'true' : 'false'}
						>
							<span className="consultingMenu">{tab}</span>
						</li>
					))}
				</ul>
			</div>
			{selectedTab === 'Contributions' ? (
				<table id="ntfGrid" className="notifyContribSettings ntfGrid">
					<tr>
						<th colspan="2">Email Notification</th>
					</tr>
					<tr>
						<th>Immediate</th>
						<th>Weekly</th>
					</tr>

					<tr>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="MyContributions"
									value="notifyEmailEach"
									type="radio"
									checked={settings.MyContributions.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="MyContributions"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.MyContributions.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
				</table>
			) : (
				<table id="ntfGrid" className="notifyApprAndReviwerSettings ntfGrid">
					<tr>
						<th rowspan="2">Module</th>
						<th rowspan="2">Events</th>
						<th colspan="2">Email Notification</th>
					</tr>
					<tr>
						<th>Immediate</th>
						<th>Weekly</th>
					</tr>
					<tr>
						<td rowspan="2">Activities</td>
						<td>Review Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="ActivityReview"
									value="notifyEmailEach"
									type="radio"
									checked={settings.ActivityReview.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="ActivityReview"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.ActivityReview.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
					<tr>
						<td>Approval Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="ActivityApprove"
									value="notifyEmailEach"
									type="radio"
									checked={settings.ActivityApprove.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="ActivityApprove"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.ActivityApprove.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="2">Topics</td>
						<td>Review Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TopicReview"
									value="notifyEmailEach"
									type="radio"
									checked={settings.TopicReview.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TopicReview"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.TopicReview.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
					<tr>
						<td>Approval Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TopicApprove"
									value="notifyEmailEach"
									type="radio"
									checked={settings.TopicApprove.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TopicApprove"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.TopicApprove.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="2">Templates</td>
						<td>Review Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TemplateReview"
									value="notifyEmailEach"
									type="radio"
									checked={settings.TemplateReview.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TemplateReview"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.TemplateReview.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
					<tr>
						<td>Approval Notifications</td>
						<td>
							<label
								title="Immediate"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TemplateApprove"
									value="notifyEmailEach"
									type="radio"
									checked={settings.TemplateApprove.notifyEmailEach}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
						<td>
							<label
								title="Weekly"
								className="checkBoxContainer NotificationChkboxContainer"
							>
								<input
									name="TemplateApprove"
									value="notifyEmailDigest"
									type="radio"
									checked={settings.TemplateApprove.notifyEmailDigest}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSettingChange(e);
										}
									}}
									onClick={(e) => handleSettingChange(e)}
								/>
								<span className="checkmark" />
							</label>
						</td>
					</tr>
				</table>
			)}
		</div>
	);
}

export default NotificationSettings;
