import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AddNewPolygon from './AddNewPolygon';

const PolygonTopicBuilder = () => {

    const { url } = useRouteMatch();
    const [selectedSection, setSelectedSection] = useState(['addNewTopics']);
   
    const handleSectionSelection = (selection) => {
     if (selectedSection.includes(selection)) {
      const updateSelectionFilter = selectedSection.filter(
       (item) => item !== selection
      );
      setSelectedSection(updateSelectionFilter);
     } else {
      setSelectedSection([...selectedSection, selection]);
     }
    };

    return(
        <Switch>
            <Route path={`${url}/:categoryId/:topicId?`} exact>
                <AddNewPolygon />
            </Route>
            <Route path="*">
                <ComingSoon />
            </Route>
        </Switch>
    );
}

const ComingSoon = () => (
    <h2 className="pageNotFound">
        <b>404 Page not found!</b>
    </h2>
);
export default PolygonTopicBuilder;