import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button, Alert } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';

const GroupDerefer = ({
	showDereferGroupModal,
	setShowDereferGroupModal,
	setShowParentDereferGroupModal,
	phaseId,
	solutionMethodId,
	type,
	parentId,
	selectedGroup,
	setSelectedGroup,
	getPhaseData,
}) => {
	const { parentPhaseId } = useParams();
	const { track } = useAppInsights();
	const { axiosDeleteService } = useAxios();
	const [errorMessage, setErrorMessage] = useState(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [disableReferButton, setDisableReferButton] = useState(false);

	const dereferGroup = async () => {
		setDisableReferButton(true);
		await axiosDeleteService(
			`api/sdmactivity/derefergroup?solutionMethodId=${
				type === 'parentPhase' ? parentId : solutionMethodId
			}&phaseId=${type === 'parentPhase' ? parentPhaseId : phaseId}&groupId=${
				selectedGroup.groupId
			}`
		).then((response) => {
			if (response.data.status) {
				setShowSuccessMessage(response.data.status);
				track(1, 1702, selectedGroup.groupId, selectedGroup.groupName);
			} else {
				setErrorMessage(response.data.errorMessage);
			}
			setDisableReferButton(false);
		});
	};
	return (
		<>
			{disableReferButton ? (
				<Spinner />
			) : (
				<Modal
					className="phasePublishModal"
					show={showDereferGroupModal}
					onHide={() => {
						if (type === 'parentPhase' && showSuccessMessage) {
							setShowDereferGroupModal(false);
							getPhaseData();
						} else if (showSuccessMessage) {
							setShowDereferGroupModal(false);
							getPhaseData();
						} else if (type === 'parentPhase') {
							setShowDereferGroupModal(false);
						} else {
							setShowDereferGroupModal(false);
						}
						setSelectedGroup('');
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							De-link {selectedGroup && selectedGroup.groupName}
						</Modal.Title>
						<Icon
							onClick={() => {
								if (type === 'parentPhase' && showSuccessMessage) {
									setShowDereferGroupModal(false);
									getPhaseData();
								} else if (showSuccessMessage) {
									setShowDereferGroupModal(false);
									getPhaseData();
								} else if (type === 'parentPhase') {
									setShowDereferGroupModal(false);
								} else {
									setShowDereferGroupModal(false);
								}
								setSelectedGroup('');
							}}
							iconName="ChromeClose"
						></Icon>
					</Modal.Header>
					<Modal.Body>
						{showSuccessMessage || errorMessage ? (
							<>
								{showSuccessMessage ? (
									<Alert variant="success">
										You have successfully de-linked the group.
									</Alert>
								) : (
									<Alert variant="danger">{errorMessage}</Alert>
								)}
							</>
						) : (
							<>
								<p>
									Are you sure that you want to de-link{' '}
									{selectedGroup && selectedGroup.groupName}?
								</p>
								<div className="discardPopUpActivityButtons">
									<Button
										title="Cancel"
										className="activityDiscardPopUpCancel"
										variant="primary"
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (type === 'parentPhase') {
													setShowDereferGroupModal(false);
												} else {
												}
												setShowDereferGroupModal(false);
											}
										}}
										onClick={() => {
											if (type === 'parentPhase') {
												setShowDereferGroupModal(false);
											} else {
											}
											setShowDereferGroupModal(false);
										}}
									>
										Cancel
									</Button>

									<Button
										title="De-link"
										tabIndex={-1}
										onClick={() => {
											dereferGroup();
										}}
										disabled={disableReferButton}
										className="activityDiscardPopUpAccept"
										variant="info"
									>
										De-link
									</Button>
								</div>

								{errorMessage ? (
									<span className="errorMsg">{errorMessage}</span>
								) : (
									<></>
								)}
							</>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
export default GroupDerefer;
