import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';

const ManageAnnouncementsForm = ({
	announcements,
	setAnnouncements,
	getAnnouncements,
}) => {
	const [expandNewAnnouncement, setExpandNewAnnouncement] = useState(false);
	const { axiosPostService, axiosDeleteService } = useAxios();
	const [newAnnouncement, setNewAnnouncement] = useState({});
	const [selectedAnnouncement, setSelectedAnnouncement] = useState({});

	const updateAnnouncements = (announcementIndex, propetyName, newValue) => {
		const newAnnouncements = [...announcements];
		newAnnouncements[announcementIndex][propetyName] = newValue;
		setAnnouncements(newAnnouncements);
	};

	const getAnnouncementMessage = (announcement) => {
		if (announcement.title.length === 0)
			return 'Announcement Title cannot be empty';
		if (announcement.description.length === 0)
			return 'Announcement Description cannot be empty';
		return '';
	};

	const deleteAnnouncement = (announcement) => {
		axiosDeleteService(
			`api/sdmannouncement/announcement/${announcement.id}`
		).then((response) => {
			if (!response.data.status) {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			} else {
				toast.info('You have successfully deleted the announcement');
				setAnnouncements(response.data.sdmAnnouncements, {
					position: 'top-right',
				});
			}
		});
	};

	const saveAnnouncement = async (announcement) => {
		if (!getAnnouncementMessage(announcement)) {
			const response = await axiosPostService(
				'api/sdmannouncement/announcement/update',
				announcement
			);
			if (!response.data.status) {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			} else {
				toast.info('You have successfully updated announcement', {
					position: 'top-right',
				});
				getAnnouncements();
			}
		} else {
			toast.error(getAnnouncementMessage(announcement), {
				position: 'top-right',
			});
		}
	};

	const addAnnouncement = async () => {
		if (!getAnnouncementMessage(newAnnouncement)) {
			const response = await axiosPostService(
				'api/sdmannouncement/announcement',
				newAnnouncement
			);
			if (response.data.status) {
				toast.info('You have successfully added announcement', {
					position: 'top-right',
				});
				getAnnouncements();
				setNewAnnouncement({
					title: '',
					description: '',
					isActive: true,
					isFlyout: false,
				});
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
		} else {
			toast.error(getAnnouncementMessage(newAnnouncement), {
				position: 'top-right',
			});
		}
	};
	useEffect(() => {
		setNewAnnouncement({
			title: '',
			description: '',
			isActive: true,
			isFlyout: false,
		});
	}, []);
	return (
		<div class="middleDetailsContainer">
			<div class="methodOwnerMiddleContainer">
				<div class="methodPhaseDetailsTab">
					<div class="roleAdditionContainer ">
						<div class="addRoleButtonAction">
							<div class="selectAddRoleButton">
								<span
									tabIndex="0"
									onClick={() => {
										setExpandNewAnnouncement(!expandNewAnnouncement);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setExpandNewAnnouncement(!expandNewAnnouncement);
										}
									}}
								>
									+&nbsp;Add New Announcement
								</span>
								{expandNewAnnouncement ? (
									<Icon
										iconName="ChevronUp"
										onClick={() => {
											setExpandNewAnnouncement(!expandNewAnnouncement);
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setExpandNewAnnouncement(!expandNewAnnouncement);
											}
										}}
									/>
								) : (
									<></>
								)}
							</div>
							{expandNewAnnouncement ? (
								<div class="addRoleFields">
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Announcements Title</span>
										<input
											placeholder="Enter Announcements Name"
											value={newAnnouncement.title}
											onChange={(e) =>
												setNewAnnouncement({
													...newAnnouncement,
													title: e.target.value,
												})
											}
										/>
									</div>

									<div class="addRoleNameContainer featureDescription">
										<span class="AddRoleFieldLabel">Description</span>
										<textarea
											placeholder="Enter Description"
											value={newAnnouncement.description}
											onChange={(e) =>
												setNewAnnouncement({
													...newAnnouncement,
													description: e.target.value,
												})
											}
										/>
									</div>
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Is Active</span>

										<label className="NotificationChkboxContainer">
											<input
												type="checkbox"
												checked={newAnnouncement.isActive}
												aria-label="Is Active Checkbox"
												onChange={(e) =>
													setNewAnnouncement({
														...newAnnouncement,
														isActive: e.target.checked,
													})
												}
											/>
											<span className="CustomCheckmark"></span>
										</label>
									</div>
									<div class="addRoleNameContainer">
										<span class="AddRoleFieldLabel">Is Flyout</span>

										<label className="NotificationChkboxContainer">
											<input
												type="checkbox"
												checked={newAnnouncement.isFlyout}
												aria-label="Is Flyout Checkbox"
												onChange={(e) =>
													setNewAnnouncement({
														...newAnnouncement,
														isFlyout: e.target.checked,
													})
												}
											/>
											<span className="CustomCheckmark"></span>
										</label>
									</div>
									<div>
										<span
											className="addRoleIcon"
											title="Add New Announcement"
											onClick={addAnnouncement}
											onKeyPress={(e) => {
												if (e.which === 13) {
													addAnnouncement();
												}
											}}
										></span>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className="addRolesListContainer addDeliveryPlaybookContainer">
					<table class="table tablestyle">
  <thead className="tableHeader">
    <tr>
      <th scope="col">Announcement Name</th>
      <th scope="col">Announcement Description</th>
      <th scope="col">Is Active</th>
      <th scope="col">Is Flyout</th>
	  <th scope="col">Delete</th>
	  <th scope="col">Update</th>
    </tr>
  </thead>
  <tbody>
  {announcements.map((announcement, index) => (<tr className="tableRow">
	<td className="dataContainer"><div class="criteriaIcon ">
														<input
															value={announcement.title}
															placeholder="Title"
															onChange={(e) =>
																updateAnnouncements(
																	index,
																	'title',
																	e.target.value
																)
															}
														/>
													</div></td>
													<td><div>
															<textarea
																value={announcement.description}
																onChange={(e) =>
																	updateAnnouncements(
																		index,
																		'description',
																		e.target.value
																	)
																}
																placeholder="Description"
															/>
														</div></td>
														<td>
														<div>
															<label className="NotificationChkboxContainer">
																<input
																	type="checkbox"
																	checked={announcement.isActive}
																	aria-label="Is Active Checkbox"
																/>
																<span
																	className="CustomCheckmark"
																	onClick={(e) =>
																		updateAnnouncements(
																			index,
																			'isActive',
																			!announcement.isActive
																		)
																	}
																></span>
															</label>
														</div>

														</td>
														<td><div>
															<label className="NotificationChkboxContainer">
																<input
																	type="checkbox"
																	checked={announcement.isFlyout}
																	aria-label="Is Flyout Checkbox"
																/>
																<span
																	className="CustomCheckmark"
																	onClick={(e) =>
																		updateAnnouncements(
																			index,
																			'isFlyout',
																			!announcement.isFlyout
																		)
																	}
																></span>
															</label></div></td>
															<td><div class="criteriaEditDeleteIcons">
														{selectedAnnouncement?.id === announcement.id && (
															<div className="deleteSamplesPopUp">
																<p>
																	Are you sure that you want to delete this
																	Announcement?
																</p>
																<div>
																	<button
																		onClick={() => {
																			setSelectedAnnouncement({});
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setSelectedAnnouncement({});
																			}
																		}}
																	>
																		Cancel
																	</button>
																	<button
																		onClick={() => {
																			deleteAnnouncement(announcement);
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				deleteAnnouncement(announcement);
																			}
																		}}
																	>
																		Delete
																	</button>
																</div>
															</div>
														)}
														<span
															className="deliverPlaybookDeleteIcon margin-top20px"
															title="Delete"
															onClick={() =>
																setSelectedAnnouncement(announcement)
															}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedAnnouncement(announcement);
																}
															}}
															role="button"
															tabIndex={0}
														></span>
													</div>
													</td>
													<td><div class="criteriaSaveUpdate">
														<button
														className="font12px"
															onClick={() => saveAnnouncement(announcement)}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	saveAnnouncement(announcement);
																}
															}}
															aria-label="Update"
														>
															Update
														</button>
													</div></td>
  </tr>))}    
  </tbody>
</table>				
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageAnnouncementsForm;
