import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../../context/AxiosContext';

const MyApprovalContributionRatio = ({ setMaximizeEntity, maximizeEntity }) => {
	const { axiosGetService } = useAxios();
	const [myPerformanceTrendsData, setMyPerformanceTrendsData] = useState([]);

	const getMyPerformanceData = async () => {
		setMyPerformanceTrendsData([]);
		await axiosGetService(
			`api/sdmuser/getapprovaltocontributiongraphdata?isLastTwelveMonths=${
				maximizeEntity === 'myApprovalContributionRatio' ? true : false
			}&isDaily=${false}&isWeekly=${
				maximizeEntity !== 'myApprovalContributionRatio' ? true : false
			}`
		).then((response) => {
			setMyPerformanceTrendsData(response.data);
		});
	};

	const xAxisLabel = () => {
		const weeklyLabels = myPerformanceTrendsData.map(({ weekStartDate }) => {
			return weekStartDate + ' ';
		});
		return weeklyLabels;
	};

	const getGraphData = () => {
		const weeklyGraphData = myPerformanceTrendsData.map(
			({ approvalToContributionPercentage }) => {
				return approvalToContributionPercentage;
			}
		);
		return weeklyGraphData;
	};

	const getStepSize = () => {
		const data = myPerformanceTrendsData.map(
			({ approvalToContributionPercentage }) => {
				return approvalToContributionPercentage;
			}
		);
		const stepSize = Math.ceil(
			Math.max(...data) /
				(maximizeEntity === 'myApprovalContributionRatio' ? 10 : 5)
		);
		return data.length > 0 ? stepSize : 0;
	};

	const graphData = {
		labels: xAxisLabel(),
		datasets: [
			{
				label: 'Approval rate',
				data: getGraphData(),
				fill: false,
				backgroundColor: '#007bff',
				borderColor: '#007bff',
				borderWidth: 1,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		scales: {
			x: {
				title: {
					display: false,
					text: 'Month',
				},
				grid: {
					display: false,
				},
				ticks: {
					//  maxTicksLimit: maximizeEntity === 'myApprovalContributionRatio' ? 12 : 11,
				},
			},
			y: {
				ticks: {
					stepSize: getStepSize(),
				},
				beginAtZero: true,
				title: {
					display: true,
					text: 'Approval rate',
					font: {
						weight: 500,
						family: "'Segoe UI','Segoe UI'",
					},
				},
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			text: '',
		},
		backgroundColor: '#f8f9fa',
	};

	useEffect(() => {
		if (maximizeEntity === 'myApprovalContributionRatio' || !maximizeEntity) {
			getMyPerformanceData();
		}
	}, [maximizeEntity]);

	return (
		<div
			className={
				maximizeEntity === 'myApprovalContributionRatio'
					? 'myAchievementsDashboardTile maximized'
					: 'myAchievementsDashboardTile'
			}
		>
			<div className="dashBoardGraphExpand">
				<Icon
					iconName={
						maximizeEntity !== 'myApprovalContributionRatio'
							? 'FullScreen'
							: 'BackToWindow'
					}
					onClick={() => {
						if (!maximizeEntity) {
							setMaximizeEntity('myApprovalContributionRatio');
						} else {
							setMaximizeEntity('');
						}
					}}
				/>
			</div>
			<div className="dashBoardGraphContainer">
				<div className="dashBoardTitlePart">
					<h6>My Approval Rate</h6>
				</div>
				<div className="myPerformanceTrendsGraph">
					<Line data={graphData} options={options} />
				</div>
			</div>
		</div>
	);
};

export default MyApprovalContributionRatio;
