import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const MultiSelectDropdown = ({
	id,
	className,
	enableDropdown,
	data,
	setUpdatedData,
	label,
}) => {
	const [visible, setVisible] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(id);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item, index) => {
		const updatedData = [...data];
		updatedData[index].isSelected = !item.isSelected;
		setUpdatedData(data);
	};

	return (
		<div title={enableDropdown ? className : ''} id={id}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					onFocus={() => setVisible(true)}
					placeholder={
						data.filter(({ isSelected }) => isSelected).length === 0
							? `Select ${label}`
							: `${data.filter(({ isSelected }) => isSelected).length} selected`
					}
				/>
				<Icon
					iconName="ChevronDown"
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
				/>
				<div>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
					>
						<ul>
							{data.map((item, index) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.id}
										>
											<span>{item.name}</span>
										</li>
									);
								}

								return (
									item.name
										.toLowerCase()
										.includes(searchValue.toLowerCase()) && (
										<li title={item.name} key={item.id}>
											<label className="NotificationChkboxContainer">
												<input
													name={item.name}
													type="checkbox"
													checked={item.isSelected}
													onChange={() => handleDropdown(item, index)}
												/>
												<span className="CustomCheckmark"></span>
												<span className="complexityLabel">{item.name}</span>
											</label>
										</li>
									)
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
