import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useAxios } from '../../../../../context/AxiosContext';
import { useAuth } from '../../../../../context/UserAuthContext';
import Spinner from '../../../../../helpers/Spinner';
import { getOtherUserProfile } from '../../../../../helpers/GraphService';
import { axiosTokenService } from '../../../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../../../context/TelemetryContext';

const ReviewComments = () => {
	const suggestionTypeMapper = {
		insertion: 'Add',
		deletion: 'Remove',
		'formatInline:886cqig6g8rf': 'add bold',
		'formatInline:wdirpouvft2i': 'add underline',
		'formatInline:fttdjdjkal39': 'set link',
		'formatBlock:ulb5dwlnkdts': 'change to numbered list item',
		'formatBlock:fz6284npvaph': 'change to bulleted list item',
	};
	const { track } = useAppInsights();
	const { id, artifactId, stateId } = useParams();
	const { axiosGetService } = useAxios();
	const [isCommentAvailable, setIsCommentAvailable] = useState(true);
	const [comments, setComments] = useState(null);
	const [userDetails, setUserDetails] = useState({});
	const [showSpinner, setShowSpinner] = useState(true);
	const [reviewerEmail, setReviewerEmail] = useState(null);
	const [suggestionStatus, setSuggestionStatus] = useState({});

	const usersEmail = [];

	const getUserProfile = async () => {
		const accessToken = await axiosTokenService();
		const users = {};
		for (const email of usersEmail) {
			const userData = await getOtherUserProfile(accessToken, email);
			users[email] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}
		setUserDetails(users);
	};

	const getContributor = async (suggestions) => {
		for (const suggestion of suggestions) {
			const userEmail = JSON.parse(suggestion).authorId;
			if (!usersEmail.includes(userEmail)) {
				usersEmail.push(userEmail);
			}
		}
	};

	const getActivityContents = async () => {
		await axiosGetService(
			`api/sdmtopics/gettopiccontributionpreview?id=${id}&topicId=${artifactId}&stateId=${stateId}`
		).then((response) => {
			const data = response.data;
			if (data?.resultReviewedJson?.length) {
				const newSuggestionStatus = {};
				data.resultReviewedJson.forEach((suggestion) => {

					const parsedSuggestion = JSON.parse(suggestion);
					newSuggestionStatus[parsedSuggestion.id] = parsedSuggestion.state;
				});
				setSuggestionStatus(newSuggestionStatus);
			}
			if (data && data.resultReviewerComments) {
				usersEmail.push(data.reviewerEmail);
				setReviewerEmail(data.reviewerEmail);
				getContributor(data.resultReviewerComments);
				getUserProfile();
				setComments(data.resultReviewerComments);
			} else {
				setIsCommentAvailable(false);
			}
			setShowSpinner(false);
		});
	};

	useEffect(() => {
		getActivityContents();
	}, []);

	return (
		<div className="topicsCrowdSourcingContainer">
			<div class="topicsDescriptionDetails">
				<div className="topicDescriptionDiv">
					<h5>Reviewer Comments</h5>
					{showSpinner ||
						(isCommentAvailable && !comments) ||
						(isCommentAvailable && Object.keys(userDetails).length < 1) ? (
						<Spinner />
					) : (
						<>
							{isCommentAvailable ? (
								<div className="rejectedMyContributionConatiner">
									{comments.map((comment) => (
										<div key={JSON.parse(comment).id}>
											<div className="rejectionCard myRejectedComments">
												<div
													class={`sideBarCard ${suggestionStatus[JSON.parse(comment).id] ===
															'rejected'
															? 'userDeletedToolsAndGuidance'
															: 'userAddedItem'
														}`}
												>
													<div class="sideBarCardHeader">
														<img
															class="userImg"
															src={
																userDetails[JSON.parse(comment).authorId] &&
																userDetails[JSON.parse(comment).authorId].image
															}
															alt="User Image"
														/>
														<span>
															{userDetails[JSON.parse(comment).authorId] &&
																userDetails[JSON.parse(comment).authorId].name}
														</span>
														<div class="itemStatus">
															<span>
																{JSON.parse(comment).type === 'insertion'
																	? 'Add'
																	: JSON.parse(comment).type === 'deletion'
																		? 'Remove'
																		: 'Format'}
															</span>
															&nbsp;
															<div
																dangerouslySetInnerHTML={{
																	__html: JSON.parse(comment).data,
																}}
															/>
														</div>
													</div>
												</div>
												<div className="reasonedCommentsForRejection">
													<div className="rejectedPersonaDetails">
														<p>
															{userDetails[reviewerEmail] &&
																userDetails[reviewerEmail].name}
														</p>
														<p>{`(Reviewer)`}</p>
													</div>
													<p className="myRejectedCommentsSection">
														{JSON.parse(comment).comment}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							) : (
								<p>No comments available</p>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReviewComments;
