import React, { useState, useEffect, useRef } from 'react';

import { useAppInsights } from '../../context/TelemetryContext';

function SortDropdown({ className, data, setValue, value, enableDropdown }) {
	const { track } = useAppInsights();
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		setValue(item);
		setVisible(!visible);
		// track(1, 9, className, selectedValue);
	};
	useEffect(() => {
		const handleSortDropDown = (e) => {
		  if (e.key === 'Escape' && enableDropdown) {
			  setVisible(!visible);
		  }
		};
		window.addEventListener('keydown',handleSortDropDown);
		return () => {
		  window.removeEventListener('keydown',handleSortDropDown);
		}
	  }, [visible])
	  

	  const dropDownListRef = useRef(null);
	  useEffect(() => {
		  const handleFocusOut = (event) => {
			  if (dropDownListRef.current && !dropDownListRef.current.contains(event.relatedTarget)) {
				  setVisible(false);
			  }
		  };
	  
		  const dropdownContainer = dropDownListRef.current;
		  dropdownContainer.addEventListener('focusout', handleFocusOut);
	  
		  return () => {
			  dropdownContainer.removeEventListener('focusout', handleFocusOut);
		  };
	  }, []);

	return (
		<div title={enableDropdown ? 'Sort' : 'disabled'} id={className}>
			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<span
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13 && enableDropdown) {
							setVisible(!visible);
						}
					}}
					onClick={() => setVisible(!visible)}
					role="combobox"
					aria-label="Click to Sort tutorials"
					className="tutorialSortIcon"
					aria-expanded={enableDropdown ? 'false' : 'true'}
				>
					<div className="tabsTitleToolTip">
						<span className="tabsToolTipArrow"></span>
						<span>{value.name}</span>
					</div>
				</span>
				{value.id !== 0 ? <span>{value.name}</span> : <></>}
				<div style={{ display: 'block' }}>
					<div
						className="dropDownList"
						style={{ display: visible ? 'block' : '' }}
						ref={dropDownListRef}
					>
						<ul role="Listbox">
							{data.map((item) => {
								if (item.type === 'header') {
									return (
										<li
											className="dropdownHeader"
											title={item.name + ' Header'}
											key={item.name}
										>
											<span>{item.name}</span>
										</li>
									);
								}
								return (
									<li
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleDropdown(item);
											}
										}}
										onClick={() => handleDropdown(item)}
										title={item.name}
										key={item.name}
										role="Listitem"
										aria-label={`click to select ${item.name}`}
									>
										<span>{item.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SortDropdown;
