import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';
const closeModalTime = 2000;
const SingleContributionModal = ({
	setEnableSingleTemplateContribution,
	setEnableFileDropModal,
	setEnableTemplateContributionModal,
	setEnableLocalizedTemplate,
	title,
}) => {
	const contributionTypes = {
		newTemplate: setEnableTemplateContributionModal,
		sampleTemplate: setEnableFileDropModal,
		localizedTemplate: setEnableLocalizedTemplate,
	};
	const [contributionType, setContributionType] = useState(null);
	useEffect(() => {
		let timeoutId;
		if (contributionType) {
			timeoutId = setTimeout(() => {
				contributionTypes[contributionType](true);
				setEnableSingleTemplateContribution(false);
			}, closeModalTime);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	});
	return (
		<Modal
			className="templatesingleSelectionPopUp"
			show={true}
			onHide={() => {
				setEnableSingleTemplateContribution(false);
			}}
			size="md"
			centered
		>
			<Modal.Header className="singleContributionModal">
				<div className="templateContributionSelection">
					<span className="settingsLabel">
						What kind of contribution you want to make for '{title}'?
					</span>
				</div>
				<Icon
					iconName="Cancel"
					onClick={() => {
						setEnableSingleTemplateContribution(false);
					}}
					tabIndex={0}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setEnableSingleTemplateContribution(false);
						}
					}}
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="singleContributionDiv">
					<div class="commonContContrButton">
						<div role="none">
							<label className="checkBoxContainer filterChkboxContainer">
								<input
									name="contribution"
									type="radio"
									onChange={() => {
										setContributionType('newTemplate');
									}}
									checked={contributionType === 'newTemplate'}
								/>
								<span className="checkmark"></span>
								<span className="filterLabel">A new Template</span>
							</label>
						</div>
					</div>
					<div class="commonContContrButton">
						<div role="none">
							<label className="checkBoxContainer filterChkboxContainer">
								<input
									name="contribution"
									type="radio"
									role='radio'
									onChange={() => {
										setContributionType('sampleTemplate');
									}}
									checked={contributionType === 'sampleTemplate'}
								/>
								<span className="checkmark"></span>
								<span className="filterLabel">A Sample</span>
							</label>
						</div>
					</div>
					<div class="commonContContrButton">
						<div role="none">
							<label className="checkBoxContainer filterChkboxContainer">
								<input
									name="contribution"
									type="radio"
									onChange={() => {
										setContributionType('localizedTemplate');
									}}
									checked={contributionType === 'localizedTemplate'}
								/>
								<span className="checkmark"></span>
								<span className="filterLabel">A Localized Template</span>
							</label>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default SingleContributionModal;
