import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAxios } from '../../context/AxiosContext';
import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';
import Spinner from '../../helpers/Spinner';

import TopicsHistory from './TopicsHistory';
import AdminHistory from './TopicsHistory/AdminHistory';

const TopicsVersionHistory = ({
	topicsId,
	showTopicsVersionHistory,
	setShowTopicsVersionHistory,
	showTopicsContributionHistory,
	setShowTopicsContributionHisotry,
	topicTitle,
}) => {
	const { axiosGetService } = useAxios();
	const [contributionHistory, setShowContributionHistory] = useState([]);
	const [showTopicsCrowdSourcingHistory, setShowTopicsCrowdSourcingHistory] =
		useState(false);
	const [selectedVersion, setSelectedVersion] = useState(null);
	const [showAdminHistory, setShowAdminHistory] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [showLoaderWithBlur, setShowLoaderWithBlur] = useState(false);
	const [apiCalled, setApiCalled] = useState(false);
	const [adminHistoryChanges, setAdminHistoryChanges] = useState([]);
	const [crowdSourceHistory, setCrowdSourceHistory] = useState([]);

	const getTopicsVersion = async () => {
		setShowLoader(true);
		await axiosGetService(
			`api/sdmtopics/getCrowdsourcingData/${topicsId}`
		).then((response) => {
			setShowContributionHistory(response.data);
			setApiCalled(true);
			setShowLoader(false);
		});
	};

	const getTopicsHistory = async (versionNumber, isCrowdSourced) => {
		setShowLoaderWithBlur(true);
		await axiosGetService(
			`api/sdmtopics/getTopicDataForOwner?topicId=${topicsId}&versionNumber=${versionNumber}&isCrowdSourced=${isCrowdSourced}`
		).then((response) => {
			if (isCrowdSourced) {
				setCrowdSourceHistory(response.data);
				setShowTopicsCrowdSourcingHistory(true);
			} else {
				setAdminHistoryChanges(response.data);
				setShowAdminHistory(true);
			}
			setShowLoaderWithBlur(false);
		});
	};

	useEffect(() => {
		getTopicsVersion();
	}, []);

	return (
		<>
			{showLoaderWithBlur ? (
				<CrowdSourcingSpinner />
			) : showAdminHistory ? (
				<AdminHistory
					showAdminHistory={showAdminHistory}
					setShowAdminHistory={setShowAdminHistory}
					historyData={adminHistoryChanges[0]}
					versionDetails={selectedVersion}
				/>
			) : (
				<>
					{showTopicsCrowdSourcingHistory ? (
						<TopicsHistory
							showTopicsCrowdSourcingHistory={showTopicsCrowdSourcingHistory}
							setShowTopicsCrowdSourcingHistory={
								setShowTopicsCrowdSourcingHistory
							}
							historyData={crowdSourceHistory}
							versionDetails={selectedVersion}
						/>
					) : (
						<Modal
							className="phaseCreationRightPopup methodOwnerCollaborator topicOwnerHistoryPopUp"
							show={showTopicsVersionHistory}
							onHide={() => {
								// Don't do anything.
							}}
						>
							<Modal.Header>
								<div className="methodOwnerCollaboratorTitle">
									<Modal.Title>
										<h3>{topicTitle}</h3>
										<span>Edit History</span>
									</Modal.Title>
									<Icon
										iconName="ChromeClose"
										title="Close"
										onClick={() => setShowTopicsVersionHistory(false)}
									/>
								</div>
							</Modal.Header>
							<Modal.Body>
								{showLoader ? (
									<Spinner />
								) : (
									<div>
										{contributionHistory.length === 0 && apiCalled ? (
											<p>No history available</p>
										) : (
											<table>
												<tr>
													<th>Document Version</th>
													<th>Published Date</th>
												</tr>
												{contributionHistory.map((item) => (
													<tr>
														<td>
															<span
																className="historyVirsionNumber"
																onClick={() => {
																	getTopicsHistory(
																		item.versionNumber,
																		item.isCrowdSourced
																	);
																	setSelectedVersion(item);
																}}
															>
																{item.versionNumber}
															</span>
														</td>
														<td>{item.modifiedDate}</td>
													</tr>
												))}
											</table>
										)}
									</div>
								)}
							</Modal.Body>
						</Modal>
					)}
				</>
			)}
		</>
	);
};

export default TopicsVersionHistory;
