import React from 'react';
import Spinner from '../../helpers/Spinner';

const MyReviewsInfo = ({
	reviewerEmail,
	approverEmail,
	contributionData,
	contributorsInfo,
	type,
	documentStatus,
}) => {
	const getDocumentStatus = () => {
		if (documentStatus === 'Published') {
			if (!(approverEmail in contributorsInfo) || !(reviewerEmail in contributorsInfo)) {
				return false;
			}
			return true;
		} else if (documentStatus === 'Pending Approval') {
			if (reviewerEmail && !(reviewerEmail in contributorsInfo)) {
				return false;
			}
			return true;
		} else if (documentStatus === 'Rejected') {
			if (reviewerEmail && !(reviewerEmail in contributorsInfo)) {
				return false;
			}
			if (approverEmail && !(approverEmail in contributorsInfo)) {
				return false;
			}
			return true;
		} else {
			return true;
		}
	};
	return (
		<div className="contribtionDocStatusMainCont">
			<span className="contribtionDocStatusArrow"></span>
			<div className="approvalHistory">Approval History</div>
			{getDocumentStatus() ? (
				<ul>
					{reviewerEmail && contributorsInfo[reviewerEmail] ? (
						<li>
							<div className="docStatusListCont">
								<span className="docStatusPersonaImage">
									<img src={contributorsInfo[reviewerEmail].image} alt="img" />
								</span>
								<span className="docReviewStatusCont">
									<span className="docStatusPersonaTitle">
										{contributorsInfo[reviewerEmail].name}
									</span>
									<span className="docStatusTime">
										{documentStatus === 'Rejected' && !approverEmail
											? contributionData.reviewRejectedDate
											: contributionData.reviewDate}
									</span>
								</span>
								<span className="contribtionDocStatus">
									{documentStatus === 'Rejected' && !approverEmail ? 'rejected' : 'reviewed'}
								</span>
							</div>
						</li>
					) : documentStatus !== 'Rejected' ? (
						<div className="docPendingForApproval">
							<span className="statusDottedLine"></span>
							<span className="pendingForApprovalStatus">Pending for review</span>
						</div>
					) : (
						<></>
					)}
					{approverEmail && contributorsInfo[approverEmail] ? (
						<li>
							<div className="docStatusListCont">
								<span className="docStatusPersonaImage">
									<img src={contributorsInfo[approverEmail].image} alt="img" />
								</span>
								<span className="docReviewStatusCont">
									<span className="docStatusPersonaTitle">
										{contributorsInfo[approverEmail].name}
									</span>
									<span className="docStatusTime">
										{documentStatus === 'Rejected'
											? contributionData.approvalRejectedDate
											: contributionData.approvalDate}
									</span>
								</span>

								<span className="contribtionDocStatus">
									{documentStatus === 'Rejected' ? 'rejected' : 'approved'}
								</span>
							</div>
						</li>
					) : documentStatus !== 'Rejected' ? (
						<div className="docPendingForApproval">
							<span className="statusDottedLine"></span>
							<span className="pendingForApprovalStatus">Pending for approval</span>
						</div>
					) : (
						<></>
					)}
				</ul>
			) : (
				<div className="spinner">
					<Spinner animation="border" />
				</div>
			)}
		</div>
	);
};
export default MyReviewsInfo;
