import React, { useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import Dropdown from '../SearchDropdown';
import TemplateIcon from '../../static/TemplateIcons';
import TemplatePreview from './TemplatePreview';

const LinkTemplate = ({
	selectedTemplates,
	setSelectedTemplates,
	templateSearchValue,
	setTemplateSearchValue,
	templateList,
	enableTemplatePreview,
	setEnableTemplatePreview,
	showSpinner,
}) => {
	const [templatePreviewData, setTemplatePreviewData] = useState(null);

	const removeTemplate = (templateId) => {
		const updatedData = selectedTemplates.filter(({ id }) => id !== templateId);
		setSelectedTemplates(updatedData);
	};

	return (
		<>
			<span  className='alternativeOfh6' aria-label='Add Template'>Add Template</span>
			<Dropdown
				className="activityTemplateBuilder"
				enableDropdown={true}
				data={templateList}
				searchValue={templateSearchValue}
				setSearchValue={setTemplateSearchValue}
				updatedValue={selectedTemplates}
				setUpdatedValue={setSelectedTemplates}
				showSpinner={showSpinner}
				ariaLabel="Select template to be linked"
			/>
			<ul className="newActivityTemplateList">
				{selectedTemplates.map((item) => (
					<li>
						<div className="newActivityTemplateListItemEditForm">
							<div className="newActivityTemplateListItem">
								{item.id ? (
									<img
										src={
											item.inProgress
												? TemplateIcon['inProgress']
												: item.fileType
												? TemplateIcon[item.fileType.toLowerCase()] ||
												  TemplateIcon['defaultTemplate']
												: TemplateIcon['defaultTemplate']
										}
										className="imageOfSearchResult"
										alt="File Type"
									/>
								) : (
									<></>
								)}
								{item.name}
							</div>
							<span className="newActivityTemplateListItemIcons">
								<button
									tabIndex={0}
									title="Preview"
									aria-label={`${item.name} Preview`}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setTemplatePreviewData(item);
											setEnableTemplatePreview(true);
										}
									}}
									onClick={() => {
										setTemplatePreviewData(item);
										setEnableTemplatePreview(true);
									}}
								>
									<span className="previewIconBlack"></span>
								</button>
								<a
									href={item.inProgress ? null : item.fileLocation}
									title="Download"
									target="_blank"
								>
									<Icon
										className="NewdownloadTemplate"
										iconName="ReleaseGate"
									/>
								</a>
								<Icon
									iconName="Cancel"
									onClick={() => removeTemplate(item.id)}
									onKeyPress={(e) => {
										if (e.which === 13) {
											removeTemplate(item.id);
										}
									}}
									aria-label={`Remove ${item.name} Template`}
									tabIndex={0}
									title="Cancel"
									role="button"
								/>
							</span>
						</div>
					</li>
				))}
			</ul>
			{enableTemplatePreview ? (
				<TemplatePreview
					enableTemplatePreview={enableTemplatePreview}
					closeModal={() => {
						setEnableTemplatePreview(false);
						setTemplatePreviewData(null);
					}}
					data={templatePreviewData}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default LinkTemplate;
