export const destinationActivityProperty = [
	{
		id: 1,
		name: 'All',
		isChecked: false
	},
	{
		id: 2,
		name: 'Activity Description',
		isChecked: false
	},
	{
		id: 3,
		name: 'Entry Criteria',
		isChecked: false
	},
	{
		id: 4,
		name: 'Templates',
		isChecked: false
	},

	{
		id: 5,
		name: 'RACI',
		isChecked: false
	},
	{
		id: 6,
		name: 'Tasks',
		isChecked: false
	},
	{
		id: 7,
		name: 'Exit Criteria',
		isChecked: false
	},
	{
		id: 8,
		name: 'Guidance, Tips and Techniques',
		isChecked: false
	},

	{
		id: 9,
		name: 'Deliverables',
		isChecked: false
	},
	{
		id: 10,
		name: 'Supporting Activity',
		isChecked: false
	},
	{
		id: 11,
		name: 'Tile Description',
		isChecked: false
	},
	{ id: 12, name: 'Is Critical',
	isChecked: false },
	{ id: 13, name: 'Process Group',
	isChecked: false },
	{
        id: 14,
        name: 'SMMI Is Deliverable',
        isChecked: false
    },
    {
        id: 15,
        name: 'SMMI Is Mandatory Process Step',
        isChecked: false
    },
    {
        id: 16,
        name: 'SMMI Secure by Default',
        isChecked: false
    },
    {
        id: 17,
        name: 'Business Rules',
        isChecked: false
    },

];
