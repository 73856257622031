import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import ManageSwimlaneApprovals from '../ManageSwimlane/ManageSwimlaneApproval';
import ManageSwimlaneHistory from './ManageSwimlaneHistory';
import './ManageSwimlanes.css';
import { useHistory, useLocation } from 'react-router';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import { toast } from 'react-toastify';
const ManagePhaseSwimlanes = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(1);
	const [usersDetail, setUsersDetail] = useState({});
	const [swimlanes, setSwimlanes] = useState([]);
	const tabs = [
		{
			id: 1,
			title: 'Approvals',
		},
		{ id: 2, title: 'History' },
	];
	const getUsersDetails = async (userEmail) => {
		if (!usersDetail[userEmail]) {
			const accessToken = await axiosTokenService();
			await getOtherUserProfile(accessToken, userEmail).then((response) => {
				setUsersDetail((prevState) => ({
					...prevState,
					[userEmail]: response,
				}));
			});
		}
	};
	const components = {
		1: (
			<ManageSwimlaneApprovals
				usersDetail={usersDetail}
				getUsersDetails={getUsersDetails}
				swimlanes={swimlanes}
				setSwimlanes={setSwimlanes}
			/>
		),
		2: (
			<ManageSwimlaneHistory
				usersDetail={usersDetail}
				getUsersDetails={getUsersDetails}
				swimlanes={swimlanes}
				setSwimlanes={setSwimlanes}
			/>
		),
	};

	const changeTab = (item) => {
		if (
			swimlanes.some(
				(swimlane) =>
					swimlane.reviewedStatus === 2 && !swimlane.reviewerComments
			) &&
			currentTab === 1
		) {
			toast.error('Please provide comments to uploader to improve', {
				position: 'top-right',
			});
		} else {
			setCurrentTab(item.id);
		}
	};

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles  manageSwimlanesContainer"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				if (
					swimlanes.some(
						(swimlane) =>
							swimlane.reviewedStatus === 2 && !swimlane.reviewerComments
					) &&
					currentTab === 1
				) {
					toast.error('Please provide comments to uploader to improve', {
						position: 'top-right',
					});
				} else {
					history.push(pathname);
				}
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Phase Swimlanes
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu" role="tablist">
									{tabs.map((item) => (
										<li
											key={item.title}
											className={currentTab === item.id ? 'activePhaseTab' : ''}
											onClick={() => changeTab(item)}
											onKeyPress={(e) => {
												if (e.which === 13) {
													changeTab(item);
												}
											}}
											aria-label={item.title}
											role="tab"
											tabIndex={0}
										>
											<span>{item.title}</span>
										</li>
									))}
								</ul>
							</div>
							{components[currentTab]}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManagePhaseSwimlanes;
