import React, { useState, useEffect, useMemo } from "react";
import { Icon } from "@fluentui/react";
import Breadcrumbs from "../Breadcrumbs";
import "./AdminMenu.css";
import "../AdminManageFeature/ManageFeatures.css";
import { useHistory, useRouteMatch } from "react-router";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLocation } from "react-router";
import { TabTitle } from "../../utils/GeneralFunctions";

const AdminMenu = () => {
  const [searchValue, setSearchValue] = useState("");
  const { axiosGetService } = useAxios();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { track } = useAppInsights();
  const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
  const { pathname, search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const adminMenu = [
    {
      heading: "Home Page Settings",
      menuItems: [
        {
          title: "Manage Quick Links",
          link: `${url}?manageQuickLinks=true`,
          key: "manageQuickLinks",
        },
        {
          title: "Manage Left Panel",
          link: `${url}?manageLeftPanel=true`,
          key: "manageLeftPanel",
        },

      ]
    },
    {
      heading: "Announcements and Features",
      menuItems: [
        {
          title: "Manage Announcements",
          link: `${url}?manageAnnouncements=true`,
          key: "manageAnnouncements",
        },
        {
          title: "Manage Features",
          link: `${url}?manageFeatures=true`,
          key: "manageFeatures",
        },
        ,
        {
          title: "Manage Video Tutorials",
          link: `${url}?manageTutorials=true`,
          key: "manageTutorials",
        },
      ],
    },
    {
      heading: "Solution Methods",
      menuItems: [
        {
          title: "Manage Solution Methods",
          link: `${url}?manageSolutionMethods=true`,
          key: "manageSolutionMethods",
        },
        {
          title: "Manage Phases",
          link: `${url}?managePhases=true`,
          key: "managePhases",
        },
        {
          title: "Manage Phase Swimlanes",
          link: `${url}?managePhaseSwimlanes=true`,
          key: "managePhaseSwimlanes",
        },
        {
          title: "Manage Activities",
          link: `${url}?manageActivities=true`,
          key: "manageActivities",
        },
        {
          title: "Manage Activity Grid",
          link: `${url}?manageActivityGrid=true`,
          key: "manageActivityGrid",
        },
        {
          title: "Manage Process Groups",
          link: `${url}?manageProcessGroups=true`,
          key: "manageProcessGroups",
        },
        {
          title: "Manage RACI Grid",
          link: `${url}?manageRaciGrid=true`,
          key: "manageRaciGrid",
        },
        {
          title: "Manage Tools",
          link: `${url}?manageTools=true`,
          key: "manageTools",
        },
        {
          title: "Manage Roles",
          link: `${url}?manageRoles=true`,
          key: "manageRoles",
        },
        {
          title: "Manage Delivery Playbooks",
          link: `${url}?manageDeliveryPlayBook=true`,
          key: "manageDeliveryPlayBook",
        },
      ],
    },
    {
      heading: "Templates",
      menuItems: [{ title: "Manage Templates", link: "/template" }],
    },
    {
      heading: "Topics and Job Aids",
      menuItems: [
        { title: "Manage Topics & Topic Categories", link: "/topics" },
        {
          title: "Manage Job Aids Artifacts",
          link: `${url}?manageJobAidesArtifacts=true`,
          key: "manageJobAidesArtifacts",
        },
      ],
    },
    {
      heading: "Video Tutorials",
      menuItems: [
        {
          title: "Manage Video Tutorials",
          link: `/tutorials`,
        },
        {
          title: "Manage Video Tutorials Category",
          link: `${url}?manageVideoTutorialsCategory=true`,
          key: "manageVideoTutorialsCategory",
        },
      ],
    },
    {
      heading: "General Filters",
      menuItems: [
        {
          title: "Manage Dynamic Filters",
          link: `${url}?manageDynamicFilters=true`,
          key: "manageSolutionMethods",
        },
      ],
    },
  ];
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=0&itemTypeId=${85}&isEdit=false
			&isAutoComplete=false&isSearchResultsHome=false&isSearchResults=false
			&isMyContributions=false&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const pageBreadcrumb = {
        title: breadcrumbTitle[1],
        parent: "Home",
        path: window.location.pathname,
      };
      const newBreadcrumbs = breadcrumbs.slice(0, 1);
      newBreadcrumbs.push(pageBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[1]);
    });
  };
  useEffect(() => {
    loadBreadcrumb();
    track(1, 2001, "admin_menu", "Admin Menu");
  }, []);
  return (
    <div className="AdminMainPageContainer">
      <div className="adminMenuBreadcrumb">
        <Breadcrumbs />
      </div>
      <div className="adminMenuTitleContainer">
        <h6>Administration</h6>
        <div className="adminMenuSearch">
          <Icon iconName="Search" />
          <input
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <div className="adminMenuMainWrapper">
        {adminMenu.map((menu) => {
          return (
            <div className="adminMenuTileConatiner">
              <h6>{menu.heading}</h6>
              <div className="adminMenuTiles">
                {menu.menuItems.map((menuItem) => {
                  if (
                    !menuItem.title
                      .toLocaleLowerCase()
                      .includes(searchValue.toLocaleLowerCase())
                  )
                    return <></>;
                  return (
                    <div
                      className="menuTilePart"
                      tabIndex="0"
                      onClick={() => {
                        menuItem.link && history.push(menuItem.link);
                        track(1, 2002, menuItem.title, menuItem.title);
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          menuItem.link && history.push(menuItem.link);
                          track(1, 2002, menuItem.title, menuItem.title);
                        }
                      }}
                      role="button"
                      aria-label={`Click here to open ${menuItem.title}`}
                      aria-pressed={params.get(menuItem.key)}
                    >
                      <span className="adminMenuIcon"></span>
                      <h6>{menuItem.title}</h6>
                      <Icon iconName="ChevronRight" />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminMenu;
