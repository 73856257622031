import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TemplateReviewAll from './TemplateReviewAll';
import TemplateReviewSingle from './TemplateReviewSingle';
import TemplateApproveAll from './TemplateApproveAll';
import TemplateApproveSingle from './TemplateApproveSingle';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import {TabTitle} from '../../utils/GeneralFunctions'

const TemplateCrowdSourcing = () => {
 const { url } = useRouteMatch();
 const location = useLocation();
 const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
  useLeftMenuCollapse();

 const loadBreadcrumb = async () => {
  const pageBreadcrumb = {
   title: 'Templates',
   type: 'templates',
   parent: ['myContributions', 'home'],
   path: window.location.pathname,
  };
  const newBreadcrumbs = AddBreadCrumb(breadcrumbs, pageBreadcrumb);
  setBreadcrumbs(newBreadcrumbs);
  TabTitle('Templates');
 };

 useEffect(() => {
  loadBreadcrumb();
 }, [location]);

 useEffect(() => {
  setSkipNavigationId('templateCrowdSourcing');
 }, []);

 return (
  <Switch>
   <Route
    exact
    path={`${url}/review/:itemId/:itemTypeId/:complexityId/:languageId`}
   >
    <TemplateReviewSingle />
   </Route>
   <Route path={`${url}/review/all`}>
    <TemplateReviewAll />
   </Route>
   <Route
    exact
    path={`${url}/approve/:itemId/:itemTypeId/:complexityId/:languageId`}
   >
    <TemplateApproveSingle />
   </Route>
   <Route path={`${url}/approve/all`}>
    <TemplateApproveAll />
   </Route>
   <Route path="*">
    <ComingSoon />
   </Route>
  </Switch>
 );
};

const ComingSoon = () => (
 <h2 className="pageNotFound">
  <b>404 Page not found!</b>
 </h2>
);

export default TemplateCrowdSourcing;
