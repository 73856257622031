import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@fluentui/react/lib/Icon';
import { useAppInsights } from '../../context/TelemetryContext';
import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import './Breadcrumbs.css';

function Breadcrumbs() {
	const { track } = useAppInsights();
	const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
	const { crowdSourcingAppState, setCrowdSourcingAppState } =
		useCrowdSourcing();
	const { userLeaveWithSave, userLeaveWithoutSave } = crowdSourcingAppState;
	const changeBreadcrumbs = (index) => {
		setBreadcrumbs(breadcrumbs.slice(0, index + 1));
	};

	useEffect(() => {
		if (!userLeaveWithSave) {
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				userLeaveWithoutSave: false,
			});
		} else if (userLeaveWithSave) {
			setCrowdSourcingAppState({
				...crowdSourcingAppState,
				userLeaveWithSave: false,
				userLeaveWithoutSave: false,
			});
		}
	}, []);
	// if
	return (
		<div role="navigation" aria-label='Breadcrumb' className="phaseBreadcrumb">
			<div>
				<Icon iconName="Home" />
			</div>
			{breadcrumbs.map((item, index) => {
				if (index === breadcrumbs.length - 1) {
					return <span key={`breadcrumb_${item.title}`}>{item?.title.replace(/_.*/, '')}</span>;
				}
				return (
					<div key={`breadcrumb_${item.title}`}>
						<Link
							
						    id={`breadcrumbId_${index}`}
							title={item.title}
							to={item.path}
							role="link"
							onClick={() => {
								if (!userLeaveWithoutSave) {
									changeBreadcrumbs(index);
									track(1, 108, `breadcrumb_${item.title}`, item.title);
								}
							}}
						>
							<span>
								{index === 0
									? item.title.replace(/_.*/, '')
									: item.title.length > 10
									? `${item.title.slice(0, 10).replace(/_.*/, '')}...`
									: item.title.replace(/_.*/, '')}
							</span>
						</Link>
						<Icon iconName="ChevronRight" />
					</div>
				);
			})}
		</div>
	);
}

export default Breadcrumbs;
