import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { Icon } from '@fluentui/react/lib/Icon';

import './NewUserPreferencePopUp.css';

function NewUserPreferencePopUp(props) {
	const { track } = useAppInsights();
	const { axiosPostService, userAlias } = useAxios();
	const { appState, setAppState } = useLeftMenuCollapse();
	let didUserChnagedProjectId = false;
	const [modalShow, setModalShow] = useState(true);

	const changeUserSettings = async (selectedProjectTypeId) => {
		const id = Number(selectedProjectTypeId);
		didUserChnagedProjectId = true;
		const body = {
			email: userAlias,
			projectTypeId: Number(selectedProjectTypeId),
			userSitePreference: 0,
		};
		await axiosPostService('/api/sdmuser/insert', body).then((response) => {
			setAppState({ ...appState, userDefaultProjectTypeId: id });
			props.setProjecType(-1);
		});
	};

	const closeModal = () => {
		setModalShow(false);
		if (!didUserChnagedProjectId) {
			changeUserSettings(1);
		}
	};
	return (
		<Modal
			show={modalShow}
			onHide={() => {
				// setModalShow(false);
				// closeModal();
			}}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className="newUserPreference">
				<div className="row">
					<div className="col-sm-10">
						<h6>Select your preference</h6>
					</div>
					<div className="col-sm-2">
						<Icon
							iconName="ChromeClose"
							className="popUpClose"
							onClick={() => {
								closeModal();
							}}
						/>
					</div>
				</div>

				<p>Choose your default preference to customize your experience. </p>
				<Button
					className="consultingButton"
					onClick={(e) => {
						changeUserSettings(e.target.value);
						track(1, 8, e.target.value, 'Consulting');
						closeModal();
					}}
					value="1"
					style={{ textAlign: 'center', width: '100%' }}
					variant="primary"
				>
					Consulting Project Methods
				</Button>
				<Button
					className="supportButton"
					value="2"
					onClick={(e) => {
						changeUserSettings(e.target.value);
						track(1, 8, e.target.value, 'Support');
						closeModal();
					}}
					style={{ textAlign: 'center', width: '100%' }}
					variant="info"
				>
					Support Project Methods
				</Button>
			</Modal.Body>
		</Modal>
	);
}

export default NewUserPreferencePopUp;
