import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import GridDynamicFilter from "./GridDynamicFilter";
import { useAxios } from "../../context/AxiosContext";
import ComplexityFilter from "./ComplexityFilter";
import { useRaciGrid } from "./RaciContext";
import axios from "axios";
import { toast } from 'react-toastify';

function RaciGridFilters({
  applyFilter,
  setApplyFilter,
  showFilters,
  setShowFilters,
  complexities,
  setDynamicFilterTagMapping,
  dynamicFilterTag,
  setDynamicFilterTag,
  dynamicFilterTagObject,
  setDynamicFilterTagObject,
  roleGroupsData,
}) {
  const { axiosGetService, axiosPostService } = useAxios();
  const { axiosGetServiceWithCancel } = useAxios();
  const { selectedComplexites, setSelectedComplexites, dynamicFilterData, setDynamicFilterData } = useRaciGrid();
  const searchInputRef = useRef(null);

  const [dynamicSearchText, setDynamicSearchText] = useState({});
  const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
    {}
  );
  const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
    {}
  );
  const [selectedFiltes, setSelectedFiltes] = useState(null);
  const [currentAxiosCancelToken, setCurrentAxiosCancelToken] = useState(null);
  const [defaultRaciComplexity, setDefaultRaciComplexity] = useState(null);

  const getDefaultRaciComplexity = async () => {
    await axiosGetService(
      `api/sdmsolutionmethod/defaultdynamiccomplexityraci`
    ).then((response) => {
      setDefaultRaciComplexity(response.data);
    });
  }

  const setDefaultFilter = async () => {
    Object.keys(dynamicFilterTagObject).forEach(key => {
      if (Array.isArray(dynamicFilterTagObject[key]) && dynamicFilterTagObject[key].length === 0) {
        delete dynamicFilterTagObject[key];
      }
    });
    try {
      await axiosPostService(`api/sdmactivity/insertusersettings?filters=${JSON.stringify(dynamicFilterTagObject)}`).then((response) => {
        if (response.data) {
          setShowFilters(!showFilters);
          setDynamicFilterTagObject(response.data);
          setApplyFilter(!applyFilter);
          toast.info('You have successfully saved filters.', {
            position: 'top-right',
          });
        } else {
          toast.error(response.data.errorMessage, {
            position: 'top-right',
          });
        }
      })
    } catch (e) {
      console.log(e);
    };
  }
  const getDynamicsFilterData = async () => {
    if (Object.keys(dynamicFilterTag).length > 0) {
      const selectedFiltersIds = [];
      const filterIds = Object.keys(dynamicFilterTag);
      for (const id of filterIds) {
        if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
          selectedFiltersIds.push(
            `${Number(id)}` +
            "-" +
            dynamicFilterTag[Number(id)].selectedTagId.toString()
          );
        }
      }
      if (`${selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : 0}&complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== '' ? selectedComplexites.toString() : defaultRaciComplexity}` === selectedFiltes) {
        return;
      } else {
        setSelectedFiltes(
          `${selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : 0}&complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== '' ? selectedComplexites.toString() : defaultRaciComplexity}`
        );
      }
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      if (currentAxiosCancelToken) {
        currentAxiosCancelToken.cancel();
      }

      setCurrentAxiosCancelToken(source);
      if (selectedFiltersIds.length > 0) {
        try {
          const response = await axiosGetServiceWithCancel(
            `api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${16}&isFilter=true&artifactId=0&filterIds=${selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : 0}&complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
              ? selectedComplexites.toString()
              : defaultRaciComplexity}&searchValue=0`,
            source.token
          );
          const nameMapping = {};
          const filterTagMapping = {};
          const serachFilterData = {};
          let searchText = {};
          const data = response.data.map((item) => {
            return {
              artifactTypeId: item.artifactTypeId,
              artifactTypeName: item.artifactTypeName,
              artifactData: item.artifactData,
            };
          });
          for (const id in data) {
            nameMapping[data[id].artifactTypeId] =
              dynamicFilterSearchEnable[data[id].artifactTypeId];
            const tag = data[id].artifactData;
            let artifactTag = {};
            for (const tagId in tag) {
              artifactTag = {
                ...artifactTag,
                [tag[tagId].id]: tag[tagId].name,
              };
            }
            filterTagMapping[data[id].artifactTypeId] = artifactTag;
            searchText = {
              ...searchText,
              [data[id].artifactTypeId]: dynamicSearchText[data[id].artifactTypeId] ?? "",
            };
            serachFilterData[data[id].artifactTypeId] = dynamicSearchText[data[id].artifactTypeId]
              ? tag.filter((item) => item.name
                .toLowerCase()
                .includes(
                  dynamicSearchText[data[id].artifactTypeId].toLowerCase()
                )
              )
              : [];
          }
          setDynamicFilterSearchResult({ ...serachFilterData });
          setDynamicSearchText({ ...searchText });
          setDynamicFilterTagMapping({ ...filterTagMapping });
          setDynamicFilterSearchEnable({ ...nameMapping });
          setDynamicFilterData([...data]);
          const newDynamicFilter = { ...dynamicFilterTag };
          for (const artifactTypeId in newDynamicFilter) {
            const { artifactData } = data.find(({ artifactTypeId: id }) => id == artifactTypeId) ?? [];
            newDynamicFilter[artifactTypeId].selectedTagId = newDynamicFilter[artifactTypeId].selectedTagId.filter((tagId) => artifactData.map(({ id: itemId }) => itemId).includes(tagId)
            );
          }
          if (JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilter)) {
            setDynamicFilterTag(newDynamicFilter);
          }
        } catch { }
      }
    }
  };

  const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
    if (searchText.trim().length > 0) {
      const filteredRoles = artifactData.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setDynamicFilterSearchResult((prevState) => ({
        ...prevState,
        [artifactTypeId]: filteredRoles,
      }));
    }
    setDynamicSearchText((prevState) => ({
      ...prevState,
      [artifactTypeId]: searchText.trim().length > 0 ? searchText : "",
    }));
  };
  const collapseFilter = (e) => {
    const filterArea = document.getElementById("filtersRightPanel");
    if (filterArea &&
      !filterArea.contains(e.target) &&
      e.target.className !== "filtersButton" &&
      !(
        typeof e.target.className !== "object" &&
        e.target.className &&
        e.target.className.includes("searchIcon")
      ) &&
      e.target.className !== "root-50" &&
      e.target.className !== "root-56" &&
      e.target.className !== "showMoreFilter") {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    getDefaultRaciComplexity();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getDynamicsFilterData();
    }, 500);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [selectedComplexites, dynamicFilterTag]);

  useEffect(() => {
    const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
    if (dynamicRefValue.includes(true) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [dynamicFilterSearchEnable]);

  useEffect(() => {
    window.addEventListener("click", collapseFilter);
    return () => {
      window.removeEventListener("click", collapseFilter);
    };
  }, []);

  const filtersPanelRef = useRef(null);
  useEffect(() => {
    const handleFocusOut = (event) => {
      if (filtersPanelRef.current && !filtersPanelRef.current.contains(event.relatedTarget)) {
        setShowFilters(false);
      }
    };

    const filtersPanel = filtersPanelRef.current;
    filtersPanel.addEventListener('focusout', handleFocusOut);

    return () => {
      filtersPanel.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  return (
    <div>
      <div
        ref={filtersPanelRef}
        id="filtersRightPanel"
        className={!showFilters ? "topicLandingFilters" : ""}
      >
        <div className="filterPanelHeader">
          <h5>Filters</h5>
          <Icon
            iconName="cancel"
            onClick={() => {
              setShowFilters(false);
            }}
            onKeyPress={(e) => {
              if (e.which === 13) {
                setShowFilters(false);
              }
            }}
            tabIndex={0}
            role="button"
            aria-label="Close Filters" />
        </div>
        <div className="filtersPanelBody">
          <>
            <div className="filtersCategoryPanel">
              <h6>Complexity</h6>
              <ComplexityFilter complexities={complexities} />
            </div>
            {dynamicFilterData.map((item) => (
              <div className="filtersCategoryPanel" key={item.artifactTypeId}>
                {item.artifactData.length > 5 ? (
                  <>
                    {dynamicFilterSearchEnable[item.artifactTypeId] ? (
                      <div className="rolesSearch">
                        <input
                          type="text"
                          placeholder={`Search for  ${item.artifactTypeName}`}
                          value={dynamicSearchText[item.artifactTypeId]}
                          onChange={(e) => {
                            e.persist();
                            searchForArtifact(
                              e.target.value,
                              item.artifactTypeId,
                              item.artifactData
                            );
                          }}
                          ref={searchInputRef} />
                        <Icon
                          className="searchIcon"
                          iconName="cancel"
                          onClick={() => {
                            setDynamicFilterSearchEnable({
                              ...dynamicFilterSearchEnable,
                              [item.artifactTypeId]: false,
                            });
                            setDynamicSearchText({
                              ...dynamicSearchText,
                              [item.artifactTypeId]: "",
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: false,
                              });
                              setDynamicSearchText({
                                ...dynamicSearchText,
                                [item.artifactTypeId]: "",
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label="Cancel Search Filter" />
                      </div>
                    ) : (
                      <div className="filtersearch">
                        <h6>{item.artifactTypeName}</h6>
                        <span
                          className="searchIcon"
                          onClick={() => setDynamicFilterSearchEnable({
                            ...dynamicFilterSearchEnable,
                            [item.artifactTypeId]: true,
                          })}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setDynamicFilterSearchEnable({
                                ...dynamicFilterSearchEnable,
                                [item.artifactTypeId]: true,
                              });
                            }
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label={`click to search ${item.artifactTypeName} `}
                        >
                          <Icon iconName="search" className="searchIcon" />
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <h6>{item.artifactTypeName}</h6>
                )}
                <GridDynamicFilter
                  data={dynamicSearchText[item.artifactTypeId]
                    ? dynamicFilterSearchResult[item.artifactTypeId]
                    : item.artifactData}
                  value={dynamicFilterTag}
                  artifactTypeName={item.artifactTypeName}
                  setValue={setDynamicFilterTag}
                  dynamicFilterTagObject={dynamicFilterTagObject}
                  setDynamicFilterTagObject={setDynamicFilterTagObject}
                  artifactTypeId={item.artifactTypeId} />
              </div>
            ))}
          </>
        </div>
        <div className="filterPanelFooter">
          <button
            type="button"
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => {
              setApplyFilter(!applyFilter);
            }}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-default"
            tabIndex={0}
            onClick={() => {
              setDefaultFilter();
            }}
          >
            Set as Default
          </button>
        </div>
      </div>
    </div>
  );
}

export default RaciGridFilters;
