import React, { useState, useEffect } from 'react';
import { getUserImage } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	searchValue,
	setSearchValue,
	value,
	setValue,
	type,
	error,
	emailFound,
	ownerRadioButton,
	collaboratorDropDownList,
	updateApprovers,
	setUpdateApiCall,
	updateApiCall,
	pageType
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (dropdown && !dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const checkUserImage = async (item) => {
		const accessToken = await axiosTokenService();
		await getUserImage(
			accessToken,
			item.mail,
			item.firstName,
			item.surname
		).then((response) => {
			item.photo = response.photo;
			setValue(item);
			if(updateApiCall !== undefined){
				setUpdateApiCall(true);
			}
			if (updateApprovers) {
				updateApprovers(item);
			  }
		});
	};

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	const handleDropdown = (item) => {
		if (value.mail !== item.mail) {
			checkUserImage(item);
		} else {
			setVisible(false);
		}
		setSearchValue('');
	};
	return (
		<div title={enableDropdown ? className : ''} id={className}>
			{error['coOwner'] && <p>Invalid Email</p>}
			<input
				onChange={(e) => setSearchValue(e.target.value)}
				value={searchValue}
				onFocus={() => setVisible(true)}
				placeholder="Enter name or email to search"
				aria-label="Enter name or email to search"
			/>
			{emailFound && visible ? (
				<span className="complexityLegend"></span>
			) : (
				<></>
			)}

			<div
				className={`dropDowncontainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<div>
					{data[type].length > 0 ? (
						<div
							className={pageType == "topics" ?`dropDownList polygonOwnerDropUser ${collaboratorDropDownList}` :`dropDownList coOwner ${collaboratorDropDownList}`}
							style={{ display: visible ? 'block' : '' }}
						>
							<ul role="listbox" className={pageType == "topics" ? 'polygonDropdownStyle':''}>
								{data[type].map((item) => {
									if (item.type === 'header') {
										return (
											<li
												className="dropdownHeader"
												title={item.mail + ' Header'}
												key={item.mail}
											>
												<span>{item.displyaName}</span>
											</li>
										);
									}

									return (
										<li title={item.mail} key={item.mail}>
											<label className="NotificationChkboxContainer">
												<input
													name={item.name}
													type="checkbox"
													onChange={() => handleDropdown(item)}
												/>
												<span className={`CustomCheckmark ${ownerRadioButton}`}></span>
												<span className="complexityLabel userName">
													{item.firstName + ' ' + item.surname}
												</span>
												<span className="userEmail">{item.mail}</span>
											</label>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
