import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import MyContributionInfo from "../MyContributionInfo";
import { Icon } from "@fluentui/react";
//context
import { useAxios } from "../../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../../context/LeftMenuCollapseContext";
import { getOtherUserProfile } from "../../../helpers/GraphService";
import { axiosTokenService } from "../../../components/CkEditor/api/axiosBaseUrl";
//helpers
import AddBreadCrumb from "../../../helpers/BreadcrumbService";
import { TabTitle } from "../../../utils/GeneralFunctions";

const TemplateMyContributionPreview = ({ enableGamification }) => {
  const { id, stateId } = useParams();
  const { axiosGetService } = useAxios();
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
    useLeftMenuCollapse();
  const [templateData, setTemplateData] = useState({
    previewUrl: "",
  });
  const [contributorsInfo, setContributorsInfo] = useState({});
  const [showRejectedPopup, setShowRejectedPopup] = useState(false);
  const [showContributorInfoModal, setShowContributorInfoModal] =
    useState(false);
  const templatePreviewData = async () => {
    await axiosGetService(
      `api/sdmtemplate/getTemplatePreviewContributions/${id}`
    ).then((res) => {
      setTemplateData(res.data);
      loadBreadcrumb(res.data.title);
      getContributorInfo(res.data);
    });
  };

  const getContributorInfo = async (contributorsData) => {
    const userDetails = {};
    const accessToken = await axiosTokenService();
    if (
      contributorsData.reviewerEmail ||
      contributorsData.reviewerRejectedEmail
    ) {
      const userData = await getOtherUserProfile(
        accessToken,
        contributorsData.reviewerEmail
          ? contributorsData.reviewerEmail
          : contributorsData.reviewerRejectedEmail
      );
      userDetails[
        contributorsData.reviewerEmail
          ? contributorsData.reviewerEmail
          : contributorsData.reviewerRejectedEmail
      ] = {
        name: userData.firstName + " " + userData.surname,
        image: userData.photo,
      };
    }
    if (
      contributorsData.approverEmail ||
      contributorsData.approverRejectedEmail
    ) {
      const userData = await getOtherUserProfile(
        accessToken,
        contributorsData.approverEmail
          ? contributorsData.approverEmail
          : contributorsData.approverRejectedEmail
      );
      userDetails[
        contributorsData.approverEmail
          ? contributorsData.approverEmail
          : contributorsData.approverRejectedEmail
      ] = {
        name: userData.firstName + " " + userData.surname,
        image: userData.photo,
      };
    }
    if (contributorsData.contributorEmail) {
      const userData = await getOtherUserProfile(
        accessToken,
        contributorsData.contributorEmail
      );
      userDetails[contributorsData.contributorEmail] = {
        name: userData.firstName + " " + userData.surname,
        image: userData.photo,
      };
    }
    setContributorsInfo(userDetails);
  };

  const loadBreadcrumb = async (title) => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${id}&itemTypeId=1
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=true&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const parentBreadcrumb = {
        title: breadcrumbTitle[1],
        type: "projectType",
        path: `/mycontributions`,
        parent: ["home"],
      };

      const projectBreadcrumb = {
        title: `${title} - Historical Preview`,
        type: "projectType",
        path: window.location.pathname,
        parent: "myContributions",
      };

      const newBreadcrumbs = AddBreadCrumb(breadcrumbs.slice(0, 1), [
        parentBreadcrumb,
        projectBreadcrumb,
      ]);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(`${title} - Historical Preview`);
    });
  };

  const handleStatusClassName = (status) => {
    const rejected_status = ["All Rejected", "Rejected"];
    const pending_status = [
      "Pending Approval",
      "Pending Review",
      "Sent for Approval",
    ];
    const accepted_status = [
      "Published",
      "All Approved",
      "Partially Approved",
      "Published",
      "Approved",
    ];

    if (rejected_status.includes(status)) return "rejectedStatus";
    else if (pending_status.includes(status)) return "pendingStatus";
    else if (accepted_status.includes(status)) return "acceptedStatus";
    return "";
  };

  useEffect(() => {
    templatePreviewData();
  }, [id]);

  useEffect(() => {
    setSkipNavigationId("templateHistoryPreview");
  }, []);

  return (
    <div className="myContributionPreviewContainer">
      <h4 id="templateHistoryPreview">Historical Preview</h4>
      {stateId === "2" &&
      (templateData.approverEmail || templateData.approverRejectedEmail) &&
      contributorsInfo[
        templateData.approverEmail || templateData.approverRejectedEmail
      ] ? (
        <p className="reviewerNameLabel">
          Reviewer Name:&nbsp;
          {
            contributorsInfo[
              templateData.approverEmail || templateData.approverRejectedEmail
            ].name
          }
        </p>
      ) : (
        <></>
      )}
      {stateId === "3" &&
      templateData.reviewerEmail &&
      contributorsInfo[templateData.reviewerEmail] ? (
        <p className="reviewerNameLabel">
          Reviewer Name:&nbsp;
          {contributorsInfo[templateData.reviewerEmail].name}
        </p>
      ) : (
        <></>
      )}
      <h6>
        {templateData.title}
        {stateId === "1" ? (
          <>
            <button
              className="approvalHistoryButton"
              onMouseEnter={() => setShowContributorInfoModal(true)}
              onMouseLeave={() => setShowContributorInfoModal(false)}
              onBlur={(e) => {
                setShowContributorInfoModal(false);
              }}
              onFocus={(e) => {
                setShowContributorInfoModal(true);
              }}
            >
              Approval History
              {showContributorInfoModal ? (
                <MyContributionInfo
                  contributorEmail={templateData.contributorEmail}
                  reviewerEmail={
                    templateData.reviewerEmail
                      ? templateData.reviewerEmail
                      : templateData.reviewerRejectedEmail
                  }
                  approverEmail={
                    templateData.approverEmail
                      ? templateData.approverEmail
                      : templateData.approverRejectedEmail
                  }
                  contributionData={templateData}
                  contributorsInfo={contributorsInfo}
                  type="Historical Preview"
                  documentStatus={templateData.documentStatus}
                />
              ) : (
                <></>
              )}
            </button>
          </>
        ) : (
          <></>
        )}
      </h6>

      <div className="templateCrowdSourcingPreview">
        <div className="myContributionTemplatePreview">
          <div className="templateCrowdSourcingPanelHeader">
            <div className="templateCrowdSourcingPanelHeaderDiv">
              <span>Suggested Template</span>
              <span className="tempPreviewInfoIcon">
                <div className="tabsShowMoreMenu">
                  <span className="tabsShowMoreArrow"></span>
                  <div className="menuItemUL">
                    {templateData.contributorComments}
                  </div>
                </div>
              </span>
              {templateData.documentStatus === "Rejected" && (
                <div
                  className={
                    "status " +
                    handleStatusClassName(templateData.documentStatus)
                  }
                >
                  &nbsp;{templateData.documentStatus}
                </div>
              )}

              <div>
                <a href={templateData.filePath} target="_blank">
                  Download File
                </a>
              </div>
            </div>
            {(templateData.approverRejectedComments ||
              templateData.reviewerRejectedComments) &&
            enableGamification ? (
              <div className="rejectedCommentsSection">
                <div class="status rejectedStatus">
                  <span> &nbsp;Rejected</span>
                </div>
                <div class="rejectedCommentsActionButton">
                  {showRejectedPopup ? (
                    <>
                      <Icon iconName="CaretSolidLeft" />
                      <span
                        onClick={() => setShowRejectedPopup(!showRejectedPopup)}
                      >
                        {" "}
                        Hide Comments
                      </span>
                    </>
                  ) : (
                    <>
                      <Icon iconName="CaretSolidRight" />
                      <span
                        onClick={() => setShowRejectedPopup(!showRejectedPopup)}
                      >
                        {" "}
                        Show Comments
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="templateHistoryIframe">
            <iframe
              title="Suggested Template"
              src={templateData.previewUrl}
              className="templateCrowdSourcingIframe"
              scrolling="yes"
              arial-label="Template Preview"
            />
            {showRejectedPopup ? (
              <>
                <div class="rejectedCommentsSectionPopOver">
                  <div className="templateRejectionComments">
                    <div>
                      <span>
                        {(contributorsInfo[
                          templateData.approverEmail ||
                            templateData.approverRejectedEmail
                        ] &&
                          contributorsInfo[
                            templateData.approverEmail ||
                              templateData.approverRejectedEmail
                          ].name) ||
                          (contributorsInfo[
                            templateData.reviewerEmail ||
                              templateData.reviewerRejectedEmail
                          ] &&
                            contributorsInfo[
                              templateData.reviewerEmail ||
                                templateData.reviewerRejectedEmail
                            ].name)}
                      </span>
                      <span>
                        {templateData.approverEmail ||
                        templateData.approverRejectedEmail
                          ? "Approver"
                          : "Reviewer"}
                      </span>
                    </div>
                    <div>
                      <span>Comment : </span>
                      <p>
                        {templateData.approverEmail ||
                        templateData.approverRejectedEmail
                          ? templateData.approverRejectedComments
                          : templateData.reviewerRejectedComments}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMyContributionPreview;
