import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

import './MySettings.css';

function MySettings() {
	const { track } = useAppInsights();
	const { axiosPostService, userAlias, axiosGetService } = useAxios();
	const { appState, setAppState } = useLeftMenuCollapse();

	let { userDefaultProjectTypeId } = appState;
	const [activeProjectId, setActiveProjectId] = useState();

	const changeUserSettings = async (id) => {
		if (id !== activeProjectId) {
			const body = {
				email: userAlias,
				projectTypeId: id,
			};
			await axiosPostService('/api/sdmuser/insert', body).then(() => {
				setAppState({ ...appState, userDefaultProjectTypeId: id });
				setActiveProjectId(id);
			});
		}
	};

	useEffect(() => {
		setActiveProjectId(1);
	}, []);

	return (
		<div className="mySettingsRightPanel">
			<div className="settingsContainer">
				<h4>
					<Icon iconName="HomeSolid" />
					Default Home Screen View
				</h4>
				<div className="selectionOfSolMethod">
					<label
						className="checkBoxContainer"
						onKeyPress={(e) => {
							if (e.which === 13) {
								e.preventDefault();
								changeUserSettings(1);
								track(1, 8, 1, 'Consulting');
							}
						}}
					>
						<input
							name="methodSelection"
							type="radio"
							value="1"
							tabIndex={0}
							checked={activeProjectId === 1}
							onChange={() => {
								changeUserSettings(1);
								track(1, 8, 1, 'Consulting');
							}}
							title="Consulting"
							role="radio box"
							aria-label="Set Default home screen view to Consulting"
						/>
						<span className="settingsLabel">Consulting</span>
						<span className="checkmark" title="Consulting" />
					</label>
				</div>
			</div>
		</div>
	);
}

export default MySettings;
