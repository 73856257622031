import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import { useAxios } from '../../context/AxiosContext';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import '../ActivityContributorCrowdSourcing/DiscardPopUp.css';

function DiscardPopUp({ contributorContent }) {
	const [modalShow, setModalShow] = useState(true);
	const { topicType, id } = useParams();
	const { crowdSourcingAppState, setCrowdSourcingAppState } = useCrowdSourcing();
	const [messagesType, setMessagesType] = useState({});
	const { axiosGetService } = useAxios();
	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingReviewWithHistory&title=0`
		).then((response) => {
			message['pendingReviewWithHistory'] = response.data;
		});
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=pendingReviewWithoutHistory&title=0`
		).then((response) => {
			message['pendingReviewWithoutHistory'] = response.data;
		});
		setMessagesType(message);
	};
	const closeModal = () => {
		setModalShow(false);
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			showHistory: true,
		});
	};
	useEffect(() => {
		getMessage();
	}, []);

	return (
		<Modal
			className="activityDiscardPopUp reviewedPopUp"
			show={modalShow}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				{contributorContent ? (
					<>
						<p>{messagesType['pendingReviewWithHistory']}</p>
						<div className="discardPopUpActivityButtons">
							<Button
								title="Show me historical preview"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								className="activityDiscardPopUpAccept"
								variant="primary"
								onClick={() => closeModal()}
							>
								Show me historical preview
							</Button>
						</div>
					</>
				) : (
					<>
						<p>{messagesType['pendingReviewWithoutHistory']}</p>
						<div className="discardPopUpActivityButtons">
							<Link to={`/topics/${id}`}>
								<Button title="OK" className="activityDiscardPopUpAccept" variant="primary">
									OK
								</Button>
							</Link>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
