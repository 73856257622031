import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uniqueIdGenerator } from 'uuid';

import { useAxios } from '../../../context/AxiosContext';
import { Icon } from '@fluentui/react/lib/Icon';
import { useLeftMenuCollapse } from '../../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../../context/TelemetryContext';
import { getOtherUserProfile } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../../components/CkEditor/api/axiosBaseUrl';
import ReviewersOrApproversList from '../ReviewersOrApproversList';
import MyApprovalsInfo from '../MyReviewsInfo';

const MyApprovals = ({
	currentPage,
	pageLimit,
	setDataCount,
	setCurrentPage,
	handleStatusClassName,
}) => {
	const { track } = useAppInsights();
	const { getNotifications } = useLeftMenuCollapse();
	const [myApprovals, setMyApprovals] = useState([]);
	const [searchText, setSearchText] = useState(0);
	const { axiosGetService, axiosPostService } = useAxios();
	const [contributorsInfo, setContributorsInfo] = useState({});
	const [documentStatusId, setDocumentStatusId] = useState(-1);
	const [showApproversOrReviewersList, setShowApproversOrReviewersList] =
		useState(false);
	const [selectedArtifactId, setSelectedArtifactId] = useState(-1);

	const getMyApprovalsData = async () => {
		setDataCount(0);
		setMyApprovals([]);
		await axiosGetService(
			`api/sdmtopics/gettopicapprovals/${searchText}/${pageLimit}/${currentPage}`
		).then((response) => {
			if (Number(response.data.count) > 0) {
				const contributionData = [];
				for (const topic of response.data.topics) {
					contributionData.push({ ...topic, uniqueId: uniqueIdGenerator() });
				}
				setMyApprovals(contributionData);
				setDataCount(Number(response.data.count));
			} else {
				setMyApprovals([]);
				setDataCount(null);
			}
		});
	};

	const getUserDetails = async (
		contributedEmail,
		reviewerEmail,
		approverEmail
	) => {
		const accessToken = await axiosTokenService();
		const userDetails = {};
		if (contributedEmail && !(contributedEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, contributedEmail);
			userDetails[contributedEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (reviewerEmail && !(reviewerEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, reviewerEmail);
			userDetails[reviewerEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		if (approverEmail && !(approverEmail in contributorsInfo)) {
			const userData = await getOtherUserProfile(accessToken, approverEmail);
			userDetails[approverEmail] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}

		setContributorsInfo((prevState) => ({ ...prevState, ...userDetails }));
	};

	const getSearchText = (e) => {
		if (!e.target.value.trim()) {
			setSearchText(0);
		} else {
			setSearchText(e.target.value);
		}
	};

	const postNotificationRead = async (contribution) => {
		const postBody = {
			actionTypeId: contribution.actionTypeId,
			itemId: contribution.topicId,
			itemTypeName: 'Topic',
			isNotifiedUI: true,
			createdOn: new Date().toUTCString(),
		};
		if (contribution.documentStatus === 'Pending Approval') {
			await axiosPostService('api/sdmnotification/insert', [postBody])
				.then((response) => {
					getNotifications();
				})
				.catch((error) => console.log(error));
		}
	};

	useEffect(() => {
		if (currentPage === 1) {
			getMyApprovalsData();
		} else {
			setCurrentPage(1);
		}
	}, [searchText]);

	useEffect(() => {
		getMyApprovalsData();
	}, [currentPage]);

	useEffect(() => {
		track(2, 164, 'Topic', 'My Approvals');
	}, []);

	return (
		<div className="myContributionListDiv">
			<div className="myContributionParentDiv">
				<div className="myConributionHeaderDiv">
					<div className="newTableChildRow header">
						<div className="newTableRow">
							<div className="newWrapper newAttributes">
								<div className="newWrapper newTitleCommentModuleReporter ">
									<div className="newWrapper newTitleComment">
										<div className="newColumn newTitle newActTd ">
											Topic Name{' '}
											<div className="myContrTitleSearch">
												<Icon iconName="Search" />
												<input
													type="text"
													title="Search for a Topic"
													value={searchText === 0 ? '' : searchText}
													placeholder="Search by name"
													onChange={(e) => getSearchText(e)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn date newActTd ">Acted On</div>
							</div>
							<div className="newWrapper ">
								<div className="newColumn status  newActTd ">
									Approval Status
								</div>
							</div>
						</div>
					</div>
				</div>
				{myApprovals.map((contribution) => (
					<div
						className="mycontributionChildWrapper"
						key={contribution.uniqueId}
					>
						<div className="newTableChildRow">
							<div className="newTableRow">
								<div className="newWrapper newAttributes">
									<div className="newWrapper newTitleCommentModuleReporter ">
										<div className="newWrapper newTitleComment">
											<div className="newColumn newTitle newActTd ">
												<Link
													title={contribution.topicName}
													aria-label={contribution.topicName}
													to={
														contribution.documentStatus === 'Pending Approval'
															? `/topics/approver/edit/${
																	contribution.topicId
															  }`
															: `/mycontributions/historicalpreview/myapprovals/topics/${contribution.id}/${contribution.topicId}/${contribution.stateId}`
													}
													onClick={() => {
														postNotificationRead(contribution);
														track(
															1,
															164,
															contribution.topicId,
															contribution.topicName,
															{
																artifactType: 'Topic',
																crowdSourcingType: 'My Approvals',
																complexity: contribution.complexityName,
															}
														);
													}}
												>
													{contribution.topicName}
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="newWrapper ">
									<div className="newColumn date newActTd ">
										{contribution.approvalDate}
									</div>
								</div>
								<div className="newWrapper ">
									<div
										className={`newColumn status newActTd contributionsInfo ${handleStatusClassName(
											contribution.documentStatus
										)}`}
										tabIndex={0}
										role="button"
										aria-label={contribution.documentStatus}
										onClick={() => {
											if (contribution.documentStatus === 'Pending Approval') {
												setShowApproversOrReviewersList(true);
												setSelectedArtifactId(contribution.uniqueId);
											}
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												if (
													contribution.documentStatus === 'Pending Approval'
												) {
													setShowApproversOrReviewersList(true);
													setSelectedArtifactId(contribution.uniqueId);
												}
											}
										}}
										onMouseEnter={() => {
											setDocumentStatusId(contribution.uniqueId);
											getUserDetails(
												contribution.contributorEmail,
												contribution.reviewerEmail
													? contribution.reviewerEmail
													: contribution.reviewerRejectedEmail,
												contribution.approverEmail
													? contribution.approverEmail
													: contribution.approverRejectedEmail
											);
										}}
										onMouseLeave={() => {
											setDocumentStatusId(-1);
										}}
										onBlur={(e) => {
											setDocumentStatusId(-1);
										}}
										onFocus={(e) => {
											setDocumentStatusId(contribution.uniqueId);
											getUserDetails(
												contribution.contributorEmail,
												contribution.reviewerEmail
													? contribution.reviewerEmail
													: contribution.reviewerRejectedEmail,
												contribution.approverEmail
													? contribution.approverEmail
													: contribution.approverRejectedEmail
											);
										}}
									>
										{contribution.documentStatus
											? contribution.documentStatus
											: '-'}
										{documentStatusId === contribution.uniqueId ? (
											<MyApprovalsInfo
												contributorEmail={contribution.contributorEmail}
												reviewerEmail={
													contribution.reviewerEmail
														? contribution.reviewerEmail
														: contribution.reviewerRejectedEmail
												}
												approverEmail={
													contribution.approverEmail
														? contribution.approverEmail
														: contribution.approverRejectedEmail
												}
												contributionData={contribution}
												contributorsInfo={contributorsInfo}
												type="myApprovals"
												documentStatus={contribution.documentStatus}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</div>
						{showApproversOrReviewersList &&
						contribution.uniqueId === selectedArtifactId ? (
							<ReviewersOrApproversList
								showList={setShowApproversOrReviewersList}
								setShowList={setShowApproversOrReviewersList}
								actionTypeId={
									contribution.documentStatus === 'Pending Review' ? 1 : 2
								}
								artifactId={contribution.topicId}
								artifactType="topics"
								setSelectedArtifactId={setSelectedArtifactId}
							/>
						) : (
							<></>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default MyApprovals;
