import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';

import './TemplateCrowdSourcing.css';
import Breadcrumbs from '../Breadcrumbs';
import Pagination from '../Pagination';
import TemplateReviewCard from './TemplateReviewCard';
import { useAxios } from '../../context/AxiosContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';

import { useAppInsights } from '../../context/TelemetryContext';

function TemplateReviewAll() {
	const pageLimit = 10;
	const { axiosGetService } = useAxios();
	const { track } = useAppInsights();
	const [currentPage, setCurrentPage] = useState(1);
	const [templateCount, setTemplateCount] = useState(0);
	const [showPagination, setShowPagination] = useState(true);
	const [templateContributions, setTemplateContributions] = useState([]);
	const [complexities, setComplexities] = useState({});
	const [contributorInfo, setContributorInfo] = useState({});
	const [ratingPoints, setRatingPoints] = useState(null);
	const [userRatingPoints, setUserRatingPoints] = useState(null);
	const [ratingStars, setRatingStars] = useState(null);

	const getRatingPoints = async () => {
		await axiosGetService(`api/sdmuser/gamification/getRatingPoints`).then(
			(response) => {
				setRatingPoints(response.data);
			}
		);
	};

	const getContributions = async () => {
		setTemplateContributions([]);
		setTemplateCount(0);
		await axiosGetService(
			`api/sdmtemplate/getReviewer/${pageLimit}/${currentPage}`
		).then((response) => {
			if (response.data && response.data.templatesCount !== 0) {
				setTemplateContributions(response.data.sdmTemplate2);
				setTemplateCount(response.data.templatesCount);
				getAllContributors(response.data.sdmTemplate2);
			} else {
				setTemplateContributions(null);
			}
			getComplexities();
		});
	};

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity')
			.then((res) => {
				const complexityObject = {};
				res.data.map((item) => {
					complexityObject[item.id] = item.name;
				});
				return complexityObject;
			})
			.then((complexityObject) => setComplexities(complexityObject));
	};

	const getAllContributors = async (data) => {
		const accessToken = await axiosTokenService();
		const contributors = contributorInfo;
		for (let i = 0; i < data.length; i++) {
			const userEmail = data[i].contributorEmail;
			if (!Object.keys(contributors).includes(userEmail)) {
				contributors[userEmail] = await getOtherUserProfile(
					accessToken,
					userEmail
				);
			}
		}
		setContributorInfo({
			...contributors,
			...contributorInfo,
		});
	};

	useEffect(() => {
		getContributions();
	}, [currentPage]);

	useEffect(() => {
		getRatingPoints();
		track(2, 153, 'All', 'Review All Templates');
	}, []);

	return (
		<div className="templateCrowdSourcingMainContainer">
			<div className="templateCrowdSourcingBreadcrumb">
				<Breadcrumbs />
			</div>
			<div className="templateCrowdSourcingContainer">
				<div className="templateCrSourcingListWrapper">
					<div className="templateFixedHeaderPart">
						<div className="templateCrSourcingTitleWrapper">
							<h4>
								Suggested Templates <span>(Pending for review)</span>
							</h4>
						</div>

						{templateContributions === null ? (
							<MessageBar
								messageBarType={MessageBarType.warning}
								isMultiline={false}
							>
								There are no templates for you to review
							</MessageBar>
						) : (
							<div
								className="newTableParentRow header"
								id="templateCrowdSourcing"
							>
								<div className="newTableRow">
									<div className="newWrapper newAttributes">
										<div className="newWrapper newTitleCommentModuleReporter ">
											<div className="newWrapper newTitleComment ">
												<div className="newColumn newTitle newActTd ">
													<span className="templateNewTitle">
														Template Name
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn templateComplexity newActTd ">
											Complexity
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn uploadDetails newActTd ">
											Upload Details
										</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn details newActTd ">Details</div>
									</div>
									<div className="newWrapper ">
										<div className="newColumn approveAndReject newActTd ">
											Approve/Reject
										</div>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="listOfTemplateCrSourcing">
						{templateContributions &&
							templateContributions.map((contribution) => (
								<TemplateReviewCard
									data={contribution}
									complexities={complexities}
									key={contribution.title}
									singleView={false}
									getContributions={getContributions}
									contributorInfo={contributorInfo}
									setContributorInfo={setContributorInfo}
									getAllContributors={getAllContributors}
									key={JSON.stringify(contribution)}
									ratingPoints={ratingPoints}
									userRatingPoints={userRatingPoints}
									setUserRatingPoints={setUserRatingPoints}
									ratingStars={ratingStars}
									setRatingStars={setRatingStars}
								/>
							))}
					</div>
				</div>
			</div>

			<div
				className={`templatePagination ${
					templateCount <= pageLimit ? 'hidePagination' : ''
				}`}
			>
				{showPagination ? (
					<Pagination
						pageCount={Math.ceil(templateCount / pageLimit)}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default TemplateReviewAll;
