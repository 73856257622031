import React, { useState, useEffect } from 'react';

import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const HashtagSuggestions = ({
	data,
	className,
	getHashtag,
	searchValue,
	enableDropdown,
	setSearchValue,
	existingHashtags,
	setExistingHashtags,
	setHashtagSuggestions,
}) => {
	const [visible, setVisible] = useState(false);

	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};

	const selectHashtagFromSuggestions = async (item) => {
		const updatedHashtag = existingHashtags.filter((hashTag) => hashTag === item);
		if (updatedHashtag.length === 0) {
			setExistingHashtags([item, ...existingHashtags]);
			setHashtagSuggestions([]);
			setSearchValue('');
			setVisible(false);
		}
	};

	useEffect(() => {
		if (data.length > 0) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [data]);

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div id={className}>
			<div
				className={`dropDowncontainer hashtagsDropDownContainer ${className} ${
					enableDropdown ? '' : 'disableDropdown'
				}`}
			>
				<input
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					onFocus={() => setVisible(true)}
					placeholder="enter #Hashtags"
					title="enter hashtags"
					onKeyPress={(e) => {
						if (e.which === 13) {
							getHashtag('save');
						}
					}}
				/>
				<div>
					{data.length > 0 ? (
						<div className="dropDownList hashtagDpList" style={{ display: visible ? 'block' : '' }}>
							<ul>
								{data.map((item) => {
									if (item.type === 'header') {
										return (
											<li className="dropdownHeader" title={item.name + ' Header'} key={item.id}>
												<span>{item.name}</span>
											</li>
										);
									}

									return (
										<li title={item.name} key={item.id}>
											<span
												className="complexityLabel"
												onClick={() => {
													selectHashtagFromSuggestions(item.name);
												}}
											>
												{item.name}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default HashtagSuggestions;
