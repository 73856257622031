import React, { useEffect, useState } from 'react';

import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';



import './ExternalApps.css';
function ExternalApps() {
	const { track } = useAppInsights();
	const [copilotUsers, setCopilotUsers] = useState([])
	const { axiosGetService, userAlias } = useAxios();

	const getCopilotDataExtractUsers = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=CopilotDataExtractUsers`).then(
			(response) => {
				const updatedResponse = response.data[0].value.split(',')
				setCopilotUsers(updatedResponse)
			}
		);
	};

	const getExtractCopilot = async (fileType) => {
		toast.info('Copilot data extraction is in progress. You will receive an email once it is complete.', {
			position: toast.POSITION.TOP_RIGHT,
		});
		await axiosGetService(
			`api/sdmsolutionmethod/extractcopilotdata?userAlias=${userAlias}`
		).then(() => {
		});
	};

	useEffect(() => {
		getCopilotDataExtractUsers()
	}, [])
	
	
	return (
		<div className="externalAppMainDiv">
			<div className="commonContApps">
				<a
					href="http://aka.ms/ose"
					target="_blank"
					aria-label="Click to go to OSE"
					onClick={() => track(1, 6, 'otherTools_ose', 'OSE')}
				>
					<span className="extImgForOse"></span>
					<span className="externalAppTitle">OSE</span>
					<span className="forwardIcon"></span>
				</a>
			</div>
			<div className="commonContApps">
				<a
					href="http://aka.ms/virtuoso"
					target="_blank"
					onClick={() => track(1, 6, 'otherTools_virtuoso', 'Virtuoso')}
					aria-label="Click to go to Virtuoso"
				>
					<span className="extImgForVirtuso"></span>
					<span className="externalAppTitle">Virtuoso</span>
					<span className="forwardIcon"></span>
				</a>
			</div>
			<div className="commonContApps">
				<a
					href="http://aka.ms/SEE"
					target="_blank"
					onClick={() => track(1, 6, 'otherTools_campus', 'Campus')}
					aria-label="Click to go to SEE"
				>
					<span className="extImgForCampus"></span>
					<span className="externalAppTitle">SEE</span>
					<span className="forwardIcon"></span>
				</a>
			</div>
			<div className="commonContApps">
				<a
					href="http://aka.ms/esdevops"
					target="_blank"
					onClick={() => track(1, 6, 'otherTools_devOps', 'DevOps')}
					aria-label="Click to go to DevOps"
				>
					<span className="extImgForDevops"></span>
					<span className="externalAppTitle">DevOps</span>
					<span className="forwardIcon"></span>
				</a>
			</div>
			<div className="commonContApps">
				<a
					href="https://aka.ms/msbr"
					target="_blank"
					onClick={() => track(1, 6, 'otherTools_BusinessRule', 'BusinessRule')}
					aria-label="Click to go to Business Rule"
				>
					<span className="msbrIcon"></span>
					<span className="externalAppTitle">Business Rule</span>
					<span className="forwardIcon"></span>
				</a>
			</div>
			{copilotUsers.includes(userAlias) && (
				<div className="commonContApps cursorPointer" onClick={() => getExtractCopilot()}>
					<a
						onClick={() => track(1, 6, 'otherTools_Copilot', 'Copilot')}
						aria-label="Click here for Extract Copilot data"
					>
						<span className="copilotIcon copilotextract"></span>
						<span className="externalAppTitle">Copilot Data Extract</span>
						<span className="forwardIcon"></span>
					</a>
				</div>
			)}
		</div>
	);
}

export default ExternalApps;
