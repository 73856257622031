import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useAppInsights } from '../../../context/TelemetryContext';
import MultiSelectDropdown from './MultiSelectDropdown';
import Spinner from '../../../helpers/Spinner';
import LeftMenu from '../../LeftMenu/LeftMenu.css'

const EditRole = ({ setCurrentTab }) => {
	const [roles, setRoles] = useState([]);
	const [expandedRoles, setExpandedRoles] = useState([]);
	const [newRole, setNewRole] = useState(null);
	const { axiosGetService, axiosPostService } = useAxios();
	const [expandedNewRole, setExpandedNewRole] = useState(false);
	const [roleGroups, setRoleGroups] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);
	const [selectedRole, setSelectedRole] = useState({});

	const { track } = useAppInsights();

	const saveRole = async (updatedRole) => {
		if (getRoleMessage(updatedRole) !== '') {
			toast.error(getRoleMessage(updatedRole), {
				position: 'top-right',
			});
			return;
		}
		if (
			roles.some(
				(role) => role.name === updatedRole.name && updatedRole.id !== role.id
			)
		) {
			toast.error('Role already exists', {
				position: 'top-right',
			});
			return;
		}
		const postBody = {
			updatedRoles: [
				{
					...updatedRole,
					roleGroupIds: updatedRole.roleGroups.map(({ id }) => id).toString(),
				},
			],
		};
		const response = await axiosPostService(
			'api/sdmrole/updateRolesForAdmin',
			postBody
		);
		if (response.data.status) {
			toast.info('Role updated successfully', {
				position: 'top-right',
			});
			if (!updateRole.id) {
				setNewRole({ name: '', roleAbbr: '', roleGroups: [] });
			}
			getRoles();
		} else {
			toast.error(response.data.errorMsg, {
				position: 'top-right',
			});
		}
	};
	const deleteRole = async (role) => {
		const postBody = {
			deletedRoleIds: role.id,
		};
		const response = await axiosPostService(
			'api/sdmrole/updateRolesForAdmin',
			postBody
		);
		if (response.data.status) {
			toast.info('Roles deleted successfully', {
				position: 'top-right',
			});
			getRoles();
		} else {
			toast.error(response.data.errorMsg, {
				position: 'top-right',
			});
		}
	};
	const getRoleMessage = (role) => {
		if (role.name.length === 0) return 'Role name cannot be empty';
		if (role.name.length > 50)
			return 'Role name cannot be more than 50 characters';
		if (role.roleAbbr.length === 0) return 'Abbreviation cannot be empty';
		if (role.roleAbbr.length > 50)
			return 'Abbreviation cannot be more than 50 characters';
		return '';
	};
	const toggleRoleEditor = (roleId) => {
		if (expandedRoles.includes(roleId)) {
			setExpandedRoles(expandedRoles.filter((id) => id !== roleId));
		} else {
			setExpandedRoles([...expandedRoles, roleId]);
		}
	};
	const updateRole = (roleIndex, propetyName, newValue) => {
		const newRoles = [...roles];
		newRoles[roleIndex][propetyName] = newValue;
		setRoles(newRoles);
	};
	const getRoleGroups = async () => {
		const response = await axiosGetService(`api/sdmrole/getRoleGroups`);
		setRoleGroups(
			response.data.map(({ roleGroupId, roleGroupName }) => {
				return { id: roleGroupId.toString(), name: roleGroupName };
			})
		);
	};
	const getRoles = async () => {
		const response = await axiosGetService(`api/sdmrole/getRolesForAdmin`);
		setShowSpinner(true);
		setRoles(
			response.data.map((role) => {
				return {
					...role,
					roleGroups:
						role.roleGroupIds?.split(',').map((id) => {
							return { id };
						}) ?? [],
				};
			})
		);
		setShowSpinner(false);
	};
	useEffect(() => {
		getRoleGroups();
		getRoles();
		setNewRole({ name: '', roleAbbr: '', roleGroups: [] });
	}, []);
	return (
		<>
			<div class="middleDetailsContainer">
				<div class="methodOwnerMiddleContainer">
					{showSpinner ? (
						<Spinner />
					) : (
						<div class="methodPhaseDetailsTab">
							<div class="roleAdditionContainer">
								<div class="addRoleButtonAction">
									<div
										class="selectAddRoleButton"
										onClick={() => setExpandedNewRole(!expandedNewRole)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setExpandedNewRole(!expandedNewRole);
											}
										}}
									>
										+&nbsp;Add a New Role
									</div>
									{expandedNewRole && (
										<div class="addRoleFields">
											<div class="addRoleNameContainer">
												<span class="AddRoleFieldLabel">Role Title *</span>
												<input
													title=""
													placeholder="Enter Role Title"
													value={newRole.name}
													onChange={(e) =>
														setNewRole({
															...newRole,
															name: e.target.value,
														})
													}
												/>
											</div>
											<div class="addRoleNameContainer">
												<span class="AddRoleFieldLabel">Role Groups</span>
												<MultiSelectDropdown
													id={`RoleGroupRoleIds`}
													className="addRolesGroup"
													data={roleGroups}
													value={newRole.roleGroups}
													label="Role Groups"
													enableDropdown={true}
													setValue={(val) =>
														setNewRole({
															...newRole,
															roleGroups: val,
														})
													}
												/>
											</div>
											<div class="addRoleNameContainer">
												<span class="AddRoleFieldLabel">Abbreviation*</span>
												<input
													title=""
													placeholder="Enter Abbreviation"
													value={newRole.roleAbbr}
													onChange={(e) =>
														setNewRole({
															...newRole,
															roleAbbr: e.target.value,
														})
													}
												/>
											</div>

											<div>
												<span
													className="addRoleIcon"
													title="Add Role"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															saveRole(newRole);
														}
													}}
													onClick={() => saveRole(newRole)}
												></span>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="addRolesListContainer">
								<ul>
									{roles.map((role, index) => (
										<li key={index}>
											<div class="addRoleButtonAction">
												<div class="addRoleTitleEditPart">
													<h6>{role.name}</h6>
													<div className="editRoleDetailsDiv">
														<span
															className="editRoleIcon"
															onClick={() => {
																toggleRoleEditor(role.id);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	toggleRoleEditor(role.id);
																}
															}}
															title="Edit Role"
														></span>
														{selectedRole?.id === role.id && (
															<div className="deleteSamplesPopUp">
																<p>
																	{selectedRole.canBeDeleted
																		? `Are you sure that you want to delete this Role ?`
																		: 'Please De-link role from Activities before deleting this role'}
																</p>
																<div>
																	<button
																		onClick={() => {
																			setSelectedRole({});
																		}}
																		tabIndex={0}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setSelectedRole({});
																			}
																		}}
																	>
																		Cancel
																	</button>
																	{selectedRole.canBeDeleted && (
																		<button
																			onClick={() => {
																				deleteRole(role);
																			}}
																			tabIndex={0}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					deleteRole(role);
																				}
																			}}
																		>
																			Delete
																		</button>
																	)}
																</div>
															</div>
														)}
														<span
															className="deleteRoleIcon"
															onClick={() => setSelectedRole(role)}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setSelectedRole(role);
																}
															}}
															title="Delete Role"
														></span>
													</div>
												</div>
												{expandedRoles.includes(role.id) && (
													<div class="addRoleFields">
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">
																Role Title *
															</span>
															<input
																title=""
																placeholder="Role Title"
																value={role.name}
																onChange={(e) =>
																	updateRole(index, 'name', e.target.value)
																}
															/>
														</div>
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">Role Groups</span>
															<MultiSelectDropdown
																id={`RoleGroupRoleIds_${role.id}`}
																className="addRolesGroup"
																data={roleGroups}
																value={role.roleGroups}
																label="Role Groups"
																enableDropdown={true}
																setValue={(val) =>
																	updateRole(index, 'roleGroups', val)
																}
															/>
														</div>
														<div class="addRoleNameContainer">
															<span class="AddRoleFieldLabel">
																Abbreviation*
															</span>
															<input
																title=""
																placeholder="Abbreviation"
																value={role.roleAbbr}
																onChange={(e) =>
																	updateRole(index, 'roleAbbr', e.target.value)
																}
															/>
														</div>
														<div class="criteriaSaveUpdate">
															<button
																tabIndex={0}
																onClick={() => saveRole(role)}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		saveRole(role);
																	}
																}}
															>
																Update
															</button>
														</div>
													</div>
												)}
												{expandedRoles.includes(role.id) && (
													<p className="extractMethodologyMessage">
														{getRoleMessage(role)}
													</p>
												)}
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default EditRole;
