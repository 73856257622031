import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Icon } from "@fluentui/react";
import { handleTernaryCondition, } from '../../helpers/Validation';
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import Breadcrumbs from "../Breadcrumbs";
import { toast } from 'react-toastify';
import { useAxios } from "../../context/AxiosContext";
import './PolygonBuilder.css';
import PolygonBuilder from "./PolygonBuilder";
import { Link } from 'react-router-dom';
import DeleteModalPolygon from "./DeleteModalPolygon";
import Shapes from "./Shapes";
import { COACHMARK_ATTRIBUTE_NAME } from "office-ui-fabric-react";
import TopicsSequence from "../TopicsBuilder/TopicsSequence";

const AddNewPolygon = () => {
    const tabs = [
        { id: 1, name: 'Step 1' },
        { id: 2, name: 'Step 2' },
        { id: 3, name: 'Sequence' },
    ];
    const { breadcrumbs, setBreadcrumbs, isAdmin } = useLeftMenuCollapse();
    const [showDeleteTopicModal, setShowDeleteTopicModal] = useState(false);
    const [deleteTopicInfo, setDeleteTopicInfo] = useState({});
    const [selectedTab, setSelectedTab] = useState(1);
    const [categoryName, setCategoryName] = useState("");
    const [primaryWarningMsg, setPrimaryWarningMsg] = useState("");
    const [secondaryWarningMsg, setSecondaryWarningMsg] = useState("");
    const { axiosGetService, axiosPostService, userAlias } = useAxios();
    const { categoryId, topicId } = useParams();

    const [count, setCount] = useState(4);
    const [selectedSection, setSelectedSection] = useState(['addNewTopics']);
    const [selectedPath, setSelectedPath] = useState(0)
    const [postData, setPostData] = useState([])
    const [topicsData, setTopicsData] = useState([])
    const [isBack, setIsBack] = useState(false)
    const [deletedTopic, setDeletedTopic] = useState(false);
    const [discardTopic, setDiscardTopic] = useState(true);
    const [parentTopicName, setParentTopicName] = useState("");
    const [showLinking, setLinking] = useState(false);

    const [topics, setTopics] = useState([]);
    const [polygonTopicTitle, setPolygonTopicTitle] = useState('');

    const updatePostData = () => {
        setPostData(prevData =>
            prevData.map(item => ({ ...item, polygonSides: item.polygonSides + 1 }))
        );
    };

    const handleIncrementCounter = () => {
        if (topicId !== undefined && topicId !== null) {
            if (count < 7) {
                const noOfTopic = parseInt(postData.length);

                updatePostData();
                setCount((prevState) => prevState + 1);

                setTimeout(() => {
                    const Data = {
                        "topicId": 0,
                        "imagePaths": [],
                        "topicName": `Topic ${noOfTopic}`,
                        "diagramName": `<p>Topic ${noOfTopic}</p>`,
                        "description": "",
                        "categoryId": categoryId,
                        "isVisible": true,
                        "isCrowdSourceable": false,
                        "reviewerMembersList": [],
                        "approverMembersList": [],
                        "processGroupIds": "",
                        "HashTags": "",
                        "topicRelatedContentList": [],
                        "toolIds": "",
                        "roleIds": "",
                        "owner": userAlias,
                        "coOwnerMembersList": [],
                        "topicType": 3,
                        "sequenceTopicIds": "",
                        "polygonSides": noOfTopic + 1,
                        "polygonSequence": noOfTopic,
                        "polygonParent": topicId
                    }
                    setPostData([...postData, Data])
                }, 2000);
            } else {
                toast.info('You have reached the maximum number of Topics required in the Group.', {
                    position: 'top-right',
                });
            }
        } else {
            if (count < 7) {
                setCount((prevState) => prevState + 1);
            } else {
                toast.info('You have reached the maximum number of Topics required in the Group.', {
                    position: 'top-right',
                });
            }
        }
    };

    const handleDecrementCounter = () => {
        if (topicId !== undefined && topicId !== null) {
            toast.info('You cannot the decrease the no of topics.You can delete the topic from given below list.', {
                position: 'top-right',
            });
        } else {
            if (count > 4) {
                setCount((prevState) => prevState - 1);
            } else {
                toast.info('You have reached the minimum number of Topics required in the Group.', {
                    position: 'top-right',
                });
            }
        }
    };

    const getTopicsCategoryDataForAdmin = async (categoryId) => {
        try{
            await axiosGetService(
                `api/sdmtopiccategories/getbyid?topicCategpryId=${categoryId}`
            ).then((response) => {
                if(response.data){
                    setCategoryName(response.data.name);
                }
            });
        } catch(e) {
            console.log(e);
        }
    };

    const createPostData = (topicName, count, sides) => {
        const Data = {
            "topicId": 0,
            "imagePaths": [],
            "topicName": topicName,
            "diagramName": `<p>${topicName}</p>`,
            "description": "",
            "categoryId": categoryId,
            "isVisible": true,
            "isCrowdSourceable": false,
            "reviewerMembersList": [],
            "approverMembersList": [],
            "processGroupIds": "",
            "HashTags": "",
            "topicRelatedContentList": [],
            "toolIds": "",
            "roleIds": "",
            "owner": userAlias,
            "coOwnerMembersList": [],
            "topicType": 3,
            "sequenceTopicIds": "",
            "polygonSides": sides,
            "polygonSequence": count,
            "polygonParent": 0
        }
        setPostData(prev => [...prev, Data])
    }

    const createPolygonPostData = () => {
        for (let i = 0; i < count; i++) {
            if (i === 0) {
                createPostData('Overview', i, count)
            } else {
                createPostData(`Topic ${i}`, i, count)
            }
        }
    }

    const saveNextTopic = (category) => {
        setSelectedPath(category);
    }

    const getPolygonTopic = async () => {
        try {
            if (topicId !== null && topicId !== undefined) {
                await axiosGetService(`api/sdmtopics/getpolygontopicbyid/${topicId}`,).then((response) => {
                    const parentData = response.data.find((item) => item.topicId.toString() === topicId);
                    setCount(parseInt(parentData.polygonSides));
                    setParentTopicName(parentData.topicName.replace(/_.*/, ''));
                    setPostData(response.data);
                    setDiscardTopic(false);
                })
            }
        } catch (error) {
            console.log("Error", error);
        }
    }
    const handleNext = async () => {
        var postPolygonTopicData = {};
        if (topicId !== null && topicId !== undefined) {
            postPolygonTopicData.lstSdmTopicDraft = postData;
            postPolygonTopicData.parentTopicId = parseInt(topicId);
        } else {
            postPolygonTopicData.lstSdmTopicDraft = postData;
            postPolygonTopicData.parentTopicId = 0;
        }
        try {
            await axiosPostService('api/sdmtopics/adddraftpolygon', postPolygonTopicData).then((res) => {
                res.data.forEach(element => {
                    element.topicName = element.topicName.split("__")[0]
                });
                setTopicsData(res.data)
                setSelectedTab(2)
            })
        } catch (error) {
            console.log(error, 'ERROR')
        }
    }

    const deleteTopicPolygon = (item) => {
        if (item.polygonSides === 4) {
            setPrimaryWarningMsg("You have reached the minimum number of Topics required in the Group. Further deletion of Topics is not allowed.");
            setSecondaryWarningMsg("But you can delete the Entire Group, which is irreversible. Do you want to proceed?");
            setDeleteTopicInfo({
                id: item.polygonParent,
                title: item.topicName.replace(/_.*/, ''),
                sides: item.polygonSides,
            });
        }
        else {
            setPrimaryWarningMsg("Are you sure you want to Delete the topic?");
            setSecondaryWarningMsg("Deletion of topic will lead to loss of all the topic content and cannot be recovered.");
            setDeleteTopicInfo({
                id: item.topicId,
                title: item.topicName.replace(/_.*/, ''),
                sides: item.polygonSides,
            });
        }
        setShowDeleteTopicModal(true);
    }

    useEffect(() => {
            getTopicsCategoryDataForAdmin(categoryId);
    }, []);

    useEffect(() => {
        if (topicId !== undefined && topicId !== null) {
            getPolygonTopic();
        }
    }, [topicId, deletedTopic]);


    useEffect(() => {
        if (topicId === undefined || topicId === null) {
            createPolygonPostData();
            return () => {
                setPostData([])
            }
        }
    }, [count]);

    useEffect(() => {
        if (isAdmin) {
            axiosGetService(
                `api/sdmtopics/getbycategoryid/${categoryId}`
            ).then((response) => {
                const data = response.data.map((item) => {
                    return {
                        id: item.id.toString(),
                        name: item.name,
                        type: item.isDrafted ? 'Draft' : '',
                    };
                });
                data.push({ id: '0', name: polygonTopicTitle, type: 'New' });
                setTopics(data);
            });
        }
    }, [categoryId, isAdmin]);

    useEffect(() => {
        setTopics(
            topics.map((topic) => {
                if (topic.id === 0) {
                    topic.name = polygonTopicTitle;
                }
                return topic;
            })
        );
    }, [selectedTab]);

    return (
        <>
            <div className="topicBulderMainContainer hexagonBulderMainContainer">
                <div className="topicBulderContainer hexagonBulderContainer">
                    <div className="topicBulderHeaderContainer hexagonBulderHeaderContainer mt-0">
                        <h4>Polygon Topics Builder</h4>
                    </div>
                    <div className="topicsMenuContainer">
                        <ul className="topicsMenu" role="tablist">
                            {tabs.map((item) => (item.id !== 3 || isAdmin) && (
                                <li
                                    name={item}
                                    onClick={() => {
                                        if (item.id === 2 && selectedTab !== 1) {
                                            setSelectedTab(2);
                                        } else if (item.id === 3 && selectedTab !== 1) {
                                            setSelectedTab(3);
                                        }
                                    }}
                                    role="tab"
                                    tabIndex="0"
                                    className={handleTernaryCondition(
                                        selectedTab === item.id,
                                        'selectedTopicMenu pt-0',
                                        'notSelectedTopicMenu pt-0'
                                    )}
                                >
                                    <span className="consultingMenu">{item.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {selectedTab == 1 && (
                        <>
                            <div className="topicBulderFieldsContainer polygonTopicBuilder">
                                <div className="topicAdditionCont polygonSelectionBuilder">
                                    <div className="polygonTopicCateogry">
                                        <div className="selectedCategoryLabel" >
                                            <span>Topic Category</span>
                                        </div>
                                        <div className="selectedTopicCategory" >
                                            <span>{categoryName}</span>
                                        </div>
                                    </div>

                                    <div className="polygonTopicCateogry">
                                        <div className="selectedCategoryLabel" >
                                            <span>Number of Topics in this group &nbsp;</span>
                                            <span className="polygonSelectionNote">(Chose between 4 to 7)</span>
                                        </div>
                                        <div className="polygonSelection">
                                            <button
                                                className="btn"
                                                // disabled={topicId && postData && "disabled" }
                                                onClick={handleDecrementCounter}
                                            >
                                                <Icon
                                                    iconName="SkypeMinus"
                                                    tabIndex={0}
                                                    aria-label={`Add `}
                                                />
                                            </button>
                                            <input type="text" value={postData.length > 0 ? count : '' } disabled />
                                            <button
                                                className="btn"
                                                // disabled={topicId && postData && "disabled" }
                                                onClick={handleIncrementCounter}
                                            >
                                                <Icon
                                                    iconName="Add"
                                                    tabIndex={0}
                                                    aria-label={`Add `}
                                                />
                                            </button>
                                        </div>
                                    </div>

                                    {topicId && postData &&
                                        <div className="polygonTopicCateogry">
                                            <div className="selectedCategoryLabel" >
                                                <span>Add/Delete Topics</span>
                                            </div>
                                            <div className="polygonTopicslisting">
                                                <table>
                                                    {postData?.map((item) => (
                                                        <tr>
                                                            <td>
                                                                {item.topicName.replace(/_.*/, '')}
                                                                {item.polygonSequence !== 0 ?
                                                                    <span
                                                                        className="topicOwnerDeletIcon bigTopicOwnerDeletIcon"
                                                                        title={"Delete Topic"}
                                                                        tabIndex="0"
                                                                        onKeyPress={(e) => {
                                                                            if (e.which === 13) {
                                                                                deleteTopicPolygon(item);
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            deleteTopicPolygon(item);
                                                                        }}
                                                                    ></span>
                                                                    :
                                                                    <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    }

                                </div>
                                {postData.length > 0 ?
                                    <div className="polygonSelectedShape">
                                        <Shapes
                                            count={count}
                                            selectedPath={selectedPath}
                                            topicsData={postData}
                                            saveNextTopic={saveNextTopic}
                                            showLinking={showLinking}
                                            setLinking={setLinking}
                                        />
                                    </div>
                                    : <></>
                                }
                            </div>
                            <div className="hexagonSaveContainer">
                                <div className="polygonStepsButtons">
                                    <Link to='/topics'>
                                        <button className="polygonBackBtn">
                                            Cancel
                                        </button>
                                    </Link>
                                </div>
                                {/* <Link to='/topics'>
                                    <button className="hexagonCancelButton hexagonSaveContainerbutton">
                                        Cancel
                                    </button>
                                </Link> */}
                                <div className="topicBulderHeaderContainer">
                                    <div className="topicBulderHeaderButtons">
                                        <button
                                            onClick={handleNext}
                                            title="Next"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* <button className="hexagonSaveButton hexagonSaveContainerbutton"
                                    onClick={handleNext}>
                                    Next
                                </button> */}
                            </div>
                        </>
                    )}
                    {selectedTab === 2 && (
                        <PolygonBuilder
                            selectedSection={selectedSection}
                            count={count}
                            setPolygonSelectedTab={setSelectedTab}
                            setTopicsData={setTopicsData}
                            setTopics={setTopics}
                            topics={topics}
                            setPolygonTopicTitle={setPolygonTopicTitle}
                            polygonTopicTitle={polygonTopicTitle}
                            topicsData={topicsData}
                            setIsBack={setIsBack}
                            setDiscardTopic={setDiscardTopic}
                            discardTopic={discardTopic}
                        />
                    )}
                    {selectedTab === 3 && (
                        <TopicsSequence topics={topics} setTopics={setTopics} />
                    )}
                </div>
            </div>
            {showDeleteTopicModal ? (
                <DeleteModalPolygon
                    primaryWarningMsg={primaryWarningMsg}
                    setPrimaryWarningMsg={setPrimaryWarningMsg}
                    secondaryWarningMsg={secondaryWarningMsg}
                    setSecondaryWarningMsg={setSecondaryWarningMsg}
                    deleteTopicInfo={deleteTopicInfo}
                    setDeleteTopicInfo={setDeleteTopicInfo}
                    setShowDeleteTopicModal={setShowDeleteTopicModal}
                    setDeletedTopic={setDeletedTopic}
                    deletedTopic={deletedTopic}
                    parentTopicName={parentTopicName}
                    postData={postData}
                    setPostData={setPostData}
                    count={count}
                    setCount={setCount}
                />
            ) : (
                <></>
            )}
        </>
    );
}

export default AddNewPolygon;