import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Home.css";
import { useAxios } from "../../context/AxiosContext";
import { toast } from "react-toastify";
import { useAppInsights } from "../../context/TelemetryContext";

const PhaseMenu = ({
    isTopPhaseMenu,
    setIsTopPhaseMenu,
    setSelected,
    selected,
    setDynamicPhaseCSS,
    dynamicPhaseCSS
}) => {
	const [isMouseClicked, setIsMouseClicked] = useState(false);
    const [focusedId, setFocusedId] = useState(null);
	const [isShownHover, setIsShownHover] = useState(false);
	const [hoverText, setHoverText] = useState();
	const [hoverId, setHoverId] = useState();
	const [selectedId, setSelectedId] = useState();
	const { axiosGetService } = useAxios();
	const [phases, setPhases] = useState([]);
	const [isProgramDelivery, setIsProgramDelivery] = useState("");
	const [deliveryManagementId,setDeliveryManagementId] = useState('')
	const [realizeID,setRealizeID] = useState('')
	const { track } = useAppInsights();
	const [mcemPhases, setMcemPhases] = useState([]);


	const handleHover = (e) => {
		setHoverId(e.phaseId);
		setHoverText(e.phaseHoverDescription);
		setIsShownHover(true);
	};

	const handleSelected = (event) => {
		if (typeof event !== 'undefined') {
			setSelectedId(event.phaseId);
			setIsTopPhaseMenu(true);
		} else {
			setSelectedId(parseInt(window.location.href.split("/").at(8)));
		}
		setSelected(true);
	};

	useEffect(() => {
		handleSelected();
	}, [])

	const handleHoverOut = () => {
		setHoverId("");
		setHoverText("");
		setIsShownHover(false);
	};

    const handleFocus = (phaseId) => {
        if (focusedId !== phaseId) {
            setFocusedId(phaseId);
			setIsMouseClicked(false); 
            const phase = phases.find(p => p.phaseId === phaseId);
            if (phase) {
                handleHover(phase);
            }
        }
    };

    const handleBlur = () => {
        if(!isMouseClicked){
			setFocusedId(null);
			handleHoverOut();
		}
		setIsMouseClicked(false);
    };

	const getPhases = async () => {
		axiosGetService(`api/sdmquicklink/getmcemphaseshovercontent`)
			.then((res) => {
				if (res.status === 200) {
					setPhases(res.data);
					setDynamicPhaseCSS("phasesContentQuick" + res.data.length);
				}
			})
			.catch((err) => {
				toast.error(err, {
					position: toast.POSITION.TOP_RIGHT,
				});
			});
	};

	const getNonRealizeMCEMstagesWithRV = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=NonRealizeMCEMstagesWithRV`).then(
			(response) => {
				if(response.status === 200){
				setMcemPhases(response.data[0].value.split(','));
				}
			}
		).catch((err) => {
			console.log(err);
		});
	};

	const getDeliveryManagementId = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=DeliveryManagementId`).then(
			(response) => {
				setDeliveryManagementId(response.data[0].value)
			}
		).catch((err) => {
			console.log(err);
		});
	};

	const getRealizeValue = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=RealizeValue`).then(
			(response) => {
				setRealizeID(response.data[0].value)
			}
		).catch((err) => {
			console.log(err);
		});
	};
	

	useEffect(() => {
		getPhases();
		getProgramDeliveryId();
		getNonRealizeMCEMstagesWithRV();
		getRealizeValue();
		// getIsAdmin();
		// getProjectDeliveryId();
	}, []);

	const getProgramDeliveryId = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SDMProgramDeliveryId`).then(
			(response) => {
				setIsProgramDelivery(response.data[0].value);
			}
		);
	};
	useEffect(()=>{
		getDeliveryManagementId();
	},[])

	return (
		<>
			<div class="phasesTiles">
				{mcemPhases.map((data,index) => {
					var phasesTiles = [];
					if(phases.length > 0){
						phasesTiles = phases?.filter((item) => { 
							if(item !== undefined && (parseInt(data) === parseInt(deliveryManagementId) && parseInt(item?.phaseId) === parseInt(realizeID))){
								return item;
							}else{
								return parseInt(item.phaseId) === parseInt(data);
							}
						});
					}
					return(
						<div
							className={
								selected && selectedId === phasesTiles[0]?.phaseId
									? phasesTiles[0]?.dynamicPhaseCssClass + " selected"
									: phasesTiles[0]?.dynamicPhaseCssClass
							}
							onMouseEnter={() => {
								if(phasesTiles.length > 0){
									handleHover(phasesTiles[0]);
								}
								
							}}
							onMouseLeave={() => {
								handleHoverOut();
							}}
                            onFocus={() => handleFocus(phasesTiles[0]?.phaseId)}
                            onBlur={handleBlur}
                            data-phase-id={phasesTiles[0]?.phaseId}
							onClick={()=>setIsMouseClicked(true)}
							
						>
							{phasesTiles[0]?.phaseName.toLowerCase() === "realize value" ? (
								<>
									<NavLink
										class="custom-tooltip"
										data-tooltip={phasesTiles[0]?.phaseName} 
										id={`phaseId_${index}`}
										tabIndex={0}
										aria-current="page"
										exact={true}
										to={`/project/1/method/${deliveryManagementId}`}
										onClick={() => {
											handleSelected(phasesTiles[0]);
											track(
												1,
												2111,
												deliveryManagementId,
												phasesTiles[0]?.phaseName
											);
										}}
									>
										<span
											className={
												selected && selectedId === phasesTiles[0]?.phaseId
													? phasesTiles[0]?.phaseHoverCssClass + " selected"
													: phasesTiles[0]?.phaseHoverCssClass
											}
										/>
										<p className="blockMenuPhaseName">{phasesTiles[0]?.phaseName} </p>
									</NavLink>
									{isShownHover && hoverId === phasesTiles[0]?.phaseId ? (
										<div
											className="hoverPhaseText"
											dangerouslySetInnerHTML={{ __html: hoverText }}
										></div>
									) : (
										<></>
									)}
								</>
							) : (
								<>
									<NavLink
										class="custom-tooltip"
										data-tooltip={phasesTiles[0]?.phaseName} 
										id={`phaseId_${index}`}
										tabIndex={0}
										aria-current="page"
										exact={true}
										onClick={() => {
											if(phasesTiles.length > 0){
												handleSelected(phasesTiles[0]);
												track(
													1,
													2111,
													phasesTiles[0]?.phaseId,
													phasesTiles[0]?.phaseName
												);
											}
										}}
										to={`/project/${1}/method/${phasesTiles[0]?.solutionMethodId}/phase/${phasesTiles[0]?.phaseId
											}/parentPhase/${null}`}
									>
										<span
											className={
												selected && selectedId === phasesTiles[0]?.phaseId
													? phasesTiles[0]?.phaseHoverCssClass + " selected"
													: phasesTiles[0]?.phaseHoverCssClass
											}
										/>
										<p className="blockMenuPhaseName">{phasesTiles[0]?.phaseName}</p>
									</NavLink>
									{isShownHover && hoverId === phasesTiles[0]?.phaseId ? (
										<div
											className="hoverPhaseText"
											dangerouslySetInnerHTML={{ __html: hoverText }}
										></div>
									) : (
										<></>
									)}
								</>
							)}
						</div>
					)
				})}
				{/* {phases.map((i) => {
					return (
						<div
							className={
								selected && selectedId === i.phaseId
									? i.dynamicPhaseCssClass + " selected"
									: i.dynamicPhaseCssClass
							}
							onMouseEnter={() => {
								handleHover(i);
							}}
							onMouseLeave={() => {
								handleHoverOut();
							}}
						>
							{i.phaseName.toLowerCase() === "realize value" ? (
								<>
									<NavLink
										aria-current="page"
										exact={true}
										to={`/project/1/method/${deliveryManagementId}`}
										onClick={() => {
											handleSelected(i);
											track(
												1,
												2111,
												deliveryManagementId,
												i.phaseName
											);
										}}
									>
										<span
											className={
												selected && selectedId === i.phaseId
													? i.phaseHoverCssClass + " selected"
													: i.phaseHoverCssClass
											}
										/>
										<p className="blockMenuPhaseName">{i.phaseName} </p>
									</NavLink>
									{isShownHover && hoverId === i.phaseId ? (
										<div
											className="hoverPhaseText"
											dangerouslySetInnerHTML={{ __html: hoverText }}
										></div>
									) : (
										<></>
									)}
								</>
							) : (
								<>
									<NavLink
										aria-current="page"
										exact={true}
										onClick={() => {
											handleSelected(i);
											track(
												1,
												2111,
												i.phaseId,
												i.phaseName
											);
										}}
										to={`/project/${1}/method/${i.solutionMethodId}/phase/${i.phaseId
											}/parentPhase/${null}`}
									>
										<span
											className={
												selected && selectedId === i.phaseId
													? i.phaseHoverCssClass + " selected"
													: i.phaseHoverCssClass
											}
										/>
										<p className="blockMenuPhaseName">{i.phaseName}</p>
									</NavLink>
									{isShownHover && hoverId === i.phaseId ? (
										<div
											className="hoverPhaseText"
											dangerouslySetInnerHTML={{ __html: hoverText }}
										></div>
									) : (
										<></>
									)}
								</>
							)}
						</div>
					);
				})} */}
			</div>
		</>
	);
};

export default PhaseMenu;
