import React ,{useState,useEffect} from "react";
import Alert from "react-bootstrap/Alert";
import Button from 'react-bootstrap/Button';
import './TemporaryCkAlert'
import { useAxios } from '../../context/AxiosContext';

function TemporaryCkAlert({width}) {
const [showAlert, setShowAlert] =  useState(true)
const [ckeditorBanner,setCkeditiorBanner] = useState('')
const { axiosGetService } = useAxios();

// const handleClose = ()=>{
//     setShowAlert(false)
// }

// const getCkeditorBanner = async () => {
// 		await axiosGetService(`api/sdmsettings?sdmKey=SDMCKEditiorBanner`).then(
// 			(response) => {
// 				setCkeditiorBanner(response.data[0].value)
// 			}
// 		);
// 	};
//  useEffect(()=>{
//   getCkeditorBanner();
//  },[])

  return (
    // showAlert &&  (
    // <div style={{width:`${width}%`}}> 
    // <Alert variant="warning" onClose={handleClose} dismissible>
    //  {ckeditorBanner}
    // </Alert>
    // </div>
  
    // )

    // This Component can be used to display any Alert Banner text on all Ckeditors
    //in the application
    <></>
    
  )
}

export default TemporaryCkAlert;
