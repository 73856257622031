import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getUsersList } from '../../helpers/GraphService';

import TopicCategoryForm from './Form';

const TopiCategory = ({
	showTopicCategoryModal,
	setShowTopicCategoryModal,
	consultingTopics,
	categoryId,
	isTopic,
	procedureTypeId,
	projectTypeId,
	projectType,
	setSelectedCategoryInfo,
	getConsultingOrSupportTopics,
}) => {
	const { axiosGetService, axiosPostService } = useAxios();
	const [categoryName, setCategoryName] = useState('');
	const [isVisible, setIsVisible] = useState(true);
	const [categoryType, setCategoryType] = useState({
		id: projectTypeId,
		name: projectType,
	});
	const [procedureType, setProcedureType] = useState({});
	const [owner, setOwner] = useState({});
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [ownerEmail, setOwnerEmail] = useState('');
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		categoryOwner: [],
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		categoryOwner: false,
	});
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		categoryOwner: false,
	});
	const [showSpinner, setShowSpinner] = useState(true);
	const [topicCategories, setTopicCategories] = useState([]);
	const [topicCategoryId, setTopicCategoryId] = useState(categoryId);
	const [sequence, setSequence] = useState([]);
	const [categoryData, setCategoryData] = useState({});

	const getOwnerInfo = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue(response);
		});
	};

	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	const getUserDetails = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
	};

	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUserDetails(userEmail, setValue);
		}
	};

	const getTopicCategories = async () => {
		await axiosGetService(
			`api/sdmtopiccategories/getbycategoryandproceduretype?categoryTypeId=${categoryType.id}&isTopic=${isTopic}&procedureTypeId=${procedureTypeId}`
		).then((response) => {
			if (response.data) {
				const data = response.data.map((item) => {
					return {
						id: item.id.toString(),
						name: item.name,
						type: 'old',
					};
				});
				if (categoryId === 0) {
					data.push({
						id: '0',
						name: categoryName,
						type: 'new',
					});
				}
				setSequence(data);
			}
		});
	};

	const getCategoryDetails = async () => {
		await axiosGetService(
			`api/sdmtopiccategories/getbyid?topicCategpryId=${categoryId}`
		).then((response) => {
			const data = response.data;
			if (data) {
				if (data.owner) {
					getOwnerInfo(data.owner, setOwner);
				}
				setCategoryName(data.name ?? '');
				setCategoryType(
					data.categoryTypes.find(
						(item) => item.id === data.categoryTypeId
					) ?? {
						id: projectTypeId,
						name: projectType,
					}
				);
				const procedureData = data.procedureTypes.map((item) => {
					return {
						id: item.id,
						name: item.title,
					};
				});
				setProcedureType(
					procedureData.find((item) => item.id === data.procedureTypeId) ?? {
						id: 3,
						name: 'None',
					}
				);
				if (data.categoryTypeId) {
					setIsVisible(data.isVisible ?? true);
				}
				getCollaboratorsInfo(data.coOwnerMembersList ?? [], setCoOwners);
				getCollaboratorsInfo(data.reviewerMembersList ?? [], setReviewers);
				getCollaboratorsInfo(data.approverMembersList ?? [], setApprovers);
				setCategoryData(data);
				setShowSpinner(false);
			}
		});
	};

	const saveCategory = async () => {
		const postData = {
			name: categoryName,
			isVisible: isVisible,
			categoryTypeId: categoryType.id,
			procedureTypeId: procedureType.id,
			owner: owner.mail ?? '',
			coOwnerMembersList: coOwners.map((item) => {
				return item.mail;
			}),
			reviewerMembersList: reviewers.map((item) => {
				return item.mail;
			}),
			approverMembersList: approvers.map((item) => {
				return item.mail;
			}),
			topicCategoryIdsInSequence: sequence
				.map((item) => {
					return item.id;
				})
				.toString(),
			id: categoryId,
		};
		await axiosPostService(
			`api/sdmtopiccategories/${categoryId ? 'updatetopiccategory' : 'addtopiccategory'
			}`,
			postData
		).then((response) => {
			if (response.data.status) {
				toast.info('You have successfully saved the category', {
					position: 'top-right',
				});
				setShowTopicCategoryModal(false);
				getConsultingOrSupportTopics(projectTypeId);
				setSelectedCategoryInfo({ categoryId: 0 });
			} else {
				toast.error(response.data.errorMessage, {
					position: 'top-right',
				});
			}
		});
	};

	const updateSequence = () => {
		const updatedSequence = sequence.map((item) => {
			if (item.id === '0' || item.id === categoryId.toString()) {
				return {
					id: categoryId.toString(),
					name: categoryName,
					type: item.type,
				};
			}
			return { id: item.id, name: item.name, type: item.type };
		});
		setSequence(updatedSequence);
	};

	useEffect(() => {
		updateSequence();
	}, [categoryName]);

	useEffect(() => {
		getTopicCategories();
	}, [categoryType.id]);

	useEffect(() => {
		getCategoryDetails();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getUser(coOwnerEmail, 'coOwner');
			} else {
				setError({ ...error, ['coOwner']: false });
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (ownerEmail) {
				getUser(ownerEmail, 'categoryOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, categoryOwner: [] }));
				setError({ ...error, ['categoryOwner']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [ownerEmail]);

	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles topicCategory"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				setShowTopicCategoryModal(false);
				setSelectedCategoryInfo({ categoryId: 0 });
			}}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Topic Category details
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									<li>
										<span>Category details</span>
									</li>
									<li>
										<span>Sequence</span>
									</li>
								</ul>
							</div>
							{showSpinner ? (
								<div className="spinner">
									<Spinner animation="border" />
								</div>
							) : (
								<>
									<div class="middleDetailsContainer">
										<TopicCategoryForm
											categoryName={categoryName}
											setCategoryName={setCategoryName}
											categoryType={categoryType}
											setCategoryType={setCategoryType}
											procedureType={procedureType}
											setProcedureType={setProcedureType}
											owner={owner}
											setOwner={setOwner}
											ownerEmail={ownerEmail}
											setOwnerEmail={setOwnerEmail}
											coOwners={coOwners}
											setCoOwners={setCoOwners}
											coOwnerEmail={coOwnerEmail}
											setCoOwnerEmail={setCoOwnerEmail}
											reviewers={reviewers}
											setReviewers={setReviewers}
											reviewerEmail={reviewerEmail}
											setReviewerEmail={setReviewerEmail}
											approvers={approvers}
											setApprovers={setApprovers}
											approverEmail={approverEmail}
											setApproverEmail={setApproverEmail}
											emailFound={emailFound}
											setEmailFound={setEmailFound}
											usersList={usersList}
											setUsersList={setUsersList}
											error={error}
											setError={setError}
											removeCoOwner={removeCoOwner}
											consultingTopics={consultingTopics}
											isVisible={isVisible}
											setIsVisible={setIsVisible}
											sequence={sequence}
											setSequence={setSequence}
											categoryData={categoryData}
										/>
									</div>
									<div class="saveContinueDetailsContainer">
										<button disabled={!categoryName || !owner.mail ||coOwners.length == 0 ||approvers.length ==0|| reviewers.length == 0} class="forwardButton">
											<span
												class="saveContinueButton"
												onClick={() => {
													if (categoryName) {
														saveCategory();
													}
												}}
												tabIndex={0}
												onKeyPress={(e) => {
													if (e.which === 13) {
														if (categoryName) {
															saveCategory();
														}
													}
												}}
											>
												Save
											</span>
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopiCategory;
