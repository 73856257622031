import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditorBuild from '../classic-editor-with-track-changes';

import '../CkEditor.css';

const appData = {
    // Users data.
    users: [],
    currentUser: null,
    suggestions: [],
};

export default class ActivityContributorTrackChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // You need this state to render the <CKEditor /> component after the layout is ready.
            // <CKEditor /> needs the HTMLElement of the `Sidebar` plugin provided through
            // the `config` property and you have to ensure that is already rendered.
            isLayoutReady: false,

            editorInitialData: null,
            licenseKey: null,
        };

        this.sidebarElementRef = React.createRef();

        // Switch between inline and sidebar annotations according to the window size.
        this.refreshDisplayMode = this.refreshDisplayMode.bind(this);
        // Prevent closing the tab when any action is pending.
        this.checkPendingActions = this.checkPendingActions.bind(this);

        window.addEventListener('resize', this.refreshDisplayMode);
        window.addEventListener('beforeunload', this.checkPendingActions);
    }

    componentDidMount() {
        appData.users = this.props.users;
        appData.currentUser = this.props.currentUser;
        if (this.props.initalSuggestions) {
            appData.suggestions = this.props.initalSuggestions;
        }
        const licenseKey = this.props.licenseKey;
        this.setState({
            // When the layout is ready, you can switch the state and render the `<CKEditor />` component.
            editor: null,
            isLayoutReady: true,
            editorInitialData: this.props.initialData,
            // Save the provided license key in the local storage.
            licenseKey,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.refreshDisplayMode);
        window.removeEventListener('beforeunload', this.checkPendingActions);
        appData.suggestions = [];
    }

    render() {
        return (
            <div className="App">
                <main>
                    <div className="centered topicDetailsCkEditor">{this.renderEditor()}</div>
                </main>
            </div>
        );
    }

    renderEditor() {
        return (
            <div className="row row-editor">
                {/* Do not render the <CKEditor /> component before the layout is ready. */}
                {this.state.isLayoutReady && this.state.editorInitialData && (
                    <CKEditor
                        onReady={(editor) => {
                            this.setState({ editor });
                            editor.execute('trackChanges');
                            editor.execute('acceptAllSuggestions');
                            // editor.isReadOnly = true;
                            editor.enableReadOnlyMode( 'my-feature-id' );
                            const data = editor.getData();
                            this.props.setContent(data);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();

                            this.props.setContent(data);
                        }}
                        editor={ClassicEditorBuild}
                        config={{
                            extraPlugins: [TrackChangesIntegration],
                            sidebar: {
                                container: this.sidebarElementRef.current,
                            },
                            licenseKey: this.state.licenseKey,
                        }}
                        data={this.state.editorInitialData}
                    />
                )}
                <div ref={this.sidebarElementRef} className="sidebar"></div>
            </div>
        );
    }

    refreshDisplayMode() {
        if (!this.state.editor) {
            return;
        }

        const annotations = this.state.editor.plugins.get('Annotations');
        const sidebarElement = this.sidebarElementRef.current;
        const mediumSize = 0;
        const smallerSize = 0;
        if (window.innerWidth < mediumSize) {
            sidebarElement.classList.remove('narrow');
            sidebarElement.classList.add('hidden');
            annotations.switchTo('inline');
        } else if (window.innerWidth < smallerSize) {
            sidebarElement.classList.remove('hidden');
            sidebarElement.classList.add('narrow');
            annotations.switchTo('narrowSidebar');
        } else {
            sidebarElement.classList.remove('hidden', 'narrow');
            annotations.switchTo('wideSidebar');
        }
    }

    checkPendingActions(domEvt) {
        if (!this.state.editor) {
            return;
        }

        if (this.state.editor.plugins.get('PendingActions').hasAny) {
            domEvt.preventDefault();
            domEvt.returnValue = true;
        }
    }
}

class TrackChangesIntegration {
    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const usersPlugin = this.editor.plugins.get('Users');
        const trackChangesPlugin = this.editor.plugins.get('TrackChanges');

        // Load the users data.
        for (const user of appData.users) {
            usersPlugin.addUser(user);
        }
        usersPlugin.defineMe(appData.currentUser);

        trackChangesPlugin.adapter = {
            getSuggestion: (suggestionId) => {
                // This function should query the database for data for a suggestion with a `suggestionId`.
                return new Promise((resolve) => {
                    for (const data in appData.suggestions) {
                        if (JSON.parse(appData.suggestions[data]).id === suggestionId) {
                            resolve(JSON.parse(appData.suggestions[data]));
                        }
                    }
                });
            },

            addSuggestion: (suggestionData) => {
                // This function should save `suggestionData` in the database.

                return Promise.resolve({
                    createdAt: new Date(), // Should be set server-side.
                });
            },

            updateSuggestion: (id, suggestionData) => {
                // This function should update `suggestionData` in the database.

                return Promise.resolve();
            },
        };
    }
}
