import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@fluentui/react/lib/Icon';
import Dropdown from '../Dropdown';
import { toast } from 'react-toastify';
import { useAppInsights } from '../../../context/TelemetryContext';
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: 'white',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: 'white',
});

const Sequence = ({ pages }) => {
	const filteredPages = pages.filter(page => !page.name.includes("Consulting"));
	const [selectedPage, setSelectedPage] = useState(null);
	const [artifacts, setArtifacts] = useState([]);
	const { axiosGetService, axiosPostService } = useAxios();
	const { track } = useAppInsights();
	const getArtifactsList = async () => {
		const response = await axiosGetService(
			`api/sdmToggleFilters/getArtifactsByPageType?pageTypeId=${selectedPage.id}`
		);
		setArtifacts(response.data);
	};
	const updatePageArtifactsSequence = async () => {
		if (selectedPage) {
			const postBody = {
				pageTypeId: selectedPage.id,
				selectedArtifactIds: artifacts.map(({ id }) => id).join(),
			};
			const response = await axiosPostService(
				`api/sdmToggleFilters/updateToggleFiltersSequence`,
				postBody
			);
			track(1, 2011, selectedPage.id, selectedPage.name);
			if (response.data.status) {
				toast.info('Sequence Updated Successfully', { position: 'top-right' });
			} else {
				toast.error(response.data.errorMsg, { position: 'top-right' });
			}
		} else {
			toast.error('Page Not Selected', { position: 'top-right' });
		}
	};
	useEffect(() => {
		if (selectedPage) {
			getArtifactsList();
		}
	}, [selectedPage]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const item = reorder(
			artifacts,
			result.source.index,
			result.destination.index
		);
		setArtifacts(item);
	};
	return (
		<>
			<div class="middleDetailsContainer">
				<div class="methodOwnerMiddleContainer">
					<div class="methodPhaseDetailsTab">
						<div className="addRolesListContainer addDeliveryPlaybookContainer">
							<div className="phaseSwimlaneFilters">
								<h6>Select Page To Sequence Toggle Filters</h6>
								<div class="multiSelectdropDown CloseMultiSelectDp">
									<Dropdown
										id="pages"
										className="activityReferProjectSelectionDropdown"
										data={filteredPages}
										value={selectedPage}
										setValue={setSelectedPage}
										label="Select Page"
										enableDropdown={true}
										disableNone={true}
									/>
								</div>
							</div>
							{selectedPage ? (
								<div className="toggleFiltersListContainer">
									<h6>Change the sequence</h6>
									<div className="sequentialPhaseList">
										<DragDropContext
											onDragEnd={(result) => {
												onDragEnd(result);
											}}
										>
											<Droppable droppableId="droppable">
												{(provided, snapshot) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={getListStyle(snapshot.isDraggingOver)}
													>
														<div className="sequentialdraggblePhaseList">
															{artifacts.map((item, index) => (
																<Draggable
																	key={item.id}
																	draggableId={item.id.toString()}
																	index={index}
																>
																	{(childProvided, childSnapshot) => (
																		<div
																			ref={childProvided.innerRef}
																			{...childProvided.draggableProps}
																			{...childProvided.dragHandleProps}
																			style={getItemStyle(
																				childSnapshot.isDragging,
																				childProvided.draggableProps.style
																			)}
																		>
																			<div
																				className={`sequentialPhaseItem old`}
																			>
																				<Icon iconName="GlobalNavButton" />
																				<span>{item.name}</span>
																			</div>
																		</div>
																	)}
																</Draggable>
															))}
														</div>
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</div>
								</div>
							) : (
								<h6>Select Page to View Filters</h6>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="saveContinueDetailsContainer">
				<button
					className="forwardButton"
					onClick={updatePageArtifactsSequence}
					onKeyPress={(e) => {
						if (e.which === 13) {
							updatePageArtifactsSequence();
						}
					}}
					aria-label="Update Page Filters"
					tabIndex={0}
				>
					<span className="saveContinueButton">Save</span>
				</button>
			</div>
		</>
	);
};

export default Sequence;
