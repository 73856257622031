import {
    GET_BY_EMAIL_START,
    GET_BY_EMAIL_SUCCESS,
    GET_BY_EMAIL_FAIL,
    
} from './ActionTypes';

const initialState = {
    isSDM: true,
    getEmailData:[],
    loading:false,    
};
const getByEmailStart = (state) => {
    return {
        ...state,        
        loading: true       
    };
};
const getByEmailSuccess = (state, action) => {    
    const data = action.payload?.data;    
    return {
        ...state,        
        getEmailData: data       
    };
};
const getByEmailFail = (state) => {
    return {
            ...state,        
            loading: false       
        };        
    };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BY_EMAIL_START: return getByEmailStart(state); 
        case GET_BY_EMAIL_SUCCESS: return getByEmailSuccess(state, action);
        case GET_BY_EMAIL_FAIL: return getByEmailFail(state);
        default: return state;
    }
};



export default reducer;