import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getOtherUserProfile } from '../../helpers/GraphService';
import Spinner from '../../helpers/CrowdSourcingSpinner';

function LockModal({ showPhaseLockModal, setShowPhaseLockModal, lockEmailId }) {
	const closeModal = () => {
		setShowPhaseLockModal(false);
	};
	const [userDetails, setUserDetails] = useState({});
	const [showSpinner, setShowSpinner] = useState(true);
	const getUserDetails = async () => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, lockEmailId);
		setUserDetails({
			id: userData.mail,
			name: userData.firstName + ' ' + userData.surname,
			image: userData.photo,
		});
		setShowSpinner(false);
	};
	useEffect(() => {
		getUserDetails();
	}, []);

	return (
		<>
			{showSpinner ? (
				<Spinner />
			) : (
				<Modal
					className="activityDiscardPopUp"
					show={showPhaseLockModal}
					onHide={() => {
						// don't do anything
					}}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						<p className="lockMessage">
							Looks like{' '}
							<a
								target="_blank"
								href={`https://teams.microsoft.com/l/chat/0/0?users=${lockEmailId}`}
							>
								{userDetails.name}
							</a>{' '}
							is already doing changes on this phase. Please come back later or
							send a message.
						</p>
						<div className="discardPopUpActivityButtons">
							<Button
								onClick={() => closeModal()}
								title="Yes"
								tabIndex={-1}
								className="activityDiscardPopUpAccept"
								variant="info"
							>
								OK
							</Button>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default LockModal;
