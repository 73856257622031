import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useAxios } from '../../../../../context/AxiosContext';
import { useAuth } from '../../../../../context/UserAuthContext';
import Spinner from '../../../../../helpers/Spinner';
import { getOtherUserProfile } from '../../../../../helpers/GraphService';
import { axiosTokenService } from '../../../../../components/CkEditor/api/axiosBaseUrl';
import { useAppInsights } from '../../../../../context/TelemetryContext';

const ReviewComments = () => {
	const { track } = useAppInsights();
	const { artifactType, id, artifactId, stateId } = useParams();
	const { axiosGetService } = useAxios();
	const complexityId = Number(
		queryString.parse(window.location.search).complexity
	);
	const [isCommentAvailable, setIsCommentAvailable] = useState(true);
	const [comments, setComments] = useState(null);
	const [userDetails, setUserDetails] = useState({});
	const [showSpinner, setShowSpinner] = useState(true);
	const [reviewerEmail, setReviewerEmail] = useState(null);
	const [suggestionStatus, setSuggestionStatus] = useState({});
	const usersEmail = [];

	const getUserProfile = async () => {
		const accessToken = await axiosTokenService();
		const users = {};
		for (const email of usersEmail) {
			const userData = await getOtherUserProfile(accessToken, email);
			users[email] = {
				name: userData.firstName + ' ' + userData.surname,
				image: userData.photo,
			};
		}
		setUserDetails(users);
	};

	const getContributor = async (suggestions) => {
		for (const suggestion of suggestions) {
			const userEmail = JSON.parse(suggestion).authorId;
			if (!usersEmail.includes(userEmail)) {
				usersEmail.push(userEmail);
			}
		}
	};

	const getActivityContents = async () => {
		await axiosGetService(
			`api/sdmactivity/getactivityreviewerorapprovercontribution?id=${id}&activityId=${artifactId}&complexityId=${complexityId}&stateId=${stateId}`
		).then((response) => {
			const data = response.data;
			if (data && data.resultReviewedJson) {
				const suggestionState = Object.values(data.resultReviewedJson).reduce(
					(allSuggestions, suggestions) => {
						if (suggestions && Object.values(suggestions).length) {
							const newSuggestions = { ...allSuggestions };
							Object.values(suggestions).forEach((suggestion) => {

								const parsedSuggestion = JSON.parse(suggestion);
								newSuggestions[parsedSuggestion.id] = parsedSuggestion.state;
							});
							return {
								...newSuggestions,
							};
						}
						return allSuggestions;
					},
					{}
				);
				setSuggestionStatus(suggestionState);
			}
			if (
				data &&
				data.resultApproverComments &&
				(data.resultApproverComments.description.length > 0 ||
					data.resultApproverComments.supportingActivities.length > 0 ||
					data.resultApproverComments.toolsAndGuidance.length > 0 ||
					data.resultApproverComments.tipsAndTechniques.length > 0 ||
					data.resultApproverComments.templates.length > 0 ||
					data.resultApproverComments.entryCriteria.length > 0 ||
					data.resultApproverComments.exitCriteria.length > 0 ||
					data.resultApproverComments.task.length > 0 ||
					data.resultApproverComments.deliverables.length > 0
				)
			) {
				usersEmail.push(data.reviewerEmail);
				setReviewerEmail(data.reviewerEmail);
				getContributor(data.resultApproverComments.description);
				getContributor(data.resultApproverComments.supportingActivities);
				getContributor(data.resultApproverComments.toolsAndGuidance);
				getContributor(data.resultApproverComments.tipsAndTechniques);
				getContributor(data.resultApproverComments.templates);
				getContributor(data.resultApproverComments.entryCriteria);
				getContributor(data.resultApproverComments.exitCriteria);
				getContributor(data.resultApproverComments.task);
				getContributor(data.resultApproverComments.deliverables);
				getUserProfile();
				setComments(data.resultApproverComments);
			} else {
				setIsCommentAvailable(false);
			}
			setShowSpinner(false);
		});
	};

	const renderSection = (title, data) => {
		return (
			<>
				<h2>{title}</h2>
				{data.map((comment) => (
					<div key={JSON.parse(comment).id}>
						<div className="rejectionCard myRejectedComments">
							<div
								class={`sideBarCard ${suggestionStatus[JSON.parse(comment).id] === 'rejected'
									? 'userDeletedToolsAndGuidance'
									: 'userAddedItem'
									}`}
							>
								<div class="sideBarCardHeader">
									<img
										class="userImg"
										src={
											userDetails[JSON.parse(comment).authorId] &&
											userDetails[JSON.parse(comment).authorId].image
										}
										alt="User Image"
									/>
									<span>
										{userDetails[JSON.parse(comment).authorId] &&
											userDetails[JSON.parse(comment).authorId].name}
									</span>
									<div class="itemStatus">
										<span>
											{JSON.parse(comment).type === 'insertion'
												? 'Add'
												: JSON.parse(comment).type === 'deletion'
													? 'Remove'
													: 'Format'}
										</span>
										&nbsp;
										<div
											dangerouslySetInnerHTML={{
												__html: JSON.parse(comment).data,
											}}
										/>
									</div>
								</div>
							</div>
							<div className="reasonedCommentsForRejection">
								<div className="rejectedPersonaDetails">
									<p>
										{userDetails[reviewerEmail] &&
											userDetails[reviewerEmail].name}
									</p>
									<p>{`(Approver)`}</p>
								</div>
								<p className="myRejectedCommentsSection">
									{JSON.parse(comment).comment}
								</p>
							</div>
						</div>
					</div>
				))}
			</>
		);
	};

	useEffect(() => {
		getActivityContents();
	}, []);

	return (
		<div className="topicsCrowdSourcingContainer">
			<div class="topicsDescriptionDetails">
				<div className="topicDescriptionDiv">
					<h5>Approver Comments</h5>
					{showSpinner ||
						(isCommentAvailable && !comments) ||
						(isCommentAvailable && Object.keys(userDetails).length < 1) ? (
						<Spinner />
					) : (
						<>
							{isCommentAvailable ? (
								<div className="rejectedMyContributionConatiner">
									{comments.description.length > 0 ? (
										renderSection('Description', comments.description)
									) : (
										<></>
									)}
									{comments.supportingActivities.length > 0 ? (
										renderSection(
											'Supporting Activities',
											comments.supportingActivities
										)
									) : (
										<></>
									)}
									{comments.entryCriteria?.length > 0 ? (
										renderSection(
											'Entry Criteria',
											comments.entryCriteria
										)
									) : (
										<></>
									)}
									{comments.exitCriteria?.length > 0 ? (
										renderSection(
											'Exit Criteria',
											comments.exitCriteria
										)
									) : (
										<></>
									)}
									{comments.task?.length > 0 ? (
										renderSection(
											'Task',
											comments.task
										)
									) : (
										<></>
									)}
									{comments.toolsAndGuidance.length > 0 ? (
										renderSection('Tools', comments.toolsAndGuidance)
									) : (
										<></>
									)}
									{comments.tipsAndTechniques.length > 0 ? (
										renderSection(
											'Guidance, Tips and Techniques',
											comments.tipsAndTechniques
										)
									) : (
										<></>
									)}
									{comments.templates.length > 0 ? (
										renderSection('Deliverables', comments.templates)
									) : (
										<></>
									)}
									{comments.deliverables.length > 0 ? (
										renderSection('Deliverables', comments.deliverables)
									) : (
										<></>
									)}
								</div>
							) : (
								<p>No comments available</p>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReviewComments;
