import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ManageAnnouncementsForm from './ManageAnnouncementsForm';
import AnnouncementSequence from './AnnouncementSequence';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';

const ManageAnnouncements = ({}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(1);
	const [announcements, setAnnouncements] = useState([]);
	const { axiosGetService, axiosPostService } = useAxios();
	const getAnnouncements = () => {
		axiosGetService(
			`api/sdmannouncement/announcement?pageSize=10&pageNumber=1&id=0&searchValue=0`
		).then((response) => {
			setAnnouncements(response.data.sdmAnnouncements);
		});
	};
	const saveAnnouncementSequnce = async () => {
		const postBody = {
			announcementIdsInSequence: announcements
				.reverse()
				.map((announcement) => announcement.id)
				.join(),
		};
		const response = await axiosPostService(
			`api/sdmannouncement/updateAnnouncementSequence`,
			postBody
		);
		if (response.data.status) {
			toast.info('Updated Announcement Sequence', { position: 'top-right' });
			history.push(pathname);
		} else {
			toast.error(response.data.errorMsg);
		}
	};
	const tabs = [
		{
			id: 1,
			title: 'Add/Edit Announcements',
		},
		{ id: 2, title: 'Sequence' },
	];
	const components = {
		1: (
			<ManageAnnouncementsForm
				announcements={announcements}
				setAnnouncements={setAnnouncements}
				getAnnouncements={getAnnouncements}
			/>
		),
		2: (
			<AnnouncementSequence
				announcements={announcements}
				setAnnouncements={setAnnouncements}
				saveAnnouncementSequnce={saveAnnouncementSequnce}
			/>
		),
	};
	useEffect(() => {
		getAnnouncements();
	}, [currentTab]);
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles manageFeatures manageAnnouncements"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={() => {
				history.push(pathname);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Manage Announcements
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div class="methodOwnerMainContainer">
					<div class="methodOwnerPage">
						<div class="methodOwnerContainer">
							<div class="methodLeftContainer">
								<ul class="methodPhaseLeftMenu">
									{tabs.map((item) => (
										<li
											key={item.title}
											className={currentTab === item.id ? 'activePhaseTab' : ''}
											onClick={() => {
												setCurrentTab(item.id);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setCurrentTab(item.id);
												}
											}}
											aria-label={item.title}
											role="button"
											tabIndex={0}
										>
											<span>{item.title}</span>
										</li>
									))}
								</ul>
							</div>
							{components[currentTab]}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ManageAnnouncements;
