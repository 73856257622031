import React, { useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import TemplatePreview from './TemplatePreview';

const ActivityTemplateHisotry = ({
	templateData,
	toolsSidebarHeight,
	templateSuggestions,
	ckEditorUserMap,
	reviewedTemplateSuggestions,
	enableTemplatePreview,
	setEnableTemplatePreview,
}) => {
	const [templatePreviewData, setTemplatePreviewData] = useState(null);

	const getReviewStatus = (template, isCamelCaseRequired) => {
		if (reviewedTemplateSuggestions.length > 0) {
			const status = JSON.parse(
				reviewedTemplateSuggestions.filter(
					(item) => JSON.parse(item).id === template.id
				)[0]
			).state;
			if (isCamelCaseRequired) {
				if (status === 'accepted') {
					return 'Approved';
				}
				return status.charAt(0).toUpperCase() + status.slice(1);
			}
			return status;
		}
		return null;
	};

	const renderTemplateSuggestions = (templates) => {
		return templates.map((item) => (
			<li
				className={`${
					JSON.parse(item).suggestionType === 'insertion'
						? 'userAddedItem'
						: 'none'
				}`}
				key={item.id}
			>
				<div>
					<span className="templateEntityName">
						<a href={item.fileLocation} target="_blank">
							{JSON.parse(item).title}
						</a>
					</span>
					<span className="newActivityTemplateListItemIcons">
						<button
							tabIndex="0"
							aria-label={`${JSON.parse(item).title} Preview`}

							title="Preview"
							onClick={() => {
								setTemplatePreviewData(JSON.parse(item));
								setEnableTemplatePreview(true);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setTemplatePreviewData(JSON.parse(item));
									setEnableTemplatePreview(true);
								}
							}}
					
						>
							<span className="previewIconBlack"></span>
						</button>
						<a
							tabIndex="0"
							href={JSON.parse(item).fileLocation}
							title="Download"
							target="_blank"
						>
							<span className="templateEditDownloadIcon"></span>
							{/* <Icon
       iconName="ReleaseGate"
       aria-hidden="true"
       className="NewdownloadTemplate root-55"
      /> */}
						</a>
					</span>
				</div>
			</li>
		));
	};

	return (
		<>
			<div className="customToolsEditor">
				<div className="toolsEditorMainContainer">
					<div className="toolsHeaderEditor">
					<span className='alternativeOfh6'>Templates</span>
					</div>
					<div className="templateContentContainer">
						<ul>
							{templateData.map((item) => (
								<li className={'none'} key={item.id}>
									<div>
										<span className="templateEntityName">
											<a href={item.fileLocation} target="_blank">
												{item.title}
											</a>
										</span>
										<span className="newActivityTemplateListItemIcons">
											<button
												tabIndex="0"
												aria-label={`${item.title} Preview`}

												title="Preview"
												onClick={() => {
													setTemplatePreviewData(item);
													setEnableTemplatePreview(true);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setTemplatePreviewData(item);
														setEnableTemplatePreview(true);

													}
												}}

											>
												<span className="previewIconBlack"></span>
											</button>
											<a
											tabIndex="0"
												href={item.fileLocation}
												title="Download"
												target="_blank"
											>
												<span className="templateEditDownloadIcon"></span>
												{/* <Icon
												iconName="ReleaseGate"
												aria-hidden="true"
												className="NewdownloadTemplate root-55"
											/> */}
											</a>
										</span>
									</div>
								</li>
							))}

							{renderTemplateSuggestions(templateSuggestions)}
						</ul>
					</div>
				</div>
				<div className="toolsSidebar" style={{ height: toolsSidebarHeight }}>
					{templateSuggestions.map((card) => (
						<>
							<div
								className={`sideBarCard ${
									JSON.parse(card).suggestionType === 'insertion'
										? 'userAddedItem'
										: 'userDeletedToolsAndGuidance'
								} ${getReviewStatus(JSON.parse(card), false)}`}
							>
								<div className="sideBarCardHeader">
									<img
										className="userImg"
										src={ckEditorUserMap[JSON.parse(card).authorId][1]}
										alt=""
									/>
									<span>{ckEditorUserMap[JSON.parse(card).authorId][0]}</span>
									<span className="suggestionStatus myContributionSuggestionStatus">
										{getReviewStatus(JSON.parse(card), true)}
									</span>
								</div>
								<div className="itemStatus">
									<span>
										{JSON.parse(card).suggestionType === 'insertion'
											? 'Add'
											: 'Remove'}
									</span>
									&nbsp;"{JSON.parse(card).title}"
								</div>
							</div>
						</>
					))}
				</div>
			</div>

			{enableTemplatePreview ? (
				<TemplatePreview
					enableTemplatePreview={enableTemplatePreview}
					closeModal={() => {
						setEnableTemplatePreview(false);
						setTemplatePreviewData(null);
					}}
					data={templatePreviewData}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default ActivityTemplateHisotry;
