const ProdConfig = {
    clientId: 'dcfd803d-3dac-42ba-be88-cab64763bab6',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://sdmplus2.azurewebsites.net/',
    apiScopes: ['https://microsoft.onmicrosoft.com/apiSDMPlus/.default'],
    copilotApiScope: ['https://vww-apim.azure-api.net' + '/.default'],
    copilotApiURL: 'https://api.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://apisdmplus.azurewebsites.net/',
    instrumentationKey: 'c5a1e2e2-7a8d-4447-90c8-4e4e50bb932b',
    solutionMethodIconsUrl: 'https://sdmplusprodstorage.z19.web.core.windows.net',
    sdm1URL: 'https://sdmplus.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://sdmplus2.azurewebsites.net',
    appInsightsAPIKey: 'da70f8gt6y2fvwbp3uw5utmpe430xxuqhe85xxql',
    appInsightsAppId: '5f515d6e-3b4a-4122-98aa-82b6bde57172',
    yammerGroupId: '20349108224',
    yammerInstance: 'Prod',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'true',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusVideoTutorials/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey'
};
export default ProdConfig;
