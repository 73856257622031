import {GET_BY_EMAIL_START,
    GET_BY_EMAIL_SUCCESS,
    GET_BY_EMAIL_FAIL} from './ActionTypes.js';

export const getByEmailStart = () => {
    return {
        type: GET_BY_EMAIL_START
    };
};

export const getByEmailSuccess = (dataObj) => {
    return {
        type: GET_BY_EMAIL_SUCCESS,
        payload: { data: dataObj }
    };
};

export const getByEmailFail = () => {
    return {
        type: GET_BY_EMAIL_FAIL
    };
}