import React, { useState, useEffect } from 'react';
import Editor from '../PhaseEditor';
import PhaseRefer from './PhaseRefer';
import { titleValidator } from '../../../helpers/Validation';

const PhaseDetails = ({
	phases,
	setPhases,
	phaseTitle,
	setPhaseTitle,
	phaseDescription,
	setPhaseDescription,
	existingPhaseTitle,
	phaseTitleError,
	setPhaseTitleError,
	currentPhaseTitle,
	projectTypeId,
	solutionMethodId,
	type,
	parentId,
	setApiCallNeeded,
	phaseBuilderType,
}) => {
	const [showPhaseDetailsForm, setShowPhaseDetailsForm] = useState(false);
	const [showReferPhaseForm, setShowReferPhaseForm] = useState(false);

	useEffect(() => {
		if (
			existingPhaseTitle.includes(phaseTitle.trim().toLowerCase()) &&
			currentPhaseTitle.toLowerCase() !== phaseTitle.trim().toLowerCase()
		) {
			setPhaseTitleError(true);
		} else {
			setPhaseTitleError(false);
		}
	}, [phaseTitle]);

	return (
		<div className="methodPhaseDetailsTab">
			{phaseBuilderType === 'create' ? (
				<div>
					<div className="addNewPhaseButtonContainer">
						<div
							className="addNewPhaseActionButton"
							onClick={() => {
								setShowReferPhaseForm(!showReferPhaseForm);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowReferPhaseForm(!showReferPhaseForm);
								}
							}}
							tabIndex={0}
							role="button"
							aria-label="Link existing phase(s)"
							aria-expanded={showReferPhaseForm ? 'true' : 'false'}
						>
							Link existing phase(s)
						</div>
						{showReferPhaseForm ? (
							<PhaseRefer
								setShowReferPhaseForm={setShowReferPhaseForm}
								phases={phases}
								setPhases={setPhases}
								projectTypeId={projectTypeId}
								solutionMethodId={solutionMethodId}
								type={type}
								setApiCallNeeded={setApiCallNeeded}
								parentId={parentId}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
			) : (
				<></>
			)}

			<div>
				<div className="addNewPhaseButtonContainer">
					{phaseBuilderType === 'create' ? (
						<div
							className="addNewPhaseActionButton"
							onClick={() => {
								setShowPhaseDetailsForm(!showPhaseDetailsForm);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setShowPhaseDetailsForm(!showPhaseDetailsForm);
								}
							}}
							tabIndex={0}
							role="button"
							aria-label="Add a new phase"
							aria-expanded={
								showPhaseDetailsForm || phaseBuilderType === 'update'
									? 'true'
									: 'false'
							}
						>
							Add a new phase
						</div>
					) : (
						<></>
					)}
					{showPhaseDetailsForm || phaseBuilderType === 'update' ? (
						<div className="phaseDetailsFields">
							<div>
								<h6>Phase Title *</h6>
								<input
									placeholder="Enter Phase Name"
									title="Enter Phase Name"
									value={phaseTitle}
									onChange={(e) => setPhaseTitle(e.target.value)}
								/>
								{phaseTitleError ? <p>Phase title already exist.</p> : <></>}
								{phaseTitle && !titleValidator.test(phaseTitle) ? (
									<p className="errorMsg">Invalid title</p>
								) : (
									<></>
								)}
							</div>
							<div>
								<h6>Phase Description *</h6>
								<Editor
									phaseDescription={phaseDescription}
									setPhaseDescription={setPhaseDescription}
								/>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};
export default PhaseDetails;
