import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import HtmlDiff from 'htmldiff-js';
import { handleTernaryCondition } from '../../../helpers/Validation';
import { Icon } from '@fluentui/react';

const ActivityHistory = ({
	showAdminHistory,
	setShowAdminHistory,
	historyData,
	versionDetails,
}) => {
	const activityData = historyData[0];
	const tabs = [activityData.roleName];
	const [selectedTab, setSelectedTab] = useState(activityData.roleName);
	const [activityDetailsOld, setActivityDetailsOld] = useState({
		activityName: '',
		activityHashTags: [],
		tools: [],
		topics: [],
		procedures: [],
		templates: [],
		description: '',
		supportingActivities: '',
		deliverables: '',
		tileDescription: '',
		tipsAndTechniques: '',
		entryCriteria: [],
		exitCriteria: [],
		tasks: '',
		responsibleList: [],
		accountableList: [],
		consultedList: [],
		informedList: [],
		activityVersion: '1.00',
	});
	const [activityDetailsUpdated, setActivityDetailsUpdated] = useState({
		activityName: '',
		activityHashTags: [],
		tools: [],
		topics: [],
		procedures: [],
		templates: [],
		description: '',
		supportingActivities: '',
		deliverables: '',
		tileDescription: '',
		tipsAndTechniques: '',
		entryCriteria: [],
		exitCriteria: [],
		tasks: '',
		responsibleList: [],
		accountableList: [],
		consultedList: [],
		informedList: [],
		activityVersion: '1.00',
	});

	const getUpdatedActivityData = () => {
		setActivityDetailsUpdated({
			...activityDetailsUpdated,
			activityName: activityData.activityName,
			activityHashTags: handleTernaryCondition(
				activityData.hashTagsList,
				activityData.hashTagsList,
				[]
			),
			tools: handleTernaryCondition(
				activityData.activityToolsList,
				activityData.activityToolsList,
				[]
			),
			topics: handleTernaryCondition(
				activityData.activityTopics,
				activityData.activityTopics,
				[]
			),
			procedures: handleTernaryCondition(
				activityData.activityProcedureType,
				activityData.activityProcedureType,
				[]
			),
			templates: handleTernaryCondition(
				activityData.activityTemplateDetails,
				activityData.activityTemplateDetails,
				[]
			),
			description: handleTernaryCondition(
				activityData.description,
				activityData.description,
				''
			),
			supportingActivities: handleTernaryCondition(
				activityData.supportingActivities,
				activityData.supportingActivities,
				''
			),
			deliverables: handleTernaryCondition(
				activityData.deliverables,
				activityData.deliverables,
				''
			),
			tileDescription: handleTernaryCondition(
				activityData.tileDescription,
				activityData.tileDescription,
				''
			),
			tipsAndTechniques: handleTernaryCondition(
				activityData.tipsAndTechniques,
				activityData.tipsAndTechniques,
				''
			),
			entryCriteria: handleTernaryCondition(
				activityData.entryCriteria,
				activityData.entryCriteria,
				''
			),
			exitCriteria: handleTernaryCondition(
				activityData.exitCriteria,
				activityData.exitCriteria,
				''
			),
			tasks: handleTernaryCondition(activityData.tasks, activityData.tasks, ''),

			responsibleList: handleTernaryCondition(
				activityData.responsibleList,
				activityData.responsibleList,
				[]
			),

			accountableList: handleTernaryCondition(
				activityData.accountableList,
				activityData.accountableList,
				[]
			),
			consultedList: handleTernaryCondition(
				activityData.consultedList,
				activityData.consultedList,
				[]
			),
			informedList: handleTernaryCondition(
				activityData.informedList,
				activityData.informedList,
				[]
			),
			activityVersion: versionDetails.versionNumber,
		});
	};

	const getOldActivityData = () => {
		setActivityDetailsOld({
			...activityDetailsOld,
			activityName: activityData.previousVersionActivityName,
			activityHashTags: handleTernaryCondition(
				activityData.previousVersionHashTagsList,
				activityData.previousVersionHashTagsList,
				[]
			),
			tools: handleTernaryCondition(
				activityData.previousVersionActivityToolsList,
				activityData.previousVersionActivityToolsList,
				[]
			),
			topics: handleTernaryCondition(
				activityData.previousVersionActivityTopics,
				activityData.previousVersionActivityTopics,
				[]
			),
			procedures: handleTernaryCondition(
				activityData.previousVersionActivityProcedureType,
				activityData.previousVersionActivityProcedureType,
				[]
			),
			templates: handleTernaryCondition(
				activityData.previousVersionActivityTemplateDetails,
				activityData.previousVersionActivityTemplateDetails,
				[]
			),
			description: handleTernaryCondition(
				activityData.previousVersionDescription,
				activityData.previousVersionDescription,
				''
			),
			supportingActivities: handleTernaryCondition(
				activityData.previousVersionSupportingActivities,
				activityData.previousVersionSupportingActivities,
				''
			),
			deliverables: handleTernaryCondition(
				activityData.previousVersionDeliverables,
				activityData.previousVersionDeliverables,
				''
			),
			tileDescription: handleTernaryCondition(
				activityData.previousVersionActivityTileDescription,
				activityData.previousVersionActivityTileDescription,
				''
			),
			tipsAndTechniques: handleTernaryCondition(
				activityData.previousVersionTipsAndTechniques,
				activityData.previousVersionTipsAndTechniques,
				''
			),
			entryCriteria: handleTernaryCondition(
				activityData.previousVersionEntryCriteria,
				activityData.previousVersionEntryCriteria,
				''
			),
			exitCriteria: handleTernaryCondition(
				activityData.previousVersionExitCriteria,
				activityData.previousVersionExitCriteria,
				''
			),
			tasks: handleTernaryCondition(
				activityData.previousVersionTasks,
				activityData.previousVersionTasks,
				''
			),
			responsibleList: handleTernaryCondition(
				activityData.previousVersionResponsibleList,
				activityData.previousVersionResponsibleList,
				[]
			),
			accountableList: handleTernaryCondition(
				activityData.previousVersionAccountableList,
				activityData.previousVersionAccountableList,
				[]
			),
			consultedList: handleTernaryCondition(
				activityData.previousVersionConsultedList,
				activityData.previousVersionConsultedList,
				[]
			),
			informedList: handleTernaryCondition(
				activityData.previousVersionInformedList,
				activityData.previousVersionInformedList,
				[]
			),
			activityVersion: versionDetails.versionNumber,
		});
	};

	const getVersionDifference = (oldData, newData) => {
		return HtmlDiff.execute(oldData, newData);
	};

	const renderRACIData = (activityDetailsOld, activityDetailsUpdated) => {
		return (
			<div className="activityChildContainer orphanRole">
				{activityDetailsOld.map((role) => (
					<div
						className="activityChildTitle"
						title={role}
						key={role}
						aria-label={role}
					>
						<span className="titleOfActivity activityStyle">
							{activityDetailsUpdated.some((r) => r === role) ? (
								role
							) : (
								<del class="diffmod">{role}</del>
							)}
						</span>
					</div>
				))}
				{activityDetailsUpdated.map(
					(role) =>
						!activityDetailsOld.some((r) => r === role) && (
							<div
								className="activityChildTitle"
								title={role}
								key={role}
								aria-label={role}
							>
								<span className="titleOfActivity">
									<ins class="diffmod">{role}</ins>
								</span>
							</div>
						)
				)}
			</div>
		);
	};

	useEffect(() => {
		getOldActivityData();
		getUpdatedActivityData();
	}, []);

	return (
		<Modal
			dialogClassName={'topicsCrowdsourceHistoryModal adminHistoryPreview'}
			show={showAdminHistory}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				setShowAdminHistory(false);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<div>
						<h5>Historical Preview</h5>
						<div>
							<span>
								Document Version:&nbsp;{versionDetails.versionNumber},&nbsp;
								{versionDetails.modifiedDate}
							</span>
						</div>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="topicsCrowdsourcingHistoryModalContainer historyContainer">
					<div className="topicsMenuContainer">
						<ul className="topicsMenu">
							{tabs.map((item) => (
								<li
									name={item}
									tabindex="0"
									className={handleTernaryCondition(
										selectedTab === item,
										'selectedTopicMenu',
										'notSelectedTopicMenu'
									)}
									onClick={() => {
										setSelectedTab(item);
									}}
								>
									<span className="consultingMenu">{item}</span>
								</li>
							))}
						</ul>
					</div>
					<div className="topicsCrowdsourcingHistoryContentContainer">
						<div className="myContributionPreviewContainer">
							<div className="historicalPreview">
								<div className="activityLandinPage">
									<div className="activityMainContainer">
										<div className="activityDescriptionDetails">
											<div className="descOfActivity historyActivity">
												<h4 className="activityLandingPageTitle">
													<div
														dangerouslySetInnerHTML={{
															__html: getVersionDifference(
																activityDetailsOld.activityName,
																activityDetailsUpdated.activityName
															),
														}}
													/>
												</h4>
												<div class="existingHashTagsOfActivity methodOwner">
													{activityDetailsOld.activityHashTags.map(
														({ name }) => (
															<>
																{activityDetailsUpdated.activityHashTags.some(
																	(hashTag) => hashTag.name === name
																) && <span key={name}>{name}</span>}
																{!activityDetailsUpdated.activityHashTags.some(
																	(hashTag) => hashTag.name === name
																) && (
																	<span key={name}>
																		<del class="diffmod">{name}</del>
																	</span>
																)}
															</>
														)
													)}
													{activityDetailsUpdated.activityHashTags.map(
														({ name }) => (
															<>
																{!activityDetailsOld.activityHashTags.some(
																	(hashTag) => hashTag.name === name
																) && (
																	<span key={name}>
																		<ins class="diffmod">{name}</ins>
																	</span>
																)}
															</>
														)
													)}
												</div>
												<div className="ractMatrix row">
													<div className="activityRaciDetails col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
														<span className="subHeading" id="ResponsibleRole">
															Responsible Role:
														</span>
														{renderRACIData(
															activityDetailsOld.responsibleList,
															activityDetailsUpdated.responsibleList
														)}
													</div>
													<div className="activityRaciDetails col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
														<span className="subHeading" id="AccountableRole">
															Accountable Role:
														</span>
														{renderRACIData(
															activityDetailsOld.accountableList,
															activityDetailsUpdated.accountableList
														)}
													</div>
													<div className="activityRaciDetails col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
														<span className="subHeading" id=" ConsultedRole">
															Consulted Role:
														</span>
														{renderRACIData(
															activityDetailsOld.consultedList,
															activityDetailsUpdated.consultedList
														)}
													</div>
													<div className="activityRaciDetails col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
														<span className="subHeading" id="InformedRole">
															Informed Role:
														</span>

														{renderRACIData(
															activityDetailsOld.informedList,
															activityDetailsUpdated.informedList
														)}
													</div>
													<div className="raciMsg">
														<span>
															**The RACI here depicts the ideal state where all
															roles are staffed on the project/engagement. In
															scenarios where the particular role is not
															staffed, the person playing the role on the
															project/engagement is expected to perform the
															activity.
														</span>
													</div>
												</div>
												<div className="innerContent" id="innerContent">
													{(activityDetailsOld.description ||
														activityDetailsUpdated.description) && (
														<>
															<h6 className="sub-heading" id="desc"
																aria-label='Description'>
																Description
															</h6>
															<div
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.description,
																		activityDetailsUpdated.description
																	),
																}}
															/>
														</>
													)}

													{(activityDetailsOld.supportingActivities ||
														activityDetailsUpdated.supportingActivities) && (
														<>
															<h6 className="sub-heading" id="supportAct">
																Supporting Activities
															</h6>
															<div
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.supportingActivities,
																		activityDetailsUpdated.supportingActivities
																	),
																}}
															/>
														</>
													)}
													{(activityDetailsOld.entryCriteria ||
														activityDetailsUpdated.entryCriteria) && (
														<>
															<h6 className="sub-heading" id="entryc">
																Entry Criteria/Input
															</h6>

															<div
																className="descEn"
																id="entryCriteria"
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.entryCriteria,
																		activityDetailsUpdated.entryCriteria
																	),
																}}
															/>
														</>
													)}

													{(activityDetailsOld.tasks ||
														activityDetailsUpdated.tasks) && (
														<>
															<h6 className="sub-heading " id="tasks">
																Tasks
															</h6>
															<div
																className="descT"
																id="task"
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.tasks,
																		activityDetailsUpdated.tasks
																	),
																}}
															/>
														</>
													)}
													{(activityDetailsOld.procedures.length > 0 ||
														activityDetailsUpdated.procedures.length > 0) && (
														<>
															<h6 className="sub-heading" id="deliver">
																Procedures
															</h6>

															<div id="activityProcedure">
																<ul>
																	{activityDetailsOld.procedures.map(
																		(procedure) => (
																			<>
																				{activityDetailsUpdated.procedures.some(
																					({ name }) => name === procedure.name
																				) && (
																					<li key={procedure.name}>
																						<a
																							href={procedure.url}
																							target="_blank"
																						>
																							{procedure.name}
																						</a>
																					</li>
																				)}

																				{!activityDetailsUpdated.procedures.some(
																					({ name }) => name === procedure.name
																				) && (
																					<li key={procedure.name}>
																						<a
																							href={procedure.url}
																							target="_blank"
																						>
																							<del class="diffmod">
																								{procedure.name}
																							</del>
																						</a>
																					</li>
																				)}
																			</>
																		)
																	)}
																	{activityDetailsUpdated.procedures.map(
																		(procedure) => (
																			<>
																				{!activityDetailsOld.procedures.some(
																					({ name }) => name === procedure.name
																				) && (
																					<li key={procedure.name}>
																						<a
																							href={procedure.url}
																							target="_blank"
																						>
																							<ins class="diffmod">
																								{procedure.name}
																							</ins>
																						</a>
																					</li>
																				)}
																			</>
																		)
																	)}
																</ul>
															</div>
														</>
													)}

													{(activityDetailsOld.topics.length > 0 ||
														activityDetailsUpdated.topics.length > 0) && (
														<>
															<h6 className="sub-heading" id="deliver">
																Related Topics
															</h6>
															{(activityDetailsOld.topics.length > 0 ||
																activityDetailsUpdated.topics.length > 0) && (
																<div id="topicList">
																	<ul>
																		{activityDetailsOld.topics.map((topic) => (
																			<>
																				{activityDetailsUpdated.topics.some(
																					({ name }) => name === topic.name
																				) && (
																					<li key={topic.name}>
																						<a href={topic.url} target="_blank">
																							{topic.name}
																						</a>
																					</li>
																				)}
																				{!activityDetailsUpdated.topics.some(
																					({ name }) => name === topic.name
																				) && (
																					<li key={topic.name}>
																						<a href={topic.url} target="_blank">
																							<del class="diffmod">
																								{topic.name}
																							</del>
																						</a>
																					</li>
																				)}
																			</>
																		))}
																		{activityDetailsUpdated.topics.map(
																			(topic) => (
																				<>
																					{!activityDetailsOld.topics.some(
																						({ name }) => name === topic.name
																					) && (
																						<li key={topic.name}>
																							<a
																								href={topic.url}
																								target="_blank"
																							>
																								<ins class="diffmod">
																									{topic.name}
																								</ins>
																							</a>
																						</li>
																					)}
																				</>
																			)
																		)}
																	</ul>
																</div>
															)}
														</>
													)}
													{(activityDetailsOld.exitCriteria ||
														activityDetailsUpdated.exitCriteria) && (
														<>
															<h6 className="sub-heading " id="exitc">
																Exit Criteria/Output
															</h6>

															<div
																className="descEx"
																id="exitCriteria"
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.exitCriteria,
																		activityDetailsUpdated.exitCriteria
																	),
																}}
															/>
														</>
													)}

													{(activityDetailsOld.deliverables ||
														activityDetailsUpdated.deliverables ||
														activityDetailsOld.templates.length > 0 ||
														activityDetailsUpdated.templates.length > 0) && (
														<>
															<h6 className="sub-heading" id="deliver">
																Deliverables
															</h6>
															{(activityDetailsOld.templates.length > 0 ||
																activityDetailsUpdated.templates.length >
																	0) && (
																<div id="tempList">
																	<ul>
																		{activityDetailsOld.templates.map(
																			(template) => (
																				<>
																					{activityDetailsUpdated.templates.some(
																						({ title }) =>
																							title === template.title
																					) && (
																						<li>
																							{template.inProgress && (
																								<a
																									className="noClick"
																									href={template.fileLocation}
																									target="_blank"
																								>
																									{template.title}
																								</a>
																							)}
																							{!template.inProgress && (
																								<a
																									href={template.fileLocation}
																									target="_blank"
																								>
																									{template.title}
																								</a>
																							)}
																						</li>
																					)}
																					{!activityDetailsUpdated.templates.some(
																						({ title }) =>
																							title === template.title
																					) && (
																						<li>
																							{template.inProgress && (
																								<a
																									className="noClick"
																									href={template.fileLocation}
																									target="_blank"
																								>
																									<del class="diffmod">
																										{template.title}
																									</del>
																								</a>
																							)}

																							{!template.inProgress && (
																								<a
																									href={template.fileLocation}
																									target="_blank"
																								>
																									<del class="diffmod">
																										{template.title}
																									</del>
																								</a>
																							)}
																						</li>
																					)}
																				</>
																			)
																		)}
																		{activityDetailsUpdated.templates.map(
																			(template) => (
																				<>
																					{!activityDetailsOld.templates.some(
																						({ title }) =>
																							title === template.title
																					) && (
																						<li>
																							{template.inProgress && (
																								<a
																									className="noClick"
																									href={template.fileLocation}
																									target="_blank"
																								>
																									<ins class="diffmod">
																										{template.title}
																									</ins>
																								</a>
																							)}
																							{!template.inProgress && (
																								<a
																									href={template.fileLocation}
																									target="_blank"
																								>
																									<ins class="diffmod">
																										{template.title}
																									</ins>
																								</a>
																							)}
																						</li>
																					)}
																				</>
																			)
																		)}
																	</ul>
																</div>
															)}
															{(activityDetailsOld.deliverables ||
																activityDetailsUpdated.deliverables) && (
																<div
																	id="activityDeliverables"
																	dangerouslySetInnerHTML={{
																		__html: getVersionDifference(
																			activityDetailsOld.deliverables,
																			activityDetailsUpdated.deliverables
																		),
																	}}
																/>
															)}
														</>
													)}

													{(activityDetailsOld.tools.length > 0 ||
														activityDetailsUpdated.tools.length > 0) && (
														<>
															<h2 className="sub-heading" id="tool">
																Tools
															</h2>
															<div className="customToolsEditor">
																<div className="toolsEditorMainContainer">
																	<div className="ToolsContentContainer">
																		<ul>
																			{activityDetailsOld.tools.map((tool) => (
																				<>
																					{activityDetailsUpdated.tools.some(
																						({ name }) => name === tool.name
																					) && (
																						<li key={tool.name}>
																							<a
																								href={tool.url}
																								target="_blank"
																							>
																								{tool.name}
																							</a>
																						</li>
																					)}
																					{!activityDetailsUpdated.tools.some(
																						({ name }) => name === tool.name
																					) && (
																						<li key={tool.name}>
																							<a
																								href={tool.url}
																								target="_blank"
																							>
																								<del class="diffmod">
																									{tool.name}
																								</del>
																							</a>
																						</li>
																					)}
																				</>
																			))}
																			{activityDetailsUpdated.tools.map(
																				(tool) => (
																					<>
																						{!activityDetailsOld.tools.some(
																							({ name }) => name === tool.name
																						) && (
																							<li key={tool.name}>
																								<a
																									href={tool.url}
																									target="_blank"
																								>
																									<ins class="diffmod">
																										{tool.name}
																									</ins>
																								</a>
																							</li>
																						)}
																					</>
																				)
																			)}
																		</ul>
																	</div>
																</div>
															</div>
														</>
													)}

													{(activityDetailsOld.tipsAndTechniques ||
														activityDetailsUpdated.tipsAndTechniques) && (
														<>
															<h6 className="sub-heading" id="tipstech">
																Guidance, Tips and Techniques
															</h6>
															<div
																dangerouslySetInnerHTML={{
																	__html: getVersionDifference(
																		activityDetailsOld.tipsAndTechniques,
																		activityDetailsUpdated.tipsAndTechniques
																	),
																}}
															/>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ActivityHistory;
