import React, { useState, useEffect, useMemo } from "react";

import { useLeftMenuCollapse } from "../../../context/LeftMenuCollapseContext";
import { useAxios } from "../../../context/AxiosContext";

import MyPerformanceTrends from "./MyPerformanceTrends";
import ApplicationAverage from "./ApplicationAverage";
import Approvals from "./Approvals";
import MyApprovalContributionRatio from "./MyApprovalContributionRatio";
import MyContribution from "./MyContribution";
import { TabTitle } from "../../../utils/GeneralFunctions";

const Dashboard = ({}) => {
  const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
  const { axiosGetService } = useAxios();

  const [maximizeEntity, setMaximizeEntity] = useState("");
  const [graphEntity, setGraphEntity] = useState({});

  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=0&itemTypeId=${83}&isEdit=false
              &isAutoComplete=false&isSearchResultsHome=false&isSearchResults=false
              &isMyContributions=false&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const pageBreadcrumb = {
        title: breadcrumbTitle[1],
        parent: "Home",
        path: window.location.pathname,
      };
      const newBreadcrumbs = breadcrumbs.slice(0, 1);
      newBreadcrumbs.push(pageBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[1]);
    });
  };

  useEffect(() => {
    loadBreadcrumb();
  }, []);

  useMemo(() => {
    setGraphEntity({
      myPerformanceTrend: (
        <MyPerformanceTrends
          setMaximizeEntity={setMaximizeEntity}
          maximizeEntity={maximizeEntity}
        />
      ),
      myContribution: (
        <MyContribution
          setMaximizeEntity={setMaximizeEntity}
          maximizeEntity={maximizeEntity}
        />
      ),
      applicationAverage: (
        <ApplicationAverage
          setMaximizeEntity={setMaximizeEntity}
          maximizeEntity={maximizeEntity}
        />
      ),
      approvals: (
        <Approvals
          setMaximizeEntity={setMaximizeEntity}
          maximizeEntity={maximizeEntity}
        />
      ),
      myApprovalContributionRatio: (
        <MyApprovalContributionRatio
          setMaximizeEntity={setMaximizeEntity}
          maximizeEntity={maximizeEntity}
        />
      ),
    });
  }, [maximizeEntity]);

  return (
    <div
      className={
        maximizeEntity
          ? "myAchievementsDashboard dashboardMaximize"
          : "myAchievementsDashboard"
      }
    >
      {Object.keys(graphEntity).map((item) => (
        <React.Fragment key={item}> {graphEntity[item]}</React.Fragment>
      ))}
    </div>
  );
};

export default Dashboard;
