import React from 'react';
import { useParams, Link } from 'react-router-dom';
import queryString from 'query-string';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { useCrowdSourcing } from '../../context/CrowdSourcingContext';
import './DiscardPopUp.css';

function DiscardPopUp({ message }) {
	const { crowdSourcingAppState, setCrowdSourcingAppState } = useCrowdSourcing();
	const { successMessagePopUp, successMessageComplexityId, reviewerError } = crowdSourcingAppState;
	const activityIdentifier = queryString.parse(window.location.search).identifier;
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId } = useParams();
	const closeModal = () => {
		setCrowdSourcingAppState({
			...crowdSourcingAppState,
			successMessagePopUp: false,
		});
	};
	return (
		<Modal
			className="activityDiscardPopUp"
			show={successMessagePopUp}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			size="md"
			// aria-labelledby="Submitted"
			centered
		>
			<Modal.Body>
				<p>{message}</p>
				<div className="discardPopUpActivityButtons">
					{reviewerError ? (
						<Button
							title="OK"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									window.location.reload();
								}
							}}
							onClick={() => {
								window.location.reload();
								// closeModal();
							}}
							className="activityDiscardPopUpAccept"
							variant="info"
						>
							OK
						</Button>
					) : (
						<Link
							aria-label="Successfull Submission"
							to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${phaseId}/parentPhase/${parentPhaseId}/activity/${activityIdentifier}?complexity=${successMessageComplexityId}`}
						>
							<Button
								title="OK"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={() => {
									closeModal();
								}}
								className="activityDiscardPopUpAccept"
								variant="info"
							>
								OK
							</Button>
						</Link>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscardPopUp;
